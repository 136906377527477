import {
  Alert,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useDuplicateErrorHandler,
  useGetHandlingUnitQuery,
  usePatchHandlingUnitMutation,
  usePostHandlingUnitMutation,
} from '@omnetic-dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export type MeasurementUnitForm = {
  id?: string;
  name: string;
  description: string;
  isDefault: boolean;
};

export function WarehousesMeasurementUnitsDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {duplicateError: isDuplicated, duplicateErrorHandler} = useDuplicateErrorHandler();

  const {
    data: measurementUnit,
    isLoading,
    isError,
  } = useGetHandlingUnitQuery({id: id ?? ''}, {skip: isNil(id)});

  const [createMeasurementUnit] = usePostHandlingUnitMutation();
  const [updateMeasurementUnit] = usePatchHandlingUnitMutation();

  const handleSubmit: FormSubmitHandler<MeasurementUnitForm> = async (data) => {
    if (isNotNil(id)) {
      return await updateMeasurementUnit({
        body: {
          name: data.name,
          description: data.description,
          isDefault: data.isDefault,
        },
        id,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.warehouse.notifications.measurementUnitUpdated'));
        })
        .then(handleNavigateBack)
        .catch(handleApiError);
    }
    await createMeasurementUnit(data)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.measurementUnitCreated'));
      })
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesMeasurementUnits);
  };

  const header: SettingsTemplateHeader = {
    title: isNil(id)
      ? i18n.t('entity.warehouse.actions.newMeasurementUnit')
      : measurementUnit?.description ?? '',
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesMeasurementUnitsDetail('template')}
      header={header}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<MeasurementUnitForm>
          schema={formSchema}
          defaultValues={measurementUnit}
          onSubmit={handleSubmit}
        >
          {(control) => (
            <VStack spacing={4}>
              <Show when={isDuplicated}>
                <Alert
                  variant="error"
                  title={i18n.t('entity.warehouse.notifications.measurementUnitAlreadyExists')}
                  data-testid={testIds.settings.warehousesMeasurementUnitsDetail(
                    'measurementUnitAlreadyExists'
                  )}
                />
              </Show>
              <Grid columns={1}>
                <FormField
                  isDisabled={measurementUnit?.isDefault}
                  type="switch"
                  name="isDefault"
                  label={i18n.t('general.actions.setAsDefault')}
                  control={control}
                  data-testid={testIds.settings.warehousesMeasurementUnitsDetail('setAsDefault')}
                />
              </Grid>
              <Separator />
              <Grid columns={2}>
                <FormField
                  type="text"
                  name="name"
                  label={i18n.t('entity.warehouse.labels.name')}
                  control={control}
                  isRequired
                  data-testid={testIds.settings.warehousesMeasurementUnitsDetail('name')}
                />
                <FormField
                  type="text"
                  name="description"
                  label={i18n.t('entity.warehouse.labels.description')}
                  control={control}
                  data-testid={testIds.settings.warehousesMeasurementUnitsDetail('description')}
                />
              </Grid>
              <Space fillAvailable />
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    variant: 'secondary',
                    title: isNil(id)
                      ? i18n.t('general.actions.discard')
                      : i18n.t('general.actions.discardChanges'),
                    onClick: handleNavigateBack,
                    'data-testid': testIds.settings.warehousesMeasurementUnitsDetail('discard'),
                  },
                  {
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    variant: 'primary',
                    title: isNil(id)
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.saveChanges'),
                    'data-testid': testIds.settings.warehousesMeasurementUnitsDetail('save'),
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
      </DataStatus>
    </SettingsTemplate>
  );
}

const formSchema = object({
  name: yupString.required(),
  isDefault: boolean().default(false),
});
