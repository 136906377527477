import {Checkbox, showNotification, TableRow, TableRowAction, Tooltip} from 'platform/components';
import {Box, HStack, Integer, Show, Space, Text, ThemeColorPath} from 'platform/foundation';

import {startTransition} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isFalse, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  handleApiError,
  useGetRoleQuery,
  useGrantActionPermissionMutation,
  useRevokePermissionMutation,
  useUpdateActionPermissionMutation,
} from '@omnetic-dms/shared';

import {buildArray, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {getFixedTranslation} from '../../../utils/getFixedTranslation';
import {ProtectedUnitWithPermission, TableDataType} from '../hooks/useGetACLData';
import {NEXT_LINE_OFFSET} from '../utils/consts';
import {createScopeString} from '../utils/createScopeString';
import {ActionTooltip} from './ActionTooltip';
import {ScopeForm} from './ScopeForm';

interface ActionItemProps extends TestIdProps {
  protectedUnit: ProtectedUnitWithPermission;
  resource: TableDataType;
  level?: number;
  roleId: string;
}

export function ActionItem(props: ActionItemProps) {
  const [isScopeFormOpened, openScopeForm, closeScopeForm] = useBoolean();

  const level = props.level ?? 0;
  const scope = createScopeString(props.protectedUnit.permission?.scopes);
  const isGranted = isNotNil(props.protectedUnit.permission);

  const {data: role} = useGetRoleQuery({roleId: props.roleId});
  const isSystem = role?.system ?? false;

  const [grantPermission] = useGrantActionPermissionMutation();
  const [removePermission] = useRevokePermissionMutation();
  const [updateActionPermission] = useUpdateActionPermissionMutation();

  const grantActionPermission = (isSelected: boolean) =>
    startTransition(() => {
      const isRevoking = isFalse(isSelected) && isNotNil(props.protectedUnit.permission);

      const permissionAction = isRevoking
        ? removePermission({id: props.protectedUnit.permission?.id ?? '', roleId: props.roleId})
        : grantPermission({
            actionId: props.protectedUnit.id,
            resourceId: props.resource.id,
            roleId: props.roleId,
            scope: null,
          });

      permissionAction.unwrap().catch(handleApiError);
    });

  const handleRemoveScope = () => {
    if (isNil(props.protectedUnit.permission)) {
      return showNotification.error('No scope to remove');
    }

    updateActionPermission({
      actionId: props.protectedUnit.id,
      resourceId: props.resource.id,
      roleId: props.roleId,
      scope: null,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const rowActions = {
    primary: buildArray<TableRowAction>()
      .when(isNotNilOrEmpty(props.protectedUnit.scopes) && !isSystem, {
        title: isNilOrEmpty(props.protectedUnit.permission?.scopes)
          ? getFixedTranslation('acl.dialog.button.submit')
          : getFixedTranslation('general.actions.edit'),
        icon: 'image/edit',
        onClick: isScopeFormOpened ? closeScopeForm : openScopeForm,
      })
      .when(isNotNilOrEmpty(props.protectedUnit.permission?.scopes) && !isSystem, {
        title: getFixedTranslation('acl.dialog.button.removeScope'),
        icon: 'content/remove_circle',
        onClick: handleRemoveScope,
      }),
  };

  const rowColor: ThemeColorPath | undefined = isFalse(isGranted)
    ? 'palettes.red.10.100'
    : undefined;

  return (
    <>
      <TableRow
        actions={rowActions}
        data-testid={suffixTestId('actionRow', props)}
        key={props.protectedUnit.id}
        color={rowColor}
      >
        <Box paddingHorizontal={2} paddingLeft={(1 + level * NEXT_LINE_OFFSET) as Integer}>
          <HStack spacing={8} align="center">
            <Space horizontal={8} />
            <Checkbox
              value={isGranted}
              onChange={grantActionPermission}
              isDisabled={isSystem}
              data-testid={suffixTestId('select-action', props)}
            />
            <Tooltip
              description={
                <ActionTooltip
                  protectedUnitId={props.protectedUnit.id}
                  resourceId={props.resource.id}
                />
              }
              hasAutoWidth
            >
              <Box minWidth={50}>
                <Text size="small">{props.protectedUnit.name}</Text>
              </Box>
            </Tooltip>
          </HStack>
        </Box>
        <Box
          paddingHorizontal={2}
          paddingVertical={1}
          data-testid={suffixTestId('permissionScope', props)}
        >
          <Show when={scope}>
            <Tooltip description={scope}>
              <Text size="small">{scope}</Text>
            </Tooltip>
          </Show>
        </Box>
      </TableRow>
      <Show when={isScopeFormOpened}>
        <tr>
          <td colSpan={2}>
            <Box
              padding={2}
              paddingLeft={(1 + level * NEXT_LINE_OFFSET) as Integer}
              borderRight="1px solid"
              borderColor="general.separator"
            >
              <HStack spacing={8} align="center">
                <Space horizontal={8} />

                <ScopeForm
                  protectedUnit={props.protectedUnit}
                  roleId={props.roleId}
                  resource={props.resource}
                  closeScopeForm={closeScopeForm}
                />
              </HStack>
            </Box>
          </td>
        </tr>
      </Show>
    </>
  );
}
