import {AxiosError} from 'axios';

import {ApiException} from '@omnetic-dms/shared';

import {PossibleObject} from '../types/PossibleObject';

export const getStringErrorMessage = (
  error: AxiosError<ApiException>,
  formattingErrorHandler?: (item: PossibleObject) => string
): string[] => {
  const {data} = error.response ?? {};

  if (data?.errors.length) {
    return data.errors.map((item) =>
      formattingErrorHandler
        ? (formattingErrorHandler(item) ?? item?.code)
        : (item?.message ?? item?.code)
    );
  }

  if (data?.validationErrors.length) {
    return data.validationErrors.map((item) =>
      formattingErrorHandler ? formattingErrorHandler(item) : item?.message
    );
  }

  if (data?.error?.message) {
    return [data.error.message];
  }
  return [];
};
