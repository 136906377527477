import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';

import {browserStorageKey, document360, redirectLink} from '@omnetic-dms/config';
import {useLazyGetDocument360RedirectUrlQuery} from '@omnetic-dms/shared';

export const useDoc360Login = () => {
  const [searchParams] = useSearchParams();
  const [getDocument360RedirectURl] = useLazyGetDocument360RedirectUrlQuery();

  const isDoc360Login = searchParams.has(document360);
  const urlRedirectLink = sessionStorage.getItem(redirectLink);

  const loginToDoc360 = useCallback(() => {
    const getUsersLastKnownRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);

    if (isDoc360Login && getUsersLastKnownRefreshToken) {
      getDocument360RedirectURl({redirectTo: decodeURIComponent(urlRedirectLink ?? '/')})
        .unwrap()
        .then((res) => res.redirectUrl)
        .then((url) => window.open(url, '_self'));
    }
  }, [getDocument360RedirectURl, isDoc360Login, urlRedirectLink]);

  return loginToDoc360;
};
