import {ComponentMultiStyleConfig} from '@chakra-ui/react';

import {dgIndent} from './dgIndent.theme';
import {dgInputs} from './dgInpit.theme';

export const dataGridTheme: ComponentMultiStyleConfig = {
  parts: [
    'grid',
    'avatarCellRenderer',
    'flagCellRenderer',
    'secondLineCellRenderer',
    'checkboxCellRenderer',
    'iconCellRenderer',
    'thumbnailCellRenderer',
    'rowSelectRenderer',
    'detailRenderer',
    'linkCellRenderer',
    'rankCellRenderer',
    'inputCellEditor',
    'dateCellEditor',
    'bulkActionsPanel',
    'header',
    'footer',
    'cardWrapper',
    'inlineWrapper',
    'aside',
  ],
  baseStyle: {
    aside: {
      width: '48px',
      borderLeft: '1px solid',
      borderColor: '#DFE1E6',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      paddingTop: '8px',
      background: 'white',
    },
    avatarCellRenderer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',

      '.avatar-name': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: 2,
      },
    },
    flagCellRenderer: {
      display: 'inline-flex',
    },
    secondLineCellRenderer: {
      '*': {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.secondary-value': {
        fontSize: 'xs',
        color: 'gray.500',
      },
    },
    checkboxCellRenderer: {
      display: 'inline-flex',
    },
    iconCellRenderer: {
      display: 'inline-flex',
      width: '25px',
      height: '100%',
    },
    thumbnailCellRenderer: {
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      height: '80%',
      '& > span': {
        height: '100%',
      },
      img: {
        height: '100%',
        borderRadius: 'base',
      },
    },
    rowSelectRenderer: {
      display: 'inline-flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    expanderCellRenderer: {
      inlineSize: 'max-content',
      display: 'inline-block',
    },
    actionsCellRenderer: {
      display: 'inline-flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    detailRenderer: {
      height: 'initial',
      background: 'white',
      padding: '4',
      cursor: 'auto',
    },
    linkCellRenderer: {
      display: 'inline-flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      '*': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    rankCellRenderer: {
      display: 'inline-flex',
      height: '100%',
      alignItems: 'center',
      span: {
        width: '4px',
        height: '16px',
        marginRight: '2px',
        borderRadius: '2px',
        '&.filled': {
          backgroundColor: 'orange.300',
        },
        '&.empty': {
          backgroundColor: 'gray.300',
        },
      },
    },
    inputCellEditor: {
      display: 'inline-flex',
    },
    dateCellEditor: {
      input: {
        backgroundColor: 'white !important',
      },
    },
    header: {
      minHeight: '56px',
      maxHeight: '112px',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      padding: 3,
      display: 'flex',
      alignItems: 'center',

      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },

      '.ag-custom-header-panel': {
        flexGrow: 1,
        padding: '5px 0',
        paddingLeft: '5px',
        marginLeft: '-5px',
        '& > *': {
          marginRight: '8px',

          '&.ag-custom-separator': {
            height: '24px',
            width: '1px',
            backgroundColor: 'gray.200',
          },

          '&>*': {
            flexShrink: 0,
          },
        },
      },
    },
    footer: {
      height: '56px',
      minHeight: '56px',
      boxSizing: 'border-box',
      alignItems: 'center',
      borderTopWidth: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 4,
    },
    inlineWrapper: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    cardWrapper: {
      overflow: 'hidden',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      borderWidth: 1,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 'base',
      height: '100%',
    },
    bulkActionsPanel: {
      height: 0,
      marginBottom: '-2px',
      borderBottom: '1px solid',
      borderBottomColor: 'gray.200',
      boxSizing: 'content-box',
      overflow: 'hidden',

      '&>div': {
        w: '100%',
        h: '56px',
        bg: 'gray.50',
        display: 'flex',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    gridZebra: {
      '.ag-row-odd:not(.ag-row-hover):not(.ag-row-selected)': {
        bg: '#FAFBFC',
      },
    },
    gridRowBorder: {
      '.ag-row:not(.ag-row-last)': {
        borderBottom: '1px solid',
        borderBottomColor: 'gray.200',
      },
    },
    gridColBorder: {
      '.ag-pinned-right-header .ag-header-cell, .ag-pinned-right-cols-container .ag-cell, .ag-header-cell, .ag-header-group-cell, .ag-cell':
        {
          borderColor: 'transparent',
          borderRight: '1px solid',
          borderLeft: '0',
          borderImage:
            'linear-gradient(transparent 10%, #E2E8F0 10%,#E2E8F0 90%, transparent 90%) 0 100%;',
        },
      '.ag-column-resizing': {
        borderColor: 'blue.200',
      },
      '.ag-header-group-cell .ag-header-cell-resize::after ,.ag-header-cell .ag-header-cell-resize::after':
        {
          display: 'none',
          content: 'none',
        },
    },
    grid: {
      WebkitFontSmoothing: 'antialiased',
      color: '#091E42',
      background: 'white',
      fontSize: 'sm',
      lineHeight: 'normal',

      // Three classes for storybook purpose
      '.ag-storybook-oldCar': {
        backgroundColor: '#FFA500 !important',
      },
      '.ag-storybook-veteran': {
        backgroundColor: '#FF0000 !important',
      },
      '.ag-storybook-model': {
        backgroundColor: '#00ff00 !important',
      },
      '.ag-react-container': {
        width: '100%',
      },
      '.ag-cell > span': {
        inlineSize: 'max-content',
        minWidth: '0',
        maxWidth: '100%',
      },
      '.ag-cell': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',

        '.ellipsis': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        a: {
          color: '#0065FF',
        },
        'a:hover': {
          textDeforation: 'underline',
        },
      },
      '.ag-pinned-right-header .ag-header-cell, .ag-pinned-right-cols-container .ag-cell': {
        borderRightColor: 'transparent',
      },

      '.ag-root-wrapper': {},
      "[class^='ag-'], [class^='ag-']:focus, [class^='ag-']:after, [class^='ag-']:before": {
        boxSizing: 'border-box',
        outline: 'none',
      },
      "[class^='ag-']::-ms-clear": {display: 'none'},
      '.ag-checkbox .ag-input-wrapper,  .ag-radio-button .ag-input-wrapper': {
        overflow: 'visible',
      },
      '.ag-range-field .ag-input-wrapper': {height: '100%'},
      '.ag-toggle-button': {
        flex: 'none',
        width: 'unset',
        minWidth: 'unset',
      },
      '.ag-ltr .ag-label-align-right .ag-label': {
        marginLeft: '12px',
      },
      '.ag-rtl .ag-label-align-right .ag-label': {
        marginRight: '2',
      },

      ...dgInputs,

      '.ag-drag-handle': {
        color: 'gray.400',
      },
      '.ag-list-item, .ag-virtual-list-item': {
        height: '24px',
      },
      '.ag-keyboard-focus .ag-virtual-list-item:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-virtual-list-item:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-select-list': {
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      '.ag-list-item': {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.ag-list-item.ag-active-item': {
        backgroundColor: [
          'rgba(33, 150, 243, 0.1)',
          'var(--ag-row-hover-color, rgba(33, 150, 243, 0.1))',
        ],
      },
      '.ag-select-list-item': {
        paddingLeft: '4px',
        paddingRight: '4px',
        cursor: 'default',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
      },
      '.ag-select-list-item span': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      '.ag-select .ag-picker-field-wrapper': {
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        minHeight: '24px',
        cursor: 'default',
      },
      '.ag-select.ag-disabled .ag-picker-field-wrapper:focus': {
        boxShadow: 'none',
      },
      '.ag-select .ag-picker-field-display': {
        margin: '4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.ag-select .ag-picker-field-icon': {
        display: 'flex',
        alignItems: 'center',
      },
      '.ag-select.ag-disabled': {opacity: 0.5},
      '.ag-rich-select': {
        backgroundColor: ['#f8f8f8', 'var(--ag-control-panel-backgroundColor, #f8f8f8)'],
      },
      '.ag-rich-select-list': {
        width: '100%',
        minWidth: '200px',
        height: '273px',
      },
      '.ag-rich-select-value': {
        padding: '0 6px 0 18px',
        borderBottom: 'solid 1px',
        borderBottomColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
      },
      '.ag-rich-select-virtual-list-item': {
        cursor: 'default',
        height: '24px',
      },
      '.ag-rich-select-virtual-list-item:hover': {
        backgroundColor: [
          'rgba(33, 150, 243, 0.1)',
          'var(--ag-row-hover-color, rgba(33, 150, 243, 0.1))',
        ],
      },
      '.ag-rich-select-row': {paddingLeft: '18px'},
      '.ag-rich-select-row-selected': {
        backgroundColor: [
          'rgba(33, 150, 243, 0.3)',
          'var(--ag-selected-row-backgroundColor, rgba(33, 150, 243, 0.3))',
        ],
      },
      '.ag-row-drag,  .ag-selection-checkbox,  .ag-group-expanded,  .ag-group-contracted': {
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
      },
      '.ag-ltr .ag-row-drag, .ag-ltr .ag-selection-checkbox, .ag-ltr .ag-group-expanded, .ag-ltr .ag-group-contracted':
        {
          marginRight: '12px',
        },
      '.ag-rtl .ag-row-drag, .ag-rtl .ag-selection-checkbox, .ag-rtl .ag-group-expanded, .ag-rtl .ag-group-contracted':
        {
          marginLeft: '12px',
        },
      '.ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value)': {
        display: 'flex',
        alignItems: 'center',
        flex: 'none',
        maxWidth: '100%',
      },
      '.ag-group-expanded,  .ag-group-contracted': {
        cursor: 'pointer',
      },
      '.ag-group-title-bar-icon': {
        cursor: 'pointer',
        flex: 'none',
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
      },
      '.ag-ltr .ag-group-child-count': {marginLeft: '2px'},
      '.ag-rtl .ag-group-child-count': {marginRight: '2px'},
      '.ag-group-title-bar': {
        backgroundColor: ['#fff', 'var(--ag-subheader-backgroundColor, #fff)'],
        padding: '12px 0px 12px 16px',
      },
      '.ag-group-toolbar': {padding: '6px'},
      '.ag-disabled-group-title-bar, .ag-disabled-group-container': {
        opacity: 0.5,
      },
      '.group-item': {margin: '3px 0'},
      '.ag-label': {whiteSpace: 'nowrap'},
      '.ag-rtl .ag-label': {marginLeft: '6px'},
      '.ag-label-align-top .ag-label': {marginBottom: '3px'},
      '.ag-ltr .ag-slider-field, .ag-ltr .ag-angle-select-field': {
        marginRight: '12px',
      },
      '.ag-rtl .ag-slider-field, .ag-rtl .ag-angle-select-field': {
        marginLeft: '12px',
      },
      '.ag-angle-select-parent-circle': {
        width: '24px',
        height: '24px',
        borderRadius: '12px',
        border: 'solid 1px',
        borderColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
      },
      '.ag-angle-select-child-circle': {
        top: '4px',
        left: '12px',
        width: '6px',
        height: '6px',
        marginLeft: '-3px',
        marginTop: '-4px',
        borderRadius: '3px',
        backgroundColor: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
      },
      '.ag-picker-field-wrapper': {
        border: '1px solid',
        borderColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        borderRadius: '5px',
      },
      '.ag-picker-field-wrapper:focus': {
        boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
      },
      '.ag-picker-field-button': {
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
      },
      '.ag-dialog.ag-color-dialog': {borderRadius: '5px'},
      '.ag-color-picker .ag-picker-field-display': {
        height: '16px',
      },
      '.ag-color-panel': {padding: '6px'},
      '.ag-spectrum-color': {
        backgroundColor: 'red',
        borderRadius: '2px',
      },
      '.ag-spectrum-tools': {padding: '10px'},
      '.ag-spectrum-sat': {
        backgroundImage: 'linear-gradient(to right, white, rgba(204, 154, 129, 0))',
      },
      '.ag-spectrum-val': {
        backgroundImage: 'linear-gradient(to top, black, rgba(204, 154, 129, 0))',
      },
      '.ag-spectrum-dragger': {
        borderRadius: '12px',
        height: '12px',
        width: '12px',
        border: '1px solid white',
        background: 'black',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.24)',
      },
      '.ag-spectrum-hue-background': {borderRadius: '2px'},
      '.ag-spectrum-alpha-background': {borderRadius: '2px'},
      '.ag-spectrum-tool': {
        marginBottom: '10px',
        height: '11px',
        borderRadius: '2px',
      },
      '.ag-spectrum-slider': {
        marginTop: '-12px',
        width: '13px',
        height: '13px',
        borderRadius: '13px',
        backgroundColor: '#f8f8f8',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
      },
      '.ag-recent-color': {margin: '0 3px'},
      '.ag-recent-color:first-of-type': {marginLeft: '0'},
      '.ag-recent-color:last-of-type': {marginRight: '0'},

      '.ag-dragging-range-handle .ag-dialog,  .ag-dragging-fill-handle .ag-dialog': {
        opacity: 0.7,
        pointerEvents: 'none',
      },
      '.ag-dialog': {
        borderRadius: '3px',
        border: 'solid 1px',
        borderColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-panel': {
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
      },
      '.ag-panel-title-bar.ag-default-panel-title-bar.ag-unselectable': {
        backgroundColor: '#fff',
        borderColor: '#DFE1E6',
      },
      '.ag-column-select-column-drag-handle': {
        visibility: 'hidden',
      },
      '.ag-column-select-column-group-drag-handle': {
        visibility: 'hidden',
      },
      '.ag-panel-title-bar': {
        backgroundColor: ['#f8f8f8', 'var(--ag-header-backgroundColor, #f8f8f8)'],
        color: [
          '#181d1f',
          'var(--ag-header-foreground-color, var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f)))',
        ],
        height: '48px',
        padding: '6px 18px',
        borderBottom: 'solid 1px',
        borderBottomColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-ltr .ag-panel-title-bar-button': {
        marginLeft: '12px',
        marginRight: '6px',
      },
      '.ag-rtl .ag-panel-title-bar-button': {
        marginRight: '12px',
        marginLeft: '6px',
      },
      '.ag-popup .ag-tooltip': {
        paddingInlineStart: '8px',
        paddingInlineEnd: '8px',
        paddingTop: '4px',
        paddingBottom: '4px',
        color: 'var(--eag-colors-palettes-white-10-100)',
        fontWeight: 'var(--eag-fontWeights-medium)',
        fontSize: 'var(--eag-fontSizes-sm)',
        boxShadow: 'var(--eag-shadows-md)',
        maxWidth: '200px',
        zIndex: 'var(--eag-zIndices-tooltip)',
        backgroundColor: 'var(--eag-colors-palettes-neutral-900-100)',
        borderRadius: 'var(--eag-radii-small)',
        transition: 'opacity 0.2s',
      },
      '.ag-tooltip.ag-tooltip-hiding': {opacity: 0},

      ...dgIndent,

      '.ag-column-select-header-icon': {cursor: 'pointer'},
      '.ag-keyboard-focus .ag-column-select-header-icon:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-column-select-header-icon:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '0px',
        left: '0px',
        display: 'block',
        width: 'calc(100% - 0px)',
        height: 'calc(100% - 0px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-ltr .ag-column-group-icons:not(:last-of-type), .ag-ltr , .ag-ltr .ag-column-select-header-checkbox:not(:last-of-type), .ag-ltr .ag-column-select-header-filter-wrapper:not(:last-of-type), .ag-ltr .ag-column-select-checkbox:not(:last-of-type), .ag-ltr, .ag-ltr, .ag-ltr .ag-column-select-column-label:not(:last-of-type)':
        {
          marginRight: '16px',
        },
      '.ag-rtl .ag-column-group-icons:not(:last-of-type), .ag-rtl .ag-column-select-header-icon:not(:last-of-type), .ag-rtl .ag-column-select-header-checkbox:not(:last-of-type), .ag-rtl .ag-column-select-header-filter-wrapper:not(:last-of-type), .ag-rtl .ag-column-select-checkbox:not(:last-of-type), .ag-rtl, .ag-rtl, .ag-rtl .ag-column-select-column-label:not(:last-of-type)':
        {
          marginLeft: '12px',
        },
      '.ag-keyboard-focus .ag-column-select-virtual-list-item:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-column-select-virtual-list-item:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '1px',
        left: '1px',
        display: 'block',
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-column-select-column-group:not(:last-of-type),  .ag-column-select-column:not(:last-of-type)':
        {
          marginBottom: '9px',
        },
      '.ag-column-select-column-readonly,  .ag-column-select-column-group-readonly': {
        color: [
          'rgba(24, 29, 31, 0.5)',
          'var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5))',
        ],
        pointerEvents: 'none',
      },

      '.ag-value-change-delta': {paddingRight: '2px'},
      '.ag-value-change-delta-up': {
        color: ['#43a047', 'var(--ag-value-change-delta-up-color, #43a047)'],
      },
      '.ag-value-change-delta-down': {
        color: ['#e53935', 'var(--ag-value-change-delta-down-color, #e53935)'],
      },
      '.ag-value-change-value': {
        backgroundColor: 'transparent',
        borderRadius: '1px',
        paddingLeft: '1px',
        paddingRight: '1px',
        transition: 'backgroundColor 1s',
      },
      '.ag-value-change-value-highlight': {
        backgroundColor: [
          'rgba(22, 160, 133, 0.5)',
          'var(--ag-value-change-value-highlight-backgroundColor, rgba(22, 160, 133, 0.5))',
        ],
        transition: 'backgroundColor 0.1s',
      },
      '.ag-cell-data-changed': {
        backgroundColor: [
          'rgba(22, 160, 133, 0.5) !important',
          'var(--ag-value-change-value-highlight-backgroundColor, rgba(22, 160, 133, 0.5)) !important',
        ],
      },
      '.ag-cell-data-changed-animation': {
        backgroundColor: 'transparent',
      },
      '.ag-cell-highlight': {
        backgroundColor: [
          '#2196f3 !important',
          'var(--ag-range-selection-highlight-color, var(--ag-range-selection-border-color, #2196f3)) !important',
        ],
      },
      '.ag-row': {
        borderColor: 'gray.300',
        borderBottomStyle: 'solid',
        backgroundColor: 'white',
      },
      '.ag-row[row-id*="detail_"] .ag-react-container': {
        height: 'auto',
      },
      '.ag-row-highlight-above::after, .ag-row-highlight-below::after': {
        content: "''",
        position: 'absolute',
        width: 'calc(100% - 1px)',
        height: '1px',
        backgroundColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        left: '1px',
      },
      '.ag-row-highlight-above::after': {top: '-1px'},
      '.ag-row-highlight-above.ag-row-first::after': {top: '0'},
      '.ag-row-highlight-below::after': {bottom: '0px'},
      '.ag-row-hover': {
        backgroundColor: [
          'rgba(33, 150, 243, 0.1)',
          'var(--ag-row-hover-color, rgba(33, 150, 243, 0.1))',
        ],
      },
      '.ag-ltr .ag-right-aligned-cell': {textAlign: 'right'},
      '.ag-rtl .ag-right-aligned-cell': {textAlign: 'left'},
      '.ag-right-aligned-cell': {
        justifyContent: 'flex-end',
      },
      '.ag-ltr .ag-right-aligned-cell .ag-cell-value, .ag-ltr .ag-right-aligned-cell .ag-group-value':
        {
          marginLeft: 'auto',
        },
      '.ag-rtl .ag-right-aligned-cell .ag-cell-value, .ag-rtl .ag-right-aligned-cell .ag-group-value':
        {
          marginRight: 'auto',
        },
      '.ag-cell-wrapper': {
        height: 'auto',
      },
      '.ag-cell-wrapper.ag-row-group': {
        alignItems: 'center',
      },
      '.ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group': {
        border: '1px solid transparent',
        paddingLeft: 2,
        paddingRight: 2,
        WebkitFontSmoothing: 'subpixel-antialiased',
      },
      '.ag-row > .ag-cell-wrapper': {
        paddingLeft: '17px',
        paddingRight: '17px',
      },
      '.ag-row-dragging': {cursor: 'move', opacity: 0.5},
      '.ag-large-text-input': {height: 'auto', padding: '18px'},
      '.ag-details-row': {
        padding: '30px',
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
      },
      '.ag-layout-auto-height .ag-center-cols-clipper, .ag-layout-auto-height .ag-center-cols-container, .ag-layout-print .ag-center-cols-clipper, .ag-layout-print .ag-center-cols-container':
        {
          minHeight: '150px',
        },
      '.ag-floating-bottom-container, .ag-center-cols-container': {
        minWidth: '100%',
      },
      '.ag-overlay-loading-wrapper': {
        backgroundColor: [
          'rgba(255, 255, 255, 0.66)',
          'var(--ag-modal-overlay-backgroundColor, rgba(255, 255, 255, 0.66))',
        ],
      },
      '.ag-overlay-loading-center': {
        border: 'solid 1px',
        borderColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        background: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        borderRadius: '3px',
        boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
        padding: '6px',
      },
      '.ag-overlay-no-rows-wrapper.ag-layout-auto-height': {
        paddingTop: '60px',
      },
      '.ag-loading': {
        paddingLeft: '18px',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      },
      '.ag-loading-icon': {paddingRight: '12px'},
      '.ag-icon-loading': {
        WebkitAnimationName: 'spin',
        animationName: 'spin',
        WebkitAnimationDuration: '1000ms',
        animationDuration: '1000ms',
        WebkitAnimationIterationCount: 'infinite',
        animationIterationCount: 'infinite',
        WebkitAnimationTimingFunction: 'linear',
        animationTimingFunction: 'linear',
      },
      '@-webkit-keyframes spin': {
        from: {
          WebkitTransform: 'rotate(0deg)',
          transform: 'rotate(0deg)',
        },
        to: {
          WebkitTransform: 'rotate(360deg)',
          transform: 'rotate(360deg)',
        },
      },
      '@keyframes spin': {
        from: {
          WebkitTransform: 'rotate(0deg)',
          transform: 'rotate(0deg)',
        },
        to: {
          WebkitTransform: 'rotate(360deg)',
          transform: 'rotate(360deg)',
        },
      },
      '.ag-floating-top': {
        borderBottom: 'solid 1px',
        borderBottomColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-floating-bottom': {
        borderTop: 'solid 2px',
        borderBottom: '0px',
        borderTopColor: ['#E2E8F0', 'var(--ag-border-color, #E2E8F0)'],
      },
      '.ag-row-selected': {
        backgroundColor: [
          'rgba(33, 150, 243, 0.3)',
          'var(--ag-selected-row-backgroundColor, rgba(33, 150, 243, 0.3))',
        ],
      },
      '.ag-cell-range-selected:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing)':
        {
          backgroundColor: [
            'rgba(33, 150, 243, 0.2)',
            'var(--ag-range-selection-backgroundColor, rgba(33, 150, 243, 0.2))',
          ],
        },
      '.ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart,    .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing).ag-cell-range-chart':
        {
          backgroundColor: [
            'rgba(0, 88, 255, 0.1) !important',
            'var(--ag-range-selection-chart-backgroundColor, rgba(0, 88, 255, 0.1)) !important',
          ],
        },
      '.ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart.ag-cell-range-chart-category,      .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing).ag-cell-range-chart.ag-cell-range-chart-category':
        {
          backgroundColor: [
            'rgba(0, 255, 132, 0.1) !important',
            'var(--ag-range-selection-chart-category-backgroundColor, rgba(0, 255, 132, 0.1)) !important',
          ],
        },
      '.ag-cell-range-selected-1:not(.ag-cell-focus),  .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing)':
        {
          backgroundColor: [
            'rgba(33, 150, 243, 0.2)',
            'var(--ag-range-selection-backgroundColor-1, var(--ag-range-selection-backgroundColor, rgba(33, 150, 243, 0.2)))',
          ],
        },
      '.ag-cell-range-selected-2:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-2':
        {
          backgroundColor: [
            'rgba(33, 150, 243, 0.36)',
            'var(--ag-range-selection-backgroundColor-2, rgba(33, 150, 243, 0.36))',
          ],
        },
      '.ag-cell-range-selected-3:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-3':
        {
          backgroundColor: [
            'rgba(33, 150, 243, 0.488)',
            'var(--ag-range-selection-backgroundColor-3, rgba(33, 150, 243, 0.488))',
          ],
        },
      '.ag-cell-range-selected-4:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-4':
        {
          backgroundColor: [
            'rgba(33, 150, 243, 0.5904)',
            'var(--ag-range-selection-backgroundColor-4, rgba(33, 150, 243, 0.5904))',
          ],
        },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top': {
        borderColor: 'transparent',
        borderTopColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        borderImage: 'none',
        borderTop: '1px solid',
      },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right': {
        borderColor: 'transparent',
        borderRightColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        borderImage: 'none',
        borderRight: '1px solid',
      },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom': {
        borderColor: 'transparent',
        borderBottomColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        borderImage: 'none',
        borderBottom: '1px solid',
      },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left': {
        borderColor: 'transparent',
        borderLeftColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        borderImage: 'none',
        borderLeft: '1px solid',
      },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left.ag-cell-range-top':
        {
          borderLeftColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
          borderTopColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right.ag-cell-range-top':
        {
          borderRightColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
          borderTopColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left.ag-cell-range-bottom':
        {
          borderLeftColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
          borderBottomColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right.ag-cell-range-bottom':
        {
          borderRightColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
          borderBottomColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right.ag-cell-range-left':
        {
          borderRightColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
          borderLeftColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),  .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),  .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,  .ag-ltr .ag-cell-range-single-cell,  .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),  .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),  .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,  .ag-rtl .ag-cell-range-single-cell,  .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle':
        {
          border: '1px solid',
          borderColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
          outline: 'initial',
        },
      '.ag-cell.ag-selection-fill-top,  .ag-cell.ag-selection-fill-top.ag-cell-range-selected': {
        borderTop: '1px dashed',
        borderTopColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
      },
      '.ag-ltr .ag-cell.ag-selection-fill-right, .ag-ltr .ag-cell.ag-selection-fill-right.ag-cell-range-selected':
        {
          borderRight: '1px dashed',
          borderRightColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-rtl .ag-cell.ag-selection-fill-right, .ag-rtl .ag-cell.ag-selection-fill-right.ag-cell-range-selected':
        {
          borderLeft: '1px dashed',
          borderLeftColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-cell.ag-selection-fill-bottom,  .ag-cell.ag-selection-fill-bottom.ag-cell-range-selected':
        {
          borderBottom: '1px dashed',
          borderBottomColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-ltr .ag-cell.ag-selection-fill-left, .ag-ltr .ag-cell.ag-selection-fill-left.ag-cell-range-selected':
        {
          borderLeft: '1px dashed',
          borderLeftColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-rtl .ag-cell.ag-selection-fill-left, .ag-rtl .ag-cell.ag-selection-fill-left.ag-cell-range-selected':
        {
          borderRight: '1px dashed',
          borderRightColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
        },
      '.ag-fill-handle, .ag-range-handle': {
        position: 'absolute',
        width: '6px',
        height: '6px',
        bottom: '-1px',
        backgroundColor: ['#2196f3', 'var(--ag-range-selection-border-color, #2196f3)'],
      },
      '.ag-ltr .ag-fill-handle, .ag-ltr .ag-range-handle': {
        right: '-1px',
      },
      '.ag-rtl .ag-fill-handle, .ag-rtl .ag-range-handle': {
        left: '-1px',
      },
      '.ag-fill-handle': {cursor: 'cell'},
      '.ag-range-handle': {cursor: 'nwse-resize'},
      '.ag-menu .ag-menu-body  .ag-filter': {
        padding: '16px',
      },
      '.ag-menu': {
        border: 'solid 1px',
        borderColor: 'gray.200',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.12)',
        overflow: 'visible',
        width: '400px',
      },

      '.ag-menu-separator': {
        height: '13px',
        display: 'flex',
        alignItems: 'center',
      },
      '.ag-menu-separator-part': {
        width: '100%',
      },
      '.ag-menu-separator-part::after': {
        content: '""',
        display: 'block',
        borderTop: 'solid 1px',
        borderTopColor: 'gray.200',
        width: '100%',
      },
      '.ag-menu-option-part, .ag-compact-menu-option-part': {
        lineHeight: '16px',
        padding: '8px 0',
      },
      '.ag-menu-option-disabled, .ag-compact-menu-option-disabled': {
        opacity: 0.5,
      },
      '.ag-menu-option-icon, .ag-compact-menu-option-icon': {
        width: '30px',
      },
      '.ag-ltr .ag-menu-option-icon, .ag-ltr .ag-compact-menu-option-icon': {
        paddingLeft: '12px',
      },
      '.ag-rtl .ag-menu-option-icon, .ag-rtl .ag-compact-menu-option-icon': {
        paddingRight: '12px',
      },
      '.ag-menu-option-text, .ag-compact-menu-option-text': {
        paddingLeft: '12px',
        paddingRight: '12px',
        width: '100%',
      },
      '.ag-ltr .ag-menu-option-shortcut, .ag-ltr .ag-compact-menu-option-shortcut': {
        paddingRight: '6px',
      },
      '.ag-rtl .ag-menu-option-shortcut, .ag-rtl .ag-compact-menu-option-shortcut': {
        paddingLeft: '6px',
      },
      '.ag-menu-option-popup-pointer, .ag-compact-menu-option-popup-pointer': {
        paddingRight: '6px',
      },
      '.ag-tabs': {
        transform: 'translate(10px, 25px)',
        borderRadius: '8px',
        borderColor: 'gray.200',
      },
      '.ag-tabs-header': {
        minWidth: '220px',
        width: '100%',
        display: 'flex',
        borderBottom: 'solid 1px',
        borderBottomColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-tab': {
        borderBottom: '2px solid transparent',
        display: 'flex',
        flex: ['none', '1 1 auto'],
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'color 0.4s',
        padding: '9px',
      },
      '.ag-keyboard-focus .ag-tab:focus': {outline: 'none'},
      '.ag-keyboard-focus .ag-tab:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-tab-selected': {
        borderBottomColor: 'var(--eag-colors-general-accent)',
        color: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
      },
      '.ag-tab-selected .ag-icon': {
        color: 'var(--eag-colors-general-accent)',
      },
      '.ag-menu-header': {
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
        backgroundColor: ['#f8f8f8', 'var(--ag-control-panel-backgroundColor, #f8f8f8)'],
        paddingTop: '1px',
        minWidth: '240px',
      },
      '.ag-filter-separator': {
        borderTop: 'solid 1px',
        borderTopColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-filter-condition-operator': {height: '17px'},
      '.ag-ltr .ag-filter-condition-operator-or': {
        marginLeft: '12px',
      },
      '.ag-rtl .ag-filter-condition-operator-or': {
        marginRight: '12px',
      },
      '.ag-set-filter-select-all': {paddingTop: '12px'},
      '.ag-set-filter-filter': {
        marginTop: '12px',
        marginLeft: '12px',
        marginRight: '12px',
      },
      '.ag-filter-to': {marginTop: '9px'},
      '.ag-mini-filter': {margin: '12px 12px'},
      '.ag-set-filter-item': {margin: '0px 12px'},
      '.ag-ltr .ag-set-filter-item-value': {marginLeft: '12px'},
      '.ag-rtl .ag-set-filter-item-value': {marginRight: '12px'},
      '.ag-filter-apply-panel': {
        paddingTop: '16px',
        borderTop: 'solid 1px',
        borderTopColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
        paddingBottom: '5px',
        marginBottom: '-5px',
      },
      '.ag-filter-apply-panel-button': {lineHeight: 1.5},
      '.ag-ltr .ag-filter-apply-panel-button': {
        marginLeft: '12px',
      },
      '.ag-rtl .ag-filter-apply-panel-button': {
        marginRight: '12px',
      },
      '.ag-standard-button.ag-filter-apply-panel-button': {
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
      },
      '.ag-standard-button.ag-filter-apply-panel-button:first-of-type': {
        backgroundColor: '#EBECF0',
        borderColor: 'transparent',
        color: '#091E42',
        fontWeight: 'normal',
      },
      '.ag-standard-button.ag-filter-apply-panel-button:last-of-type': {
        backgroundColor: '#0065FF',
        color: 'white',
      },
      '.ag-group-container.ag-group-container-vertical.ag-filter-toolpanel-group-container': {
        paddingLeft: '0px',
        backgroundColor: 'gray.50',
      },
      '.ag-simple-filter-body-wrapper': {
        paddingBottom: '7px',
      },
      '.ag-simple-filter-body-wrapper > *': {
        marginBottom: '16px',
      },
      '.ag-filter-no-matches': {padding: '12px 12px'},
      '.ag-multi-filter-menu-item': {margin: '6px 0'},
      '.ag-multi-filter-group-title-bar': {
        padding: '12px 6px',
        backgroundColor: 'transparent',
      },
      '.ag-keyboard-focus .ag-multi-filter-group-title-bar:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-multi-filter-group-title-bar:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-side-bar': {position: 'relative'},
      '.ag-tool-panel-wrapper': {
        position: 'absolute',
        width: '400px',
        backgroundColor: 'white !important',
        border: '0',
        borderLeft: '1px solid #DFE1E6',
        zIndex: '1',
        boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.16)',
        top: '0',
        right: '0',
        bottom: '0',
        borderRadius: '0',
      },
      '.ag-side-buttons': {
        display: 'none',
      },
      'button.ag-side-button-button': {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        background: 'transparent',
        padding: '12px 0 12px 0',
        width: '100%',
        margin: '0',
        minHeight: '108px',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        border: 'none',
      },
      'button.ag-side-button-button:focus': {boxShadow: 'none'},
      '.ag-keyboard-focus .ag-side-button-button:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-side-button-button:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-side-button-icon-wrapper': {marginBottom: '3px'},
      '.ag-ltr .ag-side-bar-left,  .ag-rtl .ag-side-bar-right': {
        borderRight: 'solid 1px',
        borderRightColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-ltr .ag-side-bar-left .ag-tool-panel-wrapper,    .ag-rtl .ag-side-bar-right .ag-tool-panel-wrapper':
        {
          borderLeft: 'solid 1px',
          borderLeftColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        },
      '.ag-ltr .ag-side-bar-left .ag-side-button-button,    .ag-rtl .ag-side-bar-right .ag-side-button-button':
        {
          borderRight: '2px solid transparent',
          transition: 'border-right 0.3s',
        },
      '.ag-ltr .ag-side-bar-left .ag-selected .ag-side-button-button,    .ag-rtl .ag-side-bar-right .ag-selected .ag-side-button-button':
        {
          borderRightColor: [
            '#2196f3',
            'var(--ag-selected-tab-underline-color, var(--ag-alpine-active-color, #2196f3))',
          ],
        },
      '.ag-rtl .ag-side-bar-left .ag-side-button-button,    .ag-ltr .ag-side-bar-right .ag-side-button-button':
        {
          borderLeft: '2px solid transparent',
          transition: 'border-left 0.3s',
        },
      '.ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,    .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button':
        {
          borderLeftColor: [
            '#2196f3',
            'var(--ag-selected-tab-underline-color, var(--ag-alpine-active-color, #2196f3))',
          ],
        },
      '.ag-filter-toolpanel-header': {height: '36px'},
      '.ag-rtl .ag-filter-toolpanel-header, .ag-rtl .ag-filter-toolpanel-search': {
        paddingRight: '6px',
      },
      '.ag-keyboard-focus .ag-filter-toolpanel-header:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-filter-toolpanel-header:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after': {
        fontFamily: '"agGridIcons"',
        fontSize: '16px',
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        content: '"\\f114"',
        position: 'absolute',
      },
      '.ag-ltr .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after':
        {
          paddingLeft: '6px',
        },
      '.ag-rtl .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after':
        {
          paddingRight: '6px',
        },
      '.ag-filter-toolpanel-group-level-0-header': {
        height: '48px',
      },
      '.ag-filter-toolpanel-group-item': {
        marginTop: '3px',
        marginBottom: '3px',
      },
      '.ag-filter-toolpanel-search': {
        height: '57px',
      },
      '.ag-filter-toolpanel-search-input': {
        flexGrow: 1,
        height: '24px',
      },
      '.ag-ltr .ag-filter-toolpanel-search-input': {
        marginRight: '6px',
      },
      '.ag-rtl .ag-filter-toolpanel-search-input': {
        marginLeft: '6px',
      },
      '.ag-filter-toolpanel-group-level-0': {
        borderTop: 'solid 1px',
        borderTopColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
      },
      '.ag-rtl .ag-filter-toolpanel-expand, .ag-rtl .ag-filter-toolpanel-group-title-bar-icon': {
        marginLeft: '6px',
      },
      '.ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-1-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-2-header': {
        paddingLeft: '22px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-2-header': {
        paddingRight: '22px',
      },
      '.ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-2-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-3-header': {
        paddingLeft: '38px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-3-header': {
        paddingRight: '38px',
      },
      '.ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-3-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-4-header': {
        paddingLeft: '54px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-4-header': {
        paddingRight: '54px',
      },
      '.ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-4-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-5-header': {
        paddingLeft: '70px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-5-header': {
        paddingRight: '70px',
      },
      '.ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-5-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-6-header': {
        paddingLeft: '86px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-6-header': {
        paddingRight: '86px',
      },
      '.ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-6-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-7-header': {
        paddingLeft: '102px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-7-header': {
        paddingRight: '102px',
      },
      '.ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-7-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-8-header': {
        paddingLeft: '118px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-8-header': {
        paddingRight: '118px',
      },
      '.ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-8-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-9-header': {
        paddingLeft: '134px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-9-header': {
        paddingRight: '134px',
      },
      '.ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-9-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-10-header': {
        paddingLeft: '150px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-10-header': {
        paddingRight: '150px',
      },
      '.ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-10-header.ag-filter-toolpanel-group-title-bar':
        {
          backgroundColor: 'transparent',
        },
      '.ag-ltr .ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-11-header': {
        paddingLeft: '166px',
      },
      '.ag-rtl .ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-11-header': {
        paddingRight: '166px',
      },
      '.ag-filter-toolpanel-instance-filter': {
        padding: '16px 16px 16px 56px',
        backgroundColor: ['#FAFBFC', 'var(--ag-control-panel-backgroundColor, #FAFBFC)'],
        borderTop: 'solid 1px',
        borderTopColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        borderBottom: 'solid 1px',
        borderBottomColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        border: 'none',
        borderLeftColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-ltr .ag-filter-toolpanel-instance-header-icon': {
        marginLeft: '6px',
      },
      '.ag-rtl .ag-filter-toolpanel-instance-header-icon': {
        marginRight: '6px',
      },
      '.ag-pivot-mode-panel': {height: '44px', display: 'flex'},
      '.ag-pivot-mode-select': {
        display: 'flex',
        alignItems: 'center',
      },
      '.ag-ltr .ag-pivot-mode-select': {marginLeft: '16px'},
      '.ag-rtl .ag-pivot-mode-select': {marginRight: '12px'},
      '.ag-keyboard-focus .ag-column-select-header:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-column-select-header:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-column-select-header': {
        height: '56px',
        alignItems: 'center',
        padding: '0 16px',
        borderBottom: 'solid 1px',
        borderBottomColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
      },
      '.ag-column-panel-column-select': {
        borderBottom: 'solid 1px',
        borderBottomColor: 'gray.200',
      },
      '.ag-column-group-icons,  .ag-column-select-header-icon': {
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
      },
      '.ag-header': {
        borderBottom: 'solid 1px',
        borderBottomColor: 'gray.200',
        backgroundColor: '#FAFBFC',
      },
      '.ag-header-group-text, .ag-header-cell-text': {
        color: '#42526E',
        fontSize: 'sm',
        fontWeight: 'medium',
        width: '100%',
      },
      '.ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon': {
        marginLeft: '6px',
      },
      '.ag-rtl .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon': {
        marginRight: '6px',
      },
      '.ag-ltr .ag-header-cell.ag-right-aligned-header .ag-header-label-icon': {
        marginRight: '6px',
      },
      '.ag-rtl .ag-header-cell.ag-right-aligned-header .ag-header-label-icon': {
        marginLeft: '6px',
      },
      '.ag-right-aligned-header .ag-header-cell-text': {
        textAlign: 'right',
      },
      '.ag-header-cell,  .ag-header-group-cell': {
        paddingLeft: 2,
        paddingRight: 2,
      },
      '.ag-header-cell.ag-header-cell-moving,    .ag-header-group-cell.ag-header-cell-moving': {
        bg: 'white',
      },
      '.ag-keyboard-focus .ag-header-cell:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-header-cell:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-keyboard-focus .ag-header-group-cell:focus': {
        outline: 'none',
      },
      '.ag-keyboard-focus .ag-header-group-cell:focus::after': {
        content: "''",
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        top: '4px',
        left: '4px',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        border: '1px solid',
        borderColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      '.ag-header-icon': {
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
        minWidth: '24px',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '.ag-header-expand-icon': {cursor: 'pointer'},
      '.ag-ltr .ag-header-expand-icon': {paddingLeft: '4px'},
      '.ag-rtl .ag-header-expand-icon': {paddingRight: '4px'},
      '.ag-header-row.ag-header-row-column-group': {
        boxSizing: 'content-box',
        borderBottom: 'solid 1px',
        borderBottomColor: 'gray.200',
      },
      '.ag-header-cell-resize': {
        display: 'flex',
        width: '20px',
        right: '-8px',
      },
      '.ag-header-cell .ag-header-cell-resize::after, .ag-header-group-cell .ag-header-cell-resize::after':
        {
          display: 'block',
          content: "''",
          height: '24px',
          width: '1px',
          backgroundColor: 'gray.200',
          position: 'absolute',
          right: '8px',
          transform: 'translate(0, -50%)',
          top: '50%',
        },
      '.ag-header-cell.ag-column-resizing .ag-header-cell-resize::after, .ag-header-group-cell.ag-column-resizing .ag-header-cell-resize::after':
        {
          backgroundColor: 'blue.200',
        },
      '.ag-ltr .ag-header-select-all': {marginRight: '18px'},
      '.ag-rtl .ag-header-select-all': {marginLeft: '18px'},
      '.ag-ltr .ag-floating-filter-button': {marginLeft: '18px'},
      '.ag-rtl .ag-floating-filter-button': {
        marginRight: '18px',
      },
      '.ag-floating-filter-button-button': {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none',
        background: 'transparent',
        border: 'none',
        height: '16px',
        padding: '0',
        width: '16px',
      },
      '.ag-filter-loading': {
        backgroundColor: ['#f8f8f8', 'var(--ag-control-panel-backgroundColor, #f8f8f8)'],
        height: '100%',
        padding: '12px 12px',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
      },
      '.ag-paging-panel': {
        display: 'none',
      },
      '.ag-status-bar': {
        borderTop: 'solid 1px',
        borderTopColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        color: [
          'rgba(24, 29, 31, 0.5)',
          'var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5))',
        ],
        paddingRight: '24px',
        paddingLeft: '24px',
        lineHeight: '20px',
        fontWeight: 'normal',
      },
      '.ag-status-name-value-value': {
        color: ['#181d1f', 'var(--ag-foreground-color, #181d1f)'],
        fontWeight: 700,
      },
      '.ag-status-bar-center': {textAlign: 'center'},
      '.ag-status-name-value': {
        marginLeft: '8px',
        marginRight: '8px',
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      '.ag-column-drop-cell': {
        background: [
          'rgba(24, 29, 31, 0.07)',
          'var(--ag-chip-backgroundColor, rgba(24, 29, 31, 0.07))',
        ],
        borderRadius: '24px',
        height: '24px',
        padding: '0 3px',
      },
      '.ag-column-drop-cell-text': {margin: '0 6px'},
      '.ag-column-drop-cell-button': {
        minWidth: '24px',
        margin: '0 3px',
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
        opacity: 0.5,
      },
      '.ag-column-drop-cell-drag-handle': {marginLeft: '12px'},
      '.ag-column-drop-horizontal': {
        backgroundColor: ['#f8f8f8', 'var(--ag-control-panel-backgroundColor, #f8f8f8)'],
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
        borderBottom: 'solid 1px',
        borderBottomColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-ltr .ag-column-drop-horizontal': {
        paddingLeft: '18px',
      },
      '.ag-rtl .ag-column-drop-horizontal': {
        paddingRight: '18px',
      },
      '.ag-ltr .ag-column-drop-horizontal-half-width:not(:last-of-type)': {
        borderRight: 'solid 1px',
        borderRightColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-rtl .ag-column-drop-horizontal-half-width:not(:last-of-type)': {
        borderLeft: 'solid 1px',
        borderLeftColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-column-drop-horizontal-cell-separator': {
        margin: '0 6px',
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
      },
      '.ag-column-drop-horizontal-empty-message': {
        color: [
          'rgba(24, 29, 31, 0.5)',
          'var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5))',
        ],
      },
      '.ag-ltr .ag-column-drop-horizontal-icon': {
        marginRight: '18px',
      },
      '.ag-rtl .ag-column-drop-horizontal-icon': {
        marginLeft: '18px',
      },
      '.ag-column-drop-list.ag-column-drop-vertical-list': {
        height: '44px',
        padding: '8px 16px 16px 16px',
      },
      '.ag-column-drop-vertical-cell': {marginTop: '6px'},
      '.ag-column-drop-vertical': {
        minHeight: '75px',
        maxHeight: '175px',
        borderBottom: 'solid 1px',
        borderBottomColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
      },
      '.ag-column-drop-vertical.ag-last-column-drop': {
        borderBottom: 'none',
      },
      '.ag-column-drop-vertical-icon': {
        display: 'none',
      },
      '.ag-column-drop-vertical-empty-message': {
        backgroundColor: '#FAFBFC',
        overflow: 'hidden',
        color: [
          'rgba(24, 29, 31, 0.5)',
          'var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5))',
        ],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'dashed 1px',
        borderColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        padding: '12px',
      },
      '.ag-select-agg-func-popup': {
        border: 'solid 1px',
        borderColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        background: [
          '#fff',
          'var(--ag-backgroundColor, #fff)',
          '#fff',
          'var(--ag-backgroundColor, #fff)',
        ],
        borderRadius: '3px',
        boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
        padding: ['6px', '0'],
        height: '105px',
      },
      '.ag-select-agg-func-virtual-list-item': {
        cursor: 'default',
        lineHeight: '30px',
        paddingLeft: '12px',
      },
      '.ag-select-agg-func-virtual-list-item:hover': {
        backgroundColor: [
          'rgba(33, 150, 243, 0.3)',
          'var(--ag-selected-row-backgroundColor, rgba(33, 150, 243, 0.3))',
        ],
      },
      '.ag-chart-menu': {
        borderRadius: '3px',
        background: ['#fff', 'var(--ag-backgroundColor, #fff)'],
      },
      '.ag-chart-menu-icon': {
        opacity: 0.5,
        lineHeight: '24px',
        fontSize: '24px',
        width: '24px',
        height: '24px',
        margin: '2px 0',
        cursor: 'pointer',
        borderRadius: '3px',
        color: [
          '#181d1f',
          'var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))',
        ],
      },
      '.ag-chart-menu-icon:hover': {opacity: 1},
      '.ag-chart-mini-thumbnail': {
        border: '1px solid',
        borderColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
        borderRadius: '5px',
        margin: '5px',
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
      },
      '.ag-chart-mini-thumbnail:nth-last-of-type(3),    .ag-chart-mini-thumbnail:nth-last-of-type(3) ~ .ag-chart-mini-thumbnail':
        {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      '.ag-ltr .ag-chart-mini-thumbnail:first-of-type': {
        marginLeft: '0',
      },
      '.ag-rtl .ag-chart-mini-thumbnail:first-of-type': {
        marginRight: '0',
      },
      '.ag-ltr .ag-chart-mini-thumbnail:last-of-type': {
        marginRight: '0',
      },
      '.ag-rtl .ag-chart-mini-thumbnail:last-of-type': {
        marginLeft: '0',
      },
      '.ag-chart-mini-thumbnail.ag-selected': {
        borderColor: [
          '#2196f3',
          'var(--ag-minichart-selected-chart-color, var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3)))',
        ],
      },
      '.ag-chart-settings-card-item': {
        background: ['#181d1f', 'var(--ag-foreground-color, #181d1f)'],
        width: '8px',
        height: '8px',
        borderRadius: '4px',
      },
      '.ag-chart-settings-card-item.ag-selected': {
        backgroundColor: [
          '#2196f3',
          'var(--ag-minichart-selected-page-color, var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3)))',
        ],
      },
      '.ag-chart-data-column-drag-handle': {marginLeft: '6px'},
      '.ag-charts-settings-group-title-bar,  .ag-charts-data-group-title-bar,  .ag-charts-format-top-level-group-title-bar':
        {
          borderTop: 'solid 1px',
          borderTopColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
          padding: '6px 12px',
          lineHeight: '20px',
        },
      '.ag-charts-settings-group-container': {padding: '6px'},
      '.ag-charts-data-group-container': {
        padding: '12px 12px',
        paddingBottom: '3px',
      },
      '.ag-charts-data-group-container > *': {
        marginBottom: '9px',
      },
      '.ag-charts-format-top-level-group-container': {
        marginLeft: '12px',
        padding: '6px',
      },
      '.ag-charts-format-top-level-group-item': {
        margin: '6px 0',
      },
      '.ag-charts-format-sub-level-group-container': {
        padding: '12px 12px',
        paddingBottom: '0',
      },
      '.ag-charts-format-sub-level-group-container > *': {
        marginBottom: '9px',
      },
      '.ag-charts-group-container.ag-group-container-horizontal': {
        padding: '6px',
      },
      '.ag-chart-data-section,  .ag-chart-format-section': {
        display: 'flex',
        margin: '0',
      },
      '.ag-chart-menu-panel': {
        backgroundColor: ['#f8f8f8', 'var(--ag-control-panel-backgroundColor, #f8f8f8)'],
      },
      '.ag-ltr .ag-chart-menu-panel': {
        borderLeft: 'solid 1px',
        borderLeftColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-rtl .ag-chart-menu-panel': {
        borderRight: 'solid 1px',
        borderRightColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
      },
      '.ag-date-time-list-page-title': {
        flexGrow: 1,
        textAlign: 'center',
      },
      '.ag-date-time-list-page-column-label': {
        textAlign: 'center',
      },
      '.ag-date-time-list-page-entry': {textAlign: 'center'},
      '.ag-checkbox-input-wrapper': {
        fontFamily: '"agGridIcons"',
        fontSize: '16px',
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        width: '16px',
        height: '16px',
        backgroundColor: [
          '#fff',
          'var(--ag-checkbox-backgroundColor, var(--ag-backgroundColor, #fff))',
        ],
        borderRadius: '3px',
        display: 'inline-block',
        verticalAlign: 'middle',
        flex: 'none',
      },
      '.ag-checkbox-input-wrapper input, .ag-checkbox-input-wrapper input': {
        WebkitAppearance: 'none',
        opacity: 0,
        width: '100%',
        height: '100%',
      },
      '.ag-checkbox-input-wrapper:focus-within, .ag-checkbox-input-wrapper:active': {
        outline: 'none',
        boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
      },
      '.ag-checkbox-input-wrapper.ag-disabled': {opacity: 0.5},
      '.ag-checkbox-input-wrapper::after': {
        content: '"\\f108"',
        color: ['#999', 'var(--ag-checkbox-unchecked-color, #999)'],
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none',
      },
      '.ag-checkbox-input-wrapper.ag-checked::after': {
        content: '"\\f106"',
        color: [
          '#2196f3',
          'var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))',
        ],
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none',
      },
      '.ag-checkbox-input-wrapper.ag-indeterminate::after': {
        content: '"\\f107"',
        color: [
          '#999',
          'var(--ag-checkbox-indeterminate-color, var(--ag-checkbox-unchecked-color, #999))',
        ],
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none',
      },
      '.ag-toggle-button-input-wrapper': {
        boxSizing: 'border-box',
        width: '28px',
        height: '18px',
        backgroundColor: [
          '#999',
          'var(--ag-toggle-button-off-backgroundColor, var(--ag-checkbox-unchecked-color, #999))',
        ],
        borderRadius: '9px',
        position: 'relative',
        flex: 'none',
        border: '1px solid',
        borderColor: [
          '#999',
          'var(--ag-toggle-button-off-border-color, var(--ag-checkbox-unchecked-color, #999))',
        ],
      },
      '.ag-toggle-button-input-wrapper input': {
        opacity: 0,
        height: '100%',
        width: '100%',
      },
      '.ag-toggle-button-input-wrapper:focus-within': {
        outline: 'none',
        boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
      },
      '.ag-toggle-button-input-wrapper.ag-disabled': {
        opacity: 0.5,
      },
      '.ag-toggle-button-input-wrapper.ag-checked': {
        backgroundColor: [
          '#2196f3',
          'var(--ag-toggle-button-on-backgroundColor, var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3)))',
        ],
        borderColor: [
          '#2196f3',
          'var(--ag-toggle-button-on-border-color, var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3)))',
        ],
      },
      '.ag-toggle-button-input-wrapper::before': {
        content: "' '",
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        display: 'block',
        boxSizing: 'border-box',
        height: '18px',
        width: '18px',
        backgroundColor: [
          '#fff',
          'var(--ag-toggle-button-switch-backgroundColor, var(--ag-backgroundColor, #fff))',
        ],
        borderRadius: '9px',
        transition: 'left 100ms',
        border: '1px solid',
        borderColor: [
          '#999',
          'var(--ag-toggle-button-switch-border-color, var(--ag-toggle-button-off-border-color, var(--ag-checkbox-unchecked-color, #999)))',
        ],
      },
      '.ag-toggle-button-input-wrapper.ag-checked::before': {
        left: 'calc(100% - 18px)',
        borderColor: [
          '#2196f3',
          'var(--ag-toggle-button-on-border-color, var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3)))',
        ],
      },
      '.ag-radio-button-input-wrapper': {
        fontFamily: '"agGridIcons"',
        fontSize: '16px',
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        width: '16px',
        height: '16px',
        backgroundColor: [
          '#fff',
          'var(--ag-checkbox-backgroundColor, var(--ag-backgroundColor, #fff))',
        ],
        borderRadius: ['3px', '16px'],
        display: 'inline-block',
        verticalAlign: 'middle',
        flex: 'none',
      },
      '.ag-radio-button-input-wrapper input, .ag-radio-button-input-wrapper input': {
        WebkitAppearance: 'none',
        opacity: 0,
        width: '100%',
        height: '100%',
      },
      '.ag-radio-button-input-wrapper:focus-within, .ag-radio-button-input-wrapper:active': {
        outline: 'none',
        boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
      },
      '.ag-radio-button-input-wrapper.ag-disabled': {
        opacity: 0.5,
      },
      '.ag-radio-button-input-wrapper::after': {
        content: '"\\f126"',
        color: ['#999', 'var(--ag-checkbox-unchecked-color, #999)'],
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none',
      },
      '.ag-radio-button-input-wrapper.ag-checked::after': {
        content: '"\\f127"',
        color: [
          '#2196f3',
          'var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))',
        ],
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none',
      },
      "input[class^='ag-'][type='range']::-webkit-slider-runnable-track": {
        margin: '0',
        padding: '0',
        width: '100%',
        height: '3px',
        backgroundColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        borderRadius: ['3px', '3px'],
      },
      "input[class^='ag-'][type='range']::-moz-range-track": {
        margin: '0',
        padding: '0',
        width: '100%',
        height: '3px',
        backgroundColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        borderRadius: ['3px', '3px'],
      },
      "input[class^='ag-'][type='range']::-ms-track": {
        margin: '0',
        padding: '0',
        width: ['100%', 'calc(100% - 2px)'],
        height: '3px',
        backgroundColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        borderRadius: ['3px', '3px'],
        color: 'transparent',
      },
      "input[class^='ag-'][type='range']::-webkit-slider-thumb": {
        margin: '0',
        padding: '0',
        WebkitAppearance: 'none',
        width: '16px',
        height: '16px',
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        border: '1px solid',
        borderColor: ['#999', 'var(--ag-checkbox-unchecked-color, #999)'],
        borderRadius: '16px',
        WebkitTransform: 'translateY(-6.5px)',
        transform: 'translateY(-6.5px)',
      },
      "input[class^='ag-'][type='range']::-ms-thumb": {
        margin: '0',
        padding: '0',
        WebkitAppearance: 'none',
        width: '16px',
        height: '16px',
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        border: '1px solid',
        borderColor: ['#999', 'var(--ag-checkbox-unchecked-color, #999)'],
        borderRadius: '16px',
      },
      "input[class^='ag-'][type='range']::-moz-ag-range-thumb": {
        margin: '0',
        padding: '0',
        WebkitAppearance: 'none',
        width: '16px',
        height: '16px',
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        border: '1px solid',
        borderColor: ['#999', 'var(--ag-checkbox-unchecked-color, #999)'],
        borderRadius: '16px',
      },
      "input[class^='ag-'][type='range']:focus": {
        outline: 'none',
      },
      "input[class^='ag-'][type='range']:focus::-webkit-slider-thumb": {
        boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
        borderColor: [
          '#2196f3',
          'var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))',
        ],
      },
      "input[class^='ag-'][type='range']:focus::-ms-thumb": {
        boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
        borderColor: [
          '#2196f3',
          'var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))',
        ],
      },
      "input[class^='ag-'][type='range']:focus::-moz-ag-range-thumb": {
        boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
        borderColor: [
          '#2196f3',
          'var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))',
        ],
      },
      "input[class^='ag-'][type='range']:active::-webkit-slider-runnable-track": {
        backgroundColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      "input[class^='ag-'][type='range']:active::-moz-ag-range-track": {
        backgroundColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      "input[class^='ag-'][type='range']:active::-ms-track": {
        backgroundColor: [
          'rgba(33, 150, 243, 0.4)',
          'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
        ],
      },
      "input[class^='ag-'][type='range']:disabled": {
        opacity: 0.5,
      },
      '.ag-filter-toolpanel-header,  .ag-filter-toolpanel-search,  .ag-status-bar,  .ag-header-row,  .ag-panel-title-bar-title,  .ag-multi-filter-group-title-bar':
        {
          fontWeight: 700,
          color: [
            '#181d1f',
            'var(--ag-header-foreground-color, var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f)))',
          ],
        },
      '.ag-rtl .ag-pinned-left-header .ag-header-row::before': {
        left: '0',
      },
      ".ag-ltr input[class^='ag-']:not([type]),    .ag-ltr input[class^='ag-'][type='text'],    .ag-ltr input[class^='ag-'][type='number'],    .ag-ltr input[class^='ag-'][type='tel'],    .ag-ltr input[class^='ag-'][type='date'],    .ag-ltr input[class^='ag-'][type='datetime-local'],    .ag-ltr textarea[class^='ag-']":
        {
          paddingLeft: '6px',
        },
      ".ag-rtl input[class^='ag-']:not([type]),    .ag-rtl input[class^='ag-'][type='text'],    .ag-rtl input[class^='ag-'][type='number'],    .ag-rtl input[class^='ag-'][type='tel'],    .ag-rtl input[class^='ag-'][type='date'],    .ag-rtl input[class^='ag-'][type='datetime-local'],    .ag-rtl textarea[class^='ag-']":
        {
          paddingRight: '6px',
        },
      '.ag-chart-settings-nav-bar': {
        borderTop: 'solid 1px',
        borderTopColor: ['#dde2eb', 'var(--ag-secondary-border-color, #dde2eb)'],
      },
      '.ag-ltr .ag-group-title-bar-icon': {
        margin: '0px 16px',
        height: '24px',
        width: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '.ag-rtl .ag-group-title-bar-icon': {marginLeft: '6px'},
      '.ag-charts-format-top-level-group-toolbar': {
        marginTop: '6px',
      },
      '.ag-ltr .ag-charts-format-top-level-group-toolbar': {
        paddingLeft: '20px',
      },
      '.ag-rtl .ag-charts-format-top-level-group-toolbar': {
        paddingRight: '20px',
      },
      '.ag-charts-format-sub-level-group': {
        borderLeft: 'dashed 1px',
        borderLeftColor: ['#babfc7', 'var(--ag-border-color, #babfc7)'],
        paddingLeft: '6px',
        marginBottom: '12px',
      },
      '.ag-charts-format-sub-level-group-title-bar': {
        paddingTop: '0',
        paddingBottom: '0',
        background: 'none',
        fontWeight: 700,
      },
      '.ag-charts-format-sub-level-group-item:last-of-type': {
        marginBottom: '0',
      },
      '.ag-standard-button': {
        MozAppearance: 'none',
        appearance: 'none',
        WebkitAppearance: 'none',
        borderRadius: '3px',
        border: '1px solid',
        borderColor: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
        color: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
        backgroundColor: ['#fff', 'var(--ag-backgroundColor, #fff)'],
        fontWeight: 600,
        padding: '6px 12px',
      },
      '.ag-standard-button:hover': {
        borderColor: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
        backgroundColor: [
          'rgba(33, 150, 243, 0.1)',
          'var(--ag-row-hover-color, rgba(33, 150, 243, 0.1))',
        ],
      },
      '.ag-standard-button:active': {
        borderColor: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
        backgroundColor: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
        color: ['#fff', 'var(--ag-backgroundColor, #fff)'],
      },
      '.ag-standard-button:disabled': {
        color: [
          'rgba(24, 29, 31, 0.5)',
          'var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5))',
        ],
        backgroundColor: ['#f1f2f4', 'var(--ag-input-disabled-backgroundColor, #f1f2f4)'],
        borderColor: [
          'rgba(186, 191, 199, 0.3)',
          'var(--ag-input-disabled-border-color, rgba(186, 191, 199, 0.3))',
        ],
      },
      '.ag-column-drop-vertical-title-bar': {
        padding: '12px',
        paddingBottom: '0px',
      },
      '.ag-column-drop-empty-message': {
        color: ['#181d1f', 'var(--ag-foreground-color, #181d1f)'],
        opacity: 0.75,
      },
      '.ag-column-drop-cell-button:hover': {opacity: 0.75},
      '.ag-header-cell-menu-button:hover,  .ag-side-button-button:hover,  .ag-tab:hover,  .ag-panel-title-bar-button:hover,  .ag-header-expand-icon:hover, .ag-group-expanded .ag-icon:hover,  .ag-group-contracted .ag-icon:hover,  .ag-chart-settings-prev:hover,  .ag-chart-settings-next:hover, .ag-floating-filter-button-button:hover, .ag-chart-menu-icon:hover':
        {
          color: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
        },
      '.ag-chart-settings-card-item.ag-not-selected:hover': {
        opacity: 0.35,
      },
      '.ag-filter-toolpanel-group-container': {
        paddingLeft: '6px',
      },
      '.ag-set-filter-list': {
        paddingTop: '3px',
        paddingBottom: '3px',
      },
      '.ag-date-time-list-page-entry-is-current': {
        backgroundColor: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
      },

      '.ag-pinned-left-header, .ag-pinned-left-cols-container': {
        borderRight: '1px solid',
        borderColor: 'gray.200',

        zIndex: 1,
      },

      '.ag-pinned-right-header, .ag-pinned-right-cols-container': {
        borderLeft: '1px solid',
        borderColor: 'gray.200',

        zIndex: 1,
      },

      '.ag-pinned-right-cols-container, .ag-pinned-left-cols-container': {
        backgroundColor: 'white',
        minHeight: '100%',
      },

      '.ag-filter-toolpanel': {
        display: 'flex',
        flexDirection: 'column',
      },
      '.ag-custom-settings-toolpanel': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },

      '.ag-panel-header': {
        order: -1,
        width: '100%',
      },
      '.ag-default-panel-header': {
        height: '57px',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        color: '#091E42',
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: '500',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderBottom: 'solid 1px',
        borderBottomColor: 'gray.200',
      },
      '.ag-panel-content': {
        width: '100%',
        padding: '16px',
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },

      '.ag-custom-overlay-wrapper': {
        height: '100%',
      },

      '.ag-header-group-cell-label': {
        fontSize: '14px',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
      },

      '.ag-header-cell': {
        overflow: 'visible',
      },
      '.ag-pinned-right-header .ag-header-cell-resize': {
        left: '-15px',
      },
      '.ag-pinned-left-cols-container .ag-row-loading .ag-loading, .ag-pinned-right-cols-container .ag-row-loading .ag-loading':
        {
          display: 'none',
        },
      '.ag-suppress-movable-col': {
        backgroundColor: '#FFFDE8',
      },
      '.ag-locked-col': {
        backgroundColor: '#F4F5F7',
      },
      '.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child': {
        backgroundColor: '#fff',
      },
      '.ag-menu.ag-ltr.ag-popup-child': {
        backgroundColor: 'white',
        marginLeft: '10px',
        marginTop: '-8px',
      },
      '.ag-menu-list': {
        display: 'flex',
        flexDir: 'column',
      },
      '.ag-menu-list.ag-focus-managed': {
        padding: '8px',
      },
      '.ag-menu-option': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '.ag-menu-option.ag-menu-option-active': {
        backgroundColor: 'blue.50',
        color: 'blue.800',
        borderRadius: '4px',
      },
      '.ag-filter-toolpanel-search .ag-input-field-input.ag-text-field-input,.ag-column-select-header .ag-input-field-input.ag-text-field-input':
        {
          height: '32px',
          backgroundColor: '#F4F5F7',
          borderRadius: '4px',
          border: 'none',
          paddingLeft: '8px !important',
        },
      '.ag-column-select-virtual-list-viewport': {
        padding: '14px 16px',
      },
      '.ag-virtual-list-item.ag-column-select-virtual-list-item': {
        margin: '0px',
        display: 'flex',
        alignItems: 'center',
      },
      '.ag-column-drop-title-bar.ag-column-drop-vertical-title-bar': {
        padding: '12px 16px 0px 15px',
      },
      '.ag-column-drop-title.ag-column-drop-vertical-title': {
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '16px',
        color: '#42526E',
      },
      '.ag-input-field-input.ag-text-field-input': {
        backgroundColor: '#fff',
        color: '#7A869A !important',
        borderRadius: '4px !important',
        height: '32px',
        padding: '6px 0px 6px 8px !important',
      },
      '.ag-wrapper.ag-picker-field-wrapper': {
        height: '32px',
      },
      '.ag-picker-field-icon': {
        padding: '8px',
      },
      '.ag-input-field-input.ag-number-field-input': {
        height: '32px',
        padding: '6px 0px 6px 8px',
      },
      '.ag-picker-field-display': {
        margin: '6px 0px 6px 8px',
      },
      '.ag-filter-toolpanel-search-input.ag-labeled.ag-label-align-left.ag-text-field.ag-input-field':
        {
          margin: '16px',
        },
      '.ag-group-title-bar.ag-filter-toolpanel-group-title-bar.ag-unselectable.ag-filter-toolpanel-group-level-0-header.ag-filter-toolpanel-header':
        {
          padding: '12px 0px',
        },
      '.ag-column-group-icons, .ag-column-select-header-icon, .ag-group-title-bar-icon, .ag-filter-toolpanel-expand':
        {
          height: '24px',
          width: '24px',
          marginLeft: '16px',
          marginRight: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          cursor: 'pointer',
          backgroundColor: 'gray.100',
          '&:hover': {},
          '&>*': {
            display: 'flex',
          },
        },
      '.ag-column-select-header-icon, .ag-column-group-icons': {
        marginLeft: 0,
      },
      '.ag-filter-toolpanel-search .ag-filter-toolpanel-expand': {
        marginRight: 0,
      },
      '.ag-row-action-hover': {
        cursor: 'pointer',
      },
      '.ag-tooltip-custom': {
        position: 'absolute',
        width: 'auto',
      },
      // hide horizontal scroll
      '.ag-root .ag-horizontal-left-spacer': {
        borderRight: '1px solid',
        borderColor: 'gray.200',
        overflow: 'hidden',
      },
      '.ag-root .ag-horizontal-right-spacer': {
        borderLeft: '1px solid',
        borderColor: 'gray.200',
        overflow: 'hidden',
      },
      '.ag-cell[col-id="eag-col-actions"], .ag-cell[col-id="eag-col-expander"]': {
        padding: '0px',
      },
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
