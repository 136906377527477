import {z} from 'zod';

import {BaseVoidRequestSchema, BaseVoidResponseSchema} from './base';

export const GetMinorUnitMaterialApiResponseSchema = z
  .object({
    orderVariantIds: z.array(z.string().nullable()).optional(),
    amountType: z
      .enum(['MINORUNITFROMMATERIALLABOUR', 'MINORUNITFROMLABOUR', 'MINORUNITFROMMATERIAL'])
      .nullable()
      .optional(),
    amountValue: z.number().nullable().optional(),
    isMaxValue: z.boolean().nullable().optional(),
    maxValue: z.number().nullable().optional(),
    mechanicId: z.string().nullable().optional(),
  })
  .nullable();

export type GetMinorUnitMaterialApiResponse = z.infer<typeof GetMinorUnitMaterialApiResponseSchema>;

export type GetMinorUnitMaterialApiArg = z.infer<typeof BaseVoidRequestSchema>;

export const PutMinorUnitMaterialApiArgSchema = z.object({
  body: z
    .object({
      orderVariantIds: z.array(z.string()),
      amountType: z.enum([
        'MINORUNITFROMMATERIALLABOUR',
        'MINORUNITFROMLABOUR',
        'MINORUNITFROMMATERIAL',
      ]),
      amountValue: z.number().nullable().optional(),
      isMaxValue: z.boolean(),
      maxValue: z.number().nullable().optional(),
      mechanicId: z.string().nullable().optional(),
    })
    .optional(),
});

export type PutMinorUnitMaterialApiArg = z.infer<typeof PutMinorUnitMaterialApiArgSchema>;

export const PutMinorUnitMaterialApiResponseSchema = BaseVoidResponseSchema;
export type PutMinorUnitMaterialApiResponse = z.infer<typeof PutMinorUnitMaterialApiResponseSchema>;
