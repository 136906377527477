import {Choice, showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import styled from 'styled-components';

import {FC, PropsWithChildren} from 'react';
import {useMemo} from 'react';

import {browserStorageKey} from '@omnetic-dms/config';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CommonSkeleton,
  FALLBACK_LANGUAGE,
  getWorkspaceFromUri,
  SetUserSettingsItemApiArg,
  useGetAvailableLanguagesListQuery,
  useSetUserSettingsItemMutation,
} from '@omnetic-dms/shared';
import {TypographyCard} from '@omnetic-dms/teas';

import {ProfileLayout} from '../../../components/ProfileLayout/ProfileLayout';
import {persistor} from '../../../store/store';

const {workspace} = getWorkspaceFromUri();

export const Language: FC<PropsWithChildren<any>> = () => {
  const {data, isLoading} = useGetAvailableLanguagesListQuery({workspace: workspace as string});

  const [updateUserSettings] = useSetUserSettingsItemMutation();

  const onLanguageChange = (value: string | number | string[] | null) => {
    if (typeof value !== 'string') {
      return;
    }

    const arg = {
      settingKey: 'selected_language',
      userSettingRequestBody: {value},
    } as SetUserSettingsItemApiArg;

    updateUserSettings(arg)
      .unwrap()
      .then(persistor.purge)
      .then(() => i18n.changeLanguage(value))
      .then(() => {
        localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, value);
        window.location.reload();
      })
      .catch(() => {
        showNotification.error(i18n.t('general.notifications.changesSavingFailed'));
      });
  };

  const languageOptions = useMemo(
    () =>
      data && data.languages
        ? Object.keys(data.languages).map((lng) => ({
            label: data.languages[lng],
            value: lng,
          }))
        : [],
    [data]
  );
  return (
    <ProfileLayout data-testid={testIds.myProfile.language('wrapper')}>
      <ProfileCard
        data-testid={testIds.myProfile.language('wrapper')}
        title={i18n.t('general.labels.language')}
      >
        {isLoading ? (
          <CommonSkeleton />
        ) : (
          <Box width="15.7rem">
            <Choice
              data-testid={testIds.myProfile.language('choice')}
              options={languageOptions}
              name="languages"
              defaultValue={
                i18n?.resolvedLanguage ||
                localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE] ||
                data?.default
              }
              value={i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE}
              onChange={onLanguageChange}
              placeholder={i18n.t('general.labels.selectLanguage')}
              isNotClearable
            />
          </Box>
        )}
      </ProfileCard>
    </ProfileLayout>
  );
};

const ProfileCard = styled(TypographyCard)`
  width: 583px;
  margin-bottom: ${({theme}) => theme.getSize(4)};
`;
