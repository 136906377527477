import {
  ButtonGroup,
  Card,
  Checkbox,
  DataStatus,
  IconButton,
  NumberInput,
  TextInput,
  Tooltip,
  openDeleteDialog,
} from 'platform/components';
import {Box, HStack, Heading, Space, VStack, Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNil, isNotNil, not} from 'ramda';

import {TestIdProps, getRandomId, suffixTestId, yupNumber} from 'shared';

import {useGetHandlingUnitQuery} from '../../../api/metadaWarehouseHandlingUnitApi';
import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useBasketItemQuantity} from '../../../hooks/useBasketItemQuantity';
import i18n from '../../../i18n/i18n';
import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {CorrectionBasketItem} from '../types/CorrectionBasketItem';
import {CorrectionBasketItemEditingDetails} from '../types/CorrectionBasketItemEditingDetails';

interface AfterSalesCorrectionBasketItemProps<T extends CorrectionBasketItem> extends TestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: CorrectionBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
}

export function AfterSalesCorrectionBasketItem<T extends CorrectionBasketItem>(
  props: AfterSalesCorrectionBasketItemProps<T>
) {
  const formatCurrency = useFormatCurrency();

  const {data: unit, isLoading: isUnitLoading} = useGetHandlingUnitQuery(
    {id: props.item.unit ?? ''},
    {skip: isNil(props.item.unit)}
  );

  const {quantity, quantityError, handleQuantityChange} = useBasketItemQuantity({
    itemId: props.item.id,
    currentQuantity: props.item.quantity,
    validationSchema: quantitySchema,
    onQuantityChange: props.onQuantityChange,
  });

  const totalPrice = formatCurrency(
    props.item.totalPrice.withoutVat.amount ?? 0,
    props.item.totalPrice.withoutVat.currency ?? DEFAULT_CURRENCY,
    2
  );

  const tooltip = props.item.tooltip
    ? props.item.tooltip.map((item, index) => (
        <>
          {index > 0 && <Space vertical={2} />}
          <Text size="xSmall" color="white">
            {item.label}
          </Text>
          <Text size="xSmall" color="white">
            {item.value}
          </Text>
        </>
      ))
    : null;

  const handleEditItem = () => {
    if (isNil(props.item.id)) {
      throw new Error("Missing item's id");
    }

    props.onEdit({
      itemId: props.item.id,
      itemName: props.item.name,
    });
  };

  const handleDeleteItem = () => {
    openDeleteDialog({
      name: props.item.name,
      onConfirm: () => props.onDelete([props.item.id]),
      'data-testid': suffixTestId('delete', props),
    });
  };

  return (
    <Card variant="inlineGrey">
      <DataStatus isLoading={isUnitLoading} minHeight={25}>
        <VStack spacing={1}>
          <HStack justify="space-between">
            <HStack spacing={2}>
              <Checkbox
                value={props.item.isSelected}
                onChange={(isSelected) => props.onSelect(props.item.id, isSelected)}
                data-testid={suffixTestId('isSelected', props)}
              />
              <Heading size={4} data-testid={suffixTestId('number', props)}>
                {props.item?.number ?? props.item?.manufacturerNumber}
              </Heading>
            </HStack>
            <Box>
              <ButtonGroup>
                {tooltip && (
                  <Tooltip
                    key={`basketItemTooltip-${getRandomId()}`}
                    placement="top"
                    description={tooltip}
                  >
                    <IconButton
                      icon="action/info_outline"
                      size="small"
                      priority="secondary"
                      data-testid={suffixTestId('tooltip', props)}
                    />
                  </Tooltip>
                )}

                <IconButton
                  icon="image/edit"
                  size="small"
                  priority="secondary"
                  isDisabled={not(props.item.itemEditingAllowed)}
                  onClick={handleEditItem}
                  data-testid={suffixTestId('edit', props)}
                />
                <IconButton
                  icon="navigation/close"
                  size="small"
                  priority="primary"
                  severity="danger"
                  onClick={handleDeleteItem}
                  data-testid={suffixTestId('delete', props)}
                />
              </ButtonGroup>
            </Box>
          </HStack>

          <HStack spacing={1} align="center">
            <Text size="xSmall" color="tertiary" data-testid={suffixTestId('name', props)}>
              {props.item.name}
            </Text>
          </HStack>

          <Space vertical={2} />

          <HStack justify="space-between">
            <HStack spacing={2}>
              <Box width={29}>
                <NumberInput
                  value={quantity}
                  errorMessage={quantityError}
                  decimalPlaces={4}
                  onChange={handleQuantityChange}
                  isStepperVisible
                  isInvalid={isNotNil(quantityError)}
                  isDisabled={not(props.item.quantityEditingAllowed)}
                  data-testid={suffixTestId('quantity', props)}
                />
              </Box>
              <Box width={11}>
                <TextInput
                  value={unit?.name ?? 'pcs'}
                  isDisabled
                  data-testid={suffixTestId('unit', props)}
                />
              </Box>
            </HStack>

            <VStack align="flex-end">
              <Text size="xSmall" color="tertiary">
                {i18n.t('general.labels.sellingPrice')}
              </Text>
              <Text size="xSmall" alternative data-testid={suffixTestId('price', props)}>
                {totalPrice}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </DataStatus>
    </Card>
  );
}

const quantitySchema = yupNumber.min(0.0001, (params) =>
  i18n.t('general.errors.number.greaterOrEqual', {min: params.min})
);
