import {Button, Card, DataStatus} from 'platform/components';
import {HStack} from 'platform/foundation';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {any, defaultTo, head, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {queryParams, DigitalCertificateLink} from '@omnetic-dms/shared';
import {
  selectAudit,
  loadVehicleDetailsData,
  LoadAuditDataResponseItemBody,
  useThunkDispatch,
  getAudit,
  updateAuditData,
  Audit,
  selectCarAudit,
  getAuditStructure,
} from '@omnetic-dms/teas';

import {TestIdProps, suffixTestId, useQueryState, Nullish} from 'shared';

interface InspectionProps extends TestIdProps {
  vehicleId: string;
  inspectionId: string;
  isCompletionDisabled: boolean;
  isError: boolean;
  onSendEmailSmsNotification: () => void;
}

export function Inspection(props: InspectionProps) {
  const [activeInspectionTab, setActiveInspectionTab] = useQueryState(
    queryParams.SERVICE_CASE_INSPECTION
  );

  const dispatch = useThunkDispatch();

  const audit = useSelector(selectAudit);
  const {loading} = useSelector(selectCarAudit);

  const isAuditLoading = any(isTrue, [
    loading.getAudit,
    loading.getAuditStructure,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    const fetchAuditStructure = (auditStructure: LoadAuditDataResponseItemBody | Nullish) => {
      if (isNotNil(auditStructure?.controlStructureId)) {
        dispatch(getAuditStructure.action({auditStructureId: auditStructure?.controlStructureId}));
      }
    };

    if (isNotNil(props.vehicleId) && isNotNil(props.inspectionId) && !isAuditLoading) {
      loadVehicleDetailsData(props.vehicleId, dispatch).then(() => {
        dispatch(
          getAudit.action({
            auditId: props.inspectionId,
          })
        )
          .unwrap()
          .then((response) => {
            if (isNotNil(response)) {
              fetchAuditStructure(head(response));
            }
          });
      });
    }
  }, [props.vehicleId, props.inspectionId]);

  const handleInspectionTabChange = (id: string) => setActiveInspectionTab(id);

  const isAuditFinished = audit?.state === LoadAuditDataResponseItemBody.state.FINISHED_AUDIT;

  useEffect(
    () =>
      // Remove audit when component unmounts
      () => {
        dispatch(updateAuditData({auditData: undefined}));
      },
    [dispatch]
  );

  return (
    <Card title={i18n.t('page.Inspection.labels.title')}>
      <DataStatus isLoading={isAuditLoading} isError={props.isError}>
        <Audit
          activeTab={defaultTo(undefined, activeInspectionTab)}
          onTabChange={handleInspectionTabChange}
          isInspection
          isBodyOnly
          isCompletionDisabled={props.isCompletionDisabled}
          customHeaderContent={
            <HStack spacing={2}>
              <Button
                title={i18n.t('entity.order.actions.sendEmailSms')}
                variant="outlined"
                isDisabled={!isAuditFinished}
                onClick={props.onSendEmailSmsNotification}
                data-testid={suffixTestId('sendEmailSms', props)}
              />
              <DigitalCertificateLink
                inspectionId={props.inspectionId}
                isDisabled={!isAuditFinished}
              />
            </HStack>
          }
          data-testid={props['data-testid']}
        />
      </DataStatus>
    </Card>
  );
}
