import {ActionCallback, DataGrid} from 'platform/datagrid';

import {isString} from 'ramda-adjunct';

import {warehouseRoutes} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

export function DirectSalesOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    switch (actionKey) {
      case 'redirectDetail':
        if ('id' in rowData && isString(rowData.id)) {
          navigate(
            composePath(warehouseRoutes.directSalesDetailOverview, {params: {id: rowData.id}})
          );
        }
    }
  };

  return (
    <DataGrid
      gridCode="direct-sale"
      actionCallback={actionCallback}
      data-testid="directSaleDatagrid"
    />
  );
}
