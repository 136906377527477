import {Separator} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  BaseAuthorizationProfile,
  BaseSupplier,
  GetDeliveryNoteResponse,
  GetWarehousesResponse,
  PaymentTypeResponseBody,
  ReadOnlyField,
} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, Nullish, parseDate, Unpacked} from 'shared';

interface BasicInformationReadOnlyProps {
  deliveryNote: GetDeliveryNoteResponse | Nullish;
  supplier: BaseSupplier | Nullish;
  warehouse: Unpacked<GetWarehousesResponse> | Nullish;
  paymentType: Unpacked<PaymentTypeResponseBody[]> | Nullish;
  authorizationProfile: Unpacked<BaseAuthorizationProfile[]> | Nullish;
}

export function BasicInformationReadOnly(props: BasicInformationReadOnlyProps) {
  const dateTimeFormatter = useDateTimeFormatter();

  const formatToShortDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  return (
    <VStack spacing={4}>
      <Grid columns={4}>
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.supplier')}
          value={props.supplier?.name}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.warehouse')}
          value={props.warehouse?.name}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.authorizationProfile')}
          value={props.authorizationProfile?.name}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.invoiceNumber')}
          value={props.deliveryNote?.invoiceNumber}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.paymentType')}
          value={props.paymentType?.value}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.currency')}
          value={props.deliveryNote?.currency}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.exchangeRate')}
          value={props.deliveryNote?.exchangeRate}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.ncConversion')}
          value={props.deliveryNote?.ncConversion}
        />
      </Grid>

      <Separator />

      <Grid columns={4}>
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.issuedDate')}
          value={formatToShortDate(props.deliveryNote?.issuedAt)}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.createdDate')}
          value={formatToShortDate(props.deliveryNote?.created)}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.updatedDate')}
          value={formatToShortDate(props.deliveryNote?.updated)}
        />
      </Grid>
    </VStack>
  );
}
