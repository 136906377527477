import {Box, BoxProps, HStack, Space, VStack} from 'platform/foundation';

import {useMemo} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {isTrue} from 'ramda-adjunct';

import {CommonSkeleton, MenuItemProps, useGetUserMenuPinsQuery} from '@omnetic-dms/shared';

import {moveItemToLast} from 'shared';

import {Hamburger} from './components/Hamburger';
import {Header} from './components/Header';
import {Menu} from './components/Menu';
import {isMenuItemActive} from './utils/isMenuItemActive';

interface LayoutProps {
  menuItems: MenuItemProps[];
  createMenuItems: MenuItemProps[];
}

const borderProps: BoxProps = {borderColor: 'general.separator', borderBottom: '1px solid'};

export function Layout(props: LayoutProps) {
  const location = useLocation();

  const {data: pinnedMenuItems, isLoading} = useGetUserMenuPinsQuery();

  const moduleRoot = useMemo(
    () => props.menuItems.find((menuItem) => isMenuItemActive(location.pathname, menuItem.path)),
    [location.pathname, props.menuItems]
  );

  const menuItems = moveItemToLast<MenuItemProps>(
    (item) => isTrue(item.layoutProps?.isLastMenuItem),
    props.menuItems ?? []
  );

  if (isLoading) {
    return <CommonSkeleton />;
  }

  return (
    <VStack height="100%" width="100%" data-testid="layout">
      <Box
        position="fixed"
        width="100%"
        top={0}
        contain="layout"
        zIndex="MAIN_MENU"
        backgroundColor="general.white"
        data-testid="layout-headerWrapper"
      >
        <HStack width="100%">
          <Hamburger menuItems={menuItems} pinnedMenuItems={pinnedMenuItems} />

          <Box {...borderProps}>
            <Space horizontal={4} />
          </Box>

          <Box flex={1} {...borderProps}>
            <Header module={moduleRoot} data-testid="header" />
          </Box>
        </HStack>
      </Box>
      <Box
        position="fixed"
        width={12}
        top={12}
        left={0}
        bottom={0}
        contain="layout"
        zIndex="MAIN_MENU"
        backgroundColor="palettes.neutral.10.100"
        data-testid="layout-menuWrapper"
      >
        <Menu
          menuItems={menuItems}
          createMenuItems={props.createMenuItems}
          pinnedMenuItems={pinnedMenuItems}
          data-testid="layout-menu"
        />
      </Box>
      <Box
        position="fixed"
        top={12}
        left={12}
        right={0}
        bottom={0}
        overflowY="auto"
        backgroundColor="palettes.neutral.10.100"
        data-testid="layout-content"
      >
        <Outlet />
      </Box>
    </VStack>
  );
}
