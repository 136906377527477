import {match, Pattern} from 'ts-pattern';

import {ReactNode} from 'react';

import {always, has, not} from 'ramda';
import {isFalse, isNull, isTruthy, isUndefined} from 'ramda-adjunct';

import {isFeatureEnabled} from 'shared';

import {useDevice} from '../DeviceProvider/useDevice';

interface ShowProps {
  when?: unknown;
  whenFeatureEnabled?: string;
  whenFeatureDisabled?: string;
  children: ReactNode | ReactNode[];
  onMobile?: boolean;
  onTablet?: boolean;
  onNotebook?: boolean;
  onDesktop?: boolean;
  inPrinter?: boolean;
}

export function Show(props: ShowProps) {
  const hasWhen = has('when', props);
  const showOnMobile = isTruthy(props.onMobile);
  const showOnTablet = isTruthy(props.onTablet);
  const showOnNotebook = isTruthy(props.onNotebook);
  const showOnDesktop = isTruthy(props.onDesktop);
  const showInPrinter = isTruthy(props.inPrinter);
  const isResponsive =
    showOnMobile || showOnTablet || showOnNotebook || showOnDesktop || showInPrinter;

  const device = useDevice();

  const isVisible = match([isResponsive, device, props])
    .with([false, Pattern.any, Pattern.any], always(true))
    .with([true, 'mobile', {onMobile: true}], always(true))
    .with([true, 'tablet', {onTablet: true}], always(true))
    .with([true, 'notebook', {onNotebook: true}], always(true))
    .with([true, 'desktop', {onDesktop: true}], always(true))
    .with([true, 'printer', {inPrinter: true}], always(true))
    .otherwise(always(false));

  if (not(isVisible)) {
    return null;
  }

  if (isNull(props.when) || (hasWhen && isUndefined(props.when)) || isFalse(props.when)) {
    return null;
  }

  if (props.whenFeatureEnabled && !isFeatureEnabled(props.whenFeatureEnabled)) {
    return null;
  }
  if (props.whenFeatureDisabled && isFeatureEnabled(props.whenFeatureDisabled)) {
    return null;
  }

  return <>{props.children}</>;
}
