import {
  GetNoteApiResponse,
  GetNoteApiArg,
  UpdateNoteApiResponse,
  UpdateNoteApiArg,
} from '../types/api/api';
import {omneticApi} from './baseApi/omneticApi';

export const noteApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getNote: build.query<GetNoteApiResponse, GetNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/note/${queryArg.resourceId}/${queryArg.recordId}/${queryArg.context}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'note', id: queryArg.resourceId + queryArg.recordId + queryArg.context},
      ],
    }),
    updateNote: build.mutation<UpdateNoteApiResponse, UpdateNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/note/${queryArg.resourceId}/${queryArg.recordId}/${queryArg.context}`,
        method: 'PUT',
        body: queryArg.updateNoteRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'note', id: queryArg.resourceId + queryArg.recordId + queryArg.context},
      ],
    }),
  }),
});

export const {useGetNoteQuery, useUpdateNoteMutation} = noteApi;
