import {isNotNil} from 'ramda';

import {
  PatchCheckoutApiResponse,
  PatchCheckoutApiArg,
  PostCheckoutInvoiceApiResponse,
  PostCheckoutInvoiceApiArg,
  GetCheckoutByContextApiResponse,
  GetCheckoutByContextApiArg,
  PostCheckoutApiRequest,
  PostCheckoutApiResponse,
} from '../types/api/metadaCheckout';
import {metadaApi} from './baseApi/metadaApi';

export const metadaCheckoutApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postCheckout: build.mutation<PostCheckoutApiResponse, PostCheckoutApiRequest>({
      query: (body) => ({
        url: '/core/v1/checkout',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [],
    }),
    patchCheckout: build.mutation<PatchCheckoutApiResponse, PatchCheckoutApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/checkout/${queryArg.checkoutId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'checkout', id: queryArg.checkoutId}],
    }),
    postCheckoutInvoice: build.mutation<PostCheckoutInvoiceApiResponse, PostCheckoutInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/checkout/${queryArg.checkoutId}/invoice`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'checkout', id: queryArg.checkoutId},
        'profitabilityHistory',
        'serviceJobActions',
        'orderActions',
        'serviceJobActions',
        ...(isNotNil(queryArg.serviceCaseId)
          ? [
              {type: 'serviceActions' as const, id: queryArg.serviceCaseId},
              {type: 'serviceCase' as const, id: queryArg.serviceCaseId},
              'serviceOrders' as const,
              'serviceOrder' as const,
              'serviceOrderJobs' as const,
            ]
          : []),
        ...(isNotNil(queryArg.directSaleId)
          ? [{type: 'directSales' as const, id: queryArg.directSaleId}]
          : []),
      ],
    }),
    getCheckoutByContext: build.query<GetCheckoutByContextApiResponse, GetCheckoutByContextApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/checkout/${queryArg.contextTarget}/${queryArg.contextId}`,
      }),
      providesTags: (result) => [{type: 'checkout', id: result?.checkout?.id ?? ''}],
    }),
  }),
});

export const {
  usePostCheckoutMutation,
  usePatchCheckoutMutation,
  usePostCheckoutInvoiceMutation,
  useGetCheckoutByContextQuery,
  useLazyGetCheckoutByContextQuery,
} = metadaCheckoutApi;
