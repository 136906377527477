import {showNotification} from 'platform/components';

import {isNil} from 'ramda';

import {
  IssuedType,
  OrderResponseBody,
  handleApiError,
  useCreateManuallyTaxDocumentForPaymentMutation,
  useGetBusinessCaseSeriesDefinitionListQuery,
  useGetCurrentBranch,
  useGetTaxDocumentSettingListQuery,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const useGetCreateTaxDocumentForPayment = (order: OrderResponseBody | Nullish) => {
  const {data: taxDocumentGenerationSettings} = useGetTaxDocumentSettingListQuery();
  const {data: businessCaseGenerationSettings} = useGetBusinessCaseSeriesDefinitionListQuery();
  const {activeBranchId} = useGetCurrentBranch();

  const [createDocument] = useCreateManuallyTaxDocumentForPaymentMutation();

  const businessCaseDefaultDefinition = businessCaseGenerationSettings?.find(
    (item) => item.systemDefault
  );
  const businessCaseBranchDefinition = businessCaseGenerationSettings?.find(
    (item) => item.branchId === activeBranchId
  );

  const defaultSettings = taxDocumentGenerationSettings?.find((item) => item.isSystem);
  const branchSettings = taxDocumentGenerationSettings?.find(
    (item) => item.branchId === activeBranchId
  );

  const usedTaxDocumentGenerationSettings = branchSettings ?? defaultSettings;
  const usedBusinessCaseGenerationSettings =
    businessCaseBranchDefinition ?? businessCaseDefaultDefinition;

  const issuedType: IssuedType = usedTaxDocumentGenerationSettings?.issuedType ?? 'not_create';

  const createTaxDocumentFormPayment = async (transactionId: string) => {
    if (issuedType !== 'manual') {
      return;
    }
    // should never happen, only to satisfy typescript
    if (isNil(usedBusinessCaseGenerationSettings) || isNil(usedTaxDocumentGenerationSettings)) {
      return showNotification.error('Cannot create document. Generation settings are not found');
    }

    const {taxDocumentSeriesId, brokerageFeesTaxDocumentSeriesId} =
      usedBusinessCaseGenerationSettings;

    const seriesId =
      order?.orderDiscriminator === 'SALE'
        ? taxDocumentSeriesId
        : (brokerageFeesTaxDocumentSeriesId ?? taxDocumentSeriesId);

    await createDocument({
      transactionId,
      seriesId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  return [createTaxDocumentFormPayment, issuedType] as const;
};
