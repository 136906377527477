import {z} from 'zod';

const ServiceHandOverCommonSchema = z.object({
  id: z.string().nullable().optional(),
  state: z.enum(['SCHEDULED', 'COMPLETED']).nullable().optional(),
  scheduledDate: z.string().nullable().optional(),
  completedDate: z.string().nullable().optional(),
  driverFirstname: z.string().nullable().optional(),
  driverLastname: z.string().nullable().optional(),
  driverPhonePrefix: z.string().nullable().optional(),
  driverPhoneNo: z.string().nullable().optional(),
  driverEmail: z.string().nullable().optional(),
  driverIdentityCard: z.string().nullable().optional(),
  mileage: z.number().nullable().optional(),
  fuelTank: z.number().nullable().optional(),
  technicalInspection: z.string().nullable().optional(),
  emissions: z.string().nullable().optional(),
  note: z.string().nullable().optional(),
  inspectionId: z.array(z.string()).nullable().optional(),
});

export const GetServiceHandOverApiResponseSchema = ServiceHandOverCommonSchema.nullable();

export type GetServiceHandOverApiResponse = z.infer<typeof GetServiceHandOverApiResponseSchema>;

export const GetServiceHandOverApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceHandOverApiArg = z.infer<typeof GetServiceHandOverApiArgSchema>;

export const PostServiceHandOverApiResponseSchema = GetServiceHandOverApiResponseSchema;
export type PostServiceHandOverApiResponse = z.infer<typeof PostServiceHandOverApiResponseSchema>;

const PostServiceHandOverBodySchema = z.object({
  id: z.string().nullable().optional(),
  scheduledDate: z.string().nullable().optional(),
  completedDate: z.string().nullable().optional(),
  driverFirstname: z.string().nullable().optional(),
  driverLastname: z.string().nullable().optional(),
  driverPhonePrefix: z.string().nullable().optional(),
  driverPhoneNo: z.string().nullable().optional(),
  driverEmail: z.string().nullable().optional(),
  driverIdentityCard: z.string().nullable().optional(),
  mileage: z.number().nullable().optional(),
  fuelTank: z.number().nullable().optional(),
  technicalInspection: z.string().nullable().optional(),
  emissions: z.string().nullable().optional(),
  note: z.string().nullable().optional(),
  inspectionId: z.array(z.string()).nullable().optional(),
});

export const PostServiceHandOverApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: PostServiceHandOverBodySchema.nullable(),
});

export type PostServiceHandOverApiArg = z.infer<typeof PostServiceHandOverApiArgSchema>;

export const PatchServiceHandOverApiResponseSchema = GetServiceHandOverApiResponseSchema;
export type PatchServiceHandOverApiResponse = z.infer<typeof PatchServiceHandOverApiResponseSchema>;

const PatchServiceHandOverBodySchema = PostServiceHandOverBodySchema.omit({
  id: true,
}).nullable();

export const PatchServiceHandOverApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: PatchServiceHandOverBodySchema,
});

export type PatchServiceHandOverApiArg = z.infer<typeof PatchServiceHandOverApiArgSchema>;
