import {Card, Attributes, AttributesRow, Action, openDialog} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {UtmParameters} from '@omnetic-dms/shared';

import {RequiredTestIdProps, buildArray, suffixTestId} from 'shared';

import {UTMForm} from './UTMForm';

interface UTMCardProps extends RequiredTestIdProps {
  parameters: UtmParameters;
  interestId: string;
}

export const UTMCard = (props: UTMCardProps) => {
  const attributes = buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.interest.utm.source'),
      value: props.parameters.source,
    })
    .add({
      label: i18n.t('entity.interest.utm.medium'),
      value: props.parameters.medium,
    })
    .add({
      label: i18n.t('entity.interest.utm.campaign'),
      value: props.parameters.campaign,
    })
    .add({
      label: i18n.t('entity.interest.utm.term'),
      value: props.parameters.term,
    })
    .add({
      label: i18n.t('entity.interest.utm.content'),
      value: props.parameters.content,
    });

  const editUtm = () => {
    openDialog(
      <UTMForm
        data-testid={suffixTestId('form', props)}
        parameters={props.parameters}
        interestId={props.interestId}
      />,
      {
        title: i18n.t('entity.interest.labels.marketing'),
        withAdditionalFooter: true,
        'data-testid': suffixTestId('dialog', props),
      }
    );
  };

  return (
    <Card
      title={i18n.t('entity.interest.labels.marketing')}
      data-testid={props['data-testid']}
      actions={buildArray<Action>().add({
        variant: 'link',
        type: 'button',
        title: i18n.t('general.actions.edit'),
        leftIcon: 'image/edit',
        onClick: editUtm,
      })}
    >
      <Attributes rows={attributes} data-testid={props['data-testid']} />
    </Card>
  );
};
