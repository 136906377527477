import {DefaultValues} from 'react-hook-form';

import {isNil, isNotNil} from 'ramda';

import {Nullish, getApiDateString} from 'shared';

import {FullVehicleResponseBody} from '../../../types/api/api';
import {ServiceVehicleFormType} from '../types/ServiceVehicleFormType';

export const getServiceVehicleDefaultValueFromVehicle = (
  vehicle: FullVehicleResponseBody | Nullish,
  note?: string | Nullish
): DefaultValues<ServiceVehicleFormType> => ({
  make: vehicle?.make,
  modelFamily: vehicle?.modelFamily,
  type: isNil(vehicle?.type) ? undefined : [vehicle?.type],
  batteryCapacityAh: vehicle?.vehicle.battery?.batteryCapacityAh,
  batteryCapacityWh: vehicle?.vehicle.battery?.batteryCapacityWh,
  batteryCount: vehicle?.vehicle.battery?.batteryCount,
  batteryMileageLimit: vehicle?.vehicle.battery?.batteryMileageLimit,
  batteryOwnershipType: vehicle?.vehicle.battery?.batteryOwnershipType,
  batteryType: vehicle?.vehicle.battery?.batteryType,
  batteryVoltage: vehicle?.vehicle.battery?.batteryVoltage,
  batteryWarrantyUntil: vehicle?.vehicle.battery?.batteryWarrantyUntil,
  bodyStyle: isNil(vehicle?.bodyStyle) ? undefined : [vehicle?.bodyStyle],
  chargingAC: vehicle?.vehicle.battery?.chargingAC,
  chargingConnectorType: vehicle?.vehicle.battery?.chargingConnectorType,
  chargingDC: vehicle?.vehicle.battery?.chargingDC,
  chargingTimeAC: vehicle?.vehicle.battery?.chargingTimeAC,
  chargingTimeDC: vehicle?.vehicle.battery?.chargingTimeDC,
  drive: isNil(vehicle?.drive) ? undefined : [vehicle?.drive],
  electricRange: vehicle?.vehicle.battery?.electricRange,
  engineCode: vehicle?.engine?.engineCode,
  engineName: vehicle?.engine?.engineName,
  engineNumber: vehicle?.engine?.engineNumber,
  engineVolume: vehicle?.engine?.engineVolume,
  exteriorColor: vehicle?.vehicle.exteriorColor,
  exteriorColorSpecification: isNil(vehicle?.vehicle.exteriorColorSpecification)
    ? undefined
    : [vehicle?.vehicle.exteriorColorSpecification],
  firstRegistrationOn:
    isNotNil(vehicle?.firstRegistrationOnYear) &&
    isNotNil(vehicle?.firstRegistrationOnMonth) &&
    isNotNil(vehicle?.firstRegistrationOnDay)
      ? getApiDateString(
          new Date(
            vehicle?.firstRegistrationOnYear ?? 0,
            vehicle?.firstRegistrationOnMonth ?? 0,
            vehicle?.firstRegistrationOnDay ?? 0
          )
        )
      : undefined,
  fuelType: isNil(vehicle?.fuelType) ? undefined : [vehicle?.fuelType],
  gasRevisionValidUntil: vehicle?.vehicle.gasRevisionValidUntil,
  hybridType: isNil(vehicle?.vehicle.hybridType) ? undefined : [vehicle?.vehicle.hybridType],
  manufacturedOnMonth: isNil(vehicle?.manufacturedOnMonth)
    ? undefined
    : `${vehicle?.manufacturedOnMonth}`,
  manufacturedOnYear: isNil(vehicle?.manufacturedOnYear)
    ? undefined
    : `${vehicle?.manufacturedOnYear}`,
  power: vehicle?.power,
  pressureVesselValidUntil: vehicle?.vehicle.pressureVesselValidUntil,
  registrationPlate: vehicle?.vehicle.registrationPlate,
  secondaryFuelType: isNil(vehicle?.secondaryFuelType) ? undefined : [vehicle?.secondaryFuelType],
  title: vehicle?.title,
  transmission: isNil(vehicle?.transmission) ? undefined : [vehicle?.transmission],
  trim: vehicle?.trim,
  variant: vehicle?.variant,
  vin: vehicle?.vin,
  note,
});
