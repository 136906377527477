import 'currency-flags/dist/currency-flags.css';
import {
  Card,
  Flag,
  FormControl,
  FormField,
  isCurrency,
  Label,
  Separator,
} from 'platform/components';
import {GridItem, Grid, HStack, Show, Space, Text, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency} from 'platform/locale';

import {useEffect} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';

import {filter, find, head, isNil, map, pipe, sort} from 'ramda';
import {isNegative, isNotNil, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  CashRegisterDocumentKindOfPaymentResponseBody,
  ConversionRatioResponseBody,
  featureFlags,
  handleApiError,
  MoneyResponseBody,
  useGetCashRegisterDocumentKindOfPaymentListQuery,
  useGetCashRegisterListQuery,
  useGetCashRegisterQuery,
  useGetTenantQuery,
  useLazyFindConversionTablesQuery,
  useTaxDocumentCalculationV2Mutation,
} from '@omnetic-dms/shared';

import {
  parseDate,
  isFeatureEnabled,
  Nullish,
  suffixTestId,
  RequiredTestIdProps,
  useBoolean,
} from 'shared';

import {CNB, TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY} from '../constants';
import {AccountingDocumentFormValues} from '../types';
import {useRecalculateInvoiceItems} from './listOfItems/hooks/useRecalculateInvoiceItems';

interface BasicInformationProps extends RequiredTestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  documentType: 'income' | 'expense';
}

const typedHead = (val: ConversionRatioResponseBody[]) => head<ConversionRatioResponseBody>(val);

export function BasicInformation(props: BasicInformationProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const [findConversionTables, {isLoading}] = useLazyFindConversionTablesQuery();
  const [isEditedByHand, setEditByHand, setEditBySystem] = useBoolean(false);
  const formatCurrency = useFormatCurrency();
  const {data: tenant} = useGetTenantQuery();

  const [recalculateItems] = useRecalculateInvoiceItems({
    control: props.control,
    formApi: props.formApi,
    listItemType: 'cash-receipts',
  });

  const [
    paymentMethod,
    cashRegisterId,
    issueDate,
    pairedInvoiceId,
    invoiceCurrency,
    isExchangeRateRatioEnabled,
  ] = useWatch({
    control: props.control,
    name: [
      'paymentInfo.paymentMethod',
      'cashRegisterId',
      'issueDate',
      'id',
      'currency',
      'isExchangeRateRatioEnabled',
    ],
  });

  const {data: kindOfPayment} = useGetCashRegisterDocumentKindOfPaymentListQuery({});

  const [_, {data: summary}] = useTaxDocumentCalculationV2Mutation({
    fixedCacheKey: TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });
  const {data: activeCashRegister} = useGetCashRegisterQuery(
    {cashRegisterId: cashRegisterId ?? ''},
    {skip: isNil(cashRegisterId)}
  );
  const {cashRegisterOptions} = useGetCashRegisterListQuery(
    {
      inactive: false,
      withValidIncomeSeries: true,
      currency: invoiceCurrency,
    },
    {
      selectFromResult: (funcs) => ({
        ...funcs,
        cashRegisterOptions: funcs.data?.map((item) => ({value: item.id, label: item.name})),
      }),
    }
  );

  const currency =
    isCurrency(activeCashRegister?.currency) && isNotNil(activeCashRegister)
      ? activeCashRegister.currency
      : undefined;

  const priceWithVat = summary?.calculation?.priceWithVat;

  const summaryTotalMoney =
    props.documentType === 'income'
      ? {amount: parseFloat(priceWithVat?.amount ?? '0'), currency: priceWithVat?.currency}
      : {
          amount: parseFloat(`-${Math.abs(parseFloat(priceWithVat?.amount ?? '0'))}`),
          currency: priceWithVat?.currency,
        };

  const getFinalPrice = (): number => {
    if (paymentMethod === 'CARD') {
      return parseFloat(activeCashRegister?.currentBalance.amount ?? '0');
    }

    return props.documentType === 'income'
      ? parseFloat(activeCashRegister?.currentBalance.amount ?? '0') +
          parseFloat(priceWithVat?.amount ?? '0')
      : parseFloat(activeCashRegister?.currentBalance.amount ?? '0') -
          parseFloat(priceWithVat?.amount ?? '0');
  };
  const kindOfPaymentOptions = pipe(
    filter<CashRegisterDocumentKindOfPaymentResponseBody>(
      (item) => item.type === props.documentType
    ),
    map((item) => ({
      value: item.code,
      label: item.name,
    }))
  )(kindOfPayment ?? []);

  const paymentOptions = cashRegisterPaymentOptions.filter(
    (item) => activeCashRegister?.eligibleForCardPayments || item.value !== 'CARD'
  );
  const formatPrice = (val: MoneyResponseBody | Nullish): string =>
    val ? formatCurrency(parseFloat(val.amount), val.currency, 2) ?? '' : '';

  useEffect(() => {
    if (
      isNil(tenant) ||
      !currency ||
      tenant.currency === currency ||
      isNotNil(pairedInvoiceId) ||
      isEditedByHand ||
      !isFeatureEnabled(featureFlags.SALES_FOREIGN_CURRENCY) ||
      !isExchangeRateRatioEnabled
    ) {
      return;
    }

    findConversionTables({domesticCurrency: tenant.currency, foreignCurrency: currency})
      .unwrap()
      .then(
        pipe(
          find((item) => item.type === CNB),
          (item) => item?.ratios ?? [],
          filter((item: ConversionRatioResponseBody) => {
            const parsedIssueDate = parseDate(issueDate);
            const parsedValidFrom = parseDate(item.validFrom);
            return parsedIssueDate.getTime() >= parsedValidFrom.getTime();
          }),
          sort((a, b) => {
            const parsedAValidFrom = parseDate(a.validFrom);
            const parsedBValidFrom = parseDate(b.validFrom);
            return parsedBValidFrom.getTime() - parsedAValidFrom.getTime();
          }),
          typedHead
        )
      )
      .then((rate) => {
        const amount = rate?.amount ?? 1;
        const ratio = rate?.ratio ?? '';

        props.formApi.setValue('exchangeRateRatio.amount', amount.toString());
        props.formApi.setValue('exchangeRateRatio.ratio', ratio);
        props.formApi.setValue('exchangeRateRatio.currency', currency);

        recalculateItems();
      })
      .catch(handleApiError);
  }, [
    isExchangeRateRatioEnabled,
    currency,
    findConversionTables,
    isEditedByHand,
    issueDate,
    pairedInvoiceId,
    props.formApi,
    tenant,
    recalculateItems,
  ]);

  useEffect(() => {
    props.formApi.setValue(
      'isExchangeRateRatioEnabled',
      (activeCashRegister?.currency ?? tenant?.currency) !== tenant?.currency
    );
  }, [activeCashRegister?.currency, props.formApi, tenant?.currency]);

  useEffect(() => {
    if (!isString(cashRegisterId)) {
      return;
    }

    setEditBySystem();
    props.formApi.setValue('paymentInfo.paymentMethod', 'CASH');
  }, [cashRegisterId, props.formApi, setEditBySystem]);

  return (
    <Card
      title={i18n.t('general.labels.basicInformation')}
      data-testid={suffixTestId('section', props)}
    >
      <VStack spacing={4}>
        <Grid columns={4}>
          <FormField
            isRequired
            name="cashRegisterId"
            control={props.control}
            options={cashRegisterOptions}
            isNotClearable
            type="choice"
            label={i18n.t('entity.checkout.labels.cashRegister')}
            data-testid={suffixTestId('cashRegister', props)}
          />
          <Show when={isNotNil(activeCashRegister)}>
            <VStack>
              <Label>{i18n.t('general.labels.currency')}</Label>
              <Space vertical={1} />
              <HStack align="center" spacing={2}>
                <CountryFlag currency={currency} />
                <Text size="small" data-testid={suffixTestId('currencySymbol', props)}>
                  {formatCurrencySymbol(currency)}
                </Text>
              </HStack>
            </VStack>

            <VStack>
              <Label data-testid={suffixTestId('initialState', props)}>
                {i18n.t('entity.cashRegister.parameters.initialState')}
              </Label>
              <Space vertical={1} />

              <HStack justify="space-between">
                <Text alternative data-testid={suffixTestId('currentBalance', props)}>
                  {formatPrice(activeCashRegister?.currentBalance)}
                </Text>
                {isNotNil(summary) &&
                  summaryTotalMoney.amount !== 0 &&
                  paymentMethod !== 'CARD' && (
                    <Flag
                      data-testid={suffixTestId('difference', props)}
                      isSubtle
                      colorScheme={
                        isNegative(summaryTotalMoney.amount) || props.documentType === 'expense'
                          ? 'red'
                          : 'green'
                      }
                      label={
                        formatCurrency(
                          summaryTotalMoney.amount,
                          summaryTotalMoney.currency ?? '',
                          2
                        ) ?? ''
                      }
                    />
                  )}
              </HStack>
            </VStack>

            <VStack>
              <Label>{i18n.t('entity.newCashRegisterDocument.cashBalanceFinal.label')}</Label>
              <Space vertical={1} />
              <Text alternative data-testid={suffixTestId('cashBalanceFinal', props)}>
                {formatCurrency(
                  getFinalPrice(),
                  activeCashRegister?.currentBalance.currency ?? '',
                  2
                )}
              </Text>
            </VStack>
          </Show>
        </Grid>

        <Separator spacing={0} />

        <Grid columns={4}>
          <FormField
            isRequired
            label={i18n.t('entity.newCashRegisterDocument.kindOfPayment.label')}
            name="paymentInfo.paymentType"
            control={props.control}
            isNotClearable
            type="choice"
            options={kindOfPaymentOptions}
            data-testid={suffixTestId('kindOfPayment', props)}
          />

          <FormField
            isRequired
            label={i18n.t('general.labels.date')}
            name="issueDate"
            control={props.control}
            type="apiDate"
            data-testid={suffixTestId('issueDate', props)}
          />

          <FormField
            label={i18n.t('entity.lineItems.labels.variableSymbol')}
            name="paymentInfo.variableSymbol"
            control={props.control}
            type="text"
            helperText={i18n.t('entity.newCashRegisterDocument.variableSymbolOrInvoiceNumber')}
            data-testid={suffixTestId('variableSymbol', props)}
          />

          <FormField
            label={i18n.t('entity.bank.labels.paymentType')}
            name="paymentInfo.paymentMethod"
            control={props.control}
            type="choice"
            isNotClearable
            isDisabled={paymentOptions.length <= 1}
            options={paymentOptions}
            data-testid={suffixTestId('paymentMethod', props)}
            onChange={() => recalculateItems()}
          />

          <GridItem span={2}>
            <FormField
              label={i18n.t('entity.lineItems.labels.purpose')}
              name="reason"
              control={props.control}
              type="textarea"
              data-testid={suffixTestId('purpose', props)}
            />
          </GridItem>
          <Show when={tenant?.currency !== currency}>
            <FormField
              label={i18n.t('general.labels.amount')}
              name="exchangeRateRatio.amount"
              control={props.control}
              type="currency"
              currency={currency}
              isDisabled
              data-testid={suffixTestId('amount', props)}
            />
            <FormField
              label={i18n.t('general.labels.exchangeRate')}
              name="exchangeRateRatio.ratio"
              data-testid={suffixTestId('foreign-currency-ratio', props)}
              control={props.control}
              type="currency"
              onChange={() => {
                setEditByHand();
                recalculateItems();
              }}
              isDisabled={isLoading}
              currency={isCurrency(tenant?.currency) ? tenant?.currency : undefined}
              decimalPlaces={3}
            />
          </Show>
        </Grid>
      </VStack>
    </Card>
  );
}

const cashRegisterPaymentOptions = [
  {value: 'CASH', label: i18n.t('entity.invoice.paymentMethod.cash')},
  {value: 'CARD', label: i18n.t('entity.invoice.paymentMethod.card')},
];

// TODO: replace with platform component T20-27406
const CountryFlag = ({currency}: {currency: string | Nullish}) =>
  currency ? (
    <div
      style={{borderRadius: '2px'}}
      className={`currency-flag currency-flag-lg currency-flag-${currency?.toLowerCase()}`}
    />
  ) : null;
