import {Dropdown} from 'platform/components';
import {Box, Center, Heading, Icon, Space, VStack} from 'platform/foundation';

import {environment} from '@omnetic-dms/environment';
import i18n from '@omnetic-dms/i18n';
import {useGetCurrentUserInfoQuery, useGetRolesQuery} from '@omnetic-dms/shared';

import {CI_MODE, useBoolean} from 'shared';

import {AclDebugCard} from './components/AclDebugCard';
import {ApiValidation} from './components/ApiValidation';
import {CacheCleanup} from './components/CacheCleanup';
import {ContentEditable} from './components/ContentEditable';
import {DevSettingsHeaderButton} from './components/DevSettingsHeaderButton';
import {TranslationDebug} from './components/TranslationDebug';
import {DEBUG_ROLE_REFIX} from './utils/const';
import {getFixedTranslation} from './utils/getFixedTranslation';

const isDev = environment.ENV_TYPE === 'dev';

export function DevSettings() {
  const [isOpen, setOpen, setClosed] = useBoolean();
  const [isValidatingApiTraffic, startApiTrafficValidation, stopApiTrafficValidation] =
    useBoolean();
  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const {data: roles} = useGetRolesQuery({});

  const isContentEditableSettingsOn = document.body.contentEditable === 'true';
  const isTranslationsSettingsOn = i18n.language === CI_MODE;
  const isRoleDebuggingOn = roles?.some(
    (role) => role.title === `${DEBUG_ROLE_REFIX}-${currentUser?.id}`
  );

  if (!isDev) {
    return null;
  }

  const isAnyDevSettingEnabled =
    isRoleDebuggingOn ||
    isTranslationsSettingsOn ||
    isContentEditableSettingsOn ||
    isValidatingApiTraffic;

  return (
    <Dropdown
      onOpen={setOpen}
      onClose={setClosed}
      dropdownControl={
        <DevSettingsHeaderButton $isOpen={isOpen} data-testid="layout-header-devSettings-button">
          <Center height="100%">
            <Box
              width={6}
              height={6}
              backgroundColor={
                isAnyDevSettingEnabled ? 'palettes.red.30.100' : 'palettes.yellow.30.100'
              }
              borderRadius="circular"
              data-testid="layout-header-devSettings-button-icon"
            >
              <Center height="100%">
                <Icon size={3} value="action/build" cursor="pointer" />
              </Center>
            </Box>
          </Center>
        </DevSettingsHeaderButton>
      }
      strategy="fixed"
      data-testid="layout-header-devSettings-dropdown"
    >
      <Box padding={2} width={250}>
        <Heading data-testid="layout-header-devSettings-activeBranch" size={2}>
          {getFixedTranslation('page.settings.labels.devSettings')}
        </Heading>

        <Space vertical={4} />

        <VStack spacing={2}>
          <TranslationDebug />
          <ContentEditable />
          <CacheCleanup />
          <ApiValidation
            isValidating={isValidatingApiTraffic}
            startParsingValidation={startApiTrafficValidation}
            stopParsingValidation={stopApiTrafficValidation}
          />
          <AclDebugCard />
        </VStack>
      </Box>
    </Dropdown>
  );
}
