import {Alert} from 'platform/components';

import {not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useXentryIntegration} from '../../../../../hooks/useXentryIntegration';

interface XentryIntegrationProps extends RequiredTestIdProps {
  serviceCaseId: string;
}

export function XentryIntegration(props: XentryIntegrationProps) {
  const {
    linkedXentry,
    isError,
    isSuccess,
    openUnlinkConfirm,
    openChangeXentryDialog,
    getAvailableXentryOrders,
    openLinkXentryDialog,
  } = useXentryIntegration(props);

  if (isError || not(isSuccess)) {
    return null;
  }

  return linkedXentry ? (
    <Alert
      title={i18n.t('entity.integration.labels.successLinkedXentry', {xentryId: linkedXentry.id})}
      variant="success"
      hyperlinks={[
        {
          title: i18n.t('entity.integration.actions.unlink'),
          leftIcon: 'content/link_off',
          size: 'small',
          onClick: () => openUnlinkConfirm(linkedXentry?.id, getAvailableXentryOrders),
        },
        {
          title: i18n.t('entity.integration.actions.unlinkAndChange'),
          leftIcon: 'content/link_off',
          size: 'small',
          onClick: openChangeXentryDialog,
        },
        {
          title: i18n.t('entity.integration.actions.viewOrder'),
          leftIcon: 'action/open_in_new',
          size: 'small',
          isDisabled: isNilOrEmpty(linkedXentry.url),
          onClick: () => window.open(linkedXentry.url ?? ''),
        },
      ]}
      data-testid={suffixTestId('linkedXentry', props)}
    />
  ) : (
    <Alert
      title={i18n.t('entity.integration.labels.warningNotLinkedXentry')}
      variant="warning"
      hyperlinks={[
        {
          title: i18n.t('entity.integration.actions.link'),
          leftIcon: 'content/link',
          size: 'small',
          onClick: () => openLinkXentryDialog(),
        },
      ]}
      data-testid={suffixTestId('linkedXentry', props)}
    />
  );
}
