import {
  PostCalculatedItemPriceForSubcontractApiResponse,
  PostCalculatedItemPriceForSubcontractApiArg,
  GetCalculatedItemPriceForCalculationPriceApiArg,
  GetCalculatedItemPriceForCalculationPriceApiResponse,
  GetCalculatedItemPriceForCooperationApiArg,
  GetCalculatedItemPriceForCooperationApiResponse,
  GetCalculatedItemPriceForDirectPriceApiArg,
  GetCalculatedItemPriceForDirectPriceApiResponse,
  GetCalculatedItemPriceForTimeNormApiArg,
  GetCalculatedItemPriceForTimeNormApiResponse,
  GetServiceOrderJobItemWorkApiArg,
  GetServiceOrderJobItemWorkApiResponse,
  PatchOrderItemForWorkApiArg,
  PatchOrderItemForWorkApiResponse,
  PutServiceOrderItemsToOtherOrderJobApiArg,
  PutServiceOrderItemsToOtherOrderJobApiResponse,
  GetOrderItemForMaterialApiArg,
  GetOrderItemForMaterialApiResponse,
  PatchOrderItemForMaterialApiArg,
  PatchOrderItemForMaterialApiResponse,
  PostOrderItemCalculatePriceApiArg as GetOrderItemCalculatePriceApiArg,
  PostOrderItemCalculatePriceApiResponse as GetOrderItemCalculatePriceApiResponse,
} from '../types/api/metadaWorkshopServiceOrderJobItem';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderJobItemApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getOrderItemForMaterial: build.query<
      GetOrderItemForMaterialApiResponse,
      GetOrderItemForMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item-material/${queryArg.serviceItemId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'materialOrderItem', id: queryArg.serviceItemId},
      ],
    }),
    getServiceOrderJobItemWork: build.query<
      GetServiceOrderJobItemWorkApiResponse,
      GetServiceOrderJobItemWorkApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item-work/${queryArg.serviceItemId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'workBasketItem', id: queryArg.serviceItemId},
      ],
    }),
    patchOrderItemForWork: build.mutation<
      PatchOrderItemForWorkApiResponse,
      PatchOrderItemForWorkApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/work`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItem', id: queryArg.serviceItemId},
        {type: 'workBasketItems', id: queryArg.serviceJobId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceJobId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        'workSplitting',
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
    getOrderItemCalculatePrice: build.query<
      GetOrderItemCalculatePriceApiResponse,
      GetOrderItemCalculatePriceApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/calculate-price`,
        method: 'POST',
        body: queryArg.body,
      }),
      keepUnusedDataFor: 0,
    }),
    getCalculatedItemPriceForCalculationPrice: build.query<
      GetCalculatedItemPriceForCalculationPriceApiResponse,
      GetCalculatedItemPriceForCalculationPriceApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/calculate-price/calculation-price`,
        body: queryArg.body,
      }),
    }),
    getCalculatedItemPriceForCooperation: build.query<
      GetCalculatedItemPriceForCooperationApiResponse,
      GetCalculatedItemPriceForCooperationApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/calculate-price/cooperation`,
        body: queryArg.body,
      }),
    }),
    getCalculatedItemPriceForDirectPrice: build.query<
      GetCalculatedItemPriceForDirectPriceApiResponse,
      GetCalculatedItemPriceForDirectPriceApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/calculate-price/direct-price`,
        body: queryArg.body,
      }),
    }),
    getCalculatedItemPriceForTimeNorm: build.query<
      GetCalculatedItemPriceForTimeNormApiResponse,
      GetCalculatedItemPriceForTimeNormApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/calculate-price/time-norm`,
        body: queryArg.body,
      }),
    }),
    getCalculatedItemPriceForSubcontract: build.query<
      PostCalculatedItemPriceForSubcontractApiResponse,
      PostCalculatedItemPriceForSubcontractApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/calculate-price/subcontract`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    patchOrderItemForMaterial: build.mutation<
      PatchOrderItemForMaterialApiResponse,
      PatchOrderItemForMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/material`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
        {type: 'materialOrderItem', id: queryArg.serviceItemId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceJobId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'workSplitting',
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
    putServiceOrderItemsToOtherOrderJob: build.mutation<
      PutServiceOrderItemsToOtherOrderJobApiResponse,
      PutServiceOrderItemsToOtherOrderJobApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/move-items`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrder', id: queryArg.body?.serviceMoveToOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.body?.serviceMoveToOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.body?.serviceMoveToOrderId},
        'serviceOrderJobTotalAmount',
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.body?.serviceMoveToOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.body?.serviceMoveToOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
  }),
});

export const {
  useGetCalculatedItemPriceForSubcontractQuery,
  useGetServiceOrderJobItemWorkQuery,
  usePatchOrderItemForWorkMutation,
  useGetCalculatedItemPriceForCalculationPriceQuery,
  useGetCalculatedItemPriceForCooperationQuery,
  useGetCalculatedItemPriceForDirectPriceQuery,
  useGetCalculatedItemPriceForTimeNormQuery,
  usePutServiceOrderItemsToOtherOrderJobMutation,
  useGetOrderItemForMaterialQuery,
  usePatchOrderItemForMaterialMutation,
  useGetOrderItemCalculatePriceQuery,
  useLazyGetOrderItemCalculatePriceQuery,
} = metadaWorkshopServiceOrderJobItemApi;
