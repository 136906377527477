import {Card, DataStatus} from 'platform/components';
import {Grid, VStack, Show} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  FullVehicleResponseBody,
  useGetCebiaAutotracerAndReportConfigQuery,
  useGetCebiaRokvyConfigQuery,
} from '@omnetic-dms/shared';
import {
  CebiaReportWidget,
  CebiaProductionYearWidget,
  CebiaAutotracerWidget,
} from '@omnetic-dms/teas';

type ComponentProps = {
  vehicle?: FullVehicleResponseBody;
};

export function BusinessCasePurchaseVehicleCheck({vehicle}: ComponentProps) {
  const navigate = useNavigate();

  const {data: cebiaAutotracerAndReportConfig, isLoading: isCebiaAutotracerAndReportConfigLoading} =
    useGetCebiaAutotracerAndReportConfigQuery();
  const {data: cebiaRokvyConfig, isLoading: isCebiaRokvyConfigLoading} =
    useGetCebiaRokvyConfigQuery();

  const isEmpty = !cebiaAutotracerAndReportConfig?.enabled && !cebiaRokvyConfig?.enabled;
  const isLoading = isCebiaAutotracerAndReportConfigLoading || isCebiaRokvyConfigLoading;

  return (
    <Grid columns={2}>
      <Card
        title={i18n.t('entity.vehicle.labels.integrations')}
        actions={[
          {
            type: 'button',
            variant: 'link',
            leftIcon: 'action/settings',
            onClick: () => navigate(settingsRoutes.vehicleServices),
            title: i18n.t('general.labels.settings'),
            'data-testid': testIds.businessCase.buying('integrationSettingsButton'),
          },
        ]}
      >
        <VStack spacing={3}>
          <DataStatus
            isEmpty={isEmpty}
            isLoading={isLoading}
            emptyMessage={i18n.t('entity.vehicle.emptyIntegration.noServicesAvailable')}
            emptySubheadline={i18n.t('entity.vehicle.emptyIntegration.enableInSettings')}
          >
            {vehicle && (
              <>
                <Show when={cebiaAutotracerAndReportConfig?.enabled}>
                  <CebiaAutotracerWidget
                    vehicle={vehicle}
                    data-testid={testIds.businessCase.buying('cebiaAutotracerWidget')}
                  />
                </Show>
                <Show when={cebiaAutotracerAndReportConfig?.enabled}>
                  <CebiaReportWidget
                    vehicle={vehicle}
                    data-testid={testIds.businessCase.buying('cebiaReportWidget')}
                  />
                </Show>
                <Show when={cebiaRokvyConfig?.enabled}>
                  <CebiaProductionYearWidget
                    vehicle={vehicle}
                    data-testid={testIds.businessCase.buying('cebiaProductionYearWidget')}
                  />
                </Show>
              </>
            )}
          </DataStatus>
        </VStack>
      </Card>
    </Grid>
  );
}
