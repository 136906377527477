import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {isNil, always} from 'ramda';

import {Nullish} from 'shared';

import {i18n} from '../i18n/i18n';

export const getInterestStateFlagProps = (state: string | Nullish) => {
  if (isNil(state)) {
    return null;
  }

  return match<string, BaseFlagProps | null>(state)
    .with(
      'NEW',
      always({
        colorScheme: 'blue',
        label: i18n.t('general.labels.new'),
        isSubtle: true,
      })
    )
    .with(
      'CLOSED',
      always({
        colorScheme: 'green',
        label: i18n.t('general.labels.closed'),
        isSubtle: true,
      })
    )
    .with(
      'OPEN',
      always({
        colorScheme: 'orange',
        label: i18n.t('general.labels.open'),
        isSubtle: true,
      })
    )
    .with(
      'UNSUCCESSFUL',
      always({
        colorScheme: 'red',
        label: i18n.t('general.labels.unsuccessful'),
        isSubtle: true,
      })
    )
    .otherwise(always(null));
};
