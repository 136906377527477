import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {object} from 'yup';

import {defaultTo, isNil, not, pipe, reject} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {useGetMaterialDiscountGroupItemByIdQuery, useGetWarehousesQuery} from '@omnetic-dms/shared';

import {suffixTestId, yupNumber, yupString, TestIdProps} from 'shared';

import {MaterialDiscountFormType} from '../types/materialDiscountFormType';
import {DiscountGroupSelect} from './DiscountGroupSelect';

interface MaterialDiscountFormProps extends TestIdProps {
  discountId?: string;
  groupId?: string;
  onClose: () => void;
  onSubmit: FormSubmitHandler<MaterialDiscountFormType>;
}

const DISCOUNT_TYPE_OPTIONS = [
  {
    label: i18n.t('entity.workshopCustomerGroup.labels.discountOnSalePrice'),
    value: 'DISCOUNT_SALE_PRICE',
  },
  {
    label: i18n.t('entity.workshopCustomerGroup.labels.markupToPurchasePrice'),
    value: 'DISCOUNT_PURCHASE_PRICE',
  },
];

const rejectNullish = pipe<[(string | null)[] | undefined], (string | null)[], string[]>(
  defaultTo([]),
  reject(isNil)
);

export function MaterialDiscountForm(props: MaterialDiscountFormProps) {
  const isEdit = isNotNil(props.discountId) && isNotNil(props.groupId);

  const {data, isLoading, isError} = useGetMaterialDiscountGroupItemByIdQuery(
    {discountId: props.discountId ?? '', groupId: props.groupId ?? ''},
    {skip: not(isEdit)}
  );
  const {warehousesOptions, isWarehousesLoading} = useGetWarehousesQuery(undefined, {
    selectFromResult: ({data, isLoading}) => ({
      isWarehousesLoading: isLoading,
      warehousesOptions: data?.map((warehouse) => ({label: warehouse?.name, value: warehouse?.id})),
    }),
  });

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialDiscountFormType>
        defaultValues={{
          name: data?.name ?? undefined,
          discountRatio:
            isNotNil(data) && isNotNil(data.discountRatio) ? data.discountRatio * 100 : undefined,
          discountGroupIds: rejectNullish(data?.discountGroupIds),
          warehouseId: data?.warehouseId ?? undefined,
          discountType: data?.discountType ?? 'DISCOUNT_SALE_PRICE',
        }}
        onSubmit={props.onSubmit}
        schema={schema}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  name="name"
                  type="text"
                  label={i18n.t('general.labels.name')}
                  isRequired
                  data-testid={suffixTestId('name', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  name="warehouseId"
                  type="choice"
                  menuInPortal
                  options={warehousesOptions}
                  isLoading={isWarehousesLoading}
                  label={i18n.t('entity.workshopCustomerGroup.labels.warehouse')}
                  isRequired
                  data-testid={suffixTestId('warehouse', props)}
                />
              </Box>
            </HStack>
            <DiscountGroupSelect
              control={control}
              warehouseId={formApi.watch('warehouseId')}
              data-testid={suffixTestId('discount', props)}
            />
            <Card
              variant="inlineGrey"
              title={i18n.t('entity.workshopCustomerGroup.labels.discountSettings')}
            >
              <VStack spacing={4}>
                <FormField
                  control={control}
                  name="discountType"
                  type="radio"
                  direction="column"
                  spacing={3}
                  options={DISCOUNT_TYPE_OPTIONS}
                  data-testid={suffixTestId('discountType', props)}
                />
                <Box width={90}>
                  <FormField
                    control={control}
                    name="discountRatio"
                    type="number"
                    isStepperVisible
                    helperText={i18n.t('entity.workshopCustomerGroup.labels.insertPercentageValue')}
                    minStepperValue={0}
                    maxStepperValue={100}
                    isRequired
                    decimalPlaces={2}
                    data-testid={suffixTestId('discountRatio', props)}
                  />
                </Box>
              </VStack>
            </Card>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                type="submit"
                control={control}
                title={isEdit ? i18n.t('general.actions.save') : i18n.t('general.actions.create')}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  name: yupString.required(),
  warehouseId: yupString.required(),
  discountRatio: yupNumber.min(0).max(100).required(),
});
