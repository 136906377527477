import {FullVehicle} from '@omnetic-dms/shared';

import type {AddFilesToVehicleAlbumRequestBody} from '../types/AddFilesToVehicleAlbumRequestBody';
import type {AddFilesToVehicleAlbumResponse} from '../types/AddFilesToVehicleAlbumResponse';
import type {AdditionalNote} from '../types/AdditionalNote';
import type {AdditionalNoteRequestBody} from '../types/AdditionalNoteRequestBody';
import type {AutofillFeatures} from '../types/AutofillFeatures';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateVehicleRequestBody} from '../types/CreateVehicleRequestBody';
import type {EditTopMakesRequestBody} from '../types/EditTopMakesRequestBody';
import type {HighlightBulkRequestBody} from '../types/HighlightBulkRequestBody';
import type {PatchVehicleRequestBody} from '../types/PatchVehicleRequestBody';
import type {ShortcomingBulkRequestBody} from '../types/ShortcomingBulkRequestBody';
import type {SimilarVehicle} from '../types/SimilarVehicle';
import type {TagType} from '../types/TagType';
import type {TopMake} from '../types/TopMake';
import type {UpdateVehicleRequestBody} from '../types/UpdateVehicleRequestBody';
import {request as __request} from '../utils/request';

export class VehicleService {
  /**
   * @returns SimilarVehicle
   * @throws ApiError
   */
  public static getSimilarVehicles({
    size,
    offset,
    vin,
    registrationPlateContains,
    authorization,
  }: {
    size?: number | null;
    offset?: number | null;
    vin?: string | null;
    registrationPlateContains?: string | null;
    authorization?: string;
  }): CancelablePromise<Array<SimilarVehicle>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/similar',
      headers: {
        Authorization: authorization,
      },
      query: {
        size,
        offset,
        vin,
        registrationPlateContains,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static editTopMakes({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: EditTopMakesRequestBody;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/top-makes',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns TopMake
   * @throws ApiError
   */
  public static getTopMakes({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<TopMake>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/top-makes',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns FullVehicle
   * @throws ApiError
   */
  public static getVehicle({
    shouldClearCacheEntry,
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
    shouldClearCacheEntry?: boolean;
  }): CancelablePromise<FullVehicle> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/{vehicleId}',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      shouldClearCacheEntry,
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static partialUpdateVehicle({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: PatchVehicleRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/vehicle/{vehicleId}',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FullVehicle
   * @throws ApiError
   */
  public static updateVehicle({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: UpdateVehicleRequestBody;
  }): CancelablePromise<FullVehicle> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/{vehicleId}',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AdditionalNote
   * @throws ApiError
   */
  public static getAdditionalNote({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<AdditionalNote> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/{vehicleId}/additional-note',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns AdditionalNote
   * @throws ApiError
   */
  public static setAdditionalNote({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: AdditionalNoteRequestBody;
  }): CancelablePromise<AdditionalNote> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/{vehicleId}/additional-note',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AutofillFeatures
   * @throws ApiError
   */
  public static getAutofillFeatures({
    vin,
    authorization,
    decoderType,
  }: {
    vin: string;
    authorization?: string;
    decoderType?: string;
  }): CancelablePromise<AutofillFeatures> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/autofill-features/{vin}',
      path: {
        vin,
      },
      query: {
        decoderType,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns AutofillFeatures
   * @throws ApiError
   */
  public static putAutoFillFeature({
    vehicleId,
    authorization,
    decoderType,
  }: {
    vehicleId: string;
    authorization?: string;
    decoderType?: string;
  }): CancelablePromise<AutofillFeatures> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/autofill-features/{vehicleId}',
      path: {
        vehicleId,
      },
      query: {
        decoderType,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns TagType
   * @throws ApiError
   */
  public static listHighlights({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<Array<TagType>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/{vehicleId}/highlight',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns TagType
   * @throws ApiError
   */
  public static bulkCreateHighlights({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: HighlightBulkRequestBody;
  }): CancelablePromise<Array<TagType>> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/{vehicleId}/highlight/_bulk',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteHighlight({
    vehicleId,
    highlightId,
    authorization,
  }: {
    vehicleId: string;
    highlightId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/vehicle/{vehicleId}/highlight/{highlightId}',
      path: {
        vehicleId,
        highlightId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns TagType
   * @throws ApiError
   */
  public static listHighlightsForTenant({
    size,
    offset,
    authorization,
  }: {
    size?: number;
    offset?: number;
    authorization?: string;
  }): CancelablePromise<Array<TagType>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/highlight/tenant/list',
      headers: {
        Authorization: authorization,
      },
      query: {
        size,
        offset,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static archiveVehicle({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/vehicle/{vehicleId}/archive',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns FullVehicle
   * @throws ApiError
   */
  public static createVehicle({
    authorization,
    xBranch,
    requestBody,
  }: {
    authorization?: string;
    xBranch?: string;
    requestBody?: CreateVehicleRequestBody;
  }): CancelablePromise<FullVehicle> {
    return __request({
      method: 'POST',
      url: '/dms/v1/vehicle',
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static unarchiveVehicle({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/vehicle/{vehicleId}/unarchive',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns TagType
   * @throws ApiError
   */
  public static listShortcomings({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<Array<TagType>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/{vehicleId}/shortcoming',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns TagType
   * @throws ApiError
   */
  public static bulkCreateShortcomings({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: ShortcomingBulkRequestBody;
  }): CancelablePromise<Array<TagType>> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/{vehicleId}/shortcoming/_bulk',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteShortcoming({
    vehicleId,
    shortcomingId,
    authorization,
  }: {
    vehicleId: string;
    shortcomingId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/vehicle/{vehicleId}/shortcoming/{shortcomingId}',
      path: {
        vehicleId,
        shortcomingId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns TagType
   * @throws ApiError
   */
  public static listShortcomingsForTenant({
    size,
    offset,
    authorization,
  }: {
    size?: number;
    offset?: number;
    authorization?: string;
  }): CancelablePromise<Array<TagType>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/shortcoming/tenant/list',
      headers: {
        Authorization: authorization,
      },
      query: {
        size,
        offset,
      },
    });
  }

  /**
   * @returns AddFilesToVehicleAlbumResponse
   * @throws ApiError
   */
  public static addFilesToVehicleAlbum({
    vehicleAlbumId,
    authorization,
    requestBody,
  }: {
    vehicleAlbumId: string;
    authorization?: string;
    requestBody?: AddFilesToVehicleAlbumRequestBody;
  }): CancelablePromise<AddFilesToVehicleAlbumResponse> {
    return __request({
      method: 'POST',
      url: '/dms/v1/vehicle/vehicle-album/{vehicleAlbumId}',
      path: {
        vehicleAlbumId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static setVehicleAlbumCoverPhoto({
    vehicleAlbumId,
    vehiclePhotoId,
    authorization,
  }: {
    vehicleAlbumId: string;
    vehiclePhotoId: string;
    authorization?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/vehicle-album/{vehicleAlbumId}/cover-photo/{vehiclePhotoId}',
      path: {
        vehicleAlbumId,
        vehiclePhotoId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
