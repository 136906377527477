import {FormField, FormControl} from 'platform/components';

import {FieldValues, Path} from 'react-hook-form';
import {MenuPlacement} from 'react-select';

import {map, defaultTo} from 'ramda';

import {TestIdProps} from 'shared';

import {useGetPrioritizedCountriesQuery} from '../../api/cachedApi';
import {useGetTenantQuery} from '../../api/tenantApi';
import {CountryResponseBody} from '../../types/cached/Countries';

type CountrySelectProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isNotClearable?: boolean;
  menuInPortal?: boolean;
  menuPlacement?: MenuPlacement;
  onChange?: (value: string | number | string[] | null) => void;
} & TestIdProps;

export function CountrySelect<TFieldValues extends FieldValues = FieldValues>(
  props: CountrySelectProps<TFieldValues>
) {
  const {data: tenantData} = useGetTenantQuery();
  const {data: countries} = useGetPrioritizedCountriesQuery(
    {countryCode: tenantData?.country},
    {skip: !tenantData}
  );

  const countriesOptions = map(
    (country: CountryResponseBody) => ({value: country.code, label: country.name}),
    defaultTo([], countries)
  );

  return (
    <FormField
      type="choice"
      control={props.control}
      name={props.name}
      label={props.label}
      data-testid={props['data-testid']}
      options={countriesOptions}
      menuPlacement={props.menuPlacement}
      isRequired={props.isRequired}
      isNotClearable={props.isNotClearable}
      isDisabled={props.isDisabled}
      menuInPortal={props.menuInPortal}
      onChange={props.onChange}
    />
  );
}
