import {showNotification} from 'platform/components';

import {useNavigate} from 'react-router-dom';

import {accountingRoutes} from '@omnetic-dms/routes';
import {handleApiError, useCreateCorrectiveTaxDocumentMutation} from '@omnetic-dms/shared';

import {AccountingDocumentFormValues} from '../../../types';
import {useGetCorrectiveTaxDocumentBody} from './useGetCorrectiveTaxDocumentBody';

export const useHandleSubmitCorrectiveTaxDocument = () => {
  const navigate = useNavigate();
  const [createCorrectiveTaxDocument] = useCreateCorrectiveTaxDocumentMutation();
  const [getRequestBody] = useGetCorrectiveTaxDocumentBody();

  const submitCorrectiveTaxDocument = async (formData: AccountingDocumentFormValues) => {
    const correctiveTaxDocumentV2RequestBody = getRequestBody(formData);

    await createCorrectiveTaxDocument({correctiveTaxDocumentV2RequestBody})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(accountingRoutes.invoiceOverview))
      .catch(handleApiError);
  };

  return [submitCorrectiveTaxDocument] as const;
};
