import {DataStatus, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {
  featureFlags,
  handleApiError,
  queryParams,
  useFordUzywaneAuthenticateMutation,
  useGetFordUzywanePlatformQuery,
} from '@omnetic-dms/shared';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {useQueryState} from 'shared';

import {
  PlatformAuthenticationFormFordUzywaneProps,
  PlatformAuthenticationFormFordUzywane,
} from './components/PlatformAuthenticationFormFordUzywane';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

export function PlatformSettingsFordUzywane() {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const params = isNotNil(branchId) ? {branchId} : undefined;
  const {data, isLoading, isError} = useGetFordUzywanePlatformQuery({params});
  const [saveAuthentication] = useFordUzywaneAuthenticateMutation();

  const onSubmitCredentials: PlatformAuthenticationFormFordUzywaneProps['onSubmit'] = (values) =>
    saveAuthentication({
      body: values,
      params,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <DataStatus isLoading={isLoading} isError={isError}>
        {isNotNil(data) && (
          <PlatformAuthenticationFormFordUzywane
            defaultValues={data.credentials}
            onSubmit={onSubmitCredentials}
          />
        )}
        <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.FORD_UZYWANE} />
      </DataStatus>
    </Show>
  );
}
