import {showNotification} from 'platform/components';
import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {FC, PropsWithChildren, useState} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  LinkButton,
  SimpleTable,
  DataType,
  TypographyCard,
  useRouter,
  BillingInformationService,
  BillingInformationListItem,
  useCallApi,
} from '@omnetic-dms/teas';

import {composePath} from 'shared';

export const BillingInformationList: FC<PropsWithChildren<any>> = () => {
  const router = useRouter();
  const [listItems, setListItems] = useState<BillingInformationListItem[]>([]);
  const getBillingInformationList = useCallApi(BillingInformationService.getBillingInformationList);

  !listItems.length &&
    getBillingInformationList()
      .then((result) => {
        setListItems(result.billingInformationListItems ?? []);
      })
      .catch((error) => {
        showNotification.error(error.response?.data?.error?.message || error.toString());
      });

  const columns = [
    {
      Header: i18n.t('entity.customer.labels.billingName'),
      id: 'billingName',
    },
    {
      Header: i18n.t('entity.customer.labels.companyName'),
      id: 'companyName',
    },
    {
      Header: i18n.t('entity.address.labels.city'),
      id: 'city',
    },
    {
      Header: i18n.t('entity.address.labels.street'),
      id: 'street',
    },
    {
      Header: i18n.t('entity.customer.labels.registrationNumber'),
      id: 'registrationNumber',
    },
    {
      Header: null,
      id: 'action',
    },
  ];

  const createRow = (item: BillingInformationListItem): DataType => ({
    id: item.id,
    billingName: item.billingName || '-',
    companyName: item.companyName || '-',
    city: item.city || '-',
    street: item.street || '-',
    registrationNumber: item.registrationNumber || '-',
  });

  return (
    <>
      <ListCardStyled
        title={i18n.t('entity.customer.labels.billingInformation')}
        headerContent={
          <LinkButton
            data-testid={testIds.settings.billingInformation('createNew')}
            href={settingsRoutes.billingInformationNew}
          >
            <Icon value="content/add_circle" />{' '}
            {i18n.t('entity.customer.actions.addBillingInformation')}
          </LinkButton>
        }
      >
        <SimpleTable
          noZebra
          showRowDivider
          highlightRowOnHover
          tdPadding="default"
          columns={columns}
          rows={[{data: listItems?.map(createRow) || []}]}
          data-testid={testIds.settings.billingInformation('table')}
          onRowClick={(row) =>
            router.push(
              composePath(settingsRoutes.billingInformationEdit, {
                params: {id: row.id as string},
              })
            )
          }
        />
      </ListCardStyled>
    </>
  );
};

export const ListCardStyled = styled(TypographyCard)`
  .card-content {
    padding: 0;
  }
`;
