import {showNotification} from 'platform/components';
import {LightboxProps, useLightbox} from 'platform/lightbox';

import {useState} from 'react';

import {isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {useLazyGetSaleVehicleWatermarkPreviewQuery} from '@omnetic-dms/shared';

import {prepareWatermarkImage} from '../utils/prepareWatermarkImage';

export function useVehicleAlbumWatermarkPreview() {
  const [getWatermarkPreview, {isFetching, isLoading}] = useLazyGetSaleVehicleWatermarkPreviewQuery(
    {}
  );

  const [lightboxControls, {onOpen}] = useLightbox('watermarkPreview');

  const [previewImage, setPreviewImage] = useState<string>();

  const openWatermarkPreview = (saleVehiclePhotoId: string) => {
    getWatermarkPreview({saleVehiclePhotoId})
      .then((response) => {
        if (isNotNil(response.data?.content)) {
          setPreviewImage(prepareWatermarkImage(response.data.content));
          onOpen(0);
        }
      })
      .catch(() => {
        showNotification.error(i18n.t('general.labels.somethingWentWrong'));
      });
  };

  const getLightboxProps = (): Omit<LightboxProps, 'data-testid'> => ({
    controls: lightboxControls,
    images: [
      {
        id: 'watermarkPreview',
        url: previewImage?.toString() ?? '',
      },
    ] as const,
  });

  return {
    isLoading: isFetching || isLoading,
    getLightboxProps,
    openWatermarkPreview,
  };
}
