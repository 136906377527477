import {
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Heading, Space, VStack} from 'platform/foundation';
import {array, boolean, object} from 'yup';

import {useSelector} from 'react-redux';

import {defaultTo, isNil, not, pipe, reject} from 'ramda';
import {isNotNilOrEmpty, isNumber, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  selectActiveBranchId,
  useCurrencies,
  useGetEmployeeMechanicsByBranchQuery,
  useGetMinorUnitMaterialQuery,
  useGetSettingsServiceOrderVariantsQuery,
  useGetTenantQuery,
  usePutMinorUnitMaterialMutation,
} from '@omnetic-dms/shared';

import {yupNumber, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

type FormType = {
  enabled: boolean;
  orderVariantIds: string[];
  amountType: 'MINORUNITFROMMATERIALLABOUR' | 'MINORUNITFROMLABOUR' | 'MINORUNITFROMMATERIAL';
  amountValue: number;
  isMaxValue: boolean;
  maxValue: number;
  mechanicId: string;
};

const rejectNullish = pipe<[(string | null)[] | undefined], (string | null)[], string[]>(
  defaultTo([]),
  reject(isNil)
);

export function WorkshopMinorUnitMaterial() {
  const branchId = useSelector(selectActiveBranchId);
  const {getCurrencySymbol} = useCurrencies();

  const {data: {currency: defaultCurrency} = {}} = useGetTenantQuery();
  const {data, isLoading, isError} = useGetMinorUnitMaterialQuery();
  const [putMinorUnitMaterial] = usePutMinorUnitMaterialMutation();
  const {data: mechanicOptions, isLoading: isMechanicOptionsLoading} =
    useGetEmployeeMechanicsByBranchQuery({branchId});
  const {data: orderVariantsOptions, isLoading: isOrderVariantsLoading} =
    useGetSettingsServiceOrderVariantsQuery();

  const handleSubmit: FormSubmitHandler<FormType> = async (data) => {
    const {enabled, amountValue, ...body} = data;

    await putMinorUnitMaterial({
      body: enabled ? {...body, amountValue: amountValue / 100} : undefined,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.workshopMinorUnitMaterial.labels.minorUnitMaterial'),
  };

  return (
    <SettingsTemplate
      header={header}
      data-testid={testIds.settings.workshopMinorUnitMaterial('page')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<FormType>
          schema={schema}
          onSubmit={handleSubmit}
          defaultValues={{
            enabled: isNotNilOrEmpty(data),
            amountType: data?.amountType ?? 'MINORUNITFROMMATERIAL',
            amountValue: isNumber(data?.amountValue) ? Number(data?.amountValue) * 100 : undefined,
            isMaxValue: data?.isMaxValue ?? false,
            maxValue: data?.maxValue ?? undefined,
            mechanicId: data?.mechanicId ?? undefined,
            orderVariantIds: rejectNullish(data?.orderVariantIds),
          }}
        >
          {(control, formApi) => (
            <>
              <SettingsSection>
                <Card
                  variant="inlineWhite"
                  title={i18n.t(
                    'entity.workshopMinorUnitMaterial.labels.chargingForMinorUnitMaterial'
                  )}
                  control={{
                    value: formApi.watch('enabled'),
                    type: 'switch',
                    onChange: (value) => formApi.setValue('enabled', value),
                  }}
                  isExpanded={formApi.watch('enabled')}
                  data-testid={testIds.settings.workshopMinorUnitMaterial('card')}
                >
                  <VStack spacing={4}>
                    <FormField
                      control={control}
                      name="orderVariantIds"
                      type="multiChoice"
                      options={orderVariantsOptions}
                      isLoading={isOrderVariantsLoading}
                      label={i18n.t('entity.workshopMinorUnitMaterial.labels.orderVariant')}
                      helperText={i18n.t(
                        'entity.workshopMinorUnitMaterial.labels.orderVariantHelper'
                      )}
                      isRequired
                      isNotClearable
                      data-testid={testIds.settings.workshopMinorUnitMaterial('orderVariant')}
                    />
                    <Card variant="inlineGrey">
                      <VStack spacing={4}>
                        <Heading size={4}>{i18n.t('general.labels.amount')}</Heading>
                        <FormField
                          control={control}
                          name="amountType"
                          type="radio"
                          direction="column"
                          spacing={3}
                          options={[
                            {
                              label: i18n.t(
                                'entity.workshopMinorUnitMaterial.labels.unitFromMaterial'
                              ),
                              value: 'MINORUNITFROMMATERIAL',
                            },
                            {
                              label: i18n.t(
                                'entity.workshopMinorUnitMaterial.labels.unitFromLabour'
                              ),
                              value: 'MINORUNITFROMLABOUR',
                            },
                            {
                              label: i18n.t(
                                'entity.workshopMinorUnitMaterial.labels.unitFromMaterialAndLabour'
                              ),
                              value: 'MINORUNITFROMMATERIALLABOUR',
                            },
                          ]}
                          data-testid={testIds.settings.workshopMinorUnitMaterial('amountType')}
                        />
                        <Box width={82}>
                          <FormField
                            control={control}
                            name="amountValue"
                            type="number"
                            isStepperVisible
                            minStepperValue={0}
                            maxStepperValue={100}
                            data-testid={testIds.settings.workshopMinorUnitMaterial('amountValue')}
                          />
                        </Box>
                      </VStack>
                    </Card>
                    <FormField
                      control={control}
                      name="isMaxValue"
                      type="checkbox"
                      label={i18n.t('entity.workshopMinorUnitMaterial.labels.enableMaximumAmount', {
                        currency: getCurrencySymbol(defaultCurrency),
                      })}
                      data-testid={testIds.settings.workshopMinorUnitMaterial('isMaxValue')}
                    />
                    <Box width={92}>
                      <FormField
                        control={control}
                        name="maxValue"
                        type="number"
                        isDisabled={not(formApi.watch('isMaxValue'))}
                        helperText={i18n.t(
                          'entity.workshopMinorUnitMaterial.labels.amountMustNotExceedAmountSelectedPerOrder'
                        )}
                        minStepperValue={0}
                        data-testid={testIds.settings.workshopMinorUnitMaterial('maxValue')}
                      />
                    </Box>
                    <Box width={92}>
                      <FormField
                        control={control}
                        name="mechanicId"
                        type="choice"
                        options={mechanicOptions}
                        isLoading={isMechanicOptionsLoading}
                        label={i18n.t('entity.workshopMinorUnitMaterial.labels.mechanic')}
                        helperText={i18n.t(
                          'entity.workshopMinorUnitMaterial.labels.minorUnitMaterialChargeToTheSelectedMechanic'
                        )}
                        data-testid={testIds.settings.workshopMinorUnitMaterial('mechanicId')}
                      />
                    </Box>
                  </VStack>
                </Card>
              </SettingsSection>
              <Space vertical={20} />
              <SettingsFooter
                actions={[
                  {
                    control,
                    type: 'form-button',
                    buttonType: 'submit',
                    title: i18n.t('general.actions.saveChanges'),
                  },
                ]}
                data-testid={testIds.settings.workshopMinorUnitMaterial('saveChanges')}
              />
            </>
          )}
        </Form>
      </DataStatus>
    </SettingsTemplate>
  );
}

const schema = object({
  orderVariantIds: array()
    .of(yupString)
    .when('enabled', {
      is: isTrue,
      then: array().of(yupString).min(1, i18n.t('general.errors.mixed.required')).required(),
      otherwise: array().of(yupString).optional(),
    }),
  amountType: yupString.when('enabled', {
    is: isTrue,
    then: yupString.required(),
    otherwise: yupString.optional(),
  }),
  amountValue: yupNumber.when('enabled', {
    is: isTrue,
    then: yupNumber.min(0).max(100).required(),
    otherwise: yupNumber.optional(),
  }),
  isMaxValue: boolean().optional(),
  maxValue: yupNumber.min(0).optional(),
  mechanicId: yupString,
});
