import {PlatformCode} from '@omnetic-dms/shared';

import type {Advertisement} from '../types/Advertisement';
import type {Autocaris} from '../types/Autocaris';
import type {AutocarisAuthenticateRequestBody} from '../types/AutocarisAuthenticateRequestBody';
import type {Autosoft} from '../types/Autosoft';
import type {AutosoftLink} from '../types/AutosoftLink';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {EditTipCarsClientDataRequestBody} from '../types/EditTipCarsClientDataRequestBody';
import type {Facebook} from '../types/Facebook';
import type {FacebookAuthenticateRequestBody} from '../types/FacebookAuthenticateRequestBody';
import type {FacebookLink} from '../types/FacebookLink';
import type {MobileDe} from '../types/MobileDe';
import type {MobileDeAuthenticateRequestBody} from '../types/MobileDeAuthenticateRequestBody';
import type {MyWeb} from '../types/MyWeb';
import type {MyWebAuthenticateRequestBody} from '../types/MyWebAuthenticateRequestBody';
import type {MyWebLink} from '../types/MyWebLink';
import type {PatchVehicleAdvertisingSettingsRequestBody} from '../types/PatchVehicleAdvertisingSettingsRequestBody';
import type {PatchVehicleWindshieldAdvertisingSettingsRequestBody} from '../types/PatchVehicleWindshieldAdvertisingSettingsRequestBody';
import type {Platform} from '../types/Platform';
import type {SAuto} from '../types/SAuto';
import type {SAutoAuthenticateRequestBody} from '../types/SAutoAuthenticateRequestBody';
import type {TipCars} from '../types/TipCars';
import type {TipCarsAuthenticateRequestBody} from '../types/TipCarsAuthenticateRequestBody';
import type {TipCarsClientData} from '../types/TipCarsClientData';
import type {VehicleAdvertisingSettings} from '../types/VehicleAdvertisingSettings';
import type {VehiclePlatformAdvertisingSettings} from '../types/VehiclePlatformAdvertisingSettings';
import type {VehiclePlatformAdvertisingSettingsRequestBody} from '../types/VehiclePlatformAdvertisingSettingsRequestBody';
import type {VehicleWindshieldAdvertisingSettings} from '../types/VehicleWindshieldAdvertisingSettings';
import {request as __request} from '../utils/request';

export class PublishingService {
  /**
   * @returns Advertisement
   * @throws ApiError
   */
  public static getAdvertisementList({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<Array<Advertisement>> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/advertisements',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns VehicleAdvertisingSettings
   * @throws ApiError
   */
  public static editGeneralSettings({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: PatchVehicleAdvertisingSettingsRequestBody;
  }): CancelablePromise<VehicleAdvertisingSettings> {
    return __request({
      method: 'PATCH',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VehicleAdvertisingSettings
   * @throws ApiError
   */
  public static getGeneralSettings({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<VehicleAdvertisingSettings> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns VehiclePlatformAdvertisingSettings
   * @throws ApiError
   */
  public static editPlatformSettings({
    vehicleId,
    platformCode,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
    requestBody?: VehiclePlatformAdvertisingSettingsRequestBody;
  }): CancelablePromise<VehiclePlatformAdvertisingSettings> {
    return __request({
      method: 'PATCH',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platform/{platformCode}',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VehiclePlatformAdvertisingSettings
   * @throws ApiError
   */
  public static getPlatformSettings({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<VehiclePlatformAdvertisingSettings> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platform/{platformCode}',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns VehicleWindshieldAdvertisingSettings
   * @throws ApiError
   */
  public static editWindshieldSettings({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: PatchVehicleWindshieldAdvertisingSettingsRequestBody;
  }): CancelablePromise<VehicleWindshieldAdvertisingSettings> {
    return __request({
      method: 'PATCH',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/windshield',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VehicleWindshieldAdvertisingSettings
   * @throws ApiError
   */
  public static getWindshieldSettings({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<VehicleWindshieldAdvertisingSettings> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/windshield',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static autocarisAuthenticate({
    authorization,
    requestBody,
    branchId,
  }: {
    authorization?: string;
    requestBody?: AutocarisAuthenticateRequestBody;
    branchId?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/autocaris/authenticate',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static facebookAuthenticate({
    authorization,
    requestBody,
    branchId,
  }: {
    authorization?: string;
    requestBody?: FacebookAuthenticateRequestBody;
    branchId?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/facebook/authenticate',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static mobileDeAuthenticate({
    authorization,
    requestBody,
    branchId,
  }: {
    authorization?: string;
    requestBody?: MobileDeAuthenticateRequestBody;
    branchId?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/mobile.de/authenticate',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static myWebAuthenticate({
    authorization,
    requestBody,
    branchId,
  }: {
    authorization?: string;
    requestBody?: MyWebAuthenticateRequestBody;
    branchId?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/myweb/authenticate',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static sAutoAuthenticate({
    authorization,
    requestBody,
    branchId,
  }: {
    authorization?: string;
    requestBody?: SAutoAuthenticateRequestBody;
    branchId?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/sauto/authenticate',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static tipCarsAuthenticate({
    authorization,
    requestBody,
    branchId,
  }: {
    authorization?: string;
    requestBody?: TipCarsAuthenticateRequestBody;
    branchId?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/tipcars/authenticate',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AutosoftLink
   * @throws ApiError
   */
  public static getAutosoftLink({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<AutosoftLink> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sales/publishing/platform/autosoft/link',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns FacebookLink
   * @throws ApiError
   */
  public static getFacebookLink({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<FacebookLink> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sales/publishing/platform/facebook/link',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns MyWebLink
   * @throws ApiError
   */
  public static getMyWebLink({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<MyWebLink> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sales/publishing/platform/myweb/link',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static repeatLastAction({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platform/{platformCode}/repeat',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static disablePlatformForVehicle({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platforms/{platformCode}/disable',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static enablePlatformForVehicle({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platforms/{platformCode}/enable',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static disableVehicleInSale({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/vehicle/{vehicleId}/unpublish',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static enableVehicleSale({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/vehicle/{vehicleId}/publish',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static updateVehicleOnAllPlatforms({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/updateOnAllPlatforms',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static updateVehicleOnPlatform({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platform/{platformCode}/update',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns Autocaris
   * @throws ApiError
   */
  public static getAutocarisPlatform({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<Autocaris> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/autocaris',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns Autosoft
   * @throws ApiError
   */
  public static getAutosoftPlatform({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<Autosoft> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/autosoft',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns Facebook
   * @throws ApiError
   */
  public static getFacebookPlatform({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<Facebook> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/facebook',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns Platform
   * @throws ApiError
   */
  public static getList({
    branchId,
    authorization,
  }: {
    branchId?: string;
    authorization?: string;
  }): CancelablePromise<Array<Platform>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sales/publishing/platform',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns MobileDe
   * @throws ApiError
   */
  public static getMobileDePlatform({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<MobileDe> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/mobile.de',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns MyWeb
   * @throws ApiError
   */
  public static getMyWebPlatform({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<MyWeb> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/myweb',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns SAuto
   * @throws ApiError
   */
  public static getSAutoPlatform({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<SAuto> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/sauto',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static editTipCarsClientData({
    authorization,
    requestBody,
    branchId,
  }: {
    authorization?: string;
    requestBody?: EditTipCarsClientDataRequestBody;
    branchId?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/tipcars/client-data',
      headers: {
        Authorization: authorization,
      },
      query: {
        branchId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns TipCarsClientData
   * @throws ApiError
   */
  public static getTipCarsClientData({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<TipCarsClientData> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/tipcars/client-data',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns TipCars
   * @throws ApiError
   */
  public static getTipCarsPlatform({
    authorization,
    branchId,
  }: {
    authorization?: string;
    branchId?: string;
  }): CancelablePromise<TipCars> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/platform/tipcars',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static disableTenantPlatform({
    platformCode,
    branchId,
    authorization,
  }: {
    platformCode: PlatformCode;
    branchId?: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/{platformCode}/disable',
      path: {
        platformCode,
      },
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static enableTenantPlatform({
    platformCode,
    branchId,
    authorization,
  }: {
    platformCode: PlatformCode;
    branchId?: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/platform/{platformCode}/enable',
      path: {
        platformCode,
      },
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
