import {ICellRenderer, ICellRendererParams} from 'ag-grid-community';
import {Tooltip} from 'platform/components';
import {Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {forwardRef, Ref, useImperativeHandle, useState} from 'react';

import {equals} from 'ramda';

import {parseDate} from 'shared';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {Style} from '../../types/Api';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';
import {getStylesToRender} from '../../utils/getStylesToRender';
import {getUnformattedValue} from '../../utils/getUnformattedValue';
import {getValueToRender} from '../../utils/getValueToRender';

export interface DateCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
}

function DateCellRendererComponent(props: DateCellRendererProps, ref: Ref<ICellRenderer>) {
  const {getTooltipProps, data, node, colDef} = props;
  const className = getCellClassName(colDef);
  const [cellValue, setCellValue] = useState<string>(() => getUnformattedValue(props));
  const [cellStyles, setCellStyles] = useState<Style>(() => getStylesToRender(props));
  const formatDateTime = useDateTimeFormatter();

  useImperativeHandle(ref, () => ({
    refresh: (refreshProps) => {
      const newCellValue = getValueToRender(refreshProps);
      if (newCellValue !== cellValue) {
        setCellValue(newCellValue);
      }
      const newCellStyles = getStylesToRender(refreshProps);
      if (!equals(newCellStyles, cellStyles)) {
        setCellStyles(newCellStyles);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  if (!cellValue) {
    return null;
  }

  return (
    <Tooltip {...tooltipProps} label={formatDateTime('dateTimeLong', parseDate(cellValue))}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className={className}>
        <Text
          color={cellStyles.textColor}
          noWrap={!colDef?.wrapText}
          size="small"
          overflowWrap={colDef?.wrapText ? 'anywhere' : undefined}
        >
          {formatDateTime('dateShort', parseDate(cellValue))}
        </Text>
      </div>
    </Tooltip>
  );
}

export const DateCellRenderer = forwardRef<ICellRenderer, DateCellRendererProps>(
  DateCellRendererComponent
);
