import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Heading, HStack, VStack} from 'platform/foundation';
import {date, object, string} from 'yup';

import {mergeDeepRight} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  Section,
  useGender,
  CountrySelect,
  useGetEmployeePersonalDataQuery,
  usePatchEmployeePersonalDataMutation,
  handleApiError,
  PatchEmployeePersonalDataApiArg,
  usePhoneNumbers,
  useTenant,
} from '@omnetic-dms/shared';

import {getApiDateString, suffixTestId, TestIdProps} from 'shared';

import {useEmployeeUrl} from '../../hooks/useEmployeeUrl';
import {PersonalInfoForm} from './types/personalInfoFormType';
import {getPersonalInfoFormDefaultValues} from './utils/getPersonalInfoFormDefaultValues';

interface PersonalInfoProps extends TestIdProps {}

export function PersonalInfo(props: PersonalInfoProps) {
  const {employeeId} = useEmployeeUrl();

  const {data: employee, isLoading, isError} = useGetEmployeePersonalDataQuery({employeeId});
  const [patchEmployeePersonal] = usePatchEmployeePersonalDataMutation();
  const {countriesOptions} = usePhoneNumbers();
  const {genderOptions} = useGender();

  const onSubmit: FormSubmitHandler<PersonalInfoForm> = async (data) => {
    const patchEmployeeArg: PatchEmployeePersonalDataApiArg = {
      employeeId,
      body: mergeDeepRight(data, {
        personalData: {
          birthdate: data.personalData.birthdate && getApiDateString(data.personalData.birthdate),
        },
        employmentData: {
          startDate:
            data.employmentData.startDate && getApiDateString(data.employmentData.startDate),
          endDate: data.employmentData.endDate && getApiDateString(data.employmentData.endDate),
        },
      }),
    };

    await patchEmployeePersonal(patchEmployeeArg)
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };
  const {tenantPhoneInfo} = useTenant();

  return (
    <Section>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={140}>
        <Form<PersonalInfoForm>
          schema={schema}
          defaultValues={getPersonalInfoFormDefaultValues(tenantPhoneInfo, employee)}
          onSubmit={onSubmit}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <HStack width="100%" spacing={4}>
                <Box flex={1}>
                  <Card title={i18n.t('page.employeeDetail.labels.personalInfo')}>
                    <VStack spacing={4}>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="text"
                            control={control}
                            name="personalData.titleBefore"
                            label={i18n.t('entity.person.labels.titleBefore')}
                            data-testid={suffixTestId('titleBefore', props)}
                          />
                        </Box>
                        <Box flex={2}>
                          <FormField
                            type="text"
                            control={control}
                            name="personalData.firstName"
                            label={i18n.t('entity.person.labels.firstName')}
                            data-testid={suffixTestId('firstName', props)}
                          />
                        </Box>
                        <Box flex={2}>
                          <FormField
                            type="text"
                            control={control}
                            name="personalData.lastName"
                            label={i18n.t('entity.person.labels.lastName')}
                            isRequired
                            data-testid={suffixTestId('lastName', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            type="text"
                            control={control}
                            name="personalData.titleAfter"
                            label={i18n.t('entity.person.labels.titleAfter')}
                            data-testid={suffixTestId('titleAfter', props)}
                          />
                        </Box>
                      </HStack>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="date"
                            control={control}
                            name="personalData.birthdate"
                            label={i18n.t('entity.person.labels.birthdate')}
                            isRelativeDatesHidden
                            maxDate={new Date()}
                            data-testid={suffixTestId('birthdate', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            type="text"
                            control={control}
                            name="personalData.identificationNumber"
                            label={i18n.t('entity.person.labels.personalIdentifier')}
                            data-testid={suffixTestId('identificationNumber', props)}
                          />
                        </Box>
                      </HStack>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="choice"
                            control={control}
                            name="personalData.genderKey"
                            label={i18n.t('entity.person.labels.genderKey')}
                            options={genderOptions}
                            data-testid={suffixTestId('genderKey', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            type="number"
                            control={control}
                            name="personalData.fte"
                            label={i18n.t('entity.person.labels.fte')}
                            data-testid={suffixTestId('fte', props)}
                          />
                        </Box>
                      </HStack>
                      <Separator spacing={0} />
                      <Heading size={4}>{i18n.t('page.employeeDetail.labels.validity')}</Heading>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="date"
                            control={control}
                            name="employmentData.startDate"
                            label={i18n.t('entity.employee.labels.startDate')}
                            isRelativeDatesHidden
                            data-testid={suffixTestId('startDate', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            type="date"
                            control={control}
                            name="employmentData.endDate"
                            label={i18n.t('entity.employee.labels.endDate')}
                            isRelativeDatesHidden
                            data-testid={suffixTestId('endDate', props)}
                          />
                        </Box>
                      </HStack>
                    </VStack>
                  </Card>
                </Box>
                <Box flex={1}>
                  <Card title={i18n.t('page.employeeDetail.labels.permanentAddressOfResidence')}>
                    <VStack spacing={4}>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="text"
                            control={control}
                            name="addressData.addressLine1"
                            label={i18n.t('entity.address.labels.addressLine1')}
                            data-testid={suffixTestId('addressLine1', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            type="text"
                            control={control}
                            name="addressData.addressLine2"
                            label={i18n.t('entity.address.labels.addressLine2')}
                            data-testid={suffixTestId('addressLine2', props)}
                          />
                        </Box>
                      </HStack>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="text"
                            control={control}
                            name="addressData.city"
                            label={i18n.t('entity.address.labels.city')}
                            data-testid={suffixTestId('city', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <HStack spacing={4}>
                            <Box flex={1}>
                              <FormField
                                type="text"
                                control={control}
                                name="addressData.postalCode"
                                label={i18n.t('entity.address.labels.postalCode')}
                                data-testid={suffixTestId('postalCode', props)}
                              />
                            </Box>
                            <Box flex={1}>
                              <CountrySelect
                                control={control}
                                name="addressData.countryCode"
                                label={i18n.t('entity.address.labels.countryCode')}
                                data-testid={suffixTestId('countryCode', props)}
                              />
                            </Box>
                          </HStack>
                        </Box>
                      </HStack>
                      <Separator spacing={0} />
                      <Heading size={4}>
                        {i18n.t('page.employeeDetail.labels.contactInformation')}
                      </Heading>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="email"
                            control={control}
                            name="contactData.email_work"
                            label={i18n.t('entity.employee.labels.workEmail')}
                            data-testid={suffixTestId('workEmail', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            type="email"
                            control={control}
                            name="contactData.email_private"
                            label={i18n.t('entity.employee.labels.privateEmail')}
                            data-testid={suffixTestId('privateEmail', props)}
                          />
                        </Box>
                      </HStack>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            type="phone"
                            control={control}
                            name="contactData.phone_number_work"
                            label={i18n.t('entity.employee.labels.workPhone')}
                            countries={countriesOptions}
                            data-testid={suffixTestId('workPhone', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            type="phone"
                            control={control}
                            name="contactData.phone_number_private"
                            label={i18n.t('entity.employee.labels.privatePhone')}
                            countries={countriesOptions}
                            data-testid={suffixTestId('privatePhone', props)}
                          />
                        </Box>
                      </HStack>
                      <Separator spacing={0} />
                      <FormField
                        type="textarea"
                        control={control}
                        name="note"
                        label={i18n.t('general.labels.note')}
                        data-testid={suffixTestId('note', props)}
                      />
                    </VStack>
                  </Card>
                </Box>
              </HStack>
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  title={i18n.t('general.actions.discard')}
                  onClick={() =>
                    formApi.reset(getPersonalInfoFormDefaultValues(tenantPhoneInfo, employee))
                  }
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  variant="primary"
                  title={i18n.t('general.actions.saveChanges')}
                  data-testid={suffixTestId('save', props)}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </Section>
  );
}

const schema = object({
  personalData: object({
    firstName: string().nullable().optional(),
    lastName: string().nullable().required(),
    titleBefore: string().nullable().optional(),
    titleAfter: string().nullable().optional(),
    genderKey: string().nullable().optional(),
    identificationNumber: string().nullable().optional(),
    birthdate: date().nullable().optional(),
  }),
  employmentData: object({
    startDate: date().nullable().optional(),
    endDate: date().nullable().optional(),
  }),
  addressData: object({
    addressLine1: string().nullable().optional(),
    addressLine2: string().nullable().optional(),
    city: string().nullable().optional(),
    postalCode: string().nullable().optional(),
    countryCode: string().nullable().optional(),
  }),
  contactData: object({
    phone_number_work: object().optional(),
    phone_number_private: object().optional(),
    email_work: string().nullable().optional(),
    email_private: string().nullable().optional(),
  }),
  note: string().nullable().optional(),
});
