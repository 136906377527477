import {NotificationItemData} from 'platform/components';
import {match} from 'ts-pattern';

import {useCallback} from 'react';
import {Trans} from 'react-i18next';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  businessCaseRoutes,
  customerRoutes,
  interestsRoutes,
  vehiclesRoutes,
  workshopRoutes,
} from '@omnetic-dms/routes';

import {composePath, parseDate} from 'shared';

import {useLazyGetServiceCaseByServiceOrderIdQuery} from '../api/metadaWorkshopServiceCaseApi';
import {notificationTypes} from '../constants/notificationTypes';
import {ORDER_TABS} from '../constants/orderTabs';
import {i18n} from '../i18n/i18n';
import {Record, SentToastNotificationResponseBody} from '../types/api/messaging';
import {queryParams} from '../utils/queryParams';

export function useGetNotificationItemData() {
  const [getServiceCaseByServiceOrderId] = useLazyGetServiceCaseByServiceOrderIdQuery();

  const getURLFromRecord = useCallback(
    (record: Record, commentId?: string) =>
      match(record)
        .with({resourceId: 'VEHICLE'}, () =>
          composePath(vehiclesRoutes.detail, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'CUSTOMER'}, () =>
          composePath(customerRoutes.detail, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'BUSINESS_CASE'}, () =>
          composePath(businessCaseRoutes.overview, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'INTEREST'}, () =>
          composePath(interestsRoutes.overview, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'SERVICE_CASE'}, () =>
          composePath(workshopRoutes.serviceCaseDetail, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'SERVICE_ORDER'}, async () => {
          const serviceCaseId = await getServiceCaseByServiceOrderId({serviceOrderId: record.id})
            .unwrap()
            .then((data) => data?.id);

          return isNotNilOrEmpty(serviceCaseId)
            ? composePath(workshopRoutes.serviceCaseDetail, {
                params: {
                  id: serviceCaseId,
                },
                queryParams: {
                  [queryParams.SERVICE_CASE_ORDER_ID]: record.id,
                  [queryParams.SERVICE_CASE_ORDER_TAB]: ORDER_TABS.COMMENTS_NOTES,
                  [queryParams.COMMENT_ID]: commentId,
                },
                isSearchParamsPreserved: false,
              })
            : null;
        })
        .otherwise(always(undefined)),
    [getServiceCaseByServiceOrderId]
  );

  return useCallback(
    (notification: SentToastNotificationResponseBody, userId: string) =>
      match<
        SentToastNotificationResponseBody,
        Promise<NotificationItemData> | NotificationItemData | null
      >(notification)
        .with({type: notificationTypes.PARTICIPATION_ASSIGNEE_ASSIGNED}, async (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.record.name,
          url: await getURLFromRecord(data.payload.record),
          userName: data.payload.actor.name,
          title: (
            <Trans
              i18nKey="general.notifications.labels.participationAssigneeAssigned"
              values={{userName: data.payload.actor.name}}
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.PARTICIPATION_ASSIGNEEE_UNASSIGNED}, async (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.record.name,
          url: await getURLFromRecord(data.payload.record),
          userName: data.payload.actor.name,
          title: (
            <Trans
              i18nKey="general.notifications.labels.participationAssigneeUnassigned"
              values={{userName: data.payload.actor.name}}
              components={{bold: <strong />}}
            />
          ),
        }))
        .with(
          {type: notificationTypes.PARTICIPATION_OWNER_CHANGED, payload: {newOwner: {id: userId}}},
          async (data) => ({
            id: data.id,
            isRead: data.isRead,
            created: parseDate(data.createdAt),
            resource: data.payload.record.name,
            url: await getURLFromRecord(data.payload.record),
            userName: data.payload.actor.name,
            title: (
              <Trans
                i18nKey="general.notifications.labels.participationOwnerAssigned"
                values={{userName: data.payload.actor.name}}
                components={{bold: <strong />}}
              />
            ),
          })
        )
        .with({type: notificationTypes.PARTICIPATION_OWNER_CHANGED}, async (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.record.name,
          url: await getURLFromRecord(data.payload.record),
          userName: data.payload.actor.name,
          title: (
            <Trans
              i18nKey="general.notifications.labels.participationOwnerUnassigned"
              values={{userName: data.payload.actor.name}}
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.VEHICLE_INSPECTION_EXPIRATION_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          icon: 'notification/terminal',
          title: (
            <Trans
              count={data.payload.daysUntilExpiration}
              i18nKey="general.notifications.labels.vehicleInspectionExpirationNotice"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.DATAGRID_CUSTOM_PRESET_SHARED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          icon: 'social/share',
          title: (
            <Trans
              i18nKey="general.notifications.labels.datagridCustomPresetShared"
              values={{
                name: data.payload.sharedByUser.name,
                grid: data.payload.datagridCode,
                presetName: data.payload.datagridPresetTitle,
              }}
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.VEHICLE_NEXT_SERVICE_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          icon: 'action/today',
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          title: (
            <Trans
              count={data.payload.daysUntilNextService}
              i18nKey="general.notifications.labels.vehicleServiceNotice"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.VEHICLE_FILE_OPREATION_FINISHED}, (data) => {
          if (data.payload.operation === 'ROTATE') {
            return {
              id: data.id,
              isRead: data.isRead,
              created: parseDate(data.createdAt),
              resource: data.payload.vehicle.name,
              url: composePath(vehiclesRoutes.photos, {
                params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
              }),
              icon: 'image/photo',
              title: i18n.t('general.notifications.labels.vehiclePhotoRotationFinished'),
            };
          }

          if (data.payload.operation === 'REMOVE_BACKGROUND') {
            return {
              id: data.id,
              isRead: data.isRead,
              created: parseDate(data.createdAt),
              resource: data.payload.vehicle.name,
              url: composePath(vehiclesRoutes.photos, {
                params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
              }),
              icon: 'image/photo',
              title: i18n.t('general.notifications.labels.vehicleBackgroundReplacementFinished'),
            };
          }

          return null;
        })
        .with({type: notificationTypes.VEHICLE_PHOTO_ROTATE_FINISHED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.photos, {
            params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
          }),
          icon: 'image/photo',
          title: i18n.t('general.notifications.labels.vehiclePhotoRotationFinished'),
        }))
        .with({type: notificationTypes.VEHICLE_BACKGROUND_REMOVAL_FINISHED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.photos, {
            params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
          }),
          icon: 'image/photo',
          title: i18n.t('general.notifications.labels.vehicleBackgroundReplacementFinished'),
        }))
        .with({type: notificationTypes.SALE_VEHICLE_BACKGROUND_REMOVAL_FINISHED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.saleVehicle.vehicleName,
          url: composePath(vehiclesRoutes.photos, {
            params: {id: data.payload.saleVehicle.vehicleId, tab: 'vehicle-photos'},
          }),
          icon: 'image/photo',
          title: i18n.t('general.notifications.labels.vehicleBackgroundReplacementFinished'),
        }))
        .with({type: notificationTypes.COMMENT_MENTION}, async (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.record.name,
          url: await getURLFromRecord(data.payload.record, data.payload.commentId),
          userName: data.payload.author.name,
          message: data.payload.commentHtml,
          title: (
            <Trans
              i18nKey="general.notifications.labels.mention"
              values={{user: data.payload.author.name}}
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.COMMENT_RECORD_OWNER}, async (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.record.name,
          url: await getURLFromRecord(data.payload.record, data.payload.commentId),
          userName: data.payload.author.name,
          message: data.payload.commentHtml,
          title: i18n.t('general.notifications.labels.serviceOrderComment', {
            user: data.payload.author.name,
          }),
        }))
        .with({type: notificationTypes.SALE_VEHICLE_RESERVATION_CANCELLED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          icon: 'action/settings',
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          title: i18n.t('general.notifications.labels.saleVehicleReservationCancelled'),
        }))
        .otherwise(always(null)),
    [getURLFromRecord]
  );
}
