import {Card, showNotification} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  handleApiError,
  useAddToAllVehicleMutation,
  useDeletePromotionalPhotoMutation,
  useRemoveInAllVehiclesMutation,
  useResetPositionsToAllVehiclesMutation,
} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

export function PromoPhotosDataGrid() {
  const navigate = useNavigate();
  const [addToAllVehicle] = useAddToAllVehicleMutation();
  const [resetPositionsToAllVehicles] = useResetPositionsToAllVehiclesMutation();
  const [removeInAllVehicles] = useRemoveInAllVehiclesMutation();
  const [deletePromotionalPhoto] = useDeletePromotionalPhotoMutation();

  const handleAddToAllVehicles = useCallback(
    (promotionalPhotoIds: string[], reload: () => void) => {
      addToAllVehicle({
        addToAllVehiclesRequestBody: {promotionalPhotoIds},
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.promoPhotos.notifications.addToAllVehicle'));
          reload();
        })
        .catch(handleApiError);
    },
    [addToAllVehicle]
  );

  const handleResetPositionsToAllVehicles = useCallback(
    (promotionalPhotoIds: string[], reload: () => void) => {
      resetPositionsToAllVehicles({
        resetPositionsToAllVehiclesRequestBody: {promotionalPhotoIds},
      })
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.promoPhotos.notifications.resetPositionsToAllVehicles')
          );
          reload();
        })
        .catch(handleApiError);
    },
    [resetPositionsToAllVehicles]
  );

  const handleRemoveInAllVehicles = useCallback(
    (promotionalPhotoIds: string[], reload: () => void) => {
      removeInAllVehicles({
        removeInAllVehiclesRequestBody: {promotionalPhotoIds},
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.promoPhotos.notifications.removeInAllVehicles'));
          reload();
        })
        .catch(handleApiError);
    },
    [removeInAllVehicles]
  );

  const handleDeletePromotionalPhoto = useCallback(
    (promotionalPhotoIds: string[], reload: () => void) => {
      deletePromotionalPhoto({
        deletePromotionalPhotoRequestBody: {promotionalPhotoIds},
      })
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.promoPhotos.notifications.deletePromotionalPhoto')
          );
          reload();
        })
        .catch(handleApiError);
    },
    [deletePromotionalPhoto]
  );

  const actionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, refreshData}) => {
      const promotionalPhotoIds = Array.isArray(rowId) ? rowId : [rowId];

      switch (actionKey) {
        case 'add_to_all_vehicles':
          handleAddToAllVehicles(promotionalPhotoIds, refreshData);

          break;
        case 'reset_positions_in_all_vehicles':
          handleResetPositionsToAllVehicles(promotionalPhotoIds, refreshData);

          break;
        case 'remove_from_all_vehicles':
          handleRemoveInAllVehicles(promotionalPhotoIds, refreshData);

          break;
        case 'delete':
          handleDeletePromotionalPhoto(promotionalPhotoIds, refreshData);

          break;
        case 'edit':
          navigate(
            composePath(settingsRoutes.advertisingPromoPhotosDetail, {
              params: {
                id: String(rowId),
              },
            })
          );

          break;
      }
    },
    [
      handleAddToAllVehicles,
      handleResetPositionsToAllVehicles,
      handleRemoveInAllVehicles,
      handleDeletePromotionalPhoto,
      navigate,
    ]
  );

  return (
    <Card
      actions={[
        {
          type: 'button',
          variant: 'link',
          onClick: () => navigate(settingsRoutes.advertisingPromoPhotosCreate),
          leftIcon: 'content/add',
          title: i18n.t('general.actions.addNew'),
        },
      ]}
      title={i18n.t('page.salesSettings.labels.promotionalPhotosList')}
    >
      <Box height={125}>
        <DataGrid gridCode="promo-photo" actionCallback={actionCallback} />
      </Box>
    </Card>
  );
}
