import {DropdownItem, openDropdown} from 'platform/components';
import {Box, Clickable, GridItem, Show} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {CSSProperties} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useAuditImages} from '../hooks/useAuditImages';
import {useConditionContext} from '../hooks/useConditionContext';
import {useConditionUploadContext} from '../hooks/useConditionUploadContext';
import {useRotateImages} from '../hooks/useRotateImages';
import {ImagePreview} from './ImagePreview';
import {UploadControl} from './UploadControl';
import {UploadImage} from './UploadImage';

interface UploadedImagesProps extends TestIdProps {
  isMultiple?: boolean;
  isAlwaysShowUpload?: boolean;
  ratio: CSSProperties['aspectRatio'];
}

export function AuditImages(props: UploadedImagesProps) {
  const {uploadImages, categoryId, paramDefinitionId, handleDeleteImage} =
    useConditionUploadContext();
  const {isDisabledForUser} = useConditionContext();

  const {assets, lightboxUploadImages, lightboxAssets} = useAuditImages();

  const {handleImageRotate, isImageProcessingInProgress} = useRotateImages({
    categoryId,
    paramDefinitionId,
  });

  const [lightboxAssetsGalleryControls, {onOpen: onAssetsGalleryOpen}] = useLightbox(
    `auditImagesAssets-${categoryId}-${paramDefinitionId}`
  );
  const [lightboxUploadImagesGalleryControls, {onOpen: onUploadImagesGalleryOpen}] = useLightbox(
    `auditImages-${categoryId}-${paramDefinitionId}`
  );

  const getDropdownItems = (imageId: string, isUploaded?: boolean) => (
    <>
      <DropdownItem
        label={i18n.t('general.actions.rotateLeft')}
        leftIcon="action/rotateLeft"
        onClick={() => handleImageRotate(imageId, 270)}
      />
      <DropdownItem
        label={i18n.t('general.actions.rotateRight')}
        leftIcon="action/rotateRight"
        onClick={() => handleImageRotate(imageId, 90)}
      />
      <DropdownItem
        label={i18n.t('general.actions.delete')}
        leftIcon="action/delete"
        onClick={() => handleDeleteImage(imageId, isUploaded)}
      />
    </>
  );

  const onPhotoClick = (imageId: string | null) => () => {
    if (isNotNilOrEmpty(lightboxUploadImages)) {
      const imageIndex = lightboxUploadImages.findIndex((item) => item.id === imageId);
      onUploadImagesGalleryOpen(imageIndex ?? 0);
    }
    if (isNotNilOrEmpty(lightboxAssets)) {
      const imageIndex = lightboxAssets.findIndex((item) => item.id === imageId);
      onAssetsGalleryOpen(imageIndex ?? 0);
    }
  };

  if (isNilOrEmpty(assets) && isNilOrEmpty(uploadImages)) {
    return (
      <GridItem span={1}>
        <Box width="100%" height="100%" overflow="hidden" position="relative" ratio={props.ratio}>
          <UploadControl
            type="card"
            isMultiple={props.isMultiple}
            data-testid={suffixTestId('cardUpload', props)}
          />
        </Box>
      </GridItem>
    );
  }

  return (
    <>
      {assets?.map((asset) => (
        <GridItem key={asset.imageId ?? asset.uploadedAssetsId} span={1}>
          <Clickable
            onContextMenu={(e) => {
              if (isDisabledForUser) {
                return;
              }
              asset.imageId && openDropdown(e, getDropdownItems(asset.imageId));
            }}
            isDisabled={isDisabledForUser}
          >
            <Box
              width="100%"
              height="100%"
              borderRadius="small"
              overflow="hidden"
              position="relative"
              ratio={props.ratio}
            >
              <ImagePreview
                isImageRotationInProgress={isImageProcessingInProgress(asset.imageId)}
                resizeUrl={asset.resizeUrl}
                onOpenClick={onPhotoClick(asset.imageId)}
                ratio={props.ratio}
                data-testid={suffixTestId(`uploadedImage-preview-${asset.imageId}`, props)}
              />
            </Box>
          </Clickable>
        </GridItem>
      ))}
      {uploadImages.map((image) => (
        <GridItem key={image.imageId} span={1}>
          <Clickable
            onContextMenu={(e) => {
              if (isDisabledForUser) {
                return;
              }
              image.imageId && openDropdown(e, getDropdownItems(image.imageId, true));
            }}
            isDisabled={isDisabledForUser}
          >
            <Box width="100%" height="100%" position="relative" ratio={props.ratio}>
              <UploadImage
                image={image}
                ratio={props.ratio}
                isImageRotationInProgress={isImageProcessingInProgress(image.imageId)}
                onOpenClick={onPhotoClick(image.imageId)}
                data-testid={suffixTestId(`uploadedImage-${image.imageId}`, props)}
              />
            </Box>
          </Clickable>
        </GridItem>
      ))}
      <Show when={isTrue(props.isAlwaysShowUpload)}>
        <GridItem span={1}>
          <Box width="100%" height="100%" ratio={props.ratio}>
            <UploadControl
              type="card"
              isMultiple={props.isMultiple}
              data-testid={suffixTestId('cardUpload', props)}
            />
          </Box>
        </GridItem>
      </Show>

      <Lightbox
        data-testid={suffixTestId('audit-assetsGallery', props)}
        controls={lightboxAssetsGalleryControls}
        images={lightboxAssets}
      />

      <Lightbox
        data-testid={suffixTestId('audit-uploadImagesGallery', props)}
        controls={lightboxUploadImagesGalleryControls}
        images={lightboxUploadImages}
      />
    </>
  );
}
