import {openConfirmDialog} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';

import {useNavigate} from 'react-router-dom';

import {isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useDeleteArticleCategoryMutation} from '@omnetic-dms/shared';

import {composePath} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehouseArticleCategory() {
  const navigate = useNavigate();

  const [deleteArticleCategory] = useDeleteArticleCategoryMutation();

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.category'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newCategory'),
        onClick: () => {
          navigate(settingsRoutes.warehousesArticleCategoryCreate);
        },
        'data-testid': testIds.settings.warehousesArticleCategory('newArticleCategory'),
      },
    ],
  };

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData}) => {
    switch (actionKey) {
      case 'redirectDetail':
      case 'edit':
        if ('id' in rowData && isString(rowData.id)) {
          navigate(
            composePath(settingsRoutes.warehousesArticleCategoryDetail, {
              params: {id: rowData.id},
            })
          );
        }
        break;
      case 'delete':
        if ('id' in rowData) {
          openConfirmDialog({
            text: i18n.t('general.actions.delete'),
            onConfirm: () => {
              deleteArticleCategory({articleCategoryId: rowData.id as string})
                .unwrap()
                .then(() => refreshData())
                .catch(handleApiError);
            },
          });
        }
        break;
    }
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesArticleCategory('template')}
      header={header}
    >
      <DataGrid gridCode="warehouse-article-category" actionCallback={actionCallback} />
    </SettingsTemplate>
  );
}
