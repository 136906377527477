import {ButtonGroup, Card, Flag, FlagProps, IconButton, Parameters} from 'platform/components';
import {HStack, Heading, Link, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {workshopRoutes} from '@omnetic-dms/routes';

import {TestIdProps, composePath, parseDate, suffixTestId} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import i18n from '../../../i18n/i18n';
import {ServiceCampaign} from '../../../types/api/metadaWorkshopServiceCampaign';

interface CampaignCardProps extends TestIdProps {
  data: ServiceCampaign;
  onAdd?: VoidFunction;
  isSuggested?: boolean;
}

export function CampaignCard(props: CampaignCardProps) {
  const formatDateTime = useDateTimeFormatter();

  const isAssignedToOrder = isNotNilOrEmpty(props.data.serviceOrder);

  const flag = match<ServiceCampaign['state'], FlagProps>(props.data.state)
    .with('DONE', () => ({colorScheme: 'green', label: i18n.t('entity.campaigns.labels.done')}))
    .with('CANCELLED', () => ({
      colorScheme: 'neutral',
      label: i18n.t('entity.campaigns.labels.cancelled'),
    }))
    .with('CLOSED', () => ({
      colorScheme: 'red',
      label: i18n.t('entity.campaigns.labels.closed'),
    }))
    .with('RELEASED_NON_ACTIVE', () => ({
      colorScheme: 'orange',
      label: i18n.t('entity.campaigns.labels.releasedNonActive'),
    }))
    .with('ACTIVE', () =>
      match<[boolean, boolean], FlagProps>([Boolean(props.isSuggested), isAssignedToOrder])
        .with([true, false], () => ({
          colorScheme: 'yellow',
          label: i18n.t('entity.campaigns.labels.serviceCampaign'),
        }))
        .with([false, true], () => ({
          colorScheme: 'orange',
          label: i18n.t('general.labels.inProgress'),
        }))
        .otherwise(() => ({
          colorScheme: 'blue',
          label: i18n.t('entity.campaigns.labels.active'),
        }))
    )
    .otherwise(() => ({label: EMPTY_PLACEHOLDER}));

  const parameters = [
    props.data.defectCode ?? EMPTY_PLACEHOLDER,
    `${i18n.t('general.labels.from')}: ${
      props.data.startDate
        ? formatDateTime('dateShort', parseDate(props.data.startDate))
        : EMPTY_PLACEHOLDER
    }`,
    `${i18n.t('general.labels.to')}: ${
      props.data.dueDate
        ? formatDateTime('dateShort', parseDate(props.data.dueDate))
        : EMPTY_PLACEHOLDER
    }`,
    `${i18n.t('entity.campaigns.labels.dateOfRealization')}: ${
      props.data.stateDate
        ? formatDateTime('dateShort', parseDate(props.data.stateDate))
        : EMPTY_PLACEHOLDER
    }`,
  ];

  const href = isNotNil(props.data.serviceOrder?.id)
    ? composePath(workshopRoutes.serviceCaseDetail, {
        params: {id: props.data.serviceOrder?.serviceCaseId ?? ''},
        queryParams: {
          orderId: props.data.serviceOrder?.id,
        },
        isSearchParamsPreserved: false,
      })
    : composePath(workshopRoutes.draftServiceCase, {
        params: {id: props.data.serviceOrder?.serviceCaseId ?? ''},
        queryParams: {
          tab: 'serviceJobs',
        },
        isSearchParamsPreserved: false,
      });

  return (
    <Card variant="inlineGrey" data-testid={suffixTestId('card', props)}>
      <HStack justify="space-between" align="center">
        <VStack spacing={1}>
          <HStack spacing={2} align="center">
            <Heading size={5} data-testid={suffixTestId('name', props)}>
              {props.data.name ?? EMPTY_PLACEHOLDER}
            </Heading>
            <Flag size="small" {...flag} data-testid={suffixTestId('flag', props)} />
          </HStack>
          <Parameters
            color="tertiary"
            parameters={parameters}
            data-testid={suffixTestId('parameters', props)}
          />
        </VStack>
        <ButtonGroup size="small">
          <Show when={isNotNil(props.onAdd) && !isAssignedToOrder}>
            <IconButton
              size="small"
              icon="content/add_circle"
              severity="informational"
              onClick={props.onAdd}
              data-testid={suffixTestId('add', props)}
            />
          </Show>
          <Show when={isAssignedToOrder}>
            <Link
              size="xSmall"
              href={href}
              title={i18n.t('entity.warehouse.actions.viewServiceOrder')}
              target="_blank"
              leftIcon="action/open_in_new"
              data-testid={suffixTestId('open-in-new', props)}
            />
          </Show>
        </ButtonGroup>
      </HStack>
    </Card>
  );
}
