import {
  Alert,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {object, string} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  getOptionsFromWarehouses,
  handleApiError,
  useDuplicateErrorHandler,
  useGetMarketingCodeQuery,
  useGetWarehousesQuery,
  usePatchMarketingCodeMutation,
  usePostMarketingCodeMutation,
} from '@omnetic-dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export type CreateNewMarketingCodeForm = {
  warehouseId: string;
  name: string;
  externalCode: string;
  salesBasePriceMarkup: number;
  warrantyPriceMarkup: number;
};

export function WarehouseMarketingCodeDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();
  const {
    data: marketingCode,
    isLoading: isMarketingCodeLoading,
    isError: isMarketingCodeError,
  } = useGetMarketingCodeQuery({marketingCodeId: id ?? ''}, {skip: isNil(id)});
  const [createMarketingCode] = usePostMarketingCodeMutation();
  const [updateMarketingCode] = usePatchMarketingCodeMutation();

  const isLoading = isNotNil(id)
    ? isWarehousesLoading || isMarketingCodeLoading
    : isWarehousesLoading;
  const isError = isNotNil(id) ? isWarehousesError || isMarketingCodeError : isWarehousesError;

  const handleSubmit: FormSubmitHandler<CreateNewMarketingCodeForm> = async (data) => {
    if (isNotNil(id)) {
      return await updateMarketingCode({
        body: {
          warehouseId: data.warehouseId,
          name: data.name,
          externalCode: data.externalCode,
          salesBasePriceMarkup: data.salesBasePriceMarkup,
          warrantyPriceMarkup: data.warrantyPriceMarkup,
        },
        marketingCodeId: id,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.warehouse.labels.warehouseAccountUpdated'));
          handleNavigateBack();
        })
        .catch(handleApiError);
    }
    await createMarketingCode(data)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.labels.warehouseAccountCreated'));
        handleNavigateBack();
      })
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesMarketingCode);
  };

  const header: SettingsTemplateHeader = {
    title: isNil(id) ? i18n.t('entity.warehouse.labels.newMarketingCode') : marketingCode?.name,
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.workshopPresetsCategory('template')}
      header={header}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<CreateNewMarketingCodeForm>
          schema={FormSchema}
          defaultValues={marketingCode}
          onSubmit={handleSubmit}
        >
          {(control) => (
            <VStack spacing={4} width="50%">
              <Show when={duplicateError}>
                <Alert
                  variant="error"
                  title={i18n.t('entity.warehouse.labels.marketingCodeAlreadyExists')}
                  data-testid={testIds.settings.warehousesMarketingCodeDetail(
                    'marketingCodeAlreadyExists'
                  )}
                />
              </Show>
              <Grid columns={2}>
                <FormField
                  isNotClearable
                  isRequired
                  isDisabled={isNotNil(id)}
                  control={control}
                  type="choice"
                  name="warehouseId"
                  label={i18n.t('entity.warehouse.labels.warehouse')}
                  options={getOptionsFromWarehouses(warehouses)}
                  data-testid={testIds.settings.warehousesMarketingCodeDetail('warehouseId')}
                />
              </Grid>
              <Separator />
              <Grid columns={2}>
                <FormField
                  isRequired
                  control={control}
                  type="text"
                  name="name"
                  label={i18n.t('general.labels.name')}
                  data-testid={testIds.settings.warehousesMarketingCodeDetail('name')}
                />
                <FormField
                  control={control}
                  type="text"
                  name="externalCode"
                  label={i18n.t('general.labels.externalCode')}
                  data-testid={testIds.settings.warehousesMarketingCodeDetail('externalCode')}
                />
              </Grid>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="number"
                  isStepperVisible
                  name="salesBasePriceMarkup"
                  label={`${i18n.t('entity.warehouse.labels.salesBasePriceMarkup')} (%)`}
                  data-testid={testIds.settings.warehousesMarketingCodeDetail(
                    'salesBasePriceMarkup'
                  )}
                />
                <FormField
                  control={control}
                  type="number"
                  isStepperVisible
                  name="warrantyPriceMarkup"
                  label={`${i18n.t('entity.warehouse.labels.warrantyPriceMarkup')} (%)`}
                  data-testid={testIds.settings.warehousesMarketingCodeDetail(
                    'warrantyPriceMarkup'
                  )}
                />
              </Grid>
              <Space fillAvailable />
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    variant: 'secondary',
                    title: isNil(id)
                      ? i18n.t('general.actions.discard')
                      : i18n.t('general.actions.discardChanges'),
                    onClick: handleNavigateBack,
                    'data-testid': testIds.settings.workshopPresetsCategoryDetail('discard'),
                  },
                  {
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    variant: 'primary',
                    title: isNil(id)
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.saveChanges'),
                    'data-testid': testIds.settings.workshopPresetsCategoryDetail('save'),
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
      </DataStatus>
    </SettingsTemplate>
  );
}

const FormSchema = object({
  warehouseId: string().required(),
  name: string().required(),
});
