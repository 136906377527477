import {DataStatus} from 'platform/components';
import {HStack} from 'platform/foundation';

import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {clone, map, mergeDeepRight} from 'ramda';

import {handleApiError, selectTenant} from '@omnetic-dms/shared';
import {
  InlineEditingContextGlobalUpdateMethod,
  InlineEditingProvider,
  BillingInformationService,
  fetchBillingInformation,
  BillingInformation,
  CreateBillingInformationRequestBody,
  PutBillingInformationRequestBody,
  Tenant,
  useCallApi,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {BillingInfoCache} from '../types';
import {BankInformation} from './BankInformation';
import {BillingContactInformation} from './BillingContactInformation';

interface BillingInformationProps {
  billingInformationId?: string;
  setData?: (data: CreateBillingInformationRequestBody) => void;
}

export const BillingInformationDetail: FC<BillingInformationProps> = ({
  billingInformationId,
  setData,
}) => {
  const dispatch = useThunkDispatch();
  const [loading, setIsLoading] = useState(true);
  const {data: tenant} = useSelector(selectTenant);
  const cachedBillingInfo = useRef<BillingInfoCache>({
    // eslint-disable-next-line no-restricted-syntax
    data: {deputyPersons: []} as unknown as PutBillingInformationRequestBody,
    tenant: tenant as Tenant,
  });
  const isEdit = useMemo(() => !!billingInformationId, [billingInformationId]);

  const putBillingInformation = useCallApi(BillingInformationService.putBillingInformation);

  useEffect(() => {
    if (!isEdit) {
      setIsLoading(false);
    }
  }, [isEdit]);

  useEffect(() => {
    if (billingInformationId) {
      dispatch(fetchBillingInformation(billingInformationId))
        .unwrap()
        .then((billingInformationData) => {
          cachedBillingInfo.current.data = clone(
            billingInformationData
          ) as PutBillingInformationRequestBody;
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, billingInformationId]);

  const updateMethod = (data: PutBillingInformationRequestBody): Promise<BillingInformation> => {
    cachedBillingInfo.current.data = mergeDeepRight(
      cachedBillingInfo.current.data,
      data
    ) as PutBillingInformationRequestBody;
    setData && setData(cachedBillingInfo.current.data as CreateBillingInformationRequestBody);

    if (isEdit && billingInformationId) {
      const billing = cachedBillingInfo.current?.data;

      billing.bankAccounts = map((ba) => {
        ba.swift = ba.swift || null;
        ba.iban = ba.iban || null;
        return ba;
      }, billing.bankAccounts);

      // TODO: Change on BE might be needed (schemas) - isPrimary, personalIdCardType
      const response = putBillingInformation({
        billingInformationId,
        requestBody: billing,
      });

      response.catch((error) => {
        handleApiError(error.response);
      });

      return response;
    }
    return Promise.resolve(cachedBillingInfo.current.data as BillingInformation);
  };

  return (
    <section>
      <InlineEditingProvider
        value={{
          updateMethod: updateMethod as InlineEditingContextGlobalUpdateMethod,
          editedValue: cachedBillingInfo.current,
        }}
      >
        <DataStatus isLoading={loading}>
          <HStack spacing={4}>
            <BillingContactInformation />
            <BankInformation />
          </HStack>
        </DataStatus>
      </InlineEditingProvider>
    </section>
  );
};
