import {Checkbox, DataStatus, Table} from 'platform/components';
import {Box, HStack, Heading, Scroll, getSize} from 'platform/foundation';

import {isEmpty} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useGetRoleQuery,
  useGrantAllPermissionMutation,
  useRevokeAllPermissionMutation,
} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

import {ResourceItem} from './components/ResourceItem';
import {useGetACLData} from './hooks/useGetACLData';
import {filterAndMapResourcesBySearchString} from './utils/filterAndMapResourcesBySearchString';
import {getResourceCheckboxValues} from './utils/getResourceCheckboxValues';

interface AccessControlListParams {
  roleId: string;
  searchString: string | null;
}

const HEADER_HEIGHT = getSize(37);
const FORM_HEIGHT = getSize(44);
const FOOTER_HEIGHT = getSize(35);

export function AccessControlList(props: AccessControlListParams) {
  const {roleId} = useRequiredParams();

  const [grantAllPermission] = useGrantAllPermissionMutation();
  const [revokeAllPermission] = useRevokeAllPermissionMutation();

  const {data: role} = useGetRoleQuery({roleId});
  const isSystem = role?.system ?? false;

  const {tableData, isError, isLoading} = useGetACLData(props.roleId);
  const filteredResources = filterAndMapResourcesBySearchString(props.searchString, tableData);

  const firstLevelCheckboxValues = filteredResources.map(getResourceCheckboxValues);
  const hasAllResourcesChecked = firstLevelCheckboxValues.every((item) => item.isChecked);
  const hasResourcesIndeterminate =
    !hasAllResourcesChecked &&
    firstLevelCheckboxValues.some((item) => item.isIndeterminate || item.isChecked);

  const handleCheckAll = (isGranting: boolean) => {
    const checkboxAction = isGranting ? grantAllPermission : revokeAllPermission;

    checkboxAction({roleId: props.roleId}).unwrap().catch(handleApiError);
  };

  return (
    <DataStatus
      spacing={10}
      isEmpty={isEmpty(filteredResources)}
      isLoading={isLoading}
      isError={isError}
    >
      <Scroll height={`calc(100vh - ${HEADER_HEIGHT} - ${FORM_HEIGHT} - ${FOOTER_HEIGHT})`} always>
        <Table
          data-testid={testIds.settings.roleManagementDetail('aclTable')}
          columns={[
            {
              element: (
                <Box paddingHorizontal={2} paddingVertical={4}>
                  <HStack align="center" spacing={21}>
                    <Checkbox
                      value={hasAllResourcesChecked}
                      isIndeterminate={hasResourcesIndeterminate}
                      onChange={handleCheckAll}
                      isDisabled={isSystem}
                      data-testid={testIds.settings.roleManagementDetail('select-all')}
                    />
                    <Box
                      paddingHorizontal={2}
                      borderLeft="solid 1px"
                      borderColor="general.separator"
                    >
                      <Heading color="secondary" size={5}>
                        {i18n.t('page.settings.role.tabs.label.permissions')}
                      </Heading>
                    </Box>
                  </HStack>
                </Box>
              ),
              width: '40%',
            },
            {
              element: (
                <Box paddingHorizontal={2} borderLeft="solid 1px" borderColor="general.separator">
                  <HStack>
                    <Heading color="secondary" size={5}>
                      {i18n.t('general.labels.description')}
                    </Heading>
                  </HStack>
                </Box>
              ),
              width: '30%',
            },
            {
              element: (
                <Box paddingHorizontal={2} borderLeft="solid 1px" borderColor="general.separator">
                  <HStack>
                    <Heading color="secondary" size={5}>
                      {i18n.t('acl.permission.condition')}
                    </Heading>
                  </HStack>
                </Box>
              ),
              width: '30%',
            },
          ]}
        >
          {filteredResources?.map((resource) => (
            <ResourceItem
              data={resource}
              data-testid={testIds.settings.roleManagementDetail('aclTable')}
              key={resource.id}
            />
          ))}
        </Table>
      </Scroll>
    </DataStatus>
  );
}
