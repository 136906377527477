import {Dropdown, DropdownItem, IconButton, openDeleteDialog} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {ConsentType, handleApiError, useDeleteConsentTypeMutation} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

interface ConsentTypesListActionsProps {
  type: ConsentType;
}

export function ConsentTypesListActions({type: {id}}: ConsentTypesListActionsProps) {
  const [deleteConsentType] = useDeleteConsentTypeMutation();
  const navigate = useNavigate();

  return (
    <>
      <Dropdown dropdownControl={<IconButton icon="navigation/more_vert" size="small" />}>
        <DropdownItem
          onClick={() => navigate(composePath(settingsRoutes.gdprConsentTypesEdit, {params: {id}}))}
          label={i18n.t('general.actions.edit')}
          leftIcon="image/edit"
        />
        <DropdownItem
          onClick={() =>
            openDeleteDialog({
              onConfirm: () =>
                deleteConsentType({consentTypeId: id}).unwrap().catch(handleApiError),
            })
          }
          label={i18n.t('general.actions.delete')}
          leftIcon="action/delete"
        />
      </Dropdown>
    </>
  );
}
