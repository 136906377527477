import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  UserResponseBody,
  handleApiError,
  useGetUsersQuery,
  usePutEmployeeLinkMutation,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

type FormValues = {
  userId: string;
};

interface PairUserFormProps extends RequiredTestIdProps {
  employeeId: string;
}

export function PairUserForm(props: PairUserFormProps) {
  const [linkToUser] = usePutEmployeeLinkMutation();
  const {data: users, isLoading} = useGetUsersQuery();

  const userOptions = users?.map(userToOption) || [];

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    await linkToUser({employeeId: props.employeeId, userId: values.userId})
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  return (
    <Form<FormValues> schema={schema} onSubmit={handleSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="userId"
            type="choice"
            options={userOptions}
            isLoading={isLoading}
            isSearchable
            menuInPortal
            isRequired
            label={i18n.t('entity.user.labels.emailUsername')}
            data-testid={suffixTestId('pair-user', props)}
          />

          <ButtonGroup align="right">
            <Button
              variant="secondary"
              title={i18n.t('general.actions.cancel')}
              onClick={closeCurrentDialog}
              data-testid={suffixTestId('pair-cancel', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.confirm')}
              data-testid={suffixTestId('pair-confirm', props)}
              variant="primary"
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const userToOption = (user: UserResponseBody) => ({
  value: user.id,
  label: reject(isNilOrEmpty, [user.firstName, user.lastName]).join(' ') || user.email,
});

const schema = object({
  userId: yupString.required(),
});
