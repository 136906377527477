import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

type PaymentType = 'BANK_TRANSFER' | 'PAYMENT_CARD' | 'CASH';
export const getPaymentTypeFromMethod = (paymentMethod: string | undefined): PaymentType =>
  match<string | undefined, PaymentType>(paymentMethod)
    .with('OFFSET', always('BANK_TRANSFER'))
    .with('CARD', 'PAYMENT_CARD', always('PAYMENT_CARD'))
    .when((val) => isNilOrEmpty(val), always('BANK_TRANSFER'))
    .otherwise(always(paymentMethod as PaymentType));
