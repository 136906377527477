import {BreadcrumbType, Form, FormField, FormSubmitHandler} from 'platform/components';
import {object, string} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useGetEmployeeCompetenceQuery,
  usePatchEmployeeCompetenceMutation,
  usePostEmployeeCompetenceMutation,
} from '@omnetic-dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type CompetencesFormType = {description: string};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.employeeCompetences.labels.title'),
    href: settingsRoutes.employeeCompetences,
  },
];

export function EmployeeCompetencesDetail() {
  const navigate = useNavigate();
  const {id} = useParams();

  const [postEmployeeCompetence] = usePostEmployeeCompetenceMutation();
  const [patchEmployeeCompetence] = usePatchEmployeeCompetenceMutation();
  const {data, isError, isLoading} = useGetEmployeeCompetenceQuery(
    {id: id ?? ''},
    {skip: isNil(id)}
  );

  const handleSubmit: FormSubmitHandler<CompetencesFormType> = async ({description}) => {
    const request = isNil(id)
      ? postEmployeeCompetence({body: {description}})
      : patchEmployeeCompetence({body: {description}, id});

    await request
      .unwrap()
      .then(() => navigate(settingsRoutes.employeeCompetences))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        breadcrumbs,
        title: data?.description ?? i18n.t('entity.employeeCompetences.labels.newCompetence'),
      }}
      isError={isError}
      isLoading={isLoading}
      isCreating={isNil(id)}
      data-testid={testIds.settings.employeeCompetencesDetail('settingsTemplate')}
    >
      <Form<CompetencesFormType>
        defaultValues={{description: data?.description ?? undefined}}
        schema={schema}
        onSubmit={handleSubmit}
      >
        {(control) => (
          <>
            <SettingsSection>
              <FormField
                control={control}
                type="text"
                name="description"
                label={i18n.t('general.labels.description')}
                data-testid={testIds.settings.employeeCompetencesDetail('description')}
              />
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  onClick: () => navigate(settingsRoutes.employeeCompetences),
                  title: id
                    ? i18n.t('general.actions.discardChanges')
                    : i18n.t('general.actions.discard'),
                  variant: 'secondary',
                  'data-testid': testIds.settings.employeeCompetencesDetail('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: id
                    ? i18n.t('general.actions.saveAndClose')
                    : i18n.t('general.actions.create'),
                  'data-testid': testIds.settings.employeeCompetencesDetail('save'),
                },
              ]}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({description: string().nullable().required()});
