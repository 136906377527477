import {ButtonProps, closeDialog, openDialog} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {equals, isNil, not} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  useDeleteWorkBasketItemsMutation,
  usePostWorkBasketItemsToOrderMutation,
  queryParams,
  useGetServiceOrderJobQuery,
  FullScreenModal,
  useGetEmployeeMechanicsQuery,
  useGetServiceOrderVariantQuery,
  useGetServiceOrderQuery,
  AfterSalesLabourBasket,
  useGetWorkBasketItemsQuery,
  LabourBasketItemEditingDetails,
  usePatchWorkBasketItemMutation,
  EitherQuantityOrError,
  useInvalidBasketItemsIds,
  AssignedMechanic,
} from '@omnetic-dms/shared';

import {buildArray, suffixTestId, RequiredTestIdProps, useQueryState} from 'shared';

import {BulkEditItem} from '../../../../../components/BulkEditItem/BulkEditItem';
import {EditItem} from '../../../../../components/EditItem/EditItem';
import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {WorkBasketItemType} from '../../../../../types/WorkBasketItemType';
import {ItemListCard} from './ItemListCard';

interface AddWorkModalProps extends RequiredTestIdProps {
  onSubmit: VoidFunction;
}

export function AddWorkModal(props: AddWorkModalProps) {
  const {serviceCaseId, orderId, addWorkJobId} = useWorkshopUrl();
  const [, , closeModal] = useQueryState(queryParams.SERVICE_CASE_ADD_WORK_JOB_ID);
  const [mechanic, setMechanic] = useState<AssignedMechanic | null>(null);

  const [patchWorkBasketItem] = usePatchWorkBasketItemMutation();

  const {data: serviceJob} = useGetServiceOrderJobQuery({
    serviceCaseId,
    serviceOrderId: orderId,
    serviceJobId: addWorkJobId,
  });
  const {data: serviceOrder} = useGetServiceOrderQuery({serviceCaseId, serviceOrderId: orderId});
  const [deleteWorkBasketItems, {isLoading: isDeleteLoading}] = useDeleteWorkBasketItemsMutation();
  const [postWorkBasketItemsToOrder, {isLoading: isPostLoading}] =
    usePostWorkBasketItemsToOrderMutation();
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: isNil(serviceOrder?.serviceOrderVariantId)}
  );
  const {data: employeeMechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {skip: isNil(orderVariant?.authorizationProfileId)}
  );
  const {data, isLoading, isError} = useGetWorkBasketItemsQuery({
    serviceCaseId,
    serviceOrderId: orderId,
    serviceJobId: addWorkJobId,
  });

  const basketItems = data?.workBasketItem ?? [];

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  useEffect(() => {
    const mechanic = serviceJob?.assignMechanics?.[0];

    if (isNil(mechanic)) {
      return;
    }

    setMechanic({
      id: mechanic.id!,
      isDefault: true,
      costCenterId: mechanic.costCenterId!,
    });
  }, [serviceJob?.assignMechanics]);

  const handleCancel = () => {
    deleteWorkBasketItems({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addWorkJobId,
      body: {serviceItemId: basketItems?.map((item) => item.id) ?? []},
    })
      .unwrap()
      .catch(handleApiError)
      .finally(closeModal);
  };

  const handleAddWork = () => {
    postWorkBasketItemsToOrder({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addWorkJobId,
      body: mechanic
        ? {
            assignMechanics: [
              {
                id: mechanic.id ?? '',
                isDefault: true,
                costCenterId: mechanic.costCenterId ?? '',
              },
            ],
          }
        : undefined,
    })
      .unwrap()
      .then(() => {
        closeModal();
        props.onSubmit();
      })
      .catch(handleApiError);
  };

  const handleMechanicChange = (mechanic: AssignedMechanic | null) => setMechanic(mechanic);

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems?.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchWorkBasketItem({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addWorkJobId,
      serviceItemId: itemId,
      body: {
        quantity: quantity.newQuantity,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEditWorkItem = ({
    itemId,
    itemName,
    priceTypeName,
  }: LabourBasketItemEditingDetails) => {
    if (isNil(itemId)) {
      return;
    }

    openDialog(
      <EditItem
        itemId={itemId}
        onClose={() => closeDialog('editBasketItem')}
        serviceCaseId={serviceCaseId}
        serviceOrderId={orderId}
        serviceJobId={addWorkJobId}
        data-testid={suffixTestId('editItem', props)}
      />,
      {
        title: `${itemName ?? ''} ${priceTypeName ? `(${priceTypeName})` : ''}`,
        id: 'editBasketItem',
      }
    );
  };

  const handleDeleteWorkItem = async (itemId: string) => {
    await deleteWorkBasketItems({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addWorkJobId,
      body: {serviceItemId: [itemId]},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const removeItems = (items: WorkBasketItemType[]) =>
    deleteWorkBasketItems({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addWorkJobId,
      body: {serviceItemId: items.map((item) => item.id)},
    })
      .unwrap()
      .catch(handleApiError);

  const openItemsSettings = (items: WorkBasketItemType[]) => {
    openDialog(
      <BulkEditItem
        items={items}
        onClose={() => closeDialog('bulkEditBasketItem')}
        serviceCaseId={serviceCaseId}
        serviceOrderId={orderId}
        serviceJobId={addWorkJobId}
        data-testid={suffixTestId('editItem', props)}
      />,
      {
        title: i18n.t('entity.addWork.actions.itemsSettings'),
        id: 'bulkEditBasketItem',
      }
    );
  };

  const actions = buildArray<ButtonProps>()
    .add({
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleCancel,
      isLoading: isDeleteLoading,
      'data-testid': suffixTestId('cancel', props),
    })
    .add({
      title: i18n.t('general.actions.add'),
      onClick: handleAddWork,
      isLoading: isPostLoading,
      isDisabled: isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('add', props),
    });

  return (
    <FullScreenModal headline={i18n.t('entity.addWork.actions.addWork')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <ItemListCard data-testid={suffixTestId('itemList', props)} />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesLabourBasket<WorkBasketItemType>
              basket={{
                items: basketItems,
                totalPrice: data?.workBasketTotalPrice,
                isLoading,
                hasError: isError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeleteLoading,
              }}
              mechanic={{
                mechanics: employeeMechanics,
                assignedMechanic: mechanic,
                onMechanicChange: handleMechanicChange,
              }}
              bulkActions={[
                {action: openItemsSettings, label: i18n.t('entity.addWork.actions.itemsSettings')},
                {action: removeItems, label: i18n.t('general.actions.remove')},
              ]}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEditWorkItem}
              onDelete={handleDeleteWorkItem}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
