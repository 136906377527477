import {Separator} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  ArticleFolderTree,
  catchUnhandledDataGridActions,
  GetWarehousesResponse,
  useGetDirectSaleQuery,
  useWarehouseDataGridTreeFolder,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {RequestTabMaterialWarehouseTabExternalFilters} from './RequestTabMaterialWarehouseTabExternalFilters';

const DSMATERIAL_EXTERNAL_FILTER_ID = 'dsmaterial-external-filter';

interface WarehouseTabProps extends TestIdProps {
  onWarehouseDispatch: (warehouseArticleId: string) => Promise<void>;
  onWarehouseRequest: (warehouseArticleId: string) => Promise<void>;
  onWarehouseNonBinding: (warehouseArticleId: string) => Promise<void>;
  availableWarehouses: GetWarehousesResponse;
}

export function RequestTabMaterialWarehouseTab(props: WarehouseTabProps) {
  const {directSaleId} = useWarehouseParams();
  const {data: directSale} = useGetDirectSaleQuery({directSaleId: directSaleId ?? ''});

  const [folderTreeRef, dataGridModifier] = useWarehouseDataGridTreeFolder({
    availableWarehouses: props.availableWarehouses,
  });

  const warehouseActionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const warehouseArticleId = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(warehouseArticleId)) {
      throw new Error('Row id is not defined');
    }

    match(actionKey)
      .with('dispatch', () => props.onWarehouseDispatch(warehouseArticleId).then(refreshData))
      .with('request', () => props.onWarehouseRequest(warehouseArticleId).then(refreshData))
      .with('nonBinding', () => props.onWarehouseNonBinding(warehouseArticleId).then(refreshData))
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <HStack height="100%" data-testid={props['data-testid']}>
      <Box flex={1}>
        <VStack height="100%">
          <Box>
            <div id={DSMATERIAL_EXTERNAL_FILTER_ID}></div>
          </Box>
          <Space vertical={8} />
          <ArticleFolderTree
            ref={folderTreeRef}
            data-testid={suffixTestId('articleFolderTree', props)}
          />
        </VStack>
      </Box>

      <Separator orientation="vertical" />

      <Box flex={3}>
        <DataGrid
          // DG must be re-rendered on folder change to update the query modifier
          // eslint-disable-next-line no-restricted-syntax
          key={dataGridModifier?.key}
          gridCode="spare-parts-ds"
          actionCallback={warehouseActionCallback}
          externalFilterId={DSMATERIAL_EXTERNAL_FILTER_ID}
          filterComponent={(datagridProps) => (
            <RequestTabMaterialWarehouseTabExternalFilters
              directSaleVariantId={directSale?.directSaleVariantId}
              {...datagridProps}
            />
          )}
          queryModifier={dataGridModifier?.queryModifier}
          data-testid={suffixTestId('warehouse', props)}
        />
      </Box>
    </HStack>
  );
}
