import {Alpha3CountryCode} from 'platform/components';

import {DefaultValues} from 'react-hook-form';

import {defaultTo} from 'ramda';

import {GetEmployeePersonalDataApiResponse, PhoneNumber} from '@omnetic-dms/shared';

import {parseDate} from 'shared';

import {PersonalInfoForm} from '../types/personalInfoFormType';

const defaultValue = defaultTo(undefined);

export const getPersonalInfoFormDefaultValues = (
  tenantPhoneInfo?: PhoneNumber,
  employee?: GetEmployeePersonalDataApiResponse
): DefaultValues<PersonalInfoForm> => ({
  addressData: {
    addressLine1: defaultValue(employee?.addressData?.addressLine1),
    addressLine2: defaultValue(employee?.addressData?.addressLine2),
    city: defaultValue(employee?.addressData?.city),
    countryCode: defaultValue(employee?.addressData?.countryCode),
    postalCode: defaultValue(employee?.addressData?.postalCode),
  },
  contactData: {
    email_private: defaultValue(employee?.contactData?.email_private),
    email_work: defaultValue(employee?.contactData?.email_work),
    phone_number_private: {
      prefix: employee?.contactData?.phone_number_private?.prefix ?? tenantPhoneInfo?.prefix,
      number: employee?.contactData?.phone_number_private?.number ?? '',
      countryCode: (employee?.contactData?.phone_number_private?.countryCode ??
        tenantPhoneInfo?.countryCode) as Alpha3CountryCode,
    },
    phone_number_work: {
      prefix: employee?.contactData?.phone_number_work?.prefix ?? tenantPhoneInfo?.prefix,
      number: employee?.contactData?.phone_number_work?.number ?? '',
      countryCode: (employee?.contactData?.phone_number_work?.countryCode ??
        tenantPhoneInfo?.countryCode) as Alpha3CountryCode,
    },
  },
  employmentData: {
    endDate: parseDate(employee?.employmentData?.endDate) ?? undefined,
    startDate: parseDate(employee?.employmentData?.startDate) ?? undefined,
  },
  note: defaultValue(employee?.note),
  personalData: {
    birthdate: parseDate(employee?.personalData?.birthdate) ?? undefined,
    firstName: defaultValue(employee?.personalData?.firstName),
    genderKey: defaultValue(employee?.personalData?.genderKey),
    identificationNumber: defaultValue(employee?.personalData?.identificationNumber),
    lastName: defaultValue(employee?.personalData?.lastName),
    titleAfter: defaultValue(employee?.personalData?.titleAfter),
    titleBefore: defaultValue(employee?.personalData?.titleBefore),
    fte: defaultValue(employee?.personalData?.fte),
  },
});
