import {subDays, startOfDay} from 'date-fns';
import {Actions, Button, Dialog, Form, FormField, FormSubmitHandler} from 'platform/components';
import {VStack, Text, Display, HStack, Space, Show} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {object, date} from 'yup';

import {is} from 'ramda';

import {i18n} from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps, useBoolean} from 'shared';

interface OverviewItemProps extends TestIdProps {
  value: number | Nullish;
  date?: Date | Nullish;
  title: string;
  subtitle: string;
  onEdit?: (date: Date) => Promise<boolean>;
  editDialogModalLabel?: string;
  isMutationLoading?: boolean;
}

export function InsightItem(props: OverviewItemProps) {
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useBoolean(false);
  const formatDateTime = useDateTimeFormatter();

  const onSubmit: FormSubmitHandler<FormValues> = async (values) => {
    const isSuccess = await props.onEdit?.(values.editDate);
    if (isSuccess) {
      closeEditDialog();
    }
  };

  const isValueValid = is(Number)(props.value);

  const todayStartOfDay = startOfDay(new Date());

  const submittedDate =
    props.date || (isValueValid ? subDays(todayStartOfDay, props.value!) : todayStartOfDay);

  const dateToDisplay =
    props.date || isValueValid
      ? formatDateTime('dateShort', props.date || subDays(new Date(), props.value!))
      : EMPTY_PLACEHOLDER;

  const displayValue = isValueValid ? String(props.value) : EMPTY_PLACEHOLDER;

  return (
    <VStack spacing={4} width={90}>
      <VStack spacing={2}>
        <Text color="secondary" size="small">
          {props.title}
        </Text>
        <Display size={1}>{displayValue}</Display>
      </VStack>
      <VStack spacing={2}>
        <Text color="secondary" size="small">
          {props.subtitle}
        </Text>
        <HStack>
          <Text>{dateToDisplay}</Text>
          <Show when={props.onEdit}>
            <Space horizontal={4} />
            <Actions
              actions={[
                {
                  variant: 'link',
                  type: 'button',
                  size: 'small',
                  leftIcon: 'image/edit',
                  title: i18n.t('general.actions.edit'),
                  onClick: openEditDialog,
                },
              ]}
              data-testid={suffixTestId('insightItemActionsWrapper', props)}
            />
            <Dialog
              isOpen={isEditDialogOpen}
              onClose={closeEditDialog}
              size="small"
              title={i18n.t('entity.vehicle.labels.overview.editModal.title')}
              data-testid={suffixTestId('insightItemDialog', props)}
            >
              <Form<FormValues>
                onSubmit={onSubmit}
                schema={schema}
                defaultValues={{editDate: submittedDate}}
              >
                {(control) => (
                  <>
                    <FormField
                      name="editDate"
                      control={control}
                      type="date"
                      label={props.editDialogModalLabel}
                      data-testid={suffixTestId('insightItemEditDialog-dateField', props)}
                    />
                    <Space vertical={6} />
                    <HStack justify="flex-end" spacing={2}>
                      <Button
                        variant="secondary"
                        onClick={closeEditDialog}
                        title={i18n.t('general.actions.discard')}
                        isDisabled={props.isMutationLoading}
                        data-testid={suffixTestId('insightItemEditDialog-cancel', props)}
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        isLoading={props.isMutationLoading}
                        data-testid={suffixTestId('insightItemEditDialog-confirm', props)}
                        title={i18n.t('general.actions.save')}
                      />
                    </HStack>
                  </>
                )}
              </Form>
            </Dialog>
          </Show>
        </HStack>
      </VStack>
    </VStack>
  );
}

const schema = object({
  editDate: date()
    .max(new Date(), i18n.t('entity.vehicle.labels.overview.editModal.dateError'))
    .required(),
});

type FormValues = {
  editDate: Date;
};
