import {ButtonGroup, DataStatus, showNotification} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {includes, isNil, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  useGetServiceCaseOrdersQuery,
  usePostServiceWorkItemCopyToServiceOrderMutation,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {MoveRequestItemOrder} from '../../../../../components/MoveRequestItemOrder/MoveRequestItemOrder';
import {SelectedJobType} from '../../../../../types/SelectedJobType';

interface CopyHistoryItem extends TestIdProps {
  itemIds: string[];
  serviceCaseId: string;
  onClose: VoidFunction;
}

export function CopyHistoryItem(props: CopyHistoryItem) {
  const [selectedJob, setSelectedJob] = useState<SelectedJobType>();

  const {data, isLoading, isError} = useGetServiceCaseOrdersQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const [postServiceWorkItemCopyToServiceOrder, {isLoading: isPostLoading}] =
    usePostServiceWorkItemCopyToServiceOrderMutation();

  const activeOrders = data?.filter((order) =>
    not(includes(order?.state, ['LOCKED', 'ARCHIVED', 'CLOSED', 'CANCELLED']))
  );

  const handleCopy = () =>
    postServiceWorkItemCopyToServiceOrder({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: selectedJob?.orderId ?? '',
      serviceJobId: selectedJob?.jobId ?? '',
      body: {
        ids: props.itemIds,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        props.onClose();
      })
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNilOrEmpty(data)} minHeight={23}>
      <VStack spacing={4}>
        <Text size="small" color="tertiary">
          {i18n.t('entity.order.labels.copyDescription')}
        </Text>
        {activeOrders?.map(
          (order, index) =>
            order && (
              <MoveRequestItemOrder
                key={order.id}
                serviceCaseId={props.serviceCaseId}
                id={order.id}
                selectedJob={selectedJob}
                onJobSelect={(jobId) => setSelectedJob({jobId, orderId: order.id})}
                data-testid={suffixTestId(`order-[${index}]`, props)}
              />
            )
        )}
        <ButtonGroup
          align="right"
          buttons={[
            {
              title: i18n.t('general.actions.cancel'),
              variant: 'secondary',
              onClick: props.onClose,
            },
            {
              title: i18n.t('general.actions.confirm'),
              isDisabled: isNil(selectedJob),
              onClick: handleCopy,
              isLoading: isPostLoading,
              'data-testid': suffixTestId('move', props),
            },
          ]}
        />
      </VStack>
    </DataStatus>
  );
}
