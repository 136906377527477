import {isNil} from 'ramda';

import {
  Permission,
  PermissionExpressionListResponseBody,
  isActionPermission,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const resolvePermission = (
  permission: Permission | Nullish,
  userPermissions: PermissionExpressionListResponseBody | Nullish
) => {
  if (isNil(permission)) {
    return true;
  }

  if (isNil(userPermissions)) {
    return false;
  }

  const foundPermission = isActionPermission(permission)
    ? userPermissions?.actions?.find(
        ({actionId, resourceId}) =>
          actionId === permission?.actionId && resourceId === permission?.resourceId
      )
    : userPermissions?.fields?.find(
        ({fieldId, resourceId}) =>
          fieldId === permission?.fieldId && resourceId === permission?.resourceId
      );

  if (isNil(foundPermission)) {
    return true;
  }

  return foundPermission.permissionsExpression.result !== 'DISALLOW';
};
