import {
  Attributes,
  ButtonGroup,
  ButtonProps,
  Card,
  closeCurrentDialog,
  EmptyStatus,
  FormControl,
  openDialog,
  showNotification,
} from 'platform/components';
import {Box, Center, Grid, HStack, Show, Text, VStack} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {customerRoutes} from '@omnetic-dms/routes';
import {
  ContractInformationResponseBodyV2,
  CustomerResponseBodyV2,
  EMPTY_PLACEHOLDER,
  featureFlags,
  SupplierDraftResponseBody,
  useAddress,
  useGetBillingInformationV2Query,
  useGetCurrentBranch,
} from '@omnetic-dms/shared';

import {
  buildArray,
  composePath,
  isFeatureEnabled,
  Nullish,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {REQUIRED_INDICATOR} from '../constants';
import {AccountingDocumentFormValues, CustomerType} from '../types';
import {accountingCustomerToAddressData} from '../utils/accountingCustomerToAddressData';
import {convertContractInformationToInvoiceCustomer} from '../utils/convertContractInformationToInvoiceCustomer';
import {AffiliatedEntityCheckbox} from './AffiliatedEntityCheckbox';
import {AssignCustomerForm} from './AssignCustomerForm';

interface CreateInvoiceCustomerAndSupplierProps extends RequiredTestIdProps {
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  control: FormControl<AccountingDocumentFormValues>;
  supplier?: SupplierDraftResponseBody | Nullish;
  buttons?: ButtonProps[];
  allowAdditionalCustomer?: boolean;
  isCustomerFixed?: boolean;
}

export function CustomerAndSupplier(props: CreateInvoiceCustomerAndSupplierProps) {
  const navigate = useNavigate();
  const {composeAddress, composeAddressV1} = useAddress();
  const {data: currentBranch} = useGetCurrentBranch();
  const {data: branchBillingInformation} = useGetBillingInformationV2Query(
    {id: currentBranch?.billingInformation?.id ?? ''},
    {skip: isNil(currentBranch?.billingInformation?.id)}
  );

  props.formApi.register('customer');
  props.formApi.register('additionalCustomer');

  const customer = useWatch({control: props.control, name: 'customer'});
  const additionalCustomer = useWatch({control: props.control, name: 'additionalCustomer'});

  const isCustomerNil = isNil(customer);

  const handleSelectAdditionalCustomer = (
    newCustomer: CustomerResponseBodyV2,
    contractInformation: ContractInformationResponseBodyV2,
    type: CustomerType | null
  ) => {
    if (isNil(newCustomer) || isNil(type)) {
      showNotification.error();
      return;
    }

    const invoiceCustomerInfo = convertContractInformationToInvoiceCustomer(
      newCustomer.id,
      contractInformation
    );

    props.formApi.setValue('additionalCustomer', {
      contractInformationId: contractInformation.id,
      type,
      customer: invoiceCustomerInfo,
    });
    props.formApi.clearErrors('additionalCustomer');
    closeCurrentDialog();
  };

  const handleSelectNewCustomer = (
    newCustomer: CustomerResponseBodyV2,
    contractInformation: ContractInformationResponseBodyV2
  ) => {
    if (isNil(newCustomer)) {
      return;
    }

    const invoiceCustomerInfo = convertContractInformationToInvoiceCustomer(
      newCustomer.id,
      contractInformation
    );

    props.formApi.setValue('customer', invoiceCustomerInfo);
    props.formApi.clearErrors('customer');
    closeCurrentDialog();
  };

  const handleSelectOrCreateAdditionalCustomer = () => {
    openDialog(
      <AssignCustomerForm
        onSave={handleSelectAdditionalCustomer}
        isAdditionalCustomer
        data-testid={suffixTestId('additionalCustomer', props)}
      />,
      {
        title: i18n.t('general.actions.addAnotherCustomer'),
        'data-testid': suffixTestId('addAnotherCustomer-modal', props),
      }
    );
  };

  const handleEditCustomer = () =>
    openDialog(
      <AssignCustomerForm
        onSave={handleSelectNewCustomer}
        defaultValues={{
          customerId: customer.id,
          contractInformationId: customer.contractInformationId,
        }}
        data-testid={suffixTestId('newCustomer', props)}
      />,
      {
        title: i18n.t('general.actions.addAnotherCustomer'),
      }
    );

  const handleEditAdditionalCustomer = () =>
    openDialog(
      <AssignCustomerForm
        onSave={handleSelectAdditionalCustomer}
        isAdditionalCustomer
        defaultValues={{
          customerId: additionalCustomer?.customer?.id,
          contractInformationId: additionalCustomer?.contractInformationId,
          type: additionalCustomer?.type,
        }}
        data-testid={suffixTestId('additionalCustomer', props)}
      />,
      {
        title: i18n.t('general.actions.addAnotherCustomer'),
      }
    );

  const handleSelectOrCreateCustomer = () =>
    openDialog(
      <AssignCustomerForm
        onSave={handleSelectNewCustomer}
        data-testid={suffixTestId('newCustomer', props)}
      />,
      {
        title: i18n.t('general.actions.assignCustomer'),
        'data-testid': suffixTestId('newCustomer-modal', props),
      }
    );

  const supplier = isNotNil(props.supplier)
    ? {
        name: props.supplier.name,
        address: composeAddressV1(accountingCustomerToAddressData(props.supplier)),
        registrationNumber: props.supplier.cin,
        vatNumber: props.supplier.tin,
      }
    : {
        name: currentBranch?.billingInformation?.billingName,
        address: composeAddress(branchBillingInformation?.contactInformation.address),
        registrationNumber:
          currentBranch?.billingInformation?.contactInformation?.registrationNumber,
        vatNumber: currentBranch?.billingInformation?.contactInformation?.vatNumber,
      };

  const getActionButtons = () => {
    const proxyArray = buildArray<ButtonProps>().when(
      isNil(additionalCustomer) &&
        props.allowAdditionalCustomer &&
        isFeatureEnabled(featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER),
      {
        title: i18n.t('general.actions.addAnotherCustomer'),
        onClick: handleSelectOrCreateAdditionalCustomer,
        leftIcon: 'content/add_circle',
        'data-testid': suffixTestId('additional-customer', props),
      }
    );

    props.buttons?.forEach(proxyArray.add);

    return proxyArray;
  };

  return (
    <Box data-testid={suffixTestId('section', props)}>
      <Grid columns={2} spacing={4}>
        <Card
          variant="inlineGrey"
          title={isCustomerNil ? undefined : i18n.t('module.customer.title')}
        >
          {isCustomerNil ? (
            <Center minHeight={46}>
              <VStack spacing={4}>
                <EmptyStatus
                  variant="person"
                  headline={i18n.t`entity.accounting.labels.noCustomerAssigned`}
                  data-testid={suffixTestId('noCustomerAssigned', props)}
                />
                <HStack justify="center">
                  <ButtonGroup
                    variant="link"
                    size="small"
                    buttons={[
                      {
                        title: i18n.t('general.actions.assignCustomer'),
                        onClick: handleSelectOrCreateCustomer,
                        leftIcon: 'content/add_circle',
                        'data-testid': suffixTestId('assignCustomer', props),
                      },
                      ...(props.buttons ?? []),
                    ]}
                  />
                </HStack>
              </VStack>
            </Center>
          ) : (
            <VStack spacing={4}>
              <Card
                variant="inlineWhite"
                actions={
                  isNil(props.isCustomerFixed)
                    ? [
                        {
                          type: 'dropdown-iconButton',
                          'data-testid': suffixTestId('edit-customer', props),
                          menuItems: [
                            {
                              label: i18n.t('general.actions.changeCustomer'),
                              onClick: handleSelectOrCreateCustomer,
                            },
                            {
                              label: i18n.t('general.labels.edit'),
                              onClick: handleEditCustomer,
                            },
                            {
                              label: i18n.t('general.actions.goToDetail'),
                              onClick: () =>
                                navigate(
                                  composePath(customerRoutes.detail, {params: {id: customer.id}})
                                ),
                            },
                          ],
                          icon: 'navigation/more_vert',
                        },
                      ]
                    : []
                }
                title={customer.name}
                parameters={[i18n.t('entity.accounting.labels.payer')]}
                data-testid={suffixTestId('newCustomer', props)}
              >
                <VStack spacing={2}>
                  <Attributes
                    size="third"
                    data-testid={suffixTestId('customer', props)}
                    rows={[
                      {
                        label: i18n.t('entity.customer.labels.address'),
                        value:
                          composeAddressV1(accountingCustomerToAddressData(customer)) ||
                          EMPTY_PLACEHOLDER,
                        testId: 'address',
                      },
                      {
                        label: i18n.t('entity.invoice.labels.registrationNumber'),
                        value: customer.cin,
                        testId: 'cin',
                      },
                      {
                        label: i18n.t('entity.invoice.labels.vatNumber'),
                        value: customer.tin,
                      },
                    ]}
                  />
                  <AffiliatedEntityCheckbox
                    formApi={props.formApi}
                    customerId={customer.id}
                    contractInformationId={customer.contractInformationId}
                  />
                </VStack>
              </Card>

              <Show
                when={isNotNil(additionalCustomer) && props.allowAdditionalCustomer}
                whenFeatureEnabled={featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER}
              >
                <Card
                  variant="inlineWhite"
                  actions={[
                    {
                      type: 'dropdown-iconButton',
                      menuItems: [
                        {
                          label: i18n.t('general.actions.changeCustomer'),
                          onClick: handleSelectOrCreateAdditionalCustomer,
                        },
                        {
                          label: i18n.t('general.labels.edit'),
                          onClick: handleEditAdditionalCustomer,
                        },
                        {
                          label: i18n.t('general.actions.goToDetail'),
                          onClick: () =>
                            navigate(
                              composePath(customerRoutes.detail, {
                                params: {
                                  id: additionalCustomer?.customer?.id,
                                },
                              })
                            ),
                        },
                        {
                          label: (
                            <Text color="danger" size="small">
                              {i18n.t('general.actions.remove')}
                            </Text>
                          ),
                          severity: 'danger',
                          onClick: () => props.formApi.setValue('additionalCustomer', null),
                        },
                      ],
                      icon: 'navigation/more_vert',
                    },
                  ]}
                  title={additionalCustomer?.customer?.name}
                  parameters={[i18n.t(`entity.accounting.labels.${additionalCustomer?.type}`)]}
                  data-testid={suffixTestId('additionalCustomer', props)}
                >
                  <VStack spacing={2}>
                    <Attributes
                      size="third"
                      data-testid={suffixTestId('additionalCustomer', props)}
                      rows={[
                        {
                          label: i18n.t('entity.customer.labels.address'),
                          testId: 'address',
                          value:
                            composeAddressV1(
                              additionalCustomer?.customer
                                ? accountingCustomerToAddressData(additionalCustomer.customer)
                                : null
                            ) || EMPTY_PLACEHOLDER,
                        },
                        {
                          label: i18n.t('entity.invoice.labels.registrationNumber'),
                          value: additionalCustomer?.customer?.cin,
                        },
                        {
                          label: i18n.t('entity.invoice.labels.vatNumber'),
                          value: additionalCustomer?.customer?.tin,
                        },
                      ]}
                    />
                  </VStack>
                </Card>
              </Show>
              <ButtonGroup variant="link" size="small" buttons={getActionButtons()} />
            </VStack>
          )}
        </Card>

        <Card title={i18n.t('general.labels.supplier')} variant="inlineWhite">
          <Attributes
            size="third"
            data-testid={suffixTestId('supplier', props)}
            rows={[
              {
                label: i18n.t('general.labels.name'),
                content: (
                  <Text
                    alternative
                    size="xSmall"
                    data-testid={suffixTestId('supplier-name', props)}
                  >
                    {supplier.name}
                  </Text>
                ),
              },
              {
                label: i18n.t('entity.customer.labels.address'),
                value: supplier.address,
                testId: 'address',
              },
              {
                label: i18n.t('entity.invoice.labels.registrationNumber'),
                value: supplier.registrationNumber,
                testId: 'registrationNumber',
              },
              {
                label: i18n.t('entity.invoice.labels.vatNumber'),
                value: supplier.vatNumber,
              },
            ]}
          />
        </Card>
      </Grid>
      <Show when={isNotNil(props.formApi.formState.errors.customer)}>
        <Text color="danger" size="xSmall" data-testid={suffixTestId('error', props)}>
          {REQUIRED_INDICATOR} {props.formApi.formState.errors.customer?.message}
        </Text>
      </Show>
    </Box>
  );
}
