import {Chips, Separator, Slider, TextInput} from 'platform/components';
import {Grid, HStack, VStack} from 'platform/foundation';

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {equals, isNil, isNotNil} from 'ramda';
import {isArray, isObjectLike, isString} from 'ramda-adjunct';

import {debounce} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useSliderTicks} from '../../hooks/useSliderTicks';
import {IFilter, IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

export type PlainSliderRangeProps = {
  min: number;
  max: number;
  minOpen: boolean;
  maxOpen: boolean;
  unit: string | null;
  stepSize: number | null;
  steps: number[];
  markerSize: number | null;
  markers: number[];
  decimalsAllowed: boolean;
  inputsVisible: boolean;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters;

function PlainSliderRangeFilterComponent(
  {
    filterChangedCallback,
    min,
    max,
    minOpen,
    maxOpen,
    unit,
    stepSize,
    steps,
    markerSize,
    markers,
    decimalsAllowed,
    inputsVisible,
    isDisabled,
    quickFilters,
  }: PlainSliderRangeProps,
  ref: ForwardedRef<IFilter>
) {
  const ticks = useSliderTicks(min, max, markerSize, markers);
  const valueFormatter = useCallback(
    (value: number) => (unit ? `${value} [${unit}]` : `${value}`),
    [unit]
  );

  const [filterValue, _setFilterValue] = useState<
    [number | null, number | null] | string | undefined
  >();

  const isActive = useRef<boolean>(false);
  const setFilterValue = (value: [number | null, number | null] | string | undefined) => {
    isActive.current = Array.isArray(value) || isString(value);
    _setFilterValue(value);
  };
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue,
    defaultValue: undefined,
  });

  const debouncedFilterChangedCallback = useMemo(
    () => debounce(filterChangedCallback, 700),
    [filterChangedCallback]
  );

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      if (!filterValue) {
        return undefined;
      }

      if (isArray(filterValue)) {
        return {from: filterValue[0] ?? min, to: filterValue[1] ?? max};
      }

      return filterValue;
    },

    setModel(
      model: {from: number; to: number} | [number | null, number | null] | string | undefined
    ) {
      if (equals(model, filterValue)) {
        return;
      }

      if (isNil(model)) {
        setFilterValue(undefined);
      }

      if (model && isObjectLike(model) && 'from' in model && 'to' in model) {
        setFilterValue([model.from, model.to]);
      }

      if (isString(model)) {
        setFilterValue(model);
      }

      filterChangedCallback();
    },
  }));

  const onChange = (value: [number, number]) => {
    setFilterValue(value);
    debouncedFilterChangedCallback();
  };
  const onFromInputChange = (textValue: string | null) => {
    const value = isNotNil(textValue) ? parseFloat(textValue) : null;

    if (isNil(filterValue)) {
      setFilterValue([value, 0]);
    }

    if (isArray(filterValue)) {
      setFilterValue([
        isNotNil(value) && isNaN(value) ? null : value,
        (filterValue?.[1] as number) ?? max,
      ]);
    }

    if (isString(filterValue)) {
      setFilterValue([isNotNil(value) && isNaN(value) ? null : value, max]);
    }

    debouncedFilterChangedCallback();
  };

  const onToInputChange = (textValue: string | null) => {
    const value = isNotNil(textValue) ? parseFloat(textValue) : null;

    if (isNil(filterValue)) {
      setFilterValue([0, value]);
    }

    if (isArray(filterValue)) {
      setFilterValue([
        (filterValue?.[0] as number) ?? min,
        isNotNil(value) && isNaN(value) ? null : value,
      ]);
    }

    if (isString(filterValue)) {
      setFilterValue([min, isNotNil(value) && isNaN(value) ? null : value]);
    }

    debouncedFilterChangedCallback();
  };

  const isQuickFilterValue = isString(filterValue) ? [filterValue] : undefined;

  const sliderValue: [number, number] = isArray(filterValue)
    ? [filterValue?.[0] ?? min, filterValue?.[1] ?? max]
    : [min, max];

  const isFrom = isArray(filterValue) ? filterValue?.[0] : min;
  const isTo = isArray(filterValue) ? filterValue?.[1] : max;

  const isFromValue = isFrom?.toString() ?? null;
  const isToValue = isTo?.toString() ?? null;

  return (
    <VStack spacing={5}>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Slider
        isDisabled={isDisabled}
        isRange
        min={min}
        max={max}
        step={stepSize ?? steps ?? 1}
        value={sliderValue}
        onChange={onChange}
        formatValue={valueFormatter}
        ticks={ticks}
        onlyEdges={true}
        displayValue="auto"
      />
      <Grid columns={2}>
        <TextInput value={isFromValue} onChange={onFromInputChange} type="number" />
        <TextInput value={isToValue} onChange={onToInputChange} type="number" />
      </Grid>
    </VStack>
  );
}

export const PlainSliderRangeFilter = forwardRef<IFilter, PlainSliderRangeProps>(
  PlainSliderRangeFilterComponent
);
