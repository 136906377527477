import {isEmpty, isNil} from 'ramda';

import {Nullish, isFeatureEnabled} from 'shared';

export const resolveFeatureFlag = (featureFlag: string | Nullish) => {
  if (isNil(featureFlag) || isEmpty(featureFlag)) {
    return true;
  }

  return isFeatureEnabled(featureFlag);
};
