import {useSelector} from 'react-redux';

import {find, propEq} from 'ramda';

import {useGetCashRegisterListQuery} from '../api/accountingApi';
import {selectTenant} from '../api/tenantApi';

export function useCashRegister(currency?: string) {
  const {data: {currency: defaultCurrency} = {}} = useSelector(selectTenant);

  const {
    data: cashRegisters = [],
    isLoading,
    isError,
  } = useGetCashRegisterListQuery({
    inactive: false,
    currency: currency ?? defaultCurrency,
    withValidIncomeSeries: true,
  });

  const cashRegisterOptions = cashRegisters.map((cashRegister) => ({
    value: cashRegister.id,
    label: cashRegister.name,
  }));

  const getCashRegisterById = (id: string) => find(propEq(id, 'id'), cashRegisters ?? []);

  return {
    cashRegisters,
    cashRegisterOptions,
    getCashRegisterById,
    isLoading,
    isError,
  };
}
