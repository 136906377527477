import {ButtonProps, closeCurrentDialog, openDialog, showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {defaultTo, equals, isNil, not} from 'ramda';
import {isArray, isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  AfterSalesMaterialBasket,
  AssignedMechanic,
  BaseDirectSaleMaterialItem,
  EitherQuantityOrError,
  FullScreenModal,
  GetDirectSaleMaterialItemResponse,
  GetWarehousesResponse,
  handleApiError,
  MaterialBasketItemEditingDetails,
  TooltipItem,
  useBulkDeleteDirectSaleItemsMutation,
  useDeleteDirectSaleMaterialBasketItemsMutation,
  useGetDirectSaleMaterialBasketItemsQuery,
  useGetEmployeeMechanicsQuery,
  useInvalidBasketItemsIds,
  useLazyGetDirectSaleMaterialItemQuery,
  usePatchDirectSaleMaterialBasketItemQuantityMutation,
  usePostDirectSaleMaterialDispatchMutation,
  usePostDirectSaleMaterialItemsMechanicMutation,
  usePostDirectSaleMaterialNonBindingMutation,
  usePostDirectSaleMaterialRequestMutation,
} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabMaterialItemEdit} from './RequestTabMaterialItemEdit';
import {RequestTabMaterialList} from './RequestTabMaterialList';

// Temporary solution, will be removed after resolving
// TODO https://carvago.atlassian.net/browse/T20-51559
type MaterialBasketItemWithTooltip = Omit<BaseDirectSaleMaterialItem, 'tooltip'> & {
  tooltip: TooltipItem[];
};

interface RequestTabMaterialModalProps extends RequiredTestIdProps {
  directSaleId: string;
  authorizationProfileId?: string;
  onDiscard: () => void;
  availableWarehouses: GetWarehousesResponse;
}

export function RequestTabMaterialModal(props: RequestTabMaterialModalProps) {
  const [mechanic, setMechanic] = useState<AssignedMechanic | null>(null);
  const [getDirectSaleMaterialItem] = useLazyGetDirectSaleMaterialItemQuery();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetDirectSaleMaterialBasketItemsQuery({directSaleId: props.directSaleId});

  const {data: mechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: defaultTo('', props.authorizationProfileId)},
    {skip: isNil(props.authorizationProfileId)}
  );

  const [assignMechanicsToDirectSale, {isLoading: isAssigningMechanicsToDirectSale}] =
    usePostDirectSaleMaterialItemsMechanicMutation();

  const [emptyBasket, {isLoading: isDiscarding}] = useDeleteDirectSaleMaterialBasketItemsMutation();

  const [dispatchMaterial] = usePostDirectSaleMaterialDispatchMutation();

  const [requestMaterial] = usePostDirectSaleMaterialRequestMutation();

  const [nonBindingMaterial] = usePostDirectSaleMaterialNonBindingMutation();

  const [changeMaterialBasketItemQuantity] = usePatchDirectSaleMaterialBasketItemQuantityMutation();

  const [deleteMaterialItems, {isLoading: isDeletingBasketItems}] =
    useBulkDeleteDirectSaleItemsMutation();

  const basketItems = basket?.sparePartsBasketItem ?? [];
  const basketTotalPrice = basket?.sparePartsBasketTotalPrice;
  const basketMechanicId = basket?.assignMechanicId;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  // Manually set the last selected mechanic to include it in onSubmit payload since
  // AfterSalesMaterialBasket's onChange not being triggerable without unnecessary useEffect.
  useEffect(() => {
    if (isNil(basketMechanicId)) {
      return;
    }

    const lastSelectedMechanic = mechanics?.employees?.find(
      (mechanic) => mechanic?.id === basketMechanicId
    );

    if (isNil(lastSelectedMechanic)) {
      return;
    }

    setMechanic({
      id: lastSelectedMechanic.id!,
      isDefault: true,
      costCenterId: lastSelectedMechanic.costCenterId!,
    });
  }, [basketMechanicId, mechanics?.employees]);

  const assignedMechanic = defaultTo(null, mechanic);

  const handleAdd = async () => {
    if (isNotNil(assignedMechanic)) {
      return await assignMechanicsToDirectSale({
        directSaleId: props.directSaleId,
        body: {
          assignMechanics: {
            id: assignedMechanic.id,
            isDefault: assignedMechanic.isDefault ?? null,
            costCenterId: assignedMechanic.costCenterId,
          },
        },
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('entity.warehouse.notifications.materialAdded'))
        )
        .then(props.onDiscard)
        .catch(handleApiError);
    }

    await assignMechanicsToDirectSale({
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.materialAdded')))
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await emptyBasket({directSaleId: props.directSaleId})
      .unwrap()
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleMechanicChange = (mechanic: AssignedMechanic | null) => {
    setMechanic(mechanic);
  };

  const handleWarehouseDispatchMaterial = async (warehouseArticleId: string) => {
    await dispatchMaterial({directSaleId: props.directSaleId, body: {warehouseArticleId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleWarehouseRequestMaterial = async (warehouseArticleId: string) => {
    await requestMaterial({directSaleId: props.directSaleId, body: {warehouseArticleId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleWarehouseNonBindingMaterial = async (warehouseArticleId: string) => {
    await nonBindingMaterial({directSaleId: props.directSaleId, body: {warehouseArticleId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await changeMaterialBasketItemQuantity({
      directSaleId: props.directSaleId,
      itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = async (editingDetails: MaterialBasketItemEditingDetails) => {
    if (isNil(editingDetails?.itemId)) {
      throw new Error("Missing itemId, can't open editing dialog");
    }

    const materialItem = (await getDirectSaleMaterialItem({
      itemId: editingDetails.itemId,
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .catch(handleApiError)) as GetDirectSaleMaterialItemResponse;

    const itemName = materialItem ? `${materialItem?.name} (${materialItem?.number})` : '';

    openDialog(
      <RequestTabMaterialItemEdit
        directSaleId={props.directSaleId}
        item={materialItem}
        isEditingDisabled={false}
        authorizationProfileId={props.authorizationProfileId}
        onAfterSubmit={closeCurrentDialog}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('dialog.editMaterial', props)}
      />,
      {
        title: itemName,
      }
    );
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {items: ids.map((id) => ({id}))};

    await deleteMaterialItems({
      directSaleId: props.directSaleId,
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscarding,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isAssigningMechanicsToDirectSale,
      isDisabled: isNilOrEmpty(basketItems) || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  // Temporary solution, will be removed after resolving
  // TODO https://carvago.atlassian.net/browse/T20-51559
  const basketItemsWithTooltip = basketItems?.map((basketItem) => ({
    ...basketItem,
    tooltip: [
      {
        label: i18n.t('entity.warehouse.labels.requestType'),
        value: basketItem.tooltip?.requestType ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.warehouse'),
        value: basketItem.tooltip?.warehouseName ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.manufacturer'),
        value: basketItem.tooltip?.manufacturerName ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.storageLocation'),
        value: basketItem.tooltip?.articleLocation ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.dispensingUnit'),
        value: basketItem.tooltip?.dispensingUnit.toString() ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.discountRate'),
        value: basketItem.tooltip?.discountRate ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.currentMargin'),
        value: basketItem.tooltip?.currentMargin ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.purchasePrice'),
        value: basketItem.tooltip?.purchasePrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.recommendedPrice'),
        value: basketItem.tooltip?.recommendedPrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.baseSalesPrice'),
        value: basketItem.tooltip?.saleBasePrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.additionalDescription'),
        value: basketItem.tooltip?.additionalDescription ?? EMPTY_PLACEHOLDER,
      },
    ],
  }));

  return (
    <FullScreenModal headline={i18n.t('general.actions.addMaterial')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <RequestTabMaterialList
              onWarehouseDispatch={handleWarehouseDispatchMaterial}
              onWarehouseRequest={handleWarehouseRequestMaterial}
              onWarehouseNonBinding={handleWarehouseNonBindingMaterial}
              availableWarehouses={props.availableWarehouses}
              data-testid={suffixTestId('itemList', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesMaterialBasket<MaterialBasketItemWithTooltip>
              basket={{
                items: basketItemsWithTooltip,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingBasketItems,
              }}
              mechanic={{mechanics, assignedMechanic, onMechanicChange: handleMechanicChange}}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEdit}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
