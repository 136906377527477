import {filter, isNil, join, pipe} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes} from '@omnetic-dms/routes';
import {
  getBusinessCaseStateFlag,
  LastActiveBusinessCaseResponseBody,
  SaleVehicleResponseBody,
  SnippetProps,
} from '@omnetic-dms/shared';

import {buildArray, Nullish, composePath} from 'shared';

import {getShouldDisplayLastActiveBC} from './getShouldDisplayLastActiveBC';

export const getBusinessCaseSnippet = (
  saleVehicle: SaleVehicleResponseBody | Nullish,
  saleVehicleBC: LastActiveBusinessCaseResponseBody | Nullish,
  isVehicleAlreadySelling?: boolean
): Partial<SnippetProps> => {
  const shouldDisplayLastActiveBC = getShouldDisplayLastActiveBC(saleVehicleBC);

  if (isNil(saleVehicle) || !shouldDisplayLastActiveBC) {
    return {};
  }

  const isBuyingWidgetDisabled =
    (saleVehicle?.sellingState === 'sold' || isVehicleAlreadySelling) && !shouldDisplayLastActiveBC;
  const businessCaseStateFlag = getBusinessCaseStateFlag(saleVehicleBC?.businessCaseState);
  const ownerNameParts = [saleVehicleBC?.ownerFirstName, saleVehicleBC?.ownerLastName];
  const ownerName = pipe(
    filter(isNotNilOrEmpty),
    join(' '),
    (name) => `${i18n.t('entity.vehicle.labels.seller')}: ${name}`
  )(ownerNameParts);

  return {
    title: saleVehicleBC?.customerName ?? saleVehicleBC?.id,
    icon: 'places/business_center',
    href: composePath(businessCaseRoutes.overview, {
      params: {id: saleVehicleBC?.id},
      isSearchParamsPreserved: false,
    }),
    flags: isNotNil(businessCaseStateFlag) ? [businessCaseStateFlag] : undefined,
    isDisabled: isBuyingWidgetDisabled,
    parameters: buildArray<string>()
      .when(isNotNilOrEmpty(saleVehicleBC?.code), saleVehicleBC?.code ?? '')
      .add(ownerName),
  };
};
