import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {FC, PropsWithChildren, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {any, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {cebiaApi, useGetVehicleQuery} from '@omnetic-dms/shared';
import {
  getCebiaAutotraceAndReport,
  getCebiaEquipmentCredentials,
  getCustomTenantCatalogue,
  getTopMakes,
  getVehicleMakeModels,
  loadHighlightsList,
  loadShortcomingsList,
  loadVehicleDetailsData,
  selectCarAudit,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {useRequiredParams} from 'shared';

import {VehicleDocuments} from './components/VehicleDocuments';

export const Documents: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId} = useRequiredParams();
  const {data: vehicle} = useGetVehicleQuery({vehicleId});

  const {loading} = useSelector(selectCarAudit);
  const isAuditLoading = any(isTrue, [
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (isNotNil(vehicleId) && isNotNil(vehicle) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch).then(async () => {
        await dispatch(
          getVehicleMakeModels.action({
            vehicleType: vehicle.type!,
            make: vehicle.make,
          })
        );
        await Promise.all([
          dispatch(getTopMakes.action({})),
          dispatch(getCustomTenantCatalogue.action({})),
          dispatch(loadHighlightsList()),
          dispatch(loadShortcomingsList()),
          dispatch(getCebiaEquipmentCredentials()),
          dispatch(getCebiaAutotraceAndReport()),
          dispatch(cebiaApi.endpoints.getCebiaReport.initiate({vehicleId})),
        ]);
      });
    }
  }, [vehicle, vehicleId]);

  return (
    <VStack width="100%">
      <DataStatus isLoading={isAuditLoading} minHeight={100}>
        <VehicleDocuments />;
      </DataStatus>
    </VStack>
  );
};
