import {useMemo, useRef, ReactNode, RefObject, useLayoutEffect} from 'react';
import {createPortal} from 'react-dom';

import {isNotNil} from 'ramda-adjunct';

import {createContext, useForceUpdate} from 'shared';

import {usePortalManager} from './PortalContext';

type PortalContext = HTMLDivElement | null;

const [PortalContextProvider, usePortalContext] = createContext<PortalContext>({
  strict: false,
  name: 'PortalContext',
});

interface ContainerProps {
  children: ReactNode;
  zIndex?: number;
}

function Container(props: ContainerProps) {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: props.zIndex,
        top: 0,
        left: 0,
        right: 0,
        // NB: Don't add `bottom: 0`, it makes the entire app unusable
        // @see https://github.com/chakra-ui/chakra-ui/issues/3201
      }}
    >
      {props.children}
    </div>
  );
}

interface DefaultPortalProps {
  children: ReactNode;
  appendToParentPortal?: boolean;
}

/**
 * Portal that uses `document.body` as container
 */
function DefaultPortal(props: DefaultPortalProps) {
  const {appendToParentPortal, children} = props;

  const tempNode = useRef<HTMLDivElement | null>(null);
  const portal = useRef<HTMLDivElement | null>(null);

  const forceUpdate = useForceUpdate();

  const parentPortal = usePortalContext();
  const manager = usePortalManager();

  useLayoutEffect(() => {
    if (!tempNode.current) {
      return;
    }

    const doc = tempNode.current!.ownerDocument;
    const host = appendToParentPortal ? parentPortal ?? doc.body : doc.body;

    if (!host) {
      return;
    }

    portal.current = doc.createElement('div');

    host.appendChild(portal.current);
    forceUpdate();

    const portalNode = portal.current;
    return () => {
      if (host.contains(portalNode)) {
        host.removeChild(portalNode);
      }
    };
  }, []);

  const _children = isNotNil(manager?.zIndex) ? (
    <Container zIndex={manager?.zIndex}>{children}</Container>
  ) : (
    children
  );

  return portal.current ? (
    createPortal(
      <PortalContextProvider value={portal.current}>{_children}</PortalContextProvider>,
      portal.current
    )
  ) : (
    <span ref={tempNode} />
  );
}

interface ContainerPortalProps {
  containerRef: RefObject<HTMLElement | null>;
  appendToParentPortal?: boolean;
  children: ReactNode;
}

/**
 * Portal that uses a custom container
 */
function ContainerPortal(props: ContainerPortalProps) {
  const {children, containerRef, appendToParentPortal} = props;
  const containerEl = containerRef.current;
  const host = containerEl ?? (isBrowser ? document.body : undefined);

  const portal = useMemo(() => {
    const node = containerEl?.ownerDocument.createElement('div');
    return node;
  }, [containerEl]);

  const forceUpdate = useForceUpdate();

  useLayoutEffect(() => {
    forceUpdate();
  }, []);

  useLayoutEffect(() => {
    if (!portal || !host) {
      return;
    }
    host.appendChild(portal);
    return () => {
      host.removeChild(portal);
    };
  }, [portal, host]);

  if (host && portal) {
    return createPortal(
      <PortalContextProvider value={appendToParentPortal ? portal : null}>
        {children}
      </PortalContextProvider>,
      portal
    );
  }

  return null;
}

export interface PortalProps {
  /**
   * The `ref` to the component where the portal will be attached to.
   */
  containerRef?: RefObject<HTMLElement | null>;
  /**
   * The content or node you'll like to portal
   */
  children: ReactNode;
  /**
   * If `true`, the portal will check if it is within a parent portal
   * and append itself to the parent's portal node.
   * This provides nesting for portals.
   *
   * If `false`, the portal will always append to `document.body`
   * regardless of nesting. It is used to opt out of portal nesting.
   */
  appendToParentPortal?: boolean;
}

export function Portal(props: PortalProps) {
  const {containerRef, ...rest} = props;
  return containerRef ? (
    <ContainerPortal containerRef={containerRef} {...rest} />
  ) : (
    <DefaultPortal {...rest} />
  );
}

const isBrowser = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
