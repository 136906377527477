import {Form, FormField, FormSubmitHandler, Label, showNotification} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil, keysIn} from 'ramda';
import {isNotNilOrEmpty, isNotString, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  EditInvoiceMarginApiArg,
  handleApiError,
  useBranches,
  useCreateInvoiceMarginMutation,
  useGetInvoiceMarginQuery,
  useUpdateInvoiceMarginMutation,
} from '@omnetic-dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = EditInvoiceMarginApiArg;

const breadcrumbs = [
  {
    label: i18n.t('page.accountingSettings.labels.invoiceMargin'),
    href: settingsRoutes.invoiceMarginOverview,
  },
];

export function InvoiceMarginDetail() {
  const navigate = useNavigate();
  const {id} = useParams();
  const isCreating = isNotString(id);

  const [updateInvoiceMargin] = useUpdateInvoiceMarginMutation();
  const [createInvoiceMargin] = useCreateInvoiceMarginMutation();

  const {
    data: branches,
    branchOptions,
    isLoading: isLoadingBranches,
    isError: isBranchesError,
  } = useBranches();
  const {
    data: invoiceMargin,
    isLoading: isLoadingInvoiceMargin,
    isError: isInvoiceMarginError,
  } = useGetInvoiceMarginQuery({id: id as string}, {skip: isCreating});

  const isLoading = isLoadingBranches || isLoadingInvoiceMargin;
  const isError = isBranchesError || isInvoiceMarginError;

  const handleOnSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const submitAction = isCreating ? createInvoiceMargin : updateInvoiceMargin;

    await submitAction(data)
      .unwrap()
      .then(() => {
        showNotification.success();
        navigate(settingsRoutes.invoiceMarginOverview);
      })
      .catch(handleApiError);
  };

  const getTitle = () => {
    if (isNil(branches) || isNil(invoiceMargin) || isCreating) {
      return i18n.t('page.accounting.labels.newDefinition');
    }

    const {branchId, isSystem, ...invoiceMarginItems} = invoiceMargin;

    if (isSystem) {
      return i18n.t('page.accounting.labels.tenantDefinition');
    }

    const branchName = branches.branchListItems.find(
      (branch) => branch.id === branchId
    )?.marketingName;

    const filledBusinessCaseType = keysIn(invoiceMarginItems)
      .filter((key) => isTrue(invoiceMarginItems[key as keyof typeof invoiceMarginItems]))
      .map((key) => i18n.t(`page.accountingSettings.labels.${key}`))
      .join(', ');

    return `${branchName}${
      isNotNilOrEmpty(filledBusinessCaseType) ? ' - ' : ''
    }${filledBusinessCaseType}`;
  };

  return (
    <SettingsTemplate
      header={{
        title: getTitle(),
        breadcrumbs,
      }}
      isCreating={isCreating}
      data-testid={testIds.settings.invoiceMarginDetail('page')}
      isError={isError}
      isLoading={isLoading}
    >
      <Form<FormValues> onSubmit={handleOnSubmit} defaultValues={invoiceMargin} schema={schema}>
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isDisabled={invoiceMargin?.isSystem}
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  placeholder={
                    invoiceMargin?.isSystem
                      ? i18n.t('page.accounting.labels.tenantDefinition')
                      : undefined
                  }
                  isRequired
                  isNotClearable
                />
              </Grid>
            </SettingsSection>

            <VStack spacing={1}>
              <Label isRequired>{i18n.t('page.accountingSettings.labels.businessCaseType')}</Label>
              <VStack spacing={2}>
                <FormField
                  control={control}
                  type="checkbox"
                  name="brokerageNotVatDeductible"
                  label={i18n.t('page.accountingSettings.labels.brokerageNotVatDeductible')}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="buyNotVatDeductible"
                  label={i18n.t('page.accountingSettings.labels.buyNotVatDeductible')}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="buyVatDeductible"
                  label={i18n.t('page.accountingSettings.labels.buyVatDeductible')}
                />
              </VStack>
            </VStack>

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discard'),
                  onClick: () => navigate(settingsRoutes.invoiceMarginOverview),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: isCreating
                    ? i18n.t('general.actions.create')
                    : i18n.t('general.actions.edit'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  branchId: yupString.when('isSystem', {
    is: true,
    then: (def) => def.default(null),
    otherwise: (def) => def.required(),
  }),
  buyNotVatDeductible: boolean().default(false).nullable().required(),
  buyVatDeductible: boolean().default(false).nullable().required(),
  brokerageNotVatDeductible: boolean().default(false).nullable().required(),
});
