import {DropdownTagMenuItem, showNotification} from 'platform/components';
import {Nullish} from 'utility-types';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  UserResponseBody,
  handleApiError,
  useBlockUserMutation,
  useUnblockUserMutation,
} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

export const useUserActivationDropdownParams = (user: UserResponseBody | Nullish) => {
  const [blockUser] = useBlockUserMutation();
  const [unblockUser] = useUnblockUserMutation();

  const isUserBlocked = user?.blocked ?? false;

  const handleBlockUser = () => {
    if (isNil(user)) {
      throw new Error('User is not defined');
    }

    blockUser({userId: user?.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleUnblockUser = () => {
    if (isNil(user)) {
      throw new Error('User is not defined');
    }

    unblockUser({userId: user?.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const dropdownItems = buildArray<DropdownTagMenuItem>()
    .when(isUserBlocked, {
      label: i18n.t('general.actions.activate'),
      onClick: handleUnblockUser,
    })
    .whenNot(isUserBlocked, {
      label: i18n.t('general.actions.deactivate'),
      onClick: handleBlockUser,
    });

  const colorScheme = isUserBlocked ? 'red' : 'green';
  const label = isUserBlocked
    ? i18n.t('general.labels.blocked')
    : i18n.t('entity.settings.labels.active');

  return {label, dropdownItems, colorScheme} as const;
};
