import {Action, closeDialog, openDialog} from 'platform/components';
import {useRefreshDataGrid} from 'platform/datagrid';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useMatch, useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {workshopRoutes, testIds} from '@omnetic-dms/routes';
import {CatalogueFolder, LabourCatalogItemFrom, Main, WorkItemsListCard} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {DirectSalesOverview} from './(sections)/DirectSalesOverview/DirectSalesOverview';
import {ServiceCaseOverview} from './(sections)/ServiceCaseoverview/ServiceCaseOverview';
import {ServiceOrderOverview} from './(sections)/ServiceOrderOverview/ServiceOrderOverview';

export function WorkshopOverview() {
  const navigate = useNavigate();
  const labourOperationSettingsMatch = useMatch(workshopRoutes.labourOperationSettings);

  const [labourDataGridRef, refreshLabourDataGrid] = useRefreshDataGrid();
  const [activeLabourtFolder, setActiveLabourFolder] = useState<CatalogueFolder | undefined>();

  const onCreateNewLabourClick = () => {
    if (isNil(activeLabourtFolder)) {
      return;
    }

    openDialog(
      <LabourCatalogItemFrom
        onClose={() => closeDialog('createNewItemDialog')}
        onSubmitted={refreshLabourDataGrid}
        catalogueFolder={activeLabourtFolder}
        data-testid={testIds.workshop.serviceCases('createNewLabour')}
      />,
      {
        id: 'createNewItemDialog',
        title: i18n.t('general.labels.createNew'),
        scrollBehavior: 'outside',
      }
    );
  };

  const tabs = [
    {
      id: 'serviceCases',
      title: i18n.t('page.workshop.labels.serviceCases'),
      content: <ServiceCaseOverview />,
      href: workshopRoutes.serviceCases,
    },
    {
      id: 'serviceOrders',
      title: i18n.t('page.workshop.labels.serviceOrders'),
      content: <ServiceOrderOverview />,
      href: workshopRoutes.serviceOrders,
    },
    {
      id: 'directSales',
      title: i18n.t('page.workshop.labels.directSales'),
      content: <DirectSalesOverview />,
      href: workshopRoutes.directSales,
    },
    {
      id: 'labourOperationSettings',
      title: i18n.t('page.workshop.labels.labourOperationSettings'),
      content: (
        <WorkItemsListCard
          data-testid={testIds.workshop.labourOperationSettings()}
          createOverride={{
            datagridRef: labourDataGridRef,
            refreshDataGrid: refreshLabourDataGrid,
            activeFolder: activeLabourtFolder,
            setActiveFolder: setActiveLabourFolder,
          }}
        />
      ),
      href: workshopRoutes.labourOperationSettings,
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('module.workshop.title')} />
      <Main
        isFullHeight
        tabs={tabs}
        actions={buildArray<Action>()
          .whenNot(labourOperationSettingsMatch, {
            type: 'button',
            title: i18n.t('page.workshop.labels.newServiceCase'),
            onClick: () => navigate(workshopRoutes.createServiceCase),
          })
          .when(labourOperationSettingsMatch, {
            type: 'button',
            title: i18n.t('entity.workshopServicePackages.actions.createCatalogueItem'),
            isDisabled: isNil(activeLabourtFolder),
            onClick: onCreateNewLabourClick,
          })}
        data-testid={testIds.workshop.serviceCases('createServiceCase')}
      />
    </>
  );
}
