import {ButtonGroup, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  SaleVehicleResponseBody,
  useGetBusinessCaseQuery,
  useSetReadyForPricingMutation,
} from '@omnetic-dms/shared';

import {Nullish, useRequiredParams} from 'shared';

interface ReadyToPriceButtonProps {
  BusinessCaseProgressState: SaleVehicleResponseBody['buyingState'] | Nullish;
  onAfterSubmit?: () => void;
}

export function ReadyToPriceButton(props: ReadyToPriceButtonProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicle =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0] ?? null
      : businessCase?.offers?.[0]?.purchaseVehicles?.[0] ?? null;

  const [setReadyForPricing, {isLoading}] = useSetReadyForPricingMutation();

  const handleReadyToPrice = () => {
    if (!purchaseVehicle?.vehicleId) {
      showNotification.error();
      return;
    }

    void setReadyForPricing({
      businessCaseId,
      offerPurchaseVehicleId: purchaseVehicle?.vehicleId ?? '',
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(props.onAfterSubmit)
      .catch(handleApiError);
  };

  const shouldBeButtonVisible = match(props.BusinessCaseProgressState ?? '')
    .with('buying', always(true))
    .with('for-pricing', always(false))
    .otherwise(always(false));

  if (!shouldBeButtonVisible) {
    return null;
  }

  return (
    <ButtonGroup
      align="left"
      buttons={[
        {
          onClick: handleReadyToPrice,
          title: i18n.t('general.actions.readyToPrice'),
          isLoading,
          isDisabled: !purchaseVehicle?.costs.costsOn,
        },
      ]}
    />
  );
}
