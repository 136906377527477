import {DayMonthType} from 'platform/foundation';

import {useLocale} from '../../hooks/useLocale';
import {LocaleDayMonthFormat} from './types/LocaleDayMonthFormat';
import {formatDayMonth} from './utils/formatDayMonth';

export function useDayMonthFormatter() {
  const {localeConfig, language} = useLocale();

  return (dayMonthFormat: LocaleDayMonthFormat, value: DayMonthType) =>
    formatDayMonth(localeConfig, language, dayMonthFormat, value);
}
