import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  OptionType,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isFalse} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  IssuedType,
  handleApiError,
  useBranches,
  useGetTaxDocumentSettingQuery,
  useUpdateTaxDocumentSettingMutation,
} from '@omnetic-dms/shared';

import {NullishBoolean, useNavigate, useRequiredParams, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string | null;
  issuedType: IssuedType;
};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accountingSettings.labels.issuedType'),
    href: settingsRoutes.taxDocumentIssuedType,
  },
];

export function TaxDocumentForPaymentIssuedTypeDetail() {
  const {id: issuedTypeId} = useRequiredParams();
  const navigate = useNavigate();

  const {
    data: issuedTypeDefinition,
    isLoading: isLoadingDefinition,
    isError: isDefinitionError,
  } = useGetTaxDocumentSettingQuery({id: issuedTypeId});
  const [updateIssuedTypeDefinition] = useUpdateTaxDocumentSettingMutation();

  const {
    data: branches,
    branchOptions,
    isLoading: isLoadingBranches,
    isError: isBranchesError,
  } = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) =>
    await updateIssuedTypeDefinition({updateTaxDocumentSettingRequestBody: data, id: issuedTypeId})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.taxDocumentIssuedType))
      .catch(handleApiError);

  const isLoading = isLoadingBranches || isLoadingDefinition;
  const isError = isBranchesError || isDefinitionError;

  const branchName =
    branches?.branchListItems.find((branch) => branch.id === issuedTypeDefinition?.branchId)
      ?.marketingName ?? i18n.t('page.accounting.labels.tenantDefinition');

  const title = `${branchName} - ${i18n.t(`page.accountingSettings.labels.${issuedTypeDefinition?.issuedType}`)}`;

  const defaultValues = issuedTypeDefinition
    ? {
        branchId: issuedTypeDefinition.branchId,
        issuedType: issuedTypeDefinition.issuedType,
      }
    : undefined;

  return (
    <SettingsTemplate
      header={{
        title,
        breadcrumbs,
      }}
      data-testid={testIds.settings.taxDocumentIssuedTypeDetail('page')}
      isLoading={isLoading}
      isError={isError}
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        schema={getSchema(issuedTypeDefinition?.isSystem)}
        defaultValues={defaultValues}
      >
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  isRequired
                  isDisabled={issuedTypeDefinition?.isSystem}
                  placeholder={
                    issuedTypeDefinition?.isSystem
                      ? i18n.t('page.accounting.labels.tenantDefinition')
                      : undefined
                  }
                />
                <FormField
                  control={control}
                  type="choice"
                  options={issuedTypeOptions}
                  name="issuedType"
                  isNotClearable
                  isRequired
                  label={i18n.t('page.accountingSettings.labels.issuedType')}
                />
              </Grid>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.taxDocumentIssuedType),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const getSchema = (isSystem: NullishBoolean) =>
  object({
    branchId: yupString.when([], {
      is: () => isFalse(isSystem),
      then: (schema) => schema.required(),
    }),
    issuedType: yupString.required(),
  });

const issuedTypeOptions: OptionType<IssuedType>[] = [
  {value: 'automatic', label: i18n.t('page.accountingSettings.labels.automatic')},
  {value: 'manual', label: i18n.t('page.accountingSettings.labels.manual')},
  {value: 'not_create', label: i18n.t('page.accountingSettings.labels.not_create')},
];
