import {
  Actions,
  ButtonGroup,
  Overlay,
  ProgressBar,
  showNotification,
  Upload,
  UploadState,
} from 'platform/components';
import {Box, Center, Clickable, Grid, Show} from 'platform/foundation';
import {Lightbox, LightboxVideo, useLightbox} from 'platform/lightbox';
import {RcFile} from 'rc-upload/lib/interface';

import {CSSProperties, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {useLazyGetFileByIdQuery, useUploadVideoAssetMutation} from '@omnetic-dms/shared';

import {Nullish, parseDate, randomLowerCaseUUID, suffixTestId, TestIdProps} from 'shared';

import {
  QueueVideoTask,
  RawFile,
  useVideoUploadQueueAction,
  useVideoUploadQueueState,
} from 'features/video-upload-queue';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {addVideoAssets} from '../../../store/carAudit/reducer';
import {selectActiveAuditId} from '../../../store/carAudit/selectors';
import {useAuditVideos} from '../hooks/useAuditVideos';

interface AuditVideosProps extends TestIdProps {
  paramDefinitionId: string;
  categoryId: string;
  ratio?: CSSProperties['aspectRatio'];
  isMandatory?: boolean;
}

export function AuditVideos(props: AuditVideosProps) {
  const [getFileById] = useLazyGetFileByIdQuery();
  const {videoAssets} = useAuditVideos(props.categoryId, props.paramDefinitionId);
  const [enteredVideo, setIsMouseEnter] = useState<number | null>(null);
  const auditId = useSelector(selectActiveAuditId);
  const dispatch = useThunkDispatch();

  const [uploadState, setUploadState] = useState<UploadState>(UploadState.Idle);
  const {tasks, uploadProgress} = useVideoUploadQueueState();
  const {handleMultiMultiPartUpload} = useVideoUploadQueueAction();
  const [uploadVideoAsset] = useUploadVideoAssetMutation();

  const [lightboxUploadImagesGalleryControls, {onOpen: onUploadImagesGalleryOpen}] = useLightbox(
    `auditVideos-${props.categoryId}-${props.paramDefinitionId}`
  );

  const lightboxVideoAssets: LightboxVideo[] | Nullish = videoAssets?.map((video) => ({
    ...video,
    handleFetchVideo: () =>
      getFileById({fileId: video.videoId})
        .unwrap()
        .then((response) => response.originalUri),
  }));

  const handleUploadFinished = async (params: QueueVideoTask) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    const options = {
      videoId: params.connectionFileId,
      auditId,
      categoryId: props.categoryId,
      updatedAt: parseDate(Date.now()).toISOString(),
      vehicleAuditVideoAssetId: params.id,
      paramDefinitionId: props.paramDefinitionId,
    };
    const response = await uploadVideoAsset({auditId: options.auditId, body: options}).unwrap();

    if (response.id) {
      dispatch(
        addVideoAssets({
          categoryId: props.categoryId,
          paramDefinitionId: props.paramDefinitionId,
          assets: [{id: response.id, videoId: params.connectionFileId}],
        })
      );
      showNotification.success(
        i18n.t('entity.inspection.labels.uploadCompleted', {filename: params.name})
      );
    } else {
      showNotification.error(
        i18n.t('entity.inspection.errors.uploadFailed', {filename: params.name})
      );
    }
  };

  const handleStartUpload = async (file: RcFile) => {
    setUploadState(UploadState.Uploading);

    const rawFile: RawFile = {
      id: randomLowerCaseUUID(),
      uri: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      mimeType: file.type,
    };

    await handleMultiMultiPartUpload(rawFile, handleUploadFinished);
    setUploadState(UploadState.Success);
  };

  return (
    <Grid columns={8}>
      <Lightbox
        data-testid={suffixTestId('audit-uploadVideosGallery', props)}
        controls={lightboxUploadImagesGalleryControls}
        videos={lightboxVideoAssets}
      />
      {videoAssets?.map((videoAsset, index) => (
        <Box
          key={videoAsset.id}
          width="100%"
          height="100%"
          borderRadius="small"
          backgroundColor="palettes.neutral.30.100"
          ratio={props.ratio}
          position="relative"
          overflow="hidden"
        >
          <Clickable
            width="100%"
            height="100%"
            onMouseEnter={() => setIsMouseEnter(index)}
            onMouseLeave={() => setIsMouseEnter(null)}
          >
            <Show when={enteredVideo === index}>
              <Overlay variant="neutral" density="high">
                <Center width="100%" height="100%" justify="center">
                  <ButtonGroup data-testid={suffixTestId('preview', props)}>
                    <Actions
                      data-testid={suffixTestId('preview', props)}
                      actions={[
                        {
                          type: 'iconButton',
                          size: 'default',
                          priority: 'secondary',
                          icon: 'AV/play_arrow',
                          onClick: () => onUploadImagesGalleryOpen(index),
                        },
                      ]}
                    />
                  </ButtonGroup>
                </Center>
              </Overlay>
            </Show>
          </Clickable>
        </Box>
      ))}

      {tasks.map((task) => {
        const progress =
          (uploadProgress.find((progress) => (progress.id = task.id))?.progress ?? 0) / 100;

        return (
          <Box
            key={task.id}
            width="100%"
            height="100%"
            ratio={props.ratio}
            padding={2}
            border="1px dashed"
            borderColor="palettes.neutral.80.100"
            borderRadius="xSmall"
          >
            <Center height="100%">
              <ProgressBar
                percentage={progress || undefined}
                indeterminate={progress === 0}
                small
              />
            </Center>
          </Box>
        );
      })}

      <Box width="100%" height="100%" ratio={props.ratio} position="relative" overflow="hidden">
        <Upload
          size="default"
          accept="video/*"
          type="card"
          uploadIcon="image/video_call"
          uploadText={`${props.isMandatory ? '*' : ''} ${i18n.t('general.actions.addVideos')}`}
          errorIcon="navigation/cancel"
          customRequest={({onSuccess}) => {
            onSuccess?.('', new XMLHttpRequest());
          }}
          onStart={handleStartUpload}
          uploadState={uploadState}
          isMultiple
          data-testid={suffixTestId('uploadControl', props)}
        />
      </Box>
    </Grid>
  );
}
