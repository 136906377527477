import {DataStatus, Separator} from 'platform/components';
import {Heading, Text, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {defaultTo, dropLast, join, pipe, split, sum} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  EMPTY_PLACEHOLDER,
  GetServiceOrderJobApiResponse,
  numberToTimeString,
  useGetServiceCaseOrderJobsQuery,
  useGetServiceOrderTimeTrackingQuery,
  useLazyGetServiceOrderJobQuery,
} from '@omnetic-dms/shared';

import {TestIdProps, isNilOrZero} from 'shared';

import {JobTrackedInfo} from '../../../../../components/JobTrackedInfo/JobTrackedInfo';

// Polling interval - 1 minute in miliseconds
const POLLING_INTERVAL = 60000;

interface OrderTrackedTimeProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
}

export function OrderTrackedTime(props: OrderTrackedTimeProps) {
  const [estimatedTime, setEstimatedTime] = useState<null | number>(null);
  const [jobDetails, setJobDetails] = useState<GetServiceOrderJobApiResponse[]>([]);

  const {data, isLoading, isError} = useGetServiceOrderTimeTrackingQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
    },
    {pollingInterval: POLLING_INTERVAL}
  );
  const {data: serviceCaseOrderJobs} = useGetServiceCaseOrderJobsQuery({
    serviceOrderId: props.serviceOrderId,
    serviceCaseId: props.serviceCaseId,
  });
  const [getserviceOrderJob] = useLazyGetServiceOrderJobQuery();

  useEffect(() => {
    if (isNilOrEmpty(serviceCaseOrderJobs)) {
      return;
    }

    Promise.all([
      ...(serviceCaseOrderJobs?.map((job) =>
        getserviceOrderJob({
          serviceOrderId: props.serviceOrderId,
          serviceCaseId: props.serviceCaseId,
          serviceJobId: job?.id ?? '',
        }).unwrap()
      ) ?? []),
    ]).then((jobs) => {
      setEstimatedTime(sum([...jobs.map((job) => job?.workTimeEstimated ?? 0)]));
      setJobDetails(jobs);
    });
  }, [getserviceOrderJob, serviceCaseOrderJobs, props.serviceOrderId, props.serviceCaseId]);

  return (
    <DataStatus
      isLoading={isLoading}
      isError={isError}
      isEmpty={isNilOrEmpty(data?.serviceOrder?.serviceCaseJobs)}
    >
      <VStack>
        <VStack spacing={1}>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.order.labels.totalWorkTime')}
          </Text>
          <Heading size={3}>{getFormatedTime(data?.serviceOrder?.totalTimeTracked)}</Heading>
          <Text size="xSmall" color="secondary">
            {isNilOrZero(estimatedTime)
              ? i18n.t('entity.orderRequest.labels.noEstimatedTime')
              : `${numberToTimeString(estimatedTime)} (${i18n.t('entity.orderRequest.labels.estimatedTime')})`}
          </Text>
        </VStack>
        {data?.serviceOrder?.serviceCaseJobs?.map((jobTime) => {
          const job = jobDetails.find((job) => job?.id === jobTime?.serviceCaseJobId);

          return (
            <VStack key={jobTime?.serviceCaseJobId}>
              <Separator />
              <JobTrackedInfo
                jobName={job?.name ?? EMPTY_PLACEHOLDER}
                estimatedTime={job?.workTimeEstimated}
                serviceCaseId={props.serviceCaseId}
                serviceOrderId={props.serviceOrderId}
                serviceCaseJobId={job?.id}
              />
            </VStack>
          );
        })}
      </VStack>
    </DataStatus>
  );
}

const getFormatedTime = pipe(defaultTo('00:00:00'), split(':'), dropLast(1), join(':'));
