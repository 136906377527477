import {FormControl} from 'platform/components';
import {Grid} from 'platform/foundation';

import {useEffect} from 'react';
import {Path, UseFormReturn} from 'react-hook-form';

import {BackgroundRemovalFormValues} from '../types/BackgroundRemovalFormValues';
import {ImageOptionItem} from './ImageOptionItem';

interface BackgroundSelectorProps {
  name: Path<BackgroundRemovalFormValues>;
  options: string[];
  control: FormControl<BackgroundRemovalFormValues>;
  formApi: UseFormReturn<BackgroundRemovalFormValues>;
}

export function BackgroundSelector(props: BackgroundSelectorProps) {
  const activeItem = props.formApi.watch(props.name) as (typeof props.options)[number];

  useEffect(() => {
    props.formApi.register(props.name);
  }, [props.formApi, props.name]);

  return (
    <Grid columns={4}>
      {props.options.map((style) => (
        <ImageOptionItem
          key={style}
          formApi={props.formApi}
          name={props.name}
          value={style}
          activeItem={activeItem}
        />
      ))}
    </Grid>
  );
}
