import {SortByOptionsType} from '../../types/CommonTypes';
import {DataViewContainerName, DataViewStateType} from './DataViewState';
import {createDataViewSlice} from './reducer';
import {createDataViewSelectors} from './selectors';

type CreateDataViewParams = {
  name: DataViewContainerName;
  sortByOptions?: SortByOptionsType[];
  initialState?: Partial<DataViewStateType>;
  margin?: number;
  pathToVehicleData?: string[];
  marginLte?: number;
  marginGte?: number;
  positionGte?: number;
  positionLte?: number;
};

export const createDataView = ({name, sortByOptions, initialState}: CreateDataViewParams) => {
  const {reducer, actions} = createDataViewSlice(name, initialState);
  const selectors = createDataViewSelectors(name);

  return {
    reducer,
    actions,
    selectors,
    name,
    sortByOptions,
  };
};

// export {DataViewContainerName};
// export * from './DataViewState';
