/* eslint-disable default-param-last */
import {PayloadAction} from '@reduxjs/toolkit';

import {all, put, takeLatest} from 'redux-saga/effects';

import {isNotNil} from 'ramda-adjunct';

import {SourcingVehicleService} from '../../services/SourcingVehicleService';
import {callApiSaga} from '../../utils/api';
import {loadedVehicleDetail, loadVehicleDetail} from './reducer';
import {LoadVehicleDetailPayload} from './types';

export function* getVehicleDetailGen({
  payload: {
    id,
    margin,
    marginLte,
    marginGte,
    currency,
    branchId,
    positionGte,
    positionLte,
    buyerCountry,
  },
}: PayloadAction<LoadVehicleDetailPayload>): Generator {
  let data = null;
  const query = {
    currency,
    buyerCountry,
    externalId: id,
    xBranch: branchId,
    // eslint-disable-next-line no-restricted-syntax
    margin: (isNotNil(margin) ? parseInt(margin as string, 10) : undefined) as unknown as number,
    // eslint-disable-next-line no-restricted-syntax
    marginLte: (isNotNil(marginLte)
      ? parseInt(marginLte as string, 10)
      : undefined) as unknown as number,
    // eslint-disable-next-line no-restricted-syntax
    marginGte: (isNotNil(marginGte)
      ? parseInt(marginGte as string, 10)
      : undefined) as unknown as number,
    positionGte: isNotNil(positionGte) ? Number(positionGte as string) : undefined,
    positionLte: isNotNil(positionLte) ? Number(positionLte as string) : undefined,
  };

  // eslint-disable-next-line no-restricted-syntax
  data = yield* callApiSaga(SourcingVehicleService.sourcingVehicleDetail, query);

  yield put(loadedVehicleDetail(data));
}

export function* vehicleDetailSaga(): Generator {
  yield all([takeLatest(loadVehicleDetail.type, getVehicleDetailGen)]);
}
