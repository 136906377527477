import {Attributes} from 'platform/components';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {useGetTagsForEntityQuery} from '../../../api/tagApi';
import {useVehicleCatalogue} from '../../../hooks/useVehicleCatalogue';
import i18n from '../../../i18n/i18n';
import {EntityResourceIds, FullVehicleResponseBody} from '../../../types/api/api';
import {ValueWithTags} from '../../ValueWithTags/ValueWithTags';

interface VehicleOverviewProps extends TestIdProps {
  vehicle: FullVehicleResponseBody;
}

export function AftersaleVehicleOverview(props: VehicleOverviewProps) {
  const [vehicleUtils] = useVehicleCatalogue(props.vehicle.type);

  const bodyStyle = vehicleUtils.getBodyStyle(props.vehicle?.bodyStyle);
  const vehicleType = vehicleUtils.getVehicleType(props.vehicle?.type);
  const transmission = vehicleUtils.getTransmission(props.vehicle?.transmission);
  const driveType = vehicleUtils.getDrive(props.vehicle?.drive);
  const doorCountCategory = vehicleUtils.getDoorCount(props.vehicle?.doorCountCategory);
  const fuelType = vehicleUtils.getFuelType(props.vehicle?.fuelType);

  const {data: tags} = useGetTagsForEntityQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: props.vehicle.id ?? '',
    },
    {skip: !props.vehicle.id}
  );

  return (
    <Attributes
      data-testid={suffixTestId('overview', props)}
      rows={[
        {
          label: i18n.t('entity.vehicle.labels.licensePlate'),
          value: props.vehicle.state?.registrationPlate,
          testId: 'licensePlate',
        },
        {
          label: i18n.t('entity.vehicle.labels.vin'),
          value: props.vehicle.vin,
          testId: 'vin',
        },
        {
          label: i18n.t('entity.vehicle.labels.type'),
          value: vehicleType,
          testId: 'type',
        },
        {
          label: i18n.t('entity.vehicle.labels.body'),
          value: bodyStyle,
          testId: 'body',
        },
        {
          label: i18n.t('entity.vehicle.labels.title'),
          content: <ValueWithTags value={props.vehicle.title} tags={tags} />,
          testId: 'title',
        },
        {
          label: i18n.t('entity.vehicle.labels.firstRegistration'),
          value: `${
            isNotNil(props.vehicle.firstRegistrationOnDay)
              ? `${props.vehicle.firstRegistrationOnDay}.`
              : ''
          }${
            isNotNil(props.vehicle.firstRegistrationOnMonth)
              ? `${props.vehicle.firstRegistrationOnMonth}.`
              : ''
          }${props.vehicle.firstRegistrationOnYear ?? ''}`,
          testId: 'firstRegistration',
        },
        {
          label: i18n.t('entity.vehicle.labels.transmission'),
          value: transmission,
          testId: 'transmission',
        },
        {
          label: i18n.t('entity.vehicle.labels.drive'),
          value: driveType,
          testId: 'drive',
        },
        {
          label: i18n.t('entity.vehicle.labels.numberOfDoors'),
          value: doorCountCategory,
          testId: 'numberOfDoors',
        },
        {
          label: i18n.t('entity.vehicle.labels.fuel'),
          value: fuelType,
          testId: 'fuel',
        },
        {
          label: i18n.t('entity.vehicle.labels.power'),
          value: isNotNil(props.vehicle.power) ? props.vehicle.power.toString() : undefined,
          testId: 'power',
        },
        {
          label: `${i18n.t('entity.vehicle.labels.engineCapacity')} (${i18n.t(
            'general.metric.ccm'
          )})`,
          value: props.vehicle.engine?.engineVolume,
          testId: 'engineCapacity',
        },
      ]}
    />
  );
}
