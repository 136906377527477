import {FormControl, TextInput} from 'platform/components';

import {UseFormReturn, useController} from 'react-hook-form';

import {juxt} from 'ramda';

import {Nullish, TestIdProps, suffixTestId, noop} from 'shared';

import {useGetVehicleTitle} from '../../../hooks/useGetVehicleTitle';
import i18n from '../../../i18n/i18n';
import {ServiceVehicleFormType} from '../types/ServiceVehicleFormType';

export interface VehicleTitleFieldProps extends TestIdProps {
  defaultValue?: string | Nullish;
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType, object>;
}

export function VehicleTitleField(props: VehicleTitleFieldProps) {
  const make = props.formApi.watch('make');
  const vehicleType = props.formApi.watch('type')?.[0];
  const modelFamily = props.formApi.watch('modelFamily');
  const trim = props.formApi.watch('trim');
  const variant = props.formApi.watch('variant');

  const [vehicleTitle, {isLoading}] = useGetVehicleTitle({
    make,
    vehicleType,
    modelFamily,
    trim,
    variant,
  });

  const controller = useController({
    name: 'title',
    control: props.control,
    defaultValue: props.defaultValue ?? vehicleTitle,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = juxt<any[], unknown>([
    controller.field.onChange,
    props.control.onChange ?? noop,
  ]);

  return (
    <TextInput
      data-testid={suffixTestId('vehicleTitle', props)}
      type="text"
      isDisabled={isLoading}
      label={i18n.t('entity.vehicle.labels.vehicleTitle')}
      name={controller.field.name}
      value={controller.field.value ?? null}
      onChange={onChange}
    />
  );
}
