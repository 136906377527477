import {
  Button,
  ButtonGroup,
  Choice,
  closeCurrentDialog,
  closeDialog,
  CreatableChoice,
  DataStatus,
  FlagColorScheme,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  IconButton,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {Box, Hide, HStack, Show, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {
  concat,
  defaultTo,
  filter,
  find,
  has,
  head,
  indexBy,
  is,
  isNotNil,
  keys,
  map,
  match,
  path,
  pipe,
  prop,
  sort,
} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {parseDate} from 'shared';

import {
  useAssignTagMutation,
  useCreateTagMutation,
  useDeleteTagMutation,
  useEditTagMutation,
  useGetTagQuery,
  useGetTagsForEntityQuery,
  useGetTagsQuery,
  useRestoreTagMutation,
} from '../../../../api/tagApi';
import i18n from '../../../../i18n/i18n';
import {EditTagArg, EntityResourceIds, GetTagRes, GetTagResBody} from '../../../../types/api/api';
import {handleApiError} from '../../../../utils/handleApiError';
import {CREATE_OR_EDIT_TAG_DIALOG_ID} from './const';

interface CreateOrEditTagForm {
  tagValueId?: string;
  resourceId: EntityResourceIds;
  recordId: string;
  isDeletable?: boolean;
  isCreatable?: boolean;
}

const isString = is(String);
const WHITESPACE_REGEX = new RegExp('/^s+$/');
const MAX_TAG_LENGTH = 24;

export function CreateOrEditTagForm({
  tagValueId,
  isDeletable,
  isCreatable,
  ...assignRequestBody
}: CreateOrEditTagForm) {
  const currentDate = new Date();

  const [updateTag] = useEditTagMutation();
  const [createTag] = useCreateTagMutation();
  const [assignTag] = useAssignTagMutation();
  const [restoreTag] = useRestoreTagMutation();
  const [deleteTag] = useDeleteTagMutation();

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isTagsError,
  } = useGetTagsQuery(
    {resourceId: assignRequestBody.resourceId},
    {refetchOnMountOrArgChange: true}
  );

  const tagId = head(tags ?? [])?.id;

  const {
    data: selectedTag,
    isLoading: isLoadingSelectedTag,
    isError: isSelectedTagError,
  } = useGetTagQuery({tagValueId: tagValueId!, tagId: tagId!}, {skip: !tagValueId || !tagId});

  const {
    data: entityTags,
    isLoading: isLoadingEntityTags,
    isError: isEntityTagsError,
  } = useGetTagsForEntityQuery(assignRequestBody);

  const isCreating = !tagValueId;

  const getTagValues = pipe(
    find<GetTagResBody>((item) => item.allowedResources.includes(assignRequestBody.resourceId)),
    path<GetTagRes[]>(['values']),
    defaultTo<GetTagRes[]>([])
  );

  const tagsById = indexBy(prop('id'), getTagValues(tags ?? []));
  const alreadyAssignedTagsIds = entityTags?.map(({valueId}) => valueId) ?? [];

  const [creatableValue, setCreatableValue] = useState<string | null>(tagValueId ?? null);
  const [createdTags, setCreatedTags] = useState<OptionType[]>([]);

  const handleRestoreTag = async (expiredTag: GetTagRes, requestBody: EditTagArg) => {
    if (!tagId) {
      showNotification.error();
      return;
    }

    const restoreTagMutation = restoreTag({
      tagId,
      tagValueId: expiredTag.id,
    });
    const updateTagMutation = updateTag({
      ...requestBody,
      tagValueId: expiredTag.id,
      ...assignRequestBody,
    });
    const assignTagMutation = assignTag({tagValueId: expiredTag.id, tagId, ...assignRequestBody});

    await Promise.all([restoreTagMutation, updateTagMutation])
      .then(
        async () =>
          await assignTagMutation.then(() => {
            closeCurrentDialog();
          })
      )
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<GetTagRes> = async (data) => {
    if (!creatableValue || !tagId) {
      showNotification.error();
      return;
    }

    const requestBody: EditTagArg = {
      color: data.color,
      name: tagsById[creatableValue]?.name ?? creatableValue,
      tagValueId: tagsById[creatableValue]?.id ?? tagValueId,
      tagId,
    };

    const error = isNewOptionValid(requestBody.name);
    if (error) {
      showNotification.error(error);
      return;
    }

    const expiredTag = path<GetTagRes[]>([1, 'values'])(tags)?.find(
      (tag) => tag.name === creatableValue
    );

    if (expiredTag) {
      await handleRestoreTag(expiredTag, requestBody);
      return;
    }
    const assignTagValue = async (tagValueId: string) =>
      await assignTag({...assignRequestBody, tagValueId, tagId})
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);

    if (isNilOrEmpty(tagsById[creatableValue])) {
      await createTag(requestBody)
        .unwrap()
        .then((res) => assignTagValue(res.id))
        .catch(handleApiError);
    } else if (!isCreating) {
      await updateTag(requestBody)
        .unwrap()
        .then((res) => assignTagValue(res.id))
        .catch(handleApiError);
    } else {
      await assignTagValue(tagsById[creatableValue].id);
    }
  };

  const handleValueChange = (val: unknown, formApi: UseFormReturn<GetTagRes>) => {
    if (!isString(val)) {
      showNotification.error();
      return;
    }

    setCreatableValue(val);

    if (!!tagsById[val] && isCreating) {
      formApi.setValue('color', tagsById[val].color);
    }
  };

  const handleDelete = () => {
    const onConfirm = async () => {
      const valueId = isCreating ? creatableValue : tagValueId;

      if (!valueId || !tagId) {
        showNotification.error();
        return;
      }

      await deleteTag({tagId, tagValueId: valueId})
        .unwrap()
        .then(() => closeDialog(CREATE_OR_EDIT_TAG_DIALOG_ID))
        .catch(handleApiError);
    };

    openDeleteDialog({
      text: i18n.t('general.actions.removeTag', {
        name: selectedTag?.name ?? tagsById[creatableValue ?? 0]?.name,
      }),
      onConfirm,
    });
  };

  const colorOptions = colorToOption();

  const tagOptions = isCreating
    ? pipe(
        getTagValues,
        defaultTo<GetTagRes[]>([]),
        filter<GetTagRes>(
          (val) =>
            !alreadyAssignedTagsIds.includes(val.id) &&
            (currentDate?.getTime() <= parseDate(val.expiresAt)?.getTime() || !val.expiresAt)
        ),
        map((val) => ({value: val.id, label: val.name})),
        concat(createdTags),
        sort(sortOptionsAlphabetically)
      )(tags ?? [])
    : [{value: selectedTag?.id, label: selectedTag?.name}];

  const isLoading = isLoadingSelectedTag ?? isLoadingEntityTags ?? isLoadingTags;
  const isError = isSelectedTagError ?? isEntityTagsError ?? isTagsError;

  const isTagSelected = isNotNil(creatableValue) && has(creatableValue, tagsById);

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={31}>
      <Form<GetTagRes> onSubmit={handleSubmit} defaultValues={selectedTag} schema={schema}>
        {(control, formApi) => (
          <VStack spacing={4}>
            <HStack spacing={2} align="flex-end">
              <Box flexGrow={1}>
                <Show when={isCreatable}>
                  <CreatableChoice
                    value={creatableValue}
                    onChange={(val) => handleValueChange(val, formApi)}
                    options={tagOptions}
                    placeholder={i18n.t('general.actions.createNewTagPlaceholder')}
                    label={`*${i18n.t('entity.vehicle.labels.vehicleTitle')}`}
                    formatCreateLabel={(value) =>
                      `${i18n.t('general.actions.createNewTag')}  "${value}"`
                    }
                    onCreateOption={(value) => {
                      setCreatedTags((prev) => [...prev, {label: value, value}]);
                      handleValueChange(value, formApi);
                    }}
                    isNotClearable
                  />
                </Show>
                <Hide when={isCreatable}>
                  <Choice
                    value={creatableValue}
                    onChange={(val) => handleValueChange(val, formApi)}
                    options={tagOptions}
                    placeholder={i18n.t('general.actions.createNewTagPlaceholder')}
                    label={`*${i18n.t('entity.vehicle.labels.vehicleTitle')}`}
                    isNotClearable
                  />
                </Hide>
              </Box>
              <Show when={isTagSelected && isDeletable}>
                <IconButton onClick={handleDelete} icon="action/delete" severity="danger" />
              </Show>
            </HStack>
            <FormField
              name="color"
              control={control}
              label={i18n.t('general.labels.tagColor')}
              placeholder={`${i18n.t('general.labels.select')}...`}
              noOptionsMessage={() => i18n.t('general.labels.noOptions')}
              options={colorOptions}
              type="choice"
              isDisabled={
                (tagsById[creatableValue ?? ''] && isCreating) ||
                (isCreatable !== undefined && !isCreatable)
              }
              isNotClearable
              isRequired
              menuInPortal
            />

            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.labels.dismiss')}
                onClick={closeCurrentDialog}
                variant="secondary"
              />
              <FormButton
                title={i18n.t('general.actions.save')}
                control={control}
                type="submit"
                isDisabled={!creatableValue}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

type OptionType = {label: string; value: string};
const sortOptionsAlphabetically = (a: OptionType, b: OptionType) => a.label.localeCompare(b.label);

const colorToOption = () =>
  pipe(
    keys<object>,
    map((scheme) => ({value: scheme, label: i18n.t(`general.labels.color.${scheme}`)})),
    sort(sortOptionsAlphabetically)
  )(FlagColorScheme.primary);

const isNewOptionValid = (val: string | null): string | undefined => {
  if (!val) {
    return i18n.t('general.notifications.defaultErrorMessage');
  }
  if (val.length >= MAX_TAG_LENGTH) {
    return i18n.t('general.errors.string.max', {max: MAX_TAG_LENGTH});
  }
  if (match(WHITESPACE_REGEX, val).length >= 1) {
    return i18n.t('general.errors.string.whiteSpace');
  }

  return undefined;
};

const schema = Yup.object().shape({
  color: Yup.string().required(),
});
