import {ErrorBoundary} from '@sentry/react';
import {showNotification} from 'platform/components';
import {Hide} from 'platform/foundation';

import {Suspense, lazy, useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {Outlet} from 'react-router-dom';

import {browserStorageKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import i18n from '@omnetic-dms/i18n';
import {
  CommonSkeleton,
  getWorkspaceFromUri,
  useGetCurrentUserInfoQuery,
  useListPermissionExpressionQuery,
} from '@omnetic-dms/shared';

import {isTestEnvironment} from '../../utils/isTestEnvironment';
import {ApolloProvider} from '../ApolloProvider/ApolloProvider';
import {ApplicationUpdateBoundary} from './components/ApplicationUpdateBoundary';
import {PrefetchBoundary} from './components/PrefetchBoundary';
import {RotateOverlay} from './components/RotateOverlay';
import {SentryErrorBoundary} from './components/SentryErrorBoundary';
import {gtmInit} from './gtmInit';
import {useOrientation} from './hooks/useOrientation';
import {sentryInit} from './sentryInit';

const Datadog = lazy(() => import('./components/Datadog'));

gtmInit();

// some users still have cached permissions in local storage
// we do not want to cache them saparately anymore
// https://carvago-cn.sentry.io/issues/5473047525/?project=5201681
// TODO (remove): [T20-57881]
if (localStorage.getItem('userPermissions')) {
  localStorage.removeItem('userPermissions');
}

export function Initialize() {
  const isLandscape = useOrientation();

  const {workspace} = getWorkspaceFromUri();

  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const {data: userPermissions} = useListPermissionExpressionQuery();

  const language = userInfo?.settings?.language;

  useEffect(() => {
    const dataLayer = {
      environment: environment.ENV_TYPE,
      workspace: workspace ?? '',
      userEmail: '',
    };

    if (userInfo && userInfo.id && userInfo.email && !isTestEnvironment) {
      dataLayer.userEmail = userInfo.email;

      sentryInit(userInfo);

      TagManager.dataLayer({dataLayer});
    }

    if (!language || language === localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE]) {
      return;
    }
    i18n
      .changeLanguage(language)
      .then(() => localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, language))
      .catch(() => showNotification.error('fatal error - failed to set the application language'));
  }, [workspace, userInfo, language]);

  if (!userInfo || !language || !userPermissions) {
    return <CommonSkeleton />;
  }

  return (
    <SentryErrorBoundary>
      <ErrorBoundary>
        <Suspense fallback={null}>
          <Datadog />
        </Suspense>
      </ErrorBoundary>
      <ApolloProvider>
        <PrefetchBoundary>
          <ApplicationUpdateBoundary>
            <Outlet />
            <Hide when={isLandscape} onDesktop onNotebook>
              <RotateOverlay />
            </Hide>
          </ApplicationUpdateBoundary>
        </PrefetchBoundary>
      </ApolloProvider>
    </SentryErrorBoundary>
  );
}
