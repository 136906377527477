import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {useGetWatermarkQuery} from '@omnetic-dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {WatermarkForm} from './components/WatermarkForm';

export function PhotosWatermarking() {
  const {isLoading} = useGetWatermarkQuery();

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{
        title: i18n.t('page.salesSettings.labels.watermark'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      data-testid="settings-advertising-watermarking"
    >
      <WatermarkForm />
    </SettingsTemplate>
  );
}
