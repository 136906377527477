import {
  PostDiscountGroupRequest,
  PostDiscountGroupRequestSchema,
  PostDiscountGroupResponse,
  PostDiscountGroupResponseSchema,
  GetDiscountGroupRequest,
  GetDiscountGroupRequestSchema,
  GetDiscountGroupResponse,
  GetDiscountGroupResponseSchema,
  GetDiscountGroupsRequest,
  GetDiscountGroupsRequestSchema,
  GetDiscountGroupsResponse,
  GetDiscountGroupsResponseSchema,
  PatchDiscountGroupRequest,
  PatchDiscountGroupRequestSchema,
  PatchDiscountGroupResponse,
  PatchDiscountGroupResponseSchema,
} from '../types/api/metadaWarehouseDiscountGroup';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDiscountGroupApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDiscountGroups: build.query<GetDiscountGroupsResponse, GetDiscountGroupsRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/discount-groups`,
        method: 'GET',
        invalidatesTags: ['discountGroups'],
        params: {warehouseId: queryArg?.warehouseId},
      }),
      extraOptions: {
        requestSchema: GetDiscountGroupsRequestSchema,
        responseSchema: GetDiscountGroupsResponseSchema,
      },
    }),
    getDiscountGroup: build.query<GetDiscountGroupResponse, GetDiscountGroupRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/discount-group/${queryArg.discountGroupId}`,
        method: 'GET',
        invalidatesTags: ['discountGroups'],
      }),
      extraOptions: {
        requestSchema: GetDiscountGroupRequestSchema,
        responseSchema: GetDiscountGroupResponseSchema,
      },
    }),
    postDiscountGroup: build.mutation<PostDiscountGroupResponse, PostDiscountGroupRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/discount-group`,
        method: 'POST',
        body: queryArg,
        invalidatesTags: ['discountGroups'],
      }),
      extraOptions: {
        requestSchema: PostDiscountGroupRequestSchema,
        responseSchema: PostDiscountGroupResponseSchema,
      },
    }),
    patchDiscountGroup: build.mutation<PatchDiscountGroupResponse, PatchDiscountGroupRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/discount-group/${queryArg.discountGroupId}`,
        method: 'PATCH',
        body: queryArg.body,
        invalidatesTags: ['discountGroups'],
      }),
      extraOptions: {
        requestSchema: PatchDiscountGroupRequestSchema,
        responseSchema: PatchDiscountGroupResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDiscountGroupsQuery,
  useGetDiscountGroupQuery,
  usePostDiscountGroupMutation,
  usePatchDiscountGroupMutation,
} = metadaWarehouseDiscountGroupApi;
