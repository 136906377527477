import {match} from 'ts-pattern';

import {always} from 'ramda';

import {useGetTenantQuery} from '../api/tenantApi';
import {DEFAULT_COUNTRY} from '../constants/country';
import {DEFAULT_CURRENCY} from '../constants/currency';
import {CountryAlpha3CodeEnum} from '../types/cached/Countries';

/**
 * A hook that provides the tenant data and utility functions related to the tenant.
 * @returns An object containing the tenant data, tenant country, and a function to check if a given country code matches the tenant's country.
 */
export function useTenant() {
  const {data: tenant} = useGetTenantQuery();
  const tenantCountry = tenant?.country ?? DEFAULT_COUNTRY;
  const tenantCurrency = tenant?.currency ?? DEFAULT_CURRENCY;
  const handleTenantPhoneInfo = (tenantCountry: CountryAlpha3CodeEnum) => {
    if (tenant?.contactInformation?.selfEmployedInformation?.phoneNumber) {
      return tenant.contactInformation.selfEmployedInformation.phoneNumber;
    } else {
      return match(tenantCountry)
        .with('POL', always({number: '', prefix: '+48', countryCode: tenantCountry}))
        .with('SVK', always({number: '', prefix: '+421', countryCode: tenantCountry}))
        .otherwise(always({number: '', prefix: '+420', countryCode: 'CZE'}));
    }
  };

  const tenantPhoneInfo = handleTenantPhoneInfo(tenantCountry);

  const isTenantCountrySupported = (country: CountryAlpha3CodeEnum) => tenantCountry === country;
  const isTenantCountryRestricted = (country: CountryAlpha3CodeEnum[]) =>
    country.includes(tenantCountry);

  return {
    /**
     * The tenant data.
     */
    tenant,
    /**
     * The country code of the tenant.
     */
    tenantCountry,
    /**
     * The currency of the tenant.
     */
    tenantCurrency,
    /**
     * The phone number, phone number prefix and the country code of the tenant.
     */
    tenantPhoneInfo,
    /**
     * A function that checks if a given country code matches the tenant's country.
     * @param country - The country code to check.
     * @returns A boolean indicating whether the given country code matches the tenant's country.
     */
    isTenantCountrySupported,
    /**
     * A function that checks if a given country code does matches the tenant's country.
     * @param country - The country code to check.
     * @returns A boolean indicating whether the given country code does not match the tenant's country.
     */
    isTenantCountryRestricted,
  };
}
