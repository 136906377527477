import styled from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {getRandomId} from 'shared';

import {CustomerAdvancedSearchFieldMatchTypeResponseBody} from '../../../types/api/customer';

interface TagReplacerProps {
  text: string;
  matchType?: CustomerAdvancedSearchFieldMatchTypeResponseBody | null;
  isErrorMatch?: boolean;
}

const TAG_PATTERN = /<em>(.*?)<\/em>/gi;

export const TagReplacer = (props: TagReplacerProps) => {
  const parts = props.text.split(TAG_PATTERN);

  const HighlightComponent = match(props.matchType)
    .with('FULL', always(HighlightSuccess))
    .with('PARTIAL', always(Highlight))
    .otherwise(always(HighlightWarning));

  if (props.isErrorMatch) {
    return <HighlightComponent key={props.text}>{props.text}</HighlightComponent>;
  }

  return (
    <>
      {parts.map((part: string, index: number) => {
        if (index % 2 === 1) {
          return <HighlightComponent key={getRandomId()}>{part}</HighlightComponent>;
        }
        return part;
      })}
    </>
  );
};

// font-weight must be used to provide styling for the Highlighter component

// eslint-disable-next-line eag/no-css-property
const Highlight = styled.span`
  font-weight: ${({theme}) => theme.fontWeights.bold};
`;

// eslint-disable-next-line eag/no-css-property
const HighlightSuccess = styled.span`
  font-weight: ${({theme}) => theme.fontWeights.bold};
  color: ${({theme}) => theme.colors.text.success};
`;

// eslint-disable-next-line eag/no-css-property
const HighlightWarning = styled.span`
  font-weight: ${({theme}) => theme.fontWeights.bold};
  color: ${({theme}) => theme.colors.text.warning};
`;
