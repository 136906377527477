import {Choice, Dialog, MultiChoice, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {useMemo} from 'react';

import {isNil, always} from 'ramda';
import {isArray, isNull, isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import i18n from '../../../i18n/i18n';
import {GetUsersApiResponse} from '../../../types/api/api';
import {AssignmentType} from '../types/AssignmentType';

type UserOptionType = {value: string; label: string};
interface UserDialogProps extends TestIdProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  updateAssignmentType: (newState: AssignmentType | null) => void;
  deleteErroMessage: () => void;
  assignmentType: AssignmentType;
  users: GetUsersApiResponse | undefined;
  isErrorMessage?: boolean;
}

export function EditUserDialog(props: UserDialogProps) {
  const userOptions = useMemo(
    () =>
      props.users?.reduce<UserOptionType[]>(
        (result, user) =>
          user.blocked
            ? result
            : result.concat({value: user.id, label: `${user.firstName} ${user.lastName}`}),
        []
      ),
    [props.users]
  );

  const handleSelect = (value: string | number | string[] | null) => {
    if (isArray(value) || isString(value) || isNull(value)) {
      props.deleteErroMessage();
      props.updateAssignmentType({...props.assignmentType, value});
    } else {
      showNotification.error();
    }
  };

  const isAssigneesDialog = props.assignmentType?.type === 'assignees';
  const dialogTitle =
    props.assignmentType?.type === 'assignees'
      ? i18n.t('general.labels.addPeople')
      : i18n.t('general.labels.owner');

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="small"
      scrollBehavior="outside"
      title={dialogTitle}
      buttons={[
        {variant: 'secondary', onClick: props.onClose, title: i18n.t('general.actions.discard')},
        {variant: 'primary', onClick: props.onSave, title: i18n.t('general.actions.save')},
      ]}
      data-testid={suffixTestId('dialog', props)}
    >
      {isAssigneesDialog ? (
        <MultiChoice
          value={match([isArray(props.assignmentType.value), isNil(props.assignmentType.value)])
            .with([true, false], always(props.assignmentType.value as string[]))
            .with([false, false], always([props.assignmentType.value as string]))
            .otherwise(always([]))}
          options={userOptions ?? []}
          onChange={handleSelect}
          closeMenuOnSelect={!isAssigneesDialog}
          hasOptionCheckbox={isAssigneesDialog}
          placeholder={i18n.t(`general.labels.participation.type.${props.assignmentType?.type}`)}
          data-testid={suffixTestId('assignee', props)}
        />
      ) : (
        <Choice
          errorMessage={props.isErrorMessage ? i18n.t('general.labels.required') : undefined}
          value={
            isArray(props.assignmentType.value)
              ? props.assignmentType.value[0]
              : props.assignmentType.value
          }
          options={userOptions ?? []}
          onChange={handleSelect}
          closeMenuOnSelect={!isAssigneesDialog}
          hasOptionCheckbox={isAssigneesDialog}
          placeholder={i18n.t(`general.labels.participation.type.${props.assignmentType?.type}`)}
          data-testid={suffixTestId('assignee', props)}
        />
      )}
    </Dialog>
  );
}
