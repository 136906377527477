import {
  GetReceiveNoteCorrectionBasketRequest,
  GetReceiveNoteCorrectionBasketRequestSchema,
  GetReceiveNoteCorrectionBasketResponse,
  GetReceiveNoteCorrectionBasketResponseSchema,
  PatchReceiveNoteCorrectionBasketItemQuantityRequest,
  PatchReceiveNoteCorrectionBasketItemQuantityRequestSchema,
  PatchReceiveNoteCorrectionBasketItemQuantityResponse,
  PatchReceiveNoteCorrectionBasketItemQuantityResponseSchema,
  PostReceiveNoteCorrectionBasketCheckoutRequest,
  PostReceiveNoteCorrectionBasketCheckoutRequestSchema,
  PostReceiveNoteCorrectionBasketCheckoutResponse,
  PostReceiveNoteCorrectionBasketCheckoutResponseSchema,
} from '../types/api/metadaWarehouseReceiveNoteCorrectionBasket';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseReceiveNoteCorrectionBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getReceiveNoteCorrectionBasket: build.query<
      GetReceiveNoteCorrectionBasketResponse,
      GetReceiveNoteCorrectionBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/basket`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrectionBasket', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: GetReceiveNoteCorrectionBasketRequestSchema,
        responseSchema: GetReceiveNoteCorrectionBasketResponseSchema,
      },
    }),
    postReceiveNoteCorrectionBasketCheckout: build.mutation<
      PostReceiveNoteCorrectionBasketCheckoutResponse,
      PostReceiveNoteCorrectionBasketCheckoutRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/basket/checkout`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrectionBasket', id: queryArg.creditNoteId},
        {type: 'receiveNoteCorrections', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: PostReceiveNoteCorrectionBasketCheckoutRequestSchema,
        responseSchema: PostReceiveNoteCorrectionBasketCheckoutResponseSchema,
      },
    }),
    patchReceiveNoteCorrectionBasketItemQuantity: build.mutation<
      PatchReceiveNoteCorrectionBasketItemQuantityResponse,
      PatchReceiveNoteCorrectionBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/basket-item/${queryArg.creditNoteItemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrectionBasket', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteCorrectionBasketItemQuantityRequestSchema,
        responseSchema: PatchReceiveNoteCorrectionBasketItemQuantityResponseSchema,
      },
    }),
  }),
});

export const {
  useGetReceiveNoteCorrectionBasketQuery,
  usePostReceiveNoteCorrectionBasketCheckoutMutation,
  usePatchReceiveNoteCorrectionBasketItemQuantityMutation,
} = metadaWarehouseReceiveNoteCorrectionBasketApi;
