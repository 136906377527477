import {ActionCallback, DataGrid} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {head} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {warehouseRoutes} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';

export function IssueNotes() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, sourceSearchParams}) => {
    const issueNoteId = isArray(rowId) ? head(rowId) : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(
          composePath(warehouseRoutes.issueNoteDetailOverview, {
            queryParams: sourceSearchParams,
            params: {id: issueNoteId},
            isSearchParamsPreserved: false,
          })
        )
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode="issue-notes-service-order-issue-note"
        actionCallback={actionCallback}
        data-testid="issueNotesServiceOrderIssueNoteDatagrid"
      />
    </FullscreenDataGridWrapper>
  );
}
