import {openDialog, closeCurrentDialog} from 'platform/components';
import {Link} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {testIds} from '@omnetic-dms/routes';

import {suffixTestId, TestIdProps} from 'shared';

import {useGetOccupiedSpaceQuery} from '../../../api/storageApi';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import i18n from '../../../i18n/i18n';
import {StorageSectorsDialog} from '../../StorageSectorsDialog/StorageSectorsDialog';

export interface ParkingPlaceProps extends TestIdProps {
  recordId: string;
}

export function ParkingPlace(props: ParkingPlaceProps) {
  const {data: parkingPlace} = useGetOccupiedSpaceQuery(
    {recordId: props.recordId ?? '', spaceType: 'VEHICLE'},
    {skip: isNilOrEmpty(props.recordId)}
  );

  const [canChangeParkingPlace] = usePermissions({
    permissionKeys: ['vehicleChangeParkingPlace'],
  });

  const onParkingPlaceClick = () => {
    openDialog(
      <StorageSectorsDialog
        spaceType="VEHICLE"
        recordId={props.recordId}
        onClose={closeCurrentDialog}
        selectedSpace={
          isNotNil(parkingPlace?.number) && parkingPlace?.sectorId
            ? {number: parkingPlace.number, sectorId: parkingPlace.sectorId}
            : undefined
        }
        data-testid={testIds.vehicles.detail('parkingPlace')}
      />,
      {
        title: i18n.t('entity.vehicle.labels.assignParkingPlace'),
        size: 'large',
        scrollBehavior: 'inside',
        'data-testid': suffixTestId('assignParkingPlace', props),
      }
    );
  };

  const parkingPlaceName =
    `${parkingPlace?.prefix ?? ''}${parkingPlace?.number ?? ''}` || undefined;

  return (
    <Link
      size="small"
      isDisabled={!canChangeParkingPlace}
      leftIcon="maps/local_parking"
      title={parkingPlaceName ?? i18n.t('entity.vehicle.labels.assignedParkingPlace')}
      onClick={onParkingPlaceClick}
      data-testid={suffixTestId('parking', props)}
    />
  );
}
