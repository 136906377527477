import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  AccountingDocumentRecalculateApiArg,
  AccountingDocumentRecalculateApiRes,
  CancelInvoiceApiArg,
  CancelInvoiceApiResponse,
  CancelInvoiceProformaApiArg,
  CancelInvoiceProformaApiResponse,
  ChangeInvoiceCustomerApiArg,
  CreateCheckoutCorrectiveTaxDocumentApiArg,
  CreateCheckoutCorrectiveTaxDocumentApiRes,
  CreateCorrectiveTaxDocumentApiArg,
  CreateCorrectiveTaxDocumentApiResponse,
  CreateDiscountItemApiArg,
  CreateDiscountItemApiResponse,
  CreateDocumentSeriesDefinitionApiArg,
  CreateDocumentSeriesDefinitionApiResponse,
  CreateInvoiceApiArg,
  CreateInvoiceApiResponse,
  CreateInvoiceDraftApiArg,
  CreateInvoiceDraftApiResponse,
  CreateInvoiceProformaApiArg,
  CreateInvoiceProformaApiResponse,
  CreateManuallyTaxDocumentForPaymentApiArg,
  CreateManuallyTaxDocumentForPaymentApiResponse,
  CreateMarginItemApiArg,
  CreateMarginItemApiResponse,
  CreateStandardItemApiArg,
  CreateStandardItemApiResponse,
  CreateStandardMultipleItemsApiArg,
  CreateStandardMultipleItemsApiResponse,
  CreateTaxDocumentSettingApiArg,
  CreateTaxDocumentSettingApiResponse,
  CreateVatTotalAndCalculationSettingApiArg,
  CreateVatTotalAndCalculationSettingApiResponse,
  DeleteDocumentSeriesDefinitionApiArg,
  DeleteDocumentSeriesDefinitionApiResponse,
  DeleteInvoiceDraftApiArg,
  DeleteInvoiceDraftApiResponse,
  DeleteTaxDocumentSettingApiArg,
  DeleteTaxDocumentSettingApiResponse,
  DeleteVatTotalAndCalculationSettingApiArg,
  DeleteVatTotalAndCalculationSettingApiResponse,
  GetBalanceInvoiceApiArg,
  GetBalanceInvoiceApiResponse,
  GetBalanceInvoiceByInvoiceProformaIdApiArg,
  GetBalanceInvoiceByInvoiceProformaIdApiResponse,
  GetCalculateByPercentageApiArg,
  GetCalculateByPercentageApiRes,
  GetCalculatePercentageApiArg,
  GetCalculatePercentageApiRes,
  GetCorrectiveTaxDocumentApiArg,
  GetCorrectiveTaxDocumentApiResponse,
  GetCorrectiveTaxDocumentItemsApiArg,
  GetCorrectiveTaxDocumentItemsApiResponse,
  GetCorrectiveTaxDocumentPaymentListApiArg,
  GetCorrectiveTaxDocumentPaymentListApiResponse,
  GetDocumentSeriesDefinitionListApiResponse,
  GetInvoiceApiArg,
  GetInvoiceApiResponse,
  GetInvoiceDraftApiArg,
  GetInvoiceDraftApiResponse,
  GetInvoiceDraftItemsApiArg,
  GetInvoiceDraftItemsApiResponse,
  GetInvoiceItemsApiArg,
  GetInvoiceItemsApiResponse,
  GetInvoicePaymentListApiArg,
  GetInvoicePaymentListApiResponse,
  GetInvoiceProformaApiArg,
  GetInvoiceProformaApiResponse,
  GetInvoiceProformaItemsApiArg,
  GetInvoiceProformaItemsApiResponse,
  GetInvoiceProformaPaymentListApiArg,
  GetInvoiceProformaPaymentListApiResponse,
  GetListVatTotalAndCalculationSettingApiArg,
  GetListVatTotalAndCalculationSettingApiResponse,
  GetPaymentTypeEnumApiResponse,
  GetPaymentTypesForInvoicePaymentApiArg,
  GetPaymentTypesForInvoicePaymentApiResponse,
  GetTaxDocumentForPaymentApiResponse,
  GetTaxDocumentSettingApiArg,
  GetTaxDocumentSettingApiResponse,
  GetTaxDocumentSettingListApiArg,
  GetTaxDocumentSettingListApiResponse,
  GetVatTotalAndCalculationSettingApiArg,
  GetVatTotalAndCalculationSettingApiResponse,
  PayWithBankTransferApiArg,
  PayWithBankTransferApiResponse,
  PayWithBankTransferArg,
  PayWithBankTransferRes,
  PayWithCardAndCreateReceiptApiArg,
  PayWithCardAndCreateReceiptApiResponse,
  PayWithCardApiArg,
  PayWithCardApiResponse,
  PayWithCardArg,
  PayWithCardReceiptArg,
  PayWithCardReceiptRes,
  PayWithCardRes,
  PayWithCashAndCreateReceiptApiArg,
  PayWithCashAndCreateReceiptApiResponse,
  PayWithCashApiArg,
  PayWithCashApiResponse,
  PayWithCashArg,
  PayWithCashReceiptArg,
  PayWithCashReceiptRes,
  PayWithCashRes,
  PostInvoiceDraftApiArg,
  PostInvoiceDraftApiResponse,
  PutInvoiceDraftApiArg,
  PutInvoiceDraftApiResponse,
  RenderBalanceInvoiceDocumentApiArg,
  RenderBalanceInvoiceDocumentApiResponse,
  RenderCorrectiveTaxDocumentApiArg,
  RenderCorrectiveTaxDocumentApiResponse,
  RenderExpenseCashReceiptDocumentApiArg,
  RenderExpenseCashReceiptDocumentApiResponse,
  RenderIncomeCashReceiptDocumentApiArg,
  RenderIncomeCashReceiptDocumentApiResponse,
  RenderInvoiceDocumentApiArg,
  RenderInvoiceDocumentApiResponse,
  RenderInvoiceProformaDocumentApiArg,
  RenderInvoiceProformaDocumentApiResponse,
  RenderTaxDocumentForPaymentDocumentApiArg,
  RenderTaxDocumentForPaymentDocumentApiResponse,
  SendCorrectiveTaxDocumentApiArg,
  SendCorrectiveTaxDocumentApiResponse,
  SendInvoiceApiArg,
  SendInvoiceApiResponse,
  TaxDocumentCalculationApiArg,
  UpdateDocumentSeriesDefinitionApiArg,
  UpdateDocumentSeriesDefinitionApiResponse,
  UpdateInvoiceDraftApiArg,
  UpdateInvoiceDraftApiResponse,
  UpdateTaxDocumentSettingApiArg,
  UpdateTaxDocumentSettingApiResponse,
  UpdateVatTotalAndCalculationSettingApiArg,
  UpdateVatTotalAndCalculationSettingApiResponse,
} from '../types/api/accounting';
import {
  CancelCashRegisterDocumentApiArg,
  CancelCorrectiveTaxDocumentApiArg,
  CancelCorrectiveTaxDocumentApiResponse,
  CreateCashRegisterApiArg,
  CreateCashRegisterApiResponse,
  CreateCashRegisterDocumentApiArg,
  CreateCashRegisterDocumentApiResponse,
  CreateDocumentLimitDefinitionApiArg,
  CreateDocumentLimitDefinitionApiResponse,
  CreateInvoiceMarginApiArg,
  CreateInvoiceMarginApiRes,
  CreateRoundingDefinitionApiArg,
  CreateRoundingDefinitionApiResponse,
  CreateVatCalculationDefinitionApiArg,
  CreateVatCalculationDefinitionApiResponse,
  CreateVatCheckTotalsDefinitionApiArg,
  CreateVatCheckTotalsDefinitionApiResponse,
  DeleteInvoiceMarginApiArg,
  DeleteInvoiceMarginApiRes,
  DeleteNegativeDocumentCancellationApiArg,
  DeleteNegativeDocumentCancellationApiRes,
  DeleteProformaGenerationApiArg,
  DeleteProformaGenerationApiRes,
  DeleteRoundingDefinitionApiArg,
  DeleteRoundingDefinitionApiResponse,
  DeleteVatCalculationDefinitionApiArg,
  DeleteVatCalculationDefinitionApiResponse,
  DeleteVatCheckTotalsDefinitionApiArg,
  DeleteVatCheckTotalsDefinitionApiResponse,
  EditInvoiceMarginApiArg,
  EditInvoiceMarginApiRes,
  FindRoundingDefinitionValidityApiArg,
  FindRoundingDefinitionValidityApiResponse,
  GetCashRegisterApiArg,
  GetCashRegisterApiResponse,
  GetCashRegisterDocumentApiArg,
  GetCashRegisterDocumentApiResponse,
  GetCashRegisterDocumentKindOfPaymentListApiArg,
  GetCashRegisterDocumentKindOfPaymentListApiResponse,
  GetCashRegisterDocumentStateListApiArg,
  GetCashRegisterDocumentStateListApiResponse,
  GetCashRegisterDocumentTypeListApiArg,
  GetCashRegisterDocumentTypeListApiResponse,
  GetCashRegisterListApiArg,
  GetCashRegisterListApiResponse,
  GetDocumentLimitDefinitionApiArg,
  GetDocumentLimitDefinitionApiResponse,
  GetDocumentLimitDefinitionListApiArg,
  GetDocumentLimitDefinitionListApiResponse,
  GetDocumentRestrictionApiArg,
  GetDocumentRestrictionApiResponse,
  GetInvoiceMarginApiArg,
  GetInvoiceMarginApiRes,
  GetInvoiceMarginsApiRes,
  GetNegativeDocumentCancellationApiArg,
  GetNegativeDocumentCancellationApiRes,
  GetProformaGenerationApiArg,
  GetProformaGenerationApiRes,
  GetProformaGenerationListApiArg,
  GetProformaGenerationListApiRes,
  GetRoundStepApiArg,
  GetRoundStepApiResponse,
  GetRoundingDefinitionApiArg,
  GetRoundingDefinitionApiResponse,
  GetRoundingDefinitionListApiArg,
  GetRoundingDefinitionListApiResponse,
  GetTaxDocumentForPaymentApiArg,
  GetVatCalculationDefinitionApiArg,
  GetVatCalculationDefinitionApiResponse,
  GetVatCalculationDefinitionListApiResponse,
  GetVatCheckTotalsDefinitionApiArg,
  GetVatCheckTotalsDefinitionApiResponse,
  GetVatCheckTotalsDefinitionListApiArg,
  GetVatCheckTotalsDefinitionListApiResponse,
  InactivateCashRegisterApiArg,
  InactivateCashRegisterApiResponse,
  PatchCashRegisterApiArg,
  PatchCashRegisterApiResponse,
  PostNegativeDocumentCancellationApiArg,
  PostNegativeDocumentCancellationApiRes,
  PostProformaGenerationApiArg,
  PostProformaGenerationApiRes,
  PutProformaGenerationApiArg,
  PutProformaGenerationApiRes,
  RemoveDocumentLimitDefinitionApiArg,
  RemoveDocumentLimitDefinitionApiResponse,
  TaxDocumentCalculationApiResponse,
  TaxDocumentCalculationV2ApiResponse,
  UpdateDocumentLimitDefinitionApiArg,
  UpdateDocumentLimitDefinitionApiResponse,
  UpdateNegativeDocumentCancellationApiArg,
  UpdateNegativeDocumentCancellationApiRes,
  UpdateRoundingDefinitionApiArg,
  UpdateRoundingDefinitionApiResponse,
  UpdateVatCalculationDefinitionApiArg,
  UpdateVatCalculationDefinitionApiResponse,
  UpdateVatCheckTotalsDefinitionApiArg,
  UpdateVatCheckTotalsDefinitionApiResponse,
} from '../types/api/api';
import {omneticApi} from './baseApi/omneticApi';

export const accountingApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getCashRegisterDocumentKindOfPaymentList: build.query<
      GetCashRegisterDocumentKindOfPaymentListApiResponse,
      GetCashRegisterDocumentKindOfPaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document/kind-of-payment`,
      }),
    }),
    getCashRegisterDocumentStateList: build.query<
      GetCashRegisterDocumentStateListApiResponse,
      GetCashRegisterDocumentStateListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document/state`,
      }),
    }),
    getCashRegisterDocumentTypeList: build.query<
      GetCashRegisterDocumentTypeListApiResponse,
      GetCashRegisterDocumentTypeListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document/type`,
      }),
    }),
    createCashRegister: build.mutation<CreateCashRegisterApiResponse, CreateCashRegisterApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register`,
        method: 'POST',
        body: queryArg.createCashRegisterRequestBody,
      }),
      invalidatesTags: ['cashRegisters'],
    }),
    getCashRegisterList: build.query<GetCashRegisterListApiResponse, GetCashRegisterListApiArg>({
      query: (params) => ({
        url: `/dms/v1/cash-register`,
        params,
      }),
      providesTags: ['CurrentUser'],
    }),
    getTenantRegisterList: build.query<GetCashRegisterListApiResponse, GetCashRegisterListApiArg>({
      query: (params) => ({
        url: `/dms/v1/cash-register`,
        omitHeaders: ['X-Branch'],
        params,
      }),
      providesTags: ['CurrentUser', 'cashRegisters'],
    }),
    getCashRegister: build.query<GetCashRegisterApiResponse, GetCashRegisterApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register/${queryArg.cashRegisterId}`,
        headers: {'X-Branch': queryArg['X-Branch']},
      }),
      keepUnusedDataFor: 0,
    }),
    patchCashRegister: build.mutation<PatchCashRegisterApiResponse, PatchCashRegisterApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register/${queryArg.cashRegisterId}`,
        method: 'PATCH',
        body: queryArg.patchCashRegisterRequestBody,
      }),
      invalidatesTags: ['cashRegisters'],
    }),
    inactivateCashRegister: build.mutation<
      InactivateCashRegisterApiResponse,
      InactivateCashRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register/${queryArg.cashRegisterId}/inactivate`,
        method: 'POST',
      }),
      invalidatesTags: ['cashRegisters'],
    }),
    cancelCashRegisterDocument: build.mutation<null, CancelCashRegisterDocumentApiArg>({
      invalidatesTags: (result, error, queryArg) => [
        {type: 'CashRegisterDocument', id: queryArg.cashRegisterDocumentId},
        'cashRegisters',
        'correctiveTaxDocument',
      ],
      queryFn: async (queryArg, queryApi, options, fetch) => {
        const successfulResponse = {data: null};

        const canceledDocument = await fetch({
          url: `/dms/v2/cash-receipt/${queryArg.cashRegisterDocumentId}/cancel`,
          method: 'POST',
          body: queryArg.cancelCashRegisterDocumentRequestBody,
        });

        // if query is successful, return data
        if (isNil(canceledDocument.error)) {
          return successfulResponse;
        }

        // is true when there is BC payment linked to canceled register document
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isBusinessCaseIdError = (canceledDocument?.error as any)?.data?.errors?.some(
          (item: {data?: {businessCaseId?: string}}) => isNotNil(item?.data?.businessCaseId)
        );

        if (isBusinessCaseIdError) {
          // withdraw linked payment
          const withdrawalPayment = await fetch({
            url: `/dms/v1/checkout/cash-register-document/${queryArg.cashRegisterDocumentId}/withdraw-payment`,
            method: 'PUT',
          });

          // check for errors
          if (withdrawalPayment.error) {
            throw withdrawalPayment.error;
          }

          return successfulResponse;
        } else {
          throw canceledDocument.error;
        }
      },
    }),
    createCashRegisterDocument: build.mutation<
      CreateCashRegisterDocumentApiResponse,
      CreateCashRegisterDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/cash-receipt`,
        method: 'POST',
        body: queryArg.createCashRegisterDocumentRequestBody,
      }),
    }),
    getCashRegisterDocument: build.query<
      GetCashRegisterDocumentApiResponse,
      GetCashRegisterDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/cash-receipt/${queryArg.cashRegisterDocumentId}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, queryArg) => [
        {type: 'CashRegisterDocument', id: queryArg.cashRegisterDocumentId},
      ],
    }),
    createDocumentLimitDefinition: build.mutation<
      CreateDocumentLimitDefinitionApiResponse,
      CreateDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition`,
        method: 'POST',
        body: queryArg.createDocumentLimitDefinitionRequestBody,
      }),
      invalidatesTags: ['cashRegisterLimitDefinition'],
    }),
    getDocumentLimitDefinitionList: build.query<
      GetDocumentLimitDefinitionListApiResponse,
      GetDocumentLimitDefinitionListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition`,
      }),
      providesTags: ['cashRegisterLimitDefinition'],
    }),
    getDocumentLimitDefinition: build.query<
      GetDocumentLimitDefinitionApiResponse,
      GetDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition/${queryArg.id}`,
      }),
    }),
    removeDocumentLimitDefinition: build.mutation<
      RemoveDocumentLimitDefinitionApiResponse,
      RemoveDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['cashRegisterLimitDefinition'],
    }),
    updateDocumentLimitDefinition: build.mutation<
      UpdateDocumentLimitDefinitionApiResponse,
      UpdateDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateDocumentLimitDefinitionRequestBody,
      }),
      invalidatesTags: ['cashRegisterLimitDefinition'],
    }),
    getDocumentRestriction: build.query<
      GetDocumentRestrictionApiResponse,
      GetDocumentRestrictionApiArg
    >({
      query: (params) => ({
        url: `/dms/v1/document-restriction`,
        params,
      }),
    }),
    cancelCorrectiveTaxDocument: build.mutation<
      CancelCorrectiveTaxDocumentApiResponse,
      CancelCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/corrective-tax-document/cancel/${queryArg.correctiveTaxDocumentId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
      ],
    }),
    createCheckoutCorrectiveTaxDocument: build.mutation<
      CreateCheckoutCorrectiveTaxDocumentApiRes,
      CreateCheckoutCorrectiveTaxDocumentApiArg
    >({
      query: ({checkoutId, orderId, paymentId, type, requestBody}) => ({
        url: `/dms/v2/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/${type}-corrective-tax-document`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'invoicePayment',
        'Checkout',
        'documentsCount',
        'invoiceProformaPaymentList',
        'paymentPrescription',
        'SaleVehicle',
        'BusinessCaseDetail',
      ],
    }),
    getCorrectiveTaxDocumentItems: build.query<
      GetCorrectiveTaxDocumentItemsApiResponse,
      GetCorrectiveTaxDocumentItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/corrective-tax-document/${queryArg.correctiveTaxDocumentId}/item`,
      }),
    }),
    sendCorrectiveTaxDocument: build.mutation<
      SendCorrectiveTaxDocumentApiResponse,
      SendCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/corrective-tax-document/send/${queryArg.correctiveTaxDocumentId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
      ],
    }),
    renderBalanceInvoiceDocument: build.mutation<
      RenderBalanceInvoiceDocumentApiResponse,
      RenderBalanceInvoiceDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/balance-invoice/render`,
        method: 'PUT',
        body: queryArg.renderBalanceInvoiceDocumentRequestBody,
      }),
    }),
    renderCorrectiveTaxDocument: build.mutation<
      RenderCorrectiveTaxDocumentApiResponse,
      RenderCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/corrective-tax-document/render`,
        method: 'PUT',
        body: queryArg.renderCorrectiveTaxDocumentRequestBody,
      }),
    }),
    renderExpenseCashReceiptDocument: build.mutation<
      RenderExpenseCashReceiptDocumentApiResponse,
      RenderExpenseCashReceiptDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/document/expense-cash-receipt/render`,
        method: 'PUT',
        body: queryArg.renderExpenseCashReceiptDocumentRequestBody,
      }),
    }),
    renderIncomeCashReceiptDocument: build.mutation<
      RenderIncomeCashReceiptDocumentApiResponse,
      RenderIncomeCashReceiptDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/document/income-cash-receipt/render`,
        method: 'PUT',
        body: queryArg.renderIncomeCashReceiptDocumentRequestBody,
      }),
    }),
    renderInvoiceDocument: build.mutation<
      RenderInvoiceDocumentApiResponse,
      RenderInvoiceDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/invoice/render`,
        method: 'PUT',
        body: queryArg.renderInvoiceDocumentRequestBody,
      }),
    }),
    renderInvoiceProformaDocument: build.mutation<
      RenderInvoiceProformaDocumentApiResponse,
      RenderInvoiceProformaDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/invoice-proforma/render`,
        method: 'PUT',
        body: queryArg.renderInvoiceProformaDocumentRequestBody,
      }),
    }),
    renderTaxDocumentForPaymentDocument: build.mutation<
      RenderTaxDocumentForPaymentDocumentApiResponse,
      RenderTaxDocumentForPaymentDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/tax-document-for-payment/render`,
        method: 'PUT',
        body: queryArg.renderTaxDocumentForPaymentDocumentRequestBody,
      }),
    }),
    createManuallyTaxDocumentForPayment: build.mutation<
      CreateManuallyTaxDocumentForPaymentApiResponse,
      CreateManuallyTaxDocumentForPaymentApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/tax-document-for-payment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['invoiceProformaPaymentList'],
    }),
    cancelInvoice: build.mutation<CancelInvoiceApiResponse, CancelInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/cancel/${queryArg.invoiceId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    createDocumentSeriesDefinition: build.mutation<
      CreateDocumentSeriesDefinitionApiResponse,
      CreateDocumentSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/document-series-definition`,
        method: 'POST',
        body: queryArg.documentSeriesDefinitionRequestBody,
      }),
      invalidatesTags: ['DocumentSeriesDefinition'],
    }),
    getDocumentSeriesDefinitionList: build.query<GetDocumentSeriesDefinitionListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/invoice/document-series-definition`,
      }),
      providesTags: ['DocumentSeriesDefinition'],
    }),
    deleteDocumentSeriesDefinition: build.mutation<
      DeleteDocumentSeriesDefinitionApiResponse,
      DeleteDocumentSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/document-series-definition/${queryArg.documentSeriesDefinitionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DocumentSeriesDefinition'],
    }),
    updateDocumentSeriesDefinition: build.mutation<
      UpdateDocumentSeriesDefinitionApiResponse,
      UpdateDocumentSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/document-series-definition/${queryArg.documentSeriesDefinitionId}`,
        method: 'PUT',
        body: queryArg.documentSeriesDefinitionRequestBody,
      }),
      invalidatesTags: ['DocumentSeriesDefinition'],
    }),
    replaceInvoiceCustomer: build.mutation<void, ChangeInvoiceCustomerApiArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/${invoiceId}/customer`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    getInvoiceItems: build.query<GetInvoiceItemsApiResponse, GetInvoiceItemsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/${queryArg.invoiceId}/item`,
      }),
    }),
    getInvoicePaymentList: build.query<
      GetInvoicePaymentListApiResponse,
      GetInvoicePaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/payment/payment/${queryArg.invoiceId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
      ],
    }),
    getCorrectiveTaxDocumentPaymentList: build.query<
      GetCorrectiveTaxDocumentPaymentListApiResponse,
      GetCorrectiveTaxDocumentPaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/corrective-tax-document/payment/payment/${queryArg.correctiveTaxDocumentId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    sendInvoice: build.mutation<SendInvoiceApiResponse, SendInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/send/${queryArg.invoiceId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    deleteInvoiceDraft: build.mutation<DeleteInvoiceDraftApiResponse, DeleteInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-draft/${queryArg.invoiceDraftId}`,
        method: 'DELETE',
      }),
    }),
    putInvoiceDraft: build.mutation<PutInvoiceDraftApiResponse, PutInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-draft/${queryArg.invoiceDraftId}`,
        method: 'PUT',
        body: queryArg.invoiceDraftRequestBody,
      }),
    }),
    getInvoiceDraftItems: build.query<GetInvoiceDraftItemsApiResponse, GetInvoiceDraftItemsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-draft/${queryArg.invoiceDraftId}/item`,
      }),
      keepUnusedDataFor: 0,
    }),
    postInvoiceDraft: build.mutation<PostInvoiceDraftApiResponse, PostInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-draft`,
        method: 'POST',
        body: queryArg.invoiceDraftRequestBody,
      }),
    }),
    cancelInvoiceProforma: build.mutation<
      CancelInvoiceProformaApiResponse,
      CancelInvoiceProformaApiArg
    >({
      query: ({invoiceProformaId}) => ({
        url: `/dms/v1/invoice-proforma/${invoiceProformaId}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoiceProforma', invoiceId: queryArg.invoiceProformaId},
      ],
    }),

    getInvoiceProformaItems: build.query<
      GetInvoiceProformaItemsApiResponse,
      GetInvoiceProformaItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-proforma/${queryArg.invoiceId}/item`,
      }),
    }),
    getInvoiceProformaPaymentList: build.query<
      GetInvoiceProformaPaymentListApiResponse,
      GetInvoiceProformaPaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-proforma/payment/payment/${queryArg.invoiceId}`,
      }),
      providesTags: ['invoiceProformaPaymentList'],
    }),
    getPaymentTypesForInvoicePayment: build.query<
      GetPaymentTypesForInvoicePaymentApiResponse,
      GetPaymentTypesForInvoicePaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/payment-type/invoice`,
      }),
    }),
    createRoundingDefinition: build.mutation<
      CreateRoundingDefinitionApiResponse,
      CreateRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition`,
        method: 'POST',
        body: queryArg.createRoundingDefinitionRequestBody,
      }),
    }),
    getRoundingDefinitionList: build.query<
      GetRoundingDefinitionListApiResponse,
      GetRoundingDefinitionListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition`,
      }),
    }),
    deleteRoundingDefinition: build.mutation<
      DeleteRoundingDefinitionApiResponse,
      DeleteRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getRoundingDefinition: build.query<
      GetRoundingDefinitionApiResponse,
      GetRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/${queryArg.id}`,
      }),
    }),
    updateRoundingDefinition: build.mutation<
      UpdateRoundingDefinitionApiResponse,
      UpdateRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateRoundingDefinitionRequestBody,
      }),
    }),
    findRoundingDefinitionValidity: build.mutation<
      FindRoundingDefinitionValidityApiResponse,
      FindRoundingDefinitionValidityApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition-validity`,
        method: 'POST',
        body: queryArg.findRoundingDefinitionRequestBody,
      }),
    }),
    payInvoiceWithBankTransfer: build.mutation<PayWithBankTransferRes, PayWithBankTransferArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-bank-transfer/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCard: build.mutation<PayWithCardRes, PayWithCardArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-card/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCardReceipt: build.mutation<PayWithCardReceiptRes, PayWithCardReceiptArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-card-receipt/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCash: build.mutation<PayWithCashRes, PayWithCashArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-cash/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCashReceipt: build.mutation<PayWithCashReceiptRes, PayWithCashReceiptArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-cash-receipt/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),

    getPaymentTypeEnum: build.query<GetPaymentTypeEnumApiResponse, void>({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/payment-type`,
      }),
    }),
    getRoundStep: build.query<GetRoundStepApiResponse, GetRoundStepApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/round-step`,
      }),
    }),
    invoicingDocumentRecalculate: build.mutation<
      AccountingDocumentRecalculateApiRes,
      AccountingDocumentRecalculateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-items/recalculate`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    taxDocumentCalculation: build.mutation<
      TaxDocumentCalculationApiResponse,
      TaxDocumentCalculationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tax-document-calculation`,
        method: 'POST',
        body: queryArg.taxDocumentCalculationRequestBody,
      }),
    }),
    taxDocumentCalculationV2: build.mutation<
      TaxDocumentCalculationV2ApiResponse,
      TaxDocumentCalculationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/tax-document-calculation`,
        method: 'POST',
        body: queryArg.taxDocumentCalculationRequestBody,
      }),
    }),
    getVatCheckTotalsDefinitionList: build.query<
      GetVatCheckTotalsDefinitionListApiResponse,
      GetVatCheckTotalsDefinitionListApiArg
    >({
      query: () => ({
        url: `/dms/v1/vat-check-totals-definition`,
      }),
      providesTags: ['VatCheckTotalsDefinition'],
    }),
    getVatCheckTotalsDefinition: build.query<
      GetVatCheckTotalsDefinitionApiResponse,
      GetVatCheckTotalsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-check-totals-definition/${queryArg}`,
      }),
      providesTags: ['VatCheckTotalsDefinition'],
    }),
    createVatCheckTotalsDefinition: build.mutation<
      CreateVatCheckTotalsDefinitionApiResponse,
      CreateVatCheckTotalsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-check-totals-definition`,
        method: 'POST',
        body: queryArg.vatCheckTotalsDefinitionRequestBody,
      }),
      invalidatesTags: ['VatCheckTotalsDefinition'],
    }),
    updateVatCheckTotalsDefinition: build.mutation<
      UpdateVatCheckTotalsDefinitionApiResponse,
      UpdateVatCheckTotalsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-check-totals-definition/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.vatCheckTotalsDefinitionRequestBody,
      }),
      invalidatesTags: ['VatCheckTotalsDefinition'],
    }),
    deleteVatCheckTotalsDefinition: build.mutation<
      DeleteVatCheckTotalsDefinitionApiResponse,
      DeleteVatCheckTotalsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-check-totals-definition/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VatCheckTotalsDefinition'],
    }),
    getVatCalculationDefinitionList: build.query<GetVatCalculationDefinitionListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/vat-calculation-definition`,
      }),
    }),
    getVatCalculationDefinition: build.query<
      GetVatCalculationDefinitionApiResponse,
      GetVatCalculationDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-calculation-definition/${queryArg}`,
      }),
    }),
    createVatCalculationDefinition: build.mutation<
      CreateVatCalculationDefinitionApiResponse,
      CreateVatCalculationDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-calculation-definition`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateVatCalculationDefinition: build.mutation<
      UpdateVatCalculationDefinitionApiResponse,
      UpdateVatCalculationDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-calculation-definition/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateVatCalculationDefinitionRequestBody,
      }),
    }),
    deleteVatCalculationDefinition: build.mutation<
      DeleteVatCalculationDefinitionApiResponse,
      DeleteVatCalculationDefinitionApiArg
    >({
      query: (id) => ({
        url: `/dms/v1/vat-calculation-definition/${id}`,
        method: 'DELETE',
      }),
    }),
    postNegativeDocumentCancellation: build.mutation<
      PostNegativeDocumentCancellationApiRes,
      PostNegativeDocumentCancellationApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition`,
        method: 'POST',
        body,
      }),
    }),
    updateNegativeDocumentCancellation: build.mutation<
      UpdateNegativeDocumentCancellationApiRes,
      UpdateNegativeDocumentCancellationApiArg
    >({
      query: ({id, ...body}) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'cancellationDefinition', definitionId: queryArg.id},
      ],
    }),
    getNegativeDocumentCancellation: build.query<
      GetNegativeDocumentCancellationApiRes,
      GetNegativeDocumentCancellationApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition/${id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'cancellationDefinition', definitionId: queryArg.id},
      ],
    }),
    deleteNegativeDocumentCancellation: build.mutation<
      DeleteNegativeDocumentCancellationApiRes,
      DeleteNegativeDocumentCancellationApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition/${id}`,
        method: 'DELETE',
      }),
    }),
    createInvoiceMargin: build.mutation<CreateInvoiceMarginApiRes, CreateInvoiceMarginApiArg>({
      query: (body) => ({
        url: '/dms/v1/accounting/margin-invoice-setting',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['marginDefinitions'],
    }),
    updateInvoiceMargin: build.mutation<EditInvoiceMarginApiRes, EditInvoiceMarginApiArg>({
      query: ({id, ...body}) => ({
        url: `/dms/v1/accounting/margin-invoice-setting/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['marginDefinitions'],
    }),
    deleteInvoiceMargin: build.mutation<DeleteInvoiceMarginApiRes, DeleteInvoiceMarginApiArg>({
      query: ({id}) => ({
        url: `/dms/v1/accounting/margin-invoice-setting/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['marginDefinitions'],
    }),
    getInvoiceMargin: build.query<GetInvoiceMarginApiRes, GetInvoiceMarginApiArg>({
      query: ({id}) => ({
        url: `/dms/v1/accounting/margin-invoice-setting/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getInvoiceMargins: build.query<GetInvoiceMarginsApiRes, void>({
      query: () => ({
        url: '/dms/v1/accounting/margin-invoice-settings',
      }),
      providesTags: ['marginDefinitions'],
    }),
    postProformaGeneration: build.mutation<
      PostProformaGenerationApiRes,
      PostProformaGenerationApiArg
    >({
      query: (body) => ({
        url: '/dms/v1/accounting/business-case/proforma-invoice/setting',
        method: 'POST',
        body,
      }),
    }),
    getProformaGenerationList: build.query<
      GetProformaGenerationListApiRes,
      GetProformaGenerationListApiArg
    >({
      query: () => ({
        url: '/dms/v1/accounting/business-case/proforma-invoice/settings',
      }),
    }),
    getProformaGeneration: build.query<GetProformaGenerationApiRes, GetProformaGenerationApiArg>({
      query: ({id}) => ({
        url: `/dms/v1/accounting/business-case/proforma-invoice/setting/${id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'proformaGeneration', definitionId: queryArg.id},
      ],
    }),
    putProformaGeneration: build.mutation<PutProformaGenerationApiRes, PutProformaGenerationApiArg>(
      {
        query: ({id, ...body}) => ({
          url: `/dms/v1/accounting/business-case/proforma-invoice/setting/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, error, queryArg) => [
          {type: 'proformaGeneration', definitionId: queryArg.id},
        ],
      }
    ),
    deleteProformaGeneration: build.mutation<
      DeleteProformaGenerationApiRes,
      DeleteProformaGenerationApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v1/accounting/business-case/proforma-invoice/setting/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'proformaGeneration', definitionId: queryArg.id},
      ],
    }),
    getBalanceInvoiceByInvoiceProformaId: build.query<
      GetBalanceInvoiceByInvoiceProformaIdApiResponse,
      GetBalanceInvoiceByInvoiceProformaIdApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/balance-invoice/invoice-proforma/${queryArg.invoiceProformaId}`,
      }),
    }),
    getBalanceInvoice: build.query<GetBalanceInvoiceApiResponse, GetBalanceInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/balance-invoice/${queryArg.balanceInvoiceId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.balanceInvoiceId},
      ],
    }),
    createCorrectiveTaxDocument: build.mutation<
      CreateCorrectiveTaxDocumentApiResponse,
      CreateCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v3/corrective-tax-document`,
        method: 'POST',
        body: queryArg.correctiveTaxDocumentV2RequestBody,
      }),
    }),
    getCorrectiveTaxDocument: build.query<
      GetCorrectiveTaxDocumentApiResponse,
      GetCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v3/corrective-tax-document/${queryArg.correctiveTaxDocumentId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
      ],
    }),
    createInvoice: build.mutation<CreateInvoiceApiResponse, CreateInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v3/invoice`,
        method: 'POST',
        body: queryArg.invoiceV2RequestBody,
      }),
    }),
    getInvoice: build.query<GetInvoiceApiResponse, GetInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v3/invoice/${queryArg.invoiceId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'invoice', invoiceId: queryArg.invoiceId}],
      keepUnusedDataFor: 0,
    }),
    createInvoiceDraft: build.mutation<CreateInvoiceDraftApiResponse, CreateInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-draft`,
        method: 'POST',
        body: queryArg.invoiceDraftV2RequestBody,
      }),
    }),
    getInvoiceDraft: build.query<GetInvoiceDraftApiResponse, GetInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-draft/${queryArg.invoiceDraftId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    updateInvoiceDraft: build.mutation<UpdateInvoiceDraftApiResponse, UpdateInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-draft/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.invoiceDraftV2RequestBody,
      }),
    }),
    createInvoiceProforma: build.mutation<
      CreateInvoiceProformaApiResponse,
      CreateInvoiceProformaApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-proforma`,
        method: 'POST',
        body: queryArg.invoiceProformaV2RequestBody,
      }),
    }),
    getInvoiceProforma: build.query<GetInvoiceProformaApiResponse, GetInvoiceProformaApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-proforma/${queryArg.invoiceProformaId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoiceProforma', invoiceId: queryArg.invoiceProformaId},
      ],
    }),
    createDiscountItem: build.mutation<CreateDiscountItemApiResponse, CreateDiscountItemApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-discount`,
        method: 'POST',
        body: queryArg.createInvoicingItemDiscountRequestBody,
      }),
    }),
    createMarginItem: build.mutation<CreateMarginItemApiResponse, CreateMarginItemApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-margin`,
        method: 'POST',
        body: queryArg.createInvoicingItemMarginRequestBody,
      }),
    }),
    createStandardItem: build.mutation<CreateStandardItemApiResponse, CreateStandardItemApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-standard`,
        method: 'POST',
        body: queryArg.createInvoicingItemStandardRequestBody,
      }),
    }),
    createStandardMultipleItems: build.mutation<
      CreateStandardMultipleItemsApiResponse,
      CreateStandardMultipleItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-standard-multiple`,
        method: 'POST',
        body: queryArg.createInvoicingItemsStandardRequestBodies,
      }),
    }),
    getTaxDocumentForPayment: build.query<
      GetTaxDocumentForPaymentApiResponse,
      GetTaxDocumentForPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/tax-document-for-payment/${queryArg.id}`,
      }),
    }),
    calculatePercentage: build.query<GetCalculatePercentageApiRes, GetCalculatePercentageApiArg>({
      query: (params) => ({
        url: `/dms/v1/discount/calculate/percentage`,
        params,
      }),
    }),
    calculateByPercentage: build.query<
      GetCalculateByPercentageApiRes,
      GetCalculateByPercentageApiArg
    >({
      query: (params) => ({
        url: `/dms/v1/discount/calculate/by-percentage`,
        params,
      }),
    }),
    payCorrectiveTaxDocumentWithBankTransfer: build.mutation<
      PayWithBankTransferApiResponse,
      PayWithBankTransferApiArg
    >({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-bank-transfer/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCardAndCreateReceipt: build.mutation<
      PayWithCardAndCreateReceiptApiResponse,
      PayWithCardAndCreateReceiptApiArg
    >({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-card-receipt/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCard: build.mutation<PayWithCardApiResponse, PayWithCardApiArg>({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-card/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCashAndCreateReceipt: build.mutation<
      PayWithCashAndCreateReceiptApiResponse,
      PayWithCashAndCreateReceiptApiArg
    >({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-cash-receipt/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCash: build.mutation<PayWithCashApiResponse, PayWithCashApiArg>({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-cash/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    createTaxDocumentSetting: build.mutation<
      CreateTaxDocumentSettingApiResponse,
      CreateTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting`,
        method: 'POST',
        body: queryArg.taxDocumentSettingRequestBody,
      }),
      invalidatesTags: ['TaxDocumentSettingList'],
    }),
    deleteTaxDocumentSetting: build.mutation<
      DeleteTaxDocumentSettingApiResponse,
      DeleteTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TaxDocumentSettingList'],
    }),
    getTaxDocumentSetting: build.query<
      GetTaxDocumentSettingApiResponse,
      GetTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting/${queryArg.id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getTaxDocumentSettingList: build.query<
      GetTaxDocumentSettingListApiResponse,
      GetTaxDocumentSettingListApiArg
    >({
      query: () => ({
        url: `/dms/v1/accounting/tax-document-setting/list`,
      }),
      providesTags: ['TaxDocumentSettingList'],
    }),
    updateTaxDocumentSetting: build.mutation<
      UpdateTaxDocumentSettingApiResponse,
      UpdateTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateTaxDocumentSettingRequestBody,
      }),
      invalidatesTags: ['TaxDocumentSettingList'],
    }),
    createVatTotalAndCalculationSetting: build.mutation<
      CreateVatTotalAndCalculationSettingApiResponse,
      CreateVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteVatTotalAndCalculationSetting: build.mutation<
      DeleteVatTotalAndCalculationSettingApiResponse,
      DeleteVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation/${queryArg.vatTotalAndCalculationId}`,
        method: 'DELETE',
      }),
    }),
    getVatTotalAndCalculationSetting: build.query<
      GetVatTotalAndCalculationSettingApiResponse,
      GetVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation/${queryArg.vatTotalAndCalculationId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    updateVatTotalAndCalculationSetting: build.mutation<
      UpdateVatTotalAndCalculationSettingApiResponse,
      UpdateVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation/${queryArg.vatTotalAndCalculationId}`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
    getListVatTotalAndCalculationSetting: build.query<
      GetListVatTotalAndCalculationSettingApiResponse,
      GetListVatTotalAndCalculationSettingApiArg
    >({
      query: () => ({
        url: `/dms/v1/vat-total-and-calculation/list`,
      }),
    }),
  }),
});

export const {
  useGetListVatTotalAndCalculationSettingQuery,
  useUpdateVatTotalAndCalculationSettingMutation,
  useGetVatTotalAndCalculationSettingQuery,
  useDeleteVatTotalAndCalculationSettingMutation,
  useCreateVatTotalAndCalculationSettingMutation,
  useGetProformaGenerationListQuery,
  useCreateTaxDocumentSettingMutation,
  useDeleteTaxDocumentSettingMutation,
  useGetTaxDocumentSettingQuery,
  useGetTaxDocumentSettingListQuery,
  useCreateManuallyTaxDocumentForPaymentMutation,
  useUpdateTaxDocumentSettingMutation,
  useGetCorrectiveTaxDocumentPaymentListQuery,
  usePayCorrectiveTaxDocumentWithBankTransferMutation,
  usePayCorrectiveTaxDocumentWithCardAndCreateReceiptMutation,
  usePayCorrectiveTaxDocumentWithCardMutation,
  usePayCorrectiveTaxDocumentWithCashAndCreateReceiptMutation,
  usePayCorrectiveTaxDocumentWithCashMutation,
  useGetTenantRegisterListQuery,
  useGetBalanceInvoiceQuery,
  useCreateInvoiceMutation,
  useCreateInvoiceDraftMutation,
  useUpdateInvoiceDraftMutation,
  useLazyCalculateByPercentageQuery,
  useLazyCalculatePercentageQuery,
  useDeleteProformaGenerationMutation,
  usePostProformaGenerationMutation,
  usePutProformaGenerationMutation,
  useGetProformaGenerationQuery,
  useGetInvoiceMarginQuery,
  useGetInvoiceMarginsQuery,
  useDeleteInvoiceMarginMutation,
  useUpdateInvoiceMarginMutation,
  useCreateInvoiceMarginMutation,
  useGetVatCalculationDefinitionListQuery,
  useGetVatCalculationDefinitionQuery,
  useCreateVatCalculationDefinitionMutation,
  useUpdateVatCalculationDefinitionMutation,
  useDeleteVatCalculationDefinitionMutation,
  useGetCashRegisterDocumentKindOfPaymentListQuery,
  useGetCashRegisterDocumentStateListQuery,
  useGetCashRegisterDocumentTypeListQuery,
  useCreateCashRegisterMutation,
  useGetCashRegisterListQuery,
  useGetNegativeDocumentCancellationQuery,
  useGetCashRegisterQuery,
  useDeleteNegativeDocumentCancellationMutation,
  usePatchCashRegisterMutation,
  useInactivateCashRegisterMutation,
  useCancelCashRegisterDocumentMutation,
  usePostNegativeDocumentCancellationMutation,
  useUpdateNegativeDocumentCancellationMutation,
  useCreateCashRegisterDocumentMutation,
  useGetCashRegisterDocumentQuery,
  useCreateDocumentLimitDefinitionMutation,
  useGetDocumentLimitDefinitionListQuery,
  useGetDocumentLimitDefinitionQuery,
  useRemoveDocumentLimitDefinitionMutation,
  useUpdateDocumentLimitDefinitionMutation,
  useGetDocumentRestrictionQuery,
  useCancelCorrectiveTaxDocumentMutation,
  useCreateCorrectiveTaxDocumentMutation,
  useGetCorrectiveTaxDocumentQuery,
  useGetCorrectiveTaxDocumentItemsQuery,
  useSendCorrectiveTaxDocumentMutation,
  useRenderBalanceInvoiceDocumentMutation,
  useRenderCorrectiveTaxDocumentMutation,
  useRenderExpenseCashReceiptDocumentMutation,
  useRenderIncomeCashReceiptDocumentMutation,
  useRenderInvoiceDocumentMutation,
  useRenderInvoiceProformaDocumentMutation,
  useRenderTaxDocumentForPaymentDocumentMutation,
  useCancelInvoiceMutation,
  useCreateDocumentSeriesDefinitionMutation,
  useGetDocumentSeriesDefinitionListQuery,
  useDeleteDocumentSeriesDefinitionMutation,
  useUpdateDocumentSeriesDefinitionMutation,
  useGetInvoiceQuery,
  useLazyGetInvoiceProformaPaymentListQuery,
  useLazyGetInvoiceQuery,
  useLazyGetInvoiceItemsQuery,
  useGetInvoiceItemsQuery,
  useGetInvoicePaymentListQuery,
  useSendInvoiceMutation,
  useDeleteInvoiceDraftMutation,
  useGetInvoiceDraftQuery,
  usePutInvoiceDraftMutation,
  useGetInvoiceDraftItemsQuery,
  usePostInvoiceDraftMutation,
  useCreateInvoiceProformaMutation,
  useGetInvoiceProformaQuery,
  useGetInvoiceProformaItemsQuery,
  useGetInvoiceProformaPaymentListQuery,
  useCreateCheckoutCorrectiveTaxDocumentMutation,
  useGetPaymentTypesForInvoicePaymentQuery,
  useCreateRoundingDefinitionMutation,
  useGetRoundingDefinitionListQuery,
  useDeleteRoundingDefinitionMutation,
  useTaxDocumentCalculationV2Mutation,
  useTaxDocumentCalculationMutation,
  useInvoicingDocumentRecalculateMutation,
  useGetRoundingDefinitionQuery,
  useUpdateRoundingDefinitionMutation,
  useFindRoundingDefinitionValidityMutation,
  useReplaceInvoiceCustomerMutation,
  useGetPaymentTypeEnumQuery,
  useGetRoundStepQuery,
  useGetTaxDocumentForPaymentQuery,
  useGetVatCheckTotalsDefinitionListQuery,
  useGetVatCheckTotalsDefinitionQuery,
  useCreateVatCheckTotalsDefinitionMutation,
  useCancelInvoiceProformaMutation,
  useUpdateVatCheckTotalsDefinitionMutation,
  useDeleteVatCheckTotalsDefinitionMutation,
  usePayInvoiceWithBankTransferMutation,
  usePayInvoiceWithCardMutation,
  usePayInvoiceWithCardReceiptMutation,
  usePayInvoiceWithCashMutation,
  usePayInvoiceWithCashReceiptMutation,
} = accountingApi;
