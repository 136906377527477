import {
  Button,
  ButtonGroup,
  DataStatus,
  closeCurrentDialog,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {Box, Right, Space, Stack, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {customerRoutes} from '@omnetic-dms/routes';
import {
  CustomerInformationCard,
  CustomerContactPersonCard,
  Section,
  PatchDirectSaleRequest,
  CustomerMatchOrCreate,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId} from 'shared';

interface CustomerProps extends RequiredTestIdProps {
  customerId: string;
  isDirectSaleLoading: boolean;
  isEditingDisabled: boolean;
  onCustomerChange: (customerId: PatchDirectSaleRequest['body']['customerId']) => Promise<void>;
}

export function Customer(props: CustomerProps) {
  const handleSelectCustomer = (customerId: string) => {
    props.onCustomerChange(customerId);
    closeCurrentDialog();
  };

  const handleAssignCustomer = () => {
    openDialog(
      <CustomerMatchOrCreate
        onCustomer={(customer) => handleSelectCustomer(customer.id)}
        secondStepComponentType="BUSINESS_CASE"
        data-testid={suffixTestId('dialogContent.assignCustomer', props)}
      />,
      {
        title: i18n.t('entity.customer.labels.customer'),
        'data-testid': suffixTestId('dialog.assignCustomer', props),
      }
    );
  };

  const handleRemoveCustomer = () => {
    openDeleteDialog({
      text: i18n.t('entity.warehouse.actions.deleteEntity'),
      onConfirm: () => props.onCustomerChange(null),
      'data-testid': suffixTestId('deleteDialog.remove', props),
    });
  };

  const handleViewCustomerCard = () => {
    window.open(composePath(customerRoutes.detail, {params: {id: props.customerId}}), '_blank');
  };

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus
        isLoading={props.isDirectSaleLoading}
        isEmpty={isNilOrEmpty(props.customerId)}
        minHeight={200}
        emptyMessage={i18n.t('entity.warehouse.labels.noAssignedCustomer')}
        action={{
          title: i18n.t('general.actions.assignCustomer'),
          onClick: handleAssignCustomer,
          isDisabled: props.isEditingDisabled,
        }}
        data-testid={suffixTestId('assignedCustomer', props)}
      >
        <Right>
          <ButtonGroup data-testid={suffixTestId('actions', props)}>
            <Button
              variant="dangerOutlined"
              title={i18n.t('general.actions.remove')}
              onClick={handleRemoveCustomer}
              isDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('actions.remove', props)}
            />
            <Button
              variant="outlined"
              title={i18n.t('general.actions.change')}
              onClick={handleAssignCustomer}
              isDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('actions.change', props)}
            />
            <Button
              variant="outlined"
              leftIcon="action/launch"
              title={i18n.t('entity.warehouse.actions.viewCustomerCard')}
              onClick={handleViewCustomerCard}
              data-testid={suffixTestId('actions.viewCustomerCard', props)}
            />
          </ButtonGroup>
        </Right>
        <Space vertical={4} />
        <Stack
          direction={['column', 'column', 'row', 'row']}
          spacing={4}
          data-testid={suffixTestId('customerDetails', props)}
        >
          <Box flex={1}>
            <VStack spacing={4}>
              <CustomerInformationCard
                customerId={props.customerId}
                isEditingDisabled={props.isEditingDisabled}
                data-testid={suffixTestId('customerInfo', props)}
              />
            </VStack>
          </Box>
          <Box flex={1}>
            <VStack spacing={4}>
              <CustomerContactPersonCard
                customerId={props.customerId}
                isEditingDisabled={props.isEditingDisabled}
              />
            </VStack>
          </Box>
        </Stack>
      </DataStatus>
    </Section>
  );
}
