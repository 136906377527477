import {useCallback} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {replace} from 'ramda';

import {debounce} from 'shared';

import {useParsePersonalIdMutation} from '../api/customerApi';
import {COUNTRY_CZE} from '../constants/country';
import {CustomerRequestBody, ParsePersonalIdApiArg} from '../types/api/customer';
import {handleApiError} from '../utils/handleApiError';
import {useTenant} from './useTenant';

const DEBOUNCE_TIMEOUT = 700;

export const useParsePersonalId = () => {
  const {tenantCountry, isTenantCountrySupported} = useTenant();

  const [parsePersonalId] = useParsePersonalIdMutation();

  const parsePersonalIdData = useCallback(
    (formApi: UseFormReturn<CustomerRequestBody>) => {
      const personalId = formApi.getValues('foundingPerson.personData.personalIdentifier');
      let country = formApi.getValues('foundingPerson.personData.citizenshipCode');

      if (isTenantCountrySupported(COUNTRY_CZE)) {
        country = tenantCountry;
      }

      if (!personalId || !country) {
        return;
      }

      const args: ParsePersonalIdApiArg = {
        parsePersonalIdRequestBody: {
          personalId: replace('/', '')(personalId ?? ''),
          country,
        },
      };

      parsePersonalId(args)
        .unwrap()
        .then((personalIdInfo) => {
          formApi.setValue('foundingPerson.personData.birthdate', personalIdInfo.birthdate);
          formApi.setValue('foundingPerson.personData.genderKey', personalIdInfo.genderKey);
        })
        .catch((error) => handleApiError(error, {silent: true}));
    },
    [isTenantCountrySupported, parsePersonalId, tenantCountry]
  );

  return debounce(parsePersonalIdData, DEBOUNCE_TIMEOUT);
};
