import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {BaseSupplier, getOptionsFromSuppliers} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {SupplierArticlesAddNewForm} from '../types/SupplierArticlesAddNewForm';

interface SupplierArticlesAddNewSupplierInfoFormProps extends RequiredTestIdProps {
  control: FormControl<SupplierArticlesAddNewForm>;
  suppliers: BaseSupplier[] | Nullish;
}

export function SupplierArticlesAddNewSupplierInfoForm(
  props: SupplierArticlesAddNewSupplierInfoFormProps
) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.supplierInfo')}</Heading>
      <Grid columns={4} spacing={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="supplierInfo.supplierId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.supplier')}
            options={getOptionsFromSuppliers(props.suppliers)}
            isRequired
            data-testid={suffixTestId('inputs.supplierId', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="supplierInfo.supplierOrderingNumber"
            type="text"
            label={i18n.t('entity.warehouse.labels.supplierOrderingNumber')}
            data-testid={suffixTestId('inputs.supplierOrderingNumber', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="supplierInfo.supplierBulkPackageQuantity"
            type="text"
            label={i18n.t('entity.warehouse.labels.supplierBulkPackageQuantity')}
            isRequired
            data-testid={suffixTestId('inputs.supplierBulkPackageQuantity', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
