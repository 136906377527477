/* eslint-disable no-console */
import {Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';
import {v4 as uuid} from 'uuid';

import {useEffect, useState} from 'react';

import {isEmpty} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {ZOD_PARSING_ERROR_PREFIX} from 'shared';

import {getFixedTranslation} from '../utils/getFixedTranslation';

const nativeConsoleWarn = console.warn;

interface ApiValidationProps {
  isValidating: boolean;
  startParsingValidation: () => void;
  stopParsingValidation: () => void;
}

type MessageType = {
  method: string;
  endpointName: string;
  schemaType: string;
  message: string;
  id: string;
};

export function ApiValidation(props: ApiValidationProps) {
  const [parsingErrors, setParsingErrors] = useState<MessageType[]>([]);

  const handleStartApiValidation = () => {
    props.startParsingValidation();
    console.warn = (...messages) => {
      console.log(messages);
      if (isArray(messages) && messages[0] === ZOD_PARSING_ERROR_PREFIX) {
        const [prefix, method, endpointName, schemaType, message] = messages;

        if (
          parsingErrors.some(
            (error) => error.endpointName === endpointName && error.message === message
          )
        ) {
          return;
        }

        setParsingErrors((prev) => [
          ...prev,
          {
            method,
            endpointName,
            schemaType,
            message,
            id: uuid(),
          },
        ]);
      } else {
        nativeConsoleWarn(messages);
      }
    };
  };

  const cleanUp = () => {
    console.warn = nativeConsoleWarn;
    setParsingErrors([]);
  };

  const handleStopApiValidation = () => {
    props.stopParsingValidation();
    console.log(parsingErrors);
  };

  useEffect(
    () => () => {
      cleanUp();
    },
    []
  );

  return (
    <Card
      control={{
        type: 'switch',
        onChange: (val) => {
          const action = val ? handleStartApiValidation : handleStopApiValidation;

          action();
        },
        value: props.isValidating,
      }}
      title={getFixedTranslation('page.settings.labels.apiValidation')}
      isExpandable
      isClosedByDefault
      variant="inlineWhite"
      flags={[
        props.isValidating
          ? {label: getFixedTranslation('general.labels.on'), colorScheme: 'orange', isSubtle: true}
          : {
              label: getFixedTranslation('general.labels.off'),
              colorScheme: 'neutral',
              isSubtle: true,
            },
      ]}
    >
      <DataStatus isEmpty={isEmpty(parsingErrors)} spacing={4}>
        <VStack spacing={2}>
          {parsingErrors.map((error) => (
            <Card
              key={error.id}
              isExpandable
              isClosedByDefault
              title={error.endpointName}
              variant="inlineGrey"
            >
              <pre>
                <strong>schemaType:</strong> {error.schemaType} <br />
                <strong>method:</strong> {error.method || 'undefined'} <br />
                <strong>message:</strong> {error.message} <br />
              </pre>
            </Card>
          ))}
        </VStack>
      </DataStatus>
    </Card>
  );
}
