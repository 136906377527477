import {Card, useDialog} from 'platform/components';
import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';

import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  documentContextApi,
  Section,
  UploadDocumentsDialog,
  useCustomerUrl,
  useGetDocumentDatagridActions,
} from '@omnetic-dms/shared';

export function Documents() {
  const {customerId} = useCustomerUrl();
  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();
  const dispatch = useDispatch();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {customerId}]),
    [customerId]
  );

  const [actionCallback] = useGetDocumentDatagridActions(customerId);

  const onDocumentCreated = () => {
    refreshDataGrid();

    dispatch(
      documentContextApi.util.invalidateTags([{type: 'documentsCount', id: customerId ?? ''}])
    );
  };

  return (
    <Section>
      <Card
        title={i18n.t('page.customer.documents.title')}
        actions={[
          {
            variant: 'link',
            type: 'button',
            leftIcon: 'action/backup',
            onClick: openUploadDialog,
            title: i18n.t('entity.document.actions.uploadDocument'),
          },
        ]}
      >
        <DataGrid
          ref={dataGridRef}
          data-testid="customer-documents"
          gridCode="document-context-list-customer"
          emptyState={{
            headline: i18n.t('page.customer.notifications.noDocuments'),
            subheadline: i18n.t('page.customer.notifications.documentListWillAppearHere'),
          }}
          actionCallback={actionCallback}
          autoHeight
          queryModifier={queryModifier}
        />
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={onDocumentCreated}
        contextTarget="customer"
        contextId={customerId}
      />
    </Section>
  );
}
