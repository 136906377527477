import {
  getColorFromSeverity,
  Severity,
  ThemeIconKey,
  Center,
  Heading,
  HStack,
  Icon,
  Show,
  Space,
  Text,
  VStack,
} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {Button, ButtonProps} from '../Button/Button';

interface StatusProps extends TestIdProps {
  headline: string;
  icon?: ThemeIconKey;
  subheadline?: string;
  severity?: Severity;
  action?: Pick<ButtonProps, 'onClick' | 'title' | 'isDisabled' | 'leftIcon' | 'variant'>;
  isCompact?: boolean;
}

export function Status(props: StatusProps) {
  const StackComponent = props.isCompact ? VStack : HStack;

  const isWithButton = isNotNil(props.action?.variant) && props.action?.variant !== 'link';

  const iconColor = props.severity
    ? getColorFromSeverity(props.severity)
    : 'palettes.neutral.100.100';

  return (
    <Center width="100%">
      <Center
        data-testid={suffixTestId('status', props)}
        maxWidth={props.isCompact ? 100 : undefined}
      >
        <VStack align="center">
          <Show when={props.icon}>
            <Icon size={8} value={props.icon} color={iconColor} />
            <Space vertical={3} />
          </Show>
          <Heading size={5} color="tertiary" data-testid={suffixTestId(`status-headline`, props)}>
            {props.headline}
          </Heading>

          <Show when={props.subheadline || props.action}>
            <Space vertical={1} />
            <StackComponent align="center" spacing={props.isCompact || isWithButton ? 4 : 1}>
              <Show when={props.subheadline}>
                <Text
                  size="xSmall"
                  color="tertiary"
                  data-testid={suffixTestId(`status-subheadline`, props)}
                  align="center"
                >
                  {props.subheadline}
                </Text>
              </Show>
              <Show when={props.action}>
                <Button
                  size={isWithButton ? 'default' : 'small'}
                  variant={props.action?.variant ?? 'link'}
                  data-testid={suffixTestId('status-action-button', props)}
                  {...props.action}
                />
              </Show>
            </StackComponent>
          </Show>
        </VStack>
      </Center>
    </Center>
  );
}
