import {Card, Separator, DataStatus} from 'platform/components';
import {GridItem, Grid, Space} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  numberToTimeString,
  PriceSummaryItem,
  SummaryItem,
  useGetOrderProfitabilityQuery,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

interface ProfitabilitySummaryProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string;
}

export function ProfitabilitySummary(props: ProfitabilitySummaryProps) {
  const formatPercentage = useFormatPercentage();

  const {data, isLoading, isError} = useGetOrderProfitabilityQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.orderId,
  });

  const discountPercentage = getDiscountPercentage(
    data?.basePrice?.withVat?.amount,
    data?.discount?.withVat?.amount
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={46}>
      <Card variant="inlineGrey">
        <Grid columns={6} spacing={4}>
          <GridItem>
            <PriceSummaryItem
              title={i18n.t('entity.order.labels.originalPrice')}
              price={data?.basePrice?.withVat?.amount}
              priceWithoutVat={data?.basePrice?.withoutVat?.amount}
              currency={data?.basePrice?.withVat?.currency}
              data-testid={suffixTestId('originalPrice', props)}
            />
          </GridItem>
          <GridItem>
            <PriceSummaryItem
              title={i18n.t('general.labels.discount')}
              price={data?.discount?.withVat?.amount}
              priceWithoutVat={data?.discount?.withoutVat?.amount}
              currency={data?.discount?.withVat?.currency}
              flag={
                isNotNil(discountPercentage)
                  ? {
                      label: formatPercentage(discountPercentage, 'nearest'),
                    }
                  : undefined
              }
              data-testid={suffixTestId('discount', props)}
            />
          </GridItem>
          <GridItem>
            <PriceSummaryItem
              title={i18n.t('entity.order.labels.totalPrice')}
              price={data?.totalPrice?.withVat?.amount}
              priceWithoutVat={data?.totalPrice?.withoutVat?.amount}
              currency={data?.totalPrice?.withVat?.currency}
              data-testid={suffixTestId('totalPrice', props)}
            />
          </GridItem>
          <GridItem>
            <PriceSummaryItem
              title={i18n.t('entity.order.labels.deposit')}
              price={data?.deposit?.withVat?.amount}
              priceWithoutVat={data?.deposit?.withoutVat?.amount}
              currency={data?.deposit?.withVat?.currency}
              data-testid={suffixTestId('deposit', props)}
            />
          </GridItem>
          <Space fillAvailable />
          <GridItem>
            <PriceSummaryItem
              title={i18n.t('entity.order.labels.forPayment')}
              price={data?.restForPayment?.withVat?.amount}
              priceWithoutVat={data?.restForPayment?.withoutVat?.amount}
              currency={data?.restForPayment?.withVat?.currency}
              data-testid={suffixTestId('deposit', props)}
            />
          </GridItem>
        </Grid>
        <Separator />
        <Grid columns={6} spacing={4}>
          <GridItem>
            <SummaryItem
              title={i18n.t('entity.order.labels.estiomatedTime')}
              value={numberToTimeString(data?.timePlanned?.amount ?? 0)}
              data-testid={suffixTestId('estimatedTime', props)}
            />
          </GridItem>
          <GridItem>
            <SummaryItem
              title={i18n.t('entity.order.labels.planningEfficency')}
              value={formatPercentage(data?.timePlanningEfficiency ?? 0)}
              data-testid={suffixTestId('planningEfficency', props)}
            />
          </GridItem>
          <GridItem>
            <SummaryItem
              title={i18n.t('entity.order.labels.timeTracked')}
              value={numberToTimeString(data?.timeTracked?.amount ?? 0)}
              data-testid={suffixTestId('timeTracked', props)}
            />
          </GridItem>
          <GridItem>
            <SummaryItem
              title={i18n.t('entity.order.labels.performance')}
              value={formatPercentage(data?.timePerformance ?? 0)}
              color={(data?.timePerformance ?? 0) >= 100 ? 'success' : 'danger'}
              data-testid={suffixTestId('performance', props)}
            />
          </GridItem>
          <Space fillAvailable />
          <GridItem>
            <SummaryItem
              title={i18n.t('entity.order.labels.timeSold')}
              value={numberToTimeString(data?.timeSold?.amount ?? 0)}
              data-testid={suffixTestId('timeSold', props)}
            />
          </GridItem>
        </Grid>
      </Card>
    </DataStatus>
  );
}

const getDiscountPercentage = (
  originalPrice: number | Nullish,
  discountPrice: number | Nullish
) => {
  if (isNil(originalPrice) || isNil(discountPrice) || discountPrice === 0) {
    return;
  }

  return discountPrice / originalPrice;
};
