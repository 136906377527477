import {ButtonProps} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {
  FullScreenModal,
  handleApiError,
  useDeleteBasketLabourItemsApiMutation,
  usePostBasketLabourItemCheckoutApiMutation,
  WorkItemsListCard,
} from '@omnetic-dms/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {WorkBasketCard} from './WorkBasketCard';

interface AddWorkItemsDialogProps extends TestIdProps {
  packageId: string;
  onClose: () => void;
  onAfterSubmit: () => void;
}

export function AddWorkItemsDialog(props: AddWorkItemsDialogProps) {
  const [postBasketLabourItemCheckout, {isLoading: isPostBasketLabourItemCheckoutLoading}] =
    usePostBasketLabourItemCheckoutApiMutation();
  const [deleteBasketLabourItems, {isLoading: isDeleteBasketLabourItemsLoading}] =
    useDeleteBasketLabourItemsApiMutation();

  const handleAddMaterial = () =>
    postBasketLabourItemCheckout({packageId: props.packageId})
      .unwrap()
      .then(props.onAfterSubmit)
      .then(props.onClose)
      .catch(handleApiError);

  const handleCancel = () =>
    deleteBasketLabourItems({packageId: props.packageId})
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);

  const actions = buildArray<ButtonProps>()
    .add({
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleCancel,
      isLoading: isDeleteBasketLabourItemsLoading,
      'data-testid': suffixTestId('cancel', props),
    })
    .add({
      title: i18n.t('general.actions.add'),
      onClick: handleAddMaterial,
      isLoading: isPostBasketLabourItemCheckoutLoading,
      'data-testid': suffixTestId('add', props),
    });

  return (
    <FullScreenModal
      headline={i18n.t('entity.workshopServicePackages.labels.addWork')}
      actions={actions}
    >
      <Box padding={4} height="100%">
        <VStack spacing={4} height="100%">
          <Box maxHeight="100%" flex={1}>
            <HStack spacing={4} height="100%">
              <Box flex={2}>
                <WorkItemsListCard packageId={props.packageId} />
              </Box>
              <Box flex={1}>
                <WorkBasketCard
                  data-testid={suffixTestId('basket', props)}
                  packageId={props.packageId}
                />
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </FullScreenModal>
  );
}
