import {PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';
import {call, all, put, takeLatest} from 'typed-redux-saga';

import {PutEffect} from 'redux-saga/effects';

import i18n from '@omnetic-dms/i18n';
import {downloadHostedFile, ApiException} from '@omnetic-dms/shared';

import {AddTemplateService} from '../../services/AddTemplateService';
import {DeleteTemplateService} from '../../services/DeleteTemplateService';
import {GetTemplatesService} from '../../services/GetTemplatesService';
import {PatchTemplateService} from '../../services/PatchTemplateService';
import {SetPrimaryTemplateService} from '../../services/SetPrimaryTemplateService';
import {uploadFileService} from '../../services/UploadFileService';
import {
  DownloadDocumentTemplate,
  TUploadDocumentTemplateRequest,
  UpdateDocumentTemplateRequest,
} from '../../types/GeneralSettingsDocuments';
import {callApiSaga} from '../../utils/api';
import {getStatusCode, isAxiosError} from '../../utils/customError';
import {getErrorMessages} from '../../utils/getErrorMessage';
import {
  deleteFile,
  downloadTemplate,
  downloadTemplateLoaded,
  fetchTemplateGroup,
  fetchTemplateGroupError,
  fetchTemplateGroupSuccess,
  getDocumentTemplate,
  getDocumentTemplateError,
  getDocumentTemplateSuccess,
  setPrimaryTemplate,
  templateUpdated,
  updateTemplateRequest,
  uploadDocumentTemplateError,
  uploadDocumentTemplateRequest,
  uploadDocumentTemplateSuccess,
} from './reducer';

function prepareDefaultName(templateFileName: string, type: string) {
  return templateFileName && !templateFileName.endsWith('.docx')
    ? `${templateFileName}.default.${type}`
    : templateFileName;
}

function* deleteFileSaga(action: PayloadAction<string>): Generator {
  const templateId = action.payload;
  try {
    yield* callApiSaga(DeleteTemplateService.deleteTemplate, {
      templateId,
    });
    yield* call(() =>
      showNotification.success(i18n.t('entity.document.notifications.documentDeleted'))
    );

    yield* put(getDocumentTemplate());
  } catch (error: any) {
    yield* call(handlingError, error as AxiosError<ApiException>);
  }
}

function* downloadTemplateGen(action: PayloadAction<DownloadDocumentTemplate>): Generator {
  const {url, customTemplateId, fileName, type} = action.payload;
  try {
    yield* call(
      downloadHostedFile,
      url,
      !customTemplateId ? prepareDefaultName(fileName, type) : fileName
    );
    yield* put(downloadTemplateLoaded());
  } catch (error: any) {
    yield* call(
      handlingError,
      error as AxiosError<ApiException>,
      i18n.t('entity.document.notifications.downloadFailed')
    );
    yield* put(downloadTemplateLoaded());
  }
}

function* uploadDocumentTemplateGen(
  action: PayloadAction<TUploadDocumentTemplateRequest>
): Generator {
  const {
    code,
    file,
    title,
    note,
    onError,
    onFinishS3Put,
    onProgress,
    onGetUploadedFile,
    onSuccess,
  } = action.payload;

  try {
    const data = yield* call(
      uploadFileService,
      file,
      onError,
      onFinishS3Put,
      onProgress,
      undefined,
      onGetUploadedFile
    );

    if (data?.fileId) {
      const {fileName} = yield* callApiSaga(AddTemplateService.addTemplate, {
        requestBody: {
          fileId: data.fileId,
          title,
          note: note ?? null,
          documentKindCode: code,
        },
      });
      yield* put(
        uploadDocumentTemplateSuccess({
          code,
          fileName,
        })
      );

      yield* call(() =>
        showNotification.success(i18n.t('entity.files.notifications.uploadSuccess', {fileName}))
      );

      onSuccess?.();
    } else {
      yield* put(uploadDocumentTemplateError({error: null, code}));
      yield* call(() =>
        showNotification.error(i18n.t('entity.document.notifications.uploadingIssue'))
      );
    }
  } catch (error: any) {
    yield* call(handlingError, error as AxiosError<ApiException>);
    yield* put(uploadDocumentTemplateError({error: error as AxiosError<ApiException>, code}));
  }
}

function* updateTemplateRequestSaga(
  action: PayloadAction<UpdateDocumentTemplateRequest>
): Generator {
  const {
    file,
    title,
    note,
    customTemplateId,
    onError,
    onFinishS3Put,
    onProgress,
    onGetUploadedFile,
  } = action.payload;

  if (file && onError) {
    try {
      const data = yield* call(
        uploadFileService,
        file,
        onError,
        onFinishS3Put,
        onProgress,
        undefined,
        onGetUploadedFile
      );
      if (data?.fileId) {
        yield* patchTemplate(title, customTemplateId, note, data?.fileId);
      }
    } catch (error: any) {
      yield* put(templateUpdated({customTemplateId}));
      yield* call(handlingError, error as AxiosError<ApiException>);
    }
  } else {
    yield* patchTemplate(title, customTemplateId, note);
  }
}

function* patchTemplate(
  title: string,
  customTemplateId: string,
  note?: string,
  fileId?: string | undefined
): Generator {
  try {
    if (customTemplateId) {
      yield* callApiSaga(PatchTemplateService.patchTemplate, {
        requestBody: {
          title,
          note,
          fileId,
          templateId: customTemplateId,
        },
      });
      yield* put(templateUpdated({customTemplateId}));
      yield* put(getDocumentTemplate());
      yield* call(() =>
        showNotification.success(i18n.t('entity.document.notifications.templateUpdated'))
      );
    }
  } catch (error: any) {
    yield* put(templateUpdated({customTemplateId}));
    yield* call(handlingError, error as AxiosError<ApiException>);
  }
}

function* fetchTemplateSaga(): Generator {
  try {
    const response = yield* callApiSaga(GetTemplatesService.getTemplateGroups, {});
    const documentKinds = yield* callApiSaga(GetTemplatesService.getDocumentKinds, {});
    yield* put(fetchTemplateGroupSuccess({result: response, documentKinds}));
  } catch (error: any) {
    yield* call(handlingError, error as AxiosError<ApiException>);
    yield* put(fetchTemplateGroupError());
  }
}

function* setPrimaryTemplateSaga(action: PayloadAction<string>): Generator {
  try {
    yield* callApiSaga(SetPrimaryTemplateService.setPrimaryTemplate, {
      requestBody: {templateId: action.payload},
    });
    yield* put(getDocumentTemplate());
    yield* call(() =>
      showNotification.success(i18n.t('entity.document.notifications.templateUpdated'))
    );
  } catch (error: any) {
    yield* call(handlingError, error as AxiosError<ApiException>);
  }
}

function* getDocumentTemplateSaga(): Generator {
  try {
    const response = yield* callApiSaga(GetTemplatesService.getTemplates, {});
    yield* put(getDocumentTemplateSuccess(response));
  } catch (error: any) {
    yield* call(handlingError, error as AxiosError<ApiException>);
    yield* put(getDocumentTemplateError());
  }
}

function* handlingError(
  error: AxiosError<ApiException> | Error,
  subtitle?: string
): Generator<PutEffect> {
  if (isAxiosError(error)) {
    call(() =>
      showNotification.error([
        getStatusCode(error).toString(),
        subtitle ? subtitle : getErrorMessages(error).toString(),
      ])
    );
  } else {
    call(() => showNotification.error([String(error), String(subtitle ? subtitle : error)]));
  }
}

export function* documentsSaga(): Generator {
  yield* all([
    takeLatest(uploadDocumentTemplateRequest, uploadDocumentTemplateGen),
    takeLatest(downloadTemplate, downloadTemplateGen),
    takeLatest(fetchTemplateGroup, fetchTemplateSaga),
    takeLatest(updateTemplateRequest, updateTemplateRequestSaga),
    takeLatest(setPrimaryTemplate, setPrimaryTemplateSaga),
    takeLatest(getDocumentTemplate, getDocumentTemplateSaga),
    takeLatest(deleteFile, deleteFileSaga),
  ]);
}
