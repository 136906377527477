import {
  Button,
  Card,
  DataStatus,
  Segment,
  closeCurrentDialog,
  openDialog,
} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Box, HStack, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';

import {isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  catchUnhandledDataGridActions,
  handleApiError,
  useGetDatagridSynchronizationServiceItemsExternalHistoryQuery,
  usePostDatagridSynchronizeServiceItemsExternalHistoryMutation,
} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, Nullish, RequiredTestIdProps, parseDate} from 'shared';

import {CopyHistoryItem} from './CopyHistoryItem';

enum History {
  Local = 'localHistory',
  External = 'externalHistory',
}

interface WorkItemsProps extends RequiredTestIdProps {
  serviceCaseId: string;
  vehicleId: string | Nullish;
}

export function WorkItems(props: WorkItemsProps) {
  const [history, setHistory] = useState<History>(History.Local);

  const formatDateTime = useDateTimeFormatter();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [postDatagridSynchronizeServiceItemsExternalHistory, {isLoading: isSyncRunning}] =
    usePostDatagridSynchronizeServiceItemsExternalHistoryMutation();

  const {data, isLoading, isError} = useGetDatagridSynchronizationServiceItemsExternalHistoryQuery(
    {vehicleId: props.vehicleId ?? ''},
    {skip: isNil(props.vehicleId)}
  );

  const handleSync = () =>
    postDatagridSynchronizeServiceItemsExternalHistory({
      body: {vehicleId: props.vehicleId ?? ''},
    })
      .then(refreshDataGrid)
      .catch(() => handleApiError);

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {vehicleId: props.vehicleId ?? null}]);

  const itemActionCallback: ActionCallback = ({actionKey, rowId}) => {
    if (actionKey !== 'copy') {
      catchUnhandledDataGridActions(actionKey);
    }

    openDialog(
      <CopyHistoryItem
        serviceCaseId={props.serviceCaseId}
        itemIds={isArray(rowId) ? rowId : [rowId]}
        onClose={closeCurrentDialog}
        data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-copyDialog')}
      />,
      {
        title: i18n.t('entity.order.labels.copyToServiceOrder'),
      }
    );
  };

  return (
    <DataStatus
      isLoading={isNil(props.vehicleId) || isLoading}
      isError={isError}
      key="historyOfRepairs-work-items"
      minHeight={175}
    >
      <Card>
        <HStack align="center" justify="space-between">
          <Segment
            minWidth={35}
            value={history}
            onChange={(syncType) => {
              setHistory(syncType);
              if (syncType === History.External) {
                handleSync();
              }
            }}
            options={[
              {
                value: History.Local,
                label: 'Local history',
              },
              {
                value: History.External,
                label: 'External history',
              },
            ]}
          />
          {history === History.External && (
            <HStack align="center" spacing={2}>
              <Text size="small" color="secondary">
                {`${i18n.t('entity.workItems.labels.lastSync')}: ${data ? formatDateTime('dateTimeLong', parseDate(data?.synchronizedAt)) : EMPTY_PLACEHOLDER}`}
              </Text>

              <Button
                onClick={handleSync}
                isLoading={isSyncRunning}
                variant="ghostLink"
                size="small"
                title={i18n.t('entity.workItems.actions.synchronize')}
                leftIcon="navigation/refresh"
                data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-syncButton')}
              />
            </HStack>
          )}
        </HStack>
        <Box height={175}>
          {history === 'localHistory' && (
            <DataGrid
              gridCode="service-history-order-items"
              queryModifier={queryModifier}
              actionCallback={itemActionCallback}
              data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-workItems-local')}
            />
          )}
          {history === 'externalHistory' && (
            <DataGrid
              ref={dataGridRef}
              gridCode="service-items-external-history"
              queryModifier={queryModifier}
              actionCallback={itemActionCallback}
              data-testid={testIds.workshop.serviceCaseDetail(
                'historyOfRepairs-workItems-external'
              )}
            />
          )}
        </Box>
      </Card>
    </DataStatus>
  );
}
