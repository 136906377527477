import {
  PostVehicleGroupApiResponse,
  PostVehicleGroupApiArg,
  GetVehicleGroupApiResponse,
  GetVehicleGroupApiArg,
  DeleteVehicleGroupApiResponse,
  DeleteVehicleGroupApiArg,
  PatchVehicleGroupApiResponse,
  PatchVehicleGroupApiArg,
  GetVehicleGroupsApiArg,
  GetVehicleGroupsApiResponse,
  PostVehicleGroupSetAsActiveApiResponse,
  PostVehicleGroupSetAsActiveApiArg,
  PostVehicleGroupSetAsInactiveApiResponse,
  PostVehicleGroupSetAsInactiveApiArg,
} from '../types/api/metadaVehicleGroup';
import {metadaApi} from './baseApi/metadaApi';

const metadaVehicleGroupApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postVehicleGroup: build.mutation<PostVehicleGroupApiResponse, PostVehicleGroupApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-group`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['vehicleGroups'],
    }),
    getVehicleGroup: build.query<GetVehicleGroupApiResponse, GetVehicleGroupApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-group/${queryArg.vehicleGroupId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'vehicleGroup', id: queryArg.vehicleGroupId},
      ],
    }),
    deleteVehicleGroup: build.mutation<DeleteVehicleGroupApiResponse, DeleteVehicleGroupApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-group/${queryArg.vehicleGroupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'vehicleGroups',
        {type: 'vehicleGroup', id: queryArg.vehicleGroupId},
      ],
    }),
    patchVehicleGroup: build.mutation<PatchVehicleGroupApiResponse, PatchVehicleGroupApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-group/${queryArg.vehicleGroupId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'vehicleGroup', id: queryArg.vehicleGroupId},
      ],
    }),
    getVehicleGroups: build.query<GetVehicleGroupsApiResponse, GetVehicleGroupsApiArg>({
      query: () => ({
        url: `/core/v1/vehicle-groups`,
      }),
      providesTags: ['vehicleGroups'],
    }),
    postVehicleGroupSetAsActive: build.mutation<
      PostVehicleGroupSetAsActiveApiResponse,
      PostVehicleGroupSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-group/${queryArg.vehicleGroupId}/set-as-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'vehicleGroups',
        {type: 'vehicleGroup', id: queryArg.vehicleGroupId},
      ],
    }),
    postVehicleGroupSetAsInactive: build.mutation<
      PostVehicleGroupSetAsInactiveApiResponse,
      PostVehicleGroupSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-group/${queryArg.vehicleGroupId}/set-as-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'vehicleGroups',
        {type: 'vehicleGroup', id: queryArg.vehicleGroupId},
      ],
    }),
  }),
});

export const {
  usePostVehicleGroupMutation,
  useGetVehicleGroupQuery,
  useDeleteVehicleGroupMutation,
  usePatchVehicleGroupMutation,
  useGetVehicleGroupsQuery,
  usePostVehicleGroupSetAsActiveMutation,
  usePostVehicleGroupSetAsInactiveMutation,
} = metadaVehicleGroupApi;
