import {ButtonProps, closeCurrentDialog, openDialog, showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {defaultTo, equals, isNil, not} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  AfterSalesLabourBasket,
  AssignedMechanic,
  BaseDirectSaleLabourItem,
  EitherQuantityOrError,
  FullScreenModal,
  GetDirectSaleLabourItemResponse,
  handleApiError,
  LabourBasketItemEditingDetails,
  TooltipItem,
  useDeleteDirectSaleLabourBasketItemMutation,
  useDeleteDirectSaleLabourBasketItemsMutation,
  useGetDirectSaleLabourBasketItemsQuery,
  useGetEmployeeMechanicsQuery,
  useInvalidBasketItemsIds,
  useLazyGetDirectSaleLabourItemQuery,
  usePatchDirectSaleLabourBasketItemQuantityMutation,
  usePostDirectSaleLabourItemsMechanicMutation,
  usePostDirectSaleLabourItemToBasketMutation,
} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourItemEdit} from './RequestTabLabourItemEdit';
import {RequestTabLabourList} from './RequestTabLabourList';

// Temporary solution, will be removed after resolving
// TODO https://carvago.atlassian.net/browse/T20-51559
type BaseDirectSaleWorkItemWithTooltip = Omit<BaseDirectSaleLabourItem, 'tooltip'> & {
  tooltip: TooltipItem[];
};

interface AddLabourModalProps extends RequiredTestIdProps {
  directSaleId: string;
  authorizationProfileId?: string;
  onDiscard: () => void;
}

export function RequestTabLabourModal(props: AddLabourModalProps) {
  const [mechanic, setMechanic] = useState<AssignedMechanic | null>(null);

  const [getDirectSaleLabourItem] = useLazyGetDirectSaleLabourItemQuery();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetDirectSaleLabourBasketItemsQuery({directSaleId: props.directSaleId});

  const {data: mechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: defaultTo('', props.authorizationProfileId)},
    {skip: isNil(props.authorizationProfileId)}
  );

  const basketItems = basket?.workBasketItem ?? [];
  const basketTotalPrice = basket?.workBasketTotalPrice;
  const basketMechanicId = basket?.assignMechanicId;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  // Manually set the last selected mechanic to include it in onSubmit payload since
  // AfterSalesLabourBasket's onChange not being triggerable without unnecessary useEffect.
  useEffect(() => {
    if (isNil(basketMechanicId)) {
      return;
    }

    const lastSelectedMechanic = mechanics?.employees?.find(
      (mechanic) => mechanic?.id === basketMechanicId
    );

    if (isNil(lastSelectedMechanic)) {
      return;
    }

    setMechanic({
      id: lastSelectedMechanic.id!,
      isDefault: true,
      costCenterId: lastSelectedMechanic.costCenterId!,
    });
  }, [basketMechanicId, mechanics?.employees]);

  const [assignMechanicsToDirectSale, {isLoading: isAssigningMechanicsToDirectSale}] =
    usePostDirectSaleLabourItemsMechanicMutation();

  const [emptyBasket, {isLoading: isDiscarding}] = useDeleteDirectSaleLabourBasketItemsMutation();

  const [addItemToBasket] = usePostDirectSaleLabourItemToBasketMutation();

  const [changeLabourBasketItemQuantity] = usePatchDirectSaleLabourBasketItemQuantityMutation();

  const [deleteLabourBasketItem, {isLoading: isDeletingItem}] =
    useDeleteDirectSaleLabourBasketItemMutation();

  const assignedMechanic = defaultTo(null, mechanic);

  const handleAdd = async () => {
    if (isNotNil(assignedMechanic)) {
      return await assignMechanicsToDirectSale({
        directSaleId: props.directSaleId,
        body: {
          assignMechanics: {
            id: assignedMechanic.id,
            isDefault: assignedMechanic.isDefault,
            costCenterId: assignedMechanic.costCenterId,
          },
        },
      })
        .unwrap()
        .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.labourAdded')))
        .then(props.onDiscard)
        .catch(handleApiError);
    }

    await assignMechanicsToDirectSale({
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.labourAdded')))
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await emptyBasket({directSaleId: props.directSaleId})
      .unwrap()
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleMechanicChange = (mechanic: AssignedMechanic | null) => {
    setMechanic(mechanic);
  };

  const handleAddItemToBasket = async (labourCatalogId: string) => {
    await addItemToBasket({directSaleId: props.directSaleId, body: {labourCatalogId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await changeLabourBasketItemQuantity({
      directSaleId: props.directSaleId,
      itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = async (editingDetails: LabourBasketItemEditingDetails) => {
    if (isNil(editingDetails.itemId)) {
      return;
    }

    const labourItem = (await getDirectSaleLabourItem({
      itemId: editingDetails.itemId,
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .catch(handleApiError)) as GetDirectSaleLabourItemResponse;

    const itemName = labourItem ? `${labourItem?.name} (${labourItem?.number})` : '';

    openDialog(
      <RequestTabLabourItemEdit
        directSaleId={props.directSaleId}
        item={labourItem}
        isEditingDisabled={false}
        authorizationProfileId={props.authorizationProfileId}
        onAfterSubmit={closeCurrentDialog}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('dialog.editLabour', props)}
      />,
      {
        title: itemName,
      }
    );
  };

  const handleDelete = async (itemId: string) => {
    await deleteLabourBasketItem({directSaleId: props.directSaleId, itemId})
      .unwrap()
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscarding,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isAssigningMechanicsToDirectSale,
      isDisabled: isNilOrEmpty(basketItems) || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  // Temporary solution, will be removed after resolving
  // TODO https://carvago.atlassian.net/browse/T20-51559
  const basketItemsWithTooltip = basketItems?.map((item) => ({
    ...item,
    tooltip: [
      {
        label: i18n.t('entity.warehouse.labels.priceType'),
        value: item.tooltip?.priceType || EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.workType'),
        value: item.tooltip?.workType || EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.dontApplyDiscount'),
        value: item.tooltip?.doNotApplyDiscount || EMPTY_PLACEHOLDER,
      },
    ],
  }));

  return (
    <FullScreenModal headline={i18n.t('general.actions.addWork')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <RequestTabLabourList
              directSaleId={props.directSaleId}
              onAddItemToBasket={handleAddItemToBasket}
              data-testid={suffixTestId('itemList', props)}
            />
          </Box>
          <Box flex={1}>
            <AfterSalesLabourBasket<BaseDirectSaleWorkItemWithTooltip>
              basket={{
                items: basketItemsWithTooltip,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingItem,
              }}
              mechanic={{mechanics, assignedMechanic, onMechanicChange: handleMechanicChange}}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEdit}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
