import {defaultTo, find} from 'ramda';

import {TestIdProps} from 'shared';

import {useGetInterestQuery, useUpdateInterestMutation} from '../../../api/interestApi';
import {useGetCurrentUserInfoQuery} from '../../../api/userApi';
import {BranchResponseBody} from '../../../types/api/api';
import {UpdateInterestApiArg} from '../../../types/api/interest';
import {handleApiError} from '../../../utils/handleApiError';
import {BranchChanger} from './BranchChanger';

export interface InterestBranchProps extends TestIdProps {
  recordId: string;
}

export function InterestBranch(props: InterestBranchProps) {
  const {data: interest} = useGetInterestQuery({id: props.recordId});
  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();

  const [updateInterest] = useUpdateInterestMutation({});

  const handleBranchChange = (branchId: string) => {
    const updateInterestInput: UpdateInterestApiArg = {
      id: props.recordId,
      updateInterestRequestBody: {
        branchId,
      },
    };

    updateInterest(updateInterestInput).unwrap().catch(handleApiError);
  };

  const activeBranch = find(
    (branch: BranchResponseBody) => branch.id === interest?.branchId,
    defaultTo([], currentUserInfo?.branches)
  );

  return (
    <BranchChanger
      recordId={props.recordId}
      activeBranch={activeBranch}
      onChange={handleBranchChange}
      data-testid={props['data-testid']}
    />
  );
}
