import {format} from 'date-fns';

import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import {isEmpty, isNotEmpty} from 'ramda';

import {featureFlags, usePermissions} from '@omnetic-dms/shared';

import {isFeatureEnabled} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {getActualAuditData, updateAudit} from '../../../store/carAudit/actions';
import {setErrorCategories, updateAuditFields} from '../../../store/carAudit/reducer';
import {AuditData, AuditFields} from '../../../store/carAudit/reducerUtils';
import {
  selectAudit,
  selectAuditState,
  selectNavigationCategories,
  selectNavigationCategoriesStateParams,
} from '../../../store/carAudit/selectors';
import {AuditCategoriesWithError} from '../../../types/ConditionTypes';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {RequestAuditDataFieldsBody} from '../../../types/RequestAuditDataFieldsBody';
import {ConditionContext, TConditionContext} from '../hooks/useConditionContext';
import {useSecondSetValidation} from '../hooks/useSecondSetValidation';
import {useVehicleFeatures} from '../hooks/useVehicleFeatures';
import {NavigationState} from '../types/NavigationState';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {getFormFieldName} from '../utils/getFormFieldName';
import {isValidValue} from '../utils/getFormSchema';
import {getInitialFormValues} from '../utils/getInitialFormValues';

type AuditContextProps = Omit<
  TConditionContext,
  | 'loadActualData'
  | 'loadActualDataAndValidate'
  | 'handleChangeCategory'
  | 'hasPermissionsToEditInspectionData'
  | 'isDisabledAuditEdit'
  | 'isDisabledForUser'
> & {
  setIsAutoSaveDisabled: (isAutoSaveDisabled: boolean) => void;
  getChangedCategoryStateFieldValue: () => AuditFields;
  updateFields: (formFields: AuditFields) => Promise<void>;
};

export const AuditContext: FC<PropsWithChildren<AuditContextProps>> = ({
  formRenderProps,
  requiredFormFields,
  isCreateVehicle,
  setIsAutoSaveDisabled,
  getChangedCategoryStateFieldValue,
  updateFields,
  children,
  isInNotEditableState,
}) => {
  const dispatch = useThunkDispatch();
  const navigationStateParams = useSelector(selectNavigationCategoriesStateParams);
  const navigationCategories = useSelector(selectNavigationCategories);
  const audit = useSelector(selectAudit);
  const auditState = useSelector(selectAuditState);
  const {
    featureCategoryExists,
    selectedFeaturesCategoryIds,
    selectedFeaturesAssetCategoryIds,
    featuresCategoriesId,
    featuresMainCategoriesIds,
    featuresAssetsCategoriesIds,
  } = useVehicleFeatures(
    navigationCategories?.find(
      (category) => category.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT
    )
  );
  const {filterSecondSetValidateError} = useSecondSetValidation(
    navigationCategories?.find(
      (category) => category.uniqueKey === AuditCategoryUniqueKey.WHEELS_AND_TIRES
    )
  );
  const [permissionToCreateInspection, permissionToUpdateInspection] = usePermissions({
    permissionKeys: ['createInspection', 'updateInspection'],
    scopes: {
      updateInspection: {inspectionType: audit?.inspectionType},
    },
  });

  const {
    handleSubmit,
    form: {initialize, pauseValidation, resumeValidation, setConfig},
  } = formRenderProps;

  const setNavigationCategoryStates = async (
    categoriesWithError: AuditCategoriesWithError,
    auditState: LoadAuditDataResponseItemBody.state
  ): Promise<void> => {
    const isAuditFinished =
      auditState && auditState === LoadAuditDataResponseItemBody.state.FINISHED_AUDIT;

    if (!navigationCategories || !audit || isAuditFinished) {
      return;
    }

    const finishedState = `${NavigationState.finished}-${format(new Date(), 'dd/MM/yy')}`;
    const requestBody: RequestAuditDataFieldsBody[] = [];
    const stateValues: AuditFields = {};

    for await (const {id: categoryId} of navigationCategories) {
      const paramDefinitionId = navigationStateParams?.[categoryId];
      if (!paramDefinitionId) {
        continue;
      }

      const value = categoriesWithError?.[categoryId]
        ? NavigationState.notFinishedError
        : finishedState;

      requestBody.push({
        categoryId,
        paramDefinitionId,
        value,
        updatedAt: new Date().toISOString(),
      });

      stateValues[getFormFieldName(categoryId, paramDefinitionId)] = value;
    }
    await dispatch(
      updateAudit.action({
        auditId: audit.id,
        requestBody,
      })
    );
    await dispatch(updateAuditFields(stateValues));
  };

  const filterFeatureCategoryErrors = (categoriesWithError: AuditCategoriesWithError) =>
    Object.keys(categoriesWithError)
      .filter((categoryWithError) => {
        if (featuresCategoriesId?.includes(categoryWithError)) {
          return selectedFeaturesCategoryIds?.includes(categoryWithError);
        }
        if (featuresAssetsCategoriesIds?.includes(categoryWithError)) {
          return selectedFeaturesAssetCategoryIds?.includes(categoryWithError);
        }
        return true;
      })
      .filter((categoriesWithError, _, previousErrors) => {
        if (
          !previousErrors.some((previousError) =>
            selectedFeaturesCategoryIds?.includes(previousError)
          )
        ) {
          if (featuresMainCategoriesIds.includes(categoriesWithError)) {
            return false;
          }
        }

        return true;
      })
      .reduce((obj: AuditCategoriesWithError, key) => {
        obj[key] = categoriesWithError[key];
        return obj;
      }, {});

  const getCategoriesWithError = (audit: AuditData): AuditCategoriesWithError => {
    if (!requiredFormFields) {
      return {};
    }

    const categoriesWithError: AuditCategoriesWithError = {};

    Object.keys(requiredFormFields.fields).forEach((key) => {
      const field = audit?.fields?.[key];
      if (!Boolean(field) || !isValidValue(field)) {
        Object.values(requiredFormFields.fields[key]).forEach((categoryId) => {
          categoriesWithError[categoryId] = true;
        });
      }
    });

    Object.keys(requiredFormFields.assets).forEach((key) => {
      if (!audit?.assets?.[key]) {
        Object.values(requiredFormFields.assets[key]).forEach((categoryId) => {
          categoriesWithError[categoryId] = true;
        });
        const keyParts = key.split('_');
        if (keyParts[1]) {
          categoriesWithError[keyParts[1]] = true;
        }
      }
    });

    filterSecondSetValidateError(categoriesWithError);

    return featureCategoryExists
      ? filterFeatureCategoryErrors(categoriesWithError)
      : categoriesWithError;
  };

  const _loadActualData = async (auditId: string): Promise<AuditData> => {
    await dispatch(
      getActualAuditData.action({
        auditId,
      })
    );

    setConfig('keepDirtyOnReinitialize', false);
    initialize(getInitialFormValues(audit?.fields, navigationStateParams) ?? {});
    setConfig('keepDirtyOnReinitialize', true);

    return audit as AuditData;
  };

  const loadActualData = async (auditId: string): Promise<AuditData> => {
    setIsAutoSaveDisabled(true);
    const audit = await _loadActualData(auditId);
    setIsAutoSaveDisabled(false);

    return audit;
  };

  const validate = async (audit: AuditData): Promise<void> => {
    const categoriesWithError = getCategoriesWithError(audit);

    await dispatch(setErrorCategories(categoriesWithError));
    await handleSubmit();
    await setNavigationCategoryStates(categoriesWithError, audit.state);

    if (isNotEmpty(categoriesWithError)) {
      return Promise.reject({categoriesWithError});
    }
  };

  const loadActualDataAndValidate = async (): Promise<void> => {
    if (!audit) {
      return;
    }
    if (
      auditState === 'to_revision' &&
      isFeatureEnabled(featureFlags.INSPECTION_TO_REVISION_NO_EDIT)
    ) {
      return;
    }
    try {
      setIsAutoSaveDisabled(true);
      const actualData = await _loadActualData(audit.id);
      resumeValidation();
      await validate(actualData);
    } finally {
      //@ts-expect-error pauseValidation has parameter preventNotification
      pauseValidation(false);
      setIsAutoSaveDisabled(false);
    }
  };

  const handleChangeCategory = async (): Promise<void> => {
    const stateFieldValue = getChangedCategoryStateFieldValue();
    if (isEmpty(stateFieldValue)) {
      return;
    }

    await updateFields(stateFieldValue);
  };

  const isDisabledAuditEdit = (includeState = true): boolean => {
    if (isInNotEditableState && includeState) {
      return true;
    }
    return !permissionToUpdateInspection;
  };

  const hasPermissionsToEditInspectionData = (): boolean => {
    const hasPermissionsToEditInspection =
      permissionToUpdateInspection ||
      (permissionToCreateInspection &&
        auditState !== LoadAuditDataResponseItemBody.state.FINISHED_AUDIT);

    const disableInspectionEditInToRevisionState =
      auditState &&
      [
        LoadAuditDataResponseItemBody.state.TO_REVISION,
        LoadAuditDataResponseItemBody.state.FINISHED_AUDIT,
      ].includes(auditState);

    return (hasPermissionsToEditInspection || false) && !disableInspectionEditInToRevisionState;
  };

  return (
    <ConditionContext.Provider
      value={{
        formRenderProps,
        requiredFormFields,
        isCreateVehicle,
        loadActualData,
        loadActualDataAndValidate,
        handleChangeCategory,
        hasPermissionsToEditInspectionData,
        isDisabledAuditEdit,
        isInNotEditableState,
        isDisabledForUser: isDisabledAuditEdit() || !hasPermissionsToEditInspectionData(),
      }}
    >
      {children}
    </ConditionContext.Provider>
  );
};
