import {DataStatus} from 'platform/components';

import {isNil} from 'ramda';

import {
  VehicleWidget,
  getBusinessCaseTradeTypeFlag,
  useGetVehicleV2Query,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type VehicleOverviewProps = {
  vehicleId: string;
  isBrokerage: boolean;
} & RequiredTestIdProps;

export const VehicleOverview = (props: VehicleOverviewProps) => {
  const {
    data: vehicle,
    isLoading: isLoadingVehicle,
    isError: isVehicleError,
  } = useGetVehicleV2Query({vehicleId: props.vehicleId ?? ''}, {skip: isNil(props.vehicleId)});

  const flag = getBusinessCaseTradeTypeFlag(props.isBrokerage ? 'BROKERAGE' : 'IN_STOCK');

  return (
    <DataStatus isLoading={isLoadingVehicle} isError={isVehicleError}>
      <VehicleWidget
        data-testid={suffixTestId('vehicleOverview', props)}
        key={vehicle?.id}
        vehicle={vehicle!}
        flags={[flag!]}
      />
    </DataStatus>
  );
};
