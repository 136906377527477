import {Card, DataStatus} from 'platform/components';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {useGetCebiaFeatureDecoderConfigQuery} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';
import {AutofillWidget} from './AutofillWidget';

interface CebiaWidgetProps extends RequiredTestIdProps {
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function CebiaWidget(props: CebiaWidgetProps) {
  const navigate = useNavigate();
  const cebiaFeatureDecoderQuery = useGetCebiaFeatureDecoderConfigQuery();

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.integrations')}
      actions={[
        {
          variant: 'link',
          type: 'button',
          leftIcon: 'action/settings',
          onClick: () => navigate(settingsRoutes.vehicleServices),
          'data-testid': suffixTestId('settingsLink', props),
          title: i18n.t('general.labels.settings'),
        },
      ]}
      isExpandable
    >
      <DataStatus
        isEmpty={!cebiaFeatureDecoderQuery.data?.enabled}
        isLoading={cebiaFeatureDecoderQuery.isLoading}
        isError={cebiaFeatureDecoderQuery.isError}
        emptyMessage={i18n.t('entity.vehicle.emptyIntegration.noServicesAvailable')}
        emptySubheadline={i18n.t('entity.vehicle.emptyIntegration.enableInSettings')}
      >
        <AutofillWidget formApi={props.formApi} data-testid={suffixTestId('autofill', props)} />
      </DataStatus>
    </Card>
  );
}
