import validatePhone from 'phone';
import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Button,
  Separator,
  IconButton,
  DialogFooter,
} from 'platform/components';
import {VStack, HStack, Box, Align, Space, Heading} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {replace} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, yupString, Nullish, TestIdProps, debounce} from 'shared';

import {useParsePersonalIdMutation} from '../../api/customerApi';
import {COUNTRY_CZE} from '../../constants/country';
import {useCustomerAddress} from '../../hooks/useCustomerAddress';
import {useGender} from '../../hooks/useGender';
import {useTenant} from '../../hooks/useTenant';
import i18n from '../../i18n/i18n';
import {
  AddressResponseBodyV2,
  CreateCustomerAddressV2ApiResponse,
  EmailData,
  ParsePersonalIdApiArg,
  PersonRequestBody,
  PersonResponseBodyV2,
  PhoneNumberData,
} from '../../types/api/customer';
import {PhoneNumber} from '../../types/common/PhoneNumber';
import {getContactPersonDefaultValues} from '../../utils/getContactPersonDefaultValues';
import {handleApiError} from '../../utils/handleApiError';
import {CountrySelect} from '../CountrySelect/CountrySelect';
import {EmailList} from '../EmailList/EmailList';
import {IdentityCardList} from '../IdentityCardList/IdentityCardList';
import {yupIdentityCardSchema} from '../IdentityCardList/yupIdentityCardSchema';
import {PhoneNumberList} from '../PhoneNumberList/PhoneNumberList';

interface ContactPersonFormProps extends TestIdProps {
  customerId: string;
  addresses?: AddressResponseBodyV2[];
  isLoading?: boolean;
  contact?: PersonResponseBodyV2;
  onSubmit?: FormSubmitHandler<PersonRequestBody>;
  onClose?: () => void;
}

const DEBOUNCE_TIMEOUT = 700;

export function ContactPersonForm(props: ContactPersonFormProps) {
  const {tenantCountry, isTenantCountrySupported, tenantPhoneInfo} = useTenant();
  const {addressOptions, openCreateAddressDialog, openEditAddressDialog} = useCustomerAddress(
    props.customerId
  );

  const {genderOptions} = useGender();

  const [parsePersonalId] = useParsePersonalIdMutation();

  const parsePersonalIdData = useCallback(
    (formApi: UseFormReturn<PersonRequestBody>) => {
      const personalId = formApi.getValues('personData.personalIdentifier');
      let country = formApi.getValues('personData.citizenshipCode');

      if (isTenantCountrySupported(COUNTRY_CZE)) {
        country = tenantCountry;
      }

      if (!personalId || !country) {
        return;
      }

      const args: ParsePersonalIdApiArg = {
        parsePersonalIdRequestBody: {
          personalId: replace('/', '')(personalId ?? ''),
          country,
        },
      };

      parsePersonalId(args)
        .unwrap()
        .then((personalIdInfo) => {
          formApi.setValue('personData.birthdate', personalIdInfo.birthdate);
          formApi.setValue('personData.genderKey', personalIdInfo.genderKey);
        })
        .catch((error) => handleApiError(error, {silent: true}));
    },
    [isTenantCountrySupported, parsePersonalId, tenantCountry]
  );

  const handleParsePersonalId = debounce(parsePersonalIdData, DEBOUNCE_TIMEOUT);

  const onCreateAddress =
    (formApi: UseFormReturn<PersonRequestBody>) => (address: CreateCustomerAddressV2ApiResponse) =>
      formApi.setValue('permanentAddressId', address.id);

  return (
    <Form<PersonRequestBody>
      onSubmit={props.onSubmit}
      defaultValues={getContactPersonDefaultValues(tenantPhoneInfo, props.contact)}
      schema={schema}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="personData.titleBefore"
                maxLength={10}
                label={i18n.t('entity.person.labels.titleBefore')}
                data-testid={suffixTestId('titleBefore', props)}
              />
            </Box>
            <Box flex={2}>
              <FormField
                control={control}
                type="text"
                name="personData.firstName"
                maxLength={64}
                label={i18n.t('entity.person.labels.firstName')}
                data-testid={suffixTestId('firstName', props)}
              />
            </Box>
            <Box flex={2}>
              <FormField
                control={control}
                type="text"
                name="personData.lastName"
                isRequired
                maxLength={64}
                label={i18n.t('entity.person.labels.lastName')}
                data-testid={suffixTestId('lastName', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="personData.titleAfter"
                maxLength={10}
                label={i18n.t('entity.person.labels.titleAfter')}
                data-testid={suffixTestId('titleAfter', props)}
              />
            </Box>
          </HStack>
          <Separator spacing={0} />
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                name="personData.roles"
                type="text"
                label={i18n.t('entity.person.labels.role')}
                data-testid={suffixTestId('roles', props)}
              />
            </Box>
            <Box flex={1}>
              <Space fillAvailable />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <CountrySelect<PersonRequestBody>
                control={control}
                name="personData.citizenshipCode"
                label={i18n.t('entity.person.labels.citizenshipCode')}
                onChange={() => handleParsePersonalId(formApi)}
                menuInPortal
                data-testid={suffixTestId('citizenshipCode', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="personData.personalIdentifier"
                label={i18n.t('entity.person.labels.personalIdentifier')}
                onChange={() => handleParsePersonalId(formApi)}
                data-testid={suffixTestId('personalIdentifier', props)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="personData.genderKey"
                options={genderOptions}
                label={i18n.t('entity.person.labels.genderKey')}
                menuInPortal
                data-testid={suffixTestId('genderKey', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="apiDate"
                name="personData.birthdate"
                isRelativeDatesHidden
                label={i18n.t('entity.person.labels.birthdate')}
                data-testid={suffixTestId('birthdate', props)}
              />
            </Box>
          </HStack>
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('entity.person.permanentAddressId')}</Heading>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="permanentAddressId"
                options={addressOptions}
                label={i18n.t('entity.address.labels.selectAddress')}
                menuInPortal
                data-testid={suffixTestId('permanentAddressId', props)}
              />
            </Box>
            <HStack height={13} align="flex-end">
              <IconButton
                icon="image/edit"
                severity="informational"
                isDisabled={isNilOrEmpty(formApi.watch('permanentAddressId'))}
                onClick={() => openEditAddressDialog(formApi.watch('permanentAddressId')!)}
                data-testid={suffixTestId('editPermanentAddressId', props)}
              />
            </HStack>
          </HStack>
          <Align left>
            <Button
              leftIcon="content/add_circle"
              title={i18n.t('entity.customer.actions.newAddress')}
              size="small"
              variant="link"
              onClick={() => openCreateAddressDialog({onSuccess: onCreateAddress(formApi)})}
              data-testid={suffixTestId('newAddress', props)}
            />
          </Align>
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('entity.person.labels.contactInformation')}</Heading>
          <PhoneNumberList<PersonRequestBody>
            control={control}
            formApi={formApi}
            name="phoneNumbers"
            data-testid={suffixTestId('phoneNumberList', props)}
          />
          <EmailList<PersonRequestBody>
            control={control}
            formApi={formApi}
            name="emails"
            data-testid={suffixTestId('emailList', props)}
          />
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('general.labels.identityCard')}</Heading>
          <IdentityCardList<PersonRequestBody>
            name="identityCards"
            control={control}
            formApi={formApi}
            data-testid={suffixTestId('identityCardList', props)}
          />
          <DialogFooter data-testid={props['data-testid']}>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose ?? closeCurrentDialog}
                isDisabled={props.isLoading}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={props.isLoading}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </VStack>
      )}
    </Form>
  );
}

const schema = Yup.object().shape(
  {
    personData: Yup.object().shape({
      titleBefore: yupString.optional(),
      firstName: yupString.optional().max(64),
      lastName: yupString.required().max(64),
      titleAfter: yupString.optional(),
      genderKey: yupString.optional(),
      roles: yupString.optional(),
      citizenshipCode: yupString.optional(),
      personalIdentifier: yupString.optional(),
    }),
    identityCards: Yup.array().of(yupIdentityCardSchema),
    permanentAddressId: yupString.optional(),
    phoneNumbers: Yup.array().when('emails', {
      is: (emails?: EmailData[]) =>
        emails?.length && emails.length <= 1 && isNilOrEmpty(emails?.[0]?.email),
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              type: yupString.optional(),
              phoneNumber: Yup.mixed()
                .test(
                  'isPhone',
                  i18n.t('general.validations.invalidPhoneNumber'),
                  (phone?: PhoneNumber | Nullish) => {
                    if (isNilOrEmpty(phone?.number)) {
                      return true;
                    }
                    return validatePhone(phone?.number || '', {
                      country: phone?.countryCode,
                      validateMobilePrefix: false,
                    }).isValid;
                  }
                )
                .test(
                  'required',
                  i18n.t('general.validations.phoneOrEmail'),
                  (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
                ),
            })
          )
          .min(1),
      otherwise: (schema) =>
        schema.of(
          Yup.object().shape({
            type: yupString.optional(),
            phoneNumber: Yup.mixed().test(
              'isPhone',
              i18n.t('general.validations.invalidPhoneNumber'),
              (phone?: PhoneNumber | Nullish) => {
                if (isNilOrEmpty(phone?.number)) {
                  return true;
                }
                return validatePhone(phone?.number || '', {
                  country: phone?.countryCode,
                  validateMobilePrefix: false,
                }).isValid;
              }
            ),
          })
        ),
    }),
    emails: Yup.array().when('phoneNumbers', {
      is: (phoneNumbers?: PhoneNumberData[]) =>
        phoneNumbers?.length &&
        phoneNumbers?.length <= 1 &&
        isNilOrEmpty(phoneNumbers?.[0]?.phoneNumber?.number),
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              type: yupString.optional(),
              email: yupString.required(i18n.t('general.validations.phoneOrEmail')),
            })
          )
          .min(1),
      otherwise: (schema) =>
        schema.of(
          Yup.object().shape({
            type: yupString.optional(),
            email: yupString.optional().default(''),
          })
        ),
    }),
  },
  [['emails', 'phoneNumbers']]
);
