import {DataStatus, FormSubmitHandler, showNotification} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {ReactNode, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {always, find, head, isNil} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  CheckoutBillingInformation,
  CheckoutPayment,
  CheckoutPaymentFormType,
  EntityResourceIds,
  handleApiError,
  selectTenant,
  useBank,
  useGetCheckoutByContextQuery,
  useGetServiceOrderCheckoutRestrictionQuery,
  useGetServiceOrderQuery,
  useLazyGetCustomerV2Query,
  usePatchCheckoutMutation,
  usePostCheckoutInvoiceMutation,
} from '@omnetic-dms/shared';

import {buildArray, getApiDateString, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {useHandlServiceOrderMandatoryFieldsError} from '../hooks/useHandlServiceOrderMandatoryFieldsError';
import {useOrderMandatoryFields} from '../hooks/useOrderMandatoryFields';

interface CheckoutProps extends RequiredTestIdProps {}

export function Checkout(props: CheckoutProps) {
  const {orderId, serviceCaseId} = useWorkshopUrl();
  const {getBankAccountByNumber} = useBank();
  const handleMandatoryFieldsError = useHandlServiceOrderMandatoryFieldsError(orderId);
  const {
    data: checkoutRestriction,
    isLoading: isCheckoutRestrictionLoading,
    isError: isCheckoutRestrictionError,
  } = useGetServiceOrderCheckoutRestrictionQuery({
    serviceCaseId,
    serviceOrderId: orderId,
  });

  const {data: {currency: defaultCurrency} = {}} = useSelector(selectTenant);

  const {
    data: checkout,
    isLoading,
    isError,
  } = useGetCheckoutByContextQuery(
    {contextId: orderId, contextTarget: 'service-order'},
    {refetchOnMountOrArgChange: true}
  );
  const {data: serviceOrder} = useGetServiceOrderQuery({serviceCaseId, serviceOrderId: orderId});
  const {isFieldRequired} = useOrderMandatoryFields(orderId);

  const [getCustomer] = useLazyGetCustomerV2Query();
  const [patchCheckout] = usePatchCheckoutMutation();
  const [postCheckoutInvoice] = usePostCheckoutInvoiceMutation();

  const [customerType, setCustomerType] = useState<string | null>(null);
  const [otherCustomerId, setOtherCustomerId] = useState<string | null>(null);
  const [customerContractId, setCustomerContractId] = useState<string | null>(null);

  useEffect(() => {
    if (isNil(checkout) || isNilOrEmpty(checkout?.customers)) {
      return;
    }

    const selectedCustomer = find(
      (customer) => isTrue(customer?.isSelected),
      checkout.customers ?? []
    );
    const firstCustomer = head(checkout.customers ?? []);

    const customer = selectedCustomer ?? firstCustomer;

    if (isNil(customer)) {
      return;
    }

    setCustomerType(customer.type);

    if (customer.type === 'OTHER') {
      setOtherCustomerId(customer.id);
    }

    if (isNotNil(customer.contactInformationId)) {
      return setCustomerContractId(customer.contactInformationId);
    }

    if (isNil(customer?.id)) {
      return;
    }

    getCustomer({customerId: customer.id})
      .unwrap()
      .then((response) => {
        const contract = head(response?.contractInformation ?? []);
        setCustomerContractId(contract?.id ?? null);
      })
      .catch(handleApiError);
  }, [checkout, getCustomer]);

  const handleCustomerTypeChange = (type: string) => {
    setCustomerType(type);

    const selectedCustomer = find((customer) => customer?.type === type, checkout?.customers ?? []);

    if (type === 'OTHER') {
      setOtherCustomerId(selectedCustomer?.id ?? null);
    }

    if (isNotNil(selectedCustomer?.id) && isNil(selectedCustomer?.contactInformationId)) {
      getCustomer({customerId: selectedCustomer?.id ?? ''})
        .unwrap()
        .then((response) => {
          const contract = head(response?.contractInformation ?? []);
          setCustomerContractId(contract?.id ?? null);
        })
        .catch(handleApiError);
    } else {
      setCustomerContractId(selectedCustomer?.contactInformationId ?? null);
    }
  };

  const handleOtherCustomerSelect = (customerId: string) => {
    setOtherCustomerId(customerId);
    getCustomer({customerId})
      .unwrap()
      .then((response) => {
        const contract = head(response?.contractInformation ?? []);
        setCustomerContractId(contract?.id ?? null);
      })
      .catch(handleApiError);
  };

  const handleRemoveCustomer = () => {
    setOtherCustomerId(null);
    setCustomerContractId(null);
  };

  const selectedCustomer = find(
    (customer) => customer?.type === customerType,
    checkout?.customers ?? []
  );

  const selectedCustomerId = customerType === 'OTHER' ? otherCustomerId : selectedCustomer?.id;

  const handleSaveChanges = async (
    data: CheckoutPaymentFormType,
    shouldShowNottification?: boolean
  ) => {
    const selectedBankAccout = getBankAccountByNumber(data.bankAccount);

    await patchCheckout({
      checkoutId: checkout?.checkout?.id ?? '',
      body: {
        customer: {
          contactInformationId: customerContractId ?? '',
          id: selectedCustomerId ?? '',
          type: customerType ?? '',
        },
        depositAllowed: false,
        depositPayment: [],
        paymentInfo: {
          foreignCurrencyPayment: data.currency
            ? {
                currency: data.currency,
                exchangeRate: data.exchangeRate ?? 0,
                ratio: data.ratio,
              }
            : undefined,
          incomingBankAccount: data.bankAccount
            ? {
                bankAccount: data.bankAccount,
                bankName: selectedBankAccout?.accountName,
                swift: selectedBankAccout?.swift,
                iban: selectedBankAccout?.iban,
              }
            : undefined,
          issueDate: getApiDateString(data.issuedOn),
          method: data.paymentMethod,
          paymentAmount: {
            amount: data.amount,
            currency: defaultCurrency ?? '',
            isPriceWithVat: true,
          },
          due: data.due,
          dueDate: getApiDateString(data.dueDate),
          taxableEventDate: getApiDateString(data.taxableEventDate),
          note: data.note,
          cashRegisterId: data.cashRegisterId,
        },
      },
    })
      .unwrap()
      .then(() => shouldShowNottification && showNotification.success())
      .catch(handleApiError);
  };

  const handleIssuePayment: FormSubmitHandler<CheckoutPaymentFormType> = (data) =>
    handleSaveChanges(data).then(() =>
      postCheckoutInvoice({serviceCaseId, checkoutId: checkout?.checkout?.id ?? ''})
        .unwrap()
        .then((res) => {
          const hyundaiSiebelSent = !!res?.integrationProcess?.find(
            (item) => item.code === 'HYUNDAI_CRM_EU'
          )?.status;

          if (hyundaiSiebelSent) {
            return showNotification.success(
              i18n.t('entity.serviceCase.notification.siebelHistorySent')
            );
          }

          showNotification.success();
        })
        .catch(handleMandatoryFieldsError)
    );

  const isIssueButtonDisabled =
    isFieldRequired('generatingOrderSheet') && serviceOrder?.state !== 'OPEN';

  const issueButtonBlockers = buildArray<ReactNode>(
    checkoutRestriction?.map((restriction) => (
      <Text size="xSmall" alternative color="white" key={restriction.code}>
        {restriction.message}
      </Text>
    ))
  ).when(
    isIssueButtonDisabled,
    <Text size="xSmall" alternative color="white">
      {i18n.t('entity.orderCheckout.labels.needToGenerateOrderList')}
    </Text>
  );

  return (
    <DataStatus
      isLoading={isLoading || isCheckoutRestrictionLoading}
      isError={isError || isCheckoutRestrictionError}
    >
      <VStack spacing={4}>
        <CheckoutBillingInformation
          customers={checkout?.customers}
          customerTyp={customerType}
          customerId={selectedCustomerId}
          customerContractId={customerContractId}
          getChipLabelByType={getChipLabelByType}
          onCustomerTypChange={handleCustomerTypeChange}
          onOtherCustomerSelect={handleOtherCustomerSelect}
          onOtherCustomerRemove={handleRemoveCustomer}
          onCustomerContractChange={setCustomerContractId}
          hasInvoice={isNotNil(checkout?.invoice)}
          hasVerification
          resourceId={EntityResourceIds.serviceOrder}
          recordId={orderId}
          data-testid={suffixTestId('billingInfo', props)}
        />
        <CheckoutPayment
          invoice={checkout?.invoice}
          checkout={checkout}
          onSaveChanges={(data) => handleSaveChanges(data, true)}
          onIssuePayment={handleIssuePayment}
          issueButtonTooltip={issueButtonBlockers.length ? issueButtonBlockers : undefined}
          isIssueButtonDisabled={isNotNilOrEmpty(issueButtonBlockers)}
          isSubmitButtonDisabled={
            isNilOrEmpty(selectedCustomerId) || isNilOrEmpty(customerContractId)
          }
          customerId={selectedCustomerId}
          serviceCaseId={serviceCaseId}
          serviceOrderId={orderId}
          data-testid={suffixTestId('payment', props)}
        />
      </VStack>
    </DataStatus>
  );
}

const getChipLabelByType = (type: string | Nullish) =>
  match(type)
    .with('DEFAULT', always(i18n.t('general.labels.default')))
    .with('OTHER', always(i18n.t('entity.orderCheckout.labels.otherCustomer')))
    .with('OPERATOR', always(i18n.t('entity.ownership.label.vehicleOperator')))
    .with('USER', always(i18n.t('entity.ownership.label.vehicleUser')))
    .with('MANAGER', always(i18n.t('entity.ownership.label.vehicleManager')))
    .with('OWNER', always(i18n.t('entity.ownership.label.vehicleOwner')))
    .with('INVOICE', always(i18n.t('entity.ownership.label.defaultRecepient')))
    .otherwise(always(null));
