import {Attributes, Card, DataStatus} from 'platform/components';
import {Grid, Show, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  AccountingCustomerRequestBody,
  AccountingCustomerResponseBody,
  CustomFieldValue,
  EMPTY_PLACEHOLDER,
  featureFlags,
  GetInvoiceApiResponse,
  SupplierResponseBody,
  useAddress,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {accountingCustomerToAddressData} from '../utils/accountingCustomerToAddressData';
import {AffiliatedEntityCheckboxDetail} from './AffiliatedEntityCheckboxDetail';

interface CustomerAndSupplierDetailProps extends TestIdProps {
  customer: AccountingCustomerRequestBody | Nullish;
  additionalCustomer?: GetInvoiceApiResponse['additionalCustomer'] | Nullish;
  supplier: SupplierResponseBody | AccountingCustomerResponseBody;
  additionalFields?: CustomFieldValue[] | Nullish;
}

export function CustomerAndSupplierDetail(props: CustomerAndSupplierDetailProps) {
  const {composeAddressV1} = useAddress();

  return (
    <Grid columns={2}>
      {isNotNil(props.customer) ? (
        <Card variant="inlineGrey" title={i18n.t('module.customer.title')}>
          <VStack spacing={4}>
            <Card
              title={props.customer.name}
              parameters={[i18n.t('entity.accounting.labels.payer')]}
              variant="inlineWhite"
              data-testid={suffixTestId('customer-card', props)}
            >
              <VStack spacing={2}>
                <Attributes
                  size="third"
                  rows={[
                    {
                      label: i18n.t('entity.customer.labels.address'),
                      value:
                        composeAddressV1(accountingCustomerToAddressData(props.customer)) ||
                        EMPTY_PLACEHOLDER,
                    },
                    {
                      label: i18n.t('entity.invoice.labels.registrationNumber'),
                      value: props.customer.cin,
                    },
                    {
                      label: i18n.t('entity.invoice.labels.vatNumber'),
                      value: props.customer.tin,
                    },
                  ]}
                  data-testid={suffixTestId('customer-info', props)}
                />
                <AffiliatedEntityCheckboxDetail
                  customerId={props.customer.id}
                  additionalFields={props.additionalFields}
                />
              </VStack>
            </Card>

            <Show
              when={isNotNil(props.additionalCustomer)}
              whenFeatureEnabled={featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER}
            >
              <Card
                title={props.additionalCustomer?.name}
                parameters={[i18n.t(`entity.accounting.labels.${props.additionalCustomer?.type}`)]}
                variant="inlineWhite"
                data-testid={suffixTestId('additionalCustomer-card', props)}
              >
                <Attributes
                  size="third"
                  rows={[
                    {
                      label: i18n.t('entity.customer.labels.address'),
                      value:
                        composeAddressV1(
                          props.additionalCustomer
                            ? accountingCustomerToAddressData(props.additionalCustomer)
                            : null
                        ) || EMPTY_PLACEHOLDER,
                    },
                    {
                      label: i18n.t('entity.invoice.labels.registrationNumber'),
                      value: props.additionalCustomer?.cin,
                    },
                    {
                      label: i18n.t('entity.invoice.labels.vatNumber'),
                      value: props.additionalCustomer?.tin,
                    },
                  ]}
                />
              </Card>
            </Show>
          </VStack>
        </Card>
      ) : (
        <Card variant="inlineGrey">
          <DataStatus
            isEmpty
            minHeight={42}
            emptyMessage={i18n.t('entity.accounting.labels.noCustomerAssigned')}
          />
        </Card>
      )}
      <Card title={i18n.t('general.labels.supplier')} variant="inlineWhite">
        <Attributes
          size="third"
          rows={[
            {
              label: i18n.t('general.labels.name'),
              content: (
                <Text alternative size="xSmall">
                  {props.supplier.name}
                </Text>
              ),
            },
            {
              label: i18n.t('entity.customer.labels.address'),
              value:
                composeAddressV1(accountingCustomerToAddressData(props.supplier)) ||
                EMPTY_PLACEHOLDER,
            },
            {
              label: i18n.t('entity.invoice.labels.registrationNumber'),
              value: props.supplier.cin,
            },
            {
              label: i18n.t('entity.invoice.labels.vatNumber'),
              value: props.supplier.tin,
            },
          ]}
        />
      </Card>
    </Grid>
  );
}
