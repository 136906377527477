import {Card, useDialog} from 'platform/components';
import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  documentContextApi,
  Main,
  UploadDocumentsDialog,
  useGetDocumentDatagridActions,
} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

export function VehicleDocuments() {
  const {id: vehicleId} = useRequiredParams();

  const dispatch = useDispatch();

  const [actionCallback] = useGetDocumentDatagridActions(null);

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const onDocumentCreated = () => {
    refreshDataGrid();

    dispatch(
      documentContextApi.util.invalidateTags([{type: 'documentsCount', id: vehicleId ?? ''}])
    );
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {vehicleId}]),
    [vehicleId]
  );

  return (
    <Main>
      <Card
        title={i18n.t('page.vehicle.documents.title')}
        actions={[
          {
            variant: 'link',
            type: 'button',
            leftIcon: 'action/backup',
            onClick: openUploadDialog,
            title: i18n.t('entity.document.actions.uploadDocument'),
          },
        ]}
      >
        <Box minHeight={40}>
          <DataGrid
            ref={dataGridRef}
            gridCode="document-context-list-vehicle"
            emptyState={{
              headline: i18n.t('page.vehicle.notifications.noDocuments'),
              subheadline: i18n.t('page.vehicle.notifications.documentListWillAppearHere'),
            }}
            actionCallback={actionCallback}
            autoHeight
            queryModifier={queryModifier}
          />
        </Box>
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={onDocumentCreated}
        contextTarget="vehicle"
        contextId={vehicleId}
      />
    </Main>
  );
}
