import {showNotification} from 'platform/components';

import {useSearchParams} from 'react-router-dom';

import {businessCaseRoutes} from '@omnetic-dms/routes';
import {handleApiError, useCreateCheckoutCorrectiveTaxDocumentMutation} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {AccountingDocumentFormValues} from '../../../types';
import {getValidatedCheckoutSearchParams} from '../utils/getValidatedCheckoutSearchParams';
import {useGetCorrectiveTaxForBCDocumentBody} from './useGetCorrectiveTaxForBCDocumentBody';

export const useHandleSubmitCheckoutCorrectiveDocument = () => {
  const navigate = useNavigate();
  const [getRequestBody] = useGetCorrectiveTaxForBCDocumentBody();
  const [searchParams] = useSearchParams();

  const [createCorrectiveTaxDocument] = useCreateCheckoutCorrectiveTaxDocumentMutation();

  const submitCorrectiveTaxDocument = async (formData: AccountingDocumentFormValues) => {
    const {businessCaseId, type, ...checkoutParams} =
      getValidatedCheckoutSearchParams(searchParams);
    const requestBody = getRequestBody(formData);

    await createCorrectiveTaxDocument({
      ...checkoutParams,
      type,
      requestBody,
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(() =>
        navigate(
          composePath(businessCaseRoutes.checkout, {
            params: {id: businessCaseId},
            queryParams: {
              CheckoutOrder: 'payment',
              createdCorrectiveDocumentType: type,
            },
          })
        )
      )
      .catch(handleApiError);
  };

  return [submitCorrectiveTaxDocument] as const;
};
