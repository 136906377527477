import {openConfirmDialog, showNotification} from 'platform/components';

import {isEmpty, isNil, isNotNil} from 'ramda';

import {
  useLazyGetSalesPlatformsQuery,
  useUpdateVehicleOnAllPlatformsMutation,
  useUpdateVehicleOnPlatformMutation,
} from '../api/salesApi';
import {useLazyGetSaleVehicleQuery} from '../api/saleVehicleApi';
import i18n from '../i18n/i18n';
import {UpdateVehicleOnPlatformApiArg} from '../types/api/sales';
import {handleApiError} from '../utils/handleApiError';

export function useAdPlatformsVehicleUpdateDialog() {
  const [getSalesPlatforms] = useLazyGetSalesPlatformsQuery();
  const [getSaleVehicle] = useLazyGetSaleVehicleQuery();
  const [updateVehicleOnAllPlatforms] = useUpdateVehicleOnAllPlatformsMutation();
  const [updateVehicleOnSinglePlatform] = useUpdateVehicleOnPlatformMutation();

  async function update(
    vehicleId: string,
    platformCode?: UpdateVehicleOnPlatformApiArg['platformCode']
  ): Promise<void> {
    const saleVehicle = await getSaleVehicle({vehicleId});

    if (isNotNil(saleVehicle.error)) {
      return;
    }

    const hasAdvertisements = await getSalesPlatforms({
      vehicleId,
    })
      .unwrap()
      .then((advertisements) =>
        advertisements.some((ad) => ad.enabled && ad.status === 'published')
      )
      .catch(handleApiError);

    if (!hasAdvertisements) {
      return;
    }

    openConfirmDialog({
      text: i18n.t('entity.vehicle.labels.infoChangedUpdateClassifiedsQuestion'),
      onConfirm: () => {
        if (isNil(platformCode) || isEmpty(platformCode)) {
          return updateVehicleOnAllPlatforms({
            vehicleId,
          })
            .unwrap()
            .then(() =>
              showNotification.success(i18n.t('entity.vehicle.labels.classifiedsWillBeUpdated'))
            )
            .catch(handleApiError);
        }

        return updateVehicleOnSinglePlatform({
          vehicleId,
          platformCode,
        })
          .unwrap()
          .then(() =>
            showNotification.success(i18n.t('entity.vehicle.labels.classifiedsWillBeUpdated'))
          )
          .catch(handleApiError);
      },
    });
  }

  return [update];
}
