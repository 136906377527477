import {Alert, Card} from 'platform/components';
import {Hide, Show, Space, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useAddCheckoutOrderPurchasePaymentMutation,
  useGetOrderQuery,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {useGetShowPaymentForm} from '../../hooks/useGetShowPaymentForm';
import {getPaymentsByDiscriminator} from '../../utils/getPaymentsByDiscriminator';
import {IssuePaymentForm} from '../PaymentForm/IssuePaymentForm';
import {PaymentDetail} from './components/PaymentDetail';
import {PaymentTitle} from './components/PaymentTitle';
import {getCanAddAnotherPayment} from './utils/getCanAddAnotherPayment';

interface FinalPaymentListProps {
  checkoutId: string;
  orderId: string;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
  isBrokerageSale?: boolean;
}

export const PaymentList = (props: FinalPaymentListProps) => {
  const [addCheckoutOrderPayment] = useAddCheckoutOrderPurchasePaymentMutation();

  const {data: order} = useGetOrderQuery({
    checkoutId: props.checkoutId,
    orderId: props.orderId,
  });

  const [getShowPaymentForm] = useGetShowPaymentForm({
    checkoutId: props.checkoutId,
    order,
  });

  const {balanceAndPurchases: payments, deposits} = getPaymentsByDiscriminator(order);
  const hasUnpaidDeposits = deposits.some((deposit) => deposit.paymentState !== 'PAID');
  const canAddAnotherPayment = getCanAddAnotherPayment(order);

  const handleAddAnotherPayment = () =>
    addCheckoutOrderPayment({
      checkoutId: props.checkoutId,
      orderId: props.orderId,
    })
      .unwrap()
      .catch(handleApiError);

  const paymentProps = {
    order: order!,
    checkoutId: props.checkoutId,
    refreshBusinessCaseCheckoutInfo: props.refreshBusinessCaseCheckoutInfo,
    handleAddAnotherPayment,
  };

  return (
    <VStack spacing={4}>
      <PaymentTitle order={order} />
      {payments.map((payment, index) => {
        const shouldShowPaymentForm = getShowPaymentForm(payment.paymentState);

        return (
          <Card variant="inlineGrey" key={payment.id}>
            <Show when={payment.paymentState === 'PAID'}>
              <Alert
                message={i18n.t('entity.checkout.labels.allPaymentsPaid')}
                variant="success"
                data-testid={testIds.businessCase.checkout(`allPaymentsPaid-${index}`)}
              />
              <Space vertical={4} />
            </Show>
            <Show when={shouldShowPaymentForm}>
              <Show when={hasUnpaidDeposits}>
                <Alert
                  variant="warning"
                  title={i18n.t('general.notifications.paymentNotPossibleBeforeDepositsPaid.title')}
                  data-testid={testIds.businessCase.checkout(
                    `paymentNotPossibleBeforeDepositsPaid-${index}`
                  )}
                />
                <Space vertical={4} />
              </Show>

              <IssuePaymentForm
                {...paymentProps}
                payment={payment}
                isBrokerageSale={props.isBrokerageSale}
                data-testid={testIds.businessCase.checkout(
                  `issuePaymentForm-${payment.paymentDiscriminator}-${index}`
                )}
                canAddAnotherPayment={index === payments.length - 1 ? canAddAnotherPayment : false}
              />
            </Show>
            <Hide when={shouldShowPaymentForm}>
              <PaymentDetail
                {...paymentProps}
                payment={payment}
                data-testid={testIds.businessCase.checkout(
                  `paymentDetail-${payment.paymentDiscriminator}-${index}`
                )}
                canAddAnotherPayment={index === payments.length - 1 ? canAddAnotherPayment : false}
              />
            </Hide>
          </Card>
        );
      })}
    </VStack>
  );
};
