import {
  Alert,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
  closeCurrentDialog,
  ButtonGroup,
  Button,
  FormButton,
} from 'platform/components';
import {GridItem, Grid, Space} from 'platform/foundation';
import {object} from 'yup';

import i18n from '@omnetic-dms/i18n';
import {
  MoveAllMatricesFromCarisApiArg,
  handleApiError,
  useMoveAllMatricesFromCarisMutation,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

export function ConvertFromCebiaForm(props: RequiredTestIdProps) {
  const [moveFromCaris, {isLoading}] = useMoveAllMatricesFromCarisMutation();

  const handleSubmit: FormSubmitHandler<MoveAllMatricesFromCarisApiArg> = async (data) =>
    await moveFromCaris(data)
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);

  return (
    <Form<MoveAllMatricesFromCarisApiArg> onSubmit={handleSubmit} schema={schema}>
      {(control) => (
        <>
          <Grid columns={2}>
            <GridItem span={2}>
              <Alert
                message={i18n.t('page.accountingSettings.labels.moveFromCarisAlertMessage')}
                variant="warning"
                data-testid={suffixTestId('moveFromCarisAlertMessage', props)}
              />
            </GridItem>

            <FormField
              type="text"
              control={control}
              name="googleDocId"
              isRequired
              label={i18n.t('page.accountingSettings.labels.googleDocId')}
              data-testid={suffixTestId('googleDocId', props)}
            />

            <FormField
              type="text"
              control={control}
              name="sheetName"
              isRequired
              label={i18n.t('page.accountingSettings.labels.sheetName')}
              data-testid={suffixTestId('sheetName', props)}
            />

            <FormField
              type="apiDate"
              control={control}
              name="validFrom"
              isRequired
              label={i18n.t('page.accountingSettings.labels.validFrom')}
              data-testid={suffixTestId('validFrom', props)}
            />

            <FormField
              type="apiDate"
              control={control}
              name="validTo"
              isRequired
              label={i18n.t('page.accountingSettings.labels.validTo')}
              data-testid={suffixTestId('validTo', props)}
            />
          </Grid>

          <Space vertical={4} />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              onClick={closeCurrentDialog}
              variant="secondary"
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              title={i18n.t('general.actions.convert')}
              control={control}
              type="submit"
              isLoading={isLoading}
              data-testid={suffixTestId('convert', props)}
            />
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}

const schema = object({
  googleDocId: yupString.required(),
  sheetName: yupString.required(),
  validTo: yupString.required(),
  validFrom: yupString.required(),
});
