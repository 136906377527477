import {Mutator} from 'final-form';
import {Card, DataStatus, Separator} from 'platform/components';
import {Hide, Right, Show} from 'platform/foundation';

import {FC, PropsWithChildren, ReactNode, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {isEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {carAuditApi, featureFlags, usePermissions, vehicleApi} from '@omnetic-dms/shared';

import {TestIdProps, appendSuffix, isFeatureEnabled, suffixTestId} from 'shared';

import {useRouter} from '../../../hooks/useRouter';
import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {updateAudit} from '../../../store/carAudit/actions';
import {updateAuditFields} from '../../../store/carAudit/reducer';
import {AuditFields} from '../../../store/carAudit/reducerUtils';
import {
  selectActiveAuditId,
  selectAudit,
  selectAuditFields,
  selectCarAudit,
  selectNavigationCategories,
  selectNavigationCategoriesStateParams,
  selectStructure,
} from '../../../store/carAudit/selectors';
import {AuditForm, TConditionForm, TConditionFormRenderProps} from '../../../types/ConditionTypes';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {getUpdateRequestBodyFromFormData} from '../../../utils/getUpdateRequestBodyFromFormData';
import {noop} from '../../../utils/someTeasUtils';
import {AutoSave} from '../../FinalForm/components/AutoSave';
import {Form} from '../../FinalForm/Form';
import {FormApi} from '../../FinalForm/types/FormApi';
import {useVehicleCreateContext} from '../../VehicleCreateContext/hooks/useVehicleCreateContext';
import {NavigationState} from '../types/NavigationState';
import {getCategoryKeyFromUri} from '../utils/getCategoryKeyFromUri';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getFormSchema} from '../utils/getFormSchema';
import {getInitialFormValues} from '../utils/getInitialFormValues';
import {getRequiredFieldsFromCategories} from '../utils/getRequiredFieldsFromCategories';
import {AuditContext} from './AuditContext';
import {AuditHeader} from './AuditHeader';
import {AuditTabs} from './AuditTabs';
import {FinishInspection} from './FinishInspection';
import {GeneralComment} from './GeneralComment';
import {ToFinishingComment} from './ToFinishingComment';

export const Audit: FC<AuditProps> = (props) => {
  const {header, createVehicleFeatures, isCreate, ...rest} = props;

  const dispatch = useThunkDispatch();
  const auditStructure = useSelector(selectStructure);
  const auditId = useSelector(selectActiveAuditId);
  const audit = useSelector(selectAudit);
  const auditFields = useSelector(selectAuditFields);
  const isEditForToRevisionDisabled = isFeatureEnabled(featureFlags.INSPECTION_TO_REVISION_NO_EDIT);
  const isInNotEditableState = audit?.state === 'to_revision' && isEditForToRevisionDisabled;
  const navigationCategories = useSelector(selectNavigationCategories);
  const navigationCategoriesStateParams = useSelector(selectNavigationCategoriesStateParams);
  const router = useRouter();

  const [permissionToCreateInspection, permissionToUpdateInspection] = usePermissions({
    permissionKeys: ['createInspection', 'updateInspection'],
    scopes: {
      updateInspection: {inspectionType: audit?.inspectionType},
    },
  });

  const {inspectionTab, conditionTab} = router.params;
  const activeTabUri = conditionTab ?? inspectionTab;

  const {setConditionForm} = useVehicleCreateContext();

  let isAutoSaveDisabled = false;

  const setIsAutoSaveDisabled = (_isAutoSaveDisabled: boolean) => {
    isAutoSaveDisabled = _isAutoSaveDisabled;
  };

  const requiredFormFields = useMemo(
    () => getRequiredFieldsFromCategories(auditStructure?.categories),
    [auditStructure]
  );

  const schema = useMemo(
    () => getFormSchema(Object.keys(requiredFormFields.fields)),
    [requiredFormFields]
  );

  const initialValues = useMemo(
    () => getInitialFormValues(audit?.fields, navigationCategoriesStateParams),
    [navigationCategoriesStateParams]
  );

  const getCurrentMainCategory = () => {
    if (!activeTabUri) {
      return navigationCategories?.[0];
    }

    return navigationCategories?.find(
      (category) => category.uniqueKey === getCategoryKeyFromUri(String(activeTabUri))
    );
  };

  const getChangedCategoryStateFieldValue = (): AuditFields => {
    const changedCategory = getCurrentMainCategory();

    if (changedCategory) {
      const fieldName = getFormFieldName(
        changedCategory.id,
        navigationCategoriesStateParams[changedCategory.id]
      );
      if (auditFields?.[fieldName] !== NavigationState.notFinished) {
        return {[fieldName]: NavigationState.notFinished};
      }
    }

    return {};
  };

  const updateFields = async (formFields: AuditFields): Promise<void> => {
    await dispatch(updateAuditFields(formFields));

    if (!isCreate) {
      await dispatch(
        updateAudit.action({
          auditId: auditId || '',
          requestBody: getUpdateRequestBodyFromFormData(formFields),
        })
      );
      dispatch(vehicleApi.util.invalidateTags([{type: 'Vehicle', id: props.vehicleId ?? ''}]));
      dispatch(carAuditApi.util.invalidateTags([{type: 'AuditData', id: props.vehicleId ?? ''}]));
    }
  };

  const isDisabledAuditEdit = (): boolean => {
    const isDisabledForUser =
      audit?.inspectionType === LoadAuditDataResponseItemBody.inspectionType.VALIDATION_INSPECTION
        ? !permissionToCreateInspection ||
          (audit?.finishedAt !== null && !permissionToUpdateInspection)
        : !permissionToUpdateInspection;

    return isDisabledForUser;
  };

  const handleSave = async (
    values: AuditFields,
    form: FormApi<AuditForm, Partial<AuditForm>>
  ): Promise<void> => {
    if (
      isAutoSaveDisabled ||
      form.getState().pristine ||
      isDisabledAuditEdit() ||
      !auditId ||
      isEmpty(values)
    ) {
      return;
    }

    const formFields = {
      ...values,
      ...getChangedCategoryStateFieldValue(),
    };

    await updateFields(formFields);
  };

  const AuditBody = (
    <>
      <AuditHeader
        isInspection={props.isInspection}
        customHeaderContent={props.customHeaderContent}
        data-testid={suffixTestId('audit-header', rest)}
        isInNotEditableState={isInNotEditableState}
      />
      <Separator />
      <ToFinishingComment />
      <GeneralComment />

      <AuditTabs
        data-testid={suffixTestId('audit-tabs', rest)}
        onTabChange={props.onTabChange}
        activeTab={props.activeTab}
        createVehicleFeatures={createVehicleFeatures}
        isInspection={props.isInspection}
      />
      <Show when={isNotNil(props.isCompletionDisabled)}>
        <Right>
          <FinishInspection
            buttonTitle={i18n.t('general.actions.complete')}
            showWhenFinished
            isDisabled={props.isCompletionDisabled}
          />
        </Right>
      </Show>
    </>
  );

  return (
    <AuditWrapper {...props} data-testid={suffixTestId('audit', rest)}>
      <Form<TConditionForm>
        formId={appendSuffix('auditForm', props['data-testid'])}
        initialValues={initialValues}
        isFormSaverDisabled
        onSubmit={noop}
        mutators={
          {
            setFieldValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          } as Record<string, Mutator<TConditionForm>>
        }
        validateAfterSubmit
        schema={schema}
        render={(formRenderProps: TConditionFormRenderProps) => {
          setConditionForm?.(formRenderProps.form);

          return (
            <AuditContext
              {...{
                formRenderProps,
                requiredFormFields,
                isCreateVehicle: Boolean(props.isCreate),
                setIsAutoSaveDisabled,
                getChangedCategoryStateFieldValue,
                updateFields,
                isInNotEditableState,
              }}
            >
              <AutoSave
                save={handleSave}
                saveOnInactiveBlur
                debouncedTime={700}
                partial
                saveOnChange
              />
              {Boolean(header) && header}
              <Hide when={props.isBodyOnly}>
                <Card data-testid={suffixTestId('audit-card', rest)}>{AuditBody}</Card>
              </Hide>
              <Show when={props.isBodyOnly}>{AuditBody}</Show>
            </AuditContext>
          );
        }}
      />
    </AuditWrapper>
  );
};

type AuditBaseProps = {
  isCreate?: boolean;
  notFoundText?: string;
  createVehicleFeatures?: string[];
} & TestIdProps;

const AuditWrapper: FC<PropsWithChildren<AuditBaseProps>> = ({
  isCreate,
  notFoundText,
  children,
}) => {
  const {loading} = useSelector(selectCarAudit);
  const auditId = useSelector(selectActiveAuditId);

  if (isCreate) {
    return <DataStatus isLoading={loading.getNewestAuditStructure}>{children}</DataStatus>;
  }

  return (
    <DataStatus
      isLoading={loading.getAudit || loading.getCondition || loading.getAuditStructure}
      isEmpty={!auditId}
      emptyMessage={notFoundText}
    >
      {children}
    </DataStatus>
  );
};

type AuditProps = AuditBaseProps &
  TestIdProps & {
    onTabChange?: (id: string) => void;
    activeTab?: string;
    isInspection?: boolean;
    isBodyOnly?: boolean;
    isCompletionDisabled?: boolean;
    customHeaderContent?: ReactNode;
    header?: ReactNode;
    vehicleId?: string;
  };
