/* eslint-disable filename-export/match-named-export */
import {
  Action,
  Actions,
  Card,
  DataStatus,
  Form,
  FormField,
  openConfirmDialog,
  openDialog,
  OptionType,
} from 'platform/components';
import {Grid, Heading, Hide, HStack, Icon, Right, Show, VStack} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';
import styled from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {FC, ReactNode, useState} from 'react';
import {useSelector} from 'react-redux';

import {defaultTo, F, reject, T} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  CurrencyResponseBody,
  DEFAULT_CURRENCY,
  DEFAULT_FOREIGN_CURRENCY,
  handleApiError,
  OrderDiscriminator,
  selectTenant,
  useGetBusinessCaseQuery,
  useGetCheckoutQuery,
  useGetCurrenciesQuery,
  useGetOrderQuery,
  useGetSaleVehicleQuery,
  useMergeCheckoutOrderMutation,
  usePatchCheckoutOrderForeignCurrencyPaymentMutation,
} from '@omnetic-dms/shared';
import {
  PriceBox,
  SimpleTable,
  CellType,
  OrderDiscriminatorEnum,
  PaymentStateEnum,
  TypeOfSaleEnum,
  OrderSplitDialog,
} from '@omnetic-dms/teas';

import {buildArray, suffixTestId, TestIdProps, useQueryState, useRequiredParams} from 'shared';

import {CorrectiveTaxDocumentPaymentList} from '../../CorrectiveTaxDocumentPaymentList';
import {DepositPaymentList} from '../../DepositPaymentList/DepositPaymentList';
import {FleetInsurance} from '../../FleetInsurance';
import {PaymentList} from '../../PaymentList/PaymentList';
import {CheckoutOrderPaymentsProps} from '../types/CheckoutOrderPaymentsProps';
import {CheckoutOrderAdvanceDepositWarning} from './CheckoutOrderAdvanceDepositWarning';
import {useProformaCreationSettings} from './hooks/useProformaCreationSettings';

const NameColumnCell = styled.div`
  font-weight: bold;
`;
const ColumnHeader = styled.div`
  text-align: right;
  padding-right: 8px;
`;
const PriceColumnHeader = styled.div`
  text-align: right;
`;
const PriceColumnCell = styled.div`
  text-align: right;
  padding-right: 8px;
`;

type OrderRowType = {
  name: string;
  priceWoVAT: string;
  VAT: string;
  price: string;
};
type OrderRowExtendedType = OrderRowType & {
  [key: string]: string;
};

const OrdersSimpleTableHeader = (headerText: string): ReactNode => (
  <ColumnHeader>{headerText}</ColumnHeader>
);

const OrderPricesSimpleTableHeader = (headerText: string): ReactNode => (
  <PriceColumnHeader>{headerText}</PriceColumnHeader>
);

const OrdersSimpleTableNameCell: FC<CellType<OrderRowExtendedType>> = ({row, column}) => {
  const cellValue: string = row?.[column.id];
  return <NameColumnCell>{cellValue}</NameColumnCell>;
};

const OrdersSimpleTablePriceCell: FC<CellType<OrderRowExtendedType>> = ({row, column}) => {
  const cellValue: string = row?.[column.id];
  return (
    <PriceColumnCell css={column.id === 'price' ? `font-weight: bold;` : ''}>
      {cellValue}
    </PriceColumnCell>
  );
};

interface ForeignCurrencyFormValues {
  currency: string | null;
  exchangeRateRatioAmount: string | null;
}

export const CheckoutOrderPayment: FC<CheckoutOrderPaymentsProps & TestIdProps> = ({
  order,
  allOrders,
  isBrokeredSale,
  isFleetInsuranceDisabled,
  ...props
}) => {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();
  const {id: businessCaseId} = useRequiredParams();

  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const vehicleId = businessCase?.offers?.[0].purchaseVehicles?.[0]?.vehicleId;

  const {data: saleVehicle} = useGetSaleVehicleQuery(
    {
      vehicleId: vehicleId ?? '',
    },
    {skip: isNilOrEmpty(vehicleId)}
  );
  const isProformaGenerationEnabledInSettings = useProformaCreationSettings();
  const {data: checkoutInfo} = useGetCheckoutQuery({checkoutId: props.checkoutId});
  const [_, setActiveOrderTabId] = useQueryState('order-tabs');

  const {
    data: rtkq_order,
    refetch: refetchOrder,
    isLoading: isLoadingOrder,
  } = useGetOrderQuery({
    checkoutId: props.checkoutId,
    orderId: order.id,
  });

  const [foreignCurrency, setForeignCurrency] = useState<string | null>(
    order.currency ?? DEFAULT_FOREIGN_CURRENCY
  );

  const {data: tenant} = useSelector(selectTenant);
  const {
    data: currencies,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useGetCurrenciesQuery();
  const [mergeCheckoutOrder] = useMergeCheckoutOrderMutation();
  const [patchOrderForeignCurrency, {isLoading: isPatchingForeignCurrency}] =
    usePatchCheckoutOrderForeignCurrencyPaymentMutation({
      fixedCacheKey: 'foreignCurrencyPayment',
    });

  const isDepositNotToggleable =
    rtkq_order?.payments.some((payment) => payment.paymentState !== PaymentStateEnum.CONCEPT) ||
    order.payments.some((payment) => payment.paymentState !== PaymentStateEnum.CONCEPT);

  const columns = [
    {
      id: 'name',
      Header: i18n.t('entity.checkout.labels.itemName'),
      width: '44%',
      Cell: OrdersSimpleTableNameCell,
    },
    {
      id: 'priceWoVAT',
      Header: OrdersSimpleTableHeader(i18n.t('entity.checkout.labels.itemPriceWoVAT')),
      Cell: OrdersSimpleTablePriceCell,
      width: '20.5%',
    },
    {
      id: 'VAT',
      Header: OrdersSimpleTableHeader(i18n.t('entity.accounting.labels.vat')),
      Cell: OrdersSimpleTablePriceCell,
      width: '15%',
    },
    {
      id: 'price',
      Header: OrderPricesSimpleTableHeader(i18n.t('entity.checkout.labels.itemPrice')),
      Cell: OrdersSimpleTablePriceCell,
      width: '20.5%',
    },
  ];

  const rows = [
    {
      data: order.items.map((item) => ({
        name: item.name,
        priceWoVAT:
          item.deductible && item.price.withoutVat
            ? formatCurrency(
                Number(item.price.withoutVat.amount),
                item.price.withoutVat.currency,
                2
              ) ?? '–'
            : '–',
        VAT: item.deductible ? `${formatNumber(Number(Number(item.price.vatRate)), 0)}%` : '–',
        price:
          formatCurrency(Number(item.price.withVat.amount), item.price.withVat.currency, 2) ?? '',
      })),
    },
  ];

  const handleUpdateForeignCurrencyPayment = (args: {
    isForeignCurrencyEnabled?: boolean;
    currency?: string | null;
  }) => {
    if (isDepositNotToggleable) {
      return;
    }

    const foreignCurrencyPayment =
      args.isForeignCurrencyEnabled ?? rtkq_order?.foreignCurrencyPayment ?? false;

    patchOrderForeignCurrency({
      checkoutId: props.checkoutId,
      orderId: order.id,
      orderForeignCurrencyPaymentRequestBody: {
        foreignCurrencyPayment,
        currency: foreignCurrencyPayment ? args.currency ?? foreignCurrency ?? null : null,
        exchangeRateRatioAmount: 1,
      },
    })
      .unwrap()
      .then(refetchOrder)
      .then(() => props.refreshBusinessCaseCheckoutInfo(null))
      .catch(handleApiError);
  };

  const onForeignCurrencyChange = (value: string | number | string[] | null) => {
    setForeignCurrency(String(value));
    handleUpdateForeignCurrencyPayment({currency: String(value)});
  };

  const hasSomeOrderPaymentCreated = allOrders.some((order) =>
    order.payments.some((payment) => payment.paymentState !== PaymentStateEnum.CONCEPT)
  );

  const isFleetInsuranceAvailable =
    isNotNil(props.purchaseVehicleId) &&
    order.orderDiscriminator === OrderDiscriminatorEnum.PURCHASE;

  const currencyOptions: OptionType[] =
    reject(
      (currency: CurrencyResponseBody) => currency.code === tenant?.currency ?? DEFAULT_CURRENCY,
      currencies ?? []
    ).map((currency) => ({
      label: currency.code,
      value: currency.code,
    })) ?? [];

  /**
   * ID of second ("other") order to merge to
   * needed only in case there are exactly 2 orders in checkout
   */
  const otherOrderId = allOrders.find((item) => item.id !== order.id)?.id;

  const orderActions = buildArray<Action>()
    .when(allOrders.length === 2, {
      type: 'button',
      variant: 'secondary',
      title: i18n.t('entity.checkout.actions.mergeOrder'),
      leftIcon: 'communication/call_merge',
      onClick: () => handleMergeCheckoutOrder(otherOrderId),
    })
    .when(allOrders.length > 2, {
      type: 'dropdown-button',
      variant: 'secondary',
      title: i18n.t('entity.checkout.actions.mergeOrder'),
      leftIcon: 'communication/call_merge',
      menuItems: allOrders.map((iteratedOrder, index) => ({
        label: i18n.t('entity.checkout.actions.mergeOrderWith', {
          order: `${i18n.t('entity.checkout.labels.order')} ${index + 1}`,
        }),
        onClick: () => handleMergeCheckoutOrder(iteratedOrder.id),
        isDisabled: iteratedOrder.id === order.id,
      })),
    })
    .when(order.items.length > 1, {
      type: 'button',
      variant: 'secondary',
      title: i18n.t('entity.checkout.actions.splitOrder'),
      leftIcon: 'communication/call_split',
      onClick: () =>
        openDialog(
          <OrderSplitDialog
            data-testid={suffixTestId('split-dialog', props)}
            checkoutId={props.checkoutId}
            orderId={order.id}
            items={order.items}
            onComplete={props.onCheckoutInfoChange}
          />,
          {
            title: i18n.t('entity.checkout.actions.splitOrder'),
          }
        ),
    });

  const handleMergeCheckoutOrder = (targetOrderId?: string) => {
    if (!targetOrderId) {
      return;
    }

    openConfirmDialog({
      text: i18n.t('entity.checkout.labels.mergeOrdersConfirm'),
      onConfirm: () =>
        mergeCheckoutOrder({
          checkoutId: props.checkoutId,
          orderId: order.id,
          targetOrderId,
        })
          .unwrap()
          .then((data) => {
            props.onCheckoutInfoChange?.();
            const firstOrderId = data.orders[0]?.id;

            if (isNotNil(firstOrderId)) {
              setActiveOrderTabId(firstOrderId);
            }
          })
          .catch(handleApiError),
    });
  };

  const isBusinessCaseClosed = businessCase?.businessCaseState === 'CLOSED';
  const foreignCurrencyDefaultValues: Partial<ForeignCurrencyFormValues> = {
    currency:
      saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode ?? DEFAULT_FOREIGN_CURRENCY,
    exchangeRateRatioAmount: String(order.exchangeRateRatioAmount ?? 1),
  };

  return (
    <VStack spacing={4}>
      <CheckoutOrderAdvanceDepositWarning saleVehicle={props.saleVehicle} />
      <Card variant="inlineWhite">
        <PriceBox
          data-testid={suffixTestId('totalPrice', props)}
          price={
            order.typeOfSale === TypeOfSaleEnum.INLAND
              ? order.totalPrice.withVat
              : order.totalPrice.withoutVat
          }
          priceSub={order.items[0].deductible ? order.totalPrice.withoutVat : undefined}
          title={i18n.t('entity.checkout.labels.total')}
          showDecimals
        />
      </Card>
      <VStack spacing={4}>
        <HStack spacing={2} align="center">
          <Icon value="action/list" color="general.accent" />
          <Heading size={4} data-testid={suffixTestId('paymentItems', props)}>
            {i18n.t('entity.checkout.labels.items')}
          </Heading>
        </HStack>
        <SimpleTable
          data-testid={suffixTestId('paymentItems', props)}
          noZebra
          columns={columns}
          rows={rows}
          showRowDivider
          tdPadding="default"
        />
        <Show
          when={
            order.items.every((item) => item.itemContextId) &&
            !hasSomeOrderPaymentCreated &&
            !isBusinessCaseClosed
          }
        >
          <Right>
            <Actions actions={orderActions} data-testid={suffixTestId('orderActions', props)} />
          </Right>
        </Show>
      </VStack>

      <Hide when={isBusinessCaseClosed}>
        <DataStatus
          isEmpty={isNilOrEmpty(currencyOptions)}
          isLoading={isCurrenciesLoading}
          isError={isCurrenciesError}
        >
          <Card
            title={i18n.t('entity.checkout.foreignCurrency.title')}
            variant="inlineGrey"
            control={{
              type: 'switch',
              isDisabled: isDepositNotToggleable || isLoadingOrder || isPatchingForeignCurrency,
              value: rtkq_order?.foreignCurrencyPayment ?? false,
              onChange: (value) => {
                handleUpdateForeignCurrencyPayment({isForeignCurrencyEnabled: value});
              },
            }}
            isExpanded={rtkq_order?.foreignCurrencyPayment ?? false}
            isExpandable
          >
            <Form<ForeignCurrencyFormValues> defaultValues={foreignCurrencyDefaultValues}>
              {(control) => (
                <Grid columns={4}>
                  <FormField
                    name="currency"
                    label={i18n.t('general.labels.currency')}
                    type="choice"
                    options={currencyOptions}
                    onChange={onForeignCurrencyChange}
                    isDisabled={isDepositNotToggleable}
                    isNotClearable
                    control={control}
                    data-testid={suffixTestId('currency', props)}
                  />
                  <FormField
                    name="exchangeRateRatioAmount"
                    label={i18n.t('general.labels.amount')}
                    type="text"
                    isDisabled
                    control={control}
                    data-testid={suffixTestId('exchangeRateRatioAmount', props)}
                  />
                </Grid>
              )}
            </Form>
          </Card>
        </DataStatus>
      </Hide>

      <Show
        when={match<[boolean | undefined, boolean, OrderDiscriminator, boolean], boolean>([
          props.readonly,
          order.depositAllowed,
          order.orderDiscriminator,
          isProformaGenerationEnabledInSettings,
        ])
          .with(
            [false, Pattern.boolean, OrderDiscriminatorEnum.SALE, true],
            [false, Pattern.boolean, 'PURCHASE_BROKERAGE_FEES', true],
            [Pattern.boolean, true, OrderDiscriminatorEnum.SALE, true],
            [Pattern.boolean, true, 'PURCHASE_BROKERAGE_FEES', true],
            T
          )
          .otherwise(F)}
      >
        <DepositPaymentList
          checkoutId={props.checkoutId}
          refreshBusinessCaseCheckoutInfo={props.refreshBusinessCaseCheckoutInfo}
          orderId={order.id}
          data-testid={suffixTestId('depositPayment', props)}
        />
      </Show>
      <VStack spacing={4}>
        <PaymentList
          checkoutId={props.checkoutId}
          orderId={order.id}
          refreshBusinessCaseCheckoutInfo={props.refreshBusinessCaseCheckoutInfo}
          data-testid={suffixTestId('depositPayment', props)}
          isBrokerageSale={isBrokeredSale}
        />

        <CorrectiveTaxDocumentPaymentList
          orderId={order.id}
          taxDocument={checkoutInfo?.taxDocuments}
        />

        <Show when={isFleetInsuranceAvailable}>
          <FleetInsurance
            data-testid="checkoutOrderPayments-fleetInsurance"
            isDisabled={isFleetInsuranceDisabled}
            vehicleId={defaultTo('', props.purchaseVehicleId)}
          />
        </Show>
      </VStack>
    </VStack>
  );
};
