import {Alpha3CountryCode, PhoneInput, PhoneNumber, showNotification} from 'platform/components';

import {FC} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  usePatchCurrentUserMutation,
  PatchCurrentUserApiArg,
  selectTenant,
} from '@omnetic-dms/shared';
import {selectPhoneNumbers, getStringErrorMessage} from '@omnetic-dms/teas';

export type Props = {
  fieldName: string;
  value: PhoneNumber;
};

export const PhoneField: FC<Props> = ({fieldName, value}) => {
  const phoneList = useSelector(selectPhoneNumbers);
  const [patchUser] = usePatchCurrentUserMutation();

  const {data: {country: tenantCountry} = {}} = useSelector(selectTenant);

  const {control, handleSubmit} = useForm({
    mode: 'onBlur',
    defaultValues: {
      [fieldName]: value || {
        prefix: '',
        number: '',
        countryCode: tenantCountry as Alpha3CountryCode,
      },
    },
  });

  const dispatcher = async (values: {[fieldName: string]: PhoneNumber}): Promise<void> => {
    if (value === values[fieldName]) {
      return;
    }

    try {
      const arg = {
        updateCurrentUserRequestBody: {
          [fieldName]: values[fieldName],
        },
      } as PatchCurrentUserApiArg;

      const res = await patchUser(arg).unwrap();

      if (res && res.id) {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      }
    } catch (error: any) {
      showNotification.error(getStringErrorMessage(error));
    }
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({field: {...rest}}) => (
        <PhoneInput
          {...rest}
          data-testid={testIds.myProfile.personalInformation(fieldName)}
          countries={phoneList.map((phone) => ({
            countryCode: phone.code as Alpha3CountryCode,
            dialCode: phone.prefix,
            name: phone.name,
          }))}
          preferredCountries={phoneList
            .filter((phone) => phone.preferred)
            .map((phone) => phone.code as Alpha3CountryCode)}
          onBlur={handleSubmit(dispatcher)}
        />
      )}
    />
  );
};
