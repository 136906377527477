import {
  CancelPaymentPrescriptionApiResponse,
  CancelPaymentPrescriptionApiArg,
  ConfirmPaymentPrescriptionApiResponse,
  ConfirmPaymentPrescriptionApiArg,
  CreateBankTransferTransactionApiResponse,
  CreateBankTransferTransactionApiArg,
  CreateCashTransactionApiResponse,
  CreateCashTransactionApiArg,
  CreatePaymentPrescriptionApiResponse,
  CreatePaymentPrescriptionApiArg,
  PairTransactionToPaymentPrescriptionApiResponse,
  PairTransactionToPaymentPrescriptionApiArg,
  ShowPaymentPrescriptionApiResponse,
  ShowPaymentPrescriptionApiArg,
  UpdatePaymentPrescriptionApiResponse,
  UpdatePaymentPrescriptionApiArg,
  UnpairTransactionFromPaymentPrescriptionApiResponse,
  UnpairTransactionFromPaymentPrescriptionApiArg,
} from '../types/api/payment';
import {omneticApi} from './baseApi/omneticApi';

const paymentApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    cancelPaymentPrescription: build.mutation<
      CancelPaymentPrescriptionApiResponse,
      CancelPaymentPrescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/${queryArg.paymentPrescriptionId}/cancel`,
        method: 'POST',
      }),
    }),
    confirmPaymentPrescription: build.mutation<
      ConfirmPaymentPrescriptionApiResponse,
      ConfirmPaymentPrescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/${queryArg.paymentPrescriptionId}/confirm`,
        method: 'POST',
      }),
    }),
    createBankTransferTransaction: build.mutation<
      CreateBankTransferTransactionApiResponse,
      CreateBankTransferTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/bank-transfer-transaction`,
        method: 'POST',
        body: queryArg.createBankTransferTransactionRequestBody,
      }),
    }),
    createCashTransaction: build.mutation<
      CreateCashTransactionApiResponse,
      CreateCashTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/cash-transaction`,
        method: 'POST',
        body: queryArg.createCashTransactionRequestBody,
      }),
    }),
    createPaymentPrescription: build.mutation<
      CreatePaymentPrescriptionApiResponse,
      CreatePaymentPrescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment`,
        method: 'POST',
        body: queryArg.createPaymentPrescriptionRequestBody,
      }),
    }),
    pairTransactionToPaymentPrescription: build.mutation<
      PairTransactionToPaymentPrescriptionApiResponse,
      PairTransactionToPaymentPrescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/${queryArg.paymentPrescriptionId}/pair/${queryArg.transactionId}`,
        method: 'POST',
      }),
    }),
    showPaymentPrescription: build.query<
      ShowPaymentPrescriptionApiResponse,
      ShowPaymentPrescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/${queryArg.paymentPrescriptionId}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['paymentPrescription'],
    }),
    updatePaymentPrescription: build.mutation<
      UpdatePaymentPrescriptionApiResponse,
      UpdatePaymentPrescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/${queryArg.paymentPrescriptionId}`,
        method: 'PUT',
        body: queryArg.updatePaymentPrescriptionRequestBody,
      }),
    }),
    unpairTransactionFromPaymentPrescription: build.mutation<
      UnpairTransactionFromPaymentPrescriptionApiResponse,
      UnpairTransactionFromPaymentPrescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/payment/${queryArg.paymentPrescriptionId}/unpair/${queryArg.transactionId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCancelPaymentPrescriptionMutation,
  useConfirmPaymentPrescriptionMutation,
  useCreateBankTransferTransactionMutation,
  useCreateCashTransactionMutation,
  useCreatePaymentPrescriptionMutation,
  usePairTransactionToPaymentPrescriptionMutation,
  useShowPaymentPrescriptionQuery,
  useUpdatePaymentPrescriptionMutation,
  useUnpairTransactionFromPaymentPrescriptionMutation,
} = paymentApi;
