import {
  CreateDocumentContextApiArg,
  CreateDocumentContextApiResponse,
  CreateDocumentContextBulkApiArg,
  CreateDocumentContextBulkApiResponse,
  DeleteDocumentContextApiArg,
  DeleteDocumentContextApiResponse,
  GetDocumentContextCountApiArg,
  GetDocumentContextCountApiResponse,
} from '../types/api/documentContext';
import {omneticApi} from './baseApi/omneticApi';

export const documentContextApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createDocumentContextBulk: build.mutation<
      CreateDocumentContextBulkApiResponse,
      CreateDocumentContextBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/document-context/bulk`,
        method: 'POST',
        body: queryArg.documentContextBulkRequestBody,
      }),
    }),
    createDocumentContext: build.mutation<
      CreateDocumentContextApiResponse,
      CreateDocumentContextApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/document-context`,
        method: 'POST',
        body: queryArg.documentContextRequestBody,
      }),
    }),
    deleteDocumentContext: build.mutation<
      DeleteDocumentContextApiResponse,
      DeleteDocumentContextApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/document-context/${queryArg.documentContextId}`,
        method: 'DELETE',
      }),
    }),
    getDocumentContextCount: build.query<
      GetDocumentContextCountApiResponse,
      GetDocumentContextCountApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/document-context/${queryArg.contextTarget}/${queryArg.contextId}/count`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'documentsCount', id: queryArg.contextId}],
    }),
  }),
});

export const {
  useCreateDocumentContextBulkMutation,
  useCreateDocumentContextMutation,
  useDeleteDocumentContextMutation,
  useGetDocumentContextCountQuery,
} = documentContextApi;
