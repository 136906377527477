import {openDeleteDialog} from 'platform/components';
import {Icon} from 'platform/foundation';

import {FC, PropsWithChildren, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {EMPTY_PLACEHOLDER, selectBranchList} from '@omnetic-dms/shared';
import {
  Button,
  LinkButton,
  SimpleTable,
  Tag,
  useRouter,
  selectPaymentTypes,
  deleteDocumentRoundingDefinition,
  selectDocumentRoundingDefinitionsList,
  PaymentTypeEnum,
  RoundingDefinition,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {composePath} from 'shared';

import {ActionButtonsColumn, CardFullWidth} from '../../CashRegisters/styles';

export const DocumentRoundingDefinitionList: FC<PropsWithChildren<any>> = () => {
  const router = useRouter();
  const dispatch = useThunkDispatch();
  const {data: {branchListItems: branchList} = {}} = useSelector(selectBranchList);
  const paymentTypes = useSelector(selectPaymentTypes);
  const items = useSelector(selectDocumentRoundingDefinitionsList);

  const columns = useMemo(
    () => [
      {
        id: 'branch',
        Header: i18n.t('entity.branch.labels.branch'),
        Cell: ({row}: {row: RoundingDefinition}) => {
          const branchName = branchList?.find(
            (branch) => branch?.id === row?.branchId
          )?.marketingName;

          return <strong>{branchName ?? EMPTY_PLACEHOLDER}</strong>;
        },
      },
      {
        id: 'paymentType',
        Header: i18n.t('entity.bank.labels.paymentType'),
        Cell: ({row}: {row: RoundingDefinition}) => {
          const paymentType = paymentTypes?.find((payment) => payment.key === row?.paymentType);

          return (
            <Tag
              yellow={paymentType?.key === PaymentTypeEnum.CASH}
              magenta={paymentType?.key === PaymentTypeEnum.BANK_TRANSFER}
              purple={paymentType?.key === PaymentTypeEnum.PAYMENT_CARD}
            >
              {paymentType?.value}
            </Tag>
          );
        },
      },
      {
        id: 'action',
        Header: null,
        Cell: ({row}: {row: RoundingDefinition}) => (
          <ActionButtonsColumn onClick={(e) => e.stopPropagation()}>
            <Link
              to={composePath(settingsRoutes.documentRoundingDefinitionsDetail, {
                params: {
                  id: row?.id,
                },
              })} // TODO: Use correct ID https://carvago.atlassian.net/browse/T20-2743
            >
              <Button compact icon={<Icon value="action/search" />} />
            </Link>
            <Link
              to={composePath(settingsRoutes.documentRoundingDefinitionsEdit, {
                params: {
                  id: row?.id,
                },
              })}
            >
              <Button compact icon={<Icon value="image/edit" />} />
            </Link>
            {row?.isSystem ? null : (
              <Button
                compact
                icon={<Icon value="action/delete" />}
                onClick={() => {
                  if (!row?.isSystem && row?.id) {
                    const selectedItemBranchName = branchList?.find(
                      (branch) => branch?.id === row?.branchId
                    )?.marketingName;

                    openDeleteDialog({
                      text: i18n.t(
                        'entity.accounting.labels.deleteRoundingDefinitionConfirmationText',
                        {
                          branch: selectedItemBranchName,
                        }
                      ),
                      onConfirm: async () => {
                        await dispatch(deleteDocumentRoundingDefinition(row.id));
                      },
                    });
                  }
                }}
              />
            )}
          </ActionButtonsColumn>
        ),
      },
    ],
    [branchList, paymentTypes, dispatch]
  );

  return (
    <CardFullWidth
      noPadding
      title={i18n.t('page.accounting.labels.definitions')}
      headerContent={
        <Link to={settingsRoutes.documentRoundingDefinitionsAdd}>
          <LinkButton data-testid={testIds.settings.documentRoundingDefinitionsEdit('createNew')}>
            <Icon value="content/add_circle" /> {i18n.t('page.accounting.actions.addDefinition')}
          </LinkButton>
        </Link>
      }
    >
      <SimpleTable
        noZebra
        showRowDivider
        highlightRowOnHover
        rows={[{data: items}]}
        getRowId={(row) => row.id}
        columns={columns}
        tdPadding="default"
        onRowClick={(row) =>
          router.push(
            composePath(settingsRoutes.documentRoundingDefinitionsDetail, {
              params: {
                id: row?.id,
              },
            })
          )
        }
      />
    </CardFullWidth>
  );
};
