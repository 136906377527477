import {Action, Actions} from 'platform/components';
import {Icon, HStack, Link, Space} from 'platform/foundation';

import {TestIdProps, buildArray, openFile, suffixTestId} from 'shared';

import {FileResponseBody} from '../../../types/api/api';
import {downloadHostedFile} from '../../../utils/downloadHostedFile';
import {getIconKeyByFileName} from '../../../utils/getIconKeyByFileName';
import {printFile} from '../../../utils/printFile';

interface DocumentItemProps extends TestIdProps {
  document: FileResponseBody;
  onSendEmail: (document: FileResponseBody) => void;
  isDocxFormat?: boolean;
}

export function DocumentItem(props: DocumentItemProps) {
  const openDocument = () => {
    openFile(props.document.pdfUri);
  };

  const printDocument = () => {
    printFile(props.document.pdfUri);
  };

  const downloadDocument = () => {
    if (props.isDocxFormat) {
      downloadHostedFile(props.document.fileUri, props.document.filename);
    } else {
      downloadHostedFile(props.document.pdfUri, props.document.pdfFilename);
    }
  };

  const emailDocument = () => {
    props.onSendEmail(props.document);
  };

  const actions = buildArray<Action>()
    .add({
      type: 'iconButton',
      icon: 'action/print',
      onClick: printDocument,
    })
    .add({
      type: 'iconButton',
      icon: 'file/download',
      onClick: downloadDocument,
    })
    .add({
      type: 'iconButton',
      icon: 'communication/email',
      onClick: emailDocument,
    });

  return (
    <HStack spacing={4} align="center">
      <Icon value={getIconKeyByFileName(props.document.filename)} />
      <Link
        title={props.document.filename}
        size="xSmall"
        onClick={openDocument}
        data-testid={suffixTestId('documentItemLink', props)}
      />
      <Space fillAvailable />
      <Actions actions={actions} data-testid={suffixTestId('documentItemActions', props)} />
    </HStack>
  );
}
