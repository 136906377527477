import {Card, TabProps, TabsHeader} from 'platform/components';
import {DataGridRef} from 'platform/datagrid';
import {Box, HStack, VStack} from 'platform/foundation';

import {RefObject, useState} from 'react';

import i18n from '@omnetic-dms/i18n';
import {GetServiceOrderIssueNoteResponse} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {ServiceOrderTab} from './ServiceOrderTab';
import {WarehouseArticlesTab} from './WarehouseArticlesTab';

interface MaterialListProps extends TestIdProps {
  serviceOrderIssueNote: GetServiceOrderIssueNoteResponse | Nullish;
  addedSparePartsDatagridRef: RefObject<DataGridRef>;
  isBasketEmpty: boolean;
}

export function MaterialList(props: MaterialListProps) {
  const [activeTabId, setActiveTabId] = useState(TABS_IDS.serviceOrderTab);

  const tabs: TabProps[] = [
    {
      id: TABS_IDS.serviceOrderTab,
      title: i18n.t('entity.warehouse.labels.serviceOrder'),
      content: (
        <ServiceOrderTab
          addedSparePartsDatagridRef={props.addedSparePartsDatagridRef}
          serviceOrderIssueNoteId={props.serviceOrderIssueNote?.serviceOrderIssueNoteId}
          serviceOrderId={props.serviceOrderIssueNote?.serviceOrderId}
          serviceCaseId={props.serviceOrderIssueNote?.serviceCaseId}
          serviceOrderAttributes={props.serviceOrderIssueNote?.serviceOrder}
          isBasketEmpty={props.isBasketEmpty}
          data-testid={suffixTestId('tabs.serviceOrder', props)}
        />
      ),
    },
    {
      id: TABS_IDS.warehouseArticlesTab,
      title: i18n.t('entity.warehouse.labels.warehouseArticles'),
      content: (
        <WarehouseArticlesTab
          serviceOrderIssueNoteId={props.serviceOrderIssueNote?.serviceOrderIssueNoteId}
          serviceOrderId={props.serviceOrderIssueNote?.serviceOrderId}
          serviceOrderVariantId={props.serviceOrderIssueNote?.serviceOrder?.serviceOrderVariantId}
          data-testid={suffixTestId('tabContent.warehouseArticles', props)}
        />
      ),
      'data-testid': suffixTestId('tabs.warehouseArticles', props),
    },
  ];

  const activeTabContent = tabs.find(
    (tab) => tab.id === activeTabId ?? TABS_IDS.serviceOrderTab
  )?.content;

  return (
    <VStack height="100%" maxHeight="100%">
      <HStack>
        <TabsHeader
          variant="container"
          tabs={tabs}
          activeTabId={activeTabId}
          onChange={setActiveTabId}
          isFullWidth
          data-testid={suffixTestId('tabs', props)}
        />
      </HStack>

      <Box height="100%" flex={1}>
        <Card key={activeTabId} isFullHeight>
          {activeTabContent}
        </Card>
      </Box>
    </VStack>
  );
}

const TABS_IDS = {
  serviceOrderTab: 'serviceOrder',
  warehouseArticlesTab: 'warehouseArticles',
};
