import {diff} from 'deep-object-diff';
import {
  Alert,
  Button,
  ButtonGroup,
  Choice,
  closeCurrentDialog,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormSubmitHandler,
  Segment,
  Separator,
  showNotification,
  TextInput,
} from 'platform/components';
import {Hide, Show, VStack, Space, Grid, GridItem, HStack, Box} from 'platform/foundation';
import {match} from 'ts-pattern';
import {object} from 'yup';

import {useEffect, useRef, useState} from 'react';
import {DeepPartial, UseFormReturn} from 'react-hook-form';

import {
  assocPath,
  clone,
  concat,
  defaultTo,
  flip,
  head,
  isEmpty,
  isNil,
  last,
  not,
  path,
  pipe,
  always,
} from 'ramda';
import {isFunction, isNilOrEmpty, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  PostReceiveNoteItemManualRequest,
  DeliveryQuantityMappingRequest,
  GetReceiveNoteItemNewResponse,
  GetReceiveNoteItemRefreshedResponse,
  GetReceiveNoteItemResponse,
  GetWarehouseAccountResponse,
  GetWarehouseResponse,
  GetWarehousesResponse,
  handleApiError,
  Rate,
  ReceiveNoteItemChangedField,
  TenantResponseBody,
  PatchReceiveNoteItemManualRequest,
  useGetHandlingUnitsQuery,
  useLazyGetReceiveNoteItemNewQuery,
  useLazyGetReceiveNoteItemQuery,
  useLazyGetReceiveNoteItemRefreshedQuery,
  usePatchReceiveNoteItemManualMutation,
  usePostReceiveNoteItemManualMutation,
  usePutDeliveryQuantityMappingMutation,
  usePutReceiveNoteItemCalculationMutation,
  VatRate,
  useGetManufacturersQuery,
  useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  GetManufacturersResponse,
} from '@omnetic-dms/shared';

import {
  Nullish,
  RequiredTestIdProps,
  sanitizeObject,
  suffixTestId,
  Unpacked,
  yupNumber,
} from 'shared';

import {ArticleState} from '../../../../../types/ArticleState';
import {ReceiveNoteItemFormType} from '../../../../../types/ReceiveNote';
import {filterElementsBySuffixMatch} from '../../../../../utils/filterElementsBySuffixMatch';
import {getOptionsFromManufacturers} from '../../../../../utils/getOptionsFromManufacturers';
import {objectPaths} from '../../../../../utils/objectPaths';
import {removeKeys} from '../../../../../utils/removeKeys';
import {ReceiveNoteItemData} from '../types/ReceiveNoteItemData';
import {ArticleDetailCard} from './ArticleDetailCard';
import {ArticleForm} from './ArticleForm';
import {AvailabilitySegment} from './AvailabilitySegment';
import {DeliveryItemDetailCard} from './DeliveryItemDetailCard';
import {DeliveryItemForm} from './DeliveryItemForm';
import {ReceiveNoteItemForm} from './ReceiveNoteItemForm';
import {RequestsSegment} from './RequestsSegment';
import {SupplierOrdersSegment} from './SupplierOrdersSegment';
import {SupplierToWarehouseMappingForm} from './SupplierToWarehouseMappingForm';

const ABORT_ERROR = 'AbortError';

enum Segments {
  AVAILABILITY = 'availability',
  REQUESTS = 'requests',
  SUPPLIER_ORDERS = 'supplierOrders',
}

export interface AddItemFormProps extends RequiredTestIdProps {
  receiveNoteId: string | Nullish;
  warehouseId: string | Nullish;
  supplierId: string | Nullish;
  receiveNoteItemData: ReceiveNoteItemData | Nullish;
  warehouse: Unpacked<GetWarehousesResponse> | Nullish;
  warehouseAccount: GetWarehouseAccountResponse | Nullish;
  warehouseDetail?: GetWarehouseResponse | Nullish;
  vatRates: VatRate[] | Nullish;
  vatRatesByCountry: Rate[] | Nullish;
  tenant: TenantResponseBody | Nullish;
  state: 'PENDING' | 'COMPLETED' | Nullish;
  refreshDatagrid: () => void;
  isEditing?: boolean;
}

export function AddItemForm(props: AddItemFormProps) {
  const [activeSegment, setActiveSegment] = useState<Segments>(Segments.AVAILABILITY);

  const [searchedCatalogueNumber, setSearchedCatalogueNumber] = useState<string | null>(null);
  const [searchedManufacturerId, setSearchedManufacturerId] = useState<string | null>(null);

  const [catalogueNumberErrorMessage, setCatalogueNumberErrorMessage] = useState<
    string | undefined
  >(undefined);

  const [manufacturerIdErrorMessage, setManufacturerIdErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [articleState, setArticleState] = useState<ArticleState | null>(null);

  const [receiveNoteItem, setReceiveNoteItem] = useState<GetReceiveNoteItemResponse | Nullish>(
    null
  );

  const [receiveNoteItemNew, setReceiveNoteItemNew] = useState<
    GetReceiveNoteItemNewResponse | Nullish
  >(null);

  const [receiveNoteItemRefreshed, setReceiveNoteItemRefreshed] = useState<
    GetReceiveNoteItemRefreshedResponse | Nullish
  >(null);

  const {data: manufacturers, isLoading: areManufacturersLoading} = useGetManufacturersQuery();

  const {data: handlingUnits, isLoading: areHandlingUnitsLoading} = useGetHandlingUnitsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [getReceiveNoteItem] = useLazyGetReceiveNoteItemQuery();

  const [getReceiveNoteItemNew] = useLazyGetReceiveNoteItemNewQuery();

  const [getReceiveNoteItemRefreshed] = useLazyGetReceiveNoteItemRefreshedQuery();

  const [getArticleIdByManufacturerNumberAndManufacturerId] =
    useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery();

  const [postReceiveNoteItemManual, {isLoading: isPostReceiveNoteItemLoading}] =
    usePostReceiveNoteItemManualMutation();

  const [patchReceiveNoteItemManual, {isLoading: isPatchReceiveNoteItemLoading}] =
    usePatchReceiveNoteItemManualMutation();

  const [recalculateReceiveNoteItemPrices] = usePutReceiveNoteItemCalculationMutation();

  const [saveMapping, {isLoading: isSaveMappingLoading}] = usePutDeliveryQuantityMappingMutation();

  const defaultHandlingUnit = handlingUnits?.find((handlingUnit) => handlingUnit.isDefault);

  const articleId =
    receiveNoteItemNew?.article?.id ??
    receiveNoteItem?.article?.id ??
    receiveNoteItemRefreshed?.article?.id;

  const supplierId =
    receiveNoteItemNew?.article?.supplierId ??
    receiveNoteItem?.article?.supplierId ??
    receiveNoteItemRefreshed?.article?.supplierId;

  const manufacturerNumber =
    receiveNoteItemNew?.article?.manufacturerNumber ??
    receiveNoteItem?.article?.manufacturerNumber ??
    receiveNoteItemRefreshed?.article?.manufacturerNumber;

  const manufacturerId =
    receiveNoteItemNew?.article?.manufacturerId ??
    receiveNoteItem?.article?.manufacturerId ??
    receiveNoteItemRefreshed?.article?.manufacturerId;

  const deliveryNoteQuantity =
    receiveNoteItemNew?.deliveryNoteItem?.quantity ?? receiveNoteItem?.deliveryNoteItem?.quantity;

  const deliveryUnitId =
    receiveNoteItemNew?.supplierToWarehouseMapping.deliveryUnit ??
    receiveNoteItem?.supplierToWarehouseMapping.deliveryUnit;

  const receiveUnitId =
    receiveNoteItemNew?.article?.handlingUnit ??
    receiveNoteItem?.article?.handlingUnit ??
    receiveNoteItemRefreshed?.article?.handlingUnit;

  const articleHandlingUnitName = handlingUnits?.find(
    (handlingUnit) => handlingUnit.id === receiveUnitId
  )?.name;

  const isEditing = Boolean(props.isEditing);
  const isEditingDisabled = isEditing && props.state === 'COMPLETED';
  const doesExist = articleState === 'doesExist';
  const doesNotExist = articleState === 'doesNotExist';
  const hasBeenCreated = articleState === 'hasBeenCreated';
  const isCheckingForArticle = articleState === 'isChecking';
  const isChecked = doesExist || doesNotExist || hasBeenCreated;
  const isNotChecked = not(articleState);
  const shouldCardsBeExpanded = isChecked || isEditing;

  const defaultValues: DeepPartial<ReceiveNoteItemFormType> = {
    receiveNoteItem: {
      quantity: defaultTo(1, receiveNoteItem?.receiveNoteItem?.quantity),
      unitPrice: defaultTo(0, receiveNoteItem?.receiveNoteItem?.purchasePrice),
      totalPrice: defaultTo(0, receiveNoteItem?.receiveNoteItem?.purchaseValue),
      vatType: defaultTo('S', receiveNoteItem?.receiveNoteItem?.vatType),
    },
    deliveryItem: {
      quantityVerified: defaultTo(1, receiveNoteItem?.deliveryNoteItem?.quantityVerified),
      unitPrice: defaultTo(0, receiveNoteItem?.deliveryNoteItem?.purchasePrice),
      totalPrice: defaultTo(0, receiveNoteItem?.deliveryNoteItem?.purchaseValue),
      vatType: defaultTo('S', receiveNoteItem?.deliveryNoteItem?.vatType),
    },
    supplierToWarehouseMapping: {
      deliveryQuantity: defaultTo(1, receiveNoteItem?.supplierToWarehouseMapping?.deliveryQuantity),
      deliveryUnit: deliveryUnitId,
      receiveQuantity: defaultTo(1, receiveNoteItem?.supplierToWarehouseMapping?.receiveQuantity),
      receiveUnit: receiveUnitId,
    },
    article: {
      name: receiveNoteItem?.article?.name,
      manufacturerNumber: receiveNoteItem?.article?.manufacturerNumber,
      manufacturerId: receiveNoteItem?.article?.manufacturerId,
      storageLocation: receiveNoteItem?.article?.storageLocation,
      warehouseAccount: defaultTo(
        props.warehouseAccount?.id,
        receiveNoteItem?.article?.warehouseAccount
      ),
    },
  };

  // Needed for proper recalculations
  const prevFormValues = useRef<DeepPartial<ReceiveNoteItemFormType>>(defaultValues);
  // Needed for cancelling recalculating requests from the form onChange event
  const abortControllerInstance = useRef<AbortController | Nullish>();

  // Get the detail of new receive note item
  useEffect(() => {
    if (isNil(props.receiveNoteItemData?.articleId) || isEditing) {
      return;
    }

    handleGetReceiveNoteItemNew(props.receiveNoteItemData?.articleId);
    // We want to fetch the data only on these two deps change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, props.receiveNoteItemData?.articleId]);

  // Get the detail of receive note item in edit mode
  useEffect(() => {
    if (not(isEditing)) {
      return;
    }

    const handleGetReceiveNoteItem = async () => {
      setArticleState('isChecking');

      return await getReceiveNoteItem({
        receiveNoteId: props.receiveNoteId as string,
        itemId: props.receiveNoteItemData?.receiveNoteItemId as string,
      })
        .unwrap()
        .then((receiveNoteItem) => {
          // Set the prevFormValues now, we need them for proper recalculation of prices
          handleMapPrevFormValues(receiveNoteItem);
          setReceiveNoteItem(receiveNoteItem);
          setSearchedCatalogueNumber(defaultTo('', receiveNoteItem?.article?.manufacturerNumber));
          setSearchedManufacturerId(defaultTo('', receiveNoteItem?.article?.manufacturerId));
        })
        .then(() => setArticleState('doesExist'))
        .catch(handleApiError);
    };

    handleGetReceiveNoteItem();
    // We need to have the missing dependency (handleMapPrevFormValues) outside of this scope, because we are using it on multiple places
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditing,
    props.receiveNoteId,
    props.receiveNoteItemData?.receiveNoteItemId,
    getReceiveNoteItem,
  ]);

  const handleGetReceiveNoteItemNew = async (
    articleId: string,
    wasCreated?: boolean,
    formApi?: UseFormReturn<ReceiveNoteItemFormType>
  ) => {
    setArticleState('isChecking');

    const request = {
      receiveNoteId: props.receiveNoteId as string,
      articleId,
    };

    await getReceiveNoteItemNew(request)
      .unwrap()
      .then((receiveNoteItemNew) => {
        handleArticleState(receiveNoteItemNew, wasCreated);
        resetFormValues(receiveNoteItemNew, formApi);
      })
      .catch(handleApiError);
  };

  const handleCheckItem = async (formApi: UseFormReturn<ReceiveNoteItemFormType>) => {
    let canBeChecked = true;

    if (isNilOrEmpty(searchedCatalogueNumber)) {
      setCatalogueNumberErrorMessage(i18n.t('general.validations.fieldIsRequired'));
      canBeChecked = false;
    } else {
      setCatalogueNumberErrorMessage(undefined);
    }

    if (isNilOrEmpty(searchedManufacturerId)) {
      setManufacturerIdErrorMessage(i18n.t('general.validations.fieldIsRequired'));
      canBeChecked = false;
    } else {
      setManufacturerIdErrorMessage(undefined);
    }

    if (not(canBeChecked)) {
      return;
    }

    const articleIdResponse = await getArticleIdByManufacturerNumberAndManufacturerId({
      warehouseId: props.warehouseId as string,
      manufacturerNumber: searchedCatalogueNumber as string,
      manufacturerId: searchedManufacturerId as string,
    })
      .unwrap()
      .catch(handleApiError);

    if (!articleIdResponse) {
      setArticleState('doesNotExist');
      setReceiveNoteItemNew(null);
      return;
    }

    await handleGetReceiveNoteItemNew(articleIdResponse.articleId, false, formApi);
  };

  const handleArticleState = (
    receiveNoteItem: GetReceiveNoteItemNewResponse | Nullish,
    wasCreated?: boolean
  ) => {
    if (isNil(receiveNoteItem)) {
      return setArticleState('doesNotExist');
    }

    if (wasCreated) {
      setArticleState('hasBeenCreated');
      handleMapPrevFormValues(receiveNoteItemNew);
      setReceiveNoteItemNew(receiveNoteItem);
      return;
    }

    setArticleState('doesExist');
    handleMapPrevFormValues(receiveNoteItemNew);
    setReceiveNoteItemNew(receiveNoteItem);
  };

  const handleRefreshArticle = async (formApi: UseFormReturn<ReceiveNoteItemFormType>) => {
    const resetReceiveNoteData = () => {
      setReceiveNoteItemNew(null);
      setReceiveNoteItem(null);
    };

    await getReceiveNoteItemRefreshed({
      receiveNoteId: props.receiveNoteId as string,
      articleId: articleId as string,
    })
      .unwrap()
      .then((receiveNoteItemRefreshed) => {
        setReceiveNoteItemRefreshed(receiveNoteItemRefreshed);
        mapReceiveUnit(receiveNoteItemRefreshed.article.handlingUnit, formApi);
      })
      .then(resetReceiveNoteData)
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<ReceiveNoteItemFormType> = async (values) => {
    if (isNil(props.receiveNoteItemData)) {
      const receiveNoteItemRequest: PostReceiveNoteItemManualRequest = {
        receiveNoteId: props.receiveNoteId as string,
        articleId: articleId as string,
        quantity: values.receiveNoteItem.quantity,
        purchasePrice: values.receiveNoteItem.unitPrice,
        stockValue: values.receiveNoteItem.totalPrice,
        vatType: values.receiveNoteItem.vatType,
        deliveryItem: {
          quantityVerified: values.deliveryItem.quantityVerified,
          unitPrice: values.deliveryItem.unitPrice,
          stockValue: values.deliveryItem.totalPrice,
          vatType: values.deliveryItem.vatType,
        },
        supplierToWarehouseMapping: {
          deliveryQuantity: values.supplierToWarehouseMapping.deliveryQuantity,
          deliveryUnit: values.supplierToWarehouseMapping.deliveryUnit,
          receiveQuantity: values.supplierToWarehouseMapping.receiveQuantity,
        },
      };

      return await postReceiveNoteItemManual(receiveNoteItemRequest)
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('page.warehouse.notification.receiveNoteItemCreated'));
          props.refreshDatagrid();
          closeCurrentDialog();
        })
        .catch(handleApiError);
    }

    const receiveNoteItemRequest: PatchReceiveNoteItemManualRequest = {
      receiveNoteItemId: props.receiveNoteItemData?.receiveNoteItemId as string,
      body: {
        quantity: values.receiveNoteItem.quantity,
        purchasePrice: values.receiveNoteItem.unitPrice,
        stockValue: values.receiveNoteItem.totalPrice,
        vatType: values.receiveNoteItem.vatType,
        deliveryItem: {
          quantityVerified: values.deliveryItem.quantityVerified,
          unitPrice: values.deliveryItem.unitPrice,
          stockValue: values.deliveryItem.totalPrice,
          vatType: values.deliveryItem.vatType,
        },
        supplierToWarehouseMapping: {
          deliveryQuantity: values.supplierToWarehouseMapping.deliveryQuantity,
          deliveryUnit: values.supplierToWarehouseMapping.deliveryUnit,
          receiveQuantity: values.supplierToWarehouseMapping.receiveQuantity,
        },
      },
    };

    await patchReceiveNoteItemManual(receiveNoteItemRequest)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('page.warehouse.notification.receiveNoteItemUpdated'));
        props.refreshDatagrid();
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const handleChange = (
    values: DeepPartial<ReceiveNoteItemFormType>,
    // We don't know the correct type here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: any,
    formApi: UseFormReturn<ReceiveNoteItemFormType>
  ) => {
    const copiedValues = JSON.parse(JSON.stringify(values));
    const {asString} = pipe(
      defaultTo({}),
      flip(diff)(sanitizeObject(copiedValues)),
      objectPaths
    )(prevFormValues.current);

    // Name of changed the field
    const changedField = last<string>(change.name?.split('.') ?? []);
    const changedFieldWithFullPath = change.name;
    // Indicate if the changed field is allowed to trigger recalculation
    const shouldTriggerRecalculation = ALLOWED_VALUES.includes(changedField);
    // Get the difference between the previous and current form values
    const difference = diff(sanitizeObject(prevFormValues.current), sanitizeObject(copiedValues));
    // Difference that contains only allowed values
    const allowedDifference = removeKeys(
      difference,
      concat(ALLOWED_VALUES, ALLOWED_VALUES_IN_OBJECT)
    );

    const isReceiveNoteItemPricesDiffEmpty = allowedDifference?.receiveNoteItem
      ? isEmpty(allowedDifference?.receiveNoteItem)
      : false;
    const isDeliveryItemPricesDiffEmpty = allowedDifference?.deliveryItem
      ? isEmpty(allowedDifference?.deliveryItem)
      : false;
    const isSupplierToWarehouseMappingDiffEmpty = allowedDifference?.supplierToWarehouseMapping
      ? isEmpty(allowedDifference?.supplierToWarehouseMapping)
      : false;

    const isDiffEmpty =
      isReceiveNoteItemPricesDiffEmpty &&
      isDeliveryItemPricesDiffEmpty &&
      isSupplierToWarehouseMappingDiffEmpty;

    if (not(shouldTriggerRecalculation) || isNilOrEmpty(allowedDifference) || isDiffEmpty) {
      return;
    }

    const arrayOfChangedValues = asString.includes('.') ? asString.split(',') : asString;
    const pathToChangedValues = head(
      filterElementsBySuffixMatch(arrayOfChangedValues, ALLOWED_VALUES)
    )?.split('.') as string[];
    const changedValue = path(pathToChangedValues ?? [], values);
    const newPrevFormValues = assocPath(
      pathToChangedValues ?? [],
      changedValue,
      prevFormValues.current
    );

    prevFormValues.current = newPrevFormValues;

    // Map FE field names to BE field names to ensure correct data mapping
    const field = match(changedFieldWithFullPath)
      .with('receiveNoteItem.unitPrice', always('purchasePrice'))
      .with('deliveryItem.totalPrice', always('totalPriceDelivery'))
      .otherwise(always(changedField));

    if (abortControllerInstance.current && isFunction(abortControllerInstance.current.abort)) {
      abortControllerInstance.current.abort();
    }

    const receiveNoteItemCalculateController = new AbortController();

    abortControllerInstance.current = receiveNoteItemCalculateController;

    recalculateReceiveNoteItemPrices({
      body: {
        changedFieldValue: {
          field: field as ReceiveNoteItemChangedField,
        },
        currentFeFieldsValues: {
          quantity: values.receiveNoteItem?.quantity,
          purchasePrice: values.receiveNoteItem?.unitPrice,
          totalPrice: values.receiveNoteItem?.totalPrice,
          deliveryItem: {
            quantityVerified: values.deliveryItem?.quantityVerified,
            unitPrice: values.deliveryItem?.unitPrice,
            totalPriceDelivery: values.deliveryItem?.totalPrice,
          },
          supplierToWarehouseMapping: {
            deliveryQuantity: values.supplierToWarehouseMapping?.deliveryQuantity,
            receiveQuantity: values.supplierToWarehouseMapping?.receiveQuantity,
          },
        },
      },
      signal: receiveNoteItemCalculateController.signal,
    })
      .unwrap()
      .then((response) => {
        // newValues must have exactly the same structure as prevFormValues in handleMapPrevFormValues for recalculations to work properly!
        const newValues = clone(
          sanitizeObject({
            ...values,
            receiveNoteItem: {
              quantity: response.receiveNoteItem.quantity,
              unitPrice: response.receiveNoteItem.purchasePrice,
              totalPrice: response.receiveNoteItem.purchaseValue,
              vatType: defaultTo('S', values.receiveNoteItem?.vatType),
            },
            deliveryItem: {
              quantityVerified: response.deliveryNoteItem.quantityVerified,
              unitPrice: response.deliveryNoteItem.purchasePrice,
              totalPrice: response.deliveryNoteItem.purchaseValue,
              vatType: defaultTo('S', values.deliveryItem?.vatType),
            },
            supplierToWarehouseMapping: {
              deliveryQuantity: response.supplierToWarehouseMapping.deliveryQuantity,
              deliveryUnit: values.supplierToWarehouseMapping?.deliveryUnit,
              receiveQuantity: response.supplierToWarehouseMapping.receiveQuantity,
              receiveUnit: values.supplierToWarehouseMapping?.receiveUnit,
            },
          })
        );

        prevFormValues.current = newValues;
        formApi.reset(newValues);
      })
      .catch((e) => {
        // If the request is aborted, then we don't want to show the error notification
        if (e && e.error && isString(e.error) && e.error.includes(ABORT_ERROR)) {
          return;
        }
        handleApiError(e);
      });
  };

  const handleMapPrevFormValues = (
    data: GetReceiveNoteItemResponse | GetReceiveNoteItemNewResponse | Nullish
  ) => {
    prevFormValues.current = {
      receiveNoteItem: {
        quantity: defaultTo(1, data?.receiveNoteItem?.quantity),
        unitPrice: defaultTo(0, data?.receiveNoteItem?.purchasePrice),
        totalPrice: defaultTo(0, data?.receiveNoteItem?.purchaseValue),
        vatType: defaultTo('S', data?.receiveNoteItem?.vatType),
      },
      deliveryItem: {
        quantityVerified: defaultTo(1, data?.deliveryNoteItem?.quantityVerified),
        unitPrice: defaultTo(0, data?.deliveryNoteItem?.purchasePrice),
        totalPrice: defaultTo(0, data?.deliveryNoteItem?.purchaseValue),
        vatType: defaultTo('S', data?.deliveryNoteItem?.vatType),
      },
      supplierToWarehouseMapping: {
        deliveryQuantity: defaultTo(1, data?.supplierToWarehouseMapping?.deliveryQuantity),
        deliveryUnit: data?.supplierToWarehouseMapping?.deliveryUnit,
        receiveQuantity: defaultTo(1, data?.supplierToWarehouseMapping?.receiveQuantity),
        receiveUnit: data?.article?.handlingUnit,
      },
      article: {
        name: data?.article?.name,
        manufacturerNumber: data?.article?.manufacturerNumber,
        manufacturerId: data?.article?.manufacturerId,
        storageLocation: data?.article?.storageLocation,
        warehouseAccount: defaultTo(props.warehouseAccount?.id, data?.article?.warehouseAccount),
      },
    };
  };

  const handleSaveMapping = async (
    supplierToWarehouseMapping: GetReceiveNoteItemResponse['supplierToWarehouseMapping']
  ) => {
    if (isNil(articleId) || isNil(supplierId) || isNil(manufacturerNumber)) {
      throw new Error("Couldn't save mapping");
    }

    const requestBody: DeliveryQuantityMappingRequest = {
      body: {
        articleId,
        supplierId,
        manufacturer_number: manufacturerNumber,
        supplierToWarehouseMapping: {
          deliveryQuantity: supplierToWarehouseMapping.deliveryQuantity,
          deliveryUnit: supplierToWarehouseMapping.deliveryUnit,
          receiveQuantity: supplierToWarehouseMapping.receiveQuantity,
        },
      },
    };

    await saveMapping(requestBody)
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.mappingSaved')))
      .catch(handleApiError);
  };

  const handleDiscard = () => {
    closeCurrentDialog();
  };

  const resetFormValues = (
    receiveNoteItemNew: GetReceiveNoteItemNewResponse,
    formApi: UseFormReturn<ReceiveNoteItemFormType> | Nullish
  ) => {
    formApi?.reset(
      {
        ...receiveNoteItemNew,
        supplierToWarehouseMapping: {
          ...receiveNoteItemNew.supplierToWarehouseMapping,
          receiveUnit: receiveNoteItemNew.article.handlingUnit,
        },
      },
      {keepValues: true}
    );
  };

  const mapReceiveUnit = (
    handlingUnit: string,
    formApi: UseFormReturn<ReceiveNoteItemFormType>
  ) => {
    formApi.setValue('supplierToWarehouseMapping.receiveUnit', handlingUnit);
  };

  const segments = [
    {
      value: Segments.AVAILABILITY,
      label: i18n.t('entity.warehouse.labels.availability'),
      content: (
        <AvailabilitySegment
          articleId={articleId}
          manufacturerNumber={manufacturerNumber}
          manufacturerId={manufacturerId}
          data-testid={suffixTestId('segments.availability', props)}
        />
      ),
    },
    {
      value: Segments.REQUESTS,
      label: i18n.t('entity.warehouse.labels.requests'),
      content: (
        <RequestsSegment
          receiveNoteItemId={props.receiveNoteItemData?.receiveNoteItemId}
          data-testid={suffixTestId('segments.requests', props)}
        />
      ),
    },
    {
      value: Segments.SUPPLIER_ORDERS,
      label: i18n.t('entity.warehouse.labels.supplierOrders'),
      content: (
        <SupplierOrdersSegment
          receiveNoteItemId={props.receiveNoteItemData?.receiveNoteItemId}
          data-testid={suffixTestId('segments.supplierOrders', props)}
        />
      ),
    },
  ];

  const segmentContent = segments.find((segment) => segment.value === activeSegment)?.content;

  return (
    <Form<ReceiveNoteItemFormType>
      key={defaultValues.article?.manufacturerNumber}
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control, formApi) => {
        // For controlling recalculations of prices
        formApi.watch((data, test) => handleChange(data, test, formApi));
        const supplierToWarehouseMapping = formApi.watch('supplierToWarehouseMapping');

        return (
          <VStack>
            <VStack spacing={4}>
              <Grid columns={4} spacing={4}>
                <GridItem span={2}>
                  <TextInput
                    label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                    value={searchedCatalogueNumber}
                    onChange={setSearchedCatalogueNumber}
                    errorMessage={catalogueNumberErrorMessage}
                    isRequired
                    isDisabled={isCheckingForArticle || isEditing}
                    data-testid={suffixTestId('inputs.manufacturerNumber', props)}
                  />
                </GridItem>
                <GridItem span={2}>
                  <Choice
                    label={i18n.t('entity.warehouse.labels.manufacturer')}
                    value={searchedManufacturerId}
                    onChange={setSearchedManufacturerId}
                    errorMessage={manufacturerIdErrorMessage}
                    options={getOptionsFromManufacturers(manufacturers)}
                    isLoading={areManufacturersLoading}
                    isRequired
                    isDisabled={isCheckingForArticle || isEditing}
                    data-testid={suffixTestId('inputs.manufacturer', props)}
                  />
                </GridItem>

                <Hide when={isEditing}>
                  <GridItem span={1}>
                    <Button
                      variant="secondary"
                      title={i18n.t('general.labels.checkItem')}
                      onClick={() => handleCheckItem(formApi)}
                      isDisabled={isCheckingForArticle}
                      data-testid={suffixTestId('actions.checkItem', props)}
                    />
                  </GridItem>
                </Hide>
              </Grid>
              <Show when={not(isEditing) && doesExist}>
                <Alert
                  type="inline"
                  variant="info"
                  title={i18n.t('entity.warehouse.labels.itemIsAlreadyInDatabase')}
                  message={i18n.t('entity.warehouse.labels.itemDetailsPrefilled')}
                  data-testid={suffixTestId('alert.itemInDatabase', props)}
                />
              </Show>
              <Show when={not(isEditing) && doesNotExist}>
                <Alert
                  type="inline"
                  variant="warning"
                  title={i18n.t('entity.warehouse.labels.itemNotInDatabase')}
                  message={i18n.t('entity.warehouse.labels.youCanCreateNewWarehouseArticle')}
                  data-testid={suffixTestId('alerts.itemNotInDatabase', props)}
                />
              </Show>
              <Show when={not(isEditing) && hasBeenCreated}>
                <Alert
                  type="inline"
                  variant="success"
                  title={i18n.t('entity.warehouse.labels.warehouseArticleWasSuccessfullyCreated')}
                  data-testid={suffixTestId('alerts.articleCreated', props)}
                />
              </Show>
            </VStack>

            <Separator />

            <DataStatus isLoading={isCheckingForArticle} minHeight={100}>
              <ReceiveNoteItemForm
                control={control}
                vatRates={props.vatRates}
                tenant={props.tenant}
                handlingUnit={articleHandlingUnitName ?? defaultHandlingUnit?.name}
                currency={props.warehouseDetail?.currency}
                isChecked={isChecked}
                isEditingDisabled={isEditingDisabled}
                data-testid={suffixTestId('sections.receiveNoteItem', props)}
              />

              <Separator />

              <DeliveryItemForm
                control={control}
                deliveryNoteQuantity={deliveryNoteQuantity}
                vatRates={props.vatRates}
                tenant={props.tenant}
                handlingUnit={articleHandlingUnitName ?? defaultHandlingUnit?.name}
                currency={props.warehouseDetail?.currency}
                isChecked={isChecked}
                isEditingDisabled={isEditingDisabled}
                data-testid={suffixTestId('sections.deliveryItem', props)}
              />

              <Separator />

              <Hide when={doesNotExist}>
                <SupplierToWarehouseMappingForm
                  control={control}
                  handlingUnits={handlingUnits}
                  areHandlingUnitsLoading={areHandlingUnitsLoading}
                  isChecked={isChecked}
                  isEditingDisabled={isEditingDisabled}
                  onSaveMapping={() => handleSaveMapping(supplierToWarehouseMapping)}
                  isSaveMappingLoading={isSaveMappingLoading}
                  data-testid={suffixTestId('sections.supplierToWarehouseMapping', props)}
                />

                <Space vertical={4} />

                <DeliveryItemDetailCard
                  deliveryNoteQuantity={deliveryNoteQuantity}
                  handlingUnit={articleHandlingUnitName ?? defaultHandlingUnit?.name}
                  manufacturerNumber={manufacturerNumber}
                  receiveNoteItem={receiveNoteItem}
                  receiveNoteItemNew={receiveNoteItemNew}
                  receiveNoteItemRefreshed={receiveNoteItemRefreshed}
                  isDisabled={isNotChecked}
                  isExpanded={shouldCardsBeExpanded}
                  data-testid={suffixTestId('sections.deliveryItemDetailCard', props)}
                />

                <Space vertical={4} />

                <ArticleDetailCard
                  articleId={articleId}
                  manufacturerNumber={manufacturerNumber}
                  receiveNoteItem={receiveNoteItem}
                  receiveNoteItemNew={receiveNoteItemNew}
                  receiveNoteItemRefreshed={receiveNoteItemRefreshed}
                  currency={props.warehouseDetail?.currency}
                  isDisabled={isNotChecked}
                  isExpanded={shouldCardsBeExpanded}
                  onRefresh={() => handleRefreshArticle(formApi)}
                  data-testid={suffixTestId('sections.articleDetailCard', props)}
                />

                <Space vertical={4} />
              </Hide>

              <Show when={doesNotExist}>
                <ArticleForm
                  warehouseId={props.warehouseId}
                  searchedCatalogueNumber={searchedCatalogueNumber}
                  searchedManufacturerId={searchedManufacturerId}
                  onCreateArticle={handleGetReceiveNoteItemNew}
                  manufacturers={manufacturers as GetManufacturersResponse}
                  handlingUnit={receiveUnitId ?? defaultHandlingUnit?.id}
                  handlingUnits={handlingUnits}
                  areHandlingUnitsLoading={areHandlingUnitsLoading}
                  data-testid={suffixTestId('sections.createNewArticle', props)}
                />
              </Show>

              <Show when={isEditing}>
                <HStack>
                  <Segment<Segments>
                    value={activeSegment}
                    onChange={setActiveSegment}
                    options={segments}
                  />
                </HStack>
                <Box>{segmentContent}</Box>
              </Show>

              <DialogFooter>
                <ButtonGroup align="right">
                  <Button
                    variant="secondary"
                    title={i18n.t('general.labels.discard')}
                    onClick={handleDiscard}
                    data-testid={suffixTestId('actions.discard', props)}
                  />
                  <FormButton
                    control={control}
                    type="submit"
                    variant="primary"
                    title={
                      isEditing
                        ? i18n.t('general.labels.saveChanges')
                        : i18n.t('general.labels.add')
                    }
                    isDisabled={
                      isNotChecked ||
                      doesNotExist ||
                      isPostReceiveNoteItemLoading ||
                      isPatchReceiveNoteItemLoading ||
                      isEditingDisabled
                    }
                    data-testid={suffixTestId('actions.submit', props)}
                  />
                </ButtonGroup>
              </DialogFooter>
            </DataStatus>
          </VStack>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  receiveNoteItem: object({
    quantity: yupNumber.positive(),
    unitPrice: yupNumber.positive(),
    totalPrice: yupNumber.positive(),
  }),
  deliveryItem: object({
    quantityVerified: yupNumber.positive(),
    unitPrice: yupNumber.positive(),
    totalPrice: yupNumber.positive(),
  }),
  supplierToWarehouseMapping: object({
    deliveryQuantity: yupNumber.positive(),
    receiveQuantity: yupNumber.positive(),
  }),
});

const ALLOWED_VALUES_IN_OBJECT = ['receiveNoteItem', 'deliveryItem', 'supplierToWarehouseMapping'];

// nested keys from ALLOWED_VALUES_IN_OBJECT
const ALLOWED_VALUES = [
  'quantity',
  'unitPrice',
  'quantityVerified',
  'deliveryQuantity',
  'receiveQuantity',
  'totalPrice',
  'totalPriceDelivery',
];
