import {FormControl, FormField, isCurrency} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {useCallback, useEffect, useState} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CalculatePrice,
  DEFAULT_CURRENCY,
  PutPurchaseVehiclePricingCentralizedApiArg,
  selectTenant,
  VatType,
} from '@omnetic-dms/shared';
import {DEFAULT_COUNTRY} from '@omnetic-dms/teas';

import {FinanceSummary} from './FinanceSummary';

export interface VatDeductibleFormProps {
  vatType: VatType;
  control: FormControl<PutPurchaseVehiclePricingCentralizedApiArg>;
  formApi: UseFormReturn<PutPurchaseVehiclePricingCentralizedApiArg>;
  vehicleId: string;
}

type LastTouchedFieldType = 'withVat' | 'withoutVat';

export function VatDeductibleForm(props: VatDeductibleFormProps) {
  const {data: selectedTenant} = useSelector(selectTenant);
  const currency = isCurrency(selectedTenant?.currency)
    ? selectedTenant?.currency
    : DEFAULT_CURRENCY;

  const [lastTouchedSellingPriceField, setLastTouchedSellingPriceField] =
    useState<LastTouchedFieldType>();
  const [lastTouchedMaxBuyingPricePriceField, setLastTouchedMaxBuyingPriceField] =
    useState<LastTouchedFieldType>();

  const country = selectedTenant?.country ?? DEFAULT_COUNTRY;

  const handleCalculationChange = useCallback(
    (name: 'maxBuyingPrice' | 'sellingPrice') =>
      (res: {withVat?: string} | {withoutVat?: string}) => {
        if ('withVat' in res) {
          props.formApi.setValue(`${name}.priceWithVat.amount`, res?.withVat ?? '');
        }
        if ('withoutVat' in res) {
          props.formApi.setValue(`${name}.priceWithoutVat.amount`, res?.withoutVat ?? '');
        }
      },
    [props.formApi]
  );

  const [maxBuyingPriceWithVat, maxBuyingPriceWoVat, sellingPriceWithVat, sellingPriceWoVat] =
    useWatch({
      control: props.control,
      name: [
        'maxBuyingPrice.priceWithVat.amount',
        'maxBuyingPrice.priceWithoutVat.amount',
        'sellingPrice.priceWithVat.amount',
        'sellingPrice.priceWithoutVat.amount',
      ],
    });

  /**
   * Setting internal vatDirectionValue
   */
  useEffect(() => {
    if (!lastTouchedSellingPriceField) {
      return;
    }

    const value =
      lastTouchedSellingPriceField === 'withVat' ? 'FROM_PRICE_WITH_VAT' : 'FROM_PRICE_WITHOUT_VAT';

    props.formApi.setValue('sellingPrice.vatDirection', value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTouchedSellingPriceField]);

  /**
   * Setting internal vatDirectionValue
   */
  useEffect(() => {
    if (!lastTouchedMaxBuyingPricePriceField) {
      return;
    }

    const value =
      lastTouchedMaxBuyingPricePriceField === 'withVat'
        ? 'FROM_PRICE_WITH_VAT'
        : 'FROM_PRICE_WITHOUT_VAT';

    props.formApi.setValue('maxBuyingPrice.vatDirection', value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTouchedMaxBuyingPricePriceField]);

  return (
    <VStack spacing={3}>
      <HStack spacing={4}>
        {/* MAX BUYING PRICE CALCULATION */}
        <CalculatePrice
          amount={
            lastTouchedMaxBuyingPricePriceField === 'withVat'
              ? maxBuyingPriceWithVat
              : maxBuyingPriceWoVat
          }
          countryCode={country}
          type={lastTouchedMaxBuyingPricePriceField}
          vatCode={props.vatType}
          onChange={handleCalculationChange('maxBuyingPrice')}
        />
        <Box flexGrow={1}>
          <FormField
            control={props.control}
            name="maxBuyingPrice.priceWithoutVat.amount"
            type="currency"
            currency={currency}
            onChange={() => setLastTouchedMaxBuyingPriceField('withoutVat')}
            label={i18n.t('page.businessCase.labels.maxBuyingPriceWoVat')}
            data-testid={testIds.businessCase.buying('pricing-maxBuyingPrice-priceWithoutVat')}
          />
        </Box>
        <Box flexGrow={1}>
          <FormField
            control={props.control}
            name="maxBuyingPrice.priceWithVat.amount"
            type="currency"
            currency={currency}
            onChange={() => setLastTouchedMaxBuyingPriceField('withVat')}
            label={i18n.t('page.businessCase.labels.maxBuyingPrice')}
            data-testid={testIds.businessCase.buying('pricing-maxBuyingPrice-priceWithVat')}
          />
        </Box>
      </HStack>
      <HStack spacing={4}>
        {/* SELLING PRICE CALCULATION */}
        <CalculatePrice
          amount={
            lastTouchedSellingPriceField === 'withVat' ? sellingPriceWithVat : sellingPriceWoVat
          }
          countryCode={country}
          type={lastTouchedSellingPriceField}
          vatCode={props.vatType}
          onChange={handleCalculationChange('sellingPrice')}
        />
        <Box flexGrow={1}>
          <FormField
            control={props.control}
            name="sellingPrice.priceWithoutVat.amount"
            type="currency"
            currency={currency}
            label={i18n.t('general.labels.sellingPriceWithoutVat')}
            onChange={() => setLastTouchedSellingPriceField('withoutVat')}
            data-testid={testIds.businessCase.buying('pricing-sellingPrice-priceWithoutVat')}
          />
        </Box>
        <Box flexGrow={1}>
          <FormField
            control={props.control}
            name="sellingPrice.priceWithVat.amount"
            type="currency"
            currency={currency}
            label={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
            onChange={() => setLastTouchedSellingPriceField('withVat')}
            data-testid={testIds.businessCase.buying('pricing-sellingPrice-priceWithVat')}
          />
        </Box>
      </HStack>
      <FinanceSummary
        maxBuyingPrice={maxBuyingPriceWoVat}
        sellingPrice={sellingPriceWoVat}
        vehicleId={props.vehicleId}
      />
    </VStack>
  );
}
