import {
  Button,
  ButtonGroup,
  Dialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  IconButton,
  Upload,
  UploadState,
} from 'platform/components';
import {HStack, Icon, Link, Space, ThemeIconKey, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';
import * as Yup from 'yup';

import {useState} from 'react';

import {always, isEmpty, isNil} from 'ramda';
import {isNonEmptyArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  ContextTarget,
  handleApiError,
  useCreateDocumentContextBulkMutation,
  useCreateDocumentContextMutation,
  useCustomUpload,
} from '@omnetic-dms/shared';
import {UploadedFile} from '@omnetic-dms/teas';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {DOC_FILE_EXTENSIONS, PDF_FILES_EXTENSIONS} from './constants';

interface UploadDocumentsDialogProps extends TestIdProps {
  contextId: string;
  contextTarget: ContextTarget;
  isOpen: boolean;
  handleClose: () => void;
  onDocumentCreated: () => void;
  bulkContext?: {
    target: ContextTarget;
    targetId: string;
  }[];
}

type UploadDocumentsFormType = {
  notes: {[fileId: string]: string | null};
};

/**
 * @deprecated - use platform instead
 */
export function UploadDocumentsDialog(props: UploadDocumentsDialogProps) {
  const [isUploading, setIsUploading, stopIsUploading] = useBoolean();
  const [hasUploadError, showUploadError, hideUploadError] = useBoolean();
  const [documents, setDocuments] = useState<UploadedFile[]>([]);

  const [createDocument] = useCreateDocumentContextMutation();
  const [createBulkDocument] = useCreateDocumentContextBulkMutation();

  const [uploadFile] = useCustomUpload();

  const handleClose = () => {
    setDocuments([]);
    props.handleClose();
  };

  const handleCreateDocument: FormSubmitHandler<UploadDocumentsFormType> = async ({
    notes,
  }: UploadDocumentsFormType) => {
    const actions = documents.map((document) => {
      const fileName = document.name;

      if (isNonEmptyArray(props.bulkContext)) {
        return createBulkDocument({
          documentContextBulkRequestBody: {
            fileName,
            note: notes[document.fileId] || null,
            fileRemoteId: document?.fileId ?? '',
            contexts: props.bulkContext,
            contextSourceId: props.contextId,
            contextSource: props.contextTarget,
            manual: true,
          },
        })
          .unwrap()
          .catch(handleApiError);
      }

      return createDocument({
        documentContextRequestBody: {
          fileName,
          note: notes[document.fileId] || null,
          fileRemoteId: document?.fileId ?? '',
          contextId: props.contextId,
          contextTarget: props.contextTarget,
        },
      })
        .unwrap()
        .catch(handleApiError);
    });

    await Promise.all(actions).then(props.onDocumentCreated).then(handleClose);
  };

  const uploadState = match<[boolean, boolean], UploadState | undefined>([
    hasUploadError,
    isUploading,
  ])
    .with([true, Pattern.boolean], always(UploadState.Error))
    .with([Pattern.boolean, true], always(UploadState.Uploading))
    .with([Pattern.boolean, false], always(UploadState.Success))
    .otherwise(always(undefined));

  const removeDocument = (fileId: string) =>
    setDocuments((prevState) => prevState.filter((document) => document.fileId !== fileId));

  const getFileIcon = (fileName: string): ThemeIconKey => {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (isNil(fileExtension)) {
      return 'files_compact/property_TXT';
    }

    const icon = match<string, ThemeIconKey>(fileExtension)
      .when((val) => PDF_FILES_EXTENSIONS.includes(val), always('files_compact/property_PDF'))
      .when((val) => DOC_FILE_EXTENSIONS.includes(val), always('files_compact/property_PDF'))
      .otherwise(always('files_compact/property_TXT'));

    return icon;
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={handleClose}
      title={i18n.t('entity.document.labels.addDocuments')}
    >
      <Form<UploadDocumentsFormType>
        onSubmit={handleCreateDocument}
        schema={uploadDocumentFormSchema}
      >
        {(control) => (
          <VStack spacing={4}>
            {documents.map((document) => (
              <VStack key={document.fileId} spacing={2}>
                <HStack align="center" spacing={3}>
                  <Icon value={getFileIcon(document.name)} size={8} />
                  <Link
                    size="small"
                    href={document?.originalUri}
                    target="_blank"
                    title={document?.name}
                  />
                  <Space fillAvailable />
                  <IconButton
                    severity="danger"
                    icon="action/delete"
                    onClick={() => removeDocument(document.fileId)}
                    size="small"
                  />
                </HStack>

                <FormField
                  type="text"
                  control={control}
                  name={`notes.${document.fileId}`}
                  label={i18n.t('entity.document.labels.note')}
                  data-testid={suffixTestId('note', props)}
                />
              </VStack>
            ))}

            <Upload
              name="document"
              type="card"
              size="minHeight"
              isMultiple
              onStart={() => {
                hideUploadError();
                setIsUploading();
              }}
              uploadState={uploadState}
              onError={showUploadError}
              onSuccess={stopIsUploading}
              uploadText={i18n.t('entity.document.labels.addDocuments')}
              customRequest={(option) =>
                uploadFile({
                  file: option.file,
                  onError: option.onError,
                  onSuccess: (data) => {
                    const uploadedFile: UploadedFile = {
                      originalUri: data.fileUri,
                      fileId: data.fileId,
                      name: data.file.name,
                    };
                    setDocuments((prevState) => [...prevState, uploadedFile]);
                    option.onSuccess?.(data);
                  },
                })
              }
              data-testid={suffixTestId('uploadFile', props)}
            />

            <ButtonGroup align="right">
              <Button
                onClick={handleClose}
                variant="secondary"
                title={i18n.t('general.actions.cancel')}
                isDisabled={isUploading}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.add')}
                isLoading={isUploading}
                isDisabled={isEmpty(documents)}
                data-testid={suffixTestId('add', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </Dialog>
  );
}

const uploadDocumentFormSchema = Yup.object().shape({
  note: Yup.string().default(''),
});
