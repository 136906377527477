import {
  Action,
  Card,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Box, HStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useCallback, useState} from 'react';

import {isNotNil, mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  CatalogueFolder,
  FolderTree,
  getTreeFolderQueryModifier,
  handleApiError,
  LabourCatalogItemFrom,
  MoveToFolder,
  useDeleteLabourCatalogItemsMutation,
  useGetLabourCatalogGridFilterCatalogCategoryQuery,
  usePostWorkBasketItemFromLabourCatalogMutation,
} from '@omnetic-dms/shared';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';

interface ItemListCardProps extends RequiredTestIdProps {}

const folderContextTarget = 'labour-catalog';
const folderRelationTarget = 'labour-catalog-item';

export function ItemListCard(props: ItemListCardProps) {
  const {serviceCaseId, orderId, addWorkJobId} = useWorkshopUrl();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [activeFolder, setActiveFolder] = useState<CatalogueFolder | undefined>();
  const [shouldIncludeSubfolders, setShouldIncludeSubfolders] = useState(true);

  const {
    data: catalogues,
    isLoading: isCataloguesLoading,
    isError: isCataloguesError,
  } = useGetLabourCatalogGridFilterCatalogCategoryQuery({
    serviceCaseId,
  });

  const [deleteLabourCatalogItems] = useDeleteLabourCatalogItemsMutation();
  const [postWorkBasketItemFromLabourCatalog] = usePostWorkBasketItemFromLabourCatalogMutation();

  const catalogueIds = catalogues?.map((catalogue) => catalogue.value) ?? [];

  const handleRefreshDataGrid = () => {
    if (dataGridRef.current) {
      refreshDataGrid();
    }
  };

  const onCreateNewClick = () => {
    openDialog(
      <LabourCatalogItemFrom
        onClose={() => closeDialog('createNewItemDialog')}
        onSubmitted={handleRefreshDataGrid}
        freePositionData={{serviceCaseId, serviceOrderId: orderId, serviceJobId: addWorkJobId}}
        catalogueFolder={activeFolder}
        data-testid={suffixTestId('createNewItem', props)}
      />,
      {
        id: 'createNewItemDialog',
        title: i18n.t('general.labels.createNew'),
      }
    );
  };

  const handleEditItem = (itemId: string, itemName: string, refresh: () => void) => {
    openDialog(
      <LabourCatalogItemFrom
        onClose={() => closeDialog('editCatalogItemDialog')}
        onSubmitted={refresh}
        editedId={itemId}
        freePositionData={{serviceCaseId, serviceOrderId: orderId, serviceJobId: addWorkJobId}}
        data-testid={suffixTestId('createNewItem', props)}
      />,
      {
        id: 'editCatalogItemDialog',
        title: itemName,
      }
    );
  };

  const handleDeleteItem = (labourCatalogItemIds: string[], refresh: () => void) => {
    openDeleteDialog({
      onConfirm: () =>
        deleteLabourCatalogItems({body: {labourCatalogItemIds}})
          .unwrap()
          .then(refresh)
          .catch(handleApiError),
    });
  };

  const handleMoveToFolder = (itemIds: string | string[]) => {
    openDialog(
      <MoveToFolder
        catalogueIds={catalogueIds}
        contextTarget={folderContextTarget}
        relationTarget={folderRelationTarget}
        itemIds={isArray(itemIds) ? itemIds : [itemIds]}
        onClose={closeCurrentDialog}
        onSubmitted={handleRefreshDataGrid}
        data-testid={suffixTestId('moveDialog', props)}
      />,
      {
        size: 'small',
        title: i18n.t('entity.addWork.labels.moveToFolder'),
      }
    );
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          serviceCaseId,
          serviceOrderId: orderId,
          serviceJobId: addWorkJobId,
        },
        getTreeFolderQueryModifier(activeFolder?.id, shouldIncludeSubfolders),
      ]),
    [serviceCaseId, orderId, addWorkJobId, activeFolder?.id, shouldIncludeSubfolders]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const itemId = isArray(rowId) ? rowId[0] : rowId;
    const items = isArray(rowId) ? rowId : [rowId];
    const itemName = path<string>(['itemName', 'value'], rowData);

    match([itemName, actionKey])
      .with([Pattern.any, 'delete'], () => handleDeleteItem(items, refreshData))
      .with([Pattern.string, 'edit'], ([itemName]) => handleEditItem(itemId, itemName, refreshData))
      .with([Pattern.string, 'addToBasket'], [Pattern.any, 'add'], () =>
        postWorkBasketItemFromLabourCatalog({
          serviceCaseId,
          serviceOrderId: orderId,
          serviceJobId: addWorkJobId,
          body: {labourCatalogId: items},
        })
          .unwrap()
          .catch(handleApiError)
      )
      .with([Pattern.any, 'moveToFolder'], () => handleMoveToFolder(rowId))
      .otherwise(() => showNotification.error());
  };

  return (
    <Card
      title={i18n.t('entity.addWork.lables.itemsList')}
      actions={buildArray<Action>().add({
        type: 'button',
        variant: 'link',
        leftIcon: 'content/add_circle',
        title: i18n.t('general.labels.createNew'),
        onClick: onCreateNewClick,
      })}
      data-testid={suffixTestId('header', props)}
      isFullHeight
    >
      <HStack height="100%" spacing={4}>
        <DataStatus isLoading={isCataloguesLoading} isError={isCataloguesError}>
          <Box
            flex={1}
            borderRight="1px solid"
            borderColor="palettes.neutral.40.100"
            paddingRight={4}
            padding={1}
            height="100%"
            overflowX="auto"
          >
            <FolderTree
              contextIds={catalogueIds}
              contextTarget="labour-catalog"
              activeFolderId={activeFolder?.id}
              shouldIncludeSubfolders={shouldIncludeSubfolders}
              onFolderChange={(id, contextId) =>
                setActiveFolder(isNotNil(id) && isNotNil(contextId) ? {id, contextId} : undefined)
              }
              onIncludeSubfoldersChange={setShouldIncludeSubfolders}
              data-testid={suffixTestId('catalogueTree', props)}
            />
          </Box>
          <Box flex={3}>
            <DataGrid
              // DG must be re-rendered on folder change to update the query modifier
              // eslint-disable-next-line no-restricted-syntax
              key={`${activeFolder?.id}-${shouldIncludeSubfolders}`}
              ref={dataGridRef}
              gridCode="work-catalogue"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              data-testid={suffixTestId('workCatalogue', props)}
            />
          </Box>
        </DataStatus>
      </HStack>
    </Card>
  );
}
