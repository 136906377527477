import {Row} from 'react-table';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/shared';

import {DataViewOptions} from './DataViewOptions';

export type DataViewStateType = {
  isLoading: boolean;
  orderById: string;
  selectedView: DataViewOptions;
  lastVisitedVehicleId?: string | null;
  lastVisitedVehicleIndex?: number;
  selectedRows: ReadonlyArray<Row<SourcingVehicleDetailResponseBody>>;
};

export type DataViewState = DataViewStateType;

export type SetLastVisitedVehicleType = {
  adId?: string | null;
  index?: number;
};

export enum DataViewContainerName {
  classifiedsAllOffers = `dataview/classifiedsAll`,
  classifiedsLists = `dataview/classifiedsLists`,
}
