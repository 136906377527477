import {Card, DataStatus} from 'platform/components';
import {Grid, Hide, Show, Space} from 'platform/foundation';

import {FC} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  NewOfferPurchaseBrokerageVehicleResponseBody,
  featureFlags,
  useGetComplexPermissions,
  useGetPurchaseBusinessCasePricesOverviewQuery,
  usePermissions,
  useGetParticipationQuery,
  EntityResourceIds,
} from '@omnetic-dms/shared';
import {PriceBox} from '@omnetic-dms/teas';

type BrokerageFeesSummaryProps = {
  purchaseVehicle: NewOfferPurchaseBrokerageVehicleResponseBody;
  businessCaseId: string;
};

const BrokerageFeesSummary: FC<BrokerageFeesSummaryProps> = (props) => {
  const {
    data: purchaseVehiclePrices,
    isLoading,
    isError,
  } = useGetPurchaseBusinessCasePricesOverviewQuery({businessCaseId: props.businessCaseId});

  const {canReadPurchasePrice, canReadSalePrice} = useGetComplexPermissions();

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: props.businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [canReadVehicleEarnings, canViewBusinessCaseBrokerageFees, canReadCosts] = usePermissions({
    permissionKeys: [
      'vehicleReadEarnings',
      'businessCaseViewBrokerageFees',
      'removeVehiclePhotoBackground',
    ],
    scopes: {
      businessCaseViewBrokerageFees: businessCaseParticipation,
    },
  });

  const {purchaseVehicle} = props;
  const profit = purchaseVehicle?.brokerageFees?.profit?.withVat;
  const profitSub = purchaseVehicle?.brokerageFees?.profit?.withoutVat;

  const sellingPrice = !purchaseVehicle?.pricing?.sellingPrice?.priceNotDeductible
    ? purchaseVehicle?.pricing?.sellingPrice?.priceWithVat
    : purchaseVehicle?.pricing?.sellingPrice?.priceNotDeductible;

  const sellingPriceSub = !purchaseVehicle?.pricing?.sellingPrice?.priceNotDeductible
    ? purchaseVehicle?.pricing?.sellingPrice?.priceWithoutVat
    : undefined;

  return (
    <Card data-testid={testIds.businessCase.buying('summary')} variant="inlineGrey">
      <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
        <Grid columns={4}>
          <PriceBox
            data-testid={testIds.businessCase.buying('summary-profit')}
            colored
            align="left"
            percentTag={Number(purchaseVehicle?.brokerageFees?.profit?.percentage)}
            price={profit}
            priceSub={profitSub}
            isVatDeductible
            title={i18n.t('page.businessCase.labels.profit')}
          />
          <PriceBox
            data-testid={testIds.businessCase.buying('summary-sellingPrice')}
            align="left"
            price={sellingPrice}
            priceSub={sellingPriceSub}
            isVatDeductible
            title={i18n.t('general.labels.sellingPrice')}
          />
        </Grid>
      </Show>
      <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
        <DataStatus
          isEmpty={isNilOrEmpty(purchaseVehiclePrices)}
          isLoading={isLoading}
          isError={isError}
          minHeight={10}
        >
          <Grid columns={4}>
            <Show when={canReadSalePrice}>
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-vehicleSellingPrice')}
                align="left"
                price={purchaseVehiclePrices?.vehicleSellingPrice?.withVat}
                priceSub={purchaseVehiclePrices?.vehicleSellingPrice?.withoutVat}
                isVatDeductible={purchaseVehicle?.pricing?.vatDeductible}
                title={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
              />
            </Show>
            <Show when={canReadCosts && canReadPurchasePrice}>
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-totalExpectedCosts')}
                align="left"
                price={purchaseVehiclePrices?.totalCosts}
                title={i18n.t('entity.businessCase.labels.totalExpectedCosts')}
              />
            </Show>
            <Hide when={canReadCosts && canReadPurchasePrice}>
              <Space fillAvailable />
            </Hide>
            <Show
              when={
                canReadSalePrice &&
                canReadPurchasePrice &&
                canReadVehicleEarnings &&
                canViewBusinessCaseBrokerageFees
              }
            >
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-vehicleGrossMargin')}
                colored
                align="left"
                percentTag={Number(purchaseVehiclePrices?.vehicleMargin?.percentage)}
                price={purchaseVehiclePrices?.vehicleMargin?.amount}
                title={i18n.t('entity.businessCase.labels.vehicleGrossMargin')}
                tooltipText={i18n.t('entity.businessCase.labels.vehicleGrossMarginTooltip')}
              />
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-totalGrossMargin')}
                colored
                align="left"
                percentTag={Number(purchaseVehiclePrices?.totalMargin?.percentage)}
                price={purchaseVehiclePrices?.totalMargin?.amount}
                title={i18n.t('entity.businessCase.labels.totalGrossMargin')}
                tooltipText={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
              />
            </Show>
          </Grid>
        </DataStatus>
      </Show>
    </Card>
  );
};

export const BusinessCasePurchaseVehicleBrokerageFeesSummary = BrokerageFeesSummary;
