import {DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {featureFlags, queryParams} from '@omnetic-dms/shared';
import {
  fetchSpecificPlatformRequest,
  specificPlatformSelector,
  AdvertisingPlatformCodeEnum,
} from '@omnetic-dms/teas';

import {useQueryState} from 'shared';

import {PlatformAuthentication} from './components/PlatformAuthentication';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';
import {PlatformTipCarsInfo} from './components/PlatformTipCarsInfo';
import {PlatformTipCarsVin} from './components/PlatformTipCarsVin';

interface PlatformSettingsProps {
  code: string;
}

export function PlatformSettings(props: PlatformSettingsProps) {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const dispatch = useDispatch();
  const platform = useSelector(specificPlatformSelector(props.code as AdvertisingPlatformCodeEnum));

  useEffect(() => {
    if (props.code) {
      dispatch(
        fetchSpecificPlatformRequest({platformCode: props.code, branchId: branchId ?? undefined})
      );
    }
  }, [dispatch, props.code, branchId]);

  return (
    <>
      <DataStatus isLoading={isNil(platform)}>
        <PlatformAuthentication platform={platform} />
      </DataStatus>
      <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
        <PlatformPhotoSettings platformCode={props.code} />
      </Show>
      <Show when={props.code === AdvertisingPlatformCodeEnum.TIPCARS}>
        <PlatformTipCarsVin />
        <PlatformTipCarsInfo platform={platform} />
      </Show>
    </>
  );
}
