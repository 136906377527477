import {AttributesRow} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {defaultTo} from 'ramda';

import {parseDate, Nullish, buildArray} from 'shared';

import {useGetVehicleQuery} from '../../../api/vehicleApi';
import i18n from '../../../i18n/i18n';

export function useWarrantyAndValidityVehicleParameters(vehicleId: string | Nullish) {
  const formatDateTime = useDateTimeFormatter();

  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: defaultTo('', vehicleId)},
    {skip: !vehicleId}
  );

  return buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.vehicle.labels.warrantyUntil'),
      value: vehicle?.modelSpecification?.warrantyDate
        ? formatDateTime('dateShort', parseDate(vehicle?.modelSpecification?.warrantyDate))
        : null,
      testId: 'warrantyUntil',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.warrantyMileageLimit'),
      value: vehicle?.modelSpecification?.warrantyMileage ?? '',
      testId: 'warrantyMileageLimit',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.serviceContractUntil'),
      value: vehicle?.modelSpecification?.serviceContractUntil
        ? formatDateTime('dateShort', parseDate(vehicle?.modelSpecification?.serviceContractUntil))
        : null,
      testId: 'serviceContractUntil',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.serviceContractMileageLimit'),
      value: vehicle?.modelSpecification?.serviceContractMileageLimit,
      testId: 'serviceContractMileageLimit',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.serviceContractNote'),
      value: vehicle?.modelSpecification?.serviceContractNote,
      testId: 'serviceContractNote',
    });
}
