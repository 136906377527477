import {padCharsStart} from 'ramda-adjunct';

import {VehicleTypeEnum} from '../../../types/api/api';
import {ServiceVehicleFormType} from '../../ServiceVehicleForm/types/ServiceVehicleFormType';

export const getVehicleV2FromServiceVehicle = (data: ServiceVehicleFormType) => ({
  ...data,
  registrationPlate: data.registrationPlate?.toUpperCase(),
  vin: data.vin?.toUpperCase(),
  type: data.type[0] as VehicleTypeEnum,
  manufacturedOn:
    data.manufacturedOnYear &&
    data.manufacturedOnMonth &&
    `${data.manufacturedOnYear}-${padCharsStart('0', 2, data.manufacturedOnMonth)}`,
  bodyStyle: data.bodyStyle?.[0],
  fuelType: data.fuelType?.[0],
  secondaryFuelType: data.secondaryFuelType?.[0],
  hybridType: data.hybridType?.[0],
  transmission: data.transmission?.[0],
  drive: data.drive?.[0],
  exteriorColorSpecification: data.exteriorColorSpecification?.[0],
  exteriorColor: {
    value: data.exteriorColor?.value ?? null,
    name: data.exteriorColor?.name ?? null,
    code: data.exteriorColor?.code ?? null,
  },
});
