import {CountryCode} from 'libphonenumber-js';

import {defaultTo, find} from 'ramda';

import {Nullish} from 'shared';

import {useGetCurrenciesQuery} from '../api/cachedApi';
import {CurrencyResponseBody} from '../types/cached/Currencies';

type Code = CountryCode | string | Nullish;

export function useCurrencies() {
  const {data} = useGetCurrenciesQuery();

  const getCyrrencyByCode = (code: Code): CurrencyResponseBody | undefined =>
    find((country) => country?.code === code, data ?? []);

  const currencyOptions = data?.map((g) => ({
    label: g.name,
    value: g.code,
  }));

  return {
    currencyOptions,
    getCurrency: (code: Code) => defaultTo(null, getCyrrencyByCode(code)),
    getCurrencySymbol: (code: Code) => defaultTo(null, getCyrrencyByCode(code)?.symbol),
    getCurrencyName: (code: Code) => defaultTo(null, getCyrrencyByCode(code)?.name),
  };
}
