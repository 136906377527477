import {DataStatus, FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {getApiDateString, suffixTestId, RequiredTestIdProps, handleFormReset} from 'shared';

import {monthOptions} from '../../../constants/monthOptions';
import {yearOptions} from '../../../constants/yearOptions';
import {useVehicleCatalogue} from '../../../hooks/useVehicleCatalogue';
import i18n from '../../../i18n/i18n';
import {VinDecoderResponseBody} from '../../../types/api/vinDecoder';
import {MakeSelect} from '../../MakeSelect/MakeSelect';
import {ModelSelect} from '../../ModelSelect/ModelSelect';
import {VinDecoderField} from '../../VinDecoderField/VinDecoderField';
import {ServiceVehicleFormType} from '../types/ServiceVehicleFormType';
import {FuelField} from './FuelField';
import {VehicleTitleField} from './VehicleTitleField';

interface ServiceVehicleFormContentProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function ServiceVehicleFormContent(props: ServiceVehicleFormContentProps) {
  const vehicleType = props.formApi.watch('type')?.[0] ?? 'VEHICLETYPE_PASSENGER_CAR';

  const [vehicleUtils, {isLoading, isError}] = useVehicleCatalogue(vehicleType);

  const handleDecodeVin =
    (formApi: UseFormReturn<ServiceVehicleFormType>) => (decodedData?: VinDecoderResponseBody) => {
      if (decodedData?.vehicleType) {
        formApi.setValue('type', [decodedData.vehicleType]);
      }
      if (decodedData?.make) {
        formApi.setValue('make', decodedData.make);
      }
      if (decodedData?.modelFamily) {
        formApi.setValue('modelFamily', decodedData.modelFamily);
      }
      if (decodedData?.trim) {
        formApi.setValue('trim', decodedData.trim);
      }
      if (decodedData?.variant) {
        formApi.setValue('variant', decodedData.variant);
      }
      if (
        decodedData?.firstRegistrationOnYear &&
        decodedData?.firstRegistrationOnMonth &&
        decodedData?.firstRegistrationOnDay
      ) {
        formApi.setValue(
          'firstRegistrationOn',
          getApiDateString(
            new Date(
              parseInt(decodedData.firstRegistrationOnYear, 10),
              parseInt(decodedData.firstRegistrationOnMonth, 10),
              parseInt(decodedData.firstRegistrationOnDay, 10)
            )
          )
        );
      }

      if (decodedData?.manufacturedOnYear) {
        formApi.setValue('manufacturedOnYear', decodedData.manufacturedOnYear);
      }
      if (decodedData?.manufacturedOnMonth) {
        formApi.setValue('manufacturedOnMonth', decodedData.manufacturedOnMonth);
      }
      if (decodedData?.otherRecords) {
        formApi.setValue('note', decodedData.otherRecords);
      }
      if (decodedData?.bodyStyle) {
        formApi.setValue('bodyStyle', [decodedData.bodyStyle]);
      }
      if (decodedData?.fuelType) {
        formApi.setValue('fuelType', [decodedData.fuelType]);
      }
      if (decodedData?.transmission) {
        formApi.setValue('transmission', [decodedData.transmission]);
      }
      if (decodedData?.drive) {
        formApi.setValue('drive', [decodedData.drive]);
      }
      if (decodedData?.exteriorColor) {
        formApi.setValue('exteriorColor.value', decodedData.exteriorColor);
      }
      if (decodedData?.engineCode) {
        formApi.setValue('engineCode', decodedData.engineCode);
      }
      if (decodedData?.power) {
        formApi.setValue('power', parseInt(decodedData.power));
      }
      if (decodedData?.engineVolume) {
        formApi.setValue('engineVolume', parseInt(decodedData.engineVolume));
      }
      if (decodedData?.secondaryFuelType) {
        formApi.setValue('secondaryFuelType', [decodedData.secondaryFuelType]);
      }
    };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
      <VStack spacing={4}>
        <VinDecoderField<ServiceVehicleFormType>
          control={props.control}
          formApi={props.formApi}
          registrationPlateField={{
            name: 'registrationPlate',
            label: i18n.t('entity.vehicle.labels.licensePlate'),
          }}
          vinField={{name: 'vin', label: i18n.t('entity.vehicle.labels.vin')}}
          onDecodedData={handleDecodeVin(props.formApi)}
          data-testid={suffixTestId('serviceVehicleForm-vinDecoder', props)}
        />
        <FormField
          control={props.control}
          type="chips"
          name="type"
          options={vehicleUtils.vehicleTypeOptions ?? []}
          label={i18n.t('entity.vehicle.labels.vehicleType')}
          isRequired
          onChange={() =>
            handleFormReset<ServiceVehicleFormType>(props.formApi, [
              {name: 'make', value: null},
              {name: 'modelFamily', value: null},
              {name: 'trim', value: null},
              {name: 'title', value: null},
              {name: 'variant', value: null},
              {name: 'bodyStyle', value: null},
              {name: 'transmission', value: null},
              {name: 'drive', value: null},
              {name: 'fuelType', value: null},
              {name: 'chargingAC', value: null},
              {name: 'chargingTimeAC', value: null},
              {name: 'chargingDC', value: null},
              {name: 'chargingTimeDC', value: null},
              {name: 'batteryType', value: null},
              {name: 'batteryCapacityWh', value: null},
              {name: 'batteryCapacityAh', value: null},
              {name: 'batteryVoltage', value: null},
              {name: 'batteryCount', value: null},
              {name: 'chargingConnectorType', value: null},
              {name: 'electricRange', value: null},
              {name: 'batteryMileageLimit', value: null},
              {name: 'batteryWarrantyUntil', value: null},
              {name: 'batteryOwnershipType', value: null},
              {name: 'secondaryFuelType', value: null},
              {name: 'hybridType', value: null},
              {name: 'gasRevisionValidUntil', value: null},
              {name: 'pressureVesselValidUntil', value: null},
              {name: 'engineName', value: null},
              {name: 'engineCode', value: null},
              {name: 'power', value: null},
              {name: 'engineVolume', value: null},
            ])
          }
          data-testid={suffixTestId('serviceVehicleForm-vehicleType', props)}
        />
        <Grid columns={4}>
          <MakeSelect
            vehicleType={vehicleType}
            control={props.control}
            isRequired
            name="make"
            label={i18n.t('entity.vehicle.labels.make')}
            data-testid={suffixTestId('serviceVehicleForm', props)}
            onChange={() =>
              handleFormReset<ServiceVehicleFormType>(props.formApi, [
                {name: 'modelFamily', value: null},
                {name: 'trim', value: null},
                {name: 'title', value: null},
                {name: 'variant', value: null},
                {name: 'engineName', value: null},
                {name: 'engineCode', value: null},
                {name: 'power', value: null},
                {name: 'engineVolume', value: null},
              ])
            }
          />
          <ModelSelect
            vehicleType={vehicleType}
            makes={props.formApi.watch('make')}
            control={props.control}
            isRequired
            name="modelFamily"
            label={i18n.t('entity.vehicle.labels.model')}
            onChange={() =>
              handleFormReset<ServiceVehicleFormType>(props.formApi, [
                {name: 'trim', value: null},
                {name: 'title', value: null},
                {name: 'variant', value: null},
                {name: 'engineName', value: null},
                {name: 'engineCode', value: null},
                {name: 'power', value: null},
                {name: 'engineVolume', value: null},
              ])
            }
            data-testid={suffixTestId('serviceVehicleForm', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="trim"
            label={i18n.t('entity.vehicle.labels.trimLevel')}
            data-testid={suffixTestId('serviceVehicleForm-trim', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="variant"
            label={i18n.t('entity.vehicle.labels.variantName')}
            data-testid={suffixTestId('serviceVehicleForm-variant', props)}
          />
          <GridItem span={2}>
            <VehicleTitleField
              formApi={props.formApi}
              control={props.control}
              data-testid={suffixTestId('serviceVehicleForm-title', props)}
            />
          </GridItem>
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="apiDate"
            name="firstRegistrationOn"
            label={i18n.t('entity.vehicle.labels.firstRegistration')}
            data-testid={suffixTestId('serviceVehicleForm-firstRegistrationOn', props)}
          />
          <FormField
            control={props.control}
            type="choice"
            name="manufacturedOnYear"
            label={i18n.t('entity.vehicle.labels.realProductionYear')}
            options={yearOptions}
            data-testid={suffixTestId('serviceVehicleForm-manufacturedOnYear', props)}
          />
          <FormField
            control={props.control}
            type="choice"
            name="manufacturedOnMonth"
            label={i18n.t('entity.vehicle.labels.realProductionMonth')}
            options={monthOptions}
            data-testid={suffixTestId('serviceVehicleForm-manufacturedOnMonth', props)}
          />
        </Grid>
        <FormField
          control={props.control}
          type="textarea"
          name="note"
          label={i18n.t('entity.invoice.labels.internalNote')}
          data-testid={suffixTestId('serviceVehicleForm-note', props)}
        />
        <FormField
          control={props.control}
          type="chips"
          name="bodyStyle"
          options={vehicleUtils.vehicleStyleOptions ?? []}
          label={i18n.t('entity.vehicle.labels.body')}
          data-testid={suffixTestId('serviceVehicleForm-bodyStyle', props)}
        />
        <FuelField
          control={props.control}
          formApi={props.formApi}
          data-testid={suffixTestId('serviceVehicleForm-fuel', props)}
        />
        <Grid columns={2}>
          <FormField
            control={props.control}
            type="chips"
            name="transmission"
            options={vehicleUtils.transmissionOptions ?? []}
            label={i18n.t('entity.vehicle.labels.transmission')}
            data-testid={suffixTestId('serviceVehicleForm-transmission', props)}
          />
          <FormField
            control={props.control}
            type="chips"
            name="drive"
            options={vehicleUtils.driveOptions ?? []}
            label={i18n.t('entity.vehicle.labels.drive')}
            data-testid={suffixTestId('serviceVehicleForm-drive', props)}
          />
          <FormField
            control={props.control}
            type="chips"
            name="exteriorColorSpecification"
            options={vehicleUtils.bodyColorTypeOptions ?? []}
            label={i18n.t('entity.vehicle.labels.bodyColorType')}
            data-testid={suffixTestId('serviceVehicleForm-exteriorColorSpecification', props)}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="choice"
            name="exteriorColor.value"
            options={vehicleUtils.bodyColorOptions ?? []}
            menuInPortal
            label={i18n.t('entity.vehicle.labels.bodyColor')}
            data-testid={suffixTestId('serviceVehicleForm-bodyColor', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="exteriorColor.name"
            label={i18n.t('entity.vehicle.labels.manufacturerColorName')}
            data-testid={suffixTestId('serviceVehicleForm-manufacturerColorName', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="exteriorColor.code"
            label={i18n.t('entity.vehicle.labels.manufacturerColorCode')}
            data-testid={suffixTestId('serviceVehicleForm-manufacturerColorCode', props)}
          />
        </Grid>
        <Heading size={4}>{i18n.t('entity.vehicle.labels.engine')}</Heading>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="text"
            name="engineName"
            label={i18n.t('entity.vehicle.labels.engineName')}
            data-testid={suffixTestId('serviceVehicleForm-engineName', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="engineNumber"
            label={i18n.t('entity.vehicle.labels.engineNumber')}
            data-testid={suffixTestId('serviceVehicleForm-engineNumber', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="engineCode"
            label={i18n.t('entity.vehicle.labels.engineCode')}
            data-testid={suffixTestId('serviceVehicleForm-engineCode', props)}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="number"
            name="power"
            suffix={i18n.t('general.metric.kW')}
            label={i18n.t('entity.vehicle.labels.power')}
            data-testid={suffixTestId('serviceVehicleForm-power', props)}
          />
          <FormField
            control={props.control}
            type="number"
            name="engineVolume"
            suffix={i18n.t('general.metric.ccm')}
            label={i18n.t('entity.vehicle.labels.engineCapacity')}
            data-testid={suffixTestId('serviceVehicleForm-engineCapacity', props)}
          />
        </Grid>
      </VStack>
    </DataStatus>
  );
}
