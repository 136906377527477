import {DataStatus, showNotification} from 'platform/components';

import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  DocumentLimitDefinitionResponseBody,
  handleApiError,
  selectTenant,
  useCreateDocumentLimitDefinitionMutation,
  useGetDocumentLimitDefinitionQuery,
  useUpdateDocumentLimitDefinitionMutation,
} from '@omnetic-dms/shared';

import {CurrencyCodeType, parseDate} from 'shared';

import {CashRegisterLimitForm} from './CashRegisterLimitForm';
import {emptyRestrictionItem} from './utils';

export function DefinitionDetail() {
  const navigate = useNavigate();

  const {id} = useParams();
  const isCreating = isNil(id);
  const {
    data: limitDetail,
    isLoading,
    isError,
  } = useGetDocumentLimitDefinitionQuery({id: id ?? ''}, {skip: isCreating});

  const {data: {currency} = {}} = useSelector(selectTenant);

  const [updateDefinition] = useUpdateDocumentLimitDefinitionMutation();
  const [createDefinition] = useCreateDocumentLimitDefinitionMutation();

  const handleSubmit = async (values: Omit<DocumentLimitDefinitionResponseBody, 'id'>) => {
    if (!isCreating) {
      if (!limitDetail) {
        return;
      }

      await updateDefinition({id: limitDetail.id, updateDocumentLimitDefinitionRequestBody: values})
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.cashRegisterLimits.notifications.definitionUpdated')
          );
          navigate(settingsRoutes.cashLimits);
        })
        .catch(handleApiError);
    } else {
      await createDefinition({createDocumentLimitDefinitionRequestBody: values})
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.cashRegisterLimits.notifications.definitionCreated')
          );

          navigate(settingsRoutes.cashLimits);
        })
        .catch(handleApiError);
    }
  };

  const handleCancel = () => {
    navigate(settingsRoutes.cashLimits);
  };

  return (
    <DataStatus isError={!currency || isError} isLoading={isLoading}>
      <CashRegisterLimitForm
        defaultValues={
          limitDetail
            ? {
                branchId: limitDetail.branchId,
                currency: limitDetail.currency ?? '',
                system: limitDetail.system,
                restrictions: limitDetail.restrictions.map((restriction) => ({
                  ...restriction,
                  validFrom: parseDate(restriction.validFrom),
                })) ?? [emptyRestrictionItem(currency as CurrencyCodeType)],
              }
            : {
                branchId: null,
                currency: currency ?? '',
                restrictions: [emptyRestrictionItem(currency as CurrencyCodeType)],
                system: false,
              }
        }
        edit={!isCreating}
        isSystem={isTrue(limitDetail?.system)}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </DataStatus>
  );
}
