import {isNil} from 'ramda';

import {Rate} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getVatRateByVatType = (
  vatRates: Rate[] | Nullish,
  vatType: string | Nullish
): Rate | Nullish => {
  if (isNil(vatRates)) {
    return null;
  }
  return vatRates.find((vatRate) => vatRate.type === vatType);
};
