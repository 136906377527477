import {CombinedState, combineReducers, Reducer} from 'redux';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/shared';

import {VehicleSliceState, VehiclesSliceName} from '../../types/SourcingVehicleState';
import {createVehiclesSlice} from './reducer';

export const classifiedsVehicles = createVehiclesSlice<SourcingVehicleDetailResponseBody>(
  VehiclesSliceName.classifieds,
  ['sourcingVehicle']
);

type CombinedReducer<K extends string, R> = Reducer<CombinedState<{[key in K]: R}>>;

const reducer: CombinedReducer<VehiclesSliceName, VehicleSliceState> = combineReducers({
  [VehiclesSliceName.classifieds]: classifiedsVehicles.reducer,
});

export * from './reducer';
export * from './actions';
export * from '../../types/SourcingVehicleState';

export const vehiclesReducer = reducer;
