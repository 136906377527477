import {useCallback} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isNotNil} from 'ramda';

import {AccountingDocumentRecalculateItemRes} from '@omnetic-dms/shared';

import {isNilOrZero} from 'shared';

import {AccountingDocumentFormValues, InvoiceItem} from '../../../types';
import {isValidIndex} from '../utils/isValidIndex';

export type UpdateRowType = {
  updatedItemIndex: number;
  updatedItem: AccountingDocumentRecalculateItemRes;
  items: AccountingDocumentRecalculateItemRes[];
  invoiceItems: AccountingDocumentFormValues['invoiceItems'];
};

export type ResetInvalidDiscountType = {
  invoiceItems: AccountingDocumentFormValues['invoiceItems'];
};

export const useRerenderRows = (formApi: UseFormReturn<AccountingDocumentFormValues>) => {
  const updateDiscount = useCallback(
    ({updatedItem, updatedItemIndex}: UpdateRowType) => {
      formApi.setValue(`invoiceItems.${updatedItemIndex}.pricePerUnit`, updatedItem.unitPrice);
      formApi.setValue(`invoiceItems.${updatedItemIndex}.priceWithVat`, updatedItem.priceWithVat);
      formApi.setValue(
        `invoiceItems.${updatedItemIndex}.priceWithoutVat`,
        updatedItem.priceWithoutVat
      );
    },
    [formApi]
  );

  const resetInvalidDiscounts = useCallback(
    ({invoiceItems}: ResetInvalidDiscountType) => {
      invoiceItems.forEach((item, index) => {
        if (!isDiscountItemInvalid(item)) {
          return;
        }

        formApi.setValue(`invoiceItems.${index}.priceWithVat`, '0');
        formApi.setValue(`invoiceItems.${index}.priceWithoutVat`, '0');
      });
    },
    [formApi]
  );

  const updateMargin = useCallback(
    ({updatedItemIndex, updatedItem, items, invoiceItems}: UpdateRowType) => {
      formApi.setValue(`invoiceItems.${updatedItemIndex}.priceWithVat`, updatedItem.priceWithVat);
      formApi.setValue(
        `invoiceItems.${updatedItemIndex}.priceWithoutVat`,
        updatedItem.priceWithoutVat
      );

      const discountItemIndex = invoiceItems.findIndex(
        (item) => item.relatedItemId === updatedItem.itemId && item.type === 'discount'
      );
      const discountItem = items.find(
        (item) => item.relatedItemId === updatedItem.itemId && item.type === 'discount'
      );

      if (isNotNil(discountItem) && isValidIndex(discountItemIndex)) {
        updateDiscount({
          items,
          invoiceItems,
          updatedItemIndex: discountItemIndex,
          updatedItem: discountItem,
        });
      }
    },
    [formApi, updateDiscount]
  );

  const updateStandardItem = useCallback(
    ({invoiceItems, items, updatedItem, updatedItemIndex}: UpdateRowType) => {
      formApi.setValue(`invoiceItems.${updatedItemIndex}.priceWithVat`, updatedItem.priceWithVat);
      formApi.setValue(
        `invoiceItems.${updatedItemIndex}.priceWithoutVat`,
        updatedItem.priceWithoutVat
      );

      const discountItemIndex = invoiceItems.findIndex(
        (item) => item.relatedItemId === updatedItem.itemId && item.type === 'discount'
      );
      const discountItem = items.find(
        (item) => item.relatedItemId === updatedItem.itemId && item.type === 'discount'
      );

      if (isNotNil(discountItem) && isValidIndex(discountItemIndex)) {
        updateDiscount({
          invoiceItems,
          items,
          updatedItem: discountItem,
          updatedItemIndex: discountItemIndex,
        });
      }

      const marginItemIndex = invoiceItems.findIndex(
        (item) => item.relatedItemId === updatedItem.itemId && item.type === 'margin'
      );
      const marginItem = items.find(
        (item) => item.relatedItemId === updatedItem.itemId && item.type === 'margin'
      );

      if (isNotNil(marginItem) && isValidIndex(marginItemIndex)) {
        updateMargin({
          invoiceItems,
          items,
          updatedItem: marginItem,
          updatedItemIndex: marginItemIndex,
        });
      }
    },
    [formApi, updateDiscount, updateMargin]
  );

  const updateAllItems = useCallback(
    ({invoiceItems, items}: Omit<UpdateRowType, 'updatedItem' | 'updatedItemIndex'>) => {
      invoiceItems.forEach((item, index) => {
        const updatedItem = items.find((res) => res.itemId === item.itemId);

        if (updatedItem) {
          formApi.setValue(`invoiceItems.${index}.priceWithoutVat`, updatedItem.priceWithoutVat);
          formApi.setValue(`invoiceItems.${index}.priceWithVat`, updatedItem.priceWithVat);
        }
      });
    },
    [formApi]
  );

  return {updateDiscount, updateMargin, updateStandardItem, updateAllItems, resetInvalidDiscounts};
};

const isDiscountItemInvalid = (val: InvoiceItem) =>
  val.type === 'discount' && isNilOrZero(parseFloat(val.pricePerUnit ?? '0'));
