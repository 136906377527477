import {Card, DataStatus} from 'platform/components';
import {Space, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {TestIdProps, suffixTestId} from 'shared';

import {useGetVehicleServiceCampaignQuery} from '../../api/metadaWorkshopServiceCampaignApi';
import i18n from '../../i18n/i18n';
import {CampaignCard} from './components/CampaignCard';
import {CampaignData} from './types/CampaignData';

interface ServiceCampaignsProps extends TestIdProps {
  vehicleId: string;
  onAdd?: (serviceCampaign: CampaignData) => void;
}

export function ServiceCampaigns(props: ServiceCampaignsProps) {
  const {data, isLoading, isError} = useGetVehicleServiceCampaignQuery({
    vehicleId: props.vehicleId,
  });

  const activeCampaigns = data?.serviceCampaign?.filter(
    (campaign) => campaign.kind === 'ACTIVE_CAMPAIGN'
  );
  const previousCampaigns = data?.serviceCampaign?.filter(
    (campaign) => campaign.kind === 'PREVIOUS_CAMPAIGN'
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError} data-testid={suffixTestId('status', props)}>
      <Card title={i18n.t('entity.campaigns.labels.activeCampaigns')}>
        <VStack spacing={3}>
          <DataStatus
            isEmpty={isNilOrEmpty(activeCampaigns)}
            data-testid={suffixTestId('activeCampaignsStatus', props)}
          >
            {activeCampaigns?.map((campaign, index) => (
              <CampaignCard
                key={campaign.id}
                data={campaign}
                onAdd={
                  isNotNil(props.onAdd)
                    ? () =>
                        props.onAdd?.({
                          platformCode: data?.platformCode ?? '',
                          serviceCode: data?.serviceCode ?? '',
                          serviceCampaignId: campaign.id ?? '',
                          name: campaign.name,
                        })
                    : undefined
                }
                data-testid={suffixTestId(`activeCampaign-${index}`, props)}
              />
            ))}
          </DataStatus>
        </VStack>
      </Card>
      <Space vertical={4} />
      <Card title={i18n.t('entity.campaigns.labels.previousCampaigns')}>
        <VStack spacing={3}>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.campaigns.labels.previousCampaignsDescription')}
          </Text>
          <DataStatus
            isEmpty={isNilOrEmpty(previousCampaigns)}
            data-testid={suffixTestId('previousCampaignsStatus', props)}
          >
            {previousCampaigns?.map((campaign, index) => (
              <CampaignCard
                key={campaign.id}
                data={campaign}
                data-testid={suffixTestId(`previousCampaign-${index}`, props)}
              />
            ))}
          </DataStatus>
        </VStack>
      </Card>
    </DataStatus>
  );
}
