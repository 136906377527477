import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {values} from 'ramda';

import {vehiclesRoutes} from '@omnetic-dms/routes';
import {PartialVehiclePhotosRoutes} from '@omnetic-dms/shared';
import {selectAudit, selectInspections, selectSalesVehicleData} from '@omnetic-dms/teas';

export const useBatchButtonLocation = () => {
  const vehicleDetail = useSelector(selectSalesVehicleData);
  const inspections = useSelector(selectInspections);
  const auditData = useSelector(selectAudit);

  const params = useParams();

  const location = useMemo(() => {
    if (params?.tab === vehiclesRoutes.DownloadEventVehiclePhotos) {
      return PartialVehiclePhotosRoutes.vehicle;
    }
    if (params?.tab === vehiclesRoutes.DownloadEventInspections) {
      return PartialVehiclePhotosRoutes.inspections;
    }
    if (params?.tab === vehiclesRoutes.DownloadEventCondition) {
      return PartialVehiclePhotosRoutes.condition;
    }

    return PartialVehiclePhotosRoutes.vehicle;
  }, [params?.tab]);

  const isDisabled = useMemo(() => {
    if (location === PartialVehiclePhotosRoutes.vehicle) {
      return vehicleDetail?.photos?.length;
    }
    if (location === PartialVehiclePhotosRoutes.inspections) {
      return inspections?.length || auditData?.id;
    }
    if (location === PartialVehiclePhotosRoutes.condition) {
      return values(auditData?.assets ?? {})?.length;
    }
  }, [location, vehicleDetail?.photos, inspections?.length, auditData?.assets]);

  return [location, !Boolean(isDisabled)] as const;
};
