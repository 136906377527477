import {Button, DialogsContext, showNotification, useAnimatedPopper} from 'platform/components';
import {Box} from 'platform/foundation';

import {useContext} from 'react';

import i18n from '@omnetic-dms/i18n';
import {handleApiError, usePostServiceOrderAddServicePackageMutation} from '@omnetic-dms/shared';

import {TestIdProps} from 'shared';

import {ServicePackages} from '../../../../../components/ServicePackages/ServicePackages';

interface PackagesDropdownProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
}

export function PackagesDropdown(props: PackagesDropdownProps) {
  const {isAnyDialogOpen} = useContext(DialogsContext);

  const {popperProps, Popper, togglePopper, referenceRef} = useAnimatedPopper({
    placement: 'bottom-end',
    gutter: 4,
    isOutsideClickIgnored: isAnyDialogOpen,
  });

  const [postServiceOrderAddServicePackage] = usePostServiceOrderAddServicePackageMutation();

  const handleAddServicePackage = async (servicePackageId: string) => {
    await postServiceOrderAddServicePackage({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      servicePackageId,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  return (
    <>
      <div ref={referenceRef}>
        <Button
          title={i18n.t('entity.workshopServicePackages.labels.packages')}
          variant="outlined"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            togglePopper();
          }}
          data-testid={props['data-testid']}
        />
      </div>
      <Popper {...popperProps}>
        <Box
          borderRadius="medium"
          boxShadow="elevation_2"
          border="1px solid"
          borderColor="palettes.neutral.40.100"
          backgroundColor="general.white"
          padding={3}
          width={116}
        >
          <ServicePackages
            isWithoutTitle
            serviceCaseId={props.serviceCaseId}
            onServicePackageAdd={handleAddServicePackage}
            data-testid={props['data-testid']}
          />
        </Box>
      </Popper>
    </>
  );
}
