import {z} from 'zod';

import {UnionToSameValueObject} from 'shared';

import {BaseIdSchema, BaseVoidResponse, CreatedAndUpdatedBySchema} from './base';

/*
 * COMMON TYPES
 * */

export const SalesPriceCalculationSchema = z.enum([
  'MARGIN_ON_BASE',
  'MARKUP_ON_BASE',
  'PROFIT_ON_BASE',
  'MANUAL',
  'RECOMMENDED_PRICE',
  'MARKETING_CODE',
]);

export type SalesPriceCalculation = z.infer<typeof SalesPriceCalculationSchema>;

export type SalesPriceCalculationObject = UnionToSameValueObject<SalesPriceCalculation>;

export const salesPriceCalculationObject: SalesPriceCalculationObject = {
  MARGIN_ON_BASE: 'MARGIN_ON_BASE',
  MARKUP_ON_BASE: 'MARKUP_ON_BASE',
  PROFIT_ON_BASE: 'PROFIT_ON_BASE',
  MANUAL: 'MANUAL',
  RECOMMENDED_PRICE: 'RECOMMENDED_PRICE',
  MARKETING_CODE: 'MARKETING_CODE',
};

export const BasePriceSourceSchema = z.enum([
  'AVG_PURCHASE',
  'LAST_PURCHASE',
  'RECOMMENDED',
  'MANUAL',
]);

export type BasePriceSource = z.infer<typeof BasePriceSourceSchema>;
export type BasePriceSourceObject = UnionToSameValueObject<BasePriceSource>;
export const basePriceSourceObject: BasePriceSourceObject = {
  AVG_PURCHASE: 'AVG_PURCHASE',
  LAST_PURCHASE: 'LAST_PURCHASE',
  RECOMMENDED: 'RECOMMENDED',
  MANUAL: 'MANUAL',
};

export const ArticleTreeFolderSchema = z.object({
  leafId: z.string().nullable(),
});
export type ArticleTreeFolder = z.infer<typeof ArticleTreeFolderSchema>;

export const PricesSettingsSchema = z.object({
  avgPurchacePriceWithoutVat: z.number(),
  avgPurchacePriceWithVat: z.number(),
  lastPurchacePriceWithoutVat: z.number(),
  lastPurchacePriceWithVat: z.number(),
  recommendedPurchacePriceWithoutVat: z.number(),
  recommendedPurchacePriceWithVat: z.number(),
  salesPriceCalculation: SalesPriceCalculationSchema,
  basePriceSource: BasePriceSourceSchema,
  unitSalesPriceWithVat: z.boolean(),
});
export type PricesSettings = z.infer<typeof PricesSettingsSchema>;

export const DispensingPricesSchema = z.object({
  basePriceWithoutVat: z.number(),
  basePriceWithVat: z.number(),
  saleBasePriceWithoutVat: z.number(),
  saleBasePriceWithVat: z.number(),
  saleBaseProfit: z.number().nullish(),
  saleBaseMarginPercent: z.number().nullish(),
  saleBaseMarkUpPercent: z.number().nullish(),
  warrantyPriceWithoutVat: z.number(),
  warrantyPriceWithVat: z.number(),
  warrantyProfit: z.number(),
  warrantyMarginPercent: z.number(),
  warrantyMarkUpPercent: z.number(),
});
export type DispensingPrices = z.infer<typeof DispensingPricesSchema>;

export const BaseArticleSchema = z.object({
  warehouseId: z.string(),
  supplierId: z.string(),
  manufacturerId: z.string(),
  makeCode: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  stockNumber: z.string().optional(),
  manufacturerNumber: z.string(),
  manufacturerName: z.string().optional(),
  handlingUnit: z.string(),
  dispensingUnit: z.union([z.number(), z.null()]), // Allows number or null
  receivedHandlingQuantity: z.number().optional(),
  vatType: z.string(),
  storageLocation: z.string().optional(),
  discountGroup: z.string().optional(),
  treeFolder: ArticleTreeFolderSchema,
  marketingCode: z.string().optional(),
  warehouseAccount: z.string().optional(),
  pricesSettings: PricesSettingsSchema,
  dispensingPrices: DispensingPricesSchema.partial(),
  isTemporary: z.boolean().optional(),
  isRemovable: z.boolean(),
});
export type BaseArticle = z.infer<typeof BaseArticleSchema>;

export const BaseRecalculationSchema = z.object({
  supplierId: z.string(),
  manufacturerId: z.string(),
  makeCode: z.string(),
  name: z.string(),
  description: z.string(),
  stockNumber: z.string(),
  manufacturerNumber: z.string(),
  handlingUnit: z.string(),
  receivedHandlingQuantity: z.number(),
  vatType: z.string(),
  storageLocation: z.string(),
  discountGroup: z.string(),
  category: z.string(),
  marketingCode: z.string(),
  warehouseAccount: z.string(),
  pricesSettings: PricesSettingsSchema,
  dispensingPrices: DispensingPricesSchema,
});
export type BaseRecalculation = z.infer<typeof BaseRecalculationSchema>;

export const GetArticleAvailabilityRequestSchema = z.object({
  id: z.string(),
  warehouseId: z.string(),
});
export type GetArticleAvailabilityRequest = z.infer<typeof GetArticleAvailabilityRequestSchema>;

export const GetArticleAvailabilityResponseSchema = z.object({
  warehouse_id_fk: z.string().optional(),
  stock_availability_id_fk: z.string().optional(),
  article_id_fk: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  internal_number: z.string().optional(),
  manufacturer_catalog_num: z.string().optional(),
  measurement_unit: z.string().optional(),
  vat_type: z.string().optional(),
  location: z.string().optional(),
  stock_qty: z.number().optional(),
  reserved_qty: z.number().optional(),
  available_qty: z.number().optional(),
  in_transit_qty: z.number().optional(),
  purchase_price: z.number().optional(),
  service_order_price: z.number().optional(),
  direct_sale_price: z.number().optional(),
  created: z.string().optional(),
  created_by: z.string().optional(),
  updated: z.string().optional(),
  updated_by: z.string().optional(),
});
export type GetArticleAvailabilityResponse = z.infer<typeof GetArticleAvailabilityResponseSchema>;

export const GetArticleStockIdResponseSchema = z.object({
  stock_availability_id: z.string().optional(),
});
export type GetArticleStockIdResponse = z.infer<typeof GetArticleStockIdResponseSchema>;

export const GetArticleStockIdRequestSchema = z.object({
  id: z.string(),
  warehouseId: z.string(),
});
export type GetArticleStockIdRequest = z.infer<typeof GetArticleStockIdRequestSchema>;

export type PatchArticleAvailabilityResponse = BaseVoidResponse;

export const PatchArticleAvailabilityRequestSchema = z.object({
  warehouseId: z.string(),
  id: z.string(),
  body: z.object({
    stock_qty: z.number().optional(),
    reserved_qty: z.number().optional(),
    available_qty: z.number().optional(),
    in_transit_qty: z.number().optional(),
    stock_qty_relative: z.number().optional(),
    reserved_qty_relative: z.number().optional(),
    available_qty_relative: z.number().optional(),
    in_transit_qty_relative: z.number().optional(),
  }),
});
export type PatchArticleAvailabilityRequest = z.infer<typeof PatchArticleAvailabilityRequestSchema>;

export const PostArticleAvailabilityResponseSchema = z.object({
  stock_availability_id: z.string().optional(),
});
export type PostArticleAvailabilityResponse = z.infer<typeof PostArticleAvailabilityResponseSchema>;

export const PostArticleAvailabilityRequestSchema = z.object({
  warehouseId: z.string(),
  body: z.object({
    article_id_fk: z.string().optional(),
    stock_qty: z.number().optional(),
    reserved_qty: z.number().optional(),
    available_qty: z.number().optional(),
    in_transit_qty: z.number().optional(),
  }),
});
export type PostArticleAvailabilityRequest = z.infer<typeof PostArticleAvailabilityRequestSchema>;

/*
 *  Stock availability
 *
 * */

export const StockAvailabilityRequestSchema = z.object({articleId: z.string()});
export type StockAvailabilityRequest = z.infer<typeof StockAvailabilityRequestSchema>;

export const StockAvailabilityResponseSchema = z.object({
  articleId: z.string(),
  stockQuantity: z.number(),
  reservedQuantity: z.number(),
  availableQuantity: z.number(),
  inTransitQuantity: z.number(),
});
export type StockAvailabilityResponse = z.infer<typeof StockAvailabilityResponseSchema>;

export const PatchArticleDefaultSupplierRequestSchema = z.object({
  articleId: z.string(),
  body: z.object({
    supplierId: z.string(),
  }),
});
export type PatchArticleDefaultSupplierRequest = z.infer<
  typeof PatchArticleDefaultSupplierRequestSchema
>;

export type PatchArticleDefaultSupplierResponse = BaseVoidResponse;

export const PatchArticleInfoRequestSchema = z.object({
  articleId: z.string(),
  body: z.object({
    name: z.string(),
  }),
});
export type PatchArticleInfoRequest = z.infer<typeof PatchArticleInfoRequestSchema>;

export type PatchArticleInfoResponse = BaseVoidResponse;

/*
 *  Article
 *
 * */

export const WarehouseArticlesRowSchema = z.object({
  id: z.string(),
  warehouseId: z.object({
    value: z.object({
      key: z.string(),
      label: z.string(),
    }),
  }),
});

export type WarehouseArticlesRow = z.infer<typeof WarehouseArticlesRowSchema>;

export const BaseArticleStockItemSchema = z.object({
  supplierId: z.string(),
  storageDate: z.string(),
  storageLocation: z.string(),
  purchasePrice: z.number(),
  stockQuantity: z.number(),
  value: z.number(),
  stockMovementType: z.string(),
});

export type BaseArticleStockItem = z.infer<typeof BaseArticleStockItemSchema>;

export const GetArticleStockItemsRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
});

export type GetArticleStockItemsRequest = z.infer<typeof GetArticleStockItemsRequestSchema>;

export const GetArticleStockItemsResponseSchema = z.array(BaseArticleStockItemSchema);
export type GetArticleStockItemsResponse = BaseArticleStockItem[];

export const GetArticleStockItemRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
  stockItemId: z.string(),
});

export type GetArticleStockItemRequest = z.infer<typeof GetArticleStockItemRequestSchema>;

export const GetArticleStockItemResponseSchema = BaseArticleStockItemSchema;
export type GetArticleStockItemResponse = z.infer<typeof GetArticleStockItemResponseSchema>;

export const GetArticleStockItemOverviewRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
});

export type GetArticleStockItemOverviewRequest = z.infer<
  typeof GetArticleStockItemOverviewRequestSchema
>;

export const GetArticleStockItemOverviewResponseSchema = z.object({
  articleId: z.string(),
  totalValue: z.string(),
  totalInventory: z.string(),
  lastReception: z.number(),
});

export type GetArticleStockItemOverviewResponse = z.infer<
  typeof GetArticleStockItemOverviewResponseSchema
>;

export const GetArticleStockAvailabilityOverviewRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
});

export type GetArticleStockAvailabilityOverviewRequest = z.infer<
  typeof GetArticleStockAvailabilityOverviewRequestSchema
>;

export const GetArticleStockAvailabilityOverviewResponseSchema = z.object({
  articleId: z.string(),
  stockQuantity: z.number(),
  reservedQuantity: z.number(),
  availableQuantity: z.number(),
  inTransitOnStockQuantity: z.number(),
  inTransitForRequestsQuantity: z.number(),
  inTransitQuantity: z.number(),
  pendingCreditNoteQuantity: z.number(),
  lastOrder: z.string(),
  lastReception: z.string(),
  lastDispatch: z.string(),
});

export type GetArticleStockAvailabilityOverviewResponse = z.infer<
  typeof GetArticleStockAvailabilityOverviewResponseSchema
>;

/*
 *  GET
 * */

export const GetArticleRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
});

export type GetArticleRequest = z.infer<typeof GetArticleRequestSchema>;

export const GetArticleResponseSchema =
  BaseArticleSchema.merge(BaseIdSchema).merge(CreatedAndUpdatedBySchema);
export type GetArticleResponse = z.infer<typeof GetArticleResponseSchema>;

/*
 *  GET BY ManufacturerNumber
 *
 * */

export const GetArticleIdByManufacturerNumberRequestSchema = z.object({
  warehouseId: z.string(),
  manufacturerNumber: z.string(),
});

export type GetArticleIdByManufacturerNumberRequest = z.infer<
  typeof GetArticleIdByManufacturerNumberRequestSchema
>;

export const GetArticleIdByManufacturerNumberResponseSchema = z.object({
  articleId: z.string(),
});

export type GetArticleIdByManufacturerNumberResponse = z.infer<
  typeof GetArticleIdByManufacturerNumberResponseSchema
>;
/*
 *  GET by manufacturerNumber and manufacturerId
 *
 * */

export const GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema = z.object({
  warehouseId: z.string(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
});

export type GetArticleIdByManufacturerNumberAndManufacturerIdRequest = z.infer<
  typeof GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema
>;

export const GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema = z.object({
  articleId: z.string(),
});

export type GetArticleIdByManufacturerNumberAndManufacturerIdResponse = z.infer<
  typeof GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema
>;

/*
 * POST
 * */

export const CreateArticleRequestSchema = z.object({
  warehouseId: z.string(),
  body: BaseArticleSchema.partial(),
});
export type CreateArticleRequest = z.infer<typeof CreateArticleRequestSchema>;

export const CreateArticleResponseSchema = z.object({
  articleId: z.string(),
});
export type CreateArticleResponse = z.infer<typeof CreateArticleResponseSchema>;

/*
 * PATCH
 * */

export const UpdateArticleRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
  body: BaseArticleSchema.partial(),
});
export type UpdateArticleRequest = z.infer<typeof UpdateArticleRequestSchema>;

export const UpdateArticleResponseSchema = BaseArticleSchema;
export type UpdateArticleResponse = z.infer<typeof UpdateArticleResponseSchema>;

/*
 *  DELETE
 * */

export const DeleteArticleRequestSchema = z.object({
  articleId: z.array(z.string()),
});
export type DeleteArticleRequest = z.infer<typeof DeleteArticleRequestSchema>;

export type DeleteArticleResponse = BaseVoidResponse;

/*
 * CALCULATION
 * */

const changedFieldValueSchema = z.object({
  field: z.string(),
  value: z.string(),
});

export const CalculateArticleRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
  body: z.object({
    changedFieldValue: changedFieldValueSchema,
    currentFeFieldsValues: BaseRecalculationSchema.partial(),
  }),
});
export type CalculateArticleRequest = z.infer<typeof CalculateArticleRequestSchema> & {
  signal: AbortSignal;
};

export const CalculateArticleResponseSchema = z.object({
  currency: z.string(),
  pricesSettings: PricesSettingsSchema,
  dispensingPrices: DispensingPricesSchema,
});
export type CalculateArticleResponse = z.infer<typeof CalculateArticleResponseSchema>;

/**
 * Supplier articles
 */
export const GetSupplierArticleRequestSchema = z.object({
  deliveryQuantityMappingId: z.string(),
});

export type GetSupplierArticleRequest = z.infer<typeof GetSupplierArticleRequestSchema>;

export const GetSupplierArticleResponseSchema = z.object({
  supplierId: z.string(),
  supplierName: z.string().optional(),
  supplierOrderingNumber: z.string().optional(),
  articleId: z.string(),
  articleName: z.string().optional(),
  manufacturerNumber: z.string().optional(),
  manufacturerName: z.string().optional(),
  deliveryQuantity: z.number(),
  deliveryUnitId: z.string(),
  receiveQuantity: z.number(),
  receiveUnitId: z.string(),
  supplierBulkPackageQuantity: z.number(),
  recommendedPriceWithoutVat: z.number().optional(),
  purchasePriceWithoutVat: z.number().optional(),
  currency: z.string().optional(),
});

export type GetSupplierArticleResponse = z.infer<typeof GetSupplierArticleResponseSchema>;
