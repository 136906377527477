/* eslint-disable filename-export/match-named-export */
import {CountryCode} from 'libphonenumber-js';
import {formatPhoneNumber} from 'platform/locale';

import {CountryResponseBody, PersonResponseBodyV2} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {IdentityCardData} from '../types/IdentityCardData';
import {IdentityCardType} from '../types/IdentityCardType';
import {Person} from '../types/Person';

export const getPersonFullName = (person?: PersonResponseBodyV2 | null) => {
  if (!person) {
    return '';
  }
  return [person.titleBefore, person.firstName, person.lastName, person.titleAfter]
    .filter((x) => !!x)
    .join(' ');
};

export const getPersonName = (person?: Person | null) => {
  if (!person) {
    return '';
  }
  return [person.personData.firstName, person.personData.lastName].filter((x) => !!x).join(' ');
};

export const getDefaultPersonPhoneNumber = (
  person: PersonResponseBodyV2 | undefined | null
): string => {
  if (!person) {
    return '';
  }
  if (!person.phoneNumbers?.[0]) {
    return '';
  }

  const phoneNumber = `${person.phoneNumbers[0]?.prefix}${person.phoneNumbers[0]?.number}`;
  return formatPhoneNumber(phoneNumber, person.phoneNumbers[0]?.countryCode as CountryCode) ?? '';
};

export const getDefaultPersonEmail = (person?: PersonResponseBodyV2 | null) => {
  if (!person) {
    return '';
  }
  return person.emails?.[0]?.email || '';
};

export const getPersonInformation = (person: PersonResponseBodyV2 | null) => {
  if (!person) {
    return '';
  }

  if (person?.firstName || person?.lastName) {
    return getPersonFullName(person);
  } else if (person?.phoneNumbers?.length) {
    return getDefaultPersonPhoneNumber(person);
  } else {
    return getDefaultPersonEmail(person);
  }
};

export const getPersonIdentityCard = (
  identityCardData: IdentityCardData | Nullish,
  countries?: CountryResponseBody[],
  identityCardTypes?: IdentityCardType[]
) => {
  if (!identityCardData) {
    return '';
  }

  const country = countries?.find(
    (country) => country.code === identityCardData.issuedInCountryCode
  );

  const identityCardType = identityCardTypes?.find(
    (idType) => idType.code === identityCardData.type
  );

  return [
    identityCardType?.name || identityCardData.type,
    identityCardData.cardNumber,
    identityCardData.issuer,
    country?.name || identityCardData.issuedInCountryCode,
  ]
    .filter((x) => !!x)
    .join(', ');
};
