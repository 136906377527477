import {Alert, Card, DataStatus, Form, FormField} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {assoc} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {useBranches, useGetCustomerSeriesDefinitionQuery} from '@omnetic-dms/shared';
import {selectSeries, Series, noop} from '@omnetic-dms/teas';

const systemBranchId = 'seriesDefinition1';
const systemBranchOption = [{value: systemBranchId, label: i18n.t('general.labels.all')}];

export function CustomerSeriesDefinitionDetail() {
  const params = useParams<{id: string}>();
  const {
    data: definition,
    isLoading: isDefinitionLoading,
    isError: isDefinitionErrored,
  } = useGetCustomerSeriesDefinitionQuery(
    {seriesDefinitionId: params.id ?? ''},
    {skip: !params.id}
  );
  const {branchOptions: tenantBranchOptions} = useBranches();

  // there are no assigned branches for "systemDefault", since its valid for all branches
  // therefore BE sends `branchId: null`
  const defaultValues = definition?.systemDefault
    ? assoc('branchId', systemBranchId, definition)
    : definition;

  const branchOptions = definition?.systemDefault ? systemBranchOption : tenantBranchOptions;
  const seriesOptions = useSelector(selectSeries).map(seriesToOption);

  return (
    <Card title={definition?.name}>
      <VStack spacing={4}>
        <Alert
          type="inline"
          variant="info"
          message={i18n.t('entity.seriesDefinition.notification.defaultSeriesNotEditable')}
        />

        <DataStatus isLoading={isDefinitionLoading} isError={isDefinitionErrored} minHeight={15}>
          <Form onSubmit={noop} defaultValues={defaultValues} schema={schema}>
            {(control) => (
              <Box width={90}>
                <VStack spacing={4}>
                  <FormField
                    name="name"
                    type="text"
                    control={control}
                    label={i18n.t('entity.seriesDefinition.labels.definitionName')}
                    data-testid={testIds.settings.customerCodeDefinition('name')}
                    isDisabled={definition?.systemDefault}
                  />
                  <FormField
                    name="seriesId"
                    type="choice"
                    control={control}
                    label={i18n.t('entity.seriesDefinition.labels.customerCodeDefinitionSeries')}
                    data-testid={testIds.settings.customerCodeDefinition('seriesId')}
                    options={seriesOptions}
                    isDisabled={definition?.systemDefault}
                  />
                  <FormField
                    name="branchId"
                    type="choice"
                    control={control}
                    label={i18n.t('entity.branch.labels.branch')}
                    data-testid={testIds.settings.customerCodeDefinition('branchId')}
                    options={branchOptions}
                    isDisabled={definition?.systemDefault}
                  />
                </VStack>
              </Box>
            )}
          </Form>
        </DataStatus>
      </VStack>
    </Card>
  );
}

const seriesToOption = (series: Series) => ({
  value: series.id,
  label: series.name,
});

const schema = Yup.object().shape({
  name: Yup.string().required(),
  seriesId: Yup.string(),
  branchId: Yup.string(),
});
