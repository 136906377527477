import {Card, DataStatus} from 'platform/components';
import {GridItem, Grid} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {interestsRoutes, testIds} from '@omnetic-dms/routes';
import {useGetInterestStatisticsQuery} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {StatisticColumn} from './StatisticColumn';

interface InterestStatisticsCardProps {
  branchId: string | Nullish;
}

export function InterestStatisticsCard(props: InterestStatisticsCardProps) {
  const {data, isLoading, isError} = useGetInterestStatisticsQuery(
    {
      branchId: props.branchId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Card
      variant="inlineWhite"
      data-testid={testIds.dashboard.home('card-interest')}
      title={i18n.t('entity.interest.labels.interests')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Grid columns={4}>
          <GridItem>
            <StatisticColumn
              label={i18n.t('general.labels.open')}
              count={data?.open}
              href={interestsRoutes.home}
              data-testid={testIds.dashboard.home('interest')}
            />
          </GridItem>
          <GridItem>
            <StatisticColumn label={i18n.t('general.labels.sell')} count={data?.sell} />
          </GridItem>
          <GridItem>
            <StatisticColumn label={i18n.t('general.labels.buy')} count={data?.buy} />
          </GridItem>
          <GridItem>
            <StatisticColumn label={i18n.t('general.labels.swap')} count={data?.swap} />
          </GridItem>
        </Grid>
      </DataStatus>
    </Card>
  );
}
