import {
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Center, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {Helmet} from 'react-helmet-async';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {interestRoutes, testIds} from '@omnetic-dms/routes';
import {
  CreateInterestApiArg,
  CustomerResponseBodyV2,
  CustomerWidgetCard,
  EntityHeader,
  handleApiError,
  Page,
  Section,
  useCreateInterestMutation,
  useCreateMinisaleMutation,
  useInterestCatalogueQuery,
  useCodeList,
  queryParams,
  CreateMinisaleApiArg,
  useCreateMinipurchaseMutation,
  CreateMinipurchaseApiArg,
  interestSearchParams,
} from '@omnetic-dms/shared';

import {composePath, useNavigate, yupString} from 'shared';

type CreateInterestForm = {
  type: ['SELLING' | 'BUYING' | 'SWAP'];
  customer?: CustomerResponseBodyV2;
  sourceCodeId?: [string];
};

export function InterestCreate() {
  const navigate = useNavigate();

  const [createInterest, {isLoading: isCreateInterestLoading}] = useCreateInterestMutation();
  const [createMinisale, {isLoading: isCreateMinisaleLoading}] = useCreateMinisaleMutation();
  const [createMinipurchase, {isLoading: isCreateMinipurchaseLoading}] =
    useCreateMinipurchaseMutation();
  const {
    data: interestCatalogueData,
    isLoading: isInterestCatalogueLoading,
    isError: isInterestCatalogueErrored,
  } = useInterestCatalogueQuery();
  const [interestSourcesCodelist] = useCodeList('interest_source');

  const handleSubmit: FormSubmitHandler<CreateInterestForm> = async (values) => {
    if (!values.customer?.id || !values.sourceCodeId) {
      showNotification.error();
      return;
    }

    const args: CreateInterestApiArg = {
      createInterestRequestBody: {
        customerId: values.customer?.id,
        sourceCodeId: values.sourceCodeId[0],
        type: values.type[0],
      },
    };

    await createInterest(args)
      .unwrap()
      .then((interest) => {
        if (!interest?.id) {
          showNotification.error();
          return;
        }

        const navigateToDetail = () => {
          navigate(
            composePath(interestRoutes.detail, {
              params: {
                id: interest.id,
              },
              queryParams: {
                [queryParams.COMPONENT_SECTIONS_SECTION]:
                  interest.type !== 'BUYING'
                    ? interestSearchParams.interestDetail.selling
                    : interestSearchParams.interestDetail.buying,
              },
            })
          );
        };

        if (interest.type === 'BUYING') {
          const args: CreateMinisaleApiArg = {
            createMinisaleRequestBody: {
              interestId: interest.id,
              customerBuyingStatement: false,
              isVatDeductible: false,
            },
          };

          createMinisale(args).unwrap().then(navigateToDetail).catch(handleApiError);
        }

        if (interest.type === 'SELLING') {
          const args: CreateMinipurchaseApiArg = {
            body: {
              interestId: interest.id,
            },
          };
          createMinipurchase(args).unwrap().then(navigateToDetail).catch(handleApiError);
        }

        if (interest.type === 'SWAP') {
          const minisaleArgs: CreateMinisaleApiArg = {
            createMinisaleRequestBody: {
              interestId: interest.id,
              customerBuyingStatement: false,
              isVatDeductible: false,
            },
          };
          const minipurchaseArgs: CreateMinipurchaseApiArg = {
            body: {
              interestId: interest.id,
            },
          };
          createMinipurchase(minipurchaseArgs)
            .unwrap()
            .then(() => createMinisale(minisaleArgs).unwrap().catch(handleApiError))
            .then(navigateToDetail)
            .catch(handleApiError);
        }
      })
      .catch(handleApiError);
  };

  return (
    <>
      <Helmet title={i18n.t('page.interestConcept.title')} />
      <Form<CreateInterestForm> schema={schema} onSubmit={handleSubmit}>
        {(control, formApi) => (
          <DataStatus
            data-testid={testIds.interest.create('createInterest')}
            isLoading={isInterestCatalogueLoading}
            isError={isInterestCatalogueErrored}
            minHeight={200}
          >
            <Page
              data-testid={testIds.interest.create('createInterest')}
              isFullHeight
              header={
                <EntityHeader
                  data-testid={testIds.interest.create('header')}
                  title={i18n.t('entity.interest.labels.newInterest')}
                  actions={[
                    {
                      'data-testid': testIds.interest.create('header-discard'),
                      type: 'button',
                      variant: 'secondary',
                      title: i18n.t('general.actions.discard'),
                      isDisabled:
                        isCreateInterestLoading ||
                        isCreateMinisaleLoading ||
                        isCreateMinipurchaseLoading,
                      onClick: () => navigate(interestRoutes.overview),
                    },
                    {
                      'data-testid': testIds.interest.create('header-create'),
                      type: 'form-button',
                      variant: 'primary',
                      title: i18n.t('general.actions.create'),
                      control,
                      isDisabled: isNilOrEmpty(formApi.watch('customer')),
                      isLoading:
                        isCreateInterestLoading ||
                        isCreateMinisaleLoading ||
                        isCreateMinipurchaseLoading,
                      buttonType: 'submit',
                    },
                  ]}
                  flags={[
                    {label: i18n.t('entity.interest.labels.concept'), colorScheme: 'neutral'},
                  ]}
                  icon="content/next_week"
                  isTagDeletable
                  isTagUpdatable
                  isTagReadable
                  isTagCreatable
                  isTagAssignable
                  isTagUnassignable
                />
              }
            >
              <Section>
                <Center>
                  <VStack spacing={4} maxWidth={166} width="100%">
                    <Card title={i18n.t('entity.interest.labels.interest')}>
                      <VStack spacing={4}>
                        <FormField
                          data-testid={testIds.interest.create('source')}
                          control={control}
                          label={i18n.t('entity.interest.labels.source')}
                          name="sourceCodeId"
                          type="chips"
                          options={
                            interestSourcesCodelist?.map((item) => ({
                              label: item.name,
                              value: item.codeId,
                            })) ?? []
                          }
                        />
                        <FormField
                          data-testid={testIds.interest.create('type')}
                          control={control}
                          label={i18n.t('entity.interest.labels.type')}
                          name="type"
                          type="chips"
                          options={
                            interestCatalogueData?.type?.map((item) => ({
                              label: item.name,
                              value: item.key,
                            })) ?? []
                          }
                        />
                      </VStack>
                    </Card>
                    <CustomerWidgetCard
                      data-testid={testIds.interest.create('customer')}
                      isContactPersonHidden
                      secondStepComponentType="BUSINESS_CASE"
                      customer={formApi.watch('customer')}
                      onChange={() => formApi.setValue('customer', undefined)}
                      onCustomer={(customer) => formApi.setValue('customer', customer)}
                    />
                  </VStack>
                </Center>
              </Section>
            </Page>
          </DataStatus>
        )}
      </Form>
    </>
  );
}

const schema = object().shape({
  customer: object().shape({
    id: yupString.required(),
  }),
  sourceCodeId: array().of(yupString).min(1).required(),
  type: array().of(yupString).min(1).required(),
});
