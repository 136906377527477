import {Switch} from '../../Switch/Switch';
import {useFieldProps} from '../hooks/useFieldProps';
import {SwitchRendererResponseBody} from '../types/modelSchema';

export function SwitchRenderer(props: SwitchRendererResponseBody) {
  const {name} = props;
  const {onChange, onBlur, onFocus, value} = useFieldProps(props);

  return (
    <Switch
      label={props.label}
      data-testid={name}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || ''}
    />
  );
}
