import {
  ButtonGroup,
  Card,
  Checkbox,
  Choice,
  DataStatus,
  Flag,
  IconButton,
  NumberInput,
  TextInput,
  Tooltip,
  openDeleteDialog,
} from 'platform/components';
import {Box, HStack, Heading, Space, VStack, Text, Show} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useRef, useState} from 'react';

import {always, defaultTo, head, isNil, isNotNil, not} from 'ramda';
import {isArray, isNilOrEmpty} from 'ramda-adjunct';

import {
  DOT_CHARACTER,
  Nullish,
  TestIdProps,
  getRandomId,
  precisionCalculation,
  suffixTestId,
  yupNumber,
} from 'shared';

import {useGetHandlingUnitQuery} from '../../../api/metadaWarehouseHandlingUnitApi';
import {useGetServiceCaseOrderJobsQuery} from '../../../api/metadaWorkshopServiceOrderJobApi';
import {usePutServiceOrderItemsToOtherOrderJobMutation} from '../../../api/metadaWorkshopServiceOrderJobItemApi';
import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useBasketItemQuantity} from '../../../hooks/useBasketItemQuantity';
import i18n from '../../../i18n/i18n';
import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {JobChange} from '../../../types/basket/JobChange';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {MaterialBasketItem} from '../../../types/basket/MaterialBasketItem';
import {MaterialBasketItemEditingDetails} from '../../../types/basket/MaterialBasketItemEditingDetails';
import {getOptionsFromServiceCaseOrderJobs} from '../../../utils/getOptionsFromServiceCaseOrderJobs';
import {handleApiError} from '../../../utils/handleApiError';

interface AfterSalesMaterialBasketItemProps<T extends MaterialBasketItem> extends TestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: MaterialBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
  /**
   * @about warehouse only, used for moving item to other order job directly
   */
  jobChange?: JobChange;
}

export function AfterSalesMaterialBasketItem<T extends MaterialBasketItem>(
  props: AfterSalesMaterialBasketItemProps<T>
) {
  const formatCurrency = useFormatCurrency();

  const [selectedServiceCaseJobId, setSelectedServiceCaseJobId] = useState<string | Nullish>(
    props.item.serviceCaseJobId
  );

  const {data: unit, isLoading: isUnitLoading} = useGetHandlingUnitQuery(
    {id: props.item.unit ?? ''},
    {skip: isNil(props.item.unit)}
  );

  const {data: serviceCaseOrderJobs, isLoading: isServiceCaseOrderJobsLoading} =
    useGetServiceCaseOrderJobsQuery(
      {
        serviceOrderId: props.jobChange?.serviceOrderId as string,
        serviceCaseId: props.jobChange?.serviceCaseId as string,
      },
      {
        skip:
          not(props.jobChange?.canPerformJobChange) ||
          isNilOrEmpty(props.jobChange?.serviceOrderId) ||
          isNilOrEmpty(props.jobChange?.serviceCaseId),
      }
    );

  const [putServiceOrderItemsToOtherOrderJob, {isLoading: isMoveLoading}] =
    usePutServiceOrderItemsToOtherOrderJobMutation();

  const {quantity, quantityError, handleQuantityChange} = useBasketItemQuantity({
    itemId: props.item.id,
    currentQuantity: props.item.quantity,
    validationSchema: quantitySchema(props.item.dispensingUnit ?? 1),
    onQuantityChange: props.onQuantityChange,
  });

  const prevSelectedServiceCaseJobId = useRef<string | Nullish>(null);

  const dispensingUnit = defaultTo(1, props.item.dispensingUnit);

  const totalPrice = formatCurrency(
    props.item.totalPrice.withoutVat.amount ?? 0,
    props.item.totalPrice.withoutVat.currency ?? DEFAULT_CURRENCY,
    2
  );

  const tooltip = props.item.tooltip
    ? props.item.tooltip.map((item, index) => (
        <>
          {index > 0 && <Space vertical={2} />}
          <Text size="xSmall" color="white">
            {item.label}
          </Text>
          <Text size="xSmall" color="white">
            {item.value}
          </Text>
        </>
      ))
    : null;

  const initiallySelectedServiceCaseJobId = props.item.serviceCaseJobId;

  const serviceCaseJobId = match([
    isNil(selectedServiceCaseJobId),
    isNil(initiallySelectedServiceCaseJobId),
  ])
    .with([true, false], always(initiallySelectedServiceCaseJobId))
    .with([false, false], always(selectedServiceCaseJobId))
    .otherwise(always(selectedServiceCaseJobId));

  const handleEditItem = () => {
    if (isNil(props.item.id)) {
      throw new Error("Missing item's id");
    }

    if (props.jobChange?.canPerformJobChange) {
      return props.onEdit({
        itemId: props.item.serviceOrderJobItemId as string,
        itemName: props.item.name,
        itemNumber: props.item?.number ?? props.item?.manufacturerNumber!,
        serviceCaseId: props.jobChange?.serviceCaseId,
        serviceOrderId: props.jobChange?.serviceOrderId,
        serviceJobId: props.item.serviceCaseJobId,
      });
    }

    props.onEdit({
      itemId: props.item.id,
      itemName: props.item.name,
      itemNumber: props.item?.number ?? props.item?.manufacturerNumber!,
    });
  };

  const handleDeleteItem = () => {
    openDeleteDialog({
      name: props.item.name,
      onConfirm: () => props.onDelete([props.item.id]),
      'data-testid': suffixTestId('delete', props),
    });
  };

  const handleJobChange = (serviceCaseJobId: string | number | string[] | null) => {
    if (not(props.jobChange?.canPerformJobChange)) {
      return;
    }

    prevSelectedServiceCaseJobId.current = selectedServiceCaseJobId;

    const parsedValue = isArray(serviceCaseJobId) ? head(serviceCaseJobId) : serviceCaseJobId;

    const selectedJob = serviceCaseOrderJobs?.find(
      (serviceCaseOrderJob) => serviceCaseOrderJob?.id === parsedValue
    );

    if (isNil(selectedJob) || isNil(prevSelectedServiceCaseJobId.current)) {
      return;
    }

    putServiceOrderItemsToOtherOrderJob({
      serviceCaseId: props.jobChange?.serviceCaseId as string,
      serviceOrderId: props.jobChange?.serviceOrderId as string,
      body: {
        serviceMoveToJobId: selectedJob.id as string,
        serviceMoveToOrderId: props.jobChange?.serviceOrderId as string,
        items: [
          {
            serviceJobId: prevSelectedServiceCaseJobId.current,
            serviceItemId: props.item.serviceOrderJobItemId as string,
          },
        ],
      },
    })
      .unwrap()
      .then(() => setSelectedServiceCaseJobId(selectedJob.id))
      .catch((error) => {
        handleApiError(error);
        setSelectedServiceCaseJobId(prevSelectedServiceCaseJobId.current);
      });
  };

  return (
    <Card variant="inlineGrey">
      <DataStatus isLoading={isUnitLoading} minHeight={25}>
        <VStack spacing={1}>
          <HStack justify="space-between">
            <HStack spacing={2}>
              <Checkbox
                value={props.item.isSelected}
                onChange={(isSelected) => props.onSelect(props.item.id, isSelected)}
                data-testid={suffixTestId('isSelected', props)}
              />
              <Heading size={4} data-testid={suffixTestId('number', props)}>
                {props.item?.number ?? props.item?.manufacturerNumber}
              </Heading>
            </HStack>
            <Box>
              <ButtonGroup>
                {tooltip && (
                  <Tooltip
                    key={`basketItemTooltip-${getRandomId()}`}
                    placement="top"
                    description={tooltip}
                  >
                    <IconButton
                      icon="action/info_outline"
                      size="small"
                      priority="secondary"
                      data-testid={suffixTestId('tooltip', props)}
                    />
                  </Tooltip>
                )}

                <IconButton
                  icon="image/edit"
                  size="small"
                  priority="secondary"
                  isDisabled={not(props.item.itemEditingAllowed)}
                  onClick={handleEditItem}
                  data-testid={suffixTestId('edit', props)}
                />
                <IconButton
                  icon="navigation/close"
                  size="small"
                  priority="primary"
                  severity="danger"
                  onClick={handleDeleteItem}
                  data-testid={suffixTestId('delete', props)}
                />
              </ButtonGroup>
            </Box>
          </HStack>

          <HStack spacing={1} align="center">
            <Show when={isNotNil(props.item.requestType)}>
              <Flag
                label={props.item.requestType!}
                colorScheme="neutral"
                size="small"
                data-testid={suffixTestId('requestType', props)}
              />
              <Text size="xSmall" color="tertiary">
                {DOT_CHARACTER}
              </Text>
            </Show>

            <Text size="xSmall" color="tertiary" data-testid={suffixTestId('name', props)}>
              {props.item.name}
            </Text>
          </HStack>

          <Space vertical={2} />

          <HStack justify="space-between">
            <HStack spacing={2}>
              <Box width={29}>
                <NumberInput
                  value={quantity}
                  step={dispensingUnit}
                  errorMessage={quantityError}
                  decimalPlaces={4}
                  onChange={handleQuantityChange}
                  isStepperVisible
                  shouldRoundStepsByDifference
                  isInvalid={isNotNil(quantityError)}
                  isDisabled={not(props.item.quantityEditingAllowed)}
                  data-testid={suffixTestId('quantity', props)}
                />
              </Box>
              <Box width={11}>
                <TextInput
                  value={unit?.name ?? 'pcs'}
                  isDisabled
                  data-testid={suffixTestId('unit', props)}
                />
              </Box>
            </HStack>

            <VStack align="flex-end">
              <Text size="xSmall" color="tertiary">
                {i18n.t('general.labels.sellingPrice')}
              </Text>
              <Text size="xSmall" alternative data-testid={suffixTestId('price', props)}>
                {totalPrice}
              </Text>
            </VStack>
          </HStack>

          <Show when={props.jobChange?.canPerformJobChange}>
            <Space vertical={2} />
            <Choice
              isNotClearable
              isDisabled={not(props.item.itemEditingAllowed)}
              isLoading={isServiceCaseOrderJobsLoading || isMoveLoading}
              value={defaultTo('', serviceCaseJobId)}
              onChange={handleJobChange}
              options={getOptionsFromServiceCaseOrderJobs(serviceCaseOrderJobs)}
              data-testid={suffixTestId('serviceCaseJobId', props)}
            />
          </Show>
        </VStack>
      </DataStatus>
    </Card>
  );
}

const quantitySchema = (dispensingUnit: number) =>
  yupNumber
    .min(0.0001, (params) => i18n.t('general.errors.number.greaterOrEqual', {min: params.min}))
    .test(
      'isQuantityDivisibleByDispensingUnit',
      `${i18n.t('entity.warehouse.labels.quantityMustBeMultipleOfDispensingUnit')} (${dispensingUnit})`,
      (value) => precisionCalculation.modulo(defaultTo(0, value), dispensingUnit) === 0
    );
