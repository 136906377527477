import {
  GetPhotoSettingsApiResponse,
  GetPhotoSettingsApiArg,
  UpdatePhotoSettingsApiResponse,
  UpdatePhotoSettingsApiArg,
} from '../types/api/api';
import {omneticApi} from './baseApi/omneticApi';

export const publishingPlatformPhotosSettingsApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getPhotoSettings: build.query<GetPhotoSettingsApiResponse, GetPhotoSettingsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/${queryArg.platformCode}/settings/photo`,
        params: queryArg.params,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'photoSettings', id: queryArg.platformCode},
      ],
    }),
    updatePhotoSettings: build.mutation<UpdatePhotoSettingsApiResponse, UpdatePhotoSettingsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/${queryArg.platformCode}/settings/photo`,
        method: 'PUT',
        body: queryArg.updatePlatformPhotoSettingsRequestBody,
        params: queryArg.params,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'photoSettings', id: queryArg.platformCode},
      ],
    }),
  }),
});

export const {useGetPhotoSettingsQuery, useUpdatePhotoSettingsMutation} =
  publishingPlatformPhotosSettingsApi;
