import {TabProps, TabsHeader} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {useState} from 'react';

import {propEq} from 'ramda';

import {TestIdProps, suffixTestId} from 'shared';

import {useGetCustomerContractBasicInformationQuery} from '../../api/metadaCustomerContractApi';
import i18n from '../../i18n/i18n';
import {FullScreenModal} from '../FullScreenModal/FullScreenModal';
import {ContractBasicInfoTab} from './components/ContractBasicInfoTab';
import {ContractIndividualPricesTab} from './components/ContractIndividualPricesTab';
import {ContractMaterialDiscountsTab} from './components/ContractMaterialDiscountsTab';
import {ContractWorkDiscountsTab} from './components/ContractWorkDiscountsTab';

const TAB_IDS = {
  BASIC_INFORMATION: 'basicInformation',
  WORK_DISCOUNTS: 'workDiscounts',
  MATERIAL_DISCOUNTS: 'materialDiscounts',
  INDIVIDUAL_PRICES: 'individualPrices',
};

interface CustomerContractModalProps extends TestIdProps {
  customerContractId: string;
  onClose: VoidFunction;
}

export function CustomerContractModal(props: CustomerContractModalProps) {
  const [activeTabId, setActiveTabId] = useState(TAB_IDS.BASIC_INFORMATION);

  const {data} = useGetCustomerContractBasicInformationQuery({
    customerContractId: props.customerContractId,
  });

  const tabs: TabProps[] = [
    {
      title: i18n.t('general.labels.basicInformation'),
      id: TAB_IDS.BASIC_INFORMATION,
      'data-testid': suffixTestId('basicInfo', props),
      content: (
        <ContractBasicInfoTab
          customerContractId={props.customerContractId}
          data-testid={suffixTestId('basicInfo', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.customerContract.labels.workDiscounts'),
      id: TAB_IDS.WORK_DISCOUNTS,
      'data-testid': suffixTestId('workDiscounts', props),
      content: (
        <ContractWorkDiscountsTab
          customerContractId={props.customerContractId}
          data-testid={suffixTestId('workDiscounts', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.customerContract.labels.materialDiscounts'),
      id: TAB_IDS.MATERIAL_DISCOUNTS,
      'data-testid': suffixTestId('materialDiscounts', props),
      content: (
        <ContractMaterialDiscountsTab
          customerContractId={props.customerContractId}
          data-testid={suffixTestId('materialDiscounts', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.customerContract.labels.individualPrices'),
      id: TAB_IDS.INDIVIDUAL_PRICES,
      'data-testid': suffixTestId('individualPrices', props),
      content: (
        <ContractIndividualPricesTab
          customerContractId={props.customerContractId}
          data-testid={suffixTestId('individualPrices', props)}
        />
      ),
    },
  ];

  const activeTabContent = tabs.find(propEq(activeTabId, 'id'))?.content;

  return (
    <FullScreenModal headline={data?.name ?? undefined} onClose={props.onClose}>
      <VStack>
        <Box backgroundColor="general.white" paddingHorizontal={3}>
          <TabsHeader
            activeTabId={activeTabId ?? TAB_IDS.BASIC_INFORMATION}
            onChange={setActiveTabId}
            tabs={tabs}
            data-testid={suffixTestId('tabs', props)}
          />
        </Box>
        <Box padding={3}>{activeTabContent}</Box>
      </VStack>
    </FullScreenModal>
  );
}
