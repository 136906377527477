import {Preview, PreviewAction} from 'platform/components';
import {
  Box,
  Hide,
  HStack,
  Integer,
  makeStorageUrl,
  Show,
  StorageImage,
  ThemeIconKey,
  useElementSize,
} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {isNotNilOrEmpty, round} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {IconWithActions} from './IconWithActions';

export interface EntityHeaderImageProps extends TestIdProps {
  image?: string;
  imageActions?: PreviewAction[];
  icon?: ThemeIconKey;
  iconActions?: PreviewAction[];
}
const RATIO = 4 / 3;

export function EntityHeaderImage(props: EntityHeaderImageProps) {
  const theme = useTheme();
  const [ref, , height] = useElementSize<HTMLDivElement>();

  const heightInPx = height ?? 0;
  const widthInPx = round(RATIO * heightInPx);
  const heightInSteps = round(heightInPx * theme.space.step) as Integer;
  const widthInSteps = round(widthInPx * theme.space.step) as Integer;
  const iconSize = round(heightInSteps / 2) as Integer;

  if (!props.icon && !props.image) {
    return null;
  }

  return (
    <Box ref={ref}>
      <Box
        overflow="hidden"
        borderRadius="medium"
        backgroundColor="palettes.neutral.30.100"
        height="100%"
      >
        <Show when={props.image}>
          <Show when={isNotNilOrEmpty(props.imageActions)}>
            <HStack>
              <Preview
                url={props.image ?? ''}
                makeUrl={makeStorageUrl}
                width={widthInSteps}
                height={heightInSteps}
                fit="cover"
                actions={props.imageActions?.map((action) => ({
                  icon: action.icon,
                  onClick: action.onClick,
                }))}
                data-testid={suffixTestId('entityHeaderImage-image', props)}
              />
            </HStack>
          </Show>
          <Hide when={isNotNilOrEmpty(props.imageActions)}>
            <StorageImage
              url={props.image}
              width={widthInSteps}
              height={heightInSteps}
              fit="cover"
              data-testid={suffixTestId('entityHeaderImage-image', props)}
            />
          </Hide>
        </Show>
        <Show when={props.icon && !props.image}>
          <HStack width={heightInSteps} height="100%" align="center" justify="center">
            <IconWithActions
              width={widthInSteps}
              height={heightInSteps}
              icon={props.icon}
              iconSize={iconSize}
              actions={props.iconActions}
              data-testid={suffixTestId('entityHeaderImage-icon', props)}
            />
          </HStack>
        </Show>
      </Box>
    </Box>
  );
}
