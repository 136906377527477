import {Box, HStack, VStack} from 'platform/foundation';
import {DeepReadonly} from 'utility-types';

import {FC, useCallback} from 'react';

import i18n from '@omnetic-dms/i18n';
import {CountryResponseBody} from '@omnetic-dms/shared';
import {useFields} from '@omnetic-dms/teas';

import {useFormContextWithSubmit} from '../hooks/useFormContextWithSubmit';
import {ContactInfoFieldsFormType} from '../types';

export interface ContactInfoFieldsProps {
  countries: DeepReadonly<CountryResponseBody[]>;
  required?: string[];
}

export const ContactInfoFields: FC<ContactInfoFieldsProps> = ({countries, required = []}) => {
  const {onSubmit, form} = useFormContextWithSubmit<ContactInfoFieldsFormType>();

  const contactFields = useFields(form);
  const addressFields = useFields<ContactInfoFieldsFormType, ['address']>(form);

  const isRequired = useCallback((name: string) => required.includes(name), [required]);

  return (
    <VStack spacing={4}>
      <HStack spacing={4}>
        {contactFields.text({
          name: ['registrationNumber'],
          defaultValue: '',
          inputProps: {
            required: isRequired('registrationNumber'),
            placeholder: '–',
            label: i18n.t('entity.customer.labels.registrationNumber'),
          },
          onBlur: onSubmit,
        })}
        {contactFields.text({
          name: ['vatNumber'],
          defaultValue: '',
          inputProps: {
            placeholder: '–',
            label: i18n.t('entity.customer.labels.vatNumber'),
          },
          onBlur: onSubmit,
        })}
      </HStack>
      <HStack spacing={4}>
        <Box flex={1}>
          {contactFields.text({
            name: ['companyName'],
            defaultValue: '',
            inputProps: {
              required: isRequired('companyName'),
              placeholder: '–',
              label: i18n.t('entity.customer.labels.companyName'),
            },
            onBlur: onSubmit,
          })}
        </Box>
        <Box flex={1} />
      </HStack>
      {contactFields.text({
        name: ['fileNumber'],
        defaultValue: '',
        inputProps: {
          required: isRequired('fileNumber'),
          placeholder: '–',
          label: i18n.t('entity.customer.labels.fileNumber'),
        },
        onBlur: onSubmit,
      })}
      <HStack spacing={4}>
        <Box flex={1}>
          {addressFields.text({
            name: ['address', 'street'],
            defaultValue: '',
            inputProps: {
              required: isRequired('address.street'),
              placeholder: '–',
              label: i18n.t('entity.address.labels.street'),
            },
            onBlur: onSubmit,
          })}
        </Box>
        <Box flex={1}>
          <HStack spacing={4}>
            <Box flex={1}>
              {addressFields.text({
                name: ['address', 'city'],
                defaultValue: '',
                inputProps: {
                  required: isRequired('address.city'),
                  placeholder: '–',
                  label: i18n.t('entity.address.labels.city'),
                },
                onBlur: onSubmit,
              })}
            </Box>
            <Box flex={1}>
              {addressFields.text({
                name: ['address', 'zipCode'],
                defaultValue: '',
                inputProps: {
                  required: isRequired('address.zipCode'),
                  placeholder: '–',
                  label: i18n.t('entity.address.labels.zipCode'),
                },
                onBlur: onSubmit,
              })}
            </Box>
          </HStack>
        </Box>
      </HStack>
      <HStack spacing={4}>
        <Box flex={1}>
          {addressFields.text({
            name: ['address', 'state'],
            defaultValue: '',
            inputProps: {
              required: isRequired('address.state'),
              placeholder: '–',
              label: i18n.t('entity.address.labels.stateOrProvince'),
            },
            onBlur: onSubmit,
          })}
        </Box>
        <Box flex={1}>
          {addressFields.dropdown({
            name: ['address', 'country'],
            defaultValue: '',
            options: countries,
            getOptionLabel: (option: CountryResponseBody) => option.name,
            getOptionValue: (option?: CountryResponseBody | null) => option?.code ?? '',
            dropdownProps: {
              required: isRequired('address.country'),
              placeholder: i18n.t('entity.address.labels.selectCountry'),
              label: i18n.t('entity.address.labels.country'),
            },
            onChange: onSubmit,
          })}
        </Box>
      </HStack>
    </VStack>
  );
};
