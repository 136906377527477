import {
  Action,
  Actions,
  Card,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  openDialog,
} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {isFalsy, isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {useGetCashRegisterDocumentQuery} from '../../../api/accountingApi';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import i18n from '../../../i18n/i18n';
import {FileResponseBody} from '../../../types/api/api';
import {
  VehicleActiveReservationApiResponse,
  VehicleReservationResponseBody,
} from '../../../types/api/saleVehicle';
import {featureFlags} from '../../../utils/featureFlags';
import {DocumentsDialog} from '../../DocumentsDialog/DocumentsDialog';
import {ActiveReservationBailDetail} from '../../VehicleReservation/components/ActiveReservationBailDetail';
import {FutureAgreementDialog} from '../../VehicleReservation/components/FutureAgreementDialog';
import {RefundedReservationDeposits} from '../../VehicleReservation/components/RefundedReservationDeposits';
import {ActiveReservationDetail} from './ActiveReservationDetail';
import {ReservationCustomerDetail} from './ReservationCustomerDetail';

interface ActiveReservationCardProps extends TestIdProps {
  reservation: VehicleReservationResponseBody | VehicleActiveReservationApiResponse;
  vehicleId: string;
  isCancelled?: boolean;
  onEdit?: VoidFunction;
  onDelete?: VoidFunction;
}

const AGREEMENT_DIALOG_ID = 'futureAgreementDialog';

export function ActiveReservationCard(props: ActiveReservationCardProps) {
  const [hasCashRegisterDocumentReadPermission] = usePermissions({
    permissionKeys: ['readCashRegisterDocument'],
  });

  const {
    data: cashRegisterDocument,
    isLoading: isCashRegisterDocumentLoading,
    isError: isCashRegisterDocumentError,
  } = useGetCashRegisterDocumentQuery(
    {
      cashRegisterDocumentId: props.reservation?.paidDeposit?.incomeCashReceiptId ?? '',
    },
    {
      skip:
        isNilOrEmpty(props.reservation?.paidDeposit?.incomeCashReceiptId) ||
        !hasCashRegisterDocumentReadPermission,
    }
  );

  const handleDocumentGenerated = (document: FileResponseBody) => {
    closeDialog(AGREEMENT_DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={props.reservation.customer?.id ?? ''}
        documents={[document]}
        text={i18n.t('entity.vehicle.labels.futureAgreementCreatedText')}
        isDocxFormat
      />,
      {
        title: i18n.t('entity.vehicle.labels.futureAgreementCreatedTitle'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const handleCreateFutureAgreement = () => {
    openDialog(
      <FutureAgreementDialog
        businessCaseId={props.reservation.businessCase?.id ?? ''}
        customerId={props.reservation.customer?.id ?? ''}
        onDocumentCreation={handleDocumentGenerated}
      />,
      {
        id: AGREEMENT_DIALOG_ID,
        title: i18n.t('entity.vehicle.labels.futureAgreementTitle'),
        scrollBehavior: 'outside',
      }
    );
  };

  const reservationCardActions = buildArray<Action>().when(isFalsy(props.isCancelled), {
    title: i18n.t('entity.vehicle.labels.createContract'),
    onClick: handleCreateFutureAgreement,
    type: 'button',
    variant: 'outlined',
  });

  return (
    <Card>
      <VStack spacing={4}>
        <ActiveReservationDetail
          reservation={props.reservation}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          isCancelled={props.isCancelled}
          data-testid={suffixTestId('detail', props)}
        />
        <Show when={isNotNil(props.reservation.customer?.id)}>
          <ReservationCustomerDetail
            customerId={props.reservation.customer?.id ?? ''}
            businessCaseId={props.reservation.businessCase?.id}
          />
        </Show>
        <Show when={hasCashRegisterDocumentReadPermission}>
          <DataStatus
            isLoading={isCashRegisterDocumentLoading}
            isError={isCashRegisterDocumentError}
          >
            <Hide
              when={
                props.reservation?.paidDeposit ||
                !props.reservation.customer?.id ||
                !props.reservation.businessCase?.id
              }
              whenFeatureDisabled={featureFlags.VEHICLE_RESERVATION_FUTURE_AGREEMENT}
            >
              <Actions
                align="right"
                actions={reservationCardActions}
                data-testid={suffixTestId('createContractAction', props)}
              />
            </Hide>
            <Show when={isNotNilOrEmpty(props.reservation?.paidDeposit)}>
              <ActiveReservationBailDetail
                reservation={props.reservation}
                vehicleId={props.vehicleId}
                cashRegisterDocument={cashRegisterDocument}
                isCanceled={props.isCancelled}
                onCreateFutureAgreement={handleCreateFutureAgreement}
              />
            </Show>
          </DataStatus>
        </Show>

        <Show
          when={
            isNotNilOrEmpty(props.reservation?.refundedDeposits) &&
            hasCashRegisterDocumentReadPermission
          }
        >
          <RefundedReservationDeposits
            reservation={props.reservation}
            vehicleId={props.vehicleId}
          />
        </Show>
      </VStack>
    </Card>
  );
}
