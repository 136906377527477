import {FC, PropsWithChildren} from 'react';

import {classifiedsVehicles} from '@omnetic-dms/teas';

import {SourcingContextType} from '../types/SourcingContextType';
import {SourcingContext} from './sourcingContext';

/**
 * Wrap component in context
 * @param Component Sourcing page component
 */
export const withSourcingPage = (Component: FC): FC<PropsWithChildren<any>> => {
  return (props) => {
    const context: SourcingContextType = {
      vehiclesModel: classifiedsVehicles,
    };
    return (
      <SourcingContext.Provider value={context}>
        <Component {...props} />
      </SourcingContext.Provider>
    );
  };
};
