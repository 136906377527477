import {
  PostDirectSaleRequest,
  PostDirectSaleResponse,
  DeleteDirectSaleLabourBasketItemsRequest,
  DeleteDirectSaleLabourBasketItemsResponse,
  DeleteDirectSaleLabourBasketItemRequest,
  DeleteDirectSaleLabourBasketItemResponse,
  DeleteDirectSaleMaterialBasketItemsRequest,
  DeleteDirectSaleMaterialBasketItemsResponse,
  GetDirectSaleActionsRequest,
  GetDirectSaleActionsResponse,
  GetDirectSaleDiscountRequest,
  GetDirectSaleDiscountResponse,
  GetDirectSaleLabourBasketItemsRequest,
  GetDirectSaleLabourBasketItemsResponse,
  GetDirectSaleLabourItemRequest,
  GetDirectSaleLabourItemResponse,
  GetDirectSaleMaterialBasketItemsRequest,
  GetDirectSaleMaterialBasketItemsResponse,
  GetDirectSaleMaterialItemRequest,
  GetDirectSaleMaterialItemResponse,
  GetDirectSaleRequest,
  GetDirectSaleResponse,
  PatchDirectSaleLabourBasketItemQuantityRequest,
  PatchDirectSaleLabourBasketItemQuantityResponse,
  PatchDirectSaleLabourItemRequest,
  PatchDirectSaleLabourItemResponse,
  PatchDirectSaleMaterialBasketItemQuantityRequest,
  PatchDirectSaleMaterialBasketItemQuantityResponse,
  PatchDirectSaleMaterialItemRequest,
  PatchDirectSaleMaterialItemResponse,
  PatchDirectSaleGetCurrentPriceRequest,
  PatchDirectSaleGetCurrentPriceResponse,
  PostDirectSaleLabourItemCalculatePriceRequest,
  PostDirectSaleLabourItemCalculatePriceResponse,
  PostDirectSaleMaterialItemCalculatePriceRequest,
  PostDirectSaleMaterialItemCalculatePriceResponse,
  PutDirectSaleActionRequest,
  PutDirectSaleActionResponse,
  PutDirectSaleDiscountRequest,
  PutDirectSaleDiscountResponse,
  PatchDirectSaleRequest,
  PatchDirectSaleResponse,
  PostDirectSaleMaterialItemsMechanicResponse,
  PostDirectSaleMaterialItemsMechanicRequest,
  PostDirectSaleMaterialItemToBasketResponse,
  PostDirectSaleMaterialItemToBasketRequest,
  PostDirectSaleLabourItemsMechanicResponse,
  PostDirectSaleLabourItemsMechanicRequest,
  PostDirectSaleLabourItemToBasketResponse,
  PostDirectSaleLabourItemToBasketRequest,
  BulkDeleteDirectSaleItemsResponse,
  BulkDeleteDirectSaleItemsRequest,
  GetDirectSaleRequestSchema,
  GetDirectSaleResponseSchema,
  PostDirectSaleRequestSchema,
  PostDirectSaleResponseSchema,
  PatchDirectSaleRequestSchema,
  PatchDirectSaleResponseSchema,
  GetDirectSaleMaterialBasketItemsRequestSchema,
  GetDirectSaleMaterialBasketItemsResponseSchema,
  GetDirectSaleMaterialItemRequestSchema,
  GetDirectSaleMaterialItemResponseSchema,
  PatchDirectSaleMaterialItemRequestSchema,
  PatchDirectSaleMaterialItemResponseSchema,
  PostDirectSaleMaterialItemCalculatePriceRequestSchema,
  PostDirectSaleMaterialItemCalculatePriceResponseSchema,
  DeleteDirectSaleMaterialBasketItemsRequestSchema,
  DeleteDirectSaleMaterialBasketItemsResponseSchema,
  PostDirectSaleMaterialItemsMechanicRequestSchema,
  PostDirectSaleMaterialItemsMechanicResponseSchema,
  PostDirectSaleMaterialItemToBasketRequestSchema,
  PostDirectSaleMaterialItemToBasketResponseSchema,
  PatchDirectSaleMaterialBasketItemQuantityRequestSchema,
  PatchDirectSaleMaterialBasketItemQuantityResponseSchema,
  GetDirectSaleLabourBasketItemsRequestSchema,
  GetDirectSaleLabourBasketItemsResponseSchema,
  GetDirectSaleLabourItemRequestSchema,
  GetDirectSaleLabourItemResponseSchema,
  PatchDirectSaleLabourItemRequestSchema,
  PatchDirectSaleLabourItemResponseSchema,
  PostDirectSaleLabourItemCalculatePriceRequestSchema,
  PostDirectSaleLabourItemCalculatePriceResponseSchema,
  DeleteDirectSaleLabourBasketItemsRequestSchema,
  DeleteDirectSaleLabourBasketItemsResponseSchema,
  PostDirectSaleLabourItemsMechanicRequestSchema,
  PostDirectSaleLabourItemsMechanicResponseSchema,
  PostDirectSaleLabourItemToBasketRequestSchema,
  PostDirectSaleLabourItemToBasketResponseSchema,
  GetDirectSaleActionsRequestSchema,
  GetDirectSaleActionsResponseSchema,
  BulkDeleteDirectSaleItemsRequestSchema,
  BulkDeleteDirectSaleItemsResponseSchema,
  PatchDirectSaleLabourBasketItemQuantityRequestSchema,
  PatchDirectSaleLabourBasketItemQuantityResponseSchema,
  DeleteDirectSaleLabourBasketItemRequestSchema,
  DeleteDirectSaleLabourBasketItemResponseSchema,
  PutDirectSaleActionRequestSchema,
  PutDirectSaleActionResponseSchema,
  GetDirectSaleDiscountRequestSchema,
  GetDirectSaleDiscountResponseSchema,
  PutDirectSaleDiscountRequestSchema,
  PutDirectSaleDiscountResponseSchema,
  PatchDirectSaleGetCurrentPriceRequestSchema,
  PatchDirectSaleGetCurrentPriceResponseSchema,
} from '../types/api/metadaWarehouseDirectSale';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDirectSaleApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSale: build.query<GetDirectSaleResponse, GetDirectSaleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [{type: 'directSales', id: queryArg.directSaleId}],
      extraOptions: {
        requestSchema: GetDirectSaleRequestSchema,
        responseSchema: GetDirectSaleResponseSchema,
      },
    }),
    postDirectSale: build.mutation<PostDirectSaleResponse, PostDirectSaleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale`,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: ['directSales'],
      extraOptions: {
        requestSchema: PostDirectSaleRequestSchema,
        responseSchema: PostDirectSaleResponseSchema,
      },
    }),
    patchDirectSale: build.mutation<PatchDirectSaleResponse, PatchDirectSaleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PatchDirectSaleRequestSchema,
        responseSchema: PatchDirectSaleResponseSchema,
      },
    }),
    getDirectSaleMaterialBasketItems: build.query<
      GetDirectSaleMaterialBasketItemsResponse,
      GetDirectSaleMaterialBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart`,
        method: 'GET',
      }),
      providesTags: ['directSaleMaterialBasketItems'],
      extraOptions: {
        requestSchema: GetDirectSaleMaterialBasketItemsRequestSchema,
        responseSchema: GetDirectSaleMaterialBasketItemsResponseSchema,
      },
    }),
    getDirectSaleMaterialItem: build.query<
      GetDirectSaleMaterialItemResponse,
      GetDirectSaleMaterialItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}`,
        method: 'GET',
      }),
      providesTags: ['directSaleMaterialItems'],
      extraOptions: {
        requestSchema: GetDirectSaleMaterialItemRequestSchema,
        responseSchema: GetDirectSaleMaterialItemResponseSchema,
      },
    }),
    patchDirectSaleMaterialItem: build.mutation<
      PatchDirectSaleMaterialItemResponse,
      PatchDirectSaleMaterialItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSales', 'directSaleMaterialBasketItems'],
      extraOptions: {
        requestSchema: PatchDirectSaleMaterialItemRequestSchema,
        responseSchema: PatchDirectSaleMaterialItemResponseSchema,
      },
    }),
    postDirectSaleMaterialItemCalculatePrice: build.query<
      PostDirectSaleMaterialItemCalculatePriceResponse,
      PostDirectSaleMaterialItemCalculatePriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}/calculate-price`,
        method: 'POST',
        body: queryArg.body,
      }),
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemCalculatePriceRequestSchema,
        responseSchema: PostDirectSaleMaterialItemCalculatePriceResponseSchema,
      },
    }),
    deleteDirectSaleMaterialBasketItems: build.mutation<
      DeleteDirectSaleMaterialBasketItemsResponse,
      DeleteDirectSaleMaterialBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleMaterialBasketItems',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: DeleteDirectSaleMaterialBasketItemsRequestSchema,
        responseSchema: DeleteDirectSaleMaterialBasketItemsResponseSchema,
      },
    }),
    postDirectSaleMaterialItemsMechanic: build.mutation<
      PostDirectSaleMaterialItemsMechanicResponse,
      PostDirectSaleMaterialItemsMechanicRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/checkout`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleMaterialBasketItems',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemsMechanicRequestSchema,
        responseSchema: PostDirectSaleMaterialItemsMechanicResponseSchema,
      },
    }),
    postDirectSaleMaterialDispatch: build.mutation<
      PostDirectSaleMaterialItemToBasketResponse,
      PostDirectSaleMaterialItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/dispatch`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasketItems'],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemToBasketRequestSchema,
        responseSchema: PostDirectSaleMaterialItemToBasketResponseSchema,
      },
    }),
    postDirectSaleMaterialRequest: build.mutation<
      PostDirectSaleMaterialItemToBasketResponse,
      PostDirectSaleMaterialItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasketItems'],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemToBasketRequestSchema,
        responseSchema: PostDirectSaleMaterialItemToBasketResponseSchema,
      },
    }),
    postDirectSaleMaterialNonBinding: build.mutation<
      PostDirectSaleMaterialItemToBasketResponse,
      PostDirectSaleMaterialItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/nonbinding`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasketItems'],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemToBasketRequestSchema,
        responseSchema: PostDirectSaleMaterialItemToBasketResponseSchema,
      },
    }),
    patchDirectSaleMaterialBasketItemQuantity: build.mutation<
      PatchDirectSaleMaterialBasketItemQuantityResponse,
      PatchDirectSaleMaterialBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/${queryArg.itemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasketItems'],
      extraOptions: {
        requestSchema: PatchDirectSaleMaterialBasketItemQuantityRequestSchema,
        responseSchema: PatchDirectSaleMaterialBasketItemQuantityResponseSchema,
      },
    }),
    // labour items
    getDirectSaleLabourBasketItems: build.query<
      GetDirectSaleLabourBasketItemsResponse,
      GetDirectSaleLabourBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart`,
        method: 'GET',
      }),
      providesTags: ['directSaleLabourBasketItems'],
      extraOptions: {
        requestSchema: GetDirectSaleLabourBasketItemsRequestSchema,
        responseSchema: GetDirectSaleLabourBasketItemsResponseSchema,
      },
    }),
    getDirectSaleLabourItem: build.query<
      GetDirectSaleLabourItemResponse,
      GetDirectSaleLabourItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}`,
        method: 'GET',
      }),
      providesTags: ['directSaleLabourItems'],
      extraOptions: {
        requestSchema: GetDirectSaleLabourItemRequestSchema,
        responseSchema: GetDirectSaleLabourItemResponseSchema,
      },
    }),
    patchDirectSaleLabourItem: build.mutation<
      PatchDirectSaleLabourItemResponse,
      PatchDirectSaleLabourItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSales', 'directSaleLabourBasketItems'],
      extraOptions: {
        requestSchema: PatchDirectSaleLabourItemRequestSchema,
        responseSchema: PatchDirectSaleLabourItemResponseSchema,
      },
    }),
    postDirectSaleLabourItemCalculatePrice: build.query<
      PostDirectSaleLabourItemCalculatePriceResponse,
      PostDirectSaleLabourItemCalculatePriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}/calculate-price`,
        method: 'POST',
        body: queryArg.body,
      }),
      extraOptions: {
        requestSchema: PostDirectSaleLabourItemCalculatePriceRequestSchema,
        responseSchema: PostDirectSaleLabourItemCalculatePriceResponseSchema,
      },
    }),
    deleteDirectSaleLabourBasketItems: build.mutation<
      DeleteDirectSaleLabourBasketItemsResponse,
      DeleteDirectSaleLabourBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleLabourBasketItems',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: DeleteDirectSaleLabourBasketItemsRequestSchema,
        responseSchema: DeleteDirectSaleLabourBasketItemsResponseSchema,
      },
    }),
    postDirectSaleLabourItemsMechanic: build.mutation<
      PostDirectSaleLabourItemsMechanicResponse,
      PostDirectSaleLabourItemsMechanicRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/checkout`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleLabourBasketItems',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PostDirectSaleLabourItemsMechanicRequestSchema,
        responseSchema: PostDirectSaleLabourItemsMechanicResponseSchema,
      },
    }),
    postDirectSaleLabourItemToBasket: build.mutation<
      PostDirectSaleLabourItemToBasketResponse,
      PostDirectSaleLabourItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/item/labour-catalog`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleLabourBasketItems'],
      extraOptions: {
        requestSchema: PostDirectSaleLabourItemToBasketRequestSchema,
        responseSchema: PostDirectSaleLabourItemToBasketResponseSchema,
      },
    }),
    getDirectSaleActions: build.query<GetDirectSaleActionsResponse, GetDirectSaleActionsRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/actions`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [{type: 'directSales', id: queryArg.directSaleId}],
      extraOptions: {
        requestSchema: GetDirectSaleActionsRequestSchema,
        responseSchema: GetDirectSaleActionsResponseSchema,
      },
    }),
    bulkDeleteDirectSaleItems: build.mutation<
      BulkDeleteDirectSaleItemsResponse,
      BulkDeleteDirectSaleItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/items-delete`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSales', id: queryArg.directSaleId},
        {type: 'directSaleMaterialBasketItems'},
      ],
      extraOptions: {
        requestSchema: BulkDeleteDirectSaleItemsRequestSchema,
        responseSchema: BulkDeleteDirectSaleItemsResponseSchema,
      },
    }),
    patchDirectSaleLabourBasketItemQuantity: build.mutation<
      PatchDirectSaleLabourBasketItemQuantityResponse,
      PatchDirectSaleLabourBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/item/${queryArg.itemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleLabourBasketItems', 'directSaleLabourItems'],
      extraOptions: {
        requestSchema: PatchDirectSaleLabourBasketItemQuantityRequestSchema,
        responseSchema: PatchDirectSaleLabourBasketItemQuantityResponseSchema,
      },
    }),
    deleteDirectSaleLabourBasketItem: build.mutation<
      DeleteDirectSaleLabourBasketItemResponse,
      DeleteDirectSaleLabourBasketItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/item/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['directSaleLabourBasketItems'],
      extraOptions: {
        requestSchema: DeleteDirectSaleLabourBasketItemRequestSchema,
        responseSchema: DeleteDirectSaleLabourBasketItemResponseSchema,
      },
    }),
    putDirectSaleAction: build.mutation<PutDirectSaleActionResponse, PutDirectSaleActionRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/action`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSales'],
      extraOptions: {
        requestSchema: PutDirectSaleActionRequestSchema,
        responseSchema: PutDirectSaleActionResponseSchema,
      },
    }),
    getDirectSaleDiscount: build.query<GetDirectSaleDiscountResponse, GetDirectSaleDiscountRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/discount`,
          method: 'GET',
        }),
        providesTags: (result, error, queryArg) => [
          {type: 'directSaleDiscount', id: queryArg.directSaleId},
        ],
        extraOptions: {
          requestSchema: GetDirectSaleDiscountRequestSchema,
          responseSchema: GetDirectSaleDiscountResponseSchema,
        },
      }
    ),
    putDirectSaleDiscount: build.mutation<
      PutDirectSaleDiscountResponse,
      PutDirectSaleDiscountRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/discount`,
        body: queryArg.body,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSaleDiscount', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PutDirectSaleDiscountRequestSchema,
        responseSchema: PutDirectSaleDiscountResponseSchema,
      },
    }),
    patchDirectSaleGetCurrentPrice: build.mutation<
      PatchDirectSaleGetCurrentPriceResponse,
      PatchDirectSaleGetCurrentPriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/get-current-price`,
        body: queryArg.body,
        method: 'PATCH',
      }),
      extraOptions: {
        requestSchema: PatchDirectSaleGetCurrentPriceRequestSchema,
        responseSchema: PatchDirectSaleGetCurrentPriceResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleQuery,
  useLazyGetDirectSaleQuery,
  usePostDirectSaleMutation,
  usePatchDirectSaleMutation,
  useBulkDeleteDirectSaleItemsMutation,
  useGetDirectSaleMaterialBasketItemsQuery,
  useGetDirectSaleMaterialItemQuery,
  useLazyGetDirectSaleMaterialItemQuery,
  usePatchDirectSaleMaterialItemMutation,
  usePostDirectSaleMaterialItemCalculatePriceQuery,
  useDeleteDirectSaleMaterialBasketItemsMutation,
  usePostDirectSaleMaterialItemsMechanicMutation,
  usePostDirectSaleMaterialDispatchMutation,
  usePostDirectSaleMaterialRequestMutation,
  usePostDirectSaleMaterialNonBindingMutation,
  usePatchDirectSaleMaterialBasketItemQuantityMutation,
  useGetDirectSaleLabourBasketItemsQuery,
  useGetDirectSaleLabourItemQuery,
  useLazyGetDirectSaleLabourItemQuery,
  usePatchDirectSaleLabourItemMutation,
  usePostDirectSaleLabourItemCalculatePriceQuery,
  useDeleteDirectSaleLabourBasketItemsMutation,
  usePostDirectSaleLabourItemsMechanicMutation,
  usePostDirectSaleLabourItemToBasketMutation,
  // TODO POST time-norm
  // TODO POST calculation-price
  // TODO POST direct-price
  // TODO POST cooperation
  usePatchDirectSaleLabourBasketItemQuantityMutation,
  useDeleteDirectSaleLabourBasketItemMutation,
  useGetDirectSaleActionsQuery,
  usePutDirectSaleActionMutation,
  useGetDirectSaleDiscountQuery,
  usePutDirectSaleDiscountMutation,
  usePatchDirectSaleGetCurrentPriceMutation,
} = metadaWarehouseDirectSaleApi;
