import {openDialog, closeDialog} from 'platform/components';

import {not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  usePostAvailableXentryOrdersMutation,
  handleApiError,
  usePostIntegrationsForXentryLinkMutation,
} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useOnMount} from 'shared';

import {ConfirmChangeXentryOrderDialog} from '../components/ConfirmChangeXentryOrderDialog';
import {ConfirmUnlinkDialog} from '../components/ConfirmUnlinkDialog';
import {LinkXentry} from '../components/LinkXentry';

interface XentryIntegrationProps extends RequiredTestIdProps {
  serviceCaseId: string;
}

export function useXentryIntegration(props: XentryIntegrationProps) {
  const [postAvailableXentryOrders, {data, isError, isSuccess}] =
    usePostAvailableXentryOrdersMutation();
  const [postIntegrationsForXentryLink] = usePostIntegrationsForXentryLinkMutation();

  const getAvailableXentryOrders = () =>
    postAvailableXentryOrders({serviceCaseId: props.serviceCaseId}).unwrap().catch(handleApiError);

  const handleLinkXetry = (xentryOrderId: string) =>
    postIntegrationsForXentryLink({serviceCaseId: props.serviceCaseId, body: {xentryOrderId}})
      .unwrap()
      .then(getAvailableXentryOrders)
      .catch(handleApiError);

  const linkedXentry = data?.xentryJob?.find((job) => job?.isCurrentlyLinked);

  const openLinkXentryDialog = () =>
    openDialog(
      <LinkXentry
        serviceCaseId={props.serviceCaseId}
        onClose={() => closeDialog('selectXentryDialog')}
        onLink={(xentryOrderId) =>
          handleLinkXetry(xentryOrderId).then(() => closeDialog('selectXentryDialog'))
        }
        data-testid={suffixTestId('linkXentry', props)}
      />,
      {title: i18n.t('entity.integration.labels.selectXentryOrder'), id: 'selectXentryDialog'}
    );

  const openUnlinkConfirm = (xentryId: string | Nullish, onConfirm: () => unknown) =>
    openDialog(
      <ConfirmUnlinkDialog
        dialogId="confirmUnlinkXentryOrder"
        serviceCaseId={props.serviceCaseId}
        xentryId={xentryId}
        onConfirm={onConfirm}
        data-testid={suffixTestId('unlinkXentry', props)}
      />,
      {
        id: 'confirmUnlinkXentryOrder',
        size: 'small',
      }
    );

  const openChangeConfirm = (xentryId: string | Nullish, onConfirm: () => unknown) =>
    openDialog(
      <ConfirmChangeXentryOrderDialog
        dialogId="confirmChangeXentryOrder"
        serviceCaseId={props.serviceCaseId}
        xentryId={xentryId}
        onConfirm={onConfirm}
        data-testid={suffixTestId('unlinkXentry', props)}
      />,
      {
        id: 'confirmChangeXentryOrder',
        size: 'small',
      }
    );

  const openChangeXentryDialog = () =>
    openDialog(
      <LinkXentry
        serviceCaseId={props.serviceCaseId}
        linkedXentryId={linkedXentry?.id}
        onClose={() => closeDialog('changeXentryDialog')}
        onLink={(xentryOrderId) => {
          openChangeConfirm(xentryOrderId, () => {
            handleLinkXetry(xentryOrderId);
            closeDialog('changeXentryDialog');
          });
        }}
        data-testid={suffixTestId('linkXentry', props)}
      />,
      {title: i18n.t('entity.integration.labels.selectXentryOrder'), id: 'changeXentryDialog'}
    );

  useOnMount(() => {
    if (isNilOrEmpty(props.serviceCaseId)) {
      return;
    }

    getAvailableXentryOrders().then((response) => {
      const isNotXentryLinked =
        !!response?.xentryJob?.length &&
        response?.xentryJob?.every((job) => not(job.isCurrentlyLinked));
      if (isNotXentryLinked) {
        openLinkXentryDialog();
      }
    });
  });

  return {
    linkedXentry,
    isError,
    isSuccess,
    openUnlinkConfirm,
    openChangeXentryDialog,
    getAvailableXentryOrders,
    openLinkXentryDialog,
  };
}
