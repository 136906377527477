import {
  Actions,
  closeCurrentDialog,
  DataStatus,
  showNotification,
  Tooltip,
} from 'platform/components';
import {Box, Heading, HStack, Image, Link, Text, VStack} from 'platform/foundation';

import {isNonPositive} from 'ramda-adjunct';

import {settingsRoutes} from '@omnetic-dms/routes';

import {suffixTestId, TestIdProps} from 'shared';

import {useRemoveSaleVehiclePhotosBackgroundMutation} from '../../api/saleVehiclePhotoApi';
import {
  useGetBackgroundRemovalSettingsQuery,
  useRemoveVehiclePhotosBackgroundMutation,
} from '../../api/vehicleApi';
import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import i18n from '../../i18n/i18n';
import {handleApiError} from '../../utils/handleApiError';

interface BackgroundRemovalDialogPropsBase extends TestIdProps {
  vehiclePhotoIds: string[];
  onConfirm?: () => void;
}

interface WithVehicleId extends BackgroundRemovalDialogPropsBase {
  vehicleId: string;
  saleVehicleId?: never;
  saleVehicleAlbumId?: never;
}

interface WithSaleVehicleId extends BackgroundRemovalDialogPropsBase {
  saleVehicleId: string;
  saleVehicleAlbumId: string;
  vehicleId?: never;
}

export function BackgroundRemovalDialog(props: WithVehicleId | WithSaleVehicleId) {
  const [canConfigureBackgroundRemoval] = usePermissions({
    permissionKeys: ['configureBackgroundRemoval'],
  });

  const {data: settings, isLoading, isError} = useGetBackgroundRemovalSettingsQuery();

  const [removePhotosBackground, {isLoading: isRemoveBackgroundsLoading}] =
    useRemoveVehiclePhotosBackgroundMutation();

  const [removeSaleVehiclePhotosBackground, {isLoading: isSaleVehiclePhotosBackgroundsLoading}] =
    useRemoveSaleVehiclePhotosBackgroundMutation();

  const handleBackgroundRemoval = () => {
    const promise = props.saleVehicleId
      ? removeSaleVehiclePhotosBackground({
          saleVehicleId: props.saleVehicleId,
          saleVehicleAlbumId: props.saleVehicleAlbumId,
          removeSaleVehiclePhotosBackgroundRequestBody: {
            saleVehiclePhotoIds: props.vehiclePhotoIds,
          },
        })
      : removePhotosBackground({
          vehicleId: props.vehicleId!,
          body: {
            vehiclePhotoIds: props.vehiclePhotoIds,
          },
        });

    promise
      .unwrap()
      .then(props.onConfirm)
      .then(closeCurrentDialog)
      .then(() => {
        showNotification.success(i18n.t('entity.photo.backgroundRemoval.scheduledNotification'));
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <HStack spacing={6}>
        <Box width={70}>
          <Image
            data-testid={suffixTestId('background-image', props)}
            src={`assets/images/background-replacement/${settings?.backgroundId}.jpg`}
          />
        </Box>
        <VStack spacing={6}>
          <Heading size={3}>{i18n.t('entity.photo.backgroundRemoval.dialogHeadline')}</Heading>
          <Text size="small" alternative>
            {i18n.t('entity.photo.backgroundRemoval.countInformation', {
              count: props.vehiclePhotoIds.length,
            })}
          </Text>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.photo.backgroundRemoval.description')}
          </Text>
          <Tooltip
            label={
              !canConfigureBackgroundRemoval ? i18n.t('general.labels.noPermission') : undefined
            }
          >
            <Link
              data-testid={suffixTestId('configure-removal', props)}
              href={settingsRoutes.backgroundRemoval}
              title={i18n.t('entity.photo.backgroundRemoval.changeSettings')}
              leftIcon="action/magic_wand"
              isDisabled={!canConfigureBackgroundRemoval}
              size="small"
            />
          </Tooltip>
          <Actions
            data-testid={suffixTestId('actions', props)}
            align="right"
            actions={[
              {
                title: i18n.t('general.actions.cancel'),
                type: 'button',
                variant: 'secondary',
                onClick: closeCurrentDialog,
              },
              {
                title: i18n.t('general.actions.confirm'),
                type: 'button',
                isDisabled: isNonPositive(props.vehiclePhotoIds.length),
                isLoading: isRemoveBackgroundsLoading || isSaleVehiclePhotosBackgroundsLoading,
                onClick: handleBackgroundRemoval,
              },
            ]}
          />
        </VStack>
      </HStack>
    </DataStatus>
  );
}
