import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {Rate} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getOptionsFromVatRates = (vatRates: Rate[] | Nullish): Option[] => {
  if (isNil(vatRates)) {
    return [];
  }
  return vatRates.map((vatRate) => ({
    label: vatRate.name,
    value: vatRate.type,
  }));
};
