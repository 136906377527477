import {bool, mixed, object, SchemaOf, string} from 'yup';

import {isNotNilOrEmpty, isUndefined} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {PricePurchaseBusinessCaseRequestBody, VatTypeEnum} from '@omnetic-dms/shared';

const MINIMUM_ALLOWED_NUMBER = 0.01;

const testRequiredNumber = mixed()
  .test(
    'number-value-type',
    i18n.t('general.errors.mixed.required'),
    (val) => !Number.isNaN(parseFloat(val)) || isUndefined(val)
  )
  .test(
    'number-value-amount',
    i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
    (val) => isNotNilOrEmpty(val) && parseFloat(val) >= MINIMUM_ALLOWED_NUMBER
  );

const testMinimalNumber = mixed().test(
  'number-value-amount',
  i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
  (val) => Number.isNaN(parseFloat(val)) || parseFloat(val) >= MINIMUM_ALLOWED_NUMBER
);

const $Money = object().shape({
  amount: testRequiredNumber,
});

const $OptionalMoney = object().shape({
  amount: testMinimalNumber,
});

const $NonDeductiblePrice = object().shape({
  priceNotDeductible: $Money,
  priceWithVat: mixed().default(null).nullable(),
  priceWithoutVat: mixed().default(null).nullable(),
  vatType: mixed().default(null).nullable(),
});

const $OptionalNonDeductiblePrice = object().shape({
  priceNotDeductible: $OptionalMoney,
  priceWithVat: mixed().default(null).nullable(),
  priceWithoutVat: mixed().default(null).nullable(),
  vatType: mixed().default(null).nullable(),
});

const $DeductiblePrice = object().shape({
  priceWithVat: $Money,
  priceWithoutVat: $Money,
  priceNotDeductible: mixed().default(null).nullable(),
  vatType: mixed().oneOf(Object.values(VatTypeEnum)).required(),
});

const $OptionalDeductiblePrice = object().shape({
  priceWithVat: $OptionalMoney,
  priceWithoutVat: $OptionalMoney,
  priceNotDeductible: mixed().default(null).nullable(),
  vatType: mixed().oneOf(Object.values(VatTypeEnum)).required(),
});

export const $PricingFormScheme = (
  isCustomerRequestedPriceFilled: boolean
): SchemaOf<Partial<PricePurchaseBusinessCaseRequestBody>> =>
  object().shape({
    isVatDeductible: bool().defined(),
    expectedPurchasePrice: mixed().when('isVatDeductible', {
      is: true,
      then: $OptionalDeductiblePrice,
      otherwise: $OptionalNonDeductiblePrice,
    }),
    expectedSellingPrice: mixed().when('isVatDeductible', {
      is: true,
      then: isCustomerRequestedPriceFilled ? $DeductiblePrice : $OptionalDeductiblePrice,
      otherwise: isCustomerRequestedPriceFilled ? $NonDeductiblePrice : $OptionalNonDeductiblePrice,
    }),
    maxPurchasePrice: $OptionalMoney.nullable(),
    updateReasonNote: string().nullable(),
    customerExpectedPrice: $OptionalMoney.nullable(),
    expectedPurchaseDate: string().nullable(),
    purchasePriceInForeignCurrency: object()
      .shape({
        foreignCurrencyCode: string().optional().nullable(),
        priceWithoutVat: string().optional().nullable(),
        priceWithVat: string().optional().nullable(),
      })
      .nullable(),
  });

export const $BrokeragePricingFormScheme = (): SchemaOf<
  Partial<Omit<PricePurchaseBusinessCaseRequestBody, 'expectedPurchasePrice' | 'maxPurchasePrice'>>
> =>
  object().shape({
    isVatDeductible: bool().required(i18n.t('general.notifications.errorFieldRequired')),
    expectedSellingPrice: mixed().when('isVatDeductible', {
      is: true,
      then: $DeductiblePrice,
      otherwise: $NonDeductiblePrice,
    }),
    updateReasonNote: string().nullable(),
    customerExpectedPrice: $OptionalMoney.nullable(),
    expectedPurchaseDate: string().nullable(),
    purchasePriceInForeignCurrency: object()
      .shape({
        foreignCurrencyCode: string().optional().nullable(),
        priceWithoutVat: string().optional().nullable(),
        priceWithVat: string().optional().nullable(),
      })
      .nullable(),
  });
