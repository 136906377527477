import {Attributes, Card} from 'platform/components';
import {HStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  BusinessCaseResponseBody,
  getNaturalPersonFullName,
  useReadCodeQuery,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, parseDate, suffixTestId} from 'shared';

type ClosedBusinessCaseDetailsProps = {
  businessCase: BusinessCaseResponseBody;
} & RequiredTestIdProps;

export const ClosedBusinessCaseDetails = (props: ClosedBusinessCaseDetailsProps) => {
  const {reasonCloseCodeId, closedBy, closedAt, reasonCloseNote} = props.businessCase;
  const formatDateTime = useDateTimeFormatter();

  const {data: reasonCodeData} = useReadCodeQuery(
    {
      codelistId: 'unsuccessful_business_case_reason',
      codeId: reasonCloseCodeId!,
    },
    {
      skip: isNil(reasonCloseCodeId),
    }
  );

  return (
    <Card title={i18n.t('entity.order.labels.caseClosed')}>
      <HStack spacing={4}>
        <Attributes
          data-testid={suffixTestId('reason-closedby', props)}
          size="quarter"
          rows={[
            {label: i18n.t('general.labels.reason'), value: reasonCodeData?.name},
            {
              label: i18n.t('general.label.closedBy'),
              value: getNaturalPersonFullName(closedBy),
            },
          ]}
        />
        <Attributes
          size="quarter"
          data-testid={suffixTestId('note-closedOn', props)}
          rows={[
            {label: i18n.t('general.labels.note'), value: reasonCloseNote || null},
            {
              label: i18n.t('general.label.closedOn'),
              value: closedAt && formatDateTime('dateTimeLong', parseDate(closedAt)),
            },
          ]}
        />
      </HStack>
    </Card>
  );
};
