import {
  GetErrorLogApiArg,
  GetErrorLogApiRes,
  ReprocessErrorLogApiArg,
  ReprocessErrorLogApiRes,
  AccPostDeleteErrorLogsApiResponse,
  AccPostDeleteErrorLogsApiArg,
} from '../types/api/accounting';
import {metadaApi} from './baseApi/metadaApi';

export const metadaErrorLogs = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    reprocessErrorLog: build.mutation<ReprocessErrorLogApiRes, ReprocessErrorLogApiArg>({
      query: (params) => ({
        url: `/accounting/v1/restart-accounting-from-error-log`,
        method: 'POST',
        params,
      }),
    }),
    getErrorLog: build.query<GetErrorLogApiRes, GetErrorLogApiArg>({
      query: ({id}) => ({
        url: `/accounting/v1/error-log-detail/${id}`,
      }),
    }),
    accPostDeleteErrorLogs: build.mutation<
      AccPostDeleteErrorLogsApiResponse,
      AccPostDeleteErrorLogsApiArg
    >({
      query: ({deleteAll, ...body}) => ({
        url: `accounting/v1/error-log-records-clean-up`,
        method: 'POST',
        body,
        params: {deleteAll},
      }),
    }),
  }),
});

export const {
  useReprocessErrorLogMutation,
  useGetErrorLogQuery,
  useAccPostDeleteErrorLogsMutation,
} = metadaErrorLogs;
