import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Grid, HStack, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import i18n from '@omnetic-dms/i18n';
import {featureFlags} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId, yupNumber} from 'shared';

import {PaymentSettings} from '../types/PaymentSettings';
import {IN_EUROPE, OUT_OF_EUROPE} from '../utils/const';

interface PaymentSettingsFormProps extends TestIdProps {
  defaultValues: Partial<PaymentSettings>;
  onSubmit: FormSubmitHandler<PaymentSettings>;
  onClose: VoidFunction;
}

export function PaymentSettingsForm(props: PaymentSettingsFormProps) {
  const abroadSaleOptions = [
    {label: i18n.t('entity.customerPaymentSettings.labels.saleToEU'), value: IN_EUROPE},
    {label: i18n.t('entity.customerPaymentSettings.labels.saleOutsideEU'), value: OUT_OF_EUROPE},
  ];
  return (
    <Form<PaymentSettings>
      defaultValues={props.defaultValues}
      onSubmit={props.onSubmit}
      schema={formSchema}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <Show whenFeatureEnabled={featureFlags.CUSTOMER_ABROAD_SALE_SETTING}>
            <Card
              variant="inlineGrey"
              control={{
                type: 'switch',
                label: i18n.t('entity.customerPaymentSettings.labels.abroadSale'),
                value: formApi.watch('allowAbroadSale'),
                onChange: (value) => formApi.setValue('allowAbroadSale', value),
                'data-testid': suffixTestId('allowAbroadSale', props),
              }}
              isExpanded={formApi.watch('allowAbroadSale')}
            >
              <HStack spacing={4}>
                <FormField
                  control={control}
                  type="chips"
                  name="abroadSale"
                  isRequired
                  options={abroadSaleOptions}
                  data-testid={suffixTestId('abroadSaleOptions', props)}
                />
              </HStack>
            </Card>
          </Show>
          <Card
            variant="inlineGrey"
            control={{
              type: 'switch',
              label: i18n.t('entity.customerPaymentSettings.labels.allowBankTransfer'),
              value: formApi.watch('allowBankTransfer'),
              onChange: (value) => formApi.setValue('allowBankTransfer', value),
              'data-testid': suffixTestId('allowBankTransfer', props),
            }}
            isExpanded={formApi.watch('allowBankTransfer')}
          >
            <Grid spacing={4} columns={2}>
              <FormField
                control={control}
                type="integer"
                name="invoiceMaturity"
                label={i18n.t('entity.customerPaymentSettings.labels.invoiceMaturityDays')}
                isRequired
                data-testid={suffixTestId('invoiceMaturity', props)}
              />
              <FormField
                control={control}
                type="email"
                name="email"
                label={i18n.t('entity.customerPaymentSettings.labels.email')}
                data-testid={suffixTestId('email', props)}
              />
            </Grid>
          </Card>
          <ButtonGroup align="right">
            <Button
              data-testid={suffixTestId('discard', props)}
              variant="secondary"
              onClick={props.onClose}
              title={i18n.t('general.actions.discard')}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const formSchema = object({
  invoiceMaturity: yupNumber.when('allowBankTransfer', {
    is: true,
    then: yupNumber.required(),
  }),
});
