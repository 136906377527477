import {
  UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField,
  showNotification,
} from 'platform/components';
import {Pattern, match} from 'ts-pattern';

import {useEffect, useRef, useState} from 'react';

import i18n from '@omnetic-dms/i18n';
import {
  SaleVehiclePhotoPositionDto,
  UpdatePositionInSaleVehicleRequestBody,
  UploadFileError,
  handleApiError,
  useAddFilesToSaleVehicleAlbumMutation,
  useAddFilesToSaleVehicleAlbumOptimisticMutation,
  useAddPromoPhotosToSaleVehicleMutation,
  useCustomUpload,
  useDeleteSaleVehicleAlbumMutation,
  useDeleteSaleVehiclePhotoMutation,
  useRemoveInSaleVehicleMutation,
  useRotateSaleVehiclePhotosMutation,
  useSetSaleVehicleAlbumCoverPhotoMutation,
  useUpdatePositionsInSaleVehicleMutation,
  useUpdateSaleVehiclePhotoAlbumMutation,
  useResetPositionsInSaleVehicleMutation,
  UploadFileResponse,
} from '@omnetic-dms/shared';

interface UseVehicleAlbumProps {
  saleVehicleAlbumId: string;
  saleVehicleId: string;
}

export const useSaleVehicleAlbum = (props: UseVehicleAlbumProps) => {
  const [processingImageIds, setProcessingImageIds] = useState<string[]>([]);
  const [uploadFile] = useCustomUpload();
  const [albumImagePreviews, setImagePreviews] = useState<{id: string; uri: string}[]>([]);

  const uploadQueue = useRef<Map<string, Promise<UploadFileResponse | undefined>>>(new Map());

  const [updateSaleVehicleAlbum, {isLoading: isUpdateSaleVehicleAlbumLoading}] =
    useUpdateSaleVehiclePhotoAlbumMutation();

  const [deleteSaleVehicleAlbum, {isLoading: isDeleteAlbumLoading}] =
    useDeleteSaleVehicleAlbumMutation();

  const [addSaleVehiclePhotoToAlbum, {isLoading: isAddSaleVehiclePhotoToAlbumLoading}] =
    useAddFilesToSaleVehicleAlbumMutation();
  const [addSaleVehiclePhotoToAlbumCache] = useAddFilesToSaleVehicleAlbumOptimisticMutation();

  const [addPromoPhotosToAlbum, {isLoading: isAddPromoPhotosLoading}] =
    useAddPromoPhotosToSaleVehicleMutation();
  const [deletePromoPhotoFromAlbum, {isLoading: isDeletePromoPhotoLoading}] =
    useRemoveInSaleVehicleMutation();

  const [deleteSaleVehiclePhoto, {isLoading: isDeleteSaleVehiclePhotoLoading}] =
    useDeleteSaleVehiclePhotoMutation();

  const [rotateSaleVehiclePhoto] = useRotateSaleVehiclePhotosMutation();

  const [setAsCoverPhoto, {isLoading: isSetAsCoverPhotoLoading}] =
    useSetSaleVehicleAlbumCoverPhotoMutation();

  const [
    updatePromotionalPhotosPositionsInSaleVehicle,
    {isLoading: isUpdatePromotionalPhotosPositionsInSaleVehicleLoading},
  ] = useUpdatePositionsInSaleVehicleMutation();

  const [resetPromotionalPhotosPositions, {isLoading: isResetPromotionalPhotosPositionsLoading}] =
    useResetPositionsInSaleVehicleMutation();

  const {saleVehicleAlbumId, saleVehicleId} = props;
  useEffect(() => {
    if (uploadQueue.current.size > 0 && uploadQueue.current.size === albumImagePreviews.length) {
      const queue = Array.from(uploadQueue.current.values());
      const fileIds: string[] = [];
      Promise.allSettled(queue).then(() => {
        albumImagePreviews.forEach(async (preview) => {
          if (uploadQueue.current.has(preview.id)) {
            // this will resolve immediately, as the promise is already resolved
            const data = await uploadQueue.current.get(preview.id);
            if (data) {
              // Update the query data optimistically to prevent flickering on slow networks
              addSaleVehiclePhotoToAlbumCache({
                saleVehicleAlbumId,
                saleVehicleId,
                data: {
                  fileId: data.file.name,
                  fileUri: data.fileUri,
                },
              });

              // all promises are resolved, so we can safely add the file id to the list
              fileIds.push(data.fileId);
            }
            uploadQueue.current.delete(preview.id);
          }
          hideFilePreview(preview.id);
        });

        addSaleVehiclePhotoToAlbum({
          saleVehicleAlbumId,
          addFilesToSaleVehicleAlbumRequestBody: {
            fileIds,
          },
        })
          .unwrap()
          .catch(handleApiError);
      });
    }
  }, [
    albumImagePreviews,
    addSaleVehiclePhotoToAlbum,
    addSaleVehiclePhotoToAlbumCache,
    saleVehicleAlbumId,
    saleVehicleId,
  ]);

  const showFilePreview = (file: string | File) => {
    if (typeof file === 'string') {
      setImagePreviews((prev) => [...prev, {id: file, uri: file}]);
    } else {
      setImagePreviews((prev) => [...prev, {id: file.name, uri: URL.createObjectURL(file)}]);
    }
  };

  const hideFilePreview = (fileName: string) => {
    setImagePreviews((prev) => prev.filter((preview) => preview.id !== fileName));
  };

  const startProcessingImage = (id: string | string[]) => {
    setProcessingImageIds((prev) => [...prev, ...(Array.isArray(id) ? id : [id])]);
  };

  const stopProcessingImage = (id: string | string[]) => {
    setProcessingImageIds((prev) =>
      prev.filter((imageId) => (Array.isArray(id) ? !id.includes(imageId) : id !== imageId))
    );
  };

  const addToAlbum = ({
    file,
    onSuccess,
    onError,
  }: {
    file: File;
    onSuccess?: (data: unknown) => void;
    onError?: (error: UploadFileError | ProgressEvent) => void;
  }) => {
    const uploadPromise = new Promise<UploadFileResponse | undefined>((resolve, reject) => {
      uploadFile({
        file,
        onError: (error) => {
          onError?.(error);
          reject();
        },
        onSuccess: (data) => {
          resolve(data);
          onSuccess?.(data);
        },
      });
    });

    uploadQueue.current.set(file.name, uploadPromise);
  };

  const addPromotionalPhotosToAlbum = () => {
    addPromoPhotosToAlbum({
      addToSaleVehicleRequestBody: {
        saleVehicleId: props.saleVehicleId,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const deletePromotionalPhotoFromAlbum = (uuid: string) => {
    if (!uuid) {
      throw new Error('No file id provided to delete');
    }
    deletePromoPhotoFromAlbum({
      removeInSaleVehicleRequestBody: {
        saleVehiclePromoPhotoId: uuid,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const deleteFromAlbum = (uuid: string | string[]) => {
    if (!uuid || (Array.isArray(uuid) && !uuid.length)) {
      throw new Error('No file id provided to delete');
    }
    startProcessingImage(uuid);
    deleteSaleVehiclePhoto({
      saleVehicleId: props.saleVehicleId,
      saleVehicleAlbumId: props.saleVehicleAlbumId,
      saleVehiclePhotoDeleteRequestBody: {
        photos: Array.isArray(uuid) ? uuid.map((fileId) => ({fileId})) : [{fileId: uuid}],
      },
    })
      .unwrap()
      .catch(handleApiError)
      .finally(() => {
        stopProcessingImage(uuid);
      });
  };

  const deleteAlbum = () => {
    deleteSaleVehicleAlbum({
      saleVehicleAlbumId: props.saleVehicleAlbumId,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const moveImages = (sourceAlbum: string, photos: SaleVehiclePhotoPositionDto[]) => {
    updateSaleVehicleAlbum({
      saleVehiclePhotoUpdateAlbumRequestBody: {
        photos: photos.map((image, index) => ({...image, position: index})),
        sourceAlbum,
        destinationAlbum: props.saleVehicleAlbumId,
        position: photos[0]?.position * 100 + 100,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const movePromotionalImages = (positions: UpdatePositionInSaleVehicleRequestBody[]) => {
    updatePromotionalPhotosPositionsInSaleVehicle({
      updatePositionsInSaleVehicleRequestBody: {
        positions,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const resetPromotionalPhotos = () => {
    resetPromotionalPhotosPositions({
      resetPositionsInSaleVehicleRequestBody: {
        saleVehicleId: props.saleVehicleId,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  // HANDLERS
  const handleUploadError = (error: Error | string, _ret: Record<string, unknown>, file: File) => {
    match({error})
      .with(
        {
          error: Pattern.instanceOf(UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField),
        },
        () => {
          showNotification.error(
            i18n.t('entity.photo.notifications.imageFormatIsNotAllowed', {
              name: file.name,
              mimeFormat: file.type,
            })
          );
        }
      )
      .otherwise(() => {
        showNotification.error(
          i18n.t('entity.photo.notifications.uploadFailed', {
            name: file.name,
          })
        );
      });
  };

  // IMAGE ACTIONS
  const rotateImage = ({uuid, angle}: {uuid: string | string[]; angle: 90 | 180 | 270}) => {
    if (!uuid || (Array.isArray(uuid) && !uuid.length)) {
      throw new Error('No file id provided to rotate');
    }
    startProcessingImage(uuid);
    rotateSaleVehiclePhoto({
      saleVehicleId: props.saleVehicleId,
      saleVehicleAlbumId: props.saleVehicleAlbumId,
      rotateSaleVehiclePhotosRequestBody: {
        saleVehiclePhotoIds: Array.isArray(uuid) ? uuid : [uuid],
        angle,
      },
    })
      .unwrap()
      .catch(handleApiError)
      .finally(() => {
        // delay stopping to prevent flickering of spinners
        setTimeout(() => {
          stopProcessingImage(uuid);
        }, 1000);
      });
  };

  const setImageAsCoverPhoto = (uuid: string) => {
    startProcessingImage(uuid);
    setAsCoverPhoto({
      saleVehicleAlbumId: props.saleVehicleAlbumId,
      saleVehiclePhotoId: uuid,
    })
      .unwrap()
      .catch(handleApiError)
      .finally(() => {
        stopProcessingImage(uuid);
      });
  };

  return {
    actions: {
      addImageToAlbum: addToAlbum,
      addPromotionalPhotosToAlbum,
      deleteImageFromAlbum: deleteFromAlbum,
      deletePromotionalPhotoFromAlbum,
      rotateImage,
      setImageAsCoverPhoto,
      deleteAlbum,
      moveImages,
      movePromotionalImages,
      resetPromotionalPhotos,
    },
    handlers: {
      handleUploadError,
    },
    filePreview: {
      list: () => albumImagePreviews,
      show: showFilePreview,
      hide: hideFilePreview,
    },
    processingImageIds,
    startProcessingImage,
    stopProcessingImage,
    areActionsInProgress:
      isDeleteAlbumLoading ||
      isAddSaleVehiclePhotoToAlbumLoading ||
      isAddPromoPhotosLoading ||
      isDeletePromoPhotoLoading ||
      isSetAsCoverPhotoLoading ||
      isDeleteSaleVehiclePhotoLoading ||
      isUpdateSaleVehicleAlbumLoading ||
      isUpdatePromotionalPhotosPositionsInSaleVehicleLoading ||
      isResetPromotionalPhotosPositionsLoading,
  };
};
