import {Action, Card} from 'platform/components';
import {Box, Grid, GridItem, Hide} from 'platform/foundation';

import {FC, useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CommentsWidget,
  EntityResourceIds,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  usePermissions,
} from '@omnetic-dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {BusinessCasePurchaseVehicleBrokerageFeesDetail} from './BusinessCasePurchaseVehicleBrokerageFeesDetail';
import {BusinessCasePurchaseVehicleBrokerageFeesForm} from './BusinessCasePurchaseVehicleBrokerageFeesForm';

const BrokerageFeesTab: FC = () => {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const offer = businessCase?.offers[0];
  const isEditable = businessCase?.actions.purchaseEditVehicle;
  const purchaseVehicle = businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0];

  const isConfirmed = !!purchaseVehicle?.brokerageFees;

  const [isDetail, setDetail] = useState((isConfirmed && isEditable) || !isEditable);

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: purchaseVehicle?.id ?? '',
    },
    {skip: isNil(purchaseVehicle?.id)}
  );

  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
    hasEditBusinessCasePurchaseBrokerageFeesPermission,
  ] = usePermissions({
    permissionKeys: [
      'vehicleCommentRead',
      'vehicleCommentAdd',
      'vehicleCommentDelete',
      'editBusinessCasePurchaseBrokerageFees',
    ],
    scopes: {
      vehicleCommentAdd: vehicleParticipation,
      vehicleCommentRead: vehicleParticipation,
    },
  });

  const toggleDetail = () => setDetail((prevDetail) => !prevDetail);

  useEffect(() => {
    setDetail((isConfirmed && isEditable) || !isEditable);
  }, [isConfirmed, isEditable]);

  const cardTitle = isDetail ? i18n.t('entity.businessCase.labels.contractLength') : undefined;

  return (
    <Grid columns={2}>
      <GridItem>
        <Card
          title={cardTitle}
          actions={buildArray<Action>().when(isDetail && isEditable, {
            type: 'button',
            variant: 'link',
            leftIcon: 'image/edit',
            onClick: toggleDetail,
            isDisabled: !hasEditBusinessCasePurchaseBrokerageFeesPermission,
            title: i18n.t('general.actions.edit'),
            'data-testid': testIds.businessCase.buying('brokerageFees-edit'),
          })}
          data-testid={testIds.businessCase.buying('brokerageFees')}
          variant="inlineWhite"
        >
          {purchaseVehicle && (
            <>
              {isDetail ? (
                <BusinessCasePurchaseVehicleBrokerageFeesDetail
                  values={purchaseVehicle.brokerageFees}
                />
              ) : (
                <BusinessCasePurchaseVehicleBrokerageFeesForm
                  businessCaseId={businessCaseId}
                  offerId={offer?.id}
                  purchaseVehicleId={purchaseVehicle.id}
                  data-testid={testIds.businessCase.buying('brokerageFees-edit')}
                  initialValues={purchaseVehicle}
                  onClose={toggleDetail}
                  isEditDisabled={!hasEditBusinessCasePurchaseBrokerageFeesPermission}
                />
              )}
            </>
          )}
        </Card>
      </GridItem>
      <Hide when={isNilOrEmpty(purchaseVehicle?.vehicleId) || !hasVehicleCommentReadPermission}>
        <Box>
          {purchaseVehicle && (
            <CommentsWidget
              cardVariant="inlineWhite"
              title={i18n.t('entity.vehicle.labels.vehicleComments')}
              resourceId={EntityResourceIds.vehicle}
              recordId={purchaseVehicle.vehicleId!}
              isAddDisabled={!hasVehicleCommentAddPermission}
              isDeleteDisabled={!hasVehicleCommentDeletePermission}
            />
          )}
        </Box>
      </Hide>
    </Grid>
  );
};

export const BusinessCasePurchaseVehicleBrokerageFees = BrokerageFeesTab;
