import {
  Button,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Heading, Hide, Right, Show, Space, VStack} from 'platform/foundation';

import {useNavigate, useParams} from 'react-router-dom';

import {head, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  CreateTypeOfControlSettingsRequestBody,
  handleApiError,
  InspectionType,
  useCreateTypeOfControlSettingsMutation,
  useGetBasicStructureForSettingsQuery,
  useGetInspectionTemplatesQuery,
  useGetStructureSettingsQuery,
} from '@omnetic-dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CategoryCard} from './components/CategoryCard';
import {StructureSettingsForm} from './types/StructureSettingsForm';

interface InspectionsDetailProps {
  inspectionType?: InspectionType;
}

export function InspectionsDetail(props: InspectionsDetailProps) {
  const {id} = useParams();
  const navigate = useNavigate();
  const [createTypeOfControlSettings] = useCreateTypeOfControlSettingsMutation();

  const isTemplateDefinedByType = isNotNil(props.inspectionType);

  const {
    data: templateData,
    isLoading: isTemplateLoading,
    isError: isTemplateError,
  } = useGetInspectionTemplatesQuery(
    {},
    {
      selectFromResult: ({data, isLoading, isError}) => ({
        data: data?.find((template) =>
          isTemplateDefinedByType
            ? template.inspectionType === props.inspectionType
            : template.id === id
        ),
        isLoading,
        isError,
      }),
    }
  );

  const {
    data: structureData,
    isLoading: isStructureLoading,
    isError: isStructureError,
  } = useGetBasicStructureForSettingsQuery(
    {templateId: templateData?.id},
    {skip: !templateData?.id}
  );

  const {
    data: settingsData,
    isLoading: isSettingsDataLoading,
    isError: isSettingsDataError,
  } = useGetStructureSettingsQuery({typeOfControlId: templateData?.id}, {skip: !templateData?.id});

  const handleSubmit: FormSubmitHandler<StructureSettingsForm> = async (data) => {
    if (isNil(templateData) || isNil(settingsData) || isNil(data.name)) {
      return;
    }
    const body: CreateTypeOfControlSettingsRequestBody = {
      inspectionType: templateData.inspectionType,
      key: settingsData.key ?? null,
      validation: settingsData.validation,
      name: data.name,
      items: [],
    };

    Object.entries(data.categories).forEach(([categoryId, isVisible]) => {
      body.items.push({
        mandatory: false,
        visible: isVisible,
        paramDefinitionId: null,
        categoryId,
      });
    });

    Object.entries(data.paramDefinitions).forEach(([paramDefinitionId, state]) => {
      body.items.push({
        mandatory: state.isMandatory ?? false,
        visible: state.isChecked,
        categoryId: null,
        paramDefinitionId,
      });
    });

    await createTypeOfControlSettings({createTypeOfControlSettingsRequestBody: body})
      .unwrap()
      .then(() =>
        props.inspectionType
          ? showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
          : navigate(settingsRoutes.inspections)
      )
      .catch(handleApiError);
  };

  const getDefaultValues = () => {
    const defaultValues: StructureSettingsForm = {
      name: templateData?.name,
      categories: {},
      paramDefinitions: {},
    };

    settingsData?.items.forEach((item) => {
      if (item.categoryId) {
        defaultValues.categories[item.categoryId] = item.visible;
      }
      if (item.paramDefinitionId) {
        defaultValues.paramDefinitions[item.paramDefinitionId] = {
          isChecked: item.visible,
          isMandatory: item.mandatory,
        };
      }
    });
    return defaultValues;
  };

  const isLoading = isTemplateLoading || isStructureLoading || isSettingsDataLoading;
  const isError = isTemplateError || isStructureError || isSettingsDataError;

  return (
    <SettingsTemplate
      header={{title: templateData?.name}}
      description={i18n.t('page.vehicleSettings.labels.inspectionDescription')}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.inspectionDetail('template')}
    >
      <Form<StructureSettingsForm> defaultValues={getDefaultValues()} onSubmit={handleSubmit}>
        {(control, formApi) => (
          <VStack spacing={4}>
            <Hide when={props.inspectionType}>
              <Box maxWidth={100}>
                <FormField
                  data-testid={testIds.settings.inspectionDetail('template')}
                  control={control}
                  name="name"
                  type="text"
                  label={i18n.t('general.labels.name')}
                  isRequired
                />
              </Box>
              <Separator />
            </Hide>
            <Heading size={4}>{i18n.t('page.inspectionSettings.labels.structure')}</Heading>
            {structureData &&
              head(structureData)?.categories.map((category) => (
                <CategoryCard
                  key={category.id}
                  categoryData={category}
                  isActive={formApi.watch('categories')[category.id]}
                  formApi={formApi}
                />
              ))}
            <Right>
              <Hide when={props.inspectionType}>
                <Button
                  data-testid={testIds.settings.inspectionDetail('discardChanges')}
                  onClick={() => navigate(settingsRoutes.inspections)}
                  title={i18n.t('general.actions.discardChanges')}
                  variant="secondary"
                />
              </Hide>
              <Show when={props.inspectionType}>
                <FormButton
                  control={control}
                  type="reset"
                  title={i18n.t('general.actions.discardChanges')}
                  variant="secondary"
                  data-testid={testIds.settings.inspectionDetail('discardChanges')}
                />
              </Show>
              <Space horizontal={2} />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.saveChanges')}
                data-testid={testIds.settings.inspectionDetail('saveChanges')}
              />
            </Right>
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}
