import {
  Alert,
  ButtonGroup,
  ButtonProps,
  closeCurrentDialog,
  DataStatus,
  showNotification,
} from 'platform/components';
import {GridItem, Grid} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  AdvertisingPlatformCodeEnum,
  handleApiError,
  useAdPlatformsVehicleUpdateDialog,
  useEditPlatformSettingsMutation,
  useGetPlatformSettingsQuery,
  VehiclePlatformAdvertisingSettingsRequestBody,
  VehiclePlatformAdvertisingSettingsResponseBody,
} from '@omnetic-dms/shared';
import {Form, $AdvertisingSettingsRequestBody} from '@omnetic-dms/teas';

import {suffixTestId, appendSuffix, TestIdProps, buildArray} from 'shared';

import {AdvertisingAttributeField} from './AdvertisingAttributeField';

interface PlatformEditProps extends TestIdProps {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
  isDisabled: boolean;
}

export function PlatformEdit(props: PlatformEditProps) {
  const [updateVehicleAds] = useAdPlatformsVehicleUpdateDialog();

  const [editPlatformSettings, {isLoading: isEditLoading}] = useEditPlatformSettingsMutation();

  const {
    data: platformSettings,
    isLoading,
    isError,
  } = useGetPlatformSettingsQuery(
    {
      vehicleId: props.vehicleId,
      platformCode: props.platformCode,
    },
    {
      skip: isNilOrEmpty(props.vehicleId) || isNilOrEmpty(props.platformCode),
    }
  );

  const handleSubmit = async (values: VehiclePlatformAdvertisingSettingsRequestBody) => {
    await editPlatformSettings({
      vehicleId: props.vehicleId,
      platformCode: props.platformCode,
      vehiclePlatformAdvertisingSettingsRequestBody: values,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .then(() => {
        showNotification.success(
          i18n.t('general.notifications.advertisement.windshieldDataEditSubtitle')
        );
        updateVehicleAds(props.vehicleId, props.platformCode);
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNilOrEmpty(platformSettings)}>
      <Form<
        VehiclePlatformAdvertisingSettingsResponseBody,
        VehiclePlatformAdvertisingSettingsResponseBody,
        VehiclePlatformAdvertisingSettingsRequestBody
      >
        formId={appendSuffix('platformEditForm', props['data-testid'])}
        onSubmit={handleSubmit}
        initialValues={platformSettings}
        schema={$AdvertisingSettingsRequestBody}
        getFormValues={(settings) => ({attributes: settings.attributes})}
        getRequestBody={(values) => ({
          attributes: values.attributes.map((attribute) => ({
            ...attribute,
            value: attribute.override ? attribute.value : null,
          })),
        })}
        render={(formRendererProps) => {
          const {handleSubmit, FieldArray} = formRendererProps;

          const actions = buildArray<ButtonProps>()
            .add({
              title: i18n.t('general.actions.discard'),
              variant: 'secondary',
              onClick: closeCurrentDialog,
            })
            .whenNot(props.isDisabled, {
              title: i18n.t('general.actions.confirm'),
              onClick: handleSubmit,
              isLoading: isEditLoading,
            });

          return (
            <form onSubmit={handleSubmit}>
              <Grid columns={12}>
                <GridItem span={12}>
                  <Alert
                    data-testid={suffixTestId('platformEdit-alert', props)}
                    type="inline"
                    variant="info"
                    title={i18n.t('general.notifications.advertisement.platformDataEdit')}
                  />
                </GridItem>
                <FieldArray<VehiclePlatformAdvertisingSettingsRequestBody> name="attributes">
                  {({fields}) =>
                    fields.map((name) => (
                      <AdvertisingAttributeField
                        name={name}
                        key={name}
                        isDisabled={props.isDisabled}
                      />
                    ))
                  }
                </FieldArray>
                <GridItem span={12}>
                  <ButtonGroup align="right" buttons={actions} />
                </GridItem>
              </Grid>
            </form>
          );
        }}
      />
    </DataStatus>
  );
}
