import type {CancelablePromise} from '../types/CancelablePromise';
import type {SourcingVehicleDetail} from '../types/SourcingVehicleDetail';
import {request as __request} from '../utils/request';

export class SourcingVehicleService {
  /**
   * @returns SourcingVehicleDetail
   * @throws ApiError
   */
  public static sourcingVehicleDetail({
    externalId,
    xBranch,
    marginLte,
    marginGte,
    positionGte,
    positionLte,
    currency,
    buyerCountry,
    authorization,
  }: {
    externalId: string;
    xBranch: string;
    marginLte?: number | null;
    marginGte?: number | null;
    positionGte?: number | null;
    positionLte?: number | null;
    currency?: string;
    buyerCountry?: string;
    authorization?: string;
  }): CancelablePromise<SourcingVehicleDetail> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sourcing-vehicle/{externalId}',
      path: {
        externalId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      query: {
        marginLte,
        marginGte,
        positionGte,
        positionLte,
        currency,
        buyerCountry,
      },
    });
  }
}
