import {
  ButtonProps,
  Alert,
  AlertProps,
  openConfirmDialog,
  openDialog,
  closeCurrentDialog,
} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {always, equals, isNil, not} from 'ramda';
import {isArray, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  queryParams,
  useDeleteAllMaterialBasketItemsMutation,
  useGetServiceOrderJobQuery,
  usePostMaterialBasketItemsToOrderMutation,
  usePutItemsBasketActionMutation,
  FullScreenModal,
  AfterSalesMaterialBasket,
  useGetMaterialBasketItemsQuery,
  useInvalidBasketItemsIds,
  EitherQuantityOrError,
  useDeleteMaterialBasketItemsMutation,
  usePatchMaterialBasketItemQuantityMutation,
  useGetEmployeeMechanicsQuery,
  useGetServiceOrderVariantQuery,
  useGetServiceOrderQuery,
  EditMaterialItem,
  MaterialBasketItemEditingDetails,
  AssignedMechanic,
} from '@omnetic-dms/shared';

import {buildArray, Nullish, suffixTestId, TestIdProps, useQueryState} from 'shared';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {ExternalSystemStateType} from '../types/externalSystemStateType';
import {MaterialBasketItemType} from '../types/MaterialBasketItemType';
import {MaterialListCard} from './MaterialListCard';

interface AddMaterialRequestModalProps extends TestIdProps {
  onSubmit: VoidFunction;
}

export function AddMaterialRequestModal(props: AddMaterialRequestModalProps) {
  const {serviceCaseId, orderId, addMaterialRequestJobId} = useWorkshopUrl();
  const [externalSystemState, setExternalSystemState] = useState<ExternalSystemStateType | null>(
    null
  );
  const [, , closeModal] = useQueryState(queryParams.SERVICE_CASE_ADD_MATERIAL_REQUEST_JOB_ID);
  const [assignedMechanic, setAssignedMechanic] = useState<AssignedMechanic | Nullish>(null);

  const {data: serviceJob} = useGetServiceOrderJobQuery({
    serviceCaseId,
    serviceOrderId: orderId,
    serviceJobId: addMaterialRequestJobId,
  });

  const {data: serviceOrder} = useGetServiceOrderQuery({serviceCaseId, serviceOrderId: orderId});
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: isNil(serviceOrder?.serviceOrderVariantId)}
  );

  const {data: mechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {skip: isNil(orderVariant?.authorizationProfileId)}
  );

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: isBasketError,
  } = useGetMaterialBasketItemsQuery({
    serviceCaseId,
    serviceOrderId: orderId,
    serviceJobId: addMaterialRequestJobId,
  });

  const [deleteMaterialBasketItems, {isLoading: isDeleteBasketItemsLoading}] =
    useDeleteMaterialBasketItemsMutation();
  const [patchMaterialBasketItemQuantity] = usePatchMaterialBasketItemQuantityMutation();

  const basketItems = basket?.materialBasketItem ?? [];

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const [putItemsBasketAction, {isLoading: isPutItemsLoading}] = usePutItemsBasketActionMutation();
  const [deleteAllMaterialBasketItems, {isLoading: isDeleteLoading}] =
    useDeleteAllMaterialBasketItemsMutation();
  const [postMaterialBasketItemsToOrder, {isLoading: isPostLoading}] =
    usePostMaterialBasketItemsToOrderMutation();

  useEffect(() => {
    const mechanic = serviceJob?.assignMechanics?.[0];

    if (isNil(mechanic)) {
      return;
    }

    setAssignedMechanic({
      id: mechanic.id!,
      isDefault: true,
      costCenterId: mechanic.costCenterId!,
    });
  }, [serviceJob?.assignMechanics]);

  const handleCancel = () => {
    deleteAllMaterialBasketItems({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addMaterialRequestJobId,
    })
      .unwrap()
      .catch(handleApiError)
      .finally(closeModal);
  };

  const handleAddMaterial = () =>
    postMaterialBasketItemsToOrder({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addMaterialRequestJobId,
      body: assignedMechanic
        ? {
            assignMechanics: [
              {
                id: assignedMechanic?.id ?? '',
                isDefault: true,
                costCenterId: assignedMechanic?.costCenterId,
              },
            ],
          }
        : undefined,
    })
      .unwrap()
      .then(() => {
        closeModal();
        props.onSubmit();
      })
      .catch(handleApiError);

  const handleMechanicChange = (mechanic: AssignedMechanic | Nullish) =>
    setAssignedMechanic(mechanic);

  const actions = buildArray<ButtonProps>()
    .add({
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleCancel,
      isLoading: isDeleteLoading,
      'data-testid': suffixTestId('cancel', props),
    })
    .add({
      title: i18n.t('general.actions.add'),
      onClick: handleAddMaterial,
      isLoading: isPostLoading,
      'data-testid': suffixTestId('add', props),
    });

  const onUploadClick = () => {
    openConfirmDialog({
      text: i18n.t('entity.addMaterial.lables.uploadFromXentry'),
      onConfirm: () =>
        putItemsBasketAction({
          basketType: 'material-basket',
          body: {actionKey: 'xentry-xhpi-inquiry'},
          serviceCaseId,
          serviceOrderId: orderId,
          serviceJobId: addMaterialRequestJobId,
        })
          .unwrap()
          .then(() => setExternalSystemState('UPLOAD_SCCESS'))
          .catch((error) =>
            handleApiError(error, {callback: () => setExternalSystemState('UPLOAD_ERROR')})
          ),
    });
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchMaterialBasketItemQuantity({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addMaterialRequestJobId,
      serviceItemId: itemId,
      body: {
        quantity: quantity.newQuantity || 0,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {serviceItemId: ids};

    await deleteMaterialBasketItems({
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addMaterialRequestJobId,
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const onEdit = (editingDetails: MaterialBasketItemEditingDetails) =>
    openDialog(
      <EditMaterialItem
        itemId={editingDetails.itemId}
        serviceCaseId={serviceCaseId}
        serviceJobId={addMaterialRequestJobId}
        serviceOrderId={orderId}
        onClose={closeCurrentDialog}
      />,
      {title: `${editingDetails.itemName} (${editingDetails.itemNumber})`}
    );

  const alertProps = match<ExternalSystemStateType | null, AlertProps>(externalSystemState)
    .with(
      'PREPARED',
      always({
        variant: 'info',
        title: i18n.t('entity.addMaterial.labels.materialPrepared'),
        message: i18n.t('entity.addMaterial.labels.materialPreparedMessage'),
      })
    )
    .with(
      'UPLOAD_SCCESS',
      always({
        variant: 'success',
        title: i18n.t('entity.addMaterial.labels.materialUploaded'),
        onClose: () => setExternalSystemState(null),
      })
    )
    .with(
      'UPLOAD_ERROR',
      always({
        variant: 'error',
        title: i18n.t('entity.addMaterial.labels.materialUploadedError'),
        message: i18n.t('entity.addMaterial.labels.materialUploadedErrorMessage'),
        hyperlinks: [
          {
            leftIcon: 'file/upload',
            onClick: onUploadClick,
            isDisabled: isPutItemsLoading,
            title: i18n.t('general.actions.upload'),
          },
        ],
      })
    )
    .with(null, always({}))
    .exhaustive();

  return (
    <FullScreenModal
      headline={i18n.t('entity.addMaterial.actions.addMaterialRequest')}
      actions={actions}
    >
      <Box padding={4} height="100%">
        <VStack spacing={4} height="100%">
          <Show when={isNotNil(externalSystemState)}>
            <Alert data-testid={suffixTestId('alert', props)} {...alertProps} />
          </Show>
          <Box maxHeight="100%" flex={1}>
            <HStack spacing={4} height="100%">
              <Box flex={5}>
                <MaterialListCard
                  onExternalSystemChange={setExternalSystemState}
                  data-testid={suffixTestId('itemList', props)}
                />
              </Box>
              <Box flex={1} minWidth={86}>
                <AfterSalesMaterialBasket<MaterialBasketItemType>
                  basket={{
                    items: basket?.materialBasketItem,
                    totalPrice: basket?.materialBasketTotalPrice,
                    isLoading: isBasketLoading,
                    hasError: isBasketError,
                    hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                    isDeletingItems: isDeleteBasketItemsLoading,
                  }}
                  mechanic={{mechanics, assignedMechanic, onMechanicChange: handleMechanicChange}}
                  onQuantityChange={handleQuantityChange}
                  onEdit={onEdit}
                  onDelete={handleDelete}
                  data-testid={suffixTestId('basket', props)}
                />
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </FullScreenModal>
  );
}
