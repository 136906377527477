import {isTrue} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {useGetVehicleSalesActionsQuery} from '../api/saleVehicleApi';
import {VehicleSalesAction, VehicleSalesActionsResponseBody} from '../types/api/saleVehicle';

export function useSaleVehicleActions(vehicleId: string) {
  const {data} = useGetVehicleSalesActionsQuery({vehicleId}, {refetchOnFocus: true});

  return {isSaleActionEnabled: isSaleActionEnabled(data)};
}

export const isSaleActionEnabled =
  (data: VehicleSalesActionsResponseBody | Nullish) => (actionKey: VehicleSalesAction) =>
    isTrue(data?.actions?.includes(actionKey));
