import {createSelector} from '@reduxjs/toolkit';

import {FullVehicle} from '@omnetic-dms/shared';

import type {TeasState} from '../../types/TeasState';

export const selectCarDetails = (state: TeasState) => state.sales.carDetails;

export const selectSalesVehicleDetail = createSelector(
  selectCarDetails,
  (state) => state.vehicleDetail
);
export const selectSalesVehicleData = createSelector(
  selectSalesVehicleDetail,
  (state) => state?.data
);

export const selectCloneVehicleData = createSelector(selectSalesVehicleDetail, (state) => {
  const {modelSpecification, ...rest} = state.data || {};

  return {
    ...rest,
    vin: null,
    state: null,
    modelSpecification: {
      originCountry: null,
      firstRegistrationCountry: null,
      lastRegistrationCountry: null,
      warrantyDate: null,
      warrantyMileage: null,
      isRegistered: null,
      serviceContractUntil: null,
      serviceContractMileageLimit: null,
      serviceContractNote: null,
      seatCount: modelSpecification?.seatCount,
    },
  } as FullVehicle;
});

export const selectSalesVehicleIsLoading = createSelector(
  selectSalesVehicleDetail,
  (state) => state?.isLoading
);

export const selectPhotosSelectionActiveAlbum = createSelector(
  selectCarDetails,
  (state) => state.photoSelectionActiveAlbum
);

export const pickSelectedPhotos = createSelector(
  selectCarDetails,
  (state) => state.vehicleSelectedPhotos
);
