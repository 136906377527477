import {captureMessage} from '@sentry/browser';
import {ColumnState} from 'ag-grid-community';
import {diff} from 'deep-object-diff';
import {nanoid} from 'nanoid';
import {showNotification} from 'platform/components';

import {useCallback, useRef, useState} from 'react';

import {filter, isNil, map, move, pick, pipe, not} from 'ramda';
import {isArray, isNilOrEmpty, notEqual} from 'ramda-adjunct';

import {Nullish, sanitizeObject, useOnMount, useThrottledCallback} from 'shared';

import {SMART_SEARCH_KEY} from '../constants/smartSearchKey';
import {useDataGridContext} from '../context/useDataGridContext';
import {useDataQueryIdContext} from '../context/useDataQueryIdContext';
import {AgGridEvent, ColumnApi, ColumnResizedEvent, GridApi} from '../types/AgGridTypes';
import {
  ActivePreset,
  ColumnsSetting,
  FeGridSettings,
  GetDataQueryResponse,
  PostPresetRequest,
  Preset,
  PresetDetail,
  PutPresetRenameRequest,
  PutPresetRequest,
  VirtualPreset,
} from '../types/Api';
import {PresetsPanelRendererProps} from '../types/PresetsPanelRendererType';
import {getColumnState} from '../utils/getColumnState';
import {getPresetStates} from '../utils/getPresetStates';
import {isPresetColumnsSettingsInvalid} from '../utils/isPresetColumnsSettingsInvalid';
import {isPresetCustom} from '../utils/isPresetCustom';
import {getSearchParamsByGridCode} from '../utils/url/getSearchParamsByGridCode';
import {useGridApiEventListener} from './useGridApiEventListener';
import {useHttpCalls} from './useHttpCalls';

const filterTechnicalArray = (columnSettings: ColumnsSetting[]): ColumnsSetting[] =>
  columnSettings.filter(
    (columnSetting) =>
      columnSetting.columnKey !== 'smartSearch' && columnSetting.columnKey !== 'eag-col-actions'
  );

export const usePresets = (
  gridApi: GridApi,
  columnApi: ColumnApi,
  dataGridSettings: FeGridSettings,
  dataQuery: GetDataQueryResponse | Nullish,
  setSettings: (settings: FeGridSettings) => void
): PresetsPanelRendererProps => {
  const httpCalls = useHttpCalls();
  const {
    gridCode,
    virtualPreset,
    customPresets: initialCustomPresets,
    activePreset: initialActivePreset,
    onActivePresetChange,
  } = useDataGridContext();
  const dataQueryIdContext = useDataQueryIdContext();

  const searchParams = getSearchParamsByGridCode(gridCode);

  const [activePreset, setActivePreset] = useState<ActivePreset>(() => initialActivePreset);
  const [customPresets, setCustomPresets] = useState<Preset[]>(() => initialCustomPresets);
  const [activePresetDataQuery, setActivePresetDataQuery] = useState<
    GetDataQueryResponse | Nullish
  >(dataQuery);
  const isDataGridLoaded = useRef<boolean>(false);

  // We need to keep last virtual preset state to compare it with current state and update virtual preset only if it was changed
  const lastVirtualPresetState = useRef<VirtualPreset>({
    ...virtualPreset,
    columnsSettings: filterTechnicalArray(virtualPreset.columnsSettings),
  });

  // Indicate that custom preset has unsaved change
  const [presetChanged, setPresetChanged] = useState<boolean>(false);

  const updatePreset = useCallback(
    (presetId: string) => {
      const modifiedPreset: PutPresetRequest = {
        gridSettings: dataGridSettings,
        columnsSettings: getPresetStates(columnApi),
        dataQueryId: getSearchParamsByGridCode(gridCode).queryId,
      };

      if (isPresetColumnsSettingsInvalid(modifiedPreset)) {
        captureMessage('updatePreset', (scope) =>
          scope.setLevel('error').setExtras({
            userDescription: 'update custom preset from usePresets - updatePreset',
            state: modifiedPreset,
          })
        );
        return;
      }

      return httpCalls.updatePreset(presetId, modifiedPreset).then(() => {
        onActivePresetChange(modifiedPreset);
        setPresetChanged(false);
        setActivePresetDataQuery((prevState) => ({
          ...prevState!,
          filters: gridApi.getFilterModel(),
          smartSearch: gridApi.getFilterModel()[SMART_SEARCH_KEY],
        }));
        httpCalls.getPresets().then((presets) => presets && setCustomPresets(presets));
      });
    },
    [columnApi, gridApi, virtualPreset, customPresets, dataGridSettings]
  );

  const updateVirtualPreset = (settings: FeGridSettings | null) => {
    const dataQueryId = searchParams?.queryId;

    if (isNilOrEmpty(dataQueryId)) {
      return;
    }

    const modifiedPreset: VirtualPreset = {
      columnsSettings: filterTechnicalArray(getPresetStates(columnApi)),
      gridSettings: settings ?? dataGridSettings,
      dataQueryId,
    };

    const isPresetModified = isNilOrEmpty(lastVirtualPresetState.current)
      ? null
      : diff(lastVirtualPresetState.current, modifiedPreset);

    if (isPresetColumnsSettingsInvalid(modifiedPreset)) {
      captureMessage('updatePreset', (scope) =>
        scope.setLevel('error').setExtras({
          userDescription: 'update virtual preset from usePresets - updateVirtualPreset',
          state: modifiedPreset,
        })
      );
      return;
    }

    if (isNilOrEmpty(isPresetModified)) {
      return;
    }

    return httpCalls.updateVirtualPreset(modifiedPreset).then(() => {
      lastVirtualPresetState.current = modifiedPreset;
    });
  };

  const throttledUpdateVirtualPreset = useThrottledCallback(updateVirtualPreset, 1000);
  const extractColumnStateProperties = (columnState: ColumnState[]) =>
    filter(
      (column: Pick<ColumnState, 'colId'>) => column.colId !== 'eag-col-actions',
      map(pick(['colId', 'width', 'hide', 'pinned']), columnState as Required<ColumnState>[])
    );

  const presetChangeHandler = useCallback(
    (event: AgGridEvent) => {
      if (
        isColumnResizeEvent(event) &&
        event.column &&
        event.column.getId() === 'eag-col-actions'
      ) {
        return;
      }

      // DestroyCalled is true on datgrid unmount (route change) and we don't want update virtual preset  in such situation
      // @ts-ignore
      if (event.api.destroyCalled) {
        return;
      }

      if (event.type === 'firstDataRendered') {
        isDataGridLoaded.current = true;
      }
      if (!isPresetCustom(activePreset)) {
        throttledUpdateVirtualPreset(null);
      } else {
        if (isNil(activePresetDataQuery) || not(isDataGridLoaded.current)) {
          return;
        }

        setPresetChanged(
          notEqual(
            sanitizeObject(
              isArray(activePresetDataQuery.filters) ? {} : activePresetDataQuery.filters
            ),
            event.api.getFilterModel()
          ) ||
            notEqual(
              extractColumnStateProperties(gridApi.getColumnState()),
              extractColumnStateProperties(getColumnState(activePreset, activePresetDataQuery))
            ) ||
            notEqual(dataGridSettings, activePreset.gridSettings)
        );
      }
    },
    [
      virtualPreset,
      updatePreset,
      activePreset,
      activePresetDataQuery,
      throttledUpdateVirtualPreset,
      gridApi,
    ]
  );

  const {addListeners, removeListeners} = useGridApiEventListener(
    gridApi,
    [
      'sortChanged',
      'filterChanged',
      'columnVisible',
      'columnPinned',
      'columnResized',
      'columnMoved',
      'columnGroupOpened',
      'firstDataRendered',
      'settingsChanged',
    ],
    presetChangeHandler
  );

  useOnMount(addListeners);

  const activatePreset = async (preset: Preset) => {
    const presetDetail = await httpCalls.getPreset(preset.id);
    const presetFilters = await httpCalls.getDataQuery(preset.dataQueryId);
    const defaultState = columnApi.getColumnState();

    if (!presetDetail || !presetFilters) {
      return;
    }

    removeListeners();

    columnApi.applyColumnState({
      state: getColumnState(presetDetail, presetFilters, defaultState),
      // Whether column order should be applied
      applyOrder: true,
      // State to apply to columns where state is missing for those columns
      defaultState: {
        sort: null,
        pinned: null,
      },
    });

    const newFilters = {
      ...presetFilters.filters,
      [SMART_SEARCH_KEY]: presetFilters.smartSearch,
    };

    httpCalls.activatePreset(preset.id).then(() => {
      onActivePresetChange(presetDetail);
      setActivePreset(presetDetail);
      setSettings(presetDetail.gridSettings);
      gridApi?.setFilterModel(newFilters);
      setActivePresetDataQuery(presetFilters);
      dataQueryIdContext.changeDataQueryId?.(presetFilters?.id);
      addListeners();
      setPresetChanged(false);
    });
  };

  const deactivatePreset = async () => {
    const newVirtualPreset = await httpCalls.getVirtualPreset();

    if (!newVirtualPreset) {
      return;
    }

    const presetFilters = await httpCalls.getDataQuery(newVirtualPreset.dataQueryId);
    const defaultState = columnApi.getColumnState();

    if (!presetFilters) {
      return;
    }

    removeListeners();
    setPresetChanged(false);

    const newFilters = {
      ...presetFilters.filters,
      [SMART_SEARCH_KEY]: presetFilters.smartSearch,
    };

    gridApi?.setFilterModel(newFilters);

    columnApi.applyColumnState({
      state: getColumnState(lastVirtualPresetState.current, presetFilters, defaultState),
      // Whether column order should be applied
      applyOrder: true,
      // State to apply to columns where state is missing for those columns
      defaultState: {
        sort: null,
        pinned: null,
      },
    });

    httpCalls.activatePreset(null).then(() => {
      onActivePresetChange(lastVirtualPresetState.current);
      setActivePreset(lastVirtualPresetState.current);
      setPresetChanged(false);
      dataQueryIdContext.changeDataQueryId?.(presetFilters?.id);
      setSettings(newVirtualPreset.gridSettings);
      addListeners();
    });
  };

  const deletePreset = (presetId: string) => {
    const shouldDeactivatePreset = isPresetCustom(activePreset) && activePreset.id === presetId;
    return httpCalls.deletePreset(presetId).then(() => {
      httpCalls.getPresets().then((response) => {
        if (shouldDeactivatePreset) {
          deactivatePreset();
        }

        if (response) {
          setCustomPresets(response);
        }
      });
    });
  };

  const duplicatePreset = async (presetId: string) => {
    const presetDetail = await httpCalls.getPreset(presetId);

    if (!presetDetail) {
      return;
    }

    const preset: PresetDetail = {
      ...presetDetail,
      id: nanoid(),
      title: `${presetDetail.title} (copy)`,
    };

    return httpCalls.createPreset(preset).then(() => {
      httpCalls.getPresets().then((response) => response && setCustomPresets(response));
    });
  };

  const createPreset = (title: string, description: string) => {
    const columns = getPresetStates(columnApi);

    const preset: PostPresetRequest = {
      id: nanoid(),
      title,
      description,
      dataQueryId: searchParams.queryId,
      gridSettings: dataGridSettings,
      columnsSettings: columns,
      isActive: true,
    };

    return httpCalls.createPreset(preset).then(() => {
      httpCalls.getPresets().then((response) => {
        if (!response) {
          return;
        }

        setCustomPresets(response);
        const createdPreset = response.find((res) => res.title === title);

        if (isNil(createdPreset)) {
          showNotification.error('Preset was not found. Unable to activate');
          captureMessage('createPreset', (scope) =>
            scope.setLevel('error').setExtras({
              userDescription: 'create custom preset from usePresets - createPreset',
              state: createdPreset,
            })
          );
          return;
        }

        activatePreset(createdPreset!);
      });
    });
  };

  const renamePreset = (presetId: string, title: string, description: string) => {
    const renamedPreset: PutPresetRenameRequest = {
      title,
      description,
    };

    return httpCalls.updateRenamePreset(presetId, renamedPreset).then(() => {
      httpCalls.getPresets().then((response) => response && setCustomPresets(response));
    });
  };

  const updatePresetOrder = (fromIdx: number, toIdx: number) => {
    const newCustomPresetsOrder = pipe<[Preset[]], string[], string[]>(
      map((preset) => preset.id),
      move(fromIdx)(toIdx)
    )(customPresets);

    return httpCalls.presetOrder(newCustomPresetsOrder).then(() => {
      httpCalls.getPresets().then((response) => response && setCustomPresets(response));
    });
  };

  const resetOverrides = () => {
    setPresetChanged(false);
    if (isNil(activePresetDataQuery)) {
      return;
    }

    const newFilters = {
      ...activePresetDataQuery.filters,
      [SMART_SEARCH_KEY]: activePresetDataQuery.smartSearch,
    };
    columnApi.applyColumnState({
      state: getColumnState(activePreset, activePresetDataQuery, columnApi.getColumnState()),
      // Whether column order should be applied
      applyOrder: true,
      // State to apply to columns where state is missing for those columns
      defaultState: {
        sort: null,
        pinned: null,
      },
    });
    setSettings(activePreset.gridSettings);
    gridApi?.setFilterModel(newFilters);
  };

  const rowCountGetter = (dataQueryId: string) =>
    httpCalls
      .getCountByQuery(dataQueryId, !!gridApi.getFilterModel()[SMART_SEARCH_KEY])
      .then((response) => response ?? 0);

  return {
    presets: customPresets,
    activePreset,
    activatePreset,
    presetChanged,
    deactivatePreset,
    resetOverrides,
    updatePreset,
    deletePreset,
    duplicatePreset,
    createPreset,
    renamePreset,
    updatePresetPosition: updatePresetOrder,
    rowCountGetter,
  };
};

function isColumnResizeEvent(e: AgGridEvent): e is ColumnResizedEvent {
  return 'finished' in e && e.type === 'columnResized';
}
