import {FC, useCallback, PropsWithChildren} from 'react';

import {VehicleTypeEnumObject} from '@omnetic-dms/shared';
import {Form, VehicleSeriesDefinition} from '@omnetic-dms/teas';

import {useVehicleSeriesDefinitionContext} from '../hooks/useVehicleSeriesDefinitionContext';
import {VehicleSeriesDefinitionFormWrapperProps, VehicleSeriesDefinitionFormType} from '../types';
import {VehicleSeriesDefinitionFormFields} from './FormFields';
import {
  $VehicleSeriesDefinitionFormValidations,
  $VehicleSeriesSystemDefinitionFormValidations,
} from './validations';

export const VehicleSeriesDefinitionFormWrapper: FC<
  PropsWithChildren<VehicleSeriesDefinitionFormWrapperProps>
> = ({FormWrapper, defaultValues, initialValues, onSubmit, children}) => {
  const {isSystem} = useVehicleSeriesDefinitionContext();
  const getFormValues = useCallback(
    (values: VehicleSeriesDefinition): VehicleSeriesDefinitionFormType => {
      const {id, isSystem, ..._values} = values;

      return {
        ..._values,
        vehicleType: _values.vehicleType as VehicleTypeEnumObject,
        makes: _values.makes ?? [],
      };
    },
    []
  );

  const getRequestBody = useCallback(
    (values: VehicleSeriesDefinitionFormType): VehicleSeriesDefinitionFormType => {
      let makes = null;

      if (Array.isArray(values.makes)) {
        makes = values.makes.length === 0 ? null : values.makes;
      }

      return {...values, makes};
    },
    []
  );

  return (
    <Form<VehicleSeriesDefinitionFormType, VehicleSeriesDefinition>
      onSubmit={onSubmit}
      isFormSaverDisabled
      schema={
        isSystem
          ? $VehicleSeriesSystemDefinitionFormValidations()
          : $VehicleSeriesDefinitionFormValidations()
      }
      getFormValues={getFormValues}
      getRequestBody={getRequestBody}
      defaultValues={defaultValues}
      initialValues={initialValues}
      mutators={{
        resetMakes: (_, state, {changeValue}) => {
          changeValue(state, 'makes', () => null);
        },
      }}
      render={() => (
        <>
          {FormWrapper ? (
            <FormWrapper>
              <VehicleSeriesDefinitionFormFields />
            </FormWrapper>
          ) : (
            <VehicleSeriesDefinitionFormFields />
          )}

          {children}
        </>
      )}
    />
  );
};
