import {Action, FlagProps, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  handleApiError,
  NavigationItem,
  useDeleteSupplierOrderMutation,
  useGetSupplierOrderQuery,
  usePatchSupplierOrderStateMutation,
} from '@omnetic-dms/shared';

import {buildArray, composePath, generateHashFromObjects, useNavigate} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {Overview} from './(sections)/Overview/Overview';

export function SupplierOrderDetail() {
  const {supplierOrderId} = useWarehouseParams();

  const navigate = useNavigate();

  const {
    data: supplierOrder,
    isLoading: isSupplierOrderLoading,
    isError: hasSupplierOrderError,
  } = useGetSupplierOrderQuery({orderId: supplierOrderId}, {skip: isNilOrEmpty(supplierOrderId)});

  const [patchSupplierOrderState, {isLoading: isPatchSupplierOrderStateLoading}] =
    usePatchSupplierOrderStateMutation();

  const [deleteSupplierOrder] = useDeleteSupplierOrderMutation();

  const handleNavigateBack = () => {
    navigate(composePath(warehouseRoutes.supplierOrderList));
  };

  const handleCancelSupplierOrder = () => {
    if (isNil(supplierOrder?.order?.orderId)) {
      return;
    }

    deleteSupplierOrder({body: {orderId: [supplierOrder?.order?.orderId]}})
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.warehouse.notifications.supplierOrderCancelled'))
      )
      .then(handleNavigateBack);
  };

  const handleLockSupplierOrder = () => {
    if (isNil(supplierOrder?.order?.orderId)) {
      return;
    }

    patchSupplierOrderState({orderId: supplierOrder?.order?.orderId, body: {orderState: 'CLOSED'}})
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.warehouse.notifications.supplierOrderLocked'))
      )
      .catch(handleApiError);
  };

  const handleUnlockSupplierOrder = () => {
    if (isNil(supplierOrder?.order?.orderId)) {
      return;
    }

    patchSupplierOrderState({orderId: supplierOrder?.order?.orderId, body: {orderState: 'PENDING'}})
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.warehouse.notifications.supplierOrderUnlocked'))
      )
      .catch(handleApiError);
  };

  const handleSendToSupplier = () => {
    if (isNil(supplierOrder?.order?.orderId)) {
      return;
    }

    patchSupplierOrderState({orderId: supplierOrder?.order?.orderId, body: {orderState: 'SENT'}})
      .unwrap()
      .then(() =>
        showNotification.success(
          i18n.t('entity.warehouse.notifications.supplierOrderSentToSupplier')
        )
      )
      .catch(handleApiError);
  };

  const isPending = supplierOrder?.order?.state === 'PENDING';
  const isSent = supplierOrder?.order?.state === 'SENT';
  const isClosed = supplierOrder?.order?.state === 'CLOSED';

  const headerFlags = buildArray<FlagProps>()
    .when(isPending, {
      colorScheme: 'blue',
      label: i18n.t('general.labels.pending'),
    })
    .when(isSent, {
      colorScheme: 'yellow',
      label: i18n.t('general.labels.sent'),
    })
    .when(isClosed, {
      colorScheme: 'green',
      label: i18n.t('entity.warehouse.labels.closedState'),
    });

  const headerActions = match(supplierOrder?.order?.state)
    .returnType<Action[]>()
    .with(
      'PENDING',
      always([
        {
          type: 'button',
          variant: 'dangerOutlined',
          title: i18n.t('page.warehouse.actions.cancelSupplierOrder'),
          onClick: handleCancelSupplierOrder,
          isLoading: isPatchSupplierOrderStateLoading,
          'data-testid': testIds.warehouse.supplierOrderDetailOverview(
            'headerActions.cancelSupplierOrder'
          ),
        },
        {
          type: 'button',
          variant: 'secondary',
          title: i18n.t('page.warehouse.actions.lockSupplierOrder'),
          onClick: handleLockSupplierOrder,
          isLoading: isPatchSupplierOrderStateLoading,
          'data-testid': testIds.warehouse.supplierOrderDetailOverview(
            'headerActions.lockSupplierOrder'
          ),
        },
        {
          type: 'button',
          variant: 'primary',
          title: i18n.t('page.warehouse.actions.sendToSupplier'),
          onClick: handleSendToSupplier,
          isLoading: isPatchSupplierOrderStateLoading,
          'data-testid': testIds.warehouse.supplierOrderDetailOverview(
            'headerActions.sendToSupplier'
          ),
        },
      ])
    )
    .with(
      'CLOSED',
      always([
        {
          type: 'button',
          variant: 'secondary',
          title: i18n.t('page.warehouse.actions.unlock'),
          onClick: handleUnlockSupplierOrder,
          isLoading: isPatchSupplierOrderStateLoading,
          'data-testid': testIds.warehouse.supplierOrderDetailOverview('headerActions.unlock'),
        },
        {
          type: 'button',
          variant: 'primary',
          title: i18n.t('page.warehouse.actions.sendToSupplier'),
          onClick: handleSendToSupplier,
          isLoading: isPatchSupplierOrderStateLoading,
          'data-testid': testIds.warehouse.supplierOrderDetailOverview(
            'headerActions.sendToSupplier'
          ),
        },
      ])
    )
    .otherwise(always([]));

  const header: DetailTemplateHeader = {
    title: `${i18n.t('entity.warehouse.labels.supplierOrder')} ${supplierOrder?.order?.orderNumber ?? ''}`,
    controls: ['ASSIGNEE'],
    flags: headerFlags,
    actions: headerActions,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'supplierOrderDetail',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.supplierOrderDetailOverview, {
        params: {id: supplierOrderId},
      }),
      content: (
        <Overview
          data-testid={testIds.warehouse.supplierOrderDetailOverview('section.overview')}
          supplierOrder={supplierOrder}
          isPending={isPending}
          isOrderLoading={isSupplierOrderLoading}
          hasOrderError={hasSupplierOrderError}
        />
      ),
      'data-testid': testIds.warehouse.supplierOrderDetailOverview('navigation.supplierOrder'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.supplierOrder')} />
      <DetailTemplate
        key={generateHashFromObjects(supplierOrder)}
        isLoading={isSupplierOrderLoading}
        isError={hasSupplierOrderError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.supplierOrderDetailOverview('page')}
      />
    </>
  );
}
