import {match} from 'ts-pattern';

import {always} from 'ramda';

import {InvoicingDocumentType} from '@omnetic-dms/shared';

export const getInvoicingDocumentType = (type: string | null) =>
  match<string | null, InvoicingDocumentType>(type)
    .with('BALANCE_INVOICE', always('balance_invoice'))
    .with('PROFORMA', always('invoice_proforma'))
    .with('TAX_DOCUMENT_FOR_PAYMENT', always('tax_document_for_payment'))
    .otherwise(always('invoice'));
