import {DataStatus} from 'platform/components';

import {forwardRef, useImperativeHandle, useState} from 'react';

import {isNotNil} from 'ramda';

import {
  ActiveFolder,
  DataGridTreeFolderHandle,
  FolderTree,
  useGetLabourCatalogGridFilterCatalogCategoryQuery,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

const FOLDER_CONTEXT_TARGET = 'labour-catalog';

interface LabourFolderTreeProps extends TestIdProps {}

export const LabourFolderTree = forwardRef<DataGridTreeFolderHandle, LabourFolderTreeProps>(
  (props, ref) => {
    const [activeFolder, setActiveFolder] = useState<ActiveFolder | undefined>();
    const [includeSubfolders, setIncludeSubfolders] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({activeFolderId: activeFolder?.id, includeSubfolders}), [
      activeFolder?.id,
      includeSubfolders,
    ]);

    const {
      data: labourCatalogList,
      isLoading: isLabourCatalogListLoading,
      isError: isLabourCatalogListError,
    } = useGetLabourCatalogGridFilterCatalogCategoryQuery();

    const catalogueIds = labourCatalogList?.map((catalogue) => catalogue.value) ?? [];

    return (
      <DataStatus isLoading={isLabourCatalogListLoading} isError={isLabourCatalogListError}>
        <FolderTree
          contextIds={catalogueIds}
          contextTarget={FOLDER_CONTEXT_TARGET}
          onFolderChange={(id, contextId) =>
            setActiveFolder(isNotNil(id) && isNotNil(contextId) ? {id, contextId} : undefined)
          }
          activeFolderId={activeFolder?.id}
          shouldIncludeSubfolders={includeSubfolders}
          onIncludeSubfoldersChange={setIncludeSubfolders}
          data-testid={suffixTestId('catalogueTree', props)}
        />
      </DataStatus>
    );
  }
);
