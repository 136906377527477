import {OptionType} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {PaymentImportMode} from '@omnetic-dms/shared';

export const importTypeOptions: OptionType<PaymentImportMode>[] = [
  {value: 'importTrxOnly', label: i18n.t('page.accountingSettings.labels.importTrxOnly')},
  {
    value: 'importTrxDmsCreateTaxDocument',
    label: i18n.t('page.accountingSettings.labels.importTrxDmsCreateTaxDocument'),
  },
  {
    value: 'importTaxDocumentOnly',
    label: i18n.t('page.accountingSettings.labels.importTaxDocumentOnly'),
  },
];
