import {CSSDimension, Integer, Box, VStack, Spinner, SpinnerSize} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {EmptyStatus, EmptyStatusAction} from '../EmptyStatus/EmptyStatus';
import {ErrorStatus} from '../ErrorStatus/ErrorStatus';

interface DataStatusProps extends TestIdProps {
  spacing?: Integer;
  minHeight?: CSSDimension | Integer;
  grow?: number;
  isLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  errorMessage?: string;
  emptyMessage?: string;
  emptySubheadline?: string;
  spinnerSize?: SpinnerSize;
  action?: EmptyStatusAction;
  children?: ReactNode;
  isCompact?: boolean;
}

export function DataStatus(props: DataStatusProps) {
  if (!props.isError && !props.isEmpty && !props.isLoading) {
    return <>{props.children}</>;
  }

  return (
    <VStack
      minHeight={props.minHeight}
      justify="center"
      align="center"
      grow={props.grow}
      data-testid={suffixTestId('dataStatus', props)}
    >
      <Box padding={props.spacing}>
        <DataStatusContent {...props} />
      </Box>
    </VStack>
  );
}

function DataStatusContent(props: DataStatusProps) {
  if (props.isError) {
    return <ErrorStatus headline={props.errorMessage} data-testid={props['data-testid']} />;
  }

  if (props.isLoading) {
    return (
      <Spinner size={props.spinnerSize} data-testid={suffixTestId('dataStatus-spinner', props)} />
    );
  }

  if (props.isEmpty) {
    return (
      <EmptyStatus
        headline={props.emptyMessage}
        subheadline={props.emptySubheadline}
        action={props.action}
        isCompact={props.isCompact}
        data-testid={props['data-testid']}
      />
    );
  }
  return null;
}
