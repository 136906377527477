import {Action} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {customerRoutes, testIds} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {composePath, noop, useNavigate} from 'shared';

export function CustomerOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, sourceSearchParams}) => {
    if (isArray(rowId)) {
      return;
    }
    const path = composePath(customerRoutes.detail, {
      params: {id: rowId},
      isSearchParamsPreserved: false,
      queryParams: sourceSearchParams,
    });

    match(actionKey)
      .with('detail', () => navigate(path))
      .with('detail_newTab', () => window.open(path))
      .otherwise(noop);
  };

  const actions: Action[] = [
    {
      type: 'button',
      onClick: () => navigate(customerRoutes.create),
      variant: 'primary',
      'data-testid': testIds.customer.overview('create'),
      title: i18n.t('entity.customer.actions.newCustomer'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.customers.title')} />
      <Main isFullHeight actions={actions} data-testid={testIds.customer.overview('container')}>
        <DataGrid
          data-testid={testIds.customer.overview('datagrid')}
          gridCode="customer"
          actionCallback={actionCallback}
        />
      </Main>
    </>
  );
}
