import {FormControl, FormField, Tooltip} from 'platform/components';
import {Grid, Show, Text, VStack} from 'platform/foundation';

import {useWatch} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {BaseServiceOrderVariant} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {getOptionsFromServiceOrderVariants} from '../../../utils/getOptionsFromServiceOrderVariants';
import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';

interface AllowServiceOrderIssueProps extends TestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  serviceOrderVariants: BaseServiceOrderVariant[] | Nullish;
}

export function AllowServiceOrderIssue(props: AllowServiceOrderIssueProps) {
  const allowServiceOrderIssue = useWatch({name: 'allowServiceOrderIssue'});

  return (
    <>
      <FormField
        name="allowServiceOrderIssue"
        type="switch"
        label={i18n.t('entity.warehouse.labels.allowServiceOrderIssue')}
        control={props.control}
        data-testid={props['data-testid']}
      />
      <Show when={allowServiceOrderIssue}>
        <VStack spacing={2}>
          <Grid columns={2}>
            <FormField
              name="allowedOrderVariants"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.allowedServiceOrderVariants')}
              options={getOptionsFromServiceOrderVariants(props.serviceOrderVariants)}
              control={props.control}
              isNotClearable
              data-testid={suffixTestId('allowedOrderVariants', props)}
            />
          </Grid>
          <Tooltip>
            <Text size="xSmall" color="tertiary" noWrap>
              {i18n.t('entity.warehouse.labels.allowedServiceOrderVariantsText')}
            </Text>
          </Tooltip>
        </VStack>
      </Show>
    </>
  );
}
