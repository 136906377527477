import {z} from 'zod';

import {BaseVoidRequestSchema, BaseVoidResponseSchema} from './base';

const ServiceOrderDocumentSchema = z.object({
  templateId: z.string().nullable().optional(),
  docSeriesId: z.string().nullable().optional(),
});

const PaymentTypeSchema = z.object({
  isAllowed: z.boolean(),
  invoice: ServiceOrderDocumentSchema.nullable().optional(),
  invoiceCorrection: ServiceOrderDocumentSchema.nullable().optional(),
  invoiceEu: ServiceOrderDocumentSchema.nullable().optional(),
  invoiceEuCorrection: ServiceOrderDocumentSchema.nullable().optional(),
  invoiceOutsideEu: ServiceOrderDocumentSchema.nullable().optional(),
  invoiceOutsideEuCorrection: ServiceOrderDocumentSchema.nullable().optional(),
});

const DocumentsSchema = z.object({
  invoice: ServiceOrderDocumentSchema.nullable().optional(),
  orderSheet: ServiceOrderDocumentSchema.nullable().optional(),
  orderOffer: ServiceOrderDocumentSchema.nullable().optional(),
  orderClaim: ServiceOrderDocumentSchema.nullable().optional(),
});

const PaymentTypeOptionsSchema = z.object({
  defaultPaymentType: z.enum(['BANK_TRANSFER', 'CARD', 'CASH', 'CASH_ON_DELIVERY']).nullable(),
  cashPayment: PaymentTypeSchema.nullable(),
  cardPayment: PaymentTypeSchema.nullable(),
  bankPayment: PaymentTypeSchema.nullable(),
  internalPayment: PaymentTypeSchema.nullable(),
});

export const PostServiceOrderVariantApiResponseSchema = z
  .object({
    id: z.string().nullable(),
  })
  .nullable();

export type PostServiceOrderVariantApiResponse = z.infer<
  typeof PostServiceOrderVariantApiResponseSchema
>;

export const PostServiceOrderVariantApiArgSchema = z.object({
  body: z
    .object({
      authorizationProfileId: z.string(),
      serviceOrderTypeId: z.string(),
      name: z.string(),
      note: z.string().nullable().optional(),
      priceType: z.string(),
      priceTypeRatio: z.number().nullable().optional(),
      rateType: z.string(),
      rateTypeRatio: z.number().nullable().optional(),
      isUnitPriceWithVat: z.boolean(),
      defaultCustomerId: z.string().nullable().optional(),
      isChangeableDefaultCustomer: z.boolean().nullable().optional(),
      suffix: z.string(),
      minimalMaterialMargin: z.number().nullable().optional(),
      minimalWorkMargin: z.number().nullable().optional(),
      isAutomaticSendToChronos: z.boolean().nullable().optional(),
      isAutomaticSendToPlanner: z.boolean().nullable().optional(),
    })
    .nullable(),
});

export type PostServiceOrderVariantApiArg = z.infer<typeof PostServiceOrderVariantApiArgSchema>;

export const GetServiceOrderVariantApiResponseSchema = z
  .object({
    id: z.string().nullable(),
    authorizationProfileId: z.string().nullable(),
    serviceCaseTypeId: z.string().nullable(),
    serviceOrderTypeId: z.string().nullable(),
    name: z.string().nullable(),
    note: z.string().nullable(),
    priceType: z.string().nullable(),
    priceTypeRatio: z.number().nullable(),
    rateType: z.string().nullable(),
    rateTypeRatio: z.number().nullable(),
    isUnitPriceWithVat: z.boolean().nullable(),
    defaultCustomerId: z.string().nullable(),
    isChangeableDefaultCustomer: z.boolean().nullable(),
    suffix: z.string().nullable(),
    minimalMaterialMargin: z.number().nullable(),
    minimalWorkMargin: z.number().nullable(),
    isAutomaticSendToChronos: z.boolean().nullable(),
    isAutomaticSendToPlanner: z.boolean().nullable(),
  })
  .nullable();

export type GetServiceOrderVariantApiResponse = z.infer<
  typeof GetServiceOrderVariantApiResponseSchema
>;

export const GetServiceOrderVariantApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
});

export type GetServiceOrderVariantApiArg = z.infer<typeof GetServiceOrderVariantApiArgSchema>;

export const PostServiceOrderVariantSetAsActiveApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceOrderVariantSetAsActiveApiResponse = z.infer<
  typeof PostServiceOrderVariantSetAsActiveApiResponseSchema
>;

export const PostServiceOrderVariantSetAsActiveApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
});

export type PostServiceOrderVariantSetAsActiveApiArg = z.infer<
  typeof PostServiceOrderVariantSetAsActiveApiArgSchema
>;

export const PostServiceOrderVariantSetAsInactiveResponseSchema = BaseVoidResponseSchema;

export type PostServiceOrderVariantSetAsInactiveResponse = z.infer<
  typeof PostServiceOrderVariantSetAsInactiveResponseSchema
>;

export const PostServiceOrderVariantSetAsInactiveApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
});

export type PostServiceOrderVariantSetAsInactiveApiArg = z.infer<
  typeof PostServiceOrderVariantSetAsInactiveApiArgSchema
>;

export const PatchServiceOrderVariantApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceOrderVariantApiResponse = z.infer<
  typeof PatchServiceOrderVariantApiResponseSchema
>;

export const DeleteServiceOrderVariantApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
});

export type DeleteServiceOrderVariantApiArg = z.infer<typeof DeleteServiceOrderVariantApiArgSchema>;

export const DeleteServiceOrderVariantResponseSchema = BaseVoidResponseSchema;

export type DeleteServiceOrderVariantResponse = z.infer<
  typeof DeleteServiceOrderVariantResponseSchema
>;

export const PatchServiceOrderVariantApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
  body: z
    .object({
      authorizationProfileId: z.string(),
      serviceOrderTypeId: z.string(),
      name: z.string(),
      note: z.string().nullable().optional(),
      priceType: z.string(),
      priceTypeRatio: z.number().nullable().optional(),
      rateType: z.string(),
      rateTypeRatio: z.number().nullable().optional(),
      isUnitPriceWithVat: z.boolean(),
      defaultCustomerId: z.string().nullable().optional(),
      isChangeableDefaultCustomer: z.boolean().nullable().optional(),
      suffix: z.string(),
      minimalMaterialMargin: z.number().nullable().optional(),
      minimalWorkMargin: z.number().nullable().optional(),
      isAutomaticSendToChronos: z.boolean().nullable().optional(),
      isAutomaticSendToPlanner: z.boolean().nullable().optional(),
    })
    .nullable(),
});

export type PatchServiceOrderVariantApiArg = z.infer<typeof PatchServiceOrderVariantApiArgSchema>;

export const GetServiceOrderVariantDocumentsApiResponseSchema = z
  .object({
    documents: DocumentsSchema.nullable().optional(),
    paymentType: PaymentTypeOptionsSchema.nullable().optional(),
  })
  .nullable();

export type GetServiceOrderVariantDocumentsApiResponse = z.infer<
  typeof GetServiceOrderVariantDocumentsApiResponseSchema
>;

export const GetServiceOrderVariantDocumentsApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
});

export type GetServiceOrderVariantDocumentsApiArg = z.infer<
  typeof GetServiceOrderVariantDocumentsApiArgSchema
>;

export const PatchServiceOrderVariantDocumentsApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceOrderVariantDocumentsApiResponse = z.infer<
  typeof PatchServiceOrderVariantDocumentsApiResponseSchema
>;

export const PatchServiceOrderVariantDocumentsApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
  body: z
    .object({
      documents: DocumentsSchema.nullable().optional(),
      paymentType: PaymentTypeOptionsSchema.nullable().optional(),
    })
    .nullable(),
});
export type PatchServiceOrderVariantDocumentsApiArg = z.infer<
  typeof PatchServiceOrderVariantDocumentsApiArgSchema
>;

export const GetServiceOrderVariantMandatoryFieldsApiResponseSchema = z
  .array(
    z.object({
      serviceOrderTypeId: z.string().nullable().optional(),
      field: z.string().nullable().optional(),
      group: z.number().nullable().optional(),
      position: z.number().nullable().optional(),
      isSelected: z.boolean().nullable().optional(),
      mandatoryCondition: z
        .array(
          z.object({
            event: z.string(),
            isSelected: z.boolean(),
          })
        )
        .nullable()
        .optional(),
    })
  )
  .nullable()
  .optional();

export type GetServiceOrderVariantMandatoryFieldsApiResponse = z.infer<
  typeof GetServiceOrderVariantMandatoryFieldsApiResponseSchema
>;

export const GetServiceOrderVariantMandatoryFieldsApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
});

export type GetServiceOrderVariantMandatoryFieldsApiArg = z.infer<
  typeof GetServiceOrderVariantMandatoryFieldsApiArgSchema
>;

export const PatchServiceOrderVariantMandatoryFieldsApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceOrderVariantMandatoryFieldsApiResponse = z.infer<
  typeof PatchServiceOrderVariantMandatoryFieldsApiResponseSchema
>;

export const PatchServiceOrderVariantMandatoryFieldsApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
  body: z.array(
    z.object({
      field: z.string(),
      isSelected: z.boolean(),
      mandatoryCondition: z
        .array(
          z.object({
            event: z.string(),
            isSelected: z.boolean(),
          })
        )
        .nullable()
        .optional(),
    })
  ),
});

export type PatchServiceOrderVariantMandatoryFieldsApiArg = z.infer<
  typeof PatchServiceOrderVariantMandatoryFieldsApiArgSchema
>;

export const GetSettingsServiceOrderVariantsApiResponseSchema = z
  .object({
    serviceOrderVariants: z
      .object({
        serviceOrderVariant: z
          .array(
            z.object({
              id: z.string().nullable(),
              authorizationProfileId: z.string().nullable(),
              serviceCaseTypeId: z.string().nullable(),
              serviceOrderTypeId: z.string().nullable(),
              name: z.string().nullable(),
              note: z.string().nullable(),
              priceType: z.string().nullable(),
              priceTypeRatio: z.number().nullable(),
              rateType: z.string().nullable(),
              rateTypeRatio: z.number().nullable(),
              isUnitPriceWithVat: z.boolean().nullable(),
              defaultCustomerId: z.string().nullable(),
              isChangeableDefaultCustomer: z.boolean().nullable(),
              suffix: z.string().nullable(),
              minimalMaterialMargin: z.number().nullable(),
              minimalWorkMargin: z.number().nullable(),
              branchId: z.string().nullable(),
            })
          )
          .nullable(),
      })
      .nullable(),
  })
  .nullable();

export type GetSettingsServiceOrderVariantsApiResponse = z.infer<
  typeof GetSettingsServiceOrderVariantsApiResponseSchema
>;

export const GetSettingsServiceOrderVariantsApiArgSchema = BaseVoidRequestSchema;

export type GetSettingsServiceOrderVariantsApiArg = z.infer<
  typeof GetSettingsServiceOrderVariantsApiArgSchema
>;

export const GetServiceOrderVariantPaymentTypeApiResponseSchema =
  PaymentTypeOptionsSchema.nullable();

export type GetServiceOrderVariantPaymentTypeApiApiResponse = z.infer<
  typeof GetServiceOrderVariantPaymentTypeApiResponseSchema
>;

export const GetServiceOrderVariantPaymentTypeApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
});

export type GetServiceOrderVariantPaymentTypeApiApiArg = z.infer<
  typeof GetServiceOrderVariantPaymentTypeApiArgSchema
>;

export const PatchServiceOrderVariantPaymentTypeApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceOrderVariantPaymentTypeApiApiResponse = z.infer<
  typeof PatchServiceOrderVariantPaymentTypeApiResponseSchema
>;

export const PatchServiceOrderVariantPaymentTypeApiArgSchema = z.object({
  serviceOrderVariantId: z.string(),
  body: z
    .object({
      defaultPaymentType: z.enum(['BANK_TRANSFER', 'CARD', 'CASH', 'CASH_ON_DELIVERY']).nullable(),
      cardPayment: PaymentTypeSchema.nullable(),
      cashPayment: PaymentTypeSchema.nullable(),
      bankPayment: PaymentTypeSchema.nullable(),
      internalPayment: PaymentTypeSchema.nullable(),
    })
    .nullable(),
});

export type PatchServiceOrderVariantPaymentTypeApiApiArg = z.infer<
  typeof PatchServiceOrderVariantPaymentTypeApiArgSchema
>;
