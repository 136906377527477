import {
  Button,
  ButtonGroup,
  Dialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {CreateRoleRequestBody, handleApiError, useCreateRoleMutation} from '@omnetic-dms/shared';

import {composePath} from 'shared';

import {ERoleType} from '../../UserManagementDetail/components/SetPasswordModal/types';

interface NewRoleDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

type FormValues = Pick<CreateRoleRequestBody, 'title'> & {
  roleType: string;
  baseRole: string;
};

export function NewRoleDialog({...dialogProps}: NewRoleDialogProps) {
  const navigate = useNavigate();
  const [createRole] = useCreateRoleMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const {roleType, baseRole, title} = data;

    const createRoleRequestBody = roleType === ERoleType.blank ? {title} : {title, baseRole};

    await createRole({createRoleRequestBody})
      .unwrap()
      .then((res) =>
        navigate(
          composePath(settingsRoutes.roleManagementDetail, {
            params: {roleId: res.id},
            queryParams: {mode: 'create'},
          })
        )
      )
      .catch(handleApiError);
  };

  return (
    <Dialog
      {...dialogProps}
      size="small"
      title={i18n.t('page.settings.actions.newCustomRole')}
      data-testid={testIds.settings.userManagement('newRoleModal')}
      scrollBehavior="outside"
    >
      <Form<FormValues>
        schema={createRoleSchema}
        onSubmit={handleSubmit}
        mode="onSubmit"
        defaultValues={{roleType: 'blank'}}
      >
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="title"
              type="text"
              data-testid={testIds.settings.userManagement('newRoleModalTextField')}
            />

            <ButtonGroup align="right">
              <Button
                onClick={dialogProps.onClose}
                variant="secondary"
                title={i18n.t('general.actions.cancel')}
                data-testid={testIds.settings.userManagement('newRoleDiscard')}
              />
              <FormButton
                control={control}
                title={i18n.t('general.actions.save')}
                type="submit"
                variant="primary"
                data-testid={testIds.settings.userManagement('newRoleCreate')}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </Dialog>
  );
}

const createRoleSchema = Yup.object().shape({
  title: Yup.string().required(),
});
