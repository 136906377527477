import {DataStatus, Form, FormSubmitHandler} from 'platform/components';
import {boolean, number, object, string} from 'yup';

import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {isNil, mergeAll} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  documentContextApi,
  handleApiError,
  queryParams,
  useGetServiceOrderTypesQuery,
  useGetServiceOrderVariantDocumentsQuery,
  useGetServiceOrderVariantMandatoryFieldsQuery,
  useGetServiceOrderVariantPaymentTypeQuery,
  useGetServiceOrderVariantQuery,
  usePatchServiceOrderVariantDocumentsMutation,
  usePatchServiceOrderVariantMandatoryFieldsMutation,
  usePatchServiceOrderVariantMutation,
  usePatchServiceOrderVariantPaymentTypeMutation,
  usePostServiceOrderVariantMutation,
} from '@omnetic-dms/shared';

import {useQueryState, yupString} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentsTab} from './(sections)/DocumentsTab/DocumentsTab';
import {GeneralTab} from './(sections)/GeneralTab/GeneralTab';
import {MandatoryFieldsTab} from './(sections)/MandatoryFieldsTab/MandatoryFieldsTab';
import {PaymentTab} from './(sections)/PaymentTab/PaymentTab';
import {mandatoryConditions} from './conditions/mandatoryConditions';
import {FormType, MandatoryFieldType} from './types/ServiceOrderVariant';
import {getFieldsByOrderType} from './utils/getFieldsByOrderType';

const GENERAL_TAB = 'general';
const DOCUMENTS_TAB = 'documents';
const PAYMENTS_TAB = 'payments';
const MANDATORY_FIELDS_TAB = 'mandatoryFields';

export function ServiceOrderVariantsDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, updateQuery] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const {
    data: orderVariant,
    isLoading: isOrderVariantLoading,
    isError: isOrderVariantError,
  } = useGetServiceOrderVariantQuery({serviceOrderVariantId: id ?? ''}, {skip: isNil(id)});
  const {
    data: orderVariantDocuments,
    isLoading: isOrderVariantDocumentsLoading,
    isError: isOrderVariantDocumentsError,
  } = useGetServiceOrderVariantDocumentsQuery({serviceOrderVariantId: id ?? ''}, {skip: isNil(id)});
  const {
    data: mandatoryFields,
    isLoading: isMandatoryFieldsLoading,
    isError: isMandatoryFieldsError,
  } = useGetServiceOrderVariantMandatoryFieldsQuery(
    {serviceOrderVariantId: id ?? ''},
    {skip: isNil(id)}
  );
  const {
    data: orderVariantPaymentType,
    isLoading: isOrderVariantPaymentTypeLoading,
    isError: isOrderVariantPaymentTypeError,
  } = useGetServiceOrderVariantPaymentTypeQuery(
    {serviceOrderVariantId: id ?? ''},
    {skip: isNil(id)}
  );

  const {data: orderTypes} = useGetServiceOrderTypesQuery();

  const [postOrderVariant] = usePostServiceOrderVariantMutation();
  const [patchOrderVariant] = usePatchServiceOrderVariantMutation();
  const [patchOrderVariantDocuments] = usePatchServiceOrderVariantDocumentsMutation();
  const [patchServiceOrderVariantMandatoryFields] =
    usePatchServiceOrderVariantMandatoryFieldsMutation();

  const [patchPaymentType] = usePatchServiceOrderVariantPaymentTypeMutation();

  const handleSubmit: FormSubmitHandler<FormType> = async (data) => {
    const body = {
      ...data.general,
      authorizationProfileId: data.general?.authorizationProfileId ?? '',
      serviceOrderTypeId: data.general?.serviceOrderTypeId ?? '',
      name: data.general?.name ?? '',
      priceType: data.general?.priceType ?? '',
      rateType: data.general?.rateType ?? '',
      suffix: data.general?.suffix ?? '',
      isUnitPriceWithVat: data.general?.isUnitPriceWithVat ?? false,
      isAutomaticSendToChronos: data.general?.isAutomaticSendToChronos ?? false,
      isAutomaticSendToPlanner: data.general?.isAutomaticSendToPlanner ?? false,
      isChangeableDefaultCustomer: data.general?.isChangeableDefaultCustomer ?? false,
    };

    const orderCode = orderTypes?.find((type) => type?.id === body.serviceOrderTypeId)?.code;

    const mandatoryFields = getFieldsByOrderType(orderCode).map((mandatoryField) => ({
      field: mandatoryField,
      isSelected: !!data.mandatoryFields?.[mandatoryField],
      mandatoryCondition:
        mandatoryField === 'generatingOrderSheet'
          ? undefined
          : mandatoryConditions.map((condition) => ({
              event: condition,
              isSelected:
                !!data.mandatoryFields?.[mandatoryField] &&
                !!data.mandatoryFieldConditions?.[mandatoryField]?.includes(condition),
            })),
    }));

    await (
      isNil(id) ? postOrderVariant({body}) : patchOrderVariant({serviceOrderVariantId: id, body})
    )
      .unwrap()
      .then((response) => {
        const documentRequest = patchOrderVariantDocuments({
          serviceOrderVariantId: response?.id ?? id ?? '',
          body: data.documents,
        })
          .unwrap()
          .catch(handleApiError);

        const patchServiceTypeRequest = patchPaymentType({
          body: data.paymentType,
          serviceOrderVariantId: response?.id ?? id ?? '',
        })
          .unwrap()
          .catch(handleApiError);

        const mandatoryFieldsRequest = patchServiceOrderVariantMandatoryFields({
          serviceOrderVariantId: response?.id ?? id ?? '',
          body: mandatoryFields,
        })
          .unwrap()
          .catch(handleApiError);

        Promise.all([documentRequest, patchServiceTypeRequest, mandatoryFieldsRequest])
          .then(() => navigate(settingsRoutes.serviceOrderVariants))
          .then(() => {
            dispatch(
              documentContextApi.util.invalidateTags([{type: 'documentsCount', id: id ?? ''}])
            );
          });
      })
      .catch(handleApiError);
  };

  const mandatoryFieldsArray =
    mandatoryFields?.map(
      (field) =>
        ({[field?.field ?? '']: !!field?.isSelected}) as Record<MandatoryFieldType, boolean>
    ) ?? ([] as Record<MandatoryFieldType, boolean>[]);

  const mandatoryFieldConditionsArray =
    mandatoryFields?.map((field) => {
      const selectedEvent = field?.mandatoryCondition?.find(
        (condition) => condition?.isSelected
      )?.event;

      return {
        [field?.field ?? '']: selectedEvent ? [selectedEvent] : [],
      } as Record<MandatoryFieldType, string[]>;
    }) ?? ([] as Record<MandatoryFieldType, string[]>[]);

  const mandatoryFieldsDefaultValues =
    mergeAll<Record<MandatoryFieldType, boolean>>(mandatoryFieldsArray);

  const mandatoryFieldConditionDefaultValues = mergeAll<Record<MandatoryFieldType, string[]>>(
    mandatoryFieldConditionsArray
  );

  return (
    <DataStatus
      isLoading={
        isOrderVariantLoading ||
        isOrderVariantDocumentsLoading ||
        isMandatoryFieldsLoading ||
        isOrderVariantPaymentTypeLoading
      }
      isError={
        isOrderVariantError ||
        isOrderVariantDocumentsError ||
        isMandatoryFieldsError ||
        isOrderVariantPaymentTypeError
      }
      minHeight={60}
    >
      <Form<FormType>
        defaultValues={{
          general: orderVariant,
          documents: orderVariantDocuments,
          paymentType: orderVariantPaymentType || {
            cashPayment: {
              isAllowed: false,
            },
            cardPayment: {
              isAllowed: false,
            },
            bankPayment: {
              isAllowed: false,
            },
            internalPayment: {
              isAllowed: false,
            },
          },
          mandatoryFields: mandatoryFieldsDefaultValues,
          mandatoryFieldConditions: mandatoryFieldConditionDefaultValues,
        }}
        onSubmit={handleSubmit}
        schema={FormSchema}
        onInvalidSubmit={(error) => {
          if (error.paymentType) {
            return updateQuery(PAYMENTS_TAB);
          }

          if (error.general) {
            return updateQuery(GENERAL_TAB);
          }
        }}
        isFullHeight
      >
        {(control, formApi) => {
          const selectedOrderTypeId = formApi.watch('general.serviceOrderTypeId');
          const selectedOrderType = orderTypes?.find((type) => type?.id === selectedOrderTypeId);

          return (
            <SettingsTemplate
              header={{
                title: `${
                  orderVariant?.name ?? i18n.t('entity.order.actions.newServiceOrderVariant')
                }${selectedOrderType ? ` (${selectedOrderType?.name})` : ''}`,
                breadcrumbs: [
                  {
                    label: i18n.t('entity.order.labels.serviceOrderVariants'),
                    href: settingsRoutes.serviceOrderVariants,
                  },
                ],
              }}
              tabs={[
                {
                  title: i18n.t('general.labels.general'),
                  queryId: GENERAL_TAB,
                  content: <GeneralTab control={control} formApi={formApi} />,
                },
                {
                  title: i18n.t('general.labels.documents'),
                  queryId: DOCUMENTS_TAB,
                  content: <DocumentsTab control={control} formApi={formApi} />,
                },
                {
                  title: i18n.t('entity.bank.labels.paymentType'),
                  queryId: PAYMENTS_TAB,
                  content: <PaymentTab control={control} formApi={formApi} />,
                },
                {
                  title: i18n.t('entity.order.labels.mandatoryFields'),
                  queryId: MANDATORY_FIELDS_TAB,
                  content: (
                    <MandatoryFieldsTab
                      control={control}
                      formApi={formApi}
                      selectedOrderTypeCode={selectedOrderType?.code}
                    />
                  ),
                  isDisabled: isNilOrEmpty(formApi.watch('general.serviceOrderTypeId')),
                },
              ]}
              isCreating={isNil(id)}
              data-testid={testIds.settings.serviceOrderVariantsDetail('template')}
            />
          );
        }}
      </Form>
    </DataStatus>
  );
}

const paymentSchema = object().shape({
  isAllowed: boolean().required(),
  invoice: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
      templateId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
      templateId: yupString,
    }),
  }),
  invoiceCorrection: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
    }),
  }),
  invoiceEu: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
    }),
  }),
  invoiceEuCorrection: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
    }),
  }),
  invoiceOutsideEu: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
    }),
  }),
  invoiceOutsideEuCorrection: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
    }),
  }),
});

const internalPaymentSchema = object().shape({
  isAllowed: boolean().required(),
  invoice: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
      templateId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
      templateId: yupString,
    }),
  }),
  invoiceCorrection: object().when('isAllowed', {
    is: true,
    then: object().shape({
      docSeriesId: yupString.required(),
    }),
    otherwise: object().shape({
      docSeriesId: yupString,
    }),
  }),
});

const FormSchema = object({
  general: object({
    authorizationProfileId: yupString.required(),
    serviceOrderTypeId: yupString.required(),
    name: yupString.required(),
    note: yupString,
    priceType: yupString.required(),
    priceTypeRatio: number().nullable(),
    rateType: yupString.required(),
    rateTypeRatio: number().nullable(),
    isUnitPriceWithVat: boolean().nullable(),
    defaultCustomerId: yupString,
    isChangeableDefaultCustomer: boolean().nullable(),
    suffix: yupString.required(),
    minimalMaterialMargin: number().nullable(),
    minimalWorkMargin: number().nullable(),
  }),
  paymentType: object({
    defaultPaymentType: string().nullable(),
    cashPayment: paymentSchema,
    cardPayment: paymentSchema,
    bankPayment: paymentSchema,
    internalPayment: internalPaymentSchema,
  }),
});
