import {BaseVoidResponseSchema} from '../types/api/base';
import {
  PostMaterialDiscountGroupApiResponse,
  PostMaterialDiscountGroupApiArg,
  GetMaterialDiscountGroupByIdApiResponse,
  GetMaterialDiscountGroupByIdApiArg,
  DeleteMaterialDiscountGroupApiResponse,
  DeleteMaterialDiscountGroupApiArg,
  PatchMaterialDiscountGroupApiResponse,
  PatchMaterialDiscountGroupApiArg,
  GetMaterialDiscountGroupItemByIdApiResponse,
  GetMaterialDiscountGroupItemByIdApiArg,
  DeleteMaterialDiscountGroupItemApiResponse,
  DeleteMaterialDiscountGroupItemApiArg,
  PatchMaterialDiscountGroupItemApiResponse,
  PatchMaterialDiscountGroupItemApiArg,
  PostMaterialDiscountGroupItemApiResponse,
  PostMaterialDiscountGroupItemApiArg,
  GetMaterialDiscountGroupsApiArg,
  GetMaterialDiscountGroupsApiResponse,
  GetMaterialDiscountGroupDiscountsApiArg,
  GetMaterialDiscountGroupDiscountsApiResponse,
  PostMaterialDiscountGroupSetActiveApiResponse,
  PostMaterialDiscountGroupSetActiveApiArg,
  PostMaterialDiscountGroupSetInActiveApiResponse,
  PostMaterialDiscountGroupSetInActiveApiArg,
  PostMaterialDiscountGroupApiResponseSchema,
  PostMaterialDiscountGroupApiArgSchema,
  GetMaterialDiscountGroupByIdApiResponseSchema,
  GetMaterialDiscountGroupByIdApiArgSchema,
  DeleteMaterialDiscountGroupApiArgSchema,
  PatchMaterialDiscountGroupApiArgSchema,
  GetMaterialDiscountGroupItemByIdApiResponseSchema,
  GetMaterialDiscountGroupItemByIdApiArgSchema,
  DeleteMaterialDiscountGroupItemApiArgSchema,
  PatchMaterialDiscountGroupItemApiArgSchema,
  PostMaterialDiscountGroupItemApiArgSchema,
  GetMaterialDiscountGroupsApiResponseSchema,
  GetMaterialDiscountGroupsApiArgSchema,
  GetMaterialDiscountGroupDiscountsApiResponseSchema,
  GetMaterialDiscountGroupDiscountsApiArgSchema,
  PostMaterialDiscountGroupSetActiveApiArgSchema,
  PostMaterialDiscountGroupSetInActiveApiArgSchema,
} from '../types/api/metadaWorkshopMaterialDiscountGroup';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopMaterialDiscountGroupApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postMaterialDiscountGroup: build.mutation<
      PostMaterialDiscountGroupApiResponse,
      PostMaterialDiscountGroupApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostMaterialDiscountGroupApiResponseSchema,
        requestSchema: PostMaterialDiscountGroupApiArgSchema,
      },
    }),
    getMaterialDiscountGroupById: build.query<
      GetMaterialDiscountGroupByIdApiResponse,
      GetMaterialDiscountGroupByIdApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.groupId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroup', id: queryArg.groupId},
      ],
      extraOptions: {
        responseSchema: GetMaterialDiscountGroupByIdApiResponseSchema,
        requestSchema: GetMaterialDiscountGroupByIdApiArgSchema,
      },
    }),
    deleteMaterialDiscountGroup: build.mutation<
      DeleteMaterialDiscountGroupApiResponse,
      DeleteMaterialDiscountGroupApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.groupId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroup', id: queryArg.groupId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: DeleteMaterialDiscountGroupApiArgSchema,
      },
    }),
    patchMaterialDiscountGroup: build.mutation<
      PatchMaterialDiscountGroupApiResponse,
      PatchMaterialDiscountGroupApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.groupId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroup', id: queryArg.groupId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PatchMaterialDiscountGroupApiArgSchema,
      },
    }),
    getMaterialDiscountGroupItemById: build.query<
      GetMaterialDiscountGroupItemByIdApiResponse,
      GetMaterialDiscountGroupItemByIdApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.groupId}/discount/${queryArg.discountId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroupItem', id: queryArg.discountId},
      ],
      extraOptions: {
        responseSchema: GetMaterialDiscountGroupItemByIdApiResponseSchema,
        requestSchema: GetMaterialDiscountGroupItemByIdApiArgSchema,
      },
    }),
    deleteMaterialDiscountGroupItem: build.mutation<
      DeleteMaterialDiscountGroupItemApiResponse,
      DeleteMaterialDiscountGroupItemApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.groupId}/discount/${queryArg.discountId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroupItem', id: queryArg.discountId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: DeleteMaterialDiscountGroupItemApiArgSchema,
      },
    }),
    patchMaterialDiscountGroupItem: build.mutation<
      PatchMaterialDiscountGroupItemApiResponse,
      PatchMaterialDiscountGroupItemApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.groupId}/discount/${queryArg.discountId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroupItem', id: queryArg.discountId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PatchMaterialDiscountGroupItemApiArgSchema,
      },
    }),
    postMaterialDiscountGroupItem: build.mutation<
      PostMaterialDiscountGroupItemApiResponse,
      PostMaterialDiscountGroupItemApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.materialDiscountGroupId}/discount`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostMaterialDiscountGroupApiResponseSchema,
        requestSchema: PostMaterialDiscountGroupItemApiArgSchema,
      },
    }),
    getMaterialDiscountGroups: build.query<
      GetMaterialDiscountGroupsApiResponse,
      GetMaterialDiscountGroupsApiArg
    >({
      query: () => ({
        url: `core/v1/material-discount-groups`,
      }),
      providesTags: ['materialDiscountGroups'],
      extraOptions: {
        responseSchema: GetMaterialDiscountGroupsApiResponseSchema,
        requestSchema: GetMaterialDiscountGroupsApiArgSchema,
      },
    }),
    getMaterialDiscountGroupDiscounts: build.query<
      GetMaterialDiscountGroupDiscountsApiResponse,
      GetMaterialDiscountGroupDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/material-discount-group/${queryArg.groupId}/discounts`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroupDiscounts', id: queryArg.groupId},
      ],
      extraOptions: {
        responseSchema: GetMaterialDiscountGroupDiscountsApiResponseSchema,
        requestSchema: GetMaterialDiscountGroupDiscountsApiArgSchema,
      },
    }),
    postMaterialDiscountGroupSetActive: build.mutation<
      PostMaterialDiscountGroupSetActiveApiResponse,
      PostMaterialDiscountGroupSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/material-discount-group/${queryArg.groupId}/set-active`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroup', id: queryArg.groupId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PostMaterialDiscountGroupSetActiveApiArgSchema,
      },
    }),
    postMaterialDiscountGroupSetInActive: build.mutation<
      PostMaterialDiscountGroupSetInActiveApiResponse,
      PostMaterialDiscountGroupSetInActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/material-discount-group/${queryArg.groupId}/set-inactive`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialDiscountGroup', id: queryArg.groupId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PostMaterialDiscountGroupSetInActiveApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostMaterialDiscountGroupMutation,
  useGetMaterialDiscountGroupByIdQuery,
  useDeleteMaterialDiscountGroupMutation,
  usePatchMaterialDiscountGroupMutation,
  useGetMaterialDiscountGroupItemByIdQuery,
  useDeleteMaterialDiscountGroupItemMutation,
  usePatchMaterialDiscountGroupItemMutation,
  usePostMaterialDiscountGroupItemMutation,
  useGetMaterialDiscountGroupsQuery,
  useGetMaterialDiscountGroupDiscountsQuery,
  useLazyGetMaterialDiscountGroupDiscountsQuery,
  usePostMaterialDiscountGroupSetActiveMutation,
  usePostMaterialDiscountGroupSetInActiveMutation,
} = metadaWorkshopMaterialDiscountGroupApi;
