import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {tiresInventoryRoutes, testIds} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {TierListOverview} from './(sections)/ListOverview/TierListOverview';
import {TireOrderOverview} from './(sections)/OrderOverview/TireOrderOverview';

export function TiresInventoryOverview() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title={i18n.t('page.tiresInventory.labels.tiresInventory')} />
      <Main
        isFullHeight
        tabs={[
          {
            id: 'tireOrders',
            title: i18n.t('page.tiresInventory.labels.tireOrders'),
            content: <TireOrderOverview />,
            href: tiresInventoryRoutes.tireOrders,
          },
          {
            id: 'tierList',
            title: i18n.t('page.tiresInventory.labels.tierList'),
            content: <TierListOverview />,
            href: tiresInventoryRoutes.tireList,
          },
        ]}
        actions={[
          {
            type: 'button',
            title: i18n.t('page.tiresInventory.labels.newOrder'),
            onClick: () => navigate(tiresInventoryRoutes.createTireOrder),
          },
        ]}
        data-testid={testIds.tiresInventory.tireOrders('page')}
      />
    </>
  );
}
