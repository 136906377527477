import {DataStatus, Form, FormField} from 'platform/components';
import {FormToDataGridConnectorProps} from 'platform/datagrid';
import {Grid, GridItem, VStack} from 'platform/foundation';

import {i18n} from '@omnetic-dms/i18n';
import {useGetManufacturersQuery} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {getOptionsFromManufacturers} from '../../../../../utils/getOptionsFromManufacturers';
import {getSearchTypeOptions} from '../../../../../utils/getSearchTypeOptions';

type MaterialPriceListTabExternalFiltersForm = Partial<{
  oemCode: string;
  searchType: string;
  manufacturer: string;
  supplier: string;
}>;

type MaterialPriceListTabExternalFiltersProps = FormToDataGridConnectorProps & TestIdProps;

export function MaterialPriceListTabExternalFilters(
  props: MaterialPriceListTabExternalFiltersProps
) {
  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const isLoading = isManufacturersLoading;
  const isError = isManufacturersError;

  const searchTypeOptions = getSearchTypeOptions();
  const defaultSearchType = searchTypeOptions[0].value;

  const values = {
    ...props.values,
    searchType: props.values.searchType ?? defaultSearchType,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialPriceListTabExternalFiltersForm> values={values} onChange={props.onChange}>
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={3} align="flex-end">
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="oemCode"
                  type="text"
                  label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                  data-testid={suffixTestId('catalogueNumber', props)}
                />
              </GridItem>
              <GridItem span={1}>
                <FormField
                  control={control}
                  name="searchType"
                  type="choice"
                  options={searchTypeOptions}
                  isNotClearable
                  data-testid={suffixTestId('filteringMethod', props)}
                />
              </GridItem>
            </Grid>
            <FormField
              control={control}
              name="manufacturer"
              type="choice"
              label={i18n.t('entity.warehouse.labels.manufacturer')}
              options={getOptionsFromManufacturers(manufacturers)}
              data-testid={suffixTestId('manufacturer', props)}
            />
            <FormField
              control={control}
              name="supplier"
              type="choice"
              label={i18n.t('entity.warehouse.labels.supplier')}
              options={[]}
              data-testid={suffixTestId('supplier', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
