import {
  DeleteServiceCaseApiArg,
  DeleteServiceCaseApiResponse,
  DeleteServiceCaseJobApiArg,
  DeleteServiceCaseJobApiResponse,
  GetCaseItemsTotalAmountApiArg,
  GetCaseItemsTotalAmountApiResponse,
  GetMetadaServiceCaseApiArg,
  GetMetadaServiceCaseApiResponse,
  GetPostponeJobsApiArg,
  GetPostponeJobsApiResponse,
  GetServiceCaseJobApiArg,
  GetServiceCaseJobApiResponse,
  GetServiceCaseOrdersApiArg,
  GetServiceCaseOrdersApiResponse,
  PostServiceCaseApiArg,
  PostServiceCaseApiResponse,
  PostServiceCaseJobFromPostponeJobApiArg,
  PostServiceCaseJobFromPostponeJobApiResponse,
  PatchServiceCaseApiResponse,
  PatchServiceCaseApiArg,
  GetServiceCaseByServiceOrderIdApiArg,
  GetServiceCaseByServiceOrderIdApiResponse,
  GetServiceCaseJobsApiArg,
  GetServiceCaseJobsApiResponse,
  GetServiceCaseOrderVariantsApiArg,
  GetServiceCaseOrderVariantsApiResponse,
  PatchServiceCaseJobApiArg,
  PatchServiceCaseJobApiResponse,
  PostServiceCaseCreateOrdersApiArg,
  PostServiceCaseCreateOrdersApiResponse,
  PostServiceCaseJobApiArg,
  PostServiceCaseJobApiResponse,
  PostServiceCaseCopyServiceRequestsApiArg,
  PostServiceCaseCopyServiceRequestsApiResponse,
  GetServiceCaseJobItemsCountApiResponse,
  GetServiceCaseJobItemsCountApiArg,
} from '../types/api/metadaWorkshopServiceCase';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceCaseApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceCase: build.mutation<PostServiceCaseApiResponse, PostServiceCaseApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getServiceCaseByServiceOrderId: build.query<
      GetServiceCaseByServiceOrderIdApiResponse,
      GetServiceCaseByServiceOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case`,
        params: {serviceOrderId: queryArg.serviceOrderId},
      }),
    }),
    getMetadaServiceCase: build.query<GetMetadaServiceCaseApiResponse, GetMetadaServiceCaseApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceCase', id: queryArg.serviceCaseId},
      ],
    }),
    deleteServiceCase: build.mutation<DeleteServiceCaseApiResponse, DeleteServiceCaseApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCase', id: queryArg.serviceCaseId},
      ],
    }),
    patchServiceCase: build.mutation<PatchServiceCaseApiResponse, PatchServiceCaseApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'orderVariants', id: queryArg.serviceCaseId},
      ],
    }),
    getCaseItemsTotalAmount: build.query<
      GetCaseItemsTotalAmountApiResponse,
      GetCaseItemsTotalAmountApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/items-total-amount`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
      ],
    }),
    getServiceCaseJob: build.query<GetServiceCaseJobApiResponse, GetServiceCaseJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/job/${queryArg.serviceJobId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'serviceJob', id: queryArg.serviceJobId}],
    }),
    deleteServiceCaseJob: build.mutation<
      DeleteServiceCaseJobApiResponse,
      DeleteServiceCaseJobApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/job/${queryArg.serviceCaseJobId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceJob', id: queryArg.serviceCaseJobId},
        {type: 'postponeJobs', id: queryArg.serviceCaseId},
        'vehicleCampaign',
      ],
    }),
    getServiceCaseOrders: build.query<GetServiceCaseOrdersApiResponse, GetServiceCaseOrdersApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/orders`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
      ],
    }),
    getPostponeJobs: build.query<GetPostponeJobsApiResponse, GetPostponeJobsApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/postpone-jobs`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'postponeJobs', id: queryArg.serviceCaseId},
      ],
    }),
    postServiceCaseJobFromPostponeJob: build.mutation<
      PostServiceCaseJobFromPostponeJobApiResponse,
      PostServiceCaseJobFromPostponeJobApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/service-order-variant-id/${queryArg.serviceOrderVariantId}/postpone-job/${queryArg.postponeJobId}/job`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'postponeJobs', id: queryArg.serviceCaseId},
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
      ],
    }),
    postServiceCaseCreateOrders: build.mutation<
      PostServiceCaseCreateOrdersApiResponse,
      PostServiceCaseCreateOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/create-orders`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCase', id: queryArg.serviceCaseId},
      ],
    }),
    patchServiceCaseJob: build.mutation<PatchServiceCaseJobApiResponse, PatchServiceCaseJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/job/${queryArg.serviceCaseJobId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceJob', id: queryArg.serviceCaseJobId},
      ],
    }),
    getServiceCaseJobs: build.query<GetServiceCaseJobsApiResponse, GetServiceCaseJobsApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/jobs`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
      ],
    }),
    getServiceCaseOrderVariants: build.query<
      GetServiceCaseOrderVariantsApiResponse,
      GetServiceCaseOrderVariantsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order-variants`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'orderVariants', id: queryArg.serviceCaseId},
      ],
    }),
    postServiceCaseJob: build.mutation<PostServiceCaseJobApiResponse, PostServiceCaseJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/job`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
      ],
    }),
    postServiceCaseCopyServiceRequests: build.mutation<
      PostServiceCaseCopyServiceRequestsApiResponse,
      PostServiceCaseCopyServiceRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/service-requests-copy`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
      ],
    }),
    getServiceCaseJobItemsCount: build.query<
      GetServiceCaseJobItemsCountApiResponse,
      GetServiceCaseJobItemsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/job/${queryArg.serviceJobId}/items/count`,
      }),
    }),
  }),
});

export const {
  usePostServiceCaseMutation,
  useGetMetadaServiceCaseQuery,
  useLazyGetMetadaServiceCaseQuery,
  useDeleteServiceCaseMutation,
  useGetServiceCaseJobQuery,
  useDeleteServiceCaseJobMutation,
  useGetServiceCaseOrdersQuery,
  useGetPostponeJobsQuery,
  usePostServiceCaseJobFromPostponeJobMutation,
  useGetCaseItemsTotalAmountQuery,
  usePatchServiceCaseMutation,
  useLazyGetServiceCaseByServiceOrderIdQuery,
  usePostServiceCaseCreateOrdersMutation,
  usePostServiceCaseJobMutation,
  usePatchServiceCaseJobMutation,
  useGetServiceCaseJobsQuery,
  useGetServiceCaseOrderVariantsQuery,
  usePostServiceCaseCopyServiceRequestsMutation,
  useGetServiceCaseJobItemsCountQuery,
} = metadaWorkshopServiceCaseApi;
