import {createApi} from '@reduxjs/toolkit/query/react';
import {captureMessage} from '@sentry/react';
import retry from 'async-retry';

import {uploadFileWithProgress} from 'shared';

import {
  FileInfoResponse,
  FileUriResponse,
  UploadFileRequestArg,
  UploadFileResponse,
} from '../../types/api/api';
import {
  FileOperationAcknowledgeApiArg,
  FileOperationAcknowledgeApiResponse,
  GetFileByIdApiApiRequestArg,
  GetFileByIdApiResponse,
  SendMultipartUploadCompletedApiArg,
  SendMultipartUploadCompletedApiResponse,
  SendMultipartUploadRequestApiArg,
  SendMultipartUploadRequestApiResponse,
} from '../../types/api/fileStorage';
import {teasBaseQuery} from '../baseQuery/teasBaseQuery';

export const fileStorageApi = createApi({
  baseQuery: teasBaseQuery,
  reducerPath: 'rtk_fileStorageApi',
  endpoints: (build) => ({
    uploadFile: build.mutation<UploadFileResponse, UploadFileRequestArg>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBaseQuery) {
        try {
          return await retry(
            async () => {
              const fileInfoResponse = await fetchWithBaseQuery({
                body: {filename: args.file.name || ''},
                url: `/dms/v1/file-storage`,
                method: 'POST',
              });

              if (fileInfoResponse.error) {
                throw fileInfoResponse.error;
              }

              const fileInfo = fileInfoResponse.data as FileInfoResponse;
              const fileUriResponse = await fetchWithBaseQuery({
                url: `/dms/v1/file-storage/${fileInfo.fileId}`,
              });

              if (fileUriResponse.error) {
                throw fileUriResponse.error;
              }

              await uploadFileWithProgress<File>({
                file: args.file,
                url: fileInfo.uploadUri,
                shouldOmitSharedHeaders: true,
                onProgress: args.onProgress,
              });

              return {
                data: {
                  fileId: fileInfo.fileId,
                  signedUrl: fileInfo.uploadUri,
                  fileUri: (fileUriResponse.data as FileUriResponse).originalUri,
                  file: args.file,
                },
              };
            },
            {retries: 3}
          );
        } catch (error: any) {
          if (error.message) {
            captureMessage(error.message, error);
          }
          throw error;
        }
      },
    }),
    fileOperationAcknowledge: build.mutation<
      FileOperationAcknowledgeApiResponse,
      FileOperationAcknowledgeApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/file-storage/file-operation/acknowledge`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getFileById: build.query<GetFileByIdApiResponse, GetFileByIdApiApiRequestArg>({
      query: (queryArg) => ({
        url: `/dms/v1/file-storage/${queryArg.fileId}`,
      }),
    }),
    sendMultipartUploadRequest: build.mutation<
      SendMultipartUploadRequestApiResponse,
      SendMultipartUploadRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/file-storage/multipart`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    sendMultipartUploadCompleted: build.mutation<
      SendMultipartUploadCompletedApiResponse,
      SendMultipartUploadCompletedApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/file-storage/upload/multipart/${queryArg.fileId}/complete`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
});

export const {
  useUploadFileMutation,
  useFileOperationAcknowledgeMutation,
  useGetFileByIdQuery,
  useLazyGetFileByIdQuery,
  useSendMultipartUploadRequestMutation,
  useSendMultipartUploadCompletedMutation,
} = fileStorageApi;
