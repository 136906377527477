import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {DEFAULT_ORDER_ID} from '../../types/SourcingOrderTypes';
import {DataViewOptions} from './DataViewOptions';
import {
  DataViewState,
  DataViewContainerName,
  DataViewStateType,
  SetLastVisitedVehicleType,
} from './DataViewState';
import {createDataViewSelectors} from './selectors';

export const createDataViewSlice = (
  name: DataViewContainerName,
  initialValues: Partial<DataViewStateType> = {}
) => {
  const initialState: DataViewState = {
    isLoading: false,
    selectedView: DataViewOptions.table,
    orderById: DEFAULT_ORDER_ID,
    lastVisitedVehicleId: undefined,
    lastVisitedVehicleIndex: undefined,
    selectedRows: [],
    ...initialValues,
  };

  const dataviewSlice = createSlice({
    name,
    initialState,
    reducers: {
      updateOrderBy: (state, {payload}: {payload: string}) => ({
        ...state,
        orderById: payload || DEFAULT_ORDER_ID,
      }),
      setDataView: (state, {payload}: {payload: DataViewOptions}) => ({
        ...state,
        selectedView: payload,
      }),
      setLastVisitedVehicle: (state, {payload}: PayloadAction<SetLastVisitedVehicleType>) => ({
        ...state,
        lastVisitedVehicleId: payload.adId,
        lastVisitedVehicleIndex: payload.index,
      }),
      setSelectedRows: (state, {payload}) => ({
        ...state,
        selectedRows: payload,
      }),
      resetDataView: (state, {payload}: {payload: Partial<DataViewStateType>}) => ({
        ...state,
        ...initialState,
        ...payload,
      }),
    },
  });

  const {reducer, actions} = dataviewSlice;

  const selectors = createDataViewSelectors(name);
  return {
    initialState,
    reducer,
    actions,
    selectors,
  };
};
