import {Dropdown, DropdownItem, Tooltip} from 'platform/components';
import {Icon, IconProps, Heading} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {FC, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {selectAudit} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditDamageValueBody} from '../../../types/ConditionTypes';
import {noop} from '../../../utils/someTeasUtils';
import {useConditionContext} from '../hooks/useConditionContext';
import {useConditionDamageLocation} from '../hooks/useConditionDamageLocation';
import {useConditionDamagesContext} from '../hooks/useConditionDamagesContext';
import {AuditParamType} from '../types/AuditParamType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {getAuditFieldData} from '../utils/getAuditFieldData';
import {getDamageTypeLabel} from '../utils/getDamageTypeLabel';
import {getDamageValueColor} from '../utils/getDamageValueColor';
import {getParamFromCategory} from '../utils/getParamFromCategory';
import {getTranslation} from '../utils/getTranslation';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
`;

const RelatedDamageWrapper = styled.div<{right: boolean}>`
  ${({right}) =>
    right
      ? css`
          right: 0;
          border-left: 8px solid;
        `
      : css`
          left: 0;
          border-right: 8px solid;
        `}
  border-bottom: 8px solid;
  border-color: #fff;
  position: absolute;
  top: 0;

  svg {
    width: 13px;
  }
`;

const LocationWrapper = styled.div<{isDisabled: boolean}>`
  background: var(--eag-colors-palettes-neutral-10-100);
  color: var(--eag-colors-palettes-neutral-900-100);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  padding: 12px;

  ${({isDisabled}) =>
    isDisabled
      ? css`
          cursor: not-allowed;
          opacity: 0.4;
        `
      : css`
          &[data-highlighted='true'],
          &:hover {
            background: var(--eag-colors-palettes-orange-10-100);
            color: var(--eag-colors-palettes-orange-80-100);
          }
        `}
`;

const DamageSignWrapper = styled.div<{hasRelatedLeft?: boolean}>`
  position: absolute;
  bottom: 0;
  left: ${({hasRelatedLeft}) => (hasRelatedLeft ? '45px' : '0')};
  right: 0;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 100%;
  max-height: 100%;
  transform: scaleY(-1);
`;

const DamageSign = styled.div<{$color: string; $highlight: boolean}>`
  @keyframes blink {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    14% {
      transform: scale(1.3);
    }

    28% {
      transform: scale(1);
      opacity: 0.66;
    }

    42% {
      transform: scale(1.5);
    }

    70% {
      transform: scale(1);
      opacity: 1;
    }
  }
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background: ${({$color}) => $color};
  ${({$highlight}) =>
    $highlight &&
    css`
      animation: blink 0.65s ease-in-out;
    `}
`;

const CapitalizeLabel = styled.span`
  text-transform: capitalize;
`;

type CategoryDamage = {
  label: string;
  type: string;
};

type DamageLocationCategoryProps = {
  category: AuditCategoryOfStructure;
  label?: string;
  hasRelatedLeft?: boolean;
};

const DamageLocationCategory: FC<DamageLocationCategoryProps & TestIdProps> = ({
  category,
  label,
  hasRelatedLeft,
  ...rest
}) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const auditData = useSelector(selectAudit);
  const {isDisabledForUser} = useConditionContext();

  const {onAddDamage} = useConditionDamagesContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const {highlightedDamageType} = useConditionDamageLocation();

  const getCategoryDamages = useCallback(
    (category: AuditCategoryOfStructure): CategoryDamage[] => {
      const damageTypeParam = getParamFromCategory(category, AuditParamType.SELECTABLE_BUTTON);
      if (!damageTypeParam) {
        return [];
      }

      const damageTypeFieldData = getAuditFieldData(
        category.id,
        damageTypeParam.id,
        auditData ?? undefined
      );

      return Boolean(damageTypeFieldData) && Array.isArray(damageTypeFieldData)
        ? (damageTypeFieldData as AuditDamageValueBody[]).map(({type}) => ({
            label: getDamageTypeLabel(type, damageTypeParam.values, locale),
            type,
          }))
        : [];
    },
    [auditData, locale]
  );

  const categories = [category, ...(category.childCategories?.map((category) => category) ?? [])];
  let categoryDamages: CategoryDamage[] = [];

  categories.forEach((category) => {
    categoryDamages = [...categoryDamages, ...getCategoryDamages(category)];
  });

  const hasChildCategories = Boolean(category.childCategories?.length);

  const handleLocationClick = () => {
    if (hasChildCategories) {
      setIsDropdownOpen(true);
      return;
    }

    onAddDamage(category);
  };

  const otherDamage = {
    type: AuditCategoryUniqueKey.OTHER,
    label: i18n.t('general.labels.other'),
  };

  const damageAliases: Record<string, {type: string; label: string}> = {
    [AuditCategoryUniqueKey.OTHER_DEFINE_IN_NOTE]: otherDamage,
    [AuditCategoryUniqueKey.OTHER_DEFINE_IN_THE_NOTE]: otherDamage,
  };

  return (
    <>
      {hasChildCategories && (
        <Dropdown
          isOpen={isDropdownOpen}
          dropdownControl={<></>}
          onClose={() => setIsDropdownOpen(false)}
          data-testid={suffixTestId(category.id, rest)}
        >
          {category.childCategories?.map((item) => (
            <DropdownItem
              key={`child-category-item-${item.id}-${item.name}`}
              label={getTranslation(locale, item.name)}
              onClick={() => onAddDamage(item)}
              data-testid={suffixTestId(`${category.id}-${item.id}`, rest)}
            />
          ))}
        </Dropdown>
      )}

      <LocationWrapper
        onClick={isDisabledForUser ? noop : handleLocationClick}
        isDisabled={false}
        data-highlighted={Boolean(categoryDamages.length)}
        data-testid={suffixTestId(`${category.id}-damage-locationWrapper`, rest)}
      >
        {label ? (
          <Heading
            size={4}
            alternative
            data-testid={suffixTestId(`${category.id}-damage-location-${label}`, rest)}
          >
            {label}
          </Heading>
        ) : (
          <Icon value={`condition/${category.uniqueKey.toLowerCase()}` as IconProps['value']} />
        )}
      </LocationWrapper>
      <DamageSignWrapper
        data-testid={suffixTestId(`${category.id}-damage-signWrapper`, rest)}
        hasRelatedLeft={hasRelatedLeft}
      >
        {categoryDamages?.map((damage, index) => {
          const {type, label} = damageAliases?.[damage.type] || damage;

          return (
            <Tooltip
              label={<CapitalizeLabel>{label}</CapitalizeLabel>}
              key={`category-damages-${category.id}-${damage.type}-${index}`}
            >
              <DamageSign
                $highlight={highlightedDamageType === type}
                $color={getDamageValueColor(type)}
                data-testid={suffixTestId(`${category.id}-damage-sign-${label}`, rest)}
              />
            </Tooltip>
          );
        })}
      </DamageSignWrapper>
    </>
  );
};

type DamageLocationProps = {
  category: AuditCategoryOfStructure;
  relatedCategory?: AuditCategoryOfStructure;
  label?: string;
};

export const DamageLocation: FC<DamageLocationProps & TestIdProps> = ({
  category,
  relatedCategory,
  label,
  ...rest
}) => {
  if (!category) {
    return null;
  }

  if (label) {
    return (
      <Container>
        <DamageLocationCategory
          category={category}
          label={label}
          data-testid={rest['data-testid']}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Wrapper>
        <DamageLocationCategory
          category={category}
          data-testid={rest['data-testid']}
          hasRelatedLeft={relatedCategory?.uniqueKey === AuditCategoryUniqueKey.R_MIRROR}
        />
      </Wrapper>
      {relatedCategory && (
        <RelatedDamageWrapper right={relatedCategory.uniqueKey === AuditCategoryUniqueKey.L_MIRROR}>
          <DamageLocationCategory category={relatedCategory} data-testid={rest['data-testid']} />
        </RelatedDamageWrapper>
      )}
    </Container>
  );
};
