import {
  Action,
  Card,
  closeCurrentDialog,
  DataStatus,
  DropdownActionMenuItem,
  Label,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Box, Center, Grid, Heading, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {always, defaultTo, head, isNil, mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  catchUnhandledDataGridActions,
  GetArticleResponse,
  handleApiError,
  Section,
  useGetPartsRequestQuery,
  usePatchPartsRequestItemCancelMutation,
  usePatchPartsRequestItemDeleteMutation,
} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {ReservationCustomer} from './components/ReservationCustomer';
import {ReservationDirectSales} from './components/ReservationDirectSales';
import {ReservationOther} from './components/ReservationOther';
import {ReservationServiceOrder} from './components/ReservationServiceOrder';
import {ReservationServiceOrderIssue} from './components/ReservationServiceOrderIssue';

interface ReservationsProps extends RequiredTestIdProps {
  article: GetArticleResponse;
  handlingUnitLabel: string | null;
}

export function Reservations(props: ReservationsProps) {
  const params = useWarehouseParams();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const {
    data: partsRequest,
    isLoading: isPartsRequestLoading,
    isError: isPartsRequestError,
  } = useGetPartsRequestQuery({articleId: params.articleId});

  const [cancelReservation] = usePatchPartsRequestItemCancelMutation();
  const [deleteReservation] = usePatchPartsRequestItemDeleteMutation();

  const isLoading = isPartsRequestLoading;
  const isError = isPartsRequestError;

  const dispensingUnit = defaultTo(1, props.article.dispensingUnit);

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const reservationItemId = isArray(rowId) ? head(rowId) : rowId;
    const entityType = path(['originEntityType', 'value', 'key'], rowData) as string;

    if (isNil(reservationItemId)) {
      throw new Error('Row id is not defined');
    }

    if (isNil(entityType)) {
      throw new Error('entityType is not defined');
    }

    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        match(entityType)
          .with('service_order_issue', () =>
            openDialog(
              <ReservationServiceOrderIssue
                articleId={params.articleId}
                reservationItemId={reservationItemId}
                dispensingUnit={dispensingUnit}
                onSubmit={refreshData}
              />,
              {
                title: i18n.t('entity.warehouse.labels.reservationServiceOrderIssue'),
                size: 'large',
                withAdditionalFooter: true,
              }
            )
          )
          .with('svc_job_item', () =>
            openDialog(
              <ReservationServiceOrder
                articleId={params.articleId}
                warehouseId={params.warehouseId}
                reservationItemId={reservationItemId}
                dispensingUnit={dispensingUnit}
                cancelAndDeleteConfirmText={i18n.t(
                  'entity.warehouse.actions.cancelAndDeleteServiceOrderReservation'
                )}
                onSubmit={refreshData}
                onCancel={handleCancelReservationFromEditDialog}
                onCancelAndDelete={handleCancelAndDeleteReservationFromEditDialog}
                data-testid={testIds.warehouse.articleDetailReservations(
                  'reservation.serviceOrder'
                )}
              />,
              {
                title: i18n.t('entity.warehouse.labels.reservationServiceOrder'),
                size: 'large',
                withAdditionalFooter: true,
              }
            )
          )
          .with('wrh_sale_item', () =>
            openDialog(
              <ReservationDirectSales
                articleId={params.articleId}
                reservationItemId={reservationItemId}
                dispensingUnit={dispensingUnit}
                cancelAndDeleteConfirmText={i18n.t(
                  'entity.warehouse.actions.cancelAndDeleteDirectSaleReservation'
                )}
                onSubmit={refreshData}
                onCancel={handleCancelReservationFromEditDialog}
                onCancelAndDelete={handleCancelAndDeleteReservationFromEditDialog}
                data-testid={testIds.warehouse.articleDetailReservations('reservation.directSales')}
              />,
              {
                title: i18n.t('entity.warehouse.labels.reservationDirectSales'),
                size: 'large',
                withAdditionalFooter: true,
                'data-testid': suffixTestId('reservationDialog.directSales', props),
              }
            )
          )
          .with('customer', () =>
            openDialog(
              <ReservationCustomer
                articleId={params.articleId}
                reservationItemId={reservationItemId}
                dispensingUnit={dispensingUnit}
                onSubmit={refreshData}
                onCancel={handleCancelReservationFromEditDialog}
                data-testid={testIds.warehouse.articleDetailReservations('reservation.customer')}
              />,
              {
                title: i18n.t('entity.warehouse.labels.reservationCustomer'),
                size: 'large',
                withAdditionalFooter: true,
                'data-testid': suffixTestId('reservationDialog.customer', props),
              }
            )
          )
          .with('other', () =>
            openDialog(
              <ReservationOther
                articleId={params.articleId}
                reservationItemId={reservationItemId}
                dispensingUnit={dispensingUnit}
                onSubmit={refreshData}
                onCancel={handleCancelReservationFromEditDialog}
                data-testid={testIds.warehouse.articleDetailReservations('reservation.other')}
              />,
              {
                title: i18n.t('entity.warehouse.labels.reservationOther'),
                size: 'small',
                withAdditionalFooter: true,
                'data-testid': suffixTestId('reservationDialog.other', props),
              }
            )
          )
          .otherwise(() =>
            showNotification.error(
              `Action callback was not specified for entity type ${entityType}`
            )
          );
      })
      .with('delete', () => {
        const confirmAndDeleteText = match(entityType)
          .with(
            'svc_job_item',
            always(i18n.t('entity.warehouse.actions.cancelAndDeleteServiceOrderReservation'))
          )
          .with(
            'wrh_sale_item',
            always(i18n.t('entity.warehouse.actions.cancelAndDeleteDirectSaleReservation'))
          )
          .otherwise(always(undefined));

        openDeleteDialog({
          text: confirmAndDeleteText,
          onConfirm: () => {
            deleteReservation({requestItemId: reservationItemId})
              .unwrap()
              .then(() => {
                showNotification.success(
                  i18n.t('entity.warehouse.notifications.reservationDeleted')
                );
              })
              .then(refreshData)
              .catch(() => {
                showNotification.error(i18n.t('entity.warehouse.notifications.deleteFailed'));
              });
          },
        });
      })
      .with('cancel', () =>
        openDeleteDialog({
          text: i18n.t('entity.warehouse.actions.cancelReservationConfirm'),
          onConfirm: () => {
            cancelReservation({requestItemId: reservationItemId})
              .unwrap()
              .then(() => {
                showNotification.success(
                  i18n.t('entity.warehouse.notifications.reservationCancelled')
                );
              })
              .then(refreshData)
              .catch(handleApiError);
          },
        })
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleCancelReservationFromEditDialog = (reservationItemId: string) => {
    cancelReservation({requestItemId: reservationItemId})
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.reservationCancelled'));
      })
      .then(closeCurrentDialog)
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const handleCancelAndDeleteReservationFromEditDialog = (reservationItemId: string) => {
    deleteReservation({requestItemId: reservationItemId})
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.reservationDeleted'));
      })
      .then(closeCurrentDialog)
      .then(refreshDataGrid)
      .catch(() => {
        showNotification.error(i18n.t('entity.warehouse.notifications.deleteFailed'));
      });
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([filter, {isReserved: true, articleId: params.articleId}]),
    [params.articleId]
  );

  if (!partsRequest) {
    return (
      <Center width="100%">
        <DataStatus isLoading={isLoading} isError={isError} minHeight="100vh" />
      </Center>
    );
  }

  const formatReservationCount = (count: number | Nullish) => {
    const outputCount = defaultTo(0, count);
    return `${outputCount} ${props.handlingUnitLabel}`;
  };

  const menuItems: DropdownActionMenuItem[] = [
    {
      label: i18n.t('entity.warehouse.labels.serviceOrder'),
      onClick: () => {
        openDialog(
          <ReservationServiceOrder
            articleId={params.articleId}
            warehouseId={params.warehouseId}
            dispensingUnit={dispensingUnit}
            onSubmit={refreshDataGrid}
            data-testid={testIds.warehouse.articleDetailReservations('reservation.serviceOrder')}
          />,
          {
            title: i18n.t('entity.warehouse.labels.reservationServiceOrder'),
            size: 'large',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('reservationDialog.serviceOrder', props),
          }
        );
      },
    },
    {
      label: i18n.t('entity.warehouse.labels.directSales'),
      onClick: () => {
        openDialog(
          <ReservationDirectSales
            articleId={params.articleId}
            dispensingUnit={dispensingUnit}
            onSubmit={refreshDataGrid}
            data-testid={testIds.warehouse.articleDetailReservations('reservation.directSales')}
          />,
          {
            title: i18n.t('entity.warehouse.labels.reservationDirectSales'),
            size: 'large',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('reservationDialog.directSales', props),
          }
        );
      },
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      onClick: () => {
        openDialog(
          <ReservationCustomer
            articleId={params.articleId}
            dispensingUnit={dispensingUnit}
            onSubmit={refreshDataGrid}
            data-testid={testIds.warehouse.articleDetailReservations('reservation.customer')}
          />,
          {
            title: i18n.t('entity.warehouse.labels.reservationCustomer'),
            size: 'large',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('reservationDialog.customer', props),
          }
        );
      },
    },
    {
      label: i18n.t('entity.warehouse.labels.other'),
      onClick: () => {
        openDialog(
          <ReservationOther
            articleId={params.articleId}
            dispensingUnit={dispensingUnit}
            onSubmit={refreshDataGrid}
            data-testid={testIds.warehouse.articleDetailReservations('reservation.other')}
          />,
          {
            title: i18n.t('entity.warehouse.labels.reservationOther'),
            size: 'small',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('reservationDialog.other', props),
          }
        );
      },
    },
  ];

  const reservationsCardActions: Action[] = [
    {
      type: 'dropdown-button',
      title: i18n.t('general.actions.add'),
      variant: 'link',
      leftIcon: 'content/add_circle',
      menuItems,
      'data-testid': suffixTestId('add', props),
    },
  ];

  return (
    <Section>
      <VStack spacing={4}>
        <Card title={i18n.t('entity.warehouse.labels.reservationsOverview')}>
          <Grid columns={6}>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.totalReservations')}</Label>
              <Heading size={1}>{formatReservationCount(partsRequest.total)}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.serviceOrderReservations')}</Label>
              <Heading size={1}>{formatReservationCount(partsRequest.serviceOrder)}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.serviceOrderIssueReservations')}</Label>
              <Heading size={1}>{formatReservationCount(partsRequest.serviceOrderIssue)}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.directSalesReservations')}</Label>
              <Heading size={1}>{formatReservationCount(partsRequest.warehouseSale)}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.customerReservations')}</Label>
              <Heading size={1}>{formatReservationCount(partsRequest.customer)}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.otherReservations')}</Label>
              <Heading size={1}>{formatReservationCount(partsRequest.other)}</Heading>
            </VStack>
          </Grid>
        </Card>
        <Card
          title={i18n.t('entity.warehouse.labels.reservations')}
          actions={reservationsCardActions}
        >
          <Box height="52vh">
            <DataGrid
              ref={dataGridRef}
              gridCode="warehouse-parts-request"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              data-testid={suffixTestId('reservationsList', props)}
            />
          </Box>
        </Card>
      </VStack>
    </Section>
  );
}
