import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isEmpty} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {MakeFieldDefinitionResponseBody} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {CreateDefinitionForm} from './CreateDefinitionForm';
import {DefinitionFormRow} from './DefinitionFormRow';

interface ResourceDefinitionListProps extends RequiredTestIdProps {
  definitions: MakeFieldDefinitionResponseBody[];
  resourceId: string;
}

export function ResourceDefinitionList(props: ResourceDefinitionListProps) {
  const [isCreating, startCreating, cancelCreating] = useBoolean(false);

  const areDefinitionsEmpty = isEmpty(props.definitions);

  return (
    <>
      <DataStatus
        isEmpty={areDefinitionsEmpty && !isCreating}
        action={{
          title: i18n.t('page.generalSettings.labels.addField'),
          leftIcon: 'content/add_circle',
          onClick: startCreating,
        }}
      >
        <VStack spacing={4}>
          {(props.definitions ?? []).map((definition, index) => (
            <DefinitionFormRow
              definition={definition}
              data-testid={suffixTestId(`[${index}]`, props)}
              key={definition.id}
            />
          ))}

          <CreateDefinitionForm
            data-testid={testIds.settings.customFields(`addDefinitionForm-${props.resourceId}`)}
            resourceId={props.resourceId}
            onAfterSubmit={cancelCreating}
          />
        </VStack>
      </DataStatus>
    </>
  );
}
