import {Form, FormField, FormSubmitHandler, Separator, showNotification} from 'platform/components';
import {Grid, HStack, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  getOptionsFromAuthorizationProfiles,
  handleApiError,
  selectUserBranchOptions,
  useGetAuthorizationProfilesQuery,
  useGetServicePackageCategoriesQuery,
  useGetTenantQuery,
  usePatchServicePackageBasicInformationMutation,
  usePostServicePackageMutation,
} from '@omnetic-dms/shared';

import {TestIdProps, composePath, suffixTestId, yupString} from 'shared';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {VehicleFields} from './components/VehicleFields';
import {BasicInformationFormType} from './types/basicInformationFormType';

interface BasicInformationFormProps extends TestIdProps {
  id?: string;
  defaultValues?: Partial<BasicInformationFormType>;
}

const VEHICLE_AGE_OPTIONS = [
  {
    label: i18n.t('entity.workshopServicePackages.labels.vehicleAgeUndecided'),
    value: 'VEHICLE_AGE_UNDECIDED',
  },
  {
    label: i18n.t('entity.workshopServicePackages.labels.vehicleAgeDecided'),
    value: 'VEHICLE_AGE_DECIDED',
  },
];

export function BasicInformationForm(props: BasicInformationFormProps) {
  const navigate = useNavigate();

  const branchOptions = useSelector(selectUserBranchOptions);
  const {data: tenant} = useGetTenantQuery();
  const {data: authorizationProfiles, isLoading: isAuthorizationProfilesLoading} =
    useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});
  const {data: servicePackageCategories, isLoading: isServicePackageCategoriesLoading} =
    useGetServicePackageCategoriesQuery();
  const [postServicePackage] = usePostServicePackageMutation();
  const [patchServicePackageBasicInformation] = usePatchServicePackageBasicInformationMutation();

  const handleNavigateBack = () => navigate(settingsRoutes.workshopServicePackages);

  const handleSubmit: FormSubmitHandler<BasicInformationFormType> = async (data) => {
    if (isNotNilOrEmpty(props.id)) {
      return await patchServicePackageBasicInformation({
        servicePackageId: props.id ?? '',
        body: {
          ...data,
          isActive: true,
          branchIds: data.branchIds ?? null,
          authorizationProfileIds: data.authorizationProfileIds ?? null,
          number: data?.number ?? null,
          vehicleType: data?.vehicleType ?? null,
          vehicleMakes: data?.vehicleMakes ?? null,
          vehicleModelFamilies: data?.vehicleModelFamilies ?? null,
          vehicleModels: data?.vehicleModels ?? null,
          vehicleAgeFrom: data?.vehicleAgeFrom ?? null,
          vehicleAgeTo: data?.vehicleAgeTo ?? null,
          isLockServicePackage: !!data.isLockServicePackage,
          isDoNotApplyDiscount: !!data.isDoNotApplyDiscount,
        },
      })
        .unwrap()
        .then(() => {
          showNotification.success();
        })
        .catch(handleApiError);
    }
    await postServicePackage({
      body: {
        ...data,
        isActive: true,
        isLockServicePackage: !!data.isLockServicePackage,
        isDoNotApplyDiscount: !!data.isDoNotApplyDiscount,
      },
    })
      .unwrap()
      .then((response) => {
        showNotification.success();
        navigate(
          composePath(settingsRoutes.workshopEditServicePackages, {
            params: {id: response?.id ?? ''},
          })
        );
      })
      .catch(handleApiError);
  };

  const servicePackageCategoryOptions = servicePackageCategories?.servicePackagesCategory?.map(
    (category) => ({
      label: category?.name,
      value: category?.id,
    })
  );

  return (
    <Form<BasicInformationFormType>
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={{
        ...props.defaultValues,
        vehicleAgeType: props.defaultValues?.vehicleAgeType ?? 'VEHICLE_AGE_UNDECIDED',
      }}
    >
      {(control, formApi) => (
        <>
          <SettingsSection>
            <VStack spacing={4}>
              <FormField
                control={control}
                type="text"
                name="name"
                isRequired
                label={i18n.t('general.labels.name')}
                data-testid={suffixTestId('name', props)}
              />
              <Grid columns={2} spacing={4}>
                <FormField
                  control={control}
                  type="multiChoice"
                  name="branchIds"
                  options={branchOptions}
                  label={i18n.t('entity.branch.labels.branch')}
                  data-testid={suffixTestId('branchIds', props)}
                  isNotClearable
                />
                <FormField
                  control={control}
                  type="multiChoice"
                  name="authorizationProfileIds"
                  options={getOptionsFromAuthorizationProfiles(authorizationProfiles)}
                  isLoading={isAuthorizationProfilesLoading}
                  label={i18n.t('entity.workshopServicePackages.labels.authorizationProfile')}
                  data-testid={suffixTestId('authorizationProfileIds', props)}
                  isNotClearable
                />
              </Grid>
              <Grid columns={2} spacing={4}>
                <FormField
                  control={control}
                  type="text"
                  name="number"
                  label={i18n.t('general.labels.number')}
                  data-testid={suffixTestId('number', props)}
                />
                <FormField
                  control={control}
                  type="choice"
                  isRequired
                  name="servicePackageCategoryId"
                  options={servicePackageCategoryOptions}
                  isLoading={isServicePackageCategoriesLoading}
                  label={i18n.t('entity.workshopServicePackages.labels.servicePackageCategory')}
                  data-testid={suffixTestId('servicePackageCategoryId', props)}
                />
              </Grid>
              <VehicleFields
                control={control}
                formApi={formApi}
                data-testid={suffixTestId('vehicleFields', props)}
              />
              <Grid columns={2} spacing={4}>
                <FormField
                  control={control}
                  type="radio"
                  name="vehicleAgeType"
                  label={i18n.t('entity.workshopServicePackages.labels.vehicleAge')}
                  options={VEHICLE_AGE_OPTIONS}
                  data-testid={suffixTestId('vehicleAgeType', props)}
                />
                <Grid columns={2} spacing={4}>
                  <FormField
                    control={control}
                    type="number"
                    name="vehicleAgeFrom"
                    label={i18n.t('entity.workshopServicePackages.labels.vehicleAgeFrom')}
                    isDisabled={formApi.watch('vehicleAgeType') === 'VEHICLE_AGE_UNDECIDED'}
                    data-testid={suffixTestId('vehicleAgeFrom', props)}
                  />
                  <FormField
                    control={control}
                    type="number"
                    name="vehicleAgeTo"
                    label={i18n.t('entity.workshopServicePackages.labels.vehicleAgeTo')}
                    isDisabled={formApi.watch('vehicleAgeType') === 'VEHICLE_AGE_UNDECIDED'}
                    data-testid={suffixTestId('vehicleAgeTo', props)}
                  />
                </Grid>
              </Grid>
              <Separator spacing={0} />
              <HStack spacing={4}>
                <FormField
                  control={control}
                  type="checkbox"
                  name="isLockServicePackage"
                  label={i18n.t('entity.workshopServicePackages.labels.lockServicePackage')}
                  data-testid={suffixTestId('isLockServicePackage', props)}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="isDoNotApplyDiscount"
                  label={i18n.t('general.labels.doNotApplyDiscount')}
                  data-testid={suffixTestId('isDoNotApplyDiscount', props)}
                />
              </HStack>
            </VStack>
          </SettingsSection>
          <Space vertical={20} />
          <SettingsFooter
            actions={[
              {
                type: 'button',
                variant: 'secondary',
                title: props.id
                  ? i18n.t('general.actions.discardChanges')
                  : i18n.t('general.actions.discard'),
                onClick: handleNavigateBack,
                'data-testid': suffixTestId('discard', props),
              },
              {
                type: 'form-button',
                control,
                buttonType: 'submit',
                variant: 'primary',
                title: props.id
                  ? i18n.t('general.actions.saveChanges')
                  : i18n.t('general.actions.save'),
                'data-testid': suffixTestId('submit', props),
              },
            ]}
          />
        </>
      )}
    </Form>
  );
}

const formSchema = object({
  name: yupString.required(),
  servicePackageCategoryId: yupString.required(),
});
