import {Heading, Icon} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {sourcingRoutes} from '@omnetic-dms/routes';

import {composePath} from 'shared';

import {useRouter} from '../../../hooks/useRouter';
import {PREV_URL_COOKIE} from '../../../types/CommonAppTypes';
import {SOURCING_USER_LISTS, SourcingSubPageRoute} from '../../../types/SourcingSomeTypes';
import {pathsWithBackButton} from '../utils/pathsWithBackButton';
import {BackButton} from './BackButton';
import {BackButtonWrapper} from './BackButtonWrapper';
import {TitleSectionWrapper} from './TitleSectionWrapper';

export function TitleSection() {
  const router = useRouter();
  const pageRoute = router?.route;

  const backButton = () => {
    const allOffersUrl = SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds);
    const prevUrl = localStorage.getItem(PREV_URL_COOKIE) || allOffersUrl;

    router.replace(
      pageRoute ===
        composePath(sourcingRoutes.vehicleDetail, {
          params: {adId: ':adId'},
        })
        ? prevUrl
        : allOffersUrl
    );
  };

  return (
    <TitleSectionWrapper>
      {pathsWithBackButton.includes(pageRoute) && (
        <BackButtonWrapper>
          <BackButton
            primary
            compact
            onlyIcon
            onClick={backButton}
            icon={<Icon size={4} value="hardware/keyboard_arrow_left" />}
          />
        </BackButtonWrapper>
      )}

      <Heading headingLevel={2} size={4}>
        {i18n.t('page.sourcing.labels.sourcing')}
      </Heading>
    </TitleSectionWrapper>
  );
}
