import {
  Action,
  DataStatus,
  closeCurrentDialog,
  showNotification,
  openDialog,
} from 'platform/components';
import {useRefreshDataGrid} from 'platform/datagrid';
import {Box, HStack} from 'platform/foundation';

import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {defaultTo, isNil, not, equals} from 'ramda';
import {isArray, isNotNilOrEmpty, isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  AfterSalesMaterialBasket,
  AssignedMechanic,
  EntityHeader,
  Page,
  EitherQuantityOrError,
  ServiceOrderIssueNoteBasketItem,
  handleApiError,
  useDeleteServiceOrderIssueNoteBasketMutation,
  useGetEmployeeMechanicsQuery,
  EditMaterialItem,
  MaterialBasketItemEditingDetails,
  useGetServiceOrderIssueNoteBasketQuery,
  useGetServiceOrderIssueNoteQuery,
  useGetServiceOrderIssueVariantQuery,
  useGetServiceOrderQuery,
  usePatchServiceOrderIssueNoteBasketCheckoutMutation,
  usePatchServiceOrderIssueNoteBasketItemQuantityMutation,
  useBulkDeleteServiceOrderIssueNoteItemsMutation,
  useInvalidBasketItemsIds,
  TooltipItem,
} from '@omnetic-dms/shared';

import {composePath, EMPTY_PLACEHOLDER, useNavigate, useRequiredParams} from 'shared';

import {MISSING_EDITING_DETAILS_MESSAGE} from '../../constants/missingEditingDetailsMessage';
import {MaterialList} from './components/MaterialList';

// Temporary solution, will be removed after resolving
// TODO https://carvago.atlassian.net/browse/T20-51559
type MaterialBasketItemWithTooltip = Omit<ServiceOrderIssueNoteBasketItem, 'tooltip'> & {
  tooltip: TooltipItem[];
};

export function ServiceOrderIssueNew() {
  const {serviceOrderIssueNoteId} = useRequiredParams();

  const navigate = useNavigate();

  const [addedSparePartsDatagridRef, refreshAddedSparePartsDatagrid] = useRefreshDataGrid();

  const [mechanic, setMechanic] = useState<AssignedMechanic | null>(null);

  const [mechanicError, setMechanicError] = useState<string | undefined>(undefined);

  const {
    data: serviceOrderIssueNote,
    isLoading: isServiceOrderIssueNoteLoading,
    isError: hasServiceOrderIssueNoteError,
  } = useGetServiceOrderIssueNoteQuery({
    serviceOrderIssueNoteId,
  });

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetServiceOrderIssueNoteBasketQuery({
    serviceOrderIssueNoteId,
  });

  const {
    data: serviceOrderIssueVariant,
    isLoading: isServiceOrderIssueVariantLoading,
    isError: hasServiceOrderIssueVariantError,
  } = useGetServiceOrderIssueVariantQuery(
    {serviceOrderIssueVariantId: defaultTo('', serviceOrderIssueNote?.serviceOrderIssueVariantId)},
    {skip: isNil(serviceOrderIssueNote)}
  );

  const {
    data: serviceOrder,
    isLoading: isServiceOrderLoading,
    isError: hasServiceOrderError,
  } = useGetServiceOrderQuery(
    {
      serviceCaseId: defaultTo('', serviceOrderIssueNote?.serviceCaseId),
      serviceOrderId: defaultTo('', serviceOrderIssueNote?.serviceOrderId),
    },
    {skip: isNil(serviceOrderIssueNote)}
  );

  const {data: mechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: defaultTo('', serviceOrderIssueVariant?.authorizationProfileId)},
    {skip: isNil(serviceOrderIssueVariant?.authorizationProfileId)}
  );

  const [discardBasket] = useDeleteServiceOrderIssueNoteBasketMutation();

  const [patchServiceOrderIssueNoteBasketCheckout, {isLoading: isPatchLoading}] =
    usePatchServiceOrderIssueNoteBasketCheckoutMutation();

  const [patchServiceOrderIsseNoteBasketItemQuantity] =
    usePatchServiceOrderIssueNoteBasketItemQuantityMutation();

  const [deleteBasketItems, {isLoading: isDeletingBasketItems}] =
    useBulkDeleteServiceOrderIssueNoteItemsMutation();

  const serviceCaseId = defaultTo('', serviceOrderIssueNote?.serviceCaseId);

  const serviceOrderId = defaultTo('', serviceOrderIssueNote?.serviceOrderId);

  const basketItems = basket?.sparePartsBasketItem ?? [];

  const basketTotalPrice = basket?.sparePartsBasketTotalPrice;

  const basketMechanicId = basket?.assignMechanicId;

  const isLoading =
    isServiceOrderIssueNoteLoading || isServiceOrderIssueVariantLoading || isServiceOrderLoading;

  const hasError =
    hasServiceOrderIssueNoteError || hasServiceOrderIssueVariantError || hasServiceOrderError;

  const assignedMechanic = defaultTo(null, mechanic);

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  // Manually set the last selected mechanic to include it in onSubmit payload since
  // AfterSalesMaterialBasket's onChange not being triggerable without unnecessary useEffect.
  useEffect(() => {
    if (isNil(basketMechanicId)) {
      return;
    }

    const lastSelectedMechanic = mechanics?.employees?.find(
      (mechanic) => mechanic?.id === basketMechanicId
    );

    if (isNil(lastSelectedMechanic)) {
      return;
    }

    setMechanic({
      id: lastSelectedMechanic.id!,
      isDefault: true,
      costCenterId: lastSelectedMechanic.costCenterId!,
    });
  }, [basketMechanicId, mechanics?.employees]);

  const handleAdd = async () => {
    if (isNil(assignedMechanic) && isNil(basket?.assignMechanicId)) {
      return setMechanicError(i18n.t('general.notifications.fieldIsRequired'));
    }

    await patchServiceOrderIssueNoteBasketCheckout({
      serviceOrderIssueNoteId,
      body: {
        mechanic: assignedMechanic!,
      },
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.itemsIssued')))
      .then(() =>
        navigate(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            params: {id: serviceOrderIssueNoteId},
          })
        )
      )
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await discardBasket({serviceOrderIssueNoteId})
      .unwrap()
      .then(() =>
        navigate(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            params: {id: serviceOrderIssueNoteId},
          })
        )
      )
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const handleMechanicChange = (mechanic: AssignedMechanic | null) => {
    setMechanic(mechanic);
    setMechanicError(undefined);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchServiceOrderIsseNoteBasketItemQuantity({
      serviceOrderIssueNoteId,
      itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = (editingDetails: MaterialBasketItemEditingDetails) => {
    if (
      isNil(editingDetails?.itemId) ||
      isNil(editingDetails?.serviceCaseId) ||
      isNil(editingDetails?.serviceOrderId) ||
      isNil(editingDetails?.serviceJobId)
    ) {
      throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
    }

    openDialog(
      <EditMaterialItem
        itemId={editingDetails.itemId}
        serviceCaseId={editingDetails.serviceCaseId}
        serviceOrderId={editingDetails.serviceOrderId}
        serviceJobId={editingDetails.serviceJobId}
        onClose={closeCurrentDialog}
      />,
      {title: editingDetails.itemName}
    );
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {
      serviceOrderIssueNoteItemIds: ids,
    };

    await deleteBasketItems({
      serviceOrderIssueNoteId,
      body: requestBody,
    })
      .unwrap()
      .then(refreshAddedSparePartsDatagrid)
      .catch(handleApiError);
  };

  const actions: Action[] = [
    {
      type: 'button',
      title: i18n.t('general.labels.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isPatchLoading,
      'data-testid': testIds.warehouse.serviceOrderIssueNew('headerActions.discard'),
    },
    {
      type: 'button',
      title: i18n.t('general.labels.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isPatchLoading,
      isDisabled: isNilOrEmpty(basketItems) || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': testIds.warehouse.serviceOrderIssueNew('headerActions.add'),
    },
  ];

  const basketItemsWithTooltip = basketItems?.map((basketItem) => ({
    ...basketItem,
    tooltip: [
      {
        label: i18n.t('entity.warehouse.labels.requestType'),
        value: basketItem.tooltip?.requestType ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.warehouse'),
        value: basketItem.tooltip?.warehouseName ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.manufacturer'),
        value: basketItem.tooltip?.manufacturerName ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.storageLocation'),
        value: basketItem.tooltip?.articleLocation ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.dispensingUnit'),
        value: basketItem.tooltip?.dispensingUnit?.toString() ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.discountRate'),
        value: basketItem.tooltip?.discountRate ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.currentMargin'),
        value: basketItem.tooltip?.currentMargin ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.purchasePrice'),
        value: basketItem.tooltip?.purchasePrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.recommendedPrice'),
        value: basketItem.tooltip?.recommendedPrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.baseSalesPrice'),
        value: basketItem.tooltip?.saleBasePrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.additionalDescription'),
        value: basketItem.tooltip?.additionalDescription ?? EMPTY_PLACEHOLDER,
      },
    ],
  }));

  return (
    <DataStatus isLoading={isLoading} isError={hasError} minHeight="100%">
      <Helmet title={i18n.t('page.warehouse.labels.newServiceOrderIssue')} />
      <Page
        header={
          <EntityHeader
            data-testid={testIds.workshop.createServiceCase('header')}
            title={i18n.t('page.warehouse.labels.newServiceOrderIssue')}
            icon="custom/warehouse"
            actions={actions}
            parameters={[serviceOrder?.number, serviceOrder?.name]}
            isTagDeletable
            isTagUpdatable
            isTagReadable
            isTagCreatable
            isTagAssignable
            isTagUnassignable
          />
        }
        data-testid={testIds.warehouse.serviceOrderIssueNew('newServiceOrderIssue')}
      >
        <Box flex={1} height="85vh">
          <Box padding={4} height="100%">
            <HStack spacing={4} height="100%">
              <Box flex={5}>
                <MaterialList
                  serviceOrderIssueNote={serviceOrderIssueNote}
                  addedSparePartsDatagridRef={addedSparePartsDatagridRef}
                  isBasketEmpty={isNilOrEmpty(basketItems)}
                  data-testid={testIds.warehouse.serviceOrderIssueNew('addMaterial-list')}
                />
              </Box>
              <Box flex={1} minWidth={86}>
                <AfterSalesMaterialBasket<MaterialBasketItemWithTooltip>
                  basket={{
                    items: basketItemsWithTooltip,
                    totalPrice: basketTotalPrice,
                    isLoading: isBasketLoading,
                    hasError: hasBasketError,
                    hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                    isDeletingItems: isDeletingBasketItems,
                  }}
                  mechanic={{
                    mechanics,
                    assignedMechanic,
                    mechanicError,
                    isMechanicRequired: true,
                    onMechanicChange: handleMechanicChange,
                  }}
                  jobChange={{
                    canPerformJobChange: true,
                    serviceCaseId,
                    serviceOrderId,
                  }}
                  onQuantityChange={handleQuantityChange}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  data-testid={testIds.warehouse.serviceOrderIssueNew('addMaterial-basket')}
                />
              </Box>
            </HStack>
          </Box>
        </Box>
      </Page>
    </DataStatus>
  );
}
