import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack, Grid} from 'platform/foundation';
import {date, object} from 'yup';

import {useEffect, useState} from 'react';
import {UseFormReset} from 'react-hook-form';

import {defaultTo, isNotNil} from 'ramda';
import {noop} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  useGetVehicleInsuranceQuery,
  useSetVehicleInsuranceMutation,
} from '@omnetic-dms/shared';

import {yupString, getApiDateString, parseDate, suffixTestId, RequiredTestIdProps} from 'shared';

type FleetInsuranceFormValues = {
  fleetInsuranceDate: Date | null;
  fleetInsuranceNote: string | null;
};

interface FleetInsuranceProps extends RequiredTestIdProps {
  vehicleId: string;
  isDisabled?: boolean;
}

export function FleetInsurance(props: FleetInsuranceProps) {
  const {
    data: insuranceData,
    isLoading: isInsuranceDataLoading,
    isError,
    error,
  } = useGetVehicleInsuranceQuery({vehicleId: props.vehicleId});
  const [setInsuranceData] = useSetVehicleInsuranceMutation();
  const [hasFleetInsurance, setHasFleetInsurance] = useState(false);

  useEffect(() => {
    setHasFleetInsurance(isNotNil(insuranceData?.fleetInsurance));
  }, [insuranceData?.fleetInsurance]);

  const defaultValues = {
    fleetInsuranceDate: parseDate(insuranceData?.fleetInsurance?.date),
    fleetInsuranceNote: defaultTo(null, insuranceData?.fleetInsurance?.note),
  };

  const onSubmit: FormSubmitHandler<FleetInsuranceFormValues> = (values) =>
    setInsuranceData({
      vehicleId: props.vehicleId,
      data: {
        fleetInsurance: {
          date: isNotNil(values.fleetInsuranceDate)
            ? getApiDateString(values.fleetInsuranceDate)
            : null,
          note: values.fleetInsuranceNote,
        },
        customerInsurance: defaultTo(null, insuranceData?.customerInsurance),
      },
    })
      .unwrap()
      .catch(handleApiError);

  const handleDeleteFleetInsurance = (reset: UseFormReset<FleetInsuranceFormValues>) =>
    setInsuranceData({
      vehicleId: props.vehicleId,
      data: {
        fleetInsurance: null,
        customerInsurance: defaultTo(null, insuranceData?.customerInsurance),
      },
    })
      .unwrap()
      .then(() => reset({fleetInsuranceNote: null, fleetInsuranceDate: null}))
      .catch(handleApiError);

  return (
    <DataStatus
      isLoading={isInsuranceDataLoading}
      isError={isError && (error as FetchBaseQueryError)?.status !== 404}
    >
      <Form<FleetInsuranceFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        schema={fleetInsuranceFormSchema}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Card
              variant="inlineGrey"
              title={i18n.t('entity.vehicle.labels.fleetInsurance')}
              control={{
                type: 'switch',
                isDisabled: props.isDisabled,
                onChange: (hasFleetInsurance) => {
                  hasFleetInsurance
                    ? onSubmit(
                        {fleetInsuranceNote: null, fleetInsuranceDate: null},
                        noop,
                        formApi.reset
                      )
                    : handleDeleteFleetInsurance(formApi.reset);
                  setHasFleetInsurance(hasFleetInsurance);
                },
                value: hasFleetInsurance,
              }}
              isExpanded={hasFleetInsurance}
            >
              <VStack spacing={4}>
                <Grid columns={3}>
                  <FormField
                    data-testid={suffixTestId('dateInput', props)}
                    label={i18n.t('entity.vehicle.labels.fleetInsuranceDate')}
                    name="fleetInsuranceDate"
                    control={control}
                    isDisabled={props.isDisabled}
                    type="date"
                  />
                </Grid>
                <FormField
                  data-testid={suffixTestId('textAreaInput', props)}
                  label={i18n.t('general.labels.note')}
                  name="fleetInsuranceNote"
                  control={control}
                  type="textarea"
                  isResizable={true}
                  isDisabled={props.isDisabled}
                />
                <ButtonGroup align="right">
                  <FormButton
                    data-testid={suffixTestId('submitButton', props)}
                    title={i18n.t('general.actions.save')}
                    type="submit"
                    control={control}
                    isDisabled={props.isDisabled}
                  />
                </ButtonGroup>
              </VStack>
            </Card>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const fleetInsuranceFormSchema = object({
  fleetInsuranceDate: date().nullable(),
  fleetInsuranceNote: yupString.max(255),
});
