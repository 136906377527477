import {DataStatus} from 'platform/components';
import {Box, Space, Stack, Text, VStack} from 'platform/foundation';

import {testIds} from '@omnetic-dms/routes';

import {useGetVehicleCustomerQuery} from '../../../api/metadaVehicleCustomer';
import i18n from '../../../i18n/i18n';
import {CustomerResponseBodyV2} from '../../../types/api/customer';
import {OwnershipType} from '../../CustomerVehicleSelection/types/ownershipType';
import {OwnershipCustomerCard} from './OwnershipCustomerCard';

interface OwnershipProps {
  vehicleId: string;
  onOwnershipChange: (customerType: OwnershipType) => void;
  onDelete: (customerType: OwnershipType) => void;
  onCopy: (customer: CustomerResponseBodyV2) => void;
  onMove: (excludedOwnership: OwnershipType) => (customer: CustomerResponseBodyV2) => void;
}

export function Ownership(props: OwnershipProps) {
  const {isLoading: isVehicleCustomerLoading, isError: isVehicleCustomerError} =
    useGetVehicleCustomerQuery({vehicleId: props.vehicleId}, {skip: !props.vehicleId});

  return (
    <>
      <Text color="secondary" size="small">
        {i18n.t('entity.ownership.label.ownershipInfo')}
      </Text>
      <Space vertical={4} />
      <DataStatus
        isLoading={isVehicleCustomerLoading}
        isError={isVehicleCustomerError}
        minHeight={100}
      >
        <Stack direction={['column', 'column', 'column', 'row']} spacing={4}>
          <Box flex={1}>
            <OwnershipCustomerCard
              vehicleId={props.vehicleId}
              ownershipType="CORVEHICLECUSTOMERTYPE_OPERATOR"
              onChange={() => props.onOwnershipChange('CORVEHICLECUSTOMERTYPE_OPERATOR')}
              onCopy={props.onCopy}
              data-testid={testIds.workshop.serviceCaseDetail('ownership-operator')}
            />
          </Box>
          <Box flex={1}>
            <VStack spacing={4}>
              <OwnershipCustomerCard
                vehicleId={props.vehicleId}
                ownershipType="CORVEHICLECUSTOMERTYPE_USER"
                onDelete={() => props.onDelete('CORVEHICLECUSTOMERTYPE_USER')}
                onChange={() => props.onOwnershipChange('CORVEHICLECUSTOMERTYPE_USER')}
                onMove={props.onMove('CORVEHICLECUSTOMERTYPE_USER')}
                data-testid={testIds.workshop.serviceCaseDetail('ownership-user')}
              />
              <OwnershipCustomerCard
                vehicleId={props.vehicleId}
                ownershipType="CORVEHICLECUSTOMERTYPE_OWNER"
                onDelete={() => props.onDelete('CORVEHICLECUSTOMERTYPE_OWNER')}
                onChange={() => props.onOwnershipChange('CORVEHICLECUSTOMERTYPE_OWNER')}
                onMove={props.onMove('CORVEHICLECUSTOMERTYPE_OWNER')}
                data-testid={testIds.workshop.serviceCaseDetail('ownership-owner')}
              />
              <OwnershipCustomerCard
                vehicleId={props.vehicleId}
                ownershipType="CORVEHICLECUSTOMERTYPE_MANAGER"
                onDelete={() => props.onDelete('CORVEHICLECUSTOMERTYPE_MANAGER')}
                onChange={() => props.onOwnershipChange('CORVEHICLECUSTOMERTYPE_MANAGER')}
                onMove={props.onMove('CORVEHICLECUSTOMERTYPE_MANAGER')}
                data-testid={testIds.workshop.serviceCaseDetail('ownership-manager')}
              />
            </VStack>
          </Box>
        </Stack>
      </DataStatus>
    </>
  );
}
