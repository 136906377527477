import {yupResolver} from '@hookform/resolvers/yup';
import {showNotification} from 'platform/components';
import {SchemaOf, object, string} from 'yup';

import {ChangeEvent, FC, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {usePatchCurrentUserMutation, PatchCurrentUserApiArg} from '@omnetic-dms/shared';
import {TextField, getStringErrorMessage} from '@omnetic-dms/teas';

type Props = {
  fieldName: string;
  label: string;
  value: string;
  disabled?: boolean;
  required?: boolean;
};

export const NameField: FC<Props> = ({fieldName, label, value, disabled, required = false}) => {
  const [patchUser] = usePatchCurrentUserMutation();

  const {
    formState: {errors},
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(nameValidationSchema(fieldName, label)),
    mode: 'onChange',
  });

  useEffect(() => {
    setValue(fieldName, value);
  }, [value, setValue, fieldName]);

  const hasError = Boolean(errors[fieldName]?.message);

  const dispatcher = async (val: string): Promise<void> => {
    if (value === val) {
      return;
    }

    if (hasError) {
      return;
    }

    try {
      const arg = {
        updateCurrentUserRequestBody: {
          [fieldName]: val,
        },
      } as PatchCurrentUserApiArg;

      const res = await patchUser(arg).unwrap();

      if (res && res.id) {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      }
    } catch (error: any) {
      showNotification.error(getStringErrorMessage(error));
    }
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({field: {onChange, ...rest}}) => (
        <TextField
          {...rest}
          required={required}
          name={`my-${fieldName}`}
          label={label}
          data-testid={testIds.myProfile.personalInformation(fieldName)}
          disabled={disabled}
          error={hasError}
          helperText={String(errors[fieldName]?.message ?? '') ?? undefined}
          onChange={(e) => onChange(removeSpace(e))}
          onBlur={(e) => dispatcher(e.target.value)}
        />
      )}
    />
  );
};

const removeSpace = (e: ChangeEvent<HTMLInputElement>) => e.target.value.replace(/\s+/g, ' ');

const firstNameLastNameValidation = (field: string, error_message: string) =>
  string()
    .required(`${field} ${i18n.t('general.notifications.errorSpecFieldRequired')}`)
    .matches(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF-\s.]+$/i, {
      message: error_message,
    })
    .max(60, error_message);

const nameValidationSchema = (fieldName: string, label: string): SchemaOf<Record<string, string>> =>
  object()
    .shape({
      firstName: firstNameLastNameValidation(
        label,
        i18n.t('general.notifications.invalidFirstName')
      ),
      lastName: firstNameLastNameValidation(label, i18n.t('general.notifications.invalidLastName')),
    })
    .pick([fieldName]);
