import {showNotification} from 'platform/components';

import {defaultTo, find, propEq} from 'ramda';

import {TestIdProps} from 'shared';

import {useGetCurrentUserInfoQuery} from '../../../api/userApi';
import {
  useGetServiceVehicleHomeBranchQuery,
  usePutServiceVehicleHomeBranchMutation,
} from '../../../api/vehicleApi';
import {handleApiError} from '../../../utils/handleApiError';
import {BranchChanger} from './BranchChanger';

export interface ServiceBranchProps extends TestIdProps {
  recordId: string;
}

export function ServiceBranch(props: ServiceBranchProps) {
  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();

  const homeBranchQuery = useGetServiceVehicleHomeBranchQuery({vehicleId: props.recordId});
  const [updateHomeBranch] = usePutServiceVehicleHomeBranchMutation();

  const handleUpdateVehicleBranch = (branchId: string) =>
    updateHomeBranch({vehicleId: props.recordId, body: {branchId}})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const activeBranch = find(
    propEq(homeBranchQuery.data?.branchId, 'id'),
    defaultTo([], currentUserInfo?.branches)
  );

  return (
    <BranchChanger
      recordId={props.recordId}
      activeBranch={activeBranch}
      onChange={handleUpdateVehicleBranch}
      data-testid={props['data-testid']}
    />
  );
}
