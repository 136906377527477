import {Switch} from 'platform/components';

import {
  useDisablePublishCebiaSmartCodeUrlMutation,
  useEnablePublishCebiaSmartCodeUrlMutation,
  useGetPublishCebiaSmartCodeUrlConfigQuery,
  handleApiError,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

interface CebiaSmartCodeUrlPublishSwitchProps extends TestIdProps {
  vehicleId: string;
  label?: string | Nullish;
}

export function CebiaSmartCodeUrlPublishSwitch(props: CebiaSmartCodeUrlPublishSwitchProps) {
  const {data} = useGetPublishCebiaSmartCodeUrlConfigQuery({vehicleId: props.vehicleId});
  const [enablePublishCebiaSmartCodeUrl] = useEnablePublishCebiaSmartCodeUrlMutation();
  const [disablePublishCebiaSmartCodeUrl] = useDisablePublishCebiaSmartCodeUrlMutation();

  const onChange = (value: boolean) => {
    if (value) {
      enablePublishCebiaSmartCodeUrl({vehicleId: props.vehicleId}).unwrap().catch(handleApiError);
    } else {
      disablePublishCebiaSmartCodeUrl({vehicleId: props.vehicleId}).unwrap().catch(handleApiError);
    }
  };

  return (
    <Switch
      data-testid={suffixTestId('cebiaAutotracerWidget-publishSwitch', props)}
      isDisabled={!data}
      label={props.label}
      value={!!data?.publishCebiaSmartCodeUrl}
      onChange={onChange}
    />
  );
}
