import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {date, object} from 'yup';

import i18n from '@omnetic-dms/i18n';
import {handleApiError, useLazyGetDeliveryNoteManualDownloadQuery} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type DownloadNewFormType = {
  dateFrom: Date;
};

interface DownloadNewProps extends RequiredTestIdProps {
  onSave: () => void;
  onDiscard: () => void;
}

export function DownloadNew(props: DownloadNewProps) {
  const [downloadPdf] = useLazyGetDeliveryNoteManualDownloadQuery();

  const handleSubmit: FormSubmitHandler<DownloadNewFormType> = async (data) => {
    await downloadPdf({params: {dateFrom: data.dateFrom.toISOString()}})
      .unwrap()
      .then(props.onSave)
      .catch(handleApiError);
  };

  const defaultValues: Partial<DownloadNewFormType> = {
    dateFrom: new Date(),
  };

  return (
    <Form<DownloadNewFormType>
      defaultValues={defaultValues}
      schema={formSchema}
      onSubmit={handleSubmit}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <FormField
            isRequired
            control={control}
            type="date"
            name="dateFrom"
            label={i18n.t('page.warehouse.labels.downloadDocumentsSinceDay')}
            data-testid={suffixTestId('cancel', props)}
          />
          <ButtonGroup align="right">
            <Button
              data-testid={suffixTestId('cancel', props)}
              variant="secondary"
              onClick={props.onDiscard}
              title={i18n.t('general.actions.discard')}
            />
            <FormButton
              data-testid={suffixTestId('submit', props)}
              control={control}
              type="submit"
              title={i18n.t('general.actions.confirm')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const formSchema = object({
  dateFrom: date().required(),
});
