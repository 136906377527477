import {isEmpty, isNil} from 'ramda';

import {Nullish} from 'shared';

import {DrillDownLevelName, DrillDownStatistics} from '../../../types/api/drillDown';

export function isRowSelected(
  data: DrillDownStatistics,
  selectedRow: Partial<Record<DrillDownLevelName, string>> | Nullish
) {
  if (isNil(selectedRow) || isEmpty(selectedRow)) {
    return false;
  }

  return data.levels.findIndex((item) => item.value !== selectedRow[item.name]) < 0;
}
