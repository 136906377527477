import {Attributes} from 'platform/components';

import {FC} from 'react';

import {PersonResponseBodyV2, useCustomerPersonAttributes} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

export interface PersonInfoProps {
  person?: PersonResponseBodyV2 | null;
  showRole?: boolean;
  showEmails?: boolean;
  showPhoneNumbers?: boolean;
  showPersonalIdentifier?: boolean;
  selfEmployed?: boolean | null;
}

export const PersonInfo: FC<PersonInfoProps & TestIdProps> = ({
  person,
  showRole,
  showEmails,
  showPhoneNumbers,
  selfEmployed,
  showPersonalIdentifier = true,
  ...rest
}) => {
  const [getAttributes] = useCustomerPersonAttributes();

  if (!person) {
    return null;
  }

  return (
    <Attributes
      data-testid={suffixTestId('personInfo', rest)}
      rows={getAttributes(person, {
        isRoleHidden: !showRole,
        isEmailsHidden: !showEmails,
        isPhoneNumbersHidden: !showPhoneNumbers,
        isPersonalIdentifierHidden: !showPersonalIdentifier,
      })}
      size="quarter"
    />
  );
};
