import {
  GetWorkSplittingBulkApiArg,
  GetWorkSplittingBulkApiArgSchema,
  GetWorkSplittingBulkApiResponse,
  GetWorkSplittingBulkApiResponseSchema,
  PutWorkSplittingBulkApiArg,
  PutWorkSplittingBulkApiArgSchema,
  PutWorkSplittingBulkApiResponse,
  PutWorkSplittingBulkApiResponseSchema,
} from '../types/api/metadaWorkshopWorkSplitting';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopWorkSplittingApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkSplittingBulk: build.query<GetWorkSplittingBulkApiResponse, GetWorkSplittingBulkApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/work-splitting-bulk`,
        params: {itemId: queryArg.itemId},
        queryArg,
      }),
      providesTags: ['workSplitting'],
      extraOptions: {
        responseSchema: GetWorkSplittingBulkApiResponseSchema,
        requestSchema: GetWorkSplittingBulkApiArgSchema,
      },
    }),
    putWorkSplittingBulk: build.mutation<
      PutWorkSplittingBulkApiResponse,
      PutWorkSplittingBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/work-splitting-bulk`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['workBasketItem', 'workSplitting'],
      extraOptions: {
        responseSchema: PutWorkSplittingBulkApiResponseSchema,
        requestSchema: PutWorkSplittingBulkApiArgSchema,
      },
    }),
  }),
});

export const {useGetWorkSplittingBulkQuery, usePutWorkSplittingBulkMutation} =
  metadaWorkshopWorkSplittingApi;
