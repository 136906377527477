import {Box, HStack, Space, VStack} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {selectBranchList} from '@omnetic-dms/shared';
import {
  FormLabel,
  TypographyCard,
  selectPaymentTypes,
  selectDocumentRoundingDefinitionDetail,
} from '@omnetic-dms/teas';

import {ValidityItemDetail} from './ValidityItemDetail';

const DocumentRoundingDefinitionsDetail: FC = () => {
  const {data: {branchListItems: branchList} = {}} = useSelector(selectBranchList);
  const paymentTypes = useSelector(selectPaymentTypes);
  const detail = useSelector(selectDocumentRoundingDefinitionDetail);

  return (
    <Box maxWidth={206}>
      <TypographyCard title={i18n.t('page.accounting.labels.definition')}>
        <HStack spacing={4}>
          <Box flex={1}>
            <FormLabel>{i18n.t('entity.branch.labels.branch')}</FormLabel>
            {branchList?.find((item) => item.id === detail?.branchId)?.marketingName ||
              i18n.t('general.labels.none')}
          </Box>
          <Box flex={1}>
            <FormLabel>{i18n.t('entity.bank.labels.paymentType')}</FormLabel>
            {paymentTypes.find((item) => item.key === detail?.paymentType)?.value ||
              i18n.t('general.labels.none')}
          </Box>
        </HStack>
        <Space vertical={2} />
        <VStack spacing={2}>
          {detail?.validities.map((item) => (
            <ValidityItemDetail item={item} key={`${item.validFrom}-${item.roundType}`} />
          ))}
        </VStack>
      </TypographyCard>
    </Box>
  );
};

export const DocumentRoundingDefinitionDetail = DocumentRoundingDefinitionsDetail;
