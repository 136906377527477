import {ReactNode} from 'react';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/shared';

export enum VehiclesSliceName {
  classifieds = 'sourcing/vehicles/classifieds',
}

export type SourcingLoadingStates = {
  vehicles: boolean;
  hidden: boolean;
  cart: boolean;
  favourite: boolean;
  comparison: boolean;
  pushingToSale: boolean;
  exporting: boolean;
};

export type LoadingState = keyof SourcingLoadingStates;

export type VehiclesMap<V = SourcingVehicleDetailResponseBody> = Record<string, V>;

export type EditingVehicle = {
  hidden: boolean;
  cart: boolean;
  favourite: boolean;
  comparison: boolean;
};

export type EditingVehicleState = keyof EditingVehicle;

export type VehicleSliceState<V = SourcingVehicleDetailResponseBody> = {
  vehicles: VehiclesMap<V>;
  hasMoreData: boolean;
  isLoading: SourcingLoadingStates;
  filteredCount: number;
  editingVehicle: Record<string, EditingVehicle>;
};

export type EditVehicleRequestPayload = {
  id: string | string[];
  successMessage?: ReactNode;
};

export type EditVehicleIds = string | string[];

export type PushVehiclesToSaleRequestPayload = {
  ids: string[];
  successMessage?: ReactNode;
};

export type ExportVehicleListRequestPayload = {
  ids: string[];
  buyerCountry?: string;
  currency?: string;
  margin?: string;
  orderBy?: string;
};
