import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {PaymentTypeResponseBody} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getOptionsFromPaymentTypes = (
  paymentTypes: PaymentTypeResponseBody[] | Nullish
): Option[] => {
  if (isNil(paymentTypes)) {
    return [];
  }

  return paymentTypes.map((paymentType) => ({
    label: paymentType.value,
    value: paymentType.key,
  }));
};
