import {DataStatus} from 'platform/components';
import {Icon, Video} from 'platform/foundation';
import {css} from 'styled-components';
import {match} from 'ts-pattern';

import {MouseEventHandler, useReducer, useRef} from 'react';

import {isNil, isNotNil} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {LightboxVideo} from '../types/LightboxVideo';

interface VideoItemProps extends RequiredTestIdProps {
  video: LightboxVideo;
}

export function VideoItem(props: VideoItemProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [state, dispatch] = useReducer(fetchVideoReducer, fetchVideoInitialState);

  const onPlayClick: MouseEventHandler<HTMLDivElement> | undefined = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch({type: 'LOADING'});
    props.video
      .handleFetchVideo()
      .then((url) => {
        if (isNil(url)) {
          dispatch({type: 'ERROR'});
        } else {
          dispatch({type: 'SUCCESS', payload: url});
        }
      })
      .then(() => {
        /**
         * short wait before component flips state
         * which turned out to be a lot less logical than checking whether the video loaded or not
         */
        setTimeout(
          () =>
            videoRef.current?.play().catch(() => {
              dispatch({type: 'ERROR'});
            }),
          FLIP_STATE_TIMEOUT
        );
      })
      .catch(() => {
        dispatch({type: 'ERROR'});
      });
  };

  return (
    <div
      css={css`
        cursor: pointer;
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({theme}) => theme.colors.palettes.neutral['10']['40']};
        overflow: hidden;
      `}
      onClick={onPlayClick}
    >
      <DataStatus isError={state.isErrored}>
        {isNotNil(state.url) && state.isSucceeded ? (
          <Video ref={videoRef} data-testid={suffixTestId('videoItem', props)} src={state.url} />
        ) : (
          <Icon
            data-testid={suffixTestId('videoItem-empty', props)}
            value="AV/play_arrow"
            size={15}
          />
        )}
      </DataStatus>
    </div>
  );
}

type FetchVideoAction = {type: 'LOADING'} | {type: 'SUCCESS'; payload: string} | {type: 'ERROR'};

type FetchVideoState = {
  isLoading: boolean;
  isErrored: boolean;
  isSucceeded: boolean;
  url: string | null;
};

const fetchVideoInitialState: FetchVideoState = {
  isLoading: false,
  isErrored: false,
  isSucceeded: false,
  url: null,
};

const fetchVideoReducer = (state: FetchVideoState, action: FetchVideoAction): FetchVideoState =>
  match(action)
    .with({type: 'LOADING'}, () => ({
      ...state,
      isLoading: true,
      isErrored: false,
      isSucceeded: false,
    }))
    .with({type: 'SUCCESS'}, ({payload}) => ({
      ...state,
      isLoading: false,
      isErrored: false,
      isSucceeded: true,
      url: payload,
    }))
    .with({type: 'ERROR'}, () => ({
      ...state,
      isLoading: false,
      isSucceeded: false,
      isErrored: true,
    }))
    .otherwise(() => state);

const FLIP_STATE_TIMEOUT = 500;
