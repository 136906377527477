import {
  GetCentralizedPricingArg,
  GetCentralizedPricingRes,
  PostCentralizedPricingArg,
  PostCentralizedPricingRes,
} from '../types/api/api';
import {omneticApi} from './baseApi/omneticApi';

export const centralizedPricing = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getCentralizedPricing: build.query<GetCentralizedPricingRes, GetCentralizedPricingArg>({
      query: () => ({
        url: `/dms/v1/settings/centralized-pricer-setting/tenant`,
      }),
      providesTags: ['CentralizedPricing'],
    }),
    postCentralizedPricing: build.mutation<PostCentralizedPricingRes, PostCentralizedPricingArg>({
      query: (queryArgs) => ({
        url: `/dms/v1/settings/centralized-pricer-setting/tenant`,
        method: 'POST',
        body: {...queryArgs, force: false},
      }),
      invalidatesTags: ['CentralizedPricing'],
    }),
  }),
});

export const {useGetCentralizedPricingQuery, usePostCentralizedPricingMutation} =
  centralizedPricing;
