import {convertToSearchParams} from 'platform/datagrid';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useCreateDataQueryQuery} from '../api/datagridApi';
import {FilterValues, OrderByRequestBody, SmartSearch} from '../types/api/datagridV6';

export function useDataQuery(
  code: string,
  options?: {
    filters?: FilterValues;
    order?: OrderByRequestBody[];
    smartSearch?: SmartSearch;
  }
) {
  const {data, ...rest} = useCreateDataQueryQuery({
    code,
    createDataQueryRequestBody: {
      filters: options?.filters ?? {},
      order: options?.order ?? [],
      smartSearch: options?.smartSearch ?? null,
    },
  });

  let queryParams: ReturnType<typeof convertToSearchParams> | undefined = undefined;
  if (data?.dataQueryId && !isNilOrEmpty(options)) {
    queryParams = convertToSearchParams({
      dataGrids: {
        gridCode: code,
        queryId: data.dataQueryId,
        rowIndex: '1',
      },
    });
  }

  return {
    gridCode: code,
    dataQueryId: data?.dataQueryId,
    queryParams,
    ...rest,
  };
}
