import {Card, showNotification} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  catchUnhandledDataGridActions,
  usePatchArticleDefaultSupplierMutation,
  usePatchArticleInfoMutation,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {CatalogFilter} from '../../../../../types/CatalogFilter';

type CastedRowData = {
  name: {value: string};
  supplierId: {value: string};
};

interface OverviewCatalogsInformationProps extends TestIdProps {
  catalogFilter: CatalogFilter;
}

export function OverviewCatalogsInformation(props: OverviewCatalogsInformationProps) {
  const {articleId} = useWarehouseParams();

  const [updateArticleInfo] = usePatchArticleInfoMutation();
  const [setDefaultSupplier] = usePatchArticleDefaultSupplierMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    const castedRowData = rowData as CastedRowData;

    if (isNil(id)) {
      throw new Error('Row id is not defined');
    }

    match(actionKey)
      .with('updateArticle', () => {
        updateArticleInfo({articleId, body: {name: castedRowData.name.value}})
          .unwrap()
          .then(() =>
            showNotification.success(i18n.t('entity.warehouse.notifications.articleInfoUpdated'))
          )
          .then(refreshData);
      })
      .with('setAsDefault', () => {
        setDefaultSupplier({articleId, body: {supplierId: castedRowData.supplierId.value}})
          .unwrap()
          .then(() =>
            showNotification.success(i18n.t('entity.warehouse.notifications.defaultSupplierSet'))
          )
          .then(refreshData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          branchId: props.catalogFilter.branchId,
          supplierId: props.catalogFilter.supplierId,
          manufacturerId: props.catalogFilter.manufacturerId,
          oemCode: props.catalogFilter.manufacturerNumber,
        },
      ]),
    [
      props.catalogFilter.branchId,
      props.catalogFilter.supplierId,
      props.catalogFilter.manufacturerId,
      props.catalogFilter.manufacturerNumber,
    ]
  );

  return (
    <Card title={i18n.t('entity.warehouse.labels.catalogsInformation')}>
      <DataGrid
        autoHeight
        gridCode="warehouse-article-catalogs-info"
        actionCallback={actionCallback}
        queryModifier={queryModifier}
        data-testid={suffixTestId('datagrid', props)}
      />
    </Card>
  );
}
