import {Action, Attributes, AttributesRow, Card, DataStatus, Separator} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {
  GetReceiveNoteItemNewResponse,
  GetReceiveNoteItemRefreshedResponse,
  GetReceiveNoteItemResponse,
  useGetManufacturerQuery,
  useGetTreeFolderPathQuery,
  useGetWarehouseAccountQuery,
} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, composePath, suffixTestId} from 'shared';

import {ArticleDetailCardPrices} from '../../../../../components/ArticleDetailCardPrices';
import {getArticleDetailCardPrices} from '../../../../../utils/getArticleDetailCardPrices';

interface ArticleDetailCardProps extends TestIdProps {
  articleId: string | Nullish;
  manufacturerNumber: string | Nullish;
  receiveNoteItem: GetReceiveNoteItemResponse | Nullish;
  receiveNoteItemNew: GetReceiveNoteItemNewResponse | Nullish;
  receiveNoteItemRefreshed: GetReceiveNoteItemRefreshedResponse | Nullish;
  currency: string | undefined;
  isDisabled: boolean;
  isExpanded: boolean;
  onRefresh: () => Promise<void>;
}

export function ArticleDetailCard(props: ArticleDetailCardProps) {
  const warehouseId =
    props.receiveNoteItemNew?.article?.warehouseId ??
    props.receiveNoteItem?.article?.warehouseId ??
    props.receiveNoteItemRefreshed?.article?.warehouseId;

  const name =
    props.receiveNoteItemNew?.article?.name ??
    props.receiveNoteItem?.article?.name ??
    props.receiveNoteItemRefreshed?.article?.name;

  const manufacturerId =
    props.receiveNoteItemNew?.article?.manufacturerId ??
    props.receiveNoteItem?.article?.manufacturerId ??
    props.receiveNoteItemRefreshed?.article?.manufacturerId;

  const warehouseAccountId =
    props.receiveNoteItemNew?.article?.warehouseAccount ??
    props.receiveNoteItem?.article?.warehouseAccount ??
    props.receiveNoteItemRefreshed?.article?.warehouseAccount;

  const storageLocation =
    props.receiveNoteItemNew?.article?.storageLocation ??
    props.receiveNoteItem?.article?.storageLocation ??
    props.receiveNoteItemRefreshed?.article?.storageLocation;

  const leafId =
    props.receiveNoteItemNew?.article?.treeFolder?.leafId ??
    props.receiveNoteItem?.article?.treeFolder?.leafId ??
    props.receiveNoteItemRefreshed?.article?.treeFolder?.leafId;

  const {data: manufacturer, isLoading: isManufacturerLoading} = useGetManufacturerQuery(
    {manufacturerId},
    {skip: isNilOrEmpty(manufacturerId)}
  );
  const {data: warehouseAccount, isLoading: isWarehouseAccountLoading} =
    useGetWarehouseAccountQuery(
      {warehouseAccountId: warehouseAccountId as string},
      {skip: isNilOrEmpty(warehouseAccountId)}
    );
  const {data: category, isLoading: isCategoryLoading} = useGetTreeFolderPathQuery(
    {leafId: leafId as string},
    {skip: isNilOrEmpty(leafId)}
  );

  const isLoading = isManufacturerLoading || isWarehouseAccountLoading || isCategoryLoading;

  const isItemExisting =
    isNotNil(props.receiveNoteItem?.article) ||
    isNotNil(props.receiveNoteItemNew?.article) ||
    isNotNil(props.receiveNoteItemRefreshed?.article);

  const handleEditArticle = () => {
    window.open(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {warehouseId, id: props.articleId},
      }),
      '_blank'
    );
  };

  const prices = getArticleDetailCardPrices(
    props.receiveNoteItemNew?.article ??
      props.receiveNoteItem?.article ??
      props.receiveNoteItemRefreshed?.article
  );

  const actions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('general.actions.refresh'),
      leftIcon: 'navigation/refresh',
      onClick: props.onRefresh,
    },
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('entity.warehouse.actions.editArticle'),
      leftIcon: 'action/open_in_new',
      onClick: handleEditArticle,
    },
  ];

  const rows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.name'),
      value: name,
    },
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: props.manufacturerNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: manufacturer?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouseAccount'),
      value: warehouseAccount?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.storageLocation'),
      value: storageLocation,
    },
    {
      label: i18n.t('entity.warehouse.labels.category'),
      value: category?.treeFolder?.pathName,
    },
  ];

  return (
    <Card
      variant="inlineGrey"
      title={i18n.t('entity.warehouse.labels.articleDetail')}
      actions={isItemExisting ? actions : undefined}
      isClosedByDefault={props.isDisabled}
      isExpandable={props.isExpanded}
      data-testid={props['data-testid']}
    >
      <VStack>
        <DataStatus isLoading={isLoading} minHeight={49}>
          <Attributes rows={rows} data-testid={suffixTestId('articleDetailAttributes', props)} />
        </DataStatus>
        <Separator spacing={3} />
        <ArticleDetailCardPrices prices={prices} currency={props.currency} />
      </VStack>
    </Card>
  );
}
