import {
  Action,
  Button,
  closeCurrentDialog,
  DataStatus,
  FormSubmitHandler,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Heading, HStack, VStack} from 'platform/foundation';
import {formatPhoneNumber} from 'platform/locale';

import {always, ifElse, pathOr, pipe} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {
  useCreateCustomerContactMutation,
  usePatchCustomerContactMutation,
  useDeleteCustomerContactMutation,
} from '../../../api/customerApi';
import {useCustomerPersonAttributes} from '../../../hooks/useCustomerPersonAttributes';
import i18n from '../../../i18n/i18n';
import {
  PersonRequestBody,
  CreateCustomerContactApiArg,
  PatchCustomerContactApiArg,
  CustomerResponseBodyV2,
  PersonResponseBodyV2,
} from '../../../types/api/customer';
import {getNaturalPersonFullName} from '../../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../../utils/handleApiError';
import {InfoCard} from '../../InfoCard/InfoCard';
import {SecondStepComponentType} from '../types/SecondStepComponentType';
import {ContactPersonForm} from './ContactPersonForm';

interface CustomerContactPersonProps extends TestIdProps {
  secondStepComponentType: SecondStepComponentType;
  customer: CustomerResponseBodyV2;
}

export function CustomerContactPerson(props: CustomerContactPersonProps) {
  const [getAttributes] = useCustomerPersonAttributes(
    props.secondStepComponentType === 'SERVICE_CASE'
  );

  const [createContact, {isLoading: isCreateContactLoading}] = useCreateCustomerContactMutation();
  const [editContact, {isLoading: isEditContactLoading}] = usePatchCustomerContactMutation();
  const [deleteContact] = useDeleteCustomerContactMutation();

  const onCreateSubmit: FormSubmitHandler<PersonRequestBody> = async (values) => {
    if (isNilOrEmpty(values?.phoneNumbers?.[0]?.phoneNumber?.number)) {
      values.phoneNumbers = [];
    }

    if (isNilOrEmpty(values?.emails?.[0]?.email)) {
      values.emails = [];
    }

    if (isNilOrEmpty(values?.identityCards?.[0]?.cardData?.cardNumber)) {
      values.identityCards = [];
    }

    const args: CreateCustomerContactApiArg = {
      customerId: props.customer.id,
      personRequestBody: values,
    };
    await createContact(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.success'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onEditSubmit =
    (contactId: string): FormSubmitHandler<PersonRequestBody> =>
    async (values) => {
      if (isNilOrEmpty(values.phoneNumbers?.[0]?.phoneNumber?.number)) {
        values.phoneNumbers = [];
      }

      if (isNilOrEmpty(values.emails?.[0]?.email)) {
        values.emails = [];
      }

      if (isNilOrEmpty(values?.identityCards?.[0]?.cardData?.cardNumber)) {
        values.identityCards = [];
      }

      const args: PatchCustomerContactApiArg = {
        customerId: props.customer.id,
        contactId,
        personRequestBody: values,
      };
      await editContact(args)
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.success'));
          closeCurrentDialog();
        })
        .catch(handleApiError);
    };

  const onDelete = (contactId: string) =>
    openDeleteDialog({
      onConfirm: () =>
        deleteContact({
          customerId: props.customer.id,
          contactId,
        })
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError),
    });

  const getPhoneNumber = pipe(
    pathOr(null, ['phoneNumbers', 0]),
    ifElse(
      isNotNilOrEmpty,
      (value) => formatPhoneNumber(`${value.prefix}${value.number}`),
      always(i18n.t('entity.customer.labels.noPhoneNumber'))
    )
  );

  const getEmail = pipe(
    pathOr(null, ['emails', 0]),
    ifElse(
      isNotNilOrEmpty,
      (value) => value.email,
      always(i18n.t('entity.customer.labels.noEmail'))
    )
  );

  const getActions = (contact: PersonResponseBodyV2): Action[] => [
    {
      type: 'iconButton',
      severity: 'danger',
      icon: 'action/delete',
      onClick: () => onDelete(contact.id),
    },
    {
      type: 'iconButton',
      icon: 'image/edit',
      onClick: () =>
        openDialog(
          <ContactPersonForm
            customerId={props.customer.id}
            isLoading={isEditContactLoading}
            contact={contact}
            onSubmit={onEditSubmit(contact.id)}
            data-testid={suffixTestId('editContact', props)}
          />,
          {
            title: i18n.t('entity.customer.labels.editContactPerson'),
            withAdditionalFooter: true,
            'data-testid': suffixTestId('editContact', props),
          }
        ),
    },
  ];

  const handleAddClick = () =>
    openDialog(
      <ContactPersonForm
        customerId={props.customer.id}
        isLoading={isCreateContactLoading}
        onSubmit={onCreateSubmit}
        data-testid={suffixTestId('createContact', props)}
      />,
      {
        title: i18n.t('entity.customer.actions.newContactPerson'),
        withAdditionalFooter: true,
        'data-testid': suffixTestId('createContact', props),
      }
    );

  return (
    <>
      <HStack justify="space-between">
        <Heading size={5}>{i18n.t('entity.customer.labels.contactPersons')}</Heading>
        <Button
          title={i18n.t('general.actions.add')}
          variant="link"
          size="small"
          leftIcon="content/add_circle"
          onClick={handleAddClick}
          data-testid={suffixTestId('addContact', props)}
        />
      </HStack>
      <DataStatus
        isEmpty={!props.customer.contacts.length}
        emptyMessage={i18n.t('entity.customer.labels.noContactPerson')}
        minHeight={23}
      >
        <VStack spacing={4}>
          {props.customer.contacts.map((contact: PersonResponseBodyV2, index) => (
            <InfoCard
              key={contact.id}
              title={getNaturalPersonFullName(contact)}
              subTitle={contact.roles}
              description={[getPhoneNumber(contact), getEmail(contact)]}
              actions={getActions(contact)}
              attributes={getAttributes(contact)}
              data-testid={suffixTestId(`contact-${index}`, props)}
              selectedContactId={contact.id}
            />
          ))}
        </VStack>
      </DataStatus>
    </>
  );
}
