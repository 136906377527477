import {openDeleteDialog, openDialog, showNotification} from 'platform/components';
import {Icon} from 'platform/foundation';

import {useCallback, useMemo} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  DocumentSeriesDefinitionResponseBody,
  EMPTY_PLACEHOLDER,
  handleApiError,
  useDeleteDocumentSeriesDefinitionMutation,
  useGetBranchListQuery,
  useGetDocumentSeriesDefinitionListQuery,
} from '@omnetic-dms/shared';
import {Button, LinkButton, SimpleTable, DataType, SeriesTypeEnum} from '@omnetic-dms/teas';

import {convertStringToCamelCase} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ActionButtonsColumn, CardFullWidth} from '../CashRegisters/styles';
import {useSeries} from '../CashRegisters/utils/seriesHook';
import {InvoiceDefinitionForm} from './components/InvoiceDefinitionForm';

export function InvoiceDefinition() {
  const [deleteDefinition] = useDeleteDocumentSeriesDefinitionMutation();
  const {seriesOptions} = useSeries({
    branchId: '',
    seriesType: SeriesTypeEnum.ACCOUNTING_INVOICE,
  });

  const {data: branchList} = useGetBranchListQuery();
  const {data: invoiceDefinitionItems} = useGetDocumentSeriesDefinitionListQuery();

  const createRow = useCallback(
    (row: DocumentSeriesDefinitionResponseBody): DataType => {
      const branchName = branchList?.branchListItems?.find(
        (branch) => branch.id === row?.branchId
      )?.marketingName;
      const seriesName = seriesOptions?.find(
        (series) => series?.value === row?.documentSeriesId
      )?.label;

      return {
        id: row?.id,
        name: <strong>{row?.name ?? EMPTY_PLACEHOLDER}</strong>,
        branch: branchName ?? EMPTY_PLACEHOLDER,
        documentSeries: seriesName ?? EMPTY_PLACEHOLDER,
        invoicingDocumentType: i18n.t(
          `invoicingDocumentType.${convertStringToCamelCase(row?.invoicingDocumentType)}`
        ),
        action: (
          <ActionButtonsColumn onClick={(e) => e.stopPropagation()}>
            <Button
              compact
              icon={<Icon value="image/edit" />}
              onClick={() =>
                openDialog(<InvoiceDefinitionForm definition={row} />, {
                  title: i18n.t('page.accounting.labels.invoiceDefinition'),
                  scrollBehavior: 'outside',
                })
              }
            />
            {row?.isSystem ? null : (
              <Button
                compact
                icon={<Icon value="action/delete" />}
                onClick={() => {
                  if (!row?.isSystem && row?.id) {
                    openDeleteDialog({
                      text: i18n.t('entity.accounting.labels.deleteDefinitionConfirmationText', {
                        name: row?.name ?? '',
                      }),
                      onConfirm: () =>
                        deleteDefinition({documentSeriesDefinitionId: row.id})
                          .unwrap()
                          .then(showNotification.success)
                          .catch(handleApiError),
                    });
                  }
                }}
              />
            )}
          </ActionButtonsColumn>
        ),
      };
    },
    [branchList?.branchListItems, seriesOptions, deleteDefinition]
  );

  const columns = useMemo(
    () => [
      {Header: i18n.t('entity.invoice.labels.name'), id: 'name'},
      {Header: i18n.t('entity.branch.labels.branch'), id: 'branch'},
      {Header: i18n.t('entity.document.labels.seriesTitle'), id: 'documentSeries'},
      {
        Header: i18n.t('entity.document.labels.invoiceType'),
        id: 'invoicingDocumentType',
      },
      {Header: null, id: 'action'},
    ],
    []
  );

  const rows = useMemo(
    () => [{data: (invoiceDefinitionItems || []).map(createRow)}],
    [createRow, invoiceDefinitionItems]
  );

  const handleCreateDefinition = () =>
    openDialog(<InvoiceDefinitionForm definition={null} />, {
      title: i18n.t('page.accounting.labels.invoiceDefinition'),
      scrollBehavior: 'outside',
    });

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accounting.labels.invoiceDefinition')}}
      data-testid="settings-invoice-definition-modal"
    >
      <CardFullWidth
        noPadding
        title={i18n.t('page.accounting.labels.invoiceDefinition')}
        headerContent={
          <LinkButton
            data-testid={testIds.settings.invoiceDefinition('createNew')}
            onClick={handleCreateDefinition}
          >
            <Icon value="content/add" /> {i18n.t('page.accounting.actions.newInvoiceDefinition')}
          </LinkButton>
        }
      >
        <SimpleTable
          noZebra
          showRowDivider
          highlightRowOnHover
          rows={rows}
          columns={columns}
          tdPadding="default"
          onRowClick={(row) => {
            const item = invoiceDefinitionItems?.find(({id}) => id === row?.id) ?? null;
            openDialog(<InvoiceDefinitionForm definition={item} />, {
              title: i18n.t('page.accounting.labels.invoiceDefinition'),
              scrollBehavior: 'outside',
            });
          }}
        />
      </CardFullWidth>
    </SettingsTemplate>
  );
}
