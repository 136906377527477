import {Card, FormSubmitHandler} from 'platform/components';
import {VStack, Heading, Text, Hide, Show} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {ReactNode} from 'react';

import {isNil} from 'ramda';

import {suffixTestId, Nullish, RequiredTestIdProps, CurrencyCodeType} from 'shared';

import {useGetTenantQuery} from '../../api/tenantApi';
import i18n from '../../i18n/i18n';
import {GetCheckoutByContextApiResponse, Invoice} from '../../types/api/metadaCheckout';
import {CheckoutPaymentFormType} from '../../types/CheckoutPaymentFormType';
import {CheckoutInvoiceOverview} from './CheckoutInvoiceOverview';
import {CheckoutPaymentForm} from './CheckoutPaymentForm';

// import CheckoutDepositPayment from './CheckoutDepositPayment';

interface CheckoutPaymentProps extends RequiredTestIdProps {
  checkout: GetCheckoutByContextApiResponse;
  invoice: Invoice | Nullish;
  isIssueButtonDisabled?: boolean;
  issueButtonTooltip?: ReactNode;
  isSubmitButtonDisabled: boolean;
  onIssuePayment: FormSubmitHandler<CheckoutPaymentFormType>;
  onSaveChanges: (data: CheckoutPaymentFormType) => Promise<unknown>;
  customerId?: string | null;
  serviceCaseId?: string | null;
  serviceOrderId?: string | null;
  shouldWatchForUnsavedChanges?: boolean | Nullish;
}

export function CheckoutPayment(props: CheckoutPaymentProps) {
  const formatCurrency = useFormatCurrency();

  const {data} = useGetTenantQuery();

  const currency = data?.currency as CurrencyCodeType | undefined;

  // TODO - temporary state for deposits remove after BE connection - https://carvago.atlassian.net/browse/T20-25703
  // const [tempDeposits, setTempDeposits] = useState<string[] | undefined>();

  // const handleRemoveDeposit = (index: number) => {
  //   if (isNil(tempDeposits)) return;
  //   setTempDeposits(remove(index, 1, tempDeposits));
  // };

  // const handleSwitchDeposit = (value: boolean) => {
  //   setTempDeposits(value ? [v4()] : undefined);
  // };

  // const handleAddDeposit = () => {
  //   if (isNil(tempDeposits)) return;
  //   setTempDeposits([...tempDeposits, v4()]);
  // };

  return (
    <VStack spacing={4}>
      <Heading size={4}>{i18n.t('entity.checkout.labels.payment')}</Heading>
      <Card variant="inlineGrey">
        <VStack spacing={1}>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.order.labels.totalAmount')}
          </Text>
          <Heading size={3} data-testid={suffixTestId('totalPrice', props)}>
            {formatCurrency(
              props.checkout?.totalAmount?.withVat?.amount ?? 0,
              props.checkout?.totalAmount?.withVat?.currency ?? currency ?? '',
              2
            )}
          </Heading>
        </VStack>
      </Card>
      {/*  TODO - finish in https://carvago.atlassian.net/browse/T20-25703 */}
      {/* <HStack spacing={3} align="center">
          <Switch value={isNotNil(tempDeposits)} onChange={handleSwitchDeposit} />
          <Heading size={4}>{i18n.t('entity.checkout.deposit')}</Heading>
        </HStack>
        <Show when={isNotNil(tempDeposits)}>
          <VStack>
            <Space vertical={4} />
            {tempDeposits?.map((key, index) => (
              <Fragment key={key}>
                <Show when={index !== 0}>
                  <Separator />
                </Show>
                <CheckoutDepositPayment
                  totalAmount={{
                    amount: props.checkout?.totalAmount?.withVat?.amount?.toString() ?? '',
                    currency: props.checkout?.totalAmount?.withVat?.currency ?? currency ?? '',
                  }}
                  onSubmit={async () => await null}
                  isRemoveEnabled={tempDeposits?.length > 1}
                  onRemoveClick={() => handleRemoveDeposit(index)}
                  data-testid={suffixTestId('deposit', props)}
                />
              </Fragment>
            ))}
            <Space vertical={4} />
            <Box>
              <Button
                title={i18n.t('entity.checkout.addDepositPayment')}
                variant="link"
                leftIcon="content/add_circle"
                onClick={handleAddDeposit}
              />
            </Box>
          </VStack>
        </Show>
        <Separator /> */}
      <Show when={isNil(props.invoice)}>
        <CheckoutPaymentForm
          checkout={props.checkout}
          onSaveChanges={props.onSaveChanges}
          onIssuePayment={props.onIssuePayment}
          isIssueButtonDisabled={props.isIssueButtonDisabled}
          issueButtonTooltip={props.issueButtonTooltip}
          isSubmitButtonDisabled={props.isSubmitButtonDisabled}
          customerId={props.customerId}
          serviceCaseId={props.serviceCaseId}
          serviceOrderId={props.serviceOrderId}
          shouldWatchForUnsavedChanges={props.shouldWatchForUnsavedChanges}
          data-testid={suffixTestId('form', props)}
        />
      </Show>
      <Hide when={isNil(props.invoice)}>
        <CheckoutInvoiceOverview
          invoice={props.invoice}
          checkout={props.checkout}
          data-testid={suffixTestId('overview', props)}
        />
      </Hide>
    </VStack>
  );
}
