import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNil, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER, SaleVehicleResponseBody} from '@omnetic-dms/shared';

export const useGetVehicleHeaderParameters = (saleVehicle: SaleVehicleResponseBody | undefined) => {
  const isSaleVehicle = isNotNil(saleVehicle);
  const formatPrice = useFormatCurrency();

  const primaryParameter = match([isSaleVehicle, isNotNil(saleVehicle?.sellingPrice?.withVat)])
    .with(
      [true, true],
      () =>
        formatPrice(
          Number(saleVehicle!.sellingPrice.withVat!.amount),
          saleVehicle!.sellingPrice.withVat!.currency
        ) ?? EMPTY_PLACEHOLDER
    )
    .with([true, false], always(EMPTY_PLACEHOLDER))
    .otherwise(always(undefined));

  const secondaryParameter = match([isSaleVehicle, isTrue(saleVehicle?.isVatDeductible)])
    .with(
      [true, true],
      always(
        isNotNil(saleVehicle?.sellingPrice?.withoutVat?.amount)
          ? formatPrice(
              Number(saleVehicle!.sellingPrice.withoutVat!.amount),
              saleVehicle!.sellingPrice.withoutVat!.currency
            ) + ` ${i18n.t('general.labels.withoutVat')}`
          : i18n.t('entity.vehicle.labels.VATDeductible')
      )
    )
    .with([true, false], always(i18n.t('entity.vehicle.labels.nondeductible')))
    .otherwise(always(undefined));

  const tertiaryParameter = match([
    isSaleVehicle,
    isNotNil(saleVehicle?.sellingPriceIsForeignCurrency?.priceWithVat) ||
      isNotNil(saleVehicle?.sellingPriceIsForeignCurrency?.priceWithoutVat),
  ])
    .with(
      [true, true],
      () =>
        formatPrice(
          Number(saleVehicle?.sellingPriceIsForeignCurrency?.priceWithVat),
          saleVehicle?.sellingPriceIsForeignCurrency?.foreignCurrencyCode ?? ''
        ) ?? EMPTY_PLACEHOLDER
    )
    .otherwise(always(undefined));

  return {primaryParameter, secondaryParameter, tertiaryParameter};
};
