import {BigDecimal} from '@omnetic-dms/shared';

import type {SaleVehiclePrice} from './SaleVehiclePrice';

export type SaleVehicle = {
  id: string;
  vehicleId: string;
  branchId: string;
  type: SaleVehicle.type;
  createdAt: string;
  sellingPrice: SaleVehiclePrice;
  realSellingPrice: SaleVehiclePrice;
  purchasePrice: SaleVehiclePrice;
  profitPrice: SaleVehiclePrice;
  profitPercent: BigDecimal | null;
  isVatDeductible: boolean | null;
  vatCode: string | null;
  currency: string | null;
  /**
   * Current selling state for Vehicle or null if there is no selling state model.
   */
  sellingState: SaleVehicle.sellingState | null;
  /**
   * Current buying state for Vehicle or null if there is no buying state model.
   */
  buyingState: SaleVehicle.buyingState | null;
};

export namespace SaleVehicle {
  export enum type {
    IN_STOCK = 'IN_STOCK',
    BROKERAGE = 'BROKERAGE',
  }

  export enum state {
    BUYING = 'BUYING',
    FAILED = 'FAILED',
    TO_SELL = 'TO_SELL',
    RESERVED = 'RESERVED',
    SOLD = 'SOLD',
  }

  /**
   * Current selling state for Vehicle or null if there is no selling state model.
   */
  export enum sellingState {
    NOT_SET = 'not-set',
    FOR_SALE = 'for-sale',
    SELLING = 'selling',
    NOT_FOR_SALE = 'not-for-sale',
    SOLD = 'sold',
    NOT_SOLD = 'not-sold',
  }

  /**
   * Current buying state for Vehicle or null if there is no buying state model.
   */
  export enum buyingState {
    NOT_SET = 'not-set',
    BUYING = 'buying',
    FOR_PRICING = 'for-pricing',
    PRICED = 'priced',
    NOT_BOUGHT = 'not-bought',
    BOUGHT = 'bought',
    RETURNED_TO_CUSTOMER = 'returned-to-customer',
    TO_BUY = 'to-buy',
  }
}
