import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {InterestResponseBody} from '@omnetic-dms/shared';

interface CanCreateBusinessCaseParams {
  state: InterestResponseBody['state'];
  type: InterestResponseBody['type'];
  canCreateSellingBusinessCase?: boolean;
  canCreateBuyingBusinessCase?: boolean;
  canCreateSwapBusinessCase?: boolean;
}

export const canCreateBusinessCase = (params: CanCreateBusinessCaseParams): boolean =>
  match([
    params.state,
    params.type,
    params.canCreateSellingBusinessCase,
    params.canCreateBuyingBusinessCase,
    params.canCreateSwapBusinessCase,
  ])
    .with(['OPEN', 'SELLING', true, Pattern.any, Pattern.any], always(true))
    .with(['NEW', 'SELLING', true, Pattern.any, Pattern.any], always(true))
    .with(['OPEN', 'BUYING', Pattern.any, true, Pattern.any], always(true))
    .with(['NEW', 'BUYING', Pattern.any, true, Pattern.any], always(true))
    .with(['OPEN', 'SWAP', true, true, Pattern.any], always(true))
    .with(['NEW', 'SWAP', true, true, Pattern.any], always(true))
    .otherwise(always(false));
