import {
  GetServiceCaseServicePackagesResponse,
  GetServiceCaseServicePackagesArg,
  PostServiceCaseAddServicePackageApiResponse,
  PostServiceCaseAddServicePackageApiArg,
  PostServiceOrderAddServicePackageApiArg,
  GetServiceCaseServicePackagesResponseSchema,
  GetServiceCaseServicePackagesArgSchema,
  PostServiceCaseAddServicePackageApiResponseSchema,
  PostServiceCaseAddServicePackageApiArgSchema,
  PostServiceOrderAddServicePackageApiArgSchema,
} from '../types/api/metadaWorkshopOrderServicePackage';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopOrderServicePackageApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceCaseServicePackages: build.query<
      GetServiceCaseServicePackagesResponse,
      GetServiceCaseServicePackagesArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/service-packages`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: GetServiceCaseServicePackagesResponseSchema,
        requestSchema: GetServiceCaseServicePackagesArgSchema,
      },
    }),
    postServiceCaseAddServicePackage: build.mutation<
      PostServiceCaseAddServicePackageApiResponse,
      PostServiceCaseAddServicePackageApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/service-package`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: PostServiceCaseAddServicePackageApiResponseSchema,
        requestSchema: PostServiceCaseAddServicePackageApiArgSchema,
      },
    }),
    postServiceOrderAddServicePackage: build.mutation<
      void,
      PostServiceOrderAddServicePackageApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/service-package/${queryArg.servicePackageId}`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        requestSchema: PostServiceOrderAddServicePackageApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetServiceCaseServicePackagesQuery,
  usePostServiceCaseAddServicePackageMutation,
  usePostServiceOrderAddServicePackageMutation,
} = metadaWorkshopOrderServicePackageApi;
