import {EquipmentResponseBody} from '@omnetic-dms/shared';

import type {Dimensions} from './Dimensions';

export type VinDecoder = {
  vehicleType?: string | null;
  firstRegistrationOnDay?: string | null;
  firstRegistrationOnMonth?: string | null;
  firstRegistrationOnYear?: string | null;
  manufacturedOnYear?: string | null;
  manufacturedOnMonth?: string | null;
  engineCode?: string | null;
  engineVolume?: string | null;
  fuelConsumptionUrban?: string | null;
  fuelConsumptionCombined?: string | null;
  fuelConsumptionExtraUrban?: string | null;
  carbonDioxideEmission?: string | null;
  emissionClass?: string | null;
  dimensions?: Dimensions | null;
  exteriorColor?: string | null;
  make?: string | null;
  modelFamily?: string | null;
  variant?: string | null;
  trim?: string | null;
  weight?: string | null;
  power?: string | null;
  seatCount?: string | null;
  transmission?: string | null;
  fuelType?: string | null;
  secondaryFuelType?: string | null;
  drive?: string | null;
  bodyStyle?: string | null;
  doorCount?: string | null;
  condition?: string | null;
  isRegistered?: boolean | null;
  decodingStatus?: VinDecoder.decodingStatus | null;
  decodedCount?: number | null;
  otherRecords?: string | null;
  seriesEquipment?: EquipmentResponseBody[] | null;
  specialEquipment?: EquipmentResponseBody[] | null;
  otherEquipment?: EquipmentResponseBody[] | null;
  features?: string[] | null;
};

export namespace VinDecoder {
  export enum decodingStatus {
    NONE = 'none',
    PARTIALLY = 'partially',
    FULLY = 'fully',
  }
}
