import {moduleKeys} from '@omnetic-dms/config';
import {sourcingRoutes} from '@omnetic-dms/routes';
import {Module, featureFlags, permissions} from '@omnetic-dms/shared';

import {SourcingPageWithFiltersContext} from './components/SourcingPageWithFiltersContext/SourcingPageWithFiltersContext';
import {SourcingClassifiedsPage} from './pages/Classifieds/Classifieds';
import {SourcingClassifieldsComparison} from './pages/UserComparison/UserComparison';
import {SourcingClassifiedsFavourite} from './pages/UserFavourite/UserFavourite';
import {SourcingClassifiedsUserHidden} from './pages/UserHidden/UserHidden';
import {SourcingClassifiedsUserToBuy} from './pages/UserToBuy/UserToBuy';
import {SourcingVehicleDetail} from './pages/VehicleDetail/VehicleDetail';

export const SourcingModule: Module = {
  id: moduleKeys.sourcing,
  requiredFeatureFlag: featureFlags.ACL_SOURCING,
  requiredPermission: permissions.sourcingOffersRead,
  layoutProps: {
    icon: 'action/shopping_cart',
    title: 'module.sourcing.title',
  },
  routerProps: {
    path: sourcingRoutes.classifieds,
  },
  content: [
    {
      id: 'sourcing-classifieds',
      routerProps: {
        path: sourcingRoutes.classifieds,
        element: (
          <SourcingPageWithFiltersContext>
            <SourcingClassifiedsPage />
          </SourcingPageWithFiltersContext>
        ),
      },
      layoutProps: {
        title: 'general.actions.allOffers',
      },
    },
    {
      id: 'sourcing-classifieds_comparison',
      routerProps: {
        path: sourcingRoutes.classifieds_comparison,
        element: <SourcingClassifieldsComparison />,
      },
      layoutProps: {
        title: 'entity.vehicle.labels.comparisionPage',
      },
    },
    {
      id: 'sourcing-classifieds_userToBuy',
      routerProps: {
        path: sourcingRoutes.classifieds_userToBuy,
        element: <SourcingClassifiedsUserToBuy />,
      },
      layoutProps: {
        title: 'entity.vehicle.labels.toBuyPage',
      },
    },
    {
      id: 'sourcing-classifieds_userHidden',
      routerProps: {
        path: sourcingRoutes.classifieds_userHidden,
        element: <SourcingClassifiedsUserHidden />,
      },
      layoutProps: {
        title: 'entity.vehicle.labels.hiddenPage',
      },
    },
    {
      id: 'sourcing-classifieds_userFavourite',
      routerProps: {
        path: sourcingRoutes.classifieds_userFavourite,
        element: <SourcingClassifiedsFavourite />,
      },
      layoutProps: {
        title: 'general.actions.favourites',
      },
    },
    {
      id: 'sourcing-vehicleDetail',
      routerProps: {
        path: sourcingRoutes.vehicleDetail,
        element: <SourcingVehicleDetail />,
      },
    },
  ],
};
