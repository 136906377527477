import {
  Action,
  DropdownActionMenuItem,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, interestRoutes, testIds} from '@omnetic-dms/routes';
import {
  InterestEndCaseDialog,
  handleApiError,
  useConvertForAllVehiclesMutation,
  useDeleteInterestMutation,
  useLazyGetAuthorizationProfilesAndCKKPermissions,
  useGetVehicleQuery,
  usePermissions,
  useUpdateInterestMutation,
} from '@omnetic-dms/shared';

import {buildArray, composePath, useNavigate} from 'shared';

import {canCreateBusinessCase} from '../utils/canCreateBusinessCase';
import {getLastActiveBusinessCase} from '../utils/getLastActiveBusinessCase';
import {isCreateBusinessCaseDisabled} from '../utils/isCreateBusinessCaseDisabled';
import {useInterestData} from './useInterestData';

export function useInterestActions(interestId: string) {
  const navigate = useNavigate();

  const {interest, miniSale, offeredVehiclesList, isActionsDisabled, miniPurchase} =
    useInterestData(interestId);

  const [updateInterest] = useUpdateInterestMutation();
  const [deleteInterest] = useDeleteInterestMutation();
  const [convertForAllVehicles] = useConvertForAllVehiclesMutation();

  const [
    hasDeleteInterestPermission,
    hasCreateBusinessCasePurchasePermission,
    hasCreateBusinessCaseSellingPermission,
    hasAddVehicleToBuyPermission,
    hasAddVehicleToSellPermission,
    canViewBusinessCaseSelling,
    canViewBusinessCasePurchase,
  ] = usePermissions({
    permissionKeys: [
      'deleteInterest',
      'createBusinessCasePurchase',
      'createBusinessCaseSelling',
      'addVehicleToBuy',
      'addVehicleToSell',
      'viewBusinessCaseSelling',
      'viewBusinessCasePurchase',
    ],
  });

  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();

  const vehicleId = miniPurchase?.offeredVehiclesIds?.[0];
  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNilOrEmpty(vehicleId)}
  );

  const canCreatePurchase = hasCreateBusinessCasePurchasePermission && hasAddVehicleToBuyPermission;
  const canCreateSell = hasCreateBusinessCaseSellingPermission && hasAddVehicleToSellPermission;

  const onConvertToBusinessCase = () => {
    convertForAllVehicles({
      interestId,
    })
      .unwrap()
      .then((response) => {
        const lastActiveBusinessCase = getLastActiveBusinessCase(response.activeBusinessCases);

        if (!lastActiveBusinessCase) {
          showNotification.error();
          return;
        }

        showNotification.success();
        navigate(
          composePath(businessCaseRoutes.overview, {
            params: {id: lastActiveBusinessCase.businessCaseId},
          })
        );
      })
      .catch(handleApiError);
    handleSearchAuthorizationProfiles(
      {
        vehicleMake: vehicleData?.make ?? '',
        vehicleType: vehicleData?.type ?? null,
        vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
        vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
      },
      interest?.customerId ?? undefined
    );
  };

  return buildArray<Action>()
    .when(interest?.state === 'NEW', {
      type: 'dropdown-iconButton',
      priority: 'secondary',
      menuItems: buildArray<DropdownActionMenuItem>()
        .when(interest?.state === 'NEW', {
          label: i18n.t('general.actions.end'),
          onClick: () =>
            openDialog(
              <InterestEndCaseDialog
                data-testid={testIds.interest.detail('submenu-action-endCaseDialog')}
                interestId={interestId}
              />,
              {
                'data-testid': testIds.interest.detail('submenu-action-endCaseDialog'),
                title: i18n.t('entity.interest.labels.endInterestCase'),
                scrollBehavior: 'outside',
              }
            ),
        })
        .when(hasDeleteInterestPermission, {
          label: i18n.t('entity.interest.actions.delete'),
          onClick: () =>
            openDeleteDialog({
              onConfirm: () =>
                deleteInterest({
                  id: interestId,
                })
                  .unwrap()
                  .then(() => {
                    showNotification.success(i18n.t('entity.interest.notifications.deleteSuccess'));
                    navigate(interestRoutes.overview);
                  })
                  .catch(handleApiError),
            }),
        }),
      'data-testid': testIds.interest.detail('headerActions-submenu'),
    })
    .when(interest?.state === 'OPEN', {
      type: 'button',
      variant: 'secondary',
      title: i18n.t('general.actions.end'),
      'data-testid': testIds.interest.detail('headerActions-end'),
      onClick: () =>
        openDialog(
          <InterestEndCaseDialog
            data-testid={testIds.interest.detail('action-endCaseDialog')}
            interestId={interestId}
          />,
          {
            'data-testid': testIds.interest.detail('action-endCaseDialog'),
            title: i18n.t('entity.interest.labels.endInterestCase'),
            scrollBehavior: 'outside',
          }
        ),
    })
    .when(
      canCreateBusinessCase({
        state: interest?.state,
        type: interest?.type,
        canCreateSellingBusinessCase: canCreateSell && canViewBusinessCaseSelling,
        canCreateBuyingBusinessCase: canCreatePurchase && canViewBusinessCasePurchase,
      }),
      {
        type: 'button',
        variant: interest?.state === 'OPEN' ? 'primary' : 'secondary',
        onClick: onConvertToBusinessCase,
        isDisabled:
          isActionsDisabled ||
          isCreateBusinessCaseDisabled({
            type: interest?.type,
            miniSale,
            offeredVehiclesList,
          }),
        leftIcon: 'places/business_center',
        title: i18n.t('entity.interest.actions.createBusinessCase'),
        'data-testid': testIds.interest.detail('headerActions-createBusinessCase'),
      }
    )
    .when(interest?.state !== 'OPEN', {
      type: 'button',
      variant: 'primary',
      onClick: () =>
        updateInterest({
          id: interestId,
          updateInterestRequestBody: {
            state: 'OPEN',
            type: interest?.type,
            customerId: interest?.customerId,
            sourceCodeId: interest?.sourceCodeId,
            branchId: interest?.branchId,
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
          })
          .catch(handleApiError),
      title: i18n.t('general.actions.open'),
      'data-testid': testIds.interest.detail('headerActions-open'),
    });
}
