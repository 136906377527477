import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Button,
  showNotification,
  OptionTypeBase,
  DataStatus,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback} from 'react';

import {concat, head, isNil} from 'ramda';

import {buildObject, Nullish, suffixTestId, TestIdProps} from 'shared';

import {useGetCustomerV2Query} from '../../api/customerApi';
import {useSendEmailMutation} from '../../api/tenantApi';
import i18n from '../../i18n/i18n';
import {SendEmailRequestBody} from '../../types/api/api';
import {getCustomerContacts} from '../../utils/getCustomerContacts';
import {handleApiError} from '../../utils/handleApiError';
import {DocumentUpload} from './DocumentUpload';
import {DocumentItem} from './types/DocumentItem';
import {SendEmailForm} from './types/SendEmailForm';

interface SendEmailProps extends TestIdProps {
  customerId?: string | Nullish;
  documents?: DocumentItem[];
}

export function SendEmail(props: SendEmailProps) {
  const {
    data,
    isLoading: isLoadingCustomer,
    isError,
  } = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {
      skip: isNil(props.customerId),
      selectFromResult: (res) => ({
        ...res,
        data: getCustomerContacts(res.data),
      }),
    }
  );

  const [sendEmail, {isLoading}] = useSendEmailMutation();

  const onSubmit: FormSubmitHandler<SendEmailForm> = async ({to, body, subject, documents}) => {
    const attachmentFiles = documents?.map((d: DocumentItem) => ({
      fileId: d.fileId,
    }));
    const args: SendEmailRequestBody = {
      to,
      body,
      subject,
      attachmentFiles,
    };
    await sendEmail(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.emailSendSuccessfully'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const getEmailOptions = useCallback(
    (creatableOptions?: string[]): OptionTypeBase<string>[] => {
      if (!data?.length && !creatableOptions?.length) {
        return [];
      }

      const personsEmails = data?.flatMap((p) =>
        p.emails.map((e) => ({
          value: e.email,
          label: e.email,
        }))
      );

      const createdEmails = creatableOptions?.map((email) => ({
        value: email,
        label: email,
      }));

      return concat(personsEmails ?? [], createdEmails ?? []);
    },
    [data]
  );

  const defaultValues = buildObject<{
    documents: DocumentItem[] | undefined;
    to: string[];
  }>()
    .documents(props.documents)
    .to([head(getEmailOptions())?.value ?? ''], getEmailOptions()?.length === 1)
    .build();

  return (
    <DataStatus isLoading={isLoadingCustomer} isError={isError}>
      <Form<SendEmailForm> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
        {(control, formApi) => {
          const currentRecipients = formApi.watch('to');
          return (
            <VStack spacing={4}>
              <FormField
                control={control}
                name="to"
                type="creatableMultiChoice"
                isRequired
                placeholder={i18n.t('general.labels.select')}
                formatCreateLabel={(inputValue: string) =>
                  `${i18n.t('general.actions.add')} ${inputValue}`
                }
                options={getEmailOptions(currentRecipients)}
                label={i18n.t('general.labels.email')}
                data-testid={suffixTestId('recipient', props)}
                isNotClearable
              />
              <FormField
                control={control}
                name="subject"
                type="text"
                label={i18n.t('general.labels.subject')}
                isRequired
                data-testid={suffixTestId('subject', props)}
              />
              <FormField
                control={control}
                name="body"
                type="textarea"
                label={i18n.t('general.labels.message')}
                data-testid={suffixTestId('message', props)}
                minRows={3}
                isRequired
              />
              <DocumentUpload control={control} data-testid={props['data-testid']} />
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  onClick={closeCurrentDialog}
                  isDisabled={isLoading}
                  title={i18n.t('general.actions.discard')}
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  type="submit"
                  isLoading={isLoading}
                  control={control}
                  title={i18n.t('general.actions.send')}
                  data-testid={suffixTestId('send', props)}
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const schema = Yup.object().shape({
  to: Yup.array().of(Yup.string()).required().min(1),
  subject: Yup.string().required().max(160),
  body: Yup.string().required(),
});
