import {DataStatus, NotificationCenter, NotificationItemData} from 'platform/components';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {useNavigate} from 'shared';

import {
  usePatchSentToastNotificationMutation,
  usePatchUnreadSentToastNotificationsMutation,
} from '../../api/messagingApi';
import {useGetCurrentUserInfoQuery} from '../../api/userApi';
import {useGetNotificationItemData} from '../../hooks/useGetNotificationItemData';
import i18n from '../../i18n/i18n';
import {ListSentToastNotificationsResponseBody} from '../../types/api/messaging';
import {handleApiError} from '../../utils/handleApiError';

interface NotificationsWidgetProps {
  notificationData?: ListSentToastNotificationsResponseBody;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  pageSize?: number;
  isHeaderHidden?: boolean;
  isDisabled?: boolean;
}

export function NotificationsWidget(props: NotificationsWidgetProps) {
  const navigate = useNavigate();

  const {data: userInfo} = useGetCurrentUserInfoQuery();

  const [notifications, setNotifications] = useState<NotificationItemData[]>();
  const [isLoadingNotificationData, setIsLoadingNotificationData] = useState(true);

  const getNotificationItemData = useGetNotificationItemData();

  const [patchNotification, {isLoading: isPatching}] = usePatchSentToastNotificationMutation();
  const [patchAllUnreadNotifications, {isLoading: isAllPatching}] =
    usePatchUnreadSentToastNotificationsMutation();

  useEffect(() => {
    if (isNil(userInfo)) {
      return;
    }

    const notifications = props.notificationData?.notifications
      ?.map((notification) => getNotificationItemData(notification, userInfo.id))
      .filter(isNotNil);

    Promise.all(notifications ?? []).then((notifications) => {
      setNotifications(notifications);
      setIsLoadingNotificationData(false);
    });
  }, [getNotificationItemData, props.notificationData?.notifications, userInfo]);

  const handleMarkAllAsRead = () => {
    patchAllUnreadNotifications({body: {isRead: true}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleMarkAsRead = (id: string) => {
    patchNotification({id, body: {isRead: true}})
      .unwrap()
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isError={props.isError}
      isLoading={props.isLoading || isLoadingNotificationData}
      isEmpty={isNilOrEmpty(notifications)}
      emptyMessage={i18n.t('general.notifications.labels.empty')}
      minHeight={80}
    >
      <NotificationCenter
        isDisabled={
          props.isDisabled || isAllPatching || isPatching || props.isLoading || props.isFetching
        }
        onMarkAllAsRead={handleMarkAllAsRead}
        onMarkAsRead={handleMarkAsRead}
        notifications={notifications}
        onNavigate={navigate}
        isHeaderHidden={props.isHeaderHidden}
        data-testid="layout-header-notificationCenter"
      />
    </DataStatus>
  );
}
