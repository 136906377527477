import {
  DeleteWorkBasketItemsApiArg,
  DeleteWorkBasketItemsApiResponse,
  GetWorkBasketItemsApiArg,
  GetWorkBasketItemsApiResponse,
  PatchWorkBasketItemApiArg,
  PatchWorkBasketItemApiResponse,
  PostWorkBasketItemFromLabourCatalogApiArg,
  PostWorkBasketItemFromLabourCatalogApiResponse,
  PostWorkBasketItemsToOrderApiArg,
  PostWorkBasketItemsToOrderApiResponse,
  PostWorkBasketItemWithCalculationPriceApiArg,
  PostWorkBasketItemWithCalculationPriceApiResponse,
  PostWorkBasketItemWithCooperationApiArg,
  PostWorkBasketItemWithCooperationApiResponse,
  PostWorkBasketItemWithDirectPriceApiArg,
  PostWorkBasketItemWithDirectPriceApiResponse,
  PostWorkBasketItemWithTimeNormApiArg,
  PostWorkBasketItemWithTimeNormApiResponse,
  PostServiceItemWorkBasketWithSubcontractApiResponse,
  PostServiceItemWorkBasketWithSubcontractApiArg,
  GetWorkBasketItemsApiResponseSchema,
  PostWorkBasketItemsToOrderApiResponseSchema,
  GetWorkBasketItemsApiArgSchema,
  PostWorkBasketItemsToOrderApiArgSchema,
  PostWorkBasketItemFromLabourCatalogApiResponseSchema,
  PostWorkBasketItemFromLabourCatalogApiArgSchema,
  PatchWorkBasketItemApiResponseSchema,
  PatchWorkBasketItemApiArgSchema,
  PostWorkBasketItemWithCalculationPriceApiResponseSchema,
  PostWorkBasketItemWithCalculationPriceApiArgSchema,
  PostWorkBasketItemWithCooperationApiResponseSchema,
  PostWorkBasketItemWithCooperationApiArgSchema,
  PostWorkBasketItemWithDirectPriceApiResponseSchema,
  PostWorkBasketItemWithDirectPriceApiArgSchema,
  PostWorkBasketItemWithTimeNormApiResponseSchema,
  PostWorkBasketItemWithTimeNormApiArgSchema,
  DeleteWorkBasketItemsApiResponseSchema,
  DeleteWorkBasketItemsApiArgSchema,
  PostServiceItemWorkBasketWithSubcontractApiResponseSchema,
  PostServiceItemWorkBasketWithSubcontractApiArgSchema,
} from '../types/api/metadaWorkshopWorkBasket';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopWorkBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkBasketItems: build.query<GetWorkBasketItemsApiResponse, GetWorkBasketItemsApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: GetWorkBasketItemsApiResponseSchema,
        requestSchema: GetWorkBasketItemsApiArgSchema,
      },
    }),
    postWorkBasketItemsToOrder: build.mutation<
      PostWorkBasketItemsToOrderApiResponse,
      PostWorkBasketItemsToOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/checkout`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceJobId},
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceJobActions', id: queryArg.serviceJobId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostWorkBasketItemsToOrderApiResponseSchema,
        requestSchema: PostWorkBasketItemsToOrderApiArgSchema,
      },
    }),
    postWorkBasketItemFromLabourCatalog: build.mutation<
      PostWorkBasketItemFromLabourCatalogApiResponse,
      PostWorkBasketItemFromLabourCatalogApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/item/labour-catalog`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PostWorkBasketItemFromLabourCatalogApiResponseSchema,
        requestSchema: PostWorkBasketItemFromLabourCatalogApiArgSchema,
      },
    }),
    patchWorkBasketItem: build.mutation<PatchWorkBasketItemApiResponse, PatchWorkBasketItemApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/item/${queryArg.serviceItemId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItem', id: queryArg.serviceItemId},
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PatchWorkBasketItemApiResponseSchema,
        requestSchema: PatchWorkBasketItemApiArgSchema,
      },
    }),
    postWorkBasketItemWithCalculationPrice: build.mutation<
      PostWorkBasketItemWithCalculationPriceApiResponse,
      PostWorkBasketItemWithCalculationPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/item/calculation-price`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PostWorkBasketItemWithCalculationPriceApiResponseSchema,
        requestSchema: PostWorkBasketItemWithCalculationPriceApiArgSchema,
      },
    }),
    postWorkBasketItemWithCooperation: build.mutation<
      PostWorkBasketItemWithCooperationApiResponse,
      PostWorkBasketItemWithCooperationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/item/cooperation`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PostWorkBasketItemWithCooperationApiResponseSchema,
        requestSchema: PostWorkBasketItemWithCooperationApiArgSchema,
      },
    }),
    postWorkBasketItemWithDirectPrice: build.mutation<
      PostWorkBasketItemWithDirectPriceApiResponse,
      PostWorkBasketItemWithDirectPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/item/direct-price`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PostWorkBasketItemWithDirectPriceApiResponseSchema,
        requestSchema: PostWorkBasketItemWithDirectPriceApiArgSchema,
      },
    }),
    postWorkBasketItemWithTimeNorm: build.mutation<
      PostWorkBasketItemWithTimeNormApiResponse,
      PostWorkBasketItemWithTimeNormApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/item/time-norm`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PostWorkBasketItemWithTimeNormApiResponseSchema,
        requestSchema: PostWorkBasketItemWithTimeNormApiArgSchema,
      },
    }),
    deleteWorkBasketItems: build.mutation<
      DeleteWorkBasketItemsApiResponse,
      DeleteWorkBasketItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/delete-items`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: DeleteWorkBasketItemsApiResponseSchema,
        requestSchema: DeleteWorkBasketItemsApiArgSchema,
      },
    }),
    postServiceItemWorkBasketWithSubcontract: build.mutation<
      PostServiceItemWorkBasketWithSubcontractApiResponse,
      PostServiceItemWorkBasketWithSubcontractApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-basket/item/subcontract`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'workBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PostServiceItemWorkBasketWithSubcontractApiResponseSchema,
        requestSchema: PostServiceItemWorkBasketWithSubcontractApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetWorkBasketItemsQuery,
  useDeleteWorkBasketItemsMutation,
  usePostWorkBasketItemsToOrderMutation,
  usePostWorkBasketItemFromLabourCatalogMutation,
  usePatchWorkBasketItemMutation,
  usePostWorkBasketItemWithCalculationPriceMutation,
  usePostWorkBasketItemWithCooperationMutation,
  usePostWorkBasketItemWithDirectPriceMutation,
  usePostWorkBasketItemWithTimeNormMutation,
  usePostServiceItemWorkBasketWithSubcontractMutation,
} = metadaWorkshopWorkBasketApi;
