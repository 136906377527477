import {
  PostServicePackageCategoryApiResponse,
  PostServicePackageCategoryApiArg,
  GetServicePackageCategoryApiResponse,
  GetServicePackageCategoryApiArg,
  DeleteServicePackageCategoryApiResponse,
  DeleteServicePackageCategoryApiArg,
  PatchServicePackageCategoryApiArg,
  PatchServicePackageCategoryApiResponse,
  GetServicePackageCategoriesApiResponse,
  GetServicePackageCategoriesApiArg,
  PostServicePackageCategoryApiResponseSchema,
  PostServicePackageCategoryApiArgSchema,
  GetServicePackageCategoryApiResponseSchema,
  GetServicePackageCategoryApiArgSchema,
  DeleteServicePackageCategoryApiResponseSchema,
  DeleteServicePackageCategoryApiArgSchema,
  PatchServicePackageCategoryApiResponseSchema,
  PatchServicePackageCategoryApiArgSchema,
  GetServicePackageCategoriesApiResponseSchema,
  GetServicePackageCategoriesApiArgSchema,
} from '../types/api/metadaWorkshopServicePackagesCategory';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServicePackagesCategoryApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServicePackageCategory: build.mutation<
      PostServicePackageCategoryApiResponse,
      PostServicePackageCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-packages-category`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['servicePackageCategories'],
      extraOptions: {
        responseSchema: PostServicePackageCategoryApiResponseSchema,
        requestSchema: PostServicePackageCategoryApiArgSchema,
      },
    }),
    getServicePackageCategory: build.query<
      GetServicePackageCategoryApiResponse,
      GetServicePackageCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-packages-category/${queryArg.categoryId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'packageCategory', id: queryArg.categoryId},
      ],
      extraOptions: {
        responseSchema: GetServicePackageCategoryApiResponseSchema,
        requestSchema: GetServicePackageCategoryApiArgSchema,
      },
    }),
    deleteServicePackageCategory: build.mutation<
      DeleteServicePackageCategoryApiResponse,
      DeleteServicePackageCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-packages-category/${queryArg.categoryId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'servicePackageCategories',
        {type: 'packageCategory', id: queryArg.categoryId},
      ],
      extraOptions: {
        responseSchema: DeleteServicePackageCategoryApiResponseSchema,
        requestSchema: DeleteServicePackageCategoryApiArgSchema,
      },
    }),
    patchServicePackageCategory: build.mutation<
      PatchServicePackageCategoryApiResponse,
      PatchServicePackageCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-packages-category/${queryArg.categoryId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'servicePackageCategories',
        {type: 'packageCategory', id: queryArg.categoryId},
      ],
      extraOptions: {
        responseSchema: PatchServicePackageCategoryApiResponseSchema,
        requestSchema: PatchServicePackageCategoryApiArgSchema,
      },
    }),
    getServicePackageCategories: build.query<
      GetServicePackageCategoriesApiResponse,
      GetServicePackageCategoriesApiArg
    >({
      query: () => ({
        url: `/service/v1/service-packages-category`,
      }),
      providesTags: ['servicePackageCategories'],
      extraOptions: {
        responseSchema: GetServicePackageCategoriesApiResponseSchema,
        requestSchema: GetServicePackageCategoriesApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostServicePackageCategoryMutation,
  useGetServicePackageCategoryQuery,
  useDeleteServicePackageCategoryMutation,
  usePatchServicePackageCategoryMutation,
  useGetServicePackageCategoriesQuery,
} = metadaWorkshopServicePackagesCategoryApi;
