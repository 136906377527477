import {all, fork} from 'redux-saga/effects';

import {
  assignmentSaga,
  commentsSaga,
  costsSaga,
  generalSettingsSaga,
  salesSaga,
  vehicleDetailSaga,
} from '@omnetic-dms/teas';

export function* rootSaga() {
  yield all([
    fork(commentsSaga),
    fork(costsSaga),
    fork(vehicleDetailSaga),
    fork(salesSaga),
    fork(generalSettingsSaga),
    fork(assignmentSaga),
  ]);
}
