import {ActionCallback, DataGrid} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

export function DirectSalesList() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowData, sourceSearchParams}) => {
    switch (actionKey) {
      case 'redirectDetail':
        if ('id' in rowData && isString(rowData.id)) {
          navigate(
            composePath(warehouseRoutes.directSalesDetailOverview, {
              queryParams: sourceSearchParams,
              params: {
                id: rowData.id,
              },
              isSearchParamsPreserved: false,
            })
          );
        }
    }
  };

  const handleCreateDirectSaleRedirect = () => {
    navigate(warehouseRoutes.directSalesCreate);
  };

  return (
    <>
      <Helmet title={i18n.t('module.warehouse.title')} />
      <Main
        heading={i18n.t('page.warehouse.labels.directSales')}
        actions={[
          {
            type: 'button',
            title: i18n.t('page.warehouse.labels.newDirectSale'),
            onClick: handleCreateDirectSaleRedirect,
            'data-testid': testIds.warehouse.directSalesList('newDirectSale'),
          },
        ]}
      >
        <Box height="86vh" width="100%">
          <DataGrid
            gridCode="direct-sale"
            actionCallback={actionCallback}
            data-testid="directSaleDatagrid"
          />
        </Box>
      </Main>
    </>
  );
}
