import {closeCurrentDialog, DataStatus, openDeleteDialog, openDialog} from 'platform/components';

import {matchPath, useLocation} from 'react-router-dom';

import {none} from 'ramda';

import {testIds, workshopRoutes} from '@omnetic-dms/routes';

import {getApiDateString} from 'shared';

import {
  useDeleteVehicleCustomerMutation,
  useGetVehicleCustomerQuery,
  usePostVehicleCustomerMutation,
} from '../../api/metadaVehicleCustomer';
import i18n from '../../i18n/i18n';
import {CustomerResponseBodyV2} from '../../types/api/customer';
import {handleApiError} from '../../utils/handleApiError';
import {OwnershipType} from '../CustomerVehicleSelection/types/ownershipType';
import {CustomerMatchOrCreate} from '../CustomerWidgetCard/components/CustomerMatchOrCreate';
import {Section} from '../Sections/components/Section';
import {CopyCustomer} from './components/CopyCustomer';
import {Invoicing} from './components/Invoicing';
import {Ownership} from './components/Ownership';
import {OwnershipReplaceConfirm} from './components/OwnershipReplaceConfirm';

const OWNERSHIP_SECTION = 'ownership';
const INVOICING_SECTION = 'invoicing';

interface OwnershipSectionProps {
  vehicleId: string;
}

export function OwnershipSection(props: OwnershipSectionProps) {
  const {pathname} = useLocation();

  const {data: vehicleCustomers} = useGetVehicleCustomerQuery({vehicleId: props.vehicleId});

  const [postVehicleCustomer, {isLoading: isPostVehicleCustomerLoading}] =
    usePostVehicleCustomerMutation();
  const [deleteVehicleCustomer] = useDeleteVehicleCustomerMutation();

  const handlePostVehicleCustomer =
    (customerType: OwnershipType) => (customer: CustomerResponseBodyV2) => {
      const serviceCasePath = matchPath(workshopRoutes.serviceCaseDetail, pathname);

      return postVehicleCustomer({
        body: {
          customerType,
          customerId: customer.id,
          vehicleId: props.vehicleId,
          eventType: serviceCasePath ? 'service-case' : 'vehicle',
          eventId: serviceCasePath ? serviceCasePath?.params.id : props.vehicleId,
          dateFrom: getApiDateString(new Date()),
        },
      })
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);
    };

  const handleOwnershipChange = (customerType: OwnershipType) =>
    openDialog(
      <DataStatus isLoading={isPostVehicleCustomerLoading} minHeight={100}>
        <CustomerMatchOrCreate
          data-testid={testIds.workshop.serviceCaseDetail('changeOwnership')}
          onCustomer={handlePostVehicleCustomer(customerType)}
          secondStepComponentType="SERVICE_CASE"
        />
      </DataStatus>,
      {title: i18n.t('entity.customer.labels.customer')}
    );

  const handleDelete = (customerType: OwnershipType) => {
    openDeleteDialog({
      onConfirm: () =>
        deleteVehicleCustomer({vehicleId: props.vehicleId, customerType})
          .unwrap()
          .catch(handleApiError),
    });
  };

  const getDoesNotHaveOwnership = (ownershipType: OwnershipType) =>
    none((customer) => customer?.customerType === ownershipType, vehicleCustomers ?? []);

  const handleCopy = (customer: CustomerResponseBodyV2) =>
    openDialog(
      <CopyCustomer
        onClose={closeCurrentDialog}
        onCopy={(ownershipType) => {
          if (getDoesNotHaveOwnership(ownershipType)) {
            return handlePostVehicleCustomer(ownershipType)(customer);
          }

          closeCurrentDialog();
          openDialog(
            <OwnershipReplaceConfirm
              ownershipType={ownershipType}
              onClose={() => {
                closeCurrentDialog();
                handleCopy(customer);
              }}
              onConfirm={() => handlePostVehicleCustomer(ownershipType)(customer)}
              data-testid={testIds.workshop.serviceCaseDetail('copyOwnershipConfirm')}
            />,
            {size: 'small'}
          );
        }}
        data-testid={testIds.workshop.serviceCaseDetail('copyOwnership')}
      />,
      {title: i18n.t('entity.ownership.actions.copyCustomer')}
    );

  const handleMove = (sourceOwnership: OwnershipType) => (customer: CustomerResponseBodyV2) => {
    const postAndDeleteOwnership = async (ownershipType: OwnershipType) => {
      await Promise.all([
        deleteVehicleCustomer({vehicleId: props.vehicleId, customerType: sourceOwnership})
          .unwrap()
          .catch(handleApiError),
        handlePostVehicleCustomer(ownershipType)(customer),
      ]);
      return;
    };

    openDialog(
      <CopyCustomer
        isMove
        onClose={closeCurrentDialog}
        excludedOwnership={sourceOwnership}
        onCopy={(ownershipType) => {
          if (getDoesNotHaveOwnership(ownershipType)) {
            return postAndDeleteOwnership(ownershipType);
          }

          closeCurrentDialog();
          openDialog(
            <OwnershipReplaceConfirm
              ownershipType={ownershipType}
              onClose={() => {
                closeCurrentDialog();
                handleMove(sourceOwnership)(customer);
              }}
              onConfirm={() => postAndDeleteOwnership(ownershipType)}
              data-testid={testIds.workshop.serviceCaseDetail('moveOwnershipConfirm')}
            />,
            {size: 'small'}
          );
        }}
        data-testid={testIds.workshop.serviceCaseDetail('moveOwnership')}
      />,
      {title: i18n.t('entity.ownership.actions.moveCustomer')}
    );
  };

  return (
    <Section
      data-testid={testIds.workshop.serviceCaseDetail('ownership')}
      tabs={[
        {
          title: i18n.t('entity.customer.labels.ownership'),
          content: (
            <Ownership
              vehicleId={props.vehicleId}
              onOwnershipChange={handleOwnershipChange}
              onDelete={handleDelete}
              onCopy={handleCopy}
              onMove={handleMove}
            />
          ),
          queryId: OWNERSHIP_SECTION,
        },
        {
          title: i18n.t('entity.customer.labels.invoiceTo'),
          content: (
            <Invoicing
              vehicleId={props.vehicleId}
              onOwnershipChange={handleOwnershipChange}
              onDelete={handleDelete}
              onMove={handleMove}
            />
          ),
          queryId: INVOICING_SECTION,
        },
      ]}
    />
  );
}
