import {Preview, DataStatus, PreviewAction} from 'platform/components';
import {Text, Box} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {UpdateWatermarkRequestBody, useGetPreviewFromSettingsQuery} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

interface WatermarkDetailPreviewProps {
  watermarkData: UpdateWatermarkRequestBody;
}

export function WatermarkDetailPreview(props: WatermarkDetailPreviewProps) {
  const {base64Url, isLoading, isFetching, isError} = useGetPreviewFromSettingsQuery(
    props.watermarkData,
    {
      skip: !props.watermarkData.fileId,
      selectFromResult: ({data, ...res}) => ({
        ...res,
        base64Url: data && `data:image/png;base64, ${data.content}`,
      }),
    }
  );

  const [lightboxControls, {onOpen}] = useLightbox('watermarkDetailPreview');

  const handleOpen = () => {
    onOpen(0);
  };

  return (
    <>
      <Text color="secondary" size="xSmall">
        {i18n.t('entity.watermark.labels.preview')}
      </Text>
      <Box height={45} width={45}>
        <DataStatus
          minHeight="100%"
          isLoading={isLoading || isFetching}
          isError={isError}
          isEmpty={!base64Url || !props.watermarkData.fileId}
        >
          <>
            <Preview
              url={base64Url ?? ''}
              width={45}
              height={45}
              makeUrl={() => base64Url ?? ''}
              shouldUseOriginal
              fit="contain"
              actions={buildArray<PreviewAction>().add({
                icon: 'action/visibility',
                onClick: handleOpen,
              })}
              data-testid={testIds.vehicles.photos('watermarkDetailPreview')}
            />
            {isNotNilOrEmpty(base64Url) && (
              <Lightbox
                data-testid={testIds.vehicles.photos('watermarkDetailPreview')}
                controls={lightboxControls}
                images={[
                  {
                    id: 'watermarkDetailPreview',
                    url: base64Url?.toString()?.replace('resize', 'get') ?? '',
                  },
                ]}
              />
            )}
          </>
        </DataStatus>
      </Box>
    </>
  );
}
