import {IFilter} from 'ag-grid-community';
import {Chips, Separator, Slider} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {isNumber, isString} from 'ramda-adjunct';

import {debounce, suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useSliderTicks} from '../../hooks/useSliderTicks';
import {IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

export type SliderFilterProps = {
  min: number;
  max: number;
  minOpen: boolean;
  maxOpen: boolean;
  unit: string | null;
  stepSize: number | null;
  steps: number[];
  markerSize: number | null;
  markers?: number[];
  decimalsAllowed: boolean;
  inputsVisible: boolean;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function SliderFilterComponent(
  {
    filterChangedCallback,
    min,
    max,
    unit,
    stepSize,
    steps,
    markerSize,
    markers,
    isDisabled,
    quickFilters,
    ...props
  }: SliderFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const isActive = useRef<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number | string | undefined>();
  const ticks = useSliderTicks(min, max, markerSize, markers);
  const midValue = useMemo<number>(() => (max + min) / 2, [min, max]);
  const {onChipsChange} = useFilterOnChipsChange<typeof sliderValue>({
    filterChangedCallback,
    setFilterValue: setSliderValue,
    defaultValue: undefined,
  });

  const valueFormatter = useCallback(
    (value: number) => (unit ? `${value} [${unit}]` : `${value}`),
    [unit]
  );

  const debouncedFilterChangedCallback = useMemo(
    () => debounce(filterChangedCallback, 700),
    [filterChangedCallback]
  );

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      return sliderValue;
    },

    setModel(model: number | undefined) {
      isActive.current = typeof model === 'number';
      setSliderValue(model);
      filterChangedCallback();
    },
  }));

  const onChange = (value: number) => {
    isActive.current = true;
    setSliderValue(value);
    debouncedFilterChangedCallback();
  };

  const isQuickFilterValue = isString(sliderValue) ? [sliderValue] : undefined;

  const isSliderValue = isNumber(sliderValue) ? sliderValue : midValue;

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('slider-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Slider
        isDisabled={isDisabled}
        min={min}
        max={max}
        step={stepSize ?? steps ?? 1}
        value={isSliderValue}
        onChange={onChange}
        formatValue={valueFormatter}
        ticks={ticks}
        onlyEdges={true}
        displayValue="auto"
        data-testid={suffixTestId('slider-filter', props)}
      />
    </VStack>
  );
}

export const SliderFilter = forwardRef<IFilter, SliderFilterProps>(SliderFilterComponent);
