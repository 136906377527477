import {Card, Chips, OptionType} from 'platform/components';

import {FC} from 'react';
import {useDispatch} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {TypeOfSale, checkoutApi, handleApiError} from '@omnetic-dms/shared';

import {Nullish, useToggle, useBoolean, TestIdProps, suffixTestId} from 'shared';

const abroadSaleOptions: OptionType<TypeOfSale>[] = [
  {
    label: i18n.t('entity.checkout.labels.abroadSaleEU'),
    value: 'inEurope',
  },
  {
    label: i18n.t('entity.checkout.labels.abroadSaleOutsideEU'),
    value: 'outOfEurope',
  },
];

interface CheckoutOrderBillingAbroadProps extends TestIdProps {
  readonly?: boolean;
  checkoutId: string;
  orderId: string;
  typeOfSale?: TypeOfSale;
  handleChangeTypeOfSaleVehicle?: (orderId: string, value: TypeOfSale) => Promise<void>;
}

export const CheckoutOrderBillingAbroad: FC<CheckoutOrderBillingAbroadProps> = ({
  readonly,
  orderId,
  typeOfSale,
  handleChangeTypeOfSaleVehicle,
  ...rest
}) => {
  const [abroadSaleOpen, toggleAbroadSaleOpen] = useToggle(typeOfSale !== 'inland');
  const [isLoading, setIsLoading, stopIsLoading] = useBoolean();

  const dispatch = useDispatch();

  const toggleAbroadSale = async () => {
    if (abroadSaleOpen) {
      await onChangeTypeOfSaleVehicle();
    }
    toggleAbroadSaleOpen();
  };

  const onChangeTypeOfSaleVehicle = (value?: string[] | Nullish) => {
    const typeOfSale = (value?.[0] as TypeOfSale) || 'inland';

    setIsLoading();
    handleChangeTypeOfSaleVehicle?.(orderId, typeOfSale)
      .then(() => dispatch(checkoutApi.util.invalidateTags([{type: 'offer', id: orderId}])))
      .catch((e) => handleApiError(e.response))
      .finally(stopIsLoading);
  };

  return (
    <Card
      variant="inlineGrey"
      title={i18n.t('entity.checkout.labels.abroadSale')}
      control={{
        type: 'switch',
        onChange: toggleAbroadSale,
        value: abroadSaleOpen,
        isDisabled: readonly,
        'data-testid': suffixTestId('abroadSaleOpen', rest),
      }}
      onExpandButtonClick={readonly ? undefined : toggleAbroadSale}
      isExpanded={abroadSaleOpen}
      data-testid={suffixTestId('abroadSale', rest)}
    >
      <Chips
        options={abroadSaleOptions}
        value={[typeOfSale as string]}
        isDisabled={readonly ?? isLoading}
        onChange={onChangeTypeOfSaleVehicle}
        data-testid={suffixTestId(`abroadSale`, rest)}
      />
    </Card>
  );
};
