import {Form, FormSubmitHandler} from 'platform/components';
import {array, object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {head, isNil} from 'ramda';
import {isUndefined} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useGetVehicleGroupQuery,
  usePatchVehicleGroupMutation,
  usePostVehicleGroupMutation,
} from '@omnetic-dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {VehicleGroupForm} from './components/VehicleGroupForm';
import {VehicleGroup} from './types/VehicleGroup';

export function VehicleGroupsDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data, isLoading, isError} = useGetVehicleGroupQuery(
    {vehicleGroupId: id ?? ''},
    {skip: !id}
  );
  const [postVehicleGroup] = usePostVehicleGroupMutation();
  const [patchVehicleGroup] = usePatchVehicleGroupMutation();

  const handleSubmit: FormSubmitHandler<VehicleGroup> = async (data) => {
    const body = {
      ...data,
      name: data.name ?? '',
      vehicleMakes: isUndefined(data.vehicleMake) ? undefined : [data.vehicleMake],
    };

    if (isNil(id)) {
      return await postVehicleGroup({
        body,
      })
        .unwrap()
        .then(() => navigate(settingsRoutes.vehicleGroups))
        .catch(handleApiError);
    }

    await patchVehicleGroup({
      vehicleGroupId: id,
      body,
    })
      .unwrap()
      .then(() => navigate(settingsRoutes.vehicleGroups))
      .catch(handleApiError);
  };

  const defaultValues: VehicleGroup = {
    ...data,
    vehicleMake: head(data?.vehicleMakes ?? []),
  };

  return (
    <SettingsTemplate
      header={{
        title: data?.name ?? i18n.t('entity.workshop.actions.newVehicleGroup'),
        breadcrumbs: [
          {
            label: i18n.t('entity.workshop.labels.vehicleGroups'),
            href: settingsRoutes.vehicleGroups,
          },
        ],
      }}
      isLoading={isLoading}
      isError={isError}
      isCreating={isNil(id)}
      data-testid={testIds.settings.vehicleGroupsDetail('template')}
    >
      <Form<VehicleGroup> defaultValues={defaultValues} schema={FormSchema} onSubmit={handleSubmit}>
        {(control, formApi) => (
          <>
            <VehicleGroupForm control={control} formApi={formApi} />
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  variant: 'secondary',
                  onClick: () => navigate(settingsRoutes.vehicleGroups),
                },
                {
                  control,
                  type: 'form-button',
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
              data-testid={testIds.settings.vehicleGroupsDetail('footer')}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const FormSchema = object({
  name: yupString.required(),
  vehicleType: yupString.nullable(),
  vehicleMake: yupString.nullable(),
  vehicleModel_families: array().of(yupString).nullable(),
  vehicleModels: array().of(yupString).nullable(),
});
