import {Button, ButtonGroup, closeDialog, Radio, RadioOption} from 'platform/components';
import {Box, Heading, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps} from 'shared';

const confirmationOptions: RadioOption[] = [
  {
    value: 'true',
    label: i18n.t('general.labels.clear'),
  },
  {
    value: 'false',
    label: i18n.t('general.labels.doNotClear'),
  },
];

interface DeactivateContractProps extends RequiredTestIdProps {
  onDeactivateContract: (isDiscountRemove: boolean) => void;
  deactivateContractDialogId: string;
}

export function DiscountsTabDeactivateContract(props: DeactivateContractProps) {
  const [confirmation, setConfirmation] = useState<string>('true');

  const handleConfirmationChange = (value: string | null) => {
    if (isNil(value)) {
      return;
    }

    setConfirmation(value);
  };

  const handleDiscard = () => {
    closeDialog(props.deactivateContractDialogId);
  };

  const handleDeactivate = () => {
    const _confirmation = confirmation === 'true' ? true : false;
    props.onDeactivateContract(_confirmation);
  };

  return (
    <VStack spacing={4}>
      <Heading size={4}>{i18n.t('warehouse.labels.deactivateContractHeading')}</Heading>

      <Box padding={4}>
        <Text color="secondary" size="small">
          <ul>
            <li>{i18n.t('warehouse.labels.deactivateContractSelectingClear')}</li>
          </ul>
        </Text>
        <Text color="secondary" size="small">
          <ul>
            <li>{i18n.t('warehouse.labels.deactivateContractDoNotSelectingClear')}</li>
          </ul>
        </Text>
      </Box>

      <Radio
        options={confirmationOptions}
        value={confirmation}
        onChange={handleConfirmationChange}
      />
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          title={i18n.t('general.actions.discard')}
          onClick={handleDiscard}
        />
        <Button
          variant="danger"
          title={i18n.t('general.actions.deactivate')}
          onClick={handleDeactivate}
        />
      </ButtonGroup>
    </VStack>
  );
}
