import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormSubmitHandler,
  closeCurrentDialog,
} from 'platform/components';
import {Box, Space, Text, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  useGrantPermissionMutation,
  useUpdateProtectedUnitPermissionMutation,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId, yupString} from 'shared';

import {ProtectedUnitWithPermission, TableDataType} from '../../hooks/useGetACLData';
import {EMPTY_SCOPE} from '../../utils/consts';
import {FormValues, ScopeList} from './ScopeList';

interface ScopeFormProps extends TestIdProps {
  protectedUnit: ProtectedUnitWithPermission;
  roleId: string;
  resource: TableDataType;
}

export function ScopeForm(props: ScopeFormProps) {
  const isUpdating = isNotNil(props.protectedUnit.permission);

  const [updateScope] = useUpdateProtectedUnitPermissionMutation();
  const [grantPermissionWithScope] = useGrantPermissionMutation();

  const grantWithScope: FormSubmitHandler<FormValues> = async (data) => {
    const scopes = data.scopes.map((scope) => ({id: scope.scopeId, values: scope.optionIds}));

    const submitAction = isUpdating
      ? updateScope({
          permissionId: props.protectedUnit.permission!.id,
          updateActionPermissionRequestBody: {
            scopes,
          },
        })
      : grantPermissionWithScope({
          grantPermissionRequestBody: {
            protectedUnitId: props.protectedUnit.id,
            resourceId: props.resource.id,
            scopes,
            roleId: props.roleId,
          },
        });

    await submitAction.unwrap().then(closeCurrentDialog).catch(handleApiError);
  };

  const roleAndActionTitle = ` ${props.resource.name} / ${props.protectedUnit.name}`;
  const scopes = props.protectedUnit.permission?.scopes;

  const defaultValues = isNotNilOrEmpty(scopes)
    ? {
        scopes: scopes?.map((scope) => ({
          scopeId: scope.id,
          optionIds: scope.values.map((item) => item.id),
        })),
      }
    : {scopes: [EMPTY_SCOPE]};

  return (
    <Form<FormValues> schema={schema} onSubmit={grantWithScope} defaultValues={defaultValues}>
      {(control, formApi) => (
        <>
          <VStack spacing={4}>
            <Box>
              <Text size="small" inline>
                {i18n.t('acl.dialog.grantAccessFor')}
              </Text>
              <Text size="small" inline alternative>
                {roleAndActionTitle}
              </Text>
            </Box>
            <ScopeList
              control={control}
              formApi={formApi}
              protectedUnit={props.protectedUnit}
              resourceId={props.resource.id}
            />
          </VStack>
          <Space vertical={4} />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.cancel')}
              variant="secondary"
              onClick={closeCurrentDialog}
              data-testid={suffixTestId('cancel', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('acl.dialog.button.submit')}
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}

const schema = object({
  scopes: array()
    .of(
      object({
        scopeId: yupString.required(),
        optionIds: array()
          .of(yupString)
          .min(1, i18n.t('general.validations.fieldIsRequired'))
          .required(),
      })
    )
    .default([]),
});
