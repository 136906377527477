import i18n from 'i18next';
import {ComponentsProvider} from 'platform/components';

import {ReactNode} from 'react';
import {useSelector} from 'react-redux';

import {defaultTo} from 'ramda';

import {
  selectActiveBranchId,
  useGetCurrenciesQuery,
  useGetLocaleConfigQuery,
} from '@omnetic-dms/shared';

import {DataGridContainer} from '../components/DataGridContainer/DataGridContainer';
import {isTestEnvironment} from './isTestEnvironment';

export type DataProviderWrapperProps = {
  children: ReactNode;
};

export const ComponentsProviderWrapper = (props: DataProviderWrapperProps) => {
  const branchId = useSelector(selectActiveBranchId);
  const {data: currenciesData} = useGetCurrenciesQuery();
  const {data: localeConfig} = useGetLocaleConfigQuery(undefined, {skip: !branchId});

  return (
    <DataGridContainer>
      <ComponentsProvider
        t={i18n.t}
        testMode={isTestEnvironment}
        currencies={defaultTo([], currenciesData)}
        language={i18n.language}
        localeConfig={localeConfig}
      >
        {props.children}
      </ComponentsProvider>
    </DataGridContainer>
  );
};
