import {isString} from 'ramda-adjunct';

/**
 * Filters elements in a given source (either a single string or an array of strings) by checking for a match against a list of target suffixes.
 * If an element in the source contains a '.', only the part after the last '.' is considered for comparison (the suffix).
 * Elements are included in the result if their suffix matches any of the strings in the targets array.
 *
 * @param {string|string[]} source - The source input to filter, can be a single string or an array of strings.
 * @param {string[]} targets - An array of target suffixes to filter against.
 * @returns {string[]} An array of filtered elements from the source that have matching suffixes in the targets array.
 * @example
 * // returns ['example.txt']
 * filterElementsBySuffixMatch(['test.js', 'example.txt', 'sample'], ['txt', 'md']);
 *
 * @example
 * // returns ['note']
 * filterElementsBySuffixMatch('note', ['note', 'txt']);
 */
export const filterElementsBySuffixMatch = (
  source: string | string[],
  targets: string[]
): string[] => {
  if (isString(source)) {
    return [source];
  }

  return source.filter((element) => {
    const suffix = element.includes('.') ? element.split('.').pop() : element;
    return targets.some((target) => target === suffix);
  });
};
