import {ColumnApi} from 'ag-grid-community';
import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {head, isNotNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {TestIdProps} from 'shared';

import {useHttpCalls} from '../hooks/useHttpCalls';
import {GridApi, GridOptions} from '../types/AgGridTypes';
import {ColumnResponseBody} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {ExportState} from '../types/ExportOptionsRenderer';
import {buildExportQueryParams} from '../utils/buildExportQueryParams';
import {downloadBlobFile} from '../utils/downloadBlobFile';
import {DefaultExportOptionsRenderer, ExportFormType} from './DefaultExportOptionsRenderer';

export interface ExportOptionsRendererWrapperProps extends TestIdProps {
  isOpen: boolean;
  onClose: () => void;
  gridProps: DataGridProps;
  gridApi?: GridApi;
  colApi?: ColumnApi;
  gridOptions: GridOptions;
  columns: ColumnResponseBody[];
  count: number;
}

const DEFAULT_FILE_NAME = 'export';
export function ExportOptionsRendererWrapper({
  gridProps,
  gridApi,
  isOpen,
  onClose,
  count,
  ...props
}: ExportOptionsRendererWrapperProps) {
  const http = useHttpCalls();
  const [exportState, setExportState] = useState<ExportState>('idle');

  const exportToFile = async (exportOptions: ExportFormType) => {
    setExportState('exporting');
    const fileType = isArray(exportOptions.fileType)
      ? head(exportOptions.fileType)
      : exportOptions.fileType;

    const options = match(fileType)
      .with('excel', () => ({
        queryParams: {
          decimalDelimiter: exportOptions.decimalSeparator,
          instanceId: gridProps.presetInstanceId,
        },
        fileExtension: 'xlsx' as const,
      }))
      .with('csv', () => ({
        queryParams: {
          decimalDelimiter: exportOptions.decimalSeparator,
          columnDelimiter: exportOptions.columnSeparator,
          instanceId: gridProps.presetInstanceId,
        },
        fileExtension: 'csv' as const,
      }))
      .otherwise(() => undefined);

    if (fileType && options?.queryParams) {
      const blob = await http.getExport(fileType, buildExportQueryParams(options.queryParams));

      try {
        if (isNotNil(blob)) {
          downloadBlobFile(blob, {fileName: DEFAULT_FILE_NAME, fileType: options.fileExtension});
        }
      } catch (e) {
        showNotification.error();
      }
    }

    setExportState('idle');
    onClose();
  };

  return (
    <DefaultExportOptionsRenderer
      totalRows={count}
      exportState={exportState}
      isOpen={isOpen}
      onClose={onClose}
      exportToFile={exportToFile}
      data-testid={props['data-testid']}
    />
  );
}
