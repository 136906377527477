export const notificationTypes = {
  PARTICIPATION_ASSIGNEE_ASSIGNED: 'participation-assignee_assigned',
  PARTICIPATION_ASSIGNEEE_UNASSIGNED: 'participation-assignee_unassigned',
  PARTICIPATION_OWNER_CHANGED: 'participation-owner_changed',
  VEHICLE_NEXT_SERVICE_NOTICE: 'vehicle-next_service_notice',
  VEHICLE_INSPECTION_EXPIRATION_NOTICE: 'vehicle-inspection_expiration_notice',
  VEHICLE_FILE_OPREATION_FINISHED: 'vehicle-file_operation_finished',
  VEHICLE_BACKGROUND_REMOVAL_FINISHED: 'vehicle_photo-remove_background_finished',
  SALE_VEHICLE_BACKGROUND_REMOVAL_FINISHED: 'sale_vehicle_photo-remove_background_finished',
  VEHICLE_PHOTO_ROTATE_FINISHED: 'vehicle_photo-rotate_finished',
  VEHICLE_PHOTO_COPY_FINISHED: 'vehicle_photo-copy_finished',
  SALE_VEHICLE_PHOTO_ROTATE_FINISHED: 'sale_vehicle_photo-rotate_finished',
  SALE_VEHICLE_PHOTO_COPY_FINISHED: 'sale_vehicle_photo-copy_finished',
  BATCH_DOWNLOAD_STATE_CHANGED: 'batch_download-bulk_event_state_changed',
  COMMENT_MENTION: 'comment-mention',
  COMMENT_RECORD_OWNER: 'comment-record_owner',
  SALE_VEHICLE_RESERVATION_CANCELLED: 'sale_vehicle-reservation_cancelled',
  PERMISSION_CHANGED: 'access_control-user_permission_changed',
  DATAGRID_CUSTOM_PRESET_SHARED: 'datagrid-custom_preset_shared',
} as const;
