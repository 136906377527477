import {createAction} from '@reduxjs/toolkit';

import {
  EditingVehicleState,
  EditVehicleIds,
  LoadingState,
  VehiclesSliceName,
} from '../../types/SourcingVehicleState';

// TODO: rename to resetSourcing and move to root of sourcing
export const resetVehicles = createAction('sourcing/resetVehicles');

export const createVehicleActions = (name: VehiclesSliceName) => {
  const setLoading = createAction(
    `${name}/setLoading`,
    (loadingState: LoadingState, value: boolean) => ({
      payload: {
        loadingState,
        value,
      },
    })
  );

  const setEditingVehicle = createAction(
    `${name}/setEditingVehicle`,
    (id: EditVehicleIds, editingState: EditingVehicleState, value: boolean) => ({
      payload: {
        id,
        editingState,
        value,
      },
    })
  );

  return {
    setLoading,
    setEditingVehicle,
  };
};

export type VehicleActions = ReturnType<typeof createVehicleActions>;
