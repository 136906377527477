import {isNil, join, pipe, reject} from 'ramda';
import {isNilOrEmpty, isNonEmptyString} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {FALLBACK_LANGUAGE} from '../i18n/fallbackLanguage';
import {i18n} from '../i18n/i18n';
import {GetApiMakeModelWithMakeApiResponse} from '../types/api/api';

type Vehicle = {
  make?: string | null;
  modelFamily?: string | null;
  trim?: string | null;
  variant?: string | null;
  title?: string | null;
};

export function getVehicleTitle(
  vehicle: Vehicle,
  makeModels?: GetApiMakeModelWithMakeApiResponse | Nullish
) {
  if (isNonEmptyString(vehicle.title)) {
    return vehicle.title;
  }

  const language = i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE;

  const makeModel = makeModels?.find((makeModel) => makeModel.make === vehicle.make);

  if (isNil(makeModel)) {
    return null;
  }

  const make = getLabel(makeModel, language);
  const modelFamily = getLabel(
    makeModel.models?.find((model) => model.model === vehicle.modelFamily),
    language
  );

  return pipe(reject(isNilOrEmpty), join(' '))([make, modelFamily, vehicle.trim, vehicle.variant]);
}

type LabelType = {
  default_label: string;
  labels?: {
    label: string;
    label_short?: string | null;
    language: string;
  }[];
};

const getLabel = (vehicleModel: LabelType | Nullish, language: string) =>
  vehicleModel?.labels?.find((label) => label.language.toUpperCase() === language.toUpperCase())
    ?.label ?? vehicleModel?.default_label;
