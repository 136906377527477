import {
  ButtonGroup,
  UploadImage,
  UploadImageValue,
  UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {useUploadFileMutation} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface UploadEmployeeImageProps extends RequiredTestIdProps {
  employeeId: string;
  handleUpload: (image: UploadImageValue) => void;
}

export function UploadEmployeeImage(props: UploadEmployeeImageProps) {
  const [uploadFile] = useUploadFileMutation();
  const [uploadedFile, setUploadedFile] = useState<UploadImageValue | null>(null);

  const handleUpload = async (file: File) => {
    const data = await uploadFile({file}).unwrap();

    return {id: data.fileId, url: data.fileUri};
  };

  const handleUploadError = (error: Error) => {
    if (error instanceof UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField) {
      showNotification.error(i18n.t('entity.watermark.notifications.imageFormatIsNotAllowed'));
    }
  };

  return (
    <VStack spacing={4}>
      <UploadImage
        uploadFileRequest={handleUpload}
        onChange={setUploadedFile}
        onUploadError={handleUploadError}
        value={uploadedFile}
        size="minHeight"
        data-testid={suffixTestId('upload-image', props)}
      />

      <ButtonGroup
        align="right"
        buttons={[
          {
            title: i18n.t('general.actions.discard'),
            variant: 'secondary',
            onClick: closeCurrentDialog,
          },
          {
            title: i18n.t('general.actions.save'),
            onClick: () => {
              if (isNil(uploadedFile)) {
                showNotification.error();
                return;
              }

              props.handleUpload(uploadedFile);
            },
            isDisabled: isNil(uploadedFile),
          },
        ]}
      />
    </VStack>
  );
}
