import {AttributesRow} from 'platform/components';
import {formatPhoneNumber, useDateTimeFormatter} from 'platform/locale';

import {isFalsy, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {buildArray, parseDate} from 'shared';

import i18n from '../i18n/i18n';
import {ContractInformationResponseBodyV2} from '../types/api/customer';
import {getNaturalPersonFullName} from '../utils/getNaturalPersonFullName';
import {useAddress} from './useAddress';
import {useBank} from './useBank';
import {useCountry} from './useCountry';
import {useGender} from './useGender';
import {useIdentityCard} from './useIdentityCard';

export function useCustomerContractAttributes(displayAftersalesVersion?: boolean) {
  const formatDateTime = useDateTimeFormatter();

  const {composeAddress} = useAddress();
  const {getCountryName} = useCountry();
  const {composeIdentityCard} = useIdentityCard();
  const {getGenderName} = useGender();
  const {composeBankAccount} = useBank();

  const getContractLegalPerson = (contract: ContractInformationResponseBodyV2) => {
    const businessInfo = buildArray<AttributesRow>()
      .add({
        label: i18n.t('contractInformation.labels.tradeName'),
        value: contract.businessInfo?.businessInfo.tradeName,
      })
      .add({
        label: i18n.t('contractInformation.labels.registrationNumber'),
        value: contract.businessInfo?.businessInfo.registrationNumber,
      })
      .add({
        label: i18n.t('contractInformation.labels.vatNumber'),
        value: contract.businessInfo?.businessInfo.vatNumber,
      })
      .add({
        label: i18n.t('contractInformation.labels.fileNumber'),
        value: contract.businessInfo?.businessInfo.fileNumber,
      })
      .add({
        label: i18n.t('contractInformation.labels.countryOfRegistrationCode'),
        value: getCountryName(contract.businessInfo?.businessInfo?.countryOfRegistrationCode),
      })
      .add({
        label: i18n.t('contractInformation.labels.businessAddressId'),
        value: composeAddress(contract.businessInfo?.address?.address),
      });

    contract.bankAccounts?.map((bank) =>
      businessInfo.add({
        label: i18n.t('general.labels.bankAccount'),
        value: composeBankAccount(bank),
      })
    );

    businessInfo.whenNot(contract.bankAccounts?.length, {
      label: i18n.t('general.labels.bankAccount'),
      value: null,
    });

    return businessInfo;
  };

  const getContractPerson = (contract: ContractInformationResponseBodyV2) => {
    const foundingPerson = buildArray<AttributesRow>()
      .add({
        label: i18n.t('entity.person.labels.fullName'),
        value: getNaturalPersonFullName(contract.person),
      })
      .when(isString(contract.person?.roles), {
        label: i18n.t('entity.person.labels.role'),
        value: contract.person?.roles,
      })
      .when(isFalsy(displayAftersalesVersion), {
        label: i18n.t('entity.person.labels.genderKey'),
        value: getGenderName(contract.person?.genderKey),
      })
      .when(isFalsy(displayAftersalesVersion), {
        label: i18n.t('entity.person.labels.citizenshipCode'),
        value: getCountryName(contract.person?.citizenshipCode),
      })
      .add({
        label: i18n.t('entity.person.labels.birthdate'),
        value: isNotNilOrEmpty(contract.person?.birthdate)
          ? formatDateTime('dateShort', parseDate(contract.person!.birthdate!))
          : null,
      })
      .add({
        label: i18n.t('entity.person.labels.personalIdentifier'),
        value: contract.person?.personalIdentifier,
      });

    foundingPerson.whenNot(contract.person?.phoneNumbers?.length, {
      label: i18n.t('entity.phoneNumber.labels.number'),
      value: null,
    });

    contract.person?.phoneNumbers?.forEach((item) => {
      foundingPerson.add({
        label: `${i18n.t('entity.phoneNumber.labels.number')} ${
          isNotNilOrEmpty(item.type) ? `(${item.type})` : ''
        }`,
        value: formatPhoneNumber(`${item.prefix}${item.number}`),
      });
    });

    foundingPerson.whenNot(contract.person?.emails?.length, {
      label: i18n.t('entity.email.labels.email'),
      value: null,
    });

    contract.person?.emails?.forEach((item) => {
      foundingPerson.add({
        label: `${i18n.t('entity.email.labels.email')} ${
          isNotNilOrEmpty(item.type) ? `(${item.type})` : ''
        }`,
        value: item.email,
      });
    });

    foundingPerson.add({
      label: `${i18n.t('entity.address.labels.fullAddress')} ${
        isNotNilOrEmpty(contract.person?.permanentAddress?.type)
          ? `(${contract.person!.permanentAddress!.type})`
          : ''
      }`,
      value: composeAddress(contract.person?.permanentAddress?.address),
    });

    foundingPerson.whenNot(contract.person?.identityCards?.length, {
      label: i18n.t('entity.identityCard.labels.fullIdentityCard'),
      value: null,
    });

    contract.person?.identityCards?.forEach((item) => {
      foundingPerson.add({
        label: i18n.t('entity.identityCard.labels.fullIdentityCard'),
        value: composeIdentityCard(item),
      });
    });

    contract.bankAccounts?.map((bank) =>
      foundingPerson.add({
        label: i18n.t('general.labels.bankAccount'),
        value: composeBankAccount(bank),
      })
    );

    foundingPerson.whenNot(contract.bankAccounts?.length, {
      label: i18n.t('general.labels.bankAccount'),
      value: null,
    });

    return foundingPerson;
  };

  const getAttributes = (contract: ContractInformationResponseBodyV2) =>
    contract.legalForm === 'NATURAL_PERSON'
      ? getContractPerson(contract)
      : getContractLegalPerson(contract);

  return [getAttributes];
}
