import {CurrencyInput} from '../../CurrencyInput/CurrencyInput';
import {useFieldProps} from '../hooks/useFieldProps';
import {MoneyRendererResponseBody} from '../types/modelSchema';

const DEFAULT_DECIMAL_PLACES = 0;

export function MoneyRenderer(props: MoneyRendererResponseBody) {
  const {name, decimalPlaces, currency} = props;
  const {onChange, onBlur, onFocus, value} = useFieldProps(props);

  return (
    <CurrencyInput
      label={props.label}
      data-testid={name}
      onChange={onChange}
      onFocus={onFocus}
      currency={currency}
      onBlur={onBlur}
      decimalPlaces={decimalPlaces ?? DEFAULT_DECIMAL_PLACES}
      value={value || ''}
    />
  );
}
