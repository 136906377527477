import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes, testIds} from '@omnetic-dms/routes';
import {Main, featureFlags, selectSaleVehicle} from '@omnetic-dms/shared';

import {composePath, buildArray, isFeatureEnabled} from 'shared';

import {VehicleContentDetails} from './VehicleContentDetails';
import {VehicleData} from './VehicleData';
import {VehicleFeatures} from './VehicleFeatures';
import {VehicleServiceIntervals} from './VehicleServiceIntervals';

type CarDetailsProps = {
  vehicleId?: string;
};

export function VehicleDetailContent(props: CarDetailsProps) {
  const {data: saleVehicle} = useSelector(selectSaleVehicle(props.vehicleId ?? ''));
  const isSaleVehicle = isNotNil(saleVehicle);

  const navigationItems = buildArray([
    {
      id: 'basicInformation',
      title: i18n.t('general.labels.basicInformation'),
      content: <VehicleContentDetails isSaleVehicle={isSaleVehicle} />,
      href: composePath(vehiclesRoutes.detail, {params: {id: props.vehicleId}}),
      'data-testid': testIds.vehicles.detail('basicInformation'),
    },
    {
      id: 'vehicleData',
      title: i18n.t('entity.vehicle.labels.vehicleData'),
      content: <VehicleData isSaleVehicle={isSaleVehicle} />,
      href: composePath(vehiclesRoutes.vehicleData, {params: {id: props.vehicleId}}),
      'data-testid': testIds.vehicles.detail('vehicleData'),
    },
  ])
    .when(isSaleVehicle, {
      id: 'features',
      title: i18n.t('entity.vehicle.labels.features'),
      content: <VehicleFeatures />,
      href: composePath(vehiclesRoutes.features, {params: {id: props.vehicleId}}),
      'data-testid': testIds.vehicles.detail('features'),
    })
    .when(!isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2), {
      id: 'features',
      title: i18n.t('entity.vehicle.labels.features'),
      content: <VehicleFeatures />,
      href: composePath(vehiclesRoutes.features, {params: {id: props.vehicleId}}),
      'data-testid': testIds.vehicles.detail('features'),
    })
    .when(!isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2), {
      id: 'serviceIntervals',
      title: i18n.t('entity.vehicle.labels.serviceIntervals'),
      content: (
        <VehicleServiceIntervals data-testid={testIds.vehicles.detail('serviceIntervals')} />
      ),
      href: composePath(vehiclesRoutes.serviceIntervals, {params: {id: props.vehicleId}}),
      'data-testid': testIds.vehicles.detail('serviceIntervals'),
    });

  return <Main data-testid={testIds.vehicles.detail('main')} tabs={navigationItems} />;
}
