import {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import {appWorkspaceKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import {loginRoutes} from '@omnetic-dms/routes';
import {
  CommonSkeleton,
  getWorkspaceFromUri,
  useLazyWorkspaceInfoQuery,
  buildUrl,
} from '@omnetic-dms/shared';

const {workspace, shouldRedirectToAppWorkspace, isReviewApp} = getWorkspaceFromUri();

export function WorkspaceInit() {
  const navigate = useNavigate();
  const [getWorkspaceInfo, {data: workspaceInfo}] = useLazyWorkspaceInfoQuery();

  useEffect(() => {
    if (environment.workspace || isReviewApp) {
      return;
    }

    const loginWorkspaceHref = buildUrl(loginRoutes.loginWorkspace, appWorkspaceKey);

    if (!workspace || shouldRedirectToAppWorkspace) {
      window.location.href = loginWorkspaceHref;
      return;
    }

    if (!workspaceInfo && !shouldRedirectToAppWorkspace && workspace !== appWorkspaceKey) {
      getWorkspaceInfo({workspace})
        .unwrap()
        .catch(() => {
          window.location.href = loginWorkspaceHref;
        });
      return;
    }
  }, [workspaceInfo, getWorkspaceInfo, navigate]);

  if (shouldRedirectToAppWorkspace) {
    return <CommonSkeleton />;
  }

  return <Outlet />;
}
