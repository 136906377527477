import {fromUint8Array} from 'js-base64';

import {VIDEO_CHUNK_SIZE} from 'features/video-upload-queue';

export function getWebBase64(uri: string, index: number, fileSize: number) {
  return fetch(uri)
    .then((response) => response.blob())
    .then((blob) => {
      const start = index * VIDEO_CHUNK_SIZE;
      const end = Math.min((index + 1) * VIDEO_CHUNK_SIZE, fileSize);

      return blob.slice(start, end);
    })
    .then((blob) => blob.arrayBuffer())
    .then((buffer) => fromUint8Array(new Uint8Array(buffer)));
}
