import {Show, Spinner} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {useEffect} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {testIds} from '@omnetic-dms/routes';
import {useGetPreviewQuery} from '@omnetic-dms/shared';

interface WatermarkPreviewProps {
  photoId?: string;
  isOpen: boolean;
  onClose: () => void;
}

const INLINE_IMAGE_PREFIX = 'data:image/png;base64';

export function WatermarkPreview(props: WatermarkPreviewProps) {
  const {data, isFetching} = useGetPreviewQuery(
    {vehiclePhotoId: props.photoId ?? ''},
    {skip: !props.isOpen || !props.photoId}
  );

  const base64Url = !isFetching && data && `${INLINE_IMAGE_PREFIX}, ${data.content}`;

  const [lightboxControls, {onOpen}] = useLightbox('watermarkPreview');

  useEffect(() => {
    if (base64Url && props.isOpen) {
      onOpen(0);
    }
  }, [props.isOpen, base64Url]);

  return (
    <>
      <Show when={isFetching}>
        <Spinner variant="overlay" />
      </Show>
      <Show when={isNotNilOrEmpty(data)}>
        {isNotNilOrEmpty(base64Url) && (
          <Lightbox
            data-testid={testIds.vehicles.photos('watermarkPreview')}
            controls={lightboxControls}
            images={[
              {
                id: 'watermarkPreview',
                url: base64Url?.toString()?.replace('resize', 'get') ?? '',
              },
            ]}
          />
        )}
      </Show>
    </>
  );
}
