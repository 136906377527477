import {
  GetEmployeeActionsApiArg,
  GetEmployeeActionsApiResponse,
  GetEmployeeLinkApiArg,
  GetEmployeeLinkApiResponse,
  GetEmployeeMechanicsApiArg,
  GetEmployeeMechanicsApiResponse,
  GetEmployeeOrgranisationalDataApiArg,
  GetEmployeeOrgranisationalDataApiResponse,
  GetEmployeePersonalDataApiArg,
  GetEmployeePersonalDataApiResponse,
  GetEmployeeSimilarSearchApiArg,
  GetEmployeeSimilarSearchApiResponse,
  PatchEmployeeOrganisationalDataApiArg,
  PatchEmployeeOrganisationalDataApiResponse,
  PatchEmployeePersonalDataApiArg,
  PatchEmployeePersonalDataApiResponse,
  PostEmployeeApiArg,
  PostEmployeeApiResponse,
  PutEmployeeActionApiArg,
  PutEmployeeActionApiResponse,
  PutEmployeeLinkApiArg,
  PutEmployeeLinkApiResponse,
} from '../types/api/employee';
import {metadaApi} from './baseApi/metadaApi';

export const metadaEmployeeApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postEmployee: build.mutation<PostEmployeeApiResponse, PostEmployeeApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getEmployeeSimilarSearch: build.query<
      GetEmployeeSimilarSearchApiResponse,
      GetEmployeeSimilarSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee/similar`,
        params: {firstName: queryArg.firstName, lastName: queryArg.lastName},
      }),
    }),
    getEmployeeOrgranisationalData: build.query<
      GetEmployeeOrgranisationalDataApiResponse,
      GetEmployeeOrgranisationalDataApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee/${queryArg.employeeId}/organisational-data`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeOrganisationalData', projectId: queryArg.employeeId},
      ],
    }),
    patchEmployeeOrganisationalData: build.mutation<
      PatchEmployeeOrganisationalDataApiResponse,
      PatchEmployeeOrganisationalDataApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee/${queryArg.employeeId}/organisational-data`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'employeeOrganisationalData', projectId: queryArg.employeeId},
      ],
    }),
    getEmployeePersonalData: build.query<
      GetEmployeePersonalDataApiResponse,
      GetEmployeePersonalDataApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee/${queryArg.employeeId}/personal-data`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeePersonalData', projectId: queryArg.employeeId},
      ],
    }),
    patchEmployeePersonalData: build.mutation<
      PatchEmployeePersonalDataApiResponse,
      PatchEmployeePersonalDataApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee/${queryArg.employeeId}/personal-data`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'employeePersonalData', projectId: queryArg.employeeId},
      ],
    }),
    putEmployeeAction: build.mutation<PutEmployeeActionApiResponse, PutEmployeeActionApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee/${queryArg.employeeId}/action`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'employeePersonalData', projectId: queryArg.employeeId},
        {type: 'employeeActions', projectId: queryArg.employeeId},
      ],
    }),
    getEmployeeActions: build.query<GetEmployeeActionsApiResponse, GetEmployeeActionsApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee/${queryArg.employeeId}/actions`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeActions', projectId: queryArg.employeeId},
      ],
    }),
    getEmployeeMechanics: build.query<GetEmployeeMechanicsApiResponse, GetEmployeeMechanicsApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee/mechanics`,
        params: {
          authorizationProfileId: queryArg.authorizationProfileId,
          competenceId: queryArg.competenceId,
        },
      }),
    }),
    getEmployeeLink: build.query<GetEmployeeLinkApiResponse, GetEmployeeLinkApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee/link`,
        params: {employeeId: queryArg.employeeId, contextTarget: 'USER'},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeLink', projectId: queryArg.employeeId},
      ],
    }),
    putEmployeeLink: build.mutation<PutEmployeeLinkApiResponse, PutEmployeeLinkApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee/${queryArg.employeeId}/link/USER/${queryArg.userId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'employeeLink', projectId: queryArg.employeeId},
      ],
    }),
  }),
});

export const {
  usePutEmployeeLinkMutation,
  useGetEmployeeLinkQuery,
  usePostEmployeeMutation,
  useLazyGetEmployeeSimilarSearchQuery,
  useGetEmployeeOrgranisationalDataQuery,
  usePatchEmployeeOrganisationalDataMutation,
  useGetEmployeePersonalDataQuery,
  usePatchEmployeePersonalDataMutation,
  usePutEmployeeActionMutation,
  useGetEmployeeActionsQuery,
  useGetEmployeeMechanicsQuery,
} = metadaEmployeeApi;
