import {Box, VStack, Space} from 'platform/foundation';

import {useCallback, useEffect, useRef} from 'react';

import {isNotNil} from 'ramda';

import {RequiredTestIdProps, suffixTestId, useDebouncedCallback} from 'shared';

import {spaceBetweenSlides} from '../consts/spaceBetweenSlides';
import {LightboxSlide} from '../types/LightboxSlide';
import {ScrollWithoutScrollbar} from './ScrollWithoutScrollbar';
import {SlideshowImage} from './SlideshowImage';
import {SlideshowVideo} from './SlideshowVideo';

interface SlideshowProps extends RequiredTestIdProps {
  slides: LightboxSlide[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onDetailOpen: (index: number) => void;
}

export function Slideshow(props: SlideshowProps) {
  const slideshowRef = useRef<HTMLDivElement>(null);
  const slideIndexInViewRef = useRef<number>(0);

  /**
   * @description
   * This implementation is here for several reasons:
   * a) We need to trigger this block every time a new SlideshowItem is in view, therefore we set
   *    the slideIndexInViewRef pointer that we compare with activeIndex. This ensures that whenever activeIndex
   *    is not in the viewport, then scrollIntoView is triggered.
   * b) We cannot trigger scrollIntoView based on activeIndex, because if we start scrolling, we would trigger
   *    the effect for every time when slide is in view. The same effect would occur if we changed activeIndex
   *    through thumbnails => slideshow started scrolling (instant behavior in the scrollIntoView option excludes this).
   * c) When we open a new lightbox at a specific index, we must ensure that this block scrolls to the correct view.
   *    By comparing the slideIndexInViewRef pointer with activeIndex, we achieve this every time.
   */
  useEffect(() => {
    if (
      props.activeIndex !== slideIndexInViewRef.current &&
      isNotNil(slideshowRef.current?.children?.[props.activeIndex])
    ) {
      slideshowRef.current.children[props.activeIndex].scrollIntoView({
        behavior: 'instant',
        block: 'start',
      });
      slideIndexInViewRef.current = props.activeIndex;
    }
  }, [props.activeIndex]);

  const handleScroll = useCallback(() => {
    if (props.activeIndex === slideIndexInViewRef.current) {
      return;
    }
    props.setActiveIndex(slideIndexInViewRef.current);
  }, [props]);

  const onSlideshowScroll = useDebouncedCallback(handleScroll, DEBOUNCE_DELAY, DEBOUNCE_WAIT);

  const onSlideshowItemInView = (index: number) => {
    slideIndexInViewRef.current = index;
  };

  return (
    <VStack width="100%">
      <ScrollWithoutScrollbar
        $direction="y"
        onScroll={(event) => {
          event?.preventDefault();
          onSlideshowScroll();
        }}
      >
        <Box ref={slideshowRef} padding={2} paddingTop={0}>
          {props.slides.map((slide, slideIndex) => (
            <Box key={slide.id}>
              <Space vertical={spaceBetweenSlides} />
              {slide.type === 'image' ? (
                <SlideshowImage
                  data-testid={suffixTestId(`slideshow-[${slideIndex}]`, props)}
                  image={slide}
                  activeIndex={props.activeIndex}
                  onDetailOpen={() => props.onDetailOpen(slideIndex)}
                  onSlideshowItemInView={() => onSlideshowItemInView(slideIndex)}
                />
              ) : null}
              {slide.type === 'video' ? (
                <SlideshowVideo
                  data-testid={suffixTestId(`slideshow-[${slideIndex}]`, props)}
                  video={slide}
                  activeIndex={props.activeIndex}
                  onSlideshowItemInView={() => onSlideshowItemInView(slideIndex)}
                />
              ) : null}
            </Box>
          ))}
        </Box>
      </ScrollWithoutScrollbar>
    </VStack>
  );
}

const DEBOUNCE_DELAY = 200;
const DEBOUNCE_WAIT = 3000;
