import {isDate} from 'date-fns';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {isApiDateString, parseDate} from 'shared';

import {DatePicker} from '../../DatePicker/DatePicker';
import {useFieldProps} from '../hooks/useFieldProps';
import {DateRendererResponseBody} from '../types/modelSchema';

export function DateRenderer(props: DateRendererResponseBody) {
  const {onChange, value} = useFieldProps(props);
  const {name} = props;

  const date = match(value)
    .when(isDate, always(value))
    .when(isApiDateString, () => parseDate(value))
    .otherwise(always(null));

  return <DatePicker label={props.label} name={name} value={date} onChange={onChange} />;
}
