import {
  GetVehicleCustomerApiResponse,
  GetVehicleCustomerApiArg,
  PostVehicleCustomerApiResponse,
  PostVehicleCustomerApiArg,
  DeleteVehicleCustomerApiResponse,
  DeleteVehicleCustomerApiArg,
} from '../types/api/vehicleCustomer';
import {metadaApi} from './baseApi/metadaApi';

export const metadaVehicleCustomer = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getVehicleCustomer: build.query<GetVehicleCustomerApiResponse, GetVehicleCustomerApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-customer`,
        params: {vehicleId: queryArg.vehicleId, customerId: queryArg.customerId},
      }),
      providesTags: (result, error, queryArg) =>
        (queryArg.vehicleId && [{type: 'postponeJob', id: queryArg.vehicleId}]) || [],
    }),
    postVehicleCustomer: build.mutation<PostVehicleCustomerApiResponse, PostVehicleCustomerApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-customer`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        ...(queryArg.body?.vehicleId
          ? [{type: 'postponeJob' as const, id: queryArg.body.vehicleId}]
          : []),
        ...(queryArg.body?.eventType === 'service-case'
          ? [
              {type: 'serviceCase' as const, id: queryArg.body?.eventId ?? ''},
              {type: 'serviceOrders' as const, id: queryArg.body?.eventId ?? ''},
              {type: 'serviceOrder' as const, id: 'ALL'},
              {type: 'orderActions' as const, id: 'ALL'},
            ]
          : []),
      ],
    }),
    deleteVehicleCustomer: build.mutation<
      DeleteVehicleCustomerApiResponse,
      DeleteVehicleCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/vehicle-customer/${queryArg.vehicleId}`,
        method: 'DELETE',
        params: {customerType: queryArg.customerType},
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'postponeJob' as const, id: queryArg.vehicleId},
      ],
    }),
  }),
});

export const {
  useGetVehicleCustomerQuery,
  useLazyGetVehicleCustomerQuery,
  usePostVehicleCustomerMutation,
  useDeleteVehicleCustomerMutation,
} = metadaVehicleCustomer;
