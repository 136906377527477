import type {PermissionsRecordType} from '../permissions';

export const settingsPermissions = {
  readSettings: {
    actionId: 'READ',
    resourceId: 'SETTINGS',
    scopes: [],
  },
  configureBackgroundRemoval: {
    actionId: 'CONFIGURE_BACKGROUND_REMOVAL',
    resourceId: 'SALES_SETTINGS',
    scopes: [],
  },
  enableWarehouseManagement: {
    actionId: 'ENABLE_WAREHOUSE_MANAGEMENT',
    resourceId: 'SALES_SETTINGS',
    scopes: [],
  },
  disableWarehouseManagement: {
    actionId: 'DISABLE_WAREHOUSE_MANAGEMENT',
    resourceId: 'SALES_SETTINGS',
    scopes: [],
  },
} satisfies PermissionsRecordType;
