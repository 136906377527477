import {
  closeCurrentDialog,
  IconButton,
  openDeleteDialog,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Icon, Box, HStack, Text, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {
  useRemoveCodeMutation,
  useRenameCodeMutation,
  CodeResponseBody,
  handleApiError,
} from '@omnetic-dms/shared';

import {CodeForm, CodeFormProps} from './CodeForm';

interface CodelistItemProps {
  code: CodeResponseBody;
  codelistId: string;
}

export function CodelistItem(props: CodelistItemProps) {
  const [renameCode] = useRenameCodeMutation();
  const [removeCode] = useRemoveCodeMutation();

  const onEditSubmit: CodeFormProps['onSubmit'] = (values) =>
    renameCode({
      codelistId: props.codelistId,
      codeId: props.code.codeId,
      renameCodeRequestBody: {
        name: values.name,
      },
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);

  const onEdit = () => {
    openDialog(<CodeForm onSubmit={onEditSubmit} defaultValues={{name: props.code.name}} />, {
      title: i18n.t('entity.codelist.labels.editItem'),
    });
  };

  const onRemove = () => {
    openDeleteDialog({
      text: i18n.t('general.actions.confirmDeleteItem.sentence'),
      onConfirm: () =>
        removeCode({codelistId: props.codelistId, codeId: props.code.codeId})
          .unwrap()
          .then(() => showNotification.success(i18n.t('entity.codelist.notifications.itemDeleted')))
          .catch(handleApiError),
    });
  };

  return (
    <VStack>
      <Box paddingHorizontal={4}>
        <HStack height={12} align="center" spacing={4}>
          <Icon value="action/drag_indicator" color="palettes.neutral.60.100" />
          <Box flexGrow={1}>
            <Text>{props.code.name}</Text>
          </Box>
          <HStack>
            <IconButton icon="content/create" onClick={onEdit} />
            <IconButton icon="action/delete" onClick={onRemove} />
          </HStack>
        </HStack>
      </Box>
      <Separator spacing={0} />
    </VStack>
  );
}
