import {Card, DataStatus, IconButton, openDeleteDialog, Switch} from 'platform/components';
import {Box, Center, Text} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  handleApiError,
  useDeleteInspectionTemplateMutation,
  useGetInspectionTemplatesQuery,
  useToggleInspectionTemplateActiveStateMutation,
} from '@omnetic-dms/shared';

import {composePath} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function VehicleInspections() {
  const navigate = useNavigate();
  const {
    data: inspectionTemplates,
    isLoading,
    isError,
  } = useGetInspectionTemplatesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deleteInspectionTemplate] = useDeleteInspectionTemplateMutation();
  const [toggleInspectionTemplateActiveState] = useToggleInspectionTemplateActiveStateMutation();

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.vehicleSettings.labels.inspectionsTitle')}}
      description={i18n.t('page.vehicleSettings.labels.inspectionsDescription')}
      data-testid="integrations-inspections-settings-modal"
    >
      <DataStatus isEmpty={!inspectionTemplates?.length} isLoading={isLoading} isError={isError}>
        <Card
          title={i18n.t`page.vehicleSettings.labels.inspectionsTitle`}
          actions={[
            {
              type: 'button',
              size: 'small',
              variant: 'ghostLink',
              leftIcon: 'content/add_circle',
              title: i18n.t('page.vehicleSettings.actions.addNewInspection'),
              onClick: () => navigate(settingsRoutes.vehicleInspectionsNew),
            },
          ]}
        >
          {inspectionTemplates?.map((template) => (
            <Box
              key={template?.id}
              padding={3}
              borderBottom="1px solid"
              borderColor="general.separator"
            >
              <Center>
                <Switch
                  data-testid="integrations-inspections-settings-modal-switch"
                  value={template.isActive}
                  onChange={() =>
                    toggleInspectionTemplateActiveState({templateId: template.id})
                      .unwrap()
                      .catch(handleApiError)
                  }
                />
                <Box flexGrow={1} paddingLeft={4}>
                  <Text size="small">{template.name}</Text>
                </Box>
                <IconButton
                  data-testid="integrations-inspections-settings-modal-icon-edit"
                  icon="image/edit"
                  onClick={() =>
                    navigate(
                      composePath(settingsRoutes.vehicleInspectionDetail, {
                        params: {id: template.id},
                      })
                    )
                  }
                />
                <IconButton
                  data-testid="integrations-inspections-settings-modal-icon-delete"
                  icon="action/delete"
                  isDisabled={!template.isDeletable}
                  onClick={() =>
                    openDeleteDialog({
                      text: i18n.t('page.vehicleSettings.actions.deleteInspectionTemplate'),
                      onConfirm: () => {
                        deleteInspectionTemplate({templateId: template.id})
                          .unwrap()
                          .catch(handleApiError);
                      },
                    })
                  }
                />
              </Center>
            </Box>
          ))}
        </Card>
      </DataStatus>
    </SettingsTemplate>
  );
}
