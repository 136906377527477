import {Separator, Attributes, Button, openDialog, closeCurrentDialog} from 'platform/components';
import {
  Space,
  getIconFromSeverity,
  getColorFromSeverity,
  Heading,
  Text,
  HStack,
  Show,
  Icon,
  Link,
  Severity,
  Box,
  VStack,
} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';

import {TestIdProps, parseDate, suffixTestId} from 'shared';

import {useGetVerificationRegistersQuery} from '../../api/customerApi';
import i18n from '../../i18n/i18n';
import {VerificationResultType} from '../../types/api/customer';
import {VerificationServices} from './components/VerificationServices';

interface CustomerVerificationProps extends TestIdProps {
  customerId: string;
  contractInformationId: string;
  recordId?: string;
  resourceId?: string;
}

export const CustomerVerification = (props: CustomerVerificationProps) => {
  const formatDateTime = useDateTimeFormatter();

  const {data} = useGetVerificationRegistersQuery({
    contractInformationId: props.contractInformationId,
    customerId: props.customerId,
    recordId: props.recordId,
    resourceId: props.resourceId,
  });

  const handleVerification = () =>
    openDialog(
      <VerificationServices
        contractInformationId={props.contractInformationId}
        customerId={props.customerId}
        recordId={props.recordId}
        resourceId={props.resourceId}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('dialog', props)}
      />,
      {
        title: i18n.t('entity.customerVerification.actions.verifyCustomer'),
      }
    );

  const verificatedRegisters = data?.filter(
    (register) =>
      isNotNil(register.verificationInfo?.requestedAt) &&
      isNotNil(register.verificationInfo?.requestedBy)
  );
  const verificationInfo = verificatedRegisters?.[0]?.verificationInfo;

  return (
    <VStack spacing={4}>
      {isNotNil(verificationInfo) ? (
        <>
          <Separator spacing={0} />
          <VStack spacing={1}>
            <Heading size={5}>{i18n.t('entity.customerVerification.labels.results')}</Heading>
            <Text size="xSmall" color="tertiary">
              {i18n.t('entity.customerVerification.labels.lastUpdate', {
                date: formatDateTime('dateShort', parseDate(verificationInfo.requestedAt)),
                name: verificationInfo.requestedBy,
              })}
            </Text>
          </VStack>
          <Box>
            <Separator spacing={0} />
            <Attributes
              size="quarter"
              rows={verificatedRegisters?.map((register) => {
                const severity = getSeverityFromResultType(register.verificationInfo?.resultType);

                return {
                  label: i18n.t(
                    `entity.customerVerification.labels.registers.${register.verificationRegisterType}`
                  ),
                  content: (
                    <HStack spacing={2}>
                      <Icon
                        value={getIconFromSeverity(severity)}
                        color={getColorFromSeverity(severity)}
                        size={4}
                      />
                      <Text size="xSmall" color={severity}>
                        {register.verificationInfo?.resultDescription}
                      </Text>
                      <Show when={register.verificationInfo?.resultUrl}>
                        <Space fillAvailable />
                        <Link
                          size="xSmall"
                          href={register.verificationInfo?.resultUrl}
                          target="_blank"
                          title={i18n.t('entity.customerVerification.actions.showResults')}
                        />
                      </Show>
                    </HStack>
                  ),
                };
              })}
            />
            <Separator spacing={0} />
          </Box>
        </>
      ) : null}
      <Box>
        <Button
          variant="secondary"
          title={i18n.t('entity.customerVerification.actions.verifyCustomer')}
          onClick={handleVerification}
          leftIcon="content/how_to_reg"
        />
      </Box>
    </VStack>
  );
};

const getSeverityFromResultType = (type?: VerificationResultType): Severity => {
  if (type === 'error') {
    return 'danger';
  }
  return type ?? 'informational';
};
