import {
  closeCurrentDialog,
  DataStatus,
  Menu,
  MenuAction,
  MenuItem,
  openDialog,
} from 'platform/components';
import {Heading, Hide, HStack, Space} from 'platform/foundation';

import {defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {useGetServiceCaseServicePackagesQuery} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {PackageTooltip} from './components/PackageTooltip';
import {ServicePackageDetail} from './components/ServicePackageDetail';

interface ServicePackagesProps extends TestIdProps {
  serviceCaseId: string;
  isWithoutTitle?: boolean;
  onServicePackageAdd: (servicePackageId: string) => Promise<void>;
}

export function ServicePackages(props: ServicePackagesProps) {
  const {data, isError, isLoading} = useGetServiceCaseServicePackagesQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const getActions = (servicePackageId: string | Nullish): MenuAction[] | undefined => {
    if (isNil(servicePackageId)) {
      return;
    }

    return [
      {
        type: 'iconButton',
        icon: 'action/info_outline',
        onClick: () =>
          openDialog(<ServicePackageDetail servicePackageId={servicePackageId} />, {
            title: i18n.t('entity.workshopServicePackages.labels.servicePackage'),
            buttons: [
              {
                type: 'button',
                variant: 'secondary',
                title: i18n.t('general.actions.discard'),
                onClick: closeCurrentDialog,
              },
              {
                type: 'button',
                title: i18n.t('general.actions.add'),
                onClick: () => props.onServicePackageAdd(servicePackageId).then(closeCurrentDialog),
              },
            ],
          }),
      },
      {
        type: 'iconButton',
        icon: 'content/add_circle',
        severity: 'informational',
        onClick: () => props.onServicePackageAdd(servicePackageId),
      },
    ];
  };

  const menuItems: MenuItem[] =
    data?.servicePackageCategory?.map((item, itemIndex) => ({
      id: defaultTo('', item?.id),
      label: defaultTo('', item?.name),
      leftIcon: 'file/folder',
      'data-testid': suffixTestId(`category-[${itemIndex}]`, props),
      items: item?.servicePackage?.map((servicePackage, packageIndex) => ({
        id: defaultTo('', servicePackage?.id),
        label: defaultTo('', servicePackage?.name),
        actions: getActions(servicePackage?.id),
        tooltip: <PackageTooltip servicePackageId={servicePackage?.id} />,
        'data-testid': suffixTestId(`item-[${itemIndex}]-[${packageIndex}]`, props),
      })),
    })) ?? [];

  return (
    <>
      <Hide when={props.isWithoutTitle}>
        <HStack justify="space-between">
          <Heading size={4}>{i18n.t('entity.workshopServicePackages.labels.title')}</Heading>
        </HStack>
        <Space vertical={6} />
      </Hide>
      <DataStatus isError={isError} isLoading={isLoading} isEmpty={isNilOrEmpty(menuItems)}>
        <Menu
          isSearchable
          isDefaultOpen
          items={menuItems}
          onItemChange={props.onServicePackageAdd}
          shouldTruncateLongText
          data-testid={props['data-testid'] ?? 'packages'}
        />
      </DataStatus>
    </>
  );
}
