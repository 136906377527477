import {sourcingRoutes} from '@omnetic-dms/routes';

import {composePath} from 'shared';

import {
  SOURCING_USER_LISTS,
  SourcingSubPageRoute,
  UserListPage,
} from '../../../types/SourcingSomeTypes';

export const pathsWithBackButton = [
  SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.CART_PAGE),
  SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.COMPARISON_PAGE),
  SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.FAVORITES_VEHICLE_PAGE),
  SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.HIDDEN_VEHICLE_PAGE),
  composePath(sourcingRoutes.vehicleDetail, {
    params: {adId: ':adId'},
  }),
];
