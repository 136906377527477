import {isEqual} from 'date-fns';
import {Action, Actions, DataStatus, openConfirmDialog, Separator} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Heading, HStack, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useEffect, useState} from 'react';

import {isNil, isNotNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  BaseDirectSale,
  BasePartsRequest,
  catchUnhandledDataGridActions,
  getNaturalPersonFullName,
  GetWarehousesResponse,
  handleApiError,
  PatchDirectSaleRequest,
  useBulkDeleteDirectSaleItemsMutation,
  useLazyGetCustomerV2Query,
  useLazyGetDirectSaleQuery,
  usePatchDirectSaleGetCurrentPriceMutation,
  usePatchDirectSaleMutation,
  usePatchPartsRequestCancelByOriginEntityMutation,
  usePostIssueNotesMutation,
  usePostPartsRequestMutation,
} from '@omnetic-dms/shared';

import {
  getApiDateString,
  parseDate,
  suffixTestId,
  TestIdProps,
  useBoolean,
  useDebouncedValue,
} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {RequestTabEdit, RequestTabEditProps} from './RequestTabEdit';
import {RequestTabForm} from './RequestTabForm';
import {RequestTabLabourModal} from './RequestTabLabourModal';
import {RequestTabMaterialModal} from './RequestTabMaterialModal';
import {RequestTabFormData} from './types/RequestTabFormData';

const DEBOUNCE_DELAY = 1500;

interface RequestTabProps extends TestIdProps {
  directSale?: BaseDirectSale;
  isDirectSaleLoading: boolean;
  isEditingDisabled: boolean;
  availableWarehouses: GetWarehousesResponse;
}

type DirectSaleItemRowData = {
  id: string;
  type: {value: {key: string; label: string}};
  name: {value: string};
  directSaleId?: {value: string};
  articleId?: {value: string};
  warehouseId?: {value: string};
  unitPrice?: {value: {amount: number; currency: string}};
  amount?: {value: number};
  state: {value: {key: string; label: string}};
};

export function RequestTab(props: RequestTabProps) {
  const {directSaleId} = useWarehouseParams();
  const [isAddLabourModalVisible, , , toggleAddLabourModal] = useBoolean();
  const [isAddMaterialModalVisible, , , toggleAddMaterialModal] = useBoolean();
  const [requestTabDataGridRef, refreshRequestTabDataGrid] = useRefreshDataGrid();
  const [directSaleDetails, setDirectSaleDetails] = useState<RequestTabFormData | null>(null);
  const [editDialogProps, setEditDialogProps] = useState<RequestTabEditProps | null>(null);

  const [getDirectSaleDetail] = useLazyGetDirectSaleQuery();
  const [getCustomer] = useLazyGetCustomerV2Query();
  const [createIssueNote] = usePostIssueNotesMutation();
  const [createPartsRequest] = usePostPartsRequestMutation();
  const [deleteDirectSalesItems] = useBulkDeleteDirectSaleItemsMutation();
  const [patchDirectSaleGetCurrentPrice] = usePatchDirectSaleGetCurrentPriceMutation();
  const [cancelPartsRequest] = usePatchPartsRequestCancelByOriginEntityMutation();
  const [saveDirectSale] = usePatchDirectSaleMutation();

  const debouncedDirectSaleDetails = useDebouncedValue(directSaleDetails, DEBOUNCE_DELAY);

  useEffect(() => {
    const handleDelayedSave = async (data: PatchDirectSaleRequest['body']) => {
      const directSaleNeededAt = parseDate(props.directSale?.neededAt || null);
      const formNeededAt = parseDate(data.neededAt || null);
      const requestData = {
        directSaleId,
        body: {
          ...data,
          neededAt: isNotNil(formNeededAt) ? getApiDateString(formNeededAt) : formNeededAt,
        },
      };

      const areBothNull = isNil(directSaleNeededAt) && isNil(formNeededAt);

      if (areBothNull || isEqual(directSaleNeededAt, formNeededAt)) {
        await saveDirectSale(requestData).unwrap().catch(handleApiError);
      } else {
        await openIsItemNeededAtUpdateConfirmDialog().then((isItemNeededAtUpdate) => {
          requestData.body.isItemNeededAtUpdate = isItemNeededAtUpdate;
          saveDirectSale(requestData)
            .unwrap()
            .then(() => {
              if (isItemNeededAtUpdate) {
                refreshRequestTabDataGrid();
              }
            })
            .catch(handleApiError);
        });
      }
    };

    if (debouncedDirectSaleDetails) {
      handleDelayedSave(debouncedDirectSaleDetails);
    }
  }, [debouncedDirectSaleDetails, directSaleId, saveDirectSale]);

  const handleDiscardAddLabourModal = () => {
    toggleAddLabourModal();
    refreshRequestTabDataGrid();
  };

  const handleDiscardAddMaterialModal = () => {
    toggleAddMaterialModal();
    refreshRequestTabDataGrid();
  };

  const mapDirectSaleItemToPartsRequest = (
    directSaleItem: DirectSaleItemRowData,
    neededAt?: string
  ): {directSaleId: string; body: BasePartsRequest} => ({
    directSaleId,
    body: {
      originEntityType: 'wrh_sale_item',
      requestItems: [
        {
          originEntityId: directSaleItem.id,
          articleId: directSaleItem.articleId?.value ?? '',
          itemNeededAtDate: neededAt,
          proposedSalePrice: directSaleItem.unitPrice?.value?.amount,
          quantity: directSaleItem.amount?.value ?? 0,
        },
      ],
    },
  });

  const openIsItemNeededAtUpdateConfirmDialog = () =>
    new Promise<boolean>((resolve) => {
      openConfirmDialog({
        text: i18n.t('page.warehouse.labels.updateNeededAtDateOnExistingItems'),
        onConfirm: () => resolve(true),
        onCloseComplete: () => resolve(false),
      });
    });

  const requestActions: Action[] = [
    {
      type: 'button',
      title: i18n.t('general.actions.addWork'),
      variant: 'ghostLink',
      leftIcon: 'content/add_circle',
      onClick: toggleAddLabourModal,
      isDisabled: props.isEditingDisabled,
      'data-testid': suffixTestId('requestAction.addLabour', props),
    },
    {
      type: 'button',
      title: i18n.t('general.actions.addMaterial'),
      variant: 'ghostLink',
      leftIcon: 'content/add_circle',
      onClick: toggleAddMaterialModal,
      isDisabled: props.isEditingDisabled,
      'data-testid': suffixTestId('requestAction.addMaterial', props),
    },
  ];

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {directSaleId}]),
    [directSaleId]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData, queryId}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;
    const directSaleItems = (isArray(rowData) ? rowData : [rowData]) as DirectSaleItemRowData[];

    match(actionKey)
      .with('getCurrentPrices', () => {
        patchDirectSaleGetCurrentPrice({
          directSaleId,
          body: {directSaleItemId: rowId as string[]},
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('redirectDetail', 'edit', () => {
        const directSaleDetail = getDirectSaleDetail({directSaleId}).unwrap().catch(handleApiError);

        directSaleDetail.then((data) => {
          const isEditingDisabled =
            data?.state === 'LOCKED' || data?.state === 'ARCHIVED' || data?.state === 'CLOSED';

          setEditDialogProps({
            itemId: id,
            isEditingDisabled,
            dataQueryId: queryId,
            directSaleId,
            authorizationProfileId: data?.authorizationProfileId,
            onAfterSubmit: refreshData,
            onClose: () => setEditDialogProps(null),
            'data-testid': props['data-testid'] as string,
          });
        });
      })
      .with('delete', () => {
        const itemsIds = directSaleItems.map((directSaleItem) => ({id: directSaleItem.id}));

        deleteDirectSalesItems({
          directSaleId,
          body: {
            items: itemsIds,
          },
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('issue', () => {
        const directSaleNumber = props.directSale?.number;
        const directSaleAuthorizationProfileId = props.directSale?.authorizationProfileId;

        if (isNil(directSaleNumber) || isNil(directSaleAuthorizationProfileId)) {
          throw new Error(
            'Cannot issue. The direct sale number or authorization profile id is missing.'
          );
        }

        const customerDataPromise = isNotNil(props.directSale?.customerId)
          ? getCustomer({customerId: props.directSale?.customerId ?? ''})
              .unwrap()
              .catch(handleApiError)
          : Promise.resolve(null);
        customerDataPromise.then((customerData) => {
          createIssueNote({
            directSaleId,
            body: {
              originDocId: directSaleId,
              originDocType: 'direct-sale',
              originDocNumber: directSaleNumber,
              authorizationProfileId: directSaleAuthorizationProfileId,
              customerName: getNaturalPersonFullName(customerData?.foundingPerson) ?? undefined,
              items: directSaleItems.map((directSaleItem) => ({
                warehouseId: directSaleItem.warehouseId?.value ?? '',
                originItemId: directSaleItem.id,
                articleId: directSaleItem.articleId?.value ?? '',
                requestItemOriginEntityType: 'wrh_sale_item',
              })),
            },
          })
            .unwrap()
            .then(refreshData)
            .catch(handleApiError);
        });
      })
      .with('requestCancellation', () => {
        const originEntityIds = directSaleItems.map((directSaleItem) => directSaleItem.id);

        cancelPartsRequest({
          directSaleId,
          body: {
            originEntityId: originEntityIds,
          },
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('request', () => {
        const directSaleItem = directSaleItems[0];

        getDirectSaleDetail({directSaleId})
          .unwrap()
          .then((directSale) =>
            createPartsRequest(
              mapDirectSaleItemToPartsRequest(directSaleItem, directSale?.neededAt)
            )
          )
          .then(refreshData)
          .catch(handleApiError);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <VStack>
      <RequestTabEdit {...(editDialogProps as RequestTabEditProps)} />

      <DataStatus isLoading={props.isDirectSaleLoading}>
        <RequestTabForm
          directSale={props.directSale}
          onChange={setDirectSaleDetails}
          isEditingDisabled={props.isEditingDisabled}
          data-testid={suffixTestId('requestTabForm', props)}
        />

        <Separator />

        <VStack spacing={4} height="50vh">
          <HStack justify="space-between">
            <Heading size={4}>{i18n.t('entity.warehouse.labels.items')}</Heading>
            <Actions actions={requestActions} data-testid={suffixTestId('listActions', props)} />
          </HStack>

          <DataGrid
            ref={requestTabDataGridRef}
            actionCallback={actionCallback}
            gridCode="direct-sale-item"
            queryModifier={queryModifier}
            emptyState={{
              headline: i18n.t('entity.warehouse.notifications.noWorkItems'),
              subheadline: i18n.t('entity.warehouse.notifications.addWorkItems'),
            }}
            data-testid={suffixTestId('requestedItems', props)}
          />
        </VStack>
      </DataStatus>

      <Show when={isAddLabourModalVisible}>
        <RequestTabLabourModal
          directSaleId={directSaleId}
          authorizationProfileId={props.directSale?.authorizationProfileId}
          onDiscard={handleDiscardAddLabourModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.addLabour')}
        />
      </Show>

      <Show when={isAddMaterialModalVisible}>
        <RequestTabMaterialModal
          directSaleId={directSaleId}
          availableWarehouses={props.availableWarehouses}
          authorizationProfileId={props.directSale?.authorizationProfileId}
          onDiscard={handleDiscardAddMaterialModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.addMaterial')}
        />
      </Show>
    </VStack>
  );
}
