import {FormApi} from 'final-form';
import {
  ButtonGroup,
  DataStatus,
  DialogFooter,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Grid, GridItem, Hide, Show, Space} from 'platform/foundation';

import {useState} from 'react';
import {useSelector} from 'react-redux';

import {
  defaultTo,
  isNil,
  isNotEmpty,
  isNotNil,
  map,
  or,
  pick,
  pipe,
  reduce,
  toUpper,
  values,
} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CreateVehicleV2ApiArg,
  FullVehicle,
  handleApiError,
  useCreateServiceVehicleMutation,
  useCreateVehicleV2Mutation,
  useDeleteVehicleMutation,
  useGetVehicleQuery,
  useSetAdditionalNoteMutation,
  useUpdateVehicleV2Mutation,
  VehicleTypeEnumObject,
} from '@omnetic-dms/shared';
import {
  $VehicleCreateFormState,
  ConfirmDialog,
  createCustomMake,
  createCustomModel,
  Form,
  Fuel,
  FuelTypeEnum,
  noop,
  selectBodyColors,
  selectBodyStyles,
  selectColorTypes,
  selectDrives,
  selectTransmissions,
  selectVehicleMakeModels,
  useThunkDispatch,
  VinDecoder,
} from '@omnetic-dms/teas';

import {Nullish, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {InfoForm} from './components/InfoForm';
import {VehicleCreateFormState} from './components/types';
import {VehicleFinder} from './components/VehicleFinder';
import {VinAndLicensePlate} from './components/VinAndLicensePlate';
import {TYPE_DEPENDENT_FIELDS} from './constants/constants';
import {getFormValues} from './utils/getFormValues';
import {getRequestBody, VehicleCreateFormStateWithNote} from './utils/getRequestBody';
import {useDecodedFields} from './utils/vehicleDuplicatesHintsUtils';

interface CreateServiceVehicleFormProps extends TestIdProps {
  onAfterSubmit: (vehicleId: string | Nullish) => void;
  onVehicleChoose?: (vehicleId: string | Nullish) => void;
  onCancel?: () => void;
  defaultVehicleId?: string | null;
  showFullForm?: boolean;
}

export function CreateServiceVehicle(props: CreateServiceVehicleFormProps) {
  const dispatch = useThunkDispatch();
  const {onDecode} = useDecodedFields();

  const makeModels = useSelector(selectVehicleMakeModels);
  const [setAdditionalNote] = useSetAdditionalNoteMutation();
  const [createVehicle] = useCreateVehicleV2Mutation();
  const [updateVehicle] = useUpdateVehicleV2Mutation();
  const [createServiceVehicle] = useCreateServiceVehicleMutation();
  const [deleteVehicle] = useDeleteVehicleMutation();

  const isEditing = isNotNil(props.defaultVehicleId);
  const [isFullFormVisible, setFullFormOpen, setFullFormClose] = useBoolean(
    isEditing || props.showFullForm
  );

  const {
    data: vehicle,
    isLoading,
    isError,
  } = useGetVehicleQuery(
    {vehicleId: props.defaultVehicleId ?? ''},
    {skip: !isEditing, refetchOnMountOrArgChange: true}
  );

  const [decoding, setDecoding] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const handleCreateVehicle = async (
    requestValues: CreateVehicleV2ApiArg & {note?: string}
  ): Promise<void> => {
    const {note, ...values} = requestValues;

    if (isNil(props.defaultVehicleId)) {
      await createVehicle(values)
        .unwrap()
        .then(async ({id}) => {
          if (isNotNil(note) && isNotEmpty(note)) {
            await setAdditionalNote({vehicleId: id, additionalNoteRequestBody: {note}});
          }

          await createServiceVehicle({vehicleId: id})
            .unwrap()
            .then(() => props.onAfterSubmit(id))
            .then(() => showNotification.success())
            .catch(async (error) => {
              await deleteVehicle({vehicleId: id});
              handleApiError(error);
            });
        })
        .catch(handleApiError);
    } else {
      updateVehicle({id: props.defaultVehicleId, ...values})
        .unwrap()
        .then(() => props.onAfterSubmit(props.defaultVehicleId))
        .then(() => showNotification.success())
        .catch(handleApiError);
    }
  };

  const handleDecode =
    (form: FormApi<VehicleCreateFormState, Partial<VehicleCreateFormState>>) =>
    async (vin: string): Promise<void | VinDecoder> => {
      setDecoding(true);
      const decoded = await onDecode(vin, form).finally(() => setDecoding(false));
      return decoded;
    };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<VehicleCreateFormStateWithNote, FullVehicle, CreateVehicleV2ApiArg>
        formId={testIds.vehicles.create('form')}
        restartOnSubmit={false}
        getFormValues={getFormValues}
        initialValues={vehicle as FullVehicle}
        defaultValues={{
          features: [],
          isArchived: false,
          type: VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR,
        }}
        getRequestBody={getRequestBody(makeModels)}
        mutators={{
          setVehicleTitle: ([value], state, {changeValue}) => {
            changeValue(state, 'title', () => value);
          },
          clearModel: (_, state, {changeValue}) => {
            changeValue(state, 'modelFamily', () => undefined);
          },
          handleTypeChange: ([newType], state) => {
            const formStateValues = state?.formState?.values as VehicleCreateFormState;
            const dependentValues = values(pick(TYPE_DEPENDENT_FIELDS, formStateValues));
            const somethingChanged = reduce<boolean, boolean>(
              or,
              false,
              map(Boolean, dependentValues)
            );
            setConfirmOpen(formStateValues.type !== newType && somethingChanged);
          },
          changeType: ([type], state, {changeValue}) => {
            changeValue(state, 'type', () => type);
          },
          resetTypeDependentFields: (_, state, {changeValue}) => {
            for (const field of TYPE_DEPENDENT_FIELDS) {
              changeValue(state, field, () => undefined);
            }
          },
          createCustomMake: ([vehicleType, customMake], state, {changeValue}) => {
            const makeKey = customMake;
            dispatch(createCustomMake({makeKey, vehicleType}));
            changeValue(state, 'make', () => makeKey);
          },
          createCustomModel: (
            [vehicleType, makeKey, customModelOptionName],
            state,
            {changeValue}
          ) => {
            dispatch(
              createCustomModel({
                makeKey,
                modelKey: customModelOptionName,
                vehicleType,
              })
            );
            changeValue(state, 'modelFamily', () => customModelOptionName);
          },
          setManufactureDate: ([manufacturedOnYear, manufacturedOnMonth], state, {changeValue}) => {
            changeValue(state, 'manufacturedOnYear', () => String(manufacturedOnYear));
            changeValue(state, 'manufacturedOnMonth', () => String(manufacturedOnMonth));
          },
        }}
        onSubmit={handleCreateVehicle}
        schema={$VehicleCreateFormState}
        validateAfterSubmit
        render={({handleSubmit, form, Subscribe, Field}) => (
          <form onSubmit={noop}>
            <Hide when={isFullFormVisible}>
              <VehicleFinder
                onVehicleSelect={props.onVehicleChoose}
                onCreateClick={({licencePlate, vinCode}) => {
                  setFullFormOpen();
                  form.change('vin', vinCode);
                  form.change('state.registrationPlate', licencePlate);
                }}
                data-testid={suffixTestId('finder', props)}
              />
            </Hide>

            <Show when={isFullFormVisible}>
              <VinAndLicensePlate
                excludeId={vehicle?.id}
                onDecode={handleDecode(form)}
                createMode
                decoding={decoding}
                onVehicleSelect={(vehicle) => props.onVehicleChoose?.(vehicle.id)}
              />
              <Space vertical={2} />

              <InfoForm createMode data-testid={testIds.vehicles.create('info-section')} />

              <Subscribe
                name="type"
                component={({input: {value: vehicleType}}) => {
                  if (
                    [
                      VehicleTypeEnumObject.VEHICLETYPE_TRAILER,
                      VehicleTypeEnumObject.VEHICLETYPE_SEMI_TRAILER,
                    ].includes(vehicleType)
                  ) {
                    return null;
                  }

                  /* eslint-disable react-hooks/rules-of-hooks */
                  const drivesList = useSelector(selectDrives(vehicleType));
                  const transmissionsList = useSelector(selectTransmissions(vehicleType));
                  const bodyTypeOptions = useSelector(selectBodyStyles(vehicleType));
                  const bodyColorOptions = useSelector(selectBodyColors(vehicleType));
                  const colorTypes = useSelector(selectColorTypes(vehicleType));

                  return (
                    <Box paddingVertical={4}>
                      <Field
                        as="text"
                        name="note"
                        label={i18n.t('entity.invoice.labels.internalNote')}
                      />
                      <Separator spacing={0} />
                      <Box paddingTop={4}>
                        <Grid columns={2}>
                          <GridItem span={2}>
                            <Show when={bodyTypeOptions?.length}>
                              <Subscribe
                                name="type"
                                component={({input: {value: vehicleType}}) => (
                                  <Field<VehicleTypeEnumObject>
                                    name="bodyStyle"
                                    as="chips"
                                    disabled={isEditing}
                                    label={i18n.t('entity.vehicle.labels.body')}
                                    options={bodyTypeOptions}
                                    limit={
                                      vehicleType === VehicleTypeEnumObject.VEHICLETYPE_MOTORCYCLE
                                        ? 9
                                        : 11
                                    }
                                    enabledDeselect
                                  />
                                )}
                              />
                            </Show>
                          </GridItem>

                          <GridItem span={2}>
                            <Fuel />
                          </GridItem>

                          <Show when={transmissionsList?.length}>
                            <Field
                              as="chips"
                              name="transmission"
                              label={i18n.t('entity.vehicle.labels.transmission')}
                              options={transmissionsList}
                              enabledDeselect
                            />
                          </Show>
                          <Show when={drivesList?.length}>
                            <Field
                              as="chips"
                              name="drive"
                              label={i18n.t('entity.vehicle.labels.drive')}
                              options={drivesList}
                              enabledDeselect
                            />
                          </Show>

                          <Field
                            as="chips"
                            name="additionalInformation.exteriorColorSpecification"
                            label={i18n.t('entity.vehicle.labels.bodyColorType')}
                            options={colorTypes}
                            enabledDeselect
                          />
                        </Grid>
                        <Box paddingTop={4}>
                          <Grid columns={4}>
                            <Field
                              as="select"
                              name="additionalInformation.exteriorColor"
                              label={i18n.t('entity.vehicle.labels.bodyColor')}
                              options={bodyColorOptions}
                            />

                            <Field
                              name="additionalInformation.manufacturerColorName"
                              label={i18n.t('entity.vehicle.labels.colorName')}
                            />
                            <Field
                              name="additionalInformation.manufacturerColorCode"
                              label={i18n.t('entity.vehicle.labels.manufacturerColorCode')}
                            />
                          </Grid>
                        </Box>

                        <Separator spacing={0} />

                        <Box paddingTop={4}>
                          <Grid columns={4}>
                            <Field
                              name="engine.engineName"
                              label={i18n.t('entity.vehicle.labels.engineName')}
                              format={toUpperCase}
                            />
                            <Field
                              name="engine.engineNumber"
                              label={i18n.t('entity.vehicle.labels.engineNumber')}
                              format={toUpperCase}
                            />
                            <Field
                              name="engine.engineCode"
                              label={i18n.t('entity.vehicle.labels.engineCode')}
                              format={toUpperCase}
                            />
                          </Grid>
                        </Box>

                        <Subscribe<FuelTypeEnum>
                          name="fuelType"
                          component={({input: {value: fuelType}}) => (
                            <GridItem span={2}>
                              <Grid columns={4}>
                                <Field
                                  as="integer"
                                  name="power"
                                  label={i18n.t(`entity.vehicle.labels.power2`)}
                                  suffix={i18n.t('general.metric.kW')}
                                />
                                <Hide
                                  when={
                                    fuelType === FuelTypeEnum.FUELTYPE_ELECTRIC ||
                                    fuelType === FuelTypeEnum.FUELTYPE_HYDROGEN
                                  }
                                >
                                  <Field
                                    as="integer"
                                    maxLength={5}
                                    name="engine.engineVolume"
                                    label={i18n.t('entity.vehicle.labels.engineCapacity')}
                                    suffix={i18n.t('general.metric.ccm')}
                                  />
                                </Hide>
                              </Grid>

                              <Grid columns={4}>
                                <Show when={fuelType === FuelTypeEnum.FUELTYPE_HYBRID}>
                                  <Field
                                    as="integer"
                                    name="engine.powerCombustionEngine"
                                    label={i18n.t(`entity.vehicle.labels.powerCombustionEngine`)}
                                    suffix={i18n.t('general.metric.kW')}
                                  />
                                  <Field
                                    as="integer"
                                    name="engine.powerElectricEngine"
                                    label={i18n.t(`entity.vehicle.labels.powerElectricEngine`)}
                                    suffix={i18n.t('general.metric.kW')}
                                  />
                                </Show>
                              </Grid>
                            </GridItem>
                          )}
                        />
                      </Box>
                    </Box>
                  );
                }}
              />
              <Separator />

              <DialogFooter>
                <ButtonGroup
                  align="right"
                  buttons={[
                    {
                      variant: 'secondary',
                      isDisabled: form.getState().submitting,
                      title: i18n.t('general.actions.discard'),
                      onClick: props.onCancel ?? setFullFormClose,
                      'data-testid': testIds.vehicles.create('discard'),
                    },
                    {
                      variant: 'primary',
                      isLoading: form.getState().submitting,
                      title: i18n.t('general.actions.save'),
                      onClick: handleSubmit,
                      'data-testid': testIds.vehicles.create('save'),
                    },
                  ]}
                />
              </DialogFooter>

              <Subscribe
                name="type"
                component={({input: {value}}) => (
                  <ConfirmDialog
                    onConfirm={() => {
                      setConfirmOpen(false);
                    }}
                    onClose={() => {
                      setConfirmOpen(false);
                    }}
                    isOpen={confirmOpen}
                    type={value}
                    data-testid={suffixTestId('confirm', props)}
                  />
                )}
              />
            </Show>
          </form>
        )}
      />
    </DataStatus>
  );
}

const toUpperCase = pipe(defaultTo(''), toUpper);
