import {Card, CardProps} from 'platform/components';
import {Grid, Heading, Space} from 'platform/foundation';

import {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {suffixTestId, TestIdProps} from 'shared';

import {selectErrorCategories} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {ParamType} from '../../../types/ParamType';
import {useConditionContext} from '../hooks/useConditionContext';
import {findByParamType} from '../utils/findByParamType';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getTranslation} from '../utils/getTranslation';
import {resetCategoryFields} from '../utils/resetCategoryFields';
import {resetToggleRelatedFields} from '../utils/resetToggleRelatedFields';
import {AdditionalInformation} from './AdditionalInformation';
import {AuditFormField} from './AuditFormField';

interface CategoryCardProps {
  data: AuditCategoryOfStructure;
  cardVariant: string;
  additionalInformationBeside?: boolean;
  isNewAdded?: boolean;
  photoGrid?: number;
}

export const CategoryCard: FC<CategoryCardProps & TestIdProps> = ({
  data: category,
  additionalInformationBeside,
  cardVariant,
  isNewAdded,
  photoGrid,
  ...rest
}) => {
  const {isDisabledForUser} = useConditionContext();
  const locale = useSelector(selectUserSelectedLanguage);
  const errorCategories = useSelector(selectErrorCategories);

  const {
    formRenderProps: {
      Field,
      form: {
        mutators: {setFieldValue},
        getFieldState,
      },
    },
  } = useConditionContext();

  const name = getTranslation(locale, category.name);
  const relatedActions = category.paramDefinitions?.relatedActions;
  const additionalInformation = category.paramDefinitions?.additionalInformation;
  const isMandatory = category.mandatory;

  const additionalComment = findByParamType(additionalInformation, ParamType.ADDITIONAL_COMMENT);
  const additionalPhotos = findByParamType(additionalInformation, ParamType.ADDITIONAL_PHOTOS);
  const toggleAction = findByParamType(relatedActions, ParamType.TOGGLE);
  const fieldName = getFormFieldName(category.id, toggleAction?.id);

  useEffect(() => {
    const fieldValue = getFieldState(fieldName)?.value;

    if (isNewAdded && fieldValue === undefined) {
      setFieldValue(fieldName, true);
    }
  }, [isNewAdded]);

  if (!category.visible) {
    return null;
  }

  const cardTitle = `${isMandatory ? '*' : ''} ${name}`;

  if (!toggleAction) {
    return (
      <Card
        variant={cardVariant as CardProps['variant']}
        data-testid={suffixTestId('categoryCard', rest)}
      >
        <Heading size={4} color={errorCategories?.[category.id] ? 'danger' : undefined}>
          {cardTitle}
        </Heading>
        <Space vertical={4} />
        <Grid columns={2} verticalSpacing={0}>
          <ChildCategoriesRelatedActions
            data={category}
            data-testid={suffixTestId('categoryCard', rest)}
          />
          <RelatedActions
            data={relatedActions ?? []}
            category={category}
            data-testid={suffixTestId('categoryCard', rest)}
          />
        </Grid>
        <AdditionalInformation
          data-testid={suffixTestId('categoryCard', rest)}
          categoryId={category.id}
          additionalComment={additionalComment}
          additionalPhotos={additionalPhotos}
          beside={additionalInformationBeside}
          uniqueKey={category.uniqueKey}
          photoGrid={photoGrid}
        />
      </Card>
    );
  }

  const relatedActionsData = relatedActions?.filter(
    (relatedAction) => relatedAction.type !== ParamType.TOGGLE
  );

  return (
    <Field
      name={fieldName}
      component={({input}) => {
        const toggleValue = toggleAction?.values?.find((option) => option.value === 'true');

        const toggleContent = toggleValue?.content;
        const fieldValue = getFieldState(fieldName)?.value;
        const isChecked = typeof fieldValue === 'boolean' ? fieldValue : Boolean(input.value);

        const toggleAdditionalInformation = toggleContent?.additionalInformation;

        const toggleAdditionalComment = findByParamType(
          toggleAdditionalInformation,
          ParamType.ADDITIONAL_COMMENT
        );
        const toggleAdditionalPhotos = findByParamType(
          toggleAdditionalInformation,
          ParamType.ADDITIONAL_PHOTOS
        );

        const hasOnlyToggle =
          !toggleContent && !relatedActionsData?.length && !additionalInformation?.length;
        const isExpanded = !hasOnlyToggle ? isChecked : false;

        return (
          <Card
            isExpanded={isExpanded}
            variant={cardVariant as CardProps['variant']}
            title={cardTitle}
            control={{
              type: 'switch',
              isDisabled: isDisabledForUser,
              value: isChecked,
              onChange: (checked) => {
                if (!checked) {
                  resetToggleRelatedFields(category, toggleAction, setFieldValue);
                  category.childCategories?.forEach((childCategory) => {
                    resetCategoryFields(childCategory, setFieldValue);
                  });
                }
                input.onChange(checked);
              },
              'data-testid': suffixTestId('categoryCard', rest),
            }}
            data-testid={suffixTestId('categoryCard', rest)}
          >
            <Grid columns={2} verticalSpacing={0}>
              <ChildCategoriesRelatedActions
                data={category}
                data-testid={suffixTestId('categoryCard', rest)}
              />
              <RelatedActions
                data={[...(toggleContent?.relatedActions ?? []), ...(relatedActionsData ?? [])]}
                category={category}
                data-testid={suffixTestId('categoryCard', rest)}
              />
            </Grid>
            {toggleAdditionalInformation && (
              <AdditionalInformation
                categoryId={category.id}
                additionalComment={toggleAdditionalComment}
                additionalPhotos={toggleAdditionalPhotos}
                beside={additionalInformationBeside}
                uniqueKey={category.uniqueKey}
                photoGrid={photoGrid}
                data-testid={suffixTestId('categoryCard', rest)}
              />
            )}
            <AdditionalInformation
              categoryId={category.id}
              additionalComment={additionalComment}
              additionalPhotos={additionalPhotos}
              beside={additionalInformationBeside}
              uniqueKey={category.uniqueKey}
              photoGrid={photoGrid}
              data-testid={suffixTestId('categoryCard', rest)}
            />
          </Card>
        );
      }}
    />
  );
};

const ChildCategoriesRelatedActions: FC<Pick<CategoryCardProps, 'data'> & TestIdProps> = ({
  data: category,
  ...rest
}) => (
  <>
    {category.childCategories?.map((category) => {
      if (!category.visible) {
        return null;
      }

      const relatedActions: AuditParamDefinition[] = [];
      category.paramDefinitions?.relatedActions?.forEach((relatedAction) => {
        relatedActions.push({
          ...relatedAction,
          label: category.name,
        });
      });

      return relatedActions?.map((paramDefinition) => (
        <AuditFormField
          key={`related-action-${paramDefinition.id}-${category.id}`}
          paramDefinition={paramDefinition}
          categoryId={category.id}
          uniqueKey={category.uniqueKey}
          data-testid={suffixTestId(`childCategoriesRelatedActions-${paramDefinition.id}`, rest)}
        />
      ));
    })}
  </>
);

interface RelatedActionsProps {
  category: AuditCategoryOfStructure;
  data?: Array<AuditParamDefinition>;
}

const RelatedActions: FC<RelatedActionsProps & TestIdProps> = ({data, category, ...rest}) => (
  <>
    {data?.map((paramDefinition) => (
      <AuditFormField
        key={`relatedActions-${category.id}-${paramDefinition.id}`}
        paramDefinition={paramDefinition}
        categoryId={category.id}
        uniqueKey={category.uniqueKey}
        data-testid={suffixTestId(`relatedActions-${paramDefinition.id}`, rest)}
      />
    ))}
  </>
);
