import {Card} from 'platform/components';
import {Link, Show, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {
  businessCaseRoutes,
  customerRoutes,
  interestsRoutes,
  testIds,
  vehiclesRoutes,
} from '@omnetic-dms/routes';
import {featureFlags, usePermissions} from '@omnetic-dms/shared';

export function QuickActionsCard() {
  const [canReadBusinessCase] = usePermissions({permissionKeys: ['businessCaseRead']});

  return (
    <Card
      variant="inlineWhite"
      data-testid={testIds.dashboard.home('quick-actions')}
      isFullHeight
      title={i18n.t('page.homepage.labels.quickActions')}
    >
      <VStack spacing={3} align="flex-start">
        <Show when={canReadBusinessCase} whenFeatureEnabled={featureFlags.ACL_SALES}>
          <Link
            href={businessCaseRoutes.concept}
            size="small"
            data-testid={testIds.dashboard.home('add-business-case')}
            title={i18n.t('entity.businessCase.actions.addBusinessCase')}
          />
          <Link
            href={interestsRoutes.concept}
            size="small"
            data-testid={testIds.dashboard.home('button-add-interest')}
            title={i18n.t('entity.interest.actions.newInterest')}
          />
        </Show>
        <Link
          href={vehiclesRoutes.create}
          size="small"
          data-testid={testIds.dashboard.home('button-add-vehicle')}
          title={i18n.t('entity.vehicle.actions.addVehicle')}
        />
        <Link
          href={customerRoutes.create}
          size="small"
          data-testid={testIds.dashboard.home('button-add-customer')}
          title={i18n.t('entity.customer.actions.addCustomer')}
        />
      </VStack>
    </Card>
  );
}
