import {
  GetTestDriveApiArg,
  GetTestDriveApiResponse,
  UpdateTestDriveApiArg,
  UpdateTestDriveApiResponse,
} from '../types/api/testDriveNew';
import {omneticApi as api} from './baseApi/omneticApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTestDrive: build.query<GetTestDriveApiResponse, GetTestDriveApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/test-drive/${queryArg.id}`,
        headers: {Authorization: queryArg.authorization},
      }),
    }),
    updateTestDrive: build.mutation<UpdateTestDriveApiResponse, UpdateTestDriveApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/test-drive/${queryArg.testDriveId}`,
        method: 'PUT',
        body: queryArg.testDriveRequestBody,
        headers: {Authorization: queryArg.authorization},
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useGetTestDriveQuery, useUpdateTestDriveMutation} = injectedRtkApi;
