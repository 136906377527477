import {DataGrid, DataGridRef, QueryFilterObject} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {ForwardedRef, forwardRef, useCallback} from 'react';

interface ProbabiltyDataGridProps {
  serviceCaseId: string;
  orderId: string;
}

export const ProfitabilityDataGrid = forwardRef(
  (props: ProbabiltyDataGridProps, ref: ForwardedRef<DataGridRef>) => {
    const queryModifier = useCallback(
      (filter: QueryFilterObject) => ({
        ...filter,
        orderId: props.orderId,
      }),
      [props.orderId]
    );

    return (
      <Box height={48}>
        <DataGrid
          ref={ref}
          gridCode="service-order-profitability"
          autoHeight
          queryModifier={queryModifier}
        />
      </Box>
    );
  }
);
