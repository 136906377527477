import {useLocale} from 'platform/locale';

import {useGetAllApiEnumsQuery, useGetCountriesQuery} from '@omnetic-dms/shared';

import {getEnumByProperty, PropertyName} from '../../../utils/getEnumByProperty';
import {getDefaultLabel} from '../utils/getDefaultLabel';

export type ApiEnum = {
  key: string;
  value: string;
  label: string;
  colorCode?: string;
}[];

export function useApiEnums() {
  const {language} = useLocale();
  const {data: enums, isLoading} = useGetAllApiEnumsQuery({
    lang: [language],
    vehicleType: ['VEHICLETYPE_PASSENGER_CAR'],
  });
  const {data: countries} = useGetCountriesQuery();

  const getEnum = (propertyName: PropertyName): ApiEnum => {
    if (propertyName === 'country') {
      return (
        countries?.map((country) => ({
          key: country.code,
          value: country.code,
          label: country.name,
        })) ?? []
      );
    }

    return getEnumByProperty(propertyName, enums).map((item) => ({
      key: item.const_key,
      value: item.const_key,
      label: getDefaultLabel(item),
      colorCode: propertyName === 'body_color' ? item.properties?.[0]?.value : undefined,
    }));
  };

  return {enums, getEnum, isLoading};
}
