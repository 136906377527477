import {FormControl} from 'platform/components';
import {Box, Grid, GridItem, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../types/ServiceVehicleFormType';
import {Additional} from './components/Additional';
import {Main} from './components/Main';

interface BasicInformationProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function BasicInformation(props: BasicInformationProps) {
  return (
    <Box padding={4}>
      <Grid columns={3}>
        <GridItem span={2}>
          <VStack spacing={4}>
            <Main
              control={props.control}
              formApi={props.formApi}
              data-testid={suffixTestId('main', props)}
            />
            <Additional
              control={props.control}
              formApi={props.formApi}
              data-testid={suffixTestId('additional', props)}
            />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}
