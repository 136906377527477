import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {GetWarehouseHandlingUnitsResponse} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {getOptionsFromHandlingUnits} from '../../../../../utils/getOptionsFromHandlingUnits';
import {ReceiveNoteCorrectionItemForm} from '../types/ReceiveNoteCorrectionItemForm';

export interface SupplierToWarehouseMappingFormProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteCorrectionItemForm>;
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish;
  areHandlingUnitsLoading: boolean;
}

export function SupplierToWarehouseMappingForm(props: SupplierToWarehouseMappingFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.supplierToWarehouseMapping')}</Heading>

      <Grid columns={4} spacing={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="supplierToWarehouseMapping.deliveryQuantity"
            label={i18n.t('entity.warehouse.labels.deliveryQuantity')}
            isDisabled
            data-testid={suffixTestId('inputs.deliveryQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="choice"
            name="supplierToWarehouseMapping.deliveryUnit"
            label={i18n.t('entity.warehouse.labels.deliveryUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            isLoading={props.areHandlingUnitsLoading}
            isDisabled
            data-testid={suffixTestId('inputs.deliveryUnit', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="currency"
            name="supplierToWarehouseMapping.receiveQuantity"
            label={i18n.t('entity.warehouse.labels.receiveQuantity')}
            isDisabled
            data-testid={suffixTestId('inputs.receiveQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="choice"
            name="supplierToWarehouseMapping.receiveUnit"
            label={i18n.t('entity.warehouse.labels.receiveUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            isLoading={props.areHandlingUnitsLoading}
            isDisabled
            menuInPortal
            data-testid={suffixTestId('inputs.receiveUnit', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
