import {
  GetServiceCaseTypeApiArg,
  GetServiceCaseTypeApiResponse,
  PatchServiceCaseTypeApiArg,
  PatchServiceCaseTypeApiResponse,
  PostServiceCaseTypeApiArg,
  PostServiceCaseTypeApiResponse,
  PatchServiceCaseTypeSetAsActiveApiResponse,
  PatchServiceCaseTypeSetAsActiveApiArg,
  PatchServiceCaseTypeSetAsInactiveApiResponse,
  PatchServiceCaseTypeSetAsInactiveApiArg,
  PostServiceCaseTypeApiResponseSchema,
  PostServiceCaseTypeApiArgSchema,
  GetServiceCaseTypeApiResponseSchema,
  GetServiceCaseTypeApiArgSchema,
  PatchServiceCaseTypeApiArgSchema,
  PatchServiceCaseTypeApiResponseSchema,
  PatchServiceCaseTypeSetAsActiveApiResponseSchema,
  PatchServiceCaseTypeSetAsActiveApiArgSchema,
  PatchServiceCaseTypeSetAsInactiveApiResponseSchema,
  PatchServiceCaseTypeSetAsInactiveApiArgSchema,
} from '../types/api/metadaWorkshopServiceCaseType';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceCaseTypeApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceCaseType: build.mutation<PostServiceCaseTypeApiResponse, PostServiceCaseTypeApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case-type`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostServiceCaseTypeApiResponseSchema,
        requestSchema: PostServiceCaseTypeApiArgSchema,
      },
    }),
    getServiceCaseType: build.query<GetServiceCaseTypeApiResponse, GetServiceCaseTypeApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case-type/${queryArg.serviceCaseTypeId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceCaseType', id: queryArg.serviceCaseTypeId},
      ],
      extraOptions: {
        responseSchema: GetServiceCaseTypeApiResponseSchema,
        requestSchema: GetServiceCaseTypeApiArgSchema,
      },
    }),
    patchServiceCaseType: build.mutation<
      PatchServiceCaseTypeApiResponse,
      PatchServiceCaseTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case-type/${queryArg.serviceCaseTypeId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseType', id: queryArg.serviceCaseTypeId},
      ],
      extraOptions: {
        responseSchema: PatchServiceCaseTypeApiResponseSchema,
        requestSchema: PatchServiceCaseTypeApiArgSchema,
      },
    }),
    patchServiceCaseTypeSetAsActive: build.mutation<
      PatchServiceCaseTypeSetAsActiveApiResponse,
      PatchServiceCaseTypeSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case-type/${queryArg.serviceCaseTypeId}/set-as-active`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseType', id: queryArg.serviceCaseTypeId},
      ],
      extraOptions: {
        responseSchema: PatchServiceCaseTypeSetAsActiveApiResponseSchema,
        requestSchema: PatchServiceCaseTypeSetAsActiveApiArgSchema,
      },
    }),
    patchServiceCaseTypeSetAsInactive: build.mutation<
      PatchServiceCaseTypeSetAsInactiveApiResponse,
      PatchServiceCaseTypeSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case-type/${queryArg.serviceCaseTypeId}/set-as-inactive`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseType', id: queryArg.serviceCaseTypeId},
      ],
      extraOptions: {
        responseSchema: PatchServiceCaseTypeSetAsInactiveApiResponseSchema,
        requestSchema: PatchServiceCaseTypeSetAsInactiveApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostServiceCaseTypeMutation,
  useGetServiceCaseTypeQuery,
  usePatchServiceCaseTypeMutation,
  usePatchServiceCaseTypeSetAsActiveMutation,
  usePatchServiceCaseTypeSetAsInactiveMutation,
} = metadaWorkshopServiceCaseTypeApi;
