import {Card, FormControl, FormField, Separator} from 'platform/components';
import {Box, Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../types/ServiceVehicleFormType';
import {FluidForm} from './components/FluidForm';
import {ReplacementForm} from './components/ReplacementForm';
import {ServiceForm} from './components/ServiceForm';

interface ServiceIntervalsProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function ServiceIntervals(props: ServiceIntervalsProps) {
  return (
    <Box padding={4}>
      <Grid columns={3}>
        <GridItem span={2}>
          <Card>
            <Card variant="inlineGrey">
              <VStack spacing={4}>
                <Heading size={4}>{i18n.t('entity.vehicle.labels.serviceInspection')}</Heading>
                <ServiceForm
                  control={props.control}
                  formApi={props.formApi}
                  data-testid={suffixTestId('serviceInspection', props)}
                />
                <Separator spacing={0} />
                <Heading size={4}>{i18n.t('entity.vehicle.labels.engineOil')}</Heading>
                <ReplacementForm
                  control={props.control}
                  formApi={props.formApi}
                  groupName="engineOil"
                  data-testid={suffixTestId('engineOil', props)}
                />
                <Separator spacing={0} />
                <Heading size={4}>{i18n.t('entity.vehicle.labels.gearboxOil')}</Heading>
                <ReplacementForm
                  control={props.control}
                  formApi={props.formApi}
                  groupName="gearboxOil"
                  data-testid={suffixTestId('gearboxOil', props)}
                />
                <Separator spacing={0} />
                <Heading size={4}>
                  {i18n.t('entity.vehicle.labels.fourByFourOilDistributor')}
                </Heading>
                <ReplacementForm
                  control={props.control}
                  formApi={props.formApi}
                  groupName="fourByFourOilDistributor"
                  data-testid={suffixTestId('fourByFourOilDistributor', props)}
                />
                <Separator spacing={0} />
                <Heading size={4}>{i18n.t('entity.vehicle.labels.timingBelt')}</Heading>
                <ReplacementForm
                  control={props.control}
                  formApi={props.formApi}
                  groupName="timingBelt"
                  data-testid={suffixTestId('timingBelt', props)}
                />
                <Separator spacing={0} />
                <Heading size={4}>{i18n.t('entity.vehicle.labels.brakeFluid')}</Heading>
                <FluidForm
                  control={props.control}
                  formApi={props.formApi}
                  groupName="brakeFluid"
                  data-testid={suffixTestId('brakeFluid', props)}
                />
                <Separator spacing={0} />
                <Heading size={4}>{i18n.t('entity.vehicle.labels.coolantFluid')}</Heading>
                <FluidForm
                  control={props.control}
                  formApi={props.formApi}
                  groupName="coolantFluid"
                  data-testid={suffixTestId('coolantFluid', props)}
                />
                <Separator spacing={0} />
                <Heading size={4}>{i18n.t('entity.vehicle.labels.annualMileage')}</Heading>
                <Grid columns={4}>
                  <GridItem>
                    <FormField
                      control={props.control}
                      name="serviceIntervals.annualMileage.calculatedFromServiceVisits"
                      type="number"
                      suffix={i18n.t('general.metric.km')}
                      label={i18n.t('entity.vehicle.labels.serviceVisits')}
                    />
                  </GridItem>
                </Grid>
              </VStack>
            </Card>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
}
