import {OptionType} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {VehicleAgeType} from '@omnetic-dms/shared';

export const ageTypeOptions: OptionType<VehicleAgeType>[] = [
  {
    label: i18n.t('general.labels.undecided'),
    value: 'VEHICLE_AGE_UNDECIDED',
  },
  {
    label: i18n.t('entity.customerContract.labels.vehicleAgeInMonths'),
    value: 'VEHICLE_AGE_IN_MONTHS',
  },
];
