import {Card} from 'platform/components';
import {DataGrid, QueryFilterObject} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {useRequiredParams} from 'shared';

import {Main} from '../../components/Main/Main';
import i18n from '../../i18n/i18n';

interface ActivityLogProps {
  code:
    | 'vehicle-action-log'
    | 'customer-action-log'
    | 'interest-action-log'
    | 'vehicle-audit-log'
    | 'business_case-action-log';
}

export function ActivityLogs(props: ActivityLogProps) {
  const {id} = useRequiredParams();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {record: id}]),
    [id]
  );
  return (
    <Main>
      <Card title={i18n.t('general.labels.activityLogs')}>
        <Box>
          <DataGrid
            gridCode={props.code}
            autoHeight
            queryModifier={queryModifier}
            data-testid="activity-logs-datagrid"
          />
        </Box>
      </Card>
    </Main>
  );
}
