import {Attributes, AttributesRow} from 'platform/components';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';
import {css} from 'styled-components';

import {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  BuyingOfferPurchaseVehicleResponseBody,
  DeductibleOrNotDeductiblePrice,
  featureFlags,
  selectTenant,
  useGetUserQuery,
} from '@omnetic-dms/shared';
import {selectVatRatesByCountryCode, ApiMoney, VatRate} from '@omnetic-dms/teas';

import {buildArray, getApiDateString, isFeatureEnabled, parseDate} from 'shared';

interface BuyingDetailProps {
  values?: BuyingOfferPurchaseVehicleResponseBody;
  maxBuyingPrice?: ApiMoney;
}

export const BusinessCasePurchaseVehicleBuyingDetail: FC<BuyingDetailProps> = ({
  values,
  maxBuyingPrice,
}) => {
  const isBusinessCaseSimplifiedPricingEnabled = isFeatureEnabled(
    featureFlags.BUSINESS_CASE_PRICING_SIMPLIFIED
  );

  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const {vatType, vatDeductible, buyingPrice, reason, pricedOn, buyer} = values ?? {};
  const {data: buyerUser} = useGetUserQuery({id: buyer ?? ''}, {skip: isNil(buyer)});

  const {data} = useSelector(selectTenant);

  const vatRates = useSelector(selectVatRatesByCountryCode(data?.country))?.rates;

  const vatRate = useMemo(
    // eslint-disable-next-line no-restricted-syntax
    () => vatRates?.find((type) => type.type === (vatType as unknown as VatRate.type)),
    [vatRates, vatType]
  );

  const getFormattedPrice = useCallback(
    (value?: DeductibleOrNotDeductiblePrice) => {
      if (!value) {
        return '–';
      }

      if (!vatDeductible) {
        const {priceNotDeductible} = value;
        if (priceNotDeductible) {
          return formatCurrency(parseFloat(priceNotDeductible.amount), priceNotDeductible.currency);
        }
        return '–';
      }

      const {priceWithVat, priceWithoutVat} = value;

      if (!priceWithVat) {
        return '–';
      }

      return (
        <div>
          {priceWithVat && (
            <div>{formatCurrency(parseFloat(priceWithVat.amount), priceWithVat.currency)}</div>
          )}
          {priceWithoutVat && (
            <div
              css={css`
                color: ${({theme}) => theme.colors.text.tertiary};
              `}
            >
              {formatCurrency(parseFloat(priceWithoutVat.amount), priceWithoutVat.currency)}
            </div>
          )}
        </div>
      );
    },
    [vatDeductible, formatCurrency]
  );

  const rows = buildArray<AttributesRow>([
    {
      label: i18n.t('general.labels.vatDeductible'),
      value: vatDeductible ? i18n.t('general.labels.yes') : i18n.t('general.labels.no'),
      testId: testIds.businessCase.buying('buying-vatDeductible'),
    },
  ])
    .whenNot(isBusinessCaseSimplifiedPricingEnabled, {
      label: i18n.t('page.businessCase.labels.maxBuyingPrice'),
      value:
        maxBuyingPrice &&
        formatCurrency(parseFloat(maxBuyingPrice.amount), maxBuyingPrice.currency),
      testId: testIds.businessCase.buying('buying-maxBuyingPrice'),
    })
    .add({
      label: i18n.t('general.labels.buyingPrice'),
      content: getFormattedPrice(buyingPrice),
      testId: testIds.businessCase.buying('buying-buyingPrice'),
    })
    .add({
      label: i18n.t('general.labels.reason'),
      value: reason,
      testId: testIds.businessCase.buying('buying-reason'),
    })
    .add({
      label: i18n.t('general.labels.buyer'),
      value: buyerUser ? `${buyerUser.firstName} ${buyerUser.lastName}` : '–',
      testId: testIds.businessCase.buying('buying-buyer'),
    })
    .add({
      label: i18n.t('general.labels.pricedOn'),
      value: pricedOn ? getApiDateString(parseDate(pricedOn)) : '–',
      testId: testIds.businessCase.buying('buying-pricedOn'),
    });

  if (vatRate && vatRate.type !== VatRate.type.Z) {
    rows.splice(1, 0, {
      label: i18n.t('general.labels.vatRate'),
      value: `${formatNumber(Number(vatRate.rate), 0)} %`,
      testId: testIds.businessCase.buying('buying-vatRate'),
    });
  }

  return (
    <Attributes data-testid={testIds.businessCase.buying('buying')} size="third" rows={rows} />
  );
};
