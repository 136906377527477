import {moduleKeys} from '@omnetic-dms/config';
import {businessCaseRoutes} from '@omnetic-dms/routes';
import {featureFlags, Module, permissions} from '@omnetic-dms/shared';

import {BusinessCases} from './pages';
import {BusinessCaseDetailWrapper} from './pages/BusinessCaseDetail/BusinessCaseDetail';
import {CreateBusinessCase} from './pages/CreateBusinessCase/CreateBusinessCase';

export const BusinessCaseModule: Module = {
  id: moduleKeys.businessCase,
  layoutProps: {
    icon: 'places/business_center',
    title: 'page.businessCases.title',
  },
  routerProps: {
    path: businessCaseRoutes.home,
    element: <BusinessCases />,
  },
  requiredPermission: permissions.businessCaseRead,
  requiredFeatureFlag: featureFlags.ACL_SALES,
  content: [
    {
      id: 'business-cases-list',
      routerProps: {
        path: businessCaseRoutes.home,
        element: <BusinessCases />,
      },
      layoutProps: {
        title: 'page.businessCases.title',
      },
    },
    {
      id: 'business-cases-list-openSearch',
      routerProps: {
        path: businessCaseRoutes.openSearch,
        element: <BusinessCases />,
      },
    },
    {
      id: 'business-cases-complaints-list',
      routerProps: {
        path: businessCaseRoutes.complaintsList,
        element: <BusinessCases />,
      },
      requiredPermission: permissions.complaintRead,
    },
    {
      id: 'business-cases-detail',
      routerProps: {
        paths: [
          businessCaseRoutes.overview,
          businessCaseRoutes.customer,
          businessCaseRoutes.buying,
          businessCaseRoutes.selling,
          businessCaseRoutes.testDrive,
          businessCaseRoutes.reservations,
          businessCaseRoutes.insurance,
          businessCaseRoutes.checkout,
          businessCaseRoutes.documents,
          businessCaseRoutes.activityLog,
        ],
        element: <BusinessCaseDetailWrapper />,
      },
    },
    {
      id: 'business-case-complaints',
      routerProps: {
        path: businessCaseRoutes.complaints,
        element: <BusinessCaseDetailWrapper />,
      },
      requiredPermission: permissions.complaintRead,
    },
    {
      id: 'business-cases-concept',
      routerProps: {
        path: businessCaseRoutes.concept,
        element: <CreateBusinessCase />,
      },
      layoutProps: {
        isPartOfCreateMenuItem: true,
        title: 'entity.businessCase.actions.addBusinessCase',
      },
    },
  ],
};
