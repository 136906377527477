import {DataGrid, QueryFilterObject} from 'platform/datagrid';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

interface RequestsSegmentProps extends RequiredTestIdProps {
  receiveNoteItemId: string | Nullish;
}

export function RequestsSegment(props: RequestsSegmentProps) {
  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {receiveNoteItemId: props.receiveNoteItemId}]),
    [props.receiveNoteItemId]
  );

  return (
    <DataGrid
      gridCode="receive-note-item-requests"
      queryModifier={queryModifier}
      autoHeight
      data-testid={suffixTestId('requests', props)}
    />
  );
}
