import {Button, Card, FormControl, FormField} from 'platform/components';
import {Box, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {Path, useFieldArray, UseFormReturn} from 'react-hook-form';

import {isPositive} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  EMPTY_PLACEHOLDER,
  getOptionsFromServiceCaseOrderVariants,
  useGetServiceCaseOrderVariantsQuery,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {GoodwillFormType} from '../types/GoodwillFormType';

interface NewGoodwillOrderItemsProps extends TestIdProps {
  control: FormControl<GoodwillFormType>;
  formApi: UseFormReturn<GoodwillFormType>;
  serviceCaseId: string;
  requestName: string | Nullish;
  onRatioChange: () => void;
}

export function NewGoodwillOrderItems(props: NewGoodwillOrderItemsProps) {
  const {data: orderVariants, isLoading} = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const {fields, remove, append} = useFieldArray({
    control: props.control,
    name: 'newOrders',
  });

  const getRatioName = (index: number): Path<GoodwillFormType> => `newOrders.${index}.ratio`;
  const getVariantName = (index: number): Path<GoodwillFormType> => `newOrders.${index}.variant`;

  return (
    <VStack spacing={4}>
      {fields.map(({id}, index) => (
        <Card key={id} variant="inlineWhite">
          <HStack>
            <Box flex={1}>
              <VStack spacing={1}>
                <Heading size={4} data-testid={suffixTestId('orderName', props)}>
                  {`${i18n.t('entity.orderRequest.labels.newServiceOrder')}${
                    fields.length > 1 ? ` (${index + 1})` : ''
                  }`}
                </Heading>
                <Text color="tertiary" size="small">
                  {`${i18n.t('general.labels.request')}: ${props.requestName ?? EMPTY_PLACEHOLDER}`}
                </Text>
              </VStack>
            </Box>
            <VStack spacing={1} align="flex-end">
              <Text color="tertiary" size="small">
                {i18n.t('entity.orderRequest.labels.ratio')}
              </Text>
              <Heading
                size={3}
                color={
                  isPositive(props.formApi.watch(getRatioName(index))) ? 'primary' : 'tertiary'
                }
                data-testid={suffixTestId(`[${index}]-orderName`, props)}
              >
                {`${props.formApi.watch(getRatioName(index)) ?? 0} %`}
              </Heading>
            </VStack>
          </HStack>
          <Space vertical={4} />
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                name={getRatioName(index)}
                type="number"
                label={`${i18n.t('entity.orderRequest.labels.ratio')} (%)`}
                onChange={props.onRatioChange}
                data-testid={suffixTestId(`[${index}]-ratio`, props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={props.control}
                name={getVariantName(index)}
                type="choice"
                options={getOptionsFromServiceCaseOrderVariants(orderVariants)}
                isLoading={isLoading}
                label={i18n.t('entity.orderRequest.labels.orderVariant')}
                menuInPortal
                data-testid={suffixTestId(`[${index}]-orderVariant`, props)}
              />
            </Box>
          </HStack>
          <Show when={fields.length > 1}>
            <Space vertical={4} />
            <Button
              title={i18n.t('general.actions.remove')}
              onClick={() => remove(index)}
              variant="dangerLink"
              data-testid={suffixTestId(`[${index}]-removeOrder`, props)}
            />
          </Show>
        </Card>
      ))}
      <Box>
        <Button
          title={i18n.t('entity.orderRequest.actions.addOrder')}
          onClick={() => append({ratio: 0, variant: null})}
          variant="link"
          leftIcon="content/add_circle"
          data-testid={suffixTestId('addOrder', props)}
        />
      </Box>
    </VStack>
  );
}
