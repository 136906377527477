import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {AnyAction} from 'redux';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/shared';

import {VehicleDetail} from '../../types/CommonTypes';
import {
  EditVehicleIds,
  VehicleSliceState,
  VehiclesSliceName,
} from '../../types/SourcingVehicleState';
import {updateTotalVehicleCosts} from '../costs/saga';
import {submitSourcingVehicleSummaryChange} from '../vehicleDetail/reducer';
import {createVehicleActions, resetVehicles} from './actions';
import {createSourcingVehiclesEffects} from './effects';
import {createVehiclesSelectors} from './selectors';

const editVehicles =
  (param: 'hidden' | 'inCart' | 'favourite' | 'comparison', newValue: boolean) =>
  (state: VehicleSliceState, {payload}: PayloadAction<EditVehicleIds>) => {
    const ids = Array.isArray(payload) ? payload : [payload];
    ids.forEach((id) => {
      if (state.vehicles[id]) {
        state.vehicles[id][param] = newValue;
        state.vehicles[id].seen = true;
      }
    });
  };

export const createVehiclesSlice = <V extends VehicleDetail>(
  name: VehiclesSliceName,
  pathToVehicle: string[]
) => {
  const actions = createVehicleActions(name);

  const effects = createSourcingVehiclesEffects(name, actions);

  const selectors = createVehiclesSelectors<V>(name);

  const {setLoading, setEditingVehicle} = actions;

  const {pushVehiclesToSaleRequest} = effects;

  const initialState: VehicleSliceState = {
    vehicles: {},
    hasMoreData: true,
    isLoading: {
      vehicles: true,
      hidden: false,
      cart: false,
      favourite: false,
      comparison: false,
      pushingToSale: false,
      exporting: false,
    },
    editingVehicle: {},
    filteredCount: 0,
  };

  const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(setLoading, (state, {payload}) => {
          state.isLoading[payload.loadingState] = payload.value;
        })
        .addCase(setEditingVehicle, (state, {payload}) => {
          const ids = Array.isArray(payload.id) ? payload.id : [payload.id];
          ids.forEach((id) => {
            state.editingVehicle[id] = {
              ...state.editingVehicle[id],
              [payload.editingState]: payload.value,
            };
          });
        })
        .addCase(resetVehicles, () => initialState)
        .addCase(pushVehiclesToSaleRequest.fulfilled, editVehicles('inCart', false))
        .addMatcher(
          (action): action is AnyAction => action.type === submitSourcingVehicleSummaryChange.type,
          (state, action) => {
            const {vehicleId, summary, vehicleCosts} = action.payload;
            const vehicleSummary = state.vehicles[vehicleId]?.summary;
            const newVehicleCosts = vehicleCosts || vehicleSummary?.vehicleCosts;

            if (vehicleId && state.vehicles[vehicleId]) {
              state.vehicles[vehicleId].vehicleSummary = summary;
              state.vehicles[vehicleId].summary.purchase = summary.purchasePrice;
              state.vehicles[vehicleId].summary.retail =
                (vehicleSummary?.purchase ?? 0) + (vehicleSummary?.margin ?? 0) + newVehicleCosts;
              state.vehicles[vehicleId].summary.vehicleCosts = newVehicleCosts;
            }
          }
        )
        .addMatcher(
          (action): action is AnyAction => action.type === updateTotalVehicleCosts.type,
          (state, action) => {
            const {id, totalCosts} = action;

            if (id && state.vehicles[id]) {
              state.vehicles[id].summary.vehicleCosts = totalCosts;
            }
          }
        );
    },
  });

  const {reducer} = slice;

  return {
    reducer,
    pathToVehicle,
    ...effects,
    ...selectors,
  };
};

// helper class to infer ReturnType with generic parameter
// https://stackoverflow.com/questions/52963637/typescript-is-it-possible-to-get-the-return-type-of-a-generic-function
class Helper<T extends VehicleDetail> {
  ReturnType = createVehiclesSlice<T>(VehiclesSliceName.classifieds, []);
}

export type SourcingVehiclesModel<T extends VehicleDetail = SourcingVehicleDetailResponseBody> =
  Helper<T>['ReturnType'];
