import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {composePath, useRequiredParams} from 'shared';

import {ServiceCaseOverview} from './components/ServiceCaseOverview';
import {ServiceOrderOverview} from './components/ServiceOrderOverview';

export function ServiceCases() {
  const {id} = useRequiredParams();

  const navigationItems = [
    {
      id: 'serviceCases',
      title: i18n.t('page.workshop.labels.serviceCases'),
      content: <ServiceCaseOverview />,
      href: composePath(vehiclesRoutes.serviceCases, {params: {id}}),
    },
    {
      id: 'serviceOrders',
      title: i18n.t('page.workshop.labels.serviceOrders'),
      content: <ServiceOrderOverview />,
      href: composePath(vehiclesRoutes.serviceOrders, {params: {id}}),
    },
  ];

  return <Main data-testid={testIds.vehicles.ownership('main')} tabs={navigationItems} />;
}
