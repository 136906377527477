import {getYear, isAfter, isBefore, isSameDay} from 'date-fns';

import {isNil} from 'ramda';

import {useGetTiresGeneralSettingsQuery} from '@omnetic-dms/shared';

import {getApiDateString} from 'shared';

const getDateByYearMonthDay = (year: number, month: number, day: number) =>
  new Date(year, month, day);

export function useDefaultStoredUntilDate() {
  const {data, isLoading} = useGetTiresGeneralSettingsQuery();
  const currentDate = new Date();
  const currentYear = getYear(currentDate);

  if (!data?.hasAutomaticStoreUntilSettings) {
    return {isLoading, storedUntil: undefined};
  }

  const selected = data?.storeUntilSettings?.find((item) => {
    const fromDate = getDateByYearMonthDay(
      currentYear,
      (item?.receivedAt?.month ?? 1) - 1,
      item?.receivedAt?.day ?? 1
    );
    let toDate = getDateByYearMonthDay(
      currentYear,
      (item?.receivedTo?.month ?? 1) - 1,
      item?.receivedTo?.day ?? 1
    );

    if (isBefore(toDate, fromDate)) {
      toDate = getDateByYearMonthDay(
        currentYear + 1,
        (item?.receivedTo?.month ?? 1) - 1,
        item?.receivedTo?.day ?? 1
      );
    }

    return (
      (isBefore(currentDate, toDate) || isSameDay(currentDate, toDate)) &&
      (isAfter(currentDate, fromDate) || isSameDay(currentDate, fromDate))
    );
  });

  if (isNil(selected)) {
    return {isLoading, storedUntil: undefined};
  }

  let prefiledDate = getDateByYearMonthDay(
    currentYear,
    (selected?.preFillDate?.month ?? 1) - 1,
    selected?.preFillDate?.day ?? 1
  );

  if (isBefore(prefiledDate, currentDate)) {
    prefiledDate = getDateByYearMonthDay(
      currentYear + 1,
      (selected?.preFillDate?.month ?? 1) - 1,
      selected?.preFillDate?.day ?? 1
    );
  }

  return {isLoading, storedUntil: getApiDateString(prefiledDate)};
}
