import {Image} from 'platform/foundation';
import {ThemeIconKey, Icon, Box, Hide, Text, VStack} from 'platform/foundation';

interface RiskIconProps {
  title: string;
  icon: ThemeIconKey;
  isDisabled?: boolean;
}

export function RiskIcon(props: RiskIconProps) {
  return (
    <VStack spacing={3} minWidth={20} align="center">
      <Text size="xxSmall" color="secondary">
        {props.title}
      </Text>
      <Box position="relative" width={10} height={10} opacity={props.isDisabled ? 0.15 : 1}>
        <Icon value={props.icon} size={10} color="general.black" />
        <Hide when={props.isDisabled}>
          <Box position="absolute" width={4} height={4} top={0} left={9}>
            <Image src="assets/images/shield_check.svg" width={4} height={4} />
          </Box>
        </Hide>
      </Box>
    </VStack>
  );
}
