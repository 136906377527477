import {
  Dropdown,
  DropdownItem,
  openDialog,
  closeCurrentDialog,
  openDeleteDialog,
} from 'platform/components';
import {Link} from 'platform/foundation';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useGetParticipationQuery} from '../../../api/participationApi';
import {useUpdateKeyLocationMutation} from '../../../api/salesApi';
import {useGetVehicleQuery} from '../../../api/vehicleApi';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import i18n from '../../../i18n/i18n';
import {EntityResourceIds} from '../../../types/api/api';
import {handleApiError} from '../../../utils/handleApiError';
import {KeyLocationForm} from './KeyLocationForm';

export interface KeyLocationProps extends TestIdProps {
  recordId: string;
}

export function KeyLocation(props: KeyLocationProps) {
  const {data: vehicle} = useGetVehicleQuery({vehicleId: props.recordId});
  const [updateKeyLocation] = useUpdateKeyLocationMutation();

  const {data: vehicleParticipationId} = useGetParticipationQuery({
    recordId: props.recordId,
    resourceId: EntityResourceIds.vehicle,
  });

  const [canChangeVehicleKeyNumber] = usePermissions({
    permissionKeys: ['vehicleChangeKeyNumber'],
    scopes: {
      vehicleChangeKeyNumber: vehicleParticipationId,
    },
  });

  const handleUpdateKeyLocation = async (keyLocation: string | null) => {
    if (!vehicle) {
      return;
    }

    await updateKeyLocation({
      vehicleId: props.recordId,
      updateVehicleKeyLocationRequestBody: {
        keyLocation,
      },
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const openKeyLocationDialog = (defaultValue?: string | Nullish) =>
    openDialog(
      <KeyLocationForm
        onClose={closeCurrentDialog}
        onSubmit={({keyLocation}) => handleUpdateKeyLocation(keyLocation ?? null)}
        keyLocation={defaultValue}
        data-testid={suffixTestId('key-form', props)}
      />,
      {
        size: 'small',
        title: defaultValue
          ? i18n.t('entity.vehicle.labels.editVehicleKey')
          : i18n.t('entity.vehicle.labels.addVehicleKey'),
      }
    );

  const onKeyLocationClick = () => {
    if (vehicle?.keyLocation) {
      return;
    }

    openKeyLocationDialog();
  };

  const handleDelete = () => {
    openDeleteDialog({onConfirm: () => handleUpdateKeyLocation(null)});
  };

  return (
    <Dropdown
      dropdownControl={
        <Link
          size="small"
          leftIcon="communication/vpn_key"
          title={vehicle?.keyLocation ?? i18n.t('entity.vehicle.labels.keyLocation')}
          onClick={onKeyLocationClick}
          isDisabled={!canChangeVehicleKeyNumber}
          data-testid={suffixTestId('key', props)}
        />
      }
      isDisabled={!canChangeVehicleKeyNumber}
      data-testid={suffixTestId('key-dropdown', props)}
      isOpen={vehicle?.keyLocation ? undefined : false}
    >
      <DropdownItem
        label={i18n.t('general.actions.edit')}
        onClick={() => openKeyLocationDialog(vehicle?.keyLocation)}
        data-testid={suffixTestId('key-dropdown-edit', props)}
      />
      <DropdownItem
        label={i18n.t('general.actions.delete')}
        onClick={handleDelete}
        data-testid={suffixTestId('key-dropdown-delete', props)}
      />
    </Dropdown>
  );
}
