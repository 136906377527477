import {Action, Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';

import {customerRoutes} from '@omnetic-dms/routes';

import {buildArray, composePath, TestIdProps} from 'shared';

import {useGetCustomerV2Query} from '../../api/customerApi';
import {useEditCustomer} from '../../hooks/useEditCustomer';
import i18n from '../../i18n/i18n';
import {CustomerInformationAttributes} from '../CustomerInformationAttributes/CustomerInformationAttributes';

interface CustomerInformationCardProps extends TestIdProps {
  customerId: string;
  isDetailLinkVisible?: boolean;
  onChange?: VoidFunction;
  isEditingDisabled?: boolean;
  isCustomerChangeDisabled?: boolean;
  isChangeCustomerForbidden?: boolean;
}

export function CustomerInformationCard(props: CustomerInformationCardProps) {
  const {data, isLoading, isError} = useGetCustomerV2Query({customerId: props.customerId});

  const editCustomer = useEditCustomer(data);

  const actions = buildArray<Action>()
    .when(props.isDetailLinkVisible, {
      type: 'button',
      variant: 'link',
      title: i18n.t('general.labels.detail'),
      leftIcon: 'action/open_in_new',
      isDisabled: isNil(data),
      onClick: () =>
        window.open(
          composePath(customerRoutes.detail, {
            params: {id: props.customerId},
            isSearchParamsPreserved: false,
          })
        ),
    })
    .when(!props.isChangeCustomerForbidden && isNotNil(props.onChange), {
      type: 'button',
      variant: 'link',
      title: i18n.t('general.actions.change'),
      leftIcon: 'navigation/refresh',
      isDisabled: isNil(data) || props.isCustomerChangeDisabled,
      onClick: props.onChange,
    })
    .whenNot(props.isChangeCustomerForbidden, {
      type: 'button',
      variant: 'link',
      title: i18n.t('general.actions.edit'),
      leftIcon: 'editor/mode',
      isDisabled: isNil(data) || props.isEditingDisabled,
      onClick: editCustomer,
      ...{'data-testid': props['data-testid']},
    });

  return (
    <Card
      title={i18n.t('entity.customer.labels.customerInformation')}
      actions={actions}
      data-testid={props['data-testid']}
    >
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        minHeight={90}
        data-testid={props['data-testid']}
      >
        <VStack spacing={2}>
          {data && (
            <CustomerInformationAttributes customer={data} data-testid={props['data-testid']} />
          )}
        </VStack>
      </DataStatus>
    </Card>
  );
}
