import {BaseVoidRequestSchema} from '../types/api/base';
import {
  GetMinorUnitMaterialApiResponse,
  GetMinorUnitMaterialApiArg,
  PutMinorUnitMaterialApiResponse,
  PutMinorUnitMaterialApiArg,
  GetMinorUnitMaterialApiResponseSchema,
  PutMinorUnitMaterialApiResponseSchema,
  PutMinorUnitMaterialApiArgSchema,
} from '../types/api/metadaWorkshopMinorUnitMaterial';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopMinorUnitMaterialApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getMinorUnitMaterial: build.query<GetMinorUnitMaterialApiResponse, GetMinorUnitMaterialApiArg>({
      query: () => ({
        url: `service/v1/minor-unit-material`,
      }),
      providesTags: ['minorUnitMaterial'],
      extraOptions: {
        responseSchema: GetMinorUnitMaterialApiResponseSchema,
        requestSchema: BaseVoidRequestSchema,
      },
    }),
    putMinorUnitMaterial: build.mutation<
      PutMinorUnitMaterialApiResponse,
      PutMinorUnitMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/minor-unit-material`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['minorUnitMaterial'],
      extraOptions: {
        responseSchema: PutMinorUnitMaterialApiResponseSchema,
        requestSchema: PutMinorUnitMaterialApiArgSchema,
      },
    }),
  }),
});

export const {useGetMinorUnitMaterialQuery, usePutMinorUnitMaterialMutation} =
  metadaWorkshopMinorUnitMaterialApi;
