import {Nullish} from 'shared';

import {getEvaluationTranslations} from '../constants/evaluation';
import {i18n} from '../i18n/i18n';

export function getEvaluationTooltipLabel(evaluation: string | Nullish): string {
  return evaluation
    ? getEvaluationTranslations()[Number(evaluation)]
    : i18n.t('entity.condition.labels.statusUnknown');
}
