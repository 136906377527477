import {useRef} from 'react';

import {DataGridRef} from '../types/DataGridRef';

/**
 * @about Hook used to refresh DG data without re-rendering the component
 * @returns Ref object and refresh function
 * @example
 * const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
 */
export const useRefreshDataGrid = () => {
  const ref = useRef<DataGridRef>(null);

  const refreshData = () => {
    if (!ref?.current) {
      throw new Error('Ref object has not been assigned');
    }
    ref.current.refreshData();
  };

  return [ref, refreshData] as const;
};
