import {Label, Separator} from 'platform/components';
import {GridItem, Text, Heading, Show, VStack, Grid, Box} from 'platform/foundation';
import {useDateTimeFormatter, useFormatNumber} from 'platform/locale';
import {match} from 'ts-pattern';

import {defaultTo, isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {getNaturalPersonFullName, GetServiceCheckInApiResponse} from '@omnetic-dms/shared';

import {parseDate, Nullish, EMPTY_PLACEHOLDER, suffixTestId, TestIdProps} from 'shared';

interface CheckInHandOverParameterProps extends TestIdProps {
  data?: GetServiceCheckInApiResponse;
  isCheckin?: boolean;
}

export function CheckInHandOverParameter(props: CheckInHandOverParameterProps) {
  const formatDateTime = useDateTimeFormatter();
  const formatNumber = useFormatNumber();

  return (
    <VStack spacing={4}>
      <Heading size={4}>{i18n.t('entity.workshop.labels.vehicleDriver')}</Heading>
      <Grid columns={[2, 2, 4, 4]}>
        <GridItem>
          <Label>{i18n.t('general.labels.nameAndSurname')}</Label>
          <Text data-testid={suffixTestId('driverName', props)}>
            {getNaturalPersonFullName({
              firstName: props.data?.driverFirstname,
              lastName: props.data?.driverLastname,
            })}
          </Text>
        </GridItem>
        <GridItem>
          <Label>{i18n.t('entity.phoneNumber.labels.number')}</Label>
          <Text data-testid={suffixTestId('driverPhoneNumber', props)}>
            {defaultTo(
              EMPTY_PLACEHOLDER,
              props.data?.driverPhoneNo &&
                `${props.data?.driverPhonePrefix} ${props.data?.driverPhoneNo}`
            )}
          </Text>
        </GridItem>
        <GridItem>
          <Label>{i18n.t('general.labels.emailAddress')}</Label>
          <Text data-testid={suffixTestId('driverEmail', props)}>
            {defaultTo(EMPTY_PLACEHOLDER, props.data?.driverEmail)}
          </Text>
        </GridItem>
        <GridItem>
          <Label>{i18n.t('general.labels.identityCard')}</Label>
          <Text data-testid={suffixTestId('driverIdentityCard', props)}>
            {defaultTo(EMPTY_PLACEHOLDER, props.data?.driverIdentityCard)}
          </Text>
        </GridItem>
      </Grid>
      <Separator spacing={0} />
      <Heading size={4}>{i18n.t('entity.workshop.labels.vehicleInformation')}</Heading>
      <Grid columns={[2, 2, 4, 4]}>
        <GridItem>
          <Label>{i18n.t('entity.vehicle.labels.actualMileage')}</Label>
          <Text data-testid={suffixTestId('mileage', props)}>
            {defaultTo(EMPTY_PLACEHOLDER, formatNumber(props.data?.mileage))}
          </Text>
        </GridItem>
        <GridItem>
          <Label>{i18n.t('entity.workshop.labels.technicalInspectionValidUntil')}</Label>
          <Text data-testid={suffixTestId('technicalInspection', props)}>
            {defaultTo(
              EMPTY_PLACEHOLDER,
              props.data?.technicalInspection &&
                formatDateTime('dateShort', parseDate(props.data?.technicalInspection))
            )}
          </Text>
        </GridItem>
        <GridItem>
          <Label>{i18n.t('entity.workshop.labels.emissionsValidUntil')}</Label>
          <Text data-testid={suffixTestId('emissions', props)}>
            {defaultTo(
              EMPTY_PLACEHOLDER,
              props.data?.emissions && formatDateTime('dateShort', parseDate(props.data?.emissions))
            )}
          </Text>
        </GridItem>
        <GridItem>
          <Label>{i18n.t('entity.workshop.labels.fuelTank')}</Label>
          <Text data-testid={suffixTestId('fuelTank', props)}>
            {formatFuelTank(props.data?.fuelTank)}
          </Text>
        </GridItem>
      </Grid>
      <Show when={props.isCheckin}>
        <Grid columns={[2, 2, 4, 4]}>
          <GridItem>
            <Label>{i18n.t('entity.order.labels.consentToTestDrive')}</Label>
            <Text data-testid={suffixTestId('consentToTestDrive', props)}>
              {props.data?.consentToTestDrive
                ? i18n.t('general.labels.yes')
                : i18n.t('general.labels.no')}
            </Text>
          </GridItem>
          <GridItem>
            <Label>{i18n.t('entity.order.labels.vehicleIsNotInService')}</Label>
            <Text data-testid={suffixTestId('vehicleIsNotInService', props)}>
              {props.data?.isVehicleNotInService
                ? i18n.t('general.labels.yes')
                : i18n.t('general.labels.no')}
            </Text>
          </GridItem>
        </Grid>
      </Show>
      <Separator spacing={0} />
      <Box>
        <Label>{i18n.t('general.labels.note')}</Label>
        <Text data-testid={suffixTestId('note', props)}>
          {defaultTo(EMPTY_PLACEHOLDER, props.data?.note)}
        </Text>
      </Box>
    </VStack>
  );
}

const formatPercentage = (value: number | Nullish) => {
  if (isNil(value)) {
    return;
  }

  return `${Math.round(value * 100)} %`;
};

const formatFuelTank = (value: number | Nullish) => {
  if (isNil(value)) {
    return;
  }

  const roundedValue = Math.round(value / 0.25) * 0.25;

  return match(roundedValue)
    .with(0, () => 'E')
    .with(0.25, () => '1/4')
    .with(0.5, () => '1/2')
    .with(0.75, () => '3/4')
    .with(1, () => 'F')
    .otherwise(formatPercentage);
};
