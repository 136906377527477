import {Grid, GridItem} from 'platform/foundation';

import {ElementRenderer} from '../components/ElementRenderer';
import {FullSchema} from '../types/FullSchema';
import {RowRendererResponseBody} from '../types/modelSchema';

const DEFAULT_COLUMNS = 4;

export function RowRenderer(props: RowRendererResponseBody) {
  return (
    <Grid spacing={1} columns={props.columns ?? DEFAULT_COLUMNS}>
      {props.elements?.map(({columnSpan, ...element}) => (
        <GridItem key={element.name} span={columnSpan ?? undefined}>
          <ElementRenderer
            rendererRegistry={props.rendererRegistry}
            schema={element as FullSchema}
          />
        </GridItem>
      ))}
    </Grid>
  );
}
