import {addMonths, Day, isAfter, isBefore, isSameMonth, startOfMonth} from 'date-fns';
import {Box, Grid, Hide, HStack, Scroll, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';

import {isNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {Placement} from '../../types/Placement';
import {useAnimatedPopper} from '../AnimatedPopper/hooks/useAnimatedPopper';
import {Button} from '../Button/Button';
import {CalendarFormValue, DisabledDate} from '../Calendar/types';
import {Label} from '../Label/Label';
import {Separator} from '../Separator/Separator';
import {TextInput} from '../TextInput/TextInput';
import {useTranslationContext} from '../TranslationProvider/TranslationContext';
import {DateRangePickerCalendar} from './components/DateRangePickerCalendar';
import {DateRangePickerRelativeDateRanges} from './components/DateRangePickerRelativeDateRanges';
import {RelativeDateRange} from './types';
import {getDefaultRelativeDateRanges} from './utils/getDefaultRelativeDateRanges';
import {getInitialActiveMonths} from './utils/getInitialActiveMonths';
import {isEnabledDateRange} from './utils/isEnabledDateRange';

export interface DateRangePickerProps extends FormControlProps<null | [Date, Date]>, TestIdProps {
  relativeDateRanges?: RelativeDateRange[];
  isRelativeDateRangesHidden?: boolean;
  disabledWeekdays?: Day[];
  /** disabled days of week, 0 = Sunday */
  disabledDates?: DisabledDate[];
  minDate?: Date;
  maxDate?: Date;
  placement?: Placement;
}

export function DateRangePicker(props: DateRangePickerProps) {
  const t = useTranslationContext();
  const formatDate = useDateTimeFormatter();

  const {popperProps, Popper, openPopper, referenceRef, closePopper} = useAnimatedPopper({
    gutter: 4,
    placement: props.placement ?? 'bottom-start',
  });

  const [activeMonthFrom, setActiveMonthFrom] = useState(
    () => getInitialActiveMonths(props.value, props.minDate, props.maxDate)[0]
  );
  const [activeMonthTo, setActiveMonthTo] = useState(() => getInitialActiveMonths(props.value)[1]);

  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [incompleteValue, setIncompleteValue] = useState<null | [Date | null, Date | null]>(null);

  const onChange = (value: CalendarFormValue) => {
    if (!Array.isArray(value)) {
      return;
    }

    if (value[0] && value[1]) {
      setIncompleteValue(null);
      return props.onChange?.([value[0], value[1]]);
    }

    setIncompleteValue(value);
  };

  const onChangeRelativeDate = (value: [Date, Date] | null) => {
    setIncompleteValue(null);
    if (!isNil(value)) {
      setActiveMonthFrom(startOfMonth(value[0]));
      setActiveMonthTo(startOfMonth(value[1]));
    }
    props.onChange?.(value);
  };

  const onChangeActiveMonthFrom = (date: Date) => {
    setActiveMonthFrom(date);
    if (isSameMonth(date, activeMonthTo) || isAfter(date, activeMonthTo)) {
      setActiveMonthTo(addMonths(date, 1));
    }
  };

  const onChangeActiveMonthTo = (date: Date) => {
    setActiveMonthTo(date);
    if (isSameMonth(date, activeMonthFrom) || isBefore(date, activeMonthFrom)) {
      setActiveMonthFrom(addMonths(date, -1));
    }
  };

  const onOpen = () => {
    const [from, to] = getInitialActiveMonths(props.value, props.minDate, props.maxDate);
    setActiveMonthFrom(from);
    setActiveMonthTo(to);
    setIncompleteValue(null);
    openPopper();
  };

  const onClear = () => {
    props.onChange?.(null);
    closePopper();
  };

  const canOpenPopper = !props.isDisabled;

  const isVisibleOnlyOneCalendar =
    !!props.minDate && !!props.maxDate && isSameMonth(props.minDate, props.maxDate);

  const relativeDateRanges = props.isRelativeDateRangesHidden
    ? []
    : props.relativeDateRanges ?? getDefaultRelativeDateRanges(t);
  const enabledRelativeDateRanges = relativeDateRanges.filter((relativeDateRange) =>
    isEnabledDateRange(relativeDateRange.value, props.minDate, props.maxDate)
  );

  return (
    <>
      <VStack>
        <Show when={props.label}>
          <Label>{props.label}</Label>
        </Show>
        <Grid columns={2} spacing={4}>
          <div ref={referenceRef}>
            <TextInput
              leftIcon="action/date_range"
              onClick={canOpenPopper ? onOpen : undefined}
              value={props.value?.[0] ? formatDate('dateShort', props.value[0]) : ''}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              isDisabled={props.isDisabled}
              placeholder={t('general.labels.select')}
              data-testid={suffixTestId('inputFrom', props)}
            />
          </div>
          <TextInput
            leftIcon="action/date_range"
            onClick={canOpenPopper ? onOpen : undefined}
            value={props.value?.[1] ? formatDate('dateShort', props.value[1]) : ''}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            isDisabled={props.isDisabled}
            placeholder={t('general.labels.select')}
            data-testid={suffixTestId('inputTo', props)}
          />
        </Grid>
      </VStack>
      <Popper {...popperProps}>
        <Box
          borderRadius="medium"
          boxShadow="elevation_2"
          border="1px solid"
          borderColor="palettes.neutral.40.100"
          backgroundColor="general.white"
        >
          <VStack>
            <HStack>
              <Show when={enabledRelativeDateRanges.length > 0}>
                <Scroll maxHeight={95} auto>
                  <Box padding={4}>
                    <DateRangePickerRelativeDateRanges
                      relativeDateRanges={enabledRelativeDateRanges}
                      value={props.value}
                      onChange={onChangeRelativeDate}
                      data-testid={props['data-testid']}
                    />
                  </Box>
                </Scroll>
                <Separator orientation="vertical" spacing={0} />
              </Show>
              <DateRangePickerCalendar
                activeMonth={activeMonthFrom}
                onChangeActiveMonth={onChangeActiveMonthFrom}
                value={incompleteValue ?? props.value}
                onChange={onChange}
                hoveredDate={hoveredDate}
                onHoverDate={setHoveredDate}
                disabledWeekdays={props.disabledWeekdays}
                disabledDates={props.disabledDates}
                minDate={props.minDate}
                maxDate={props.maxDate}
                calendarControlMaxDate={
                  props.maxDate &&
                  (isVisibleOnlyOneCalendar
                    ? startOfMonth(props.maxDate)
                    : addMonths(startOfMonth(props.maxDate), -1))
                }
                data-testid={suffixTestId('calendarFrom', props)}
              />
              <Hide when={isVisibleOnlyOneCalendar}>
                <Separator orientation="vertical" spacing={0} />
                <DateRangePickerCalendar
                  activeMonth={activeMonthTo}
                  onChangeActiveMonth={onChangeActiveMonthTo}
                  value={incompleteValue ?? props.value}
                  onChange={onChange}
                  hoveredDate={hoveredDate}
                  onHoverDate={setHoveredDate}
                  disabledWeekdays={props.disabledWeekdays}
                  disabledDates={props.disabledDates}
                  minDate={props.minDate}
                  maxDate={props.maxDate}
                  calendarControlMinDate={
                    props.minDate && addMonths(startOfMonth(props.minDate), 1)
                  }
                  data-testid={suffixTestId('calendarTo', props)}
                />
              </Hide>
            </HStack>
            <Separator spacing={0} />
            <Box padding={4}>
              <HStack justify="flex-end" spacing={2}>
                <Button
                  variant="link"
                  onClick={onClear}
                  title={t('general.actions.clearDate')}
                  data-testid={suffixTestId('buttonClear', props)}
                />
                <Button
                  onClick={closePopper}
                  title={t('general.actions.setDate')}
                  data-testid={suffixTestId('buttonSetDate', props)}
                />
              </HStack>
            </Box>
          </VStack>
        </Box>
      </Popper>
    </>
  );
}
