import {isNotNil} from 'ramda-adjunct';

import {CountryResponseBody, useGetCurrentUserInfoQuery} from '@omnetic-dms/shared';

export function useSourcingTargetCountry(): CountryResponseBody | null {
  const {data} = useGetCurrentUserInfoQuery();

  const countryCode = data?.settings.country;

  return isNotNil(countryCode)
    ? {
        name: countryCode,
        code: countryCode,
        preferred: false,
      }
    : null;
}
