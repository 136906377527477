import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {SeriesResponseBody, SeriesType} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getOptionsFromSeries = (
  series: SeriesResponseBody[] | Nullish,
  type: SeriesType
): Option[] => {
  if (isNil(series)) {
    return [];
  }

  return series
    .filter((serie) => serie.type === type)
    .map((serie) => ({
      label: serie.name,
      value: serie.id,
    }));
};
