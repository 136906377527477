import {Attributes, Card, DataStatus} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {CommentsWidget, EntityResourceIds, useGetVehicleV2Query} from '@omnetic-dms/shared';

import {RequiredTestIdProps, composePath, parseDate, suffixTestId, useRequiredParams} from 'shared';

import {VehicleServiceIntervalFluidAttributes} from './VehicleServiceIntervalFluidAttributes';
import {VehicleServiceIntervalReplacementAttributes} from './VehicleServiceIntervalReplacementAttributes';

interface VehicleServiceIntervalsProps extends RequiredTestIdProps {}

export function VehicleServiceIntervals(props: VehicleServiceIntervalsProps) {
  const {id: vehicleId} = useRequiredParams();
  const vehicleV2Query = useGetVehicleV2Query({vehicleId});

  const formatDateTime = useDateTimeFormatter();
  const navigate = useNavigate();

  const serviceInspection = vehicleV2Query.data?.serviceIntervals?.serviceInspection;
  const engineOil = vehicleV2Query.data?.serviceIntervals?.engineOil;
  const gearboxOil = vehicleV2Query.data?.serviceIntervals?.gearboxOil;
  const fourByFourOilDistributor = vehicleV2Query.data?.serviceIntervals?.fourByFourOilDistributor;
  const timingBelt = vehicleV2Query.data?.serviceIntervals?.timingBelt;
  const brakeFluid = vehicleV2Query.data?.serviceIntervals?.brakeFluid;
  const coolantFluid = vehicleV2Query.data?.serviceIntervals?.coolantFluid;
  const annualMileage = vehicleV2Query.data?.serviceIntervals?.annualMileage;

  const handleEditVehicle = () => {
    navigate(
      composePath(vehiclesRoutes.editServiceVehicle, {
        params: {id: vehicleId},
        queryParams: {tab: 'service-intervals'},
      })
    );
  };

  return (
    <DataStatus isLoading={vehicleV2Query.isLoading} isError={vehicleV2Query.isError}>
      <Grid columns={2}>
        <GridItem>
          <Card
            title={i18n.t('entity.vehicle.labels.serviceIntervals')}
            actions={[
              {
                type: 'button',
                variant: 'link',
                size: 'small',
                leftIcon: 'image/edit',
                onClick: handleEditVehicle,
                'data-testid': suffixTestId('edit', props),
                title: i18n.t('general.actions.edit'),
              },
            ]}
          >
            <VStack spacing={4}>
              <Heading size={4}>{i18n.t('entity.vehicle.labels.serviceInspection')}</Heading>
              <Attributes
                rows={[
                  {
                    label: i18n.t('entity.vehicle.labels.dateOfLastInspection'),
                    value: serviceInspection?.lastServiceInspectionDate
                      ? formatDateTime(
                          'dateShort',
                          parseDate(serviceInspection?.lastServiceInspectionDate)
                        )
                      : null,
                  },
                  {
                    label: i18n.t('entity.vehicle.labels.replacementAfterMonths'),
                    value: serviceInspection?.replacementAfterMonths,
                  },
                  {
                    label: i18n.t('entity.vehicle.labels.dateOfNextServiceInspection'),
                    value: serviceInspection?.nextServiceInspectionDate
                      ? formatDateTime(
                          'dateShort',
                          parseDate(serviceInspection?.nextServiceInspectionDate)
                        )
                      : null,
                  },
                  {
                    label: `${i18n.t('entity.vehicle.labels.lastServiceInspection')} (${i18n.t('general.metric.km')})`,
                    value: serviceInspection?.lastServiceInspectionMileage,
                  },
                  {
                    label: `${i18n.t('entity.vehicle.labels.replacementAfterMileage')} (${i18n.t('general.metric.km')})`,
                    value: serviceInspection?.replacementAfterMileage,
                  },
                  {
                    label: `${i18n.t('entity.vehicle.labels.nextServiceInspection')} (${i18n.t('general.metric.km')})`,
                    value: serviceInspection?.nextServiceInspectionMileage,
                  },
                ]}
              />
              <Heading size={4}>{i18n.t('entity.vehicle.labels.engineOil')}</Heading>
              <VehicleServiceIntervalReplacementAttributes
                part={engineOil}
                data-testid={suffixTestId('engineOil', props)}
              />
              <Heading size={4}>{i18n.t('entity.vehicle.labels.gearboxOil')}</Heading>
              <VehicleServiceIntervalReplacementAttributes
                part={gearboxOil}
                data-testid={suffixTestId('gerboxOil', props)}
              />
              <Heading size={4}>{i18n.t('entity.vehicle.labels.fourByFourOilDistributor')}</Heading>
              <VehicleServiceIntervalReplacementAttributes
                part={fourByFourOilDistributor}
                data-testid={suffixTestId('fourByFourOilDistributor', props)}
              />
              <Heading size={4}>{i18n.t('entity.vehicle.labels.timingBelt')}</Heading>
              <VehicleServiceIntervalReplacementAttributes
                part={timingBelt}
                data-testid={suffixTestId('timingBelt', props)}
              />
              <Heading size={4}>{i18n.t('entity.vehicle.labels.brakeFluid')}</Heading>
              <VehicleServiceIntervalFluidAttributes
                part={brakeFluid}
                data-testid={suffixTestId('brakeFluid', props)}
              />
              <Heading size={4}>{i18n.t('entity.vehicle.labels.coolantFluid')}</Heading>
              <VehicleServiceIntervalFluidAttributes
                part={coolantFluid}
                data-testid={suffixTestId('coolantFluid', props)}
              />
              <Heading size={4}>{i18n.t('entity.vehicle.labels.annualMileage')}</Heading>
              <Attributes
                rows={[
                  {
                    label: `${i18n.t('entity.vehicle.labels.serviceVisits')} (${i18n.t('general.metric.km')})`,
                    value: annualMileage?.calculatedFromServiceVisits,
                  },
                ]}
              />
            </VStack>
          </Card>
        </GridItem>
        <GridItem>
          <CommentsWidget
            title={i18n.t('entity.vehicle.labels.vehicleComments')}
            resourceId={EntityResourceIds.vehicle}
            recordId={vehicleId}
          />
        </GridItem>
      </Grid>
    </DataStatus>
  );
}
