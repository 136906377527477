import {ActionCallback, DataGrid, ExternalFilters} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {useEffect} from 'react';

import {isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  catchUnhandledDataGridActions,
  GetSupplierOrderResponse,
  useWarehouseDataGridTreeFolder,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {FullscreenDataGridWrapper} from '../../../../../components/FullscreenDataGridWrapper';
import {MaterialPurchaseListTabExternalFilters} from './MaterialPurchaseListTabExternalFilters';

const SUPPLIER_ORDER_PURCHASE_LIST_FILTER_ID = 'supplier-order-purchase-list-filter';

interface MaterialPurchaseListTabProps extends TestIdProps {
  onAddMaterialFromPurchaseListToBasket: (requestItemId: string[]) => Promise<void>;
  onActiveFolderIdChange?: (activeFolderId?: string) => void;
  onExternalFilterChange: (filters: ExternalFilters) => void;
  supplierOrder: GetSupplierOrderResponse;
}

export const MaterialPurchaseListTab = (props: MaterialPurchaseListTabProps) => {
  const [folderTreeRef, dataGridModifier, activeFolderId] = useWarehouseDataGridTreeFolder({
    warehouseId: props.supplierOrder.order.warehouseId,
  });

  useEffect(() => {
    props.onActiveFolderIdChange?.(activeFolderId);
  }, [activeFolderId]);

  const warehouseActionCallback: ActionCallback = ({actionKey, refreshData, rowId}) => {
    const ids = isArray(rowId) ? rowId : [rowId];

    if (isNil(ids)) {
      throw new Error('Selected ids are not defined');
    }

    match(actionKey)
      .with('addToBasket', () => props.onAddMaterialFromPurchaseListToBasket(ids).then(refreshData))
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper
      externalFilterId={SUPPLIER_ORDER_PURCHASE_LIST_FILTER_ID}
      externalFilterHeight={90}
      folderTreeRef={folderTreeRef}
      hideCardWrapper
      data-testid={props['data-testid']}
    >
      <DataGrid
        // DG must be re-rendered on folder change to update the query modifier
        // eslint-disable-next-line no-restricted-syntax
        key={dataGridModifier?.key}
        gridCode="warehouse-supplier-order-purchase-list-cart"
        actionCallback={warehouseActionCallback}
        externalFilterId={SUPPLIER_ORDER_PURCHASE_LIST_FILTER_ID}
        queryModifier={dataGridModifier?.queryModifier}
        _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
        data-testid={suffixTestId('warehouseSupplierOrderPurchaseListBasket', props)}
        filterComponent={MaterialPurchaseListTabExternalFilters}
        onExternalFilterChange={props.onExternalFilterChange}
      />
    </FullscreenDataGridWrapper>
  );
};
