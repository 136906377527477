import {
  PutServiceCaseActionApiResponse,
  PutServiceCaseActionApiArg,
  GetServiceCaseActionsApiResponse,
  GetServiceCaseActionsApiArg,
  PutServiceCaseActionApiResponseSchema,
  PutServiceCaseActionApiArgSchema,
  GetServiceCaseActionsApiResponseSchema,
  GetServiceCaseActionsApiArgSchema,
} from '../types/api/metadaWorkshopServiceCaseActions';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceCaseActionsApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    putServiceCaseAction: build.mutation<
      PutServiceCaseActionApiResponse,
      PutServiceCaseActionApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/action`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        'serviceOrderMandatoryFields',
        'serviceOrders',
        'serviceOrder',
        'serviceOrderJobs',
        'checkout',
      ],
      extraOptions: {
        responseSchema: PutServiceCaseActionApiResponseSchema,
        requestSchema: PutServiceCaseActionApiArgSchema,
      },
    }),
    getServiceCaseActions: build.query<
      GetServiceCaseActionsApiResponse,
      GetServiceCaseActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/actions`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: GetServiceCaseActionsApiResponseSchema,
        requestSchema: GetServiceCaseActionsApiArgSchema,
      },
    }),
  }),
});

export const {usePutServiceCaseActionMutation, useGetServiceCaseActionsQuery} =
  metadaWorkshopServiceCaseActionsApi;
