import {defaultTo, find, map, pathOr, pipe} from 'ramda';

import {Nullish} from 'shared';

import {useGetVatRatesQuery} from '../api/cachedApi';
import {useGetTenantQuery} from '../api/tenantApi';
import {VatRate} from '../types/cached/VatRates';

type VatRateOption = {
  value: string;
  label: string;
  fieldLabel: string;
  rate: string;
  type: string;
};

export function useGetVatRatesOptions() {
  const {data: tenant} = useGetTenantQuery();
  const {vatOptions} = useGetVatRatesQuery(undefined, {
    selectFromResult: ({data}) => ({
      vatOptions: pipe<
        [VatRate[] | Nullish],
        VatRate[],
        VatRate | undefined,
        VatRate['rates'],
        VatRateOption[]
      >(
        defaultTo([]),
        find<VatRate>(({code}) => code === tenant?.country),
        pathOr([], ['rates']),
        map((vatRate) => ({
          value: vatRate.type,
          label: `${vatRate.rate} % ${vatRate.name}`,
          fieldLabel: `${vatRate.rate} %`,
          rate: vatRate.rate,
          type: vatRate.type,
        }))
      )(data),
    }),
  });
  return [vatOptions];
}
