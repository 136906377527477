/* eslint-disable @typescript-eslint/no-explicit-any */
import {TextInput} from 'platform/components';
import {Box, Grid, GridItem, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';
import styled, {css} from 'styled-components';

import {FieldRenderProps} from 'react-final-form';
import {useSelector} from 'react-redux';

import {path} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {selectTenant} from '@omnetic-dms/shared';
import {
  AdvertisingAttributeRequestBody,
  AnyObject,
  noop,
  PriceExtra,
  PriceValueType,
  useFormRenderer,
  VehicleAdvertisingAttributeRequestBody,
} from '@omnetic-dms/teas';

import {CurrencyCodeType} from 'shared';

import {usePriceCalculation} from '../hooks/usePriceCalculation';
import {PriceField} from './PriceField';

type AttributeProps = {
  name: string;
  isDisabled: boolean;
};

const SecondPrice = ({
  input: {value},
  isDisabled,
}: FieldRenderProps<VehicleAdvertisingAttributeRequestBody> & {isDisabled?: boolean}) => {
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const secondPrice = usePriceCalculation(
    value.value as PriceValueType,
    (value.extra as PriceExtra)?.countryCode
  );
  const currencyValue = (value.value as PriceValueType)?.value?.currency;

  return (value.extra as PriceExtra | undefined)?.vatDeductible ? (
    <GridItem span={12}>
      <Grid columns={12}>
        <GridItem span={6} />
        <GridItem span={6}>
          <Box width="100%" paddingBottom={4} position="relative">
            <TextInput
              label={
                (value.value as PriceValueType)?.withVat
                  ? i18n.t('general.labels.priceWithoutVat')
                  : i18n.t('general.labels.priceWithVat')
              }
              value={secondPrice}
              onChange={noop}
              suffix={formatCurrencySymbol(currencyValue as CurrencyCodeType)}
              isDisabled={!value.override || isDisabled}
              data-testid={testIds.vehicles.publishing('secondPrice')}
            />
          </Box>
        </GridItem>
      </Grid>
    </GridItem>
  ) : null;
};

export function AdvertisingAttributeField({name, isDisabled}: AttributeProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {data: {currency: defaultCurrency} = {}} = useSelector(selectTenant);

  const {
    Field,
    Subscribe,
    form: {getState},
  } = useFormRenderer<AdvertisingAttributeRequestBody, AdvertisingAttributeRequestBody, AnyObject>({
    subscription: {initialValues: true},
  });

  const attribute = path(
    name.split(/\.|\[|\]/).filter((v) => !!v),
    getState().values
  ) as AdvertisingAttributeRequestBody;

  const {title, required, type, extra, code, hasPublishControl} = attribute;
  const label = required ? `* ${title}` : title;

  switch (code) {
    case 'vin':
      return (
        <GridItem span={12}>
          <Grid columns={12}>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="switch"
                  disabled={!hasPublishControl || isDisabled}
                  name={[name, 'publish'] as any}
                  label={label}
                />
              </Box>
            </GridItem>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="checkbox"
                  disabled={isDisabled}
                  name={[name, 'override'] as any}
                  // TODO: FIXME translation
                  label={i18n.t('entity.vehicle.labels.override')}
                />
              </Box>
            </GridItem>
            <GridItem span={6}>
              <VStack>
                <Subscribe
                  name={[name, 'override'] as any}
                  component={({input: {value: override}}) => (
                    <Field
                      as="text"
                      name={[name, 'value'] as any}
                      disabled={!override || isDisabled}
                      maxLength={17}
                      format={(value) => value?.toUpperCase() || ''}
                      parse={(value) => value?.toUpperCase() || ''}
                    />
                  )}
                />
                <Subscribe
                  name={[name, 'override'] as any}
                  component={({input: {value: override}}) => (
                    <Field
                      name={[name, 'extra', 'hidden'] as any}
                      as="checkbox"
                      disabled={!override || isDisabled}
                      label={i18n.t('entity.vehicle.labels.hiddenVin')}
                    />
                  )}
                />
              </VStack>
            </GridItem>
          </Grid>
        </GridItem>
      );
    default:
      break;
  }

  switch (type) {
    case 'financing_price':
      return (
        <GridItem span={12}>
          <Grid columns={12}>
            <Field as="hidden" name={[name, 'value.vatRate'] as any} defaultValue={extra.vatRate} />
            <Field
              as="hidden"
              name={[name, 'value.value.currency'] as any}
              defaultValue={defaultCurrency}
            />
            <GridItem span={12}>
              <Grid columns={12}>
                <GridItem span={3}>
                  <Box paddingTop={2}>
                    <Field
                      as="switch"
                      disabled={!hasPublishControl || isDisabled}
                      name={[name, 'publish'] as any}
                      label={label}
                    />
                  </Box>
                </GridItem>
                <GridItem span={3}>
                  <Box paddingTop={2}>
                    <Field
                      as="checkbox"
                      disabled={isDisabled}
                      name={[name, 'override'] as any}
                      // FIXME translation
                      label={i18n.t('entity.vehicle.labels.override')}
                    />
                  </Box>
                </GridItem>
                <GridItem span={6}>
                  <Subscribe<VehicleAdvertisingAttributeRequestBody>
                    name={[name] as any}
                    component={({input: {value}}) => {
                      const currencyValue = (value.value as PriceValueType)?.value?.currency;
                      const {override} = value;
                      return (
                        <PriceField
                          name={[name, 'value.value.amount'] as any}
                          suffix={formatCurrencySymbol(currencyValue as CurrencyCodeType)}
                          disabled={!override || isDisabled}
                        />
                      );
                    }}
                  />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem span={12}>
              <Grid columns={12}>
                <GridItem span={6} />
                <GridItem span={6}>
                  <div
                    css={css`
                      margin-top: -16px;
                    `}
                  >
                    <Subscribe<VehicleAdvertisingAttributeRequestBody>
                      name={[name] as any}
                      component={({input: {value}}) => (
                        <Field
                          as="checkbox"
                          name={[name, 'value.withVat'] as any}
                          label={
                            // eslint-disable-next-line no-restricted-syntax
                            (
                              <FirstCapital>{i18n.t('general.labels.withVat')}</FirstCapital>
                            ) as unknown as string
                          }
                          defaultValue={!(value.extra as PriceExtra | undefined)?.vatDeductible}
                          disabled={!value.override || !value.extra?.vatDeductible || isDisabled}
                        />
                      )}
                    />
                  </div>
                </GridItem>
              </Grid>

              <Subscribe<VehicleAdvertisingAttributeRequestBody>
                name={[name] as any}
                component={(props) => <SecondPrice {...props} isDisabled={isDisabled} />}
              />
            </GridItem>
          </Grid>
        </GridItem>
      );
    case 'price':
      return (
        <GridItem span={12} key={name}>
          <Grid columns={12}>
            <Field as="hidden" name={[name, 'value.vatRate'] as any} defaultValue={extra.vatRate} />
            <Field
              as="hidden"
              name={[name, 'value.value.currency'] as any}
              defaultValue={defaultCurrency}
            />
            <GridItem span={12}>
              <Grid columns={12}>
                <GridItem span={3}>
                  <Box paddingTop={2}>
                    <Field
                      as="switch"
                      disabled={!hasPublishControl || isDisabled}
                      name={[name, 'publish'] as any}
                      label={label}
                    />
                  </Box>
                </GridItem>
                <GridItem span={3}>
                  <Box paddingTop={2}>
                    <Field
                      as="checkbox"
                      disabled={isDisabled}
                      name={[name, 'override'] as any}
                      // FIXME translation
                      label={i18n.t('entity.vehicle.labels.override')}
                    />
                  </Box>
                </GridItem>
                <GridItem span={6}>
                  <Subscribe<VehicleAdvertisingAttributeRequestBody>
                    name={[name] as any}
                    component={({input: {value}}) => {
                      const {currency: currencyValue} = (value.value as PriceValueType)?.value;
                      const {override} = value;
                      return (
                        <PriceField
                          name={[name, 'value.value.amount'] as any}
                          suffix={formatCurrencySymbol(currencyValue as CurrencyCodeType)}
                          disabled={!override || isDisabled}
                        />
                      );
                    }}
                  />
                </GridItem>
              </Grid>
            </GridItem>

            <GridItem span={12}>
              <Grid columns={12}>
                <GridItem span={6} />
                <GridItem span={6}>
                  <div
                    css={css`
                      margin-top: -16px;
                    `}
                  >
                    <Subscribe<VehicleAdvertisingAttributeRequestBody>
                      name={[name] as any}
                      component={({input: {value}}) => (
                        <Field
                          as="checkbox"
                          name={[name, 'value.withVat'] as any}
                          label={
                            // eslint-disable-next-line no-restricted-syntax
                            (
                              <FirstCapital>{i18n.t('general.labels.withVat')}</FirstCapital>
                            ) as unknown as string
                          }
                          defaultValue={!(value.extra as PriceExtra | undefined)?.vatDeductible}
                          disabled={!value.override || !value.extra?.vatDeductible || isDisabled}
                        />
                      )}
                    />
                  </div>
                </GridItem>
              </Grid>

              <Subscribe<VehicleAdvertisingAttributeRequestBody>
                name={[name] as any}
                component={(props) => <SecondPrice {...props} isDisabled={isDisabled} />}
              />
            </GridItem>
          </Grid>
        </GridItem>
      );
    case 'mileage':
      return (
        <GridItem span={12} key={name}>
          <Grid columns={12}>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="switch"
                  disabled={!hasPublishControl || isDisabled}
                  name={[name, 'publish'] as any}
                  label={label}
                />
              </Box>
            </GridItem>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="checkbox"
                  disabled={isDisabled}
                  name={[name, 'override'] as any}
                  // FIXME translation
                  label={i18n.t('entity.vehicle.labels.override')}
                />
              </Box>
            </GridItem>
            <GridItem span={6}>
              <Subscribe
                name={[name] as any}
                component={({
                  input: {
                    value: {override},
                  },
                }) => (
                  <>
                    <Field
                      as="integer"
                      name={[name, 'value.value'] as any}
                      disabled={!override || isDisabled}
                      maxLength={9}
                      suffix="km"
                    />
                    <Field
                      as="hidden"
                      name={[name, 'value.unit'] as any}
                      defaultValue="km"
                      parse={(value) => (override ? value : undefined)}
                    />
                  </>
                )}
              />
            </GridItem>
          </Grid>
        </GridItem>
      );
    case 'phone':
      return (
        <GridItem span={12} key={name}>
          <Grid columns={12}>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="switch"
                  disabled={!hasPublishControl || isDisabled}
                  name={[name, 'publish'] as any}
                  label={label}
                />
              </Box>
            </GridItem>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="checkbox"
                  disabled={isDisabled}
                  name={[name, 'override'] as any}
                  // FIXME translation
                  label={i18n.t('entity.vehicle.labels.override')}
                />
              </Box>
            </GridItem>
            <GridItem span={6}>
              <Subscribe
                name={[name] as any}
                component={({
                  input: {
                    value: {override},
                  },
                }) => (
                  <Field
                    as="phone"
                    name={[name, 'value'] as any}
                    disabled={!override || isDisabled}
                  />
                )}
              />
            </GridItem>
          </Grid>
        </GridItem>
      );
    default:
      return (
        <GridItem span={12} key={name}>
          <Grid columns={12}>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="switch"
                  disabled={!hasPublishControl || isDisabled}
                  name={[name, 'publish'] as any}
                  label={label}
                />
              </Box>
            </GridItem>
            <GridItem span={3}>
              <Box paddingTop={2}>
                <Field
                  as="checkbox"
                  disabled={isDisabled}
                  name={[name, 'override'] as any}
                  // TODO: FIXME translation
                  label={i18n.t('entity.vehicle.labels.override')}
                />
              </Box>
            </GridItem>
            <GridItem span={6}>
              <Subscribe
                name={[name] as any}
                component={({
                  input: {
                    value: {override},
                  },
                }) => (
                  <Field
                    as="text"
                    name={[name, 'value'] as any}
                    multiline={type === 'text'}
                    disabled={!override || isDisabled}
                  />
                )}
              />
            </GridItem>
          </Grid>
        </GridItem>
      );
  }
}

const FirstCapital = styled.span`
  &::first-letter {
    text-transform: capitalize;
  }
`;
