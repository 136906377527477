import {Card, IconButton} from 'platform/components';
import {Box, Heading, HStack, VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  DEFAULT_COUNTRY,
  DrillDown,
  selectTenant,
  SourcingVehicleDetailResponseBody,
} from '@omnetic-dms/shared';
import {selectUserSettings} from '@omnetic-dms/teas';

import {convertMileageToAlphaMileageRange} from './utils/convertMileageToAlphaMileageRange';

interface FullScreenDetailLayoutProps {
  vehicle: SourcingVehicleDetailResponseBody;
  onClose?: () => void;
}

export function FullScreenDetailLayout({vehicle, onClose}: FullScreenDetailLayoutProps) {
  const {data: {country: tenantCountry} = {country: DEFAULT_COUNTRY}} = useSelector(selectTenant);
  const {currency, country = tenantCountry} = useSelector(selectUserSettings);

  return (
    <Box width="100%" height="100vh" backgroundColor="palettes.neutral.30.100">
      <VStack>
        <Box paddingHorizontal={6} paddingVertical={3} backgroundColor="palettes.white.10.100">
          <HStack align="center" justify="space-between">
            <Heading size={1}>{i18n.t('general.labels.drillDown')}</Heading>
            <IconButton
              data-testid="close-fullscreen-detail-layout"
              onClick={() => onClose?.()}
              icon="navigation/close"
            />
          </HStack>
        </Box>
        <Box flex={1} paddingVertical={4} paddingHorizontal={2}>
          <Card>
            <DrillDown
              regions={[country]}
              currency={currency ?? 'CZK'}
              selectedRow={{
                make: vehicle.sourcingVehicle.make!.key,
                model: vehicle.sourcingVehicle.model!.key,
                year: vehicle.sourcingVehicle.year!.toString(),
                fuel_type: vehicle.sourcingVehicle.fuelType!.key,
                drive: vehicle.sourcingVehicle.driveType!.key,
                transmission: vehicle.sourcingVehicle.transmission!.key,
                power: vehicle.sourcingVehicle.power!.toString(),
                mileage_range: convertMileageToAlphaMileageRange(vehicle.sourcingVehicle.mileage!),
                car_style: vehicle.sourcingVehicle.carStyle!.key,
              }}
              focusOnSelectedRow
              data-testid={testIds.sourcing.vehicleDetail('fullScreenDetailLayout-drillDown')}
            />
          </Card>
        </Box>
      </VStack>
    </Box>
  );
}
