import {FormSubmitHandler} from 'platform/components';

import {defaultTo, map, pipe} from 'ramda';

import {
  CommentsCard as CommentsCardComponent,
  handleApiError,
  CommentCardForm,
  useCreateCustomerCommentMutation,
  useGetCustomerCommentListQuery,
  CreateCustomerCommentApiArg,
  useDeleteCustomerCommentMutation,
  DeleteCustomerCommentApiArg,
  CustomerCommentResponseBody,
  CommentResponseBody,
  useGetUserOptions,
} from '@omnetic-dms/shared';

import {TestIdProps} from 'shared';

interface CommentsCardProps extends TestIdProps {
  customerId: string;
}

export function CommentsCard(props: CommentsCardProps) {
  const {
    comments,
    isLoading: isFetchingCommentsLoading,
    isError: isFetchingCommentsErrored,
  } = useGetCustomerCommentListQuery(
    {customerId: props.customerId},
    {selectFromResult: ({data, ...other}) => ({...other, comments: transformComments(data)})}
  );
  const {userOptions} = useGetUserOptions();

  const [createCustomerComment, {isLoading: isSendCommentLoading}] =
    useCreateCustomerCommentMutation();
  const [deleteCustomerComment, {isLoading: isDeleteCommentLoading}] =
    useDeleteCustomerCommentMutation();

  const onSubmit: FormSubmitHandler<CommentCardForm> = async (values, _, reset) => {
    const arg: CreateCustomerCommentApiArg = {
      customerId: props.customerId,
      commentRequestBody: {
        comment: values.comment,
      },
    };
    await createCustomerComment(arg)
      .unwrap()
      .then(() => {
        reset();
      })
      .catch(handleApiError);
  };

  const onDelete = async (commentId: string) => {
    const arg: DeleteCustomerCommentApiArg = {
      customerId: props.customerId,
      commentId,
    };
    await deleteCustomerComment(arg).unwrap().catch(handleApiError);
  };

  return (
    <CommentsCardComponent
      isFetchingCommentsLoading={isFetchingCommentsLoading}
      isFetchingCommentsErrored={isFetchingCommentsErrored}
      isSendCommentLoading={isSendCommentLoading}
      isDeleteCommentLoading={isDeleteCommentLoading}
      comments={comments}
      onSubmit={onSubmit}
      onDelete={onDelete}
      userOptions={userOptions}
      data-testid={props['data-testid']}
    />
  );
}

const transformComments = pipe<
  [CustomerCommentResponseBody[] | undefined],
  CustomerCommentResponseBody[],
  CommentResponseBody[]
>(
  defaultTo([]),
  map((comment) => ({
    id: comment.id,
    text: comment.comment,
    createdAt: comment.createdAt,
    createdBy: comment.authorId,
    updatedAt: comment.editedAt,
    updatedBy: comment.authorId,
  }))
);
