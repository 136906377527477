import {useMemo} from 'react';
import {useController, useFormContext} from 'react-hook-form';

import {FullSchema} from '../types/FullSchema';
import {Schema} from '../types/Schema';
import {useEventActions} from './useEventActions';

/**
 * Connects a form field to the form context
 */
export function useFieldProps(schema: Schema | FullSchema) {
  const {control, setValue, getValues} = useFormContext();
  const {field} = useController({name: (schema as FullSchema)?.name, control});

  const resetEvents = useEventActions(schema as FullSchema, getValues, setValue);

  /*
   * Underline components work only in the controlled mode so they don't accept undefined value.
   * Therefore we need conver undefined to null.
   * */
  const nonUndefinedValue = field.value ?? null;

  return useMemo(() => {
    const onChange = (value?: unknown) => {
      resetEvents?.onChange?.(value);
      field?.onChange?.(value);
    };

    const onBlur = (value?: unknown) => {
      resetEvents?.onBlur?.(value);
      field?.onBlur?.();
    };

    const onFocus = () => {
      resetEvents?.onFocus?.();
    };

    return {...field, value: nonUndefinedValue, onChange, onBlur, onFocus};
  }, [field, resetEvents, nonUndefinedValue]);
}
