import {Parameter} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil, isNotNil, reject} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {BusinessCaseResponseBody, getNaturalPersonFullName} from '@omnetic-dms/shared';

import {parseDate} from 'shared';

export function useGetParameters(businessCase: BusinessCaseResponseBody | undefined) {
  const formatDateTime = useDateTimeFormatter();

  const getCreatedAt = isNotNil(businessCase?.createdAt)
    ? `${i18n.t('general.labels.dateCreated')}: ${formatDateTime(
        'dateTimeShort',
        parseDate(businessCase?.createdAt)
      )}`
    : null;

  const getClosedAt = isNotNil(businessCase?.closedAt)
    ? `${i18n.t('general.labels.dateClosed')}: ${formatDateTime(
        'dateTimeShort',
        parseDate(businessCase?.closedAt)
      )}`
    : null;

  const getCreatedBy = `${i18n.t('general.labels.createdBy')}: ${getNaturalPersonFullName(businessCase?.createdBy)}`;

  const getClosedBy = isNotNil(businessCase?.closedBy?.firstName)
    ? `${i18n.t('general.labels.closedBy')}: ${getNaturalPersonFullName(businessCase?.closedBy)}`
    : null;

  const parameters: Parameter[] = reject(isNil, [
    businessCase?.code,
    getCreatedBy,
    getCreatedAt,
    getClosedBy,
    getClosedAt,
  ]);

  return [parameters];
}
