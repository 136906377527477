import {GetWindshieldApiRes, PutWindshieldApiArg} from '../types/api/api';
import {omneticApi} from './baseApi/omneticApi';

export const windshieldApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getWindshieldUrl: build.query<GetWindshieldApiRes, void>({
      query: () => ({url: `/dms/v1/sales/windshield`}),
      providesTags: ['windshield'],
    }),
    putWindshieldUrl: build.mutation<void, PutWindshieldApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sales/windshield`,
        body: queryArg,
        method: 'PUT',
      }),
      invalidatesTags: ['windshield'],
    }),
  }),
});

export const {useGetWindshieldUrlQuery, usePutWindshieldUrlMutation} = windshieldApi;
