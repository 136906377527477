import {Dropdown, DropdownItem, IconButton} from 'platform/components';

import {suffixTestId, TestIdProps} from 'shared';

import i18n from '../../../i18n/i18n';

export interface CommentCardItemActionsProps extends TestIdProps {
  id: string;
  isDeleteCommentLoading?: boolean;
  onDelete?: (id: string) => void;
  isDeleteDisabled?: boolean;
}

export function CommentCardItemActions(props: CommentCardItemActionsProps) {
  return (
    <Dropdown
      isLazy
      lazyBehavior="unmount"
      placement="bottom-end"
      strategy="fixed"
      boundary="scrollParent"
      dropdownControl={
        <IconButton icon="navigation/more_vert" data-testid="button-secondaryActions-more" />
      }
      data-testid={suffixTestId('comment-actions', props)}
    >
      <DropdownItem
        key="comment-actions-delete"
        label={i18n.t('general.actions.delete')}
        isLoading={props.isDeleteCommentLoading}
        onClick={() => props.onDelete?.(props.id)}
        isDisabled={props.isDeleteDisabled}
        data-testid={suffixTestId('comment-actionDelete', props)}
      />
    </Dropdown>
  );
}
