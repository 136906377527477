import {Attributes, AttributesRow, DataStatus} from 'platform/components';
import {Link} from 'platform/foundation';
import {useFormatCurrency, useDateTimeFormatter} from 'platform/locale';

import {testIds} from '@omnetic-dms/routes';

import {convertStringToCamelCase, openFile} from 'shared';
import {parseDate, buildArray} from 'shared';

import {
  useGetCashRegisterDocumentQuery,
  useGetCashRegisterQuery,
  useRenderExpenseCashReceiptDocumentMutation,
  useRenderIncomeCashReceiptDocumentMutation,
} from '../../../api/accountingApi';
import i18n from '../../../i18n/i18n';
import {VehicleReservationDepositResponseBody} from '../../../types/api/saleVehicle';
import {handleApiError} from '../../../utils/handleApiError';

interface RefundedReservationDepositDetailProps {
  refundedAdvanceDeposit: VehicleReservationDepositResponseBody;
}

export function RefundedReservationDepositDetail(props: RefundedReservationDepositDetailProps) {
  const [renderIncomeDocument] = useRenderIncomeCashReceiptDocumentMutation();
  const [renderExpenseDocument] = useRenderExpenseCashReceiptDocumentMutation();
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const {
    data: incomeCashRegisterDocument,
    isLoading: incomeCashRegisterDocumentLoading,
    isError: incomeCashRegisterDocumentError,
  } = useGetCashRegisterDocumentQuery({
    cashRegisterDocumentId: props.refundedAdvanceDeposit.incomeCashReceiptId,
  });

  const {
    data: incomeCashRegister,
    isLoading: incomeCashRegisterLoading,
    isError: incomeCashRegisterError,
  } = useGetCashRegisterQuery(
    {
      cashRegisterId: incomeCashRegisterDocument?.cashRegisterId ?? '',
    },
    {skip: !incomeCashRegisterDocument?.cashRegisterId}
  );

  const {
    data: expenseCashRegisterDocument,
    isLoading: expenseCashRegisterDocumentLoading,
    isError: expenseCashRegisterDocumentError,
  } = useGetCashRegisterDocumentQuery({
    cashRegisterDocumentId: props.refundedAdvanceDeposit.expenseCashReceiptId ?? '',
  });

  const isError =
    incomeCashRegisterDocumentError || incomeCashRegisterError || expenseCashRegisterDocumentError;

  const isLoading =
    incomeCashRegisterDocumentLoading ||
    incomeCashRegisterLoading ||
    expenseCashRegisterDocumentLoading;

  const isEmpty =
    !incomeCashRegister || !incomeCashRegisterDocument || !expenseCashRegisterDocument;

  const handleGenerateIncomePDF = async () => {
    await renderIncomeDocument({
      renderIncomeCashReceiptDocumentRequestBody: {
        cashRegisterDocumentId: props.refundedAdvanceDeposit.incomeCashReceiptId,
      },
    })
      .unwrap()
      .then((documentUrl) => {
        if (documentUrl) {
          openFile(documentUrl.pdfUrl);
        }
      })
      .catch(handleApiError);
  };

  const handleGenerateExpensePDF = async () => {
    await renderExpenseDocument({
      renderExpenseCashReceiptDocumentRequestBody: {
        cashRegisterDocumentId: props.refundedAdvanceDeposit.expenseCashReceiptId ?? '',
      },
    })
      .unwrap()
      .then((documentUrl) => {
        if (documentUrl) {
          openFile(documentUrl.pdfUrl);
        }
      })
      .catch(handleApiError);
  };

  if (isLoading && isEmpty) {
    return <DataStatus isLoading />;
  }

  const attributesRows = buildArray<AttributesRow>()
    .when(incomeCashRegisterDocument, {
      label: i18n.t('general.labels.amount'),
      value: formatCurrency(
        Number(incomeCashRegisterDocument!.totalPriceWithVat.amount),
        incomeCashRegisterDocument!.totalPriceWithVat.currency,
        2
      ),
    })
    .when(incomeCashRegisterDocument, {
      label: i18n.t('entity.accounting.labels.paymentMethod'),
      value: i18n.t(
        `entity.invoice.paymentMethod.${convertStringToCamelCase(
          incomeCashRegisterDocument!.paymentMethod
        )}`
      ),
    })
    .when(incomeCashRegisterDocument, {
      label: i18n.t('general.labels.issuedOn'),
      value: formatDateTime(
        'dateShort',
        parseDate(incomeCashRegisterDocument!.issuedAt) || new Date()
      ),
    })
    .when(incomeCashRegister, {
      label: i18n.t('general.labels.cashRegister'),
      value: incomeCashRegister!.name,
    })
    .when(incomeCashRegisterDocument, {
      label: i18n.t('general.labels.note'),
      value: incomeCashRegisterDocument!.note,
    })
    .when(incomeCashRegisterDocument, {
      label: i18n.t('entity.vehicle.labels.incomeDocument'),
      content: (
        <Link
          size="xSmall"
          onClick={handleGenerateIncomePDF}
          data-testid={testIds.accounting.cashReceiptDetail('generatePdf')}
          title={`${incomeCashRegisterDocument!.number}_${i18n.t(
            'entity.vehicle.labels.incomeDocument'
          )}`}
        />
      ),
    })
    .when(expenseCashRegisterDocument, {
      label: i18n.t('entity.vehicle.labels.expenseDocument'),
      content: (
        <Link
          size="xSmall"
          onClick={handleGenerateExpensePDF}
          data-testid={testIds.accounting.cashReceiptDetail('generateExpensePdf')}
          title={`${expenseCashRegisterDocument!.number}_${i18n.t(
            'entity.vehicle.labels.expenseDocument'
          )}`}
        />
      ),
    });

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isEmpty}>
      <Attributes rows={attributesRows} />
    </DataStatus>
  );
}
