import i18n from '@omnetic-dms/i18n';
import {OrderDiscriminator, PaymentDiscriminator} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

export const getPaymentMethodOptions = (
  orderDiscriminator: OrderDiscriminator,
  paymentDiscriminator: PaymentDiscriminator
) => {
  const isSale = orderDiscriminator === 'SALE';
  const isBalance = paymentDiscriminator === 'BALANCE';

  return buildArray([
    {value: 'CASH', label: i18n.t('entity.invoice.paymentMethod.cash')},
    {value: 'PAYMENT_CARD', label: i18n.t('entity.invoice.paymentMethod.card')},
    {value: 'BANK_TRANSFER', label: i18n.t('entity.invoice.paymentMethod.bankTransfer')},
  ]).when(isSale && isBalance, {
    value: 'OFFSET',
    label: i18n.t('entity.invoice.paymentMethod.offset'),
  });
};
