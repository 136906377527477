import {Chips} from '../../Chip/Chips';
import {useFieldProps} from '../hooks/useFieldProps';
import {useOptionsManager} from '../hooks/useOptionsManager';
import {ChipsRendererResponseBody} from '../types/modelSchema';

export function ChipsRenderer(props: ChipsRendererResponseBody) {
  const {onChange, value} = useFieldProps(props);
  const options = useOptionsManager(props.defaultOptions, props.dependentOptions, props.url);

  return (
    <Chips
      label={props.label}
      data-testid={props.name}
      value={value}
      onChange={onChange}
      options={options}
    />
  );
}
