import {useSelector} from 'react-redux';

import {useGetBranchQuery} from '../api/tenantApi';
import {selectActiveBranchId} from '../features/tenants/branchSelectors';

export const useGetCurrentBranch = () => {
  const activeBranchId = useSelector(selectActiveBranchId);

  const activeBranch = useGetBranchQuery({branchId: activeBranchId});

  return {activeBranchId, ...activeBranch};
};
