import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {featureFlags} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../components/SettingsStackItems/SettingsStackItems';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {MaximumAcceptedPriceSection} from './MaximumAcceptedPriceSection';

export function BusinessCases() {
  const items = buildArray<SettingsStackItemProps>()
    .add({
      cyId: testIds.settings.businessCase('definition-link'),
      title: i18n.t('page.salesSettingsBusinessCase.labels.businessCaseDefinition'),
      url: settingsRoutes.businessCaseDefinition,
    })
    .add({
      cyId: testIds.settings.businessCase('comissionFee-link'),
      title: i18n.t('page.salesSettingsBusinessCase.labels.commissionFeeTitle'),
      url: settingsRoutes.businessCaseCommissionFee,
    })
    .whenFeatureEnabled(featureFlags.SALES_CENTRALIZED_PRICING, {
      cyId: testIds.settings.businessCase('centralizedPricing-link'),
      title: i18n.t('page.salesSettingsBusinessCase.labels.centralizedPricing'),
      url: settingsRoutes.businessCaseCentralizedPricing,
    })
    .add({
      cyId: 'invoiceCostGeneration',
      title: i18n.t('page.accountingSettings.labels.invoiceCostGeneration'),
      url: settingsRoutes.invoiceCostOverview,
    })
    .add({
      cyId: 'generatedDocuments',
      title: i18n.t('page.bcDocumentsSettings.labels.title'),
      url: settingsRoutes.generatedDocuments,
    });

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.businessCases.title')}}
      data-testid="settings-businessCases"
    >
      <MaximumAcceptedPriceSection />
      <SettingsStackItems items={items} />
    </SettingsTemplate>
  );
}
