import {Card} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {customerRoutes} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

export function VehicleCustomersCard() {
  const id = useParams()?.id as string;
  const navigate = useNavigate();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      vehicle: [id],
    }),
    [id]
  );

  const actionCallback = useCallback<ActionCallback>(
    ({actionKey, rowId}) => {
      const customerId = isArray(rowId) ? rowId[0] : rowId;

      switch (actionKey) {
        case 'detail':
          navigate(composePath(customerRoutes.detail, {params: {id: customerId}}));
          break;
      }
    },
    [navigate]
  );

  return (
    <Card title={i18n.t('page.customers.title')}>
      <Box height={80}>
        <DataGrid
          gridCode="vehicle-customers-list"
          actionCallback={actionCallback}
          queryModifier={queryModifier}
        />
      </Box>
    </Card>
  );
}
