import {FlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {LiteralUnionAutocomplete, Nullish} from 'shared';

import i18n from '../i18n/i18n';
import {BusinessCaseInternalType, PurchaseVehicleType} from '../types/api/businessCase';

export const getBusinessCaseTradeTypeFlag = (
  type: LiteralUnionAutocomplete<PurchaseVehicleType | BusinessCaseInternalType> | Nullish
): Pick<FlagProps, 'colorScheme' | 'label'> | undefined =>
  match<
    LiteralUnionAutocomplete<PurchaseVehicleType | BusinessCaseInternalType> | Nullish,
    Pick<FlagProps, 'colorScheme' | 'label'> | undefined
  >(type)
    .with(
      'BROKERAGE',
      'PURCHASE_BROKERAGE',
      always({
        colorScheme: 'teal',
        label: i18n.t('entity.vehicle.labels.typeBrokerage'),
      })
    )
    .with(
      'IN_STOCK',
      'BUYING',
      always({
        colorScheme: 'yellow',
        label: i18n.t('entity.vehicle.labels.typeInStock'),
      })
    )
    .otherwise(always(undefined));
