import {Image} from 'platform/foundation';
import {HStack} from 'platform/foundation';

export function LogoStack() {
  return (
    <HStack justify="space-between" align="center" spacing={8}>
      <Image src="assets/images/logos/allianz.png" alt="Allianz" width={25} />
      <Image src="assets/images/logos/kooperativa.png" alt="Kooperativa" width={25} />
      <Image src="assets/images/logos/csob.png" alt="ČSOB" width={25} />
      <Image src="assets/images/logos/cpp.png" alt="ČPP" width={25} />
      <Image src="assets/images/logos/pillow.png" alt="Pillow" width={25} />
      <Image src="assets/images/logos/generali.png" alt="Generali" width={25} />
      <Image src="assets/images/logos/okgroup.png" alt="Ok Group" width={25} />
    </HStack>
  );
}
