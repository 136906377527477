import {BaseVoidRequestSchema, BaseVoidResponseSchema} from '../types/api/base';
import {
  CreateWarehouseAccountRequest,
  CreateWarehouseAccountRequestSchema,
  CreateWarehouseAccountResponse,
  GetWarehouseAccountRequest,
  GetWarehouseAccountRequestSchema,
  GetWarehouseAccountResponse,
  GetWarehouseAccountResponseSchema,
  GetWarehouseAccountsRequest,
  GetWarehouseAccountsResponse,
  GetWarehouseAccountsResponseSchema,
  UpdateWarehouseAccountRequest,
  UpdateWarehouseAccountRequestSchema,
  UpdateWarehouseAccountResponse,
  UpdateWarehouseAccountResponseSchema,
} from '../types/api/metadaWarehouseAccount';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseAccountApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getWarehouseAccounts: build.query<GetWarehouseAccountsResponse, GetWarehouseAccountsRequest>({
      query: () => ({
        url: `warehouse/v1/warehouse-accounts`,
        method: 'GET',
        invalidatesTags: ['warehouseAccounts'],
      }),
      extraOptions: {
        requestSchema: BaseVoidRequestSchema,
        responseSchema: GetWarehouseAccountsResponseSchema,
      },
    }),
    getWarehouseAccount: build.query<GetWarehouseAccountResponse, GetWarehouseAccountRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse-account/${queryArg.warehouseAccountId}`,
        method: 'GET',
        invalidatesTags: ['warehouseAccounts'],
      }),
      extraOptions: {
        requestSchema: GetWarehouseAccountRequestSchema,
        responseSchema: GetWarehouseAccountResponseSchema,
      },
    }),
    postWarehouseAccount: build.mutation<
      CreateWarehouseAccountResponse,
      CreateWarehouseAccountRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse-account`,
        method: 'POST',
        body: queryArg,
        invalidatesTags: ['warehouseAccounts'],
      }),
      extraOptions: {
        requestSchema: CreateWarehouseAccountRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
    patchWarehouseAccount: build.mutation<
      UpdateWarehouseAccountResponse,
      UpdateWarehouseAccountRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse-account/${queryArg.warehouseAccountId}`,
        method: 'PATCH',
        body: queryArg.body,
        invalidatesTags: ['warehouseAccounts'],
      }),
      extraOptions: {
        requestSchema: UpdateWarehouseAccountRequestSchema,
        responseSchema: UpdateWarehouseAccountResponseSchema,
      },
    }),
  }),
});

export const {
  useGetWarehouseAccountsQuery,
  useGetWarehouseAccountQuery,
  useLazyGetWarehouseAccountQuery,
  usePostWarehouseAccountMutation,
  usePatchWarehouseAccountMutation,
} = metadaWarehouseAccountApi;
