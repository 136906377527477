import {OptionType} from 'platform/components';

import {buildArray} from 'shared';

import i18n from '../i18n/i18n';
import {GetServiceOrderVariantDocumentsApiResponse} from '../types/api/metadaWorkshopServiceOrderVariant';

export const getOrderPaymentOptions = (
  orderVariantDocuments?: GetServiceOrderVariantDocumentsApiResponse
) =>
  buildArray<OptionType>()
    .when(orderVariantDocuments?.paymentType?.bankPayment?.isAllowed ?? true, {
      label: i18n.t('entity.invoice.paymentMethod.bankTransfer'),
      value: 'BANK_TRANSFER',
    })
    .when(orderVariantDocuments?.paymentType?.cardPayment?.isAllowed ?? true, {
      label: i18n.t('entity.invoice.paymentMethod.card'),
      value: 'CARD',
    })
    .when(orderVariantDocuments?.paymentType?.cashPayment?.isAllowed ?? true, {
      label: i18n.t('entity.invoice.paymentMethod.cash'),
      value: 'CASH',
    });
