import {DataStatus} from 'platform/components';

import {Main, useGetVehicleComplaintsQuery, VehicleComplaints} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

export function Claims() {
  const {id} = useRequiredParams();

  const {data: complaints = [], isLoading, isError} = useGetVehicleComplaintsQuery({vehicleId: id});

  return (
    <Main>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
        <VehicleComplaints vehicleId={id} complaints={complaints} />
      </DataStatus>
    </Main>
  );
}
