import {Action, Card} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useState} from 'react';

import {not} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  BaseAuthorizationProfile,
  BaseSupplier,
  CurrencyResponseBody,
  GetReceiveNoteCorrectionResponse,
  GetWarehousesResponse,
  PaymentTypeResponseBody,
} from '@omnetic-dms/shared';

import {buildArray, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteCorrectionCard} from './ReceiveNoteCorrectionCard';
import {ReceiveNoteOriginalCard} from './ReceiveNoteOriginalCard';

interface ReceiveNoteDetailCardProps extends RequiredTestIdProps {
  receiveNoteCorrection: GetReceiveNoteCorrectionResponse;
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  paymentTypes: PaymentTypeResponseBody[] | Nullish;
  currencies: CurrencyResponseBody[] | Nullish;
}

export function ReceiveNoteDetailCard(props: ReceiveNoteDetailCardProps) {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

  const isReceiveNoteCorrectionPending =
    props.receiveNoteCorrection?.creditNote?.state === 'PENDING';

  const receiveNoteActions = buildArray<Action>().when(isReadOnly, {
    type: 'button',
    variant: 'link',
    leftIcon: 'image/edit',
    title: i18n.t('general.labels.edit'),
    isDisabled: not(isReceiveNoteCorrectionPending),
    onClick: () => setIsReadOnly(false),
  });

  return (
    <Card
      title={i18n.t('entity.warehouse.labels.receiveNote')}
      actions={receiveNoteActions}
      data-testid={props['data-testid']}
    >
      <VStack spacing={4}>
        <ReceiveNoteCorrectionCard
          receiveNoteCorrection={props.receiveNoteCorrection}
          suppliers={props.suppliers}
          warehouses={props.warehouses}
          authorizationProfiles={props.authorizationProfiles}
          paymentTypes={props.paymentTypes}
          currencies={props.currencies}
          isReadOnly={isReadOnly}
          onClose={() => setIsReadOnly(true)}
          data-testid={suffixTestId('cards.correction', props)}
        />
        <ReceiveNoteOriginalCard
          receiveNoteCorrection={props.receiveNoteCorrection}
          suppliers={props.suppliers}
          warehouses={props.warehouses}
          authorizationProfiles={props.authorizationProfiles}
          paymentTypes={props.paymentTypes}
          currencies={props.currencies}
          data-testid={suffixTestId('cards.original', props)}
        />
      </VStack>
    </Card>
  );
}
