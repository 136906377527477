import {renameKeysWith} from 'ramda-adjunct';

import {
  BatchDeleteSeriesApiArg,
  BatchDeleteSeriesApiResponse,
  CreateSeriesApiArg,
  CreateSeriesApiResponse,
  GenerateSeriesNumberApiArg,
  GenerateSeriesNumberApiResponse,
  GetSeriesApiArg,
  GetSeriesApiResponse,
  GetSeriesListApiArg,
  GetSeriesListApiResponse,
  GetSeriesTypeListApiResponse,
  PatchSeriesApiArg,
  PatchSeriesApiResponse,
} from '../types/api/series';
import {omneticApi} from './baseApi/omneticApi';

const injectedRtkApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getSeriesTypeList: build.query<GetSeriesTypeListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/series/type`,
      }),
    }),
    batchDeleteSeries: build.mutation<BatchDeleteSeriesApiResponse, BatchDeleteSeriesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/series`,
        method: 'DELETE',
        body: queryArg.body,
      }),
      invalidatesTags: ['GeneralSeries'],
    }),
    createSeries: build.mutation<CreateSeriesApiResponse, CreateSeriesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/series`,
        method: 'POST',
        body: queryArg.createSeriesRequestBody,
      }),
    }),
    getSeriesList: build.query<GetSeriesListApiResponse, GetSeriesListApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/series`,
        params: {
          search: queryArg.search,
          ...renameKeysWith((index) => `type[${index}]`, queryArg.type ?? []),
        },
      }),
      providesTags: ['GeneralSeries'],
    }),
    generateSeriesNumber: build.mutation<
      GenerateSeriesNumberApiResponse,
      GenerateSeriesNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/series/${queryArg.seriesId}/generate-number`,
        method: 'POST',
      }),
    }),
    getSeries: build.query<GetSeriesApiResponse, GetSeriesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/series/${queryArg.seriesId}`,
      }),
    }),
    patchSeries: build.mutation<PatchSeriesApiResponse, PatchSeriesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/series/${queryArg.seriesId}`,
        method: 'PATCH',
        body: queryArg.patchSeriesRequestBody,
      }),
    }),
  }),
});

export const {
  useGetSeriesTypeListQuery,
  useBatchDeleteSeriesMutation,
  useCreateSeriesMutation,
  useGetSeriesListQuery,
  useGenerateSeriesNumberMutation,
  useGetSeriesQuery,
  usePatchSeriesMutation,
} = injectedRtkApi;
