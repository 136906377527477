import {PlatformCode} from '@omnetic-dms/shared';

import {AdvertisingPlatformCodeEnum} from './AdvertisingPlatformCodeEnum';

export enum EPlatformUpdateTypes {
  switchOff, // type for platforms `ENABLE` || `PUBLISH`
  switchOn, // type for platforms `DISABLE` || `UNPUBLISH`
}

export type PlatformDetailType = {
  vehicleId: string;
  code: PlatformCode;
  onError?: () => void;
};

export type TUpdatePlatformStatusRequest = {
  vehicleId: string;
  platformCode?: `${AdvertisingPlatformCodeEnum}`;
  option: EPlatformUpdateTypes;
};
