import {
  CreateInterestApiArg,
  CreateInterestApiResponse,
  DeleteInterestApiArg,
  DeleteInterestApiResponse,
  GetInterestApiArg,
  GetInterestApiResponse,
  GetInterestCatalogueApiResponse,
  UpdateInterestApiArg,
  UpdateInterestApiResponse,
  ConvertForAllVehiclesApiArg,
  ConvertForAllVehiclesApiResponse,
  ConvertForBuyingVehicleApiArg,
  ConvertForBuyingVehicleApiResponse,
  ConvertForSellingVehicleApiArg,
  ConvertForSellingVehicleApiResponse,
  GetInterestStatisticsApiArg,
  GetInterestStatisticsApiResponse,
  CreateInterestPriceReportApiResponse,
  CreateInterestPriceReportApiArg,
  GetInterestPriceReportsApiResponse,
  GetInterestPriceReportsApiArg,
  CreateMinisaleApiResponse,
  CreateMinisaleApiArg,
  UpdateMinisaleApiResponse,
  UpdateMinisaleApiArg,
  DeleteMinisaleApiArg,
  DeleteMinisaleApiResponse,
  GetMinisaleApiArg,
  GetMinisaleApiResponse,
  CreateMinipurchaseApiResponse,
  CreateMinipurchaseApiArg,
  UpdateMinipurchaseApiResponse,
  UpdateMinipurchaseApiArg,
  GetMinipurchaseApiResponse,
  GetMinipurchaseApiArg,
  GetOfferedVehiclesListApiResponse,
  GetOfferedVehiclesListApiArg,
  UpdateInterestUtmApiResponse,
  UpdateInterestUtmApiArg,
} from '../types/api/interest';
import {omneticApi} from './baseApi/omneticApi';

export const interestApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createInterest: build.mutation<CreateInterestApiResponse, CreateInterestApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/interest`,
        method: 'POST',
        body: queryArg.createInterestRequestBody,
      }),
    }),
    convertForAllVehicles: build.mutation<
      ConvertForAllVehiclesApiResponse,
      ConvertForAllVehiclesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.interestId}/convert-all`,
        method: 'POST',
      }),
      invalidatesTags: (result) => [{type: 'Interest', id: result?.id ?? undefined}],
    }),
    convertForBuyingVehicle: build.mutation<
      ConvertForBuyingVehicleApiResponse,
      ConvertForBuyingVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.interestId}/convert-buying`,
        method: 'POST',
      }),
      invalidatesTags: (result) => [{type: 'Interest', id: result?.id ?? undefined}],
    }),
    convertForSellingVehicle: build.mutation<
      ConvertForSellingVehicleApiResponse,
      ConvertForSellingVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.interestId}/convert-selling/${queryArg.vehicleId}`,
        method: 'POST',
      }),
      invalidatesTags: (result) => [{type: 'Interest', id: result?.id ?? undefined}],
    }),
    deleteInterest: build.mutation<DeleteInterestApiResponse, DeleteInterestApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getInterest: build.query<GetInterestApiResponse, GetInterestApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.id}`,
      }),
      providesTags: (result) => [{type: 'Interest', id: result?.id ?? undefined}],
    }),
    updateInterest: build.mutation<UpdateInterestApiResponse, UpdateInterestApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateInterestRequestBody,
      }),
      invalidatesTags: (_, __, queryArg) => [{type: 'Interest', id: queryArg.id}],
    }),
    interestCatalogue: build.query<GetInterestCatalogueApiResponse, void>({
      query: () => ({
        url: `/dms/v1/interest/catalogue`,
      }),
    }),
    getInterestStatistics: build.query<
      GetInterestStatisticsApiResponse,
      GetInterestStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/statistics/interest/`,
        params: {branchId: queryArg.branchId},
      }),
      providesTags: ['Interest'],
    }),
    createInterestPriceReport: build.mutation<
      CreateInterestPriceReportApiResponse,
      CreateInterestPriceReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.interestId}/price-report`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['interestPriceReport'],
    }),
    getInterestPriceReports: build.query<
      GetInterestPriceReportsApiResponse,
      GetInterestPriceReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.interestId}/price-report`,
      }),
      providesTags: ['interestPriceReport'],
    }),
    createMinipurchase: build.mutation<CreateMinipurchaseApiResponse, CreateMinipurchaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/minipurchase`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'miniPurchase',
        {type: 'Interest', id: queryArg.body.interestId},
      ],
    }),
    updateMinipurchase: build.mutation<UpdateMinipurchaseApiResponse, UpdateMinipurchaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/minipurchase`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'miniPurchase', id: queryArg.body.minipurchaseId},
        {type: 'Interest', id: queryArg.interestId},
      ],
    }),
    getMinipurchase: build.query<GetMinipurchaseApiResponse, GetMinipurchaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/minipurchase/${queryArg.minipurchaseId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'miniPurchase', id: queryArg.minipurchaseId},
      ],
    }),
    createMinisale: build.mutation<CreateMinisaleApiResponse, CreateMinisaleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/minisale`,
        method: 'POST',
        body: queryArg.createMinisaleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'miniSale',
        {type: 'Interest', id: queryArg.createMinisaleRequestBody.interestId},
      ],
    }),
    updateMinisale: build.mutation<UpdateMinisaleApiResponse, UpdateMinisaleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/minisale`,
        method: 'PUT',
        body: queryArg.updateMinisaleRequestBody,
      }),
    }),
    deleteMinisale: build.mutation<DeleteMinisaleApiResponse, DeleteMinisaleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/minisale/${queryArg.minisaleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Interest', id: queryArg.interestId},
        {type: 'miniSale', id: queryArg.minisaleId},
      ],
    }),
    getMinisale: build.query<GetMinisaleApiResponse, GetMinisaleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/minisale/${queryArg.minisaleId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'miniSale', id: queryArg.minisaleId}],
    }),
    getOfferedVehiclesList: build.query<
      GetOfferedVehiclesListApiResponse,
      GetOfferedVehiclesListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/offered-cars-list`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    updateInterestUtm: build.mutation<UpdateInterestUtmApiResponse, UpdateInterestUtmApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/interest/${queryArg.id}/utm`,
        method: 'PUT',
        body: queryArg.updateInterestUtmRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [{type: 'Interest', id: queryArg.id}],
    }),
  }),
});

export const {
  useCreateInterestMutation,
  useDeleteInterestMutation,
  useUpdateInterestMutation,
  useInterestCatalogueQuery,
  useGetInterestQuery,
  useLazyGetInterestQuery,
  useConvertForAllVehiclesMutation,
  useConvertForBuyingVehicleMutation,
  useConvertForSellingVehicleMutation,
  useGetInterestStatisticsQuery,
  useCreateInterestPriceReportMutation,
  useGetInterestPriceReportsQuery,
  useCreateMinisaleMutation,
  useUpdateMinisaleMutation,
  useDeleteMinisaleMutation,
  useGetMinisaleQuery,
  useCreateMinipurchaseMutation,
  useUpdateMinipurchaseMutation,
  useGetMinipurchaseQuery,
  useGetOfferedVehiclesListQuery,
  useLazyGetMinipurchaseQuery,
  useUpdateInterestUtmMutation,
} = interestApi;
