import {
  Button,
  ButtonGroup,
  Card,
  Choice,
  DataStatus,
  Label,
  RadioItem,
  showNotification,
} from 'platform/components';
import {Clickable, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {includes, isNil, not} from 'ramda';
import {isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  CampaignData,
  getOptionsFromServiceCaseOrderVariants,
  handleApiError,
  useGetServiceCaseOrdersQuery,
  useGetServiceCaseOrderVariantsQuery,
  usePostServiceCaseOrderJobServiceCampaignMutation,
  usePostServiceOrderMutation,
} from '@omnetic-dms/shared';

import {LiteralUnionAutocomplete, suffixTestId, TestIdProps} from 'shared';

import {RequestOrder} from '../../../components/RequestOrder';

interface AddRecallCampaignProps extends TestIdProps {
  serviceCaseId: string;
  campaign: CampaignData;
  onClose: () => void;
}

export function AddRecallCampaign(props: AddRecallCampaignProps) {
  const [selectedOrder, setSelectedOrder] = useState<LiteralUnionAutocomplete<'NEW'> | null>(null);
  const [variant, setVariant] = useState<number | string[] | string | null>(null);

  const {
    data: serviceCaseOrders,
    isLoading,
    isError,
  } = useGetServiceCaseOrdersQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const {data: orderVariants} = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const [postServiceOrder, {isLoading: isPostServiceOrderLoading}] = usePostServiceOrderMutation();
  const [postCampaign, {isLoading: isPostCampaignLoading}] =
    usePostServiceCaseOrderJobServiceCampaignMutation();

  const handleMoveRequest = async () => {
    if (isNil(selectedOrder)) {
      return;
    }

    const orderId =
      selectedOrder === 'NEW' && isString(variant)
        ? await postServiceOrder({
            serviceCaseId: props.serviceCaseId,
            body: {orderVariantId: variant},
          })
            .unwrap()
            .then((response) => response?.id)
        : selectedOrder;

    postCampaign({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: orderId ?? '',
      body: {serviceCampaign: props.campaign},
    })
      .unwrap()
      .then(props.onClose)
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const activeOrders = serviceCaseOrders?.filter((order) =>
    not(includes(order?.state, ['LOCKED', 'ARCHIVED', 'CLOSED', 'CANCELLED']))
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={23}>
      <VStack spacing={4}>
        <Text color="tertiary" size="small">
          {i18n.t('entity.campaigns.labels.addToOrderDescription')}
        </Text>
        {activeOrders?.map(
          (order, index) =>
            order && (
              <RequestOrder
                key={order?.id}
                order={order}
                isSelected={selectedOrder === order?.id}
                onSelect={() => setSelectedOrder(order?.id ?? null)}
                data-testid={suffixTestId(`requestOrder-[${index}]`, props)}
              />
            )
        )}
        <Card variant="inlineWhite">
          <Clickable
            onClick={() => setSelectedOrder('NEW')}
            data-testid={suffixTestId('selectNew', props)}
          >
            <HStack spacing={3} align="center">
              <RadioItem
                value={selectedOrder === 'NEW'}
                data-testid={suffixTestId('selected', props)}
              />
              <Heading size={4}>{i18n.t('entity.order.labels.newServiceOrder')}</Heading>
            </HStack>
          </Clickable>
          <Show when={selectedOrder === 'NEW'}>
            <Space vertical={4} />
            <Label>{i18n.t('entity.order.labels.orderVariant')}</Label>
            <Choice
              value={variant}
              onChange={setVariant}
              options={getOptionsFromServiceCaseOrderVariants(orderVariants)}
              placeholder={i18n.t('general.labels.select')}
              menuInPortal
              data-testid={suffixTestId('variant', props)}
            />
          </Show>
        </Card>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={props.onClose}
            data-testid={suffixTestId('cancel', props)}
            title={i18n.t('general.actions.cancel')}
          />
          <Button
            onClick={handleMoveRequest}
            isDisabled={!selectedOrder || (selectedOrder === 'NEW' && isNil(variant))}
            isLoading={isPostServiceOrderLoading || isPostCampaignLoading}
            data-testid={suffixTestId('move', props)}
            title={i18n.t('entity.order.actions.moveHere')}
          />
        </ButtonGroup>
      </VStack>
    </DataStatus>
  );
}
