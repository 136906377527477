import {Card, Chips, ChipsOption} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';

import {useState} from 'react';

import {defaultTo} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {GetWarehousesResponse} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {RequestTabMaterialPriceListTab} from './RequestTabMaterialPriceListTab';
import {RequestTabMaterialWarehouseTab} from './RequestTabMaterialWarehouseTab';

interface MaterialListProps extends TestIdProps {
  onWarehouseDispatch: (warehouseArticleId: string) => Promise<void>;
  onWarehouseRequest: (warehouseArticleId: string) => Promise<void>;
  onWarehouseNonBinding: (warehouseArticleId: string) => Promise<void>;
  availableWarehouses: GetWarehousesResponse;
}

const WAREHOUSE = 'WAREHOUSE';
const PRICE_LIST = 'PRICE_LIST';

export function RequestTabMaterialList(props: MaterialListProps) {
  const [selectedChip, setSelectedChip] = useState(WAREHOUSE);

  const chipsOptions: ChipsOption[] = [
    {
      label: i18n.t('entity.warehouse.labels.warehouse'),
      value: WAREHOUSE,
    },
    {
      label: i18n.t('entity.warehouse.labels.priceList'),
      value: PRICE_LIST,
    },
  ];

  return (
    <Card title={i18n.t('entity.warehouse.labels.itemsList')} isFullHeight>
      <VStack spacing={4} height="100%">
        <Chips
          value={[selectedChip]}
          onChange={(value) => setSelectedChip(defaultTo(WAREHOUSE, value?.[0]))}
          options={chipsOptions}
        />
        <Box flex={1}>
          <Show when={selectedChip === WAREHOUSE}>
            <RequestTabMaterialWarehouseTab
              onWarehouseDispatch={props.onWarehouseDispatch}
              onWarehouseRequest={props.onWarehouseRequest}
              onWarehouseNonBinding={props.onWarehouseNonBinding}
              availableWarehouses={props.availableWarehouses}
              data-testid={suffixTestId('tabs.warehouse', props)}
            />
          </Show>
          <Show when={selectedChip === PRICE_LIST}>
            <RequestTabMaterialPriceListTab />
          </Show>
        </Box>
      </VStack>
    </Card>
  );
}
