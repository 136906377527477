import {
  GetReceiveNoteCorrectionRequest,
  GetReceiveNoteCorrectionRequestSchema,
  GetReceiveNoteCorrectionResponse,
  GetReceiveNoteCorrectionResponseSchema,
  PatchReceiveNoteCorrectionDeleteRequest,
  PatchReceiveNoteCorrectionDeleteRequestSchema,
  PatchReceiveNoteCorrectionDeleteResponse,
  PatchReceiveNoteCorrectionDeleteResponseSchema,
  PatchReceiveNoteCorrectionRequest,
  PatchReceiveNoteCorrectionRequestSchema,
  PatchReceiveNoteCorrectionResponse,
  PatchReceiveNoteCorrectionResponseSchema,
  PostReceiveNoteCorrectionRequest,
  PostReceiveNoteCorrectionRequestSchema,
  PostReceiveNoteCorrectionResponse,
  PostReceiveNoteCorrectionResponseSchema,
} from '../types/api/metadaWarehouseReceiveNoteCorrection';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseReceiveNoteCorrectionApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getReceiveNoteCorrection: build.query<
      GetReceiveNoteCorrectionResponse,
      GetReceiveNoteCorrectionRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrections', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: GetReceiveNoteCorrectionRequestSchema,
        responseSchema: GetReceiveNoteCorrectionResponseSchema,
      },
    }),
    postReceiveNoteCorrection: build.mutation<
      PostReceiveNoteCorrectionResponse,
      PostReceiveNoteCorrectionRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/credit-note/manual',
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['receiveNoteCorrections'],
      extraOptions: {
        requestSchema: PostReceiveNoteCorrectionRequestSchema,
        responseSchema: PostReceiveNoteCorrectionResponseSchema,
      },
    }),
    patchReceiveNoteCorrection: build.mutation<
      PatchReceiveNoteCorrectionResponse,
      PatchReceiveNoteCorrectionRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/manual`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrections', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteCorrectionRequestSchema,
        responseSchema: PatchReceiveNoteCorrectionResponseSchema,
      },
    }),
    deleteReceiveNoteCorrection: build.mutation<
      PatchReceiveNoteCorrectionDeleteResponse,
      PatchReceiveNoteCorrectionDeleteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/delete`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['receiveNoteCorrections'],
      extraOptions: {
        requestSchema: PatchReceiveNoteCorrectionDeleteRequestSchema,
        responseSchema: PatchReceiveNoteCorrectionDeleteResponseSchema,
      },
    }),
  }),
});

export const {
  useGetReceiveNoteCorrectionQuery,
  usePostReceiveNoteCorrectionMutation,
  usePatchReceiveNoteCorrectionMutation,
  useDeleteReceiveNoteCorrectionMutation,
} = metadaWarehouseReceiveNoteCorrectionApi;
