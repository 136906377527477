import {FormControl, isCurrency} from 'platform/components';

import {useWatch} from 'react-hook-form';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  DEFAULT_CURRENCY,
  VatCheckTotalsDefinitionDocumentTypeEnum,
  useGetCashRegisterQuery,
  useGetTenantQuery,
} from '@omnetic-dms/shared';

import {AccountingDocumentFormValues} from '../../../types';

export const useGetInvoiceCurrency = (
  control: FormControl<AccountingDocumentFormValues>,
  listItemType: VatCheckTotalsDefinitionDocumentTypeEnum
) => {
  const [cashRegisterId] = useWatch({
    control,
    name: ['cashRegisterId'],
  });

  const {data: tenant} = useGetTenantQuery();
  const {data: cashRegister} = useGetCashRegisterQuery(
    {cashRegisterId: cashRegisterId ?? ''},
    {skip: isNilOrEmpty(cashRegisterId)}
  );

  const usedCurrency =
    (listItemType === 'cash-receipts' ? cashRegister?.currency : tenant?.currency) ??
    DEFAULT_CURRENCY;
  const currency = isCurrency(usedCurrency) ? usedCurrency : DEFAULT_CURRENCY;

  return [currency] as const;
};
