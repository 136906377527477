import {z} from 'zod';

import {BaseVoidResponseSchema, CreatedAndUpdatedBySchema, PaymentTypeSchema} from './base';

const ReceiveNoteCorrectionStateSchema = z.enum(['PENDING', 'COMPLETED']);

const ReceiveNoteCorrectionTotalPriceSchema = z.object({
  totalPriceWithoutVat: z.number(),
  totalPriceWithVat: z.number(),
});

export type ReceiveNoteCorrectionTotalPrice = z.infer<typeof ReceiveNoteCorrectionTotalPriceSchema>;

/**
 * GET
 */
export const GetReceiveNoteCorrectionRequestSchema = z.object({
  creditNoteId: z.string(),
});
export type GetReceiveNoteCorrectionRequest = z.infer<typeof GetReceiveNoteCorrectionRequestSchema>;

export const GetReceiveNoteCorrectionResponseSchema = z.object({
  creditNote: z
    .object({
      creditNoteId: z.string(),
      creditNoteNumber: z.string().optional(),
      supplierId: z.string(),
      warehouseId: z.string(),
      authorizationProfileId: z.string(),
      correctionInvoiceNumber: z.string().optional(),
      correctionInvoiceDate: z.string().optional(),
      paymentType: PaymentTypeSchema,
      currency: z.string().optional(),
      exchangeRate: z.number().optional(),
      ncConversion: z.string().optional(),
      state: ReceiveNoteCorrectionStateSchema,
      note: z.string().optional(),
      correctedReceiveNoteCorrectionId: z.string().optional(),
    })
    .merge(CreatedAndUpdatedBySchema),
  deliveryNote: z.object({
    deliveryNoteId: z.string(),
    deliveryNoteNumber: z.string().optional(),
    supplierId: z.string(),
    warehouseId: z.string().optional(),
    authorizationProfileId: z.string(),
    invoiceNumber: z.string().optional(),
    invoiceDate: z.string().optional(),
    paymentType: PaymentTypeSchema,
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
    issuedAt: z.string().optional(),
    created: z.string(),
    updated: z.string().optional(),
  }),
  receiveNote: z.object({
    receiveNoteId: z.string(),
    receiveNoteNumber: z.string().optional(),
    supplierId: z.string(),
    warehouseId: z.string(),
    authorizationProfileId: z.string(),
    invoiceNumber: z.string().optional(),
    invoiceDate: z.string().optional(),
    paymentType: PaymentTypeSchema,
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
    issuedAt: z.string().optional(),
    created: z.string(),
    updated: z.string().optional(),
  }),
  total: ReceiveNoteCorrectionTotalPriceSchema.optional(),
});
export type GetReceiveNoteCorrectionResponse = z.infer<
  typeof GetReceiveNoteCorrectionResponseSchema
>;

/**
 * POST requests
 */
export const PostReceiveNoteCorrectionRequestSchema = z.object({
  body: z.object({
    receiveNoteId: z.string(),
    supplierId: z.string(),
    warehouseId: z.string(),
    correctionInvoiceNumber: z.string().optional(),
    invoiceIssueDate: z.string().optional(),
    paymentType: PaymentTypeSchema,
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
  }),
});
export type PostReceiveNoteCorrectionRequest = z.infer<
  typeof PostReceiveNoteCorrectionRequestSchema
>;

export const PostReceiveNoteCorrectionResponseSchema = z.object({
  id: z.string(),
});
export type PostReceiveNoteCorrectionResponse = z.infer<
  typeof PostReceiveNoteCorrectionResponseSchema
>;

/**
 * PATCH requests
 */
export const PatchReceiveNoteCorrectionRequestSchema = z.object({
  creditNoteId: z.string(),
  body: z.object({
    warehouseId: z.string(),
    supplierId: z.string(),
    correctionInvoiceNumber: z.string().optional(),
    invoiceIssueDate: z.string().optional(),
    paymentType: PaymentTypeSchema,
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
  }),
});
export type PatchReceiveNoteCorrectionRequest = z.infer<
  typeof PatchReceiveNoteCorrectionRequestSchema
>;

export const PatchReceiveNoteCorrectionResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteCorrectionResponse = z.infer<
  typeof PatchReceiveNoteCorrectionResponseSchema
>;

export const PatchReceiveNoteCorrectionDeleteRequestSchema = z.object({
  body: z.object({
    creditNoteId: z.array(z.string()),
  }),
});
export type PatchReceiveNoteCorrectionDeleteRequest = z.infer<
  typeof PatchReceiveNoteCorrectionDeleteRequestSchema
>;

export const PatchReceiveNoteCorrectionDeleteResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteCorrectionDeleteResponse = z.infer<
  typeof PatchReceiveNoteCorrectionDeleteResponseSchema
>;

/**
 * PUT requests
 */
