import {Card, DataStatus} from 'platform/components';
import {Grid, Hide, Show, Space} from 'platform/foundation';

import {FC} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  NewOfferPurchaseVehicleResponseBody,
  OfferPriceResponseBody,
  featureFlags,
  useGetComplexPermissions,
  useGetPurchaseBusinessCasePricesOverviewQuery,
  useGetSaleVehicleQuery,
  usePermissions,
} from '@omnetic-dms/shared';
import {PriceBox} from '@omnetic-dms/teas';

import {Nullish} from 'shared';

type BusinessCasePurchaseVehiclePriceSummaryProps = {
  purchaseVehicle: NewOfferPurchaseVehicleResponseBody;
  expectedCosts?: OfferPriceResponseBody | Nullish;
  canReadCosts?: boolean;
  businessCaseId: string;
};

export const BusinessCasePurchaseVehiclePriceSummary: FC<
  BusinessCasePurchaseVehiclePriceSummaryProps
> = ({purchaseVehicle, expectedCosts, canReadCosts, businessCaseId}) => {
  const {
    data: purchaseVehiclePrices,
    isLoading,
    isError,
  } = useGetPurchaseBusinessCasePricesOverviewQuery({businessCaseId});
  const {canReadPurchasePrice, canReadSalePrice} = useGetComplexPermissions();

  const {data: saleVehicle} = useGetSaleVehicleQuery(
    {vehicleId: purchaseVehicle?.vehicleId ?? ''},
    {skip: isNilOrEmpty(purchaseVehicle?.vehicleId)}
  );
  const [canReadVehicleEarning] = usePermissions({
    permissionKeys: ['vehicleReadEarnings'],
  });

  const buyingPrice = purchaseVehicle?.buying?.vatDeductible
    ? purchaseVehicle?.buying.buyingPrice.priceWithVat
    : purchaseVehicle?.buying?.buyingPrice.priceNotDeductible;

  const buyingPriceSub = purchaseVehicle?.buying?.vatDeductible
    ? purchaseVehicle?.buying.buyingPrice.priceWithoutVat
    : undefined;

  const sellingPrice = purchaseVehicle?.buying?.vatDeductible
    ? purchaseVehicle?.pricing.sellingPrice.priceWithVat
    : purchaseVehicle?.pricing.sellingPrice.priceNotDeductible;

  const sellingPriceSub = purchaseVehicle?.buying?.vatDeductible
    ? purchaseVehicle?.pricing.sellingPrice.priceWithoutVat
    : undefined;

  const profit = purchaseVehicle?.profit?.withVat;

  const profitSub = purchaseVehicle?.buying?.vatDeductible
    ? purchaseVehicle?.profit?.withoutVat
    : undefined;

  return (
    <Card
      variant="inlineGrey"
      isExpandable
      title={i18n.t`page.businessCase.labels.salVehiclePriceSummary`}
    >
      <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
        <Grid columns={4}>
          <PriceBox
            data-testid={testIds.businessCase.buying('summary-sellingPrice')}
            align="left"
            price={sellingPrice}
            priceSub={sellingPriceSub}
            isVatDeductible={purchaseVehicle?.buying?.vatDeductible}
            title={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
          />
          <PriceBox
            data-testid={testIds.businessCase.buying('summary-buyingPrice')}
            align="left"
            price={buyingPrice}
            priceSub={buyingPriceSub}
            isVatDeductible={purchaseVehicle?.buying?.vatDeductible}
            title={i18n.t('entity.businessCase.labels.vehicleBuyingPrice')}
          />
          <PriceBox
            data-testid={testIds.businessCase.buying('summary-profit')}
            colored
            align="left"
            percentTag={Number(purchaseVehicle?.margin)}
            price={profit}
            priceSub={profitSub}
            isVatDeductible={purchaseVehicle?.buying?.vatDeductible}
            title={i18n.t('entity.businessCase.labels.expectedProfit')}
          />
          <Show when={canReadCosts}>
            <PriceBox
              data-testid={testIds.businessCase.buying('summary-costs')}
              colored
              align="left"
              price={expectedCosts?.withoutVat}
              title={i18n.t('entity.businessCase.labels.additionalExpectedCosts')}
            />
          </Show>
        </Grid>
      </Show>
      <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
        <DataStatus
          isEmpty={isNilOrEmpty(purchaseVehiclePrices)}
          isLoading={isLoading}
          isError={isError}
          minHeight={10}
        >
          <Grid columns={4}>
            <Show when={canReadPurchasePrice}>
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-buyingPrice')}
                align="left"
                price={purchaseVehiclePrices?.vehiclePurchasePrice?.withVat}
                priceSub={purchaseVehiclePrices?.vehiclePurchasePrice?.withoutVat}
                isVatDeductible={purchaseVehicle?.buying?.vatDeductible}
                title={i18n.t('entity.businessCase.labels.vehicleBuyingPrice')}
                foreignPrice={saleVehicle?.purchasePriceIsForeignCurrency}
              />
            </Show>
            <Show when={canReadCosts}>
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-costs')}
                align="left"
                price={purchaseVehiclePrices?.additionalCosts}
                title={i18n.t('entity.businessCase.labels.additionalExpectedCosts')}
                tooltipText={i18n.t('entity.businessCase.labels.additionalExpectedCostsTooltip')}
              />
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-totalExpectedCosts')}
                align="left"
                price={purchaseVehiclePrices?.totalCosts}
                title={i18n.t('entity.businessCase.labels.totalExpectedCosts')}
              />
              <Show when={canReadSalePrice && canReadPurchasePrice}>
                <PriceBox
                  data-testid={testIds.businessCase.buying('summary-vehicleGrossMargin')}
                  colored
                  align="left"
                  percentTag={Number(purchaseVehiclePrices?.vehicleMargin?.percentage)}
                  price={purchaseVehiclePrices?.vehicleMargin?.amount}
                  title={i18n.t('entity.businessCase.labels.vehicleGrossMargin')}
                  tooltipText={i18n.t('entity.businessCase.labels.vehicleGrossMarginTooltip')}
                />
              </Show>
              <Hide when={canReadSalePrice && canReadPurchasePrice}>
                <Space fillAvailable />
              </Hide>
            </Show>
            <Hide when={canReadCosts}>
              <Space fillAvailable />
              <Space fillAvailable />
              <Space fillAvailable />
            </Hide>

            <Show when={canReadPurchasePrice && canReadSalePrice}>
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-vehicleSellingPrice')}
                align="left"
                price={purchaseVehiclePrices?.vehicleSellingPrice?.withVat}
                priceSub={purchaseVehiclePrices?.vehicleSellingPrice?.withoutVat}
                isVatDeductible={purchaseVehicle?.buying?.vatDeductible}
                title={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
              />
            </Show>
            <Show when={canReadSalePrice && canReadVehicleEarning}>
              <Show when={canReadPurchasePrice}>
                <PriceBox
                  data-testid={testIds.businessCase.buying('summary-expectedEarnings')}
                  align="left"
                  price={purchaseVehiclePrices?.additionalEarnings}
                  title={i18n.t('entity.businessCase.labels.expectedEarnings')}
                  tooltipText={i18n.t('entity.businessCase.labels.expectedEarningsTooltip')}
                />
              </Show>
              <Hide when={canReadPurchasePrice}>
                <Space fillAvailable />
              </Hide>
              <PriceBox
                data-testid={testIds.businessCase.buying('summary-realEarnings')}
                align="left"
                price={purchaseVehiclePrices?.totalEarnings}
                title={i18n.t('entity.businessCase.labels.totalExpectedEarnings')}
              />
              <Show when={canReadPurchasePrice}>
                <PriceBox
                  data-testid={testIds.businessCase.buying('summary-totalGrossMargin')}
                  colored
                  align="left"
                  percentTag={Number(purchaseVehiclePrices?.totalMargin?.percentage)}
                  price={purchaseVehiclePrices?.totalMargin?.amount}
                  title={i18n.t('entity.businessCase.labels.totalGrossMargin')}
                  tooltipText={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
                />
              </Show>
            </Show>
            <Hide when={canReadSalePrice}>
              <Space fillAvailable />
              <Space fillAvailable />
              <Space fillAvailable />
            </Hide>
          </Grid>
        </DataStatus>
      </Show>
    </Card>
  );
};
