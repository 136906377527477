import {
  Button,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  OptionType,
  Separator,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {Grid, HStack} from 'platform/foundation';

import {DeepPartial} from 'react-hook-form';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  DocumentSeriesDefinitionRequestBody,
  DocumentSeriesDefinitionResponseBody,
  handleApiError,
  useBranches,
  useCreateDocumentSeriesDefinitionMutation,
  useUpdateDocumentSeriesDefinitionMutation,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {useSeriesOptions} from '../../../hooks/useSeriesOptions';

interface InvoiceDefinitionFormProps extends TestIdProps {
  definition: DocumentSeriesDefinitionResponseBody | null;
}

type FormValues = DocumentSeriesDefinitionRequestBody;

export function InvoiceDefinitionForm(props: InvoiceDefinitionFormProps) {
  const isCreating = isNil(props.definition);

  const [updateDefinition] = useUpdateDocumentSeriesDefinitionMutation();
  const [createDefinition] = useCreateDocumentSeriesDefinitionMutation();

  const {groupedSeries, isSeriesError, isSeriesLoading} = useSeriesOptions();
  const {branchOptions, isLoading: isBranchesLoading, isError: isBranchesError} = useBranches();

  const isLoading = isSeriesLoading || isBranchesLoading;
  const isError = isBranchesError || isSeriesError;

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const submitAction = isCreating ? createDefinition : updateDefinition;

    await submitAction({
      documentSeriesDefinitionRequestBody: data,
      documentSeriesDefinitionId: props.definition?.id ?? '',
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const initialValues: DeepPartial<DocumentSeriesDefinitionRequestBody> = {
    branchId: props.definition?.branchId,
    documentSeriesId: props.definition?.documentSeriesId,
    invoicingDocumentType: isInvoiceOrCTD(props.definition?.invoicingDocumentType)
      ? props.definition?.invoicingDocumentType
      : undefined,
    name: props.definition?.name,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormValues> onSubmit={handleSubmit} defaultValues={initialValues}>
        {(control) => (
          <>
            <Grid columns={2}>
              <FormField
                type="text"
                name="name"
                control={control}
                label={i18n.t('entity.seriesDefinition.labels.definitionName')}
                data-testid={suffixTestId('name', props)}
              />
              <FormField
                type="choice"
                options={branchOptions}
                name="branchId"
                control={control}
                label={i18n.t('entity.branch.labels.branch')}
                data-testid={suffixTestId('branch', props)}
              />
              <FormField
                type="choice"
                options={groupedSeries['accounting/invoice']}
                name="documentSeriesId"
                control={control}
                label={i18n.t('entity.document.labels.seriesTitle')}
                data-testid={suffixTestId('documentSeriesId', props)}
              />
              <FormField
                type="choice"
                options={documentSeriesOptions}
                name="invoicingDocumentType"
                control={control}
                label={i18n.t('entity.document.labels.documentType')}
                data-testid={suffixTestId('invoicingDocumentType', props)}
              />
            </Grid>
            <Separator />
            <HStack justify="flex-end" spacing={2}>
              <Button
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={closeCurrentDialog}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                control={control}
                type="submit"
                data-testid={suffixTestId('submit', props)}
                title={
                  isCreating ? i18n.t('general.actions.create') : i18n.t('general.actions.save')
                }
              />
            </HStack>
          </>
        )}
      </Form>
    </DataStatus>
  );
}

const documentSeriesOptions: OptionType<FormValues['invoicingDocumentType']>[] = [
  {
    value: 'invoice',
    label: i18n.t('entity.checkout.labels.invoice'),
  },
  {value: 'corrective_tax_document', label: i18n.t('entity.checkout.labels.correctiveTaxDocument')},
];

const isInvoiceOrCTD = (val: unknown): val is FormValues['invoicingDocumentType'] =>
  val === 'invoice' || val === 'corrective_tax_document';
