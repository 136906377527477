import {
  Alert,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
  Separator,
} from 'platform/components';
import {GridItem, Grid, Show, VStack, Space} from 'platform/foundation';
import {boolean, object} from 'yup';

import {useParams} from 'react-router-dom';

import {isNil, not} from 'ramda';
import {isNotNil, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  getOptionsFromAuthorizationProfiles,
  getOptionsFromBranches,
  handleApiError,
  useDuplicateErrorHandler,
  useGetAuthorizationProfilesQuery,
  useGetBranchListQuery,
  useGetSeriesListQuery,
  useGetServiceOrderIssueVariantQuery,
  useGetTemplatesQuery,
  useGetTenantQuery,
  usePatchServiceOrderIssueVariantMutation,
  usePostServiceOrderIssueVariantMutation,
} from '@omnetic-dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {getOptionsFromSeriesList} from '../../utils/getOptionsFromSeriesList';
import {getOptionsFromTemplates} from '../../utils/getOptionsFromTemplates';

export type ServiceOrderIssueVariantForm = {
  name: string;
  branchId: string;
  authorizationProfileId: string;
  docSeriesId: string;
  documentTemplateId: string;
  isDefault: boolean;
};

export function WarehouseServiceOrderIssueVariantsDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const {
    data: serviceOrderIssueVariant,
    isLoading: isServiceOrderIssueVariantLoading,
    isError: hasServiceOrderIssueVariantError,
  } = useGetServiceOrderIssueVariantQuery(
    {serviceOrderIssueVariantId: params.id ?? ''},
    {skip: isNil(params.id)}
  );
  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();
  const {
    data: authorizationProfiles,
    isLoading: isAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});
  const {
    data: branches,
    isLoading: isBranchesLoading,
    isError: hasBranchesError,
  } = useGetBranchListQuery();
  const {
    data: seriesList,
    isLoading: isSeriesListLoading,
    isError: hasSeriesListError,
  } = useGetSeriesListQuery({type: ['workshop/service_order_issue']});
  const {
    data: issueDocumentTemplates,
    isLoading: isIssueDocumentTemplatesLoading,
    isError: hasIIssueDocumentTemplatesError,
  } = useGetTemplatesQuery({documentKindCode: 'service-order-issue'});

  const [postServiceOrderIssueVariant] = usePostServiceOrderIssueVariantMutation();
  const [patchServiceOrderIssueVariant] = usePatchServiceOrderIssueVariantMutation();

  const isLoading =
    isServiceOrderIssueVariantLoading ||
    isTenantLoading ||
    isAuthorizationProfilesLoading ||
    isBranchesLoading ||
    isSeriesListLoading ||
    isIssueDocumentTemplatesLoading;

  const isError =
    hasServiceOrderIssueVariantError ||
    hasTenantError ||
    hasAuthorizationProfilesError ||
    hasBranchesError ||
    hasSeriesListError ||
    hasIIssueDocumentTemplatesError;

  const handleSubmit: FormSubmitHandler<ServiceOrderIssueVariantForm> = async (data) => {
    if (isNotNil(params.id)) {
      return await patchServiceOrderIssueVariant({
        serviceOrderIssueVariantId: params.id,
        body: data,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        })
        .then(() => handleNavigateBack())
        .catch(handleApiError);
    }

    await postServiceOrderIssueVariant(data)
      .unwrap()
      .then(() => {
        showNotification.success(
          i18n.t('entity.warehouse.notifications.serviceOrderIssueVariantCreated')
        );
      })
      .then(() => handleNavigateBack())
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesServiceOrderIssueVariants);
  };

  const getBranchId = (authorizationProfileId: string | number | null) => {
    if (not(isString(authorizationProfileId))) {
      return '';
    }

    const branchId = authorizationProfiles?.find(
      (ap) => ap.id === authorizationProfileId
    )?.branchId;

    return branchId ?? '';
  };

  const defaultValues: Partial<ServiceOrderIssueVariantForm> = {
    name: serviceOrderIssueVariant?.name,
    branchId: serviceOrderIssueVariant?.branchId,
    authorizationProfileId: serviceOrderIssueVariant?.authorizationProfileId,
    docSeriesId: serviceOrderIssueVariant?.docSeriesId,
    documentTemplateId: serviceOrderIssueVariant?.documentTemplateId,
    isDefault: serviceOrderIssueVariant?.isDefault,
  };

  const header: SettingsTemplateHeader = {
    title: isNil(params.id)
      ? i18n.t('entity.warehouse.labels.newServiceOrderIssueVariant')
      : serviceOrderIssueVariant?.name ?? '',
    breadcrumbs: [
      {
        label: i18n.t('entity.warehouse.labels.serviceOrderIssueVariants'),
        href: settingsRoutes.warehousesServiceOrderIssueVariants,
      },
    ],
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<ServiceOrderIssueVariantForm>
        schema={formSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        isFullHeight
      >
        {(control, formApi) => (
          <>
            <Show when={duplicateError}>
              <Alert
                variant="error"
                title={i18n.t(
                  'entity.warehouse.notifications.serviceOrderIssueVariantAlreadyExists'
                )}
                data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail(
                  'serviceOrderIssueVariantAlreadyExists'
                )}
              />
            </Show>
            <SettingsTemplate
              header={header}
              data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail('template')}
            >
              <VStack spacing={4}>
                <FormField
                  control={control}
                  type="switch"
                  name="isDefault"
                  label={i18n.t('entity.warehouse.labels.setAsDefaultHandlingUnit')}
                  data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail(
                    'isDefault'
                  )}
                />

                <Grid columns={2}>
                  <Separator />
                </Grid>

                <Grid columns={2}>
                  <FormField
                    control={control}
                    type="text"
                    name="name"
                    label={i18n.t('entity.warehouse.labels.name')}
                    isRequired
                    data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail('name')}
                  />
                </Grid>

                <Grid columns={4}>
                  <GridItem span={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="authorizationProfileId"
                      label={i18n.t('entity.warehouse.labels.authorizationProfile')}
                      options={getOptionsFromAuthorizationProfiles(authorizationProfiles)}
                      onChange={(authorizationProfileId) =>
                        formApi.setValue('branchId', getBranchId(authorizationProfileId))
                      }
                      isRequired
                      isNotClearable
                      data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail(
                        'authorizationProfileId'
                      )}
                    />
                  </GridItem>
                  <GridItem span={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="branchId"
                      options={getOptionsFromBranches(branches)}
                      label={i18n.t('entity.branch.labels.branch')}
                      isDisabled
                      data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail(
                        'branchId'
                      )}
                    />
                  </GridItem>
                </Grid>

                <Grid columns={4}>
                  <GridItem span={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="docSeriesId"
                      label={i18n.t('entity.warehouse.labels.serviceOrderIssueSeries')}
                      options={getOptionsFromSeriesList(seriesList, 'workshop/service_order_issue')}
                      isRequired
                      isNotClearable
                      data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail(
                        'docSeriesId'
                      )}
                    />
                  </GridItem>
                  <GridItem span={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="documentTemplateId"
                      label={i18n.t('entity.warehouse.labels.serviceOrderIssueTemplate')}
                      options={getOptionsFromTemplates(issueDocumentTemplates)}
                      isRequired
                      isNotClearable
                      data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail(
                        'documentTemplateId'
                      )}
                    />
                  </GridItem>
                </Grid>
                <Space fillAvailable />
                <SettingsFooter
                  actions={[
                    {
                      type: 'button',
                      variant: 'secondary',
                      title: isNil(params.id)
                        ? i18n.t('general.actions.discard')
                        : i18n.t('general.actions.discardChanges'),
                      onClick: handleNavigateBack,
                      'data-testid':
                        testIds.settings.warehousesServiceOrderIssueVariantsDetail('discard'),
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      variant: 'primary',
                      title: isNil(params.id)
                        ? i18n.t('general.actions.save')
                        : i18n.t('general.actions.saveChanges'),
                      'data-testid':
                        testIds.settings.warehousesServiceOrderIssueVariantsDetail('submit'),
                    },
                  ]}
                />
              </VStack>
            </SettingsTemplate>
          </>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  name: yupString.required(),
  authorizationProfileId: yupString.required(),
  docSeriesId: yupString.required(),
  documentTemplateId: yupString.required(),
  isDefault: boolean().default(false),
});
