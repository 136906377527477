import {DataStatus, FlagProps} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';
import {Nullish} from 'utility-types';

import {Helmet} from 'react-helmet-async';

import {isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  EntityHeader,
  Page,
  Section,
  useGetServiceCampaignHyundaiApiQuery,
} from '@omnetic-dms/shared';

import {parseDate, buildArray, useRequiredParams} from 'shared';

import {ServiceCampaignVehicles} from './components/ServiceCampaignVehicles';

export function ServiceCampaignDetail() {
  const {id} = useRequiredParams();
  const formatDateTime = useDateTimeFormatter();

  const {data, isLoading, isError} = useGetServiceCampaignHyundaiApiQuery({
    manufacturerActionNumber: id,
  });

  const stateFlag = match(data?.state)
    .with(
      'CANCELLED',
      () => ({label: i18n.t('general.labels.cancelled'), colorScheme: 'red'}) as const
    )
    .with(
      'ACTIVE',
      () => ({label: i18n.t('page.workshop.labels.active'), colorScheme: 'blue'}) as const
    )
    .with('CLOSED', () => ({label: i18n.t('general.labels.closed'), colorScheme: 'red'}) as const)
    .with(
      'RELEASED_NON_ACTIVE',
      () =>
        ({label: i18n.t('page.workshop.labels.releasedNonActive'), colorScheme: 'grey'}) as const
    )
    .otherwise(() => undefined);

  const getDate = (value: string | Nullish) =>
    isNotNil(value) ? formatDateTime('dateShort', parseDate(value)) : '-';

  return (
    <>
      <Helmet title={i18n.t('entity.campaigns.labels.serviceCampaign')} />
      <DataStatus isLoading={isLoading} isError={isError} minHeight="100%">
        <Page
          isFullHeight
          header={
            <EntityHeader
              data-testid={testIds.workshop.serviceCampaignDetail('header')}
              title={data?.description ?? ''}
              icon="action/build"
              flags={buildArray<FlagProps>().when(stateFlag, stateFlag as FlagProps)}
              parameters={[
                data?.manufacturerActionNumber ?? '-',
                i18n.t('page.workshop.labels.hyundai'),
                `${i18n.t('page.workshop.labels.startDate')}: ${getDate(data?.startDate)}`,
                `${i18n.t('page.workshop.labels.dueDate')}: ${getDate(data?.dueDate)}`,
              ]}
            />
          }
          data-testid={testIds.workshop.serviceCampaignDetail()}
        >
          <Section isFullHeight data-testid={testIds.workshop.serviceCampaignDetail('sections')}>
            <ServiceCampaignVehicles
              serviceCampaignId={id}
              data-testid={testIds.workshop.serviceCampaignDetail()}
            />
          </Section>
        </Page>
      </DataStatus>
    </>
  );
}
