import {Button, closeDialog, Label, openDialog} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isNilOrEmpty, padCharsStart} from 'ramda-adjunct';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useGetOccupiedSpaceQuery, useGetSectorQuery} from '../../../api/storageApi';
import i18n from '../../../i18n/i18n';
import {OccupiedSpaceRequestBody} from '../../../types/api/storage';
import {StorageSectorsDialog} from '../../StorageSectorsDialog/StorageSectorsDialog';

interface StorageLocationProps extends RequiredTestIdProps {
  setId: string | Nullish;
  isDisabled?: boolean;
  onSelectStorageLocationWithoutId?: (selectedSpace: OccupiedSpaceRequestBody | undefined) => void;
  selectStorageLocationWithoutId?: OccupiedSpaceRequestBody;
}

export function StorageLocation(props: StorageLocationProps) {
  const {data: selectStorageLocationWithoutId} = useGetSectorQuery(
    {sectorId: props.selectStorageLocationWithoutId?.sectorId ?? ''},
    {skip: isNilOrEmpty(props.selectStorageLocationWithoutId)}
  );

  const {data: occupiedSpace} = useGetOccupiedSpaceQuery(
    {recordId: props.setId ?? '', spaceType: 'TIRE_ORDER'},
    {skip: isNilOrEmpty(props.setId)}
  );
  const {data: occupiedSpaceSector} = useGetSectorQuery(
    {sectorId: occupiedSpace?.sectorId ?? ''},
    {skip: isNilOrEmpty(occupiedSpace)}
  );

  const occupiedSpaceLabel =
    occupiedSpace &&
    occupiedSpaceSector &&
    `${occupiedSpace.prefix}${padCharsStart(
      '0',
      String(occupiedSpaceSector.numberOfParkingSpaces - 1).length,
      String(occupiedSpace.number)
    )}`;

  const selectStorageLocationWithoutIdLabel =
    selectStorageLocationWithoutId &&
    props.selectStorageLocationWithoutId &&
    `${selectStorageLocationWithoutId.prefix}${padCharsStart(
      '0',
      String(selectStorageLocationWithoutId.numberOfParkingSpaces - 1).length,
      String(props.selectStorageLocationWithoutId.number)
    )}`;

  const selectedSpace: OccupiedSpaceRequestBody | undefined =
    props.selectStorageLocationWithoutId ??
    (occupiedSpace && isNotNil(occupiedSpace?.number) && isNotNil(occupiedSpace?.sectorId)
      ? {number: occupiedSpace.number, sectorId: occupiedSpace.sectorId}
      : undefined);

  return (
    <VStack align="flex-start">
      <Label>{i18n.t('entity.warehouse.labels.storageLocation')}</Label>
      <Button
        variant="link"
        leftIcon="custom/warehouse"
        isDisabled={props.isDisabled}
        title={
          occupiedSpaceLabel ??
          selectStorageLocationWithoutIdLabel ??
          i18n.t('entity.warehouse.actions.assignStorageLocation')
        }
        onClick={() =>
          openDialog(
            <StorageSectorsDialog
              spaceType="TIRE_ORDER"
              recordId={props.setId}
              isDefaultShowingFree
              onClose={() => closeDialog('storageLocationDialog')}
              selectedSpace={selectedSpace}
              onSelectStorageLocationWithoutId={props.onSelectStorageLocationWithoutId}
              data-testid={suffixTestId('storageLocationSelectDialog', props)}
            />,
            {
              id: 'storageLocationDialog',
              title: i18n.t('entity.warehouse.labels.assignStorageLocation'),
            }
          )
        }
        data-testid={suffixTestId('openStorageLocation', props)}
      />
    </VStack>
  );
}
