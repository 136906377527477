import 'ag-grid-enterprise';

import {ReactElement, useCallback, useMemo} from 'react';
import ReactDOM from 'react-dom';

import {Box} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {useGridTheme} from '../hooks/useGridTheme';
import {GridApi} from '../types/AgGridTypes';
import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {ToolpanelType} from '../types/ToolpanelType';
import {SettingsToolpanelRenderer} from './SettingsToolPanelRenderer';
import {ToolpanelToggleRenderer} from './ToolpanelToggleRenderer';

export interface DataGridAsideProps extends TestIdProps {
  dataGridSettings: FeGridSettings;
  dataGridProps: DataGridProps;
  gridApi?: GridApi;
  internalGridId: string;
  definition: TransformedDefinitionApiResponse;
  setSettings: (settings: FeGridSettings) => void;
}

export function DataGridAside({
  dataGridSettings,
  gridApi,
  internalGridId,
  definition,
  setSettings,
  ...rest
}: DataGridAsideProps) {
  const {asideTheme} = useGridTheme(dataGridSettings, definition);

  const {isFilterActive} = useDataGridFiltersModel();

  const showAside = useMemo(
    () =>
      definition.behavior.columnToolPanelVisible ||
      definition.behavior.filterToolPanelVisible ||
      definition.behavior.settingsToolPanelVisible,
    [
      definition.behavior.columnToolPanelVisible,
      definition.behavior.filterToolPanelVisible,
      definition.behavior.settingsToolPanelVisible,
    ]
  );

  const settingsToolPanelParent = document.querySelector(
    `#${internalGridId} .ag-custom-settings-toolpanel`
  );

  const toggleToolpanel = useCallback(
    (toolPanelKey: string) => {
      if (gridApi) {
        const isOpen = gridApi.getOpenedToolPanel() === toolPanelKey;
        if (isOpen) {
          gridApi.closeToolPanel();
        } else {
          gridApi.openToolPanel(toolPanelKey);
        }
      }
    },
    [gridApi]
  );

  const panelItems = useMemo<Array<ReactElement>>(() => {
    const items: Array<ReactElement> = [];
    if (!showAside) {
      return items;
    }

    if (definition.behavior.filterToolPanelVisible) {
      items.push(
        <ToolpanelToggleRenderer
          key="filters"
          toggleToolpanel={() => toggleToolpanel('filters')}
          toolpanel={ToolpanelType.filter}
          hasActiveFilter={isFilterActive}
          data-testid={suffixTestId('toolpanel', rest)}
        />
      );
    }
    if (definition.behavior.columnToolPanelVisible) {
      items.push(
        <ToolpanelToggleRenderer
          key="column"
          toggleToolpanel={() => toggleToolpanel('columns')}
          toolpanel={ToolpanelType.column}
          data-testid={suffixTestId('toolpanel', rest)}
        />
      );
    }
    if (definition.behavior.settingsToolPanelVisible) {
      items.push(
        <ToolpanelToggleRenderer
          key="settings"
          toggleToolpanel={() => toggleToolpanel('settings')}
          toolpanel={ToolpanelType.settings}
          data-testid={suffixTestId('toolpanel', rest)}
        />
      );
    }

    return items;
  }, [ToolpanelToggleRenderer, showAside, toggleToolpanel, isFilterActive, dataGridSettings]);

  if (!showAside) {
    return null;
  }

  return (
    <Box __css={asideTheme} data-testid="DGv4--aside">
      {panelItems}
      {settingsToolPanelParent &&
        definition.behavior.settingsToolPanelVisible &&
        ReactDOM.createPortal(
          <SettingsToolpanelRenderer
            gridApi={gridApi}
            settings={dataGridSettings}
            setSettings={setSettings}
            data-testid={suffixTestId('settingsToolpanel', rest)}
          />,
          settingsToolPanelParent
        )}
    </Box>
  );
}
