import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {captureMessage} from '@sentry/browser';
import {FilterModel} from 'ag-grid-community';

import {isEmpty, isNil} from 'ramda';

import {Preset, PutPresetRequest} from '../types/Api';
import {ConnectionOverride} from '../types/ConnectionOverride';
import {QueryFilterObject} from '../types/QueryFilterObject';
import {objectHasEveryEqualPropOfAnother} from '../utils/objectHasEveryEqualPropOfAnother';
import {useHttpCalls} from './useHttpCalls';

type UseLoadPresetsProps = {
  gridCode?: string;
  presetInstanceId?: string;
  connectionOverride?: ConnectionOverride;
  onApiError?: (error: FetchBaseQueryError) => void;
  queryModifier?: (filter: QueryFilterObject) => QueryFilterObject;
};

export const useLoadPresets = (props: UseLoadPresetsProps) => {
  const http = useHttpCalls({
    gridCode: props.gridCode,
    presetInstanceId: props.presetInstanceId,
    connectionOverride: props.connectionOverride,
    onApiError: props?.onApiError,
  });

  const handleUpdatePresetForCurrentQueryModifier = async (preset: Preset) => {
    const data = await http.getDataQuery(preset.dataQueryId);

    if (isNil(data) || isNil(data?.filters)) {
      return;
    }

    const staticFilters = props.queryModifier ? props.queryModifier({}) : {};

    const isAllStaticFilterPresent = objectHasEveryEqualPropOfAnother(staticFilters, data.filters);

    if (isAllStaticFilterPresent) {
      return;
    }

    const filters: FilterModel = {...data.filters, ...staticFilters};

    await http
      .createDataQuery({
        filters,
        order: data.order,
        smartSearch: data.smartSearch ?? '',
      })
      .then(async (queryIdResponseBody) => {
        if (!queryIdResponseBody) {
          return;
        }

        const newDataQueryId = queryIdResponseBody.dataQueryId;

        const presetData = await http.getPreset(preset.id);

        if (
          isNil(presetData) ||
          isNil(presetData?.gridSettings) ||
          isEmpty(presetData?.gridSettings) ||
          isNil(presetData?.columnsSettings) ||
          isEmpty(presetData?.columnsSettings)
        ) {
          captureMessage('updatePreset', (scope) =>
            scope.setLevel('error').setExtras({
              userDescription: 'update custom preset from useLoadPresets',
              state: presetData,
            })
          );
          return;
        }

        const modifiedPreset: PutPresetRequest = {
          gridSettings: presetData.gridSettings,
          columnsSettings: presetData.columnsSettings,
          dataQueryId: newDataQueryId,
        };

        http.updatePreset(preset.id, modifiedPreset);
      });
  };

  const loadPresets = () =>
    http.getPresets().then(async (response) => {
      if (!response) {
        return;
      }
      await Promise.all([
        ...response.map((preset) => handleUpdatePresetForCurrentQueryModifier(preset)),
      ]);
      return await http.getPresets();
    });

  return loadPresets;
};
