import {
  Alert,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {object, string} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useDuplicateErrorHandler,
  useGetDiscountGroupQuery,
  useGetWarehousesQuery,
  usePatchDiscountGroupMutation,
  usePostDiscountGroupMutation,
  getOptionsFromWarehouses,
} from '@omnetic-dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export type CreateNewDiscountGroupForm = {
  warehouseId: string;
  name: string;
  regularOrderDiscount: number;
  expressOrderDiscount: number;
};

export function WarehouseDiscountGroupDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();
  const {
    data: discountGroup,
    isLoading: isDiscountGroupLoading,
    isError: isDiscountGroupError,
  } = useGetDiscountGroupQuery({discountGroupId: id ?? ''}, {skip: isNil(id)});
  const [createDiscountGroup] = usePostDiscountGroupMutation();
  const [updateDiscountGroup] = usePatchDiscountGroupMutation();

  const isLoading = isNotNil(id)
    ? isWarehousesLoading || isDiscountGroupLoading
    : isWarehousesLoading;
  const isError = isNotNil(id) ? isWarehousesError || isDiscountGroupError : isWarehousesError;

  const handleSubmit: FormSubmitHandler<CreateNewDiscountGroupForm> = async (data) => {
    if (isNotNil(id)) {
      return await updateDiscountGroup({body: data, discountGroupId: id})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.warehouse.labels.discountGroupUpdated'));
          handleNavigateBack();
        })
        .catch(handleApiError);
    }
    await createDiscountGroup(data)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.labels.discountGroupCreated'));
        handleNavigateBack();
      })
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesDiscountGroup);
  };

  const header: SettingsTemplateHeader = {
    title: isNil(id) ? i18n.t('entity.warehouse.labels.newDiscountGroup') : discountGroup?.name,
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.workshopPresetsCategory('template')}
      header={header}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<CreateNewDiscountGroupForm>
          schema={FormSchema}
          defaultValues={discountGroup}
          onSubmit={handleSubmit}
        >
          {(control) => (
            <VStack spacing={4} width="50%">
              <Show when={duplicateError}>
                <Alert
                  variant="error"
                  title={i18n.t('entity.warehouse.labels.discountGroupAlreadyExists')}
                />
              </Show>
              <Grid columns={2}>
                <FormField
                  isNotClearable
                  isRequired
                  isDisabled={isNotNil(id)}
                  control={control}
                  type="choice"
                  name="warehouseId"
                  label={i18n.t('entity.warehouse.labels.warehouse')}
                  options={getOptionsFromWarehouses(warehouses)}
                />
              </Grid>
              <Separator />
              <Grid columns={2}>
                <FormField
                  isRequired
                  control={control}
                  type="text"
                  name="name"
                  label={i18n.t('general.labels.name')}
                />
              </Grid>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="number"
                  isStepperVisible
                  name="regularOrderDiscount"
                  label={`${i18n.t('entity.warehouse.labels.regularOrderDiscount')} (%)`}
                />
                <FormField
                  control={control}
                  type="number"
                  isStepperVisible
                  name="expressOrderDiscount"
                  label={`${i18n.t('entity.warehouse.labels.expressOrderDiscount')} (%)`}
                />
              </Grid>
              <Space fillAvailable />
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    variant: 'secondary',
                    title: isNil(id)
                      ? i18n.t('general.actions.discard')
                      : i18n.t('general.actions.discardChanges'),
                    onClick: handleNavigateBack,
                    'data-testid': testIds.settings.workshopPresetsCategoryDetail('discard'),
                  },
                  {
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    variant: 'primary',
                    title: isNil(id)
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.saveChanges'),
                    'data-testid': testIds.settings.workshopPresetsCategoryDetail('save'),
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
      </DataStatus>
    </SettingsTemplate>
  );
}

const FormSchema = object({
  warehouseId: string().required(),
  name: string().required(),
});
