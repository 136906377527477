import {isNil} from 'ramda';

import {NullishBoolean} from 'shared';

import {EntityHeaderFlag} from '../components/EntityHeader/EntityHeader';
import i18n from '../i18n/i18n';

export const getVehiclePhotographedFlagProps = (
  isPhotographed: NullishBoolean
): EntityHeaderFlag | null => {
  if (isNil(isPhotographed)) {
    return null;
  }

  return isPhotographed
    ? {
        colorScheme: 'blue',
        label: i18n.t('entity.vehicle.labels.photographed'),
      }
    : {
        colorScheme: 'neutral',
        label: i18n.t('entity.vehicle.labels.notPhotographed'),
      };
};
