import {
  CreateBillingInformationApiResponse,
  CreateBillingInformationApiArg,
  GetBillingInformationListApiResponse,
  GetBillingInformationApiResponse,
  GetBillingInformationApiArg,
  PutBillingInformationApiResponse,
  PutBillingInformationApiArg,
  CreateBranchApiResponse,
  CreateBranchApiArg,
  GetBranchListApiResponse,
  GetBranchApiResponse,
  GetBranchApiArg,
  PutBranchApiResponse,
  PutBranchApiArg,
  GetTenantApiResponse,
  PutTenantApiResponse,
  PutTenantApiArg,
  GetCebiaAutotracerAndReportConfigApiResponse,
  PatchCebiaAutotracerAndReportConfigApiResponse,
  PatchCebiaAutotracerAndReportConfigApiArg,
  GetCebiaFeatureDecoderConfigApiResponse,
  PatchCebiaFeatureDecoderConfigApiResponse,
  PatchCebiaFeatureDecoderConfigApiArg,
  GetCebiaVinDecoderConfigApiResponse,
  PatchCebiaVinDecoderConfigApiResponse,
  PatchCebiaVinDecoderConfigApiArg,
  GetKonzultaConfigApiResponse,
  PatchKonzultaConfigApiResponse,
  PatchKonzultaConfigApiArg,
  PostKonzultaSmsApiResponse,
  PostKonzultaSmsApiArg,
  GetCebiaRokvyConfigApiResponse,
  PatchCebiaRokvyConfigApiResponse,
  PatchCebiaRokvyConfigApiArg,
  GetRegisterApiResponse,
  GetDistraintRegisterApiResponse,
  PatchEuViesConfigApiArg,
  PatchDistraintRegisterConfigApiArg,
  PatchInsolvencyRegisterConfigApiArg,
  PatchInvalidDocumentRegisterConfigApiArg,
  PatchUnreliableVatPayerRegisterConfigApiArg,
  SendEmailRequestBody,
  GetEmailConfigApiResponse,
  GetAutostacjaSvConnectorConfigApiResponse,
  PatchAutostacjaSvConnectorConfigApiResponse,
  PatchAutostacjaSvConnectorConfigApiArg,
  GetOmneticVinDecoderConfigApiResponse,
  PatchOmneticVinDecoderConfigApiResponse,
  PatchOmneticVinDecoderConfigApiArg,
  GetEmployeePhotoApiArg,
  GetEmployeePhotoApiResponse,
  SetEmployeePhotoApiArg,
  SetEmployeePhotoApiResponse,
} from '../types/api/api';
import {omneticApi} from './baseApi/omneticApi';

export const tenantApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createBillingInformation: build.mutation<
      CreateBillingInformationApiResponse,
      CreateBillingInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/billing-information`,
        method: 'POST',
        body: queryArg.createBillingInformationRequestBody,
      }),
    }),
    getBillingInformationList: build.query<GetBillingInformationListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/billing-information`,
      }),
    }),
    getBillingInformation: build.query<
      GetBillingInformationApiResponse,
      GetBillingInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/billing-information/${queryArg.billingInformationId}`,
      }),
    }),
    putBillingInformation: build.mutation<
      PutBillingInformationApiResponse,
      PutBillingInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/billing-information/${queryArg.billingInformationId}`,
        method: 'PUT',
        body: queryArg.putBillingInformationRequestBody,
      }),
    }),
    createBranch: build.mutation<CreateBranchApiResponse, CreateBranchApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/branch`,
        method: 'POST',
        body: queryArg.createBranchRequestBody,
      }),
    }),
    getBranchList: build.query<GetBranchListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/branch`,
      }),
      providesTags: ['BranchList'],
    }),
    getBranch: build.query<GetBranchApiResponse, GetBranchApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/branch/${queryArg.branchId}`,
      }),
      providesTags: (result) => [{type: 'Branch', id: result?.id}],
    }),
    putBranch: build.mutation<PutBranchApiResponse, PutBranchApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/branch/${queryArg.branchId}`,
        method: 'PUT',
        body: queryArg.putBranchRequestBody,
      }),
      invalidatesTags: (result) => [{type: 'Branch', id: result?.id}],
    }),
    getTenant: build.query<GetTenantApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant`,
      }),
      providesTags: ['Tenant'],
    }),
    putTenant: build.mutation<PutTenantApiResponse, PutTenantApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant`,
        method: 'PUT',
        body: queryArg.putTenantRequestBody,
      }),
      invalidatesTags: ['Tenant'],
    }),
    getCebiaAutotracerAndReportConfig: build.query<
      GetCebiaAutotracerAndReportConfigApiResponse,
      void
    >({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/cebia-autotracer-and-report`,
      }),
      providesTags: ['CebiaAutotracerAndReportConfig'],
    }),
    patchCebiaAutotracerAndReportConfig: build.mutation<
      PatchCebiaAutotracerAndReportConfigApiResponse,
      PatchCebiaAutotracerAndReportConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/cebia-autotracer-and-report`,
        method: 'PATCH',
        body: queryArg.cebiaAutotracerAndReportConfigRequestBody,
      }),
      invalidatesTags: ['CebiaAutotracerAndReportConfig'],
    }),
    getCebiaFeatureDecoderConfig: build.query<GetCebiaFeatureDecoderConfigApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/cebia-feature-decoder`,
      }),
      providesTags: ['CebiaFeatureDecoderConfig'],
    }),
    patchCebiaFeatureDecoderConfig: build.mutation<
      PatchCebiaFeatureDecoderConfigApiResponse,
      PatchCebiaFeatureDecoderConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/cebia-feature-decoder`,
        method: 'PATCH',
        body: queryArg.cebiaFeatureDecoderConfigRequestBody,
      }),
      invalidatesTags: ['CebiaFeatureDecoderConfig'],
    }),
    getCebiaVinDecoderConfig: build.query<GetCebiaVinDecoderConfigApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/cebia-vin-decoder`,
      }),
      providesTags: ['CebiaVinDecoderConfig'],
    }),
    patchCebiaVinDecoderConfig: build.mutation<
      PatchCebiaVinDecoderConfigApiResponse,
      PatchCebiaVinDecoderConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/cebia-vin-decoder`,
        method: 'PATCH',
        body: queryArg.cebiaVinDecoderConfigRequestBody,
      }),
      invalidatesTags: ['CebiaVinDecoderConfig'],
    }),
    getOmneticVinDecoderConfig: build.query<GetOmneticVinDecoderConfigApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/omnetic-vin-decoder`,
      }),
      providesTags: ['OmneticVinDecoderConfig'],
    }),
    patchOmneticVinDecoderConfig: build.mutation<
      PatchOmneticVinDecoderConfigApiResponse,
      PatchOmneticVinDecoderConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/omnetic-vin-decoder`,
        method: 'PATCH',
        body: queryArg.omneticVinDecoderConfigRequestBody,
      }),
      invalidatesTags: ['OmneticVinDecoderConfig'],
    }),
    getKonzultaConfig: build.query<GetKonzultaConfigApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/konzulta-sms`,
      }),
    }),
    patchKonzultaConfig: build.mutation<PatchKonzultaConfigApiResponse, PatchKonzultaConfigApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/konzulta-sms`,
        method: 'PATCH',
        body: queryArg.konzultaConfigRequestBody,
      }),
    }),
    postKonzultaSms: build.mutation<PostKonzultaSmsApiResponse, PostKonzultaSmsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/konzulta-sms`,
        method: 'POST',
        body: queryArg.konzultaSmsSenderRequestBody,
      }),
    }),
    getCebiaRokvyConfig: build.query<GetCebiaRokvyConfigApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/cebia-rokvy`,
      }),
      providesTags: ['CebiaRokvyConfig'],
    }),
    getUnreliableVatPayerRegister: build.query<GetRegisterApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/unreliable-vat-payer-register`,
      }),
      providesTags: ['UnreliableVatPayerRegister'],
    }),
    getInsolvencyRegister: build.query<GetRegisterApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/insolvency-register`,
      }),
      providesTags: ['InsolvencyRegister'],
    }),
    getDistraintRegister: build.query<GetDistraintRegisterApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/distraint-register`,
      }),
      providesTags: ['DistraintRegister'],
    }),
    getEUViesRegister: build.query<GetRegisterApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/eu-vies`,
      }),
      providesTags: ['EuVies'],
    }),
    getInvalidDocumentRegister: build.query<GetRegisterApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/invalid-document-register`,
      }),
      providesTags: ['InvalidDocumentRegister'],
    }),

    patchDistraintRegisterConfig: build.mutation<void, PatchDistraintRegisterConfigApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/distraint-register`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['DistraintRegister'],
    }),
    patchEuViesConfig: build.mutation<void, PatchEuViesConfigApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/eu-vies`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['EuVies'],
    }),
    patchInsolvencyRegisterConfig: build.mutation<void, PatchInsolvencyRegisterConfigApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/insolvency-register`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['InsolvencyRegister'],
    }),
    patchInvalidDocumentRegisterConfig: build.mutation<
      void,
      PatchInvalidDocumentRegisterConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/invalid-document-register`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['InvalidDocumentRegister'],
    }),
    patchUnreliableVatPayerRegisterConfig: build.mutation<
      void,
      PatchUnreliableVatPayerRegisterConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/unreliable-vat-payer-register`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['UnreliableVatPayerRegister'],
    }),

    sendEmail: build.mutation<void, SendEmailRequestBody>({
      query: (body) => ({
        url: `/dms/v1/tenant/integrated-service/email`,
        method: 'POST',
        body,
      }),
    }),

    getEmailConfig: build.query<GetEmailConfigApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/email`,
        method: 'GET',
      }),
    }),
    getAutostacjaSvConnectorConfig: build.query<GetAutostacjaSvConnectorConfigApiResponse, void>({
      query: () => ({
        url: `/dms/v1/tenant/integrated-service/autostacja-sv-connector`,
        method: 'GET',
      }),
    }),
    patchAutostacjaSvConnectorConfig: build.mutation<
      PatchAutostacjaSvConnectorConfigApiResponse,
      PatchAutostacjaSvConnectorConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/autostacja-sv-connector`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),

    patchCebiaRokvyConfig: build.mutation<
      PatchCebiaRokvyConfigApiResponse,
      PatchCebiaRokvyConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/tenant/integrated-service/cebia-rokvy`,
        method: 'PATCH',
        body: queryArg.cebiaRokvyConfigRequestBody,
      }),
      invalidatesTags: ['CebiaRokvyConfig'],
    }),
    getEmployeePhoto: build.query<GetEmployeePhotoApiResponse, GetEmployeePhotoApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/employee/${queryArg.employeeId}/photo`,
      }),
      providesTags: (res, error, args) => [{type: 'EmployeePhoto', id: args.employeeId}],
    }),
    setEmployeePhoto: build.mutation<SetEmployeePhotoApiResponse, SetEmployeePhotoApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/employee/${queryArg.employeeId}/photo`,
        method: 'PUT',
        body: queryArg.setEmployeePhotoRequestBody,
      }),
      invalidatesTags: (res, error, args) => [{type: 'EmployeePhoto', id: args.employeeId}],
    }),
  }),
});

export const selectTenant = tenantApi.endpoints.getTenant.select();
export const selectBranchById = (branchId: string) =>
  tenantApi.endpoints.getBranch.select({branchId});
export const selectBranchList = tenantApi.endpoints.getBranchList.select();

export const {
  useGetEmployeePhotoQuery,
  useSetEmployeePhotoMutation,
  useCreateBillingInformationMutation,
  useGetBillingInformationListQuery,
  useGetBillingInformationQuery,
  usePutBillingInformationMutation,
  useCreateBranchMutation,
  useGetBranchListQuery,
  useGetBranchQuery,
  usePutBranchMutation,
  useGetTenantQuery,
  useLazyGetTenantQuery,
  usePutTenantMutation,
  useGetCebiaAutotracerAndReportConfigQuery,
  usePatchCebiaAutotracerAndReportConfigMutation,
  useGetCebiaFeatureDecoderConfigQuery,
  usePatchCebiaFeatureDecoderConfigMutation,
  useGetCebiaVinDecoderConfigQuery,
  usePatchCebiaVinDecoderConfigMutation,
  useGetOmneticVinDecoderConfigQuery,
  usePatchOmneticVinDecoderConfigMutation,
  useGetKonzultaConfigQuery,
  usePatchKonzultaConfigMutation,
  usePostKonzultaSmsMutation,
  useGetCebiaRokvyConfigQuery,
  usePatchCebiaRokvyConfigMutation,
  useGetDistraintRegisterQuery,
  useGetInsolvencyRegisterQuery,
  useGetUnreliableVatPayerRegisterQuery,
  useGetInvalidDocumentRegisterQuery,
  useGetEUViesRegisterQuery,
  usePatchDistraintRegisterConfigMutation,
  usePatchEuViesConfigMutation,
  usePatchInsolvencyRegisterConfigMutation,
  usePatchInvalidDocumentRegisterConfigMutation,
  usePatchUnreliableVatPayerRegisterConfigMutation,
  useSendEmailMutation,
  useGetEmailConfigQuery,
  useGetAutostacjaSvConnectorConfigQuery,
  usePatchAutostacjaSvConnectorConfigMutation,
} = tenantApi;
