import {useFormatCurrency, useFormatNumber} from 'platform/locale';

import {CURRENCY_CZK, TRANSLATED_LANGUAGES} from '@omnetic-dms/shared';
import {useSourcingCurrency} from '@omnetic-dms/teas';

import {getSourcingTableColumns} from '../utils/getSourcingTableColumns';

export function useSourcingTableColumns() {
  const currency = useSourcingCurrency();

  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  return getSourcingTableColumns({
    currency: currency?.code ?? CURRENCY_CZK,
    formatNumber,
    formatCurrency,
    locale: TRANSLATED_LANGUAGES.czech,
  });
}
