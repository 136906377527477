import {DataStatus, Flag} from 'platform/components';
import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import i18n from '@omnetic-dms/i18n';
import {InsuranceOffer, InsuranceType} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {InsuranceCard} from './InsuranceCard';
import {RiskIcon} from './RiskIcon';

interface InsuranceOfferCardProps {
  insurance: InsuranceOffer | Nullish;
}

export function InsuranceOfferCard(props: InsuranceOfferCardProps) {
  const formatCurrency = useFormatCurrency();

  const isThirdPartyLiability = props.insurance?.type === InsuranceType.POV;

  return (
    <InsuranceCard>
      <DataStatus minHeight={31} isLoading={false} isError={false}>
        <HStack justify="space-between" height={31}>
          <Box flex={1} padding={6} overflow="hidden">
            <HStack spacing={[3, 6, 6, 12]}>
              <VStack spacing={1}>
                <Text size="xSmall" color="secondary">
                  {i18n.t('entity.insurance.labels.insuranceFee')}
                </Text>
                <Heading size={2} isSingleLine>
                  {formatCurrency(props.insurance?.insuranceWithDiscount ?? 0, 'CZK')}
                </Heading>
                <HStack spacing={1} align="center">
                  <Text size="small" color="secondary" strikethrough noWrap>
                    {formatCurrency(props.insurance?.insurance ?? 0, 'CZK')}
                  </Text>
                  <Flag
                    size="small"
                    colorScheme="green"
                    label={i18n.t('general.labels.discount')}
                  />
                </HStack>
              </VStack>

              <VStack spacing={4}>
                <Text size="xSmall" color="secondary" noWrap>
                  {i18n.t('entity.insurance.labels.povLimit')}
                </Text>
                <Text alternative noWrap>
                  {i18n.t('entity.insurance.labels.povLimitValue')}
                </Text>
              </VStack>

              <HStack width="100%" spacing={[1, 1, 1, 2]} justify="space-between" maxWidth={225}>
                <RiskIcon
                  title={i18n.t('entity.insurance.labels.risks.assistance')}
                  icon="pictogram/assistance"
                />
                <RiskIcon
                  title={i18n.t('entity.insurance.labels.risks.crash')}
                  isDisabled={isThirdPartyLiability}
                  icon="pictogram/car_crash"
                />
                <RiskIcon
                  title={i18n.t('entity.insurance.labels.risks.theft')}
                  isDisabled={isThirdPartyLiability}
                  icon="pictogram/steal"
                />
                <RiskIcon
                  title={i18n.t('entity.insurance.labels.risks.nature')}
                  isDisabled={isThirdPartyLiability}
                  icon="pictogram/natural_element"
                />
                <RiskIcon
                  title={i18n.t('entity.insurance.labels.risks.vandalism')}
                  isDisabled={isThirdPartyLiability}
                  icon="pictogram/vandalism"
                />
                <RiskIcon
                  title={i18n.t('entity.insurance.labels.risks.animal')}
                  isDisabled={isThirdPartyLiability}
                  icon="pictogram/animal"
                />
              </HStack>
            </HStack>
          </Box>

          <HStack shrink={0} grow={0}>
            <Box width={1} height="100%" backgroundColor="palettes.green.60.100" />
            <Box
              flex={1}
              height="100%"
              backgroundColor="palettes.green.10.100"
              paddingTop={6}
              paddingBottom={6}
              paddingLeft={6}
              paddingRight={10}
            >
              <VStack spacing={1}>
                <Text size="xSmall" color="secondary">
                  {i18n.t('entity.insurance.labels.averageFee')}
                </Text>
                <Heading size={2} color="success">
                  {formatCurrency(props.insurance?.commissionFull ?? 0, 'CZK')}
                </Heading>
                <Text size="small" color="secondary">
                  {i18n.t('entity.insurance.labels.feeYearly', {
                    amount: formatCurrency(props.insurance?.commissionYearly ?? 0, 'CZK'),
                  })}
                </Text>
              </VStack>
            </Box>
          </HStack>
        </HStack>
      </DataStatus>
    </InsuranceCard>
  );
}
