import {Action, Card, DataStatus} from 'platform/components';
import {Heading, Hide, Show, Text, VStack} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';

import {testIds} from '@omnetic-dms/routes';

import {buildArray, Nullish, TestIdProps} from 'shared';

import {useGetCustomerV2Query} from '../../../api/customerApi';
import {useEditCustomer} from '../../../hooks/useEditCustomer';
import i18n from '../../../i18n/i18n';
import {CustomerResponseBodyV2} from '../../../types/api/customer';
import {CustomerInformation} from '../../CustomerWidgetCard/components/CustomerInformation';
import {CustomerMatchOrCreate} from '../../CustomerWidgetCard/components/CustomerMatchOrCreate';

interface CustomerStepProps extends TestIdProps {
  customer: CustomerResponseBodyV2 | Nullish;
  customerName: string | Nullish;
  onCustomerSelect: (customer: CustomerResponseBodyV2) => void;
  onResetCustomer: () => void;
  isCustomerResetAllowed?: boolean;
}

export function CustomerStep(props: CustomerStepProps) {
  const {
    data: customer,
    isLoading,
    isError,
  } = useGetCustomerV2Query(
    {customerId: props.customer?.id ?? ''},
    {skip: isNil(props.customer?.id)}
  );
  const editCustomer = useEditCustomer(customer, props.onCustomerSelect);

  return (
    <VStack spacing={4} data-testid={testIds.vehicles.reservations('customerSelect')}>
      <Heading size={3}>{i18n.t('entity.customer.labels.customer')}</Heading>
      <Text size="small" color="secondary">
        {i18n.t('entity.vehicle.labels.reservationCustomerDescription')}
      </Text>
      <Hide when={isNotNil(props.customer?.id)}>
        <CustomerMatchOrCreate
          secondStepComponentType="VEHICLE_RESERVATION"
          onCustomer={props.onCustomerSelect}
          data-testid={testIds.vehicles.reservations('customerSelect')}
        />
      </Hide>
      <Show when={isNotNil(props.customer?.id)}>
        <Card
          variant="inlineGrey"
          title={props.customerName}
          actions={buildArray<Action>()
            .when(props.isCustomerResetAllowed, {
              title: i18n.t('entity.customer.labels.changeCustomer'),
              type: 'button',
              leftIcon: 'action/cached',
              variant: 'link',
              onClick: props.onResetCustomer,
            })
            .when(isNotNil(customer), {
              variant: 'link',
              type: 'button',
              title: i18n.t('general.actions.edit'),
              leftIcon: 'image/edit',
              onClick: editCustomer,
            })}
        >
          <DataStatus isLoading={isLoading} isError={isError}>
            <CustomerInformation
              secondStepComponentType="BUSINESS_CASE"
              customer={customer!}
              isContactPersonHidden
              data-testid={testIds.vehicles.reservations('customerInformation')}
            />
          </DataStatus>
        </Card>
      </Show>
    </VStack>
  );
}
