import {
  Action,
  Card,
  closeCurrentDialog,
  openConfirmDialog,
  openDialog,
  TabProps,
  Tabs,
} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject} from 'platform/datagrid';
import {Box, HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {always, head} from 'ramda';
import {isArray, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  ArticleFolderTree,
  handleApiError,
  useGetItemsBasketActionsQuery,
  usePostMaterialBasketItemForPriceListMutation,
  usePostMaterialBasketItemForWarehouseMutation,
  usePutItemsBasketActionMutation,
  useWarehouseDataGridTreeFolder,
  WarehouseAction,
} from '@omnetic-dms/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {ExternalSystemStateType} from '../types/externalSystemStateType';
import {OpenXentryLink} from './OpenXentryLink';

const EXTERNAL_SYSTEM_ADD_KEY = 'xentry-xhpi-cart';
const EXTERNAL_SYSTEM_UPLOAD_KEY = 'xentry-xhpi-inquiry';

interface MaterialListCardProps extends TestIdProps {
  onExternalSystemChange: (state: ExternalSystemStateType) => void;
}

export function MaterialListCard(props: MaterialListCardProps) {
  const {serviceCaseId, orderId, addMaterialRequestJobId} = useWorkshopUrl();
  const [folderTreeRef, dataGridModifier] = useWarehouseDataGridTreeFolder();

  const {data} = useGetItemsBasketActionsQuery({
    serviceCaseId,
    serviceOrderId: orderId,
    serviceJobId: addMaterialRequestJobId,
    basketType: 'material-basket',
  });
  const [putItemsBasketAction, {isLoading: isPutItemsLoading}] = usePutItemsBasketActionMutation();
  const [addMaterialBasketItemForWarehouse] = usePostMaterialBasketItemForWarehouseMutation();
  const [addMaterialBasketItemForPriceList] = usePostMaterialBasketItemForPriceListMutation();

  const externalSystemAddOption = data?.itemsBasketActions?.options?.find(
    (option) => option?.key === EXTERNAL_SYSTEM_ADD_KEY
  );
  const externalSystemUploadOption = data?.itemsBasketActions?.options?.find(
    (option) => option?.key === EXTERNAL_SYSTEM_UPLOAD_KEY
  );

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => {
      const filters = {
        ...filter,
        serviceCaseId,
        serviceOrderId: orderId,
        serviceJobId: addMaterialRequestJobId,
      };
      return dataGridModifier?.queryModifier ? dataGridModifier.queryModifier(filters) : filters;
    },
    [serviceCaseId, orderId, addMaterialRequestJobId, dataGridModifier]
  );

  const onAddClick = () => {
    putItemsBasketAction({
      basketType: 'material-basket',
      body: {actionKey: EXTERNAL_SYSTEM_ADD_KEY},
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addMaterialRequestJobId,
    })
      .unwrap()
      .then((response) => {
        openDialog(
          <OpenXentryLink
            xentryLink={response?.xentry_xhpi_cart?.xhpiUrl}
            onClose={closeCurrentDialog}
          />,
          {size: 'small'}
        );
        props.onExternalSystemChange('PREPARED');
      })
      .catch(handleApiError);
  };

  const onUploadClick = () => {
    openConfirmDialog({
      text: i18n.t('entity.addMaterial.lables.uploadFromXentry'),
      onConfirm: () =>
        putItemsBasketAction({
          basketType: 'material-basket',
          body: {actionKey: EXTERNAL_SYSTEM_UPLOAD_KEY},
          serviceCaseId,
          serviceOrderId: orderId,
          serviceJobId: addMaterialRequestJobId,
        })
          .unwrap()
          .then(() => props.onExternalSystemChange('UPLOAD_SCCESS'))
          .catch((error) =>
            handleApiError(error, {callback: () => props.onExternalSystemChange('UPLOAD_ERROR')})
          ),
    });
  };

  const warehouseArticleActionCallback: ActionCallback = ({actionKey, rowId}) => {
    const warehouseArticleId = isArray(rowId) ? head(rowId) : rowId;

    if (!warehouseArticleId) {
      return;
    }

    const warehouseAction = match<string, WarehouseAction | null>(actionKey)
      .with('nonBinding', always('non-binding'))
      .with('dispatch', always('dispatch-request'))
      .with('request', always('request'))
      .otherwise(always(null));

    warehouseAction &&
      addMaterialBasketItemForWarehouse({
        serviceCaseId,
        serviceOrderId: orderId,
        serviceJobId: addMaterialRequestJobId,
        warehouseAction,
        body: {warehouseArticleId},
      })
        .unwrap()
        .catch(handleApiError);
  };

  const supplierPriceListActionCallback: ActionCallback = ({actionKey, rowData}) => {
    switch (actionKey) {
      case 'priceList':
        if ('id' in rowData && isString(rowData.id)) {
          addMaterialBasketItemForPriceList({
            serviceCaseId,
            serviceOrderId: orderId,
            serviceJobId: addMaterialRequestJobId,
            body: {warehouseArticleId: rowData.id},
          })
            .unwrap()
            .catch(handleApiError);
        }
        break;
    }
  };

  const tabs: TabProps[] = [
    {
      id: 'warehouse',
      title: i18n.t('entity.warehouse.labels.warehouse'),
      content: (
        <Box height="75vh" width="100%">
          <HStack height="100%" spacing={4}>
            <Box
              flex={1}
              borderRight="1px solid"
              borderColor="palettes.neutral.40.100"
              paddingRight={4}
              padding={1}
              height="100%"
              overflowX="auto"
            >
              <ArticleFolderTree
                ref={folderTreeRef}
                data-testid={testIds.warehouse.articleList('articleFolderTree')}
              />
            </Box>
            <Box flex={3}>
              <DataGrid
                // DG must be re-rendered on folder change to update the query modifier
                // eslint-disable-next-line no-restricted-syntax
                key={dataGridModifier?.key}
                gridCode="warehouse-articles-cart"
                actionCallback={warehouseArticleActionCallback}
                emptyState={{
                  headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
                }}
                queryModifier={queryModifier}
                _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'CUSTOM'}}
                data-testid={testIds.workshop.serviceCaseDetail('warehouse-articles')}
              />
            </Box>
          </HStack>
        </Box>
      ),
    },
    {
      id: 'priceList',
      title: i18n.t('entity.warehouse.labels.priceList'),
      content: (
        <Box height="75vh" width="100%">
          <DataGrid
            gridCode="supplier-price-list-cart"
            actionCallback={supplierPriceListActionCallback}
            queryModifier={queryModifier}
            externalFilterId="work-catalogue-external-filter"
            data-testid={suffixTestId('workCatalogue', props)}
          />
        </Box>
      ),
    },
  ];

  return (
    <Card
      title={i18n.t('entity.addMaterial.lables.itemsList')}
      actions={buildArray<Action>()
        .when(externalSystemAddOption && externalSystemAddOption.style !== 'hidden', {
          type: 'button',
          variant: 'link',
          leftIcon: 'content/add_circle',
          title: i18n.t(`entity.addMaterial.actions.addFromExternalSystem`),
          isDisabled: externalSystemAddOption?.style === 'disabled',
          isLoading: externalSystemAddOption?.style === 'enable' && isPutItemsLoading,
          onClick: onAddClick,
        })
        .when(externalSystemUploadOption && externalSystemUploadOption.style !== 'hidden', {
          type: 'button',
          variant: 'link',
          leftIcon: 'file/upload',
          title: i18n.t(`entity.addMaterial.actions.uploadFromExternalSystem`),
          isDisabled: externalSystemUploadOption?.style === 'disabled',
          isLoading: externalSystemUploadOption?.style === 'enable' && isPutItemsLoading,
          onClick: onUploadClick,
        })}
      data-testid={suffixTestId('header', props)}
      isFullHeight
    >
      <VStack height="100%" grow={1}>
        <Tabs tabs={tabs} data-testid={testIds.workshop.serviceCaseDetail('tabs')} />
      </VStack>
    </Card>
  );
}
