import {FlagProps} from 'platform/components';

import {useMemo} from 'react';

import {isNil} from 'ramda';

import {Nullish} from 'shared';

import i18n from '../i18n/i18n';

export type ServiceStateType =
  | 'DEMAND'
  | 'DRAFT'
  | 'HANDED_OVER'
  | 'PLANNED'
  | 'CANCELLED'
  | 'CHECKED_IN'
  | 'CREATED';

export type OrderStateType =
  | 'CANCELLED'
  | 'CLOSED'
  | 'IN_PROGRESS'
  | 'INPROGRESS'
  | 'ARCHIVED'
  | 'LOCKED'
  | 'OFFER'
  | 'OPEN'
  | 'PAUSED'
  | 'PLANNED'
  | 'REQUEST'
  | 'WORKDONE'
  | 'WORK_DONE'
  | 'DRAFT'
  | 'INVOICED'
  | 'POSTPONED';

export type JobStateType =
  | 'ARCHIVED'
  | 'CANCELLED'
  | 'DRAFT'
  | 'REQUEST'
  | 'OFFER'
  | 'OPEN'
  | 'INPROGRESS'
  | 'PAUSED'
  | 'WORKDONE'
  | 'LOCKED'
  | 'CLOSED'
  | 'POSTPONED'
  | 'SVCPOSTPONEJOB_POSTPONE'
  | 'SVCPOSTPONEJOB_DONE'
  | 'SVCPOSTPONEJOB_CANCELLED';

export type StateType = ServiceStateType | OrderStateType | JobStateType;

const getStateFlag = (state: string | Nullish): FlagProps | undefined => {
  if (isNil(state)) {
    return;
  }

  switch (state) {
    case 'ARCHIVED':
      return {label: i18n.t('entity.serviceOrder.states.archived'), colorScheme: 'neutral'};
    case 'CLOSED':
      return {label: i18n.t('entity.serviceOrder.states.closed'), colorScheme: 'green'};
    case 'DRAFT':
      return {label: i18n.t('entity.serviceOrder.states.draft'), colorScheme: 'neutral'};
    case 'INPROGRESS':
    case 'IN_PROGRESS':
      return {
        label: i18n.t('entity.serviceOrder.states.inProgress'),
        colorScheme: 'blue',
      };
    case 'INVOICED':
      return {
        label: i18n.t('entity.serviceOrder.states.invoiced'),
        colorScheme: 'green',
      };
    case 'LOCKED':
      return {label: i18n.t('entity.serviceOrder.states.locked'), colorScheme: 'green'};
    case 'OFFER':
      return {label: i18n.t('entity.serviceOrder.states.offer'), colorScheme: 'blue'};
    case 'OPEN':
      return {label: i18n.t('entity.serviceOrder.states.open'), colorScheme: 'blue'};
    case 'PAUSED':
      return {label: i18n.t('entity.serviceOrder.states.paused'), colorScheme: 'orange'};
    case 'PLANNED':
      return {label: i18n.t('entity.serviceOrder.states.planned'), colorScheme: 'blue'};
    case 'REQUEST':
    case 'DEMAND':
      return {label: i18n.t('entity.serviceOrder.states.request'), colorScheme: 'blue'};
    case 'WORKDONE':
    case 'WORK_DONE':
      return {label: i18n.t('entity.serviceOrder.states.workDone'), colorScheme: 'green'};
    case 'SVCPOSTPONEJOB_POSTPONE':
    case 'POSTPONED':
      return {label: i18n.t('entity.serviceOrder.states.postponed'), colorScheme: 'neutral'};
    case 'CANCELLED':
      return {label: i18n.t('entity.serviceOrder.states.cancelled'), colorScheme: 'red'};
    case 'CHECKED_IN':
      return {label: i18n.t('entity.serviceOrder.states.checkedIn'), colorScheme: 'blue'};
    case 'HANDED_OVER':
      return {label: i18n.t('entity.serviceOrder.states.handedOver'), colorScheme: 'green'};
    case 'CREATED':
      return {label: i18n.t('entity.serviceOrder.states.created'), colorScheme: 'neutral'};
    default:
      return {label: state};
  }
};

export function useStateFlag(state: StateType | Nullish) {
  return useMemo(() => {
    const flagProps = getStateFlag(state);

    return flagProps;
  }, [state]);
}
