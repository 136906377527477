import {match, Pattern} from 'ts-pattern';

import {ReactNode} from 'react';

import {always, not} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {isFeatureEnabled} from 'shared';

import {useDevice} from '../DeviceProvider/useDevice';

export interface HideProps {
  when?: unknown;
  whenFeatureEnabled?: string;
  whenFeatureDisabled?: string;
  children: ReactNode | ReactNode[];
  onMobile?: boolean;
  onTablet?: boolean;
  onNotebook?: boolean;
  onDesktop?: boolean;
  inPrinter?: boolean;
}

export function Hide(props: HideProps) {
  const hideOnMobile = isTrue(props.onMobile);
  const hideOnTablet = isTrue(props.onTablet);
  const hideOnNotebook = isTrue(props.onNotebook);
  const hideOnDesktop = isTrue(props.onDesktop);
  const hideInPrinter = isTrue(props.inPrinter);
  const isResponsive =
    hideOnMobile || hideOnTablet || hideOnNotebook || hideOnDesktop || hideInPrinter;

  const device = useDevice();

  const isHidden = match([isResponsive, device, props])
    .with([false, Pattern.any, Pattern.any], always(false))
    .with([true, 'mobile', {onMobile: true}], always(true))
    .with([true, 'tablet', {onTablet: true}], always(true))
    .with([true, 'notebook', {onNotebook: true}], always(true))
    .with([true, 'desktop', {onDesktop: true}], always(true))
    .with([true, 'printer', {inPrinter: true}], always(true))
    .otherwise(always(false));

  if (isHidden) {
    return null;
  }

  if (!!props.when) {
    return null;
  }

  if (props.whenFeatureEnabled && isFeatureEnabled(props.whenFeatureEnabled)) {
    return null;
  }
  if (props.whenFeatureDisabled && not(isFeatureEnabled(props.whenFeatureDisabled))) {
    return null;
  }

  return <>{props.children}</>;
}
