import {Grid, Clickable, GridItem, Show, Hide} from 'platform/foundation';

import {FC, ReactNode} from 'react';

import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useAuditImages} from '../hooks/useAuditImages';
import {useConditionContext} from '../hooks/useConditionContext';
import {useConditionUploadContext} from '../hooks/useConditionUploadContext';
import {getFormFieldName} from '../utils/getFormFieldName';
import {AuditImages} from './AuditImages';
import {UploadContext} from './UploadContext';
import {UploadControl} from './UploadControl';

interface AdditionalInformationProps {
  categoryId: string;
  additionalPhotos?: AuditParamDefinition;
  additionalComment?: AuditParamDefinition;
  beside?: boolean;
  uniqueKey: string;
  photoGrid?: number;
}

export const AdditionalInformation: FC<AdditionalInformationProps & TestIdProps> = (props) => {
  const {additionalComment, additionalPhotos, beside, categoryId} = props;
  const {Field} = useFormRenderer();
  const {isDisabledForUser} = useConditionContext();
  const isAdditionalCommentMandatory = additionalComment?.mandatory;

  return (
    <AdditionalInformationWrapper {...props}>
      <Grid
        columns={beside ? 2 : 1}
        data-testid={suffixTestId('additionalInformation', props)}
        verticalSpacing={0}
      >
        {additionalComment && (
          <GridItem span={1}>
            <Field
              as="nullableText"
              multiline
              parse={(value) => value || ''}
              name={getFormFieldName(categoryId, additionalComment?.id)}
              label={i18n.t('general.labels.note')}
              textareaProps={{minRows: 1}}
              disabled={isDisabledForUser}
              required={isAdditionalCommentMandatory}
            />
          </GridItem>
        )}
        <GridItem span={beside ? 2 : 1}>
          {additionalPhotos && (
            <AdditionalPhotos
              {...props}
              data-testid={suffixTestId('additionalInformation-photos', props)}
            />
          )}
        </GridItem>
      </Grid>
    </AdditionalInformationWrapper>
  );
};

type AdditionalInformationWrapperProps = AdditionalInformationProps & {
  children: ReactNode;
};

const AdditionalInformationWrapper: FC<AdditionalInformationWrapperProps> = ({
  categoryId,
  additionalPhotos,
  children,
}) => {
  if (additionalPhotos) {
    return (
      <UploadContext categoryId={categoryId} paramDefinitionId={additionalPhotos.id} type="button">
        {children}
      </UploadContext>
    );
  }

  return <>{children}</>;
};

const AdditionalPhotos: FC<AdditionalInformationProps & TestIdProps> = ({
  categoryId,
  additionalPhotos,
  beside,
  photoGrid,
  ...rest
}) => {
  const {uploadImages} = useConditionUploadContext();
  const {assets} = useAuditImages();

  const hasAdditionalPhotos = isNilOrEmpty(assets) && isNilOrEmpty(uploadImages);

  return (
    <Clickable onContextMenu={(e) => e.preventDefault()}>
      <Show when={hasAdditionalPhotos}>
        <UploadControl
          type="button"
          isMultiple
          data-testid={suffixTestId('additionalPhotos', rest)}
        />
      </Show>
      <Hide when={hasAdditionalPhotos}>
        <Grid columns={isNotNil(photoGrid) ? photoGrid : beside ? 8 : 5}>
          <AuditImages
            isMultiple
            isAlwaysShowUpload
            ratio="4 / 3"
            data-testid={suffixTestId('additionalPhotos', rest)}
          />
        </Grid>
      </Hide>
    </Clickable>
  );
};
