import {Button, closeCurrentDialog, openDialog} from 'platform/components';
import {Box, Hide, Right, Show, Space, Stack, VStack} from 'platform/foundation';

import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {find, isNil, isNotNil, not, uniqBy} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useGetCustomerV2Query} from '../../api/customerApi';
import {useLazyGetVehicleCustomerQuery} from '../../api/metadaVehicleCustomer';
import {useGetVehicleQuery} from '../../api/vehicleApi';
import i18n from '../../i18n/i18n';
import {CustomerResponseBodyV2} from '../../types/api/customer';
import {GetVehicleCustomerApiResponse} from '../../types/api/vehicleCustomer';
import {handleApiError} from '../../utils/handleApiError';
import {AftersalesVehicleWidgetCard} from '../AftersalesVehicleWidgetCard/AftersalesVehicleWidgetCard';
import {CustomerMatchOrCreate} from '../CustomerWidgetCard/components/CustomerMatchOrCreate';
import {CustomerWidgetCard} from '../CustomerWidgetCard/CustomerWidgetCard';
import {SuggestedVehicles} from './components/SuggestedVehicles';

interface CustomerVehicleSelectionProps extends TestIdProps {
  customerId: string | Nullish;
  vehicleId: string | Nullish;
  suggestedVehicles: GetVehicleCustomerApiResponse | null;
  onCustomerChange: (customerId: string | Nullish) => void;
  onVehicleChange: (vehicleId: string | Nullish) => void;
  onSuggestedVehiclesChange: (suggestedVehicles: GetVehicleCustomerApiResponse | null) => void;
  onContinueClick?: () => void;
}

export function CustomerVehicleSelection(props: CustomerVehicleSelectionProps) {
  const [searchParams] = useSearchParams();

  const defaultVehicleId = searchParams.get('vehicleId');

  const [getVehicleCustomer] = useLazyGetVehicleCustomerQuery();

  const {currentData: customer} = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {skip: !props.customerId}
  );
  const {data: vehicle, refetch: refetchVehicle} = useGetVehicleQuery(
    {vehicleId: props.vehicleId ?? ''},
    {skip: not(props.vehicleId)}
  );

  useEffect(() => {
    if (isNotNilOrEmpty(defaultVehicleId)) {
      getVehicleCustomer({vehicleId: defaultVehicleId})
        .unwrap()
        .then((customers) => {
          const vehicleOperator = find(
            (ownership) => ownership?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR',
            customers ?? []
          );
          props.onCustomerChange(vehicleOperator?.customerId ?? null);
        })
        .catch(handleApiError);
    }
  }, [defaultVehicleId]);

  const handleVehicleSelect = (vehicleId: string | Nullish) => {
    props.onVehicleChange(vehicleId ?? null);

    if (isNil(customer) && isNotNil(vehicleId)) {
      if (props.vehicleId === vehicleId) {
        refetchVehicle();
      }

      getVehicleCustomer({vehicleId})
        .unwrap()
        .then((customers) => {
          const vehicleOperator = find(
            (ownership) => ownership?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR',
            customers ?? []
          );
          props.onCustomerChange(vehicleOperator?.customerId ?? null);
        })
        .catch(handleApiError);
    }
  };

  const handleCustomerChange = () =>
    openDialog(
      <CustomerMatchOrCreate
        secondStepComponentType="SERVICE_CASE"
        onCustomer={(customer) => {
          closeCurrentDialog();
          handleCustomerSelect(customer);
        }}
        data-testid={suffixTestId('customerChange', props)}
      />,
      {
        title: i18n.t('entity.customer.labels.customer'),
      }
    );

  const handleCustomerSelect = async (selectedCustomer: CustomerResponseBodyV2) => {
    props.onCustomerChange(selectedCustomer.id);

    if (!vehicle) {
      const vehicles = await getVehicleCustomer({customerId: selectedCustomer.id})
        .unwrap()
        .catch(handleApiError);

      if (vehicles?.length) {
        props.onSuggestedVehiclesChange(uniqBy((e) => e?.vehicleId, vehicles));
      }
    }
  };

  return (
    <VStack spacing={2} height="100%">
      <Stack direction={['column', 'column', 'column', 'row']} spacing={4}>
        <Box flex={1}>
          <Show when={props.suggestedVehicles}>
            {props.suggestedVehicles && (
              <SuggestedVehicles
                selectedVehicle={vehicle ?? null}
                suggestedVehicles={props.suggestedVehicles}
                onVehicleChange={(vehicle) => props.onVehicleChange(vehicle?.id ?? null)}
                onAddNew={() => {
                  props.onVehicleChange(null);
                  props.onSuggestedVehiclesChange(null);
                }}
                data-testid={suffixTestId('suggestedVehicles', props)}
              />
            )}
          </Show>
          <Hide when={props.suggestedVehicles}>
            <AftersalesVehicleWidgetCard
              onChange={handleVehicleSelect}
              vehicle={vehicle}
              data-testid={suffixTestId('vehicleWidgetCard', props)}
            />
          </Hide>
        </Box>
        <Box flex={1}>
          <CustomerWidgetCard
            onCustomer={handleCustomerSelect}
            onChange={handleCustomerChange}
            customer={customer}
            secondStepComponentType="SERVICE_CASE"
            hasVerification
            isDetailLinkVisible
            data-testid={suffixTestId('customerWidgetCard', props)}
          />
        </Box>
      </Stack>
      <Show when={props.onContinueClick}>
        <Space fillAvailable />
        <Right>
          <Button
            onClick={props.onContinueClick}
            variant="outlined"
            isDisabled={not(props.vehicleId) || not(props.customerId)}
            title={i18n.t('general.actions.continue')}
            data-testid={suffixTestId('continue', props)}
          />
        </Right>
      </Show>
    </VStack>
  );
}
