import {format} from 'date-fns';
import {DayMonthType} from 'platform/foundation';

import {curry, replace} from 'ramda';
import {isInvalidDate} from 'ramda-adjunct';

import {AppLanguage} from '../../../types/AppLanguage';
import {LocaleConfig} from '../../../types/LocaleConfig';
import {convertStringLocaleToDateFns} from '../../../utils/convertStringLocaleToDateFns';
import {LocaleDayMonthFormat} from '../types/LocaleDayMonthFormat';
import {getDateValueFromDayMonth} from './getDateValueFromDayMonth';

/**
 * Function for formating day month value into string
 * @param {LocaleConfig} localeConfig config for formating function
 * @param {AppLanguage} language selected language
 * @param {LocaleDayMonthFormat} dayMonthFormat formate of output string
 * @param {DayMonthType} value value to format
 * @returns {string} formated day month by locale config and selected language
 */
function uncurriedFormatDayMonth(
  localeConfig: LocaleConfig,
  language: AppLanguage,
  dayMonthFormat: LocaleDayMonthFormat,
  value: DayMonthType
) {
  const date = getDateValueFromDayMonth(value.month, value.day);

  if (isInvalidDate(date)) {
    return 'Invalid date';
  }

  const formatString = replace(
    /[/ .]y[y]*/,
    '',
    localeConfig.dateTimeFormat[dayMonthFormat]
  ).trim();

  return format(date, formatString, {
    locale: convertStringLocaleToDateFns(language),
  });
}

export const formatDayMonth = curry(uncurriedFormatDayMonth);
