import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {BaseDirectSaleType} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getOptionsFromDirectSaleTypes = (
  directSaleTypes: BaseDirectSaleType[] | Nullish
): Option[] => {
  if (isNil(directSaleTypes)) {
    return [];
  }

  return directSaleTypes.map((directSaleType) => ({
    label: directSaleType.name,
    value: directSaleType.directSaleTypeId,
  }));
};
