import {
  Button,
  Separator,
  openDialog,
  closeCurrentDialog,
  FormSubmitHandler,
} from 'platform/components';
import {Spinner} from 'platform/foundation';
import {Icon, Show, HStack, VStack, Text, Right} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isEmptyArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps, useDebouncedValue} from 'shared';

import {useCreateServiceVehicleMutation} from '../../../api/metadaServiceVehicle';
import {
  useCreateVehicleV2Mutation,
  useGetSimilarVehiclesV2Query,
  useLazyGetSimilarVehiclesV2Query,
  useSetAdditionalNoteMutation,
} from '../../../api/vehicleApi';
import i18n from '../../../i18n/i18n';
import {GetSimilarVehiclesApiResponse} from '../../../types/api/api';
import {handleApiError} from '../../../utils/handleApiError';
import {ServiceVehicleForm} from '../../ServiceVehicleForm/ServiceVehicleForm';
import {ServiceVehicleFormType} from '../../ServiceVehicleForm/types/ServiceVehicleFormType';
import {getVehicleV2FromServiceVehicle} from '../utils/getVehicleV2FromServiceVehicle';
import {MatchingVehicle} from './MatchingVehicle';

const DEFAULT_VEHICLE_COUNT = 4;

export interface MatchingVehiclesProps extends TestIdProps {
  vin: string | Nullish;
  registrationPlateContains: string | Nullish;
  onVehicleSelect?: (vehicleId: string) => void;
}

export function MatchingVehicles(props: MatchingVehiclesProps) {
  const [createVehicle] = useCreateVehicleV2Mutation();
  const [createServiceVehicle] = useCreateServiceVehicleMutation();
  const [setAdditionalNote] = useSetAdditionalNoteMutation();

  const [moreVehicles, setMoreVehicles] = useState<GetSimilarVehiclesApiResponse | undefined>(
    undefined
  );

  const debouncedVin = useDebouncedValue(props.vin, 700);
  const debouncedRegistrationPlateContains = useDebouncedValue(
    props.registrationPlateContains,
    700
  );

  const [getSimilarVehicles, {isLoading: isLoadingMore, isFetching: isFetchingMore}] =
    useLazyGetSimilarVehiclesV2Query();
  const {
    data: similarVehicles,
    isLoading,
    isFetching,
  } = useGetSimilarVehiclesV2Query(
    {
      size: DEFAULT_VEHICLE_COUNT,
      registrationPlateContains: debouncedRegistrationPlateContains ?? undefined,
      vinContains: debouncedVin ?? undefined,
    },
    {skip: !debouncedVin && !debouncedRegistrationPlateContains}
  );

  useEffect(() => {
    setMoreVehicles(undefined);
  }, [debouncedVin, debouncedRegistrationPlateContains]);

  const onLoadMoreClick = async () => {
    const moreVehiclesResponse = await getSimilarVehicles({
      offset: DEFAULT_VEHICLE_COUNT,
      registrationPlateContains: debouncedRegistrationPlateContains,
      vinContains: debouncedVin,
    })
      .unwrap()
      .catch(handleApiError);

    if (moreVehiclesResponse) {
      setMoreVehicles(moreVehiclesResponse.vehicles);
    }
  };

  const onCreateSubmit: FormSubmitHandler<ServiceVehicleFormType> = (data) =>
    createVehicle(getVehicleV2FromServiceVehicle(data))
      .unwrap()
      .then((vehicle) => {
        closeCurrentDialog();
        props.onVehicleSelect?.(vehicle.id);
        createServiceVehicle({vehicleId: vehicle.id});

        if (isNotNilOrEmpty(data.note)) {
          setAdditionalNote({
            vehicleId: vehicle.id,
            additionalNoteRequestBody: {note: data.note ?? ''},
          });
        }
      })
      .catch(handleApiError);

  const onCreateVehicle = () => {
    openDialog(
      <ServiceVehicleForm
        onCancel={closeCurrentDialog}
        onSubmit={onCreateSubmit}
        defaultValue={{vin: props.vin, registrationPlate: props.registrationPlateContains}}
        data-testid={suffixTestId('create-vehicle', props)}
      />,
      {
        title: i18n.t('entity.vehicle.labels.createVehicle'),
      }
    );
  };

  const isEmpty = isEmptyArray(similarVehicles?.vehicles);
  const isLoadingVehicles = isFetching || isLoading;
  const isLoadingMoreVehicles = isLoadingMore || isFetchingMore;
  const isShowingMatchingVehicles = debouncedVin || debouncedRegistrationPlateContains;
  const isCreatePrimary = !isShowingMatchingVehicles || isNilOrEmpty(similarVehicles?.vehicles);

  return (
    <VStack spacing={4}>
      <Show when={isShowingMatchingVehicles}>
        <Show when={isLoadingVehicles || isEmpty}>
          <HStack spacing={2} align="center" justify="center" height={22}>
            <Show when={isLoadingVehicles}>
              <Spinner size="small" />
              <Text size="small" color="tertiary" data-testid={suffixTestId('searching', props)}>
                {i18n.t('entity.vehicle.labels.searching')}
              </Text>
            </Show>
            <Show when={isEmpty && !isLoadingVehicles}>
              <Icon value="notification/do_disturb" color="text.tertiary" size={4} />
              <Text size="small" color="tertiary" data-testid={suffixTestId('noVehicles', props)}>
                {i18n.t('entity.vehicle.labels.noMatchingVehicles')}
              </Text>
            </Show>
          </HStack>
        </Show>
        <Show when={!isLoadingVehicles && !isEmpty}>
          <Text size="xSmall" color="secondary">
            {`${i18n.t('entity.vehicle.labels.matchingVehicles')} (${similarVehicles?.totalCount ?? 0})`}
          </Text>
          <VStack>
            {similarVehicles?.vehicles.map((vehicle, index) => (
              <VStack key={vehicle.id}>
                <Show when={index > 0}>
                  <Separator />
                </Show>
                <MatchingVehicle
                  onSelect={props.onVehicleSelect}
                  vehicle={vehicle}
                  searchedVin={props.vin}
                  searchedRegistrationPlate={props.registrationPlateContains}
                  data-testid={suffixTestId(`vehicle-[${index}]`, props)}
                />
              </VStack>
            ))}
            {moreVehicles?.map((vehicle, index) => (
              <VStack key={vehicle.id}>
                <Separator />
                <MatchingVehicle
                  onSelect={props.onVehicleSelect}
                  vehicle={vehicle}
                  searchedVin={props.vin}
                  searchedRegistrationPlate={props.registrationPlateContains}
                  data-testid={suffixTestId(`vehicle-[${index + DEFAULT_VEHICLE_COUNT}]`, props)}
                />
              </VStack>
            ))}
          </VStack>
        </Show>
        <Show
          when={(similarVehicles?.totalCount ?? 0) > DEFAULT_VEHICLE_COUNT && isNil(moreVehicles)}
        >
          <Button
            variant="link"
            onClick={onLoadMoreClick}
            isLoading={isLoadingMoreVehicles}
            leftIcon="navigation/expand_more"
            title={i18n.t('entity.customer.actions.more')}
            data-testid={suffixTestId(`more`, props)}
          />
        </Show>
      </Show>
      <Right>
        <Button
          onClick={onCreateVehicle}
          variant={isCreatePrimary ? 'primary' : 'secondary'}
          title={i18n.t('entity.vehicle.actions.createNewVehicle')}
          data-testid={suffixTestId(`createNewVehicle`, props)}
        />
      </Right>
    </VStack>
  );
}
