import {
  Attributes,
  AttributesRow,
  Button,
  ButtonGroup,
  Card,
  DialogFooter,
  Separator,
  closeCurrentDialog,
  closeDialog,
  openDialog,
} from 'platform/components';
import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Grid, GridItem, HStack, Heading, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';

import {composePath, RequiredTestIdProps, suffixTestId, useNavigate} from 'shared';

import {PriceListRowData} from '../../types/PriceListRowData';
import {ArticleCreate} from '../ArticleCreate/ArticleCreate';
import {PriceListDetailPrice} from './components/PriceListDetailPrice';

interface PriceListDetailProps extends RequiredTestIdProps {
  id: string;
  priceListRowData: PriceListRowData;
}

export function PriceListDetail(props: PriceListDetailProps) {
  const navigate = useNavigate();
  const [datagridRef, refreshDataGrid] = useRefreshDataGrid();

  const manufacturerNumber = props?.priceListRowData?.catalogNumber?.value;
  const manufacturer = props?.priceListRowData?.manufacturer?.value?.id;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          manufacturerNumber,
          manufacturer,
        },
      ]),
    [manufacturerNumber, manufacturer]
  );

  const handleCreateNewArticle = () => {
    openDialog(
      <ArticleCreate
        defaultValues={{
          manufacturerNumber,
          manufacturerId: manufacturer,
          name: props?.priceListRowData?.name?.value,
        }}
        onArticleCreate={(createdArticleData) => {
          if (createdArticleData.submittedButton === 'CREATE_AND_EDIT') {
            return navigate(
              composePath(warehouseRoutes.articleDetailOverview, {
                params: {
                  warehouseId: createdArticleData.formValues.warehouseId,
                  id: createdArticleData.articleId,
                },
              })
            );
          }

          closeCurrentDialog();
        }}
        onClose={() => closeDialog('createNewArticleDialog')}
        hasCreateAndEditButton
        data-testid={suffixTestId('createNewArticle', props)}
      />,
      {
        id: 'createNewArticleDialog',
        title: i18n.t('entity.warehouse.labels.createNewArticle'),
        scrollBehavior: 'outside',
        withAdditionalFooter: true,
      }
    );
  };

  const itemsAttributes: AttributesRow[] = [
    {label: i18n.t('entity.warehouse.labels.name'), value: props.priceListRowData?.name?.value},
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: props.priceListRowData?.catalogNumber?.value,
    },
    {
      label: i18n.t('entity.warehouse.labels.orderingNumber'),
      value: props.priceListRowData?.orderingNumber?.value,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: props.priceListRowData?.manufacturer?.value?.label,
    },
    {
      label: i18n.t('entity.warehouse.labels.supplier'),
      value: props.priceListRowData?.supplier?.value?.label,
    },
  ];

  return (
    <VStack spacing={4}>
      <Card
        variant="inlineGrey"
        title={i18n.t('entity.warehouse.labels.itemDetail')}
        data-testid={suffixTestId('itemDetail', props)}
      >
        <VStack>
          <Attributes
            rows={itemsAttributes}
            size="quarter"
            data-testid={suffixTestId('itemAttributes', props)}
          />

          <Separator />

          <Grid columns={4}>
            <GridItem span={1}>
              <PriceListDetailPrice
                label={`${i18n.t('entity.warehouse.labels.purchasePrice')} ${i18n.t('general.labels.w/oVat')}`}
                price={props.priceListRowData?.purchasePrice?.value?.amount}
                currency={props.priceListRowData?.purchasePrice?.value?.currency}
                data-testid={suffixTestId('prices.purchase', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <PriceListDetailPrice
                label={`${i18n.t('entity.warehouse.labels.recommendedPrice')} ${i18n.t('general.labels.w/oVat')}`}
                price={props.priceListRowData?.recommendedPrice?.value?.amount}
                currency={props.priceListRowData?.purchasePrice?.value?.currency}
                data-testid={suffixTestId('prices.recommended', props)}
              />
            </GridItem>
          </Grid>
        </VStack>
      </Card>

      <Heading size={5}>{i18n.t('entity.warehouse.labels.availability')}</Heading>

      <DataGrid
        ref={datagridRef}
        gridCode="warehouse-availability"
        autoHeight
        queryModifier={queryModifier}
        data-testid={suffixTestId('warehouseAvailability', props)}
      />

      <HStack>
        <Button
          variant="link"
          title={i18n.t('general.actions.refresh')}
          leftIcon="navigation/refresh"
          onClick={refreshDataGrid}
          data-testid={suffixTestId('actions.refresh', props)}
        />
      </HStack>

      <DialogFooter>
        <ButtonGroup
          align="right"
          buttons={[
            {
              title: i18n.t('entity.warehouse.actions.createNewArticle'),
              variant: 'secondary',
              onClick: handleCreateNewArticle,
              'data-testid': suffixTestId('actions.createNewArticle', props),
            },
            {
              title: i18n.t('general.actions.close'),
              variant: 'primary',
              onClick: closeCurrentDialog,
              'data-testid': suffixTestId('actions.close', props),
            },
          ]}
        />
      </DialogFooter>
    </VStack>
  );
}
