import {
  DataStatus,
  DialogFooter,
  Form,
  ButtonGroup,
  Button,
  FormButton,
  closeCurrentDialog,
  Separator,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {DeepPartial} from 'utility-types';
import {object} from 'yup';

import {defaultTo, not, isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  useGetHandlingUnitQuery,
  useGetHandlingUnitsQuery,
  useGetSupplierArticleQuery,
  useGetSuppliersQuery,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, yupNumber, yupString} from 'shared';

import {FALLBACK_HANDLING_UNIT} from '../../../../../constants/fallbackHandlingUnit';
import {SupplierArticlesAddNewForm} from '../types/SupplierArticlesAddNewForm';
import {SupplierArticlesAddNewCatalogInfo} from './SupplierArticlesAddNewCatalogInfo';
import {SupplierArticlesAddNewSupplierInfoForm} from './SupplierArticlesAddNewSupplierInfoForm';
import {SupplierArticlesAddNewSupplierToWarehouseMappingForm} from './SupplierArticlesAddNewSupplierToWarehouseMappingForm';

interface SupplierArticlesAddNewProps extends RequiredTestIdProps {
  deliveryQuantityMappingId?: string;
  isEditing?: boolean;
}

export function SupplierArticlesAddNew(props: SupplierArticlesAddNewProps) {
  const isEditing = defaultTo(false, props.isEditing);

  const {
    data: supplierArticle,
    isLoading: isSupplierArticleLoading,
    isError: hasSupplierArticleError,
  } = useGetSupplierArticleQuery(
    {deliveryQuantityMappingId: props.deliveryQuantityMappingId!},
    {skip: isNil(props.deliveryQuantityMappingId) || not(isEditing)}
  );

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: handlingUnits,
    isLoading: areHandlingUnitsLoading,
    isError: hasHandlingUnitsError,
  } = useGetHandlingUnitsQuery();

  const {
    data: deliveryUnit,
    isLoading: isDeliveryUnitLoading,
    isError: hasDeliveryUnitError,
  } = useGetHandlingUnitQuery(
    {id: supplierArticle?.deliveryUnitId!},
    {skip: isNil(supplierArticle?.deliveryUnitId)}
  );

  const {
    data: receiveUnit,
    isLoading: isReceiveUnitLoading,
    isError: hasReceiveUnitError,
  } = useGetHandlingUnitQuery(
    {id: supplierArticle?.receiveUnitId!},
    {skip: isNil(supplierArticle?.receiveUnitId)}
  );

  const isLoading =
    isSupplierArticleLoading ||
    areSuppliersLoading ||
    areHandlingUnitsLoading ||
    isDeliveryUnitLoading ||
    isReceiveUnitLoading;

  const hasError =
    hasSupplierArticleError ||
    hasSuppliersError ||
    hasHandlingUnitsError ||
    hasDeliveryUnitError ||
    hasReceiveUnitError;

  const defaultValues: DeepPartial<SupplierArticlesAddNewForm> = {
    supplierInfo: {
      supplierId: supplierArticle?.supplierId,
      supplierOrderingNumber: supplierArticle?.supplierOrderingNumber,
      supplierBulkPackageQuantity: supplierArticle?.supplierBulkPackageQuantity,
    },
    supplierToWarehouseMapping: {
      deliveryQuantity: supplierArticle?.deliveryQuantity,
      deliveryUnit: supplierArticle?.deliveryUnitId,
      warehouseQuantity: supplierArticle?.receiveQuantity,
      warehouseUnit: supplierArticle?.receiveUnitId,
    },
  };

  return (
    <DataStatus isLoading={isLoading} isError={hasError} minHeight={155}>
      <Form<SupplierArticlesAddNewForm> schema={formSchema} defaultValues={defaultValues}>
        {(control) => (
          <>
            <VStack spacing={4}>
              <SupplierArticlesAddNewSupplierInfoForm
                control={control}
                suppliers={suppliers}
                data-testid={suffixTestId('sections.supplierInfo', props)}
              />
              <Separator spacing={0} />
              <SupplierArticlesAddNewSupplierToWarehouseMappingForm
                control={control}
                handlingUnits={handlingUnits}
                deliveryUnit={deliveryUnit?.name ?? FALLBACK_HANDLING_UNIT}
                receiveUnit={receiveUnit?.name ?? FALLBACK_HANDLING_UNIT}
                data-testid={suffixTestId('sections.supplierToWarehouseMapping', props)}
              />
              <SupplierArticlesAddNewCatalogInfo
                articleName={supplierArticle?.articleName}
                supplierName={supplierArticle?.supplierName}
                manufactuterName={supplierArticle?.manufacturerName}
                supplierOrderingNumber={supplierArticle?.supplierOrderingNumber}
                manufacturerNumber={supplierArticle?.manufacturerNumber}
                supplierBulkPackageQuantity={supplierArticle?.supplierBulkPackageQuantity}
                recommendedPriceWithoutVat={supplierArticle?.recommendedPriceWithoutVat}
                purchasePriceWithoutVat={supplierArticle?.purchasePriceWithoutVat}
                currency={supplierArticle?.currency}
                data-testid={suffixTestId('sections.catalogInfo', props)}
              />
            </VStack>
            <DialogFooter>
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discard')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                  data-testid={suffixTestId('actions.discard', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  title={
                    isEditing
                      ? i18n.t('general.actions.saveChanges')
                      : i18n.t('general.actions.add')
                  }
                  data-testid={suffixTestId('actions.submit', props)}
                />
              </ButtonGroup>
            </DialogFooter>
          </>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  supplierInfo: object({
    supplierId: yupString.required(),
    supplierOrderingNumber: yupString,
    supplierBulkPackageQuantity: yupNumber.required().positive(),
  }),
  supplierToWarehouseMapping: object({
    deliveryQuantity: yupNumber.required().positive(),
    deliveryUnit: yupString.required(),
    warehouseQuantity: yupNumber.required().positive(),
    warehouseUnit: yupString.required(),
  }),
});
