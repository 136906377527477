import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  IconButton,
  openDialog,
} from 'platform/components';
import {Align, Box, Heading, HStack, VStack, Show} from 'platform/foundation';
import {object} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {eqProps, find, lensPath, map, not, pipe, set} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {testIds} from '@omnetic-dms/routes';

import {Nullish, suffixTestId, TestIdProps, yupString} from 'shared';

import {useAddress} from '../../hooks/useAddress';
import {useCustomerAddress} from '../../hooks/useCustomerAddress';
import {useCustomerGroups} from '../../hooks/useCustomerGroups';
import {useInstitution} from '../../hooks/useInstitution';
import i18n from '../../i18n/i18n';
import {CompGetInfoV3ApiResponse} from '../../types/api/company';
import {
  AddressRequestBodyV2,
  AddressResponseBodyV2,
  CreateCustomerAddressV2ApiResponse,
  CustomerRequestBody,
  CustomerResponseBodyV2,
} from '../../types/api/customer';
import {featureFlags} from '../../utils/featureFlags';
import {getCustomerDefaultValues} from '../../utils/getCustomerDefaultValues';
import {InternationalAddressForm} from '../AddressForm/InternationalAddressForm';
import {CountrySelect} from '../CountrySelect/CountrySelect';
import {DecodeBusinessIdButton} from '../DecodeBusinessIdButton/DecodeBusinessIdButton';

interface EditLegalPersonFormProps extends TestIdProps {
  isLoading?: boolean;
  customer: CustomerResponseBodyV2;
  onSubmit: FormSubmitHandler<CustomerRequestBody>;
  onClose?: () => void;
}

export function EditLegalPersonForm(props: EditLegalPersonFormProps) {
  const {institutionOptions} = useInstitution();
  const {customerGroupOptions} = useCustomerGroups();
  const {
    addressOptions,
    openCreateAddressDialog,
    openEditAddressDialog,
    createCustomerAddress,
    isCreateCustomerAddressLoading,
  } = useCustomerAddress(props.customer.id);

  const {composeAddress} = useAddress();
  const handleDecodeBusinessIdForm =
    (formApi: UseFormReturn<CustomerRequestBody>) => (company: CompGetInfoV3ApiResponse) => {
      const prepareAddressesStructure = (a: AddressResponseBodyV2) => ({
        id: a.id,
        address: composeAddress(a.address),
      });

      const compareWhetherAddressAlreadyExists = (address: {id: string; address: string | null}) =>
        eqProps('address', address, company?.[0]);

      const findCustomerAddress = pipe(
        map(prepareAddressesStructure),
        find(compareWhetherAddressAlreadyExists)
      )(props.customer?.addresses ?? []);

      const isAddressAlreadyExist = isNotNilOrEmpty(findCustomerAddress);

      /**
       * if the decoded address does not exist in the customer entity,
       * offer the user to add the address to their existing
       */
      if (not(isAddressAlreadyExist)) {
        const addressData: AddressRequestBodyV2 = {
          address: {
            street: company?.[0]?.street ?? '',
            descriptiveNumber: company?.[0]?.descriptiveNumber ?? null,
            orientationNumber: company?.[0]?.orientationNumber ?? null,
            city: company?.[0]?.city ?? '',
            zip: company?.[0]?.zipCode ?? '',
            state: company?.[0]?.state,
            country: company?.[0]?.country ?? '',
            addressComplement: null,
            prefix: company?.[0]?.prefix ?? null,
            district: null,
          },
          type: i18n.t('entity.person.permanentAddressId'),
          invalid: null,
        };

        openDialog(
          <InternationalAddressForm
            data-testid={testIds.customer.detail('decode-dialog')}
            isLoading={isCreateCustomerAddressLoading}
            address={addressData}
            onSubmit={createCustomerAddress((address) =>
              formApi?.setValue('businessInfo.businessAddressId', address.id)
            )}
          />,
          {
            'data-testid': testIds.customer.detail('decode-dialog'),
            title: i18n.t('entity.customer.labels.wouldYouLikeToAddNewAddress'),
            withAdditionalFooter: true,
            scrollBehavior: 'outside',
          }
        );
      }
      /**
       * if the address exists in the customer entity, set its default value in the form
       */
      if (isAddressAlreadyExist && findCustomerAddress?.id) {
        formApi.setValue('businessInfo.businessAddressId', findCustomerAddress.id);
      }

      pipe(
        set(lensPath(['businessInfo', 'businessInfoData']), {
          countryOfRegistrationCode: company?.[0]?.country ?? null,
          registrationNumber: company?.[0]?.businessId,
          vatNumber: company?.[0]?.taxId ?? null,
          fileNumber: company?.[0]?.fileNumber ?? null,
          tradeName: company?.[0]?.businessName ?? null,
        }),
        formApi.reset
      )(formApi.getValues());
    };

  const onCreateAddress =
    (formApi: UseFormReturn<CustomerRequestBody>) =>
    (address: CreateCustomerAddressV2ApiResponse) =>
      formApi.setValue('businessInfo.businessAddressId', address.id);

  const onSubmit: FormSubmitHandler<CustomerRequestBody> = async (values, a, b) => {
    await schema.validate(values);
    await props.onSubmit(values, a, b);
  };

  return (
    <Form<CustomerRequestBody>
      onSubmit={onSubmit}
      defaultValues={getCustomerDefaultValues(props.customer)}
      schema={schema}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="businessInfo.businessInfoData.registrationNumber"
                label={i18n.t('entity.businessInfo.labels.registrationNumber')}
                data-testid={suffixTestId('registrationNumber', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="businessInfo.businessInfoData.vatNumber"
                label={i18n.t('entity.businessInfo.labels.vatNumber')}
                data-testid={suffixTestId('vatNumber', props)}
              />
            </Box>
            <HStack height={13} align="flex-end">
              <DecodeBusinessIdButton
                businessId={formApi.watch('businessInfo.businessInfoData.registrationNumber')}
                vatId={formApi.watch('businessInfo.businessInfoData.vatNumber')}
                countryCode={formApi.watch(
                  'businessInfo.businessInfoData.countryOfRegistrationCode'
                )}
                onSuccess={handleDecodeBusinessIdForm(formApi)}
                data-testid={suffixTestId('decode', props)}
              />
            </HStack>
          </HStack>
          <Box flex={1}>
            <FormField
              control={control}
              type="text"
              name="businessInfo.businessInfoData.tradeName"
              label={i18n.t('entity.businessInfo.labels.tradeName')}
              isRequired
              data-testid={suffixTestId('tradeName', props)}
            />
          </Box>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="businessInfo.businessInfoData.fileNumber"
                label={i18n.t('entity.businessInfo.labels.fileNumber')}
                data-testid={suffixTestId('fileNumber', props)}
              />
            </Box>
            <Box flex={1}>
              <CountrySelect<CustomerRequestBody>
                control={control}
                name="businessInfo.businessInfoData.countryOfRegistrationCode"
                label={i18n.t('entity.businessInfo.labels.countryOfRegistrationCode')}
                isRequired
                menuInPortal
                data-testid={suffixTestId('countryOfRegistrationCode', props)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="businessInfo.businessAddressId"
                options={addressOptions}
                isRequired
                label={i18n.t('contractInformation.labels.businessAddressId')}
                menuInPortal
                data-testid={suffixTestId('permanentAddressId', props)}
              />
            </Box>
            <HStack height={13} align="flex-end">
              <IconButton
                icon="image/edit"
                severity="informational"
                isDisabled={isNilOrEmpty(formApi.watch('businessInfo.businessAddressId'))}
                onClick={() =>
                  openEditAddressDialog(formApi.watch('businessInfo.businessAddressId')!)
                }
                data-testid={suffixTestId('editPermanentAddressId', props)}
              />
            </HStack>
          </HStack>
          <Align left>
            <Button
              leftIcon="content/add_circle"
              title={i18n.t('entity.customer.actions.newAddress')}
              size="small"
              variant="link"
              onClick={() => openCreateAddressDialog({onSuccess: onCreateAddress(formApi)})}
              data-testid={suffixTestId('newAddress', props)}
            />
          </Align>
          <Heading size={4}>{i18n.t('entity.businessInfo.labels.institutions.title')}</Heading>
          <FormField
            control={control}
            type="multiChoice"
            name="institutions"
            menuPlacement="top"
            options={institutionOptions}
            label={i18n.t('entity.address.labels.selectInstitutions')}
            menuInPortal
            isNotClearable
            data-testid={suffixTestId('institution', props)}
          />
          <Show whenFeatureEnabled={featureFlags.CORE_CUSTOMER_GROUPS}>
            <Heading size={4}>{i18n.t('entity.person.segmentation')}</Heading>
            <FormField
              name="customerGroups"
              label={i18n.t('entity.person.labels.customerGroups')}
              type="multiChoice"
              options={customerGroupOptions}
              menuPlacement="top"
              menuInPortal
              isNotClearable
              control={control}
            />
          </Show>
          <DialogFooter data-testid={props['data-testid']}>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose ?? closeCurrentDialog}
                isDisabled={props.isLoading}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={props.isLoading}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </VStack>
      )}
    </Form>
  );
}

const schema = object().shape({
  businessInfo: object().shape({
    businessAddressId: yupString.required(),
    businessInfoData: object().shape(
      {
        countryOfRegistrationCode: yupString.required(),
        registrationNumber: yupString.when('vatNumber', {
          is: (value: string | Nullish) => isNilOrEmpty(value),
          then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
        }),
        vatNumber: yupString.when('registrationNumber', {
          is: (value: string | Nullish) => isNilOrEmpty(value),
          then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
        }),
        tradeName: yupString.required(),
        fileNumber: yupString.optional(),
      },
      [['registrationNumber', 'vatNumber']]
    ),
  }),
});
