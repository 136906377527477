import {DataStatus} from 'platform/components';

import {useDispatch} from 'react-redux';

import {find, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  customerApi,
  handleApiError,
  PersonRequestBody,
  PersonResponseBodyV2,
  useCreateCustomerContactMutation,
  useGetBusinessCaseQuery,
  useGetCustomerV2Query,
  usePatchCustomerContactMutation,
  usePutBusinessCaseContactPersonMutation,
} from '@omnetic-dms/shared';
import {AutoSave, Form, personRequestToResponse} from '@omnetic-dms/teas';

import {Nullish, useRequiredParams} from 'shared';

import {PersonSelect} from '../Person/PersonSelect';

interface ContactPersonFormProps {
  customerId: string;
  contactPersonId?: string | Nullish;
  isDisabled?: boolean;
}

type BusinessCaseContactPersonRequestBody = {
  contactId: string | Nullish;
};

export function ContactPersonForm(props: ContactPersonFormProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const {
    data: customer,
    isLoading,
    isError,
  } = useGetCustomerV2Query({customerId: props.customerId});

  const [patchCustomerContact] = usePatchCustomerContactMutation();
  const [createCustomerContact] = useCreateCustomerContactMutation();
  const [putBusinessCaseContactPerson] = usePutBusinessCaseContactPersonMutation();

  const contactPerson = find(
    (person) => person.id === props.contactPersonId,
    customer?.contacts ?? []
  );

  const dispatch = useDispatch();

  const handleSubmit = (requestBody: BusinessCaseContactPersonRequestBody) => {
    if (typeof requestBody.contactId === 'string') {
      putBusinessCaseContactPerson({
        businessCaseId: businessCase!.id,
        putBusinessCaseContactPersonRequestBody: {
          contactId: requestBody.contactId,
        },
      })
        .unwrap()
        .then(() => {
          dispatch(customerApi.util.invalidateTags([{type: 'Customer', id: props.customerId}]));
        })
        .catch(handleApiError);
    }
  };

  const handlePersonSubmit = (
    personRequestBody: PersonRequestBody,
    contactId: string | null
  ): Promise<PersonResponseBodyV2 | null> => {
    if (isNotNil(contactId)) {
      return patchCustomerContact({
        customerId: props.customerId,
        contactId,
        personRequestBody,
      })
        .unwrap()
        .then((person) =>
          putBusinessCaseContactPerson({
            businessCaseId: businessCase!.id,
            putBusinessCaseContactPersonRequestBody: {
              contactId,
            },
          })
            .unwrap()
            .then(() => personRequestToResponse(person.id, customer?.addresses, personRequestBody))
            .catch((error) => {
              handleApiError(error);
              return null;
            })
        )
        .catch((error) => {
          handleApiError(error);
          return null;
        });
    } else {
      const args = {
        customerId: props.customerId,
        personRequestBody,
      };
      return createCustomerContact(args)
        .unwrap()
        .then((person) =>
          putBusinessCaseContactPerson({
            businessCaseId: businessCase!.id,
            putBusinessCaseContactPersonRequestBody: {
              contactId: person.id,
            },
          })
            .unwrap()
            .then(() => personRequestToResponse(person.id, customer?.addresses, personRequestBody))
            .catch((error) => {
              handleApiError(error);
              return null;
            })
        )
        .catch((error) => {
          handleApiError(error);
          return null;
        });
    }
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNilOrEmpty(customer)}>
      <Form<BusinessCaseContactPersonRequestBody>
        formId={testIds.businessCase.customer('contactForm')}
        onSubmit={handleSubmit}
        initialValues={{contactId: contactPerson?.id}}
        isFormSaverDisabled
        render={() => (
          <>
            <AutoSave save={handleSubmit} saveOnChange />
            <PersonSelect<BusinessCaseContactPersonRequestBody>
              name="contactId"
              label={i18n.t('entity.person.selectPerson')}
              handlePersonSubmit={handlePersonSubmit}
              initialValue={contactPerson}
              person={customer!.contacts}
              inlineEdit
              showPersonInfo
              showPhoneNumbers
              showEmails
              showRole
              isDisabled={props.isDisabled}
            />
          </>
        )}
      />
    </DataStatus>
  );
}
