import styled from 'styled-components';

export const TitleSectionWrapper = styled.div`
  padding: 0 1.143rem;
  margin-left: -1.143rem;
  width: auto;
  display: flex;
  align-items: center;

  &::after {
    display: block;
    height: 1.5rem;
    content: '';
    width: 0.071rem;
    background: rgb(223 225 230);
    margin-left: 1.143rem;
  }
`;
