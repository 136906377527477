import {isNil, isNotNil} from 'ramda';

import {Nullish} from 'shared';

import {useGetMakeModelWithMakeQuery} from '../api/vehicleCatalogueApi';
import {FALLBACK_LANGUAGE} from '../i18n/fallbackLanguage';
import {i18n} from '../i18n/i18n';
import {getVehicleTitle} from '../utils/getVehicleTitle';

type Vehicle = {
  make?: string | null;
  vehicleType?: string;
  modelFamily?: string | null;
  trim?: string | null;
  variant?: string | null;
  title?: string | null;
};

export function useGetVehicleTitle(
  vehicle: Vehicle | Nullish
): [string | Nullish, {isLoading: boolean; isError: boolean}] {
  const {data, isLoading, isError} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicle?.vehicleType ?? '',
      make: vehicle?.make ?? '',
      lang: [i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: isNil(vehicle?.vehicleType)}
  );

  return [isNotNil(vehicle) ? getVehicleTitle(vehicle, data) : null, {isLoading, isError}];
}
