import styled from 'styled-components';

interface DevSettingsHeaderButtonProps {
  $isOpen: boolean;
}

export const DevSettingsHeaderButton = styled.div<DevSettingsHeaderButtonProps>`
  background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  height: ${({theme}) => theme.getSize(12)};
  width: ${({theme}) => theme.getSize(12)};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
  cursor: pointer;
`;
