import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Card} from 'platform/components';
import {Box, Heading, HStack, Space, VStack} from 'platform/foundation';
import {Currency} from 'platform/locale';

import {FC, useContext, useState} from 'react';
import {
  FieldErrors,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {selectTenant} from '@omnetic-dms/shared';
import {
  useFields,
  inlineEditingContext,
  InlineEditingContextType,
  selectCurrencies,
  BillingInformation,
} from '@omnetic-dms/teas';

import {validationCheckerContext} from '../../BillingInfoDetail/components/validationCheckerContext';
import {RemoveButton} from '../../Tenant/styles';
import {BankAccountsFormType} from '../types';
import {bankAccountsValidations} from './bankAccountsValidations';

export interface BankAccountsProps {
  onSubmit?: (values: BankAccountsFormType) => void;
  onSubmitError?: (errors: FieldErrors<BankAccountsFormType>) => void;
}

export const BankAccounts: FC<BankAccountsProps> = ({onSubmit, onSubmitError}) => {
  const {data: {currency} = {}} = useSelector(selectTenant);

  const inlineEdit = useContext(inlineEditingContext) as InlineEditingContextType<
    unknown,
    {data?: BillingInformation}
  >;
  const validationComp = useContext(validationCheckerContext);
  const billingInfo = inlineEdit.editedValue?.data ?? ({} as BillingInformation);
  const methods = useForm<BankAccountsFormType>({
    resolver: yupResolver(bankAccountsValidations()),
    defaultValues: {
      bankAccounts: billingInfo?.bankAccounts ?? [
        {
          accountName: '',
          accountNumber: '',
          bankName: '',
          iban: '',
          swift: '',
          currency,
          isPrimary: false,
        },
      ],
    },
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    shouldUnregister: false,
  });

  const {control, handleSubmit} = methods;

  const {
    fields: bankAccounts,
    append: addBankAccount,
    remove: removeBankAccount,
  } = useFieldArray<BankAccountsFormType>({control, name: 'bankAccounts', keyName: 'id'});
  const getFields = useFields<BankAccountsFormType, ['bankAccounts', number]>(methods);

  const [expandedBankAccount, setExpandedBankAccount] = useState<number | null>(0);
  const currencies = useSelector(selectCurrencies);

  const handleExpansions = (index: number) => {
    setExpandedBankAccount(index === expandedBankAccount ? null : index);
  };

  const handleOnSubmit: SubmitHandler<BankAccountsFormType> = (values) => {
    const _values = {
      bankAccounts: values.bankAccounts.map(({isPrimary, ...account}) => ({
        ...account,
        isPrimary: isPrimary ?? false,
      })),
    };

    onSubmit?.(_values);

    validationComp?.setValue('bankAccounts', true);
    inlineEdit.updateMethod(_values, _values);
  };

  const handleOnSubmitError: SubmitErrorHandler<BankAccountsFormType> = (errors) => {
    onSubmitError?.(errors);

    validationComp?.setValue('bankAccounts', false);
  };

  const submitForm = () => {
    handleSubmit(handleOnSubmit, handleOnSubmitError)();
  };

  const handleDropdownChange = () => {
    submitForm();
  };

  const handleAddNewBankAccount = () => {
    addBankAccount({
      isPrimary: false,
      bankName: '',
      accountName: '',
      accountNumber: '',
      currency: currency ?? '',
      iban: '',
      swift: '',
    });
  };

  const handleRemoveBankAccount = (index: number) => {
    removeBankAccount(index);
    submitForm();
  };

  const values = methods.getValues();
  return (
    <VStack>
      <Heading size={4}>{i18n.t('general.labels.bankAccount')}</Heading>
      <Space vertical={4} />
      <VStack spacing={4}>
        {bankAccounts?.map((bankAccount, index) => (
          <div key={bankAccount.id}>
            <Card
              isExpandable
              variant="inlineGrey"
              isExpanded={expandedBankAccount === index}
              title={`${values.bankAccounts[index]?.accountNumber} •
            ${values.bankAccounts[index]?.bankName}`}
              onExpandButtonClick={() => handleExpansions(index)}
            >
              <div>
                <VStack spacing={4}>
                  {getFields.hidden({
                    name: ['bankAccounts', index, 'isPrimary'],
                    defaultValue: bankAccount.isPrimary ?? undefined,
                  })}
                  {getFields.text({
                    name: ['bankAccounts', index, 'accountName'],
                    onBlur: submitForm,
                    defaultValue: bankAccount.accountName,
                    inputProps: {
                      placeholder: '–',
                      required: true,
                      label: i18n.t('entity.bank.labels.accountName'),
                    },
                  })}
                  <HStack spacing={4}>
                    {getFields.text({
                      name: ['bankAccounts', index, 'accountNumber'],
                      onBlur: submitForm,
                      defaultValue: bankAccount.accountNumber,
                      inputProps: {
                        placeholder: '–',
                        required: true,
                        label: i18n.t('entity.bank.labels.accountNumber'),
                      },
                    })}
                    {getFields.text({
                      name: ['bankAccounts', index, 'bankName'],
                      defaultValue: bankAccount.bankName,
                      onBlur: submitForm,
                      inputProps: {
                        placeholder: '–',
                        label: i18n.t('entity.bank.labels.name'),
                      },
                    })}
                  </HStack>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      {getFields.text({
                        name: ['bankAccounts', index, 'iban'],
                        onBlur: submitForm,
                        defaultValue: bankAccount.iban,
                        inputProps: {
                          placeholder: '–',
                          label: i18n.t('entity.bank.labels.iban'),
                        },
                      })}
                    </Box>
                    <Box flex={1}>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          {getFields.text({
                            name: ['bankAccounts', index, 'swift'],
                            onBlur: submitForm,
                            defaultValue: bankAccount.swift,
                            inputProps: {
                              placeholder: '–',
                              label: i18n.t('entity.bank.labels.swift'),
                            },
                          })}
                        </Box>
                        <Box flex={1}>
                          {getFields.dropdown({
                            name: ['bankAccounts', index, 'currency'],
                            onChange: handleDropdownChange,
                            getOptionLabel: (option: Currency) => option.name,
                            getOptionValue: (option?: Currency | null) => option?.code ?? '',
                            options: currencies ?? [],
                            defaultValue: bankAccount.currency as string,
                            dropdownProps: {
                              placeholder: '–',
                              label: i18n.t('entity.bank.labels.currency'),
                            },
                          })}
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  {bankAccounts.length > 1 && (
                    <Box>
                      <RemoveButton
                        data-testid={testIds.settings.billingInformation(
                          `bankAccount-${index}-delete`
                        )}
                        type="button"
                        onClick={() => handleRemoveBankAccount(index)}
                      >
                        {i18n.t('entity.bank.actions.removeBankAccount')}
                      </RemoveButton>
                    </Box>
                  )}
                </VStack>
              </div>
            </Card>
          </div>
        ))}
      </VStack>
      <Box>
        <Space vertical={2} />
        <Button
          variant="link"
          data-testid={testIds.settings.billingInformation('createNew')}
          leftIcon="content/add_circle"
          onClick={handleAddNewBankAccount}
          title={i18n.t('entity.bank.actions.newBankAccount')}
        />
      </Box>
    </VStack>
  );
};
