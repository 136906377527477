import {ColDefWithIndex, GroupDefWithIndex} from './getGroupedColDefs';
import {isColDef} from './isColDef';

export const getColDefsVisibleInToolPanel = (colDefs: (ColDefWithIndex | GroupDefWithIndex)[]) => {
  return colDefs
    .filter((columnConfig) =>
      isColDef(columnConfig) ? columnConfig.filter && !columnConfig.suppressFiltersToolPanel : true
    )
    .map((columnConfig) =>
      isColDef(columnConfig)
        ? columnConfig
        : {
            ...columnConfig,
            children: columnConfig.children.filter((child) =>
              isColDef(child) ? child.filter && !child.suppressFiltersToolPanel : true
            ),
          }
    );
};
