import {
  Action,
  Card,
  CardProps,
  closeCurrentDialog,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {ActionCallback, DataGrid, useRefreshDataGrid} from 'platform/datagrid';
import {Box, Hide, Show} from 'platform/foundation';

import {useDispatch} from 'react-redux';

import {assoc, path} from 'ramda';
import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {omneticApi} from '../../api/baseApi/omneticApi';
import {useDeleteCostMutation} from '../../api/saleVehicleApi';
import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {useSaleVehicleActions} from '../../hooks/useSaleVehicleActions';
import i18n from '../../i18n/i18n';
import {handleApiError} from '../../utils/handleApiError';
import {ApplyVehiclePresetForm} from '../ApplyVehiclePresetForm/ApplyVehiclePresetForm';
import {CreateVehiclePresetForm} from '../CreateVehiclePresetForm/CreateVehiclePresetForm';
import {VehicleEarningCostForm} from '../VehicleEarningCostForm/VehicleEarningCostForm';
import {EditCostForm} from './components/EditCostForm';

interface VehicleCostsGridCardProps extends TestIdProps {
  vehicleId: string;
  variant?: CardProps['variant'];
  refreshBusinessCase?: () => void;
  isSimplified?: boolean;
}

export function VehicleCostsGridCard(props: VehicleCostsGridCardProps) {
  const [deleteCost] = useDeleteCostMutation();
  const {isSaleActionEnabled} = useSaleVehicleActions(props.vehicleId);
  const dispatch = useDispatch();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const invalidateBC = () =>
    dispatch(omneticApi.util.invalidateTags(['BusinessCaseDetail', 'BusinessCasePurchasePrices']));

  const [canCreateCost, canEditCost] = usePermissions({
    permissionKeys: ['vehicleCreateCost', 'vehicleEditCost'],
  });

  /**
   * Memo is used because of a bug in DataGrid. See https://carvago.atlassian.net/browse/T20-18662
   */
  const queryModifier = assoc('vehicle', props.vehicleId);

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const costId = isArray(rowId) ? rowId[0] : rowId;
    switch (actionKey) {
      case 'delete':
        openDeleteDialog({
          name: path(['name', 'value'], rowData),
          'data-testid': suffixTestId('deleteDialog', props),
          onConfirm: () =>
            deleteCost({vehicleId: props.vehicleId, costId})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError)
              .then(props.refreshBusinessCase)
              .then(() => invalidateBC())
              .catch((e) => handleApiError(e.response)),
        });
        break;

      case 'edit':
        if (canEditCost) {
          openDialog(
            <EditCostForm
              data-testid={suffixTestId('editDialog', props)}
              costId={costId}
              vehicleId={props.vehicleId}
              onCancel={closeCurrentDialog}
              onSuccess={() => {
                refreshData();
                closeCurrentDialog();
                invalidateBC();
                props.refreshBusinessCase?.();
              }}
              isSimplified={props.isSimplified}
              isCostsEditable={isSaleActionEnabled('SALE_VEHICLE_EDIT_EXPECTED_COST')}
            />,
            {title: i18n.t('entity.vehicle.costs.editCost')}
          );
        }
        break;
    }
  };

  const openAddDialog = () =>
    openDialog(
      <VehicleEarningCostForm
        data-testid={suffixTestId('addDialog', props)}
        entity="cost"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={() => {
          closeCurrentDialog();
          invalidateBC();
          props.refreshBusinessCase?.();
          if (isNotNilOrEmpty(dataGridRef.current)) {
            refreshDataGrid();
          }
        }}
        isSimplified={props.isSimplified}
        isCostsEditable={isSaleActionEnabled('SALE_VEHICLE_EDIT_EXPECTED_COST')}
      />,
      {
        title: i18n.t('entity.vehicle.costs.addCost'),
        size: props.isSimplified ? 'small' : undefined,
        'data-testid': suffixTestId('addDialog', props),
      }
    );

  const openApplyPresetDialog = () =>
    openDialog(
      <ApplyVehiclePresetForm
        entity="cost"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={() => {
          closeCurrentDialog();
          invalidateBC();
          props.refreshBusinessCase?.();
          if (isNotNilOrEmpty(dataGridRef.current)) {
            refreshDataGrid();
          }
        }}
        data-testid={suffixTestId('applyPresetDialog', props)}
      />,
      {title: i18n.t('entity.earningsCosts.actions.addPreset'), size: 'small'}
    );

  const openCreatePresetDialog = () =>
    openDialog(
      <CreateVehiclePresetForm
        entity="cost"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={closeCurrentDialog}
        data-testid={suffixTestId('createPresetDialog', props)}
      />,
      {title: i18n.t('entity.earningsCosts.actions.savePreset'), size: 'small'}
    );

  const actions = buildArray<Action>()
    .when(canCreateCost, {
      type: 'button',
      size: 'small',
      variant: 'link',
      onClick: openCreatePresetDialog,
      leftIcon: 'content/save',
      title: i18n.t('entity.earningsCosts.actions.savePreset'),
      'data-testid': suffixTestId('createPreset', props),
    })
    .when(canCreateCost, {
      type: 'button',
      size: 'small',
      variant: 'link',
      onClick: openApplyPresetDialog,
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.earningsCosts.actions.addFromPreset'),
      'data-testid': suffixTestId('addPresetButton', props),
    })
    .when(canCreateCost, {
      type: 'button',
      size: 'small',
      variant: 'link',
      onClick: openAddDialog,
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.earningsCosts.actions.addItem'),
      'data-testid': suffixTestId('addButton', props),
    });

  return (
    <>
      <Card
        variant={props.variant}
        title={i18n.t('entity.earningsCosts.labels.costs')}
        actions={actions}
      >
        <Show when={props.isSimplified}>
          <Box height={65} key="business_case_costs">
            <DataGrid
              gridCode="business_case_costs"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              emptyState={{
                action: canCreateCost
                  ? {
                      title: i18n.t('entity.earningsCosts.actions.addCost'),
                      leftIcon: 'content/add_circle',
                      onClick: openAddDialog,
                    }
                  : undefined,
              }}
              data-testid={suffixTestId('grid', props)}
            />
          </Box>
        </Show>
        <Hide when={props.isSimplified}>
          <Box height={65} key="vehicle_costs">
            <DataGrid
              gridCode="vehicle_costs"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              emptyState={{
                action: canCreateCost
                  ? {
                      title: i18n.t('entity.earningsCosts.actions.addCost'),
                      leftIcon: 'content/add_circle',
                      onClick: openAddDialog,
                    }
                  : undefined,
              }}
              ref={dataGridRef}
              data-testid={suffixTestId('grid', props)}
            />
          </Box>
        </Hide>
      </Card>
    </>
  );
}
