import {BaseVoidResponseSchema} from '../types/api/base';
import {
  GetArticleStockIdResponse,
  GetArticleStockIdRequest,
  CalculateArticleRequest,
  CalculateArticleResponse,
  CreateArticleRequest,
  CreateArticleResponse,
  GetArticleAvailabilityRequest,
  GetArticleAvailabilityResponse,
  GetArticleRequest,
  GetArticleResponse,
  GetArticleStockAvailabilityOverviewRequest,
  GetArticleStockAvailabilityOverviewResponse,
  GetArticleStockItemOverviewRequest,
  GetArticleStockItemOverviewResponse,
  GetArticleStockItemRequest,
  GetArticleStockItemResponse,
  GetArticleStockItemsRequest,
  GetArticleStockItemsResponse,
  UpdateArticleRequest,
  UpdateArticleResponse,
  PostArticleAvailabilityResponse,
  PostArticleAvailabilityRequest,
  PatchArticleAvailabilityResponse,
  PatchArticleAvailabilityRequest,
  StockAvailabilityResponse,
  StockAvailabilityRequest,
  DeleteArticleResponse,
  DeleteArticleRequest,
  PatchArticleDefaultSupplierResponse,
  PatchArticleDefaultSupplierRequest,
  PatchArticleInfoResponse,
  PatchArticleInfoRequest,
  GetArticleAvailabilityResponseSchema,
  GetArticleAvailabilityRequestSchema,
  PostArticleAvailabilityRequestSchema,
  PostArticleAvailabilityResponseSchema,
  PatchArticleAvailabilityRequestSchema,
  PatchArticleInfoRequestSchema,
  PatchArticleDefaultSupplierRequestSchema,
  GetArticleStockAvailabilityOverviewRequestSchema,
  GetArticleStockAvailabilityOverviewResponseSchema,
  GetArticleStockItemOverviewRequestSchema,
  GetArticleStockItemOverviewResponseSchema,
  GetArticleStockItemRequestSchema,
  GetArticleStockItemResponseSchema,
  GetArticleStockItemsRequestSchema,
  GetArticleStockItemsResponseSchema,
  StockAvailabilityRequestSchema,
  StockAvailabilityResponseSchema,
  DeleteArticleRequestSchema,
  CalculateArticleRequestSchema,
  CalculateArticleResponseSchema,
  UpdateArticleRequestSchema,
  UpdateArticleResponseSchema,
  CreateArticleRequestSchema,
  CreateArticleResponseSchema,
  GetArticleStockIdRequestSchema,
  GetArticleStockIdResponseSchema,
  GetArticleRequestSchema,
  GetArticleResponseSchema,
  GetSupplierArticleResponse,
  GetSupplierArticleRequest,
  GetSupplierArticleRequestSchema,
  GetSupplierArticleResponseSchema,
  GetArticleIdByManufacturerNumberAndManufacturerIdRequest,
  GetArticleIdByManufacturerNumberAndManufacturerIdResponse,
  GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema,
  GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema,
} from '../types/api/metadaWarehouseArticle';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseArticleApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getArticleAvailability: build.query<
      GetArticleAvailabilityResponse,
      GetArticleAvailabilityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article_availability/${queryArg.id}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'availabilityArticle', id: queryArg.id}],
      extraOptions: {
        requestSchema: GetArticleAvailabilityRequestSchema,
        responseSchema: GetArticleAvailabilityResponseSchema,
      },
    }),
    postArticleAvailability: build.mutation<
      PostArticleAvailabilityResponse,
      PostArticleAvailabilityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article_availability`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'stockSvailabilityId', id: queryArg.body?.article_id_fk ?? ''},
      ],
      extraOptions: {
        requestSchema: PostArticleAvailabilityRequestSchema,
        responseSchema: PostArticleAvailabilityResponseSchema,
      },
    }),
    patchArticleAvailability: build.mutation<
      PatchArticleAvailabilityResponse,
      PatchArticleAvailabilityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article_availability/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'availabilityArticle', id: queryArg.id},
      ],
      extraOptions: {
        requestSchema: PatchArticleAvailabilityRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
    getArticleStockId: build.query<GetArticleStockIdResponse, GetArticleStockIdRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.id}/stock_availability_id`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'stockSvailabilityId', id: queryArg.id}],
      extraOptions: {
        requestSchema: GetArticleStockIdRequestSchema,
        responseSchema: GetArticleStockIdResponseSchema,
      },
    }),
    getArticle: build.query<GetArticleResponse, GetArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'article', id: queryArg.articleId}],
      extraOptions: {
        requestSchema: GetArticleRequestSchema,
        responseSchema: GetArticleResponseSchema,
      },
    }),
    postArticle: build.mutation<CreateArticleResponse, CreateArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['article'],
      extraOptions: {
        requestSchema: CreateArticleRequestSchema,
        responseSchema: CreateArticleResponseSchema,
      },
    }),
    patchArticle: build.mutation<UpdateArticleResponse, UpdateArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'article', id: queryArg.articleId}],
      extraOptions: {
        requestSchema: UpdateArticleRequestSchema,
        responseSchema: UpdateArticleResponseSchema,
      },
    }),
    putArticleCalculation: build.mutation<CalculateArticleResponse, CalculateArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/calculation`,
        method: 'PUT',
        body: queryArg.body,
        signal: queryArg.signal,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'article', id: queryArg.articleId}],
      extraOptions: {
        requestSchema: CalculateArticleRequestSchema,
        responseSchema: CalculateArticleResponseSchema,
      },
    }),
    deleteArticle: build.mutation<DeleteArticleResponse, DeleteArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/article/bulk-delete`,
        method: 'PATCH',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['article'],
      extraOptions: {
        requestSchema: DeleteArticleRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
    getArticleIdByManufacturerNumberAndManufacturerId: build.query<
      GetArticleIdByManufacturerNumberAndManufacturerIdResponse,
      GetArticleIdByManufacturerNumberAndManufacturerIdRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/manufacturer-id/${queryArg.manufacturerId}/manufacturer-number`,
        params: {manufacturerNumber: queryArg.manufacturerNumber},
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema,
        responseSchema: GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema,
      },
    }),
    getArticleStockAvailability: build.query<StockAvailabilityResponse, StockAvailabilityRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/article/${queryArg.articleId}/overview`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: StockAvailabilityRequestSchema,
        responseSchema: StockAvailabilityResponseSchema,
      },
    }),
    getArticleStockItems: build.query<GetArticleStockItemsResponse, GetArticleStockItemsRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/stock-item`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleStockItemsRequestSchema,
        responseSchema: GetArticleStockItemsResponseSchema,
      },
    }),
    getArticleStockItem: build.query<GetArticleStockItemResponse, GetArticleStockItemRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/stock-item/${queryArg.stockItemId}`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleStockItemRequestSchema,
        responseSchema: GetArticleStockItemResponseSchema,
      },
    }),
    getArticleStockItemOverview: build.query<
      GetArticleStockItemOverviewResponse,
      GetArticleStockItemOverviewRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/stock-item/overview`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleStockItemOverviewRequestSchema,
        responseSchema: GetArticleStockItemOverviewResponseSchema,
      },
    }),
    getArticleStockAvailabilityOverview: build.query<
      GetArticleStockAvailabilityOverviewResponse,
      GetArticleStockAvailabilityOverviewRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/stock-availability/overview`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleStockAvailabilityOverviewRequestSchema,
        responseSchema: GetArticleStockAvailabilityOverviewResponseSchema,
      },
    }),
    patchArticleDefaultSupplier: build.mutation<
      PatchArticleDefaultSupplierResponse,
      PatchArticleDefaultSupplierRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/article/${queryArg.articleId}/set-default-supplier`,
        body: queryArg.body,
        method: 'PATCH',
        queryArg,
      }),
      extraOptions: {
        requestSchema: PatchArticleDefaultSupplierRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
    patchArticleInfo: build.mutation<PatchArticleInfoResponse, PatchArticleInfoRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/article/${queryArg.articleId}/update-article-info`,
        body: queryArg.body,
        method: 'PATCH',
        queryArg,
      }),
      extraOptions: {
        requestSchema: PatchArticleInfoRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
    getSupplierArticle: build.query<GetSupplierArticleResponse, GetSupplierArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse-delivery-quantity-mapping/${queryArg.deliveryQuantityMappingId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['supplierArticles'],
      extraOptions: {
        requestSchema: GetSupplierArticleRequestSchema,
        responseSchema: GetSupplierArticleResponseSchema,
      },
    }),
  }),
});

export const {
  useGetArticleAvailabilityQuery,
  useGetArticleQuery,
  useGetArticleStockItemOverviewQuery,
  useGetArticleStockAvailabilityQuery,
  useGetArticleStockAvailabilityOverviewQuery,
  useLazyGetArticleQuery,
  usePostArticleMutation,
  usePatchArticleMutation,
  useDeleteArticleMutation,
  usePostArticleAvailabilityMutation,
  usePatchArticleAvailabilityMutation,
  useGetArticleStockIdQuery,
  useGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  usePutArticleCalculationMutation,
  usePatchArticleDefaultSupplierMutation,
  usePatchArticleInfoMutation,
  useGetSupplierArticleQuery,
} = metadaWarehouseArticleApi;
