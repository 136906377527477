import {Card, openDialog, showNotification} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject} from 'platform/datagrid';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, last} from 'ramda';
import {isArray, isNilOrEmpty, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {interestRoutes, testIds} from '@omnetic-dms/routes';
import {
  ConvertForAllVehiclesApiArg,
  handleApiError,
  InterestEndCaseDialog,
  interestSearchParams,
  queryParams,
  UpdateInterestApiArg,
  useConvertForAllVehiclesMutation,
  useLazyGetAuthorizationProfilesAndCKKPermissions,
  useGetInterestQuery,
  useGetMinipurchaseQuery,
  useGetVehicleQuery,
  useUpdateInterestMutation,
} from '@omnetic-dms/shared';

import {composePath, useNavigate, useRequiredParams} from 'shared';

/**
 * @description action definition for interest dataGrid
 */
type InterestData = {
  customer: {
    value: {
      id: string;
      label: string;
    };
  };
  id: string;
};

export function VehicleInterestsCard() {
  const {id} = useRequiredParams();
  const navigate = useNavigate();

  const [updateInterest] = useUpdateInterestMutation();
  const [convertForAllVehicles] = useConvertForAllVehiclesMutation();

  const {data: interest} = useGetInterestQuery({id});
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();
  const miniPurchaseId = interest?.minipurchasesIds ? last(interest.minipurchasesIds) : null;
  const {data: miniPurchase} = useGetMinipurchaseQuery(
    {minipurchaseId: miniPurchaseId ?? ''},
    {
      skip: isNilOrEmpty(miniPurchaseId),
    }
  );
  const vehicleId = miniPurchase?.offeredVehiclesIds?.[0];
  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNilOrEmpty(vehicleId)}
  );

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      vehicle: [id],
    }),
    [id]
  );

  const actionCallback: ActionCallback = ({
    actionKey,
    rowId,
    rowData,
    sourceSearchParams,
    refreshData,
  }) => {
    const data = isArray(rowData) ? head(rowData) : rowData;

    if (isNil(rowData) || !isString(rowId)) {
      showNotification.error();
      return;
    }

    match(actionKey)
      .with('detail', () =>
        navigate(
          composePath(interestRoutes.detail, {
            params: {id: rowId},
            isSearchParamsPreserved: false,
            queryParams: `${sourceSearchParams}&${[queryParams.COMPONENT_SECTIONS_SECTION]}=${interestSearchParams.interestDetail.overview}`,
          })
        )
      )
      .with('open', () => {
        const args: UpdateInterestApiArg = {
          id: rowId,
          updateInterestRequestBody: {
            state: 'OPEN',
          },
        };
        updateInterest(args)
          .unwrap()
          .then(() => refreshData())
          .catch(handleApiError);
      })
      .with('end', () =>
        openDialog(
          <InterestEndCaseDialog
            data-testid={testIds.interest.overview('endCaseDialog')}
            interestId={rowId}
            onSubmit={refreshData}
          />,
          {
            'data-testid': testIds.interest.overview('endCaseDialog'),
            title: i18n.t('entity.interest.labels.endInterestCase'),
            scrollBehavior: 'outside',
          }
        )
      )
      .with('createBusinessCase', () => {
        const args: ConvertForAllVehiclesApiArg = {
          interestId: (data as InterestData).id ?? '',
        };
        convertForAllVehicles(args)
          .unwrap()
          .then(() => {
            refreshData();
            showNotification.success();
          })
          .catch(handleApiError);
        handleSearchAuthorizationProfiles(
          {
            vehicleMake: vehicleData?.make ?? '',
            vehicleType: vehicleData?.type ?? null,
            vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
            vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
          },
          interest?.customerId ?? undefined
        );
      })
      .otherwise(() => {
        showNotification.warning(`Action callback was not specified for action ${actionKey}`);
      });
  };

  return (
    <>
      <Card title={i18n.t('page.interests.title')}>
        <Box height={80}>
          <DataGrid
            gridCode="vehicle-interests-list"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid={testIds.vehicles.interests('vehicle-interests-card-datagrid')}
          />
        </Box>
      </Card>
    </>
  );
}
