import {Helmet} from 'react-helmet-async';

import {defaultTo} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  NavigationItem,
  useGetIssueNoteQuery,
} from '@omnetic-dms/shared';

import {composePath, generateHashFromObjects} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {Documents} from './(sections)/Documents/Documents';
import {Overview} from './(sections)/Overview/Overview';

export function IssueNoteDetail() {
  const {issueNoteId} = useWarehouseParams();

  const {
    data: issueNote,
    isLoading: isIssueNoteLoading,
    isError: hasIssueNoteError,
  } = useGetIssueNoteQuery({issueNoteId});

  const isLoading = isIssueNoteLoading;

  const isError = hasIssueNoteError;

  const issueNoteTitle = `${i18n.t('page.warehouse.labels.issueNote')} ${defaultTo(
    '',
    issueNote?.issueNoteInformation.number
  )}`;

  const header: DetailTemplateHeader = {
    title: issueNoteTitle,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'overview',
      label: i18n.t('general.labels.overview'),
      href: composePath(warehouseRoutes.issueNoteDetailOverview, {
        params: {id: issueNoteId},
      }),
      content: (
        <Overview
          issueNote={issueNote}
          isIssueNoteLoading={isIssueNoteLoading}
          data-testid={testIds.warehouse.issueNoteDetailOverview('section.overview')}
        />
      ),
      'data-testid': testIds.warehouse.issueNoteDetailOverview('navigation.overview'),
    },
    {
      id: 'documents',
      label: i18n.t('general.labels.documents'),
      href: composePath(warehouseRoutes.issueNoteDetailDocuments, {
        params: {id: issueNoteId},
      }),
      content: (
        <Documents
          isIssueNoteLoading={isIssueNoteLoading}
          data-testid={testIds.warehouse.issueNoteDetailDocuments('section.documents')}
        />
      ),
      'data-testid': testIds.warehouse.issueNoteDetailDocuments('navigation.documents'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.issueNoteDetail')} />
      <DetailTemplate
        key={generateHashFromObjects(issueNote)}
        isLoading={isLoading}
        isError={isError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.issueNoteDetailOverview('page')}
      />
    </>
  );
}
