import {Card} from 'platform/components';
import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Box, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {useRequiredParams} from 'shared';

import {OverviewCard} from './OverviewCard';

export function VehiclePrices() {
  const {id: vehicleId} = useRequiredParams();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      vehicleId,
    }),
    [vehicleId]
  );

  return (
    <VStack spacing={4}>
      <OverviewCard
        onEdit={refreshDataGrid}
        vehicleId={vehicleId}
        data-testid={testIds.vehicles.finances('overview')}
      />
      <Card title={i18n.t('entity.vehicle.labels.priceHistory')}>
        <Box height={80}>
          <DataGrid
            ref={dataGridRef}
            gridCode="sale_vehicle_price_snapshot_dg"
            queryModifier={queryModifier}
            data-testid={testIds.vehicles.finances('priceHistory')}
          />
        </Box>
      </Card>
    </VStack>
  );
}
