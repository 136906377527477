import type {BulkDeleteUserToBuyRequestBody} from '../types/BulkDeleteUserToBuyRequestBody';
import type {BuyVehiclesRequestBody} from '../types/BuyVehiclesRequestBody';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {ExportUserToBuyToCsvRequestBody} from '../types/ExportUserToBuyToCsvRequestBody';
import {request as __request} from '../utils/request';

export class UserToBuyService {
  /**
   * @returns any
   * @throws ApiError
   */
  public static buyVehicles({
    xBranch,
    authorization,
    requestBody,
  }: {
    xBranch: string;
    authorization?: string;
    requestBody?: BuyVehiclesRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'POST',
      url: '/dms/v1/sourcing-vehicle/user-to-buy/buy-vehicles',
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static bulkDeleteUserToBuy({
    xBranch,
    authorization,
    requestBody,
  }: {
    xBranch: string;
    authorization?: string;
    requestBody?: BulkDeleteUserToBuyRequestBody;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/sourcing-vehicle/user-to-buy/_bulk',
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static exportUserToBuyToCsv({
    xBranch,
    buyerCountry,
    currency,
    margin,
    orderBy,
    authorization,
    requestBody,
  }: {
    xBranch: string;
    buyerCountry?: string;
    currency?: string;
    margin?: string;
    orderBy?: string;
    authorization?: string;
    requestBody?: ExportUserToBuyToCsvRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'POST',
      url: '/dms/v1/sourcing-vehicle/user-to-buy/export/csv',
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      query: {
        buyerCountry,
        currency,
        margin,
        orderBy,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteUserToBuy({
    externalId,
    xBranch,
    authorization,
  }: {
    externalId: string;
    xBranch: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/sourcing-vehicle/user-to-buy/{externalId}',
      path: {
        externalId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
    });
  }
}
