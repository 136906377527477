import {
  Alert,
  DataStatus,
  Hyperlink,
  openConfirmDialog,
  showNotification,
} from 'platform/components';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  EntityResourceIds,
  handleApiError,
  SaleVehicleResponseBody,
  useGetCashRegisterDocumentQuery,
  usePermissions,
  useRefundVehicleReservationDepositMutation,
  useGetParticipationQuery,
} from '@omnetic-dms/shared';

import {buildArray, getApiDateTimeString} from 'shared';

interface CheckoutOrderAdvanceDepositWarningProps {
  saleVehicle?: SaleVehicleResponseBody;
}

export function CheckoutOrderAdvanceDepositWarning(props: CheckoutOrderAdvanceDepositWarningProps) {
  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: props.saleVehicle?.vehicleId ?? '',
    },
    {skip: isNil(props.saleVehicle?.vehicleId)}
  );

  const [
    hasCashRegisterDocumentReadPermission,
    hasVehicleCancelReservationDepositPermissions,
    hasVehicleRefundReservationDepositPermissions,
  ] = usePermissions({
    permissionKeys: [
      'readCashRegisterDocument',
      'vehicleCancelReservationDeposit',
      'vehicleRefundReservationDeposit',
    ],
    scopes: {
      vehicleCancelReservationDeposit: vehicleParticipation,
      vehicleRefundReservationDeposit: vehicleParticipation,
    },
  });

  const [refundAdvanceDeposit] = useRefundVehicleReservationDepositMutation();
  const {
    data: cashRegisterDocument,
    isLoading: isCashRegisterDocumentLoading,
    isError: isCashRegisterDocumentError,
  } = useGetCashRegisterDocumentQuery(
    {
      cashRegisterDocumentId:
        props.saleVehicle?.activeReservation?.paidAdvanceDeposit?.incomeCashReceiptId ?? '',
    },
    {
      skip:
        !hasCashRegisterDocumentReadPermission ||
        !props.saleVehicle?.activeReservation?.paidAdvanceDeposit?.incomeCashReceiptId,
    }
  );

  const handleRefundAdvanceDeposit = () =>
    openConfirmDialog({
      text: i18n.t('entity.vehicle.labels.refundDeposit', {
        documentNumber: cashRegisterDocument?.number,
      }),
      onConfirm: () =>
        refundAdvanceDeposit({
          vehicleId: props.saleVehicle?.vehicleId ?? '',
          'X-Branch': props.saleVehicle?.branchId ?? '',
          body: {
            issuedAt: getApiDateTimeString(new Date()),
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success(i18n.t('entity.vehicle.labels.depositRefunded'));
          })
          .catch(handleApiError),
    });

  return (
    <DataStatus isLoading={isCashRegisterDocumentLoading} isError={isCashRegisterDocumentError}>
      {props.saleVehicle?.activeReservation?.paidAdvanceDeposit &&
        cashRegisterDocument?.state === 'taken' && (
          <Alert
            data-testid="alert-advanceDepositHasBeenCollected"
            message={i18n.t('entity.vehicle.labels.advanceDepositHasBeenCollected')}
            hyperlinks={buildArray<Hyperlink>().when(
              hasVehicleCancelReservationDepositPermissions &&
                hasVehicleRefundReservationDepositPermissions,
              {
                title: i18n.t('entity.vehicle.labels.refund'),
                onClick: handleRefundAdvanceDeposit,
              }
            )}
          />
        )}
    </DataStatus>
  );
}
