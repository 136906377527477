import {useGetServiceOrderTimeTrackingQuery} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

// Polling interval - 1 minute in miliseconds
const POLLING_INTERVAL = 60_000;

interface JobTrackedTimeProps {
  serviceCaseId?: string | Nullish;
  serviceOrderId?: string | Nullish;
  serviceCaseJobId?: string | Nullish;
}

export function useJobTime(props: JobTrackedTimeProps) {
  const {data} = useGetServiceOrderTimeTrackingQuery(
    {
      serviceCaseId: props.serviceCaseId ?? '',
      serviceOrderId: props.serviceOrderId ?? '',
      serviceCaseJobId: props.serviceCaseJobId ?? '',
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: !props.serviceCaseId || !props.serviceOrderId || !props.serviceCaseJobId,
    }
  );

  const jobTime = data?.serviceOrder?.serviceCaseJobs?.find(
    (job) => job?.serviceCaseJobId === props.serviceCaseJobId
  );

  return jobTime;
}
