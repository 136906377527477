import {
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';
import {object} from 'yup';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useGetAutostacjaSvConnectorConfigQuery,
  usePatchAutostacjaSvConnectorConfigMutation,
} from '@omnetic-dms/shared';

import {yupString} from 'shared';

interface AutostacjaSettingsSubscriptionKeyFormType {
  subscriptionKey: string;
}

const schema = object({
  subscriptionKey: yupString.required(),
});

export function AutostacjaSettingsSubscriptionKeyForm() {
  const {
    data: autostacjaQueryData,
    isLoading: isAutostacjaQueryLoading,
    isError: isAutostacjaQueryError,
  } = useGetAutostacjaSvConnectorConfigQuery();
  const [patchAutostacjaSvConnectorConfigMutation, {isLoading: isPatchAutostacjaMutationLoading}] =
    usePatchAutostacjaSvConnectorConfigMutation();

  const defaultValues = {
    subscriptionKey: autostacjaQueryData?.credentials?.subscriptionKey ?? '',
  };

  const onSubmit: FormSubmitHandler<AutostacjaSettingsSubscriptionKeyFormType> = async (values) => {
    await patchAutostacjaSvConnectorConfigMutation({
      body: {
        credentials: {
          subscriptionKey: values.subscriptionKey,
        },
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isAutostacjaQueryLoading} isError={isAutostacjaQueryError}>
      <Form<AutostacjaSettingsSubscriptionKeyFormType>
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        schema={schema}
      >
        {(control) => (
          <Grid columns={12}>
            <GridItem span={12}>
              <FormField
                control={control}
                name="subscriptionKey"
                label={i18n.t('page.integrations.labels.autostacjaSubscriptionKey')}
                type="text"
                data-testid={testIds.settings.autostacjaSettingsSubscriptionKey('subscriptionKey')}
                isDisabled={isPatchAutostacjaMutationLoading}
              />
            </GridItem>
            <GridItem span={12}>
              <ButtonGroup align="right">
                <FormButton
                  title={i18n.t('general.actions.save')}
                  control={control}
                  type="submit"
                  data-testid={testIds.settings.autostacjaSettingsSubscriptionKey('submit')}
                  isLoading={isPatchAutostacjaMutationLoading}
                />
              </ButtonGroup>
            </GridItem>
          </Grid>
        )}
      </Form>
    </DataStatus>
  );
}
