import {Button, ButtonGroup, Card, DataStatus, RadioItem} from 'platform/components';
import {Clickable, Heading, HStack, Icon, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty, sortByProp} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {handleApiError, usePostAvailableXentryOrdersMutation} from '@omnetic-dms/shared';

import {
  suffixTestId,
  RequiredTestIdProps,
  Nullish,
  parseDate,
  useBoolean,
  DOT_CHARACTER,
  useOnMount,
} from 'shared';

interface LinkXentryProps extends RequiredTestIdProps {
  linkedXentryId?: string | Nullish;
  serviceCaseId: string;
  onLink: (selectedOrder: string) => void | Promise<unknown>;
  onClose: () => void;
}

export function LinkXentry(props: LinkXentryProps) {
  const [postAvailableXentryOrders, {data, isLoading: isLoadingOrders}] =
    usePostAvailableXentryOrdersMutation();

  const getAvailableXentryOrders = () =>
    postAvailableXentryOrders({serviceCaseId: props.serviceCaseId}).unwrap().catch(handleApiError);

  const formatDateTime = useDateTimeFormatter();

  const [selectedOrder, setSelectedOrder] = useState<string | undefined>(
    props.linkedXentryId ?? undefined
  );
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const handleLinkJob = async () => {
    if (!selectedOrder) {
      return;
    }
    startLoading();
    await props.onLink(selectedOrder);
    stopLoading();
  };

  const sortedJobs = sortByProp(
    'createdAtDate',
    data?.xentryJob?.map((job) => ({
      ...job,
      createdAtDate: parseDate(job.createdAt) ?? new Date(),
    })) ?? []
  ).reverse();

  useOnMount(() => {
    if (isNilOrEmpty(props.serviceCaseId)) {
      return;
    }

    getAvailableXentryOrders();
  });

  return (
    <VStack spacing={4}>
      <Text color="tertiary" size="small">
        {i18n.t('entity.integration.labels.selectXentryOrderToLink')}
      </Text>
      <VStack spacing={2}>
        <DataStatus isLoading={isLoadingOrders} isEmpty={isNilOrEmpty(data?.xentryJob)}>
          {sortedJobs.map((job, index) => (
            <Clickable
              key={job.id}
              onClick={() => job.id && setSelectedOrder(job.id)}
              data-testid={suffixTestId(`order-[${index}]`, props)}
            >
              <Card variant="inlineGrey">
                <HStack justify="space-between" align="center">
                  <HStack spacing={2} align="flex-start">
                    <RadioItem
                      value={job.id === selectedOrder}
                      data-testid={suffixTestId(`order-[${index}]`, props)}
                    />
                    <VStack spacing={1}>
                      <Heading size={4} data-testid={suffixTestId(`order-[${index}]-id`, props)}>
                        {job.id}
                      </Heading>
                      <HStack spacing={1} align="center">
                        <Text color="secondary" size="small">
                          {`${i18n.t('general.labels.dateCreated')}: ${formatDateTime('dateTimeShort', parseDate(job.createdAt) ?? new Date())}`}
                        </Text>
                        <Show when={job.id === props.linkedXentryId}>
                          <Text color="tertiary" size="small">
                            {DOT_CHARACTER}
                          </Text>
                          <Icon value="content/link" size={4} color="severity.informational" />
                          <Text color="link" size="small">
                            {i18n.t('entity.integration.actions.alreadyLinked')}
                          </Text>
                        </Show>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Button
                    title={i18n.t('general.actions.viewDetail')}
                    variant="link"
                    leftIcon="action/open_in_new"
                    isDisabled={isNilOrEmpty(job.url)}
                    onClick={() => window.open(job.url ?? '', '_blank')}
                    data-testid={suffixTestId(`order-[${index}]-detail`, props)}
                  />
                </HStack>
              </Card>
            </Clickable>
          ))}
        </DataStatus>
        <ButtonGroup align="right">
          <Button
            title={i18n.t('general.actions.discard')}
            onClick={props.onClose}
            variant="secondary"
            data-testid={suffixTestId('close', props)}
          />
          <Button
            title={i18n.t('entity.integration.actions.link')}
            isDisabled={isNil(selectedOrder) || isLoading || selectedOrder === props.linkedXentryId}
            variant="primary"
            onClick={handleLinkJob}
            isLoading={isLoading}
            data-testid={suffixTestId('link', props)}
          />
        </ButtonGroup>
      </VStack>
    </VStack>
  );
}
