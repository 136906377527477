import {CountryCode} from 'libphonenumber-js';

import {useMemo} from 'react';

import {defaultTo, indexBy} from 'ramda';

import {Nullish} from 'shared';

import {useGetCountriesQuery} from '../api/cachedApi';
import {CountryResponseBody} from '../types/cached/Countries';

type Code = CountryCode | string | Nullish;

export function useCountry() {
  const {data} = useGetCountriesQuery();

  const countryByCode = indexBy((item) => item.code, data ?? []);
  const countriesOptions = useMemo(() => (data ?? []).map(countryToOption), [data]);

  return {
    countriesOptions,
    getCountry: (code: Code) => defaultTo(null, countryByCode[code ?? '']),
    getCountryName: (code: Code) => defaultTo(null, countryByCode[code ?? '']?.name),
    getPrimaryCurrency: (code: Code) => defaultTo(null, countryByCode[code ?? '']?.primaryCurrency),
  };
}

const countryToOption = (country: CountryResponseBody) => ({
  value: country.code,
  label: country.name,
});
