import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetSupplierOrderVariantsResponse} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getOptionsFromSupplierOrderVariants = (
  supplierOrderVariants: GetSupplierOrderVariantsResponse[] | Nullish
): Option[] => {
  if (isNil(supplierOrderVariants)) {
    return [];
  }
  return supplierOrderVariants.map((supplierOrderVariant) => ({
    label: supplierOrderVariant.name,
    value: supplierOrderVariant.orderVariantId,
  }));
};
