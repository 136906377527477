import {Route, Routes} from 'react-router-dom';

import {AccountingModule} from '@omnetic-dms/accounting';
import {AnalyticsModule} from '@omnetic-dms/analytics';
import {BusinessCaseModule} from '@omnetic-dms/business-case';
import {CustomerModule} from '@omnetic-dms/customer';
import {DemoModule} from '@omnetic-dms/demo';
import {EmployeeModule} from '@omnetic-dms/employee';
import {InspectionModule} from '@omnetic-dms/inspection';
import {InterestModule} from '@omnetic-dms/interest';
import {QuickPurchaseModule} from '@omnetic-dms/quick-purchase';
import {redirectRoute, restrictedAreaRoutes} from '@omnetic-dms/routes';
import {SettingsModule} from '@omnetic-dms/settings';
import {ErrorPage, RestrictedArea} from '@omnetic-dms/shared';
import {SourcingModule} from '@omnetic-dms/sourcing';
import {TiresInventoryModule} from '@omnetic-dms/tires-inventory';
import {VehiclesModule} from '@omnetic-dms/vehicle';
import {WarehouseModule} from '@omnetic-dms/warehouse';
import {WorkshopModule} from '@omnetic-dms/workshop';

import {useRegisterModules} from '../../hooks/useRegisterModules/useRegisterModules';
import {Redirect} from '../../pages/Redirect/Redirect';
import {Layout} from '../Layout/Layout';
import {CoreModule} from './components/CoreModule';

export function ModulesContainer() {
  const modulesCache = useRegisterModules([
    CoreModule,
    SourcingModule,
    QuickPurchaseModule,
    CustomerModule,
    VehiclesModule,
    BusinessCaseModule,
    InterestModule,
    DemoModule,
    WorkshopModule,
    WarehouseModule,
    AccountingModule,
    InspectionModule,
    SettingsModule,
    AnalyticsModule,
    EmployeeModule,
    TiresInventoryModule,
  ]);

  if (!modulesCache) {
    return null;
  }

  return (
    <Routes>
      <Route
        element={
          <Layout
            menuItems={modulesCache.menuItems}
            createMenuItems={modulesCache.createMenuItems}
          />
        }
      >
        {modulesCache.routes.map((props, index) => {
          const {element, path, ...routeProps} = props;
          return <Route key={`${path}-${index}`} {...routeProps} path={path} element={element} />;
        })}
        <Route path={restrictedAreaRoutes.home} element={<RestrictedArea />} />
        <Route path={redirectRoute} element={<Redirect />} />
      </Route>
      <Route key="app-routes-error" path="*" element={<ErrorPage message="Page Not Found" />} />
    </Routes>
  );
}
