import {
  TenantFieldDefinitionResponseBody,
  TenantFieldDefinitionAddParams,
  TenantFieldDefinitionRemoveParams,
  TenantFieldDefinitionUpdateParams,
  MakeFieldDefinitionResponseBody,
  FieldDefinitionListParams,
} from '../types/api/customFields';
import {omneticApi} from './baseApi/omneticApi';

export const customFieldsApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    addFieldDefinition: build.mutation<
      TenantFieldDefinitionResponseBody,
      TenantFieldDefinitionAddParams
    >({
      query: ({body}) => ({
        url: `/dms/v1/custom-fields/definition/tenant`,
        method: 'POST',
        body,
      }),
    }),
    deleteFieldDefinition: build.mutation<void, TenantFieldDefinitionRemoveParams>({
      query: ({resourceId, definitionId}) => ({
        url: `/dms/v1/custom-fields/definition/tenant/${resourceId}/${definitionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['customFieldList'],
    }),
    updateFieldDefinition: build.mutation<
      TenantFieldDefinitionResponseBody,
      TenantFieldDefinitionUpdateParams
    >({
      query: ({resourceId, definitionId, body}) => ({
        url: `/dms/v1/custom-fields/definition/tenant/${resourceId}/${definitionId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['customFieldList'],
    }),
    getFieldDefinitionList: build.query<
      MakeFieldDefinitionResponseBody[],
      FieldDefinitionListParams
    >({
      query: ({type}) => ({
        url: `/dms/v1/custom-fields/definition/all`,
        method: 'GET',
        params: {type},
      }),
      providesTags: ['customFieldList'],
    }),
  }),
});

export const {
  useAddFieldDefinitionMutation,
  useDeleteFieldDefinitionMutation,
  useUpdateFieldDefinitionMutation,
  useGetFieldDefinitionListQuery,
} = customFieldsApi;
