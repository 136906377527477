import {
  AdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiApiArg,
  AdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiApiResponse,
  GetAuthorizationProfileRequest,
  GetAuthorizationProfileResponse,
  GetAuthorizationProfilesRequest,
  GetAuthorizationProfilesResponse,
} from '../types/api/metadaAdminAuthorizationProfile';
import {metadaApi} from './baseApi/metadaApi';

const metadaAdminAuthorizationProfileApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getAuthorizationProfiles: build.query<
      GetAuthorizationProfilesResponse[],
      GetAuthorizationProfilesRequest
    >({
      query: (queryArg) => ({
        url: `admin/v1/authorization-profiles`,
        method: 'GET',
        params: {branchId: queryArg.branchId},
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'authorizationProfiles', id: queryArg['x-tenant']},
      ],
    }),
    getAuthorizationProfile: build.query<
      GetAuthorizationProfileResponse,
      GetAuthorizationProfileRequest
    >({
      query: (queryArg) => ({
        url: `admin/v1/authorization-profile/${queryArg.authorizationProfileId}`,
        method: 'GET',
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'authorizationProfiles', id: queryArg.authorizationProfileId},
      ],
    }),
    admPostAuthorizationProfileHasIntegrationAdvancedSearchApi: build.mutation<
      AdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiApiResponse,
      AdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiApiArg
    >({
      query: (queryArg) => ({
        url: `admin/v1/authorization-profile/integrations/advanced-search`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'x-branch': queryArg['x-branch'] ?? undefined,
          'x-country': queryArg['x-country'] ?? undefined,
          'x-currency': queryArg['x-currency'] ?? undefined,
          'x-tenant': queryArg['x-tenant'] ?? undefined,
          'x-workspace': queryArg['x-workspace'] ?? undefined,
        },
      }),
    }),
  }),
});
export const {
  useGetAuthorizationProfilesQuery,
  useGetAuthorizationProfileQuery,
  useLazyGetAuthorizationProfileQuery,
  useAdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiMutation,
} = metadaAdminAuthorizationProfileApi;
