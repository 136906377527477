import {useEffect} from 'react';

import {AlertVariants} from '../../Alert/Alert';
import type {NotificationProps, NotificationsContextProps} from '../Notification.types';

type ValueOf<T> = T[keyof T];

export const NOTIFICATIONS_EVENTS: Record<string, string> = {
  show: 'show-notification',
  hide: 'hide-notification',
  update: 'update-notification',
  clean: 'clean-notifications',
  cleanQueue: 'clean-notifications-queue',
} as const;

export function createEvent(type: ValueOf<typeof NOTIFICATIONS_EVENTS>, detail?: any) {
  return new CustomEvent(type, {detail});
}

const prepareShowNotification =
  (variant: AlertVariants) =>
  (message?: string | string[], config?: Omit<NotificationProps, 'message' | 'variant'>) => {
    window.dispatchEvent(
      createEvent(NOTIFICATIONS_EVENTS.show, {
        ...config,
        message,
        variant,
      })
    );
  };

export const showNotification: {[K in AlertVariants]: ReturnType<typeof prepareShowNotification>} =
  {
    success: prepareShowNotification('success'),
    info: prepareShowNotification('info'),
    warning: prepareShowNotification('warning'),
    error: prepareShowNotification('error'),
  };

export function updateNotification(notification: NotificationProps & {id: string}) {
  window.dispatchEvent(createEvent(NOTIFICATIONS_EVENTS.update, notification));
}

export function hideNotification(id: string) {
  window.dispatchEvent(createEvent(NOTIFICATIONS_EVENTS.hide, id));
}

export function cleanNotifications() {
  window.dispatchEvent(createEvent(NOTIFICATIONS_EVENTS.clean));
}

export function cleanNotificationsQueue() {
  window.dispatchEvent(createEvent(NOTIFICATIONS_EVENTS.cleanQueue));
}

export function useNotificationsEvents(ctx: NotificationsContextProps) {
  const events: Record<string, any> = {
    show: (event: any) => ctx.showNotification(event.detail),
    hide: (event: any) => ctx.hideNotification(event.detail),
    update: (event: any) => ctx.updateNotification(event.detail.id, event.detail),
    clean: ctx.clean,
    cleanQueue: ctx.cleanQueue,
  };

  useEffect(() => {
    Object.keys(events).forEach((event) => {
      window.addEventListener(NOTIFICATIONS_EVENTS[event], events[event]);
    });

    return () => {
      Object.keys(events).forEach((event) => {
        window.removeEventListener(NOTIFICATIONS_EVENTS[event], events[event]);
      });
    };
  }, []);
}
