import {match, Pattern} from 'ts-pattern';

import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {always} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  businessCaseRoutes,
  customerRoutes,
  interestsRoutes,
  notFoundRoute,
  vehiclesRoutes,
  workshopRoutes,
} from '@omnetic-dms/routes';
import {queryParams} from '@omnetic-dms/shared';

import {composePath} from 'shared';

import {getIdFromQueryString} from './utils/getIdFromQueryString';
import {getModuleFromQueryString} from './utils/getModuleFromQueryString';
import {getSubmoduleFromQueryString} from './utils/getSubmoduleFromQueryString';
import {getSubmoduleIdFromQueryString} from './utils/getSubmoduleIdFromQueryString';
import {getViewFromQueryString} from './utils/getViewFromQueryString';

export function Redirect() {
  const navigate = useNavigate();
  const urlSearchString = window.location.search;

  const module = getModuleFromQueryString(urlSearchString);
  const view = getViewFromQueryString(urlSearchString);
  const id = getIdFromQueryString(urlSearchString);
  const submodule = getSubmoduleFromQueryString(urlSearchString);
  const submoduleId = getSubmoduleIdFromQueryString(urlSearchString);

  const path = match({module, view, id, submodule, submoduleId})
    .with({module: 'vehicle', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(vehiclesRoutes.detail, {params: {id}, isSearchParamsPreserved: false})
    )
    .with({module: 'customer', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(customerRoutes.detail, {params: {id}, isSearchParamsPreserved: false})
    )
    .with({module: 'businessCase', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(businessCaseRoutes.overview, {params: {id}, isSearchParamsPreserved: false})
    )
    .with({module: 'interest', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(interestsRoutes.overview, {params: {id}, isSearchParamsPreserved: false})
    )
    .with(
      {
        module: 'serviceCase',
        view: 'detail',
        id: Pattern.string,
        submodule: 'serviceCaseOrder',
        submoduleId: Pattern.string,
      },
      ({id, submoduleId}) =>
        composePath(workshopRoutes.serviceCaseDetail, {
          params: {id},
          queryParams: {[queryParams.SERVICE_CASE_ORDER_ID]: submoduleId},
          isSearchParamsPreserved: false,
        })
    )
    .with({module: 'serviceCase', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(workshopRoutes.serviceCaseDetail, {params: {id}, isSearchParamsPreserved: false})
    )
    .otherwise(always(notFoundRoute));

  useEffect(() => {
    if (isNotNil(path)) {
      navigate(path, {replace: true});
    }
  }, [navigate, path]);

  return null;
}
