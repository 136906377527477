import {
  openConfirmDialog,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {always, head, isNil, path} from 'ramda';
import {isArray, isNilOrEmpty, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {accountingRoutes, testIds} from '@omnetic-dms/routes';
import {
  InvoicePaymentForm,
  handleApiError,
  useDeleteInvoiceDraftMutation,
  useSendCorrectiveTaxDocumentMutation,
  useSendInvoiceMutation,
} from '@omnetic-dms/shared';

import {composePath} from 'shared';

import {useCancelDocument} from '../../hooks/useCancelDocument';
import {useDownloadDocument} from '../../hooks/useDownloadDocument';
import {useRedirectToInvoice} from '../../hooks/useRedirectToInvoice';
import {InvoiceTypes} from './types';

export function InvoiceOverview() {
  const navigate = useNavigate();
  const [redirectToInvoice] = useRedirectToInvoice();
  const [downloadInvoice] = useDownloadDocument();

  const [deleteDraftInvoice] = useDeleteInvoiceDraftMutation();
  const [cancelDocument] = useCancelDocument();
  const [sendInvoice] = useSendInvoiceMutation();
  const [sendCorrectiveTaxDocument] = useSendCorrectiveTaxDocumentMutation();

  const handleDeleteDraftInvoice = async (invoiceDraftId: string) =>
    await deleteDraftInvoice({invoiceDraftId})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleSendInvoice = async (id: string, invoiceType: string | undefined) => {
    if (isNilOrEmpty(invoiceType)) {
      showNotification.error();
      return;
    }

    const cancelAction =
      invoiceType === 'CORRECTIVE_TAX_DOCUMENT'
        ? sendCorrectiveTaxDocument({correctiveTaxDocumentId: id})
        : sendInvoice({invoiceId: id});

    await cancelAction
      .unwrap()
      .then(() => showNotification.success('entity.invoice.state.sent'))
      .catch(handleApiError);
  };

  const redirectToCreateCorrectiveTaxDocument = (
    invoiceType: InvoiceTypes | undefined,
    id: string
  ) => {
    if (isNil(invoiceType)) {
      showNotification.error();
      return;
    }
    navigate(
      composePath(accountingRoutes.newCorrectiveTaxDocument, {
        params: {id},
        queryParams: {invoiceType},
      })
    );
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const data = isArray(rowData) ? head(rowData) : rowData;
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(rowData) || isNil(rowId) || !isString(id)) {
      showNotification.error();
      return;
    }

    /**
     * can use path here even though its not type safe
     * -> rowData is type Record<string, unknown>
     */
    const invoiceType = path<InvoiceTypes>(['invoiceType', 'value', 'key'], data);
    const invoiceNumber = path<string>(['number', 'value'], data);

    const paymentId = match(invoiceType)
      .with('CORRECTIVE_TAX_DOCUMENT', always({correctiveTaxDocumentId: id}))
      .with('PROFORMA', always({proformaId: id}))
      .otherwise(always({invoiceId: id}));

    match(actionKey)
      .with('detail', () => redirectToInvoice(invoiceType, id))
      .with('download', () => downloadInvoice(invoiceType, id))
      .with('create_corrective_tax_document', () =>
        openConfirmDialog({
          onConfirm: () => redirectToCreateCorrectiveTaxDocument(invoiceType, id),
          text: i18n.t('entity.invoice.notifications.generateCorrectiveTaxDocumentHelperText', {
            invoiceNumber,
          }),
        })
      )
      .with('send', () =>
        openConfirmDialog({
          onConfirm: () => handleSendInvoice(id, invoiceType).then(refreshData),
          text: i18n.t('entity.invoice.notifications.sendInvoiceHelperText'),
        })
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () => handleDeleteDraftInvoice(id).then(refreshData),
          text: i18n.t('entity.invoice.notifications.deleteDraftInvoiceHelperText'),
        })
      )
      .with('cancel', () =>
        openConfirmDialog({
          onConfirm: () => cancelDocument(invoiceType, id).then(refreshData),
          text: i18n.t('page.accounting.invoiceDetail.cancelDialogText', {
            invoiceNumber,
          }),
        })
      )
      .with('pay', () =>
        openDialog(
          <InvoicePaymentForm
            {...paymentId}
            onAfterSubmit={refreshData}
            data-testid={testIds.accounting.invoiceOverview('paymentDialog')}
          />,
          {
            title: i18n.t('entity.invoice.labels.paymentOfInvoice', {
              number: invoiceNumber,
            }),
            'data-testid': testIds.accounting.invoiceOverview('paymentDialog'),
            scrollBehavior: 'outside',
          }
        )
      )
      .otherwise(showNotification.error);
  };

  return (
    <>
      <Helmet title={i18n.t('page.accounting.invoiceList.title')} />
      <DataGrid
        gridCode="invoicing"
        actionCallback={actionCallback}
        data-testid={testIds.accounting.invoiceOverview('datagrid')}
      />
    </>
  );
}
