import {IStatusPanelParams} from 'ag-grid-community';
import i18n from 'i18next';
import {Box, HStack, Text} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';
import styled from 'styled-components';

import {useEffect, useState} from 'react';

import {
  isNotEmpty,
  all,
  equals,
  gt,
  isNil,
  isNotNil,
  max,
  mean,
  min,
  pluck,
  range,
  reject,
  sum,
} from 'ramda';
import {isNumber} from 'ramda-adjunct';

import {Nullish} from 'shared';

const DECIMAL_PLACES = 2;

export function AggregationStatusBar(props: IStatusPanelParams) {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const [selectedCells, setSelectedCells] = useState<{value: number; currency: string | Nullish}[]>(
    []
  );

  useEffect(() => {
    const handleRangeSelectionChanged = () => {
      const cellValues = props.api.getCellRanges()?.flatMap((cellRange) => {
        const startRow = min(cellRange.startRow?.rowIndex ?? 0, cellRange.endRow?.rowIndex ?? 0);
        const endRow = max(cellRange.startRow?.rowIndex ?? 0, cellRange.endRow?.rowIndex ?? 0);

        return range(startRow, endRow + 1).flatMap((rowIndex) =>
          cellRange.columns?.flatMap((column) => {
            const rowModel = props.api.getModel();
            const rowNode = rowModel.getRow(rowIndex);

            if (isNil(rowNode)) {
              return null;
            }

            const currency: string | Nullish = rowNode.data[column.getColId()]?.value?.currency;
            const value = props.api.getValue(column, rowNode);

            return isNumber(value) ? {value, currency} : null;
          })
        );
      });

      setSelectedCells(reject(isNil, cellValues ?? []));
    };

    props.api.addEventListener('rangeSelectionChanged', handleRangeSelectionChanged);

    return () => {
      props.api.removeEventListener('rangeSelectionChanged', handleRangeSelectionChanged);
    };
  }, [props.api]);

  // If all selected number cells have the same currency, display that currency in aggregation
  const aggCurrency =
    isNotEmpty(selectedCells) &&
    all(equals(selectedCells[0].currency), pluck('currency', selectedCells))
      ? selectedCells[0].currency
      : null;

  const getFormattedNumberOrMoney = (value: number) =>
    isNotNil(aggCurrency)
      ? formatCurrency(value, aggCurrency, DECIMAL_PLACES)
      : formatNumber(value, DECIMAL_PLACES);

  return (
    <ConditionalDisplay $isDisplayed={gt(selectedCells.length, 1)}>
      <Box paddingVertical={3}>
        <HStack spacing={2}>
          <Text size="small">{i18n.t('page.datagrid.labels.average')}:</Text>
          {getFormattedNumberOrMoney(mean(pluckValue(selectedCells)))}
          <Text size="small">{i18n.t('page.datagrid.labels.min')}:</Text>
          {getFormattedNumberOrMoney(Math.min(...pluckValue(selectedCells)))}
          <Text size="small">{i18n.t('page.datagrid.labels.max')}:</Text>
          {getFormattedNumberOrMoney(Math.max(...pluckValue(selectedCells)))}
          <Text size="small">{i18n.t('page.datagrid.labels.sum')}:</Text>
          {getFormattedNumberOrMoney(sum(pluckValue(selectedCells)))}
        </HStack>
      </Box>
    </ConditionalDisplay>
  );
}

const pluckValue = pluck('value');

// Show component doesn't work here so the visibility must be handled with display prop
const ConditionalDisplay = styled.div<{$isDisplayed: boolean}>`
  display: ${({$isDisplayed}) => ($isDisplayed ? 'block' : 'none')};
`;
