import {Card, Chips} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';
import {Box, Show, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  usePostBasketMaterialItemPriceListApiMutation,
  usePostBasketMaterialItemWarehouseArticleApiMutation,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

interface MaterialItemsListCardProps extends TestIdProps {
  packageId: string;
}

const WAREHOUSE_VALUE = 'WAREHOUSE_VALUE';
const PRICE_LIST_VALUE = 'PRICE_LIST_VALUE';

export function MaterialItemsListCard(props: MaterialItemsListCardProps) {
  const [postBasketMaterialItemPriceList] = usePostBasketMaterialItemPriceListApiMutation();
  const [postBasketMaterialItemWarehouseArticle] =
    usePostBasketMaterialItemWarehouseArticleApiMutation();

  const [selectedChip, setSelectedChip] = useState(WAREHOUSE_VALUE);

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    if (actionKey !== 'addToBasket') {
      return;
    }

    const warehouseArticleId = isArray(rowId) ? rowId[0] : rowId;

    const requestParams = {
      packageId: props.packageId,
      body: {warehouseArticleId},
    };

    const request =
      selectedChip === WAREHOUSE_VALUE
        ? postBasketMaterialItemWarehouseArticle(requestParams)
        : postBasketMaterialItemPriceList(requestParams);

    request.unwrap().catch(handleApiError);
  };

  return (
    <Card title={i18n.t('entity.workshopServicePackages.labels.itemsList')} isFullHeight>
      <VStack spacing={4} height="100%">
        <Chips
          value={[selectedChip]}
          onChange={(value) => setSelectedChip(value?.[0] ?? WAREHOUSE_VALUE)}
          options={[
            {
              label: i18n.t('entity.workshopServicePackages.labels.workshop'),
              value: WAREHOUSE_VALUE,
            },
            {
              label: i18n.t('entity.workshopServicePackages.labels.priceList'),
              value: PRICE_LIST_VALUE,
            },
          ]}
          data-testid={suffixTestId('dg-variant', props)}
        />
        <Box flex={1}>
          <Show when={selectedChip === WAREHOUSE_VALUE}>
            <DataGrid
              gridCode="basket-warehouse-articles"
              actionCallback={actionCallback}
              data-testid={suffixTestId('warehouse', props)}
            />
          </Show>
          <Show when={selectedChip === PRICE_LIST_VALUE}>
            <DataGrid
              gridCode="basket-supplier-price-list"
              actionCallback={actionCallback}
              data-testid={suffixTestId('price-list', props)}
            />
          </Show>
        </Box>
      </VStack>
    </Card>
  );
}
