import {ButtonProps, openDialog, showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {equals, isNil, not} from 'ramda';
import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  AfterSalesMaterialBasket,
  BaseSupplierOrderMaterialItem,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  MaterialBasketItemEditingDetails,
  useInvalidBasketItemsIds,
  useDeleteSupplierOrderItemsMutation,
  useDeleteSupplierOrderMaterialBasketItemsMutation,
  useGetSupplierOrderMaterialBasketQuery,
  usePatchSupplierOrderMaterialBasketItemQuantityMutation,
  usePostSupplierOrderBasketCheckoutMutation,
  usePostSupplierOrderMaterialBasketItemMutation,
  useBulkPostSupplierOrderItemsMutation,
  TooltipItem,
} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, RequiredTestIdProps, suffixTestId} from 'shared';

import {EditItem} from './EditItem';
import {MaterialList} from './MaterialList';

// Temporary solution, will be removed after resolving
// TODO https://carvago.atlassian.net/browse/T20-51559
type MaterialBasketItemWithTooltip = Omit<BaseSupplierOrderMaterialItem, 'tooltip'> & {
  tooltip: TooltipItem[];
};

interface MaterialModalProps extends RequiredTestIdProps {
  orderId: string;
  onDiscard: () => void;
  refreshDatagrid?: VoidFunction;
}

export function MaterialModal(props: MaterialModalProps) {
  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetSupplierOrderMaterialBasketQuery({orderId: props.orderId});

  const [checkoutBasket, {isLoading: isPostLoading}] = usePostSupplierOrderBasketCheckoutMutation();
  const [emptyBasket, {isLoading: isDiscardLoading}] =
    useDeleteSupplierOrderMaterialBasketItemsMutation();

  const [changeMaterialBasketItemQuantity] =
    usePatchSupplierOrderMaterialBasketItemQuantityMutation();
  const [addMaterialToBasket] = usePostSupplierOrderMaterialBasketItemMutation();
  const [deleteMaterialItems, {isLoading: isDeletingItems}] = useDeleteSupplierOrderItemsMutation();
  const [addMaterialFromPurchaseListToBasket] = useBulkPostSupplierOrderItemsMutation();

  const basketItems = basket?.sparePartsBasketItem ?? [];
  const basketTotalPrice = basket?.sparePartsBasketTotalPrice;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const handleAdd = async () => {
    await checkoutBasket({
      orderId: props.orderId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.materialAdded')))
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await emptyBasket({orderId: props.orderId})
      .unwrap()
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleAddMaterialToBasket = async (articleId: string) => {
    await addMaterialToBasket({orderId: props.orderId, body: {articleId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleAddMaterialFromPurchaseListToBasket = async (requestItemId: string[]) => {
    await addMaterialFromPurchaseListToBasket({
      orderId: props.orderId,
      body: {requestItemId, itemState: 'BASKET'},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await changeMaterialBasketItemQuantity({
      orderId: props.orderId,
      itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {itemId: ids};

    await deleteMaterialItems({
      orderId: props.orderId,
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = (editingDetails: MaterialBasketItemEditingDetails) => {
    if (isNil(editingDetails?.itemId)) {
      throw new Error("Missing itemId, can't open editing dialog");
    }

    openDialog(
      <EditItem
        supplierOrderId={props.orderId}
        supplierOrderItemId={editingDetails.itemId}
        data-testid={suffixTestId('form.addItem', props)}
      />,
      {
        title: i18n.t('general.labels.edit'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': suffixTestId('dialog.addItem', props),
      }
    );
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscardLoading,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isPostLoading,
      isDisabled: isNil(basket?.sparePartsBasketItem),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  // Temporary solution, will be removed after resolving
  // TODO https://carvago.atlassian.net/browse/T20-51559
  const basketItemsWithTooltip = basketItems?.map((basketItem) => ({
    ...basketItem,
    tooltip: [
      {
        label: i18n.t('entity.warehouse.labels.requestType'),
        value: basketItem.tooltip?.requestType ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.warehouse'),
        value: basketItem.tooltip?.warehouseName ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.manufacturer'),
        value: basketItem.tooltip?.manufacturerName ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.storageLocation'),
        value: basketItem.tooltip?.articleLocation ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.dispensingUnit'),
        value: basketItem.tooltip?.dispensingUnit?.toString() ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.discountRate'),
        value: basketItem.tooltip?.discountRate ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.currentMargin'),
        value: basketItem.tooltip?.currentMargin ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.purchasePrice'),
        value: basketItem.tooltip?.purchasePrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.recommendedPrice'),
        value: basketItem.tooltip?.recommendedPrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.baseSalesPrice'),
        value: basketItem.tooltip?.saleBasePrice ?? EMPTY_PLACEHOLDER,
      },
      {
        label: i18n.t('entity.warehouse.labels.additionalDescription'),
        value: basketItem.tooltip?.additionalDescription ?? EMPTY_PLACEHOLDER,
      },
    ],
  }));

  return (
    <FullScreenModal headline={i18n.t('general.actions.addMaterial')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <MaterialList
              onAddMaterialToBasket={handleAddMaterialToBasket}
              onAddMaterialFromPurchaseListToBasket={handleAddMaterialFromPurchaseListToBasket}
              data-testid={suffixTestId('itemList', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesMaterialBasket<MaterialBasketItemWithTooltip>
              basket={{
                items: basketItemsWithTooltip,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems,
              }}
              mechanic={{isMechanicHidden: true}}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEdit}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
