import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {find, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, tiresInventoryRoutes} from '@omnetic-dms/routes';
import {
  EntityHeader,
  GetVehicleCustomerApiResponse,
  handleApiError,
  Page,
  Section,
  useLazyGetVehicleCustomerQuery,
  usePostTireOrderMutation,
  usePostVehicleCustomerMutation,
  CustomerVehicleSelection,
} from '@omnetic-dms/shared';

import {composePath, getApiDateString, Nullish} from 'shared';

const OPERATOR_TYPE = 'CORVEHICLECUSTOMERTYPE_OPERATOR';

export function CreateTireOrder() {
  const navigate = useNavigate();

  const [getVehicleCustomer] = useLazyGetVehicleCustomerQuery();
  const [postVehicleCustomer] = usePostVehicleCustomerMutation();
  const [postTireOrder] = usePostTireOrderMutation();
  const [customerId, setCustomerId] = useState<string | Nullish>(null);
  const [vehicleId, setVehicleId] = useState<string | Nullish>(null);
  const [suggestedVehicles, setSuggestedVehicles] = useState<GetVehicleCustomerApiResponse | null>(
    null
  );

  const handleCreateTireOrder = async () => {
    if (!customerId) {
      throw new Error('missing customer Id');
    }

    const customers = vehicleId
      ? await getVehicleCustomer({vehicleId}).unwrap().catch(handleApiError)
      : undefined;

    const hasCustomerConnection = find(
      (vehicleCustomer) => vehicleCustomer?.customerId === customerId,
      customers ?? []
    );
    const hasVehicleOperator = find(
      (vehicleCustomer) => vehicleCustomer?.customerType === OPERATOR_TYPE,
      customers ?? []
    );

    if (not(hasCustomerConnection) && not(hasVehicleOperator) && vehicleId) {
      postVehicleCustomer({
        body: {
          customerType: OPERATOR_TYPE,
          customerId,
          vehicleId,
          eventId: vehicleId,
          eventType: 'vehicle',
          dateFrom: getApiDateString(new Date()),
        },
      })
        .unwrap()
        .catch(handleApiError);
    }

    postTireOrder({
      body: {
        vehicleId: vehicleId ?? undefined,
        customerId:
          !hasCustomerConnection || !hasVehicleOperator
            ? customerId
            : String(hasVehicleOperator.customerId),
      },
    })
      .unwrap()
      .then((response) => {
        if (response?.orderId) {
          navigate(
            composePath(tiresInventoryRoutes.tireOrderDetail, {
              params: {id: response?.orderId},
              queryParams: {source: 'tire-orders'},
            })
          );
        }
      })
      .catch(handleApiError);
  };

  return (
    <>
      <Helmet title={i18n.t('page.tiresInventory.labels.createTireOrder')} />
      <Page
        header={
          <EntityHeader
            data-testid={testIds.tiresInventory.createTireOrder('header')}
            title={i18n.t('page.tiresInventory.labels.newTireOrder')}
            icon="image/photo"
            actions={[
              {
                type: 'button',
                variant: 'secondary',
                title: i18n.t('general.actions.discard'),
                onClick: () => navigate(tiresInventoryRoutes.overview),
              },
              {
                type: 'button',
                title: i18n.t('general.actions.create'),
                onClick: handleCreateTireOrder,
                isDisabled: isNilOrEmpty(customerId),
              },
            ]}
            isTagDeletable
            isTagUpdatable
            isTagReadable
            isTagCreatable
            isTagAssignable
            isTagUnassignable
          />
        }
        data-testid={testIds.tiresInventory.createTireOrder('page')}
      >
        <Section>
          <CustomerVehicleSelection
            customerId={customerId}
            vehicleId={vehicleId}
            onCustomerChange={setCustomerId}
            onVehicleChange={setVehicleId}
            suggestedVehicles={suggestedVehicles}
            onSuggestedVehiclesChange={setSuggestedVehicles}
            data-testid={testIds.tiresInventory.createTireOrder('customerVehicleSelection')}
          />
        </Section>
      </Page>
    </>
  );
}
