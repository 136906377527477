import {useLightbox} from 'platform/lightbox';

import {sortBy} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  featureFlags,
  useGetPromoPhotoForVehicleQuery,
  useGetSaleVehiclePhotoListQuery,
  useGetVehiclePhotosQuery,
  VehiclePromoPhotoResponseBody,
} from '@omnetic-dms/shared';

import {isFeatureEnabled} from 'shared';

export const useVehicleHeaderImage = (vehicleId: string, saleVehicleId?: string) => {
  const {data: vehiclePhotos} = useGetVehiclePhotosQuery({vehicleId});
  const {data: saleVehiclePhotos} = useGetSaleVehiclePhotoListQuery(
    {
      saleVehicleId: saleVehicleId ?? '',
    },
    {
      skip: isNilOrEmpty(saleVehicleId) || !isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS),
    }
  );

  const {data: vehiclePromoPhotos} = useGetPromoPhotoForVehicleQuery({
    vehicleId,
  });

  const [lightboxControls, {onOpen}] = useLightbox('vehicleHeader');

  const defaultCoverPhoto = vehiclePhotos?.find((photo) => photo.isCoverPhoto);
  const saleCoverPhoto = saleVehiclePhotos?.photos?.find((photo) => photo.isCoverPhoto);

  const coverPhoto = saleCoverPhoto ?? defaultCoverPhoto;

  const sortedPhotos = sortBy(
    (item: VehiclePromoPhotoResponseBody) => item.position,
    vehiclePromoPhotos ?? []
  );
  const allPhotos = [...(saleVehiclePhotos?.photos ?? vehiclePhotos ?? []), ...sortedPhotos];

  const vehicleImages = allPhotos.map((image) => ({
    id: image.id,
    url: image.url.replace('resize', 'get'),
    resizeUrl: image.url,
    name: image.fileId,
  }));

  const onCoverPhotoClick = () => {
    const index = vehicleImages?.findIndex((slide) => slide.name === coverPhoto?.fileId);
    onOpen(index ?? 0);
  };

  return [lightboxControls, coverPhoto?.url, onCoverPhotoClick, vehicleImages] as const;
};
