import {Option} from 'platform/components';

import {map} from 'ramda';

import {
  GetCodelistApplicationLawApiResponse,
  GetCodelistApplicationLawApiResponseSchema,
  GetCodelistCostCenterApiResponse,
  GetCodelistCostCenterApiResponseSchema,
  GetCodelistInsuranceTypesApiResponse,
  GetCodelistInsuranceTypesApiResponseSchema,
  GetCodelistTechniciansApiResponse,
  GetCodelistTechniciansApiResponseSchema,
  GetDictionaryApiArg,
  GetDictionaryApiArgSchema,
  GetDictionaryApiResponse,
  GetDictionaryApiResponseSchema,
  GetLabourCatalogGridFilterCatalogCategoryApiArg,
  GetLabourCatalogGridFilterCatalogCategoryApiArgSchema,
  GetLabourCatalogGridFilterCatalogCategoryApiResponse,
  GetLabourCatalogGridFilterCatalogCategoryApiResponseSchema,
} from '../types/api/metadaWorkshopCodelist';
import {DictionaryOption} from '../types/common/DictionaryOption';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopCodelistApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getApplicationLaw: build.query<Option[], void>({
      query: () => ({url: `/service/v1/codelist/application-law`}),
      providesTags: ['applicationLawList'],
      transformResponse: (response: GetCodelistApplicationLawApiResponse) =>
        map(
          (item) => ({
            label: item?.name ?? '',
            value: item?.uuid ?? '',
          }),
          response?.['application-law'] ?? []
        ),
      extraOptions: {
        responseSchema: GetCodelistApplicationLawApiResponseSchema,
      },
    }),
    getCostCenter: build.query<Option[], void>({
      query: () => ({url: `/service/v1/codelist/cost-centers`}),
      providesTags: ['costCentersList'],
      transformResponse: (response: GetCodelistCostCenterApiResponse) =>
        map(
          (item) => ({
            label: item?.name ?? '',
            value: item?.uuid ?? '',
          }),
          response?.cost_centers ?? []
        ),
      extraOptions: {
        responseSchema: GetCodelistCostCenterApiResponseSchema,
      },
    }),
    getInsuranceTypes: build.query<Option[], void>({
      query: () => ({url: `/service/v1/codelist/insurance-types`}),
      providesTags: ['insuranceTypesList'],
      transformResponse: (response: GetCodelistInsuranceTypesApiResponse) =>
        map(
          (item) => ({
            label: item?.name ?? '',
            value: item?.uuid ?? '',
          }),
          response?.insurance_types ?? []
        ),
      extraOptions: {
        responseSchema: GetCodelistInsuranceTypesApiResponseSchema,
      },
    }),
    getTechnicians: build.query<Option[], void>({
      query: () => ({url: `/service/v1/codelist/technicans`}),
      providesTags: ['technicansList'],
      transformResponse: (response: GetCodelistTechniciansApiResponse) =>
        map(
          (item) => ({
            label: item?.name ?? '',
            value: item?.uuid ?? '',
          }),
          response?.technician ?? []
        ),
      extraOptions: {
        responseSchema: GetCodelistTechniciansApiResponseSchema,
      },
    }),
    getLabourCatalogGridFilterCatalogCategory: build.query<
      Option[],
      GetLabourCatalogGridFilterCatalogCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/codelist/labour-catalog`,
        params: {serviceCaseId: queryArg?.serviceCaseId},
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'catalogCategoryList', id: queryArg?.serviceCaseId},
      ],
      transformResponse: (response: GetLabourCatalogGridFilterCatalogCategoryApiResponse) =>
        map(
          (item) => ({
            label: item?.name ?? '',
            value: item?.labourCategoryId ?? '',
          }),
          response?.labourCatalog ?? []
        ),
      extraOptions: {
        responseSchema: GetLabourCatalogGridFilterCatalogCategoryApiResponseSchema,
        requestSchema: GetLabourCatalogGridFilterCatalogCategoryApiArgSchema,
      },
    }),
    getDictionary: build.query<DictionaryOption[], GetDictionaryApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/codelist`,
        params: {category: queryArg.category},
        queryArg,
      }),
      transformResponse: (response: GetDictionaryApiResponse) =>
        map(
          (item) => ({
            label: item?.name ?? '',
            value: item?.code ?? '',
            isDefault: item?.isDefault ?? false,
          }),
          response ?? []
        ),
      providesTags: (result, error, queryArg) => [{type: 'dictionary', id: queryArg.category}],
      extraOptions: {
        responseSchema: GetDictionaryApiResponseSchema,
        requestSchema: GetDictionaryApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetApplicationLawQuery,
  useGetCostCenterQuery,
  useGetInsuranceTypesQuery,
  useGetTechniciansQuery,
  useGetLabourCatalogGridFilterCatalogCategoryQuery,
  useGetDictionaryQuery,
} = metadaWorkshopCodelistApi;
