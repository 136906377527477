import {
  DeleteBasketItemApiApiResponse,
  DeleteBasketItemApiApiArg,
  GetBasketLabourItemLabourApiApiResponse,
  GetBasketLabourItemLabourApiApiArg,
  PatchBasketItemLabourApiApiResponse,
  PatchBasketItemLabourApiApiArg,
  GetBasketLabourItemMaterialApiApiResponse,
  GetBasketLabourItemMaterialApiApiArg,
  PatchBasketItemMaterialApiApiResponse,
  PatchBasketItemMaterialApiApiArg,
  PatchBasketItemQuantityApiApiResponse,
  PatchBasketItemQuantityApiApiArg,
  DeleteBasketLabourItemsApiApiResponse,
  DeleteBasketLabourItemsApiApiArg,
  PostBasketLabourItemCheckoutApiApiResponse,
  PostBasketLabourItemCheckoutApiApiArg,
  PostBasketLabourItemLabourCatalogApiApiResponse,
  PostBasketLabourItemLabourCatalogApiApiArg,
  GetBasketLabourItemsApiApiResponse,
  GetBasketLabourItemsApiApiArg,
  DeleteBasketMaterialItemsApiApiResponse,
  DeleteBasketMaterialItemsApiApiArg,
  PostBasketMaterialItemCheckoutApiApiResponse,
  PostBasketMaterialItemCheckoutApiApiArg,
  PostBasketMaterialItemPriceListApiApiResponse,
  PostBasketMaterialItemPriceListApiApiArg,
  PostBasketMaterialItemWarehouseArticleApiApiResponse,
  PostBasketMaterialItemWarehouseArticleApiApiArg,
  GetBasketMaterialItemsApiApiResponse,
  GetBasketMaterialItemsApiApiArg,
  DeleteBasketItemApiApiResponseSchema,
  DeleteBasketItemApiApiArgSchema,
  GetBasketLabourItemLabourApiApiResponseSchema,
  GetBasketLabourItemLabourApiApiArgSchema,
  PatchBasketItemLabourApiApiResponseSchema,
  PatchBasketItemLabourApiApiArgSchema,
  GetBasketLabourItemMaterialApiApiResponseSchema,
  GetBasketLabourItemMaterialApiApiArgSchema,
  PatchBasketItemMaterialApiApiResponseSchema,
  PatchBasketItemMaterialApiApiArgSchema,
  PatchBasketItemQuantityApiApiResponseSchema,
  PatchBasketItemQuantityApiApiArgSchema,
  DeleteBasketLabourItemsApiApiResponseSchema,
  DeleteBasketLabourItemsApiApiArgSchema,
  PostBasketLabourItemCheckoutApiApiResponseSchema,
  PostBasketLabourItemCheckoutApiApiArgSchema,
  PostBasketLabourItemLabourCatalogApiApiResponseSchema,
  PostBasketLabourItemLabourCatalogApiApiArgSchema,
  GetBasketLabourItemsApiApiResponseSchema,
  GetBasketLabourItemsApiApiArgSchema,
  DeleteBasketMaterialItemsApiApiResponseSchema,
  DeleteBasketMaterialItemsApiApiArgSchema,
  PostBasketMaterialItemCheckoutApiApiResponseSchema,
  PostBasketMaterialItemCheckoutApiApiArgSchema,
  PostBasketMaterialItemPriceListApiApiResponseSchema,
  PostBasketMaterialItemPriceListApiApiArgSchema,
  PostBasketMaterialItemWarehouseArticleApiApiResponseSchema,
  PostBasketMaterialItemWarehouseArticleApiApiArgSchema,
  GetBasketMaterialItemsApiApiResponseSchema,
  GetBasketMaterialItemsApiApiArgSchema,
} from '../types/api/metadaWorkshopBasket';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    deleteBasketItemApi: build.mutation<DeleteBasketItemApiApiResponse, DeleteBasketItemApiApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/basket-item/${queryArg.basketItemId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItem', id: queryArg.basketItemId},
        {type: 'servicePackageMaterialItem', id: queryArg.basketItemId},
        'servicePackageLabourItems',
        'servicePackageMaterialItems',
      ],
      extraOptions: {
        responseSchema: DeleteBasketItemApiApiResponseSchema,
        requestSchema: DeleteBasketItemApiApiArgSchema,
      },
    }),
    getBasketLabourItemLabourApi: build.query<
      GetBasketLabourItemLabourApiApiResponse,
      GetBasketLabourItemLabourApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket-item/${queryArg.basketItemId}/labour`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItem', id: queryArg.basketItemId},
      ],
      extraOptions: {
        responseSchema: GetBasketLabourItemLabourApiApiResponseSchema,
        requestSchema: GetBasketLabourItemLabourApiApiArgSchema,
      },
    }),
    patchBasketItemLabourApi: build.mutation<
      PatchBasketItemLabourApiApiResponse,
      PatchBasketItemLabourApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket-item/${queryArg.basketItemId}/labour`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItem', id: queryArg.basketItemId},
        'servicePackageLabourItems',
      ],
      extraOptions: {
        responseSchema: PatchBasketItemLabourApiApiResponseSchema,
        requestSchema: PatchBasketItemLabourApiApiArgSchema,
      },
    }),
    getBasketLabourItemMaterialApi: build.query<
      GetBasketLabourItemMaterialApiApiResponse,
      GetBasketLabourItemMaterialApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket-item/${queryArg.basketItemId}/material`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItem', id: queryArg.basketItemId},
      ],
      extraOptions: {
        responseSchema: GetBasketLabourItemMaterialApiApiResponseSchema,
        requestSchema: GetBasketLabourItemMaterialApiApiArgSchema,
      },
    }),
    patchBasketItemMaterialApi: build.mutation<
      PatchBasketItemMaterialApiApiResponse,
      PatchBasketItemMaterialApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket-item/${queryArg.basketItemId}/material`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItem', id: queryArg.basketItemId},
        'servicePackageMaterialItems',
      ],
      extraOptions: {
        responseSchema: PatchBasketItemMaterialApiApiResponseSchema,
        requestSchema: PatchBasketItemMaterialApiApiArgSchema,
      },
    }),
    patchBasketItemQuantityApi: build.mutation<
      PatchBasketItemQuantityApiApiResponse,
      PatchBasketItemQuantityApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket-item/${queryArg.basketItemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItem', id: queryArg.basketItemId},
        {type: 'servicePackageMaterialItem', id: queryArg.basketItemId},
        'servicePackageLabourItems',
        'servicePackageMaterialItems',
      ],
      extraOptions: {
        responseSchema: PatchBasketItemQuantityApiApiResponseSchema,
        requestSchema: PatchBasketItemQuantityApiApiArgSchema,
      },
    }),
    deleteBasketLabourItemsApi: build.mutation<
      DeleteBasketLabourItemsApiApiResponse,
      DeleteBasketLabourItemsApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/labour`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: DeleteBasketLabourItemsApiApiResponseSchema,
        requestSchema: DeleteBasketLabourItemsApiApiArgSchema,
      },
    }),
    postBasketLabourItemCheckoutApi: build.mutation<
      PostBasketLabourItemCheckoutApiApiResponse,
      PostBasketLabourItemCheckoutApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/labour/checkout`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: PostBasketLabourItemCheckoutApiApiResponseSchema,
        requestSchema: PostBasketLabourItemCheckoutApiApiArgSchema,
      },
    }),
    postBasketLabourItemLabourCatalogApi: build.mutation<
      PostBasketLabourItemLabourCatalogApiApiResponse,
      PostBasketLabourItemLabourCatalogApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/labour/item/labour-catalog`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: PostBasketLabourItemLabourCatalogApiApiResponseSchema,
        requestSchema: PostBasketLabourItemLabourCatalogApiApiArgSchema,
      },
    }),
    getBasketLabourItemsApi: build.query<
      GetBasketLabourItemsApiApiResponse,
      GetBasketLabourItemsApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/labour/items`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: GetBasketLabourItemsApiApiResponseSchema,
        requestSchema: GetBasketLabourItemsApiApiArgSchema,
      },
    }),
    deleteBasketMaterialItemsApi: build.mutation<
      DeleteBasketMaterialItemsApiApiResponse,
      DeleteBasketMaterialItemsApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/material`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: DeleteBasketMaterialItemsApiApiResponseSchema,
        requestSchema: DeleteBasketMaterialItemsApiApiArgSchema,
      },
    }),
    postBasketMaterialItemCheckoutApi: build.mutation<
      PostBasketMaterialItemCheckoutApiApiResponse,
      PostBasketMaterialItemCheckoutApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/material/checkout`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: PostBasketMaterialItemCheckoutApiApiResponseSchema,
        requestSchema: PostBasketMaterialItemCheckoutApiApiArgSchema,
      },
    }),
    postBasketMaterialItemPriceListApi: build.mutation<
      PostBasketMaterialItemPriceListApiApiResponse,
      PostBasketMaterialItemPriceListApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/material/item/price-list`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: PostBasketMaterialItemPriceListApiApiResponseSchema,
        requestSchema: PostBasketMaterialItemPriceListApiApiArgSchema,
      },
    }),
    postBasketMaterialItemWarehouseArticleApi: build.mutation<
      PostBasketMaterialItemWarehouseArticleApiApiResponse,
      PostBasketMaterialItemWarehouseArticleApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/material/item/warehouse-article`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: PostBasketMaterialItemWarehouseArticleApiApiResponseSchema,
        requestSchema: PostBasketMaterialItemWarehouseArticleApiApiArgSchema,
      },
    }),
    getBasketMaterialItemsApi: build.query<
      GetBasketMaterialItemsApiApiResponse,
      GetBasketMaterialItemsApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/basket/service-package/${queryArg.packageId}/material/items`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItems', id: queryArg.packageId},
      ],
      extraOptions: {
        responseSchema: GetBasketMaterialItemsApiApiResponseSchema,
        requestSchema: GetBasketMaterialItemsApiApiArgSchema,
      },
    }),
  }),
});

export const {
  useDeleteBasketItemApiMutation,
  useGetBasketLabourItemLabourApiQuery,
  usePatchBasketItemLabourApiMutation,
  useGetBasketLabourItemMaterialApiQuery,
  usePatchBasketItemMaterialApiMutation,
  usePatchBasketItemQuantityApiMutation,
  useDeleteBasketLabourItemsApiMutation,
  usePostBasketLabourItemCheckoutApiMutation,
  usePostBasketLabourItemLabourCatalogApiMutation,
  useGetBasketLabourItemsApiQuery,
  useDeleteBasketMaterialItemsApiMutation,
  usePostBasketMaterialItemCheckoutApiMutation,
  usePostBasketMaterialItemPriceListApiMutation,
  usePostBasketMaterialItemWarehouseArticleApiMutation,
  useGetBasketMaterialItemsApiQuery,
} = metadaWorkshopBasketApi;
