import {
  Alert,
  Button,
  ButtonGroup,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, HStack, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useRef, useState} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {
  CreateArticleRequest,
  getActiveManufacturerOptions,
  getOptionsFromWarehouses,
  handleApiError,
  useDuplicateErrorHandler,
  useGetDefaultTreeFolderQuery,
  useGetHandlingUnitsQuery,
  useGetManufacturersQuery,
  useGetWarehousesQuery,
  useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  usePostArticleMutation,
} from '@omnetic-dms/shared';

import {composePath, suffixTestId, TestIdProps, useNavigate, useOnMount, yupString} from 'shared';

import {TreeFolderPath} from '../../components/TreeFolderPath';
import {ArticleState} from '../../types/ArticleState';
import {
  getActiveOptionsFromHandlingUnits,
  getDefaultOptionFromHandlingUnits,
} from '../../utils/getOptionsFromHandlingUnits';

const FOLDER_CONTEXT_TARGET = 'spare-parts-catalog';

type SubmitButtons = 'CREATE_AND_EDIT' | 'CREATE';

type CreateArticleForm = {
  manufacturerNumber: string;
  warehouseId: string;
  name: string;
  manufacturerId: string;
  handlingUnit: string;
  activeFolderId: string;
};

export type ArticleCreateDefaultValues = {
  manufacturerNumber?: string;
  manufacturerId?: string;
  warehouseId?: string;
  name?: string;
  activeFolderId?: string;
};

export type CreatedArticleData = {
  formValues: CreateArticleForm;
  articleId: string;
  submittedButton: SubmitButtons;
};

export type HandleCheckItemConfig = {
  manufacturerId: string;
  manufacturerNumber: string;
  warehouseId: string;
};

export interface ArticleCreateProps extends TestIdProps {
  onClose?: () => void;
  onArticleCreate?: (createdArticleData: CreatedArticleData) => void;
  defaultValues?: ArticleCreateDefaultValues;
  hasCreateAndEditButton?: boolean;
  submitButtonTitle?: string;
}

export function ArticleCreate(props: ArticleCreateProps) {
  const navigate = useNavigate();

  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [activeFolderId, setActiveFolderId] = useState(props.defaultValues?.activeFolderId);

  const [articleState, setArticleState] = useState<ArticleState | null>(null);

  const articleIdRef = useRef<string | null>(null);

  const clickedButtonRef = useRef<SubmitButtons | null>(null);

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: handlingUnits,
    isLoading: isHandlingUnitsLoading,
    isError: isHandlingUnitsError,
  } = useGetHandlingUnitsQuery();

  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const {
    data: defaultTreeFolder,
    isLoading: isDefaultTreeFolderLoading,
    isError: isDefaultTreeFolderError,
  } = useGetDefaultTreeFolderQuery(
    {contextTarget: FOLDER_CONTEXT_TARGET},
    {skip: isNotNil(activeFolderId)}
  );

  if (isNil(activeFolderId) && isNotNil(defaultTreeFolder?.treeFolder?.id)) {
    setActiveFolderId(defaultTreeFolder?.treeFolder?.id);
  }

  const [createArticle, {isLoading: isCreateArticleLoading}] = usePostArticleMutation();
  const [getArticleIdByManufacturerNumberAndManufacturerId] =
    useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery();

  const hasCreateAndEditButton = props.hasCreateAndEditButton ?? false;

  const doesExist = articleState === 'doesExist';
  const doesNotExist = articleState === 'doesNotExist';
  const isCheckingForArticle = articleState === 'isChecking';

  const isLoading =
    isWarehousesLoading ||
    isHandlingUnitsLoading ||
    isManufacturersLoading ||
    isDefaultTreeFolderLoading;

  const isError =
    isWarehousesError || isHandlingUnitsError || isManufacturersError || isDefaultTreeFolderError;

  useOnMount(() => {
    if (
      isString(props.defaultValues?.manufacturerId) &&
      isString(props.defaultValues?.manufacturerNumber) &&
      isString(props.defaultValues?.warehouseId)
    ) {
      handleCheckItem({
        manufacturerId: props.defaultValues?.manufacturerId,
        manufacturerNumber: props.defaultValues?.manufacturerNumber,
        warehouseId: props.defaultValues?.warehouseId,
      });
    }
  });

  const activeManufacturerOptions = getActiveManufacturerOptions(manufacturers);

  const handleSubmit: FormSubmitHandler<CreateArticleForm> = async (values) => {
    if (isNil(activeFolderId)) {
      throw new Error('activeFolderId is not defined');
    }

    const request: CreateArticleRequest = {
      warehouseId: values.warehouseId,
      body: {
        manufacturerNumber: values.manufacturerNumber,
        manufacturerId: values.manufacturerId,
        name: values.name,
        handlingUnit: values.handlingUnit,
        treeFolder: {leafId: activeFolderId},
      },
    };

    await createArticle(request)
      .unwrap()
      .then((article) => {
        const createdArticleData: CreatedArticleData = {
          formValues: values,
          articleId: article?.articleId,
          submittedButton: clickedButtonRef.current as SubmitButtons,
        };

        props.onArticleCreate?.(createdArticleData);
      })
      .then(() => showNotification.success(i18n.t('page.warehouse.notification.articleCreated')))
      .catch(duplicateErrorHandler);
  };

  const handleDetailRedirect = (warehouseId: string) => {
    navigate(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {
          id: articleIdRef.current,
          warehouseId,
        },
      })
    );
  };

  const handleDetailOpen = (warehouseId: string) => {
    window.open(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {
          id: articleIdRef.current,
          warehouseId,
        },
      }),
      '_blank'
    );
  };

  const handleCheckItem = async (config: HandleCheckItemConfig) => {
    setArticleState('isChecking');

    const articleIdResponse = await getArticleIdByManufacturerNumberAndManufacturerId({
      warehouseId: config.warehouseId,
      manufacturerNumber: config.manufacturerNumber,
      manufacturerId: config.manufacturerId,
    })
      .unwrap()
      .catch(handleApiError);

    if (articleIdResponse) {
      setArticleState('doesExist');
      articleIdRef.current = articleIdResponse.articleId;
    } else {
      setArticleState('doesNotExist');
      articleIdRef.current = null;
    }
  };

  const clearCheckedState = () => {
    setArticleState(null);
    articleIdRef.current = null;
  };

  const defaultValues = {
    handlingUnit: getDefaultOptionFromHandlingUnits(handlingUnits)[0]?.value,
    activeFolderId,
    manufacturerNumber: props.defaultValues?.manufacturerNumber,
    manufacturerId: props.defaultValues?.manufacturerId,
    warehouseId: props.defaultValues?.warehouseId,
    name: props.defaultValues?.name,
  };

  const submitButtonTitle = props.submitButtonTitle ?? i18n.t('general.actions.create');

  return (
    <>
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        isEmpty={!warehouses}
        minHeight={96}
        data-testid={suffixTestId('status', props)}
      >
        <Form<CreateArticleForm>
          defaultValues={defaultValues}
          schema={formSchema}
          onSubmit={handleSubmit}
        >
          {(control, formApi) => {
            const manufacturerId = formApi.watch('manufacturerId');
            const manufacturerNumber = formApi.watch('manufacturerNumber');
            const warehouseId = formApi.watch('warehouseId');

            const isCheckItemButtonDisabled =
              isNilOrEmpty(manufacturerNumber) ||
              isNilOrEmpty(warehouseId) ||
              isNilOrEmpty(manufacturerId);

            return (
              <>
                <VStack spacing={4}>
                  <Show when={duplicateError}>
                    <Alert
                      variant="error"
                      title={i18n.t('entity.warehouse.labels.warehouseArticleAlreadyExists')}
                      hyperlinks={[
                        {
                          size: 'base',
                          title: i18n.t('general.actions.view'),
                          onClick: () => handleDetailRedirect(warehouseId),
                          rightIcon: 'action/open_in_new',
                        },
                      ]}
                      data-testid={suffixTestId('alert.warehouseArticleAlreadyExists', props)}
                    />
                  </Show>
                  <Show when={doesNotExist}>
                    <Alert
                      type="inline"
                      variant="success"
                      title={i18n.t('page.warehouse.labels.articleIsNotInDatabase')}
                      data-testid={suffixTestId('alert.articleIsNotInDatabase', props)}
                    />
                  </Show>
                  <Show when={doesExist}>
                    <Alert
                      type="inline"
                      variant="error"
                      hyperlinks={[
                        {
                          size: 'small',
                          title: i18n.t('general.actions.view'),
                          onClick: () => handleDetailOpen(warehouseId),
                          leftIcon: 'action/open_in_new',
                        },
                      ]}
                      title={i18n.t('entity.warehouse.labels.warehouseArticleAlreadyExists')}
                      data-testid={suffixTestId('alert.articleIsAlreadyInDatabase', props)}
                    />
                  </Show>
                  <Grid columns={1}>
                    <FormField
                      isRequired
                      control={control}
                      name="manufacturerNumber"
                      type="text"
                      label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                      onChange={clearCheckedState}
                      data-testid={suffixTestId('manufacturerNumber', props)}
                    />
                  </Grid>
                  <Grid columns={2}>
                    <FormField
                      isNotClearable
                      isRequired
                      control={control}
                      name="manufacturerId"
                      type="choice"
                      label={i18n.t('entity.warehouse.labels.manufacturer')}
                      options={activeManufacturerOptions}
                      data-testid={suffixTestId('manufacturer', props)}
                    />
                    <FormField
                      isNotClearable
                      isRequired
                      control={control}
                      name="warehouseId"
                      type="choice"
                      label={i18n.t('entity.warehouse.labels.warehouse')}
                      onChange={clearCheckedState}
                      options={getOptionsFromWarehouses(warehouses)}
                      data-testid={suffixTestId('warehouseId', props)}
                    />
                  </Grid>
                  <HStack>
                    <Button
                      variant="secondary"
                      title={i18n.t('general.labels.checkItem')}
                      onClick={() =>
                        handleCheckItem({manufacturerId, manufacturerNumber, warehouseId})
                      }
                      isLoading={isCheckingForArticle}
                      isDisabled={isCheckItemButtonDisabled}
                      data-testid={suffixTestId('actions.checkItem', props)}
                    />
                  </HStack>
                  <Separator spacing={0} />
                  <Grid columns={2}>
                    <FormField
                      isRequired
                      control={control}
                      name="name"
                      type="text"
                      label={i18n.t('general.labels.name')}
                      data-testid={suffixTestId('name', props)}
                    />
                    <FormField
                      isRequired
                      isNotClearable
                      control={control}
                      name="handlingUnit"
                      type="choice"
                      label={i18n.t('entity.warehouse.labels.handlingUnit')}
                      options={getActiveOptionsFromHandlingUnits(handlingUnits)}
                      data-testid={suffixTestId('handlingUnit', props)}
                    />
                  </Grid>
                  <TreeFolderPath
                    isRequired
                    invalidMessage={formApi.formState.errors.activeFolderId?.message}
                    leafId={activeFolderId}
                    onChange={(value) => {
                      setActiveFolderId(value?.id);
                      formApi.setValue('activeFolderId', value?.id ?? '', {shouldValidate: true});
                    }}
                    data-testid={suffixTestId('treeFolderPath', props)}
                  />
                </VStack>
                <DialogFooter>
                  <ButtonGroup align="right">
                    <Button
                      title={i18n.t('general.actions.discard')}
                      variant="secondary"
                      onClick={props.onClose}
                      data-testid={suffixTestId('discard', props)}
                    />
                    <Show when={hasCreateAndEditButton}>
                      <FormButton
                        control={control}
                        title={i18n.t('entity.warehouse.actions.createAndEditArticle')}
                        type="submit"
                        variant="secondary"
                        onClick={() => (clickedButtonRef.current = 'CREATE_AND_EDIT')}
                        isLoading={isCreateArticleLoading}
                        data-testid={suffixTestId('createAndEditArticle', props)}
                      />
                    </Show>

                    <FormButton
                      control={control}
                      type="submit"
                      title={submitButtonTitle}
                      onClick={() => (clickedButtonRef.current = 'CREATE')}
                      isLoading={isCreateArticleLoading}
                      data-testid={suffixTestId('create', props)}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </>
            );
          }}
        </Form>
      </DataStatus>
    </>
  );
}

const formSchema = object({
  manufacturerNumber: yupString.required(),
  warehouseId: yupString.required(),
  manufacturerId: yupString.required(),
  name: yupString.required(),
  activeFolderId: yupString.required(),
});
