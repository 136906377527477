import {
  DeleteSupplierOrderItemsRequest,
  GetSupplierOrderRequest,
  GetSupplierOrderResponse,
  PatchSupplierOrderRequest,
  BulkPostSupplierOrderResponse,
  BulkPostSupplierOrderRequest,
  BulkPostSupplierOrderItemsResponse,
  BulkPostSupplierOrderItemsRequest,
  PatchSupplierOrderStateRequest,
  DeleteSupplierOrderRequest,
  PostSupplierOrderResponse,
  PostSupplierOrderRequest,
  GetSupplierOrderItemResponse,
  GetSupplierOrderItemRequest,
  PatchSupplierOrderItemRequest,
  PutSupplierOrderItemCalculationRequest,
  PutSupplierOrderItemCalculationResponse,
  GetSupplierOrderMaterialBasketResponse,
  GetSupplierOrderMaterialBasketRequest,
  DeleteSupplierOrderMaterialBasketItemsRequest,
  PatchSupplierOrderMaterialBasketItemQuantityRequest,
  PostSupplierOrderMaterialBasketItemResponse,
  PostSupplierOrderMaterialBasketItemRequest,
  PostSupplierOrderBasketCheckoutRequest,
  PostSupplierOrderBasketCheckoutResponse,
  GetSupplierOrderTypeRequest,
  GetSupplierOrderTypeResponse,
  GetSupplierOrderRequestSchema,
  GetSupplierOrderResponseSchema,
  PatchSupplierOrderRequestSchema,
  PostSupplierOrderRequestSchema,
  PostSupplierOrderResponseSchema,
  BulkPostSupplierOrderRequestSchema,
  BulkPostSupplierOrderResponseSchema,
  BulkPostSupplierOrderItemsRequestSchema,
  BulkPostSupplierOrderItemsResponseSchema,
  DeleteSupplierOrderItemsRequestSchema,
  PatchSupplierOrderStateRequestSchema,
  DeleteSupplierOrderRequestSchema,
  GetSupplierOrderItemRequestSchema,
  GetSupplierOrderItemResponseSchema,
  PatchSupplierOrderItemRequestSchema,
  PutSupplierOrderItemCalculationRequestSchema,
  PutSupplierOrderItemCalculationResponseSchema,
  GetSupplierOrderMaterialBasketRequestSchema,
  GetSupplierOrderMaterialBasketResponseSchema,
  PostSupplierOrderBasketCheckoutRequestSchema,
  PostSupplierOrderBasketCheckoutResponseSchema,
  DeleteSupplierOrderMaterialBasketItemsRequestSchema,
  PostSupplierOrderMaterialBasketItemRequestSchema,
  PostSupplierOrderMaterialBasketItemResponseSchema,
  PatchSupplierOrderMaterialBasketItemQuantityRequestSchema,
  GetSupplierOrderTypeRequestSchema,
  GetSupplierOrderTypeResponseSchema,
  PatchSupplierOrderResponse,
  PatchSupplierOrderResponseSchema,
  DeleteSupplierOrderItemsResponse,
  DeleteSupplierOrderItemsResponseSchema,
  PatchSupplierOrderStateResponse,
  PatchSupplierOrderStateResponseSchema,
  DeleteSupplierOrderResponse,
  DeleteSupplierOrderResponseSchema,
  PatchSupplierOrderItemResponse,
  PatchSupplierOrderItemResponseSchema,
  DeleteSupplierOrderMaterialBasketItemsResponse,
  DeleteSupplierOrderMaterialBasketItemsResponseSchema,
  PatchSupplierOrderMaterialBasketItemQuantityResponse,
  PatchSupplierOrderMaterialBasketItemQuantityResponseSchema,
} from '../types/api/metadaWarehouseSupplierOrder';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseSupplierOrderApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierOrder: build.query<GetSupplierOrderResponse, GetSupplierOrderRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [{type: 'supplierOrders', id: queryArg.orderId}],
      extraOptions: {
        requestSchema: GetSupplierOrderRequestSchema,
        responseSchema: GetSupplierOrderResponseSchema,
      },
    }),
    patchSupplierOrder: build.mutation<PatchSupplierOrderResponse, PatchSupplierOrderRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'supplierOrders', id: queryArg.orderId},
      ],
      extraOptions: {
        requestSchema: PatchSupplierOrderRequestSchema,
        responseSchema: PatchSupplierOrderResponseSchema,
      },
    }),
    postSupplierOrder: build.mutation<PostSupplierOrderResponse, PostSupplierOrderRequest>({
      query: (body) => ({
        url: `warehouse/v1/supplier-order/manual`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'supplierOrders'}],
      extraOptions: {
        requestSchema: PostSupplierOrderRequestSchema,
        responseSchema: PostSupplierOrderResponseSchema,
      },
    }),
    bulkPostSupplierOrder: build.mutation<
      BulkPostSupplierOrderResponse,
      BulkPostSupplierOrderRequest
    >({
      query: (body) => ({
        url: `warehouse/v1/supplier-order/bulk-create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'supplierOrders'}],
      extraOptions: {
        requestSchema: BulkPostSupplierOrderRequestSchema,
        responseSchema: BulkPostSupplierOrderResponseSchema,
      },
    }),
    bulkPostSupplierOrderItems: build.mutation<
      BulkPostSupplierOrderItemsResponse,
      BulkPostSupplierOrderItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/bulk-add-items`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) =>
        queryArg.body.itemState === 'ORDER'
          ? [{type: 'supplierOrders', id: queryArg.orderId}]
          : ['supplierOrderMaterialBasketItems'],
      extraOptions: {
        requestSchema: BulkPostSupplierOrderItemsRequestSchema,
        responseSchema: BulkPostSupplierOrderItemsResponseSchema,
      },
    }),
    deleteSupplierOrderItems: build.mutation<
      DeleteSupplierOrderItemsResponse,
      DeleteSupplierOrderItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/items/delete`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'supplierOrders', id: queryArg.orderId},
        'supplierOrderMaterialBasketItems',
      ],
      extraOptions: {
        requestSchema: DeleteSupplierOrderItemsRequestSchema,
        responseSchema: DeleteSupplierOrderItemsResponseSchema,
      },
    }),
    patchSupplierOrderState: build.mutation<
      PatchSupplierOrderStateResponse,
      PatchSupplierOrderStateRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/state`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'supplierOrders', id: queryArg.orderId},
      ],
      extraOptions: {
        requestSchema: PatchSupplierOrderStateRequestSchema,
        responseSchema: PatchSupplierOrderStateResponseSchema,
      },
    }),
    deleteSupplierOrder: build.mutation<DeleteSupplierOrderResponse, DeleteSupplierOrderRequest>({
      query: (queryArg) => ({
        url: 'warehouse/v1/supplier-order/delete',
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['supplierOrders'],
      extraOptions: {
        requestSchema: DeleteSupplierOrderRequestSchema,
        responseSchema: DeleteSupplierOrderResponseSchema,
      },
    }),
    getSupplierOrderItem: build.query<GetSupplierOrderItemResponse, GetSupplierOrderItemRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/item/${queryArg.itemId}`,
        method: 'GET',
      }),
      providesTags: ['supplierOrderItem'],
      extraOptions: {
        requestSchema: GetSupplierOrderItemRequestSchema,
        responseSchema: GetSupplierOrderItemResponseSchema,
      },
    }),
    patchSupplierOrderItem: build.mutation<
      PatchSupplierOrderItemResponse,
      PatchSupplierOrderItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/item/${queryArg.itemId}/manual`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['supplierOrders', 'supplierOrderItem', 'supplierOrderMaterialBasketItems'],
      extraOptions: {
        requestSchema: PatchSupplierOrderItemRequestSchema,
        responseSchema: PatchSupplierOrderItemResponseSchema,
      },
    }),
    putSupplierOrderItemCalculation: build.mutation<
      PutSupplierOrderItemCalculationResponse,
      PutSupplierOrderItemCalculationRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/item/${queryArg.orderItemId}/recalculate`,
        method: 'PUT',
        body: queryArg.body,
        signal: queryArg.signal,
      }),
      extraOptions: {
        requestSchema: PutSupplierOrderItemCalculationRequestSchema,
        responseSchema: PutSupplierOrderItemCalculationResponseSchema,
      },
    }),
    getSupplierOrderMaterialBasket: build.query<
      GetSupplierOrderMaterialBasketResponse,
      GetSupplierOrderMaterialBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/spare-parts-cart`,
        method: 'GET',
      }),
      providesTags: ['supplierOrderMaterialBasketItems'],
      extraOptions: {
        requestSchema: GetSupplierOrderMaterialBasketRequestSchema,
        responseSchema: GetSupplierOrderMaterialBasketResponseSchema,
      },
    }),
    postSupplierOrderBasketCheckout: build.mutation<
      PostSupplierOrderBasketCheckoutResponse,
      PostSupplierOrderBasketCheckoutRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/spare-parts-cart/checkout`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'supplierOrders', id: queryArg.orderId},
        'supplierOrderMaterialBasketItems',
      ],
      extraOptions: {
        requestSchema: PostSupplierOrderBasketCheckoutRequestSchema,
        responseSchema: PostSupplierOrderBasketCheckoutResponseSchema,
      },
    }),
    deleteSupplierOrderMaterialBasketItems: build.mutation<
      DeleteSupplierOrderMaterialBasketItemsResponse,
      DeleteSupplierOrderMaterialBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/spare-parts-cart`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'supplierOrderMaterialBasketItems',
        {type: 'supplierOrders', id: queryArg.orderId},
      ],
      extraOptions: {
        requestSchema: DeleteSupplierOrderMaterialBasketItemsRequestSchema,
        responseSchema: DeleteSupplierOrderMaterialBasketItemsResponseSchema,
      },
    }),
    postSupplierOrderMaterialBasketItem: build.mutation<
      PostSupplierOrderMaterialBasketItemResponse,
      PostSupplierOrderMaterialBasketItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/spare-parts-cart/item`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'supplierOrderMaterialBasketItems',
        {type: 'supplierOrders', id: queryArg.orderId},
      ],
      extraOptions: {
        requestSchema: PostSupplierOrderMaterialBasketItemRequestSchema,
        responseSchema: PostSupplierOrderMaterialBasketItemResponseSchema,
      },
    }),
    patchSupplierOrderMaterialBasketItemQuantity: build.mutation<
      PatchSupplierOrderMaterialBasketItemQuantityResponse,
      PatchSupplierOrderMaterialBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/${queryArg.orderId}/spare-parts-cart/item/${queryArg.itemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['supplierOrderMaterialBasketItems'],
      extraOptions: {
        requestSchema: PatchSupplierOrderMaterialBasketItemQuantityRequestSchema,
        responseSchema: PatchSupplierOrderMaterialBasketItemQuantityResponseSchema,
      },
    }),
    getSupplierOrderType: build.query<GetSupplierOrderTypeResponse, GetSupplierOrderTypeRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order/type`,
        params: {
          supplierId: queryArg.supplierId,
          supplierOrderVariantId: queryArg.supplierOrderVariantId,
        },
        method: 'GET',
      }),
      providesTags: ['supplierOrderType'],
      extraOptions: {
        requestSchema: GetSupplierOrderTypeRequestSchema,
        responseSchema: GetSupplierOrderTypeResponseSchema,
      },
    }),
  }),
});

export const {
  useGetSupplierOrderQuery,
  usePostSupplierOrderMutation,
  usePatchSupplierOrderMutation,
  useBulkPostSupplierOrderMutation,
  useBulkPostSupplierOrderItemsMutation,
  useDeleteSupplierOrderItemsMutation,
  usePatchSupplierOrderStateMutation,
  useDeleteSupplierOrderMutation,
  useGetSupplierOrderTypeQuery,
  useLazyGetSupplierOrderTypeQuery,
  useGetSupplierOrderItemQuery,
  useLazyGetSupplierOrderItemQuery,
  usePatchSupplierOrderItemMutation,
  usePutSupplierOrderItemCalculationMutation,
  useGetSupplierOrderMaterialBasketQuery,
  usePostSupplierOrderBasketCheckoutMutation,
  usePostSupplierOrderMaterialBasketItemMutation,
  useDeleteSupplierOrderMaterialBasketItemsMutation,
  usePatchSupplierOrderMaterialBasketItemQuantityMutation,
} = metadaWarehouseSupplierOrderApi;
