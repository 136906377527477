import {Avatar, Button, Flag} from 'platform/components';
import {Box, Hide, HStack, Link, Show, Space, Text, VStack} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {customerRoutes} from '@omnetic-dms/routes';

import {composePath, DOT_CHARACTER, EMPTY_PLACEHOLDER, suffixTestId, TestIdProps} from 'shared';

import {useLazyGetCustomerV2Query} from '../../../api/customerApi';
import i18n from '../../../i18n/i18n';
import {
  CustomerAdvancedSearchApiArg,
  CustomerAdvancedSearchResultContactResponseBody,
  CustomerAdvancedSearchResultResponseBody,
  CustomerResponseBodyV2,
} from '../../../types/api/customer';
import {handleApiError} from '../../../utils/handleApiError';
import {CustomerMatchResult} from '../hooks/useCustomersMatch';
import {MatchingIcon} from './MatchingIcon';
import {TagReplacer} from './TagReplacer';

const REQUIRED_MATCH_ARRAY = ['FULL', 'PARTIAL'];
export interface MatchingCustomerProps extends TestIdProps {
  customer: CustomerAdvancedSearchResultResponseBody;
  contact?: CustomerAdvancedSearchResultContactResponseBody;
  searchArg: CustomerAdvancedSearchApiArg;
  matchProps: CustomerMatchResult;
  onSelect: (customer: CustomerResponseBodyV2) => void;
}

export function MatchingCustomer(props: MatchingCustomerProps) {
  const [getCustomer, {isLoading}] = useLazyGetCustomerV2Query();

  const handleSelectCustomer = () =>
    getCustomer({customerId: props.customer.id})
      .unwrap()
      .then(props.onSelect)
      .catch(handleApiError);

  return (
    <Box
      height="100%"
      borderColor="palettes.neutral.40.100"
      borderBottom="1px solid"
      backgroundColor="palettes.white.10.100"
      overflow="hidden"
      paddingVertical={3}
    >
      <HStack align="center" spacing={3}>
        <Show when={props.matchProps.isFullMatch || props.matchProps.isErrorMatch}>
          <MatchingIcon isFullMatch={props.matchProps.isFullMatch} />
        </Show>
        <Hide when={props.matchProps.isFullMatch || props.matchProps.isErrorMatch}>
          <Avatar
            size="default"
            icon={
              props.customer.discriminator === 'NATURAL_PERSON' ? 'social/person' : 'social/domain'
            }
          />
        </Hide>
        <VStack spacing={1} align="flex-start" justify="center">
          <HStack spacing={2} wrap align="center">
            <Text data-testid={suffixTestId('name', props)}>
              <TagReplacer
                text={props.matchProps.customerName}
                matchType={
                  (props.matchProps.isNameFullMatch && 'FULL') ||
                  (props.matchProps.isNameErrorMatch && 'NONE') ||
                  (props.matchProps.isNamePartialMatch && 'PARTIAL') ||
                  'PARTIAL'
                }
                isErrorMatch={props.matchProps.isNameErrorMatch}
              />
            </Text>
            <Show
              when={
                props.contact &&
                !REQUIRED_MATCH_ARRAY.includes(props.matchProps.customerNameMatchType ?? '')
              }
            >
              <Flag
                size="small"
                label={i18n.t('entity.customer.labels.contactPerson')}
                data-testid={suffixTestId('contactPerson', props)}
              />
            </Show>
          </HStack>
          <HStack spacing={1} wrap>
            <Link
              target="_blank"
              size="xSmall"
              leftIcon="action/open_in_new"
              href={composePath(customerRoutes.detail, {params: {id: props.customer.id}})}
              title={i18n.t('general.labels.detail')}
              data-testid={suffixTestId('customerDetail', props)}
            />
            <Text color="tertiary" size="xSmall">
              {DOT_CHARACTER}
            </Text>
            <Show when={isNotNilOrEmpty(props.contact)}>
              <Text
                size="xSmall"
                color="secondary"
                noWrap
                data-testid={suffixTestId('contact', props)}
              >
                <TagReplacer
                  text={props.matchProps.contactName}
                  matchType={props.matchProps.contactNameMatchType}
                  isErrorMatch={props.matchProps.isNameErrorMatch}
                />
              </Text>
              <Text color="tertiary" size="xSmall">
                {DOT_CHARACTER}
              </Text>
            </Show>
            <Text
              size="xSmall"
              color="secondary"
              noWrap
              data-testid={suffixTestId('address', props)}
            >
              {props.matchProps.shortAddress || EMPTY_PLACEHOLDER}
            </Text>
          </HStack>
          <HStack spacing={1} wrap>
            <Show when={isNotNilOrEmpty(props.customer.registrationNumberHighlight)}>
              <Text
                size="xSmall"
                color="secondary"
                noWrap
                data-testid={suffixTestId('registrationNumber', props)}
              >
                {`${i18n.t('entity.customer.labels.registrationNumber')}: `}
                <TagReplacer
                  text={props.customer.registrationNumberHighlight || EMPTY_PLACEHOLDER}
                  matchType={props.customer.registrationNumberMatchType}
                  isErrorMatch={props.matchProps.isLegalNumberErrorMatch}
                />
              </Text>
            </Show>
            <Show
              when={
                isNotNilOrEmpty(props.customer.registrationNumberHighlight) &&
                isNotNilOrEmpty(props.customer.vatNumberHighlight)
              }
            >
              <Text color="tertiary" size="xSmall">
                {DOT_CHARACTER}
              </Text>
            </Show>
            <Show when={isNotNilOrEmpty(props.customer.vatNumberHighlight)}>
              <Text
                size="xSmall"
                color="secondary"
                noWrap
                data-testid={suffixTestId('vatNumber', props)}
              >
                {`${i18n.t('entity.customer.labels.vatNumber')}: `}
                <TagReplacer
                  text={props.customer.vatNumberHighlight || EMPTY_PLACEHOLDER}
                  matchType={props.customer.vatNumberMatchType}
                  isErrorMatch={props.matchProps.isLegalNumberErrorMatch}
                />
              </Text>
            </Show>
          </HStack>
        </VStack>
        <Space fillAvailable />
        <VStack spacing={1} align="flex-end" justify="center">
          <Text data-testid={suffixTestId('phone', props)}>
            <TagReplacer
              text={props.matchProps.customerPhone?.highlight || EMPTY_PLACEHOLDER}
              matchType={props.matchProps.customerPhone?.type}
              isErrorMatch={props.matchProps.isPhoneNumberErrorMatch}
            />
          </Text>
          <Text color="secondary" size="xSmall" noWrap data-testid={suffixTestId('email', props)}>
            <TagReplacer
              text={props.matchProps.customerEmail?.highlight || EMPTY_PLACEHOLDER}
              matchType={props.matchProps.customerEmail?.type}
              isErrorMatch={props.matchProps.isEmailErrorMatch}
            />
          </Text>
        </VStack>
        <Button
          title={i18n.t('general.labels.select')}
          onClick={handleSelectCustomer}
          variant={props.matchProps.isFullMatch ? 'primary' : 'secondary'}
          isLoading={isLoading}
          data-testid={suffixTestId('select', props)}
        />
      </HStack>
    </Box>
  );
}
