import {Attributes} from 'platform/components';

import {TestIdProps} from 'shared';

import {useCustomerContractAttributes} from '../../hooks/useCustomerContractAttributes';
import {ContractInformationResponseBodyV2} from '../../types/api/customer';

interface CustomerContractAttributesProps extends TestIdProps {
  contract: ContractInformationResponseBodyV2;
  displayAftersalesVersion?: boolean;
}

export function CustomerContractAttributes(props: CustomerContractAttributesProps) {
  const [getAttributes] = useCustomerContractAttributes(props.displayAftersalesVersion);
  return (
    <Attributes
      rows={getAttributes(props.contract)}
      size="quarter"
      data-testid={props['data-testid']}
    />
  );
}
