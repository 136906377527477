import {DataStatus} from 'platform/components';

import {defaultTo, head, isNil} from 'ramda';

import {
  Main,
  useGetBusinessCaseQuery,
  useGetSaleVehicleComplaintsQuery,
  VehicleComplaints,
} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

export function BusinessCaseComplaintDetail() {
  const {id} = useRequiredParams();

  const {
    data: businessCase,
    isLoading: isBusinessCaseLoading,
    isError: isBusinessCaseError,
  } = useGetBusinessCaseQuery({businessCaseId: id});

  const offers = head(defaultTo([], businessCase?.offers));
  const saleVehicles = defaultTo([], offers?.saleVehicles);
  const vehicleId = head(saleVehicles)?.vehicleId;

  const {
    data: complaints = [],
    isLoading: isComplaintsLoading,
    isError: isComplaintsError,
  } = useGetSaleVehicleComplaintsQuery(
    {saleVehicleId: businessCase?.saleVehicleId ?? ''},
    {skip: isNil(businessCase?.saleVehicleId)}
  );

  const isLoading = isBusinessCaseLoading || isComplaintsLoading;
  const isError = isBusinessCaseError || isComplaintsError;

  return (
    <Main>
      <DataStatus isEmpty={isNil(vehicleId)} isLoading={isLoading} isError={isError}>
        <VehicleComplaints vehicleId={vehicleId ?? ''} complaints={complaints} />
      </DataStatus>
    </Main>
  );
}
