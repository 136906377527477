import {DataStatus, Separator, Table} from 'platform/components';
import {Box, Hide, VStack} from 'platform/foundation';

import {useState} from 'react';

import {head, length} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {useSourcingDrillDownQuery} from '../../api/sourcingApi';
import {DrillDownLevelName} from '../../types/api/drillDown';
import {DrillDownLevelsConfigurator} from './components/DrillDownLevelsConfigurator';
import {DrillDownRow} from './components/DrillDownRow';
import {getKeyFromLevels} from './utils/getKeyFromLevels';
import {getLevelConfigurations} from './utils/getLevelConfigurations';
import {getPermanentLevelFilters} from './utils/getPermanentLevelFilters';
import {getTableColumns} from './utils/getTableColumns';

export interface DrillDownProps extends TestIdProps {
  currency: string;
  regions: string[];
  permanentFilters?: Partial<Record<DrillDownLevelName, string>>;
  hiddenLevels?: DrillDownLevelName[];
  selectedRow: Record<DrillDownLevelName, string>;
  focusOnSelectedRow?: boolean;
  hideConfigurator?: boolean;
}

export function DrillDown(props: DrillDownProps) {
  const [levelConfigurations, setLevelConfigurations] = useState(() =>
    getLevelConfigurations(props.hiddenLevels)
  );

  const permanentLevelFilters = getPermanentLevelFilters(props.permanentFilters);
  const {data, isLoading, isFetching, isError} = useSourcingDrillDownQuery({
    levels: [
      ...permanentLevelFilters,
      {
        level: head(levelConfigurations)?.name ?? 'make',
        values: [],
        priority: length(permanentLevelFilters) + 1,
      },
    ],
    currency: props.currency,
    regions: props.regions,
  });

  const columns = getTableColumns(props.currency);

  return (
    <VStack spacing={3}>
      <Hide when={props.hideConfigurator}>
        <DrillDownLevelsConfigurator
          value={levelConfigurations}
          onChange={setLevelConfigurations}
        />
      </Hide>
      <DataStatus isLoading={isLoading || isFetching} isError={isError} minHeight={50}>
        <Box minWidth={200} paddingBottom={1}>
          <Hide when={props.hideConfigurator}>
            <Separator orientation="horizontal" spacing={0} />
          </Hide>
          <Table
            variant="stripped"
            columns={columns}
            data-testid={suffixTestId('drillDown', props)}
          >
            {data?.statisticsAggregated.map((item) => (
              <DrillDownRow
                key={getKeyFromLevels(item.levels)}
                depth={0}
                data={item}
                levelConfigurations={levelConfigurations}
                currency={props.currency}
                translations={data.translations}
                regions={props.regions}
                selectedRow={props.selectedRow}
                focusOnSelectedRow={props.focusOnSelectedRow}
              />
            ))}
          </Table>
        </Box>
      </DataStatus>
    </VStack>
  );
}
