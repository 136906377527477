import {CustomRequestOption, UploadState, Upload, isFile, IconButton} from 'platform/components';
import {Icon, VStack, HStack, Link} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {Control, useFieldArray} from 'react-hook-form';

import {always} from 'ramda';

import {DOCX_MIME_TYPE, PDF_MIME_TYPE, suffixTestId, TestIdProps} from 'shared';

import {useUploadFileMutation} from '../../api/baseApi/fileStorageApi';
import i18n from '../../i18n/i18n';
import {downloadHostedFile} from '../../utils/downloadHostedFile';
import {handleApiError} from '../../utils/handleApiError';
import {SendEmailForm} from './types/SendEmailForm';

interface DocumentUploadProps extends TestIdProps {
  control: Control<SendEmailForm>;
}

export function DocumentUpload(props: DocumentUploadProps) {
  const [uploadFile, {isLoading, isError, isSuccess}] = useUploadFileMutation();
  const {fields, remove, append} = useFieldArray({
    control: props.control,
    name: 'documents',
  });

  const handleUpload = async ({file}: CustomRequestOption) => {
    if (!isFile(file)) {
      return;
    }
    await uploadFile({file})
      .unwrap()
      .then((data) => {
        append({
          title: data.file.name,
          fileId: data.fileId,
          fileUri: data.fileUri,
          fileName: data.file.name,
        });
      })
      .catch(handleApiError);
  };

  const uploadState = match([isError, isLoading, isSuccess])
    .with([true, Pattern.any, Pattern.any], always(UploadState.Error))
    .with([false, true, false], always(UploadState.Uploading))
    .otherwise(always(UploadState.Success));

  return (
    <VStack spacing={4}>
      {fields.map((field, index) => (
        <HStack key={field.id} spacing={4} align="center" justify="space-between">
          <HStack align="center" spacing={3}>
            <Icon value="files_default/file_type_DOC" size={8} />
            <Link
              data-testid={suffixTestId('documentUpload', props)}
              onClick={() => downloadHostedFile(field.fileUri, field.fileName)}
              title={field.fileName}
            />
          </HStack>
          <IconButton
            icon="action/delete"
            data-testid={suffixTestId('documentUpload', props)}
            onClick={() => remove(index)}
          />
        </HStack>
      ))}

      <Upload
        isMultiple
        type="card"
        size="minHeight"
        uploadIcon="file/upload"
        uploadingIcon="action/hourglass_empty"
        uploadText={i18n.t('entity.document.labels.addDocuments')}
        uploadState={uploadState}
        errorIcon="navigation/cancel"
        customRequest={handleUpload}
        accept={`${DOCX_MIME_TYPE},${PDF_MIME_TYPE}`}
        data-testid={suffixTestId('documentUpload', props)}
      />
    </VStack>
  );
}
