import {
  Card,
  Separator,
  Form,
  FormField,
  FormButton,
  Button,
  FormSubmitHandler,
  showNotification,
  UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField,
} from 'platform/components';
import {GridItem, Grid, HStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useState, useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  useUploadFileMutation,
  useCreatePromotionalPhotoMutation,
  useEditPromotionalPhotoMutation,
  getApiValidationErrors,
  handleApiError,
} from '@omnetic-dms/shared';

import {PromoPhotosFormType} from '../../types/PromoPhotosFormType';
import {PromoPhotosType} from '../../types/PromoPhotosType';
import {dataChanged} from '../../utils/dataChanged';
import {getDialogTypeByValues} from '../../utils/getDialogTypeByValues';
import {Dialogs} from './components/Dialogs';
import {UnsuportedPlatformsNotification} from './components/UnsuportedPlatformsNotification';
import {DialogTypes} from './types/DialogTypes';

export interface PromoPhotosFormProps {
  editData?: PromoPhotosType;
}

const MAX_FILE_SIZE = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE * Math.pow(2, 20);

export function PromoPhotosForm({editData}: PromoPhotosFormProps) {
  const [uploadFile] = useUploadFileMutation();
  const navigate = useNavigate();
  const [createPromotionalPhoto, {isLoading: createLoading}] = useCreatePromotionalPhotoMutation();
  const [editPromotionalPhoto, {isLoading: editLoading}] = useEditPromotionalPhotoMutation();
  const [dialogType, setDialogType] = useState<{type: DialogTypes; id: string} | null>(null);

  const defaultValues = editData
    ? {
        title: editData.title,
        position: editData.position,
        fileId: {
          id: editData.fileId,
          url: editData.url,
        },
      }
    : undefined;

  const handleSubmit = useCallback<FormSubmitHandler<PromoPhotosFormType>>(
    async (data, setErrors) => {
      const fileId = data.fileId.id;
      if (editData) {
        if (dataChanged(editData, data)) {
          navigate(settingsRoutes.advertisingPromoPhotos);
          return;
        }

        await editPromotionalPhoto({
          promotionalPhotoId: editData.id,
          editPromotionalPhotoRequestBody: {...data, fileId},
        })
          .unwrap()
          .then(() => {
            showNotification.success(i18n.t('entity.promoPhotos.notifications.edited'));
            const dialogType = getDialogTypeByValues(editData, data);
            if (dialogType) {
              setDialogType({type: dialogType, id: editData.id});
            }
          })
          .catch((e) => {
            handleApiError(e, {callback: ({error}) => setErrors(getApiValidationErrors(error))});
          });
      } else {
        await createPromotionalPhoto({createPromotionalPhotoRequestBody: {...data, fileId}})
          .unwrap()
          .then((data) => {
            showNotification.success(i18n.t('entity.promoPhotos.notifications.create'));

            setDialogType({type: DialogTypes.ADDED, id: data.id});
          })
          .catch((e) => {
            handleApiError(e, {callback: ({error}) => setErrors(getApiValidationErrors(error))});
          });
      }
    },
    [createPromotionalPhoto, editPromotionalPhoto, setDialogType, navigate, editData]
  );

  const handleUpload = async (file: File) => {
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      const errorMessage = i18n.t('entity.photo.labels.maxUploadFileSize', {
        max: MAX_FILE_SIZE,
      });

      showNotification.error(errorMessage);
      return Promise.reject();
    }
    const data = await uploadFile({file}).unwrap();

    return {id: data.fileId, url: data.fileUri};
  };

  const handleUploadError = (error: Error) => {
    if (error instanceof UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField) {
      showNotification.error(i18n.t('entity.promoPhotos.notifications.imageFormatIsNotAllowed'));
    }
  };

  return (
    <>
      <Form<PromoPhotosFormType>
        onSubmit={handleSubmit}
        schema={PromoPhotosFormSchema}
        defaultValues={defaultValues}
      >
        {(control) => (
          <Card
            title={
              editData
                ? i18n.t('page.salesSettings.labels.editPromoPhoto')
                : i18n.t('page.salesSettings.labels.newPromoPhoto')
            }
          >
            <UnsuportedPlatformsNotification />
            <Grid columns={3}>
              <GridItem span={2}>
                <FormField
                  type="text"
                  control={control}
                  label={i18n.t('entity.promoPhotos.title')}
                  name="title"
                />
              </GridItem>
              <GridItem>
                <FormField
                  type="integer"
                  control={control}
                  label={i18n.t('entity.promoPhotos.order')}
                  name="position"
                />
              </GridItem>
            </Grid>
            <Separator />
            <FormField
              type="uploadImage"
              control={control}
              name="fileId"
              uploadFileRequest={handleUpload}
              onUploadError={handleUploadError}
              helperText={`${i18n.t('entity.photo.labels.uploadOrDragAndDropImage')}. ${i18n.t(
                'entity.photo.labels.maxUploadFileSize',
                {
                  max: MAX_FILE_SIZE,
                }
              )}`}
              uploadingText={i18n.t('general.labels.uploadingWithDots')}
              uploadIcon="image/photo_camera"
              linkValueFormatter={(url) => url.replace('resize', 'get')}
              srcValueFormatter={(url) => `${url}${url.includes('resize') ? '&height=108' : ''}`}
              onPreviewClick={(url) => window.open(url.replace('resize', 'get'), '_blank')}
            />
            <Separator />
            <HStack spacing={2} justify="flex-end">
              <Link to={settingsRoutes.advertisingPromoPhotos}>
                <Button variant="secondary" title={i18n.t('general.actions.cancel')} />
              </Link>
              <FormButton
                control={control}
                type="submit"
                variant="primary"
                isLoading={createLoading || editLoading}
                title={i18n.t('general.actions.save')}
              />
            </HStack>
          </Card>
        )}
      </Form>
      {dialogType && <Dialogs {...dialogType} />}
    </>
  );
}

const PromoPhotosFormSchema = Yup.object({
  title: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  position: Yup.number()
    .typeError(i18n.t('general.validations.mustBeNumber'))
    .moreThan(0, i18n.t('general.errors.number.min', {min: 0}))
    .required(i18n.t('general.validations.fieldIsRequired')),
  fileId: Yup.object()
    .nullable()
    .required(
      `${i18n.t('general.validations.fieldIsRequired')}. ${i18n.t(
        'entity.photo.labels.maxUploadFileSize',
        {max: MAX_FILE_SIZE}
      )}`
    ),
});
