import {DeepMap, FieldError} from 'react-hook-form';

import {ApiException} from '@omnetic-dms/shared';

import {ItemType} from './types/ItemType';

const patternErrorRegex = /patterns\[([\d]+)\]\.([\w]+)/;

export const parsePatternErrors = (
  errors: ApiException['validationErrors']
): DeepMap<ItemType, FieldError>[] => {
  const validationErrors: DeepMap<ItemType, FieldError>[] = [];
  errors?.forEach((error) => {
    const matches = error.field?.match(patternErrorRegex);
    if (matches && matches.length > 0) {
      const [, index, field] = matches;
      const previous = validationErrors[parseInt(index)] || {};
      validationErrors[parseInt(index)] = {
        ...previous,
        [field]: {message: error.message, type: 'validate'},
      };
    }
  });
  return validationErrors;
};

export const zipItemErrors = (
  errors1: any[] = [],
  errors2: DeepMap<ItemType, FieldError>[] = []
) => {
  let longer = errors1;
  let shorter = errors2;

  if (errors2.length > errors1.length) {
    longer = errors2;
    shorter = errors1;
  }

  return longer?.map((map, index) => {
    const shorterMap = shorter[index];
    if (shorterMap) {
      return {...shorterMap, ...map};
    }
    return map;
  });
};
