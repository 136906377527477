import {Card, Action} from 'platform/components';
import {VStack} from 'platform/foundation';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import i18n from '../../../i18n/i18n';
import {FullVehicleResponseBody} from '../../../types/api/api';
import {GetVehicleCustomerApiResponse} from '../../../types/api/vehicleCustomer';
import {SuggestedVehicle} from './SuggestedVehicle';

interface SuggestedVehiclesProps extends TestIdProps {
  selectedVehicle: FullVehicleResponseBody | null;
  suggestedVehicles: GetVehicleCustomerApiResponse;
  onVehicleChange: (vehicleId: FullVehicleResponseBody | null) => void;
  onAddNew: () => void;
}

export function SuggestedVehicles(props: SuggestedVehiclesProps) {
  return (
    <>
      <Card
        actions={buildArray<Action>().add({
          type: 'button',
          size: 'small',
          variant: 'link',
          leftIcon: 'content/add_circle',
          title: i18n.t('general.actions.addNew'),
          onClick: props.onAddNew,
        })}
        title={i18n.t('entity.vehicle.labels.vehicle')}
        data-testid={suffixTestId('header', props)}
      >
        <VStack spacing={4}>
          {props.suggestedVehicles?.map(
            (suggested) =>
              suggested?.vehicleId && (
                <SuggestedVehicle
                  key={suggested.id}
                  type={suggested?.customerType}
                  vehicleId={suggested.vehicleId}
                  isSelected={props.selectedVehicle?.id === suggested?.vehicleId}
                  onSelect={props.onVehicleChange}
                />
              )
          )}
        </VStack>
      </Card>
    </>
  );
}
