import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

interface CodeFormData {
  name: string;
}

export interface CodeFormProps {
  defaultValues?: CodeFormData;
  onSubmit: FormSubmitHandler<CodeFormData>;
}

const DEFAULT_VALUES: CodeFormData = {name: ''};

export function CodeForm(props: CodeFormProps) {
  return (
    <Form<CodeFormData>
      onSubmit={props.onSubmit}
      defaultValues={props.defaultValues ?? DEFAULT_VALUES}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            label={i18n.t('entity.codelist.labels.codeName')}
            control={control}
            name="name"
            type="text"
            isRequired
            isAutoFocused
          />
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('general.actions.cancel')}
            />
            <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
