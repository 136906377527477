import {
  Button,
  ButtonGroup,
  DialogFooter,
  Form,
  FormButton,
  FormSubmitHandler,
} from 'platform/components';
import {Space} from 'platform/foundation';
import {array, object} from 'yup';

import {DefaultValues} from 'react-hook-form';

import {Nullish, yupString, RequiredTestIdProps, suffixTestId} from 'shared';

import i18n from '../../i18n/i18n';
import {ServiceVehicleFormContent} from './components/ServiceVehicleFormContent';
import {ServiceVehicleFormType} from './types/ServiceVehicleFormType';

interface ServiceVehicleFormProps extends RequiredTestIdProps {
  defaultValue?: DefaultValues<ServiceVehicleFormType> | Nullish;
  onSubmit?: FormSubmitHandler<ServiceVehicleFormType>;
  onCancel?: () => void;
}

export function ServiceVehicleForm(props: ServiceVehicleFormProps) {
  return (
    <Form<ServiceVehicleFormType>
      defaultValues={{type: ['VEHICLETYPE_PASSENGER_CAR'], ...props.defaultValue}}
      onSubmit={props.onSubmit}
      schema={schema}
    >
      {(control, formApi) => (
        <>
          <ServiceVehicleFormContent
            data-testid={props['data-testid']}
            control={control}
            formApi={formApi}
          />
          <Space vertical={16} />
          <DialogFooter>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onCancel}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                type="submit"
                control={control}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </>
      )}
    </Form>
  );
}

const schema = object({
  make: yupString.required(),
  modelFamily: yupString.required(),
  type: array().of(yupString.required()).required().length(1, i18n.t('general.errors.required')),
});
