import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {testIds} from '@omnetic-dms/routes';

import {LiteralUnionAutocomplete, Nullish} from 'shared';

import i18n from '../i18n/i18n';
import {SaleType} from '../types/api/api';

export const getVehicleSaleTypeFlagProps = (type: LiteralUnionAutocomplete<SaleType> | Nullish) => {
  if (isNil(type)) {
    return null;
  }

  return match<LiteralUnionAutocomplete<SaleType> | Nullish, BaseFlagProps | Nullish>(type)
    .with(
      'IN_STOCK',
      always({
        label: i18n.t('entity.vehicle.labels.typeInStock'),
        colorScheme: 'orange',
        'data-testid': testIds.vehicles.detail('vehicleHeader-typeInStock'),
      })
    )
    .with(
      'BROKERAGE',
      always({
        label: i18n.t('entity.vehicle.labels.typeBrokerage'),
        colorScheme: 'teal',
        'data-testid': testIds.vehicles.detail('vehicleHeader-typeBrokerage'),
      })
    )
    .otherwise(always(undefined));
};
