import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
  Separator,
} from 'platform/components';
import {Grid, VStack, Space} from 'platform/foundation';
import {boolean, object, string} from 'yup';

import {useParams} from 'react-router-dom';

import {defaultTo, isNil} from 'ramda';
import {isNotNil, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useGetManufacturerQuery,
  usePatchManufacturerMutation,
  usePostManufacturerMutation,
} from '@omnetic-dms/shared';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export type WarehousesManufacturerForm = {
  name?: string | null;
  code?: string | null;
  isActive: boolean;
};

export function WarehousesManufacturersDetail() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: manufacturer,
    isLoading,
    isError,
  } = useGetManufacturerQuery({manufacturerId: params.id ?? ''}, {skip: isNil(params.id)});

  const [createManufacturer] = usePostManufacturerMutation();
  const [updateManufacturer] = usePatchManufacturerMutation();

  const handleSubmit: FormSubmitHandler<WarehousesManufacturerForm> = async (data) => {
    const body = {
      ...data,
      name: defaultTo('', data.name),
      isLocked: isTrue(manufacturer?.isLocked),
    };
    const promise = isNotNil(params.id)
      ? updateManufacturer({
          body,
          manufacturerId: params.id,
        })
          .unwrap()
          .then(() => {
            showNotification.success(
              i18n.t('entity.warehouse.notifications.warehouseManufacturerUpdated')
            );
          })
      : createManufacturer({body: {manufacturer: body}})
          .unwrap()
          .then(() => {
            showNotification.success(
              i18n.t('entity.warehouse.notifications.warehouseManufacturerCreated')
            );
          });

    await promise.then(() => handleNavigateBack()).catch(handleApiError);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesManufacturers);
  };

  const defaultValues: WarehousesManufacturerForm = {
    name: manufacturer?.name,
    code: manufacturer?.code,
    isActive: manufacturer?.isActive ?? true,
  };

  const header: SettingsTemplateHeader = {
    title: isNil(params.id)
      ? i18n.t('entity.warehouse.labels.newWarehouseManufacturer')
      : manufacturer?.name ?? '',
    breadcrumbs: [
      {
        label: i18n.t('entity.warehouse.labels.warehouseManufacturer'),
        href: settingsRoutes.warehousesManufacturers,
      },
    ],
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<WarehousesManufacturerForm>
        schema={formSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        isFullHeight
      >
        {(control, formApi) => (
          <>
            <SettingsTemplate
              header={header}
              data-testid={testIds.settings.warehousesManufacturersDetail('template')}
            >
              <VStack spacing={4}>
                <FormField
                  control={control}
                  type="switch"
                  name="isActive"
                  label={i18n.t('entity.warehouse.labels.setAsActive')}
                  data-testid={testIds.settings.warehousesManufacturersDetail('isActive')}
                />

                <Grid columns={2}>
                  <Separator />
                </Grid>

                <Grid columns={4}>
                  <FormField
                    control={control}
                    type="text"
                    name="name"
                    label={i18n.t('entity.warehouse.labels.name')}
                    isRequired
                    isDisabled={isTrue(manufacturer?.isLocked)}
                    data-testid={testIds.settings.warehousesManufacturersDetail('name')}
                  />
                  <FormField
                    control={control}
                    type="text"
                    name="code"
                    label={i18n.t('entity.warehouse.labels.code')}
                    isDisabled={isTrue(manufacturer?.isLocked)}
                    data-testid={testIds.settings.warehousesManufacturersDetail('code')}
                  />
                </Grid>
                <Space fillAvailable />
                <SettingsFooter
                  actions={[
                    {
                      type: 'button',
                      variant: 'secondary',
                      title: isNil(params.id)
                        ? i18n.t('general.actions.discard')
                        : i18n.t('general.actions.discardChanges'),
                      onClick: handleNavigateBack,
                      'data-testid': testIds.settings.warehousesManufacturersDetail('discard'),
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      variant: 'primary',
                      title: isNil(params.id)
                        ? i18n.t('general.actions.save')
                        : i18n.t('general.actions.saveChanges'),
                      'data-testid': testIds.settings.warehousesManufacturersDetail('submit'),
                    },
                  ]}
                />
              </VStack>
            </SettingsTemplate>
          </>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  name: string().nullable().required(),
  code: string().nullable(),
  isActive: boolean().default(true),
});
