import {Option} from 'platform/components';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {useGetTopMakesQuery} from '../api/topMakesApi';
import {useGetMakeModelWithMakeQuery} from '../api/vehicleCatalogueApi';
import {FALLBACK_LANGUAGE} from '../i18n/fallbackLanguage';
import i18n from '../i18n/i18n';
import {sortByLabel} from '../utils/sortByLabel';
import {useCustomTenantCatalogue} from './useCustomTenantCatalogue';

type GroupType = {
  label: string;
  options: readonly Option[];
};

type UseVehicleMakeReturnType = [
  {makeOptions: Option[] | Nullish; groupedMakeOptionsWithTopMakes: GroupType[] | Nullish},
  {isLoading: boolean; isError: boolean},
];

export function useVehicleMake(vehicleType: string | Nullish): UseVehicleMakeReturnType {
  const {data, isLoading, isError} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicleType ?? '',
      lang: [i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: isNilOrEmpty(vehicleType)}
  );

  const {
    customMakeOptions,
    isLoading: isCustomTenantCatalogueLoading,
    isError: isCustomTenantCatalogueErrored,
  } = useCustomTenantCatalogue(vehicleType);

  const {
    data: topMakes,
    isLoading: isTopMakesLoading,
    isError: isTopMakesErrored,
  } = useGetTopMakesQuery(undefined, {skip: isNilOrEmpty(vehicleType)});

  const baseOptions =
    data?.reduce((makes: Option[], make) => {
      if (isNilOrEmpty(make.make)) {
        return makes;
      }
      return [
        ...makes,
        {
          label: make.labels?.[0]?.label ?? make.default_label,
          value: make.make,
        },
      ];
    }, []) ?? null;

  const baseMakeOptions: Option[] | Nullish =
    isNotNilOrEmpty(customMakeOptions) && isNotNilOrEmpty(baseOptions)
      ? [...customMakeOptions!, ...baseOptions!]
      : baseOptions;

  const makeOptions: Option[] | Nullish = sortByLabel(baseMakeOptions);

  const groupedMakeOptionsWithTopMakes =
    makeOptions && topMakes
      ? [
          {
            label: i18n.t('general.labels.topMakes'),
            options: makeOptions.filter((make) =>
              topMakes.find((topMake) => make.value === topMake.code)
            ),
          },
          {
            label: i18n.t('general.labels.allMakes'),
            options: makeOptions.filter(
              (make) => !topMakes.some((topMake) => make.value === topMake.code)
            ),
          },
        ]
      : null;

  return [
    {makeOptions, groupedMakeOptionsWithTopMakes},
    {
      isLoading: isLoading || isTopMakesLoading || isCustomTenantCatalogueLoading,
      isError: isError || isTopMakesErrored || isCustomTenantCatalogueErrored,
    },
  ];
}
