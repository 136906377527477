import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {BaseVoidRequestSchema, BaseVoidResponseSchema} from '../types/api/base';
import {
  DeleteArticleCategoryRequest,
  DeleteArticleCategoryResponse,
  GetArticleCategoryRequest,
  GetArticleCategoryListRequest,
  GetArticleCategoryListResponse,
  GetArticleCategoryResponse,
  GetDefaultTreeFolderRequest,
  GetDefaultTreeFolderResponse,
  PatchArticleCategoryRequest,
  PatchArticleCategoryResponse,
  PostArticleCategoryRequest,
  PostArticleCategoryResponse,
  GetArticleCategoryRequestSchema,
  GetArticleCategoryResponseSchema,
  PostArticleCategoryRequestSchema,
  PostArticleCategoryResponseSchema,
  PatchArticleCategoryRequestSchema,
  DeleteArticleCategoryRequestSchema,
  GetArticleCategoryListResponseSchema,
} from '../types/api/metadaWarehouseArticleCategory';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseArticleCategoryApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getArticleCategory: build.query<GetArticleCategoryResponse, GetArticleCategoryRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/article-category/${queryArg.articleCategoryId}`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'articleCategory', id: queryArg.articleCategoryId},
      ],
      extraOptions: {
        requestSchema: GetArticleCategoryRequestSchema,
        responseSchema: GetArticleCategoryResponseSchema,
      },
    }),
    postArticleCategory: build.mutation<PostArticleCategoryResponse, PostArticleCategoryRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/article-category`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['articleCategory'],
      extraOptions: {
        requestSchema: PostArticleCategoryRequestSchema,
        responseSchema: PostArticleCategoryResponseSchema,
      },
    }),
    patchArticleCategory: build.mutation<PatchArticleCategoryResponse, PatchArticleCategoryRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/article-category/${queryArg.articleCategoryId}`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: (result, error, queryArg) => [
          'articleCategory',
          {type: 'articleCategory', id: queryArg.articleCategoryId ?? ''},
        ],
        extraOptions: {
          requestSchema: PatchArticleCategoryRequestSchema,
          responseSchema: BaseVoidResponseSchema,
        },
      }
    ),
    deleteArticleCategory: build.mutation<
      DeleteArticleCategoryResponse,
      DeleteArticleCategoryRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/article-category/${queryArg.articleCategoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['articleCategory'],
      extraOptions: {
        requestSchema: DeleteArticleCategoryRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
    getArticleCategoryList: build.query<
      GetArticleCategoryListResponse,
      GetArticleCategoryListRequest
    >({
      query: () => ({
        url: `warehouse/v1/article-category-list`,
        method: 'GET',
      }),
      providesTags: ['articleCategory'],
      extraOptions: {
        requestSchema: BaseVoidRequestSchema,
        responseSchema: GetArticleCategoryListResponseSchema,
      },
    }),
    // TODO: extract to tree folder api
    getDefaultTreeFolder: build.query<GetDefaultTreeFolderResponse, GetDefaultTreeFolderRequest>({
      async queryFn(queryArg, queryApi, extraOptions, fetchWithBaseQuery) {
        const articleCategoryListResult = await fetchWithBaseQuery({
          url: `warehouse/v1/article-category-list`,
          method: 'GET',
        });

        if (isNotNilOrEmpty(articleCategoryListResult.error)) {
          return {error: articleCategoryListResult.error as FetchBaseQueryError};
        }

        const articleCategoryList =
          articleCategoryListResult.data as GetArticleCategoryListResponse;
        const defaultCategoryId = articleCategoryList?.categories.find(
          (category) => category.isDefault
        )?.id;

        const result = await fetchWithBaseQuery({
          url: `/core/v1/tree-folder/${queryArg.contextTarget}/${defaultCategoryId}`,
          method: 'GET',
        });

        if (isNotNilOrEmpty(result.error)) {
          return {error: result.error as FetchBaseQueryError};
        }

        return {data: result.data as GetDefaultTreeFolderResponse};
      },
      providesTags: ['articleCategory'],
    }),
  }),
});

export const {
  useGetArticleCategoryQuery,
  usePostArticleCategoryMutation,
  usePatchArticleCategoryMutation,
  useDeleteArticleCategoryMutation,
  useGetArticleCategoryListQuery,
  useGetDefaultTreeFolderQuery,
} = metadaWarehouseArticleCategoryApi;
