import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {PermissionsKeys, ScopeValues} from '../permissions';

type ParamsWithScopes = {
  scopes: ScopeValues | Nullish;
  permissionKeys: PermissionsKeys[];
};

export const areParamsWithScope = (params: unknown): params is ParamsWithScopes =>
  isNotNilOrEmpty((params as ParamsWithScopes)?.scopes);
