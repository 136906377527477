import i18n from 'i18next';
import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Space} from 'platform/foundation';
import * as Yup from 'yup';

import {useSelector} from 'react-redux';

import {defaultTo, head, isNil, pipe, sort} from 'ramda';

import {
  AuditStructure,
  CreateAutoFillTemplateApiResponse,
  InspectionType,
  useCreateAutoFillTemplateMutation,
  useInspectionListQuery,
  useLazyGetNewestStructureByTypeQuery,
  useUpdateAuditDataMutation,
} from '@omnetic-dms/shared';

import {parseDate, Nullish} from 'shared';

import {selectAuditFields, selectInspections} from '../../../store/carAudit/selectors';
import {InspectionIdForAuditId} from '../../../types/InspectionIdForAuditId';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {getUpdateRequestBodyFromFormData} from '../../../utils/getUpdateRequestBodyFromFormData';

interface CreateTemplateFormProps extends InspectionIdForAuditId {
  vehicleId: string;
  inspectionId: string | Nullish;
}

type CreateTemplateFormType = {
  templateName: string;
};

export function CreateAutoFillTemplateForm(props: CreateTemplateFormProps) {
  const auditFields = useSelector(selectAuditFields) || {};
  const inspections = useSelector(selectInspections);

  const {data: inspectionList} = useInspectionListQuery({
    inspectionListRequestBody: {
      inspectionType: InspectionType.AUTOFILL_TEMPLATE,
      vehicleId: null,
    },
  });

  const inspectionId =
    props.inspectionId ??
    pipe(
      sort(sortByUpdatedAt),
      head,
      (inspection: LoadAuditDataResponseItemBody) => inspection?.id,
      defaultTo(head(inspectionList ?? [])?.id ?? null)
    )(inspections);

  const [getNewestStructure, {isLoading: isLoadingNewestStructure}] =
    useLazyGetNewestStructureByTypeQuery();
  const [createAutoFillTemplate, {isLoading: isLoadingCreateAutoFillTemplate}] =
    useCreateAutoFillTemplateMutation();
  const [updateAuditData, {isLoading: isLoadingUpdateAuditData}] = useUpdateAuditDataMutation();

  const isAutoFillTemplateSaving =
    isLoadingCreateAutoFillTemplate || isLoadingNewestStructure || isLoadingUpdateAuditData;

  const handleSubmit: FormSubmitHandler<CreateTemplateFormType> = async (values) => {
    const autoFillTemplateName = values.templateName;

    await getNewestStructure({
      inspectionType: InspectionType.AUTOFILL_TEMPLATE,
    })
      .unwrap()
      .then(([structure]) => {
        autoFillTemplate(autoFillTemplateName, structure);
      })
      .catch(() => showNotification.error(i18n.t('entity.autofillTemplates.notification.failed')));
  };

  const autoFillTemplate = async (name: string, structure: AuditStructure) => {
    await createAutoFillTemplate({
      vehicleId: props.vehicleId,
      auditStructureId: structure.id,
      inspectionName: name,
    })
      .unwrap()
      .then((autoFillTemplate) => setAutoFillTemplateData(autoFillTemplate))
      .catch(() => showNotification.error(i18n.t('entity.autofillTemplates.notification.failed')));
  };

  const setAutoFillTemplateData = async (autoFillTemplate: CreateAutoFillTemplateApiResponse) => {
    const autoFillData = getUpdateRequestBodyFromFormData(auditFields);
    const currentInspectionId = props.shouldUseAuditIdForUpdateTemplate
      ? props.auditId
      : inspectionId;

    if (!currentInspectionId) {
      return showNotification.error();
    }

    await updateAuditData({
      autofillTemplateId: autoFillTemplate.id,
      requestBody: autoFillData,
      inspectionId: currentInspectionId,
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.autofillTemplates.notification.success'));
        closeCurrentDialog();
      })
      .catch(() => showNotification.error(i18n.t('entity.autofillTemplates.notification.failed')));
  };

  return (
    <>
      <Form<CreateTemplateFormType>
        schema={Yup.object({
          templateName: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
        })}
        onSubmit={handleSubmit}
      >
        {(control) => (
          <>
            {i18n.t('entity.autofillTemplates.newTemplateDialog.description')}

            <Space vertical={5} />

            <Box>
              <FormField
                type="text"
                name="templateName"
                label={i18n.t('entity.autofillTemplates.newTemplateDialog.templateName.label')}
                control={control}
              />
            </Box>

            <Space vertical={4} />

            <ButtonGroup align="right">
              <FormButton
                control={control}
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.cancel')}
              />
              <FormButton
                type="submit"
                control={control}
                isLoading={isAutoFillTemplateSaving}
                title={i18n.t('general.actions.save')}
              />
            </ButtonGroup>
          </>
        )}
      </Form>
    </>
  );
}

const sortByUpdatedAt = (a: LoadAuditDataResponseItemBody, b: LoadAuditDataResponseItemBody) => {
  if (isNil(a.updateAt) || isNil(b.updateAt)) {
    return 0;
  }

  return parseDate(a.updateAt).getTime() - parseDate(b.updateAt).getTime();
};
