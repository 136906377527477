import {isNil, last} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {DrillDownStatistics} from '../../../types/api/drillDown';
import {LevelConfiguration} from '../types';

export function getFirstColumnText(
  data: DrillDownStatistics,
  levelConfiguration: LevelConfiguration | Nullish,
  translations: Record<string, string>
): string | null {
  const text = translations[last(data.levels)?.value ?? ''];

  if (isNil(text)) {
    return null;
  }

  return isNotNil(levelConfiguration) && levelConfiguration.formatter
    ? levelConfiguration.formatter(text)
    : text;
}
