import {Button, closeCurrentDialog, openDeleteDialog, openDialog} from 'platform/components';
import {DataGrid, QueryFilterObject, ActionCallback, useRefreshDataGrid} from 'platform/datagrid';
import {Heading, HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useParams} from 'react-router-dom';

import {mergeAll} from 'ramda';
import {isArray, noop} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError, useDeleteCustomerContractLabourDiscountMutation} from '@omnetic-dms/shared';

import {WorkDiscountForm} from './components/WorkDiscountForm';

export function WorkDiscountsTab() {
  const {id} = useParams();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [deleteLabourDiscount] = useDeleteCustomerContractLabourDiscountMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {customerContractId: id ?? ''}]);

  const handleAdd = () =>
    openDialog(
      <WorkDiscountForm
        customerContractId={id}
        onClose={closeCurrentDialog}
        afterSubmit={refreshDataGrid}
        data-testid={testIds.settings.customerContractsDetail('workDiscountForm')}
      />,
      {
        title: i18n.t('entity.customerContract.labels.newWorkDiscount'),
        scrollBehavior: 'inside',
      }
    );

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const labourDiscountId = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        openDialog(
          <WorkDiscountForm
            customerContractId={id}
            onClose={closeCurrentDialog}
            afterSubmit={refreshDataGrid}
            labourDiscountId={labourDiscountId}
          />,
          {
            title: i18n.t('entity.customerContract.labels.editWorkDiscount'),
            scrollBehavior: 'inside',
          }
        )
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteLabourDiscount({customerContractId: id ?? '', labourDiscountId})
              .unwrap()
              .then(refreshDataGrid)
              .catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  return (
    <VStack spacing={4}>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.customerContract.labels.workDiscounts')}</Heading>
        <Button
          title={i18n.t('general.actions.add')}
          variant="link"
          leftIcon="content/add_circle"
          size="small"
          onClick={handleAdd}
          data-testid={testIds.settings.customerContractsDetail('addWorkDiscount')}
        />
      </HStack>
      <DataGrid
        autoHeight
        ref={dataGridRef}
        gridCode="customer-contract-labour-discount"
        queryModifier={queryModifier}
        actionCallback={actionCallback}
        data-testid={testIds.settings.customerContractsDetail('workDG')}
      />
    </VStack>
  );
}
