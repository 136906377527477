import {DataGrid} from 'platform/datagrid';

import {testIds} from '@omnetic-dms/routes';

import {useBusinessCasesActionCallback} from '../../hooks/useBusinessCasesActionCallback';

export function BusinessCaseList() {
  const [actionCallback] = useBusinessCasesActionCallback();

  return (
    <DataGrid
      gridCode="business-case"
      actionCallback={actionCallback}
      data-testid={testIds.businessCase.home('bussinessCaseList')}
    />
  );
}
