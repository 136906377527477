import {EmailInput} from '../../EmailInput/EmailInput';
import {useFieldProps} from '../hooks/useFieldProps';
import {EmailRendererResponseBody} from '../types/modelSchema';

export function EmailRenderer(props: EmailRendererResponseBody) {
  const {name} = props;
  const {onChange, onBlur, onFocus, value} = useFieldProps(props);

  return (
    <EmailInput
      label={props.label}
      placeholder={props.placeholder}
      data-testid={name}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || ''}
    />
  );
}
