import {Label} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';

import {Nullish} from 'shared';

export type ReadOnlyFieldProps = {
  label: string;
  value: string | number | Nullish;
};

export function ReadOnlyField(props: ReadOnlyFieldProps) {
  return (
    <VStack>
      <Label>{props.label}</Label>
      <Text>{defaultTo('-', props.value)}</Text>
    </VStack>
  );
}
