import {ButtonProps, Form, TabsHeader, showNotification} from 'platform/components';
import {Box, Show} from 'platform/foundation';
import {match} from 'ts-pattern';
import {array, object} from 'yup';

import {useNavigate} from 'react-router-dom';

import {isNotEmpty, isNotNil, omit} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {
  FullScreenModal,
  handleApiError,
  useCreateServiceVehicleMutation,
  useCreateVehicleV2Mutation,
  useDeleteVehicleMutation,
  useLazySourcingGetCarFeaturesQuery,
  useSetAdditionalNoteMutation,
  useUpdateVehicleV2Mutation,
} from '@omnetic-dms/shared';

import {buildArray, composePath, parseDate, yupString} from 'shared';

import {ServiceVehicleFormMode} from '../../types/ServiceVehicleFormMode';
import {BasicInformation} from './(sections)/BasicInformation/BasicInformation';
import {Features} from './(sections)/Features/Features';
import {ServiceIntervals} from './(sections)/ServiceIntervals/ServiceIntervals';
import {VehicleData} from './(sections)/VehicleData/VehicleData';
import {serviceIntervalsDefault} from './constants/serviceIntervalsDefault';
import {useTabs} from './hooks/useTabs';
import {ServiceVehicleFormTabs} from './types/ServiceVehicleFormTabs';
import {ServiceVehicleFormType} from './types/ServiceVehicleFormType';
import {convertFormVehicleToVehicleV2} from './utils/convertFormVehicleToVehicleV2';

type ServiceVehicleFormProps =
  | {
      mode: ServiceVehicleFormMode.NEW;
    }
  | {
      mode: ServiceVehicleFormMode.EDIT;
      vehicleId: string;
      defaultFormData: Partial<ServiceVehicleFormType>;
    }
  | {
      mode: ServiceVehicleFormMode.CLONE;
      vehicleId: string;
      defaultFormData: Partial<ServiceVehicleFormType>;
    };

export function ServiceVehicleForm(props: ServiceVehicleFormProps) {
  const navigate = useNavigate();
  const {selectedTab, setSelectedTab} = useTabs();

  const [createVehicle, createVehicleStatus] = useCreateVehicleV2Mutation();
  const [createServiceVehicle, createServiceVehicleStatus] = useCreateServiceVehicleMutation();
  const [deleteVehicle] = useDeleteVehicleMutation();
  const [setAdditionalNote] = useSetAdditionalNoteMutation();

  const [updateVehicle, updateVehicleStatus] = useUpdateVehicleV2Mutation();

  const [getCarFeaturesQuery] = useLazySourcingGetCarFeaturesQuery();

  const isVehicleCreating = createVehicleStatus.isLoading || createServiceVehicleStatus.isLoading;
  const isVehicleUpdating = updateVehicleStatus.isLoading;

  const getFormDefaultValues = (): Partial<ServiceVehicleFormType> => {
    if (props.mode === ServiceVehicleFormMode.EDIT) {
      return props.defaultFormData;
    }

    if (props.mode === ServiceVehicleFormMode.CLONE) {
      return omit(['registrationPlate', 'vin'], props.defaultFormData);
    }

    return {
      type: ['VEHICLETYPE_PASSENGER_CAR'],
      features: [],
      serviceIntervals: serviceIntervalsDefault,
    };
  };

  const navigateToVehicleDetail = (vehicleId: string) => {
    navigate(composePath(vehiclesRoutes.detail, {params: {id: vehicleId}}));
  };

  const getCarFeatures = (formData: ServiceVehicleFormType) =>
    getCarFeaturesQuery(
      {
        body: {
          make: formData.make || '',
          model: formData.modelFamily || '',
          year: parseDate(formData.firstRegistrationOn)?.getFullYear() || 0,
          features: formData.features || [],
        },
      },
      true
    ).unwrap();

  const handleVehicleCreation = async (formData: ServiceVehicleFormType) => {
    const rawCarFeatures = await getCarFeatures(formData);

    const saneVehicle = convertFormVehicleToVehicleV2({
      formVehicle: formData,
      rawCarFeatures,
    });

    await createVehicle(saneVehicle)
      .unwrap()
      .then(async ({id: vehicleId}) => {
        await createServiceVehicle({vehicleId})
          .unwrap()
          .then(async () => {
            if (isNotNil(formData.note) && isNotEmpty(formData.note)) {
              await setAdditionalNote({
                vehicleId,
                additionalNoteRequestBody: {note: formData.note},
              });
            }

            showNotification.success();
            navigateToVehicleDetail(vehicleId);
          })
          .catch(async (error) => {
            await deleteVehicle({vehicleId});
            handleApiError(error);
          });
      })
      .catch(handleApiError);
  };

  const handleVehicleUpdate = async (formData: ServiceVehicleFormType) => {
    if (props.mode !== ServiceVehicleFormMode.EDIT) {
      return;
    }

    const rawCarFeatures = await getCarFeatures(formData);

    const saneVehicle = convertFormVehicleToVehicleV2({
      formVehicle: formData,
      rawCarFeatures,
    });

    await updateVehicle({id: props.vehicleId, ...saneVehicle})
      .unwrap()
      .then(async () => {
        if (isNotNil(formData.note) && isNotEmpty(formData.note)) {
          await setAdditionalNote({
            vehicleId: props.vehicleId,
            additionalNoteRequestBody: {note: formData.note},
          });
        }

        showNotification.success();
        navigateToVehicleDetail(props.vehicleId);
      })
      .catch(handleApiError);
  };

  return (
    <Form<ServiceVehicleFormType> defaultValues={getFormDefaultValues()} schema={schema}>
      {(control, formApi) => (
        <FullScreenModal
          headline={match(props.mode)
            .with(ServiceVehicleFormMode.NEW, () =>
              i18n.t('entity.vehicle.labels.createServiceVehicle')
            )
            .with(ServiceVehicleFormMode.EDIT, () =>
              i18n.t('entity.vehicle.labels.editServiceVehicle')
            )
            .with(ServiceVehicleFormMode.CLONE, () =>
              i18n.t('entity.vehicle.labels.cloneServiceVehicle')
            )
            .exhaustive()}
          actions={buildArray<ButtonProps>()
            .add({
              variant: 'secondary',
              title: i18n.t('general.actions.discard'),
              onClick: () => navigate(-1),
              'data-testid': testIds.vehicles.createServiceVehicle('fullscreenDialog-discard'),
            })
            .when(props.mode === ServiceVehicleFormMode.NEW, {
              variant: 'primary',
              title: i18n.t('general.actions.create'),
              type: 'submit',
              isLoading: isVehicleCreating,
              onClick: formApi.handleSubmit(handleVehicleCreation),
              'data-testid': testIds.vehicles.createServiceVehicle('fullscreenDialog-create'),
            })
            .when(props.mode === ServiceVehicleFormMode.EDIT, {
              variant: 'primary',
              title: i18n.t('general.actions.save'),
              type: 'submit',
              isLoading: isVehicleUpdating,
              onClick: formApi.handleSubmit(handleVehicleUpdate),
              'data-testid': testIds.vehicles.createServiceVehicle('fullscreenDialog-save'),
            })
            .when(props.mode === ServiceVehicleFormMode.CLONE, {
              variant: 'primary',
              title: i18n.t('entity.vehicle.labels.clone'),
              type: 'submit',
              isLoading: isVehicleCreating,
              onClick: formApi.handleSubmit(handleVehicleCreation),
              'data-testid': testIds.vehicles.createServiceVehicle('fullscreenDialog-clone'),
            })}
        >
          <Box
            backgroundColor="palettes.white.10.100"
            borderColor="palettes.neutral.40.100"
            borderBottom="1px solid"
            paddingHorizontal={4}
          >
            <TabsHeader
              activeTabId={selectedTab}
              onChange={(newTab: string) => setSelectedTab(newTab as ServiceVehicleFormTabs)}
              tabs={[
                {
                  id: ServiceVehicleFormTabs.BasicInformation,
                  title: i18n.t('general.labels.basicInformation'),
                },
                {
                  id: ServiceVehicleFormTabs.VehicleData,
                  title: i18n.t('entity.vehicle.labels.vehicleData'),
                },
                {
                  id: ServiceVehicleFormTabs.Features,
                  title: i18n.t('entity.vehicle.labels.features'),
                },
                {
                  id: ServiceVehicleFormTabs.ServiceIntervals,
                  title: i18n.t('entity.vehicle.labels.serviceIntervals'),
                },
              ]}
            />
          </Box>

          <Show when={selectedTab === ServiceVehicleFormTabs.BasicInformation}>
            <BasicInformation
              control={control}
              formApi={formApi}
              data-testid={testIds.vehicles.createServiceVehicle('fullscreenDialog')}
            />
          </Show>
          <Show when={selectedTab === ServiceVehicleFormTabs.VehicleData}>
            <VehicleData
              control={control}
              formApi={formApi}
              data-testid={testIds.vehicles.createServiceVehicle('fullscreenDialog')}
            />
          </Show>
          <Show when={selectedTab === ServiceVehicleFormTabs.Features}>
            <Features
              formApi={formApi}
              data-testid={testIds.vehicles.createServiceVehicle('fullscreenDialog')}
            />
          </Show>
          <Show when={selectedTab === ServiceVehicleFormTabs.ServiceIntervals}>
            <ServiceIntervals
              control={control}
              formApi={formApi}
              data-testid={testIds.vehicles.createServiceVehicle('fullscreenDialog')}
            />
          </Show>
        </FullScreenModal>
      )}
    </Form>
  );
}

const schema = object({
  make: yupString.required(),
  modelFamily: yupString.required(),
  type: array().of(yupString.required()).required().length(1, i18n.t('general.errors.required')),
});
