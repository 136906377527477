import {Checkbox, DataStatus, FormControl, MultiChoice} from 'platform/components';
import {Box, HStack, Text, VStack} from 'platform/foundation';

import {useFieldArray, useWatch} from 'react-hook-form';

import {any, find, findIndex, isNil, propEq} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  useGetEmployeeWorkCompetencesListQuery,
  useGetTenantQuery,
  useGetAuthorizationProfilesQuery,
  getActiveOptions,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {EmployeeOrganizationalForm} from '../types/employeeOrganizationalFormType';

interface EmployeeAuthoriationProfilesProps extends TestIdProps {
  control: FormControl<EmployeeOrganizationalForm>;
  index: number;
}

export function EmployeeAuthoriationProfiles(props: EmployeeAuthoriationProfilesProps) {
  const [selectedBranch, authorizations] = useWatch({
    control: props.control,
    name: [`branches.${props.index}.branchId`, `branches.${props.index}.authorizations`],
  });

  const {append, remove, update} = useFieldArray({
    name: `branches.${props.index}.authorizations`,
    control: props.control,
  });

  const {data: tenant} = useGetTenantQuery();
  const {
    data: authorizationProfiles,
    isLoading,
    isError,
  } = useGetAuthorizationProfilesQuery(
    {'x-tenant': tenant?.id ?? '', branchId: selectedBranch ?? ''},
    {skip: isNil(tenant)}
  );

  const {competenceOptions} = useGetEmployeeWorkCompetencesListQuery(undefined, {
    selectFromResult: ({data}) => ({
      competenceOptions: getActiveOptions(data?.competences),
    }),
  });

  const handleSelectProfile = (profileId: string | Nullish) => () => {
    const profileIndex = findIndex(propEq(profileId, 'authorizationProfileId'), authorizations);

    if (profileIndex === -1) {
      return append({
        authorizationProfileId: profileId ?? '',
        competencesIds: [],
        isToOffer: false,
      });
    }

    remove(profileIndex);
  };

  const handleIsToOfferChange = (profileId: string | Nullish) => () => {
    const profileIndex = findIndex(propEq(profileId, 'authorizationProfileId'), authorizations);

    if (profileIndex === -1) {
      return append({
        authorizationProfileId: profileId ?? '',
        competencesIds: [],
        isToOffer: true,
      });
    }

    update(profileIndex, {
      ...authorizations[profileIndex],
      isToOffer: !authorizations[profileIndex].isToOffer,
    });
  };

  const handleCompetencesChange =
    (profileId: string | Nullish) => (value: string | number | string[] | null) => {
      const profileIndex = findIndex(propEq(profileId, 'authorizationProfileId'), authorizations);

      if (profileIndex === -1) {
        return append({
          authorizationProfileId: profileId ?? '',
          competencesIds: value as string[],
          isToOffer: false,
        });
      }

      update(profileIndex, {...authorizations[profileIndex], competencesIds: value as string[]});
    };

  const getProfileValue = (profileId: string | Nullish) =>
    any(propEq(profileId, 'authorizationProfileId'), authorizations);

  const getDefaultValue = (profileId: string | Nullish) =>
    !!find(propEq(profileId, 'authorizationProfileId'), authorizations)?.isToOffer;

  const getCompetencesValue = (profileId: string | Nullish) =>
    find(propEq(profileId, 'authorizationProfileId'), authorizations)?.competencesIds ?? null;

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={50}>
      <VStack spacing={1}>
        <HStack spacing={4}>
          <Box flex={1}>
            <HStack spacing={4}>
              <Box flex={1}>
                <Text size="xSmall" color="secondary">
                  {i18n.t('page.employeeDetail.labels.authorizationProfile')}
                </Text>
              </Box>
              <Box flex={1}>
                <Text size="xSmall" color="secondary">
                  {i18n.t('page.employeeDetail.labels.offer')}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box flex={1}>
            <Text size="xSmall" color="secondary">
              {i18n.t('page.employeeDetail.labels.competence')}
            </Text>
          </Box>
        </HStack>
        <VStack spacing={2}>
          {authorizationProfiles?.map((profile, index) => (
            <HStack
              key={profile?.id}
              spacing={4}
              align="center"
              data-testid={suffixTestId('row', props)}
            >
              <Box flex={1}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <Checkbox
                      label={profile?.name}
                      value={getProfileValue(profile?.id)}
                      onChange={handleSelectProfile(profile?.id)}
                      data-testid={suffixTestId(`authorizationProfile-${index}`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <Checkbox
                      value={getDefaultValue(profile?.id)}
                      onChange={handleIsToOfferChange(profile?.id)}
                      data-testid={suffixTestId(`isToOffer-${index}`, props)}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <MultiChoice
                  options={competenceOptions ?? []}
                  value={getCompetencesValue(profile?.id)}
                  onChange={handleCompetencesChange(profile?.id)}
                  data-testid={suffixTestId(`competences-${index}`, props)}
                />
              </Box>
            </HStack>
          ))}
        </VStack>
      </VStack>
    </DataStatus>
  );
}
