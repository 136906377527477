import {Show} from 'platform/foundation';

import {featureFlags} from '@omnetic-dms/shared';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

function PlatformSettingsOtomoto() {
  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.OLX} />
    </Show>
  );
}

export const PlatformSettingsOlx = PlatformSettingsOtomoto;
