import {ActionCallback, DataGrid, QueryFilterObject} from 'platform/datagrid';

import {useParams} from 'react-router-dom';

import {mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {testIds, workshopRoutes} from '@omnetic-dms/routes';
import {workshopSearchParams} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

export function ServiceCaseOverview() {
  const {id} = useParams();
  const navigate = useNavigate();

  const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {vehicleId: id ?? ''}]);

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    if (actionKey === 'redirectDetail') {
      const serviceCaseState = path(['caseState', 'value', 'key'], rowData);
      const serviceCaseId = isArray(rowId) ? rowId[0] : rowId;

      if (serviceCaseState === 'DRAFT') {
        return composePath(workshopRoutes.draftServiceCase, {
          params: {id: serviceCaseId},
          queryParams: {
            tab: workshopSearchParams.create.serviceJobs,
          },
        });
      }

      navigate(
        composePath(workshopRoutes.serviceCaseDetail, {
          params: {
            id: serviceCaseId,
          },
        })
      );
    }
  };

  return (
    <DataGrid
      autoHeight
      gridCode="service-case"
      actionCallback={actionCallback}
      queryModifier={queryModifier}
      data-testid={testIds.workshop.serviceCases('datagrid')}
    />
  );
}
