import {Card, DataStatus} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  BusinessInfoResponseBody,
  composeAddressLineFromCommonAddress,
  OrderAdditionalCustomerResponseBody,
  PersonResponseBody,
  useGetCustomerV2Query,
} from '@omnetic-dms/shared';

import {TestIdProps} from 'shared';

import {CheckoutContractInfo} from './CheckoutContractInfo';

interface CheckoutAdditionalCustomerDetailProps extends TestIdProps {
  additionalCustomer: OrderAdditionalCustomerResponseBody;
  index: number;
  counterStartAt: number;
}

export function CheckoutAdditionalCustomerDetail(props: CheckoutAdditionalCustomerDetailProps) {
  const {
    data: customerData,
    isLoading,
    isError,
  } = useGetCustomerV2Query(
    {
      customerId: props.additionalCustomer.customerId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !props.additionalCustomer.customerId,
    }
  );

  const contractInformation = customerData?.contractInformation.find(
    (contractInformation) =>
      contractInformation.id === props.additionalCustomer.contractInformationId
  );

  const person: PersonResponseBody | null = contractInformation?.person
    ? {
        id: contractInformation.person.id,
        personData: {
          firstName: contractInformation.person.firstName,
          lastName: contractInformation.person.lastName,
          middleName: contractInformation.person.middleName,
          titleBefore: contractInformation.person.titleBefore,
          titleAfter: contractInformation.person.titleAfter,
          genderKey: contractInformation.person.genderKey,
          roles: contractInformation.person.roles,
          citizenshipCode: contractInformation.person.citizenshipCode,
          birthdate: contractInformation.person.birthdate,
          personalIdentifier: contractInformation.person.personalIdentifier,
        },
        phoneNumbers: contractInformation.person.phoneNumbers.map((phoneNumber) => ({
          type: phoneNumber.type,
          phoneNumber: {
            countryCode: phoneNumber.countryCode,
            prefix: phoneNumber.prefix,
            number: phoneNumber.number,
          },
        })),
        emails: contractInformation.person.emails,
        identityCards: contractInformation.person.identityCards.map((identityCard) => ({
          id: identityCard.id,
          identityCardData: {
            type: identityCard.type,
            cardNumber: identityCard.cardNumber,
            issuedOn: identityCard.issuedOn,
            validUntil: identityCard.validUntil,
            issuer: identityCard.issuer,
            issuedInCountryCode: identityCard.issuedInCountryCode,
            note: identityCard.note,
          },
        })),
        permanentAddress: contractInformation.person.permanentAddress
          ? {
              id: contractInformation.person.permanentAddress.id,
              addressData: {
                city: contractInformation.person.permanentAddress.address.city,
                postalCode: contractInformation.person.permanentAddress.address.zip,
                countryCode: contractInformation.person.permanentAddress.address.country,
                addressLine1: contractInformation.person.permanentAddress.address
                  ? composeAddressLineFromCommonAddress(
                      contractInformation.person.permanentAddress.address
                    )
                  : null,
                addressLine2: null,
                type: contractInformation.person.permanentAddress.type,
                province: null,
                invalid: contractInformation.person.permanentAddress.invalid,
              },
            }
          : null,
      }
    : null;

  const businessInfo: BusinessInfoResponseBody | null = contractInformation?.businessInfo
    ? {
        id: contractInformation.businessInfo.id,
        businessInfoData: {
          countryOfRegistrationCode:
            contractInformation.businessInfo.businessInfo.countryOfRegistrationCode ?? null,
          fileNumber: contractInformation.businessInfo.businessInfo.fileNumber ?? null,
          registrationNumber:
            contractInformation.businessInfo.businessInfo.registrationNumber ?? null,
          tradeName: contractInformation.businessInfo.businessInfo.tradeName ?? null,
          vatNumber: contractInformation.businessInfo.businessInfo.vatNumber ?? null,
        },
        businessAddress: contractInformation.businessInfo?.address
          ? {
              id: contractInformation.businessInfo.address.id,
              addressData: {
                addressLine1: contractInformation.businessInfo.address.address
                  ? composeAddressLineFromCommonAddress(
                      contractInformation.businessInfo.address.address
                    )
                  : null,
                addressLine2: null,
                city: contractInformation.businessInfo.address.address.city,
                postalCode: contractInformation.businessInfo.address.address.zip,
                province: null,
                countryCode: contractInformation.businessInfo.address.address.country,
                type: contractInformation.businessInfo.address.type,
                invalid: contractInformation.businessInfo.address.invalid,
              },
            }
          : null,
      }
    : null;

  return (
    <Card variant="inlineWhite">
      <VStack spacing={4}>
        <Heading size={4}>
          {i18n.t('entity.accounting.labels.nthCustomerOnInvoice', {
            n: props.index + props.counterStartAt,
          })}
        </Heading>
        <DataStatus isEmpty={isNilOrEmpty(customerData)} isLoading={isLoading} isError={isError}>
          <CheckoutContractInfo person={person} businessInfo={businessInfo} isAdditionalCustomer />
        </DataStatus>
      </VStack>
    </Card>
  );
}
