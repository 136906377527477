import {TestIdProps} from 'shared';

import {ButtonProps} from '../Button/Button';
import {Status} from '../Status/Status';
import {useTranslationContext} from '../TranslationProvider/TranslationContext';

export type EmptyStatusAction = Pick<
  ButtonProps,
  'onClick' | 'title' | 'isDisabled' | 'leftIcon' | 'variant'
>;

export interface EmptyStatusProps extends TestIdProps {
  headline?: string;
  subheadline?: string;
  variant?: 'general' | 'table' | 'image' | 'message' | 'person';
  action?: EmptyStatusAction;
  isCompact?: boolean;
}

export function EmptyStatus(props: EmptyStatusProps) {
  const t = useTranslationContext();

  return (
    <Status
      icon={icons[props.variant ?? 'general']}
      headline={props.headline ?? t(headlines[props.variant ?? 'general'])}
      subheadline={props.subheadline}
      action={props.action}
      isCompact={props.isCompact}
      data-testid={props['data-testid']}
    />
  );
}

const icons = {
  image: 'custom/no_images',
  message: 'custom/no_messages',
  person: 'custom/no_person',
  table: 'custom/no_table_data',
  general: 'custom/no_data',
} as const;

const headlines = {
  general: 'general.labels.noData',
  table: 'general.labels.noTableData',
  image: 'general.labels.noImages',
  message: 'general.labels.noMessages',
  person: 'general.labels.noPerson',
} as const;
