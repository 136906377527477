import {jwtDecode} from 'jwt-decode';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {browserStorageKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import {FlagSmithJWT, selectCurrentUserInfo} from '@omnetic-dms/shared';

import {useFlagsmith} from 'shared';

export function useInitFlagsmith() {
  const {data: userInfo} = useSelector(selectCurrentUserInfo);

  const {isFlagSmithInitialized, handleInitFlagsmith} = useFlagsmith({
    environmentID: environment.FLAGSMITH_ENV,
    apiUrl: environment.FLAGSMITH_URL,
    skip: true,
  });

  useEffect(() => {
    const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);

    if (!userInfo || !accessToken || !environment.FLAGSMITH_ENV) {
      return;
    }

    const decodedToken = jwtDecode<FlagSmithJWT>(accessToken);
    const workspace = decodedToken?.wsp ?? 'no-workspace';
    const email = userInfo.email;

    handleInitFlagsmith(`${email}__${workspace}`, {workspace, email});
  }, [userInfo, handleInitFlagsmith]);

  return isFlagSmithInitialized;
}
