import {Button, showNotification} from 'platform/components';
import {
  convertToSearchParams,
  getFormattedSourceSearchParams,
  getSourceSearchParams,
  RowControlWrapper,
} from 'platform/datagrid';
import {Heading, Hide, HStack, Show, Space} from 'platform/foundation';
import {match} from 'ts-pattern';

import {generatePath, useLocation, useMatch, useNavigate} from 'react-router-dom';

import {always} from 'ramda';
import {isNotNilOrEmpty, isNotString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {sourcingRoutes} from '@omnetic-dms/routes';
import {queryParams, useNavigationBreadCrumb} from '@omnetic-dms/shared';
import {Navigation} from '@omnetic-dms/teas';

import {composePath, RequiredTestIdProps, suffixTestId} from 'shared';

const EMPTY_TITLE = '';
interface HeaderContent extends RequiredTestIdProps {
  title?: string;
}

export function HeaderContent(props: HeaderContent) {
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbProps, isBreadcrumbVisible] = useNavigationBreadCrumb();
  const rowControlDetailMatch = useMatch(breadcrumbProps?.rowControlDetail ?? '');

  if (location.pathname.includes(sourcingRoutes.home)) {
    return <Navigation />;
  }

  const onBreadCrumbClick = () => {
    if (!breadcrumbProps) {
      showNotification.error();
      return;
    }

    if (isNotNilOrEmpty(getSourceSearchParams())) {
      const sourceGridCode = getFormattedSourceSearchParams().gridCode;

      const vehicleTab = match(sourceGridCode)
        .with('vehicles-in-advertisement', always('/in-advertisement'))
        .with('vehicle_in_warehouse_history', always('/warehouses'))
        .with('vehicle-opensearch', always('/vehicle-opensearch'))
        .otherwise(always(''));

      const newSearchParams = convertToSearchParams(
        {
          [getFormattedSourceSearchParams().gridCode]: getFormattedSourceSearchParams(),
        },
        true
      );

      navigate(
        composePath(breadcrumbProps?.navigateTo + vehicleTab, {
          queryParams: newSearchParams,
          isSearchParamsPreserved: false,
        })
      );

      return;
    }

    navigate(breadcrumbProps?.navigateTo);
  };

  const title: string = props.title ? i18n.t(props.title) : EMPTY_TITLE;

  const handleChange = (newEntity: string, sourceSearchParams: string) => {
    if (isNotString(breadcrumbProps?.rowControlDetail)) {
      throw Error('You need to specify rowControlDetail');
    }

    const URLSourceSearchParams = new URLSearchParams(sourceSearchParams);
    const locationSource = new URLSearchParams(location.search).get(queryParams.REDIRECT_SOURCE);
    if (locationSource) {
      URLSourceSearchParams.append(queryParams.REDIRECT_SOURCE, locationSource);
    }

    navigate(
      generatePath(breadcrumbProps?.rowControlDetail as string, {
        ...rowControlDetailMatch?.params,
        id: newEntity,
      }) + `?${URLSourceSearchParams.toString()}`
    );
  };

  return (
    <HStack data-testid="layout-headerTitle">
      <Hide when={isBreadcrumbVisible}>
        <Heading headingLevel={2} size={4}>
          {title}
        </Heading>
      </Hide>
      <Show when={isBreadcrumbVisible}>
        <Button
          leftIcon="navigation/arrow_back"
          title={breadcrumbProps?.title}
          variant="link"
          onClick={onBreadCrumbClick}
          data-testid={suffixTestId('back-button', props)}
        />
      </Show>
      <Space horizontal={5} />
      <Show when={rowControlDetailMatch}>
        <RowControlWrapper onChange={handleChange} />
      </Show>
    </HStack>
  );
}
