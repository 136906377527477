import {isNotEmpty} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {MakeFieldDefinitionResponseBody} from '@omnetic-dms/shared';

export const getValueTypeLabel = (definition: MakeFieldDefinitionResponseBody) => {
  if (definition.valueType === 'string' && isNotEmpty(definition.allowedValues)) {
    return `${i18n.t(`page.generalSettings.labels.string`)} (${i18n.t(`page.generalSettings.labels.enum`)})`;
  }

  return i18n.t(`page.generalSettings.labels.${definition.valueType}`);
};
