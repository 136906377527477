import {Card, Attributes, AttributesRow} from 'platform/components';

import {suffixTestId, TestIdProps} from 'shared';

import i18n from '../../../i18n/i18n';

interface VehicleInfoCardProps extends TestIdProps {
  title: string;
  onEditClick: () => void;
  parameters: AttributesRow[];
  isEditingDisabled?: boolean;
}

export function VehicleInfoCard(props: VehicleInfoCardProps) {
  return (
    <Card
      title={props.title}
      actions={[
        {
          type: 'button',
          variant: 'link',
          leftIcon: 'image/edit',
          onClick: props.onEditClick,
          isDisabled: props.isEditingDisabled,
          title: i18n.t('general.actions.edit'),
        },
      ]}
      data-testid={suffixTestId('header', props)}
    >
      <Attributes data-testid={suffixTestId('parameters', props)} rows={props.parameters} />
    </Card>
  );
}
