import {createSelector} from '@reduxjs/toolkit';

import type {TeasState} from '../../types/TeasState';
import {DataViewContainerName} from './DataViewState';

export const createDataViewSelectors = (name: DataViewContainerName) => {
  const selectDataViewSlice = (state: TeasState) => state.dataview[name];

  const selectDataView = createSelector(selectDataViewSlice, (state) => state);

  const selectOrderById = createSelector(selectDataView, (dataview) => dataview.orderById);

  const selectLastVisitedVehicleId = createSelector(
    selectDataView,
    (dataview) => dataview.lastVisitedVehicleId
  );

  return {
    selectDataView,
    selectOrderById,
    selectLastVisitedVehicleId,
  };
};
