import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {indexBy, isNil, map, prop} from 'ramda';

import {ApiException} from '@omnetic-dms/shared';

import {
  ChangeCredentialsActionsType,
  UpdatePlatformType,
  AdvertisementSettingsSliceType,
  PlatformInformationResponse,
  PlatformInformation,
  EPlatformStatusAction,
} from '../../types/AdvertisementSettings';
import {EditTipCarsClientDataRequestBody} from '../../types/EditTipCarsClientDataRequestBody';
import {FetchAdPlatformPayloadType} from '../../types/GeneralSettings';
import {Platform} from '../../types/Platform';

const STATE_NAME = 'generalSettings/AdvertisementSettings';

const initialState: AdvertisementSettingsSliceType = {
  platforms: {
    list: {},
    order: [],
    loading: false,
  },
  tipCarsClientData: {
    loading: false,
  },
  updatingTipCarsClientData: {
    updating: false,
  },
  detailedPlatforms: {},
};

const advertisementSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    requestUpdateTipCarsClientData(
      state,
      _action: PayloadAction<{clientData: EditTipCarsClientDataRequestBody; branchId?: string}>
    ) {
      state.updatingTipCarsClientData.updating = true;
    },
    updatedTipCarsClientData(state) {
      state.updatingTipCarsClientData.updating = false;
    },
    requestTipCarsClientData(state, _action: PayloadAction<{branchId?: string}>) {
      state.tipCarsClientData.loading = true;
    },
    fetchTipCarsClientData(state, {payload}: PayloadAction<EditTipCarsClientDataRequestBody>) {
      state.tipCarsClientData.data = payload;
      state.tipCarsClientData.loading = false;
    },
    fetchAdSettingsRequest(state, _action: PayloadAction<{branchId?: string}>) {
      state.platforms.loading = true;
    },
    fetchAdSettingsSuccess(state, {payload}: PayloadAction<Platform[]>) {
      const newList = indexBy(prop('code'), payload);
      const newOrder = map((order) => order.code, payload);

      state.platforms.loading = false;
      state.platforms.list = newList;
      state.platforms.order = newOrder;
    },
    updatePlatformSuccess(
      state,
      {payload: {platformCode, action}}: PayloadAction<UpdatePlatformType>
    ) {
      state.platforms.list[platformCode].enabled = Boolean(action === EPlatformStatusAction.ENABLE);
    },
    changeCredentialsRequest(state, {payload}: PayloadAction<ChangeCredentialsActionsType>) {
      const {platformCode} = payload;
      const detailedPlatform = state.detailedPlatforms[platformCode];

      if (!isNil(detailedPlatform)) {
        detailedPlatform.success = null;
        detailedPlatform.error = undefined;
      }
    },
    changeCredentialsSuccess(state, {payload}: PayloadAction<PlatformInformationResponse>) {
      const {data, code} = payload;
      const detailedPlatform = state.detailedPlatforms[code];

      if (!isNil(detailedPlatform)) {
        detailedPlatform.success = data;
        detailedPlatform.error = undefined;
      }
    },
    changeCredentialsError(
      state,
      {payload}: PayloadAction<AxiosError<ApiException> & Pick<PlatformInformationResponse, 'code'>>
    ) {
      const {response, code} = payload;
      const detailedPlatform = state.detailedPlatforms[code];

      if (!isNil(detailedPlatform) && response?.data?.errors?.[0]) {
        detailedPlatform.error = {...response.data.errors[0], name: 'error'};
      }
    },
    fetchSpecificPlatformRequest(state, _action: PayloadAction<FetchAdPlatformPayloadType>) {
      state.platforms.loading = true;
    },
    fetchSpecificPlatformSuccess(state, {payload}: PayloadAction<PlatformInformation>) {
      const {code} = payload;
      state.detailedPlatforms[code] = payload;
    },
    fetchSpecificPlatformError(
      state,
      _action: PayloadAction<AxiosError & FetchAdPlatformPayloadType>
    ) {
      state.platforms.loading = false;
    },
  },
});

const {reducer, actions} = advertisementSlice;

export const updatePlatformRequest = createAction<UpdatePlatformType>(
  `${STATE_NAME}/updatePlatformRequest`
);

export const {
  fetchAdSettingsRequest,
  fetchAdSettingsSuccess,
  updatePlatformSuccess,
  changeCredentialsRequest,
  changeCredentialsSuccess,
  changeCredentialsError,

  fetchSpecificPlatformRequest,
  fetchSpecificPlatformSuccess,
  fetchSpecificPlatformError,

  requestTipCarsClientData,
  fetchTipCarsClientData,
  requestUpdateTipCarsClientData,
  updatedTipCarsClientData,
} = actions;

export const AdvertisementSettingsReducer = reducer;
