import {
  PostCustomerContractApiResponse,
  PostCustomerContractApiArg,
  GetCustomerContractByIdApiResponse,
  GetCustomerContractByIdApiArg,
  PatchCustomerContractApiResponse,
  PatchCustomerContractApiArg,
  DeleteCustomerContractApiArg,
  DeleteCustomerContractApiResponse,
  DeleteCustomerContractLabourDiscountApiArg,
  DeleteCustomerContractLabourDiscountApiResponse,
  GetCustomerContractLabourDiscountApiArg,
  GetCustomerContractLabourDiscountApiResponse,
  PatchCustomerContractLabourDiscountApiArg,
  PatchCustomerContractLabourDiscountApiResponse,
  PostCustomerContractLabourDiscountApiArg,
  PostCustomerContractLabourDiscountApiResponse,
  DeleteCustomerContractMaterialDiscountApiArg,
  DeleteCustomerContractMaterialDiscountApiResponse,
  GetCustomerContractMaterialDiscountApiArg,
  GetCustomerContractMaterialDiscountApiResponse,
  PatchCustomerContractMaterialDiscountApiArg,
  PatchCustomerContractMaterialDiscountApiResponse,
  PostCustomerContractMaterialDiscountApiArg,
  PostCustomerContractMaterialDiscountApiResponse,
  DeleteCustomerContractIndividualPriceLabourApiArg,
  DeleteCustomerContractIndividualPriceLabourApiResponse,
  DeleteCustomerContractIndividualPriceMaterialApiArg,
  DeleteCustomerContractIndividualPriceMaterialApiResponse,
  GetCustomerContractIndividualPriceLabourApiArg,
  GetCustomerContractIndividualPriceLabourApiResponse,
  GetCustomerContractIndividualPriceMaterialApiArg,
  GetCustomerContractIndividualPriceMaterialApiResponse,
  PatchCustomerContractIndividualPriceLabourApiArg,
  PatchCustomerContractIndividualPriceLabourApiResponse,
  PatchCustomerContractIndividualPriceMaterialApiArg,
  PatchCustomerContractIndividualPriceMaterialApiResponse,
  PostCustomerContractIndividualPriceLabourApiArg,
  PostCustomerContractIndividualPriceLabourApiResponse,
  PostCustomerContractIndividualPriceMaterialApiArg,
  PostCustomerContractIndividualPriceMaterialApiResponse,
  GetCustomerContractAssignedCustomerApiArg,
  GetCustomerContractAssignedCustomerApiResponse,
  GetCustomerContractAssignedCustomerValidityApiArg,
  GetCustomerContractAssignedCustomerValidityApiResponse,
  PatchCustomerContractAssignedCustomerApiArg,
  PatchCustomerContractAssignedCustomerApiResponse,
  PatchCustomerContractAssignedCustomerValidityApiArg,
  PatchCustomerContractAssignedCustomerValidityApiResponse,
  PostCustomerContractAssignedCustomerApiArg,
  PostCustomerContractAssignedCustomerApiResponse,
  DeleteCustomerContractAssignedCustomerApiArg,
  DeleteCustomerContractAssignedCustomerApiResponse,
  GetCustomerContractBasicInformationApiResponse,
  GetCustomerContractBasicInformationApiArg,
  GetCustomerContractIndividualPricesApiArg,
  GetCustomerContractIndividualPricesApiResponse,
  GetCustomerContractLabourDiscountsApiArg,
  GetCustomerContractLabourDiscountsApiResponse,
  GetCustomerContractMaterialDiscountsApiArg,
  GetCustomerContractMaterialDiscountsApiResponse,
  GetCustomerContractsByCustomerIdApiArg,
  GetCustomerContractsByCustomerIdApiResponse,
  PutCustomerContractSetDefaultApiArg,
  PutCustomerContractSetDefaultApiResponse,
  PostCustomerContractSetActiveApiResponse,
  PostCustomerContractSetActiveApiArg,
  PostCustomerContractSetInActiveApiResponse,
  PostCustomerContractSetInActiveApiArg,
} from '../types/api/metadaCustomerContract';
import {metadaApi} from './baseApi/metadaApi';

export const metadaCustomerContractApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postCustomerContract: build.mutation<
      PostCustomerContractApiResponse,
      PostCustomerContractApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getCustomerContractById: build.query<
      GetCustomerContractByIdApiResponse,
      GetCustomerContractByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerContract', id: queryArg.customerContractId},
      ],
    }),
    patchCustomerContract: build.mutation<
      PatchCustomerContractApiResponse,
      PatchCustomerContractApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContract', id: queryArg.customerContractId},
      ],
    }),
    deleteCustomerContract: build.mutation<
      DeleteCustomerContractApiResponse,
      DeleteCustomerContractApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}`,
        method: 'DELETE',
      }),
    }),
    postCustomerContractLabourDiscount: build.mutation<
      PostCustomerContractLabourDiscountApiResponse,
      PostCustomerContractLabourDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/labour-discount`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getCustomerContractLabourDiscount: build.query<
      GetCustomerContractLabourDiscountApiResponse,
      GetCustomerContractLabourDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/labour-discount/${queryArg.labourDiscountId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerContractLabourDiscount', id: queryArg.labourDiscountId},
      ],
    }),
    deleteCustomerContractLabourDiscount: build.mutation<
      DeleteCustomerContractLabourDiscountApiResponse,
      DeleteCustomerContractLabourDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/labour-discount/${queryArg.labourDiscountId}`,
        method: 'DELETE',
      }),
    }),
    patchCustomerContractLabourDiscount: build.mutation<
      PatchCustomerContractLabourDiscountApiResponse,
      PatchCustomerContractLabourDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/labour-discount/${queryArg.labourDiscountId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContractLabourDiscount', id: queryArg.labourDiscountId},
      ],
    }),
    postCustomerContractMaterialDiscount: build.mutation<
      PostCustomerContractMaterialDiscountApiResponse,
      PostCustomerContractMaterialDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/material-discount`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getCustomerContractMaterialDiscount: build.query<
      GetCustomerContractMaterialDiscountApiResponse,
      GetCustomerContractMaterialDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/material-discount/${queryArg.materialDiscountId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerContractMaterialDiscount', id: queryArg.materialDiscountId},
      ],
    }),
    deleteCustomerContractMaterialDiscount: build.mutation<
      DeleteCustomerContractMaterialDiscountApiResponse,
      DeleteCustomerContractMaterialDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/material-discount/${queryArg.materialDiscountId}`,
        method: 'DELETE',
      }),
    }),
    patchCustomerContractMaterialDiscount: build.mutation<
      PatchCustomerContractMaterialDiscountApiResponse,
      PatchCustomerContractMaterialDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/material-discount/${queryArg.materialDiscountId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContractMaterialDiscount', id: queryArg.materialDiscountId},
      ],
    }),
    postCustomerContractIndividualPriceLabour: build.mutation<
      PostCustomerContractIndividualPriceLabourApiResponse,
      PostCustomerContractIndividualPriceLabourApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-labour`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getCustomerContractIndividualPriceLabour: build.query<
      GetCustomerContractIndividualPriceLabourApiResponse,
      GetCustomerContractIndividualPriceLabourApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-labour/${queryArg.individualPriceLabourId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invidualPriceLabour', id: queryArg.individualPriceLabourId},
      ],
    }),
    deleteCustomerContractIndividualPriceLabour: build.mutation<
      DeleteCustomerContractIndividualPriceLabourApiResponse,
      DeleteCustomerContractIndividualPriceLabourApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-labour/${queryArg.individualPriceLabourId}`,
        method: 'DELETE',
      }),
    }),
    patchCustomerContractIndividualPriceLabour: build.mutation<
      PatchCustomerContractIndividualPriceLabourApiResponse,
      PatchCustomerContractIndividualPriceLabourApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-labour/${queryArg.individualPriceLabourId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invidualPriceLabour', id: queryArg.individualPriceLabourId},
      ],
    }),
    postCustomerContractIndividualPriceMaterial: build.mutation<
      PostCustomerContractIndividualPriceMaterialApiResponse,
      PostCustomerContractIndividualPriceMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-material`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getCustomerContractIndividualPriceMaterial: build.query<
      GetCustomerContractIndividualPriceMaterialApiResponse,
      GetCustomerContractIndividualPriceMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-material/${queryArg.individualPriceMaterialId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invidualPriceMaterial', id: queryArg.individualPriceMaterialId},
      ],
    }),
    deleteCustomerContractIndividualPriceMaterial: build.mutation<
      DeleteCustomerContractIndividualPriceMaterialApiResponse,
      DeleteCustomerContractIndividualPriceMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-material/${queryArg.individualPriceMaterialId}`,
        method: 'DELETE',
      }),
    }),
    patchCustomerContractIndividualPriceMaterial: build.mutation<
      PatchCustomerContractIndividualPriceMaterialApiResponse,
      PatchCustomerContractIndividualPriceMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-contract/${queryArg.customerContractId}/individual-price-material/${queryArg.individualPriceMaterialId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invidualPriceMaterial', id: queryArg.individualPriceMaterialId},
      ],
    }),
    postCustomerContractAssignedCustomer: build.mutation<
      PostCustomerContractAssignedCustomerApiResponse,
      PostCustomerContractAssignedCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/assign-customer/${queryArg.customerId}`,
        method: 'POST',
      }),
    }),
    getCustomerContractAssignedCustomer: build.query<
      GetCustomerContractAssignedCustomerApiResponse,
      GetCustomerContractAssignedCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/assigned-customer`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerContractAssignedCustomer', id: queryArg.customerContractId},
      ],
    }),
    patchCustomerContractAssignedCustomer: build.mutation<
      PatchCustomerContractAssignedCustomerApiResponse,
      PatchCustomerContractAssignedCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/assigned-customer`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContractAssignedCustomer', id: queryArg.customerContractId},
      ],
    }),
    getCustomerContractAssignedCustomerValidity: build.query<
      GetCustomerContractAssignedCustomerValidityApiResponse,
      GetCustomerContractAssignedCustomerValidityApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/assigned-customer/${queryArg.customerId}/validity`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerContractAssignedCustomerValidity', id: queryArg.customerId},
      ],
    }),
    patchCustomerContractAssignedCustomerValidity: build.mutation<
      PatchCustomerContractAssignedCustomerValidityApiResponse,
      PatchCustomerContractAssignedCustomerValidityApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/assigned-customer/${queryArg.customerId}/validity`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContractAssignedCustomerValidity', id: queryArg.customerId},
      ],
    }),
    deleteCustomerContractAssignedCustomer: build.mutation<
      DeleteCustomerContractAssignedCustomerApiResponse,
      DeleteCustomerContractAssignedCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/assigned-customer/${queryArg.customerId}`,
        method: 'DELETE',
      }),
    }),
    getCustomerContractBasicInformation: build.query<
      GetCustomerContractBasicInformationApiResponse,
      GetCustomerContractBasicInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/basic-information`,
      }),
    }),
    getCustomerContractIndividualPrices: build.query<
      GetCustomerContractIndividualPricesApiResponse,
      GetCustomerContractIndividualPricesApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/individual-prices`,
      }),
    }),
    getCustomerContractLabourDiscounts: build.query<
      GetCustomerContractLabourDiscountsApiResponse,
      GetCustomerContractLabourDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/labour-discounts`,
      }),
    }),
    getCustomerContractMaterialDiscounts: build.query<
      GetCustomerContractMaterialDiscountsApiResponse,
      GetCustomerContractMaterialDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/material-discounts`,
      }),
    }),
    getCustomerContractsByCustomerId: build.query<
      GetCustomerContractsByCustomerIdApiResponse,
      GetCustomerContractsByCustomerIdApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract`,
        params: {customerId: queryArg.customerId},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerCustomerContracts', id: queryArg.customerId},
      ],
    }),
    putCustomerContractSetDefault: build.mutation<
      PutCustomerContractSetDefaultApiResponse,
      PutCustomerContractSetDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer/${queryArg.customerId}/set-default/customer-contract/${queryArg.customerContractId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerCustomerContracts', id: queryArg.customerId},
      ],
    }),
    postCustomerContractSetActive: build.mutation<
      PostCustomerContractSetActiveApiResponse,
      PostCustomerContractSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/set-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContract', id: queryArg.customerContractId},
      ],
    }),
    postCustomerContractSetInActive: build.mutation<
      PostCustomerContractSetInActiveApiResponse,
      PostCustomerContractSetInActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/customer-contract/${queryArg.customerContractId}/set-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContract', id: queryArg.customerContractId},
      ],
    }),
  }),
});

export const {
  useGetCustomerContractByIdQuery,
  usePostCustomerContractMutation,
  usePatchCustomerContractMutation,
  useDeleteCustomerContractMutation,
  useGetCustomerContractLabourDiscountQuery,
  usePostCustomerContractLabourDiscountMutation,
  usePatchCustomerContractLabourDiscountMutation,
  useDeleteCustomerContractLabourDiscountMutation,
  usePostCustomerContractMaterialDiscountMutation,
  useGetCustomerContractMaterialDiscountQuery,
  usePatchCustomerContractMaterialDiscountMutation,
  useDeleteCustomerContractMaterialDiscountMutation,
  useGetCustomerContractIndividualPriceMaterialQuery,
  usePostCustomerContractIndividualPriceMaterialMutation,
  usePatchCustomerContractIndividualPriceMaterialMutation,
  useDeleteCustomerContractIndividualPriceMaterialMutation,
  useGetCustomerContractIndividualPriceLabourQuery,
  usePostCustomerContractIndividualPriceLabourMutation,
  usePatchCustomerContractIndividualPriceLabourMutation,
  useDeleteCustomerContractIndividualPriceLabourMutation,
  usePostCustomerContractAssignedCustomerMutation,
  usePatchCustomerContractAssignedCustomerMutation,
  useGetCustomerContractAssignedCustomerQuery,
  useGetCustomerContractAssignedCustomerValidityQuery,
  usePatchCustomerContractAssignedCustomerValidityMutation,
  useDeleteCustomerContractAssignedCustomerMutation,
  useGetCustomerContractBasicInformationQuery,
  useLazyGetCustomerContractBasicInformationQuery,
  useGetCustomerContractIndividualPricesQuery,
  useGetCustomerContractLabourDiscountsQuery,
  useGetCustomerContractMaterialDiscountsQuery,
  useGetCustomerContractsByCustomerIdQuery,
  usePutCustomerContractSetDefaultMutation,
  usePostCustomerContractSetActiveMutation,
  usePostCustomerContractSetInActiveMutation,
} = metadaCustomerContractApi;
