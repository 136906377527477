import {Card, DataStatus, Table, TableRow} from 'platform/components';
import {Align, Box, Show, Text, VStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, TestIdProps} from 'shared';

import {useGetCustomerContractIndividualPricesQuery} from '../../../api/metadaCustomerContractApi';
import i18n from '../../../i18n/i18n';
import {useGetDiscountValue} from '../hooks/useGetDiscountValue';

interface ContractIndividualPricesTabProps extends TestIdProps {
  customerContractId: string;
}

export function ContractIndividualPricesTab(props: ContractIndividualPricesTabProps) {
  const getDiscountValue = useGetDiscountValue();

  const {data, isLoading, isError} = useGetCustomerContractIndividualPricesQuery({
    customerContractId: props.customerContractId,
  });

  return (
    <DataStatus
      isLoading={isLoading}
      isError={isError}
      isEmpty={
        isNilOrEmpty(data?.individualPriceLabour) && isNilOrEmpty(data?.individualPriceMaterial)
      }
      minHeight={100}
    >
      <VStack spacing={4}>
        <Show when={isNotNilOrEmpty(data?.individualPriceLabour)}>
          <Card title={i18n.t('entity.order.labels.work')}>
            <PriceTable>
              {data?.individualPriceLabour?.map((price) => (
                <TableRow key={price?.id}>
                  <Cell text={price?.name} />
                  <Cell text={price?.positionNumber} />
                  <Cell text={price?.discountSettingsType?.name} />
                  <Cell text={getDiscountValue(price)} isRightAligned />
                </TableRow>
              ))}
            </PriceTable>
          </Card>
        </Show>
        <Show when={isNotNilOrEmpty(data?.individualPriceMaterial)}>
          <Card title={i18n.t('entity.order.labels.material')}>
            <PriceTable>
              {data?.individualPriceMaterial?.map((price) => (
                <TableRow key={price?.id}>
                  <Cell text={price?.name} />
                  <Cell text={price?.manufacturerNumber} />
                  <Cell text={price?.discountSettingsType?.name} />
                  <Cell text={getDiscountValue(price)} isRightAligned />
                </TableRow>
              ))}
            </PriceTable>
          </Card>
        </Show>
      </VStack>
    </DataStatus>
  );
}

const HeaderCell = (props: {text: string; isLast?: boolean}) => (
  <Box
    height={14}
    backgroundColor="palettes.neutral.10.100"
    borderTop="1px solid"
    borderColor="general.separator"
  >
    <VStack justify="center" height="100%">
      <Box
        borderRight={props.isLast ? undefined : '1px solid'}
        borderColor="general.separator"
        paddingHorizontal={4}
      >
        <Align right={props.isLast}>
          <Text alternative color="secondary" size="small">
            {props.text}
          </Text>
        </Align>
      </Box>
    </VStack>
  </Box>
);

const Cell = (props: {text: string | Nullish; isRightAligned?: boolean}) => (
  <Box padding={4} height={10}>
    <VStack justify="center" height="100%">
      <Align right={props.isRightAligned}>
        <Text color="secondary" size="small">
          {props.text}
        </Text>
      </Align>
    </VStack>
  </Box>
);

const PriceTable = (props: {children: ReactNode}) => (
  <Table
    tableLayout="fixed"
    columns={[
      {
        element: <HeaderCell text={i18n.t('general.labels.name')} />,
      },
      {
        element: <HeaderCell text={i18n.t('entity.customerContract.labels.positionNumber')} />,
      },
      {
        element: <HeaderCell text={i18n.t('entity.customerContract.labels.discountType')} />,
      },
      {
        element: (
          <HeaderCell text={i18n.t('entity.customerContract.labels.discountValue')} isLast />
        ),
        width: 49,
      },
    ]}
  >
    {props.children}
  </Table>
);
