import {useSubscription} from '@apollo/client';
import {showNotification} from 'platform/components';

import {isNotNil} from 'ramda-adjunct';

import {SEND_NOTIFICATION_SUBSCRIPTION} from '../api/graphql/queries';
import {useListSentToastNotificationsQuery} from '../api/messagingApi';
import i18n from '../i18n/i18n';

export function useNotifications(pageSize: number) {
  const {data, refetch, isLoading, isFetching, isError} = useListSentToastNotificationsQuery(
    {
      limit: pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data}) => {
      const isToastNotification = isNotNil(data.data.onSendNotification.toastNotificationId);

      if (isToastNotification) {
        refetch();
        showNotification.info(i18n.t('general.labels.newNotificationReceived'));
      }
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: false,
  });

  const hasUnreadNotifications = data?.notifications?.some((notification) => !notification.isRead);

  return {
    data,
    isLoading,
    isFetching,
    isError,
    hasUnreadNotifications,
  };
}
