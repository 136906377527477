import {Button} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {handleApiError, useInspectionToInProgressMutation} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

interface ReopenInspectionProps {
  inspectionId: string;
  handleBackButton: () => void;
}

export function ReopenInspection({
  inspectionId,
  handleBackButton,
  'data-testid': dataTestId,
}: ReopenInspectionProps & TestIdProps) {
  const [reopenInspection, reopenInspectionResponse] = useInspectionToInProgressMutation();

  const onInspectionReopen = async () => {
    try {
      await reopenInspection({inspectionId}).unwrap();
      handleBackButton();
    } catch (e: any) {
      handleApiError(e);
    }
  };
  return (
    <Button
      data-testid={suffixTestId('inspectionHeader', {'data-testid': dataTestId})}
      onClick={onInspectionReopen}
      isLoading={reopenInspectionResponse.isLoading}
      isDisabled={reopenInspectionResponse.isLoading}
      title={i18n.t('general.actions.reopen')}
    />
  );
}
