import {Card, DataStatus, DropdownActionMenuItem, EmptyStatus} from 'platform/components';
import {Box, Center, Hide, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {find, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {buildArray, suffixTestId, noop, TestIdProps, useRequiredParams} from 'shared';

import {useGetCustomerV2Query} from '../../../api/customerApi';
import {useGetVehicleCustomerQuery} from '../../../api/metadaVehicleCustomer';
import i18n from '../../../i18n/i18n';
import {EntityResourceIds} from '../../../types/api/api';
import {CustomerResponseBodyV2} from '../../../types/api/customer';
import {OwnershipType} from '../../CustomerVehicleSelection/types/ownershipType';
import {CustomerWidgetCard} from '../../CustomerWidgetCard/CustomerWidgetCard';
import {getOwnershipTitle} from '../utils/getOwnershipTitle';

interface OwnershipCustomerCardProps extends TestIdProps {
  ownershipType: OwnershipType;
  vehicleId: string;
  onDelete?: () => void;
  onChange: () => void;
  onCopy?: (customer: CustomerResponseBodyV2) => void;
  onMove?: (customer: CustomerResponseBodyV2) => void;
}

export function OwnershipCustomerCard(props: OwnershipCustomerCardProps) {
  const {id: serviceCaseId} = useRequiredParams();

  const {
    selectedOwnership,
    isSelectedOwnershipLoading,
    isSelectedOwnershipError,
    isSelectedOwnershipFetching,
  } = useGetVehicleCustomerQuery(
    {vehicleId: props.vehicleId},
    {
      skip: !props.vehicleId,
      selectFromResult: ({data, isLoading, isError, isFetching}) => ({
        selectedOwnership: find(
          (ownership) => ownership?.customerType === props.ownershipType,
          data ?? []
        ),
        isSelectedOwnershipLoading: isLoading,
        isSelectedOwnershipError: isError,
        isSelectedOwnershipFetching: isFetching,
      }),
    }
  );
  const {
    currentData: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
    isFetching: isCustomerFetching,
  } = useGetCustomerV2Query(
    {customerId: selectedOwnership?.customerId ?? ''},
    {skip: !selectedOwnership?.customerId}
  );

  const title = getOwnershipTitle(props.ownershipType);

  const emptyMessage = match(props.ownershipType)
    .with('CORVEHICLECUSTOMERTYPE_OPERATOR', () =>
      i18n.t('entity.ownership.label.emptyVehicleOperator')
    )
    .with('CORVEHICLECUSTOMERTYPE_OWNER', () => i18n.t('entity.ownership.label.emptyVehicleOwner'))
    .with('CORVEHICLECUSTOMERTYPE_USER', () => i18n.t('entity.ownership.label.emptyVehicleUser'))
    .with('CORVEHICLECUSTOMERTYPE_MANAGER', () =>
      i18n.t('entity.ownership.label.emptyVehicleManager')
    )
    .with('CORVEHICLECUSTOMERTYPE_INVOICE', () =>
      i18n.t('entity.ownership.label.emptyDefaultRecepient')
    )
    .exhaustive();

  const emptyActionTitle = match(props.ownershipType)
    .with('CORVEHICLECUSTOMERTYPE_OPERATOR', () =>
      i18n.t('entity.ownership.actions.assignVehicleOperator')
    )
    .with('CORVEHICLECUSTOMERTYPE_OWNER', () =>
      i18n.t('entity.ownership.actions.assignVehicleOwner')
    )
    .with('CORVEHICLECUSTOMERTYPE_USER', () => i18n.t('entity.ownership.actions.assignVehicleUser'))
    .with('CORVEHICLECUSTOMERTYPE_MANAGER', () =>
      i18n.t('entity.ownership.actions.assignVehicleManager')
    )
    .with('CORVEHICLECUSTOMERTYPE_INVOICE', () =>
      i18n.t('entity.ownership.label.assignDefaultRecepient')
    )
    .exhaustive();

  const tooltip = match(props.ownershipType)
    .with('CORVEHICLECUSTOMERTYPE_OPERATOR', () =>
      i18n.t('entity.ownership.label.tooltipVehicleOperator')
    )
    .with('CORVEHICLECUSTOMERTYPE_OWNER', () =>
      i18n.t('entity.ownership.label.tooltipVehicleOwner')
    )
    .with('CORVEHICLECUSTOMERTYPE_USER', () => i18n.t('entity.ownership.label.tooltipVehicleUser'))
    .with('CORVEHICLECUSTOMERTYPE_MANAGER', () =>
      i18n.t('entity.ownership.label.tooltipVehicleManager')
    )
    .with('CORVEHICLECUSTOMERTYPE_INVOICE', () =>
      i18n.t('entity.ownership.label.tooltipDefaultRecepient')
    )
    .exhaustive();

  const isLoading =
    isCustomerLoading ||
    isSelectedOwnershipLoading ||
    isCustomerFetching ||
    isSelectedOwnershipFetching;
  const isError = isCustomerError || isSelectedOwnershipError;

  return (
    <>
      <Show when={customer}>
        <CustomerWidgetCard
          customActions={buildArray<DropdownActionMenuItem>()
            .when(isNotNil(props.onCopy), {
              label: i18n.t('general.actions.copyTo'),
              onClick: () => {
                if (isNil(customer)) {
                  return;
                }
                props.onCopy?.(customer);
              },
            })
            .when(isNotNil(props.onMove), {
              label: i18n.t('general.actions.move'),
              onClick: () => {
                if (isNil(customer)) {
                  return;
                }
                props.onMove?.(customer);
              },
            })
            .when(isNotNil(props.onDelete), {
              label: i18n.t('general.actions.delete'),
              severity: 'danger',
              onClick: props.onDelete ?? noop,
            })}
          customTitle={title}
          customer={customer}
          onCustomer={noop}
          onChange={props.onChange}
          secondStepComponentType="SERVICE_CASE"
          hasVerification
          isDetailLinkVisible
          recordId={serviceCaseId}
          resourceId={EntityResourceIds.serviceCase}
          tooltip={tooltip}
          data-testid={suffixTestId('selectedCustomer', props)}
        />
      </Show>
      <Hide when={customer}>
        <Card tooltip={tooltip} title={title} data-testid={suffixTestId('header', props)}>
          <DataStatus isLoading={isLoading} isError={isError} minHeight={33}>
            <Box minHeight={33}>
              <Center height={33}>
                <EmptyStatus
                  headline={emptyMessage}
                  action={{
                    title: emptyActionTitle,
                    onClick: props.onChange,
                  }}
                  data-testid={suffixTestId('header', props)}
                />
              </Center>
            </Box>
          </DataStatus>
        </Card>
      </Hide>
    </>
  );
}
