import {Dialog} from 'platform/components';
import {ControlledRowControl} from 'platform/datagrid';
import {HStack, Show, Space} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {head, isNotNil} from 'ramda';

import {
  GetDirectSaleLabourItemResponse,
  GetDirectSaleMaterialItemResponse,
  handleApiError,
  useLazyGetDirectSaleLabourItemQuery,
  useLazyGetDirectSaleMaterialItemQuery,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourItemEdit} from './RequestTabLabourItemEdit';
import {RequestTabMaterialItemEdit} from './RequestTabMaterialItemEdit';

export type Item = GetDirectSaleMaterialItemResponse | GetDirectSaleLabourItemResponse;

export type RequestTabEditProps = {
  directSaleId: string;
  itemId: string;
  isEditingDisabled?: boolean;
  authorizationProfileId?: string;
  dataQueryId: string;
  onAfterSubmit: () => void;
  onClose: () => void;
} & RequiredTestIdProps;

export function RequestTabEdit(props: RequestTabEditProps) {
  const [item, setItem] = useState<Item | null>(null);

  const [getDirectSaleMaterialItem] = useLazyGetDirectSaleMaterialItemQuery();
  const [getDirectSaleLabourItem] = useLazyGetDirectSaleLabourItemQuery();

  const isItemLabour = item && 'workType' in item;
  const isItemMaterial = item && 'manufacturerId' in item;

  const onItemIdChanged = async (newItemId: string) => {
    const directSaleMaterialItemPromise = getDirectSaleMaterialItem({
      directSaleId: props.directSaleId,
      itemId: newItemId,
    })
      .unwrap()
      .catch(handleApiError);

    const directSaleLabourItemPromise = getDirectSaleLabourItem({
      directSaleId: props.directSaleId,
      itemId: newItemId,
    })
      .unwrap()
      .catch(handleApiError);

    const responses = await Promise.all([
      directSaleMaterialItemPromise,
      directSaleLabourItemPromise,
    ]);

    const response = head(responses.filter((res) => isNotNil(res)));

    setItem(response as Item);
  };

  useEffect(() => {
    if (props.itemId && props.itemId !== item?.id) {
      onItemIdChanged(props.itemId);
    }
  }, [props.itemId]);

  const itemName = item ? `${item?.name} (${item?.number})` : '';

  return (
    <>
      <Dialog
        title={itemName}
        isOpen={isNotNil(props.itemId)}
        scrollBehavior="inside"
        withAdditionalFooter
        onClose={props.onClose}
        data-testid={props['data-testid']}
      >
        <Show when={isNotNil(props.dataQueryId)}>
          <HStack justify="flex-end">
            <ControlledRowControl
              dataQueryId={props.dataQueryId as string}
              gridCode="direct-sale-item"
              entityId={item?.id as string}
              onPageChange={onItemIdChanged}
            />
          </HStack>
          <Space vertical={4} />
        </Show>
        <Show when={isItemMaterial}>
          <RequestTabMaterialItemEdit
            directSaleId={props.directSaleId}
            item={item as GetDirectSaleMaterialItemResponse}
            isEditingDisabled={props.isEditingDisabled}
            authorizationProfileId={props.authorizationProfileId}
            onAfterSubmit={props.onAfterSubmit}
            onClose={props.onClose}
            data-testid={suffixTestId('dialog.editMaterial', props)}
          />
        </Show>

        <Show when={isItemLabour}>
          <RequestTabLabourItemEdit
            directSaleId={props.directSaleId}
            item={item as GetDirectSaleLabourItemResponse}
            isEditingDisabled={props.isEditingDisabled}
            authorizationProfileId={props.authorizationProfileId}
            onAfterSubmit={props.onAfterSubmit}
            onClose={props.onClose}
            data-testid={suffixTestId('dialog.editLabour', props)}
          />
        </Show>
      </Dialog>
    </>
  );
}
