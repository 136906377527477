import {closeCurrentDialog, FormSubmitHandler, showNotification} from 'platform/components';
import {match, Pattern} from 'ts-pattern';
import {v4 as uuid} from 'uuid';

import {always, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {buildObject, isFeatureEnabled} from 'shared';

import {
  usePayCorrectiveTaxDocumentWithBankTransferMutation,
  usePayCorrectiveTaxDocumentWithCardAndCreateReceiptMutation,
  usePayCorrectiveTaxDocumentWithCardMutation,
  usePayCorrectiveTaxDocumentWithCashAndCreateReceiptMutation,
  usePayCorrectiveTaxDocumentWithCashMutation,
  usePayInvoiceWithBankTransferMutation,
  usePayInvoiceWithCardMutation,
  usePayInvoiceWithCardReceiptMutation,
  usePayInvoiceWithCashMutation,
  usePayInvoiceWithCashReceiptMutation,
} from '../../../api/accountingApi';
import {useShowPaymentPrescriptionQuery} from '../../../api/paymentApi';
import {useGetBranchQuery} from '../../../api/tenantApi';
import {useGetDocumentData} from '../../../hooks/useGetDocumentData';
import {
  PayWithCardAndCreateReceiptApiArg,
  PayWithCardReceiptArg,
} from '../../../types/api/accounting';
import {featureFlags} from '../../../utils/featureFlags';
import {handleApiError} from '../../../utils/handleApiError';
import {InvoicePaymentFormValues} from '../types/InvoicePaymentFormValues';
import {InvoicePaymentModalProps} from '../types/InvoicePaymentModalProps';
import {getInvoiceIdFromProps} from '../utils/getInvoiceIdFromProps';

export const useSubmitInvoicePayment = (props: InvoicePaymentModalProps) => {
  const [payInvoiceWithCash] = usePayInvoiceWithCashMutation();
  const [payInvoiceWithCashAndGenerateReceipt] = usePayInvoiceWithCashReceiptMutation();
  const [payInvoiceWithCard] = usePayInvoiceWithCardMutation();
  const [payInvoiceWithCardAndGenerateReceipt] = usePayInvoiceWithCardReceiptMutation();
  const [payInvoiceWithBankTransfer] = usePayInvoiceWithBankTransferMutation();

  const [payCTDWithCash] = usePayCorrectiveTaxDocumentWithCashMutation();
  const [payCTDWithCashAndGenerateReceipt] =
    usePayCorrectiveTaxDocumentWithCashAndCreateReceiptMutation();
  const [payCTDWithCard] = usePayCorrectiveTaxDocumentWithCardMutation();
  const [payCTDWithCardAndGenerateReceipt] =
    usePayCorrectiveTaxDocumentWithCardAndCreateReceiptMutation();
  const [payCTDWithBankTransfer] = usePayCorrectiveTaxDocumentWithBankTransferMutation();

  const {type, id} = getInvoiceIdFromProps(props);

  const {data: invoice} = useGetDocumentData(type, id);

  const {data: prescription} = useShowPaymentPrescriptionQuery(
    {paymentPrescriptionId: invoice?.paymentPrescriptionId ?? ''},
    {skip: isNilOrEmpty(invoice?.paymentPrescriptionId)}
  );

  const isForeignCurrencyEnabled = isFeatureEnabled(featureFlags.SALES_FOREIGN_CURRENCY);
  const {data: invoiceBranch} = useGetBranchQuery(
    {branchId: invoice?.branchId ?? ''},
    {skip: isNil(invoice?.branchId)}
  );

  const submitInvoicePayment: FormSubmitHandler<InvoicePaymentFormValues> = async (data) => {
    if (isNil(invoice) || isNil(invoiceBranch?.billingInformation) || isNil(prescription)) {
      showNotification.error();
      return;
    }

    const shouldCreateCashReceipt = data.shouldCreateReceipt;
    const isPaymentByBankTransfer = data.paymentType !== 'BANK_TRANSFER';
    const isCorrectiveTaxDocument = type === 'CORRECTIVE_TAX_DOCUMENT';
    const isPaymentInDifferentCurrencies = invoice.currency !== prescription?.price.currency;

    const requestBody = buildObject<PayWithCardReceiptArg & PayWithCardAndCreateReceiptApiArg>()
      .invoiceId(invoice.id, !isCorrectiveTaxDocument)
      .correctiveTaxDocumentId(invoice.id, isCorrectiveTaxDocument)
      .amount({
        amount: data.amount,
        currency: prescription?.price.currency ?? '',
      })
      .paymentDate(data.paymentDate)
      .cashRegisterId(data.cashRegister, isPaymentByBankTransfer)
      .description(null, shouldCreateCashReceipt)
      .cashRegisterDocumentId(uuid(), shouldCreateCashReceipt)
      .billingInformationId(invoiceBranch!.billingInformation.id, shouldCreateCashReceipt)
      .exchangeRateRatio(
        {
          amount: parseInt(data.exchangeRateRatio.amount ?? '1'),
          ratio: data.exchangeRateRatio?.ratio?.toString() ?? '',
          currency: invoice?.currency ?? '',
        },
        isForeignCurrencyEnabled && isPaymentInDifferentCurrencies
      )
      .build();

    const submitAction = match([data.paymentType, shouldCreateCashReceipt, isCorrectiveTaxDocument])
      .with(['BANK_TRANSFER', Pattern.any, true], always(payCTDWithBankTransfer))
      .with(['PAYMENT_CARD', false, true], always(payCTDWithCard))
      .with(['PAYMENT_CARD', true, true], always(payCTDWithCardAndGenerateReceipt))
      .with(['CASH', false, true], always(payCTDWithCash))
      .with(['CASH', true, true], always(payCTDWithCashAndGenerateReceipt))
      .with(['BANK_TRANSFER', Pattern.any, false], always(payInvoiceWithBankTransfer))
      .with(['PAYMENT_CARD', false, false], always(payInvoiceWithCard))
      .with(['PAYMENT_CARD', true, false], always(payInvoiceWithCardAndGenerateReceipt))
      .with(['CASH', false, false], always(payInvoiceWithCash))
      .with(['CASH', true, false], always(payInvoiceWithCashAndGenerateReceipt))
      .otherwise(always(payInvoiceWithCard));

    await submitAction(requestBody)
      .unwrap()
      .then(props.onAfterSubmit)
      .then(closeCurrentDialog)
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  return [submitInvoicePayment] as const;
};
