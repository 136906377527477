import {Button, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';
import {Nullish} from 'utility-types';

import {isNilOrEmpty} from 'ramda-adjunct';

import {browserStorageKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';

import {useGetAvailableLanguagesListQuery} from '../../api/publicApi';
import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import i18n from '../../i18n/i18n';
import {UrlBuilderConfig, urlBuilder} from '../../utils/buildUrl';
import {featureFlags} from '../../utils/featureFlags';
import {getWorkspaceFromUri} from '../../utils/getWorkspaceFromUri';

const {workspace} = getWorkspaceFromUri();

interface DigitalCertificateLinkProps {
  inspectionId: string | Nullish;
  isDisabled?: boolean;
}

const CERTIFICATE_ALLOWED_LANGUAGES = ['en', 'de', 'fr', 'it', 'es', 'pl', 'cs', 'sk'];
const CERTIFICATE_FALLBACK_LANGUAGE = 'en';

export const DigitalCertificateLink = ({inspectionId, isDisabled}: DigitalCertificateLinkProps) => {
  const {data} = useGetAvailableLanguagesListQuery({workspace: workspace as string});
  const [canShareCarAuditData] = usePermissions({permissionKeys: ['carAuditShareLink']});

  const defaultLanguage =
    i18n?.resolvedLanguage || localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE] || data?.default;

  const onShareLink = () => {
    if (isNilOrEmpty(inspectionId)) {
      showNotification.error();
      return;
    }

    const langParameter = CERTIFICATE_ALLOWED_LANGUAGES.includes(defaultLanguage)
      ? defaultLanguage
      : CERTIFICATE_FALLBACK_LANGUAGE;

    const builderConfig: UrlBuilderConfig = {
      workspace,
      protocol: window.location.protocol,
      domain: environment.DIGITAL_CERTIFICATE_DOMAIN,
      route: `/${inspectionId}`,
      searchParam: `?lang=${langParameter}`,
    };

    const digitalCertificateUrl = urlBuilder(builderConfig);

    window.open(digitalCertificateUrl, '_blank')?.focus();
  };

  return (
    <Show
      whenFeatureEnabled={featureFlags.DC_VEHICLE_INSPECTION_SHARE_LINK}
      when={canShareCarAuditData}
    >
      <Button
        isDisabled={isNilOrEmpty(inspectionId) || isDisabled}
        title={i18n.t('page.advertisement.labels.digitalCertificate')}
        variant="outlined"
        leftIcon="communication/call_made"
        onClick={onShareLink}
        data-testid="digital-cert-link"
      />
    </Show>
  );
};
