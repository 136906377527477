import {Alert, Card} from 'platform/components';
import {GridItem, Grid} from 'platform/foundation';

import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {selectBranchList} from '@omnetic-dms/shared';
import {
  Form,
  selectSeries,
  selectInterestSeriesDefinitionDetail,
  InterestSeriesDefinition,
  Series,
  noop,
} from '@omnetic-dms/teas';

function InterestSeriesDefinitionDetail() {
  const definition = useSelector(selectInterestSeriesDefinitionDetail);
  const {data} = useSelector(selectBranchList);
  const branchList = data?.branchListItems;
  const series = useSelector(selectSeries);

  if (!definition) {
    return null;
  }

  return (
    <Card title={definition?.name}>
      <Grid columns={1}>
        <Alert
          type="inline"
          variant="info"
          message={i18n.t('entity.seriesDefinition.notification.defaultSeriesNotEditable')}
        />

        <Form<InterestSeriesDefinition>
          onSubmit={noop}
          initialValues={definition}
          render={({Field}) => (
            <Grid columns={2}>
              <GridItem>
                <Field
                  name="name"
                  label={i18n.t('entity.seriesDefinition.labels.definitionName')}
                  disabled={definition.systemDefault}
                />

                <Field<string, Series>
                  as="select"
                  name="seriesId"
                  label={i18n.t('entity.seriesDefinition.labels.interestDefinitionSeries')}
                  disabled={definition.systemDefault}
                  options={series}
                  getOptionLabel={(series) => series.name}
                  getOptionValue={(series) => series.id}
                />
              </GridItem>
              <GridItem>
                <Field
                  name="branchId"
                  as="select"
                  disabled={definition.systemDefault}
                  options={branchList}
                  label={i18n.t('entity.branch.labels.branch')}
                  placeholder={i18n.t('general.labels.select')}
                  getOptionLabel={({marketingName}) => marketingName ?? ''}
                  getOptionValue={({id}) => id}
                />
              </GridItem>
            </Grid>
          )}
        />
      </Grid>
    </Card>
  );
}

export const InteresDefinitionDetailComponent = InterestSeriesDefinitionDetail;
