import {match} from 'ts-pattern';

import {UseFieldArrayReturn, UseFormReturn} from 'react-hook-form';

import {add, head, isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {VatCheckTotalsDefinitionDocumentTypeEnum, VatTypeEnum} from '@omnetic-dms/shared';

import {buildArray, noop} from 'shared';

import {cashReceiptItemUnits, getEmptyItem, invoiceItemUnits} from '../../../constants';
import {AccountingDocumentFormValues} from '../../../types';

export const getItemListActions = (
  listApi: UseFieldArrayReturn<AccountingDocumentFormValues, 'invoiceItems', 'id'>,
  formApi: UseFormReturn<AccountingDocumentFormValues>,
  listItemType: VatCheckTotalsDefinitionDocumentTypeEnum
) => {
  const {fields, remove, append, insert} = listApi;

  const handleDeleteRow = (index: number) => {
    const currentRow = formApi.getValues().invoiceItems[index];

    const discountItem = fields.find(
      (item) => item.relatedItemId === currentRow.itemId && item.type === 'discount'
    );
    const marginItem = fields.find(
      (item) => item.relatedItemId === currentRow.itemId && item.type === 'margin'
    );

    match(currentRow?.type)
      .with('discount', () => remove(index))
      .with('margin', () => {
        const removedIndexes = buildArray<number>([index]).when(
          isNotNil(discountItem),
          fields.indexOf(discountItem!)
        );

        remove(removedIndexes);
      })
      .with('standard', () => {
        const marginItemDiscount = fields.find(
          (item) => item.relatedItemId === marginItem?.itemId && item.type === 'discount'
        );

        const removedIndexes = buildArray<number>([index])
          .when(isNotNil(discountItem), fields.indexOf(discountItem!))
          .when(isNotNil(marginItem), fields.indexOf(marginItem!))
          .when(isNotNil(marginItemDiscount), fields.indexOf(marginItemDiscount!));

        remove(removedIndexes);
      })
      .otherwise(noop);
  };

  const handleCreateNewItem = () =>
    append(
      getEmptyItem({
        type: 'standard',
        unit:
          listItemType === 'invoices'
            ? head(invoiceItemUnits)?.value
            : head(cashReceiptItemUnits)?.value,
      })
    );

  const handleCreateMargin = (index: number) => {
    const {invoiceItems} = formApi.getValues();
    const parentField = invoiceItems[index];

    if (parentField.quantity?.toString() !== '1') {
      formApi.setValue(`invoiceItems.${index}.quantity`, '1');
    }

    const hasBaseItemDiscount = invoiceItems.some(
      (item) => item.relatedItemId === parentField.itemId && item.type === 'discount'
    );
    const addedFieldOffset = hasBaseItemDiscount ? 2 : 1;
    insert(
      add(index, addedFieldOffset),
      getEmptyItem({
        relatedItemId: parentField.itemId,
        quantity: '1',
        vatType: VatTypeEnum.S,
        isUnitPriceWithVat: parentField.isUnitPriceWithVat,
        type: 'margin',
        description: i18n.t('general.labels.margin'),
      })
    );
  };
  const handleCreateDiscount = (index: number) => {
    const parentField = formApi.getValues().invoiceItems[index];

    insert(
      add(index, 1),
      getEmptyItem({
        type: 'discount',
        vatType: parentField.vatType,
        isUnitPriceWithVat: parentField.isUnitPriceWithVat,
        relatedItemId: parentField.itemId,
        quantity: '1',
        description: i18n.t('general.labels.discount'),
      })
    );
  };

  return {
    onCreateDiscount: handleCreateDiscount,
    onCreateMargin: handleCreateMargin,
    onDeleteRow: handleDeleteRow,
    onCreateNewItem: handleCreateNewItem,
  };
};
