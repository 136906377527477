import {HStack, Heading, Icon, Show} from 'platform/foundation';

import {isNotEmpty} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

import {CorrectiveTaxDocumentPayment} from './CorrectiveTaxDocumentPayment/CorrectiveTaxDocumentPayment';

type CorrectiveTaxDocumentPaymentListProps = {
  orderId: string;
  taxDocument:
    | {
        orderId: string;
        paymentId: string;
        taxDocumentId: string;
        taxDocumentType: string;
      }[]
    | Nullish;
};

export function CorrectiveTaxDocumentPaymentList(props: CorrectiveTaxDocumentPaymentListProps) {
  const currentOrderDocuments =
    props.taxDocument?.filter(
      (document) =>
        document.orderId === props.orderId && document.taxDocumentType === 'corrective-tax-document'
    ) ?? [];

  return (
    <Show when={isNotEmpty(currentOrderDocuments)}>
      <HStack spacing={2} align="center">
        <Icon value="editor/insert_drive_file" />
        <Heading size={4}>{i18n.t('entity.accounting.labels.correctiveTaxDocuments')}</Heading>
      </HStack>

      {currentOrderDocuments.map((document) => (
        <CorrectiveTaxDocumentPayment
          key={`${document.taxDocumentId}-${document.taxDocumentType}`}
          correctiveTaxDocumentId={document.taxDocumentId}
        />
      ))}
    </Show>
  );
}
