import {Option} from 'platform/components';

import {isNil, defaultTo} from 'ramda';

import {Nullish} from 'shared';

import {GetServiceCaseOrderJobsApiResponse} from '../types/api/metadaWorkshopServiceOrderJob';

export const getOptionsFromServiceCaseOrderJobs = (
  serviceCaseOrderJobs: GetServiceCaseOrderJobsApiResponse | Nullish
): Option[] => {
  if (isNil(serviceCaseOrderJobs)) {
    return [];
  }
  return serviceCaseOrderJobs.map((serviceCaseOrderJob) => ({
    label: defaultTo('', serviceCaseOrderJob?.name),
    value: defaultTo('', serviceCaseOrderJob?.id),
  }));
};
