import {
  Actions,
  Card,
  Choice,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  TextInput,
} from 'platform/components';
import {
  VStack,
  Grid,
  GridItem,
  Show,
  Space,
  Text,
  Heading,
  Hide,
  HStack,
} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {object} from 'yup';

import {isFalsy, isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  DEFAULT_CURRENCY,
  handleApiError,
  useGetBasketLabourItemMaterialApiQuery,
  useGetDiscountGroupsQuery,
  useGetServicePackageItemMaterialQuery,
  useGetVatRatesOptions,
  useGetWarehousesQuery,
  usePatchBasketItemMaterialApiMutation,
  usePatchServicePackageItemMaterialMutation,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps, yupNumber, yupString} from 'shared';

interface EditMaterialItemProps extends TestIdProps {
  itemId: string;
  context: 'basket' | 'request';
  servicePackageId?: string;
  onClose: () => void;
  onAfterSubmit?: () => void;
}

type FormType = {
  quantity: number;
  sellingPricePerUnit: number;
  vatType: string;
  isPriceUnitManual: boolean;
  isDoNotApplyDiscount: boolean;
};

export function EditMaterialItem(props: EditMaterialItemProps) {
  const {
    data: basketItemData,
    isLoading: isBasketItemLoading,
    isError: isBasketItemError,
  } = useGetBasketLabourItemMaterialApiQuery(
    {basketItemId: props.itemId},
    {skip: props.context !== 'basket'}
  );
  const {
    data: requestItemData,
    isLoading: isRequestItemLoading,
    isError: isRequestItemError,
  } = useGetServicePackageItemMaterialQuery(
    {servicePackageItemId: props.itemId, servicePackageId: props.servicePackageId ?? ''},
    {skip: props.context !== 'request' || !props.servicePackageId}
  );

  const [patchBasketItemMaterial] = usePatchBasketItemMaterialApiMutation();
  const [patchServicePackageItemMaterial] = usePatchServicePackageItemMaterialMutation();

  const {data: warehouses, isLoading: isWarehousesLoading} = useGetWarehousesQuery();
  const {data: discountGroups} = useGetDiscountGroupsQuery();

  const [vatOptions] = useGetVatRatesOptions();

  const formatCurrency = useFormatCurrency();

  const data =
    props.context === 'basket'
      ? basketItemData?.materialBasketItem
      : requestItemData?.servicePackageItem;

  const warehouseOptions = warehouses?.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.id,
  }));

  const discountGroup = discountGroups?.find(
    (group) => group.id === data?.warehouse?.discountGroup
  );

  const onSubmit: FormSubmitHandler<FormType> = (data) => {
    const request =
      props.context === 'basket'
        ? patchBasketItemMaterial({
            basketItemId: props.itemId,
            body: {...data, isUnitPriceWithVat: false},
          })
        : patchServicePackageItemMaterial({
            servicePackageItemId: props.itemId,
            servicePackageId: props.servicePackageId ?? '',
            body: {...data, isUnitPriceWithVat: false},
          });

    return request.unwrap().then(props.onAfterSubmit).then(props.onClose).catch(handleApiError);
  };

  const defaultValues = {
    isPriceUnitManual: !!data?.isPriceUnitManual,
    quantity: data?.quantity ?? 0,
    sellingPricePerUnit: data?.priceUnitWithoutVat ?? undefined,
    vatType: data?.vatType ?? undefined,
    isDoNotApplyDiscount: data?.isDoNotApplyDiscount ?? undefined,
  };

  const isLoading = isBasketItemLoading || isRequestItemLoading;
  const isError = isBasketItemError || isRequestItemError;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormType> schema={formSchema} defaultValues={defaultValues} onSubmit={onSubmit}>
        {(control, formApi) => (
          <VStack spacing={4}>
            <Card variant="inlineGrey">
              <Grid columns={4}>
                <GridItem span={2}>
                  <Choice
                    value={data?.warehouse?.warehouseId ?? null}
                    options={warehouseOptions ?? []}
                    label={i18n.t('entity.warehouse.labels.warehouse')}
                    isDisabled
                    isLoading={isWarehousesLoading}
                    data-testid={suffixTestId('warehouse', props)}
                  />
                </GridItem>
                <TextInput
                  value={data?.warehouse?.storageLocation ?? null}
                  label={i18n.t('entity.warehouse.labels.storageLocation')}
                  isDisabled
                  data-testid={suffixTestId('storageLocation', props)}
                />
                <TextInput
                  value={discountGroup?.name ?? null}
                  label={i18n.t('entity.workshopServicePackages.labels.rabatGroup')}
                  isDisabled
                  data-testid={suffixTestId('discountGroup', props)}
                />
              </Grid>
              <Separator />
              <Grid spacing={4} columns={4}>
                <FormField
                  control={control}
                  name="quantity"
                  type="integer"
                  minStepperValue={0}
                  label={i18n.t('entity.workshopServicePackages.labels.quantity')}
                  data-testid={suffixTestId('quantity', props)}
                />
                <Show when={formApi.watch('isPriceUnitManual')}>
                  <FormField
                    control={control}
                    name="sellingPricePerUnit"
                    type="number"
                    minStepperValue={0}
                    label={i18n.t('entity.workshopServicePackages.labels.sellingPricePerUnit')}
                    data-testid={suffixTestId('sellingPricePerUnit', props)}
                  />
                  <FormField
                    control={control}
                    name="vatType"
                    type="choice"
                    options={vatOptions}
                    menuInPortal
                    label={i18n.t('general.labels.vatType')}
                    data-testid={suffixTestId('vatType', props)}
                  />
                </Show>
              </Grid>
              <Space vertical={4} />
              <HStack align="center" justify="space-between">
                <FormField
                  control={control}
                  name="isPriceUnitManual"
                  type="switch"
                  label={i18n.t('entity.workshopServicePackages.labels.customPrice')}
                  data-testid={suffixTestId('customPrice', props)}
                />
                <FormField
                  control={control}
                  name="isDoNotApplyDiscount"
                  type="checkbox"
                  isDisabled={isFalsy(data?.isDoNotApplyDiscountEditable)}
                  label={i18n.t('general.labels.doNotApplyDiscount')}
                  data-testid={suffixTestId('doNotApplyDiscount', props)}
                />
              </HStack>
              <Hide
                when={
                  isNilOrEmpty(data?.purchasePriceUnitWithoutVat) &&
                  isNilOrEmpty(data?.purchasePriceUnitWithVat)
                }
              >
                <Separator />
                <VStack spacing={1}>
                  <Text color="secondary" size="xSmall">
                    {i18n.t('entity.workshopServicePackages.labels.averagePurchasePrice')}
                  </Text>
                  <Heading size={5}>
                    {formatCurrency(
                      data?.purchasePriceUnitWithoutVat ?? 0,
                      data?.currency ?? DEFAULT_CURRENCY,
                      2
                    )}
                  </Heading>
                  <Text color="secondary" size="xSmall">
                    {`${formatCurrency(
                      data?.purchasePriceUnitWithVat ?? 0,
                      data?.currency ?? DEFAULT_CURRENCY,
                      2
                    )} ${i18n.t('general.labels.withVat')}`}
                  </Text>
                </VStack>
              </Hide>
            </Card>
            <Actions
              align="right"
              data-testid={suffixTestId('actions', props)}
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discard'),
                  onClick: props.onClose,
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  title: i18n.t('general.actions.saveChanges'),
                  buttonType: 'submit',
                  control,
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  quantity: yupNumber.required().min(1),
  sellingPricePerUnit: yupNumber.when('isPriceUnitManual', {
    is: true,
    then: yupNumber.required(),
    otherwise: yupNumber,
  }),
  vatType: yupString.when('isPriceUnitManual', {
    is: true,
    then: yupString.required(),
    otherwise: yupString,
  }),
});
