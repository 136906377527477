import {Card, FormControl, FormField, Separator} from 'platform/components';
import {Grid, GridItem, VStack, Space} from 'platform/foundation';

import {Path, UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {useGetSeriesListQuery, useGetTemplatesQuery} from '@omnetic-dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {FormType} from '../../../types/ServiceOrderVariant';

interface PaymentTypeProps extends RequiredTestIdProps {
  formApi: UseFormReturn<FormType>;
  control: FormControl<FormType>;
  formSection: Path<FormType>;
  title: string;
}

export function PaymentType(props: PaymentTypeProps) {
  const isAllowed = props.formApi.watch(
    `${props.formSection}.isAllowed` as Path<FormType>
  ) as boolean;

  const {options, isLoading} = useGetSeriesListQuery(
    {
      type: ['accounting/invoice'],
    },
    {
      selectFromResult: (result) => ({
        ...result,
        options: result?.data?.map((serie) => ({
          label: serie.name,
          value: serie.id,
        })),
      }),
    }
  );

  const {data: templates, isLoading: isTemplatesLoading} = useGetTemplatesQuery({});

  const invoiceTemplate = templates
    ?.filter((template) => template?.documentKindCode === 'invoice')
    ?.map((template) => ({
      label: template.title,
      value: template.id,
    }));

  return (
    <Card
      title={props.title}
      isExpanded={isAllowed}
      control={{
        type: 'switch',
        value: isAllowed,
        onChange: (enabled) => {
          props.formApi.setValue(`${props.formSection}.isAllowed` as Path<FormType>, enabled);
          props.formApi.resetField(`paymentType.defaultPaymentType`);
        },
      }}
      variant="inlineGrey"
      data-testid={suffixTestId('card', props)}
    >
      <Separator spacing={0} />
      <Space vertical={4} />
      <VStack spacing={4}>
        <Grid columns={2} horizontalSpacing={6}>
          <GridItem>
            <FormField
              control={props.control}
              name={`${props.formSection}.invoice.templateId` as Path<FormType>}
              type="choice"
              label={i18n.t('entity.warehouse.labels.invoiceTemplate')}
              options={invoiceTemplate}
              isLoading={isTemplatesLoading}
              isRequired
              isNotClearable
              data-testid={suffixTestId('invoiceTemplate', props)}
            />
          </GridItem>
        </Grid>
        <Grid columns={2} horizontalSpacing={6}>
          <FormField
            control={props.control}
            name={`${props.formSection}.invoice.docSeriesId` as Path<FormType>}
            type="choice"
            label={i18n.t('entity.warehouse.labels.invoiceDocumentSerie')}
            options={options}
            isLoading={isLoading}
            isRequired
            isNotClearable
            onChange={(value) => {
              props.formApi.setValue(
                `${props.formSection}.invoiceCorrection.docSeriesId` as Path<FormType>,
                value,
                {shouldValidate: true}
              );
              props.formApi.setValue(
                `${props.formSection}.invoiceEu.docSeriesId` as Path<FormType>,
                value,
                {shouldValidate: true}
              );
              props.formApi.setValue(
                `${props.formSection}.invoiceEuCorrection.docSeriesId` as Path<FormType>,
                value,
                {shouldValidate: true}
              );
              props.formApi.setValue(
                `${props.formSection}.invoiceOutsideEu.docSeriesId` as Path<FormType>,
                value,
                {shouldValidate: true}
              );
              props.formApi.setValue(
                `${props.formSection}.invoiceOutsideEuCorrection.docSeriesId` as Path<FormType>,
                value,
                {shouldValidate: true}
              );
            }}
            data-testid={suffixTestId('invoiceDocumentSerie', props)}
          />
          <FormField
            control={props.control}
            name={`${props.formSection}.invoiceCorrection.docSeriesId` as Path<FormType>}
            type="choice"
            label={i18n.t('entity.settings.labels.correctiveDocument')}
            options={options}
            isLoading={isLoading}
            isRequired
            isNotClearable
            data-testid={suffixTestId('correctiveDocument', props)}
          />
        </Grid>
        <Grid columns={2} horizontalSpacing={6}>
          <FormField
            control={props.control}
            name={`${props.formSection}.invoiceEu.docSeriesId` as Path<FormType>}
            type="choice"
            label={i18n.t('entity.settings.labels.invoiceEu')}
            options={options}
            isLoading={isLoading}
            isRequired
            isNotClearable
            data-testid={suffixTestId('invoiceEu', props)}
          />
          <FormField
            control={props.control}
            name={`${props.formSection}.invoiceEuCorrection.docSeriesId` as Path<FormType>}
            type="choice"
            label={i18n.t('entity.settings.labels.correctiveDocumentEu')}
            options={options}
            isLoading={isLoading}
            isRequired
            isNotClearable
            data-testid={suffixTestId('correctiveDocumentEu', props)}
          />
        </Grid>
        <Grid columns={2} horizontalSpacing={6}>
          <FormField
            control={props.control}
            name={`${props.formSection}.invoiceOutsideEu.docSeriesId` as Path<FormType>}
            type="choice"
            label={i18n.t('entity.settings.labels.invoiceOutsideEu')}
            options={options}
            isLoading={isLoading}
            isRequired
            isNotClearable
            data-testid={suffixTestId('invoiceOutsideEu', props)}
          />
          <FormField
            control={props.control}
            name={`${props.formSection}.invoiceOutsideEuCorrection.docSeriesId` as Path<FormType>}
            type="choice"
            label={i18n.t('entity.settings.labels.correctiveDocumentOutsideEu')}
            options={options}
            isLoading={isLoading}
            isRequired
            isNotClearable
            data-testid={suffixTestId('correctiveDocumentOutsideEu', props)}
          />
        </Grid>
      </VStack>
    </Card>
  );
}
