import {openDeleteDialog, showNotification} from 'platform/components';
import {ActionCallback, DataGrid, useRefreshDataGrid} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {forwardRef, useImperativeHandle} from 'react';

import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  catchUnhandledDataGridActions,
  handleApiError,
  useDeleteReceiveNoteCorrectionMutation,
} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {FullscreenDataGridWrapper} from '../../components/FullscreenDataGridWrapper';

export type ReceiveNoteCorrectionsHandle = {
  refreshDataGrid: () => void;
};

export const ReceiveNoteCorrectionsList = forwardRef<ReceiveNoteCorrectionsHandle>((_, ref) => {
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [deleteReceiveNoteCorrection] = useDeleteReceiveNoteCorrectionMutation();

  useImperativeHandle(
    ref,
    () => ({
      refreshDataGrid,
    }),
    []
  );

  const handleDelete = (rowId: string | string[], deselectAll: () => void) => {
    openDeleteDialog({
      onConfirm: () =>
        deleteReceiveNoteCorrection({body: {creditNoteId: isArray(rowId) ? rowId : [rowId]}})
          .unwrap()
          .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
          .then(deselectAll)
          .then(refreshDataGrid)
          .catch(handleApiError),
      'data-testid': testIds.warehouse.receiveNoteCorrectionsList('deleteDialog'),
    });
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, deselectAll, sourceSearchParams}) => {
    match(actionKey)
      .with('redirectDetail', () => {
        const id = isArray(rowId) ? rowId[0] : rowId;
        navigate(
          composePath(warehouseRoutes.receiveNoteCorrectionDetailOverview, {
            queryParams: sourceSearchParams,
            params: {id},
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('delete', () => {
        handleDelete(rowId, deselectAll);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        ref={dataGridRef}
        gridCode="warehouse-credit-note"
        actionCallback={actionCallback}
        data-testid={testIds.warehouse.receiveNoteCorrectionsList('receiveNoteCorrectionsList')}
      />
    </FullscreenDataGridWrapper>
  );
});
