import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Hide, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {
  BusinessCaseSeriesDefinitionRequestBody,
  BusinessCaseSeriesDefinitionResponseBody,
  handleApiError,
  useCreateBusinessCaseSeriesDefinitionMutation,
  useLazyGetBusinessCaseSeriesDefinitionQuery,
  usePutBusinessCaseSeriesDefinitionMutation,
} from '@omnetic-dms/shared';
import {selectSeries, selectUserBranches, Series, SeriesTypeEnum} from '@omnetic-dms/teas';

import {suffixTestId, TestIdProps, yupString} from 'shared';

interface BusinessCaseSeriesDefinitionFormProps extends TestIdProps {
  definitionId?: BusinessCaseSeriesDefinitionResponseBody['id'];
}

export function BusinessCaseSeriesDefinitionForm(props: BusinessCaseSeriesDefinitionFormProps) {
  const branchList = useSelector(selectUserBranches) || [];
  const series = useSelector(selectSeries);

  const [
    getSeriesDefinition,
    {data: definition, isFetching: isFetchingDefinition, isError: isErrorDefinition},
  ] = useLazyGetBusinessCaseSeriesDefinitionQuery();

  const [saveSeriesDefinition, {isLoading: isLoadingSave}] =
    usePutBusinessCaseSeriesDefinitionMutation();

  const [createSeriesDefinition, {isLoading: isLoadingCreate}] =
    useCreateBusinessCaseSeriesDefinitionMutation();

  useEffect(() => {
    if (!props.definitionId) {
      return;
    }

    getSeriesDefinition({seriesDefinitionId: props.definitionId});
  }, [props.definitionId]);

  const businessCaseSeries = filterAndPrepareSeriesForChoice(
    series,
    SeriesTypeEnum.NEW_AND_USED_CARS_BUSINESS_CASE
  );

  const invoiceSeries = filterAndPrepareSeriesForChoice(series, SeriesTypeEnum.ACCOUNTING_INVOICE);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(),
        branchId:
          definition?.systemDefault && props.definitionId
            ? Yup.string().nullable().default(null)
            : Yup.string().required(),
        businessCaseSeriesId: Yup.string().required(),
        proformaInvoiceSeriesId: Yup.string().required(),
        invoiceSeriesId: Yup.string().required(),
        taxDocumentSeriesId: Yup.string().required(),
        correctiveTaxDocumentSeriesId: Yup.string().required(),
        brokerageFeesInvoiceSeriesId: yupString.required(),
        brokerageFeesTaxDocumentSeriesId: yupString.required(),
        brokerageFeesCorrectiveTaxDocumentSeriesId: yupString.required(),
      }),
    [definition?.systemDefault, props.definitionId]
  );

  const branchListOptions = branchList.map((branch) => ({
    value: branch.id,
    label: branch.name,
  }));

  const handleSubmit: FormSubmitHandler<BusinessCaseSeriesDefinitionRequestBody> = async (
    values
  ) => {
    if (props.definitionId && definition?.id) {
      await saveSeriesDefinition({
        seriesDefinitionId: definition?.id,
        putBusinessCaseSeriesDefinitionRequestBody: values,
      })
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('page.businessCaseSeriesDefinition.notification.success')
          );
          closeCurrentDialog();
        })
        .catch(handleApiError);

      return;
    }

    createSeriesDefinition({
      businessCaseSeriesDefinitionRequestBody: values,
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('page.businessCaseSeriesDefinition.notification.success'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isFetchingDefinition} isError={isErrorDefinition} spacing={5}>
      <Form<BusinessCaseSeriesDefinitionRequestBody>
        onSubmit={handleSubmit}
        schema={schema}
        defaultValues={definition ?? {}}
      >
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={2}>
              <FormField
                control={control}
                name="name"
                type="text"
                label={i18n.t('entity.seriesDefinition.labels.definitionName')}
                data-testid={suffixTestId('definitionName', props)}
                isRequired
              />
              <Hide when={definition?.systemDefault}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  label={i18n.t('entity.branch.labels.branch')}
                  data-testid={suffixTestId('branchId', props)}
                  options={branchListOptions}
                  isRequired
                />
              </Hide>
              <FormField
                control={control}
                type="choice"
                name="businessCaseSeriesId"
                label={i18n.t('entity.settings.labels.businessCaseSeriesId')}
                data-testid={suffixTestId('businessCaseSeriesId', props)}
                options={businessCaseSeries}
                isRequired
              />
              <FormField
                control={control}
                type="choice"
                name="invoiceSeriesId"
                label={i18n.t('entity.settings.labels.invoiceSeriesId')}
                data-testid={suffixTestId('invoiceSeriesId', props)}
                options={invoiceSeries}
                isRequired
              />
              <FormField
                control={control}
                type="choice"
                name="proformaInvoiceSeriesId"
                label={i18n.t('entity.settings.labels.proformaInvoiceSeriesId')}
                data-testid={suffixTestId('proformaInvoiceSeriesId', props)}
                options={invoiceSeries}
                isRequired
              />
              <FormField
                control={control}
                type="choice"
                name="taxDocumentSeriesId"
                label={i18n.t('entity.settings.labels.taxDocumentSeriesId')}
                data-testid={suffixTestId('taxDocumentSeriesId', props)}
                options={invoiceSeries}
                isRequired
              />
              <FormField
                control={control}
                type="choice"
                name="correctiveTaxDocumentSeriesId"
                label={i18n.t('entity.settings.labels.correctiveTaxDocumentSeriesId')}
                data-testid={suffixTestId('correctiveTaxDocumentSeriesId', props)}
                options={invoiceSeries}
                isRequired
              />
              <GridItem span={2}>
                <Separator spacing={0} />
              </GridItem>
              <FormField
                control={control}
                type="choice"
                name="brokerageFeesInvoiceSeriesId"
                label={i18n.t('entity.settings.labels.brokerageFeesInvoiceSeriesId')}
                data-testid={suffixTestId('brokerageFeesInvoiceSeriesId', props)}
                options={invoiceSeries}
                isRequired
              />
              <FormField
                control={control}
                type="choice"
                name="brokerageFeesTaxDocumentSeriesId"
                label={i18n.t('entity.settings.labels.brokerageFeesTaxDocumentSeriesId')}
                data-testid={suffixTestId('brokerageFeesTaxDocumentSeriesId', props)}
                options={invoiceSeries}
                isRequired
              />
              <FormField
                control={control}
                type="choice"
                name="brokerageFeesCorrectiveTaxDocumentSeriesId"
                label={i18n.t('entity.settings.labels.brokerageFeesCorrectiveTaxDocumentSeriesId')}
                data-testid={suffixTestId('brokerageFeesCorrectiveTaxDocumentSeriesId', props)}
                options={invoiceSeries}
                isRequired
              />
            </Grid>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.cancel')}
                variant="secondary"
                data-testid={suffixTestId('cancel', props)}
                onClick={closeCurrentDialog}
              />
              <FormButton
                control={control}
                isLoading={isLoadingSave || isLoadingCreate}
                type="submit"
                data-testid={suffixTestId('submit', props)}
                title={i18n.t(
                  props.definitionId ? 'general.actions.save' : 'general.actions.create'
                )}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

function filterAndPrepareSeriesForChoice(series: Series[], seriesType: SeriesTypeEnum) {
  return series
    .filter((item) => item.type === seriesType)
    .map((item) => ({
      value: item.id,
      label: item.name,
    }));
}
