import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {FC, PropsWithChildren, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';

import {any, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {cebiaApi, useGetVehicleQuery, useGetVehicleV2Query} from '@omnetic-dms/shared';
import {
  getTopMakes,
  getCebiaAutotraceAndReport,
  getCebiaEquipmentCredentials,
  loadHighlightsList,
  loadShortcomingsList,
  getCustomTenantCatalogue,
  getVehicleMakeModels,
  useThunkDispatch,
  useVehicleTitle,
  loadVehicleDetailsData,
  selectCarAudit,
} from '@omnetic-dms/teas';

import {useRequiredParams} from 'shared';

import {VehicleDetailContent} from './components/VehicleDetailContent';

const VehicleDetail: FC<PropsWithChildren<any>> = () => {
  const {id} = useRequiredParams();

  const {data: vehicle} = useGetVehicleV2Query({vehicleId: id});
  const vehicleTitle = useVehicleTitle(vehicle);

  return (
    <>
      <Helmet title={i18n.t('page.vehicle.title', {title: vehicleTitle})} />
      <VehicleDetailContent vehicleId={id} />
    </>
  );
};

export const VehicleDetails: FC<PropsWithChildren<any>> = (props) => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId} = useRequiredParams();
  const {data: vehicle} = useGetVehicleQuery({vehicleId});

  const {loading} = useSelector(selectCarAudit);
  const isAuditLoading = any(isTrue, [
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (isNotNil(vehicleId) && isNotNil(vehicle) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch).then(async () => {
        await dispatch(
          getVehicleMakeModels.action({
            vehicleType: vehicle.type!,
            make: vehicle.make,
          })
        );
        await Promise.all([
          dispatch(getTopMakes.action({})),
          dispatch(getCustomTenantCatalogue.action({})),
          dispatch(loadHighlightsList()),
          dispatch(loadShortcomingsList()),
          dispatch(getCebiaEquipmentCredentials()),
          dispatch(getCebiaAutotraceAndReport()),
          dispatch(cebiaApi.endpoints.getCebiaReport.initiate({vehicleId})),
        ]);
      });
    }
  }, [vehicle, vehicleId]);

  return (
    <VStack width="100%">
      <DataStatus isLoading={isAuditLoading} minHeight={100}>
        <VehicleDetail {...props} />
      </DataStatus>
    </VStack>
  );
};
