import {FormControl, FormField, TextInput} from 'platform/components';
import {Grid, GridItem, Heading, Space, VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {GetWarehouseHandlingUnitsResponse, Rate} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {AddSupplierOrderItemForm} from '../../../../../types/AddSupplierOrderItemForm';
import {getOptionsFromHandlingUnits} from '../../../../../utils/getOptionsFromHandlingUnits';
import {getOptionsFromVatRates} from '../../../../../utils/getOptionsFromVatRates';

interface OrderDetailFormProps extends RequiredTestIdProps {
  control: FormControl<AddSupplierOrderItemForm>;
  manufacturer: string | Nullish;
  handlingUnit: string | Nullish;
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish;
  areHandlingUnitsLoading: boolean;
  vatRates: Rate[] | Nullish;
  areVatRatesLoading: boolean;
  currency: string;
}

export function OrderDetailForm(props: OrderDetailFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.orderDetail')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="orderDetail.supplierQuantity"
            label={i18n.t('entity.warehouse.labels.supplierQuantity')}
            suffix={props.handlingUnit}
            data-testid={suffixTestId('inputs.supplierQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="choice"
            name="orderDetail.supplierUnit"
            label={i18n.t('entity.warehouse.labels.supplierUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            isLoading={props.areHandlingUnitsLoading}
            data-testid={suffixTestId('inputs.supplierUnit', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="orderDetail.supplierUnitPrice"
            label={i18n.t('general.labels.unitPrice')}
            suffix={props.currency}
            data-testid={suffixTestId('inputs.unitPrice', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="orderDetail.supplierTotalPrice"
            label={i18n.t('general.labels.totalPrice')}
            suffix={props.currency}
            isDisabled
            data-testid={suffixTestId('inputs.totalPrice', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="text"
            name="orderDetail.name"
            label={i18n.t('entity.warehouse.labels.name')}
            isDisabled
            data-testid={suffixTestId('inputs.name', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="text"
            name="orderDetail.catalogueNumber"
            label={i18n.t('entity.warehouse.labels.catalogueNumber')}
            isDisabled
            data-testid={suffixTestId('inputs.catalogueNumber', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <TextInput
            value={defaultTo('', props.manufacturer)}
            label={i18n.t('entity.warehouse.labels.manufacturer')}
            isDisabled
            data-testid={suffixTestId('inputs.manufacturerId', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="text"
            name="orderDetail.supplierOrderingNumber"
            label={i18n.t('entity.warehouse.labels.supplierOrderingNumber')}
            data-testid={suffixTestId('inputs.supplierOrderingNumber', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4} align="center">
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="choice"
            name="orderDetail.vat"
            label={i18n.t('entity.warehouse.labels.vat')}
            options={getOptionsFromVatRates(props.vatRates)}
            isLoading={props.areVatRatesLoading}
            data-testid={suffixTestId('inputs.vat', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="text"
            name="orderDetail.vin"
            label={i18n.t('entity.warehouse.labels.vin')}
            data-testid={suffixTestId('inputs.vin', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="text"
            name="orderDetail.keyCode"
            label={i18n.t('entity.warehouse.labels.keyCode')}
            data-testid={suffixTestId('inputs.keyCode', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <Space vertical={5} />
          <FormField
            control={props.control}
            type="switch"
            name="orderDetail.isIntelligentPart"
            label={i18n.t('entity.warehouse.labels.isIntelligentPart')}
            data-testid={suffixTestId('inputs.isIntelligentPart', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
