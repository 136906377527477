import {Heading} from 'platform/foundation';

import {isEmpty, isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  COUNTRY_CZE,
  CURRENCY_CZK,
  formatDocumentTitle,
  useSourcingListUserHiddenQuery,
} from '@omnetic-dms/shared';
import {
  classifiedsListsDataView,
  DEFAULT_ORDER_ID,
  useSourcingCurrency,
  useSourcingTargetCountry,
} from '@omnetic-dms/teas';

import {SourcingDataStatus} from '../../components/SourcingDataStatus/SourcingDataStatus';
import {SourcingLayout} from '../../components/SourcingLayout/SourcingLayout';
import {useSourcingTable} from '../../components/SourcingTable/hooks/useSourcingTable';
import {useSourcingTableColumns} from '../../components/SourcingTable/hooks/useSourcingTableColumns';
import {SourcingTable} from '../../components/SourcingTable/SourcingTable';
import {VehiclePreview} from '../../components/VehiclePreview/VehiclePreview';
import {withSourcingPage} from '../../utils/withSourcingPage';

function UserHidden() {
  const currency = useSourcingCurrency();
  const targetCountry = useSourcingTargetCountry();
  const [state, dispatch] = useSourcingTable('user-hidden', DEFAULT_ORDER_ID);
  const columns = useSourcingTableColumns();

  const sortByOptions =
    classifiedsListsDataView.sortByOptions?.map((item) => ({
      value: item.id,
      label: item.name,
      orderBy: item.orderBy,
    })) ?? [];
  const orderBy = sortByOptions.find((item) => item.value === state.orderBy)?.orderBy;

  const {data, isLoading, isFetching, error} = useSourcingListUserHiddenQuery({
    orderBy: isEmpty(orderBy) ? undefined : orderBy?.join(','),
    currency: currency?.code ?? CURRENCY_CZK,
    buyerCountry: targetCountry?.code ?? COUNTRY_CZE,
    size: 1000,
  });

  const rows = data?.data ?? [];

  return (
    <SourcingDataStatus
      iconUrl="/assets/images/empty_images/empty-hidden.svg"
      text={i18n.t('entity.vehicle.notifications.nothingHidden')}
      isEmpty={!isLoading && isEmpty(rows)}
      isError={isNotNil(error)}
    >
      <SourcingLayout
        header={
          <Heading size={2} alternative>
            {i18n.t('entity.vehicle.labels.hiddenPage')}
          </Heading>
        }
        content={
          <SourcingTable
            state={state}
            dispatch={dispatch}
            columns={columns}
            sortByOptions={sortByOptions}
            data={rows}
            isLoading={isLoading || isFetching}
            error={error}
          />
        }
        iframe={<VehiclePreview vehicleId={state.selectedRow?.sourcingVehicle.id} />}
        pageTitle={formatDocumentTitle(
          i18n.t('entity.vehicle.labels.hiddenPage'),
          i18n.t('page.sourcing.labels.classifieds'),
          i18n.t('page.sourcing.labels.sourcing')
        )}
      />
    </SourcingDataStatus>
  );
}

export const SourcingClassifiedsUserHidden = withSourcingPage(UserHidden);
