import {DataStatus, Form, FormField} from 'platform/components';
import {FormToDataGridConnectorProps} from 'platform/datagrid';
import {VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';

import {i18n} from '@omnetic-dms/i18n';
import {
  getOptionsFromSuppliers,
  useGetManufacturersQuery,
  useGetSuppliersQuery,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {RangeField} from '../../../../../components/RangeField';
import {DEFAULT_RANGE_VALUE} from '../../../../../constants/defaultRangeValue';
import {DateRangeField} from '../../../../../types/DateRangeField';
import {NumberRangeField} from '../../../../../types/NumberRangeField';
import {getOptionsFromManufacturers} from '../../../../../utils/getOptionsFromManufacturers';

type MaterialPurchaseListTabExternalFiltersForm = Partial<{
  manufacturer: string;
  supplier: string;
  created: DateRangeField;
  neededAt: DateRangeField;
  neededIn: NumberRangeField;
}>;

type MaterialPurchaseListTabExternalFiltersProps = FormToDataGridConnectorProps & TestIdProps;

export function MaterialPurchaseListTabExternalFilters(
  props: MaterialPurchaseListTabExternalFiltersProps
) {
  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const {
    data: suppliers,
    isLoading: isSuppliersLoading,
    isError: isSuppliersError,
  } = useGetSuppliersQuery();

  const isLoading = isManufacturersLoading || isSuppliersLoading;
  const isError = isManufacturersError || isSuppliersError;

  const values: MaterialPurchaseListTabExternalFiltersForm = {
    ...props.values,
    created: defaultTo(DEFAULT_RANGE_VALUE, props.values?.created),
    neededAt: defaultTo(DEFAULT_RANGE_VALUE, props.values?.neededAt),
    neededIn: defaultTo(DEFAULT_RANGE_VALUE, props.values?.neededIn),
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialPurchaseListTabExternalFiltersForm> values={values} onChange={props.onChange}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="manufacturer"
              type="choice"
              label={i18n.t('entity.warehouse.labels.manufacturer')}
              options={getOptionsFromManufacturers(manufacturers)}
              data-testid={suffixTestId('manufacturer', props)}
            />
            <FormField
              control={control}
              name="supplier"
              type="choice"
              label={i18n.t('entity.warehouse.labels.supplier')}
              options={getOptionsFromSuppliers(suppliers)}
              data-testid={suffixTestId('supplier', props)}
            />
            <RangeField
              control={control}
              type="date"
              name="created"
              labelFrom={i18n.t('general.labels.createdFrom')}
              labelTo={i18n.t('general.labels.createdTo')}
              data-testid={suffixTestId('inputs.created', props)}
            />
            <RangeField
              control={control}
              type="date"
              name="neededAt"
              labelFrom={i18n.t('general.labels.neededAtFrom')}
              labelTo={i18n.t('general.labels.neededAtTo')}
              data-testid={suffixTestId('inputs.neededAt', props)}
            />
            <RangeField
              control={control}
              type="number"
              name="neededIn"
              labelFrom={i18n.t('general.labels.neededInFrom')}
              labelTo={i18n.t('general.labels.neededInTo')}
              data-testid={suffixTestId('inputs.neededIn', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
