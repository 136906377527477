import {Grid, GridItem, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {ReadOnlyField} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, Nullish, parseDate} from 'shared';

import {ReceiveNoteReadOnlyFields} from '../types/ReceiveNoteReadOnlyFields';

export type BasicInformationReadOnlyProps = {
  readOnlyFields: ReceiveNoteReadOnlyFields;
};

export function BasicInformationReadOnly(props: BasicInformationReadOnlyProps) {
  const dateTimeFormatter = useDateTimeFormatter();

  const formatToShortDate = (date: string | Nullish) => {
    if (isNilOrEmpty(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  return (
    <>
      <VStack spacing={4}>
        <Grid columns={4}>
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.supplier')}
            value={props.readOnlyFields.supplier}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.warehouse')}
            value={props.readOnlyFields.warehouse}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.authorizationProfile')}
            value={props.readOnlyFields.authorizationProfile}
          />
        </Grid>

        <Grid columns={4}>
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.number')}
            value={props.readOnlyFields.number}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.date')}
            value={formatToShortDate(props.readOnlyFields.date)}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.invoiceNumber')}
            value={props.readOnlyFields.invoiceNumber}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.invoiceIssueDate')}
            value={formatToShortDate(props.readOnlyFields.invoiceDate)}
          />
        </Grid>

        <Grid columns={4}>
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.paymentType')}
            value={props.readOnlyFields.paymentType}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.currency')}
            value={props.readOnlyFields.currency}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.exchangeRate')}
            value={props.readOnlyFields.exchangeRate}
          />

          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.ncConversion')}
            value={props.readOnlyFields.ncConversion}
          />
        </Grid>

        <Grid columns={4}>
          <GridItem span={4}>
            <ReadOnlyField
              label={i18n.t('entity.warehouse.labels.note')}
              value={props.readOnlyFields.note}
            />
          </GridItem>
        </Grid>
      </VStack>
    </>
  );
}
