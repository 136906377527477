import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

export const PostIntegrationsForXentryLinkApiResponseSchema = BaseVoidResponseSchema;
export type PostIntegrationsForXentryLinkApiResponse = z.infer<
  typeof PostIntegrationsForXentryLinkApiResponseSchema
>;

export const PostIntegrationsForXentryLinkApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: z
    .object({
      xentryOrderId: z.string(),
    })
    .nullable(),
});

export type PostIntegrationsForXentryLinkApiArg = z.infer<
  typeof PostIntegrationsForXentryLinkApiArgSchema
>;

export const PostIntegrationsForXentryUnlinkApiResponseSchema = BaseVoidResponseSchema;
export type PostIntegrationsForXentryUnlinkApiResponse = z.infer<
  typeof PostIntegrationsForXentryUnlinkApiResponseSchema
>;

export const PostIntegrationsForXentryUnlinkApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type PostIntegrationsForXentryUnlinkApiArg = z.infer<
  typeof PostIntegrationsForXentryUnlinkApiArgSchema
>;

export const XentryJobSchema = z.object({
  id: z.string().nullable().optional(),
  createdAt: z.string().nullable().optional(),
  url: z.string().nullable().optional(),
  isCurrentlyLinked: z.boolean().nullable().optional(),
});

export type XentryJob = z.infer<typeof XentryJobSchema>;

export const PostAvailableXentryOrdersApiResponseSchema = z
  .object({
    xentryJob: z.array(XentryJobSchema).optional(),
  })
  .nullable();

export type PostAvailableXentryOrdersApiResponse = z.infer<
  typeof PostAvailableXentryOrdersApiResponseSchema
>;

export const PostAvailableXentryOrdersApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type PostAvailableXentryOrdersApiArg = z.infer<typeof PostAvailableXentryOrdersApiArgSchema>;

const IntegrationActionSchema = z.object({
  key: z.string().nullable().optional(),
  style: z.string().nullable().optional(),
  position: z.number().nullable().optional(),
});

const IntegrationSchema = z.object({
  code: z.string().nullable().optional(),
  serviceCode: z.string().nullable().optional(),
  viewDetailsLink: z.string().nullable().optional(),
  createdAt: z.string().nullable().optional(),
  updatedAt: z.string().nullable().optional(),
});

export const GetServiceCaseIntegrationsApiResponseSchema = z
  .object({
    caseIntegration: z
      .array(
        z
          .object({
            integration: IntegrationSchema.nullable().optional(),
            action: z.array(IntegrationActionSchema.nullable()).optional(),
            position: z.number().nullable().optional(),
          })
          .nullable()
      )
      .optional(),
  })
  .nullable();

export type GetServiceCaseIntegrationsApiResponse = z.infer<
  typeof GetServiceCaseIntegrationsApiResponseSchema
>;

export const GetServiceCaseIntegrationsApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCaseIntegrationsApiArg = z.infer<
  typeof GetServiceCaseIntegrationsApiArgSchema
>;

export const PostServiceOrderAudatexTaskCalculationsApiResponseSchema = BaseVoidResponseSchema;
export type PostServiceOrderAudatexTaskCalculationsApiResponse = z.infer<
  typeof PostServiceOrderAudatexTaskCalculationsApiResponseSchema
>;

export const PostServiceOrderAudatexTaskCalculationsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z.object({
    caseId: z.string(),
    taskId: z.string(),
  }),
});

export type PostServiceOrderAudatexTaskCalculationsApiArg = z.infer<
  typeof PostServiceOrderAudatexTaskCalculationsApiArgSchema
>;

export const PostServiceOrderMyClaimCalculationsApiResponseSchema = BaseVoidResponseSchema;
export type PostServiceOrderMyClaimCalculationsApiResponse = z.infer<
  typeof PostServiceOrderMyClaimCalculationsApiResponseSchema
>;

export const PostServiceOrderMyClaimCalculationsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z.object({
    myClaimId: z.string(),
  }),
});

export type PostServiceOrderMyClaimCalculationsApiArg = z.infer<
  typeof PostServiceOrderMyClaimCalculationsApiArgSchema
>;
