import {yupResolver} from '@hookform/resolvers/yup';
import {showNotification} from 'platform/components';
import {Icon, Space} from 'platform/foundation';
import * as yup from 'yup';

import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Button, TextField, updateTemplateRequest, selectCustomTemplate} from '@omnetic-dms/teas';

import {DOCX_MIME_TYPE} from 'shared';

import {changeExistingFile} from '../../../consts/changeExistingFile';
import {uploadNewFile} from '../../../utils/uploadNewFile';
import {
  ErrorText,
  FormFooter,
  SecondaryFooterButton,
  SubmitButton,
  UploadFormStyled,
  UploadStyledArea,
} from '../styles';
import {UploadDocumentFormFields} from '../types';
import {FileField} from './FileField';
import {UploadComponent} from './UploadComponent';

export interface UploadDocumentTemplateFormProps {
  readonly code: string;
  readonly customTemplateId?: string;
  readonly submit?: VoidFunction;
  readonly onClose: VoidFunction;
}

const trimNumber = (e: ChangeEvent<HTMLInputElement>, length: number) =>
  (e.target.value = e.target.value.substr(0, length));

const UploadDocumentTemplateFormComponent: ForwardRefRenderFunction<
  HTMLFormElement,
  UploadDocumentTemplateFormProps
> = ({code, onClose, customTemplateId}, ref) => {
  const [showFileError, setShowFileError] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
  const [firstChangeValid, setFirstChangeValid] = useState(false);
  const selectedDocument = useSelector(selectCustomTemplate(customTemplateId ?? ''));

  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = () => inputRef.current?.click();

  const {
    handleSubmit,
    control,
    formState: {errors},
    formState,
    setValue,
  } = useForm<UploadDocumentFormFields>({
    resolver: yupResolver(uploadDocumentTemplateSchema),
    mode: 'onChange',
    defaultValues: {
      title: '',
      note: '',
    },
  });

  const dispatch = useDispatch();
  const {isValid, isDirty, dirtyFields} = formState;
  const [file, setFile] = useState<File | null>(null);

  const onSubmit = ({title, note}: UploadDocumentFormFields) => {
    if (customTemplateId) {
      updateTemplate(customTemplateId, {title, note});
      return;
    }

    if (file) {
      uploadNewFile(dispatch, file, code, title, note);
    } else {
      setShowFileError(true);
    }
  };

  const updateTemplate = (templateId: string, {title, note}: UploadDocumentFormFields) => {
    // if changed just file
    if (file && 'lastModified' in file) {
      onClose();
      changeExistingFile(dispatch, file, code, title, templateId, note);
      return;
    }

    // if changed just title and note
    if (selectedDocument?.title !== title || selectedDocument?.note !== note) {
      onClose();
      dispatch(updateTemplateRequest({note, title, customTemplateId: templateId}));
    }
  };

  const checkDisableSubmitBtn = useCallback(() => {
    if (isValid && isDirty && file) {
      setFirstChangeValid(true);
      setDisabledSubmitBtn(false);
      return;
    }

    if ((!isValid && !firstChangeValid) || !file) {
      setDisabledSubmitBtn(true);
    }

    if (file && 'lastModified' in file) {
      setDisabledSubmitBtn(false);
    }

    if (dirtyFields.title === undefined && firstChangeValid) {
      setDisabledSubmitBtn(true);
    }
  }, [isValid, file, dirtyFields.title, firstChangeValid, isDirty]);

  useEffect(() => {
    checkDisableSubmitBtn();
  }, [checkDisableSubmitBtn]);

  useEffect(() => {
    if (selectedDocument) {
      const {fileName, title, note} = selectedDocument;
      setFile({name: fileName} as File);
      setValue('title', title);
      setValue('note', note ?? undefined);
    }
  }, [setValue, code, selectedDocument]);

  const handleFileChange = ({target: {files}}: ChangeEvent<HTMLInputElement>) => {
    if (!(files && files.length > 0 && files[0])) {
      return;
    }

    const firstFile = files[0];

    if (!DOCX_MIME_TYPE.includes(firstFile.type)) {
      showNotification.error(i18n.t('general.validations.invalidFileType'));
      return;
    }

    setShowFileError(false);
    setFile(firstFile);
  };

  return (
    <UploadFormStyled onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="title"
        control={control}
        render={({field: {onChange, value}}) => (
          <TextField
            onChange={(e) => onChange(trimNumber(e, TITLE_MAX_LENGTH))}
            name="title"
            value={value ?? ''}
            data-testid="document-upload-title"
            label={`* ${i18n.t('entity.document.labels.templateTitle')}`}
            error={Boolean(errors.title?.message)}
            helperText={errors.title?.message}
          />
        )}
      />
      <Controller
        name="note"
        control={control}
        render={({field: {onChange, value}}) => (
          <TextField
            onChange={(e) => onChange(trimNumber(e, NOTE_MAX_LENGTH))}
            name="note"
            value={value ?? ''}
            label={i18n.t('entity.document.labels.note')}
            error={Boolean(errors.note?.message)}
            data-testid="document-upload-note"
            helperText={errors.note?.message}
          />
        )}
      />
      {file === null ? (
        <UploadStyledArea>
          <div>{i18n.t('entity.documents.labels.uploadRegularText')}</div>
          <Button
            type="button"
            data-testid={testIds.settings.documentTemplates('uploadAction')}
            primary
            onClick={onClick}
          >
            <Icon value="action/backup" />
            <Space horizontal={4} />
            {i18n.t('general.actions.uploadFile')}
          </Button>
          {showFileError && (
            <ErrorText>{i18n.t('general.notifications.errorFieldRequired')}</ErrorText>
          )}
          <UploadComponent reference={inputRef} code={code} handleFileChange={handleFileChange} />
        </UploadStyledArea>
      ) : (
        <FileField
          name={file.name}
          onRemove={() => {
            setFile(null);
          }}
        />
      )}
      <FormFooter>
        <SecondaryFooterButton
          secondary
          onClick={onClose}
          data-testid={testIds.settings.documentTemplates('cancelButton')}
        >
          {i18n.t('general.actions.cancel')}
        </SecondaryFooterButton>
        {/** disabledSubmitBtn  - User can change just a file without changing  title and note,also the opposite **/}
        <SubmitButton
          data-testid={testIds.settings.documentTemplates('submitButton')}
          primary
          disabled={disabledSubmitBtn}
        >
          {customTemplateId ? i18n.t('general.actions.update') : i18n.t('general.actions.save')}
        </SubmitButton>
      </FormFooter>
    </UploadFormStyled>
  );
};

const TITLE_MAX_LENGTH = 25;
const NOTE_MAX_LENGTH = 100;

const uploadDocumentTemplateSchema = yup.object().shape({
  title: yup
    .string()
    .required(i18n.t('general.notifications.errorDocTitleRequired'))
    .trim()
    .max(TITLE_MAX_LENGTH),
  note: yup.string().trim().nullable().max(NOTE_MAX_LENGTH),
});

export const UploadDocumentTemplateForm = forwardRef(UploadDocumentTemplateFormComponent);
