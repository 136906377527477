import {isNilOrEmpty} from 'ramda-adjunct';

import {
  CommonAddressResponseBody,
  ContractInformationResponseBodyV2,
  getNaturalPersonFullName,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {AccountingDocumentFormValues} from '../types';

const getAddressLine1 = (addressData?: CommonAddressResponseBody) => {
  if (isNilOrEmpty(addressData)) {
    return null;
  }
  if (isNilOrEmpty(addressData?.street)) {
    return null;
  }
  if (isNilOrEmpty(addressData?.descriptiveNumber)) {
    return `${addressData?.street}`;
  }
  if (isNilOrEmpty(addressData?.orientationNumber)) {
    return `${addressData?.street} ${addressData?.descriptiveNumber}`;
  }
  return `${addressData?.street} ${addressData?.descriptiveNumber}/${addressData?.orientationNumber}`;
};

export const convertContractInformationToInvoiceCustomer = (
  customerId: string,
  contractInformation: ContractInformationResponseBodyV2 | Nullish
): AccountingDocumentFormValues['customer'] => {
  const isPerson = contractInformation?.legalForm === 'NATURAL_PERSON';
  const address = isPerson
    ? contractInformation?.person?.permanentAddress
    : contractInformation?.businessInfo?.address;
  const name = isPerson
    ? getNaturalPersonFullName(contractInformation?.person)
    : contractInformation?.businessInfo?.businessInfo.tradeName || '';

  return {
    name,
    id: customerId,
    contractInformationId: contractInformation?.id,
    city: address?.address?.city ?? null,
    zip: address?.address?.zip ?? null,
    country: address?.address?.country ?? null,
    addressLine1: getAddressLine1(address?.address),
    addressLine2: null,
    tradeName: contractInformation?.businessInfo?.businessInfo?.tradeName ?? null,
    cin: contractInformation?.businessInfo?.businessInfo?.registrationNumber ?? null,
    tin: contractInformation?.businessInfo?.businessInfo?.vatNumber ?? null,
  };
};
