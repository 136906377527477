import {Attributes, AttributesRow} from 'platform/components';
import {Box, HStack, Show, Text} from 'platform/foundation';
import {useFormatCurrency, useDateTimeFormatter, useFormatNumber} from 'platform/locale';

import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {defaultTo, isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  NewOfferPurchaseVehicleResponseBody,
  selectTenant,
  DeductibleOrNotDeductiblePrice as DeductibleOrNotDeductiblePriceApi,
  NewOfferPurchaseBrokerageVehicleResponseBody,
  useGetUserQuery,
  useGetComplexPermissions,
  useGetSaleVehicleQuery,
  SaleVehiclePriceInForeignCurrencyResponseBody,
  DEFAULT_CURRENCY,
} from '@omnetic-dms/shared';
import {selectVatRatesByCountryCode, VatRate} from '@omnetic-dms/teas';

import {
  parseDate,
  buildArray,
  EMPTY_PLACEHOLDER,
  getApiDateString,
  Nullish,
  suffixTestId,
  RequiredTestIdProps,
} from 'shared';

interface RequestedPriceDetailProps extends RequiredTestIdProps {
  vehicle:
    | NewOfferPurchaseVehicleResponseBody
    | NewOfferPurchaseBrokerageVehicleResponseBody
    | Nullish;
  isStock: boolean | Nullish;
  isBrokerage: boolean;
}

export function PricingDetail(props: RequestedPriceDetailProps) {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();
  const formatDateTime = useDateTimeFormatter();
  const {data: saleVehicle} = useGetSaleVehicleQuery(
    {vehicleId: props.vehicle?.vehicleId ?? ''},
    {skip: isNilOrEmpty(props.vehicle?.vehicleId)}
  );

  const {canReadPurchasePrice, canReadSalePrice} = useGetComplexPermissions();

  const {data} = useSelector(selectTenant);

  const vatRates = useSelector(selectVatRatesByCountryCode(data?.country))?.rates;

  const {data: pricingUser} = useGetUserQuery(
    {id: props.vehicle?.pricing.pricer ?? ''},
    {skip: isNil(props.vehicle?.pricing.pricer)}
  );
  const buyingVatRate = vatRates?.find(
    (rate) =>
      props.vehicle && 'buying' in props.vehicle && rate.type === props.vehicle?.buying.vatType
  );
  const sellingVatRate = vatRates?.find((rate) => rate.type === props.vehicle?.pricing.vatType);

  const getFormattedPrice = useCallback(
    (
      value?: DeductibleOrNotDeductiblePriceApi,
      foreignPrice?: SaleVehiclePriceInForeignCurrencyResponseBody | null
    ) => {
      if (!value) {
        return '–';
      }

      if (!props.vehicle?.pricing.vatDeductible) {
        if (value.priceNotDeductible) {
          return (
            <HStack spacing={1} align="center">
              <Show when={isNotNilOrEmpty(value.priceNotDeductible.amount)}>
                <Text size="xSmall">
                  {formatCurrency(
                    parseFloat(value.priceNotDeductible.amount),
                    value.priceNotDeductible.currency
                  )}
                </Text>
              </Show>
              <Show when={isNotNilOrEmpty(foreignPrice?.priceWithVat)}>
                <Text size="xSmall" data-testid={suffixTestId('foreignPriceWithVat', props)}>
                  /{' '}
                  {formatCurrency(
                    parseFloat(foreignPrice?.priceWithVat ?? '0'),
                    foreignPrice?.foreignCurrencyCode ?? DEFAULT_CURRENCY
                  )}
                </Text>
              </Show>
            </HStack>
          );
        }
        return '–';
      }

      if (!value.priceWithVat) {
        return '–';
      }

      return (
        <Box>
          <HStack spacing={1} align="center">
            <Show when={isNotNilOrEmpty(value.priceWithVat)}>
              <Text size="xSmall">
                {formatCurrency(parseFloat(value.priceWithVat.amount), value.priceWithVat.currency)}
              </Text>
            </Show>
            <Show when={isNotNilOrEmpty(foreignPrice?.priceWithVat)}>
              <Text size="xSmall" data-testid={suffixTestId('foreignPriceWithVat', props)}>
                /{' '}
                {formatCurrency(
                  parseFloat(foreignPrice?.priceWithVat ?? '0'),
                  foreignPrice?.foreignCurrencyCode ?? DEFAULT_CURRENCY
                )}
              </Text>
            </Show>
          </HStack>
          <HStack spacing={1} align="center">
            <Show when={isNotNilOrEmpty(value.priceWithoutVat)}>
              <Text color="secondary" size="xSmall">
                {formatCurrency(
                  parseFloat(value.priceWithoutVat?.amount ?? '0'),
                  value.priceWithoutVat?.currency ?? DEFAULT_CURRENCY
                )}
              </Text>
            </Show>
            <Show when={isNotNilOrEmpty(foreignPrice?.priceWithVat)}>
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('foreignPriceWithoutVat', props)}
              >
                /{' '}
                {formatCurrency(
                  parseFloat(foreignPrice?.priceWithoutVat ?? '0'),
                  foreignPrice?.foreignCurrencyCode ?? DEFAULT_CURRENCY
                )}
              </Text>
            </Show>
            <Show
              when={
                isNotNilOrEmpty(foreignPrice?.priceWithVat) ||
                isNotNilOrEmpty(value.priceWithoutVat)
              }
            >
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('foreignPriceWithVatText', props)}
              >
                {i18n.t('general.labels.withoutVat')}
              </Text>
            </Show>
          </HStack>
        </Box>
      );
    },
    [props, formatCurrency]
  );

  const rows = buildArray<AttributesRow>()
    .add({
      label: i18n.t('general.labels.vatDeductible'),
      value: props.vehicle?.pricing.vatDeductible
        ? i18n.t('general.labels.yes')
        : i18n.t('general.labels.no'),
      testId: testIds.businessCase.buying('pricing-vatDeductible'),
    })
    .add({
      label: i18n.t('entity.businessCase.labels.customerRequestedPrice'),
      value: formatCurrency(
        parseFloat(defaultTo('', props.vehicle?.costs?.customerRequestedPrice?.amount)) ?? 0,
        props.vehicle?.costs?.customerRequestedPrice?.currency ?? ''
      ),
      testId: testIds.businessCase.buying('pricing-customerRequstedPrice'),
    })
    .add({
      label: i18n.t('entity.businessCase.labels.expectedPurchaseOn'),
      value: props.vehicle?.expectedPurchaseOn
        ? formatDateTime('dateShort', parseDate(props.vehicle?.expectedPurchaseOn))
        : EMPTY_PLACEHOLDER,
      testId: testIds.businessCase.buying('pricing-expectedPurchaseOn'),
    })
    .when(props.isStock && canReadPurchasePrice, {
      label: i18n.t('general.labels.buyingPrice'),
      content: (
        <Text size="xSmall" data-testid={testIds.businessCase.buying('pricingRow-buyingPrice')}>
          {getFormattedPrice(
            (props.vehicle as NewOfferPurchaseVehicleResponseBody)?.buying?.buyingPrice,
            saleVehicle?.purchasePriceIsForeignCurrency
          )}
        </Text>
      ),
      testId: testIds.businessCase.buying('pricing-buyingPrice'),
    })
    .when(buyingVatRate && buyingVatRate?.type !== VatRate.type.Z, {
      label: i18n.t('general.labels.vatRateBuying'),
      value: `${formatNumber(parseInt(buyingVatRate?.rate ?? '0'), 0)} %`,
      testId: testIds.businessCase.buying('pricing-vatRateBuying'),
    })
    .when(props.isStock && canReadPurchasePrice, {
      label: i18n.t('entity.businessCase.labels.maxBuyingPrice'),
      value: formatCurrency(
        parseFloat(
          defaultTo(
            '',
            (props.vehicle as NewOfferPurchaseVehicleResponseBody)?.buying?.maxBuyingPrice?.amount
          )
        ),
        (props.vehicle as NewOfferPurchaseVehicleResponseBody)?.buying?.maxBuyingPrice?.currency ??
          ''
      ),
      testId: testIds.businessCase.buying('pricing-maxBuyingPrice'),
    })
    .when(canReadSalePrice, {
      label: i18n.t('general.labels.sellingPrice'),
      content: (
        <Text size="xSmall" data-testid={testIds.businessCase.buying('pricingRow-sellingPrice')}>
          {getFormattedPrice(props.vehicle?.pricing.sellingPrice)}
        </Text>
      ),
      testId: testIds.businessCase.buying('pricing-sellingPrice'),
    })
    .when(sellingVatRate && sellingVatRate?.type !== VatRate.type.Z, {
      label: i18n.t('general.labels.vatRateSelling'),
      value: `${formatNumber(parseInt(sellingVatRate?.rate ?? '0'), 0)} %`,
      testId: testIds.businessCase.buying('pricing-vatRateSelling'),
    })
    .when(canReadPurchasePrice, {
      label: i18n.t('general.labels.reason'),
      value: props.isBrokerage
        ? props.vehicle?.pricing.reason
        : (props.vehicle as NewOfferPurchaseVehicleResponseBody | Nullish)?.buying.reason,
      testId: testIds.businessCase.buying('pricing-reason'),
    })
    .add({
      label: i18n.t('entity.interest.labels.pricer'),
      value: pricingUser ? `${pricingUser.firstName} ${pricingUser.lastName}` : '–',
      testId: testIds.businessCase.buying('pricing-pricer'),
    })
    .add({
      label: i18n.t('entity.interest.labels.pricedOn'),
      value: props.vehicle?.pricing.pricedOn
        ? getApiDateString(parseDate(props.vehicle?.pricing.pricedOn))
        : '–',
      testId: testIds.businessCase.buying('pricing-pricedOn'),
    });

  return (
    <Attributes data-testid={testIds.businessCase.buying('pricing')} rows={rows} size="third" />
  );
}
