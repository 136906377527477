import {Table, TableRow} from 'platform/components';
import {Align, Heading, Show, Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {CURRENCY_CZK, SourcingPriceResponseBody} from '@omnetic-dms/shared';
import {useSourcingCurrency} from '@omnetic-dms/teas';

interface PriceTableProps {
  originalPrice: number;
  price: SourcingPriceResponseBody;
}

export function PriceTable(props: PriceTableProps) {
  const formatPrice = useFormatCurrency();
  const currency = useSourcingCurrency();

  const priceWithVat = props.price.originalWithVat ?? 0;
  const vatRate = props.price.vatRate ?? 0;

  const priceWithoutVat = priceWithVat > 0 ? (priceWithVat / (100 + vatRate)) * 100 : 0;

  return (
    <Table data-testid={testIds.sourcing.classifieds('priceTable')}>
      <TableRow data-testid={testIds.sourcing.classifieds('priceTableRow-1')}>
        <Text size="xSmall">{i18n.t('entity.sourcing.vehiclePreview.labels.priceWithVat')}</Text>
        <Align right>
          <Heading size={2}>
            {formatPrice(props.originalPrice, currency?.code ?? CURRENCY_CZK, 0)}
          </Heading>
        </Align>
      </TableRow>
      <TableRow data-testid={testIds.sourcing.classifieds('priceTableRow-2')}>
        <Text size="xSmall">
          {`${i18n.t('entity.sourcing.vehiclePreview.labels.priceWithVat')} (${i18n.t(
            'entity.sourcing.vehiclePreview.labels.inOriginalCurrency'
          )})`}
        </Text>
        <Align right>
          <Text size="small">
            {formatPrice(priceWithVat, props.price.originalCurrency?.key ?? CURRENCY_CZK, 0)}
          </Text>
        </Align>
      </TableRow>
      <Show when={props.price.vatReclaimable}>
        <TableRow data-testid={testIds.sourcing.classifieds('priceTableRow-3')}>
          <Text size="xSmall">
            {`${i18n.t('entity.sourcing.vehiclePreview.labels.priceWithoutVat')} (${i18n.t(
              'entity.sourcing.vehiclePreview.labels.inOriginalCurrency'
            )})`}
          </Text>
          <Align right>
            <Text size="small" alternative>
              {formatPrice(priceWithoutVat, props.price.originalCurrency?.key ?? CURRENCY_CZK, 0)}
            </Text>
          </Align>
        </TableRow>
      </Show>
      <TableRow data-testid={testIds.sourcing.classifieds('priceTableRow-4')}>
        <Text size="xSmall">{i18n.t('entity.sourcing.vehiclePreview.labels.vatDeductible')}</Text>
        <Align right>
          <Text size="small">
            {props.price.vatReclaimable
              ? i18n.t('general.labels.yes')
              : i18n.t('general.labels.no')}
          </Text>
        </Align>
      </TableRow>
    </Table>
  );
}
