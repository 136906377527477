import {useFormatPercentage} from 'platform/locale';

import {head} from 'ramda';

import {Nullish} from 'shared';

import i18n from '../../../i18n/i18n';
import {GetMaterialDiscountGroupDiscountsApiResponse} from '../../../types/api/metadaWorkshopMaterialDiscountGroup';

export const useDiscountAttributes = (
  discounts: GetMaterialDiscountGroupDiscountsApiResponse | Nullish
) => {
  const formatPercentage = useFormatPercentage();

  return head(discounts?.materialDiscountGroup ?? [])?.discounts?.map((discount) => ({
    label: discount?.name ?? '',
    value: `${formatPercentage(discount?.discountRatio ?? 0)} ${
      discount?.discountType === 'DISCOUNT_PURCHASE_PRICE'
        ? i18n.t('entity.order.labels.marginToPurchasePrice')
        : i18n.t('entity.order.labels.discountOnSalePrice')
    }`,
  }));
};
