import {Attributes, AttributesRow, Separator} from 'platform/components';
import {Box, Heading, HStack, Text} from 'platform/foundation';
import {useFormatNumber, useFormatCurrency} from 'platform/locale';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  selectTenant,
  Money,
  EMPTY_PLACEHOLDER,
  CalculatedBrokerageFeesResponseBody,
  VatTypeEnum,
} from '@omnetic-dms/shared';
import {PriceBox, selectVatRatesByCountryCode, VatRate} from '@omnetic-dms/teas';

import {Nullish, buildArray} from 'shared';

interface BrokerageFeesDetail {
  values?: CalculatedBrokerageFeesResponseBody | null;
}

export const BusinessCasePurchaseVehicleBrokerageFeesDetail: FC<BrokerageFeesDetail> = ({
  values,
}) => {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();
  const {data} = useSelector(selectTenant);

  const vatRates = useSelector(selectVatRatesByCountryCode(data?.country))?.rates;

  const {brokerageServices, salesCommission, contractLength} = values ?? {};

  const vatTypeInvoiceBrokerageService = brokerageServices?.invoiced?.price.vatType;
  const vatRateInvoiceBrokerageService = vatTypeInvoiceBrokerageService
    ? vatRates?.find((type) => type.type === VatRate.type[vatTypeInvoiceBrokerageService])?.rate
    : undefined;

  const vatTypeInvoiceSalesCommission =
    salesCommission?.fixed?.price.vatType || salesCommission?.percentage?.price.vatType;

  const varRateInvoiceSalesCommission = vatTypeInvoiceSalesCommission
    ? vatRates?.find((type) => type.type === VatRate.type[vatTypeInvoiceSalesCommission])?.rate
    : undefined;

  const vatTypeMinimalSalesCommission = salesCommission?.minimal?.price.vatType;
  const vatRateMinimalSalesCommission = vatTypeMinimalSalesCommission
    ? vatRates?.find((type) => type.type === VatRate.type[vatTypeMinimalSalesCommission])?.rate
    : undefined;

  const isInvoiced = brokerageServices?.invoiced?.isEnabled;
  const isDeductible = brokerageServices?.invoiced?.price.vatType !== VatTypeEnum.Z;

  const contractLengthRows = [
    {
      label: i18n.t('entity.businessCase.labels.contractLength'),
      value: isNotNil(contractLength) ? contractLength : EMPTY_PLACEHOLDER,
      testId: testIds.businessCase.buying('brokerageDuration'),
    },
  ];

  const brokerageServicesRows = buildArray<AttributesRow>([
    {
      label: String(i18n.t`page.businessCase.labels.invoiceBrokerageServices`),
      value: brokerageServices?.invoiced?.isEnabled
        ? i18n.t('general.labels.yes')
        : i18n.t('general.labels.no'),
      testId: testIds.businessCase.buying('invoiceBrokerageServices'),
    },
  ]);

  brokerageServicesRows
    .when(isInvoiced && isDeductible, {
      label: i18n.t('entity.vehicle.labels.vat'),
      value: `${vatRateInvoiceBrokerageService} %`,
      testId: testIds.businessCase.buying('serviceFeeVAT'),
    })
    .when(isInvoiced && isDeductible, {
      label: String(i18n.t`entity.businessCase.labels.serviceFeeWithoutVAT`),
      value: brokerageServices?.invoiced
        ? formatCurrency(
            parseFloat(brokerageServices?.invoiced.price.withoutVat.amount),
            brokerageServices?.invoiced.price.withoutVat.currency
          )
        : null,
      testId: testIds.businessCase.buying('serviceFeeWithoutVAT'),
    })
    .when(isInvoiced, {
      label: String(i18n.t`page.businessCase.labels.serviceFee`),
      value: brokerageServices?.invoiced
        ? formatCurrency(
            parseFloat(brokerageServices?.invoiced.price.withVat.amount),
            brokerageServices?.invoiced.price.withVat.currency
          )
        : null,
      testId: testIds.businessCase.buying('serviceFee'),
    });

  const salesCommissionEnabled = salesCommission?.isEnabled;
  const salesCommissionPercentage = salesCommissionEnabled && salesCommission.percentage;
  const minimalSalesCommissionDeductible =
    salesCommission?.minimal?.price.vatType !== VatTypeEnum.Z;
  const fixedSalesCommissionDeductible =
    salesCommissionEnabled && salesCommission.minimal?.price.vatType !== VatTypeEnum.Z;

  const salesCommissionRows = buildArray<AttributesRow>([
    {
      label: String(i18n.t`page.businessCase.labels.invoiceSalesCommission`),
      value: salesCommissionEnabled ? i18n.t('general.labels.yes') : i18n.t('general.labels.no'),
      testId: testIds.businessCase.buying('invoiceSalesCommission'),
    },
  ]);

  salesCommissionRows
    .when(salesCommissionPercentage, {
      label: String(i18n.t`entity.businessCase.labels.percentageSalesCommission`),
      value: `${formatNumber(parseFloat(salesCommission?.percentage?.percentage ?? '0'), 0)} %`,
      testId: testIds.businessCase.buying('percentageSalesCommission'),
    })
    .when(salesCommissionPercentage, {
      label: i18n.t('entity.businessCase.labels.percentageAmount'),
      value: salesCommission?.percentage?.price
        ? formatCurrency(
            parseFloat(salesCommission?.percentage?.price.withoutVat.amount ?? '0'),
            salesCommission?.percentage?.price?.withoutVat?.currency ?? ''
          )
        : null,
      testId: testIds.businessCase.buying('percentageAmount'),
    })
    .when(salesCommissionPercentage && minimalSalesCommissionDeductible, {
      label: String(i18n.t`entity.businessCase.labels.salesCommissionMinimalWithoutVat`),
      value: salesCommission?.minimal
        ? formatCurrency(
            parseFloat(salesCommission?.minimal?.price.withoutVat.amount),
            salesCommission?.minimal?.price.withoutVat.currency
          )
        : null,
      testId: testIds.businessCase.buying('salesCommissionMinimalWithoutVat'),
    })
    .when(
      salesCommissionPercentage &&
        minimalSalesCommissionDeductible &&
        !fixedSalesCommissionDeductible,
      {
        label: String(i18n.t`general.labels.vat`),
        value: vatRateMinimalSalesCommission ? `${vatRateMinimalSalesCommission} %` : null,
        testId: testIds.businessCase.buying('vat'),
      }
    )
    .when(salesCommissionPercentage, {
      label: i18n.t('page.salesSettingsBusinessCase.labels.fixedPriceWithoutVat'),
      value: salesCommission?.fixed?.price
        ? formatCurrency(
            parseFloat(salesCommission?.fixed?.price.withoutVat.amount),
            salesCommission?.fixed?.price.withoutVat.currency
          )
        : null,
      testId: testIds.businessCase.buying('salesCommissionMinimal'),
    })
    .when(fixedSalesCommissionDeductible, {
      label: `${i18n.t('entity.businessCase.labels.totalSalesCommission')} ${i18n.t(
        'general.labels.withoutVat'
      )}`,
      value: salesCommission?.total?.withoutVat
        ? formatCurrency(
            parseFloat(salesCommission?.total?.withoutVat.amount),
            salesCommission?.total?.withoutVat.currency
          )
        : null,
      testId: testIds.businessCase.buying('fixedSalesCommissionWithoutVat'),
    })
    .when(fixedSalesCommissionDeductible, {
      label: String(i18n.t`general.labels.vat`),
      value: varRateInvoiceSalesCommission ? `${varRateInvoiceSalesCommission} %` : null,
      testId: testIds.businessCase.buying('vat'),
    })
    .when(salesCommissionEnabled, {
      label: `${i18n.t('entity.businessCase.labels.totalSalesCommission')} ${i18n.t(
        'general.labels.withVat'
      )}`,
      value: salesCommission?.total?.withVat
        ? formatCurrency(
            parseFloat(salesCommission?.total?.withVat.amount),
            salesCommission?.total?.withVat.currency
          )
        : null,
      testId: testIds.businessCase.buying('fixSalesCommissionPrice'),
    });

  const totalPrice: Record<'withVat' | 'woVat', Money> | undefined = salesCommission?.total?.withVat
    .amount
    ? {
        withVat: {
          amount: (
            getNumberOrZero(brokerageServices?.invoiced?.price?.withVat.amount) +
            getNumberOrZero(salesCommission?.total?.withVat.amount)
          ).toString(),
          currency: salesCommission?.total?.withVat?.currency ?? '',
        },
        woVat: {
          amount: (
            getNumberOrZero(brokerageServices?.invoiced?.price?.withoutVat.amount) +
            getNumberOrZero(salesCommission?.total?.withoutVat.amount)
          ).toString(),
          currency: salesCommission?.total?.withoutVat?.currency ?? '',
        },
      }
    : undefined;

  return (
    <>
      <Attributes
        data-testid={testIds.businessCase.buying('brokerageFees')}
        rows={contractLengthRows}
        size="third"
      />
      <Separator />
      <Box paddingBottom={4}>
        <Heading size={4}>{i18n.t('entity.businessCase.labels.brokerageFees')}</Heading>
      </Box>
      <Attributes
        data-testid={testIds.businessCase.buying('brokerageFees')}
        rows={brokerageServicesRows}
        size="third"
      />
      <Separator />
      <Box paddingBottom={4}>
        <Heading size={4}>{i18n.t('entity.businessCase.labels.salesCommission')}</Heading>
      </Box>
      <Attributes
        data-testid={testIds.businessCase.buying('brokerageFees')}
        rows={salesCommissionRows}
        size="third"
      />
      <Separator />
      <HStack justify="space-between">
        <Text color="secondary" size="xSmall">
          {i18n.t('general.labels.totalFees')}
        </Text>
        <PriceBox
          data-testid="businessCase.purchaseVehicle.brokerageFees.totalSalesCommission"
          align="right"
          isVatDeductible={true}
          price={totalPrice?.withVat}
          priceSub={totalPrice?.woVat}
        />
      </HStack>
    </>
  );
};

const getNumberOrZero = (val: string | Nullish): number => (val ? parseFloat(val) : 0);
