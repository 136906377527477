import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {Nullish} from 'utility-types';

import {isNil} from 'ramda';

import {
  CreateVehicleRequestBody,
  useChangeVehicleTypeMutation,
  useGetVehicleQuery,
} from '@omnetic-dms/shared';

type ChangedTypeResult = {
  isSuccess: boolean;
  isSkipped: boolean;
};

export const useUpdateVehicleType = (vehicleId: string | Nullish) => {
  const isInvalidId = isNil(vehicleId);
  const {data: vehicleBeforeUpdate} = useGetVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isInvalidId}
  );
  const [updateType] = useChangeVehicleTypeMutation();

  const updateVehicleType = async (
    formValues: CreateVehicleRequestBody
  ): Promise<ChangedTypeResult> => {
    const formType = formValues.type;
    const didVehicleTypeChanged = vehicleBeforeUpdate?.type !== formType;

    if (isInvalidId) {
      return {isSuccess: false, isSkipped: false};
    }

    if (!didVehicleTypeChanged) {
      return {isSuccess: false, isSkipped: true};
    }

    const updateResult = await updateType({vehicleId, type: formType});

    return {isSuccess: isSuccessResponse(updateResult), isSkipped: false};
  };

  return [updateVehicleType];
};

type SuccessResponse = {data: unknown};
type ErrorResponse = {error: FetchBaseQueryError | SerializedError};

const isSuccessResponse = (returnValue: SuccessResponse | ErrorResponse) =>
  isNil((returnValue as ErrorResponse)?.error);
