import {AxiosError} from 'axios';
import {BreadcrumbType, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {ApiException, handleApiError, useGetSeriesQuery} from '@omnetic-dms/shared';
import {SeriesService, SeriesPatternRequestBody, SeriesTypeEnum} from '@omnetic-dms/teas';

import {getApiDateString, useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentSeriesForm, DocumentSeriesFormValues} from './components/DocumentSeriesForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.document.labels.seriesTitle'),
    href: settingsRoutes.seriesDefinition,
  },
];

export function SeriesDefinitionEdit() {
  const {id} = useRequiredParams();
  const navigate = useNavigate();

  const {
    data: series,
    isLoading,
    isError,
  } = useGetSeriesQuery({seriesId: id}, {refetchOnMountOrArgChange: true});

  const onCancel = () => navigate(settingsRoutes.seriesDefinition);

  const onSubmit = async (
    values: DocumentSeriesFormValues
  ): Promise<AxiosError<ApiException> | null> => {
    const {name, type, items, unique, withPrefix} = values;
    let error = null;
    const typeValue = type.value as SeriesTypeEnum;
    const patterns = items.map(
      ({prefix, length, startingNumber, from}): SeriesPatternRequestBody => ({
        prefix,
        length: parseInt(length),
        startingNumber,
        from: from ? getApiDateString(from) : null,
      })
    );

    await SeriesService.patchSeries({
      seriesId: series?.id as string,
      requestBody: {
        name,
        type: typeValue,
        patterns,
        unique,
        withPrefix: typeValue === 'accounting/invoice' ? withPrefix : undefined,
      },
    })
      .then(() => {
        showNotification.success();
        navigate(settingsRoutes.seriesDefinition);
      })
      .catch((err) => {
        error = err as AxiosError;
        handleApiError(err.response);
      });

    return error;
  };

  return (
    <SettingsTemplate
      header={{breadcrumbs}}
      data-testid="general-document-series-modal"
      isLoading={isLoading}
      isError={isError}
    >
      <Show when={isNotNil(series)}>
        <DocumentSeriesForm series={series} onCancel={onCancel} onSubmit={onSubmit} />
      </Show>
    </SettingsTemplate>
  );
}
