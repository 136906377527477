import {Action, Actions, DropdownActionMenuItem} from 'platform/components';

import {isNotNil, pipe, replace} from 'ramda';
import {noop} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {CountryResponseBody, featureFlags, handleApiError} from '@omnetic-dms/shared';
import {
  openInNew,
  RedirectService,
  SourcingVehicleType,
  useSourcingTargetCountry,
} from '@omnetic-dms/teas';

import {buildArray, capitalize, isFeatureEnabled} from 'shared';

import {useCreatePriceReport} from '../../hooks/useCreatePriceReport';

const openAdServer = (
  externalId: string,
  serverName: string,
  targetCountry: CountryResponseBody | null
) =>
  RedirectService.redirectUrlToAdServer({
    externalId,
    serverName,
    sellerCountry: targetCountry?.code,
  })
    .then((response) => openInNew(response?.url))
    .catch(handleApiError);

interface SourcingVehicleActionsProps {
  vehicle: SourcingVehicleType;
}

export function SourcingVehicleActions(props: SourcingVehicleActionsProps) {
  const targetCountry = useSourcingTargetCountry();
  const {createPriceReport, isPriceReportCreating} = useCreatePriceReport(props.vehicle.adId);

  const isEnabledCarvagoAvailability = isFeatureEnabled(featureFlags.SOURCING_CARVAGO_AVAILABILITY);

  const targetCountryCode = targetCountry?.code;
  const adServerReadable = pipe(replace('_', '.'), capitalize)(props.vehicle.server ?? '');

  const menuItems = buildArray<DropdownActionMenuItem>()
    .add({
      leftIcon: 'action/open_in_new',
      label: `${i18n.t('general.actions.openResultOn')} ${adServerReadable}`,
      onClick: () => isNotNil(props.vehicle.url?.full) && openInNew(props.vehicle.url.full),
    })
    .add({
      leftIcon: 'content/filter_list',
      label: i18n.t('general.actions.openResultMobileDe'),
      onClick: () => openAdServer(props.vehicle.adId, 'mobile_de', targetCountry),
    })
    .when(targetCountryCode === 'CZE', {
      leftIcon: 'content/filter_list',
      label: i18n.t('general.actions.openResultSauto'),
      onClick: () => openAdServer(props.vehicle.adId, 'sauto', targetCountry),
    })
    .when(targetCountryCode && ['DEU', 'ITA', 'AUT', 'BEL'].includes(targetCountryCode), {
      leftIcon: 'content/filter_list',
      label: i18n.t('general.actions.openResultAutoscout'),
      onClick: () => openAdServer(props.vehicle.adId, 'autoscout24', targetCountry),
    })
    .when('POL' === targetCountryCode, {
      leftIcon: 'content/filter_list',
      label: i18n.t('general.actions.openResultOtomoto'),
      onClick: () => openAdServer(props.vehicle.adId, 'otomoto', targetCountry),
    })
    .when('SVK' === targetCountryCode, {
      leftIcon: 'content/filter_list',
      label: i18n.t('general.actions.openResultAutobazarEU'),
      onClick: () => openAdServer(props.vehicle.adId, 'autobazar_eu', targetCountry),
    })
    .when('ROU' === targetCountryCode, {
      leftIcon: 'content/filter_list',
      label: i18n.t('general.actions.openResultAutovit'),
      onClick: () => openAdServer(props.vehicle.adId, 'autovit_ro', targetCountry),
    })
    .when('FRA' === targetCountryCode, {
      leftIcon: 'content/filter_list',
      label: i18n.t('general.actions.openResultLacentrale'),
      onClick: () => openAdServer(props.vehicle.adId, 'lacentrale', targetCountry),
    })
    .add({
      leftIcon: 'social/poll',
      label: i18n.t('entity.sourcing.labels.getPriceReport'),
      isLoading: isPriceReportCreating,
      onClick: createPriceReport,
    })
    .when(
      isEnabledCarvagoAvailability &&
        'carvagoAvailability' in props.vehicle &&
        props.vehicle.carvagoAvailability,
      {
        leftIcon: 'action/open_in_new',
        label: i18n.t('entity.sourcing.vehicleList.actions.openVehicleOnCarvago'),
        onClick: () =>
          'carvagoAvailability' in props.vehicle ? openInNew(props.vehicle.carvagoUrl ?? '') : noop,
      }
    );

  const actions: Action[] = [
    {
      type: 'dropdown-iconButton',
      icon: 'navigation/more_horiz',
      priority: 'secondary',
      menuItems,
    },
  ];

  return (
    <Actions
      actions={actions}
      size="small"
      data-testid={testIds.sourcing.classifieds('sourcingVehicleActions-actions')}
    />
  );
}
