import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isNil} from 'ramda';

// import {match} from 'ts-pattern';
import {
  downloadFile,
  handleApiError,
  useRenderBalanceInvoiceDocumentMutation,
  useRenderCorrectiveTaxDocumentMutation,
  useRenderInvoiceDocumentMutation,
  useRenderInvoiceProformaDocumentMutation,
  useRenderTaxDocumentForPaymentDocumentMutation,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {InvoiceTypes} from '../pages/InvoiceOverview/types';

export const useDownloadDocument = () => {
  const [
    getProformaDocument,
    {isLoading: loadingProformaDocument, isError: isProformaDocumentError},
  ] = useRenderInvoiceProformaDocumentMutation();
  const [getInvoiceDocument, {isLoading: loadingInvoiceDocument, isError: isInvoiceDocumentError}] =
    useRenderInvoiceDocumentMutation();
  const [
    getBalanceInvoiceDocument,
    {isLoading: loadingBalanceInvoiceDocument, isError: isBalanceInvoiceDocumentError},
  ] = useRenderBalanceInvoiceDocumentMutation();
  const [
    getCorrectiveTaxDocument,
    {isLoading: loadingCorrectiveTaxDocument, isError: isCorrectiveTaxDocumentError},
  ] = useRenderCorrectiveTaxDocumentMutation();
  const [
    getTaxDocumentForPayment,
    {isLoading: loadingTaxDocumentForPayment, isError: isTaxDocumentForPaymentError},
  ] = useRenderTaxDocumentForPaymentDocumentMutation();

  const isLoading =
    loadingProformaDocument ||
    loadingInvoiceDocument ||
    loadingBalanceInvoiceDocument ||
    loadingCorrectiveTaxDocument ||
    loadingTaxDocumentForPayment;
  const isError =
    isProformaDocumentError ||
    isInvoiceDocumentError ||
    isBalanceInvoiceDocumentError ||
    isCorrectiveTaxDocumentError ||
    isTaxDocumentForPaymentError;

  const downloadInvoice = async (
    invoiceType: InvoiceTypes | Nullish,
    invoiceId: string,
    isMargin = false
  ) => {
    if (isNil(invoiceType)) {
      showNotification.error();
      return;
    }

    const requestBody = {
      documentId: invoiceId,
      isMarginPrint: isMargin,
    };

    const getDownloadAction = () =>
      match(invoiceType)
        .with('PROFORMA', () =>
          getProformaDocument({
            renderInvoiceProformaDocumentRequestBody: requestBody,
          })
        )
        .with('TAX_DOCUMENT_FOR_PAYMENT', () =>
          getTaxDocumentForPayment({
            renderTaxDocumentForPaymentDocumentRequestBody: requestBody,
          })
        )
        .with('BALANCE_INVOICE', () =>
          getBalanceInvoiceDocument({
            renderBalanceInvoiceDocumentRequestBody: requestBody,
          })
        )
        .with('CORRECTIVE_TAX_DOCUMENT', () =>
          getCorrectiveTaxDocument({
            renderCorrectiveTaxDocumentRequestBody: requestBody,
          })
        )
        .otherwise(() =>
          getInvoiceDocument({
            renderInvoiceDocumentRequestBody: requestBody,
          })
        );

    await getDownloadAction()
      .unwrap()
      .then((res) => res.pdfUrl)
      .then(downloadFile)
      .catch(handleApiError);
  };

  return [downloadInvoice, {isLoading, isError}] as const;
};
