import {ThemeIconKey} from 'platform/foundation';
import {LocaleDateTimeFormat} from 'platform/locale';

import i18n from '@omnetic-dms/i18n';
import {VehicleAuditStatusLogs} from '@omnetic-dms/shared';

import {parseDate} from 'shared';

import {getStatusTranslation} from './getStatusTranslation';

export const getPopoverHeaderData = (
  formatDateTime: (dateTimeFormat: LocaleDateTimeFormat, value: Date) => string,
  data?: VehicleAuditStatusLogs | null
) => {
  if (!data) {
    return null;
  }

  const newData: {title: string; describe: string; icon: ThemeIconKey}[] = data.map(
    (item, index) => {
      const isItLastIndex = index === data.length - 1;

      const resultTitle = isItLastIndex
        ? `${i18n.t('page.inspection.created')}`
        : `${i18n.t('page.inspection.changeTo')} ${getStatusTranslation(item.status).toLowerCase()}`;

      return {
        title: resultTitle,
        describe: `${formatDateTime('dateTimeLong', parseDate(item.updatedAt))} • ${item.author.label}`,
        icon: 'paste',
      };
    }
  );
  return newData;
};
