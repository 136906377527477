import {Table, TableRow} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {useGetBillingInformationListQuery} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TableCell} from './components/TableCell';
import {TableHeader} from './components/TableHeader';

const tableColumns = [
  {
    element: <TableHeader title={i18n.t('entity.customer.labels.billingName')} />,
    id: 'billingName',
  },
  {
    element: <TableHeader title={i18n.t('entity.customer.labels.companyName')} />,
    id: 'companyName',
  },
  {
    element: <TableHeader title={i18n.t('entity.address.labels.city')} />,
    id: 'city',
  },
  {
    element: <TableHeader title={i18n.t('entity.address.labels.street')} />,
    id: 'street',
  },
  {
    element: <TableHeader title={i18n.t('entity.customer.labels.registrationNumber')} />,
    id: 'registrationNumber',
  },
  {
    element: null,
    id: 'action',
  },
];

export function BillingInformationOverview() {
  const {data: billingInformation, isLoading, isError} = useGetBillingInformationListQuery();
  const navigate = useNavigate();

  const handleCreateNewBillingInformation = () => navigate(settingsRoutes.billingInformationNew);

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.invoice.labels.billingInformationManagement'),
        actions: [
          {
            type: 'button',
            leftIcon: 'content/add_circle',
            title: i18n.t('entity.customer.actions.addBillingInformation'),
            onClick: handleCreateNewBillingInformation,
          },
        ],
      }}
      data-testid="settings-billingInformation"
      description={i18n.t('page.settings.labels.billingInformationDescription')}
      isError={isError}
      isLoading={isLoading}
    >
      <Table columns={tableColumns}>
        {billingInformation?.billingInformationListItems?.map((row) => {
          const editBillingInfo = () =>
            navigate(composePath(settingsRoutes.billingInformationEdit, {params: {id: row.id}}));

          return (
            <TableRow
              key={row.registrationNumber}
              onClick={editBillingInfo}
              actions={{
                secondary: [
                  {
                    icon: 'image/edit',
                    onClick: editBillingInfo,
                    title: i18n.t('general.actions.edit'),
                  },
                ],
              }}
            >
              <TableCell value={row.billingName} />
              <TableCell value={row.companyName} />
              <TableCell value={row.city} />
              <TableCell value={row.street} />
              <TableCell value={row.registrationNumber} />
            </TableRow>
          );
        })}
      </Table>
    </SettingsTemplate>
  );
}
