import {
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, HStack, Link, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isEmptyString, isFalse, isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  DiscountAttributes,
  handleApiError,
  useGetCustomerContractByIdQuery,
  useGetMaterialDiscountGroupsQuery,
  usePatchCustomerContractMutation,
  usePostCustomerContractMutation,
} from '@omnetic-dms/shared';

import {Nullish, yupString, composePath} from 'shared';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';

type BasicInformationFormType = {
  name: string | Nullish;
  description: string | Nullish;
  materialDiscountGroupId: string | Nullish;
};

export function BasicInformationTab() {
  const {id} = useParams();
  const navigate = useNavigate();

  const {data, isLoading, isError} = useGetCustomerContractByIdQuery(
    {customerContractId: id ?? ''},
    {skip: isNilOrEmpty(id)}
  );
  const {data: materialDiscountGroups, isLoading: isMaterialDiscountGroupsLoading} =
    useGetMaterialDiscountGroupsQuery();

  const [patchCustomerContract] = usePatchCustomerContractMutation();
  const [postCustomerContract] = usePostCustomerContractMutation();

  const handleSubmit: FormSubmitHandler<BasicInformationFormType> = (data) =>
    (isNotNil(id) && !isEmptyString(id)
      ? patchCustomerContract({
          customerContractId: id,
          body: {
            name: data.name ?? '',
            description: data.description,
            groupId: data.materialDiscountGroupId,
          },
        })
      : postCustomerContract({
          body: {
            name: data.name ?? '',
            description: data.description,
            materialDiscountGroupId: data.materialDiscountGroupId,
          },
        })
    )
      .unwrap()
      .then((response) => {
        showNotification.success();
        if (isNotNil(response?.id)) {
          navigate(
            composePath(settingsRoutes.customerContractsDetail, {params: {id: response?.id}})
          );
        }
      })
      .catch(handleApiError);

  const materialDiscountGroupOptions = materialDiscountGroups
    ?.filter((group) => group?.isActive || group?.id === data?.materialDiscountGroupId)
    .map((group) => ({
      label: group?.name,
      value: group?.id,
      isDisabled: isFalse(group?.isActive),
    }));

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <SettingsSection>
        <Form<BasicInformationFormType>
          defaultValues={data}
          onSubmit={handleSubmit}
          schema={formSchema}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="name"
                    type="text"
                    label={i18n.t('general.labels.name')}
                    isRequired
                    data-testid={testIds.settings.customerContractsDetail('name')}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="description"
                    type="text"
                    label={i18n.t('general.labels.description')}
                    data-testid={testIds.settings.customerContractsDetail('description')}
                  />
                </Box>
              </HStack>
              <Card variant="inlineGrey">
                <VStack spacing={4}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="materialDiscountGroupId"
                        type="choice"
                        label={i18n.t('entity.customerContract.labels.materialDiscountGroup')}
                        options={materialDiscountGroupOptions}
                        isLoading={isMaterialDiscountGroupsLoading}
                        data-testid={testIds.settings.customerContractsDetail('discountGroup')}
                      />
                    </Box>
                    <Box paddingTop={6} flex={1}>
                      <Link
                        title={i18n.t('general.labels.edit')}
                        leftIcon="image/edit"
                        size="small"
                        isDisabled={isNil(formApi.watch('materialDiscountGroupId'))}
                        data-testid={testIds.settings.customerContractsDetail(
                          'materialDiscountGroup'
                        )}
                        onClick={() =>
                          navigate(
                            composePath(settingsRoutes.workshopCustomerGroupDetail, {
                              params: {
                                id: formApi.watch('materialDiscountGroupId'),
                              },
                            })
                          )
                        }
                      />
                    </Box>
                  </HStack>
                  <DiscountAttributes
                    groupId={formApi.watch('materialDiscountGroupId')}
                    data-testid={testIds.settings.customerContractsDetail('discounts')}
                  />
                </VStack>
              </Card>
              <SettingsFooter
                actions={[
                  {
                    title: i18n.t('general.actions.discard'),
                    variant: 'secondary',
                    onClick: () => navigate(settingsRoutes.customerContracts),
                    type: 'button',
                  },
                  {
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    title: isNotNil(id)
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.create'),
                  },
                ]}
                data-testid={testIds.settings.customerContractsDetail('footer')}
              />
            </VStack>
          )}
        </Form>
      </SettingsSection>
    </DataStatus>
  );
}

const formSchema = object({
  name: yupString.required(),
  description: yupString,
  materialDiscountGroupId: yupString,
});
