import {Alpha3CountryCode, Label, PhoneInput, PhoneNumber} from 'platform/components';

import {FC} from 'react';
import {Field as FinalFormField, FieldMetaState, UseFieldConfig} from 'react-final-form';
import {useSelector} from 'react-redux';

import {selectTenant} from '@omnetic-dms/shared';

import {Nullish, TestIdProps} from 'shared';

import {selectPhoneNumber, selectPhoneNumbers} from '../../store/common/selectors';
import {FormFieldWrapper} from '../FinalForm/components/FormFieldWrapper';

type PhoneFieldProps = {
  name: string;
  label?: string | Nullish;
  labelTooltip?: string | Nullish;
  parse?: UseFieldConfig<PhoneNumber>['parse'];
  defaultValue?: any;
  getIsValid: (meta: FieldMetaState<unknown>) => boolean;
  getErrorMessage: (meta: FieldMetaState<unknown>) => string | undefined;
  commonFieldProps?: {
    isRequired?: boolean;
    isDisabled?: boolean;
  };
} & TestIdProps;

/**
 * @deprecated - use platform instead
 */
export const PhoneField: FC<PhoneFieldProps> = ({
  name,
  label,
  parse,
  commonFieldProps = {},
  defaultValue,
  getIsValid,
  getErrorMessage,
  ...props
}) => {
  const phoneList = useSelector(selectPhoneNumbers);
  const {data: {country} = {}} = useSelector(selectTenant);

  const phoneNumber = useSelector(selectPhoneNumber(country));

  const defaultPhoneNumber = phoneNumber
    ? {countryCode: phoneNumber.code, prefix: phoneNumber.prefix, number: ''}
    : {countryCode: country};

  return (
    <FinalFormField<PhoneNumber>
      name={name}
      parse={parse}
      defaultValue={defaultValue || defaultPhoneNumber}
    >
      {({input, meta}) => (
        <FormFieldWrapper data-testid={props['data-testid']}>
          {label && (
            <Label isRequired={commonFieldProps.isRequired} tooltip={props.labelTooltip}>
              {label}
            </Label>
          )}
          <PhoneInput
            {...commonFieldProps}
            isInvalid={!getIsValid(meta)}
            countries={phoneList.map((phone) => ({
              countryCode: phone.code as Alpha3CountryCode,
              dialCode: phone.prefix,
              name: phone.name,
            }))}
            preferredCountries={phoneList
              .filter((phone) => phone.preferred)
              .map((phone) => phone.code as Alpha3CountryCode)}
            value={(input.value || defaultPhoneNumber) as PhoneNumber}
            onChange={input.onChange}
            isDisabled={commonFieldProps.isDisabled}
            data-testid={props['data-testid']}
            errorMessage={getErrorMessage(meta)}
          />
        </FormFieldWrapper>
      )}
    </FinalFormField>
  );
};
