import {Button, TextInput} from 'platform/components';
import {HStack, Hide, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {suffixTestId, Nullish, TestIdProps} from 'shared';

import {useGetTreeFolderPathQuery} from '../../api/metadaTreeFolderApi';
import i18n from '../../i18n/i18n';

interface TreeFolderPathProps extends TestIdProps {
  leafId: string | Nullish;
  onEdit?: VoidFunction;
}

export function TreeFolderPath(props: TreeFolderPathProps) {
  const {data} = useGetTreeFolderPathQuery(
    {leafId: props.leafId ?? ''},
    {skip: isNil(props.leafId)}
  );

  return (
    <VStack>
      <TextInput
        value={data?.treeFolder?.pathName ?? null}
        isDisabled
        label={i18n.t('general.labels.category')}
        data-testid={suffixTestId('treeFolderPathInput', props)}
      />
      <Hide when={isNil(props.onEdit)}>
        <HStack>
          <Button
            variant="link"
            onClick={props.onEdit}
            title={i18n.t('general.labels.editCategory')}
            leftIcon="image/edit"
            data-testid={suffixTestId('editCategory', props)}
          />
        </HStack>
      </Hide>
    </VStack>
  );
}
