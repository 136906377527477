import {VinDecoderDecodeApiArg, VinDecoderDecodeApiResponse} from '../types/api/vinDecoder';
import {omneticApi as api} from './baseApi/omneticApi';

export const vinDecoderApi = api.injectEndpoints({
  endpoints: (build) => ({
    vinDecoderDecode: build.query<VinDecoderDecodeApiResponse, VinDecoderDecodeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vin-decoder/${queryArg.vin}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useVinDecoderDecodeQuery, useLazyVinDecoderDecodeQuery} = vinDecoderApi;
