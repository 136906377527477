import {ActionCallback, DataGrid} from 'platform/datagrid';

import {useCallback} from 'react';

import {path, prop} from 'ramda';
import {isArray, isString} from 'ramda-adjunct';

import {vehiclesRoutes} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

export function InspectionOverview() {
  const navigateTo = useNavigate();

  const actionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, rowData}) => {
      if (!isString(rowId) || isArray(rowData)) {
        return;
      }

      switch (actionKey) {
        case 'detail':
          navigateTo(
            composePath(vehiclesRoutes.inspectionDetail, {
              params: {
                id: path(['vehicleId', 'value'], rowData) as string,
                inspectionId: prop('id', rowData) as string,
              },
            })
          );
          break;
        default:
          // TODO: Create warn utils T20-17310
          // eslint-disable-next-line no-console
          console.warn(`Action callback was not specified for action ${actionKey}`);
          break;
      }
    },
    [navigateTo]
  );

  return (
    <DataGrid data-testid="inspection" gridCode="inspection" actionCallback={actionCallback} />
  );
}
