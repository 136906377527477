import {z} from 'zod';

import {BasePriceSchema, PriceWithAndWithoutVatSchema} from './aftersalesCommon';
import {BaseIdSchema, BaseVoidResponseSchema} from './base';

/**
 * Base types
 */
export const BaseDirectSaleIdSchema = z.object({
  directSaleId: z.string(),
});

export const DiscountSourceTypeSchema = z.enum([
  'customer-contract',
  'custom-discount',
  'manual-discount',
]);
export type DiscountSourceType = z.infer<typeof DiscountSourceTypeSchema>;

const DiscountSchema = z.object({
  isDoNotApplyDiscount: z.boolean(),
  isDiscountEnable: z.boolean(),
  discountSourceType: DiscountSourceTypeSchema,
  discountRate: z.number(),
});
export type Discount = z.infer<typeof DiscountSchema>;

export const BaseDirectSaleActionSchema = z.object({
  actionKey: z.enum([
    'discount',
    'variantChange',
    'offer',
    'checkout',
    'lock',
    'archive',
    'unlock',
    'reopen',
  ]),
  newDirectSaleVariantId: z.string().optional(),
});
export type BaseDirectSaleAction = z.infer<typeof BaseDirectSaleActionSchema>;

const BaseDirectSaleSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  branchId: z.string(),
  directSaleVariantId: z.string(),
  authorizationProfileId: z.string(),
  number: z.string(),
  state: z.enum(['ARCHIVED', 'OPEN', 'LOCKED', 'CLOSED']),
  name: z.string().optional(),
  vehicleId: z.string().optional(),
  customerId: z.string().optional(),
  description: z.string().optional(),
  neededAt: z.string().optional(),
  createdAt: z.string(),
  createdBy: z.string(),
  variantName: z.string(),
  tooltip: z.object({
    authorizationProfile: z.string().optional(),
    directSaleType: z.string().optional(),
    isUnitPriceWithVat: z.string().optional(),
    priceType: z.string().optional(),
    priceTypeRatio: z.number().optional(),
    minimalMaterialMargin: z.string().optional(),
    rateType: z.string().optional(),
    rateTypeRatio: z.number().optional(),
    minimalWorkMargin: z.string().optional(),
    note: z.string().optional(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
});
export type BaseDirectSale = z.infer<typeof BaseDirectSaleSchema>;

const BaseCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
});

const BaseDirectSaleLabourCatalogueSchema = z.object({
  id: z.string(),
  category: BaseCategorySchema,
});
export type BaseDirectSaleLabourCatalogue = z.infer<typeof BaseDirectSaleLabourCatalogueSchema>;

const TooltipSchema = z.object({
  requestType: z.string(),
  warehouseName: z.string(),
  manufacturerName: z.string(),
  articleLocation: z.string(),
  dispensingUnit: z.number(),
  discountRate: z.string(),
  currentMargin: z.string(),
  purchasePrice: z.string(),
  recommendedPrice: z.string(),
  saleBasePrice: z.string(),
  additionalDescription: z.string(),
});

const BaseDirectSaleMaterialItemSchema = z.object({
  id: z.string(),
  warehouse_id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  requestType: z.string(),
  name: z.string(),
  number: z.string(),
  quantity: z.number(),
  unit: z.string(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: TooltipSchema,
});
export type BaseDirectSaleMaterialItem = z.infer<typeof BaseDirectSaleMaterialItemSchema>;

const BaseDirectSaleLabourItemSchema = z.object({
  id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  priceTypeName: z.string(),
  name: z.string(),
  number: z.string(),
  labourCatalog: BaseDirectSaleLabourCatalogueSchema,
  technican: z.string(),
  quantity: z.number(),
  unit: z.string(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.object({
    priceType: z.string(),
    workType: z.string(),
    doNotApplyDiscount: z.string(),
  }),
});
export type BaseDirectSaleLabourItem = z.infer<typeof BaseDirectSaleLabourItemSchema>;

const BaseDirectSaleActionKeySchema = z.enum([
  'discount',
  'variantChange',
  'offer',
  'checkout',
  'lock',
  'archive',
  'unlock',
  'reopen',
]);

const TypeSchema = z.enum(['list-item', 'divider']);

const StyleSchema = z.enum(['enable', 'hidden', 'disabled']);

const DirectSaleActionsOptionSchema = z.object({
  type: TypeSchema,
  key: BaseDirectSaleActionKeySchema,
  style: StyleSchema,
  position: z.number(),
});
export type DirectSaleActionsOption = z.infer<typeof DirectSaleActionsOptionSchema>;

export const DirectSaleActionsSchema = z.object({
  optionClick: z.string(),
  options: z.array(DirectSaleActionsOptionSchema),
});
export type DirectSaleActions = z.infer<typeof DirectSaleActionsSchema>;

const AssignedMechanicSchema = z.object({
  id: z.union([z.string(), z.null()]).optional(),
  isDefault: z.union([z.boolean(), z.null()]).optional(),
  costCenterId: z.union([z.string(), z.null()]).optional(),
});

const BaseDirectSaleAssignMechanicsRequestSchema = z.object({
  directSaleId: z.string(),
  body: z
    .object({
      assignMechanics: AssignedMechanicSchema,
    })
    .optional(),
});
export type BaseDirectSaleAssignMechanicsRequest = z.infer<
  typeof BaseDirectSaleAssignMechanicsRequestSchema
>;

/**
 * GET requests
 */
export const GetDirectSaleRequestSchema = BaseDirectSaleIdSchema;
export type GetDirectSaleRequest = z.infer<typeof GetDirectSaleRequestSchema>;

export const GetDirectSaleResponseSchema = BaseDirectSaleSchema;
export type GetDirectSaleResponse = z.infer<typeof GetDirectSaleResponseSchema>;

export const GetDirectSaleMaterialBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type GetDirectSaleMaterialBasketItemsRequest = z.infer<
  typeof GetDirectSaleMaterialBasketItemsRequestSchema
>;

export const GetDirectSaleMaterialBasketItemsResponseSchema = z.object({
  sparePartsBasketItem: z.array(BaseDirectSaleMaterialItemSchema),
  sparePartsBasketTotalPrice: PriceWithAndWithoutVatSchema,
  assignMechanicId: z.string(),
});
export type GetDirectSaleMaterialBasketItemsResponse = z.infer<
  typeof GetDirectSaleMaterialBasketItemsResponseSchema
>;

export const GetDirectSaleLabourBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type GetDirectSaleLabourBasketItemsRequest = z.infer<
  typeof GetDirectSaleLabourBasketItemsRequestSchema
>;

export const GetDirectSaleLabourBasketItemsResponseSchema = z.object({
  workBasketItem: z.array(BaseDirectSaleLabourItemSchema),
  workBasketTotalPrice: PriceWithAndWithoutVatSchema,
  assignMechanicId: z.string(),
});
export type GetDirectSaleLabourBasketItemsResponse = z.infer<
  typeof GetDirectSaleLabourBasketItemsResponseSchema
>;

export const GetDirectSaleActionsRequestSchema = z.object({
  directSaleId: z.string(),
});
export type GetDirectSaleActionsRequest = z.infer<typeof GetDirectSaleActionsRequestSchema>;

export const GetDirectSaleActionsResponseSchema = z.object({
  directSaleActions: DirectSaleActionsSchema,
});
export type GetDirectSaleActionsResponse = z.infer<typeof GetDirectSaleActionsResponseSchema>;

export const GetDirectSaleMaterialItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
});
export type GetDirectSaleMaterialItemRequest = z.infer<
  typeof GetDirectSaleMaterialItemRequestSchema
>;

const WarehouseSchema = z.object({
  warehouseId: z.string(),
  warehouseArticleId: z.string(),
  storageLocation: z.string().optional(),
  discountGroup: z.string().optional(),
  warehouseAction: z.string(),
});

const QuantitySchema = z.object({
  amount: z.number(),
  unit: z.string(),
});

const VatDetailsSchema = z.object({
  type: z.string(),
  freeCode: z.string().optional(),
});

const MarginSchema = PriceWithAndWithoutVatSchema.merge(
  z.object({
    marginPercentage: z.number().nullable(),
    isMarginUnderMinimum: z.boolean(),
    isMarginExisting: z.boolean(),
  })
);

const AvailableDiscountSchema = z.object({
  discountSourceType: DiscountSourceTypeSchema,
  discountSourceTypeName: z.string(),
  discountRate: z.number(),
  isSet: z.boolean(),
  isChangeable: z.boolean(),
});
export type AvailableDiscount = z.infer<typeof AvailableDiscountSchema>;

const ItemsDiscountsSchema = z.object({
  isDoNotApplyDiscount: z.boolean(),
  isDiscountEnable: z.boolean(),
  availableDiscounts: z.array(AvailableDiscountSchema).optional(),
});
export type ItemsDiscounts = z.infer<typeof ItemsDiscountsSchema>;

export const GetDirectSaleMaterialItemResponseSchema = z.object({
  id: z.string(),
  warehouse: WarehouseSchema,
  name: z.string(),
  number: z.string(),
  neededAt: z.string().optional(),
  quantity: QuantitySchema,
  dispensingUnit: z.number(),
  isUnitPriceWithVat: z.boolean(),
  purchasePricePerUnit: BasePriceSchema,
  sellingPricePerUnit: BasePriceSchema,
  sellingPriceVat: VatDetailsSchema,
  articlePrice: PriceWithAndWithoutVatSchema,
  articlePriceIndicator: z.enum(['EQUAL', 'HIGHER', 'LOWER', 'NO_PRICE']),
  assignMechanic: z.string().optional(),
  isCustomPrice: z.boolean(),
  baseSellingPricePerUnit: BasePriceSchema,
  baseSellingPriceVat: z.object({type: z.string()}),
  discountSource: z.string().optional(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
  isAllowedItemEditing: z.boolean(),
  isAllowedQuantityEditing: z.boolean(),
  itemsDiscounts: ItemsDiscountsSchema,
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
});
export type GetDirectSaleMaterialItemResponse = z.infer<
  typeof GetDirectSaleMaterialItemResponseSchema
>;

export const GetDirectSaleLabourItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
});
export type GetDirectSaleLabourItemRequest = z.infer<typeof GetDirectSaleLabourItemRequestSchema>;

const MechanicSchema = z.object({
  id: z.string(),
  name: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string(),
  amount: z.number(),
});

const VATDetailsSchema = z.object({
  type: z.string(),
  freeCode: z.string().optional(),
});

const PriceTypeSchema = z.enum([
  'SVCPRICETYPE_TIME_NORM',
  'SVCPRICETYPE_DIRECT_PRICE',
  'SVCPRICETYPE_CALCULATION_PRICE',
  'SVCPRICETYPE_COOPERATION',
  'SVCPRICETYPE_SUBCONTRACT',
]);

export type PriceType = z.infer<typeof PriceTypeSchema>;

export const GetDirectSaleLabourItemResponseSchema = z.object({
  id: z.string(),
  labourCatalogId: z.string(),
  labourRateId: z.string(),
  priceType: PriceTypeSchema,
  name: z.string(),
  number: z.string(),
  workType: z.string(),
  quantity: QuantitySchema,
  isUnitPriceWithVat: z.boolean(),
  purchasePricePerUnit: BasePriceSchema,
  sellingPricePerUnit: BasePriceSchema,
  sellingPriceVat: VATDetailsSchema,
  isCustomPrice: z.boolean(),
  basePurchasePricePerUnit: BasePriceSchema,
  baseSellingPricePerUnit: BasePriceSchema,
  baseSellingPriceVat: VATDetailsSchema,
  assignMechanics: z.array(MechanicSchema).optional(),
  discountSource: z.string().optional(),
  itemEditingAllowed: z.boolean(),
  quantityEditingAllowed: z.boolean(),
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
  itemsDiscounts: ItemsDiscountsSchema,
});
export type GetDirectSaleLabourItemResponse = z.infer<typeof GetDirectSaleLabourItemResponseSchema>;

export const GetDirectSaleDiscountRequestSchema = z.object({
  directSaleId: z.string(),
});
export type GetDirectSaleDiscountRequest = z.infer<typeof GetDirectSaleDiscountRequestSchema>;

const DiscountWorkRateSchema = z.object({
  workType: z.string(),
  workRateWithoutVat: BasePriceSchema,
});
export type DiscountWorkRate = z.infer<typeof DiscountWorkRateSchema>;

const MaterialDiscountSchema = z
  .object({
    discountToSalePrice: z.number().optional(),
    markupToPurchasePrice: z.number().optional(),
  })
  .nullable();

const WorkDiscountSchema = z
  .object({
    percentageDiscount: z.number().optional(),
    discountedWorkRate: z.array(DiscountWorkRateSchema).optional(),
  })
  .nullable();

const DirectSaleDiscountSchema = z.object({
  workDiscount: WorkDiscountSchema,
  materialDiscount: MaterialDiscountSchema,
});

export const GetDirectSaleDiscountResponseSchema = DirectSaleDiscountSchema;
export type GetDirectSaleDiscountResponse = z.infer<typeof GetDirectSaleDiscountResponseSchema>;

/**
 * POST requests
 */
export const PostDirectSaleRequestSchema = z.object({
  directSaleVariantId: z.string(),
});
export type PostDirectSaleRequest = z.infer<typeof PostDirectSaleRequestSchema>;

export const PostDirectSaleResponseSchema = BaseIdSchema;
export type PostDirectSaleResponse = z.infer<typeof PostDirectSaleResponseSchema>;

export const PostDirectSaleMaterialItemsMechanicRequestSchema =
  BaseDirectSaleAssignMechanicsRequestSchema;
export type PostDirectSaleMaterialItemsMechanicRequest = z.infer<
  typeof PostDirectSaleMaterialItemsMechanicRequestSchema
>;

export const PostDirectSaleMaterialItemsMechanicResponseSchema = BaseVoidResponseSchema;
export type PostDirectSaleMaterialItemsMechanicResponse = z.infer<
  typeof PostDirectSaleMaterialItemsMechanicResponseSchema
>;

export const PostDirectSaleLabourItemsMechanicRequestSchema =
  BaseDirectSaleAssignMechanicsRequestSchema;
export type PostDirectSaleLabourItemsMechanicRequest = z.infer<
  typeof PostDirectSaleLabourItemsMechanicRequestSchema
>;

export const PostDirectSaleLabourItemsMechanicResponseSchema = BaseVoidResponseSchema;
export type PostDirectSaleLabourItemsMechanicResponse = z.infer<
  typeof PostDirectSaleLabourItemsMechanicResponseSchema
>;

export const PostDirectSaleMaterialItemToBasketRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    warehouseArticleId: z.string(),
  }),
});
export type PostDirectSaleMaterialItemToBasketRequest = z.infer<
  typeof PostDirectSaleMaterialItemToBasketRequestSchema
>;

export const PostDirectSaleMaterialItemToBasketResponseSchema = z.object({
  sparePartItem: BaseIdSchema,
});
export type PostDirectSaleMaterialItemToBasketResponse = z.infer<
  typeof PostDirectSaleMaterialItemToBasketResponseSchema
>;

export const PostDirectSaleLabourItemToBasketRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    labourCatalogId: z.string(),
  }),
});
export type PostDirectSaleLabourItemToBasketRequest = z.infer<
  typeof PostDirectSaleLabourItemToBasketRequestSchema
>;

export const PostDirectSaleLabourItemToBasketResponseSchema = z.object({
  workBasketItem: BaseIdSchema,
});
export type PostDirectSaleLabourItemToBasketResponse = z.infer<
  typeof PostDirectSaleLabourItemToBasketResponseSchema
>;

const AssignedMechanicWithAmountSchema = AssignedMechanicSchema.extend({
  amount: z.number().optional().nullable(),
});

const PostDirectSaleLabourItemCalculatePriceRequestBodySchema = z.object({
  quantity: z.number().optional().nullable(),
  workType: z.string().optional().nullable(),
  sellingPricePerUnit: z.number().optional().nullable(),
  isCustomPrice: z.boolean().optional().nullable(),
  vatType: z.string().optional().nullable(),
  assignMechanics: z.array(AssignedMechanicWithAmountSchema).optional().nullable(),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().nullish(),
    isDiscountEnable: z.boolean().nullish(),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
  }),
  getCurrentPrice: z.boolean().optional().nullable(),
});

export const PostDirectSaleLabourItemCalculatePriceRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PostDirectSaleLabourItemCalculatePriceRequestBodySchema,
});
export type PostDirectSaleLabourItemCalculatePriceRequest = z.infer<
  typeof PostDirectSaleLabourItemCalculatePriceRequestSchema
>;

const ArticlePriceIndicatorSchema = z.enum(['EQUAL', 'HIGHER', 'LOWER', 'NO_PRICE']);
export type ArticlePriceIndicator = z.infer<typeof ArticlePriceIndicatorSchema>;

const MaterialRecalculatedPricesSchema = z.object({
  articlePrice: PriceWithAndWithoutVatSchema,
  articlePriceIndicator: ArticlePriceIndicatorSchema,
  sellingPricePerUnit: BasePriceSchema,
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
  isCustomPrice: z.boolean(),
  itemsDiscounts: ItemsDiscountsSchema,
});

const LabourRecalculatedPricesSchema = z.object({
  sellingPricePerUnit: BasePriceSchema,
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
  isCustomPrice: z.boolean(),
  itemsDiscounts: ItemsDiscountsSchema,
  minimalWorkMargin: z.number().nullish(),
});

export const PostDirectSaleLabourItemCalculatePriceResponseSchema = LabourRecalculatedPricesSchema;
export type PostDirectSaleLabourItemCalculatePriceResponse = z.infer<
  typeof PostDirectSaleLabourItemCalculatePriceResponseSchema
>;

const PostDirectSaleMaterialItemCalculatePriceRequestBodySchema = z.object({
  quantity: z.number().nullable().optional(),
  sellingPricePerUnit: z.number().nullable().optional(),
  vatType: z.string().nullable().optional(),
  isCustomPrice: z.boolean().nullable().optional(),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().nullish(),
    isDiscountEnable: z.boolean().nullish(),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
  }),
  getCurrentPrice: z.boolean().nullable().optional(),
});

export const PostDirectSaleMaterialItemCalculatePriceRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PostDirectSaleMaterialItemCalculatePriceRequestBodySchema,
});
export type PostDirectSaleMaterialItemCalculatePriceRequest = z.infer<
  typeof PostDirectSaleMaterialItemCalculatePriceRequestSchema
>;

export const PostDirectSaleMaterialItemCalculatePriceResponseSchema =
  MaterialRecalculatedPricesSchema;
export type PostDirectSaleMaterialItemCalculatePriceResponse = z.infer<
  typeof PostDirectSaleMaterialItemCalculatePriceResponseSchema
>;

/**
 * PATCH requests
 */
const PatchDirectSaleRequestBodySchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  vehicleId: z.union([z.string(), z.null()]).optional(),
  customerId: z.union([z.string(), z.null()]).optional(),
  neededAt: z.union([z.string(), z.null()]).optional(),
  isItemNeededAtUpdate: z.union([z.boolean(), z.null()]).optional(),
});

export const PatchDirectSaleRequestSchema = z.object({
  directSaleId: z.string(),
  body: PatchDirectSaleRequestBodySchema,
});
export type PatchDirectSaleRequest = z.infer<typeof PatchDirectSaleRequestSchema>;

export const PatchDirectSaleResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleResponse = z.infer<typeof PatchDirectSaleResponseSchema>;

export const PatchDirectSaleMaterialBasketItemQuantityRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: z.object({
    quantity: z.number(),
  }),
});
export type PatchDirectSaleMaterialBasketItemQuantityRequest = z.infer<
  typeof PatchDirectSaleMaterialBasketItemQuantityRequestSchema
>;

export const PatchDirectSaleMaterialBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleMaterialBasketItemQuantityResponse = z.infer<
  typeof PatchDirectSaleMaterialBasketItemQuantityResponseSchema
>;

export const PatchDirectSaleLabourBasketItemQuantityRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: z.object({
    quantity: z.number(),
  }),
});
export type PatchDirectSaleLabourBasketItemQuantityRequest = z.infer<
  typeof PatchDirectSaleLabourBasketItemQuantityRequestSchema
>;

export const PatchDirectSaleLabourBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleLabourBasketItemQuantityResponse = z.infer<
  typeof PatchDirectSaleLabourBasketItemQuantityResponseSchema
>;

const PatchDirectSaleMaterialItemRequestBodySchema = z.object({
  quantity: z.number(),
  isUnitPriceWithVat: z.boolean(),
  sellingPricePerUnit: z.number(),
  vatType: z.union([z.string(), z.null()]),
  vatFreeCode: z.union([z.string(), z.null()]),
  mechanic: z.string(),
  isCustomPrice: z.boolean(),
  neededAt: z.string(),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().nullish(),
    isDiscountEnable: z.boolean().nullish(),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
  }),
});

export const PatchDirectSaleMaterialItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PatchDirectSaleMaterialItemRequestBodySchema,
});
export type PatchDirectSaleMaterialItemRequest = z.infer<
  typeof PatchDirectSaleMaterialItemRequestSchema
>;

export const PatchDirectSaleMaterialItemResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleMaterialItemResponse = z.infer<
  typeof PatchDirectSaleMaterialItemResponseSchema
>;

const BaseDirectSalePriceTypeSchema = z
  .enum([
    'SVCPRICETYPE_TIME_NORM',
    'SVCPRICETYPE_DIRECT_PRICE',
    'SVCPRICETYPE_CALCULATION_PRICE',
    'SVCPRICETYPE_COOPERATION_PRICE',
  ])
  .nullable();
export type BaseDirectSalePriceType = z.infer<typeof BaseDirectSalePriceTypeSchema>;

const PatchDirectSaleLabourItemRequestBodySchema = z.object({
  basePriceType: BaseDirectSalePriceTypeSchema,
  name: z.string(),
  number: z.string(),
  workType: z.string(),
  assignMechanics: z.array(AssignedMechanicWithAmountSchema),
  quantity: z.number(),
  isUnitPriceWithVat: z.boolean(),
  purchasePricePerUnit: z.number().optional().nullable(),
  sellingPricePerUnit: z.number(),
  vatType: z.string(),
  vatFreeCode: z.string(),
  isCustomPrice: z.boolean(),
  discount: z.object({
    isDiscountEnable: z.boolean(),
    discountSourceType: DiscountSourceTypeSchema,
    discountRate: z.number(),
    isDoNotApplyDiscount: z.boolean(),
  }),
});

export const PatchDirectSaleLabourItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PatchDirectSaleLabourItemRequestBodySchema,
});
export type PatchDirectSaleLabourItemRequest = z.infer<
  typeof PatchDirectSaleLabourItemRequestSchema
>;

export const PatchDirectSaleLabourItemResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleLabourItemResponse = z.infer<
  typeof PatchDirectSaleLabourItemResponseSchema
>;

export const PatchDirectSaleGetCurrentPriceRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    directSaleItemId: z.array(z.string()),
  }),
});
export type PatchDirectSaleGetCurrentPriceRequest = z.infer<
  typeof PatchDirectSaleGetCurrentPriceRequestSchema
>;

export const PatchDirectSaleGetCurrentPriceResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleGetCurrentPriceResponse = z.infer<
  typeof PatchDirectSaleGetCurrentPriceResponseSchema
>;

/**
 * DELETE requests
 */
export const DeleteDirectSaleMaterialBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type DeleteDirectSaleMaterialBasketItemsRequest = z.infer<
  typeof DeleteDirectSaleMaterialBasketItemsRequestSchema
>;

export const DeleteDirectSaleMaterialBasketItemsResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleMaterialBasketItemsResponse = z.infer<
  typeof DeleteDirectSaleMaterialBasketItemsResponseSchema
>;

export const DeleteDirectSaleLabourBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type DeleteDirectSaleLabourBasketItemsRequest = z.infer<
  typeof DeleteDirectSaleLabourBasketItemsRequestSchema
>;

export const DeleteDirectSaleLabourBasketItemsResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleLabourBasketItemsResponse = z.infer<
  typeof DeleteDirectSaleLabourBasketItemsResponseSchema
>;

export const DeleteDirectSaleLabourBasketItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
});
export type DeleteDirectSaleLabourBasketItemRequest = z.infer<
  typeof DeleteDirectSaleLabourBasketItemRequestSchema
>;

export const DeleteDirectSaleLabourBasketItemResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleLabourBasketItemResponse = z.infer<
  typeof DeleteDirectSaleLabourBasketItemResponseSchema
>;

export const BulkDeleteDirectSaleItemsRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    items: z.array(z.object({id: z.string()})),
  }),
});
export type BulkDeleteDirectSaleItemsRequest = z.infer<
  typeof BulkDeleteDirectSaleItemsRequestSchema
>;

export const BulkDeleteDirectSaleItemsResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteDirectSaleItemsResponse = z.infer<
  typeof BulkDeleteDirectSaleItemsResponseSchema
>;

/**
 * PUT requests
 */
export const PutDirectSaleActionRequestSchema = z.object({
  directSaleId: z.string(),
  body: BaseDirectSaleActionSchema,
});
export type PutDirectSaleActionRequest = z.infer<typeof PutDirectSaleActionRequestSchema>;

export const PutDirectSaleActionResponseSchema = BaseVoidResponseSchema;
export type PutDirectSaleActionResponse = z.infer<typeof PutDirectSaleActionResponseSchema>;

export const PutDirectSaleDiscountRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    directSaleDiscount: DirectSaleDiscountSchema,
    isDiscountApply: z.boolean(),
  }),
});
export type PutDirectSaleDiscountRequest = z.infer<typeof PutDirectSaleDiscountRequestSchema>;

export const PutDirectSaleDiscountResponseSchema = BaseVoidResponseSchema;
export type PutDirectSaleDiscountResponse = z.infer<typeof PutDirectSaleDiscountResponseSchema>;
