import {FormControl} from 'platform/components';

import {useCallback, useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AccountingDocumentRecalculateApiArg,
  AccountingDocumentRecalculateItemRes,
  PaymentType,
  VatCheckTotalsDefinitionDocumentTypeEnum,
  featureFlags,
  handleApiError,
  useGetCurrentBranch,
  useGetTenantQuery,
  useInvoicingDocumentRecalculateMutation,
  useTaxDocumentCalculationV2Mutation,
} from '@omnetic-dms/shared';

import {Nullish, isFeatureEnabled} from 'shared';

import {
  ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
} from '../../../constants';
import {AccountingDocumentFormValues} from '../../../types';
import {useGetInvoiceItemsForRecalculation} from '../hooks/useGetInvoiceItemsForRecalculation';
import {isValidIndex} from '../utils/isValidIndex';
import {useGetInvoiceCurrency} from './useGetInvoiceCurrency';
import {UpdateRowType, useRerenderRows} from './useRerenderRows';

type RecalculateInvoiceItemsArgs = {
  control: FormControl<AccountingDocumentFormValues>;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  listItemType: VatCheckTotalsDefinitionDocumentTypeEnum;
  isCorrectiveTaxDocument?: boolean;
  shouldCallOnInitialization?: boolean;
};

type RecalculateSummaryArgs =
  | (Omit<AccountingDocumentRecalculateApiArg, 'items'> & {
      items: AccountingDocumentRecalculateItemRes[];
    })
  | Nullish;

export const useRecalculateInvoiceItems = ({
  control,
  formApi,
  listItemType,
  isCorrectiveTaxDocument = false,
  shouldCallOnInitialization = false,
}: RecalculateInvoiceItemsArgs) => {
  const {activeBranchId} = useGetCurrentBranch();
  const {data: tenant} = useGetTenantQuery();

  const [currency] = useGetInvoiceCurrency(control, listItemType);
  const [mapInvoiceItemsForRecalculation] = useGetInvoiceItemsForRecalculation(
    isCorrectiveTaxDocument,
    currency
  );

  const [calculateTaxDocument] = useInvoicingDocumentRecalculateMutation({
    fixedCacheKey: ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });

  const [recalculateSummary] = useTaxDocumentCalculationV2Mutation({
    fixedCacheKey: TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });

  const {updateDiscount, updateMargin, updateStandardItem, updateAllItems, resetInvalidDiscounts} =
    useRerenderRows(formApi);

  const recalculateInvoiceSummary = useCallback(
    (recalculateProps: RecalculateSummaryArgs) => {
      if (isNil(recalculateProps)) {
        return;
      }

      const {items, dueDate, ...args} = recalculateProps;

      const invoiceItems = items.map((item) => {
        if (item.type === 'discount') {
          return {
            isUnitPriceWithVat: item.isUnitPriceWithVat,
            quantity: '1',
            reference: item.itemId,
            unitPrice: item.unitPrice.toString() ?? '0',
            vatType: item.vatType,
            relatedItemId: item.relatedItemId ?? undefined,
          };
        }

        return {
          isUnitPriceWithVat: item.isUnitPriceWithVat,
          quantity: item.quantity ?? '0',
          reference: item.itemId,
          unitPrice: item.unitPrice ?? '0',
          vatType: item.vatType,
          relatedItemId: item.relatedItemId ?? undefined,
        };
      });

      return recalculateSummary({
        taxDocumentCalculationRequestBody: {
          ...args,
          taxableSupplyDate: dueDate,
          items: invoiceItems,
        },
      })
        .unwrap()
        .catch(handleApiError);
    },
    [recalculateSummary]
  );

  const recalculate = useCallback(
    (itemId?: string) => {
      if (isNil(activeBranchId) || isNil(tenant)) {
        return;
      }

      const {
        invoiceItems,
        dateOfTaxableSupply,
        paymentInfo,
        isExchangeRateRatioEnabled,
        exchangeRateRatio,
      } = formApi.getValues();

      const items = mapInvoiceItemsForRecalculation(invoiceItems);
      // check if all necessary invoice fields are present
      if (
        isNilOrEmpty(dateOfTaxableSupply) ||
        isNilOrEmpty(paymentInfo?.paymentMethod) ||
        isNilOrEmpty(items)
      ) {
        return;
      }

      // this is necessary due to difference between calc EP and invoiceApiResponse
      const paymentType =
        paymentInfo.paymentMethod === 'CARD'
          ? 'payment_card'
          : (paymentInfo.paymentMethod.toLowerCase() as PaymentType);

      const exchangeRatio =
        isExchangeRateRatioEnabled &&
        isNotNilOrEmpty(exchangeRateRatio?.amount) &&
        isNotNilOrEmpty(exchangeRateRatio?.ratio) &&
        isFeatureEnabled(featureFlags.SALES_FOREIGN_CURRENCY)
          ? {
              ratio: exchangeRateRatio?.ratio?.toString() ?? '',
              amount: parseInt(exchangeRateRatio?.amount ?? ''),
              currency: exchangeRateRatio?.currency ?? '',
            }
          : null;

      const recalculateInvoiceDocumentBody = {
        vatCheckTotalsDocumentType: listItemType,
        dueDate: dateOfTaxableSupply,
        country: tenant.country,
        exchangeRateRatio: exchangeRatio,
        branchId: activeBranchId,
        paymentType,
        currency,
        items,
      };

      calculateTaxDocument(recalculateInvoiceDocumentBody)
        .unwrap()
        .then((data) => {
          resetInvalidDiscounts({invoiceItems});

          if (isNil(itemId)) {
            updateAllItems({invoiceItems, items: data.items});
            return {...recalculateInvoiceDocumentBody, items: data.items};
          }

          const updatedItemIndex = invoiceItems.findIndex((item) => item.itemId === itemId);
          const updatedItem = data.items.find((item) => item.itemId === itemId);

          if (isNil(updatedItem) || isNil(itemId) || !isValidIndex(updatedItemIndex)) {
            return {...recalculateInvoiceDocumentBody, items: data.items};
          }

          const updateRowProps: UpdateRowType = {
            invoiceItems,
            updatedItem,
            updatedItemIndex,
            items: data.items,
          };

          if (updatedItem.type === 'discount') {
            updateDiscount(updateRowProps);
          }
          if (updatedItem.type === 'margin') {
            updateMargin(updateRowProps);
          }
          if (updatedItem.type === 'standard') {
            updateStandardItem(updateRowProps);
          }

          return {...recalculateInvoiceDocumentBody, items: data.items};
        })
        .then(recalculateInvoiceSummary)
        .catch((err) => handleApiError(err, {silent: true}));
    },
    [
      activeBranchId,
      calculateTaxDocument,
      currency,
      formApi,
      listItemType,
      mapInvoiceItemsForRecalculation,
      recalculateInvoiceSummary,
      resetInvalidDiscounts,
      tenant,
      updateAllItems,
      updateDiscount,
      updateMargin,
      updateStandardItem,
    ]
  );

  useEffect(() => {
    if (shouldCallOnInitialization) {
      recalculate();
    }
  }, [shouldCallOnInitialization, recalculate]);

  return [recalculate];
};
