import {
  Action,
  Attributes,
  AttributesRow,
  Card,
  closeCurrentDialog,
  DataStatus,
  openDialog,
  showNotification,
} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Box, Heading, HStack, Show, Space, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {always, defaultTo, head, isNil, mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {workshopRoutes} from '@omnetic-dms/routes';
import {
  catchUnhandledDataGridActions,
  EditMaterialItem,
  getCustomerName,
  GetServiceOrderIssueNoteResponse,
  getUserName,
  handleApiError,
  Section,
  useBulkDeleteServiceOrderIssueNoteItemsMutation,
  useGetCustomerV2Query,
  useGetUserQuery,
  useGetVehicleQuery,
} from '@omnetic-dms/shared';

import {
  composePath,
  EMPTY_PLACEHOLDER,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
  useBoolean,
} from 'shared';

import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../constants/missingEditingDetailsMessage';
import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {ServiceOrderIssueAddMaterialModal} from './components/ServiceOrderIssueAddMaterialModal';

interface OverviewProps extends RequiredTestIdProps {
  isServiceOrderIssueLoading: boolean;
  priceWithoutVat: string | null;
  priceWithVat: string | null;
  serviceOrderIssueNote?: GetServiceOrderIssueNoteResponse;
}

export function Overview(props: OverviewProps) {
  const {serviceOrderIssueNoteId} = useWarehouseParams();
  const [workItemsDatagridRef, refreshWorkItemsDatagrid] = useRefreshDataGrid();
  const [isAddMaterialModalVisible, , , toggleAddMaterialModal] = useBoolean();

  const formatDateTime = useDateTimeFormatter();

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', props.serviceOrderIssueNote?.serviceOrder.vehicleId)},
    {skip: isNil(props.serviceOrderIssueNote?.serviceOrder.vehicleId)}
  );

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: hasCustomerError,
  } = useGetCustomerV2Query(
    {customerId: defaultTo('', props.serviceOrderIssueNote?.serviceOrder.customerId)},
    {skip: isNil(props.serviceOrderIssueNote?.serviceOrder.customerId)}
  );

  const {data: userWhoIssued, isLoading: isUserWhoIssuedLoading} = useGetUserQuery({
    id: defaultTo('', props.serviceOrderIssueNote?.updatedBy),
  });

  const [deleteItems] = useBulkDeleteServiceOrderIssueNoteItemsMutation();

  const isLoading =
    props.isServiceOrderIssueLoading ||
    isVehicleLoading ||
    isCustomerLoading ||
    isUserWhoIssuedLoading;

  const isError = hasVehicleError || hasCustomerError;

  const handleSubmitAddMaterial = () => {
    toggleAddMaterialModal();
    refreshWorkItemsDatagrid();
  };

  const handleViewOrderInNewTab = () => {
    window.open(
      composePath(workshopRoutes.serviceCaseDetail, {
        params: {id: props.serviceOrderIssueNote?.serviceCaseId},
      }),
      '_blank'
    );
  };

  const getCurrentDateAndTime = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDateTime('dateTimeShort', parseDate(date));
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      throw new Error('Row id is not defined');
    }

    match(actionKey)
      .with('edit', () => {
        const serviceCaseId = defaultTo('', props.serviceOrderIssueNote?.serviceCaseId);
        const serviceOrderId = defaultTo('', props.serviceOrderIssueNote?.serviceOrderId);

        const itemName = path(['itemName', 'value'], rowData) as string;
        const serviceOrderJobItemId = path(['serviceOrderJobItemId', 'value'], rowData) as string;
        const serviceCaseJobId = path(['serviceCaseJobId', 'value'], rowData) as string;

        if (isNil(itemName) || isNil(serviceCaseJobId) || isNil(serviceOrderJobItemId)) {
          throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
        }

        openDialog(
          <EditMaterialItem
            itemId={serviceOrderJobItemId}
            serviceCaseId={serviceCaseId}
            serviceOrderId={serviceOrderId}
            serviceJobId={serviceCaseJobId}
            onEdited={refreshData}
            onClose={closeCurrentDialog}
          />,
          {title: itemName, scrollBehavior: 'outside'}
        );
      })
      .with('delete', () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        deleteItems({serviceOrderIssueNoteId, body: {serviceOrderIssueNoteItemIds: ids}})
          .unwrap()
          .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
          .then(refreshData)
          .catch(handleApiError);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderIssueNoteId}]),
    [serviceOrderIssueNoteId]
  );

  const customerName = getCustomerName(customer);

  const basicInformationAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.issuedBy'),
      value: getUserName(userWhoIssued),
    },
    {
      label: i18n.t('general.labels.receivedBy'),
      value: props.serviceOrderIssueNote?.mechanic?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: getCurrentDateAndTime(props.serviceOrderIssueNote?.createdAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfIssue'),
      value: getCurrentDateAndTime(props.serviceOrderIssueNote?.updatedAt),
    },
  ];

  const serviceOrderAttributes: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderNumber'),
      value: props.serviceOrderIssueNote?.serviceOrder.number,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderType'),
      value: props.serviceOrderIssueNote?.serviceOrder.type,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderVariant'),
      value: props.serviceOrderIssueNote?.serviceOrder.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: customerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: vehicle?.title,
    },
  ];

  const priceWithoutVat = `${props.priceWithoutVat} ${i18n.t('general.labels.w/oVat')}`;

  const workItemsActions = match(props.serviceOrderIssueNote?.state)
    .returnType<Action[] | undefined>()
    .with(
      'PENDING',
      always([
        {
          type: 'button',
          variant: 'ghostLink',
          leftIcon: 'content/add_circle',
          title: i18n.t('general.actions.add'),
          onClick: toggleAddMaterialModal,
          'data-testid': suffixTestId('action.add', props),
        },
      ])
    )
    .otherwise(always(undefined));

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={60}>
        <Card title={i18n.t('general.labels.basicInformation')}>
          <Attributes
            rows={basicInformationAttributes}
            size="quarter"
            data-testid={suffixTestId('basicInformationAttributes', props)}
          />

          <Space vertical={4} />

          <Card
            title={i18n.t('entity.warehouse.labels.serviceOrder')}
            variant="inlineGrey"
            actions={[
              {
                type: 'button',
                variant: 'link',
                leftIcon: 'action/launch',
                title: i18n.t('entity.warehouse.actions.viewOrder'),
                onClick: handleViewOrderInNewTab,
              },
            ]}
          >
            <Attributes
              rows={serviceOrderAttributes}
              size="quarter"
              data-testid={suffixTestId('serviceOrderAttributes', props)}
            />
          </Card>
        </Card>

        <Space vertical={4} />

        <Card title={i18n.t('entity.warehouse.labels.workItems')} actions={workItemsActions}>
          <Box height="30vh">
            <DataGrid
              ref={workItemsDatagridRef}
              gridCode="issue-note-service-order-added-items"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              data-testid="issueNoteServiceOrderAddedItemsDatagrid"
            />
          </Box>

          <HStack minHeight={15} align="center" justify="space-between">
            <Heading size={5}>{i18n.t('general.labels.totalPrice')}</Heading>
            <HStack spacing={2}>
              <Text size="small" color="tertiary">
                {priceWithoutVat}
              </Text>
              <Heading size={5}>{props.priceWithVat}</Heading>
            </HStack>
          </HStack>
        </Card>
      </DataStatus>

      <Show when={isAddMaterialModalVisible}>
        <ServiceOrderIssueAddMaterialModal
          onDiscard={toggleAddMaterialModal}
          onSubmit={handleSubmitAddMaterial}
          data-testid={suffixTestId('dialogs.addMaterial', props)}
        />
      </Show>
    </Section>
  );
}
