import {FormSubmitHandler} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {TestIdProps} from 'shared';

import {useTenant} from '../../hooks/useTenant';
import {AddressRequestBodyV2} from '../../types/api/customer';
import {AddressFormCze} from './AddressFormCze';
import {AddressFormPol} from './AddressFormPol';
import {AddressFormSvk} from './AddressFormSvk';

interface InternationalAddressFormProps extends TestIdProps {
  isLoading?: boolean;
  isEdit?: boolean;
  address?: AddressRequestBodyV2;
  onSubmit: FormSubmitHandler<AddressRequestBodyV2>;
  onClose?: () => void;
}

export function InternationalAddressForm(props: InternationalAddressFormProps) {
  const {tenantCountry} = useTenant();

  return (
    <>
      {match(tenantCountry)
        .with(
          'POL',
          always(
            <AddressFormPol
              isEdit={props.isEdit}
              isLoading={props.isLoading}
              address={props.address}
              onSubmit={props.onSubmit}
              data-testid={props['data-testid']}
            />
          )
        )
        .with(
          'SVK',
          always(
            <AddressFormSvk
              isEdit={props.isEdit}
              isLoading={props.isLoading}
              address={props.address}
              onSubmit={props.onSubmit}
              data-testid={props['data-testid']}
            />
          )
        )
        .otherwise(
          always(
            <AddressFormCze
              isEdit={props.isEdit}
              isLoading={props.isLoading}
              address={props.address}
              onSubmit={props.onSubmit}
              data-testid={props['data-testid']}
            />
          )
        )}
    </>
  );
}
