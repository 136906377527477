import {
  Action,
  AlertProps,
  DataStatus,
  FlagProps,
  Parameter,
  PreviewAction,
} from 'platform/components';
import {CSSDimension, Integer, Show, ThemeIconKey} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {EntityResourceIds} from '../../types/api/api';
import {EntityHeaderControl} from '../EntityHeader/components/EntityHeaderControls';
import {SnippetProps} from '../EntityHeader/components/Snippet';
import {EntityHeader} from '../EntityHeader/EntityHeader';
import {NavigationItem, Page} from '../Page/Page';
import {SectionItem, Sections} from '../Sections/Sections';
import {ControlledSection} from '../Sections/types/ControlledSection';

export type DetailTemplateHeader = {
  title: string;
  height?: CSSDimension | Integer;
  icon?: ThemeIconKey;
  image?: string;
  parameters?: Parameter[];
  flags?: Omit<FlagProps, 'size'>[];
  secondaryParameter?: string;
  primaryParameter?: string;
  snippets?: SnippetProps[];
  actions?: Action[];
  resourceId?: EntityResourceIds;
  recordId?: string;
  imageActions?: PreviewAction[];
  iconActions?: PreviewAction[];
  controls?: EntityHeaderControl[];
  alert?: AlertProps;
};

type NavigationOrSection =
  | {
      sections?: never;
      navigation: NavigationItem[];
    }
  | {
      sections: SectionItem[];
      navigation?: never;
    };

type DetailTemplateProps = {
  isLoading: boolean;
  isError: boolean;
  header?: DetailTemplateHeader;
  defaultSection?: string;
  isTagDeletable?: boolean;
  isTagUpdatable?: boolean;
  isTagReadable?: boolean;
  isTagCreatable?: boolean;
  isTagAssignable?: boolean;
  isTagUnassignable?: boolean;
} & NavigationOrSection &
  RequiredTestIdProps &
  ControlledSection;

export function DetailTemplate(props: DetailTemplateProps) {
  return (
    <>
      <DataStatus
        isLoading={!props.header || props.isLoading}
        isError={props.isError}
        minHeight={props.header?.height ?? 61}
      >
        <Show when={isNotNilOrEmpty(props.sections)}>
          <EntityHeader
            title={props.header?.title}
            parameters={props.header?.parameters}
            image={props.header?.image}
            icon={props.header?.icon}
            flags={props.header?.flags}
            primaryParameter={props.header?.primaryParameter}
            secondaryParameter={props.header?.secondaryParameter}
            snippets={props.header?.snippets}
            actions={props.header?.actions}
            recordId={props.header?.recordId}
            resourceId={props.header?.resourceId}
            imageActions={props.header?.imageActions}
            iconActions={props.header?.iconActions}
            controls={props.header?.controls}
            data-testid={suffixTestId('detail', props)}
            isTagDeletable={props.isTagDeletable}
            isTagUpdatable={props.isTagUpdatable}
            isTagReadable={props.isTagReadable}
            isTagCreatable={props.isTagCreatable}
            isTagAssignable={props.isTagAssignable}
            isTagUnassignable={props.isTagUnassignable}
            alert={props.header?.alert}
          />
          <Sections
            sections={props.sections as SectionItem[]}
            defaultSection={props.defaultSection}
            data-testid={suffixTestId('detail', props)}
            activeSectionId={props.activeSectionId}
            onSectionChange={props.onSectionChange}
          />
        </Show>
        <Show when={isNotNilOrEmpty(props.navigation)}>
          <Page
            isFullHeight
            navigation={props.navigation}
            data-testid={suffixTestId('detail', props)}
            header={
              <EntityHeader
                title={props.header?.title}
                parameters={props.header?.parameters}
                image={props.header?.image}
                icon={props.header?.icon}
                flags={props.header?.flags}
                primaryParameter={props.header?.primaryParameter}
                secondaryParameter={props.header?.secondaryParameter}
                snippets={props.header?.snippets}
                actions={props.header?.actions}
                recordId={props.header?.recordId}
                resourceId={props.header?.resourceId}
                imageActions={props.header?.imageActions}
                controls={props.header?.controls}
                data-testid={suffixTestId('detail', props)}
                isTagDeletable={props.isTagDeletable}
                isTagUpdatable={props.isTagUpdatable}
                isTagReadable={props.isTagReadable}
                isTagCreatable={props.isTagCreatable}
                isTagAssignable={props.isTagAssignable}
                isTagUnassignable={props.isTagUnassignable}
              />
            }
          />
        </Show>
      </DataStatus>
    </>
  );
}
