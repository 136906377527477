import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {Action, Attributes, AttributesRow, Card, DataStatus, openDialog} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {useGetCustomersV2Query, useGetVehicleInsuranceQuery} from '@omnetic-dms/shared';

import {buildArray, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {VehicleInsuranceEditModal} from './VehicleInsuranceEditModal';

interface VehicleInsuranceProps extends RequiredTestIdProps {
  vehicleId: string;
  canEditVehicle: boolean;
}

export function VehicleInsurance(props: VehicleInsuranceProps) {
  const {data, isLoading, isError, error} = useGetVehicleInsuranceQuery({
    vehicleId: props.vehicleId,
  });
  const {data: insuranceCompanies} = useGetCustomersV2Query({
    institutions: ['INSURANCE'],
  });
  const formatDateTime = useDateTimeFormatter();
  const isInsuranceNotFound = isError && (error as FetchBaseQueryError).status === 404;
  const hasCustomerInsurance = !isInsuranceNotFound && isNotNil(data?.customerInsurance);
  const hasFleetInsurance = !isInsuranceNotFound && isNotNil(data?.fleetInsurance);

  const getCompanyName = (companyId?: string | null) =>
    insuranceCompanies?.find((company) => company.id === companyId)?.businessInfo?.businessInfo
      ?.tradeName;

  const getDate = (date?: string | null) =>
    isNotNil(date) ? formatDateTime('dateShort', parseDate(date)) : null;

  const cardActions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'image/edit',
      title: i18n.t('general.actions.edit'),
      onClick: () =>
        openDialog(<VehicleInsuranceEditModal vehicleId={props.vehicleId} />, {
          title: i18n.t('entity.vehicle.labels.insurance'),
          scrollBehavior: 'outside',
        }),
    },
  ];

  const attributeRows = buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.vehicle.labels.customerInsurance'),
      value: i18n.t(`general.labels.${hasCustomerInsurance ? 'yes' : 'no'}`),
    })
    .when(hasCustomerInsurance, {
      label: i18n.t('entity.vehicle.labels.liabilityInsuranceCompany'),
      value: getCompanyName(data?.customerInsurance?.liabilityInsurance?.institutionId),
    })
    .when(hasCustomerInsurance, {
      label: i18n.t('entity.vehicle.labels.liabilityInsuranceDate'),
      value: getDate(data?.customerInsurance?.liabilityInsurance?.date),
    })
    .when(hasCustomerInsurance, {
      label: i18n.t('entity.vehicle.labels.liabilityInsuranceNumber'),
      value: data?.customerInsurance?.liabilityInsurance?.contractNumber,
    })
    .when(hasCustomerInsurance, {
      label: i18n.t('entity.vehicle.labels.collisionInsuranceCompany'),
      value: getCompanyName(data?.customerInsurance?.collisionInsurance?.institutionId),
    })
    .when(hasCustomerInsurance, {
      label: i18n.t('entity.vehicle.labels.collisionInsuranceDate'),
      value: getDate(data?.customerInsurance?.collisionInsurance?.date),
    })
    .when(hasCustomerInsurance, {
      label: i18n.t('entity.vehicle.labels.collisionInsuranceNumber'),
      value: data?.customerInsurance?.collisionInsurance?.contractNumber,
    })
    .when(hasCustomerInsurance, {
      label: i18n.t('general.labels.note'),
      value: data?.customerInsurance?.note,
    })
    .add({
      label: i18n.t('entity.vehicle.labels.fleetInsurance'),
      value: i18n.t(`general.labels.${hasFleetInsurance ? 'yes' : 'no'}`),
    })
    .when(hasFleetInsurance, {
      label: i18n.t('entity.vehicle.labels.fleetInsuranceDate'),
      value: getDate(data?.fleetInsurance?.date),
    })
    .when(hasFleetInsurance, {
      label: i18n.t('general.labels.note'),
      value: data?.fleetInsurance?.note,
    });

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.insurance')}
      actions={props.canEditVehicle ? cardActions : undefined}
    >
      <DataStatus
        isLoading={isLoading}
        isError={isError && (error as FetchBaseQueryError).status !== 404}
      >
        <Attributes data-testid={suffixTestId('vehicle-insurance', props)} rows={attributeRows} />
      </DataStatus>
    </Card>
  );
}
