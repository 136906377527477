import {useContext} from 'react';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/shared';
import {VehicleDetail} from '@omnetic-dms/teas';

import {SourcingContextType} from '../types/SourcingContextType';
import {SourcingContext} from '../utils/sourcingContext';

export const useSourcingContext = <
  V extends VehicleDetail = SourcingVehicleDetailResponseBody,
  // eslint-disable-next-line no-restricted-syntax
>(): SourcingContextType<V> => useContext(SourcingContext) as unknown as SourcingContextType<V>;
