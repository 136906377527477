import {Action, Card, DataStatus} from 'platform/components';
import {DataGrid, QueryFilterObject} from 'platform/datagrid';
import {Box, HStack, Space, VStack, Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useCallback, useState} from 'react';

import {defaultTo, equals, isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  DEFAULT_CURRENCY,
  GetDeliveryNoteResponse,
  Section,
  useGetAuthorizationProfilesQuery,
  useGetCurrenciesQuery,
  useGetPaymentTypeEnumQuery,
  useGetSuppliersQuery,
  useGetTenantQuery,
  useGetWarehousesQuery,
} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {getSupplierById} from '../../../../utils/getSupplierById';
import {getWarehouseById} from '../../../../utils/getWarehouseById';
import {BasicInformationForm} from './components/BasicInformationForm';
import {BasicInformationReadOnly} from './components/BasicInformationReadOnly';

interface OverviewProps extends RequiredTestIdProps {
  deliveryNote: GetDeliveryNoteResponse | Nullish;
  isLoading: boolean;
  hasError: boolean;
}

export function Overview(props: OverviewProps) {
  const {deliveryNoteId} = useWarehouseParams();
  const currencyFormatter = useFormatCurrency();

  const [isReadOnly, setIsReadOnly] = useState<boolean>(isNotNilOrEmpty(deliveryNoteId));

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: warehouses,
    isLoading: areWarehousesLoading,
    isError: hasWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: currencies,
    isLoading: areCurrenciesLoading,
    isError: hasCurrenciesError,
  } = useGetCurrenciesQuery();

  const {
    data: paymentTypes,
    isLoading: arePaymentTypesLoading,
    isError: hasPaymentTypesError,
  } = useGetPaymentTypeEnumQuery();

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: areAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});

  const isLoading =
    props.isLoading ||
    areWarehousesLoading ||
    areSuppliersLoading ||
    areCurrenciesLoading ||
    arePaymentTypesLoading ||
    isTenantLoading ||
    areAuthorizationProfilesLoading;

  const hasError =
    props.hasError ||
    hasWarehousesError ||
    hasSuppliersError ||
    hasCurrenciesError ||
    hasPaymentTypesError ||
    hasTenantError ||
    hasAuthorizationProfilesError;

  const isDeliveryNoteReceived = props.deliveryNote?.state === 'RECEIVED';
  const isDeliveryNoteCompleted = props.deliveryNote?.state === 'COMPLETED';

  const supplierByDeliveryNote = getSupplierById(suppliers ?? [], props.deliveryNote?.supplierId);

  const warehouseByDeliveryNote = getWarehouseById(warehouses, props.deliveryNote?.warehouseId);

  const paymentTypeByDeliveryNote = paymentTypes?.find((paymentType) =>
    equals(paymentType.key, props.deliveryNote?.paymentType)
  );

  const authorizationProfileByDeliveryNote = authorizationProfiles?.find((authorizationProfile) =>
    equals(authorizationProfile.id, props.deliveryNote?.authorizationProfileId)
  );

  const totalPriceWithoutVat = currencyFormatter(
    defaultTo(0, props.deliveryNote?.totalValue),
    defaultTo(DEFAULT_CURRENCY, props.deliveryNote?.currency),
    2
  );

  const actions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'image/edit',
      title: i18n.t('general.actions.edit'),
      isDisabled: isDeliveryNoteReceived || isDeliveryNoteCompleted,
      onClick: () => setIsReadOnly(false),
    },
  ];

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      deliveryNoteId,
    }),
    [deliveryNoteId]
  );

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={isLoading} isError={hasError} minHeight="100vh">
        <VStack spacing={4}>
          <Card
            title={i18n.t('general.labels.basicInformation')}
            actions={isReadOnly ? actions : undefined}
            data-testid={suffixTestId('card.basicInformation', props)}
          >
            {isReadOnly ? (
              <BasicInformationReadOnly
                deliveryNote={props.deliveryNote}
                supplier={supplierByDeliveryNote}
                warehouse={warehouseByDeliveryNote}
                authorizationProfile={authorizationProfileByDeliveryNote}
                paymentType={paymentTypeByDeliveryNote}
              />
            ) : (
              <BasicInformationForm
                deliveryNote={props.deliveryNote}
                suppliers={suppliers}
                warehouses={warehouses}
                authorizationProfiles={authorizationProfiles}
                paymentTypes={paymentTypes}
                currencies={currencies}
                onClose={() => setIsReadOnly(true)}
                data-testid={testIds.warehouse.deliveryNoteDetailOverview('form.basicInformation')}
              />
            )}
          </Card>

          <Card
            title={i18n.t('general.labels.listOfItems')}
            data-testid={testIds.warehouse.deliveryNoteDetailOverview('card.listOfItems')}
          >
            <DataStatus isEmpty={isNilOrEmpty(deliveryNoteId)}>
              <Box height={100}>
                <DataGrid
                  gridCode="warehouse-delivery-note-items"
                  queryModifier={queryModifier}
                  data-testid={suffixTestId('deliveryNoteItems', props)}
                />
              </Box>
              <Box padding={2}>
                <HStack height={10} align="center">
                  <Text alternative>{i18n.t('general.labels.totalPrice')}</Text>
                  <Space fillAvailable />
                  <Text alternative>
                    {totalPriceWithoutVat} {i18n.t('general.labels.w/oVat')}
                  </Text>
                </HStack>
              </Box>
            </DataStatus>
          </Card>
        </VStack>
      </DataStatus>
    </Section>
  );
}
