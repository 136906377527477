/* eslint-disable filename-export/match-named-export */
import {z} from 'zod';

import {CustomFieldValue, CustomFieldValueSchema} from '../../utils/additionalFields';
import {Alpha3} from '../common/Alpha3';
import {NoteRequestBody, NoteResponseBody} from '../common/Note';
import {PhoneNumber, PhoneNumberSchema} from '../common/PhoneNumber';
import {RecordIdHttpModel} from './api';

export type GetSimilarCustomersApiResponse = /** status 200  */ SimilarCustomersResponseBody;
export type GetSimilarCustomersApiArg = {
  customerDiscriminator: string;
  size?: number;
  page?: number;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  registrationNumber?: string | null;
  tradeName?: string | null;
};
export type GetCustomerSeriesDefinitionListApiResponse =
  /** status 200  */ CustomerSeriesDefinitionResponseBody[];
export type GetCustomerSeriesDefinitionListApiArg = void;
export type GetCustomerSeriesDefinitionApiResponse =
  /** status 200  */ CustomerSeriesDefinitionResponseBody;
export type GetCustomerSeriesDefinitionApiArg = {
  seriesDefinitionId: string;
};
export type CreateCustomerAddressApiResponse = /** status 201  */ AddressResponseBody;
export type CreateCustomerAddressApiArg = {
  customerId: string;
  addressRequestBody: CustomerAddressRequestBody;
};
export type DeleteCustomerAddressApiResponse = unknown;
export type DeleteCustomerAddressApiArg = {
  customerId: string;
  addressId: string;
};
export type PatchCustomerAddressApiResponse = /** status 200  */ AddressResponseBody;
export type PatchCustomerAddressApiArg = {
  customerId: string;
  addressId: string;
  addressRequestBody: CommonAddressRequestBody;
};
export type GetCustomerAddressListApiResponse = /** status 200  */ AddressResponseBody[];
export type GetCustomerAddressListApiArg = {
  customerId: string;
};
export type CreateCustomerCommentApiResponse = /** status 201  */ CustomerCommentResponseBody;
export type CreateCustomerCommentApiArg = {
  customerId: string;
  commentRequestBody: CustomerCommentRequestBody;
};
export type DeleteCustomerCommentApiResponse = unknown;
export type DeleteCustomerCommentApiArg = {
  customerId: string;
  commentId: string;
};
export type GetCustomerCommentApiResponse = /** status 200  */ CustomerCommentResponseBody;
export type GetCustomerCommentApiArg = {
  customerId: string;
  commentId: string;
};
export type PatchCustomerCommentApiResponse = /** status 200  */ CustomerCommentResponseBody;
export type PatchCustomerCommentApiArg = {
  customerId: string;
  commentId: string;
  commentRequestBody: CustomerCommentRequestBody;
};
export type GetCustomerCommentListApiResponse = /** status 200  */ CustomerCommentResponseBody[];
export type GetCustomerCommentListApiArg = {
  customerId: string;
};
export type CreateCustomerContactApiResponse = /** status 201  */ PersonResponseBody;
export type CreateCustomerContactApiArg = {
  customerId: string;
  personRequestBody: PersonRequestBody;
};
export type DeleteCustomerContactApiResponse = unknown;
export type DeleteCustomerContactApiArg = {
  customerId: string;
  contactId: string;
};
export type PatchCustomerContactApiResponse = /** status 200  */ PersonResponseBody;
export type PatchCustomerContactApiArg = {
  customerId: string;
  contactId: string;
  personRequestBody: PersonRequestBody;
};
export type CreateCustomerContractInformationApiResponse =
  /** status 201  */ ContractInformationResponseBody;
export type CreateCustomerContractInformationApiArg = {
  customerId: string;
  contractInformationRequestBody: ContractInformationRequestBody;
};
export type DeleteCustomerContractInformationApiResponse = unknown;
export type DeleteCustomerContractInformationApiArg = {
  customerId: string;
  contractInformationId: string;
};
export type PatchCustomerContractInformationApiResponse =
  /** status 200  */ ContractInformationResponseBody;
export type PatchCustomerContractInformationApiArg = {
  customerId: string;
  contractInformationId: string;
  contractInformationRequestBody: ContractInformationRequestBody;
};
export type CreateCustomerApiResponse = /** status 201  */ CustomerResponseBody;
export type CreateCustomerApiArg = {
  createCustomerRequestBody: CreateCustomerRequestBody;
};
export type DeleteCustomerApiResponse = /** status 204  */ undefined;
export type DeleteCustomerApiArg = {
  customerId: string;
};
export type PatchCustomerApiResponse = /** status 200  */ CustomerResponseBody;
export type PatchCustomerApiArg = {
  customerId: string;
  customerRequestBody: CustomerRequestBody;
};
export type GetCustomerNoteApiResponse = /** status 200  */ NoteResponseBody;
export type GetCustomerNoteApiArg = {
  customerId: string;
};
export type PutCustomerNoteApiResponse = /** status 200  */ NoteResponseBody;
export type PutCustomerNoteApiArg = {
  customerId: string;
  noteRequestBody: NoteRequestBody;
};
export type ParsePersonalIdApiResponse = /** status 200  */ PersonalIdInfoResponseBody;
export type ParsePersonalIdApiArg = {
  parsePersonalIdRequestBody: ParsePersonalIdRequestBody;
};
export type SimilarCustomerType = 'EXACT' | 'PARTIAL' | 'SUGGESTION';
export const AddressDataSchema = z.object({
  addressLine1: z.string().nullable(),
  addressLine2: z.string().nullable(),
  city: z.string().nullable(),
  postalCode: z.string().nullable(),
  province: z.string().nullable(),
  countryCode: z.string().nullable(),
  type: z.string().nullable(),
  invalid: z.boolean().nullable(),
});

export type AddressData = z.infer<typeof AddressDataSchema>;
export const AddressResponseBodySchema = z.object({
  id: z.string(),
  addressData: AddressDataSchema,
});
export type AddressResponseBody = z.infer<typeof AddressResponseBodySchema>;
export type SimilarCustomerResponseBody = {
  id: string;
  type: SimilarCustomerType | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  titleBefore: string | null;
  titleAfter: string | null;
  phone: string | null;
  email: string | null;
  tradeName: string | null;
  registrationNumber: string | null;
  address: AddressResponseBodyV2 | null;
};
export type SimilarCustomersResponseBody = {
  data: SimilarCustomerResponseBody[];
  rowsCount: number;
};
export type CustomerSeriesDefinitionResponseBody = {
  id: string;
  name: string;
  seriesId: string;
  branchId: string | null;
  systemDefault: boolean;
};
export type AddressIdResponseError = {
  addressId: string;
};
export type CustomerAddressRequestBody = {
  addressData: CommonAddressRequestBody;
};
export type CustomerCommentResponseBody = {
  id: string;
  authorId: string;
  createdAt: string;
  editedAt: string | null;
  comment: string;
  authorName: string;
};
export type CustomerCommentRequestBody = {
  comment: string;
};
export const PersonDataSchema = z.object({
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  middleName: z.string().nullable(),
  titleBefore: z.string().nullable(),
  titleAfter: z.string().nullable(),
  genderKey: z.string().nullable(),
  roles: z.string().nullable(),
  citizenshipCode: z.string().nullable(),
  birthdate: z.string().nullable(),
  personalIdentifier: z.string().nullable(),
});

export type PersonData = z.infer<typeof PersonDataSchema>;
export const PhoneNumberDataSchema = z.object({
  type: z.string().nullable(),
  phoneNumber: PhoneNumberSchema,
});
export type PhoneNumberData = z.infer<typeof PhoneNumberDataSchema>;
export const EmailDataSchema = z.object({
  type: z.string().nullable(),
  email: z.string(),
});

export type EmailData = z.infer<typeof EmailDataSchema>;
export const IdentityCardDataResponseBodySchema = z.object({
  type: z.string().nullable(),
  cardNumber: z.string(),
  issuedOn: z.string().nullable(),
  validUntil: z.string().nullable(),
  issuer: z.string().nullable(),
  issuedInCountryCode: z.string().nullable(),
  note: z.string().nullable(),
});

export type IdentityCardDataResponseBody = z.infer<typeof IdentityCardDataResponseBodySchema>;
export const IdentityCardResponseBodySchema = z.object({
  id: z.string(),
  identityCardData: IdentityCardDataResponseBodySchema,
});
export type IdentityCardResponseBody = z.infer<typeof IdentityCardResponseBodySchema>;
export const PersonResponseBodySchema = z.object({
  id: z.string(),
  personData: PersonDataSchema,
  phoneNumbers: z.array(PhoneNumberDataSchema),
  emails: z.array(EmailDataSchema),
  identityCards: z.array(IdentityCardResponseBodySchema),
  permanentAddress: AddressResponseBodySchema.nullable(),
});
export type PersonResponseBody = z.infer<typeof PersonResponseBodySchema>;
export type IdentityCardDataRequestBody = {
  type: string | null;
  cardNumber: string;
  issuedOn?: string | null;
  validUntil: string | null;
  issuer: string | null;
  issuedInCountryCode: string | null;
  note: string | null;
};
export type IdentityCardRequestBody = {
  id: string | null;
  cardData: IdentityCardDataRequestBody;
};
export type PersonRequestBody = {
  personData: PersonData;
  phoneNumbers: PhoneNumberData[];
  emails: EmailData[];
  identityCards: IdentityCardRequestBody[];
  permanentAddressId: string | null;
};
export const LegalFormSchema = z.enum(['LEGAL_ENTITY', 'NATURAL_PERSON', 'SELF_EMPLOYED']);

export type LegalForm = z.infer<typeof LegalFormSchema>;
export const BankAccountDataSchema = z.object({
  name: z.string().nullable(),
  countryCode: z.string().nullable(),
  ownerName: z.string().nullable(),
  iban: z.string().nullable(),
  swiftBic: z.string().nullable(),
  currency: z.string().nullable(),
  number: z.string().nullable(),
  bankCode: z.string().nullable(),
});

export type BankAccountData = z.infer<typeof BankAccountDataSchema>;
export const CustomerBankAccountResponseBodySchema = z.object({
  id: z.string(),
  bankAccountData: BankAccountDataSchema,
});
export type CustomerBankAccountResponseBody = z.infer<typeof CustomerBankAccountResponseBodySchema>;
export const BusinessInfoDataSchema = z.object({
  countryOfRegistrationCode: z.string().nullable(),
  registrationNumber: z.string().nullable(),
  vatNumber: z.string().nullable(),
  tradeName: z.string().nullable(),
  fileNumber: z.string().nullable(),
});

export type BusinessInfoData = z.infer<typeof BusinessInfoDataSchema>;
export const BusinessInfoResponseBodySchema = z.object({
  id: z.string(),
  businessInfoData: BusinessInfoDataSchema,
  businessAddress: AddressResponseBodySchema.nullable(),
});
export type BusinessInfoResponseBody = z.infer<typeof BusinessInfoResponseBodySchema>;
export const ContractInformationResponseBodySchema = z.object({
  id: z.string(),
  permanent: z.boolean(),
  legalForm: LegalFormSchema,
  bankAccounts: z.array(CustomerBankAccountResponseBodySchema),
  person: PersonResponseBodySchema.nullable(),
  businessInfo: BusinessInfoResponseBodySchema.nullable(),
  customFieldsPayload: z.array(CustomFieldValueSchema),
});
export type ContractInformationResponseBody = z.infer<typeof ContractInformationResponseBodySchema>;

export type CustomerBankAccountRequestBody = {
  id: string | null;
  bankAccountData: BankAccountData;
};
export type BusinessInfoRequestBody = {
  businessInfoData: BusinessInfoData;
  businessAddressId: string | null;
};
export type ContractInformationRequestBody = {
  legalForm: LegalForm;
  bankAccounts: CustomerBankAccountRequestBody[];
  personId: string | null;
  businessInfo: BusinessInfoRequestBody | null;
  customFieldsPayload: CustomFieldValue[];
};
export type CustomerDiscriminator = 'NATURAL_PERSON' | 'BUSINESS';
export type CustomerSource = 'WALK_IN' | 'INBOUND_CALL' | 'OUTBOUND_CALL';
export type CustomerData = {
  customerDiscriminator: CustomerDiscriminator;
  customerSource: CustomerSource | null;
};
export type Institution = 'INSURANCE' | 'SUPPLIER' | 'FLEET' | 'LOAN' | 'LEASING';
export type CustomerResponseBody = {
  id: string;
  customerData: CustomerData;
  foundingPerson: PersonResponseBody | null;
  businessInfo: BusinessInfoResponseBody | null;
  contacts: PersonResponseBodyV2[];
  persons: PersonResponseBodyV2[];
  contractInformation: ContractInformationResponseBody[];
  addresses: AddressResponseBody[];
  selfEmployed: boolean | null;
  selfEmployedBusinessInfoData?: BusinessInfoData | null;
  selfEmployedBusinessAddress?: AddressResponseBodyV2 | null;
  publicId?: string | null;
  participationId?: string | null;
  institutions?: Institution[] | null;
  customerGroups?: string[];
};
export type CreateCustomerRequestBody = {
  customerDiscriminator: CustomerDiscriminator;
  firstName: string | null;
  lastName: string | null;
  registrationNumber: string | null;
  tradeName: string | null;
  email: string | null;
  phoneNumber: PhoneNumber | null;
  institutions?: Institution[] | null;
  customerGroups?: string[];
};
export type CustomerRequestBody = {
  customerData: CustomerData;
  foundingPerson: PersonRequestBody | null;
  businessInfo: BusinessInfoRequestBody | null;
  selfEmployed?: boolean | null;
  selfEmployedBusinessInfoData?: BusinessInfoData | null;
  selfEmployedBusinessAddressId?: string | null;
  institutions?: Institution[] | null;
  customerGroups?: string[];
};
export type PersonalIdInfoResponseBody = {
  genderKey: string | null;
  birthdate: string | null;
};
export type ParsePersonalIdRequestBody = {
  personalId: string;
  country: Alpha3;
};
export type ListCustomerContractInformationsApiResponse =
  /** status 200  */ ContractInformationResponseBodyV2[];
export type ListCustomerContractInformationsApiArg = {
  customerId: string;
};
export type VerifyCustomerContractInformationApiResponse = unknown;
export type VerifyCustomerContractInformationApiArg = {
  customerId: string;
  contractInformationId: string;
  verifyContractInformationRequestBody: VerifyContractInformationRequestBody;
};
export type VerifyContractInformationRequestBody = {
  verificationRegisterTypes: ApiVerificationRegisterType[];
  recordId?: RecordIdHttpModel | null;
};
export const ApiVerificationRegisterTypeSchema = z.enum([
  'distraint',
  'insolvency',
  'invalid_document',
  'unreliable_vat_payer',
  'eu_vies',
]);
export type ApiVerificationRegisterType = z.infer<typeof ApiVerificationRegisterTypeSchema>;
export type GetVerificationRegistersApiResponse =
  /** status 200  */ VerificationRegisterResponseBody[];
export type GetVerificationRegistersApiArg = {
  customerId: string;
  contractInformationId: string;
  recordId?: string;
  resourceId?: string;
};
export const VerificationResultTypeSchema = z.enum(['success', 'warning', 'error']);
export type VerificationResultType = z.infer<typeof VerificationResultTypeSchema>;
export const VerificationInfoResponseBodySchema = z.object({
  verificationRegisterType: ApiVerificationRegisterTypeSchema,
  resultType: VerificationResultTypeSchema,
  resultDescription: z.string(),
  resultUrl: z.string().nullable(),
  requestedAt: z.string(),
  requestedBy: z.string(),
});
export type VerificationInfoResponseBody = z.infer<typeof VerificationInfoResponseBodySchema>;
export const VerificationRegisterResponseBodySchema = z.object({
  verificationRegisterType: ApiVerificationRegisterTypeSchema,
  isAvailable: z.boolean(),
  verificationInfo: VerificationInfoResponseBodySchema.nullable(),
});
export type VerificationRegisterResponseBody = z.infer<
  typeof VerificationRegisterResponseBodySchema
>;
export type CreateCustomerAddressV2ApiResponse = /** status 201  */ UuidResponseBodyCustomer;
export type CreateCustomerAddressV2ApiArg = {
  customerId: string;
  addressRequestBody: AddressRequestBodyV2;
};
export type AddressRequestBodyV2 = {
  address: CommonAddressRequestBody;
  type: string | null;
  invalid: boolean | null;
};
export type CommonAddressRequestBody = {
  street: string;
  descriptiveNumber?: string | null;
  orientationNumber?: string | null;
  city: string;
  zip: string;
  addressComplement?: string | null;
  country: string;
  prefix?: string | null;
  district?: string | null;
  state?: string | null;
  coordinates?: {
    latitude: string;
    longitude: string;
  } | null;
  postal?: string | null;
};
export type GetCustomerAddressListV2ApiResponse = /** status 200  */ AddressResponseBodyV2[];
export type AddressResponseBodyV2 = {
  id: string;
  address: CommonAddressResponseBody;
  type: string | null;
  invalid: boolean | null;
};
export type CommonAddressResponseBody = {
  street: string;
  descriptiveNumber: string | null;
  orientationNumber: string | null;
  city: string;
  zip: string;
  addressComplement: string | null;
  country: string;
  prefix: string | null;
  district: string | null;
  state: string | null;
  postal?: string | null;
  coordinates?: CoordinatesResponseBody | null;
  externalAddressId?: string | null;
};
export type GetCustomerAddressListV2ApiArg = {
  customerId: string;
};
export type PatchCustomerAddressV2ApiResponse = /** status 204  */ void;
export type PatchCustomerAddressV2ApiArg = {
  customerId: string;
  addressId: string;
  addressRequestBody: AddressRequestBodyV2;
};
export type GetCustomerContactV2ApiResponse = /** status 200  */ PersonResponseBodyV2;
export type PersonResponseBodyV2 = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  titleBefore: string | null;
  titleAfter: string | null;
  genderKey: string | null;
  roles: string | null;
  citizenshipCode: string | null;
  birthdate: string | null;
  personalIdentifier: string | null;
  phoneNumbers: PhoneNumberResponseBody[];
  emails: EmailResponseBody[];
  identityCards: IdentityCardResponseBodyV2[];
  permanentAddress: AddressResponseBodyV2 | null;
};
export type PhoneNumberResponseBody = {
  type: string | null;
  countryCode: string;
  prefix: string;
  number: string;
};
export type EmailResponseBody = {
  type: string | null;
  email: string;
};
export type IdentityCardResponseBodyV2 = {
  id: string;
  type: string | null;
  cardNumber: string;
  issuedOn: string | null;
  validUntil: string | null;
  issuer: string | null;
  issuedInCountryCode: string | null;
  note: string | null;
};
export type GetCustomerContactV2ApiArg = {
  customerId: string;
  contactId: string;
};
export type GetCustomerV2ApiResponse = /** status 200  */ CustomerResponseBodyV2;
export type CustomerResponseBodyV2 = {
  id: string;
  discriminator: CustomerDiscriminator;
  source: CustomerSource | null;
  foundingPerson: PersonResponseBodyV2 | null;
  businessInfo: BusinessInfoResponseBodyV2 | null;
  contacts: PersonResponseBodyV2[];
  persons: PersonResponseBodyV2[];
  contractInformation: ContractInformationResponseBodyV2[];
  addresses: AddressResponseBodyV2[];
  selfEmployed: boolean | null;
  selfEmployedBusinessInfoData: BusinessInfoDataResponseBody | null;
  selfEmployedBusinessAddress: AddressResponseBodyV2 | null;
  publicId: string | null;
  institutions: Institution[] | null;
  customerGroups: string[];
  abroadSale: string | null;
};
export type BusinessInfoResponseBodyV2 = {
  id: string;
  businessInfo: BusinessInfoDataResponseBody;
  address: AddressResponseBodyV2 | null;
};
export type ContractInformationResponseBodyV2 = {
  id: string;
  permanent: boolean;
  legalForm: LegalForm;
  bankAccounts: BankAccountResponseBodyV2[];
  person: PersonResponseBodyV2 | null;
  businessInfo: BusinessInfoResponseBodyV2 | null;
  customFieldsPayload: CustomFieldValue[];
  isDeleted: boolean;
};
export type BusinessInfoDataResponseBody = {
  countryOfRegistrationCode: string | null;
  registrationNumber: string | null;
  vatNumber: string | null;
  tradeName: string | null;
  fileNumber: string | null;
};
export type BankAccountResponseBodyV2 = {
  id: string;
  name: string | null;
  countryCode: string | null;
  ownerName: string | null;
  iban: string | null;
  swiftBic: string | null;
  currency: string | null;
  number: string | null;
  bankCode: string | null;
};
export type GetCustomerV2ApiArg = {
  customerId: string;
};
export type GetCustomersV2ApiResponse = /** status 200  */ CustomerResponseBodyV2[];
export type GetCustomersV2ApiArg = {
  institutions?: Institution[];
};
export type UuidResponseBodyCustomer = {
  id: string;
};
export type CustomerAdvancedSearchApiResponse =
  /** status 200  */ CustomerAdvancedSearchResultResponseBody[];
export type CustomerAdvancedSearchApiArg = {
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  legalNumber?: string | null;
};
export type CoordinatesResponseBody = {
  latitude: string;
  longitude: string;
};
export type CustomerAdvancedSearchFieldMatchTypeResponseBody = 'FULL' | 'PARTIAL' | 'NONE';
export type CustomerAdvancedSearchResultContactResponseBody = {
  id: string;
  hasMatch: boolean;
  firstName: string | null;
  firstNameHighlight: string | null;
  firstNameMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody;
  lastName: string | null;
  lastNameHighlight: string | null;
  lastNameMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody;
  email: string | null;
  emailHighlight: string | null;
  emailMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody;
  phone: string | null;
  phoneHighlight: string | null;
  phoneMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody;
  address: CommonAddressResponseBody | null;
};
export type CustomerAdvancedSearchResultResponseBody = {
  id: string;
  discriminator: CustomerDiscriminator;
  businessAddress: CommonAddressResponseBody | null;
  vatNumber: string | null;
  vatNumberHighlight: string | null;
  vatNumberMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody;
  registrationNumber: string | null;
  registrationNumberHighlight: string | null;
  registrationNumberMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody;
  tradeName: string | null;
  tradeNameHighlight: string | null;
  tradeNameMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody;
  foundingPerson: CustomerAdvancedSearchResultContactResponseBody | null;
  hasMatchInContacts: boolean;
  contacts: CustomerAdvancedSearchResultContactResponseBody[];
};
export type PutCustomerPaymentSettingsApiResponse = unknown;
export type PutCustomerPaymentSettingsApiArg = {
  customerId: string;
  paymentSettingsRequestBody: PaymentSettingsRequestBody;
};
export type PaymentSettingsRequestBody = {
  abroadSale: ('IN_EUROPE' | 'OUT_OF_EUROPE') | null;
};
