import {createSelector} from '@reduxjs/toolkit';

import {VehicleDetail} from '../../types/CommonTypes';
import {VehicleSliceState, VehiclesSliceName} from '../../types/SourcingVehicleState';
import type {TeasState} from '../../types/TeasState';
import {DEFAULT_EDITING_STATE} from './constants';

export const createVehiclesSelectors = <V extends VehicleDetail>(name: VehiclesSliceName) => {
  const selectState = (state: TeasState): VehicleSliceState<V> =>
    // eslint-disable-next-line no-restricted-syntax
    state.sourcing.vehicles[name] as unknown as VehicleSliceState<V>;

  const selectIsLoading = createSelector(selectState, (state) => state.isLoading);
  const selectVehiclesObject = createSelector(selectState, (state) => state.vehicles);
  const selectVehicles = createSelector(selectVehiclesObject, (vehicles) =>
    Object.values(vehicles)
  );
  const selectVehicle = (id: string) => createSelector(selectState, (state) => state.vehicles[id]);
  const selectFilteredCount = createSelector(selectState, (state) => state.filteredCount);
  const selectHasMoreData = createSelector(selectState, (state) => state.hasMoreData);
  const selectVehicleEditing = (id: string) =>
    createSelector(selectState, (state) => state.editingVehicle[id] ?? DEFAULT_EDITING_STATE);

  const selectVehiclesList = createSelector(selectVehicles, (vehicles) =>
    vehicles.filter((vehicle) => !vehicle.hidden)
  );
  const selectVehiclesHiddenList = createSelector(selectVehicles, (vehicles) =>
    vehicles.filter((vehicle) => vehicle.hidden)
  );
  const selectVehiclesComparisonList = createSelector(selectVehicles, (vehicles) =>
    vehicles.filter((vehicle) => vehicle.comparison)
  );
  const selectVehiclesToBuyList = createSelector(selectVehicles, (vehicles) =>
    vehicles.filter((vehicle) => vehicle.inCart)
  );

  return {
    selectIsLoading,
    selectVehiclesObject,
    selectVehicles,
    selectVehicle,
    selectFilteredCount,
    selectHasMoreData,
    selectVehicleEditing,

    selectVehiclesList,
    selectVehiclesComparisonList,
    selectVehiclesHiddenList,
    selectVehiclesToBuyList,
  };
};
