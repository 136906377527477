import {
  GetGoodwillBulkRatioApiArg,
  GetGoodwillBulkRatioApiArgSchema,
  GetGoodwillBulkRatioApiResponse,
  GetGoodwillBulkRatioApiResponseSchema,
  GetGoodwillItemRatioApiArg,
  GetGoodwillItemRatioApiArgSchema,
  GetGoodwillItemRatioApiResponse,
  GetGoodwillItemRatioApiResponseSchema,
  PutServiceOrderItemsToGoodwillApiArg,
  PutServiceOrderItemsToGoodwillApiArgSchema,
  PutServiceOrderItemsToGoodwillApiResponse,
  PutServiceOrderItemsToGoodwillApiResponseSchema,
} from '../types/api/metadaWorkshopGoodwill';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopGoodwillApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    putServiceOrderItemsToGoodwill: build.mutation<
      PutServiceOrderItemsToGoodwillApiResponse,
      PutServiceOrderItemsToGoodwillApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/goodwill`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: [
        'serviceOrders',
        'serviceOrder',
        'serviceOrderJobs',
        'serviceOrderJobTotalAmount',
        'serviceOrderTotalAmount',
        'goodwillItemRatio',
        'workBasketItem',
        'orderProfitability',
        'checkout',
      ],
      extraOptions: {
        responseSchema: PutServiceOrderItemsToGoodwillApiResponseSchema,
        requestSchema: PutServiceOrderItemsToGoodwillApiArgSchema,
      },
    }),
    getGoodwillBulkRatio: build.query<GetGoodwillBulkRatioApiResponse, GetGoodwillBulkRatioApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/goodwill-bulk`,
        params: {serviceJobId: queryArg.serviceJobId},
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetGoodwillBulkRatioApiResponseSchema,
        requestSchema: GetGoodwillBulkRatioApiArgSchema,
      },
    }),
    getGoodwillItemRatio: build.query<GetGoodwillItemRatioApiResponse, GetGoodwillItemRatioApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/item/${queryArg.serviceItemId}/goodwill`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'goodwillItemRatio', id: queryArg.serviceItemId},
      ],
      extraOptions: {
        responseSchema: GetGoodwillItemRatioApiResponseSchema,
        requestSchema: GetGoodwillItemRatioApiArgSchema,
      },
    }),
  }),
});

export const {
  usePutServiceOrderItemsToGoodwillMutation,
  useGetGoodwillBulkRatioQuery,
  useGetGoodwillItemRatioQuery,
} = metadaWorkshopGoodwillApi;
