import {
  GetCustomerPaymentApiResponse,
  GetCustomerPaymentApiArg,
  PostCustomerPaymentApiResponse,
  PostCustomerPaymentApiArg,
  PatchCustomerPaymentApiResponse,
  PatchCustomerPaymentApiArg,
} from '../types/api/customerPayment';
import {metadaApi} from './baseApi/metadaApi';

export const metadaCustomerPaymentApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomerPayment: build.query<GetCustomerPaymentApiResponse, GetCustomerPaymentApiArg>({
      query: (queryArg) => ({
        url: `core/v1/customer-payment/${queryArg.customerId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerPaymentSettings', id: queryArg.customerId},
      ],
    }),
    postCustomerPayment: build.mutation<PostCustomerPaymentApiResponse, PostCustomerPaymentApiArg>({
      query: (queryArg) => ({
        url: `core/v1/customer-payment/${queryArg.customerId}`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerPaymentSettings', id: queryArg.customerId},
      ],
    }),
    patchCustomerPayment: build.mutation<
      PatchCustomerPaymentApiResponse,
      PatchCustomerPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `core/v1/customer-payment/${queryArg.customerId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerPaymentSettings', id: queryArg.customerId},
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCustomerPaymentQuery,
  usePostCustomerPaymentMutation,
  usePatchCustomerPaymentMutation,
} = metadaCustomerPaymentApi;
