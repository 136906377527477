import {HStack, Link, Show, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {useGetUserQuery} from '../../../api/userApi';
import i18n from '../../../i18n/i18n';
import {PriceReportResponseBody} from '../../../types/api/priceReport';
import {getNaturalPersonFullName} from '../../../utils/getNaturalPersonFullName';

interface PriceReportLineProps extends TestIdProps {
  report: PriceReportResponseBody;
}

export function PriceReportLine(props: PriceReportLineProps) {
  const formatDateTime = useDateTimeFormatter();
  const {data: user} = useGetUserQuery(
    {id: props.report.createdBy ?? ''},
    {skip: isNilOrEmpty(props.report.createdBy)}
  );

  return (
    <HStack spacing={3} align="center">
      <Link
        data-testid={suffixTestId('previousReport', props)}
        href={props.report.url}
        title={i18n.t('entity.priceReport.previousReport', {
          date: formatDateTime('dateShort', parseDate(props.report.createdAt)),
        })}
        rightIcon="action/open_in_new"
        size="small"
        target="_blank"
      />
      <Show when={isNotNilOrEmpty(user)}>
        <Text size="xSmall" color="tertiary">
          {getNaturalPersonFullName(user)}
        </Text>
      </Show>
      <Show when={isNotNil(props.report.country)}>
        <Text size="xSmall" color="secondary">
          ({props.report.country})
        </Text>
      </Show>
    </HStack>
  );
}
