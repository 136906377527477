import {openDeleteDialog} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useNavigate} from 'react-router-dom';

import {isArray} from 'ramda-adjunct';

import {settingsRoutes} from '@omnetic-dms/routes';
import {
  handleApiError,
  useDeleteServicePackageDeleteMutation,
  usePostServicePackageSetActiveMutation,
  usePostServicePackageSetInActiveMutation,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId} from 'shared';

export function Packages(props: RequiredTestIdProps) {
  const navigate = useNavigate();

  const [deleteServicePackageDelete] = useDeleteServicePackageDeleteMutation();
  const [postServicePackageSetActive] = usePostServicePackageSetActiveMutation();
  const [postServicePackageSetInActive] = usePostServicePackageSetInActiveMutation();

  const actionCallback: ActionCallback = ({rowId, actionKey, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(composePath(settingsRoutes.workshopEditServicePackages, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServicePackageDelete({servicePackageId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setAsInactive', () =>
        postServicePackageSetInActive({servicePackageId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsActive', () =>
        postServicePackageSetActive({servicePackageId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .otherwise(() => undefined);
  };

  return (
    <VStack>
      <DataGrid
        gridCode="service-packages"
        autoHeight
        actionCallback={actionCallback}
        data-testid={suffixTestId('service-packages-dataGrid', props)}
      />
    </VStack>
  );
}
