import {Card, DataStatus, Separator} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {Section, useGetEmployeeLinkQuery, useGetUserQuery} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, useRequiredParams} from 'shared';

import {ConnectUserToEmployee} from './components/ConnectUserToEmployee';
import {UserBranches} from './components/UserBranches';
import {UserCredentials} from './components/UserCredentials';
import {UserRoles} from './components/UserRoles';

interface UserDataAndRoleProps extends RequiredTestIdProps {}

export function UserDataAndRoles(props: UserDataAndRoleProps) {
  const {id: employeeId} = useRequiredParams();

  const {data: linkData, isLoading, isError} = useGetEmployeeLinkQuery({employeeId});

  const userId = linkData?.find((link) => link?.contextTarget === 'USER')?.contextId;
  const isEmployeeLinkedToUser = isNotNil(userId);

  const {isLoading: isLoadingUser, isError: isUserError} = useGetUserQuery(
    {id: userId ?? ''},
    {skip: isEmployeeLinkedToUser}
  );

  return (
    <Section>
      <Card title={i18n.t('page.employeeDetail.labels.userData')}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <Show when={!isEmployeeLinkedToUser}>
            <ConnectUserToEmployee
              employeeId={employeeId}
              data-testid={suffixTestId('ConnectUserToEmployee', props)}
            />
          </Show>

          {isEmployeeLinkedToUser && (
            <DataStatus isLoading={isLoadingUser} isError={isUserError}>
              <UserCredentials
                data-testid={suffixTestId('UserCredentials', props)}
                userId={userId}
              />
              <Separator />
              <UserBranches data-testid={suffixTestId('UserBranches', props)} userId={userId} />
              <Separator />
              <UserRoles data-testid={suffixTestId('UserRoles', props)} userId={userId} />
            </DataStatus>
          )}
        </DataStatus>
      </Card>
    </Section>
  );
}
