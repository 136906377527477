import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
  showNotification,
} from 'platform/components';
import {Box, Center, HStack, Show, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {indexBy, prop} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CalculatePrice,
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  GetCustomerRequestedPriceApiArg,
  GetCustomerRequestedPriceApiRes,
  handleApiError,
  PutCustomerRequestedPriceApiArg,
  selectTenant,
  useGetCustomerRequestedPriceQuery,
  usePutCustomerRequestedPriceMutation,
  VatType,
  VatTypeEnum,
} from '@omnetic-dms/shared';
import {selectVatRatesByCountryCode} from '@omnetic-dms/teas';

import {Nullish} from 'shared';

interface RequestedPriceFormProps extends GetCustomerRequestedPriceApiArg {
  toggleDetail: () => void;
}

type FormValues = {
  vatType: VatType;
  vatDeductible: boolean;
  priceWithVat: string;
  priceWoVat: string;
};

const MIN_INPUT_WIDTH = 35;
const MINIMUM_ALLOWED_NUMBER = 0.01;

export function RequestedPriceForm({toggleDetail, ...props}: RequestedPriceFormProps) {
  const {data: selectedTenant} = useSelector(selectTenant);
  const country = selectedTenant?.country ?? DEFAULT_COUNTRY;
  const currency = isCurrency(selectedTenant?.currency)
    ? selectedTenant?.currency ?? DEFAULT_CURRENCY
    : DEFAULT_CURRENCY;

  const [lastTouchedPriceField, setLastTouchedPriceField] = useState<'withVat' | 'withoutVat'>();

  const {data: customerRequestedPrice, isLoading} = useGetCustomerRequestedPriceQuery(props);

  const [updateRequestedPrice] = usePutCustomerRequestedPriceMutation();

  const vatRates = useSelector(selectVatRatesByCountryCode(selectedTenant?.country))?.rates?.map(
    (v) => ({
      ...v,
      value: v.type,
      label: `${v.rate} % ${v.name}`,
    })
  );
  const vatRatesByType = indexBy(prop('type'), vatRates ?? []);

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const updateRequestedPriceBody: PutCustomerRequestedPriceApiArg = {
      ...props,
      customerRequestedPrice: data.priceWithVat
        ? {
            priceWithVat: {
              amount: data.priceWithVat.toString(),
              currency,
            },
            priceWoVat: {
              amount: data.priceWoVat.toString(),
              currency,
            },
            vatType: data.vatType,
            vatRate: vatRatesByType[data.vatType]?.rate,
          }
        : null,
      isVatDeductible: data.vatDeductible,
      vatRate: {
        vatType: data.vatType,
        vatRate: vatRatesByType[data.vatType]?.rate,
        country,
      },
    };

    await updateRequestedPrice(updateRequestedPriceBody)
      .unwrap()
      .then(() => {
        showNotification.success();
        toggleDetail();
      })
      .catch(handleApiError);
  };

  const defaultValues = getDefaultValues(customerRequestedPrice);

  const handleCalculationChange = useCallback(
    (formApi: UseFormReturn<FormValues>) => (res: {withVat?: string} | {withoutVat?: string}) => {
      if ('withVat' in res) {
        formApi.setValue(`priceWithVat`, res?.withVat ?? '');
      }
      if ('withoutVat' in res) {
        formApi.setValue(`priceWoVat`, res?.withoutVat ?? '');
      }
    },
    []
  );

  return (
    <DataStatus isLoading={isLoading}>
      <Form<FormValues>
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        schema={$RequestedFormSchema}
      >
        {(control, formApi) => {
          const isVatDeductible = formApi.watch('vatDeductible');
          const vatType = formApi.watch('vatType');

          const [priceWithVat, priceWithoutVat] = formApi.watch(['priceWithVat', 'priceWoVat']);

          return (
            <VStack spacing={4}>
              <HStack spacing={4}>
                <Box flexGrow={1} width={MIN_INPUT_WIDTH}>
                  <Center justify="flex-start" height={8}>
                    <FormField
                      control={control}
                      name="vatDeductible"
                      type="checkbox"
                      label={i18n.t('general.labels.vatDeductible')}
                      data-testid={testIds.businessCase.buying('requestedPriceForm-vatDeductible')}
                    />
                  </Center>
                </Box>

                <Show when={isVatDeductible}>
                  <Box flexGrow={1} width={MIN_INPUT_WIDTH}>
                    <FormField
                      control={control}
                      name="vatType"
                      type="choice"
                      placeholder=""
                      options={vatRates}
                      data-testid={testIds.businessCase.buying('requestedPriceForm-vatType')}
                    />
                  </Box>
                </Show>
              </HStack>

              {/* SUGGESTED PRICE CALCULATION */}
              <CalculatePrice
                amount={lastTouchedPriceField === 'withVat' ? priceWithVat : priceWithoutVat}
                countryCode={country}
                type={lastTouchedPriceField}
                vatCode={vatType}
                onChange={handleCalculationChange(formApi)}
              />

              <HStack spacing={4}>
                <Show when={isVatDeductible}>
                  <Box flexGrow={1} width={MIN_INPUT_WIDTH}>
                    <FormField
                      control={control}
                      name="priceWoVat"
                      type="currency"
                      currency={currency}
                      label={i18n.t('entity.businessCase.labels.customerRequestedPriceWoVat')}
                      onChange={() => setLastTouchedPriceField('withoutVat')}
                      data-testid={testIds.businessCase.buying('requestedPriceForm-priceWoVat')}
                    />
                  </Box>
                </Show>

                <Box flexGrow={1} width={MIN_INPUT_WIDTH}>
                  <FormField
                    control={control}
                    name="priceWithVat"
                    type="currency"
                    currency={currency}
                    label={i18n.t('entity.businessCase.labels.customerRequestedPrice')}
                    onChange={() => setLastTouchedPriceField('withVat')}
                    data-testid={testIds.businessCase.buying('requestedPriceForm-priceWithVat')}
                  />
                </Box>
              </HStack>

              <ButtonGroup align="right">
                <Button
                  onClick={toggleDetail}
                  title={i18n.t('general.labels.dismiss')}
                  variant="secondary"
                />
                <FormButton
                  title={i18n.t('general.actions.save')}
                  control={control}
                  type="submit"
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const testRequiredNumber = Yup.mixed().test(
  'number-amount',
  i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
  (value) => !value || parseFloat(value) >= MINIMUM_ALLOWED_NUMBER
);

const $RequestedFormSchema = Yup.object({
  vatDeductible: Yup.bool().default(false).required(),
  vatType: Yup.mixed().when('vatDeductible', {
    is: true,
    then: Yup.mixed()
      .oneOf(Object.values(VatTypeEnum), i18n.t('general.notifications.errorFieldRequired'))
      .required(),
    otherwise: Yup.mixed().default(undefined),
  }),
  priceWoVat: Yup.mixed().when('vatDeductible', {
    is: true,
    then: testRequiredNumber,
    otherwise: Yup.mixed().default(undefined),
  }),
  priceWithVat: testRequiredNumber,
});

const getDefaultValues = (data: GetCustomerRequestedPriceApiRes | Nullish): Partial<FormValues> =>
  data?.customerRequestedPrice
    ? {
        priceWithVat: data.customerRequestedPrice.priceWithVat.amount,
        priceWoVat: data.customerRequestedPrice.priceWoVat.amount,
        vatDeductible: data.isVatDeductible,
        vatType: data.vatRate.vatType,
      }
    : {vatDeductible: true, vatType: VatTypeEnum.S};
