import {ActionCallback, DataGrid} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';

export function SupplierOrderList() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, sourceSearchParams}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        navigate(
          composePath(warehouseRoutes.supplierOrderDetailOverview, {
            queryParams: sourceSearchParams,
            params: {id},
            isSearchParamsPreserved: false,
          })
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode="warehouse-supplier-orders"
        actionCallback={actionCallback}
        data-testid={testIds.warehouse.supplierOrderList('supplierOrderList')}
      />
    </FullscreenDataGridWrapper>
  );
}
