import {Button, showNotification} from 'platform/components';
import {HStack, Space} from 'platform/foundation';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {environment} from '@omnetic-dms/environment';
import i18n from '@omnetic-dms/i18n';
import {
  getWorkspaceFromUri,
  handleApiError,
  InspectionType,
  urlBuilder,
  UrlBuilderConfig,
  useCreateAuditMutation,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {DIGITAL_CERTIFICATE_TRANSLATE_KEYS_MAP} from '../../../types/CommonAppTypes';
import {InspectionIdForAuditId} from '../../../types/InspectionIdForAuditId';
import {AutoFillTemplates} from './AutoFillTemplates';
import {ExportPDF} from './ExportPDF';
import {VerifyCondition} from './VerifyCondition';

interface Props extends TestIdProps, Omit<InspectionIdForAuditId, 'auditId'> {}

export function ConditionHeader(props: Props) {
  const [createAuditV2] = useCreateAuditMutation();
  const {id} = useParams();
  const locale = useSelector(selectUserSelectedLanguage);

  const onShareLink = () => {
    createAuditV2({
      vehicleId: id || '',
      body: {
        inspectionType: InspectionType.VEHICLE_CONDITION,
        auditStructureId: undefined,
      },
    })
      .unwrap()
      .then((response) => {
        if (isNilOrEmpty(response.id)) {
          showNotification.error();
          return;
        }
        const {workspace} = getWorkspaceFromUri();

        const builderConfig: UrlBuilderConfig = {
          workspace,
          protocol: window.location.protocol,
          domain: environment.DIGITAL_CERTIFICATE_DOMAIN,
          route: `/${response.id}`,
          searchParam: `?lang=${DIGITAL_CERTIFICATE_TRANSLATE_KEYS_MAP[locale]}`,
        };

        const digitalCertificateUrl = urlBuilder(builderConfig);

        window.open(digitalCertificateUrl, '_blank')?.focus();
      })
      .catch(handleApiError);
  };

  return (
    <>
      <HStack spacing={2} justify="flex-end">
        <Button
          title={i18n.t('general.actions.shareLink')}
          variant="outlined"
          leftIcon="social/share"
          onClick={onShareLink}
        />
        <ExportPDF data-testid={props['data-testid']} />
        <AutoFillTemplates
          data-testid={suffixTestId('conditionHeader', props)}
          shouldUseAuditIdForUpdateTemplate={props.shouldUseAuditIdForUpdateTemplate}
        />
        <VerifyCondition data-testid={props['data-testid']} />
      </HStack>
      <Space vertical={5} />
    </>
  );
}
