import {Card, DataStatus, useDialog} from 'platform/components';
import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {find, isNil, mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  Section,
  useGetDocumentDatagridActions,
  useGetVehicleCustomerQuery,
  UploadDocumentsDialog,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {useRefreshDocumentCount} from '../../../../hooks/useRefreshDocumentCount';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';

interface ServiceCaseDocumentsProps {
  vehicleId: string | Nullish;
}

export function ServiceCaseDocuments(props: ServiceCaseDocumentsProps) {
  const {serviceCaseId} = useWorkshopUrl();
  const refreshDocumentCount = useRefreshDocumentCount(serviceCaseId);

  const {selectedOwnership, isSelectedOwnershipLoading, isSelectedOwnershipError} =
    useGetVehicleCustomerQuery(
      {vehicleId: props.vehicleId!},
      {
        skip: isNil(props.vehicleId),
        selectFromResult: ({data, isLoading, isError, isFetching}) => ({
          selectedOwnership: find(
            (ownership) => ownership?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR',
            data ?? []
          ),
          isSelectedOwnershipLoading: isLoading,
          isSelectedOwnershipError: isError,
          isSelectedOwnershipFetching: isFetching,
        }),
      }
    );
  const [actionCallback] = useGetDocumentDatagridActions(selectedOwnership?.customerId);

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const handleDocumentCreated = () => {
    refreshDataGrid();
    refreshDocumentCount();
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceCaseId}]),
    [serviceCaseId]
  );

  return (
    <Section>
      <Card
        title={i18n.t('general.labels.documents')}
        actions={[
          {
            variant: 'link',
            type: 'button',
            leftIcon: 'action/backup',
            onClick: openUploadDialog,
            title: i18n.t('entity.document.actions.uploadDocument'),
            'data-testid': testIds.workshop.serviceCaseDetail('add-document'),
          },
        ]}
      >
        <DataStatus
          isLoading={isSelectedOwnershipLoading}
          isError={isSelectedOwnershipError}
          minHeight={33}
        >
          <Box minHeight={40}>
            <DataGrid
              ref={dataGridRef}
              gridCode="document-context-list-service-case"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              autoHeight
              data-testid={testIds.workshop.serviceCaseDetail('documents')}
            />
          </Box>
        </DataStatus>
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={handleDocumentCreated}
        contextTarget="service-case"
        contextId={serviceCaseId}
        bulkContext={[{target: 'service-case', targetId: serviceCaseId}]}
        data-testid={testIds.workshop.serviceCaseDetail('upload-document')}
      />
    </Section>
  );
}
