import {Button} from 'platform/components';
import {Box, HStack, Icon} from 'platform/foundation';
import {css} from 'styled-components';

import {isNil, move, sortBy} from 'ramda';

import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useSourcingGetPresetsListQuery,
  useSourcingReorderPresetsMutation,
} from '@omnetic-dms/shared';
import {DragAndDrop, DragAndDropContext, DraggedItem} from '@omnetic-dms/teas';

import {SourcingFilterPresetsControl} from '../../hooks/useSourcingFilterPresets';

interface SourcingFilterPresetButtonsProps {
  filterPresetsControl: SourcingFilterPresetsControl;
  isSortingEnabled?: boolean;
}

export function FilterPresetButtons(props: SourcingFilterPresetButtonsProps) {
  const {data} = useSourcingGetPresetsListQuery();
  const [reorderPresets] = useSourcingReorderPresetsMutation();

  const orderedPresets = sortBy((a) => a.position, data ?? []);

  const onReorder = (draggedItem: DraggedItem) => {
    if (isNil(draggedItem.destination)) {
      return;
    }

    const reorderedFilterPresets = move(
      draggedItem.source.index,
      draggedItem.destination.index,
      orderedPresets
    );

    return reorderPresets({
      reorderPresetsRequestBody: {
        items: reorderedFilterPresets.map((reorderedFilterPreset, index) => ({
          id: reorderedFilterPreset.id,
          position: index + 1,
        })),
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  if (props.isSortingEnabled) {
    return (
      <Box flexGrow={1} overflowX="auto">
        <DragAndDropContext onDragEnd={onReorder}>
          <DragAndDrop
            direction="horizontal"
            droppableId="drop"
            css={css`
              > div {
                flex-wrap: wrap;
                gap: 8px;
              }
            `}
            items={
              orderedPresets.map((filterPreset) => ({
                id: filterPreset.id,
                component: (
                  <HStack align="center">
                    <Icon
                      value="action/drag_indicator"
                      color="palettes.neutral.60.100"
                      data-testid={testIds.sourcing.classifieds('filterPresetButtons-drag')}
                    />
                    <Button
                      key={filterPreset.id}
                      title={filterPreset.name}
                      onClick={() =>
                        props.filterPresetsControl.onSelectFilterPresetId(filterPreset.id)
                      }
                      variant={
                        filterPreset.id === props.filterPresetsControl.selectedFilterPresetId
                          ? 'primary'
                          : 'outlined'
                      }
                      data-testid={testIds.sourcing.classifieds(
                        `filterPresetButtons-${filterPreset.name}`
                      )}
                    />
                  </HStack>
                ),
              })) ?? []
            }
          />
        </DragAndDropContext>
      </Box>
    );
  }

  return (
    <HStack grow={1} spacing={2} wrap>
      {orderedPresets.map((filterPreset) => (
        <Button
          key={filterPreset.id}
          title={filterPreset.name}
          onClick={() => props.filterPresetsControl.onSelectFilterPresetId(filterPreset.id)}
          variant={
            filterPreset.id === props.filterPresetsControl.selectedFilterPresetId
              ? 'primary'
              : 'outlined'
          }
          data-testid={testIds.sourcing.classifieds(`filterPresetButtons-${filterPreset.name}`)}
        />
      ))}
    </HStack>
  );
}
