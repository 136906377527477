import {Trend, TrendProps} from 'platform/components';
import {Heading, HStack, Text, ThemeColorTextPath, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {defaultTo} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {DEFAULT_CURRENCY} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {DEFAULT_PRICE} from '../../../../../constants/defaultPrice';

interface HistoryPriceSummaryItemProps extends TestIdProps {
  title: string;
  price?: number | null;
  currency?: string | null;
  color?: ThemeColorTextPath;
  trend?: TrendProps;
}

export function HistoryPriceSummaryItem(props: HistoryPriceSummaryItemProps) {
  const formatCurrency = useFormatCurrency();

  return (
    <VStack spacing={1}>
      <Text size="xSmall" color="secondary" data-testid={suffixTestId('title', props)}>
        {props.title}
      </Text>
      <HStack spacing={2}>
        <Heading size={3} color={props.color} data-testid={suffixTestId('withVat', props)}>
          {formatCurrency(
            defaultTo(DEFAULT_PRICE, props.price),
            props.currency ?? DEFAULT_CURRENCY,
            2
          )}
        </Heading>
        {isNotNil(props.trend) && <Trend {...props.trend} />}
      </HStack>
    </VStack>
  );
}
