import {ActionCallback} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {isArray} from 'ramda-adjunct';

import {businessCaseRoutes} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

export const useBusinessCasesActionCallback = (): [ActionCallback] => {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, sourceSearchParams}) => {
      if (isArray(rowId)) {
        return;
      }
      const path = composePath(
        `${businessCaseRoutes.overview}?${sourceSearchParams}` as typeof businessCaseRoutes.overview,
        {params: {id: rowId}, isSearchParamsPreserved: false}
      );

      match(actionKey)
        .with('detail', () => navigate(path))
        .with('detail_newTab', () => window.open(path))
        .otherwise(() => {
          // TODO: Create warn utils T20-17310
          // eslint-disable-next-line no-console
          console.warn(`Action callback was not specified for action ${actionKey}`);
        });
    },
    [navigate]
  );

  return [actionCallback];
};
