import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  FormButton,
  FormControl,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import i18n from '../../../i18n/i18n';
import {CreateCustomerFormType} from '../types/CreateCustomerFormType';

interface ConfirmCustomerCreationProps extends RequiredTestIdProps {
  control: FormControl<CreateCustomerFormType>;
}

export function ConfirmCustomerCreation(props: ConfirmCustomerCreationProps) {
  return (
    <VStack>
      <Text>{i18n.t('entity.customer.labels.confirmCreating')}</Text>
      <ButtonGroup align="right" data-testid={suffixTestId('confirmCreateCustomer', props)}>
        <Button
          data-testid={suffixTestId('cancel', props)}
          variant="secondary"
          onClick={closeCurrentDialog}
          title={i18n.t('general.actions.cancel')}
        />
        <FormButton
          data-testid={suffixTestId('confirm', props)}
          control={props.control}
          type="submit"
          onClick={closeCurrentDialog}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </VStack>
  );
}
