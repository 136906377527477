import {ActionCreatorWithoutPayload} from '@reduxjs/toolkit';
import {format} from 'date-fns';
import {showNotification} from 'platform/components';

import {userApi} from '@omnetic-dms/shared';

import {Thunk} from '../../hooks/useThunkDispatch';
import {
  EditingVehicleState,
  EditVehicleIds,
  EditVehicleRequestPayload,
  ExportVehicleListRequestPayload,
  PushVehiclesToSaleRequestPayload,
  VehiclesSliceName,
} from '../../types/SourcingVehicleState';
import {CallApiFunc} from '../../utils/api';
import {createAsyncThunk} from '../../utils/createAsyncThunk';
import {removeDuplicateFromAdId} from '../../utils/removeDuplicateFromAdId';
import {downloadCsv} from '../../utils/someTeasUtils';
import {removeVehicleCartDetail} from '../vehicleDetail/reducer';
import {selectVehicleDetailVehicleData} from '../vehicleDetail/selectors';
import {VehicleActions} from './actions';
import {vehiclesEndpoints} from './endpoints';

type SourcingVehiclesEffects = {
  pushVehiclesToSaleRequest: Thunk<string[], PushVehiclesToSaleRequestPayload>;
  exportVehicleListRequest: Thunk<void, ExportVehicleListRequestPayload>;
};

export const DEFAULT_SIZE_FOR_SOURCING_VEHICLES_LOAD_API = 20;

export const createSourcingVehiclesEffects = (
  name: VehiclesSliceName,
  {setLoading, setEditingVehicle}: VehicleActions
): SourcingVehiclesEffects => {
  const endpoints = vehiclesEndpoints[name];

  const createEditVehicleRequest = (
    actionType: string,
    loadingState: EditingVehicleState,
    vehicleDetailAction: ActionCreatorWithoutPayload,
    callApi: (callApi: CallApiFunc, id: EditVehicleIds) => Promise<void>
  ) =>
    createAsyncThunk<EditVehicleIds, EditVehicleRequestPayload>(
      actionType,
      async ({id, successMessage}, {extra, dispatch, getState}) => {
        dispatch(setEditingVehicle(id, loadingState, true));

        try {
          await callApi(extra.callApi, id);
          // edit number in user stats
          dispatch(userApi.endpoints.getUserStats.initiate(undefined, {forceRefetch: true}));

          // edit detail if changed
          const vehicleDetail = selectVehicleDetailVehicleData(getState());
          const ids = Array.isArray(id) ? id : [id];
          if (vehicleDetail && ids.includes(vehicleDetail.adId)) {
            dispatch(vehicleDetailAction());
          }
          showNotification.success(successMessage?.toString());
        } catch (error: any) {
          showNotification.error(error.response?.data?.error?.message || error.toString());
        } finally {
          dispatch(setEditingVehicle(id, loadingState, false));
        }
        return id;
      }
    );

  const removeVehicleCartRequest = createEditVehicleRequest(
    `${name}/removeVehicleCartRequest`,
    'cart',
    removeVehicleCartDetail,
    async (callApi, id) => {
      if (Array.isArray(id)) {
        await callApi(endpoints.removeVehicleCartBulk, {
          requestBody: {externalIds: id},
        });
      } else {
        const externalId = removeDuplicateFromAdId(id);
        if (externalId) {
          await callApi(endpoints.removeVehicleCart, {
            externalId,
          });
        }
      }
    }
  );

  const pushVehiclesToSaleRequest = createAsyncThunk<string[], PushVehiclesToSaleRequestPayload>(
    `${name}/pushVehiclesToSaleRequest`,
    async (payload, {extra, dispatch}) => {
      const {ids, successMessage} = payload;

      dispatch(setLoading('pushingToSale', true));

      try {
        await extra.callApi(endpoints.buyVehicles, {
          ids,
        });
        showNotification.success(successMessage?.toString());

        const args: EditVehicleRequestPayload = {
          id: ids,
        };
        dispatch(removeVehicleCartRequest(args));
        return payload.ids ?? [];
      } catch (error: any) {
        showNotification.error(
          error.response.data?.errors?.map((e: {message: string}) => e?.message)?.join(', ') ??
            error.toString()
        );
        return [];
      } finally {
        dispatch(setLoading('pushingToSale', false));
      }
    }
  );

  const exportVehicleListRequest = createAsyncThunk<void, ExportVehicleListRequestPayload>(
    `${name}/exportVehicleListRequest`,
    async (payload, {extra, dispatch}) => {
      const {ids, buyerCountry, currency, margin, orderBy} = payload;
      try {
        dispatch(setLoading('exporting', true));
        const exportSelection = ids.length > 0;
        const params = {
          buyerCountry,
          currency,
          margin,
          orderBy,
        };
        let data;
        if (exportSelection) {
          data = await extra.callApi(endpoints.exportVehiclesBulk, {
            ...params,
            requestBody: {externalIds: ids},
          });
        } else {
          data = await extra.callApi(endpoints.exportAllVehicles, params);
        }
        const date = new Date();
        const fileName = `${format(date, 'yyyyMMdd')}_${format(
          date,
          'HHmmss'
        )}_Exported_vehicles_TEAS_Sourcing.csv`;

        downloadCsv(data, fileName);
      } catch (error: any) {
        showNotification.error(error?.response?.data?.error?.message || error?.toString());
      } finally {
        dispatch(setLoading('exporting', false));
      }
    }
  );

  return {
    pushVehiclesToSaleRequest,
    exportVehicleListRequest,
  };
};
