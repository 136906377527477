import {Card} from 'platform/components';
import {ActionCallback, DataGrid, QueryFilterObject} from 'platform/datagrid';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, Section} from '@omnetic-dms/shared';

import {composePath, suffixTestId, TestIdProps, useNavigate} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface IssueNotesProps extends TestIdProps {}

export function IssueNotes(props: IssueNotesProps) {
  const {serviceOrderIssueNoteId} = useWarehouseParams();
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const issueNoteId = isArray(rowId) ? head(rowId) : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(
          composePath(warehouseRoutes.issueNoteDetailOverview, {
            params: {id: issueNoteId},
          })
        )
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {originDocId: serviceOrderIssueNoteId}]),
    [serviceOrderIssueNoteId]
  );

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <Card title={i18n.t('page.warehouse.labels.issueNotes')}>
        <Box height="65vh">
          <DataGrid
            gridCode="issue-notes-service-order-issue-note"
            emptyState={{headline: i18n.t('general.labels.noData')}}
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid="issueNotesServiceOrderIssueNoteDatagrid"
          />
        </Box>
      </Card>
    </Section>
  );
}
