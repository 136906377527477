import {DataStatus} from 'platform/components';
import {Hide, Show, Text, VStack} from 'platform/foundation';
import {CurrencyFormat} from 'platform/locale';

import {useSelector} from 'react-redux';

import {isNaN} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {selectTenant, useGetFinanceSummaryQuery} from '@omnetic-dms/shared';
import {DEFAULT_CURRENCY} from '@omnetic-dms/teas';

import {Nullish} from 'shared';

interface FinanceSummaryProps {
  sellingPrice: string | Nullish;
  maxBuyingPrice: string | Nullish;
  vehicleId: string;
}

export function FinanceSummary({maxBuyingPrice, sellingPrice, vehicleId}: FinanceSummaryProps) {
  const {data: selectedTenant} = useSelector(selectTenant);
  const currency = selectedTenant?.currency ?? DEFAULT_CURRENCY;

  const {data: financeSummary, isError} = useGetFinanceSummaryQuery({
    vehicleId,
  });

  const expectedNetCosts = parseFloat(financeSummary?.expectedTotalNetCostsWithoutVat.amount ?? '');
  const validExpectedNetCosts = !isNaN(expectedNetCosts) ? expectedNetCosts : 0;

  const price =
    parseFloat(sellingPrice ?? '') - parseFloat(maxBuyingPrice ?? '') - validExpectedNetCosts;

  const isPriceValid = !isNaN(price) && price !== 0;
  const isPositive = price >= 1;

  return (
    <VStack align="flex-end">
      <DataStatus isError={isError}>
        <Hide when={isNaN(expectedNetCosts)}>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.vehicle.labels.expectedCost')}{' '}
            <CurrencyFormat decimals={2} number={expectedNetCosts} currency={currency} />
          </Text>
        </Hide>
      </DataStatus>
      <Show when={isPriceValid}>
        <Text size="small" color={isPositive ? 'success' : 'danger'}>
          {isPositive ? '+' : ''}
          <CurrencyFormat decimals={2} number={price} currency={currency} />
        </Text>
      </Show>
    </VStack>
  );
}
