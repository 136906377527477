import {Grid} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {useGetActiveSaleVehicleQuery, usePermissions} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {SummaryCard} from './SummaryCard';

interface EarningsCostsSummaryProps extends TestIdProps {
  vehicleId: string;
}

export function EarningsCostsSummary(props: EarningsCostsSummaryProps) {
  const {
    data,
    isLoading: isLoadingFinancialIndicators,
    isError: hasFinancialIndicatorsError,
  } = useGetActiveSaleVehicleQuery({
    vehicleId: props.vehicleId,
  });

  const financialIndicators = data?.financialIndicators;

  const [canReadCosts, canReadEarnings] = usePermissions({
    permissionKeys: ['vehicleReadCosts', 'vehicleReadEarnings'],
  });

  return (
    <Grid columns={[1, 2]}>
      <SummaryCard
        isError={hasFinancialIndicatorsError}
        isLoading={isLoadingFinancialIndicators}
        earnings={financialIndicators?.expectedEarnings}
        costs={financialIndicators?.expectedCosts}
        margin={financialIndicators?.expectedTotalGrossMargin}
        areCostsHidden={!canReadCosts}
        areEarningsHidden={!canReadEarnings}
        title={i18n.t('entity.earningsCosts.labels.expectedState')}
        data-testid={suffixTestId('expectedState', props)}
      />
      <SummaryCard
        isError={hasFinancialIndicatorsError}
        isLoading={isLoadingFinancialIndicators}
        earnings={financialIndicators?.realEarnings}
        earningsDifference={financialIndicators?.expectedAndRealEarningsDifference}
        costs={financialIndicators?.realCosts}
        costsDifference={financialIndicators?.expectedAndRealCostsDifference}
        areCostsHidden={!canReadCosts}
        areEarningsHidden={!canReadEarnings}
        margin={financialIndicators?.totalGrossMargin}
        title={i18n.t('entity.earningsCosts.labels.realState')}
        data-testid={suffixTestId('realState', props)}
      />
    </Grid>
  );
}
