import {CombinedState, Reducer, combineReducers} from 'redux';

import i18n from '@omnetic-dms/i18n';

import {
  DEFAULT_ORDER_PARAMS,
  DEFAULT_ORDER_ID,
  DEFAULT_RECOMMENDED_CLASSIFIEDS,
  DEFAULT_ALPHABET_CLASSIFIEDS,
  DEFAULT_RECOMMENDED_ID,
} from '../../types/SourcingOrderTypes';
import {DataViewContainerName, DataViewStateType} from './DataViewState';
import {createDataView} from './factory';

const classifiendsSortBy = [
  {
    name: i18n.t('entity.vehicle.labels.sortAlphabet'),
    orderBy: DEFAULT_ALPHABET_CLASSIFIEDS,
    id: 'ALPHABET',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByNewestAd'),
    orderBy: ['days_on_stock'],
    id: 'NEWEST_AD',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByOldestAd'),
    orderBy: ['-days_on_stock'],
    id: 'OLDES_AD',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByLowestMileage'),
    orderBy: ['mileage_range'],
    id: 'LOWEST_MILEAGE',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByNewestCar'),
    orderBy: ['-year'],
    id: 'NEWEST_CAR',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByHighestPrice'),
    orderBy: ['-price'],
    id: 'HIGHEST_PRICE',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByLowestPrice'),
    orderBy: ['price'],
    id: 'LOWEST_PRICE',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByFeatureRank'),
    orderBy: ['-feature_score'],
    id: 'FEATURE_RANK',
  },
];

export const classifiedsDataView = {
  name: DataViewContainerName.classifiedsLists,
  initialState: {
    orderById: DEFAULT_ORDER_ID,
  },
  sortByOptions: [
    {
      name: i18n.t('entity.vehicle.labels.sortByLastAdded'),
      orderBy: DEFAULT_ORDER_PARAMS,
      id: DEFAULT_ORDER_ID,
    },
    ...classifiendsSortBy,
  ],
};

// DATA VIEW types
export const classifiedsListsDataView = createDataView(classifiedsDataView);
export const classifiedsAllOffersDataView = createDataView({
  ...classifiedsDataView,
  name: DataViewContainerName.classifiedsAllOffers,
  initialState: {
    orderById: DEFAULT_RECOMMENDED_ID,
  },
  sortByOptions: [
    {
      name: i18n.t('entity.vehicle.labels.sortByRecommended'),
      orderBy: DEFAULT_RECOMMENDED_CLASSIFIEDS,
      id: DEFAULT_RECOMMENDED_ID,
    },
    ...classifiendsSortBy,
    {
      name: i18n.t('entity.vehicle.labels.sortByMaxMargin'),
      orderBy: ['-max_margin'],
      id: 'MAX_MARGIN',
    },
  ],
});

type CombinedReducer<K extends string, R> = Reducer<CombinedState<{[key in K]: R}>>;

export const dataViewReducer: CombinedReducer<DataViewContainerName, DataViewStateType> =
  combineReducers({
    [DataViewContainerName.classifiedsAllOffers]: classifiedsAllOffersDataView.reducer,
    [DataViewContainerName.classifiedsLists]: classifiedsListsDataView.reducer,
  });
