import {
  Button,
  FormButton,
  FormSubmitHandler,
  FormField,
  Form,
  showNotification,
} from 'platform/components';
import {Text, Space, VStack, Grid, Heading, Align} from 'platform/foundation';
import * as Yup from 'yup';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {loginRoutes, testIds} from '@omnetic-dms/routes';
import {
  getWorkspaceFromUri,
  RequestPasswordResetApiArg,
  useRequestPasswordResetMutation,
  handleApiError,
} from '@omnetic-dms/shared';

import {AuthContainer} from '../../components/AuthContainer/AuthContainer';

const {workspace} = getWorkspaceFromUri();

type ForgotPasswordForm = {
  email: string;
};

export function ForgotPassword() {
  const navigate = useNavigate();

  const [requestPasswordReset, {isLoading}] = useRequestPasswordResetMutation();

  const onSubmit: FormSubmitHandler<ForgotPasswordForm> = async ({email}, setErrors) => {
    const arg: RequestPasswordResetApiArg = {
      workspace: workspace!,
      requestPasswordResetRequestBody: {
        email,
      },
    };
    await requestPasswordReset(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.emailSendSuccessfully'));
        navigate(loginRoutes.login);
      })
      .catch((ex) => {
        handleApiError(ex, {setErrors});
      });
  };

  return (
    <>
      <Helmet>
        <title>{i18n.t('page.login.labels.forgottenPassword')}</title>
      </Helmet>

      <AuthContainer>
        <Form onSubmit={onSubmit} schema={schema}>
          {(control) => (
            <VStack spacing={4}>
              <Align center>
                <Heading size={3}>{i18n.t('page.login.labels.forgottenPassword')}</Heading>
                <Space vertical={4} />
                <Text color="secondary">{i18n.t('page.login.notifications.enterEmail')}</Text>
              </Align>
              <FormField
                name="email"
                type="email"
                autoComplete="on"
                isRequired
                control={control}
                label={i18n.t('general.labels.email')}
                data-testid={testIds.login.loginForgotPassword('email')}
              />
              <Grid columns={2} data-testid={testIds.login.loginForgotPassword('actions')}>
                <Button
                  data-testid={testIds.login.loginForgotPassword('backToLogin')}
                  variant="ghost"
                  isDisabled={isLoading}
                  onClick={() => navigate(loginRoutes.login)}
                  leftIcon="navigation/arrow_back"
                  title={i18n.t('page.login.actions.backToLogin')}
                />
                <FormButton
                  control={control}
                  data-testid={testIds.login.loginForgotPassword('resetPassword')}
                  type="submit"
                  isLoading={isLoading}
                  title={i18n.t('page.login.actions.resetPassword')}
                />
              </Grid>
            </VStack>
          )}
        </Form>
      </AuthContainer>
    </>
  );
}

const schema = Yup.object().shape({
  email: Yup.string()
    .max(64, i18n.t('general.notifications.errorEmailMax'))
    .required(i18n.t('general.notifications.errorEmailRequired')),
});
