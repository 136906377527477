import {Card, showNotification} from 'platform/components';
import {Box, Grid, HStack, Show, VStack} from 'platform/foundation';

import {i18n} from '@omnetic-dms/i18n';
import {
  CommentsWidget,
  EntityResourceIds,
  handleApiError,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useGetVehicleInsightsQuery,
  useGetVehicleWarehouseManagementSettingsQuery,
  usePatchAdvertisedVehicleInAdvertisementUpdatedAtMutation,
  usePatchSaleVehicleStockInDateMutation,
  usePermissions,
} from '@omnetic-dms/shared';

import {getApiDateString, parseDate} from 'shared';

import {VehicleNoteWidget} from '../../../../../components/VehicleNoteWidget/VehicleNoteWidget';
import {InsightItem} from './InsightItem';

interface VehicleInsightsCardProps {
  vehicleId: string;
}

export function VehicleInsightsCard(props: VehicleInsightsCardProps) {
  const {data: vehicleInsights, refetch: refetchVehicleInsights} = useGetVehicleInsightsQuery({
    vehicleId: props.vehicleId,
  });
  const {data: saleVehicle, refetch: refetchSaleVehicleQuery} = useGetSaleVehicleQuery({
    vehicleId: props.vehicleId,
  });
  const {data: warehouseSettings, isLoading: isWarehouseSettingsQueryLoading} =
    useGetVehicleWarehouseManagementSettingsQuery();

  const [patchUpdatedAtMutation, {isLoading: isPatchUpdatedAtMutationLoading}] =
    usePatchAdvertisedVehicleInAdvertisementUpdatedAtMutation();
  const [patchStockInAtMutation, {isLoading: isPatchStockInAtMutationLoading}] =
    usePatchSaleVehicleStockInDateMutation();

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
    canEditPublishedDate,
    canEditStockInDate,
  ] = usePermissions({
    permissionKeys: [
      'vehicleCommentRead',
      'vehicleCommentAdd',
      'vehicleCommentDelete',
      'vehicleEditPublishedDate',
      'vehicleStockInDate',
    ],
    scopes: {
      vehicleCommentAdd: vehicleParticipation,
      vehicleCommentRead: vehicleParticipation,
      vehicleEditPublishedDate: vehicleParticipation,
    },
  });

  return (
    <VStack spacing={4}>
      <Card title={i18n.t('entity.interest.labels.overviewTab')}>
        <Box padding={4}>
          <HStack spacing={4}>
            <Show when={vehicleInsights}>
              <InsightItem
                value={vehicleInsights?.daysInStock}
                title={i18n.t('entity.vehicle.labels.overview.daysOnStock')}
                subtitle={i18n.t('entity.vehicle.labels.overview.stockInDate')}
                editDialogModalLabel={i18n.t(
                  'entity.vehicle.labels.overview.editModal.stockInDate'
                )}
                {...(saleVehicle?.stockInDate && {date: parseDate(saleVehicle?.stockInDate)})}
                isMutationLoading={isPatchStockInAtMutationLoading}
                onEdit={
                  !isWarehouseSettingsQueryLoading &&
                  !warehouseSettings?.isEnabled &&
                  canEditStockInDate
                    ? (date) =>
                        patchStockInAtMutation({
                          vehicleId: props.vehicleId,
                          patchSaleVehicleStockInDateRequestBody: {
                            stockInDate: getApiDateString(
                              parseDate(date.getTime() - date.getTimezoneOffset() * 60000)
                            ),
                          },
                        })
                          .unwrap()
                          .then(async () => {
                            showNotification.success(
                              i18n.t('general.notifications.changesSuccessfullySaved')
                            );
                            await refetchSaleVehicleQuery();
                            await refetchVehicleInsights();
                            return true;
                          })
                          .catch((err) => {
                            handleApiError(err);
                            return false;
                          })
                    : undefined
                }
              />
              <InsightItem
                value={vehicleInsights?.daysInAdvertising}
                title={i18n.t('entity.vehicle.labels.overview.daysAdvertized')}
                subtitle={i18n.t('entity.vehicle.labels.overview.publishedOn')}
                editDialogModalLabel={i18n.t(
                  'entity.vehicle.labels.overview.editModal.publishedOn'
                )}
                isMutationLoading={isPatchUpdatedAtMutationLoading}
                onEdit={
                  canEditPublishedDate
                    ? (date) =>
                        patchUpdatedAtMutation({
                          vehicleId: props.vehicleId,
                          patchAdvertisedVehicleInAdvertisementUpdatedAtRequestBody: {
                            inAdvertisementUpdatedAt: parseDate(
                              date.getTime() - date.getTimezoneOffset() * 60000
                            ).toISOString(),
                          },
                        })
                          .unwrap()
                          .then(async () => {
                            showNotification.success(
                              i18n.t('general.notifications.changesSuccessfullySaved')
                            );
                            await refetchVehicleInsights();
                            return true;
                          })
                          .catch((err) => {
                            handleApiError(err);
                            return false;
                          })
                    : undefined
                }
              />
              <InsightItem
                value={vehicleInsights?.daysSinceReprice}
                title={i18n.t('entity.vehicle.labels.overview.daysFromReprice')}
                subtitle={i18n.t('entity.vehicle.labels.overview.repricedOn')}
              />
            </Show>
          </HStack>
        </Box>
      </Card>
      <Grid columns={2} align="flex-start">
        <Show when={hasVehicleCommentReadPermission}>
          <CommentsWidget
            title={i18n.t('entity.vehicle.labels.vehicleComments')}
            resourceId={EntityResourceIds.vehicle}
            recordId={props.vehicleId}
            isAddDisabled={!hasVehicleCommentAddPermission}
            isDeleteDisabled={!hasVehicleCommentDeletePermission}
          />
        </Show>
        <VehicleNoteWidget
          vehicleId={props.vehicleId}
          title={i18n.t('entity.vehicle.labels.vehicleAdditionalNotes')}
        />
      </Grid>
    </VStack>
  );
}
