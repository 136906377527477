import {ActionCallback, DataGrid} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  usePatchServiceCaseTypeSetAsActiveMutation,
  usePatchServiceCaseTypeSetAsInactiveMutation,
} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function ServiceCaseType() {
  const navigate = useNavigate();

  const [patchServiceCaseTypeSetAsActive] = usePatchServiceCaseTypeSetAsActiveMutation();
  const [patchServiceCaseTypeSetAsInactive] = usePatchServiceCaseTypeSetAsInactiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(composePath(settingsRoutes.serviceCaseTypeDetail, {params: {id}}))
      )
      .with('setAsActive', () =>
        patchServiceCaseTypeSetAsActive({serviceCaseTypeId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsInactive', () =>
        patchServiceCaseTypeSetAsInactive({serviceCaseTypeId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .otherwise(always(undefined));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.workshopPresetsCategory.labels.serviceCaseType'),
        actions: [
          {
            title: i18n.t('entity.serviceCase.actions.newServiceCaseType'),
            onClick: () => navigate(settingsRoutes.serviceCaseTypeCreate),
            type: 'button',
          },
        ],
      }}
      data-testid={testIds.settings.serviceCaseType('template')}
    >
      <DataGrid
        gridCode="service-case-type"
        actionCallback={actionCallback}
        data-testid={testIds.settings.serviceCaseType('datagrid')}
      />
    </SettingsTemplate>
  );
}
