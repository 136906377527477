import {motion} from 'framer-motion';
import {showNotification, useTranslationContext} from 'platform/components';
import {
  AdaptiveImage,
  Box,
  Center,
  getValueByDevice,
  Grid,
  Hide,
  Icon,
  isTouchable,
  makeStorageUrl,
  useDevice,
  VStack,
} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {always, dec, inc, isNotNil} from 'ramda';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {InternalLightboxControls} from '../types/InternalLightboxControls';
import {LightboxAction} from '../types/LightboxAction';
import {LightboxSlide} from '../types/LightboxSlide';
import {Actions} from './Actions';
import {ScrollWithoutScrollbar} from './ScrollWithoutScrollbar';
import {VideoItem} from './VideoItem';

interface GridViewProps extends RequiredTestIdProps {
  controls: InternalLightboxControls;
  slides: LightboxSlide[];
}

export function GridView(props: GridViewProps) {
  const device = useDevice();
  const isTouchableDevice = isTouchable();
  const theme = useTheme();

  const t = useTranslationContext();

  const columnsByScale = match<number, number[]>(props.controls.gridScale)
    .with(3, always([1, 1, 1, 1]))
    .with(2, always([1, 2, 2, 3]))
    .with(1, always([2, 3, 3, 5]))
    .with(-1, always([4, 5, 5, 7]))
    .with(-2, always([5, 6, 6, 8]))
    .with(-3, always([5, 7, 9, 10]))
    .otherwise(always([3, 4, 4, 6]));

  const columns = getValueByDevice(
    device,
    columnsByScale[0],
    columnsByScale[1],
    columnsByScale[2],
    columnsByScale[3]
  );

  const minZoomTreshold = getValueByDevice(device, 5, 7, 9, 10);
  const isGridItemHoverEnabled = !isTouchableDevice && columns !== 1;

  return (
    <VStack width="100%">
      <ScrollWithoutScrollbar $direction="y">
        <Box padding={2}>
          <Grid data-testid={suffixTestId('gridView', props)} columns={columns} spacing={2}>
            {props.slides.map((slide, index) => (
              <motion.div
                key={slide.id}
                css={css`
                  cursor: pointer;
                  opacity: 1;
                  transition: opacity 0.3s ease-out;

                  ${isGridItemHoverEnabled &&
                  `
                  &:hover {
                    opacity: 0.6;
                    }
                  `}
                `}
                layout
                transition={{type: 'keyframes', stiffness: 350, damping: 25, duration: 0.3}}
                onClick={() => props.controls.onDetailOpen(index)}
              >
                <Box
                  borderRadius="small"
                  overflow="hidden"
                  ratio="4 / 3"
                  backgroundColor="palettes.neutral.800.100"
                >
                  {slide.type === 'image' ? (
                    <AdaptiveImage
                      data-testid={suffixTestId(`gridView-[${index}]`, props)}
                      url={slide.resizeUrl ?? slide.url}
                      makeUrl={makeStorageUrl}
                      shouldUseOriginal={
                        slide.resizeUrl?.startsWith('data:') ?? slide.url?.startsWith('data:')
                      }
                      ratio="4 / 3"
                      fit="cover"
                      placeholder={
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          backgroundColor={theme.components.Image.emptyFill}
                          borderRadius="small"
                        >
                          <Center width="100%" height="100%">
                            <Icon color="palettes.neutral.70.100" value="image/photo" size={6} />
                          </Center>
                        </Box>
                      }
                    />
                  ) : null}
                  {slide.type === 'video' ? (
                    <VideoItem
                      data-testid={suffixTestId(`gridView-[${index}]`, props)}
                      video={slide}
                    />
                  ) : null}
                </Box>
              </motion.div>
            ))}
          </Grid>
          <Hide when={isNotNil(props.controls.activeDetailIndex)}>
            <Actions
              data-testid={suffixTestId('gridView', props)}
              actionPosition={{
                top: 4,
                left: 4,
                right: 'auto',
              }}
              actions={[
                [
                  {
                    icon: 'social/share',
                    onClick: async () => {
                      if (navigator.share) {
                        try {
                          await navigator.share({
                            title: document.title,
                            url: window.location.href,
                          });
                        } catch (err: unknown) {
                          if (!(err instanceof Error && err.toString().includes('AbortError'))) {
                            showNotification.error(t('platform.lightbox.share-error'));
                          }
                        }
                      } else {
                        await navigator.clipboard.writeText(window.location.href);
                        showNotification.info(t('platform.lightbox.copy-to-clipboard'));
                      }
                    },
                    id: 'shareBtn',
                  },
                ],
                buildArray<LightboxAction>([])
                  .add({
                    id: 'zoomOutBtn',
                    icon: 'lightbox/minus',
                    isDisabled: columns === minZoomTreshold,
                    onClick: () => props.controls.onGridScaleChange(dec(props.controls.gridScale)),
                  })
                  .add({
                    id: 'zoomInBtn',
                    icon: 'lightbox/plus',
                    isDisabled: columns === 1,
                    onClick: () => props.controls.onGridScaleChange(inc(props.controls.gridScale)),
                  }),
              ]}
              zIndex="LIGHTBOX_ACTIONS"
            />
          </Hide>
        </Box>
      </ScrollWithoutScrollbar>
    </VStack>
  );
}
