import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, HStack, Heading, Space, VStack} from 'platform/foundation';
import {array, boolean, object} from 'yup';

import {DeepPartial, UseFormReturn} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  AddrDetailApiResponse,
  AddressSearch,
  BillingInformationV2,
  PutBranchV2ApiArg,
  handleApiError,
  useGetBranchV2Query,
  useGetTenantV2Query,
  usePhoneNumbers,
  usePostBranchV2Mutation,
  usePutBranchV2Mutation,
  useTenant,
} from '@omnetic-dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ContactPersonList} from './components/ContactPersonList';

const breadcrumbs = [
  {label: i18n.t('entity.branch.labels.branches'), href: settingsRoutes.branches},
];

export function BranchDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const isCreating = isNil(id);

  const {data: tenant} = useGetTenantV2Query();
  const {countriesOptions} = usePhoneNumbers();

  const [updateBranch] = usePutBranchV2Mutation();
  const [createBranch] = usePostBranchV2Mutation();

  const {tenantCountry} = useTenant();
  const {
    data: branch,
    isLoading,
    isError,
  } = useGetBranchV2Query({id: id ?? ''}, {skip: isCreating});

  const handleSubmit: FormSubmitHandler<PutBranchV2ApiArg> = async (values) => {
    const submitAction = isCreating ? createBranch : updateBranch;
    await submitAction({...values, id: id ?? ''})
      .unwrap()
      .then(() => {
        showNotification.success();
        navigate(settingsRoutes.branches);
      })
      .catch(handleApiError);
  };

  const setAddressFormSearch =
    (formApi: UseFormReturn<PutBranchV2ApiArg>) => (address: AddrDetailApiResponse) => {
      if (isNil(tenant)) {
        showNotification.error();
        return;
      }

      formApi.setValue(
        'address',
        {
          city: address?.city ?? '',
          street: address?.street ?? '',
          zip: address?.zipCode ?? '',
          state: address?.state ?? '',
          prefix: address?.prefix ?? '',
          descriptiveNumber: address?.descriptiveNumber ?? null,
          orientationNumber: address?.orientationNumber ?? null,
          addressComplement: null,
          district: null,
          country: tenant.contactInformation.address.country,
          coordinates: null,
          postal: null,
        },
        {shouldValidate: true}
      );
    };

  const billingInformationOptions =
    tenant?.billingInformation.map(billingInformationToOption) ?? [];
  const handleCreateNewBillingInformation = () => navigate(settingsRoutes.billingInformationNew);

  const defaultValues: DeepPartial<PutBranchV2ApiArg> = {
    name: branch?.name,
    accountingExternalId: branch?.accountingExternalId,
    publicContacts: branch?.publicContacts,
    contactPersons: branch?.contactPersons,
    address: {
      city: branch?.address?.city ?? '',
      street: branch?.address?.street ?? '',
      zip: branch?.address?.zip ?? '',
      state: branch?.address?.state ?? '',
      prefix: branch?.address?.prefix ?? '',
      descriptiveNumber: branch?.address?.descriptiveNumber ?? '',
      orientationNumber: branch?.address?.orientationNumber ?? null,
      addressComplement: branch?.address?.addressComplement ?? null,
      district: branch?.address?.district ?? null,
      country: tenantCountry ?? '',
      coordinates: null,
      postal: null,
    },
    billingInformationId: branch?.billingInformation?.id,
  };

  const redirectToOverview = () => navigate(settingsRoutes.branches);

  return (
    <SettingsTemplate
      isLoading={isLoading}
      isError={isError}
      isCreating={isCreating}
      header={{
        title: branch?.name || i18n.t('entity.branch.labels.createNewBranch'),
        subtitle: isNotNil(branch)
          ? `${i18n.t('entity.branch.labels.branchId')}: ${branch.id}`
          : undefined,
        breadcrumbs,
      }}
      data-testid={testIds.settings.branches('detail')}
    >
      <Form<PutBranchV2ApiArg>
        defaultValues={defaultValues}
        schema={schema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <VStack>
            <SettingsSection>
              <VStack spacing={5}>
                <FormField
                  control={control}
                  type="text"
                  name="name"
                  isRequired
                  label={i18n.t('page.settings.labels.marketingName')}
                  data-testid={testIds.settings.branchesEdit('marketingName')}
                />

                <FormField
                  control={control}
                  type="text"
                  name="accountingExternalId"
                  label={i18n.t('entity.vehicle.labels.externalId')}
                  data-testid={testIds.settings.branchesEdit('externalId')}
                />
              </VStack>
            </SettingsSection>

            <Separator spacing={5} />

            <SettingsSection>
              <Grid columns={2}>
                <GridItem span={2}>
                  <Heading size={4}>{i18n.t('entity.customer.labels.publicContacts')}</Heading>
                </GridItem>

                <FormField
                  name="publicContacts.phoneNumber"
                  label={i18n.t('entity.person.labels.phoneNumber')}
                  control={control}
                  type="phone"
                  countries={countriesOptions}
                  data-testid={testIds.settings.branchesEdit('publicContactsPhoneNumber')}
                />
                <FormField
                  name="publicContacts.emailAddress"
                  label={i18n.t('entity.email.labels.email')}
                  control={control}
                  type="email"
                  data-testid={testIds.settings.branchesEdit('publicContactsEmail')}
                />
                <GridItem span={2}>
                  <FormField
                    control={control}
                    type="text"
                    name="publicContacts.website"
                    label={i18n.t('general.labels.website')}
                    data-testid={testIds.settings.branchesEdit('publicContactsWebsite')}
                  />
                </GridItem>
              </Grid>
            </SettingsSection>

            <Separator spacing={5} />

            <SettingsSection>
              <VStack spacing={5}>
                <Heading size={4}>{i18n.t('entity.address.labels.address')}</Heading>
                <AddressSearch onSelect={setAddressFormSearch(formApi)} />
                <Grid columns={4}>
                  <GridItem span={2}>
                    <FormField
                      name="address.street"
                      label={i18n.t('entity.address.labels.street')}
                      control={control}
                      type="text"
                      isRequired
                      data-testid={testIds.settings.branchesEdit('addressStreet')}
                    />
                  </GridItem>
                  <FormField
                    name="address.descriptiveNumber"
                    label={i18n.t('entity.address.labels.descriptiveNumber')}
                    control={control}
                    type="text"
                    data-testid={testIds.settings.branchesEdit('addressDescriptiveNumber')}
                  />
                  <FormField
                    name="address.orientationNumber"
                    label={i18n.t('entity.address.labels.orientationNumber')}
                    control={control}
                    type="text"
                    data-testid={testIds.settings.branchesEdit('addressOrientationNumber')}
                  />
                  <GridItem span={2}>
                    <FormField
                      name="address.city"
                      label={i18n.t('entity.address.labels.city')}
                      control={control}
                      type="text"
                      isRequired
                      data-testid={testIds.settings.branchesEdit('addressCity')}
                    />
                  </GridItem>

                  <FormField
                    name="address.district"
                    label={i18n.t('entity.address.labels.district')}
                    control={control}
                    type="text"
                    data-testid={testIds.settings.branchesEdit('addressDistrict')}
                  />
                  <FormField
                    name="address.zip"
                    label={i18n.t('entity.address.labels.zipCode')}
                    control={control}
                    type="text"
                    isRequired
                    data-testid={testIds.settings.branchesEdit('addressZipCode')}
                  />
                </Grid>
              </VStack>
            </SettingsSection>

            <Separator spacing={5} />

            <VStack spacing={5}>
              <HStack justify="space-between" align="center">
                <Heading size={4}>{i18n.t('entity.customer.labels.billingInformation')}</Heading>
                <Button
                  title={i18n.t('entity.customer.actions.addNewBillingInformation')}
                  leftIcon="content/add_circle"
                  variant="link"
                  onClick={handleCreateNewBillingInformation}
                  data-testid={testIds.settings.branchesEdit('addNewBillingInformation')}
                />
              </HStack>
              <SettingsSection>
                <DataStatus isEmpty={isNilOrEmpty(billingInformationOptions)}>
                  <FormField
                    type="choice"
                    control={control}
                    name="billingInformationId"
                    isRequired
                    isNotClearable
                    options={billingInformationOptions}
                    data-testid={testIds.settings.branchesEdit('billingInformationId')}
                  />
                </DataStatus>
              </SettingsSection>
            </VStack>

            <Separator spacing={5} />

            <ContactPersonList control={control} formApi={formApi} />

            <Space vertical={5} />

            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.close')}
                onClick={redirectToOverview}
                variant="secondary"
                data-testid={testIds.settings.branchesEdit('close')}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.save')}
                data-testid={testIds.settings.branchesEdit('save')}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  name: yupString.required(),
  accountingExternalId: yupString,
  billingInformationId: yupString.required(),
  publicContacts: object({
    phoneNumber: object().default(null).nullable(),
    emailAddress: yupString.default(null),
    website: yupString.default(null),
  })
    .default(null)
    .nullable(),
  address: object({
    city: yupString.required(),
    street: yupString.required(),
    zip: yupString.required(),
    state: yupString,
    descriptiveNumber: yupString,
    orientationNumber: yupString,
    district: yupString,
  }).required(),
  contactPersons: array(
    object({
      isPrimary: boolean(),
      firstName: yupString,
      lastName: yupString,
      role: yupString,
      phoneNumber: object().nullable(),
      emailAddress: yupString,
    })
  ).nullable(),
});

const billingInformationToOption = (billingInformation: BillingInformationV2) => ({
  label: billingInformation.contactInformation.companyName,
  value: billingInformation.id,
});
