import {Option} from 'platform/components';

import {defaultTo, isNil} from 'ramda';

import {TemplateResponseBodyV2} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getOptionsFromTemplates = (
  templates: TemplateResponseBodyV2[] | Nullish
): Option[] => {
  if (isNil(templates)) {
    return [];
  }

  return templates.map((template) => ({
    label: defaultTo('', template.title),
    value: template.id,
  }));
};
