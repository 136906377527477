import {find, isNil, join, pathOr, pipe, propEq, reject} from 'ramda';

import {Nullish} from 'shared';

import {useGetIdentityCardTypesQuery} from '../api/cachedApi';
import {IdentityCardDataResponseBody} from '../types/api/customer';
import {useCountry} from './useCountry';

export function useIdentityCard() {
  const {data: identityCardTypes} = useGetIdentityCardTypesQuery();
  const {getCountryName} = useCountry();

  const getIdentityCardAttributes = (identityCard: IdentityCardDataResponseBody | Nullish) => [
    pathOr(
      identityCard?.type,
      ['name'],
      find(propEq(identityCard?.type, 'code'))(identityCardTypes ?? [])
    ),
    identityCard?.cardNumber,
    identityCard?.issuer,
    getCountryName(identityCard?.issuedInCountryCode),
  ];

  const composeIdentityCard = (identityCard: IdentityCardDataResponseBody | Nullish) => {
    if (!identityCard) {
      return null;
    }
    return pipe(getIdentityCardAttributes, reject(isNil), join(', '))(identityCard);
  };

  const identityCardOptions = identityCardTypes?.map((i) => ({
    value: i.code,
    label: i.name,
  }));

  return {composeIdentityCard, identityCardOptions};
}
