import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {GetEmployeePersonalDataApiResponse} from '@omnetic-dms/shared';

export const getEmployeeName = (employee?: GetEmployeePersonalDataApiResponse) => {
  if (isNil(employee)) {
    return '';
  }

  return [
    employee.personalData?.titleBefore,
    employee.personalData?.firstName,
    employee.personalData?.lastName,
    employee.personalData?.titleAfter,
  ]
    .filter(isNotNilOrEmpty)
    .join(' ');
};
