import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

export function mergeBasketItems<
  T extends {id: string},
  U extends {id: string; isSelected: boolean},
>(newBasketItems: T[] | Nullish, mappedBasketItems: U[] | Nullish): U[] | Nullish {
  if (isNilOrEmpty(newBasketItems)) {
    return [];
  }

  const mergedBasketItems = newBasketItems?.map((newBasketItem) => {
    const existingBasketItem = mappedBasketItems?.find(
      (existingBasketItem) => existingBasketItem.id === newBasketItem.id
    );

    return existingBasketItem
      ? {...existingBasketItem, ...newBasketItem}
      : {...newBasketItem, isSelected: false};
  });

  return mergedBasketItems as U[] | Nullish;
}
