import {
  ButtonGroup,
  DataStatus,
  Dialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box} from 'platform/foundation';
import {object, string} from 'yup';

import {Helmet} from 'react-helmet-async';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  handleApiError,
  useGetDirectSaleVariantsQuery,
  usePutDirectSaleActionMutation,
} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {DirectSaleVariantChangeForm} from '../../types/DirectSaleVariantChangeForm';
import {getOptionsFromDirectSaleVariants} from '../../utils/getOptionsFromDirectSaleVariants';

export function DirectSalesVariantChange() {
  const {directSaleId, directSaleVariantId} = useWarehouseParams();
  const navigate = useNavigate();

  const {
    data: directSaleVariants,
    isLoading: isDirectSaleVariantsLoading,
    isError: hasDirectSaleVariantsError,
  } = useGetDirectSaleVariantsQuery();

  const [changeDirectSaleVariant] = usePutDirectSaleActionMutation();

  const handleSubmit: FormSubmitHandler<DirectSaleVariantChangeForm> = async (formValues) => {
    await changeDirectSaleVariant({
      directSaleId,
      body: {actionKey: 'variantChange', newDirectSaleVariantId: formValues.directSaleVariantId},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.directSaleVariantChanged'));

        navigate(
          composePath(warehouseRoutes.directSalesDetailOverview, {
            params: {
              id: directSaleId,
            },
          })
        );
      })
      .catch(handleApiError);
  };

  const handleDiscard = () => {
    navigate(
      composePath(warehouseRoutes.directSalesDetailOverview, {
        params: {
          id: directSaleId,
        },
      })
    );
  };

  const defaultValues: DirectSaleVariantChangeForm = {
    directSaleVariantId,
  };

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.changeDirectSaleVariant')} />
      <DataStatus
        minHeight={60}
        isLoading={isDirectSaleVariantsLoading}
        isError={hasDirectSaleVariantsError}
      >
        <Dialog
          isOpen
          title={i18n.t('page.warehouse.labels.changeDirectSaleVariant')}
          size="small"
          withAdditionalFooter
          scrollBehavior="outside"
          onClose={handleDiscard}
          data-testid={testIds.warehouse.directSalesVariantChange('dialog')}
        >
          <Form<DirectSaleVariantChangeForm>
            schema={formSchema}
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
          >
            {(control) => (
              <>
                <Box height={20}>
                  <FormField
                    isRequired
                    isNotClearable
                    control={control}
                    name="directSaleVariantId"
                    type="choice"
                    label={i18n.t('page.warehouse.labels.directSaleVariant')}
                    options={getOptionsFromDirectSaleVariants(directSaleVariants)}
                    data-testid={testIds.warehouse.directSalesVariantChange('directSaleVariantId')}
                  />
                </Box>

                <DialogFooter>
                  <ButtonGroup align="right">
                    <FormButton
                      type="button"
                      title={i18n.t('general.actions.discard')}
                      variant="secondary"
                      control={control}
                      onClick={handleDiscard}
                      data-testid={testIds.warehouse.directSalesVariantChange('discard')}
                    />
                    <FormButton
                      type="submit"
                      title={i18n.t('general.actions.change')}
                      variant="primary"
                      control={control}
                      data-testid={testIds.warehouse.directSalesVariantChange('submit')}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </>
            )}
          </Form>
        </Dialog>
      </DataStatus>
    </>
  );
}

const formSchema = object({
  directSaleVariantId: string().nullable().required(),
});
