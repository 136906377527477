import {BaseVoidRequestSchema, BaseVoidResponseSchema} from '../types/api/base';
import {
  GetCodeListOptionsRequest,
  GetCodeListOptionsRequestSchema,
  GetCodeListOptionsResponse,
  GetCodeListOptionsResponseSchema,
  GetCodeListRequest,
  GetCodeListResponse,
  GetCodeListResponseSchema,
  GetStaticValueListRequest,
  GetStaticValueListRequestSchema,
  GetStaticValueListResponse,
} from '../types/api/metadaWarehouseCodeList';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseCodeListApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getEnums: build.query<GetStaticValueListResponse, GetStaticValueListRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/staticValueList`,
        params: {listCode: queryArg.listCode},
        method: 'GET',
        invalidatesTags: ['enums'],
      }),
      extraOptions: {
        requestSchema: GetStaticValueListRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
    getMeasurementUnitsEnums: build.query<GetCodeListResponse, GetCodeListRequest>({
      query: () => ({
        url: `warehouse/v1/codelist/WRH-MEASUREMENT-UNIT`,
        method: 'GET',
        invalidatesTags: ['measurementUnits'],
      }),
      extraOptions: {
        requestSchema: BaseVoidRequestSchema,
        responseSchema: GetCodeListResponseSchema,
      },
    }),
    getWarehousePricingEnums: build.query<GetCodeListResponse, GetCodeListRequest>({
      query: () => ({
        url: `warehouse/v1/codelist/WRH-WAREHOUSE-PRICING`,
        method: 'GET',
        invalidatesTags: ['warehousePricing'],
      }),
      extraOptions: {
        requestSchema: BaseVoidRequestSchema,
        responseSchema: GetCodeListResponseSchema,
      },
    }),
    getWorkRateTypes: build.query<GetCodeListResponse, GetCodeListRequest>({
      query: () => ({
        url: `warehouse/v1/codelist/WRH-RATE-TYPE`,
        method: 'GET',
        invalidatesTags: ['workRateTypes'],
      }),
      extraOptions: {
        requestSchema: BaseVoidRequestSchema,
        responseSchema: GetCodeListResponseSchema,
      },
    }),
    getMaterialPriceTypes: build.query<GetCodeListResponse, GetCodeListRequest>({
      query: () => ({
        url: `warehouse/v1/codelist/WRH-PRICE-TYPE-MATERIAL`,
        method: 'GET',
        invalidatesTags: ['materialPriceTypes'],
      }),
      extraOptions: {
        requestSchema: BaseVoidRequestSchema,
        responseSchema: GetCodeListResponseSchema,
      },
    }),
    getCodeListOptions: build.query<GetCodeListOptionsResponse, GetCodeListOptionsRequest>({
      query: (queryArg) => ({
        url: `/core/v1/codelist`,
        params: {category: queryArg.category},
      }),
      transformResponse: (response: GetCodeListResponse) =>
        (response ?? []).map((item) => ({label: item?.name ?? '', value: item?.code ?? ''})),
      extraOptions: {
        requestSchema: GetCodeListOptionsRequestSchema,
        responseSchema: GetCodeListOptionsResponseSchema,
      },
    }),
  }),
});

export const {
  useGetEnumsQuery,
  useGetMeasurementUnitsEnumsQuery,
  useGetWarehousePricingEnumsQuery,
  useGetWorkRateTypesQuery,
  useGetMaterialPriceTypesQuery,
  useGetCodeListOptionsQuery,
} = metadaWarehouseCodeListApi;
