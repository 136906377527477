import {TextInput} from '../../TextInput/TextInput';
import {useFieldProps} from '../hooks/useFieldProps';
import {TextRendererResponseBody} from '../types/modelSchema';

export function TextRenderer(props: TextRendererResponseBody) {
  const {name} = props;
  const {onChange, onBlur, onFocus, value} = useFieldProps(props);

  return (
    <TextInput
      label={props.label}
      placeholder={props.placeholder}
      data-testid={name}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || ''}
    />
  );
}
