import {
  ButtonGroup,
  Card,
  Checkbox,
  IconButton,
  NumberInput,
  Tooltip,
  openDeleteDialog,
} from 'platform/components';
import {Box, HStack, Heading, Space, VStack, Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {Fragment} from 'react';

import {isNil, isNotNil, not} from 'ramda';

import {EMPTY_PLACEHOLDER, TestIdProps, getRandomId, suffixTestId, yupNumber} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useBasketItemQuantity} from '../../../hooks/useBasketItemQuantity';
import i18n from '../../../i18n/i18n';
import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {LabourBasketItem} from '../../../types/basket/LabourBasketItem';
import {LabourBasketItemEditingDetails} from '../../../types/basket/LabourBasketItemEditingDetails';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';

interface AfterSalesLabourBasketItemProps<T extends LabourBasketItem> extends TestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: LabourBasketItemEditingDetails) => void;
  onDelete: (itemId: string) => Promise<void>;
}

export function AfterSalesLabourBasketItem<T extends LabourBasketItem>(
  props: AfterSalesLabourBasketItemProps<T>
) {
  const formatCurrency = useFormatCurrency();

  const {quantity, quantityError, handleQuantityChange} = useBasketItemQuantity({
    itemId: props.item.id,
    currentQuantity: props.item.quantity,
    validationSchema: quantitySchema,
    onQuantityChange: props.onQuantityChange,
  });

  const totalPrice = formatCurrency(
    props.item.totalPrice.withoutVat.amount ?? 0,
    props.item.totalPrice.withoutVat.currency ?? DEFAULT_CURRENCY,
    2
  );

  const tooltip = (
    <>
      {props.item.tooltip?.map((item, index) => (
        <Fragment key={item.label}>
          {index > 0 && <Space vertical={2} />}

          <Text size="xSmall" alternative color="white">
            {item.label}:
          </Text>
          <Text size="xSmall" color="white">
            {item.value || EMPTY_PLACEHOLDER}
          </Text>
        </Fragment>
      ))}
    </>
  );

  const handleEditItem = () => {
    if (isNil(props.item.id)) {
      return;
    }
    props.onEdit({
      itemId: props.item.id,
      itemName: props.item.name,
      priceTypeName: props.item.priceTypeName,
    });
  };

  const handleDeleteItem = () => {
    openDeleteDialog({
      name: props.item.name,
      onConfirm: () => props.onDelete(props.item.id),
      'data-testid': suffixTestId('delete', props),
    });
  };

  return (
    <Card variant="inlineGrey">
      <VStack spacing={1}>
        <HStack justify="space-between">
          <HStack spacing={2}>
            <Checkbox
              value={props.item.isSelected}
              onChange={(isSelected) => props.onSelect(props.item.id, isSelected)}
              data-testid={suffixTestId('select', props)}
            />
            <Heading size={4} data-testid={suffixTestId('number', props)}>
              {props.item.number}
            </Heading>
          </HStack>
          <Box>
            <ButtonGroup>
              <Tooltip
                key={`basketItemTooltip-${getRandomId()}`}
                placement="top"
                description={tooltip}
              >
                <IconButton
                  icon="action/info_outline"
                  size="small"
                  priority="secondary"
                  data-testid={suffixTestId('info', props)}
                />
              </Tooltip>
              <IconButton
                icon="image/edit"
                size="small"
                priority="secondary"
                isDisabled={not(props.item.itemEditingAllowed)}
                onClick={handleEditItem}
                data-testid={suffixTestId('edit', props)}
              />
              <IconButton
                icon="navigation/close"
                size="small"
                priority="primary"
                severity="danger"
                onClick={handleDeleteItem}
                data-testid={suffixTestId('delete', props)}
              />
            </ButtonGroup>
          </Box>
        </HStack>

        <Text size="xSmall" color="tertiary" data-testid={suffixTestId('name', props)}>
          {props.item.name}
        </Text>

        <Space vertical={2} />

        <HStack justify="space-between">
          <Box width={29}>
            <NumberInput
              value={quantity}
              errorMessage={quantityError}
              onChange={handleQuantityChange}
              isStepperVisible
              isInvalid={isNotNil(quantityError)}
              isDisabled={not(props.item.quantityEditingAllowed)}
              data-testid={suffixTestId('quantity', props)}
            />
          </Box>
          <VStack align="flex-end">
            <Text size="xSmall" color="tertiary">
              {i18n.t('general.labels.sellingPrice')}
            </Text>
            <Text size="xSmall" alternative data-testid={suffixTestId('price', props)}>
              {totalPrice}
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </Card>
  );
}

const quantitySchema = yupNumber.positive();
