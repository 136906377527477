import {css} from 'styled-components';

import {AriaRole, MouseEvent, MouseEventHandler, ReactNode} from 'react';

import {suffixTestId, Nullish, TestIdProps} from 'shared';

import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {CSSDimension} from '../../types/CSSDimension';
import {Integer} from '../../types/Integer';
import {ValueByDevice} from '../../types/ValueByDevice';
import {getCssSize} from '../../utils/getCssSize';

export interface ClickableProps extends TestIdProps {
  children?: ReactNode;
  width?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
  height?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
  maxHeight?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
  maxWidth?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
  minHeight?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
  minWidth?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
  isDisabled?: boolean | Nullish;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseWheelClick?: MouseEventHandler<HTMLDivElement>;
  onContextMenu?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  role?: AriaRole;
}

export function Clickable(props: ClickableProps) {
  const width = useResponsivePropValue(props.width);
  const height = useResponsivePropValue(props.height);
  const maxWidth = useResponsivePropValue(props.maxWidth);
  const maxHeight = useResponsivePropValue(props.maxHeight);
  const minWidth = useResponsivePropValue(props.minWidth);
  const minHeight = useResponsivePropValue(props.minHeight);

  const onAuxClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.button === 1) {
      props.onMouseWheelClick?.(event);
    }
  };
  return (
    <div
      role={props.role}
      onClick={props.onClick}
      onAuxClick={onAuxClick}
      onContextMenu={props.onContextMenu}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-testid={suffixTestId('clickable', props)}
      css={css`
        cursor: ${props.isDisabled ?? !props.onClick ? 'initial' : 'pointer'};
        pointer-events: ${props.isDisabled ? 'none' : 'all'};
        height: ${getCssSize(height)};
        width: ${getCssSize(width)};
        max-height: ${getCssSize(maxHeight)};
        min-height: ${getCssSize(minHeight)};
        max-width: ${getCssSize(maxWidth)};
        min-width: ${getCssSize(minWidth)};
      `}
    >
      {props.children}
    </div>
  );
}
