import {useState} from 'react';

import {GetManufactureYearApiResponse} from '@omnetic-dms/shared';

export function useProductionYearWidgetState() {
  const [apiResponse, setApiResponse] = useState<GetManufactureYearApiResponse>();
  const [errorMessages, setErrorMessages] = useState<string | string[]>();
  const [lastChecked, setLastChecked] = useState<Date>();

  return {
    apiResponse,
    errorMessages,
    lastChecked,
    setApiResponse,
    setErrorMessages,
    setLastChecked,
  };
}
