import {
  ButtonGroup,
  Card,
  Form,
  FormButton,
  FormSubmitHandler,
  FormField,
  DataStatus,
} from 'platform/components';
import {VStack, HStack, Heading, Text, Inline, Show} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {keys, pickBy, pipe, filter, map, fromPairs, values, isNotNil, any} from 'ramda';
import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {
  useGetVerificationRegistersQuery,
  useVerifyCustomerContractInformationMutation,
} from '../../../api/customerApi';
import i18n from '../../../i18n/i18n';
import {
  ApiVerificationRegisterType,
  VerificationRegisterResponseBody,
} from '../../../types/api/customer';
import {handleApiError} from '../../../utils/handleApiError';

type VerificationServicesFormType = Record<ApiVerificationRegisterType, boolean>;

interface VerificationServicesProps extends TestIdProps {
  customerId: string;
  contractInformationId: string;
  recordId?: string;
  resourceId?: string;
  onClose: VoidFunction;
}

export function VerificationServices(props: VerificationServicesProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data, isLoading, isError} = useGetVerificationRegistersQuery({
    contractInformationId: props.contractInformationId,
    customerId: props.customerId,
    recordId: props.recordId,
    resourceId: props.resourceId,
  });

  const [verifyCustomerContract] = useVerifyCustomerContractInformationMutation();

  const availableRegisters = pipe(
    filter((register: VerificationRegisterResponseBody) => register.isAvailable),
    map((register) => register.verificationRegisterType)
  )(data);

  const defaultValues = pipe(
    map((register: string): [string, boolean] => [register, true]),
    fromPairs
  )(availableRegisters);

  const onSubmit: FormSubmitHandler<VerificationServicesFormType> = async (values) => {
    const verificationRegisterTypes = keys(
      pickBy(Boolean, values)
    ) as ApiVerificationRegisterType[];

    await verifyCustomerContract({
      contractInformationId: props.contractInformationId,
      customerId: props.customerId,
      verifyContractInformationRequestBody: {
        verificationRegisterTypes,
        recordId:
          isNotNil(props.recordId) && isNotNil(props.resourceId)
            ? {
                id: props.recordId,
                resourceId: props.resourceId,
              }
            : undefined,
      },
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isError={isError}
      isLoading={isLoading}
      isEmpty={isNilOrEmpty(availableRegisters)}
      emptyMessage={i18n.t('entity.customerVerification.labels.noAvailableRegisters')}
      spacing={8}
    >
      <Form<VerificationServicesFormType> defaultValues={defaultValues} onSubmit={onSubmit}>
        {(control, api) => (
          <VStack spacing={4}>
            {data?.map((register) => {
              const name = register.verificationRegisterType;
              const hasHistory =
                isNotNil(register.verificationInfo?.requestedAt) &&
                isNotNil(register.verificationInfo?.requestedBy);

              return (
                <Show key={name} when={availableRegisters.includes(name)}>
                  <Card variant="inlineGrey">
                    <HStack spacing={4}>
                      <Inline>
                        <FormField
                          name={name}
                          type="checkbox"
                          control={control}
                          data-testid={suffixTestId(`checkbox-${name}`, props)}
                        />
                      </Inline>
                      <VStack spacing={2}>
                        <Heading size={5}>
                          {i18n.t(
                            `entity.customerVerification.labels.registers.${register.verificationRegisterType}`
                          )}
                        </Heading>
                        <Text size="small" color="tertiary">
                          {hasHistory
                            ? i18n.t('entity.customerVerification.labels.checkedOnBy', {
                                date: formatDateTime(
                                  'dateShort',
                                  parseDate(register.verificationInfo?.requestedAt ?? '')
                                ),
                                name: register.verificationInfo?.requestedBy,
                              })
                            : i18n.t('entity.customerVerification.labels.notChecked')}
                        </Text>
                      </VStack>
                    </HStack>
                  </Card>
                </Show>
              );
            })}
            <ButtonGroup align="right">
              <FormButton
                control={control}
                variant="secondary"
                onClick={props.onClose}
                title={i18n.t('general.actions.cancel')}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                type="submit"
                variant="primary"
                title={i18n.t('entity.customerVerification.actions.verify')}
                isDisabled={!isAnyRegisterSelected(api.watch())}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const isAnyRegisterSelected = pipe(values, (values) => any(isTrue, values));
