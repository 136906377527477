import {moduleKeys} from '@omnetic-dms/config';
import {interestRoutes} from '@omnetic-dms/routes';
import {Module, featureFlags, permissions} from '@omnetic-dms/shared';

import {InterestCreate} from './pages/InterestCreate/InterestCreate';
import {InterestDetail} from './pages/InterestDetail/InterestDetail';
import {InterestOverview} from './pages/InterestOverview/InterestOverview';

export const InterestModule: Module = {
  id: moduleKeys.interest,
  layoutProps: {
    icon: 'content/next_week',
    title: 'page.interests.title',
  },
  routerProps: {
    path: interestRoutes.overview,
    element: <InterestOverview />,
  },
  requiredPermission: permissions.businessCaseRead,
  requiredFeatureFlag: featureFlags.ACL_SALES,
  content: [
    {
      id: 'interest-overview',
      routerProps: {
        path: interestRoutes.overview,
        element: <InterestOverview />,
      },
      layoutProps: {
        title: 'page.interests.title',
      },
    },
    {
      id: 'interest-detail',
      routerProps: {
        path: interestRoutes.detail,
        element: <InterestDetail />,
      },
    },
    {
      id: 'interest-create',
      routerProps: {
        path: interestRoutes.create,
        element: <InterestCreate />,
      },
      layoutProps: {
        title: 'entity.interest.actions.newInterest',
        isPartOfCreateMenuItem: true,
      },
    },
  ],
};
