import {captureException} from '@sentry/browser';
import flagsmith from 'flagsmith';
import {ITraits} from 'flagsmith/types';

import {useCallback, useState} from 'react';

import {useOnMount} from './useOnMount';

interface UseFlagSmithProps {
  environmentID: string;
  identity?: string;
  apiUrl: string;
  skip?: boolean;
  traits?: ITraits;
}

export function useFlagsmith({environmentID, identity, apiUrl, skip, traits}: UseFlagSmithProps) {
  const [isFlagSmithInitialized, setIsInitialized] = useState<boolean>(false);

  const handleInitFlagsmith = useCallback(
    (userIdentity?: string, newTraits?: ITraits) => {
      flagsmith
        .init({
          environmentID,
          api: apiUrl,
          identity: userIdentity,
          cacheFlags: true,
          traits: newTraits,
        })
        .then(() => setIsInitialized(true))
        .catch((error) => {
          captureException(error);
          setIsInitialized(false);
        });
    },
    [environmentID, apiUrl]
  );

  useOnMount(() => {
    if (skip) {
      return;
    }
    handleInitFlagsmith(identity, traits);
  });

  return {isFlagSmithInitialized, handleInitFlagsmith};
}
