import {DataStatus} from 'platform/components';

import {testIds} from '@omnetic-dms/routes';
import {useGetTireOrderQuery, Section, VehicleOverview} from '@omnetic-dms/shared';

import {useTiresInventoryUrl} from '../../hooks/useTiresInventoryUrl';

export function Vehicle() {
  const {orderId} = useTiresInventoryUrl();

  const {
    data: tireOrder,
    isLoading: isTireOrderLoading,
    isError: isTireOrderError,
  } = useGetTireOrderQuery({orderId});

  return (
    <Section data-testid={testIds.tiresInventory.tireOrderDetail('vehicle')}>
      <DataStatus isLoading={isTireOrderLoading} isError={isTireOrderError} minHeight={100}>
        {tireOrder?.vehicleId && (
          <VehicleOverview
            vehicleId={tireOrder?.vehicleId}
            data-testid={testIds.tiresInventory.tireOrderDetail('vehicle')}
          />
        )}
      </DataStatus>
    </Section>
  );
}
