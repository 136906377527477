import {Card, Action, openDialog, closeCurrentDialog, FormSubmitHandler} from 'platform/components';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {vehiclesRoutes} from '@omnetic-dms/routes';

import {composePath, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {
  useGetAdditionalNoteQuery,
  useSetAdditionalNoteMutation,
  useUpdateVehicleV2Mutation,
} from '../../api/vehicleApi';
import i18n from '../../i18n/i18n';
import {FullVehicleResponseBody} from '../../types/api/api';
import {handleApiError} from '../../utils/handleApiError';
import {ServiceVehicleForm} from '../ServiceVehicleForm/ServiceVehicleForm';
import {ServiceVehicleFormType} from '../ServiceVehicleForm/types/ServiceVehicleFormType';
import {getServiceVehicleDefaultValueFromVehicle} from '../ServiceVehicleForm/utils/getServiceVehicleDefaultValueFromVehicle';
import {AftersaleVehicleOverview} from './components/AftersaleVehicleOverview';
import {VehicleMatchOrCreate} from './components/VehicleMatchOrCreate';
import {getVehicleV2FromServiceVehicle} from './utils/getVehicleV2FromServiceVehicle';

interface AftersalesVehicleWidgetCardProps extends RequiredTestIdProps {
  vehicle?: FullVehicleResponseBody | Nullish;
  onChange?: (vehicleId: string | null) => Promise<void> | void;
}

export function AftersalesVehicleWidgetCard(props: AftersalesVehicleWidgetCardProps) {
  const [updateVehicle] = useUpdateVehicleV2Mutation();
  const [setAdditionalNote] = useSetAdditionalNoteMutation();
  const {data: additionalNote} = useGetAdditionalNoteQuery(
    {vehicleId: props.vehicle?.id ?? ''},
    {skip: isNil(props.vehicle?.id)}
  );

  const onEditVehicleSubmit: FormSubmitHandler<ServiceVehicleFormType> = async (data) => {
    const vehicleId = props.vehicle?.id;

    if (isNil(vehicleId)) {
      return;
    }

    await updateVehicle({...getVehicleV2FromServiceVehicle(data), id: vehicleId})
      .unwrap()
      .then(closeCurrentDialog)
      .then(() => {
        if (isNotNilOrEmpty(data.note)) {
          setAdditionalNote({
            vehicleId,
            additionalNoteRequestBody: {note: data.note ?? ''},
          });
        }
      })
      .catch(handleApiError);
  };

  const handleVehicleEdit = () =>
    openDialog(
      <ServiceVehicleForm
        defaultValue={getServiceVehicleDefaultValueFromVehicle(props.vehicle, additionalNote?.note)}
        onCancel={closeCurrentDialog}
        onSubmit={onEditVehicleSubmit}
        data-testid={suffixTestId('edit', props)}
      />,
      {title: i18n.t('entity.vehicle.labels.editVehicle')}
    );

  const handleVehicleChange = () => {
    openDialog(
      <VehicleMatchOrCreate
        onVehicleSelect={(vehicleId) => {
          closeCurrentDialog();
          props.onChange?.(vehicleId);
        }}
        data-testid={suffixTestId('finder', props)}
      />,
      {title: i18n.t('entity.vehicle.labels.vehicle')}
    );
  };

  const actions: Action[] = [
    {
      title: i18n.t('general.labels.detail'),
      leftIcon: 'action/open_in_new',
      variant: 'link',
      type: 'button',
      onClick: () =>
        window.open(
          composePath(vehiclesRoutes.detail, {
            params: {id: props.vehicle?.id},
            isSearchParamsPreserved: false,
          })
        ),
    },
    {
      variant: 'link',
      type: 'button',
      title: i18n.t('general.actions.change'),
      leftIcon: 'action/autorenew',
      onClick: handleVehicleChange,
    },
    {
      variant: 'link',
      type: 'button',
      title: i18n.t('general.actions.edit'),
      leftIcon: 'image/edit',
      onClick: handleVehicleEdit,
    },
  ];

  return (
    <Card
      actions={isNotNil(props.vehicle) ? actions : undefined}
      title={i18n.t('entity.vehicle.labels.vehicle')}
      data-testid={suffixTestId('header', props)}
    >
      {props.vehicle ? (
        <AftersaleVehicleOverview
          vehicle={props.vehicle}
          data-testid={suffixTestId('overview', props)}
        />
      ) : (
        <VehicleMatchOrCreate
          onVehicleSelect={props.onChange}
          data-testid={suffixTestId('finder', props)}
        />
      )}
    </Card>
  );
}
