import {Button} from 'platform/components';
import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'platform/datagrid';
import {HStack, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

interface AvailabilityTabProps extends TestIdProps {
  articleId: string | Nullish;
  manufacturerId: string | Nullish;
  manufacturerNumber: string | Nullish;
}

export function RequestTabMaterialAvailabilityTab(props: AvailabilityTabProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          articleId: props.articleId,
          manufacturerNumber: props.manufacturerNumber,
          manufacturer: props.manufacturerId,
        },
      ]),
    [props.articleId, props.manufacturerNumber, props.manufacturerId]
  );

  return (
    <VStack spacing={0}>
      <DataGrid
        ref={dataGridRef}
        gridCode="warehouse-availability"
        queryModifier={queryModifier}
        autoHeight
        data-testid={suffixTestId('availability', props)}
      />
      <HStack justify="flex-end">
        <Button
          variant="link"
          title={i18n.t('general.actions.refresh')}
          leftIcon="navigation/refresh"
          onClick={refreshDataGrid}
          data-testid={suffixTestId('refresh', props)}
        />
      </HStack>
    </VStack>
  );
}
