import {ActionCallback, DataGrid} from 'platform/datagrid';

import {isArray} from 'ramda-adjunct';

import {testIds, tiresInventoryRoutes} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

export function TireOrderOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    if (actionKey === 'redirectDetail') {
      const tireOrderId = isArray(rowId) ? rowId[0] : rowId;

      navigate(
        composePath(tiresInventoryRoutes.tireOrderDetail, {
          params: {id: tireOrderId},
          queryParams: {source: 'tire-orders'},
        })
      );
    }
  };

  return (
    <DataGrid
      gridCode="tire-order"
      actionCallback={actionCallback}
      data-testid={testIds.tiresInventory.tireOrders('grid')}
    />
  );
}
