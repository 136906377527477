import {Card, FormControl, FormField, Separator} from 'platform/components';
import {Grid, GridItem, VStack, Space} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {useGetSeriesListQuery, useGetTemplatesQuery} from '@omnetic-dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {FormType} from '../../../types/ServiceOrderVariant';

interface InternalPaymentProps extends RequiredTestIdProps {
  formApi: UseFormReturn<FormType>;
  control: FormControl<FormType>;
  title: string;
}

export function InternalPayment(props: InternalPaymentProps) {
  const isAllowed = props.formApi.watch(`paymentType.internalPayment.isAllowed`);

  const {options, isLoading} = useGetSeriesListQuery(
    {
      type: ['accounting/invoice'],
    },
    {
      selectFromResult: (result) => ({
        ...result,
        options: result?.data?.map((serie) => ({
          label: serie.name,
          value: serie.id,
        })),
      }),
    }
  );

  const {data: templates, isLoading: isTemplatesLoading} = useGetTemplatesQuery({});

  const invoiceTemplate = templates
    ?.filter((template) => template?.documentKindCode === 'invoice')
    ?.map((template) => ({
      label: template.title,
      value: template.id,
    }));

  return (
    <Card
      title={props.title}
      isExpanded={isAllowed}
      control={{
        type: 'switch',
        value: isAllowed,
        onChange: (isEnabled) => {
          props.formApi.setValue('paymentType.internalPayment.isAllowed', isEnabled);
        },
      }}
      variant="inlineGrey"
      data-testid={suffixTestId('card', props)}
    >
      <Separator spacing={0} />
      <Space vertical={4} />
      <VStack spacing={4}>
        <Grid columns={2} horizontalSpacing={6}>
          <GridItem>
            <FormField
              control={props.control}
              name="paymentType.internalPayment.invoice.templateId"
              type="choice"
              label={i18n.t('entity.warehouse.labels.invoiceTemplate')}
              isNotClearable
              options={invoiceTemplate}
              isLoading={isTemplatesLoading}
              isRequired
              data-testid={suffixTestId('invoiceTemplate', props)}
            />
          </GridItem>
        </Grid>
        <Grid columns={2} horizontalSpacing={6}>
          <FormField
            control={props.control}
            name="paymentType.internalPayment.invoice.docSeriesId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.invoiceDocumentSerie')}
            isNotClearable
            options={options}
            isLoading={isLoading}
            isRequired
            data-testid={suffixTestId('invoiceDocumentSerie', props)}
          />
          <FormField
            control={props.control}
            name="paymentType.internalPayment.invoiceCorrection.docSeriesId"
            type="choice"
            label={i18n.t('entity.settings.labels.correctiveDocument')}
            isNotClearable
            options={options}
            isLoading={isLoading}
            isRequired
            data-testid={suffixTestId('correctiveDocument', props)}
          />
        </Grid>
      </VStack>
    </Card>
  );
}
