import {Card, Segment, SegmentProps} from 'platform/components';
import {Box, Grid, GridItem, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {featureFlags, selectActiveBranchId, usePermissions} from '@omnetic-dms/shared';
import {selectUserBranches} from '@omnetic-dms/teas';

import {BusinessCaseStatisticsCard} from './components/BusinessCaseStatisticsCard';
import {Clock} from './components/Clock';
import {FaqsCard} from './components/FaqsCard';
import {InterestStatisticsCard} from './components/InterestStatisticsCard';
import {QuickActionsCard} from './components/QuickActionsCard';
import {SupportV1} from './components/SupportV1';
import {VehicleStatisticsCard} from './components/VehicleStatisticsCard';

enum DashboardSource {
  activeBranch = 'activeBranch',
  tenant = 'tenant',
}

export function DashboardV1() {
  const activeBranchId = useSelector(selectActiveBranchId);
  const userBranches = useSelector(selectUserBranches);
  const activeBranch = userBranches?.find((userBranch) => userBranch.id === activeBranchId);

  const [canReadBusinessCase] = usePermissions({permissionKeys: ['businessCaseRead']});

  const dashboardSourceOptions: NonNullable<SegmentProps['options']> = [
    {
      value: DashboardSource.activeBranch,
      label: activeBranch?.name ?? i18n.t('page.homepage.labels.activeBranch'),
    },
    {value: DashboardSource.tenant, label: i18n.t('page.homepage.labels.tenant')},
  ];

  const [dashboardSource, setDashboardSource] = useState(DashboardSource.activeBranch);
  const branchId = dashboardSource === DashboardSource.activeBranch ? activeBranchId : undefined;

  return (
    <>
      <Helmet>
        <title>{i18n.t('page.homepage.labels.title')}</title>
      </Helmet>
      <Box padding={4} data-testid={testIds.dashboard.home('wrapper')}>
        <Card variant="inlineWhite">
          <VStack>
            <HStack spacing={2} justify="space-between" align="center">
              <Segment
                value={dashboardSource}
                onChange={(value) => setDashboardSource(value as DashboardSource)}
                options={dashboardSourceOptions}
              />
              <Text data-testid={testIds.dashboard.home('date')} size="small" color="secondary">
                <Clock />
              </Text>
            </HStack>
            <Space vertical={4} />
            <Show when={canReadBusinessCase} whenFeatureEnabled={featureFlags.ACL_SALES}>
              <Grid spacing={4} columns={2}>
                <GridItem>
                  <VStack spacing={4} grow={1}>
                    <BusinessCaseStatisticsCard branchId={branchId} />
                    <InterestStatisticsCard branchId={branchId} />
                  </VStack>
                </GridItem>
                <GridItem>
                  <VehicleStatisticsCard branchId={branchId} />
                </GridItem>
              </Grid>
            </Show>
          </VStack>
        </Card>

        <Space vertical={4} />

        <Card variant="inlineWhite">
          <Grid columns={12}>
            <GridItem span={6}>
              <SupportV1 />
            </GridItem>
            <GridItem span={3}>
              <FaqsCard />
            </GridItem>
            <GridItem span={3}>
              <QuickActionsCard />
            </GridItem>
          </Grid>
        </Card>
      </Box>
    </>
  );
}
