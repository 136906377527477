import {match} from 'ts-pattern';

import {always, flatten, isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  GetAllApiEnumsApiArg,
  GetAllApiEnumsApiResponse,
  GetAllMakeModelsApiArgs,
  GetAllMakeModelsApiResponse,
  GetApiMakeModelsWithMakeApiArg,
  GetApiMakeModelWithMakeApiArg,
  GetApiMakeModelWithMakeApiResponse,
  Makes,
  ModelFamilies,
  Models,
} from '../types/api/api';
import {GetCustomTenantCatalogueApiResponse} from '../types/api/vehicleCatalogue';
import {omneticApi} from './baseApi/omneticApi';

export const vehicleCatalogueApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getMakeModelWithMake: build.query<
      GetApiMakeModelWithMakeApiResponse,
      GetApiMakeModelWithMakeApiArg
    >({
      query: (queryArg) => {
        const URL_PREFIX = '/vehicle-catalogue/api/make-model';

        const url = match([isNotNilOrEmpty(queryArg.vehicleType), isNotNilOrEmpty(queryArg.make)])
          .with([true, true], always(`${URL_PREFIX}/${queryArg.vehicleType}/${queryArg.make}`))
          .with([true, false], always(`${URL_PREFIX}/${queryArg.vehicleType}`))
          .otherwise(always(`${URL_PREFIX}`));

        return {
          url,
          params: {lang: queryArg.lang},
        };
      },
    }),
    getMakeModelWithMakes: build.query<
      GetApiMakeModelWithMakeApiResponse,
      GetApiMakeModelsWithMakeApiArg
    >({
      async queryFn(queryArg, _queryApi, _extraOptions, fetchWithBaseQuery) {
        const results = await Promise.all(
          queryArg.makes.map((make) =>
            fetchWithBaseQuery({
              url: `/vehicle-catalogue/api/make-model/${queryArg.vehicleType}/${make}`,
              params: {lang: queryArg.lang},
            })
          )
        );

        const data = flatten(
          results.map((result) => result.data) as GetApiMakeModelWithMakeApiResponse[]
        );

        return {data};
      },
    }),
    getAllMakesModels: build.query<GetAllMakeModelsApiResponse, GetAllMakeModelsApiArgs>({
      query: (queryArg) => ({
        url: `/vehicle-catalogue/api/make-model/${queryArg?.vehicleType}/MAKE_ALL`,
        params: isNotNil(queryArg) ? {lang: queryArg?.lang} : undefined,
      }),
      transformResponse: (response: GetApiMakeModelWithMakeApiResponse) => {
        const makes: Makes = {};
        const models: Models = {};
        const modelFamilies: ModelFamilies = {};

        response.forEach((make) => {
          const makeName = make.make;

          makes[makeName] = {
            name: makeName,
            label: make.default_label,
            models: make.models?.map((model) => model.model) ?? [],
            modelFamilies: [],
          };
          make.models?.forEach((model) => {
            const modelName = model.model;

            models[modelName] = {
              name: modelName,
              label: `${make.default_label} ${model.default_label}`,
              make: makeName,
              modelFamily: model.model_group?.group,
            };
            if (isNotNil(model.model_group)) {
              const familyName = model.model_group.group;

              if (!makes[makeName].modelFamilies.includes(familyName)) {
                makes[makeName].modelFamilies.push(familyName);
              }

              if (isNil(modelFamilies[familyName])) {
                modelFamilies[familyName] = {
                  name: familyName,
                  label: `${make.default_label} ${model.model_group.default_label}`,
                  make: makeName,
                  models: [modelName],
                };
              } else {
                modelFamilies[familyName].models.push(modelName);
              }
            }
          });
        });

        return {makes, models, modelFamilies};
      },
    }),
    getAllApiEnums: build.query<GetAllApiEnumsApiResponse, GetAllApiEnumsApiArg>({
      query: (queryArg) => ({
        url: `/vehicle-catalogue/api/enum`,
        params: {
          lang: queryArg.lang,
          order_by: queryArg.orderBy,
          vehicle_type: queryArg.vehicleType,
        },
      }),
    }),
    getCustomTenantCatalogue: build.query<GetCustomTenantCatalogueApiResponse, void>({
      query: () => ({
        url: `/dms/v1/vehicle/custom-tenant-catalogue`,
      }),
    }),
  }),
});

export const {
  useGetMakeModelWithMakeQuery,
  useLazyGetMakeModelWithMakeQuery,
  useGetMakeModelWithMakesQuery,
  useGetAllMakesModelsQuery,
  useGetAllApiEnumsQuery,
  useGetCustomTenantCatalogueQuery,
} = vehicleCatalogueApi;
