import {motion} from 'framer-motion';
import {showNotification, useTranslationContext} from 'platform/components';
import {Box} from 'platform/foundation';
import {css} from 'styled-components';

import {useRef} from 'react';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {LightboxAction} from '../types/LightboxAction';
import {LightboxImage} from '../types/LightboxImage';
import {ZoomRef} from '../types/ZoomRef';
import {Actions} from './Actions';
import {Zoom} from './Zoom';
import {ZoomImage} from './ZoomImage';

interface LightboxSlideDetailProps extends RequiredTestIdProps {
  image: LightboxImage;
  onDetailClose: () => void;
}

export function Detail(props: LightboxSlideDetailProps) {
  const zoomRef = useRef<ZoomRef>(null);

  const t = useTranslationContext();

  const onZoomIn = () => {
    if (zoomRef.current) {
      zoomRef.current.zoomIn();
    }
  };

  const onZoomOut = () => {
    if (zoomRef.current) {
      zoomRef.current.zoomOut();
    }
  };

  const isBase64Image = props.image.url.startsWith('data:');

  const actions = buildArray<LightboxAction>(props.image.actions ?? [])
    .whenNot(isBase64Image, {
      icon: 'social/share',
      onClick: async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: document.title,
              url: window.location.href,
            });
          } catch (err: unknown) {
            if (!(err instanceof Error && err.toString().includes('AbortError'))) {
              showNotification.error(t('platform.lightbox.share-error'));
            }
          }
        } else {
          await navigator.clipboard.writeText(window.location.href);
          showNotification.info(t('platform.lightbox.copy-to-clipboard'));
        }
      },
      id: 'shareBtn',
    })
    .add({
      icon: 'content/save_alt',
      onClick: () => {
        const link = document.createElement('a');
        const event = new MouseEvent('click');

        if (isBase64Image) {
          link.download = `file_${new Date().toISOString()}.png`;
          link.href = props.image.url;
        } else {
          link.download = props.image.fileName ?? `file_${new Date().toISOString()}`;
          const url = new URL(props.image.url);
          url.searchParams.append('type', 'attachment');
          link.href = url.toString();
        }

        link.dispatchEvent(event);
        link.remove();
      },
      id: 'downloadBtn',
    });

  const zoomControl = buildArray<LightboxAction>([])
    .add({
      id: 'zoomOutBtn',
      icon: 'lightbox/minus',
      onClick: () => onZoomOut(),
    })
    .add({
      id: 'zoomInBtn',
      icon: 'lightbox/plus',
      onClick: () => onZoomIn(),
    });

  return (
    <motion.div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({theme}) => theme.colors.palettes.black[10][100]};
        z-index: ${({theme}) => theme.zIndices.LIGHTBOX_DETAIL};
      `}
      initial={{opacity: 0, scale: 0.95}}
      animate={{opacity: 1, scale: 1}}
      exit={{opacity: 0, scale: 0.95}}
    >
      <Box width="100%" height="100%" position="relative">
        <Zoom
          ref={zoomRef}
          data-testid={suffixTestId('imageDetail', props)}
          renderSlide={(ref) => (
            <ZoomImage
              ref={ref}
              data-testid={suffixTestId('imageDetail', props)}
              image={props.image}
            />
          )}
        />
        <Actions
          data-testid={suffixTestId('imageDetail', props)}
          zIndex="LIGHTBOX_DETAIL_ACTIONS"
          actionPosition={{
            top: 4,
            left: 4,
            right: 'auto',
          }}
          actions={[actions, zoomControl]}
        />
        <Actions
          data-testid={suffixTestId('imageDetail', props)}
          actionPosition={{top: 4, right: 4, left: 'auto'}}
          zIndex="LIGHTBOX_DETAIL_ACTIONS"
          actions={buildArray<LightboxAction[]>().add([
            {
              icon: 'navigation/close',
              onClick: props.onDetailClose,
              id: 'closeBtn',
            },
          ])}
        />
      </Box>
    </motion.div>
  );
}
