import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {VehicleAlbum} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {UploadedFile} from '../../../services/UploadFileService';
import {toggleImageSelection, toggleMultiSelectAlbum} from '../../../store/carDetails/reducer';
import {selectPhotosSelectionActiveAlbum} from '../../../store/carDetails/selectors';
import {noop} from '../../../utils/someTeasUtils';
import {DefaultMultiSelectComponent} from '../../CustomDragAndDrop';
import {DragAndDropProvider} from '../../CustomDragAndDrop/DragAndDropProvider';
import {DraggableDataWithContextId} from '../../CustomDragAndDrop/types';
import {ImageType} from '../../ImagesCard/types';
import {ImagesCardConnected} from '../../ImagesCardConnected/ImagesCardConnected';

type Props = {
  images: ImageType[];
  album?: VehicleAlbum;
  onImageClick?: (id: string) => void;
  onChange: (files: UploadedFile[], album?: VehicleAlbum) => Promise<void>;
  onDelete: (photos: string[]) => void;
  onDragEnd: (
    items: DraggableDataWithContextId<ImageType>[],
    position: number,
    droppedOn?: DraggableDataWithContextId<ImageType>
  ) => void;
  isAddForm?: boolean;
} & TestIdProps;

export const UploadPhotos: FC<Props> = ({
  album,
  onChange,
  onDelete,
  images,
  isAddForm,
  onDragEnd,
  onImageClick,
  ...props
}) => {
  const selectionActiveAlbum = useSelector(selectPhotosSelectionActiveAlbum);
  const dispatch = useDispatch();
  const [imagesState, setImagesState] = useState<ImageType[]>([]);

  useEffect(() => {
    setImagesState(images);
  }, [images]);

  const handleNewImagesSelection = (id: string) => {
    setImagesState(
      imagesState.map((image) =>
        image.id === id ? {...image, isSelected: !image.isSelected} : image
      )
    );
  };

  const handleSelectionToggle = (reset = false) => {
    if (reset) {
      setImagesState(images.map((image) => ({...image, isSelected: false})));
    }
    dispatch(toggleMultiSelectAlbum({id: ''}));
  };

  return (
    <DragAndDropProvider
      onDragStart={() => {
        if (selectionActiveAlbum === '') {
          handleSelectionToggle();
        }
      }}
      onDragEnd={onDragEnd}
      onDragFail={() => {
        if (imagesState.some((i) => i.isSelected)) {
          handleSelectionToggle();
        }
      }}
      selectedItems={imagesState.filter((i) => i.isSelected).map((i) => i.id)}
      multipleSelectionComponent={DefaultMultiSelectComponent}
      data-testid={suffixTestId('dragAndDropProvider', props)}
    >
      <ImagesCardConnected
        data-testid={props['data-testid']}
        albums={[]}
        album={album}
        droppableId={album?.id || undefined}
        cardProps={{title: i18n.t('entity.photo.labels.images')}}
        globalListener
        images={isAddForm ? imagesState : images}
        onDelete={(photos) => onDelete(photos.map((p) => p.id))}
        onUpload={onChange}
        onMove={noop}
        onCopy={noop}
        onSetAsCover={noop}
        selectionEnabled={selectionActiveAlbum === ''}
        onImageClick={onImageClick}
        onSelectionToggle={() => handleSelectionToggle(true)}
        onImageSelect={(id: string) => {
          if (isAddForm) {
            handleNewImagesSelection(id);
          } else {
            dispatch(toggleImageSelection(id));
          }
        }}
        isAddForm={isAddForm}
      />
    </DragAndDropProvider>
  );
};
