import {Card, FormControl, FormField, Switch} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {head} from 'ramda';
import {isNotNil, isString} from 'ramda-adjunct';

import {RequiredTestIdProps, suffixTestId, useToggle} from 'shared';

import {useGetCurrenciesQuery} from '../../api/cachedApi';
import {useLazyFindConversionRatiosQuery} from '../../api/moneyApi';
import {useGetTenantQuery} from '../../api/tenantApi';
import {DEFAULT_CURRENCY} from '../../constants/currency';
import i18n from '../../i18n/i18n';
import {CheckoutPaymentFormType} from '../../types/CheckoutPaymentFormType';

const CNB_TYPE = 'cnb';
const DEFAULT_RATIO = 1;

interface ForeignCurrencyPaymentProps extends RequiredTestIdProps {
  control: FormControl<CheckoutPaymentFormType>;
  formApi: UseFormReturn<CheckoutPaymentFormType>;
}

export function ForeignCurrencyPayment(props: ForeignCurrencyPaymentProps) {
  const [isActive, toggleActive] = useToggle(isNotNil(props.formApi.getValues('currency')));

  const {data: tenant} = useGetTenantQuery();
  const {data: currencyOptions, isLoading: isCurrencyOptionsLoading} = useGetCurrenciesQuery(
    undefined,
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data
          ?.filter((currency) => currency.code !== (tenant?.currency ?? DEFAULT_CURRENCY))
          .map((currency) => ({
            label: currency.code,
            value: currency.code,
          })),
      }),
    }
  );

  const [findConversionRatios] = useLazyFindConversionRatiosQuery();

  const handleChange = (value: string | number | string[] | null) => {
    if (!isString(value)) {
      return;
    }

    findConversionRatios({
      domesticCurrency: tenant?.currency ?? DEFAULT_CURRENCY,
      foreignCurrency: value,
      type: CNB_TYPE,
    })
      .unwrap()
      .then((result) => {
        const conversion = head(result);

        props.formApi.setValue(
          'exchangeRate',
          conversion?.ratio ? parseFloat(conversion.ratio) : null
        );
        props.formApi.setValue('ratio', conversion?.amount ?? DEFAULT_RATIO);
      });
  };

  return (
    <Card variant="inlineGrey">
      <VStack spacing={4}>
        <Switch
          label={i18n.t('entity.checkout.foreignCurrency.title')}
          value={isActive}
          onChange={() => {
            props.formApi.setValue('currency', null);
            props.formApi.setValue('ratio', DEFAULT_RATIO);

            toggleActive();
          }}
          data-testid={suffixTestId('toggle', props)}
        />
        <Show when={isActive}>
          <Grid columns={4}>
            <FormField
              name="currency"
              label={i18n.t('general.labels.currency')}
              type="choice"
              options={currencyOptions}
              isNotClearable
              isLoading={isCurrencyOptionsLoading}
              control={props.control}
              onChange={handleChange}
              data-testid={suffixTestId('currency', props)}
            />
            <FormField
              name="ratio"
              label={i18n.t('entity.orderRequest.labels.ratio')}
              type="text"
              isDisabled
              control={props.control}
              data-testid={suffixTestId('ratio', props)}
            />
          </Grid>
        </Show>
      </VStack>
    </Card>
  );
}
