import {AttributesRow, openDialog, Tooltip} from 'platform/components';
import {Link} from 'platform/foundation';
import {formatPhoneNumber, useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, defaultTo, isNotNil} from 'ramda';
import {isFalsy, isNotNilOrEmpty, isString, isTruthy} from 'ramda-adjunct';

import {buildArray, EMPTY_PLACEHOLDER, parseDate} from 'shared';

import {useGetTagsForEntityQuery} from '../api/tagApi';
import {SendEmail} from '../components/SendEmail/SendEmail';
import {SendSms} from '../components/SendSms/SendSms';
import {ValueWithTags} from '../components/ValueWithTags/ValueWithTags';
import i18n from '../i18n/i18n';
import {EntityResourceIds} from '../types/api/api';
import {CustomerResponseBodyV2} from '../types/api/customer';
import {featureFlags} from '../utils/featureFlags';
import {getNaturalPersonFullName} from '../utils/getNaturalPersonFullName';
import {isCustomerNaturalPerson} from '../utils/isCustomerNaturalPerson';
import {useAddress} from './useAddress';
import {useCountry} from './useCountry';
import {useCustomerGroups} from './useCustomerGroups';
import {useGender} from './useGender';
import {useIdentityCard} from './useIdentityCard';
import {useInstitution} from './useInstitution';

export function useCustomerInformationAttributes(
  customer?: CustomerResponseBodyV2,
  displayAftersalesVersion?: boolean
) {
  const formatDateTime = useDateTimeFormatter();

  const {composeAddress} = useAddress();
  const {getCountryName} = useCountry();
  const {composeIdentityCard} = useIdentityCard();
  const {getGenderName} = useGender();
  const {getInstitutions} = useInstitution();
  const {getCustomerGroupsAttribute} = useCustomerGroups();

  const {data: tags} = useGetTagsForEntityQuery(
    {
      resourceId: EntityResourceIds.customer,
      recordId: customer?.id ?? '',
    },
    {skip: !displayAftersalesVersion || !customer}
  );

  if (!customer) {
    return;
  }

  const getCustomerLegalPerson = () =>
    buildArray<AttributesRow>()
      .add({
        label: i18n.t('contractInformation.labels.tradeName'),
        content: (
          <ValueWithTags value={customer.businessInfo?.businessInfo.tradeName} tags={tags} />
        ),
      })
      .add({
        label: i18n.t('contractInformation.labels.registrationNumber'),
        value: customer.businessInfo?.businessInfo.registrationNumber,
      })
      .add({
        label: i18n.t('contractInformation.labels.vatNumber'),
        value: customer.businessInfo?.businessInfo.vatNumber,
      })
      .add({
        label: i18n.t('contractInformation.labels.fileNumber'),
        value: customer.businessInfo?.businessInfo.fileNumber,
      })
      .add({
        label: i18n.t('contractInformation.labels.countryOfRegistrationCode'),
        value: getCountryName(customer.businessInfo?.businessInfo?.countryOfRegistrationCode),
      })
      .add({
        label: i18n.t('contractInformation.labels.businessAddressId'),
        value: composeAddress(customer.businessInfo?.address?.address),
      })
      .add({
        label: i18n.t('entity.businessInfo.labels.institutions.title'),
        value: getInstitutions(customer.institutions),
      })
      .whenFeatureEnabled(featureFlags.CORE_CUSTOMER_GROUPS, {
        label: i18n.t('entity.person.labels.customerGroups'),
        content: getCustomerGroupsAttribute(customer.customerGroups),
      });

  const getCustomerPhysicalPerson = () => {
    const foundingPerson = buildArray<AttributesRow>()
      .add({
        label: i18n.t('entity.person.labels.fullName'),
        content: (
          <ValueWithTags value={getNaturalPersonFullName(customer.foundingPerson)} tags={tags} />
        ),
      })
      .when(isString(customer.foundingPerson?.roles), {
        label: i18n.t('entity.person.labels.role'),
        value: customer.foundingPerson?.roles,
      })
      .when(isFalsy(displayAftersalesVersion), {
        label: i18n.t('entity.person.labels.genderKey'),
        value: getGenderName(customer.foundingPerson?.genderKey),
      })
      .when(isFalsy(displayAftersalesVersion), {
        label: i18n.t('entity.person.labels.citizenshipCode'),
        value: getCountryName(customer.foundingPerson?.citizenshipCode),
      })
      .add({
        label: i18n.t('entity.person.labels.birthdate'),
        value: isNotNilOrEmpty(customer.foundingPerson?.birthdate)
          ? formatDateTime('dateShort', parseDate(customer!.foundingPerson!.birthdate!))
          : null,
      })
      .add({
        label: i18n.t('entity.person.labels.personalIdentifier'),
        value: customer.foundingPerson?.personalIdentifier,
      })
      .add({
        label: i18n.t('entity.customer.labels.isSelfEmployed'),
        value: match(customer.selfEmployed)
          .with(true, always(i18n.t('general.labels.yes')))
          .with(false, always(i18n.t('general.labels.no')))
          .otherwise(always(null)),
      });

    if (isTruthy(customer.selfEmployed)) {
      foundingPerson
        .add({
          label: i18n.t('contractInformation.labels.tradeName'),
          value: customer?.selfEmployedBusinessInfoData?.tradeName,
        })
        .add({
          label: i18n.t('contractInformation.labels.registrationNumber'),
          value: customer?.selfEmployedBusinessInfoData?.registrationNumber,
        })
        .add({
          label: i18n.t('contractInformation.labels.vatNumber'),
          value: customer?.selfEmployedBusinessInfoData?.vatNumber,
        })
        .add({
          label: i18n.t('contractInformation.labels.fileNumber'),
          value: customer?.selfEmployedBusinessInfoData?.fileNumber,
        })
        .add({
          label: i18n.t('contractInformation.labels.countryOfRegistrationCode'),
          value: getCountryName(customer?.selfEmployedBusinessInfoData?.countryOfRegistrationCode),
        })
        .add({
          label: i18n.t('contractInformation.labels.businessAddressId'),
          value: composeAddress(customer?.selfEmployedBusinessAddress?.address),
        });
    }

    foundingPerson.whenNot(customer.foundingPerson?.phoneNumbers?.length, {
      label: i18n.t('entity.phoneNumber.labels.number'),
      value: null,
    });

    customer.foundingPerson?.phoneNumbers?.forEach((item) => {
      foundingPerson.when(isNotNil(customer.id), {
        label: `${i18n.t('entity.phoneNumber.labels.number')} ${
          isNotNilOrEmpty(item.type) ? `(${item.type})` : ''
        }`,
        content: (
          <Tooltip label={i18n.t('general.actions.sendMessage')} placement="top-start">
            <Link
              title={defaultTo(
                EMPTY_PLACEHOLDER,
                formatPhoneNumber(`${item.prefix}${item.number}`)
              )}
              size="xSmall"
              onClick={() =>
                openDialog(
                  <SendSms
                    data-testid="sms-attributes-sendSmsModal"
                    customerId={defaultTo('', customer.id)}
                    selectedContactId={defaultTo('', customer?.foundingPerson?.id)}
                  />,
                  {
                    scrollBehavior: 'outside',
                    title: i18n.t('general.labels.smsMessage'),
                  }
                )
              }
              data-testid="send-sms-open-modal-link"
            />
          </Tooltip>
        ),
      });
    });

    foundingPerson.whenNot(customer.foundingPerson?.emails?.length, {
      label: i18n.t('entity.email.labels.email'),
      value: null,
    });

    customer.foundingPerson?.emails?.forEach((item) => {
      foundingPerson.when(isNotNil(customer.id), {
        label: `${i18n.t('entity.email.labels.email')} ${
          isNotNilOrEmpty(item.type) ? `(${item.type})` : ''
        }`,
        content: (
          <Tooltip label={i18n.t('general.actions.sendEmail')} placement="top-start">
            <Link
              title={defaultTo(EMPTY_PLACEHOLDER, item.email)}
              size="xSmall"
              onClick={() =>
                openDialog(
                  <SendEmail
                    data-testid="email-attributes-sendEmailModal"
                    customerId={customer.id}
                  />,
                  {
                    scrollBehavior: 'outside',
                    title: i18n.t('general.labels.email'),
                  }
                )
              }
              data-testid="send-email-open-modal-link"
            />
          </Tooltip>
        ),
      });
    });

    foundingPerson.add({
      label: `${i18n.t('entity.address.labels.fullAddress')}
      ${
        isNotNilOrEmpty(customer.foundingPerson?.permanentAddress?.type)
          ? `(${customer.foundingPerson!.permanentAddress!.type})`
          : ''
      }`,
      value: composeAddress(customer.foundingPerson?.permanentAddress?.address),
    });

    if (isFalsy(displayAftersalesVersion)) {
      foundingPerson.whenNot(customer.foundingPerson?.identityCards?.length, {
        label: i18n.t('entity.identityCard.labels.fullIdentityCard'),
        value: null,
      });

      customer.foundingPerson?.identityCards?.forEach((item) => {
        foundingPerson.add({
          label: i18n.t('entity.identityCard.labels.fullIdentityCard'),
          value: composeIdentityCard(item),
        });
      });
    }

    foundingPerson.whenFeatureEnabled(featureFlags.CORE_CUSTOMER_GROUPS, {
      label: i18n.t('entity.person.labels.customerGroups'),
      content: getCustomerGroupsAttribute(customer.customerGroups),
    });

    return foundingPerson;
  };

  return isCustomerNaturalPerson(customer) ? getCustomerPhysicalPerson() : getCustomerLegalPerson();
}
