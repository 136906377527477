import {DataStatus, Form, FormField} from 'platform/components';
import {FormToDataGridConnectorProps} from 'platform/datagrid';
import {VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {i18n} from '@omnetic-dms/i18n';
import {
  getOptionsFromBranches,
  getOptionsFromWarehouses,
  useGetBranchListQuery,
  useGetWarehousesQuery,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

type MaterialArticleTabExternalFiltersForm = Partial<{
  branch: string;
  warehouseId: string;
  isAvailable: boolean;
  isAvailableWarehouses: boolean;
  isInStock: boolean;
}>;

type RequestTabMaterialWarehouseTabExternalFiltersProps = {
  directSaleVariantId: string | Nullish;
} & FormToDataGridConnectorProps &
  TestIdProps;

export function RequestTabMaterialWarehouseTabExternalFilters(
  props: RequestTabMaterialWarehouseTabExternalFiltersProps
) {
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery(
    {
      directSaleVariantId: props.directSaleVariantId as string,
    },
    {skip: isNil(props.directSaleVariantId)}
  );

  const {
    data: branches,
    isLoading: branchesLoading,
    isError: isBranchesError,
  } = useGetBranchListQuery();

  const isLoading = isWarehousesLoading || branchesLoading;
  const isError = isWarehousesError || isBranchesError;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialArticleTabExternalFiltersForm> values={props.values} onChange={props.onChange}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="branch"
              type="choice"
              label={i18n.t('entity.branch.labels.branch')}
              options={getOptionsFromBranches(branches)}
              data-testid={suffixTestId('branch', props)}
            />
            <FormField
              control={control}
              name="warehouseId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(warehouses)}
              data-testid={suffixTestId('warehouse', props)}
            />
            <FormField
              control={control}
              name="isAvailable"
              label={i18n.t('entity.warehouse.labels.availableOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
            <FormField
              control={control}
              name="isAvailableWarehouses"
              label={i18n.t('entity.warehouse.labels.availableWarehousesOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
            <FormField
              control={control}
              name="isInStock"
              label={i18n.t('entity.warehouse.labels.inStock')}
              type="checkbox"
              data-testid={suffixTestId('inStock', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
