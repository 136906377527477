import {Button, Card, Switch} from 'platform/components';
import {Grid, GridItem, Show, Stack} from 'platform/foundation';

import {FC, useState} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  AddressRequestBodyV2,
  CheckoutDeputyPersonResponseBody,
  composeAddressLineFromCommonAddress,
  ContractInformationResponseBody,
  IdentityCardResponseBodyV2,
  PersonResponseBodyV2,
  useGetCustomerV2Query,
} from '@omnetic-dms/shared';
import {
  AutoSave,
  Form,
  WithValidationErrors,
  IdentityCardData,
  PersonRequestBody,
} from '@omnetic-dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {PersonSelect} from '../../Person/PersonSelect';
import {CheckoutContractInfo} from './CheckoutContractInfo';
import {
  CheckoutPersonContractInformationForm,
  CheckoutPersonContractInformationFormState,
} from './CheckoutPersonContractInformationForm';

export type CheckoutContractDeputyPersonFormState = {
  deputyPersons: Array<string>;
};

interface CheckoutContractDeputyPersonProps {
  customerId: string;
  handleSelectDeputyPerson?: (values: CheckoutContractDeputyPersonFormState) => Promise<void>;
  handleSubmitDeputyPerson?: (
    values: CheckoutPersonContractInformationFormState
  ) => Promise<WithValidationErrors<void>>;
  editable?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
  multiple?: boolean;
  handlePersonSubmit: (
    personData: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAddressSubmit: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  handleIdentityCardSubmit: (
    person: PersonResponseBodyV2
  ) => (
    cardData: IdentityCardData,
    id: string | null
  ) => Promise<IdentityCardResponseBodyV2 | null>;
  contractInformation?: ContractInformationResponseBody;
  deputyPerson?: CheckoutDeputyPersonResponseBody[];
}

export const CheckoutContractDeputyPerson: FC<CheckoutContractDeputyPersonProps & TestIdProps> = ({
  handleSelectDeputyPerson,
  handleSubmitDeputyPerson,
  handlePersonSubmit,
  contractInformation,
  deputyPerson,
  disabled,
  editable,
  mandatory,
  multiple,
  ...props
}) => {
  const [open, setOpen] = useState(!!deputyPerson?.length || (!disabled && mandatory));
  const [editOpenDeputyPerson, setEditDeputyPersonOpen] = useState<string[]>([]);

  const {data: customer} = useGetCustomerV2Query(
    {customerId: props.customerId},
    {skip: isNilOrEmpty(props.customerId)}
  );

  const toggleEditDeputyPerson = (customerPersonId: string) => () => {
    if (editOpenDeputyPerson.includes(customerPersonId)) {
      setEditDeputyPersonOpen(editOpenDeputyPerson.filter((id) => id !== customerPersonId));
    } else {
      setEditDeputyPersonOpen([...editOpenDeputyPerson, customerPersonId]);
    }
  };

  const toggleDeputyPerson = () => {
    if (open) {
      handleSelectDeputyPerson?.({deputyPersons: []});
    }
    setOpen(!open);
  };

  const editDeputyPerson =
    (customerPersonId: string) =>
    async (values: CheckoutPersonContractInformationFormState): Promise<void> => {
      await handleSubmitDeputyPerson?.(values);
      toggleEditDeputyPerson(customerPersonId)();
    };

  return (
    <Grid columns={1}>
      <Switch
        label={i18n.t('entity.order.labels.deputyPerson')}
        value={!!open}
        isDisabled={disabled || mandatory}
        onChange={toggleDeputyPerson}
        data-testid={props['data-testid']}
      />
      {open && (
        <Form<CheckoutContractDeputyPersonFormState>
          formId={props['data-testid']}
          onSubmit={handleSelectDeputyPerson}
          initialValues={{
            deputyPersons: deputyPerson?.map((deputy) => deputy?.customerPerson.id) || [],
          }}
          defaultValues={{deputyPersons: ['']}}
          render={({
            FieldArray,
            form: {
              mutators: {push},
            },
          }) => (
            <Grid columns={1}>
              {handleSelectDeputyPerson && (
                <AutoSave save={handleSelectDeputyPerson} saveOnChange />
              )}
              <FieldArray<undefined> name="deputyPersons">
                {({fields, Subscribe}) =>
                  fields.map((name, index) => (
                    <Subscribe<Array<string>>
                      key="deputyPersons"
                      name="deputyPersons"
                      component={({input: {value}}) => {
                        const selectedPersonId = value[index];

                        const deputy = deputyPerson?.find(
                          (dep) => dep.customerPerson.id === selectedPersonId
                        );

                        return (
                          <GridItem>
                            {editOpenDeputyPerson.includes(selectedPersonId) ? (
                              <CheckoutPersonContractInformationForm
                                data-testid={suffixTestId(`[${index}]`, props)}
                                handleSubmit={editDeputyPerson(selectedPersonId)}
                                onDiscard={toggleEditDeputyPerson(selectedPersonId)}
                                handlePersonSubmit={handlePersonSubmit}
                                selectedPerson={deputy?.customerPerson ?? null}
                                selectedIdentityCards={deputy?.selectedIdentityCards}
                                initialValues={{
                                  personId: deputy?.customerPerson?.id ?? null,
                                  person: deputy?.customerPerson
                                    ? {
                                        personData: deputy?.customerPerson?.personData ?? null,
                                        phoneNumbers: deputy?.customerPerson?.phoneNumbers,
                                        emails: deputy?.customerPerson?.emails,
                                        identityCards: deputy?.customerPerson?.identityCards?.map(
                                          (identityCard) => ({
                                            id: identityCard.id,
                                            cardData: identityCard.identityCardData,
                                          })
                                        ),
                                        permanentAddressId:
                                          deputy?.customerPerson?.permanentAddress?.id ?? null,
                                      }
                                    : null,
                                  selectedIdentityCardIds: deputy?.selectedIdentityCards.map(
                                    (card) => card.id
                                  ),
                                }}
                                person={customer?.persons}
                                showPersonData
                                {...props}
                              />
                            ) : (
                              <Card
                                data-testid={suffixTestId(`[${index}]`, props)}
                                variant="inlineGrey"
                              >
                                {editable && (
                                  <Grid columns={2}>
                                    <div
                                      style={{
                                        marginRight: '-100px',
                                      }}
                                    >
                                      <PersonSelect<undefined>
                                        data-testid={suffixTestId(`[${index}]`, props)}
                                        label={i18n.t('entity.person.selectPerson')}
                                        name={name}
                                        person={customer?.persons?.filter(
                                          (p) =>
                                            (!value?.includes(p.id) &&
                                              contractInformation?.person?.id !== p.id) ||
                                            selectedPersonId === p.id
                                        )}
                                        showEmails={false}
                                        showPhoneNumbers={false}
                                        showRole={false}
                                        showRemove={
                                          multiple &&
                                          fields.length !== undefined &&
                                          fields.length > 1
                                        }
                                        handleRemove={() => fields.remove(index)}
                                        handleEdit={toggleEditDeputyPerson(selectedPersonId)}
                                        handlePersonSubmit={handlePersonSubmit}
                                      />
                                    </div>
                                  </Grid>
                                )}
                                <Subscribe<string>
                                  name={name}
                                  component={({input: {value}}) => {
                                    const deputy = deputyPerson?.find(
                                      (deputyPerson) => deputyPerson.customerPerson.id === value
                                    );
                                    const personsToFind = customer?.persons?.map((person) => ({
                                      id: person.id,
                                      personData: {
                                        firstName: person.firstName,
                                        lastName: person.lastName,
                                        middleName: person.middleName,
                                        titleBefore: person.titleBefore,
                                        titleAfter: person.titleAfter,
                                        genderKey: person.genderKey,
                                        roles: person.roles,
                                        citizenshipCode: person.citizenshipCode,
                                        birthdate: person.birthdate,
                                        personalIdentifier: person.personalIdentifier,
                                      },
                                      phoneNumbers: person.phoneNumbers.map((phoneNumber) => ({
                                        type: phoneNumber.type,
                                        phoneNumber: {
                                          countryCode: phoneNumber.countryCode,
                                          prefix: phoneNumber.prefix,
                                          number: phoneNumber.number,
                                        },
                                      })),
                                      emails: person.emails,
                                      identityCards: person.identityCards.map((identityCard) => ({
                                        id: identityCard.id,
                                        identityCardData: {
                                          type: identityCard.type,
                                          cardNumber: identityCard.cardNumber,
                                          issuedOn: identityCard.issuedOn,
                                          validUntil: identityCard.validUntil,
                                          issuer: identityCard.issuer,
                                          issuedInCountryCode: identityCard.issuedInCountryCode,
                                          note: identityCard.note,
                                        },
                                      })),
                                      permanentAddress: person.permanentAddress
                                        ? {
                                            id: person.permanentAddress.id,
                                            addressData: {
                                              city: person.permanentAddress.address.city,
                                              postalCode: person.permanentAddress.address.zip,
                                              countryCode: person.permanentAddress.address.country,
                                              addressLine1: person.permanentAddress.address
                                                ? composeAddressLineFromCommonAddress(
                                                    person.permanentAddress.address
                                                  )
                                                : null,
                                              addressLine2: null,
                                              type: person.permanentAddress.type,
                                              province: null,
                                              invalid: person.permanentAddress.invalid,
                                            },
                                          }
                                        : null,
                                    }));
                                    const selectedPerson =
                                      personsToFind?.find((person) => person.id === value) ||
                                      deputy?.customerPerson;

                                    if (!selectedPerson) {
                                      return null;
                                    }

                                    return (
                                      <CheckoutContractInfo
                                        data-testid={suffixTestId(`[${index}]`, props)}
                                        person={selectedPerson}
                                        identityCards={deputy?.selectedIdentityCards}
                                      />
                                    );
                                  }}
                                />
                              </Card>
                            )}
                          </GridItem>
                        );
                      }}
                    />
                  ))
                }
              </FieldArray>
              <Show when={multiple}>
                <Stack direction="row" justify="flex-start">
                  <Button
                    data-testid={suffixTestId('addDeputyPerson', props)}
                    leftIcon="content/add_circle"
                    variant="link"
                    size="small"
                    onClick={() => push('deputyPersons', '')}
                    title={i18n.t('general.customer.addDeputyPerson')}
                  />
                </Stack>
              </Show>
            </Grid>
          )}
        />
      )}
    </Grid>
  );
};
