import {
  Button,
  ButtonGroup,
  DataStatus,
  closeCurrentDialog,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {Box, Right, Space, Stack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {
  AftersalesVehicleWidgetCard,
  Section,
  PatchDirectSaleRequest,
  VehicleOverview,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId} from 'shared';

interface VehicleProps extends RequiredTestIdProps {
  vehicleId: string;
  isDirectSaleLoading: boolean;
  isEditingDisabled: boolean;
  onVehicleChange: (vehicleId: PatchDirectSaleRequest['body']['vehicleId']) => Promise<void>;
}

export function Vehicle(props: VehicleProps) {
  const handleSelectVehicle = (vehicleId: string | null) => {
    props.onVehicleChange(vehicleId);
    closeCurrentDialog();
  };

  const handleAssignVehicle = () => {
    openDialog(
      <AftersalesVehicleWidgetCard
        onChange={handleSelectVehicle}
        data-testid={suffixTestId('dialogContent.assignVehicle', props)}
      />,
      {
        title: i18n.t('entity.vehicle.labels.vehicle'),
        disableBodyPadding: true,
        'data-testid': suffixTestId('dialog.assignVehicle', props),
      }
    );
  };

  const handleRemoveVehicle = () => {
    openDeleteDialog({
      text: i18n.t('entity.warehouse.actions.deleteEntity'),
      onConfirm: () => props.onVehicleChange(null),
      'data-testid': suffixTestId('deleteDialog.remove', props),
    });
  };

  const handleViewVehicleCard = () => {
    window.open(composePath(vehiclesRoutes.detail, {params: {id: props.vehicleId}}), '_blank');
  };

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus
        isLoading={props.isDirectSaleLoading}
        isEmpty={isNilOrEmpty(props.vehicleId)}
        minHeight={200}
        emptyMessage={i18n.t('entity.warehouse.labels.noAssignedVehicle')}
        action={{
          title: i18n.t('general.actions.assignVehicle'),
          onClick: handleAssignVehicle,
          isDisabled: props.isEditingDisabled,
        }}
        data-testid={suffixTestId('assignedVehicle', props)}
      >
        <Right>
          <ButtonGroup data-testid={suffixTestId('actions', props)}>
            <Button
              variant="dangerOutlined"
              title={i18n.t('general.actions.remove')}
              onClick={handleRemoveVehicle}
              isDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('actions.remove', props)}
            />
            <Button
              variant="outlined"
              title={i18n.t('general.actions.change')}
              onClick={handleAssignVehicle}
              isDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('actions.change', props)}
            />
            <Button
              variant="outlined"
              leftIcon="action/launch"
              title={i18n.t('entity.warehouse.actions.viewVehicleCard')}
              onClick={handleViewVehicleCard}
              data-testid={suffixTestId('actions.viewVehicleCard', props)}
            />
          </ButtonGroup>
        </Right>
        <Space vertical={4} />
        <Stack
          direction={['column', 'column', 'row', 'row']}
          spacing={4}
          data-testid={suffixTestId('vehicleDetails', props)}
        >
          <Box flex={1}>
            <VehicleOverview
              vehicleId={props.vehicleId}
              isDetailButtonHidden
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('vehicleInfo', props)}
            />
          </Box>
        </Stack>
      </DataStatus>
    </Section>
  );
}
