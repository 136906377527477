import {Hide, Show} from 'platform/foundation';

import {featureFlags} from '@omnetic-dms/shared';

import {Dashboard} from './Dashboard';
import {DashboardV1} from './DashboardV1';

export function Homepage() {
  return (
    <>
      <Show whenFeatureEnabled={featureFlags.DASHBOARD_V2}>
        <Dashboard />
      </Show>
      <Hide whenFeatureEnabled={featureFlags.DASHBOARD_V2}>
        <DashboardV1 />
      </Hide>
    </>
  );
}
