import {Card, DataStatus} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  CustomerContractModal,
  Section,
  handleApiError,
  useCustomerUrl,
  useGetCustomerContractsByCustomerIdQuery,
  usePermissions,
  usePutCustomerContractSetDefaultMutation,
} from '@omnetic-dms/shared';

import {ContractCard} from '../CustomerInformation/components/ContractCard';

export function CustomerContracts() {
  const {customerId} = useCustomerUrl();
  const [hasReadSettingsPermission] = usePermissions({permissionKeys: ['readSettings']});

  const {data, isLoading, isError} = useGetCustomerContractsByCustomerIdQuery({customerId});
  const [setDefault] = usePutCustomerContractSetDefaultMutation();

  const [contractDetailId, setContractDetailId] = useState<string>();

  const handleSetDefault = (customerContractId: string) =>
    setDefault({
      customerContractId,
      customerId,
    })
      .unwrap()
      .catch(handleApiError);

  return (
    <Section>
      <Card
        title={i18n.t('entity.customerContract.labels.customerContracts')}
        actions={
          hasReadSettingsPermission
            ? [
                {
                  type: 'button',
                  title: i18n.t('general.labels.settings'),
                  leftIcon: 'action/open_in_new',
                  variant: 'link',
                  onClick: () => window.open(settingsRoutes.customerContracts),
                },
              ]
            : undefined
        }
      >
        <DataStatus
          isLoading={isLoading}
          isError={isError}
          isEmpty={isNilOrEmpty(data)}
          minHeight={43}
        >
          <VStack spacing={4}>
            {data?.map((contract) => (
              <ContractCard
                key={contract?.customerContractId}
                customerContractId={contract?.customerContractId ?? ''}
                isDefault={contract?.isDefault ?? false}
                onSetDefault={() => handleSetDefault(contract?.customerContractId ?? '')}
                onDetail={() => setContractDetailId(contract?.customerContractId ?? '')}
              />
            ))}
          </VStack>
        </DataStatus>
      </Card>
      <Show when={isNotNil(contractDetailId)}>
        <CustomerContractModal
          customerContractId={contractDetailId ?? ''}
          onClose={() => setContractDetailId(undefined)}
          data-testid={testIds.customer.detail('contractDetailModal')}
        />
      </Show>
    </Section>
  );
}
