import {ActionCallback, DataGrid, QueryFilterObject} from 'platform/datagrid';

import {useParams} from 'react-router-dom';

import {mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {workshopRoutes} from '@omnetic-dms/routes';
import {queryParams, workshopSearchParams} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

export function ServiceOrderOverview() {
  const {id} = useParams();
  const navigate = useNavigate();

  const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {vehicleId: id ?? ''}]);

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    if (actionKey === 'redirectDetail') {
      const orderId = isArray(rowId) ? rowId[0] : rowId;
      navigate(
        composePath(workshopRoutes.serviceCaseDetail, {
          params: {
            id: path<string>(['serviceCaseId', 'value'], rowData),
          },
          queryParams: {
            section: workshopSearchParams.serviceDetail.orders,
            [queryParams.SERVICE_CASE_ORDER_ID]: orderId,
          },
        })
      );
    }
  };

  return (
    <DataGrid
      autoHeight
      gridCode="service-order"
      actionCallback={actionCallback}
      queryModifier={queryModifier}
    />
  );
}
