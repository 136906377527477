import {Attributes, AttributesRow, Card, Separator} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {DEFAULT_CURRENCY} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {SupplierArticlesAddNewCatalogInfoPrice} from './SupplierArticlesAddNewCatalogInfoPrice';

interface SupplierArticlesAddNewCatalogInfoProps extends RequiredTestIdProps {
  articleName: string | Nullish;
  supplierName: string | Nullish;
  manufactuterName: string | Nullish;
  supplierOrderingNumber: string | Nullish;
  manufacturerNumber: string | Nullish;
  supplierBulkPackageQuantity: number | Nullish;
  recommendedPriceWithoutVat: number | Nullish;
  purchasePriceWithoutVat: number | Nullish;
  currency: string | Nullish;
}

export function SupplierArticlesAddNewCatalogInfo(props: SupplierArticlesAddNewCatalogInfoProps) {
  const catalogInfo: AttributesRow[] = [
    {label: i18n.t('entity.warehouse.labels.name'), value: props.articleName},
    {label: i18n.t('entity.warehouse.labels.supplier'), value: props.supplierName},
    {label: i18n.t('entity.warehouse.labels.manufacturer'), value: props.manufactuterName},
    {
      label: i18n.t('entity.warehouse.labels.supplierOrderingNumber'),
      value: props.supplierOrderingNumber,
    },
    {label: i18n.t('entity.warehouse.labels.catalogueNumber'), value: props.manufacturerNumber},
    {
      label: i18n.t('entity.warehouse.labels.supplierBulkPackageQuantity'),
      value: props.supplierBulkPackageQuantity,
    },
  ];

  return (
    <VStack spacing={4}>
      <Card
        title={i18n.t('entity.warehouse.labels.catalogueInfo')}
        variant="inlineGrey"
        data-testid={props['data-testid']}
      >
        <Attributes
          rows={catalogInfo}
          size="quarter"
          data-testid={suffixTestId('attributes', props)}
        />

        <Separator />

        <Grid columns={4}>
          <GridItem span={1}>
            <SupplierArticlesAddNewCatalogInfoPrice
              label={`${i18n.t('entity.warehouse.labels.recommendedPrice')} ${i18n.t('general.labels.w/oVat')}`}
              price={props.recommendedPriceWithoutVat ?? 0}
              currency={props.currency ?? DEFAULT_CURRENCY}
              data-testid={suffixTestId('prices.recommended', props)}
            />
          </GridItem>
          <GridItem span={1}>
            <SupplierArticlesAddNewCatalogInfoPrice
              label={`${i18n.t('entity.warehouse.labels.purchasePrice')} ${i18n.t('general.labels.w/oVat')}`}
              price={props.purchasePriceWithoutVat ?? 0}
              currency={props.currency ?? DEFAULT_CURRENCY}
              data-testid={suffixTestId('prices.purchase', props)}
            />
          </GridItem>
        </Grid>
      </Card>
    </VStack>
  );
}
