import {match} from 'ts-pattern';

import {always} from 'ramda';

import {RedirectSubmodule} from '../types/RedirectSubmodule';

export const getSubmoduleFromQueryString = (query: string): RedirectSubmodule | null =>
  match<string | null, RedirectSubmodule | null>(new URLSearchParams(query).get('submodule'))
    .with('serviceCaseOrder', always('serviceCaseOrder'))
    .otherwise(always(null));
