import validatePhone from 'phone';
import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Button,
  Separator,
  DialogFooter,
} from 'platform/components';
import {VStack, HStack, Box, Space, Heading} from 'platform/foundation';
import {object} from 'yup';
import * as Yup from 'yup';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, yupString, TestIdProps} from 'shared';

import {useTenant} from '../../../hooks/useTenant';
import i18n from '../../../i18n/i18n';
import {
  PersonRequestBody,
  EmailData,
  PhoneNumberData,
  PersonResponseBodyV2,
} from '../../../types/api/customer';
import {PhoneNumber} from '../../../types/common/PhoneNumber';
import {getContactPersonDefaultValues} from '../../../utils/getContactPersonDefaultValues';
import {EmailList} from '../../EmailList/EmailList';
import {PhoneNumberList} from '../../PhoneNumberList/PhoneNumberList';

interface ContactPersonFormProps extends TestIdProps {
  customerId: string;
  isLoading?: boolean;
  contact?: PersonResponseBodyV2;
  onSubmit?: FormSubmitHandler<PersonRequestBody>;
  onClose?: () => void;
}

export function ContactPersonForm(props: ContactPersonFormProps) {
  const {tenantPhoneInfo} = useTenant();

  return (
    <Form<PersonRequestBody>
      onSubmit={props.onSubmit}
      defaultValues={getContactPersonDefaultValues(tenantPhoneInfo, props.contact)}
      schema={schema}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="personData.titleBefore"
                maxLength={10}
                label={i18n.t('entity.person.labels.titleBefore')}
                data-testid={suffixTestId('titleBefore', props)}
              />
            </Box>
            <Box flex={2}>
              <FormField
                control={control}
                type="text"
                name="personData.firstName"
                maxLength={64}
                label={i18n.t('entity.person.labels.firstName')}
                data-testid={suffixTestId('firstName', props)}
              />
            </Box>
            <Box flex={2}>
              <FormField
                control={control}
                type="text"
                name="personData.lastName"
                isRequired
                maxLength={64}
                label={i18n.t('entity.person.labels.lastName')}
                data-testid={suffixTestId('lastName', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="personData.titleAfter"
                maxLength={10}
                label={i18n.t('entity.person.labels.titleAfter')}
                data-testid={suffixTestId('titleAfter', props)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                name="personData.roles"
                type="text"
                label={i18n.t('entity.person.labels.role')}
                data-testid={suffixTestId('roles', props)}
              />
            </Box>
            <Box flex={1}>
              <Space fillAvailable />
            </Box>
          </HStack>
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('entity.person.labels.contactInformation')}</Heading>
          <PhoneNumberList<PersonRequestBody>
            control={control}
            formApi={formApi}
            name="phoneNumbers"
            data-testid={suffixTestId('phoneNumberList', props)}
          />
          <EmailList<PersonRequestBody>
            control={control}
            formApi={formApi}
            name="emails"
            data-testid={suffixTestId('emailList', props)}
          />
          <DialogFooter data-testid={props['data-testid']}>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose ?? closeCurrentDialog}
                isDisabled={props.isLoading}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={props.isLoading}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </VStack>
      )}
    </Form>
  );
}

const schema = object().shape(
  {
    personData: object().shape({
      titleBefore: yupString.optional(),
      firstName: yupString.optional().max(64),
      lastName: yupString.required().max(64),
      titleAfter: yupString.optional(),
      genderKey: yupString.optional(),
      roles: yupString.optional(),
      citizenshipCode: yupString.optional(),
      birthdate: yupString.optional(),
      personalIdentifier: yupString.optional(),
    }),
    phoneNumbers: Yup.array().when('emails', {
      is: (emails?: EmailData[]) =>
        emails?.length && emails.length <= 1 && isNilOrEmpty(emails?.[0]?.email),
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              type: yupString.optional(),
              phoneNumber: Yup.mixed()
                .test(
                  'isPhone',
                  i18n.t('general.validations.invalidPhoneNumber'),
                  (phone?: PhoneNumber | Nullish) => {
                    if (isNilOrEmpty(phone?.number)) {
                      return true;
                    }
                    return validatePhone(phone?.number || '', {
                      country: phone?.countryCode,
                      validateMobilePrefix: false,
                    }).isValid;
                  }
                )
                .test(
                  'required',
                  i18n.t('general.validations.phoneOrEmail'),
                  (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
                ),
            })
          )
          .min(1),
      otherwise: (schema) =>
        schema.of(
          Yup.object().shape({
            type: yupString.optional(),
            phoneNumber: Yup.mixed().test(
              'isPhone',
              i18n.t('general.validations.invalidPhoneNumber'),
              (phone?: PhoneNumber | Nullish) => {
                if (isNilOrEmpty(phone?.number)) {
                  return true;
                }
                return validatePhone(phone?.number || '', {
                  country: phone?.countryCode,
                  validateMobilePrefix: false,
                }).isValid;
              }
            ),
          })
        ),
    }),
    emails: Yup.array().when('phoneNumbers', {
      is: (phoneNumbers?: PhoneNumberData[]) =>
        phoneNumbers?.length &&
        phoneNumbers?.length <= 1 &&
        isNilOrEmpty(phoneNumbers?.[0]?.phoneNumber?.number),
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              type: yupString.optional(),
              email: yupString.required(i18n.t('general.validations.phoneOrEmail')),
            })
          )
          .min(1),
      otherwise: (schema) =>
        schema.of(
          Yup.object().shape({
            type: yupString.optional(),
            email: yupString.optional().default(''),
          })
        ),
    }),
  },
  [['emails', 'phoneNumbers']]
);
