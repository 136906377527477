import {map, pipe, uniq} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {VehicleModel} from '@omnetic-dms/shared';

export const getModeFamilyOptions = pipe(
  (models: VehicleModel[]) => models.map((model) => model.model_group),
  (groups) => groups.filter(isNotNil),
  uniq,
  map((group) => ({
    label: group?.default_label,
    value: group?.group,
  }))
);
