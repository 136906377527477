import {FormControl, TabProps, Tabs} from 'platform/components';
import {Box} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {
  BaseArticle,
  GetArticleResponse,
  GetWarehouseAccountResponse,
  TenantResponseBody,
  VatRate,
} from '@omnetic-dms/shared';

import {OverviewDispensingPricesForm} from './OverviewDispensingPricesForm';

interface OverviewPricesFormProps {
  control: FormControl<BaseArticle>;
  article: GetArticleResponse;
  vatRates: VatRate[];
  tenant: TenantResponseBody;
  warehouseAccount: GetWarehouseAccountResponse;
}

export function OverviewPricesForm(props: OverviewPricesFormProps) {
  const tabs: TabProps[] = [
    {
      title: i18n.t('entity.warehouse.labels.dispensingPrices'),
      id: 'dispensingPrices',
      content: (
        <OverviewDispensingPricesForm
          control={props.control}
          article={props.article}
          vatRates={props.vatRates}
          tenant={props.tenant}
        />
      ),
    },
  ];

  return (
    <Box
      borderRadius="small"
      boxShadow="elevation_1"
      backgroundColor="palettes.white.10.100"
      overflow="hidden"
    >
      <Tabs variant="container" tabs={tabs} />
    </Box>
  );
}
