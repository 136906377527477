import {Button, ButtonGroup, closeCurrentDialog, showNotification} from 'platform/components';
import {DataGrid, QueryFilterObject, DataGridBehaviorOverrides} from 'platform/datagrid';
import {Box, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {useGetCurrentBranch} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

interface PairWithInvoiceIdProps extends RequiredTestIdProps {
  onSubmit: (invoiceId: string) => void;
  customerId?: string;
}

export function PairWithInvoiceIdForm(props: PairWithInvoiceIdProps) {
  const {activeBranchId} = useGetCurrentBranch();

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (isNil(selectedId)) {
      showNotification.error();
      return;
    }

    await props.onSubmit(selectedId);
    closeCurrentDialog();
  };

  return (
    <VStack spacing={4}>
      <Box height="55vh">
        <DataGrid
          gridCode="pair-invoice"
          onRowSelectionChange={(rows) => {
            setSelectedId(rows[0]?.id);
          }}
          data-testid={suffixTestId('data-grid', props)}
          // @ts-ignore
          queryModifier={getQueryModifier(props.customerId, activeBranchId)}
          _useAsLastResort_definitionBehaviorOverrides={settingsOverrides}
        />
      </Box>
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          onClick={closeCurrentDialog}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          variant="primary"
          onClick={handleSubmit}
          isDisabled={!selectedId}
          title={i18n.t('general.labels.select')}
          data-testid={suffixTestId('submit', props)}
        />
      </ButtonGroup>
    </VStack>
  );
}

const settingsOverrides: DataGridBehaviorOverrides = {
  rowSelectMode: 'SINGLE',
};

const getQueryModifier =
  (customerId: string | Nullish, activeBranchId: string | Nullish) =>
  (filter: QueryFilterObject) => ({
    ...filter,
    customer: customerId ? [customerId] : undefined,
    branch: activeBranchId ? [activeBranchId] : undefined,
  });
