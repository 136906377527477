export const platformImageURLs: Record<string, string> = {
  sauto: '../assets/images/ads/sauto-logo.svg',
  tipcars: '../assets/images/ads/tipcars-logo.svg',
  'mobile.de': '../assets/images/ads/mobile-de-logo.svg',
  facebook: '../assets/images/ads/facebook-logo.svg',
  autocaris: '../assets/images/ads/autocaris-logo.svg',
  otomoto: '../assets/images/ads/otomoto-logo.svg',
  olx: '../assets/images/ads/olx-logo.svg',
  skoda_plus: '../assets/images/ads/skoda-plus-logo.svg',
  das_weltauto: '../assets/images/ads/das-welt-auto-logo.svg',
  mercedes_na_sklade: '../assets/images/ads/mercedes-logo.svg',
  hyundai_promise: '../assets/images/ads/hyundai-promise-logo.png',
  ford_uzywane: '../assets/images/ads/ford-uzywane-logo.png',
  // TODO T20-40939 remove
  fastback_trade: '../assets/images/ads/fastback-trade-logo.svg',
  fastback_inter_dealer: '../assets/images/ads/fastback-inter-dealer-logo.svg',
  community: '../assets/images/ads/community-logo.svg',
  autoscout24: '../assets/images/ads/autoscout24.svg',
};
