import {closeCurrentDialog, openConfirmDialog, openDialog} from 'platform/components';
import {ActionCallback, DataGrid, useRefreshDataGrid} from 'platform/datagrid';
import {match} from 'ts-pattern';

import {isArray, noop} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  usePatchTireLocationPositionDownMutation,
  usePatchTireLocationPositionUpMutation,
  usePatchTireLocationSetActiveMutation,
  usePatchTireLocationSetInactiveMutation,
} from '@omnetic-dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {LocationForm} from './components/LocationForm';

type ReceiveTireLocationRowData = {
  name?: {value?: string};
  isActive?: {value?: boolean};
};

export function TireInventoryLocations() {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [patchTireLocationSetActive] = usePatchTireLocationSetActiveMutation();
  const [patchTireLocationSetInactive] = usePatchTireLocationSetInactiveMutation();
  const [patchTireLocationPositionUp] = usePatchTireLocationPositionUpMutation();
  const [patchTireLocationPositionDown] = usePatchTireLocationPositionDownMutation();

  const handleAddLocation = () => {
    openDialog(
      <LocationForm
        onDiscard={closeCurrentDialog}
        onSave={() => {
          closeCurrentDialog();
          refreshDataGrid();
        }}
      />,
      {
        title: i18n.t('entity.tiresInventory.labels.newLocation'),
        size: 'small',
      }
    );
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    const castedRowData = rowData as ReceiveTireLocationRowData;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        openDialog(
          <LocationForm
            onDiscard={closeCurrentDialog}
            onSave={() => {
              closeCurrentDialog();
              refreshData();
            }}
            defaultValue={{
              id,
              name: castedRowData.name?.value ?? '',
              isActive: !!castedRowData.isActive?.value,
            }}
          />,
          {
            title: i18n.t('entity.tiresInventory.labels.editLocation'),
            size: 'small',
          }
        )
      )
      .with('setInactive', () =>
        openConfirmDialog({
          text: i18n.t('entity.tiresInventory.labels.locationSetInactive'),
          onConfirm: () =>
            patchTireLocationSetInactive({codelistId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setActive', () =>
        openConfirmDialog({
          text: i18n.t('entity.tiresInventory.labels.locationSetActive'),
          onConfirm: () =>
            patchTireLocationSetActive({codelistId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('movePositionDown', () =>
        openConfirmDialog({
          text: i18n.t('entity.tiresInventory.labels.locationMovePositionDown'),
          onConfirm: () =>
            patchTireLocationPositionDown({codelistId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('movePositionUp', () =>
        openConfirmDialog({
          text: i18n.t('entity.tiresInventory.labels.locationMovePositionUp'),
          onConfirm: () =>
            patchTireLocationPositionUp({codelistId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.tiresInventory.labels.tireLocations'),
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.tiresInventory.labels.newLocation'),
            onClick: handleAddLocation,
          },
        ],
      }}
      data-testid={testIds.settings.tireLocations('template')}
    >
      <DataGrid ref={dataGridRef} gridCode="tire-location" actionCallback={actionCallback} />
    </SettingsTemplate>
  );
}
