import {TableProps} from 'platform/components';
import {Box, HStack, Text} from 'platform/foundation';
import {currencies, formatCurrencySymbol} from 'platform/locale';

import {CurrencyCodeType} from 'shared';

import {i18n} from '../../../i18n/i18n';

export function getTableColumns(currency: string): TableProps['columns'] {
  return [
    {
      element: (
        <Box padding={1} paddingLeft={6}>
          <HStack height={6} align="center">
            <Text size="xxSmall" color="secondary">
              {i18n.t('entity.vehicle.labels.specification')}
            </Text>
          </HStack>
        </Box>
      ),
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('general.labels.soldPcs')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('general.labels.stockPcs')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('general.labels.soldPrice')} (
              {formatCurrencySymbol(currencies, currency as CurrencyCodeType)})
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('general.labels.stockPrice')} (
              {formatCurrencySymbol(currencies, currency as CurrencyCodeType)})
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('general.labels.soldMileage')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('general.labels.stockMileage')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('general.labels.gap')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: <Box padding={1} />,
    },
  ];
}
