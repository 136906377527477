import {ButtonGroup, closeCurrentDialog, DataStatus} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {find, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {noop} from 'shared';

import {useGetTemplatesQuery} from '../../../api/documentApi';
import {useCreateComplaintDocumentMutation} from '../../../api/vehicleComplaintApi';
import i18n from '../../../i18n/i18n';
import {FileResponseBody, TemplateListItemResponseBody} from '../../../types/api/api';
import {handleApiError} from '../../../utils/handleApiError';
import {DocumentTemplateBox} from '../../DocumentTemplateBox/DocumentTemplateBox';

const documentKindCode = 'complaint';

interface ComplaintDocumentDialogProps {
  complaintId: string;
  onDocumentCreation: (document: FileResponseBody) => void;
}

export function ComplaintDocumentDialog(props: ComplaintDocumentDialogProps) {
  const [documentTemplate, setDocumentTemplate] = useState<TemplateListItemResponseBody>();

  const {
    data: documentTemplates,
    isLoading,
    isError,
  } = useGetTemplatesQuery({
    documentKindCode,
  });

  const [createDocument, {isLoading: isCreateLoading}] = useCreateComplaintDocumentMutation();

  if (!documentTemplate && isNotNilOrEmpty(documentTemplates)) {
    setDocumentTemplate(find((template) => template.primary, documentTemplates ?? []));
  }

  const handleSubmit = () =>
    createDocument({body: {complaintId: props.complaintId, templateId: documentTemplate?.id ?? ''}})
      .unwrap()
      .then(props.onDocumentCreation)
      .catch(handleApiError);

  return (
    <DataStatus isEmpty={isNil(documentTemplate)} isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <Text size="small" color="secondary">
          {i18n.t('entity.vehicleComplaint.labels.complaintDocumentCreation')}
        </Text>
        <DocumentTemplateBox
          title={i18n.t('entity.vehicleComplaint.labels.complaintDocument')}
          templates={documentTemplates}
          selectedTemplate={documentTemplate}
          onTemplateChange={setDocumentTemplate}
          onSelect={noop}
          isSelected
          isDisabled
        />
        <ButtonGroup
          align="right"
          buttons={[
            {
              title: i18n.t('general.actions.cancel'),
              onClick: closeCurrentDialog,
              type: 'button',
              variant: 'secondary',
            },
            {
              title: i18n.t('general.actions.confirm'),
              onClick: handleSubmit,
              type: 'button',
              isLoading: isCreateLoading,
            },
          ]}
        />
      </VStack>
    </DataStatus>
  );
}
