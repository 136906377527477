import {ReactNode, useEffect} from 'react';

import {createContext, isDefined} from 'shared';

import {useVideoUploadManager} from '../hooks/useVideoUploadManager';
import {GetBase64Fn} from '../types/GetBase64Fn';
import {HandleMultiMultiPartUploadType} from '../types/HandleMultiMultiPartUploadType';
import {QueueVideoTask} from '../types/QueueVideoTask';
import {UploadApi} from '../types/UploadApi';
import {VideoUploadError} from '../types/VideoUploadError';
import {checkIsTaskExpired} from '../utils/checkIsTaskExpired';

type QueueVideoUploadState = {
  tasks: QueueVideoTask[];
  /**
   * Retrieves the progress of each task in the video upload queue.
   * This iterates through the list of video upload tasks, calculating the progress of each task based on the
   * status of its jobs. A task's progress is determined by the ratio of completed (`DONE`) jobs to the total number of
   * jobs within that task. The function returns an array of objects, each containing the task's ID and its progress
   * percentage. If a task has no jobs, the function returns `null` for that task to indicate that progress calculation
   * is not applicable.
   *
   * @returns An array of objects, each with a `connectionFileId` string and a `progress` number representing the task's progress.
   *          The `progress` is a decimal value between 0 and 1, where 1 signifies 100% completion.
   *          If a task has no jobs, returns `null` for that task.
   */
  uploadProgress: {
    id: string;
    /**
     * The progress of the task, represented a number between 0 and 100.
     * 100 signifies that the task is complete.
     */
    progress: number;
    connectionFileId: string;
  }[];
};

type QueueVideoUploadAction = {
  removeTask: (id: string) => void;
  /**
   * Initiates a multipart upload process for a video file.
   * This function handles the initiation of a multipart upload for a given video file.
   * It retrieves necessary server information for the upload, creates a queue video task from the file,
   * and enqueues it for processing.
   *
   * @param file - The video file to be uploaded.
   * @returns The ID of the video task that has been enqueued for processing.
   *
   * @remarks
   * This function is designed to be called when a video file needs to be uploaded via multipart upload.
   * It initiates the multipart upload process, prepares the necessary metadata, and enqueues the upload task
   * for processing. The actual upload process is handled asynchronously.
   */
  handleMultiMultiPartUpload: HandleMultiMultiPartUploadType;
};

const [StateProvider, useVideoUploadQueueState] = createContext<QueueVideoUploadState>({
  strict: true,
  name: 'VideoUploadQueueState',
});
const [ActionProvider, useVideoUploadQueueAction] = createContext<QueueVideoUploadAction>({
  strict: true,
  name: 'VideoUploadQueueAction',
});

interface Props {
  api: UploadApi;
  children: ReactNode;
  getBase64: GetBase64Fn;
  handleError: (error: VideoUploadError) => void;
}

function VideoUploadQueueProvider({children, getBase64, handleError, api}: Props) {
  const {
    tasks,
    removeTask,
    processTask,
    uploadProgress,
    handleExpiredTask,
    handleMultiMultiPartUpload,
  } = useVideoUploadManager(api, handleError);

  /**
   * Automatically processes the next pending upload task in the queue.
   * This effect triggers whenever there is a change in the `tasks` array or the `processTask` function, ensuring
   * that any task marked as 'PENDING' is processed as soon as possible. It is a crucial part of the system that
   * maintains the flow of task processing without manual intervention.
   *
   * @sideEffects
   * - Initiates the processing of an upload task by calling `processTask`.
   * - Can result in updating the state of tasks, potentially triggering re-renders.
   */
  useEffect(() => {
    const taskToRun = tasks?.find((task) => task.status === 'PENDING');

    if (!isDefined(taskToRun)) {
      return;
    }

    if (checkIsTaskExpired(taskToRun)) {
      handleExpiredTask(taskToRun);
      return;
    }

    processTask(taskToRun, getBase64);
  }, [handleExpiredTask, processTask, tasks]);

  return (
    <StateProvider value={{tasks, uploadProgress}}>
      <ActionProvider value={{handleMultiMultiPartUpload, removeTask}}>{children}</ActionProvider>
    </StateProvider>
  );
}

export {VideoUploadQueueProvider, useVideoUploadQueueState, useVideoUploadQueueAction};
