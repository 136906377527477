import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  OptionType,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  IssuedType,
  handleApiError,
  useBranches,
  useCreateTaxDocumentSettingMutation,
} from '@omnetic-dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string;
  issuedType: IssuedType;
};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accountingSettings.labels.issuedType'),
    href: settingsRoutes.taxDocumentIssuedType,
  },
];

export function TaxDocumentForPaymentIssuedTypeNew() {
  const navigate = useNavigate();
  const [createIssuedTypeDefinition] = useCreateTaxDocumentSettingMutation();

  const {branchOptions, isLoading, isError} = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) =>
    await createIssuedTypeDefinition({taxDocumentSettingRequestBody: data})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.taxDocumentIssuedType))
      .catch(handleApiError);

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accounting.labels.newDefinition'),
        breadcrumbs,
      }}
      data-testid={testIds.settings.taxDocumentIssuedTypeNew('page')}
      isLoading={isLoading}
      isError={isError}
      isCreating
    >
      <Form<FormValues> onSubmit={handleSubmit} schema={schema}>
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  options={issuedTypeOptions}
                  name="issuedType"
                  isRequired
                  label={i18n.t('page.accountingSettings.labels.issuedType')}
                />
              </Grid>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.taxDocumentIssuedType),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  branchId: yupString.required(),
  issuedType: yupString.required(),
});

const issuedTypeOptions: OptionType<IssuedType>[] = [
  {value: 'automatic', label: i18n.t('page.accountingSettings.labels.automatic')},
  {value: 'manual', label: i18n.t('page.accountingSettings.labels.manual')},
  {value: 'not_create', label: i18n.t('page.accountingSettings.labels.not_create')},
];
