import {
  Alert,
  AlertVariants,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Hide, HStack, Show, Spinner, Box} from 'platform/foundation';
import {css} from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormSpy} from 'react-final-form';
import {useNavigate} from 'react-router-dom';

import {isNotEmpty} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {
  BranchBusinessCaseResponseBody,
  CreateVehicleRequestBody,
  handleApiError,
  useGetCurrentBranch,
  useLazyFindBusinessCasesByVehicleQuery,
  useLazyGetSaleVehicleQuery,
  useReRegisterVehicleForSaleMutation,
  useTakeoverBusinessCaseMutation,
} from '@omnetic-dms/shared';
import {
  useFormRenderer,
  VehicleService,
  getVehicleOverallConditions,
  SaleVehicle,
  SimilarVehicle,
  VinDecoder,
  useCallApi,
  openInNew,
  useThunkDispatch,
  VehicleWidget,
} from '@omnetic-dms/teas';

import {composePath, debounce, noop, useBoolean, useToggle} from 'shared';

import {HintType, HintTypeEnum, SimilarVehiclesRequestBody} from '../types/Hints';
import {VehicleDuplicatesHints} from './VehicleDuplicatesHints';

const vinCharRegex = /[(A-H|J-N|P|R-Z|0-9)]{1,17}/;
const registrationPlateCharRegex = /[A-Z0-9]/g;

const parseVin = (value?: string) => {
  if (!value) {
    return '';
  }

  return value?.toUpperCase?.().match?.(vinCharRegex)?.slice(0, 17).join('');
};

const parsePlate = (value?: string) => {
  if (!value) {
    return '';
  }

  return value?.toUpperCase?.().match?.(registrationPlateCharRegex)?.slice(0, 17).join('');
};

export interface VinAndLicensePlateType {
  onDecode?: typeof noop;
  onVehicleSelect?: (vehicle: SimilarVehicle, makeSaleVehicleAvailableForSale?: boolean) => void;
  excludeId?: string | null;
  /**
   * createMode - true only when new vehicle is creating
   */
  createMode?: boolean;
  selectVehicleToPurchase?: boolean;
  decoding?: boolean;
}

export const VinAndLicensePlate: FC<VinAndLicensePlateType> = ({
  createMode = false,
  selectVehicleToPurchase,
  decoding,
  onDecode,
  excludeId,
  onVehicleSelect,
}) => {
  const vinAndRegistrationPlateCache = useRef<{registrationPlate?: string; vin?: string}>({});
  const {activeBranchId} = useGetCurrentBranch();
  const [takeoverBusinessCase, {isLoading: isLoadingTakeover}] = useTakeoverBusinessCaseMutation();
  const [getSaleVehicle] = useLazyGetSaleVehicleQuery();

  const {Field, form} = useFormRenderer<CreateVehicleRequestBody>();
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  // List of fetched similar vehicles
  const [similarVehicles, setSimilar] = useState<SimilarVehicle[]>([]);
  // List of fetched similar vehicles business cases
  const [businessCases, setBusinessCases] = useState<BranchBusinessCaseResponseBody[]>([]);
  // Dismiss suggestions
  const [dismiss, setDismiss] = useState<boolean>(false);
  // Set hint type
  const [hintType, setHintType] = useState<HintType>(null);
  // Set alert type
  const [alertType, setAlertType] = useState<AlertVariants | null>(null);
  // display button to re-register vehicle which has been already sold
  const [canBeReRegistered, setCanBeReRegistered] = useState(false);

  const [loading, startLoading, stopLoading] = useBoolean();
  const [confirmTakeoverOpen, toggleConfirmTakeoverOpen] = useToggle();
  const [confirmReRegisterOpen, toggleConfirmReRegisterOpen] = useToggle();

  const getSimilarVehicles = useCallApi(VehicleService.getSimilarVehicles);
  const [findBusinessCasesByVehicle] = useLazyFindBusinessCasesByVehicleQuery();

  const [reRegisterVehicleForSale, {isLoading: isLoadingReRegister}] =
    useReRegisterVehicleForSaleMutation();

  const handleChanges = useMemo(
    () =>
      debounce(
        (
          {vin, registrationPlateContains}: SimilarVehiclesRequestBody,
          callback?: (hintType: HintType) => void
        ) => {
          if (vin || registrationPlateContains) {
            startLoading();
            const requestBody: SimilarVehiclesRequestBody = {
              size: 20,
            };
            if (vin?.length === 17) {
              requestBody.vin = vin;
            }
            if (registrationPlateContains) {
              requestBody.registrationPlateContains = registrationPlateContains;
            }

            getSimilarVehicles(requestBody)
              .then(async (result) => {
                if (excludeId) {
                  const excludeIdIdx = result?.findIndex((vehicle) => vehicle.id === excludeId);
                  if (excludeIdIdx !== -1) {
                    result.splice(excludeIdIdx, 1);
                  }
                }

                if (result.length) {
                  const {data: existingBusinessCases} = await findBusinessCasesByVehicle({
                    vehicles: result.map((similar) => similar.id),
                  });

                  setBusinessCases(existingBusinessCases ?? []);

                  await dispatch(
                    getVehicleOverallConditions.action({
                      vehicleIds: result.map((similar) => similar.id),
                    })
                  );
                }

                const sameVinExists = result?.find((vehicle) => vin && vehicle.vin === vin);
                if (sameVinExists?.id) {
                  const {data: saleVehicle} = await getSaleVehicle({vehicleId: sameVinExists.id});

                  if (saleVehicle?.id) {
                    // TODO - T20-39110 - SALE_VEHICLE_STATES - 33
                    // TODO - T20-39110 - SALE_VEHICLE_STATES - 34
                    setCanBeReRegistered(
                      match([saleVehicle.buyingState, saleVehicle.sellingState])
                        .with(
                          [null, SaleVehicle.sellingState.SOLD],
                          [SaleVehicle.buyingState.BOUGHT, Pattern.any],
                          [SaleVehicle.buyingState.RETURNED_TO_CUSTOMER, Pattern.any],
                          [SaleVehicle.buyingState.NOT_BOUGHT, Pattern.any],
                          () => true
                        )
                        .otherwise(() => false)
                    );
                  } else {
                    setCanBeReRegistered(false);
                  }
                }

                setSimilar(result || []);
                const newHintType = sameVinExists
                  ? HintTypeEnum.ErrorVin
                  : isNotEmpty(result)
                    ? HintTypeEnum.InfoLicense
                    : null;

                setDismiss(false);
                setHintType(newHintType);

                if (callback) {
                  callback(newHintType);
                }
              })
              .catch((error) => {
                showNotification.error(
                  error.response?.data?.errors?.[0]?.message || error.toString()
                );
              })
              .finally(stopLoading);
          } else {
            setSimilar([]);
            setDismiss(false);
            stopLoading();
            setHintType(null);
          }
        },
        500
      ),
    [excludeId]
  );

  const handleTakeover = (takeoverBusinessCaseId: string) => async () => {
    await takeoverBusinessCase({
      businessCaseId: takeoverBusinessCaseId,
      takeoverBusinessCaseRequestBody: {branchId: activeBranchId},
    })
      .unwrap()
      .then(() => {
        navigate(composePath(businessCaseRoutes.buying, {params: {id: takeoverBusinessCaseId}}));
      })
      .catch(handleApiError);
  };

  const getHandleReRegister = (vehicle: SimilarVehicle) => async () => {
    if (!selectVehicleToPurchase) {
      await reRegisterVehicleForSale({vehicleId: vehicle.id}).unwrap().catch(handleApiError);
    }

    onVehicleSelect?.(vehicle, true);
  };

  const onVinOrPlateChange = useCallback(
    (vin?: string, registrationPlate?: string | null) => () => {
      if (
        vin === vinAndRegistrationPlateCache.current?.vin &&
        registrationPlate === vinAndRegistrationPlateCache.current.registrationPlate
      ) {
        return;
      }

      vinAndRegistrationPlateCache.current = {
        vin,
        registrationPlate: registrationPlate ?? undefined,
      };

      handleChanges({vin, registrationPlateContains: registrationPlate});
    },
    [handleChanges]
  );

  useEffect(() => {
    let latestVin = form.getState().values.vin;

    const unsub = form.subscribe(
      ({values}) => {
        if (latestVin !== values.vin) {
          latestVin = values.vin;

          if (values.vin?.length === 17) {
            onVinOrPlateChange(values.vin, values.state?.registrationPlate)();
          }
        }
      },
      {values: true}
    );

    return () => unsub();
  }, [form, onVinOrPlateChange]);

  const getDecodingStatus = (data: VinDecoder): AlertVariants => {
    if (data.decodingStatus === VinDecoder.decodingStatus.FULLY) {
      return 'success';
    }
    if (data.decodingStatus === VinDecoder.decodingStatus.PARTIALLY) {
      return 'warning';
    }

    return 'error';
  };

  const pickAlert = (): ReactNode => {
    switch (alertType) {
      case 'success':
        return (
          <Alert
            data-testid={testIds.vehicles.create('vinDecodedSuccessfully')}
            variant="success"
            title={i18n.t('entity.vehicle.notifications.vinDecodedSuccessfully')}
            message={i18n.t('entity.vehicle.notifications.vinDecodedSuccessfullyDescription')}
            onClose={() => setAlertType(null)}
          />
        );
      case 'warning':
        return (
          <Alert
            data-testid={testIds.vehicles.create('vinDecodedPartially')}
            variant="warning"
            title={i18n.t('entity.vehicle.notifications.vinDecodedPartially')}
            message={i18n.t('entity.vehicle.notifications.vinDecodedPartiallyDescription')}
            onClose={() => setAlertType(null)}
          />
        );
      default:
        return (
          <Alert
            data-testid={testIds.vehicles.create('vinDecodedError')}
            variant="error"
            title={i18n.t('entity.vehicle.notifications.vinDecodedError')}
            message={i18n.t('entity.vehicle.notifications.vinDecodedErrorDescriptions')}
            onClose={() => setAlertType(null)}
          />
        );
    }
  };

  return (
    <div
      css={css`
        margin-bottom: -16px;
      `}
    >
      <HStack align="flex-end" spacing={4}>
        <FormSpy<CreateVehicleRequestBody>
          subscription={{values: true, active: true}}
          render={({values}) => (
            <>
              <Box width="25%">
                <Field
                  as="text"
                  name="state.registrationPlate"
                  label={i18n.t('entity.vehicle.labels.licensePlate')}
                  parse={parsePlate}
                  onBlur={onVinOrPlateChange(
                    values?.vin ?? undefined,
                    values?.state?.registrationPlate
                  )}
                />
              </Box>
              <Box flex={1}>
                <Field
                  as="text"
                  name="vin"
                  label={i18n.t('entity.vehicle.labels.vinCode')}
                  parse={parseVin}
                  onBlur={onVinOrPlateChange(
                    values?.vin ?? undefined,
                    values?.state?.registrationPlate
                  )}
                  isCounterVisible
                  maxLength={17}
                />
              </Box>
            </>
          )}
        />
        <Box flex={0} paddingBottom={4}>
          <HStack align="center" height="100%">
            <Box paddingTop={1}>
              <FormSpy<CreateVehicleRequestBody>
                render={({values}) => (
                  <Button
                    variant="primary"
                    isLoading={decoding}
                    onClick={() => {
                      handleChanges(
                        {
                          vin: values.vin,
                          registrationPlateContains: values.state?.registrationPlate,
                        },
                        (hintType) => {
                          if (hintType !== HintTypeEnum.ErrorVin) {
                            onDecode?.(values?.vin)
                              .then((result: VinDecoder) => setAlertType(getDecodingStatus(result)))
                              .catch(() => setAlertType('error'));
                          }
                        }
                      );
                    }}
                    data-testid={testIds.vehicles.create('decodeVin')}
                    title={i18n.t('general.labels.decode')}
                  />
                )}
              />
            </Box>
          </HStack>
        </Box>
      </HStack>
      {alertType && <Box paddingBottom={4}>{pickAlert()}</Box>}
      {!dismiss && (
        <VehicleDuplicatesHints
          hintType={hintType}
          callback={() => setHintType(null)}
          createMode={createMode}
        />
      )}
      {isNotEmpty(similarVehicles) && !dismiss && (
        <Box paddingTop={4} paddingBottom={4}>
          <Card
            variant="inlineGrey"
            data-testid={testIds.vehicles.create('similarVehicles')}
            title={!loading ? i18n.t('entity.vehicle.labels.matchingVehicles') : undefined}
          >
            {loading ? (
              <span style={{textAlign: 'center'}}>
                <Spinner />
                {i18n.t('entity.vehicle.labels.searching')}
              </span>
            ) : (
              <>
                <Grid columns={1}>
                  {similarVehicles.map((vehicle, i) => {
                    const flags = vehicle.isArchived
                      ? [
                          {
                            color: 'neutral' as const,
                            name: i18n.t('entity.vehicle.labels.archived'),
                          },
                        ]
                      : undefined;

                    const existingBusinessCase = businessCases.find(
                      (businessCase) => businessCase.vehicleId === vehicle.id
                    );
                    const existingBusinessCaseInSameBranch =
                      existingBusinessCase?.branchId === activeBranchId;

                    return (
                      <GridItem key={vehicle.id}>
                        <Card
                          variant="inlineWhite"
                          data-testid={testIds.vehicles.create(`similarVehicle[${i}]`)}
                        >
                          <VehicleWidget
                            data-testid={testIds.vehicles.create(`similarVehicle[${i}]`)}
                            flags={flags}
                            vehicle={vehicle}
                            shouldShowDetailButton={false}
                            shouldShowPrice={false}
                            extraControls={
                              <ButtonGroup>
                                <Button
                                  data-testid={testIds.vehicles.create(`similarVehicle[${i}]-open`)}
                                  variant="link"
                                  leftIcon="action/launch"
                                  onClick={() =>
                                    openInNew(
                                      composePath(vehiclesRoutes.detail, {params: {id: vehicle.id}})
                                    )
                                  }
                                  title={i18n.t('entity.vehicle.labels.detail')}
                                />
                                <Show when={existingBusinessCase}>
                                  <Button
                                    data-testid={testIds.vehicles.create(
                                      `similarVehicle[${i}]-view`
                                    )}
                                    leftIcon="action/launch"
                                    variant="link"
                                    onClick={() =>
                                      openInNew(
                                        composePath(businessCaseRoutes.overview, {
                                          params: {
                                            id: existingBusinessCase?.id,
                                          },
                                        })
                                      )
                                    }
                                    title={i18n.t('entity.businessCase.labels.view')}
                                  />
                                </Show>
                                <Show when={canBeReRegistered}>
                                  <>
                                    <Button
                                      data-testid={testIds.vehicles.create(
                                        `similarVehicle[${i}]-reregister`
                                      )}
                                      variant="secondary"
                                      onClick={toggleConfirmReRegisterOpen}
                                      title={i18n.t('entity.vehicle.actions.reregister')}
                                    />
                                    <Dialog
                                      data-testid={testIds.businessCase.buying(
                                        'reregister-confirm'
                                      )}
                                      isOpen={confirmReRegisterOpen}
                                      onClose={toggleConfirmReRegisterOpen}
                                      size="small"
                                      title={i18n.t`page.vehicle.labels.confirmReRegister`}
                                    >
                                      <ButtonGroup align="right">
                                        <Button
                                          variant="secondary"
                                          onClick={toggleConfirmReRegisterOpen}
                                          isDisabled={isLoadingReRegister}
                                          title={i18n.t('general.labels.no')}
                                        />
                                        <Button
                                          variant="primary"
                                          onClick={getHandleReRegister(vehicle)}
                                          isLoading={isLoadingReRegister}
                                          title={i18n.t('general.labels.yes')}
                                        />
                                      </ButtonGroup>
                                    </Dialog>
                                  </>
                                </Show>
                                <Hide
                                  when={
                                    selectVehicleToPurchase ||
                                    existingBusinessCase ||
                                    canBeReRegistered
                                  }
                                >
                                  <Button
                                    data-testid={testIds.vehicles.create(
                                      `similarVehicle[${i}]-select`
                                    )}
                                    variant="secondary"
                                    onClick={() => onVehicleSelect?.(vehicle)}
                                    title={i18n.t('general.labels.select')}
                                  />
                                </Hide>
                                {existingBusinessCase ? (
                                  <>
                                    <Show
                                      when={
                                        selectVehicleToPurchase && !existingBusinessCaseInSameBranch
                                      }
                                    >
                                      <>
                                        <Button
                                          data-testid={testIds.vehicles.create(
                                            `similarVehicle[${i}]-takeover`
                                          )}
                                          variant="secondary"
                                          onClick={toggleConfirmTakeoverOpen}
                                          title={i18n.t('entity.businessCase.labels.takeover')}
                                        />
                                        <Dialog
                                          data-testid={testIds.businessCase.buying(
                                            'takeover-confirm'
                                          )}
                                          isOpen={confirmTakeoverOpen}
                                          onClose={toggleConfirmTakeoverOpen}
                                          size="small"
                                          title={i18n.t`page.businessCase.labels.confirmTakeover`}
                                        >
                                          <ButtonGroup align="right">
                                            <Button
                                              variant="secondary"
                                              onClick={toggleConfirmTakeoverOpen}
                                              isDisabled={isLoadingTakeover}
                                              title={i18n.t('general.labels.no')}
                                            />
                                            <Button
                                              variant="primary"
                                              onClick={handleTakeover(existingBusinessCase.id)}
                                              isLoading={isLoadingTakeover}
                                              title={i18n.t('general.labels.yes')}
                                            />
                                          </ButtonGroup>
                                        </Dialog>
                                      </>
                                    </Show>
                                  </>
                                ) : null}
                              </ButtonGroup>
                            }
                          />
                        </Card>
                      </GridItem>
                    );
                  })}
                </Grid>
                <Separator />
                <ButtonGroup align="right">
                  <Button
                    data-testid={testIds.vehicles.create('similarVehicles-dismiss')}
                    variant="link"
                    onClick={() => setDismiss(true)}
                    title={i18n.t('general.labels.dismiss')}
                  />
                </ButtonGroup>
              </>
            )}
          </Card>
        </Box>
      )}
    </div>
  );
};
