import {Table, TableRow} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {useGetBranchListQuery} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TableCell} from './components/TableCell';
import {TableHeader} from './components/TableHeader';

const columns = [
  {
    element: <TableHeader title={i18n.t('page.settings.labels.marketingName')} />,
    id: 'marketingName',
  },
  {
    element: <TableHeader title={i18n.t('entity.address.labels.city')} />,
    id: 'city',
  },
  {
    element: <TableHeader title={i18n.t('entity.address.labels.street')} />,
    id: 'street',
  },
  {
    element: <TableHeader title={i18n.t('entity.customer.labels.companyName')} />,
    id: 'companyName',
  },
  {
    element: <TableHeader title={i18n.t('entity.user.labels.users')} />,
    id: 'usersCount',
  },
  {
    element: null,
    id: 'actions',
  },
];

export function BranchOverview() {
  const navigate = useNavigate();
  const {data: branches, isLoading, isError} = useGetBranchListQuery();

  const handleCreateNewBranch = () => navigate(settingsRoutes.branchesNew);
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.branch.labels.branches'),
        actions: [
          {
            type: 'button',
            leftIcon: 'content/add_circle',
            title: i18n.t('entity.branch.actions.addBranch'),
            onClick: handleCreateNewBranch,
          },
        ],
      }}
      data-testid={testIds.settings.branches('branches-template')}
      isError={isError}
      isLoading={isLoading}
    >
      <Table columns={columns}>
        {branches?.branchListItems?.map((row) => {
          const editBranch = () =>
            navigate(composePath(settingsRoutes.branchesEdit, {params: {id: row.id}}));
          return (
            <TableRow
              key={row.id}
              onClick={editBranch}
              actions={{
                secondary: [
                  {
                    icon: 'image/edit',
                    onClick: editBranch,
                    title: i18n.t('general.actions.edit'),
                  },
                ],
              }}
            >
              <TableCell value={row.marketingName} />
              <TableCell value={row.city} />
              <TableCell value={row.street} />
              <TableCell value={row.companyName} />
              <TableCell value={row.usersCount?.toString()} />
            </TableRow>
          );
        })}
      </Table>
    </SettingsTemplate>
  );
}
