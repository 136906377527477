import {UserToBuyService} from '../../services/UserToBuyService';
import {VehiclesSliceName} from '../../types/SourcingVehicleState';

const classifiedsEndpoints = {
  // cart
  removeVehicleCart: UserToBuyService.deleteUserToBuy,
  removeVehicleCartBulk: UserToBuyService.bulkDeleteUserToBuy,
  buyVehicles: ({ids, xBranch, ...params}: {ids: string[]; xBranch: string}) =>
    UserToBuyService.buyVehicles({
      ...params,
      xBranch,
      requestBody: {
        sourcingVehiclesToBuy: ids,
      },
    }),
  exportAllVehicles: UserToBuyService.exportUserToBuyToCsv,
  exportVehiclesBulk: UserToBuyService.exportUserToBuyToCsv,
};

export const vehiclesEndpoints = {
  [VehiclesSliceName.classifieds]: classifiedsEndpoints,
};
