import {Card, FormControl, FormField, CreatableChoice, Option} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';
import {ValidationError, number} from 'yup';

import {useState, useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil, isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {BaseArticle, GetArticleResponse, useGetHandlingUnitsQuery} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {getActiveOptionsFromHandlingUnits} from '../../../../../utils/getOptionsFromHandlingUnits';

interface OverviewUnitsFormProps extends RequiredTestIdProps {
  formApi: UseFormReturn<BaseArticle>;
  control: FormControl<BaseArticle>;
  article: GetArticleResponse;
  presetDispensingUnits: Option[] | Nullish;
  arePresetDispensingUnitsLoading: boolean;
}

export function OverviewUnitsForm(props: OverviewUnitsFormProps) {
  const {data: handlingUnits} = useGetHandlingUnitsQuery();

  const [dispensingUnitOptions, setDispensingUnitOptions] = useState<Option[]>([]);

  const [selectedDispensingUnit, setSelectedDispensingUnit] = useState<string | null>(
    defaultTo('1', String(props.article.dispensingUnit))
  );

  const [dispensingUnitError, setDispensingUnitError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isNil(props.presetDispensingUnits)) {
      return;
    }
    setDispensingUnitOptions(props.presetDispensingUnits);
  }, [props.presetDispensingUnits]);

  useEffect(() => {
    if (isNil(props.article.dispensingUnit)) {
      return;
    }

    const dispensingUnitOption = {
      value: String(props.article.dispensingUnit),
      label: String(props.article.dispensingUnit),
    };

    const hasDispensingUnit = dispensingUnitOptions.some(
      (option) => option.value === dispensingUnitOption.value
    );

    if (hasDispensingUnit) {
      return;
    }

    setDispensingUnitOptions((dispensingUnits) => [...dispensingUnits, dispensingUnitOption]);
  }, [props.article.dispensingUnit, dispensingUnitOptions]);

  const handleDispensingUnitChange = (dispensingUnit: string | null) => {
    validateDispensingUnit(dispensingUnit);
    setSelectedDispensingUnit(dispensingUnit);
    props.formApi.setValue('dispensingUnit', Number(dispensingUnit));
  };

  const handleCreateDispensingUnit = (newDispensingUnit: string) => {
    validateDispensingUnit(newDispensingUnit);
    setSelectedDispensingUnit(newDispensingUnit);
    props.formApi.setValue('dispensingUnit', Number(newDispensingUnit));

    const newValue = {
      value: newDispensingUnit,
      label: newDispensingUnit,
    };

    setDispensingUnitOptions((dispensingUnits) => [...dispensingUnits, newValue]);
  };

  const validateDispensingUnit = (value: string | null) => {
    try {
      setDispensingUnitError(undefined);
      dispensingUnitSchema.validateSync(value);
      props.formApi.clearErrors('dispensingUnit');

      return true;
    } catch (err) {
      if (err instanceof ValidationError) {
        setDispensingUnitError(err.errors.join(' '));
        props.formApi.setError('dispensingUnit', {message: err.errors.join(' ')});
      }

      return false;
    }
  };

  return (
    <Card title={i18n.t('entity.warehouse.labels.units')}>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="handlingUnit"
            type="choice"
            label={i18n.t('entity.warehouse.labels.measurementUnit')}
            control={props.control}
            options={getActiveOptionsFromHandlingUnits(handlingUnits)}
            isNotClearable
            data-testid={suffixTestId('inputs.handlingUnit', props)}
          />
        </GridItem>

        <GridItem span={1}>
          <CreatableChoice
            name="dispensingUnit"
            value={selectedDispensingUnit}
            label={i18n.t('entity.warehouse.labels.dispensingUnit')}
            tooltip={i18n.t('entity.warehouse.labels.dispensingUnitTooltip')}
            options={dispensingUnitOptions}
            onChange={(dispensingUnit) => handleDispensingUnitChange(dispensingUnit)}
            onCreateOption={(newDispensingUnit) => handleCreateDispensingUnit(newDispensingUnit)}
            isLoading={props.arePresetDispensingUnitsLoading}
            isInvalid={isNotNil(dispensingUnitError)}
            errorMessage={dispensingUnitError}
            isNotClearable
          />
        </GridItem>
      </Grid>
    </Card>
  );
}

const dispensingUnitSchema = number()
  .typeError(i18n.t('general.errors.mustBeANumber'))
  .min(0.0001, (params) => i18n.t('general.errors.number.greaterOrEqual', {min: params.min}))
  .test('maxDecimalPlaces', i18n.t('entity.warehouse.labels.maxFourDecimalPlaces'), (value) => {
    if (isNil(value)) {
      return true;
    }
    const decimalPattern = new RegExp(`^\\d+(\\.\\d{0,4})?$`);
    return decimalPattern.test(value.toString());
  });
