import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {captureException} from '@sentry/browser';

import {filter, forEach, pipe, values} from 'ramda';
import {isEmptyString} from 'ramda-adjunct';

import {environment} from '@omnetic-dms/environment';

import {flattenObj} from '../../../utils/flattenObject';
import {FetchBaseQueryArgs} from '../FetchBaseQueryArgs';

const isDev = environment.ENV_TYPE === 'dev';

export const checkForEmptyStrings = (args: FetchBaseQueryArgs, api: BaseQueryApi) => {
  if (!isDev) {
    return;
  }

  const sendToSentry = () =>
    captureException(
      new Error(
        `Sending empty string value in
        \n ${JSON.stringify(args, null, 4)}
        \n in ${JSON.stringify(api, null, 4)}`
      )
    );

  pipe(flattenObj, values, filter<any[]>(isEmptyString), forEach(sendToSentry))(args);
};
