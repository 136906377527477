import type {PermissionsRecordType} from '../permissions';

export const complaintPermissions = {
  complaintRead: {
    actionId: 'READ',
    resourceId: 'COMPLAINT',
    scopes: [],
  },
  complaintCreate: {
    actionId: 'CREATE',
    resourceId: 'COMPLAINT',
    scopes: [],
  },
  complaintPatch: {
    actionId: 'PATCH',
    resourceId: 'COMPLAINT',
    scopes: [],
  },
  complaintProtocol: {
    actionId: 'PROTOCOL',
    resourceId: 'COMPLAINT',
    scopes: [],
  },
  complaintDelete: {
    actionId: 'DELETE',
    resourceId: 'COMPLAINT',
    scopes: [],
  },
} satisfies PermissionsRecordType;
