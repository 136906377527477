import {
  closeCurrentDialog,
  ColorSchemeType,
  Flag,
  FlagSizeType,
  openDialog,
  showNotification,
  Tag,
} from 'platform/components';

import {noop, suffixTestId, TestIdProps} from 'shared';

import {useUnassignTagMutation} from '../../../../api/tagApi';
import i18n from '../../../../i18n/i18n';
import {EntityResourceIds} from '../../../../types/api/api';
import {handleApiError} from '../../../../utils/handleApiError';
import {CREATE_OR_EDIT_TAG_DIALOG_ID} from './const';
import {CreateOrEditTagForm} from './CreateOrEditTagForm';

interface EditableTagProps extends TestIdProps {
  recordId: string;
  resourceId: EntityResourceIds;
  tagId: string;
  tagValueId: string;
  label: string;
  size?: FlagSizeType;
  color: ColorSchemeType;
  isTagDeletable?: boolean;
  isTagUpdatable?: boolean;
  isTagCreatable?: boolean;
  isTagUnassignable?: boolean;
}

export function EditableTag({
  label,
  color,
  size,
  isTagCreatable,
  isTagDeletable,
  isTagUpdatable,
  isTagUnassignable,
  ...tagProps
}: EditableTagProps) {
  const [unassignTag] = useUnassignTagMutation();

  const handleUnassignTag = () =>
    unassignTag(tagProps)
      .unwrap()
      .then(() => {
        showNotification.success();
        closeCurrentDialog();
      })
      .catch(handleApiError);

  const handleEditTag = () =>
    isTagUpdatable
      ? openDialog(
          <CreateOrEditTagForm
            isDeletable={isTagDeletable}
            isCreatable={isTagCreatable}
            {...tagProps}
          />,
          {
            title: i18n.t('general.labels.editTag'),
            size: 'small',
            scrollBehavior: 'outside',
            id: CREATE_OR_EDIT_TAG_DIALOG_ID,
          }
        )
      : noop;

  if (isTagUnassignable) {
    return (
      <Tag
        onClick={handleEditTag}
        size={size}
        onDelete={handleUnassignTag}
        colorScheme={color}
        label={label}
        data-testid={suffixTestId('tag', tagProps)}
      />
    );
  }
  return (
    <span onClick={handleEditTag}>
      <Flag
        label={label}
        colorScheme={color}
        data-testid={suffixTestId('tag', tagProps)}
        size={size}
      />
    </span>
  );
}
