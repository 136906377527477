import {WatermarkResponseBody} from '@omnetic-dms/shared';

import {WatermarkFormType} from '../types/watermarkForm';

export const getDefaultValue = (data?: WatermarkResponseBody): Partial<WatermarkFormType> => ({
  size: data?.size ?? 100,
  position: data?.position ?? 'center_center',
  fitToImage: data?.fitToImage ? ['fitToImage'] : [],
  addOnlyForFirstPhoto: data?.addOnlyForFirstPhoto ? 'true' : 'false',
  fileId:
    data?.fileId && data?.imageUri
      ? {
          id: data.fileId,
          url: data.imageUri,
        }
      : undefined,
});
