import {BreadcrumbType, Form, FormField, FormSubmitHandler} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  useGetBillingInformationListQuery,
  useGetBranchListQuery,
  useGetEmployeeWorkTeamQuery,
  usePatchEmployeeWorkTeamMutation,
  usePostEmployeeWorkTeamMutation,
} from '@omnetic-dms/shared';

import {Nullish, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type WorkTeamsFormType = {description: string; company: string; branch: string};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.employeeWorkTeams.labels.title'),
    href: settingsRoutes.employeeWorkTeams,
  },
];

export function EmployeeWorkTeamsDetail() {
  const navigate = useNavigate();
  const {id} = useParams();

  const [postEmployeeWorkTeam] = usePostEmployeeWorkTeamMutation();
  const [patchEmployeeWorkTeam] = usePatchEmployeeWorkTeamMutation();
  const {data, isError, isLoading} = useGetEmployeeWorkTeamQuery({id: id ?? ''}, {skip: isNil(id)});
  const {data: branches} = useGetBranchListQuery();
  const {data: billingInformations} = useGetBillingInformationListQuery();

  const billingInformationOptions = billingInformations?.billingInformationListItems.map(
    (billingInformation) => ({
      label: billingInformation.companyName,
      value: billingInformation.id,
    })
  );

  const getBranchOptions = (selectedCompany: string | Nullish) => {
    if (isNilOrEmpty(selectedCompany)) {
      return undefined;
    }

    const companyName = billingInformationOptions?.find(
      (option) => option.value === selectedCompany
    )?.label;

    return branches?.branchListItems
      ?.filter((branch) => branch.companyName === companyName)
      .map((branch) => ({label: branch.marketingName, value: branch.id}));
  };

  const handleSubmit: FormSubmitHandler<WorkTeamsFormType> = async (body) => {
    const request = isNil(id) ? postEmployeeWorkTeam({body}) : patchEmployeeWorkTeam({body, id});
    await request
      .unwrap()
      .then(() => navigate(settingsRoutes.employeeWorkTeams))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        breadcrumbs,
        title: data?.description ?? i18n.t('entity.employeeWorkTeams.labels.newWorkTeam'),
      }}
      isError={isError}
      isLoading={isLoading}
      isCreating={isNil(id)}
      data-testid={testIds.settings.employeeWorkTeamsDetail('settingsTemplate')}
    >
      <Form<WorkTeamsFormType>
        defaultValues={{
          branch: data?.branch ?? undefined,
          company: data?.company ?? undefined,
          description: data?.description ?? undefined,
        }}
        schema={schema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <>
            <SettingsSection>
              <VStack spacing={4}>
                <FormField
                  control={control}
                  type="text"
                  name="description"
                  label={i18n.t('general.labels.description')}
                  data-testid={testIds.settings.employeeWorkTeamsDetail('description')}
                />
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="company"
                      label={i18n.t('general.labels.company')}
                      options={billingInformationOptions}
                      onChange={() => formApi.setValue('branch', '')}
                      data-testid={testIds.settings.employeeWorkTeamsDetail('company')}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="branch"
                      label={i18n.t('entity.branch.labels.branch')}
                      isDisabled={isNilOrEmpty(formApi.watch('company'))}
                      options={getBranchOptions(formApi.watch('company'))}
                      data-testid={testIds.settings.employeeWorkTeamsDetail('branch')}
                    />
                  </Box>
                </HStack>
              </VStack>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  onClick: () => navigate(settingsRoutes.employeeWorkTeams),
                  title: id
                    ? i18n.t('general.actions.discardChanges')
                    : i18n.t('general.actions.discard'),
                  variant: 'secondary',
                  'data-testid': testIds.settings.employeeWorkTeamsDetail('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: id
                    ? i18n.t('general.actions.saveAndClose')
                    : i18n.t('general.actions.create'),
                  'data-testid': testIds.settings.employeeWorkTeamsDetail('save'),
                },
              ]}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  branch: yupString.required(),
  company: yupString.required(),
  description: yupString.required(),
});
