import {
  closeCurrentDialog,
  DataStatus,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, HStack, Link, Text, VStack} from 'platform/foundation';

import {isNil, move} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  useAddCodeMutation,
  useChangeOrderOfCodesMutation,
  useReadCodelistQuery,
} from '@omnetic-dms/shared';
import {DragAndDrop, DragAndDropContext, DraggedItem} from '@omnetic-dms/teas';

import {CodeForm, CodeFormProps} from './CodeForm';
import {CodelistItem} from './CodelistItem';

export interface SettingsCodelistProps {
  codelistId: string;
}

export function SettingsCodelist(props: SettingsCodelistProps) {
  const {data, isLoading, isError} = useReadCodelistQuery({codelistId: props.codelistId});
  const [addCode] = useAddCodeMutation();
  const [changeOrder] = useChangeOrderOfCodesMutation();

  const activeCodes = data?.codes.filter((code) => !code.isDisabled) ?? [];

  const onDragEnd = (draggedItem: DraggedItem) => {
    if (isNil(draggedItem.destination) || isNil(data)) {
      return;
    }

    const reorderedCodeIds = move(
      draggedItem.source.index,
      draggedItem.destination.index,
      activeCodes
    ).map((c) => c.codeId);

    changeOrder({
      codelistId: props.codelistId,
      changeOrderOfCodesRequestBody: {codeIds: reorderedCodeIds},
    });
  };

  const onAddSubmit: CodeFormProps['onSubmit'] = (values) =>
    addCode({
      codelistId: props.codelistId,
      addCodeRequestBody: {
        name: values.name,
        priority: 0,
      },
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        showNotification.success(i18n.t('entity.codelist.notifications.itemAdded'));
      })
      .catch(handleApiError);

  const onAdd = () => {
    openDialog(<CodeForm onSubmit={onAddSubmit} />, {
      title: i18n.t('entity.codelist.labels.addItem'),
    });
  };

  return (
    <Box backgroundColor="general.white" boxShadow="elevation_1" borderRadius="small">
      <VStack>
        <Box paddingHorizontal={4} paddingVertical={3}>
          <HStack justify="space-between" spacing={2}>
            <Text alternative>{data?.name}</Text>
            <Link
              leftIcon="content/add_circle"
              onClick={onAdd}
              title={i18n.t('general.actions.addNew')}
            />
          </HStack>
        </Box>
        <Separator spacing={0} />

        <DataStatus
          isLoading={isLoading}
          isError={isError}
          isEmpty={!data?.codes.length}
          minHeight={24}
        >
          <DragAndDropContext onDragEnd={onDragEnd}>
            <DragAndDrop
              direction="vertical"
              droppableId="drop"
              items={activeCodes.map((item) => ({
                id: item.codeId,
                component: <CodelistItem code={item} codelistId={props.codelistId} />,
              }))}
            />
          </DragAndDropContext>
        </DataStatus>
      </VStack>
    </Box>
  );
}
