import {UpdateWatermarkRequestBody, WatermarkPositionEnum} from '@omnetic-dms/shared';

import {WatermarkFormType} from '../types/watermarkForm';

export const getRequestData = (data: WatermarkFormType): UpdateWatermarkRequestBody => ({
  size: data.size,
  position: data.position as WatermarkPositionEnum,
  fitToImage: data.fitToImage.includes('fitToImage'),
  addOnlyForFirstPhoto: data.addOnlyForFirstPhoto === 'true',
  fileId: data.fileId?.id ?? null,
});
