import {Card, Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, HStack, Link, Right, Text, VStack} from 'platform/foundation';
import {object, string} from 'yup';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {queryParams, useGetFordUzywaneExtraLinkQuery} from '@omnetic-dms/shared';

import {Nullish, useQueryState} from 'shared';

import {PlatformAuthenticationFormData} from './PlatformAuthenticationForm';

export type PlatformAuthenticationFormFordUzywaneProps = {
  defaultValues: PlatformAuthenticationFormData | Nullish;
  onSubmit: FormSubmitHandler<PlatformAuthenticationFormData>;
};

export function PlatformAuthenticationFormFordUzywane(
  props: PlatformAuthenticationFormFordUzywaneProps
) {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const {data: extraLink} = useGetFordUzywaneExtraLinkQuery({
    branchId: isNil(branchId) ? undefined : branchId,
  });

  return (
    <Card title={i18n.t('general.labels.authentication')}>
      <Form<PlatformAuthenticationFormData>
        defaultValues={props.defaultValues}
        schema={schema}
        onSubmit={props.onSubmit}
      >
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={2}>
              <FormField
                control={control}
                name="username"
                type="text"
                label={i18n.t(`page.advertisement.labels.username`)}
                data-testid={testIds.settings.advertisingPlatforms(
                  `credentialsUsername-fordUzywane`
                )}
              />
              <FormField
                control={control}
                name="password"
                type="password"
                label={i18n.t(`page.advertisement.labels.password`)}
                data-testid={testIds.settings.advertisingPlatforms(
                  `credentialsPassword-fordUzywane`
                )}
              />
            </Grid>
            {extraLink && (
              <>
                <HStack spacing={1} align="center">
                  <Text size="xSmall">{i18n.t('general.labels.URL')}</Text>
                  <Link
                    size="small"
                    data-testid={testIds.settings.advertisingPlatforms('externalLink-fordUzywane')}
                    href={extraLink.link}
                    title={i18n.t('page.advertisement.labels.extraLinkText')}
                  />
                </HStack>
              </>
            )}
            <Right>
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.save')}
                data-testid={testIds.settings.advertisingPlatforms(`credentialsSave-fordUzywane`)}
              />
            </Right>
          </VStack>
        )}
      </Form>
    </Card>
  );
}

const schema = object({
  username: string().nullable().required(),
  password: string().nullable().required(),
});
