import {Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, GridItem, HStack, Link, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {array, object, string} from 'yup';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  CommonConditionAttribute,
  CommonDescriptionAttribute,
  CommonDigitalCertificateAttribute,
  CommonGenerationAttribute,
  CommonTitleExtensionAttribute,
  CommonVersionAttribute,
  CommonVideoUrlAttribute,
  featureFlags,
  useGetSalesPlatformsQuery,
  VehicleGeneralAdvertisingSettingsResponseBody,
} from '@omnetic-dms/shared';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {DOT_CHARACTER, parseDate, suffixTestId, TestIdProps} from 'shared';

import {GeneralSettingsFormValues} from '../types/GeneralSettingsFormValues';
import {getAttributeByCode} from '../utils/getAttributeByCode';
import {DescriptionFormField} from './DescriptionFormField';

interface GeneralSettingsFormProps extends TestIdProps {
  vehicleId: string;
  descriptionLabel: string;
  data: VehicleGeneralAdvertisingSettingsResponseBody | undefined;
  prefilledNote?: string | null;
  onDescriptionChange?: (description: string | null) => void;
  versionOptions: Array<{value: string; label: string}>;
  generationOptions: Array<{value: string; label: string}>;
  digitalCertificateOptions: Array<{value: string; label: string}>;
  onSubmit: FormSubmitHandler<GeneralSettingsFormValues>;
  onHandleEdit: () => void;
  isDisabled: boolean;
}

export function GeneralSettingsForm(props: GeneralSettingsFormProps) {
  const formatDateTime = useDateTimeFormatter();
  const {data: platforms} = useGetSalesPlatformsQuery({vehicleId: props.vehicleId});
  const isOtomotoEnabled = platforms?.find(
    ({platform}) => platform.code === AdvertisingPlatformCodeEnum.OTOMOTO
  )?.platform.enabled;

  const defaultValues: GeneralSettingsFormValues = {
    description:
      getAttributeByCode<CommonDescriptionAttribute>('description', props.data?.attributes)
        ?.value ?? null,
    title:
      getAttributeByCode<CommonTitleExtensionAttribute>('title_extension', props.data?.attributes)
        ?.value ?? null,
    version:
      getAttributeByCode<CommonVersionAttribute>('version', props.data?.attributes)?.value ?? null,
    generation:
      getAttributeByCode<CommonGenerationAttribute>('generation', props.data?.attributes)?.value ??
      null,
    digitalCertificate:
      getAttributeByCode<CommonDigitalCertificateAttribute>(
        'digital_certificate',
        props.data?.attributes
      )?.value ?? null,
    videoUrl:
      getAttributeByCode<CommonVideoUrlAttribute>('video_url', props.data?.attributes)?.value ??
      null,
    condition: String(
      getAttributeByCode<CommonConditionAttribute>('condition', props.data?.attributes)?.value ?? ''
    ),
  };

  const options: {label: string; value: string}[] = [
    {label: i18n.t('page.advertisement.labels.condition.excellent'), value: '1'},
    {label: i18n.t('page.advertisement.labels.condition.veryGood'), value: '2'},
    {label: i18n.t('page.advertisement.labels.condition.good'), value: '3'},
    {label: i18n.t('page.advertisement.labels.condition.acceptable'), value: '4'},
    {label: i18n.t('page.advertisement.labels.condition.serviceable'), value: '5'},
  ];

  return (
    <Form<GeneralSettingsFormValues>
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={props.onSubmit}
      shouldWatchForUnsavedChanges
    >
      {(control) => (
        <VStack spacing={4}>
          <DescriptionFormField
            control={control}
            descriptionLabel={props.descriptionLabel}
            isDisabled={props.isDisabled}
            onDescriptionChange={props.onDescriptionChange}
            prefilledNote={props.prefilledNote}
            data-testid={props['data-testid']}
          />
          <Grid columns={4}>
            <GridItem span={2}>
              <FormField
                control={control}
                label={i18n.t('page.advertisement.labels.titleExtension')}
                name="title"
                type="text"
                isDisabled={props.isDisabled}
                tooltip={i18n.t('page.advertisement.labels.titleExtensionTooltip')}
                maxLength={30}
                isCounterVisible
                data-testid={suffixTestId('title', props)}
              />
            </GridItem>
            <Show
              when={isOtomotoEnabled}
              whenFeatureEnabled={featureFlags.ADVERTISEMENT_VERSION_GENERATION_ATTRIBUTES}
            >
              <FormField
                control={control}
                label={i18n.t('page.advertisement.labels.version')}
                tooltip={i18n.t('page.advertisement.labels.versionTooltip')}
                name="version"
                type="choice"
                options={props.versionOptions}
                isDisabled={props.isDisabled}
                data-testid={suffixTestId('version', props)}
              />
            </Show>
            <Show
              when={isOtomotoEnabled}
              whenFeatureEnabled={featureFlags.ADVERTISEMENT_VERSION_GENERATION_ATTRIBUTES}
            >
              <FormField
                control={control}
                label={i18n.t('page.advertisement.labels.generation')}
                tooltip={i18n.t('page.advertisement.labels.generationTooltip')}
                name="generation"
                type="choice"
                options={props.generationOptions}
                isDisabled={props.isDisabled}
                data-testid={suffixTestId('generation', props)}
              />
            </Show>
            <Show
              whenFeatureEnabled={
                featureFlags.ADVERTISEMENT_DIGITAL_CERTIFICATE_VIDEO_URL_ATTRIBUTES
              }
            >
              <FormField
                control={control}
                label={i18n.t('page.advertisement.labels.digitalCertificate')}
                tooltip={i18n.t('page.advertisement.labels.digitalCertificateTooltip')}
                name="digitalCertificate"
                type="choice"
                options={props.digitalCertificateOptions}
                isDisabled={props.isDisabled}
                data-testid={suffixTestId('digitalCertificate', props)}
              />
              <FormField
                control={control}
                label={i18n.t('page.advertisement.labels.videoUrl')}
                tooltip={i18n.t('page.advertisement.labels.videoUrlTooltip')}
                name="videoUrl"
                type="text"
                isDisabled={props.isDisabled}
                data-testid={suffixTestId('videoUrl', props)}
              />
            </Show>
          </Grid>
          <HStack>
            <FormField
              control={control}
              type="chips"
              options={options}
              name="condition"
              isDisabled={props.isDisabled}
              label={i18n.t('page.advertisement.labels.conditionTitle')}
              tooltip={i18n.t('page.advertisement.labels.conditionTooltip')}
              data-testid={suffixTestId('condition', props)}
              isDeselectable
            />
          </HStack>
          {props.data?.updatedAt && (
            <Show when={isNotNil(props.data?.updatedBy)}>
              <Text size="xSmall" color="secondary">
                {`${i18n.t(`page.advertisement.labels.lastUpdateBy`)} ${
                  props.data?.updatedBy
                } ${DOT_CHARACTER} ${formatDateTime(
                  'dateTimeShort',
                  parseDate(props.data?.updatedAt)
                )}`}
              </Text>
            </Show>
          )}
          <HStack justify="space-between">
            <Link
              leftIcon="image/edit"
              title={i18n.t('page.advertisement.actions.editParameters')}
              size="small"
              onClick={props.onHandleEdit}
              data-testid="button-advertisement.listingAttributes.edit"
            />
            <Show when={!props.isDisabled}>
              <FormButton
                type="submit"
                control={control}
                title={i18n.t('general.actions.confirm')}
                data-testid={props['data-testid']}
              />
            </Show>
          </HStack>
        </VStack>
      )}
    </Form>
  );
}

const schema = object().shape({
  title: string().nullable(),
  description: string().nullable(),
  videoUrl: string().url().nullable(),
  condition: array(string()).nullable(),
});
