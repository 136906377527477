import {z} from 'zod';

import {BaseIdSchema, BaseVoidResponse, CreatedAndUpdatedBySchema} from './base';

/*
 * Get warehouses
 *
 * */

export const GetWarehousesRequestSchema = z.union([
  z.object({
    serviceOrderVariantId: z.string().optional(),
    directSaleVariantId: z.string().optional(),
    authorizationProfileId: z.string().optional(),
    supplierId: z.string().optional(),
  }),
  z.void(),
]);

export type GeWarehousesRequest = z.infer<typeof GetWarehousesRequestSchema>;

export const GetWarehousesResponseSchema = z.array(
  z.object({
    id: z.string(),
    warehouseNumber: z.string().optional(),
    name: z.string(),
    authorizationProfileId: z.string(),
  })
);

export type GetWarehousesResponse = z.infer<typeof GetWarehousesResponseSchema>;

/*
 * Get warehouse
 *
 * */

//  TODO: move to supplier folder
const WarehouseSupplierSchema = z.object({
  id: z.union([z.string(), z.null()]),
  priceListId: z.union([z.string(), z.null()]),
});

export const WarehouseDetailSchema = z.object({
  warehouseNumber: z.string().optional(),
  name: z.string(),
  address: z.string().optional(),
  supportedMake: z.string().optional(),
  defaultSupplierId: z.union([z.string(), z.null()]),
  warehousePricing: z.string().optional(),
  defaultAccountId: z.string(),
  defaultOrderShipping: z.string().optional(),
  branchId: z.string().nullable(),
  companyBillingInfoId: z.string(),
  allowServiceOrderIssue: z.boolean(),
  allowedOrderVariants: z.array(z.string()).nullable().optional(),
  commercialOrderMarginMinimum: z.number().optional(),
  stockNumberPrefix: z.string().optional(),
  receiveNoteSeriesId: z.string().optional(),
  receiveNoteTemplateId: z.string().optional(),
  issueNoteSeriesId: z.string().optional(),
  issueNoteTemplateId: z.string().optional(),
  salesPriceCalculation: z.string(),
  basePriceSource: z.string().optional(),
  salePriceProfitWithoutVat: z.number().nullable().optional(),
  salePriceMarginPercent: z.number().nullable().optional(),
  salePriceMarkupPercent: z.number().nullable().optional(),
  blockPricingSettingOnArticle: z.boolean(),
  unitSalesPriceWithVat: z.boolean(),
  dispatchToMinus: z.boolean(),
  dummyWarehouse: z.boolean(),
  allSuppliers: z.boolean(),
  suppliers: z.array(WarehouseSupplierSchema),
  allowDirectSaleIssue: z.boolean(),
  allowedDirectSaleVariants: z.array(z.string()).nullable().optional(),
  authorizationProfileId: z.string(),
  currency: z.string().optional(),
});

export type WarehousesDetail = z.infer<typeof WarehouseDetailSchema>;

export const GetWarehouseRequestSchema = z.object({
  warehouseId: z.string(),
});
export type GetWarehouseRequest = z.infer<typeof GetWarehouseRequestSchema>;

export const GetWarehouseResponseSchema =
  WarehouseDetailSchema.merge(CreatedAndUpdatedBySchema).merge(BaseIdSchema);
export type GetWarehouseResponse = z.infer<typeof GetWarehouseResponseSchema>;

/*
 * Create warehouse
 *
 * */
export const PostWarehouseRequestSchema = WarehouseDetailSchema;
export type PostWarehouseRequest = z.infer<typeof PostWarehouseRequestSchema>;

export const PostWarehouseResponseSchema = z.object({
  warehouseId: z.string(),
});
export type PostWarehouseResponse = z.infer<typeof PostWarehouseResponseSchema>;

/*
 * Update warehouse
 *
 * */
export const PatchWarehouseRequestSchema = z.object({
  warehouseId: z.string(),
  body: WarehouseDetailSchema,
});
export type PatchWarehouseRequest = z.infer<typeof PatchWarehouseRequestSchema>;

export type PatchWarehouseResponse = BaseVoidResponse;

/**
 * Delete warehouse
 *
 */
export const DeleteWarehouseRequestSchema = z.object({
  body: z.object({
    warehouseId: z.array(z.string()),
  }),
});
export type DeleteWarehouseRequest = z.infer<typeof DeleteWarehouseRequestSchema>;

export type DeleteWarehouseResponse = BaseVoidResponse;
