import {AdvertisingPlatformCodeEnum} from './AdvertisingPlatformCodeEnum';
import {EditTipCarsClientDataRequestBody} from './EditTipCarsClientDataRequestBody';
import {Platform} from './Platform';

export enum EPlatformStatusAction {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

type ExtendedPlatform<T> = {
  error?: Error;
  success?: T | null;
} & T &
  Pick<Platform, 'extraFields' | 'credentials' | 'code'>;

type PlatformResponse<T extends AdvertisingPlatformCodeEnum> = {
  code: T;
  data: T;
};

export type PlatformInformation =
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.MYWEB>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.SAUTO>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.FACEBOOK>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.TIPCARS>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.MOBILE_DE>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.AUTOCARIS>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.AUTOSOFT>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.OTOMOTO>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.OLX>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.SKODA_PLUS>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.DAS_WELT_AUTO>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.MERCEDES_NA_SKLADE>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.HYUNDAI_PROMISE>
  | ExtendedPlatform<AdvertisingPlatformCodeEnum.FORD_UZYWANE>;

export type PlatformInformationResponse =
  | PlatformResponse<AdvertisingPlatformCodeEnum.MYWEB>
  | PlatformResponse<AdvertisingPlatformCodeEnum.SAUTO>
  | PlatformResponse<AdvertisingPlatformCodeEnum.FACEBOOK>
  | PlatformResponse<AdvertisingPlatformCodeEnum.TIPCARS>
  | PlatformResponse<AdvertisingPlatformCodeEnum.MOBILE_DE>
  | PlatformResponse<AdvertisingPlatformCodeEnum.AUTOCARIS>
  | PlatformResponse<AdvertisingPlatformCodeEnum.AUTOSOFT>
  | PlatformResponse<AdvertisingPlatformCodeEnum.OTOMOTO>
  | PlatformResponse<AdvertisingPlatformCodeEnum.OLX>
  | PlatformResponse<AdvertisingPlatformCodeEnum.SKODA_PLUS>
  | PlatformResponse<AdvertisingPlatformCodeEnum.DAS_WELT_AUTO>
  | PlatformResponse<AdvertisingPlatformCodeEnum.MERCEDES_NA_SKLADE>
  | PlatformResponse<AdvertisingPlatformCodeEnum.HYUNDAI_PROMISE>
  | PlatformResponse<AdvertisingPlatformCodeEnum.FORD_UZYWANE>;

export type AdvertisementSettingsSliceType = {
  platforms: {
    list: Record<string, Platform>;
    order: string[];
    loading: boolean;
  };
  tipCarsClientData: {
    error?: null;
    data?: EditTipCarsClientDataRequestBody;
    loading: boolean;
  };
  updatingTipCarsClientData: {
    updating: boolean;
  };
  detailedPlatforms: Partial<Record<string, PlatformInformation>>;
};

export type ChangeCredentialsActionsType = {
  credentials: Record<string, string>;
  platformCode: AdvertisingPlatformCodeEnum;
  branchId?: string;
};

export type UpdatePlatformType = {
  action: EPlatformStatusAction;
  platformCode: `${AdvertisingPlatformCodeEnum}`;
  branchId?: string;
};
