import {Action} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps, useQueryState} from 'shared';

import {queryParams} from '../../../utils/queryParams';
import {SectionHeading, SectionTab} from './SectionHeading';

export interface SectionProps extends TestIdProps {
  tabs?: SectionTab[];
  defaultActiveTab?: string;
  heading?: string;
  actions?: Action[];
  children?: ReactNode;
  isFullHeight?: boolean;
  onBackClick?: () => void;
  onTabChange?: (newTab: string) => void;
}

export function Section(props: SectionProps) {
  const [tab, setTab] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const onTabChange = (index: number) => {
    if (!props.tabs) {
      return;
    }
    props.onTabChange?.(props.tabs[index].queryId);
    setTab(props.tabs[index].queryId);
  };

  const activeTab = tab ?? props.defaultActiveTab;

  let activeTabIndex = activeTab
    ? props.tabs?.findIndex(({queryId}) => queryId === activeTab) ?? 0
    : 0;

  if (activeTabIndex === -1) {
    activeTabIndex = 0;
  }

  const height = props.isFullHeight ? '100%' : undefined;

  return (
    <Box
      flex={1}
      padding={4}
      height={height}
      role="main"
      data-testid={suffixTestId('main-box', props)}
    >
      <VStack align="stretch" justify="stretch" height={height}>
        <VStack align="stretch" justify="stretch" height={height} spacing={4}>
          <SectionHeading
            tabs={props.tabs}
            title={props.heading}
            actions={props.actions}
            onBackClick={props.onBackClick}
            onTabChange={onTabChange}
            activeTabIndex={activeTabIndex}
            data-testid={suffixTestId('main-heading', props)}
          />
          <Box flex={1} data-testid={suffixTestId('main-content', props)}>
            {props.tabs?.[activeTabIndex]?.content ?? props.children}
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
}
