import {Card, DataStatus, Flag, Separator} from 'platform/components';
import {
  Box,
  Grid,
  HStack,
  Heading,
  Hide,
  Link,
  Show,
  Space,
  Text,
  VStack,
} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {Fragment, ReactNode} from 'react';
import 'react-router-dom';

import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {accountingRoutes} from '@omnetic-dms/routes';
import {EMPTY_PLACEHOLDER, Money, PaymentType} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, composePath, parseDate, suffixTestId, useNavigate} from 'shared';

import {PAYMENT_TYPE_LABELS} from '../constants';

type PaymentListInvoiceType = 'proforma' | 'invoice' | 'balance' | 'correctiveTaxDocument';
type PaymentInvoiceItem = {
  amount: Money;
  paymentId: string;
  paymentType: PaymentType;
  paymentDate: string;
  cashRegisterDocumentId?: string | null;
  cashRegisterDocumentNumber?: string | null;
  taxDocumentForPaymentId?: string | null;
  taxDocumentForPaymentNumber?: string | null;
  source: 'internal' | 'external';
};

type PaymentsListDetailProps = TestIdProps & {
  paymentItems: PaymentInvoiceItem[] | Nullish;
  invoiceType: PaymentListInvoiceType;
};

export function PaymentsListDetail(props: PaymentsListDetailProps) {
  const formatCurrency = useFormatCurrency();
  const formatDatetime = useDateTimeFormatter();
  const navigate = useNavigate();

  const isEmpty = isNilOrEmpty(props.paymentItems);

  const gridColumnsSize = props.invoiceType === 'proforma' ? 6 : 5;

  return (
    <VStack spacing={3}>
      <Heading size={4}>{i18n.t('page.accounting.invoiceDetailPayments.title')}</Heading>

      <Card>
        <DataStatus isEmpty={isEmpty} minHeight={50}>
          <VStack>
            <Box backgroundColor="palettes.neutral.10.100">
              <Grid columns={gridColumnsSize}>
                <HeaderItem hideSeparator>{i18n.t('general.labels.type')}</HeaderItem>
                <HeaderItem>{i18n.t('entity.bank.labels.paymentType')}</HeaderItem>
                <HeaderItem>{i18n.t('general.labels.amount')}</HeaderItem>
                <HeaderItem>{i18n.t('entity.invoice.labels.dateOfPayment')}</HeaderItem>
                <HeaderItem>{i18n.t('entity.checkout.labels.cashReceipt')}</HeaderItem>
                <Show when={props.invoiceType === 'proforma'}>
                  <HeaderItem>
                    {i18n.t('entity.accounting.labels.taxDocumentForPayment')}
                  </HeaderItem>
                </Show>
              </Grid>
            </Box>
            {props.paymentItems?.map((item, i) => (
              <Fragment key={item.paymentId}>
                <Separator spacing={0} />
                <Grid columns={gridColumnsSize}>
                  <RowItem data-testid={suffixTestId(`Type-${i}`, props)}>
                    <Flag
                      label={i18n.t(`entity.checkout.labels.${item.source}`)}
                      colorScheme={item.source === 'external' ? 'yellow' : 'green'}
                      isSubtle
                    />
                  </RowItem>
                  <RowItem data-testid={suffixTestId(`paymentType-${i}`, props)}>
                    {PAYMENT_TYPE_LABELS[item.paymentType] ?? EMPTY_PLACEHOLDER}
                  </RowItem>
                  <RowItem data-testid={suffixTestId(`amount-${i}`, props)}>
                    {formatCurrency(parseFloat(item.amount.amount), item.amount.currency, 2)}
                  </RowItem>
                  <RowItem data-testid={suffixTestId(`date-${i}`, props)}>
                    {formatDatetime('dateShort', parseDate(item.paymentDate))}
                  </RowItem>
                  <RowItem data-testid={suffixTestId(`id-${i}`, props)}>
                    {isNotNil(item.cashRegisterDocumentId) ? (
                      <Link
                        onClick={() =>
                          navigate(
                            composePath(accountingRoutes.cashReceiptDetail, {
                              params: {
                                id: item.cashRegisterDocumentId!,
                              },
                            })
                          )
                        }
                        title={
                          item.cashRegisterDocumentNumber ??
                          i18n.t('entity.checkout.labels.cashReceipt')
                        }
                      />
                    ) : null}
                  </RowItem>
                  {isNotNil(item.taxDocumentForPaymentId) ? (
                    <RowItem data-testid={suffixTestId('taxDocumentForPaymentId', props)}>
                      <Link
                        onClick={() =>
                          navigate(
                            composePath(accountingRoutes.taxDocumentForPaymentDetail, {
                              params: {
                                id: item.taxDocumentForPaymentId!,
                              },
                            })
                          )
                        }
                        title={
                          item.taxDocumentForPaymentNumber ??
                          i18n.t('entity.accounting.labels.taxDocumentForPayment')
                        }
                      />
                    </RowItem>
                  ) : null}
                </Grid>
              </Fragment>
            ))}
          </VStack>
        </DataStatus>
      </Card>
    </VStack>
  );
}

const HeaderItem = ({hideSeparator, children}: {hideSeparator?: boolean; children: string}) => (
  <HStack height={14} align="center">
    <Hide when={hideSeparator}>
      <HStack height={6}>
        <Separator orientation="vertical" spacing={0} />
      </HStack>
    </Hide>
    <Space horizontal={4} />
    <Heading size={5} color="secondary">
      {children}
    </Heading>
  </HStack>
);

const RowItem = (props: {children: ReactNode} & TestIdProps) => (
  <HStack height={10} align="center">
    <Space horizontal={4} />
    <Text size="small" data-testid={suffixTestId('taxDocumentForPaymentId', props)}>
      {props.children ?? EMPTY_PLACEHOLDER}
    </Text>
  </HStack>
);
