import {FetchArgs} from '@reduxjs/toolkit/query';

import {omit} from 'ramda';

import {browserStorageKey} from '@omnetic-dms/config';

import {CI_MODE_ON} from 'shared';

import {getWorkspaceFromUri} from '../../../utils/getWorkspaceFromUri';

export type FetchBaseQueryArgs = {
  omitHeaders?: boolean;
} & FetchArgs;

export const getArgsWithAuthorization = (args: FetchBaseQueryArgs, branch?: string) => {
  const {workspace} = getWorkspaceFromUri();
  const accessTokenFromStorage = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);
  const isCiModeOn = localStorage.getItem(browserStorageKey.CI_MODE) === CI_MODE_ON;

  const overrideAuthorization = (args.headers as Record<string, string | undefined>)?.Authorization;

  const authorizationFromStorage = accessTokenFromStorage && `Bearer ${accessTokenFromStorage}`;

  const authorization = overrideAuthorization ?? authorizationFromStorage;

  const headers = args.omitHeaders
    ? {}
    : {
        'accept-language': localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE),
        'x-country-format': 'iso', // https://carvago.slack.com/archives/G01AN5XCXGX/p1634202539053900
        ...(authorization ? {Authorization: authorization} : {}),
        ...(branch ? {'X-Branch': branch} : {}),
        ...(workspace ? {'X-Workspace': workspace} : {}),
        ...(isCiModeOn ? {'x-debug-mode': 'true'} : {}),
      };
  return {
    ...omit(['omitHeaders'], args),
    headers,
  };
};
