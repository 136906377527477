import {createAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';
import {all, put, select, takeEvery} from 'typed-redux-saga';

import {ApiException} from '@omnetic-dms/shared';

import {CommentService} from '../../services/CommentService';
import {CreateCommentRequestBody} from '../../types/CreateCommentRequestBody';
import {callApiSaga} from '../../utils/api';
import {getStringErrorMessage} from '../../utils/getStringErrorMessage';
import {selectActiveBranch} from '../user/selectors';
import {NAME} from './constants';
import {deleteComment, loadComments, updateComment} from './reducer';

export const loadCommentsListRequest = createAction<{
  entityId: string;
}>(`${NAME}/loadCommentsListRequest`);

export const createCommentRequest = createAction<{
  entityId: string;
  body: CreateCommentRequestBody;
}>(`${NAME}/createCommentRequest`);

export const updateCommentRequest = createAction<{
  entityId: string;
  uuid: string;
  body: CreateCommentRequestBody;
}>(`${NAME}/updateCommentRequest`);

export const deleteCommentRequest = createAction<{
  entityId: string;
  uuid: string;
}>(`${NAME}/deleteCommentRequest`);

function* loadCommentsListGen({payload}: ReturnType<typeof loadCommentsListRequest>): Generator {
  const {entityId} = payload;

  try {
    const xBranch = (yield* select(selectActiveBranch)) as string;
    const response = yield* callApiSaga(CommentService.getComments, {
      xBranch,
      externalId: entityId,
    });

    yield* put(loadComments(response ?? []));
  } catch (error: any) {
    showNotification.error(getStringErrorMessage(error as AxiosError<ApiException>));
  }
}

function* createCommentGen({payload}: ReturnType<typeof createCommentRequest>): Generator {
  const {entityId, body} = payload;
  const xBranch = (yield* select(selectActiveBranch)) as string;

  yield* callApiSaga(CommentService.createComment, {
    xBranch,
    requestBody: body,
    externalId: entityId,
  });

  yield* put(
    loadCommentsListRequest({
      entityId,
    })
  );
}

function* updateCommentGen({payload}: ReturnType<typeof updateCommentRequest>): Generator {
  const {entityId, uuid, body} = payload;

  const xBranch = (yield* select(selectActiveBranch)) as string;

  yield* put(updateComment({uuid, message: body.message}));

  yield* callApiSaga(CommentService.updateComment, {
    xBranch,
    requestBody: body,
    commentUuid: uuid,
    externalId: entityId,
  });

  yield* put(
    loadCommentsListRequest({
      entityId,
    })
  );
}

function* deleteCommentGen({payload}: ReturnType<typeof deleteCommentRequest>): Generator {
  const {entityId, uuid} = payload;

  const xBranch = (yield* select(selectActiveBranch)) as string;

  yield* put(deleteComment({uuid}));

  yield* callApiSaga(CommentService.deleteComment, {
    xBranch,
    commentUuid: uuid,
    externalId: entityId,
  });

  yield* put(
    loadCommentsListRequest({
      entityId,
    })
  );
}

export function* commentsSaga(): Generator {
  yield* all([
    takeEvery(loadCommentsListRequest, loadCommentsListGen),
    takeEvery(createCommentRequest, createCommentGen),
    takeEvery(updateCommentRequest, updateCommentGen),
    takeEvery(deleteCommentRequest, deleteCommentGen),
  ]);
}
