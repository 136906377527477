import {DataStatus} from 'platform/components';

import {useParams} from 'react-router-dom';

import {useGetAdditionalNoteQuery, useGetVehicleV2Query} from '@omnetic-dms/shared';

import {ServiceVehicleForm} from '../../components/ServiceVehicleForm/ServiceVehicleForm';
import {convertVehicleToFormData} from '../../components/ServiceVehicleForm/utils/convertVehicleToFormData';
import {ServiceVehicleFormMode} from '../../types/ServiceVehicleFormMode';

interface EditOrCloneServiceVehicleProps {
  mode: ServiceVehicleFormMode;
}

export function EditOrCloneServiceVehicle(props: EditOrCloneServiceVehicleProps) {
  const routeParams = useParams();
  const vehicleId = routeParams.id;

  const vehicleDetailQuery = useGetVehicleV2Query({vehicleId: vehicleId || ''}, {skip: !vehicleId});
  const additionalNoteQuery = useGetAdditionalNoteQuery(
    {vehicleId: vehicleId || ''},
    {skip: !vehicleId}
  );

  const isLoading = vehicleDetailQuery.isLoading || additionalNoteQuery.isLoading;
  const isError = vehicleDetailQuery.isError; // we don't need note to success. If it's empty BE returns 404 anyway

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <ServiceVehicleForm
        vehicleId={vehicleId || ''}
        mode={props.mode}
        defaultFormData={convertVehicleToFormData(
          vehicleDetailQuery.data,
          additionalNoteQuery.data?.note
        )}
      />
    </DataStatus>
  );
}
