import {
  Form,
  FormField,
  Separator,
  ButtonGroup,
  Button,
  FormButton,
  FormSubmitHandler,
  DataStatus,
} from 'platform/components';
import {HStack, Box, Text, VStack, Hide} from 'platform/foundation';
import * as Yup from 'yup';

import {head} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {
  usePatchLabourCatalogTimeNormMutation,
  usePostLabourCatalogTimeNormMutation,
} from '../../../api/metadaWorkshopLabourCatalogApi';
import {usePostWorkBasketItemWithTimeNormMutation} from '../../../api/metadaWorkshopWorkBasketApi';
import {useGetVatRatesOptions} from '../../../hooks/useGetVatRatesOptions';
import {useWorkTypeOptions} from '../../../hooks/useWorkTypeOptions';
import i18n from '../../../i18n/i18n';
import {GetLabourCatalogApiResponse} from '../../../types/api/metadaWorkshopLabourCatalog';
import {handleApiError} from '../../../utils/handleApiError';
import {TreeFolderPath} from '../../TreeFolderPath/TreeFolderPath';
import {CatalogueFolder} from '../types/catalogueFolder';
import {FreePositionData} from '../types/freePositionData';

type TimeNormFormType = {
  name: string;
  number: string;
  labourCatalogCategoryId: string;
  workType: string;
  quantity: number;
  vatType: string;
  isDoNotApplyDiscount: boolean;
};

interface TimeNormFormProps extends TestIdProps {
  initalValues: Partial<GetLabourCatalogApiResponse>;
  freePositionData?: FreePositionData;
  editedData?: GetLabourCatalogApiResponse;
  isFreePosition?: boolean;
  onClose: () => void;
  onSubmitted: (itemId?: string | null) => Promise<void>;
  catalogueFolder?: CatalogueFolder;
  onCatalogueFolderChange: VoidFunction;
  onInitialValuesChange: (values: Partial<GetLabourCatalogApiResponse>) => void;
}

export function TimeNormForm(props: TimeNormFormProps) {
  const [postLabourCatalogTimeNorm, {isLoading: isPostLabourCatalogTimeNormLoading}] =
    usePostLabourCatalogTimeNormMutation();
  const [patchLabourCatalogTimeNorm, {isLoading: isPatchLabourCatalogTimeNormLoading}] =
    usePatchLabourCatalogTimeNormMutation();
  const [postWorkBasketItemWithTimeNorm, {isLoading: isPostWorkBasketItemWithTimeNormLoading}] =
    usePostWorkBasketItemWithTimeNormMutation();
  const {getOptionsWithSelectedValue, defaultWorkTypeId, isLoading} = useWorkTypeOptions();

  const workTypeOptions = getOptionsWithSelectedValue(props.editedData?.workType);

  const [vatOptions] = useGetVatRatesOptions();

  const initialValues = props.editedData ?? props.initalValues;

  const handleSubmit: FormSubmitHandler<TimeNormFormType> = async (data) => {
    const body = {
      ...data,
      unit: i18n.t('general.labels.hour'),
      labourCatalogCategoryId:
        props.catalogueFolder?.contextId ?? props.editedData?.labourCatalogCategoryId,
    };

    if (props.editedData) {
      await patchLabourCatalogTimeNorm({labourCatalogId: props.editedData.id ?? '', body})
        .unwrap()
        .catch(handleApiError);
      await props.onSubmitted?.(props.editedData.id);
      return;
    }

    if (props.isFreePosition && props.freePositionData) {
      await postWorkBasketItemWithTimeNorm({
        serviceCaseId: props.freePositionData.serviceCaseId,
        serviceJobId: props.freePositionData.serviceJobId,
        serviceOrderId: props.freePositionData.serviceOrderId,
        body: {
          ...data,
          unit: i18n.t('general.labels.hour'),
        },
      })
        .unwrap()
        .then(() => {
          props.onSubmitted?.();
        })
        .catch(handleApiError);
      return;
    }

    const response = await postLabourCatalogTimeNorm({body}).unwrap().catch(handleApiError);
    await props.onSubmitted?.(response?.id);
  };

  const defaultValues: Partial<TimeNormFormType> = {
    name: initialValues?.name ?? undefined,
    number: initialValues?.number ?? undefined,
    workType: initialValues?.workType ?? defaultWorkTypeId ?? undefined,
    quantity: initialValues?.quantity ?? undefined,
    vatType: initialValues?.vatType ?? head(vatOptions)?.value,
    isDoNotApplyDiscount: initialValues?.isDoNotApplyDiscount ?? undefined,
  };

  const isSubmitLoading =
    isPostLabourCatalogTimeNormLoading ||
    isPatchLabourCatalogTimeNormLoading ||
    isPostWorkBasketItemWithTimeNormLoading;

  return (
    <DataStatus isLoading={isLoading} minHeight={90}>
      <Form<TimeNormFormType>
        schema={TimeNormFormSchema}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
      >
        {(control, formApi) => {
          formApi.watch((data) => props.onInitialValuesChange(data));

          return (
            <>
              <VStack spacing={4}>
                <FormField
                  control={control}
                  type="text"
                  name="name"
                  label={i18n.t('general.labels.name')}
                  isRequired
                  data-testid={suffixTestId('name', props)}
                />
                <HStack spacing={4} width="100%">
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="text"
                      name="number"
                      label={i18n.t('general.labels.number')}
                      isRequired
                      data-testid={suffixTestId('number', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="workType"
                      options={workTypeOptions}
                      label={i18n.t('entity.addWork.lables.workCategory')}
                      placeholder={i18n.t('general.labels.select')}
                      isRequired
                      menuInPortal
                      data-testid={suffixTestId('workType', props)}
                    />
                  </Box>
                </HStack>
                <Hide when={props.isFreePosition}>
                  <TreeFolderPath
                    leafId={props.catalogueFolder?.id ?? props.editedData?.treeFolder?.id}
                    onEdit={props.onCatalogueFolderChange}
                    data-testid={suffixTestId('categoryPath', props)}
                  />
                </Hide>
                <Separator spacing={0} />
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="number"
                      name="quantity"
                      label={i18n.t('entity.addWork.lables.timeNorm')}
                      isRequired
                      suffix={i18n.t('general.labels.hour')}
                      data-testid={suffixTestId('quantity', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="vatType"
                      options={vatOptions}
                      label={i18n.t('entity.addWork.lables.vat')}
                      placeholder={i18n.t('general.labels.select')}
                      isRequired
                      menuInPortal
                      data-testid={suffixTestId('vatType', props)}
                    />
                  </Box>
                </HStack>
                <Separator spacing={0} />
                <HStack align="flex-start">
                  <Box paddingRight={4}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="isDoNotApplyDiscount"
                      data-testid={suffixTestId('isDoNotApplyDiscount', props)}
                    />
                  </Box>
                  <Text size="small">{i18n.t('entity.addWork.lables.dontApplyDiscount')}</Text>
                </HStack>
              </VStack>
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discard')}
                  variant="secondary"
                  onClick={props.onClose}
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  title={
                    props.editedData
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.create')
                  }
                  isLoading={isSubmitLoading}
                  data-testid={suffixTestId('create', props)}
                />
              </ButtonGroup>
            </>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const TimeNormFormSchema = Yup.object({
  name: Yup.string().required(),
  number: Yup.string().required(),
  workType: Yup.string().required(),
  quantity: Yup.number().required(),
  vatType: Yup.string().required(),
  isDoNotApplyDiscount: Yup.boolean().nullable(),
});
