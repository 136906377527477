import {createAction, createSlice} from '@reduxjs/toolkit';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/shared';

import {NAME} from './constants';
import {
  LoadVehicleDetailPayload,
  SubmitSummaryChangeRequestPayload,
  VehicleDetailState,
} from './types';

export const loadVehicleDetail = createAction<LoadVehicleDetailPayload>(
  `${NAME}/vehicleDetail/loadVehicleDetail`
);

export const submitSourcingVehicleSummaryChange = createAction<SubmitSummaryChangeRequestPayload>(
  `${NAME}/vehicleDetail/submitSourcingVehicleSummaryChange`
);

export const addVehicleCartDetail = createAction(`${NAME}/vehicleDetail/addVehicleCartDetail`);

export const removeVehicleCartDetail = createAction(
  `${NAME}/vehicleDetail/removeVehicleCartDetail`
);

export const addVehicleFavouriteDetail = createAction(
  `${NAME}/vehicleDetail/addVehicleFavouriteDetail`
);

export const removeVehicleFavouriteDetail = createAction(
  `${NAME}/vehicleDetail/removeVehicleFavouriteDetail`
);

export const removeVehicleComparisonDetail = createAction(
  `${NAME}/vehicleDetail/removeVehicleComparisonDetail`
);

const initialState: VehicleDetailState = {
  isLoading: true,
  vehicle: {} as SourcingVehicleDetailResponseBody,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    copyDataForDetail: (_state, {payload}) => ({
      ...initialState,
      vehicle: payload,
    }),
    setIsLoading: (state, {payload}) => {
      state.isLoading = payload;
    },
    loadedVehicleDetail: (_state, {payload}) => ({
      isLoading: false,
      vehicle: payload,
    }),
    submitSummaryChange: (state, {payload}) => {
      state.vehicle.vehicleSummary = {...state.vehicle.vehicleSummary, ...payload.summary};
    },
    updateVehicleDetailCosts: (state, {payload}) => {
      state.vehicle.summary = {...state.vehicle.summary, ...payload};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadVehicleDetail, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(addVehicleFavouriteDetail, (state) => {
        state.vehicle.favourite = true;
      })
      .addCase(removeVehicleFavouriteDetail, (state) => {
        state.vehicle.favourite = false;
      })
      .addCase(addVehicleCartDetail, (state) => {
        state.vehicle.inCart = true;
      })
      .addCase(removeVehicleCartDetail, (state) => {
        state.vehicle.inCart = false;
      })
      .addCase(removeVehicleComparisonDetail, (state) => {
        state.vehicle.comparison = false;
      });
  },
});

const {
  reducer,
  actions: {setIsLoading, copyDataForDetail, loadedVehicleDetail, updateVehicleDetailCosts},
} = slice;

export {setIsLoading, copyDataForDetail, loadedVehicleDetail, updateVehicleDetailCosts};

export const vehicleDetailReducer = reducer;
