import {Card, Button} from 'platform/components';
import {Spinner, Icon, Show, HStack, VStack, Text} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isEmptyArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  handleApiError,
  useGetSimilarVehiclesQuery,
  useLazyGetSimilarVehiclesQuery,
  GetSimilarVehiclesApiResponse,
  SimilarVehicleResponseBody,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps, useDebouncedValue} from 'shared';

import {MatchingVehicle} from './MatchingVehicle';

const DEFAULT_VEHICLE_COUNT = 4;

export interface MatchingVehiclesProps extends TestIdProps {
  vin: string | Nullish;
  registrationPlateContains: string | Nullish;
  onVehicleSelect?: (vehicle: SimilarVehicleResponseBody) => void;
}

export function MatchingVehicles(props: MatchingVehiclesProps) {
  const [moreVehicles, setMoreVehicles] = useState<GetSimilarVehiclesApiResponse | undefined>(
    undefined
  );

  const debouncedVin = useDebouncedValue(props.vin, 700);
  const debouncedRegistrationPlateContains = useDebouncedValue(
    props.registrationPlateContains,
    700
  );

  const [getSimilarVehicles, {isLoading: isLoadingMore, isFetching: isFetchingMore}] =
    useLazyGetSimilarVehiclesQuery();
  const {
    data: vehicles,
    isLoading,
    isFetching,
  } = useGetSimilarVehiclesQuery(
    {
      size: DEFAULT_VEHICLE_COUNT,
      registrationPlateContains: debouncedRegistrationPlateContains,
      vin: debouncedVin,
    },
    {skip: !debouncedVin && !debouncedRegistrationPlateContains}
  );

  useEffect(() => {
    setMoreVehicles(undefined);
  }, [debouncedVin, debouncedRegistrationPlateContains]);

  const onLoadMoreClick = async () => {
    const moreVehiclesResponse = await getSimilarVehicles({
      offset: DEFAULT_VEHICLE_COUNT,
      registrationPlateContains: debouncedRegistrationPlateContains,
      vin: debouncedVin,
    })
      .unwrap()
      .catch(handleApiError);

    if (moreVehiclesResponse) {
      setMoreVehicles(moreVehiclesResponse);
    }
  };

  const isEmpty = isEmptyArray(vehicles);
  const isLoadingVehicles = isFetching || isLoading;
  const isLoadingMoreVehicles = isLoadingMore || isFetchingMore;

  if (!debouncedVin && !debouncedRegistrationPlateContains) {
    return null;
  }

  return (
    <>
      <Card
        variant="inlineGrey"
        title={
          !isLoadingVehicles && !isEmpty
            ? i18n.t('entity.vehicle.labels.matchingVehicles')
            : undefined
        }
      >
        <Show when={isLoadingVehicles || isEmpty}>
          <HStack spacing={2} align="center" justify="center">
            <Show when={isLoadingVehicles}>
              <Spinner size="small" />
              <Text size="small" color="tertiary">
                {i18n.t('entity.vehicle.labels.searching')}
              </Text>
            </Show>
            <Show when={isEmpty && !isLoadingVehicles}>
              <Icon value="notification/do_disturb" color="text.tertiary" size={4} />
              <Text size="small" color="tertiary" data-testid={suffixTestId('noVehicles', props)}>
                {i18n.t('entity.vehicle.labels.noMatchingVehicles')}
              </Text>
            </Show>
          </HStack>
        </Show>
        <Show when={!isLoadingVehicles && !isEmpty}>
          <VStack spacing={4}>
            {vehicles?.map((vehicle, index) => (
              <Card key={vehicle.id} variant="inlineWhite">
                <MatchingVehicle
                  onSelect={props.onVehicleSelect}
                  vehicle={vehicle}
                  data-testid={suffixTestId(`matching-[${index}]`, props)}
                />
              </Card>
            ))}
            {moreVehicles?.map((vehicle, index) => (
              <Card key={vehicle.id} variant="inlineWhite">
                <MatchingVehicle
                  onSelect={props.onVehicleSelect}
                  vehicle={vehicle}
                  data-testid={suffixTestId(`matching-[${index + DEFAULT_VEHICLE_COUNT}]`, props)}
                />
              </Card>
            ))}
          </VStack>
        </Show>
      </Card>
      <Show when={vehicles?.length === DEFAULT_VEHICLE_COUNT && isNil(moreVehicles)}>
        <Button
          leftIcon="navigation/expand_more"
          variant="secondary"
          title={i18n.t('entity.customer.actions.more')}
          onClick={onLoadMoreClick}
          isLoading={isLoadingMoreVehicles}
        />
      </Show>
    </>
  );
}
