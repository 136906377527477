import {Checkbox} from '../../Checkbox/Checkbox';
import {useFieldProps} from '../hooks/useFieldProps';
import {CheckboxRendererResponseBody} from '../types/modelSchema';

export function CheckboxRenderer(props: CheckboxRendererResponseBody) {
  const {name} = props;
  const {onChange, value} = useFieldProps(props);

  return <Checkbox data-testid={name} label={props.label} value={value} onChange={onChange} />;
}
