import {GetContextMenuItemsParams} from 'ag-grid-community';
import i18n from 'i18next';
import {AppLanguage, formatDateTime, formatNumber, LocaleConfig} from 'platform/locale';

import {isNil, isNotNil, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {parseDate} from 'shared';

import {CheckboxCellRenderer} from '../components/cells/CheckboxCellRenderer';
import {DateCellRenderer} from '../components/cells/DateCellRenderer';
import {DateTimeCellRenderer} from '../components/cells/DateTImeCellRenderer';
import {DecimalCellRenderer} from '../components/cells/DecimalCellRenderer';
import {FileCellRenderer} from '../components/cells/FileCellRenderer';
import {FilePlainCellRenderer} from '../components/cells/FilePlainCellRenderer';
import {FlagCellRenderer} from '../components/cells/FlagCellRenderer';
import {IntegerCellRenderer} from '../components/cells/IntegerCellRenderer';
import {LinkCellRenderer} from '../components/cells/LinkCellRenderer';
import {MoneyCellRenderer} from '../components/cells/MoneyCellRenderer';
import {MoneyWithoutDecimalCellRenderer} from '../components/cells/MoneyWithoutDecimalCellRenderer';
import {PlainCellRenderer} from '../components/cells/PlainCellRenderer';
import {TagCellRenderer, ValueProps} from '../components/cells/TagCellRenderer';
import {ThumbnailCellRenderer} from '../components/cells/ThumbnailCellRenderer';
import {BuildinMenuItem} from '../hooks/useContextMenu';
import {ColDef} from '../types/AgGridTypes';
import {ColumnResponseBody} from '../types/Api';
import {EntityResourceResolver} from '../types/EntityResourceResolver';
import {FlagPropsGetterParams} from '../types/FlagPropsGetter';
import {LinkPropsGetterParams} from '../types/LinkPropsGetter';
import {RowData} from '../types/RowData';
import {TooltipGetterProps} from '../types/TooltipGetter';
import {getFlagProps} from './getFlagProps';

const disableCopyActions = (params: GetContextMenuItemsParams) => {
  const menuItems = params.context.getContextMenuItems(params) as BuildinMenuItem[];

  return menuItems.filter(
    (item) =>
      ![
        BuildinMenuItem.copy,
        BuildinMenuItem.copyWithHeaders,
        BuildinMenuItem.copyWithGroupHeaders,
      ].includes(item)
  );
};

export const columnToTypeColDefs = (
  column: ColumnResponseBody,
  localeConfig: LocaleConfig,
  language: AppLanguage,
  entityResourceResolver?: EntityResourceResolver
): Partial<ColDef> => {
  const colDef: Partial<ColDef> = {};

  const dataPath = column.key;
  const plainPath = [column.key, 'value'];
  const labelPath = [column.key, 'value', 'label'];
  const urlPath = [column.key, 'value', 'url'];
  const keyPath = [column.key, 'value', 'key'];
  const filePath = [column.key, 'value'];

  const plainValueGetter = ({data}: {data?: RowData}) => path(plainPath, data);
  const labelValueGetter = ({data}: {data?: RowData}) => path(labelPath, data);
  const urlValueGetter = ({data}: {data?: RowData}) => path(urlPath, data);
  const keyValueGetter = ({data}: {data?: RowData}) => path(keyPath, data);

  colDef.valueGetter = plainValueGetter;

  switch (column.type) {
    case 'CODE_LIST':
      colDef.cellClass = ['PlainCellRenderer'];
      colDef.cellRenderer = PlainCellRenderer;
      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => ({
          label: path([column.key, 'value', 'label'], data),
          overflowWrap: 'anywhere',
        }),
      };
      colDef.valueGetter = ({data}: {data?: RowData}) => path([column.key, 'value', 'label'], data);
      colDef.chartDataType = 'category';
      break;
    case 'TEXT':
      colDef.cellClass = ['PlainCellRenderer'];
      colDef.cellRenderer = PlainCellRenderer;
      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => ({
          label: path([column.key, 'value'], data),
          overflowWrap: 'anywhere',
        }),
      };
      colDef.valueGetter = ({data}: {data?: RowData}) => path([column.key, 'value'], data);
      colDef.chartDataType = 'category';
      break;
    case 'ENTITY':
      if (column.renderer === 'LINK') {
        colDef.cellClass = ['LinkCellRenderer'];
        colDef.cellRenderer = LinkCellRenderer;
        colDef.cellRendererParams = {
          getTooltipProps: ({data}: TooltipGetterProps) => {
            const linkData = path(labelPath, data);

            if (!isArray(linkData)) {
              return {label: path(['value', 'label'], data), overflowWrap: 'anywhere'};
            }

            return linkData.map((link) => ({
              label: path(['label'], link),
              overflowWrap: 'anywhere',
            }));
          },
          getLinkProps: ({data}: LinkPropsGetterParams) => {
            const linkData = path([column.key, 'value'], data);

            if (isNil(linkData)) {
              return;
            }

            if (!isArray(linkData)) {
              return {
                to:
                  entityResourceResolver &&
                  entityResourceResolver(
                    path(['resourceId'], linkData ?? {}) ?? column?.resourceId,
                    path([column.key, 'value', 'id'], data ?? {}) as string
                  ),
              };
            }

            return linkData.map((link) => ({
              to:
                entityResourceResolver &&
                entityResourceResolver(link?.resourceId ?? column?.resourceId, path(['id'], link)),
            }));
          },
        };
        colDef.valueGetter = ({data}: {data?: RowData}) => {
          const linkData = path([column.key, 'value'], data);

          if (isNil(linkData)) {
            return;
          }

          if (!isArray(linkData)) {
            return path(['label'], linkData);
          }

          return linkData.map((link) => path(['label'], link));
        };
      } else {
        colDef.cellClass = ['PlainCellRenderer'];
        colDef.cellRenderer = PlainCellRenderer;
        colDef.cellRendererParams = {
          getTooltipProps: ({data}: TooltipGetterProps) => {
            const linkData = path([column.key, 'value'], data);

            if (!isArray(linkData)) {
              return {label: path(['label'], linkData), overflowWrap: 'anywhere'};
            }

            return {
              label: linkData.map((link) => path(['label'], link)).join(', '),
              overflowWrap: 'anywhere',
            };
          },
        };
        colDef.valueGetter = ({data}: {data?: RowData}) => {
          const linkData = path([column.key, 'value'], data);

          if (isNil(linkData)) {
            return;
          }

          if (!isArray(linkData)) {
            return path(labelPath, data);
          }

          return linkData.map((link) => path(['label'], link));
        };
      }

      colDef.chartDataType = 'category';
      break;
    case 'MONEY':
      if (column.renderer === 'MONEY') {
        colDef.cellRenderer = MoneyCellRenderer;
      }

      if (column.renderer === 'MONEY_WITHOUT_DECIMAL') {
        colDef.cellRenderer = MoneyWithoutDecimalCellRenderer;
      }
      colDef.cellClass = ['MoneyCellRenderer', 'ag-right-aligned-cell'];

      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => {
          const money = path([column.key, 'value'], data) as
            | {amount: number; currency: string}
            | undefined;
          return money ? `${money.amount} ${money.currency}` : '';
        },
      };
      colDef.valueGetter = plainValueGetter;
      colDef.valueFormatter = ({value}) =>
        (isNotNil(value) && formatNumber(localeConfig, value.amount, 2)) || '';
      colDef.chartDataType = 'series';
      break;
    case 'ENUM':
      if (column.renderer === 'FLAG') {
        colDef.cellClass = ['FlagCellRenderer'];
        colDef.cellRenderer = FlagCellRenderer;
        colDef.cellRendererParams = {
          getTooltipProps: ({data}: TooltipGetterProps) => {
            const linkData = path(labelPath, data);

            if (!isArray(linkData)) {
              return {label: linkData, overflowWrap: 'anywhere'};
            }

            return {label: linkData.map((link) => path(['label'], link)), overflowWrap: 'anywhere'};
          },
          getFlagProps: ({data}: FlagPropsGetterParams) =>
            getFlagProps(column.enumType, keyValueGetter({data}) as string),
        };
        colDef.valueGetter = ({data}: {data?: RowData}) => {
          const flagData = path([dataPath], data);

          if (isNil(flagData)) {
            return;
          }

          return flagData;
        };
        colDef.valueFormatter = ({value}) => {
          if (isNil(value?.value)) {
            return '';
          }
          const valueToRender = value.value as ValueProps | ValueProps[];

          return isArray(valueToRender)
            ? valueToRender
                .filter((v) => v)
                .map((v) => v.label)
                .join(', ')
            : valueToRender.label;
        };
      } else {
        colDef.cellClass = ['PlainCellRenderer'];
        colDef.cellRenderer = PlainCellRenderer;
        colDef.cellRendererParams = {
          getTooltipProps: ({data}: TooltipGetterProps) => ({
            label: labelValueGetter({data}),
            overflowWrap: 'anywhere',
          }),
        };
        colDef.valueGetter = labelValueGetter;
      }

      colDef.chartDataType = 'category';
      break;
    case 'DATE_TIME':
      if (column.renderer === 'DATE_TIME') {
        colDef.cellClass = ['DateTimeCellRenderer'];
        colDef.cellRenderer = DateTimeCellRenderer;
      } else {
        colDef.cellClass = ['DateCellRenderer'];
        colDef.cellRenderer = DateCellRenderer;
      }

      colDef.valueGetter = ({data}: {data?: RowData}) => path([column.key, 'value'], data);
      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => ({
          label: path([column.key, 'value'], data),
        }),
      };
      colDef.valueFormatter = ({value}) => {
        if (isNil(value)) {
          return '';
        }

        return formatDateTime(
          localeConfig,
          language,
          column.renderer === 'DATE_TIME' ? 'dateTimeShort' : 'dateShort',
          parseDate(value) as Date
        );
      };
      break;
    case 'DATE':
      colDef.cellClass = ['DateCellRenderer'];
      colDef.cellRenderer = DateCellRenderer;
      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => ({
          label: path([column.key, 'value'], data),
        }),
      };
      colDef.valueGetter = ({data}: {data?: RowData}) => path([column.key, 'value'], data);
      colDef.valueFormatter = ({value}) => {
        if (isNil(value)) {
          return '';
        }

        return formatDateTime(localeConfig, language, 'dateShort', parseDate(value) as Date);
      };
      break;
    case 'BOOLEAN':
      if (column.renderer === 'CHECKBOX') {
        colDef.cellClass = ['CheckboxCellRenderer'];
        colDef.cellRenderer = CheckboxCellRenderer;
      } else {
        colDef.cellClass = ['PlainCellRenderer'];
        colDef.cellRenderer = PlainCellRenderer;
      }
      colDef.valueFormatter = ({value}) => {
        if (value === true) {
          return i18n.t('general.labels.yes');
        }
        if (value === false) {
          return i18n.t('general.labels.no');
        }
        return '';
      };
      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => ({
          label:
            path([column.key, 'value'], data) === true
              ? i18n.t('general.labels.yes')
              : i18n.t('general.labels.no'),
        }),
      };
      break;
    case 'IMAGE':
      colDef.cellClass = ['ThumbnailCellRenderer'];
      colDef.cellRenderer = ThumbnailCellRenderer;
      colDef.valueGetter = urlValueGetter;
      colDef.chartDataType = 'excluded';
      colDef.contextMenuItems = disableCopyActions;
      break;
    case 'INTEGER':
      if (column.renderer === 'PLAIN') {
        colDef.cellRenderer = PlainCellRenderer;
        colDef.cellClass = ['PlainCellRenderer', 'ag-right-aligned-cell'];
      } else {
        colDef.cellRenderer = IntegerCellRenderer;
        colDef.cellClass = ['IntegerCellRenderer', 'ag-right-aligned-cell'];
      }
      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => ({
          label: path([column.key, 'value'], data),
          overflowWrap: 'anywhere',
        }),
      };
      colDef.chartDataType = 'series';
      break;
    case 'FLOAT':
      if (column.renderer === 'INTEGER') {
        colDef.cellClass = ['IntegerCellRenderer', 'ag-right-aligned-cell'];
        colDef.cellRenderer = IntegerCellRenderer;
      } else {
        colDef.cellClass = ['DecimalCellRenderer', 'ag-right-aligned-cell'];
        colDef.cellRenderer = DecimalCellRenderer;
      }

      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => ({
          label: path([column.key, 'value'], data),
          overflowWrap: 'anywhere',
        }),
      };
      colDef.chartDataType = 'series';
      break;
    case 'TAG_VALUE':
      colDef.cellClass = ['TagCellRenderer'];
      colDef.cellRenderer = TagCellRenderer;
      colDef.valueGetter = ({data}: {data?: RowData}) => {
        const flagData = path(plainPath, data);

        if (isNil(flagData)) {
          return;
        }

        return flagData;
      };
      colDef.valueFormatter = ({value}) => {
        if (isNil(value?.value)) {
          return '';
        }
        const valueToRender = value as ValueProps | ValueProps[];

        return isArray(valueToRender)
          ? valueToRender
              .filter((v) => v)
              .map((v) => v.label)
              .join(', ')
          : valueToRender.label;
      };
      colDef.cellRendererParams = {
        getTooltipProps: ({data}: TooltipGetterProps) => {
          const linkData = path(plainPath, data);

          if (!isArray(linkData)) {
            return {label: path(['value', 'label'], linkData), overflowWrap: 'anywhere'};
          }

          return {label: linkData.map((link) => path(['label'], link)), overflowWrap: 'anywhere'};
        },
      };
      break;
    case 'FILE':
      if (column.renderer === 'PLAIN') {
        colDef.cellRenderer = FilePlainCellRenderer;
      } else {
        colDef.cellRenderer = FileCellRenderer;
      }
      colDef.contextMenuItems = disableCopyActions;
      colDef.valueGetter = ({data}: {data?: RowData}) => {
        const fileData = path(filePath, data);

        if (isNil(fileData)) {
          return;
        }

        return fileData;
      };
  }

  return colDef;
};
