import i18n from 'i18next';

export type OmneticReleaseNote = {
  type: 'feature' | 'bugfix';
  url: string;
  title: string;
  dateTime: string;
  description: string;
  featureName?: string;
  countryCodesAlpha3?: string[];
  // TODO: This should be probably handled by Doc360.
  urlByCountry?: {
    [key: string]: string;
  };
};

export const omneticReleaseNotes: OmneticReleaseNote[] = [
  {
    type: 'feature',
    title: i18n.t('release_12-2024-title'),
    description: i18n.t('release_12-2024-description'),
    dateTime: '10.9.2024',
    url: '/docs/news-2024-12',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-12',
      POL: '/docs/pl/news-2024-12',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_11-2024-title'),
    description: i18n.t('release_11-2024-description'),
    dateTime: '27.8.2024',
    url: '/docs/news-2024-11',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-10',
      POL: '/docs/pl/news-2024-10',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_10-2024-title'),
    description: i18n.t('release_10-2024-description'),
    dateTime: '13.8.2024',
    url: '/docs/news-2024-10',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-10',
      POL: '/docs/pl/news-2024-10',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_09-2024-title'),
    description: i18n.t('release_09-2024-description'),
    dateTime: '30.7.2024',
    url: '/docs/news-2024-09',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-09',
      POL: '/docs/pl/news-2024-09',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_08-2024-title'),
    description: i18n.t('release_08-2024-description'),
    dateTime: '16.7.2024',
    url: '/docs/news-2024-08',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-08',
      POL: '/docs/pl/news-2024-08',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_07-2024-title'),
    description: i18n.t('release_07-2024-description'),
    dateTime: '2.7.2024',
    url: '/docs/news-2024-07',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-07',
      POL: '/docs/pl/news-2024-07',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_06-2024-title'),
    description: i18n.t('release_06-2024-description'),
    dateTime: '18.6.2024',
    url: '/docs/news-2024-06',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-06',
      POL: '/docs/pl/news-2024-06',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_05-2024-title'),
    description: i18n.t('release_05-2024-description'),
    dateTime: '4.6.2024',
    url: '/docs/news-2024-05',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-05',
      POL: '/docs/pl/news-2024-05',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_04-2024-title'),
    description: i18n.t('release_04-2024-description'),
    dateTime: '12.5.2024',
    url: '/docs/news-2024-04',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/news-2024-04',
      POL: '/docs/pl/news-2024-04',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_03-2024-title'),
    description: i18n.t('release_03-2024-description'),
    dateTime: '6.4.2024',
    url: '/docs/news-2024-03',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_02-2024-title'),
    description: i18n.t('release_02-2024-description'),
    dateTime: '25.3.2024',
    url: '/docs/novinky-2024-02',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_01-2024-title'),
    description: i18n.t('release_01-2024-description'),
    dateTime: '5.2.2024',
    url: '/docs/novinky-2024-01',
    featureName: 'release_01-2024',
    countryCodesAlpha3: ['CZE', 'POL'],
    urlByCountry: {
      CZE: '/docs/novinky-2024-01',
      POL: '/docs/pl/news-omnetic-2024-01',
    },
  },
  {
    type: 'feature',
    title: i18n.t('release_09-2023-title'),
    description: i18n.t('release_09-2023-description'),
    dateTime: '22.12.2023',
    url: '/docs/novinky-2023-09',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_08-2023-title'),
    description: i18n.t('release_08-2023-description'),
    dateTime: '4.12.2023',
    url: '/docs/novinky-2023-08',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_07-2023-title'),
    description: i18n.t('release_07-2023-description'),
    dateTime: '6.11.2023',
    url: '/docs/novinky-2023-07',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_06-2023-title'),
    description: i18n.t('release_06-2023-description'),
    dateTime: '4.9.2023',
    url: '/docs/novinky-2023-06',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_05-2023-title'),
    description: i18n.t('release_05-2023-description'),
    dateTime: '7.7.2023',
    url: '/docs/novinky-2023-05',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_04-2023-title'),
    description: i18n.t('release_04-2023-description'),
    dateTime: '12.5.2023',
    url: '/docs/novinky-2023-04',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_03-2023-title'),
    description: i18n.t('release_03-2023-description'),
    dateTime: '4.4.2023',
    url: '/docs/novinky-2023-03',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_02-2023-title'),
    description: i18n.t('release_02-2023-description'),
    dateTime: '16.3.2023',
    url: '/docs/novinky-2023-02',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_01-2023-title'),
    description: i18n.t('release_01-2023-description'),
    dateTime: '6.2.2023',
    url: '/docs/novinky-2023-01',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_06-2022-title'),
    description: i18n.t('release_06-2022-description'),
    dateTime: '2.12.2022',
    url: '/docs/novinky-06-2022',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_05-2022-title'),
    description: i18n.t('release_05-2022-description'),
    dateTime: '26.10.2022',
    url: '/docs/novinky-05-2022',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_04-2022-title'),
    description: i18n.t('release_04-2022-description'),
    dateTime: '9.9.2022',
    url: '/docs/novinky-od-11-8-2022',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_03-2022-title'),
    description: i18n.t('release_03-2022-description'),
    dateTime: '28.7.2022',
    url: '/docs/novinky-od-28-7-2022',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_02-2022-title'),
    description: i18n.t('release_02-2022-description'),
    dateTime: '1.7.2022',
    url: '/docs/novinky-od-01-07-2022',
    countryCodesAlpha3: ['CZE'],
  },
  {
    type: 'feature',
    title: i18n.t('release_01-2022-title'),
    description: i18n.t('release_01-2022-description'),
    dateTime: '15.6.2022',
    url: '/docs/novinky-od-162022',
    countryCodesAlpha3: ['CZE'],
  },
];
