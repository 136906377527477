import {browserTracingIntegration} from '@sentry/browser';
import {init, User} from '@sentry/react';

import {environment} from '@omnetic-dms/environment';
import {GetCurrentUserInfoApiResponse, getWorkspaceFromUri} from '@omnetic-dms/shared';

export function sentryInit(user: GetCurrentUserInfoApiResponse) {
  const {workspace} = getWorkspaceFromUri();

  const roles = user.roles?.map((r) => r.title).toString();

  const branch = user.defaultBranch?.name;

  const userTag: User = {
    id: user.id,
    email: user.email,
    username: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email,
  };

  init({
    dsn: environment.SENTRY_DSN,
    tracesSampleRate: 1.0,
    debug: false,
    enabled: environment.ENV_TYPE === 'prod',
    environment: environment.ENV_TYPE,
    initialScope: {
      tags: {workspace, roles, branch},
      user: userTag,
    },
    integrations: [browserTracingIntegration()],
    release: process.env.COMMIT_HASH,
    normalizeDepth: 10,
    normalizeMaxBreadth: 10,
  });
}
