import {FormControl, FormField} from 'platform/components';
import {Box, HStack, Hide, Show} from 'platform/foundation';

import {filter, groupBy, isNotNil, map, pipe, sort} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  DocumentSeriesDefinitionResponseBody,
  InvoicingDocumentTypeUnion,
  useGetCurrentBranch,
  useGetDocumentSeriesDefinitionListQuery,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, useQueryState} from 'shared';

import {AccountingDocumentFormValues} from '../types';

interface CreateInvoiceBranchSelectProps extends RequiredTestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  documentType: InvoicingDocumentTypeUnion;
  canBeSend?: boolean;
}

export function DocumentSeriesSelect(props: CreateInvoiceBranchSelectProps) {
  const {data: documentSeries} = useGetDocumentSeriesDefinitionListQuery();

  const {activeBranchId} = useGetCurrentBranch();
  const [checkoutId] = useQueryState('checkoutId');

  const isCreatingCorrectiveTaxDocumentForBC = isNotNil(checkoutId);

  const groupedSeries = pipe(
    filter(
      ({invoicingDocumentType, branchId}: DocumentSeriesDefinitionResponseBody) =>
        invoicingDocumentType === props.documentType && (branchId === activeBranchId || !branchId)
    ),
    map((series) => ({
      isBranchSeries: isNotNilOrEmpty(series.branchId),
      label: series.name,
      value: series.id,
    })),
    sort((a, b) => a.label.localeCompare(b.label)),
    groupBy((seriesOption) => (seriesOption.isBranchSeries ? 'branchSeries' : 'tenantSeries'))
  )(documentSeries ?? []);

  const groupedSeriesOptions = [
    {
      label: i18n.t('entity.accounting.labels.branchSeries'),
      options: groupedSeries.branchSeries ?? [],
    },
    {
      label: i18n.t('entity.accounting.labels.tenantSeries'),
      options: groupedSeries.tenantSeries ?? [],
    },
  ];

  const internalLabel =
    props.documentType === 'corrective_tax_document'
      ? i18n.t('entity.invoice.labels.internalCorrectiveTaxDocument')
      : i18n.t('entity.invoice.labels.internalInvoice');

  return (
    <Box paddingVertical={3} paddingHorizontal={4}>
      <HStack align="center" justify="space-between">
        <Box width={50}>
          <FormField
            control={props.control}
            name="documentSeriesId"
            type="choice"
            options={groupedSeriesOptions}
            isNotClearable
            data-testid={suffixTestId('documentSeriesId', props)}
          />
        </Box>

        <HStack spacing={4}>
          <Hide when={isCreatingCorrectiveTaxDocumentForBC}>
            <FormField
              control={props.control}
              name="isInternal"
              type="checkbox"
              label={internalLabel}
              data-testid={suffixTestId('isInternal', props)}
            />
          </Hide>
          <Show when={props.canBeSend}>
            <FormField
              control={props.control}
              name="sendAfterCreation"
              type="checkbox"
              label={i18n.t('entity.invoice.labels.sendAfterCreation')}
              data-testid={suffixTestId('sendAfterCreation', props)}
            />
          </Show>
        </HStack>
      </HStack>
    </Box>
  );
}
