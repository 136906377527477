import {IRowNode} from 'ag-grid-community';

import {useCallback} from 'react';

import {GridApi} from '../types/AgGridTypes';
import {DefinitionResponseBody} from '../types/Api';

type UseRowSelectionReturnType = (node: IRowNode, selected: boolean) => void;

export const useRowSelection = (
  api: GridApi | undefined,
  rowSelection: DefinitionResponseBody['behavior']['rowSelectMode']
): UseRowSelectionReturnType =>
  useCallback(
    (node, selected) => {
      if (!api || !rowSelection) {
        return;
      }
      node.setSelected(selected, rowSelection === 'SINGLE');
      if (rowSelection === 'SINGLE') {
        api.refreshCells({
          suppressFlash: true,
          columns: ['eag-col-select'],
        });
      }
      if (rowSelection === 'MULTIPLE') {
        api.refreshHeader();
      }
    },
    [api, rowSelection]
  );
