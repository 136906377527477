// for lists
export const DEFAULT_ORDER_PARAMS = [] as string[];
export const DEFAULT_ORDER_ID = 'LAS_ADDED';

// for all offers
export const DEFAULT_RECOMMENDED_CLASSIFIEDS = [] as string[];
export const DEFAULT_RECOMMENDED_ID = 'RECOMMENDED';
const hash = [
  'car.make',
  'car.model',
  'year',
  'cubic_capacity',
  'body_type',
  'power',
  'transmission',
  'drive_type',
  'fuel_type',
  'mileage_range',
];
export const DEFAULT_ALPHABET_CLASSIFIEDS = [...hash, 'price'];
